import React from 'react';

import { FlowConditionContactTag } from './types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { ConditionChoiceType } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';

import { SelectGroup, InputGroup } from '~/components/Inputs';
import Catalog from '~/Catalog';
import StringPrimitiveParameterValueComponent from '../../FlowParameter/ParameterValue/String/StringPrimitiveParameterValueComponent';
import Validation from '~/util/Validation';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';

import TEST_ID from './FlowConditionContactTagComponent.testid';

const text = {
  tagLabel: Catalog.tagLabel,
  invalidTag: 'Voer een geldige tag in',
};
type MyProps = {
  condition: FlowConditionContactTag;
  onChange: (newCondition: FlowConditionContactTag) => void;
  conditionChoiceType?: ConditionChoiceType | null | undefined;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
};
class FlowConditionContactTagComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  validate = () => !this.hasInvalidTagName();

  hasInvalidTagName = () => {
    const { condition } = this.props;
    const { tagName } = condition;

    return !Validation.Tag.isValid(tagName.value);
  };

  render() {
    const { condition, onChange, baseActionContext, conditionChoiceType } =
      this.props;
    const { exists, tagName } = condition;

    const tagErrorText = this.hasInvalidTagName() ? text.invalidTag : '';
    const existOptions = getExistOptions(
      baseActionContext.actionType,
      conditionChoiceType,
    );

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => (
          <InputGroup>
            <StringPrimitiveParameterValueComponent
              value={tagName}
              error={
                showValidation && this.hasInvalidTagName()
                  ? tagErrorText
                  : undefined
              }
              formatFunction={Validation.Tag.format}
              inputLabel={text.tagLabel}
              change={{
                controlled: true,
                onChange: newValue => {
                  onChange({
                    ...condition,
                    tagName: newValue,
                  });
                },
              }}
            />
            <SelectGroup
              dataTestid={TEST_ID.EXIST_SWITCHER}
              options={existOptions}
              selectedIndex={existOptions.findIndex(
                option => option.value === exists.value,
              )}
              onChange={({ option }) => {
                if (!option) return;
                onChange({
                  ...condition,
                  exists: {
                    ...condition.exists,
                    value: option.value,
                  },
                });
              }}
            />
          </InputGroup>
        )}
      </FlowContext.Consumer>
    );
  }
}

const getExistOptions = (
  type: FLOW_ACTION_TYPE | null,
  conditionChoiceType: ConditionChoiceType | null | undefined,
) => {
  const isTrigger =
    type === FLOW_ACTION_TYPE.START && conditionChoiceType === 'START';

  return [
    {
      value: true,
      label: isTrigger ? 'toegevoegd' : 'is aanwezig',
    },
    {
      value: false,
      label: isTrigger ? 'verwijderd' : 'is afwezig',
    },
  ];
};

export default withBaseActionContext<MyProps>(FlowConditionContactTagComponent);
