import React from 'react';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
import AuthWrapperHOC from './components/AuthWrapperHOC';
import CompanyDetails from './components/SetupAccountWizard/CompanyDetails';
import { AuthForm } from './Auth.style';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import { navigate } from 'gatsby';

type Props = {
  refetchAccounts: () => Promise<any>;
};

const SetupAccount = ({ refetchAccounts }: Props) => {
  const isMountedRef = useIsMountedRef();
  const title = 'Account Details';

  return (
    <AuthWrapperHOC>
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />
        <title>{title}</title>
      </MetaTags>

      <AuthForm>
        <RegisterFormFieldSet data-testid="register-details-form">
          <CompanyDetails
            afterSubmit={async () => {
              await refetchAccounts();

              if (isMountedRef.current) void navigate('/-/');
            }}
          />
        </RegisterFormFieldSet>
      </AuthForm>
    </AuthWrapperHOC>
  );
};

const RegisterFormFieldSet = styled.div<{}>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  min-height: 100%;
  & > div {
    width: 100%;
  }
`;

export default SetupAccount;
