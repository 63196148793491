import { FlowConditionTimeElapsed } from './types.flow';
import {
  Flow_Condition__Input,
  Flow_Parameter__Number__Input,
} from '~/graphql/types';
import { NumberPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import {
  asNumberPrimitiveParameterValueInput,
  isNonZeroNumberParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Number';

export default (condition: FlowConditionTimeElapsed): Flow_Condition__Input => {
  const { minute, hour, day, month, year } = condition;

  return {
    Time: {
      Elapsed: {
        minute: asParameterValueInputOrNull(minute),
        hour: asParameterValueInputOrNull(hour),
        day: asParameterValueInputOrNull(day),
        month: asParameterValueInputOrNull(month),
        year: asParameterValueInputOrNull(year),
      },
    },
  };
};

const asParameterValueInputOrNull = (
  parameterValue: NumberPrimitiveParameterValue,
): null | Flow_Parameter__Number__Input => {
  if (isNonZeroNumberParameterValue(parameterValue)) {
    return asNumberPrimitiveParameterValueInput(parameterValue);
  } else {
    return null;
  }
};
