import { or } from 'ramda';
import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { SystemSize } from '~/theme';
import { ThemeColor } from '~/theme/System/tokens/colorPalette';
import arrayToCss from '~/util/arrayToCss/index';

export type Props = HTMLAttributes<HTMLDivElement> & {
  dataTestId?: string;
  justification?: Justification;
  align?: Justification;
  gap?: SystemSize;
  wrap?: Wrap;
  margin?: Array<SystemSize | null>;
  padding?: Array<SystemSize | null>;
  width?: string;
  direction?: 'row' | 'column';
  className?: string;
  nonResponsive?: boolean;
  ref?: React.Ref<HTMLDivElement>;
};

const JustificationContainer: React.FC<Props> = React.forwardRef(
  (
    {
      dataTestId,
      children,
      justification = 'start',
      align = 'start',
      margin = [],
      padding = [],
      nonResponsive = false,
      direction = 'row',
      wrap = 'unset',
      gap,
      width = 'auto',
      css,
      ...rest
    },
    ref,
  ) => (
    <Container
      data-testid={dataTestId}
      $justification={justification}
      $align={align}
      $margin={margin}
      $padding={padding}
      $nonResponsive={nonResponsive}
      $direction={direction}
      $wrap={wrap}
      $gap={gap}
      $width={width}
      css={css}
      ref={ref}
      {...rest}
    >
      {children}
    </Container>
  ),
);

export type Justification =
  | 'start'
  | 'end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'stretch';
type FlexName =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'stretch';
type Wrap = 'wrap' | 'nowrap' | 'wrap-reverse' | 'unset';

type StartOrEnd = 'start' | 'end';

const isStart = (justification: Justification): justification is 'start' =>
  justification === 'start';
const isEnd = (justification: Justification): justification is 'end' =>
  justification === 'end';
const isStartOrEnd = (
  justification: Justification,
): justification is StartOrEnd =>
  or(isStart(justification), isEnd(justification));

const justificationToFlexName = (direction: Justification): FlexName => {
  if (isStartOrEnd(direction)) return `flex-${direction}` as FlexName;

  return direction;
};

type ContainerProps = {
  $justification: Justification;
  $align: Justification;
  $wrap: Wrap;
  $width?: string;
  $gap?: SystemSize;
  $padding?: Props['padding'];
  $margin?: Props['margin'];
  $nonResponsive: boolean;
  $direction: Props['direction'];
  fontColor?: ThemeColor;
  $backgroundColor?: ThemeColor;
  className?: string;
};

const Container = styled.div<ContainerProps>(
  ({
    $justification,
    $align,
    $wrap,
    $gap,
    $padding = [null, null, null, null],
    $margin = [null, null, null, null],
    $nonResponsive,
    $direction = 'row',
    $width,
    theme,
  }) => css`
    display: flex;
    justify-content: ${justificationToFlexName($justification)};
    align-items: ${justificationToFlexName($align)};
    flex-direction: ${$direction};
    flex-wrap: ${$wrap};
    gap: ${$gap ? theme.space($gap) : 'unset'};
    width: ${$width};

    padding: ${arrayToCss($padding, theme)};
    margin: ${arrayToCss($margin, theme)};

    ${$nonResponsive === false
      ? theme.mq.lessThan('mobile')`
      flex-direction: ${$direction};
    `
      : ''}
  `,
);

export default JustificationContainer;
