import React from 'react';
import styled, { css } from 'styled-components';

import PaginationButton from './PaginationButton';
import range from '~/util/range';
import TEST_ID from './Pagination.testid';
import Icon from '~/components/Icon';

type Props = {
  totalItems: number;
  currentPage?: number | string;
  itemsPerPage?: number;
  onSelect: (selectedPage: number) => void;
};

const Pagination = ({
  totalItems,
  currentPage = 1,
  itemsPerPage = 10,
  onSelect,
}: Props) => {
  if (!totalItems) return null;
  const _currentPage = parseInt(currentPage.toString());

  const pageNeighbours = 2;

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startPage = Math.max(1, _currentPage - pageNeighbours);
  const endPage = Math.min(totalPages, _currentPage + pageNeighbours);

  const hasLeftSpill = startPage > 1;
  const hasRightSpill = totalPages > endPage;

  const fetchPageNumbers = () => {
    if (totalPages > 5) {
      if (_currentPage > totalPages) {
        return range(1, 5);
      }

      const pages = range(startPage, endPage);

      // handle: ... < {6 7} [8] {9 10} (10)
      if (hasLeftSpill && !hasRightSpill) {
        const extraPages = range(endPage - 4, startPage - 1);
        return [...extraPages, ...pages];
      }

      // handle: (1) {1 2} [3] {4 5} > ...
      if (!hasLeftSpill && hasRightSpill) {
        const extraPages = range(_currentPage + 3, startPage + 4);
        return [...pages, ...extraPages];
      }

      // handle: ... < {4 5} [6] {7 8} > ...
      return [...pages];
    }

    return range(1, totalPages);
  };

  const pages = fetchPageNumbers();

  const firstPageSelected = _currentPage === 1;
  const lastPageSelected = _currentPage === totalPages;
  const onePageToShow = totalPages === 1;
  const exceedsFivePages = totalPages > 5;

  return (
    <PaginationContainer>
      {exceedsFivePages && (
        <PaginationButton
          disabled={firstPageSelected}
          chevronItem
          data-testid={TEST_ID.GO_TO_START_BUTTON}
          onClick={() => {
            if (!firstPageSelected) {
              onSelect(1);
            }
          }}
        >
          <StyledIcon name="double-chevron" clockwise={90} />
        </PaginationButton>
      )}

      <PaginationButton
        disabled={onePageToShow || firstPageSelected}
        chevronItem
        data-testid={TEST_ID.GO_TO_PREVIOUS_BUTTON}
        onClick={() => {
          if (!onePageToShow && !firstPageSelected) {
            onSelect(_currentPage - 1);
          }
        }}
      >
        <StyledIcon name="chevron" clockwise={90} />
      </PaginationButton>

      {pages.map((number, index) => {
        const isSelected = _currentPage === number || onePageToShow;
        return (
          <PaginationButton
            key={index}
            selected={isSelected}
            data-testid={TEST_ID.NUMBER_BUTTON(number)}
            data-selected={isSelected}
            onClick={() => {
              onSelect(number);
            }}
            digit={number.toString().length}
          >
            {number}
          </PaginationButton>
        );
      })}

      <PaginationButton
        disabled={lastPageSelected || onePageToShow}
        chevronItem
        data-testid={TEST_ID.GO_TO_NEXT_BUTTON}
        onClick={() => {
          if (!lastPageSelected && !onePageToShow) {
            onSelect(_currentPage + 1);
          }
        }}
      >
        <StyledIcon name="chevron" clockwise={-90} />
      </PaginationButton>

      {exceedsFivePages && (
        <PaginationButton
          disabled={lastPageSelected}
          chevronItem
          data-testid={TEST_ID.GO_TO_END_BUTTON}
          onClick={() => {
            if (!lastPageSelected) {
              onSelect(totalPages);
            }
          }}
        >
          <StyledIcon name="double-chevron" clockwise={-90} />
        </PaginationButton>
      )}
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div<{}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    font-size: ${theme.space('s')};
    height: 100%;
  `,
);

export default Pagination;
