import React from 'react';
import styled, { css } from 'styled-components';
import TextEditor from './TextEditor';
import ChangeColorBlock from './ChangeColor';

type CombinedTextData = {
  color: string | null | undefined;
  text: string | null | undefined;
};

type Props = {
  /** Text to text field label */
  textTitle: string;

  /** Text to color picker label */
  colorTitle: string;

  /** object name for nested data field */
  objectName: string;

  /** Data for TextField and ColorPicker */
  data: CombinedTextData;

  /** Change value callback */
  onChange: (arg0: CombinedTextData) => void;

  /** Disable value */
  disabled?: boolean;

  /** To add more components on the right side */
  children?: React.ReactNode;

  /** Increases the height of the TextArea by 2 rows */
  largeTextArea?: boolean;
};
type State = {};

/**
 * Component is described here.
 *
 * @example ./__styleguide__/CombinedTextWithColor.md
 */
class CombinedTextWithColor extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
  };
  shouldComponentUpdate(nextProps: Props) {
    const { color, text } = this.props.data;
    return (
      nextProps.data.text !== text ||
      nextProps.data.color !== color ||
      nextProps.disabled !== this.props.disabled ||
      this.props.children !== nextProps.children
    );
  }

  onColorChange = (color: string) => {
    this.props.onChange({
      ...this.props.data,
      color,
    });
  };

  onTextEditorChange = (text: string) => {
    this.props.onChange({
      ...this.props.data,
      text,
    });
  };

  render() {
    const {
      textTitle,
      colorTitle,
      disabled,
      data,
      objectName,
      children,
      largeTextArea,
    } = this.props;

    return (
      <Container>
        <TextEditor
          testName={objectName}
          text={data.text || null}
          title={textTitle}
          onChange={this.onTextEditorChange}
          name="text"
          disabled={disabled || false}
          large={largeTextArea}
        />
        <ColorBlockContainer>
          <ChangeColorBlock
            testName={objectName}
            onChange={this.onColorChange}
            color={data.color || '#ddd'}
            title={colorTitle}
            name="color"
            disabled={disabled}
          />

          {children}
        </ColorBlockContainer>
      </Container>
    );
  }
}

const Container = styled.div<{}>`
  display: flex;

  > div:first-child {
    flex: 1;
    margin-right: ${({ theme }) => theme.space('xl')};

    ${({ theme }) => css`
      ${theme.mq.lessThan('tablet')`
        margin: 0;
      `}
    `}
  }

  ${({ theme }) => css`
    margin: ${theme.space('xl')} 0;
    ${theme.mq.lessThan('tablet')`
      flex-direction: column;
      justify-content: space-between;
    `};
  `}
`;

const ColorBlockContainer = styled.div<{}>``;

export default CombinedTextWithColor;
