import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import uuid from 'uuid/v4';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import { VariableElement } from '~/components/PluginsEditor/types';

const insertVariable = editor => {
  const dhVariable: VariableElement = {
    type: ELEMENTS.VARIABLE,
    variableName: '...',
    variableType: undefined,
    /** Add as pending because we do not know tha name of the variable yet */
    pending: true,
    mappingId: uuid().replaceAll('-', ''),
    children: [{ text: '' }],
  };

  Transforms.insertNodes(editor, dhVariable);
  ReactEditor.focus(editor);
};

export default insertVariable;
