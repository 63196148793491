import gql from 'graphql-tag';

export default gql`
  mutation StartEmailSync(
    $accountId: ID!
    $account: EmailSyncAccount
    $office: EmailSyncOffice
    $user: EmailSyncUser
  ) {
    startEmailSync(
      accountId: $accountId
      account: $account
      office: $office
      user: $user
    ) {
      email
      syncState
    }
  }
`;
