import React, { ReactElement } from 'react';
import styled from 'styled-components';
import uuidv1 from 'uuid/v1';

export type DescribeNodeElementType = ReactElement | string;
export type DescribeNodeType =
  | Array<DescribeNodeElementType>
  | DescribeNodeElementType;

/**
 * Wrap the given string to show that the user still needs to fill this in
 */
export const needsUserActionDescription = (
  str: string,
): DescribeNodeElementType => (
  <UserActionDescriptionSpan key={uuidv1()}>{str}</UserActionDescriptionSpan>
);

export const UserActionDescriptionSpan = styled.span<{}>`
  font-weight: bold;

  ${({ theme }) => `
    color: ${theme.color('accent')};
  `}
`;

/**
 * Wrap the given string to show that this is a value
 */
export const specificValueDescription = (
  str: DescribeNodeType | number | null,
): DescribeNodeElementType => (
  <ValueSpan key={uuidv1()}>&apos;{str || ''}&apos;</ValueSpan>
);

const ValueSpan = styled.span<{}>`
  font-weight: bold;
`;

/**
 * Wrap the given string to show that this is a field value
 */
export const fieldLabelDescription = (str: string): DescribeNodeElementType => (
  <FieldLabelSpan key={uuidv1()}>{str}</FieldLabelSpan>
);

const FieldLabelSpan = styled.span<{}>`
  font-weight: bold;
`;
