import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { IbanElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { Drawer } from '~/components';
import { InputGroup } from '~/components/Inputs';
import Dropdown from '~/components/Dropdown';

import {
  StartAddBillingMethod,
  UpdateBillingMethodMutation,
} from '~/graphql/Mutation';
import startAddBillingMethodMutation from '~/graphql/mutation/StartAddBillingMethod';
import TEST_ID from './index.testid';
import { PAYMENT_OPTIONS } from '~/scenes/Settings/Subscription/constants';
import IBANElement from '../ChangeOrUpdateSubscriptionFlows/steps/components/IBANElement';
import Button from '~/components/Button';
import Catalog from '~/Catalog';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import cleanedFilename from '~/util/cleanedFilename';
import updateBillingMethodMutation from '~/graphql/mutation/UpdateBillingMethod';
import StripeWrapper from '~/scenes/Settings/Subscription/components/StripeWrapper';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import { isNil } from 'ramda';

const text = {
  drawerTitle: 'Wijzig betaalmethode',
  paymentOptionLabel: 'Betaalmethode',
  noPaymentIdFromStripeErrorMessage:
    'Er kan geen verbinding worden gemaakt met de betalingsserver',
  noCustomerSecretErrorMessage: Catalog.genericUnknownErrorMessageShort,
  mutationError: Catalog.genericUnknownErrorMessageShort,
  buttonLabel: 'Opslaan',
};
type Props = {
  show: boolean;
  billingName?: string | null;
  billingEmail?: string | null;
  currentLast4?: string | null;
  onClose: (updated: boolean) => void;
};
const EditPaymentDetailsSidebar = ({
  show,
  onClose,
  billingName,
  billingEmail,
  currentLast4,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const account = useCurrentAccount();
  const [loading, setLoading] = useState(false);
  const [stripeErrorMsg, setStripeErrorMsg] = useState<string | null>(null);
  const isMountedRef = useIsMountedRef();

  return (
    <Drawer
      active={show}
      hideSidebar={() => onClose(false)}
      title={text.drawerTitle}
    >
      <StartAddBillingMethod
        name={__filename}
        mutation={startAddBillingMethodMutation}
        onError={() => setLoading(false)}
      >
        {(startAddBillingMethod, { error: startAddBillingError }) => (
          <UpdateBillingMethodMutation
            name={__filename}
            mutation={updateBillingMethodMutation}
            onCompleted={() => onClose(true)}
            onError={() => setLoading(false)}
          >
            {(updateBillingMethod, { error: updateBillingError }) => {
              let errorComponent: ReactElement | null = null;
              if (stripeErrorMsg != null) {
                errorComponent = <ErrorMsg>{stripeErrorMsg}</ErrorMsg>;
              } else if (startAddBillingError != null || updateBillingError) {
                errorComponent = <ErrorMsg>{text.mutationError}</ErrorMsg>;
              }

              return (
                <Container data-testid={TEST_ID.CONTAINER}>
                  {errorComponent}
                  <InputGroup>
                    <Dropdown
                      options={PAYMENT_OPTIONS}
                      onChange={() => {}}
                      selectedOptionIdx={0}
                      label={text.paymentOptionLabel}
                    />
                  </InputGroup>
                  <InputGroup>
                    <IBANElement
                      elements={elements}
                      currentLast4={currentLast4}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Button
                      size="medium"
                      data-testid={TEST_ID.SAVE_BUTTON}
                      loading={loading}
                      disabled={loading}
                      onClick={async () => {
                        if (elements == null) {
                          throw Error(
                            `${cleanedFilename(
                              __filename,
                            )} | Should not occur | elements == null`,
                          );
                        }
                        if (stripe == null) {
                          throw Error(
                            `${cleanedFilename(
                              __filename,
                            )} | Should not occur | stripe == null`,
                          );
                        }

                        if (billingEmail == null) {
                          throw Error(
                            `${cleanedFilename(
                              __filename,
                            )} | Should not occur | billingEmail == null`,
                          );
                        }

                        if (billingName == null) {
                          throw Error(
                            `${cleanedFilename(
                              __filename,
                            )} | Should not occur | billingName == null`,
                          );
                        }

                        const ibanElement = elements.getElement(IbanElement);
                        if (ibanElement == null) {
                          throw Error(
                            `${cleanedFilename(
                              __filename,
                            )} | Should not occur | ibanElement == null`,
                          );
                        }

                        setLoading(true);

                        const result = await startAddBillingMethod({
                          variables: {
                            accountId: account.id,
                          },
                        });

                        const customerSecret =
                          result?.data?.startAddBillingMethod?.customerSecret;

                        if (isNil(customerSecret)) {
                          setStripeErrorMsg(text.noCustomerSecretErrorMessage);
                          setLoading(false);

                          return;
                        }

                        if (!isMountedRef.current) return;

                        const { setupIntent, error } =
                          await stripe.confirmSepaDebitSetup(customerSecret, {
                            payment_method: {
                              sepa_debit: ibanElement,
                              billing_details: {
                                name: billingName,
                                email: billingEmail,
                              },
                            },
                          });

                        const paymentId = setupIntent?.payment_method;

                        if (paymentId == null) {
                          const stripeError = error?.message;

                          setStripeErrorMsg(
                            stripeError == null
                              ? text.noPaymentIdFromStripeErrorMessage
                              : stripeError,
                          );
                          setLoading(false);

                          return;
                        }

                        if (!isMountedRef.current) return;

                        return updateBillingMethod({
                          variables: {
                            accountId: account.id,
                            paymentId,
                          },
                        });
                      }}
                      label={text.buttonLabel}
                    />
                  </InputGroup>
                </Container>
              );
            }}
          </UpdateBillingMethodMutation>
        )}
      </StartAddBillingMethod>
    </Drawer>
  );
};

const Container = styled.div<{}>``;

const ErrorMsg = styled.div<{}>`
  ${({ theme }) => `
    color: ${theme.color('danger')};
  `};
`;

export default (props: Props) => (
  <StripeWrapper>
    <EditPaymentDetailsSidebar {...props} />
  </StripeWrapper>
);
