import { AddTags, Delete, SendCSV } from './actionSwitcher';

export const CONTACT_ACTION: {
  DELETE: Delete;
  ADD_TAGS: AddTags;
  SEND_CSV: SendCSV;
} = {
  DELETE: 'Delete',
  ADD_TAGS: 'AddTags',
  SEND_CSV: 'SendCSV',
};

const text = {
  addTag: 'Tag toewijzen',
  delete: 'Verwijderen',
  sendCSV: 'Exporteren',
};

export const actionOptions = [
  { label: text.addTag, payload: CONTACT_ACTION.ADD_TAGS, key: 'add-tags' },
  { label: text.delete, payload: CONTACT_ACTION.DELETE, key: 'delete' },
  { label: text.sendCSV, payload: CONTACT_ACTION.SEND_CSV, key: 'send-csv' },
];
