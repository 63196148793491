import { Flow_Condition__Input } from '~/graphql/types';

const asFlowConditionAppVBOWaardecheckReportNewInput =
  (): Flow_Condition__Input => ({
    EventContactApp: {
      VBOWaardecheck: {
        Report: {
          New: {
            noOperation: true,
          },
        },
      },
    },
  });

export default asFlowConditionAppVBOWaardecheckReportNewInput;
