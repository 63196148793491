import React from 'react';
import styled, { css } from 'styled-components';

import { StartAction } from './types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { FlowCondition } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';
import {
  FlowConditionList,
  FlowConditionListWithoutId,
} from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/types.flow';

import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import asStartActionInput from './asStartActionInput';
import { FLOW_ACTION_TYPE } from '../constants';
import FlowConditionDetailsComponent from '../Base/FlowCondition/FlowConditionDetailsComponent';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import { emptyFlowConditionContactDetails } from '../Base';
import FlowConditionListComponent from '../Base/FlowConditionList/FlowConditionListComponent';
import TEST_ID from './StartActionComponent.testid';
import { emptyFlowConditionValuationReportDetails } from '../Base/FlowCondition/AppValuationReportDetails';
import { emptyFlowConditionAppZapierDetails } from '../Base/FlowCondition/AppZapierDetails';
import { generateHash } from '~/util/react';
import { getBaseActionProps } from '../util/renderActionTree';

const text = {
  explanation: 'Start deze flow',
  conditionLabel: 'Trigger',
};

type MyProps = ActionSubscriberProps & {
  id: string;
  positionString: string;
  initialActionProps: StartAction;
  isHiddenFromView: boolean;
};
type Props = WithValidatorListProps &
  MyProps &
  ReturnType<typeof getBaseActionProps>;
type State = {
  conditionListFieldState: FlowConditionList;
  conditionFieldState: FlowCondition;
  key: string | null;
  variableStashVersion: string | null;
};
class StartActionComponent extends React.Component<Props, State> {
  _isMounted = false;
  variableStash: FlowVariableStash = emptyVariableStash();

  constructor(props: Props) {
    super(props);

    const { initialActionProps } = props;

    this.state = {
      conditionFieldState: initialActionProps.startCondition,
      conditionListFieldState: initialActionProps.conditionList,
      key: null,
      variableStashVersion: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.props.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  setConditionFieldState = (newFieldState: FlowCondition) => {
    if (this._isMounted) {
      const { conditionFieldState } = this.state;

      const shouldRefreshStash =
        newFieldState.type !== conditionFieldState.type ||
        newFieldState.type === 'Flow_Condition_Event_Contact_App_Zapier_New' ||
        newFieldState.type ===
          'Flow_Condition_Event_Contact_App_Zapier_Details';

      this.setState(
        {
          conditionFieldState: newFieldState,
        },
        () => {
          this.props.announceChanges({
            updateVariableStash: shouldRefreshStash,
          });
        },
      );
    }
  };

  onDropdownOpened = () => {
    if (this._isMounted) {
      const { conditionFieldState } = this.state;
      const shouldRefreshStash =
        conditionFieldState.type ===
          'Flow_Condition_Event_Contact_App_Zapier_New' ||
        conditionFieldState.type ===
          'Flow_Condition_Event_Contact_App_Zapier_Details';
      this.props.announceChanges({
        updateVariableStash: shouldRefreshStash,
      });
    }
  };

  setConditionListFieldState = (
    newFieldState: FlowConditionListWithoutId | FlowConditionList,
  ) => {
    if (this._isMounted) {
      this.setState(
        {
          conditionListFieldState: {
            ...newFieldState,
            conditions: newFieldState.conditions.map(
              (
                condition: FlowCondition | Omit<FlowCondition, 'id'>,
                idx: number,
              ) =>
                ({
                  id: generateHash(`${JSON.stringify(condition)}-${idx}`),
                  ...condition,
                } as FlowCondition),
            ),
          },
        },
        () => {
          this.props.announceChanges();
        },
      );
    }
  };

  setVariableStash = (newStash: FlowVariableStash) => {
    this.variableStash = newStash;

    if (this._isMounted) {
      this.setState({
        variableStashVersion: newStash.version,
      });
    }
  };

  getActionProp = (): StartAction => {
    const { conditionFieldState, conditionListFieldState } = this.state;
    const { id } = this.props;

    return {
      id,
      type: FLOW_ACTION_TYPE.START,
      startCondition: conditionFieldState,
      conditionList: conditionListFieldState,
    };
  };

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asStartActionInput(this.getActionProp(), treeNode, defaults);

  render() {
    const {
      subscribeValidator,
      unsubscribeValidator,
      isHiddenFromView,
      positionString,
      outputLoading,
      announceChanges,
    } = this.props;
    const { conditionFieldState, conditionListFieldState } = this.state;
    const action = this.getActionProp();

    return (
      <BaseActionComponent
        positionString={positionString}
        actionLabel={text.explanation}
        action={action}
        variableStash={this.variableStash}
        subscribeValidator={subscribeValidator}
        unsubscribeValidator={unsubscribeValidator}
        isHiddenFromView={isHiddenFromView}
      >
        <StartConditionContainer
          data-testid={TEST_ID.START_CONDITION_CONTAINER}
        >
          <FlowConditionDetailsComponent
            outputLoading={outputLoading}
            key={conditionFieldState.id}
            onDropdownOpened={this.onDropdownOpened}
            conditionLabel={text.conditionLabel}
            condition={conditionFieldState}
            onChange={this.setConditionFieldState}
            conditionChoiceType={'START'}
            announceChanges={announceChanges}
          />
        </StartConditionContainer>
        <ConditionListContainer>
          <FlowConditionListComponent
            outputLoading={outputLoading}
            onDropdownOpened={this.onDropdownOpened}
            allowNoConditions={true}
            conditionList={conditionListFieldState}
            onChange={this.setConditionListFieldState}
            getNewConditionToAdd={() => {
              if (
                conditionFieldState.type ===
                'Flow_Condition_Event_Contact_App_Zapier_New'
              ) {
                return emptyFlowConditionAppZapierDetails();
              }
              if (
                conditionFieldState.type ===
                'Flow_Condition_Event_Contact_App_ValuationReport_New'
              ) {
                return emptyFlowConditionValuationReportDetails();
              }
              return emptyFlowConditionContactDetails();
            }}
            conditionChoiceType={'START_SUBCONDITION'}
            announceChanges={announceChanges}
          />
        </ConditionListContainer>
      </BaseActionComponent>
    );
  }
}

const ConditionListContainer = styled.div<{}>`
  height: 100%;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.color('grey', 'light')};
  `};
`;

const StartConditionContainer = styled.div<{}>`
  ${({ theme }) => css`
    padding: 0 ${theme.space('xxs')};
  `};
`;

export default withValidatorList<MyProps>(StartActionComponent);
