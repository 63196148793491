import { FlowActionFieldsFragment } from '~/graphql/types';
import { ParentedFlowActionProps, ActionTreeNode } from './types.flow';

import buildTree from './buildTree';
import { actionFragmentToProp } from '~/scenes/Automation/Flows/Actions/util';
import { extractHandledFlowActions } from '~/graphql/types.client';

export const calculateActionTree = (
  actionList: Array<ParentedFlowActionProps>,
  outputLoading: { [actionId: string]: boolean } | Record<string, never>,
): Array<ActionTreeNode> => {
  const trees: Array<ActionTreeNode> = [];

  const { startActions, parentActionDict } = treeDictsFrom(
    actionList,
    action => action.props.id,
    action => {
      const foundAction = actionList.find(
        ({ props }) => action.props.id === props.id,
      );

      if (foundAction == null) {
        throw new Error(
          'Should not occur: >> calculateActionTree -> cannot find action in list',
        );
      }

      return foundAction.parentId;
    },
  );

  startActions.forEach(startAction => {
    trees.push(
      buildTree(
        startAction,
        parentActionDict,
        action => action.props.id,
        action => action.props,
        action => action.subscriber,
        [1],
        null,
        outputLoading,
      ),
    );
  });

  return trees;
};

export const calculateInitialActionTree = (
  initialActionsFull: Array<FlowActionFieldsFragment>,
  outputLoading,
): Array<ActionTreeNode> => {
  /** We are not handling any new actions for which we do not yet set the fragment */
  const initialActions = extractHandledFlowActions(initialActionsFull);

  const trees: Array<ActionTreeNode> = [];

  const { startActions, parentActionDict } = treeDictsFrom(
    initialActions,
    action => action.id,
    action => ('parentId' in action ? action.parentId : null),
  );

  startActions.forEach(startAction => {
    trees.push(
      buildTree(
        startAction,
        parentActionDict,
        actionFragment => actionFragment.id,
        actionFragment => actionFragmentToProp(actionFragment),
        () => null,
        [1],
        null,
        outputLoading,
      ),
    );
  });

  return trees;
};

type TreeDictsReturnType<T> = {
  startActions: Array<T>;
  parentActionDict: {
    [key: string]: Array<T>;
  };
};
export const treeDictsFrom = <T>(
  actions: Array<T>,
  idGetter: (item: T) => string,
  parentIdGetter: (item: T) => string | null,
): TreeDictsReturnType<T> => {
  const startActions: Array<T> = [];
  const parentActionDict = {};
  const actionDict = {};

  actions.forEach(action => {
    actionDict[idGetter(action)] = action;

    const parentId = parentIdGetter(action);

    if (parentId == null) {
      startActions.push(action);
    } else {
      if (parentActionDict[parentId] == null) {
        parentActionDict[parentId] = [];
      }
      parentActionDict[parentId].push(action);
    }
  });

  return {
    startActions,
    parentActionDict,
  };
};
