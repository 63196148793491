import React from 'react';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';

import Desktop from './components/Desktop';
import Mobile from './components/Mobile';

export type StepBase = {
  id: string;
  label: React.ReactNode;
  disabled: boolean;
};

export type Step = StepBase & { subSteps?: Array<StepBase> };

export type Props = {
  dataTestId?: string;
  steps: Array<Step>;
  currentStep: number;
  currentSubStep?: number | null;
};

const StepList: React.FC<Props> = (props: Props) => {
  const currentBp = useCurrentBreakpoint();

  switch (currentBp) {
    case 'mobile':
      return <Mobile {...props} />;
    default:
      return <Desktop {...props} />;
  }
};

export default StepList;
