import React from 'react';
import { ElementType } from 'react';
import { AnimatedComponent } from 'react-spring';
import styled, { css } from 'styled-components';
import { getSpinAnimation } from '~/styles/animations';
import { SystemColorPalette, SystemSize } from '~/theme';
import arrayToCss from '~/util/arrayToCss';
import icons from './components';

export type IconType = keyof typeof icons;

export type Props = React.HTMLAttributes<HTMLSpanElement> & {
  name: IconType;
  color?: string;
  className?: string;
  /** Rotates the icon clockwise by a specific degree */
  clockwise?: number;
  /** Rotates the icon 180 degrees clockwise */
  flipX?: boolean;
  /** Gives the spin animation */
  spin?: boolean;
  // To remove errors for now
  inline?: boolean;
  strokeWidth?: number | string;
  /** Adds a circle background with the given color */
  background?: keyof SystemColorPalette;
  /** To use it as an animated component */
  as?: AnimatedComponent<ElementType>;
  margin?: Array<SystemSize | null>;
};

const Icon = ({
  name,
  color,
  strokeWidth,
  margin = [null, null, null, null],
  ...props
}: Props) => {
  const PickedIcon = icons[name];

  return (
    <Container
      {...props}
      spin={name === 'spinner' || props.spin}
      $margin={margin}
      $strokeWidth={strokeWidth || 2}
    >
      <PickedIcon color={color || 'currentColor'} />
    </Container>
  );
};

type ContainerProps = {
  $strokeWidth: number | string;
  $margin: Array<SystemSize | null>;
};
const Container = styled.span<Props & ContainerProps>`
  display: inline-flex;
  align-items: center;
  margin: ${({ theme, $margin }) => arrayToCss($margin, theme)};

  /** This makes all chevrons transition */
  & > svg {
    height: 1em;
    width: 1em;
    transition: transform 0.3s ease-in-out;
  }

  & > * {
    /** Fix for safari. Children do not inherit the stroke-width from the parent so we pass it to them. */
    stroke-width: ${({ $strokeWidth }) => `${$strokeWidth}`};
  }

  ${({ clockwise, flipX, spin }) => {
    if (clockwise || flipX) {
      return css`
        svg {
          transform: rotate(${flipX ? 180 : clockwise}deg);
        }
      `;
    }

    if (spin) return getSpinAnimation();

    return null;
  }}

  ${({ background, theme }) => {
    if (background) {
      return css`
        margin: ${theme.space('xxxs')} 0 0 5px;
        position: relative;

        & > svg {
          z-index: 1;
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          width: 1.3em;
          height: 1.3em;

          background-color: ${theme.color(background)};
          border-radius: ${theme.getTokens().border.radius.full};
        }
      `;
    }
    return null;
  }}
`;

export default Icon;
