import { FormApi } from 'final-form';
import { isEmpty, omit } from 'ramda';
import React from 'react';
import MetaTags from 'react-meta-tags';
import styled, { css } from 'styled-components';
import Catalog from '~/Catalog';

import ContentContainerDefault from '~/components/ContentContainer/Default';
import DatHuisLoading from '~/components/DatHuisLoading';
import DetailsBlock from '~/components/DetailsBlock';
import Field from '~/components/Forms/Field';
import Form from '~/components/Forms/Form';
import FormUtils from '~/components/FormUtils';
import IBANInput, { ibanErrors } from '~/components/IBANInput';
import forceBEFormat from '~/components/IBANInput/utils/forceBEFormat';
import getIbanValidationOutput from '~/components/IBANInput/utils/getIbanValidationOutput';
import Input from '~/components/Input';
import OverviewListHeader from '~/components/OverviewListHeader';
import FormSaveBar from '~/components/SaveBar/FormSaveBar';
import { Body } from '~/components/Typography/index';
import {
  useGetWalletSettingsQuery,
  useUpdateWalletSettingsMutation,
  WalletSettings__Input,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import leftPad from '~/util/leftPad';
import Validation from '~/util/Validation';
import WalletBalance from './components/WalletBalance';

import TEST_ID from './index.testid';
import useUserRights from '~/hooks/useUserRights';
import useViewingModeProps from '~/hooks/useViewingModeProps';

const text = {
  pageTitle: Catalog.walletLabel,
  title: Catalog.walletLabel,
  accountSettingsLabel: 'Instellingen uitbetaling',
  description:
    'Pas hier je rekeninggegevens aan. Deze worden gebruikt voor uitbetaling van je wallet.',

  balanceLabel: 'Saldo',

  totalKickback: 'totale omzet',

  accountNameLabel: 'Naam van rekeninghouder',
  emailLabel: 'Facturatie email',
  ibanLabel: 'IBAN',

  invalidEmail: Catalog.invalidEmail,
  noEmail: Catalog.noEmail,
  invalidAccountName: 'Het veld kan niet leeg zijn',

  success: 'Wijzigingen opgeslagen',
  error: 'Er is iets misgegaan bij het opslaan van wijzigingen',
};

type FormData = {
  email: string | null;
  accountName: string | null;
  iban: string | null;
};

const Wallet = () => {
  const addToast = useAddToast();
  const viewingModeProps = useViewingModeProps();

  const { update: mayUpdate } = useUserRights({ category: 'Wallet' });
  const { id: accountId } = useCurrentAccount();
  const { data, loading } = useGetWalletSettingsQuery({
    variables: {
      accountId,
    },
  });
  const [updateWalletSetting, { loading: updateLoading }] =
    useUpdateWalletSettingsMutation();

  const validate = async ({ email, accountName, iban }: FormData) => {
    const errors: {
      email: string | undefined;
      accountName: string | undefined;
      iban: string | undefined;
    } = {
      email: undefined,
      accountName: undefined,
      iban: undefined,
    };

    if (Validation.String.isEmpty(email)) {
      errors.email = text.noEmail;
    }
    if (!Validation.Email.isValid(email)) {
      errors.email = text.invalidEmail;
    }
    if (!Validation.String.isNonEmptyString(accountName)) {
      errors.accountName = text.invalidAccountName;
    }

    const validationOutput = getIbanValidationOutput(iban);

    // When IBAN includes `*` the user hasn't touched it
    if (validationOutput.error !== null && !iban?.includes('*')) {
      errors.iban = ibanErrors[validationOutput.error];
    }

    if (iban && forceBEFormat(iban).length < 18) {
      errors.iban = ibanErrors.length;
    }

    if (!iban) {
      errors.iban = ibanErrors.empty;
    }

    return errors;
  };

  if (loading || !data) return <DatHuisLoading />;

  const disabled = !mayUpdate || loading || updateLoading;

  const onSubmit = async (
    formValues: Record<string, any>,
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>,
  ) => {
    const update: WalletSettings__Input = {};

    if (
      formValues.iban?.length > 0 &&
      formValues.iban.substr(formValues.iban.length - 4) !==
        data.getWalletSettings.ibanLast4
    ) {
      update.iban = forceBEFormat(formValues.iban);
    }

    if (formValues.accountName !== data.getWalletSettings.accountName) {
      update.accountName = formValues.accountName;
    }

    if (formValues.email !== data.getWalletSettings.email) {
      update.email = formValues.email;
    }

    if (isEmpty(update)) {
      return form.restart(formValues);
    }

    await updateWalletSetting({
      variables: {
        accountId,
        update,
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        addToast([formatToastMessage(text.error, 'danger')]);
        return;
      }

      if (data) {
        addToast([formatToastMessage(text.success, 'success')]);
        form.restart({
          ...omit(['__typename'], data.updateWalletSettings),
          iban: leftPad(18, data.updateWalletSettings.ibanLast4),
        });
      }
    });
  };

  return (
    <ContentContainerDefault maxContentWidth="1300px">
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <OverviewListHeader title={text.title} />
      <GridContainer>
        <DetailsBlock title={text.accountSettingsLabel}>
          <br />
          <Body>{text.description}</Body>
          <br />
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
              ...omit(['__typename'], data.getWalletSettings),
              iban: leftPad(18, data.getWalletSettings.ibanLast4),
            }}
          >
            {({ form, submitError, valid }) => (
              <form
                onSubmit={event => {
                  if (event) event.preventDefault();
                  return form.submit();
                }}
              >
                {submitError ? (
                  <div data-testid={TEST_ID.ERROR_MESSAGE}>{submitError}</div>
                ) : null}
                <Field name="accountName">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      label={text.accountNameLabel}
                      large
                      type="text"
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [error]
                          : undefined
                      }
                      disabled={disabled}
                      {...viewingModeProps}
                      {...input}
                    />
                  )}
                </Field>
                <br />
                <Field name="email">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      label={text.emailLabel}
                      large
                      type="email"
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [error]
                          : undefined
                      }
                      disabled={disabled}
                      {...viewingModeProps}
                      {...input}
                    />
                  )}
                </Field>
                <br />
                <Field name="iban">
                  {({ input, meta: { initial, error, active } }) => (
                    <IBANInput
                      label={text.ibanLabel}
                      large
                      type="text"
                      disabled={disabled}
                      {...input}
                      previousValue={initial}
                      value={input.value === initial ? null : input.value}
                      externalErrors={
                        FormUtils.showError(error, active) ? [error] : undefined
                      }
                      showValidation={active}
                      {...viewingModeProps}
                    />
                  )}
                </Field>
                <br />
                <FormSaveBar inBlockComponent disableSave={!valid} />
              </form>
            )}
          </Form>
        </DetailsBlock>
        <BalanceDetailBlock title={text.balanceLabel}>
          <WalletBalance label={text.totalKickback} />
        </BalanceDetailBlock>
      </GridContainer>
    </ContentContainerDefault>
  );
};

const GridContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: ${theme.space('xxl')};
  `,
);

const BalanceDetailBlock = styled(DetailsBlock)`
  max-width: unset;
`;

export default Wallet;
