import { FlowParameterBooleanFieldsFragment } from '~/graphql/types';
import { BooleanParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyBooleanPrimitiveParameterValue from './emptyBooleanPrimitiveParameterValue';
import asBooleanPointerParameterValue from './asBooleanPointerParameterValue';
import asBooleanPrimitiveParameterValue from './asBooleanPrimitiveParameterValue';

export default (
  booleanParameter: FlowParameterBooleanFieldsFragment | null | undefined,
): BooleanParameterValue => {
  if (booleanParameter == null) {
    return emptyBooleanPrimitiveParameterValue();
  }
  switch (booleanParameter.__typename) {
    case PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE:
      return asBooleanPrimitiveParameterValue(booleanParameter);
    case PARAMETER_VALUE_TYPE.BOOLEAN_POINTER:
      return asBooleanPointerParameterValue(booleanParameter);
    default:
      throw new Error(
        `Should not occur: Unknown __typename (${booleanParameter.__typename}) for BooleanParameter`,
      );
  }
};
