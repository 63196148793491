import React from 'react';
import styled, { css } from 'styled-components';
import {
  calculateSpaceInsideComponent,
  calculateFontSize,
} from '~/components/util/getSizeCalculation';
const INPUT_LABEL_PADDING_LEFT = '5px';
const INPUT_LABEL_PADDING_BOTTOM = '2px';

type InputLabelProps = {
  actAsPlaceholder?: boolean;
  error?: string | null;
  small?: boolean;
  large?: boolean;
  dataTestid?: string;
};
const InputLabel = styled.span<InputLabelProps>`
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  display: inline-block;
  transition-property: color, transform, font-size;
  transition-duration: 0.3s;

  ${({ error, theme, actAsPlaceholder }) => {
    if (error) {
      return css`
        color: ${theme.color('danger')};
      `;
    }

    if (actAsPlaceholder) {
      return css`
        color: ${theme.color('text', 'light')};
      `;
    }
    return null;
  }};

  ${({ theme, actAsPlaceholder, large, small }) => {
    // one smaller
    const fontSizeNoUnit = calculateFontSize(
      theme,
      !small && !large,
      false,
      small,
    );

    if (!actAsPlaceholder) {
      return css`
        font-weight: ${theme.fontWeight('semiBold')};
        font-size: ${fontSizeNoUnit}px;
        line-height: ${theme.lineHeight('base')};
        transform: translateX(0) translateY(-${INPUT_LABEL_PADDING_BOTTOM});
      `;
    }

    const spaceInsideComponent = calculateSpaceInsideComponent(
      theme,
      small,
      large,
    );

    let placeholderFontSize = theme.space('base');
    if (small) placeholderFontSize = theme.space('s');
    if (large) placeholderFontSize = theme.space('m');

    return css`
      line-height: ${theme.lineHeight('base')};
      font-size: ${placeholderFontSize}px;
      transform: translateX(
          calc(${spaceInsideComponent}px - ${INPUT_LABEL_PADDING_LEFT})
        )
        translateY(
          calc(
            ${theme.remToPxRaw(placeholderFontSize) *
              theme.lineHeight('base')}px + ${spaceInsideComponent}px
          )
        );
    `;
  }};
`;

/** If you ever change this component search for 'label > div:first-child' and make sure it still works there  */
const InputLabelContainer = styled.div<{}>`
  position: relative;
  white-space: nowrap;
  line-height: 0;
`;

const InputLabelPlaceholder = styled.span<InputLabelProps>`
  visibility: hidden;
  pointer-events: none;
  display: inline-block;
  padding-left: ${INPUT_LABEL_PADDING_LEFT};
  padding-bottom: ${INPUT_LABEL_PADDING_BOTTOM};

  ${({ theme, large, small }) => {
    // calculate one smaller than actual
    const fontSize = calculateFontSize(theme, !small && !large, false, small);

    return css`
      font-size: ${fontSize}px;
      line-height: ${theme.lineHeight('base')};
    `;
  }};
`;

const FloatingLabel: React.FC<InputLabelProps> = ({
  children,
  dataTestid,
  error,
  ...props
}) => (
  <InputLabelContainer data-testid={dataTestid} data-error={error != null}>
    <InputLabelPlaceholder error={error} {...props}>
      {children}
    </InputLabelPlaceholder>
    <InputLabel error={error} {...props}>
      {children}
    </InputLabel>
  </InputLabelContainer>
);

export default FloatingLabel;
