import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionZapierTrigger } from '~/graphql/types.client';
import removeTypenames from '../../../removeTypenames';

/**
 *
 * @param {string} ClientFlowActionZapierTrigger - ZapierTrigger client action
 * keywords:
 */

const serializeZapierTriggerAction = (
  action: ClientFlowActionZapierTrigger,
): FlowV2_Action__Input => {
  const {
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    zapierTriggerId,
    mappings,
  } = action;

  return {
    Zapier: {
      Trigger: removeTypenames({
        id,
        accountId,
        flowBlueprintId,
        parentIds,
        zapierTriggerId,
        mappings,
      }),
    },
  };
};
export default serializeZapierTriggerAction;
