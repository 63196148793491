import React, { useMemo } from 'react';
import styled from 'styled-components';
import { createEditor, Descendant } from 'slate';
import { Slate } from 'slate-react';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { DHEditor, Plugin } from './types';
import StaticToolbar from './components/StaticToolbar';
import withInlineVoidElements from './plugins/withInlineVoidElements';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import HoveringToolbar from './components/HoveringToolbar';
import withNormalizer from './plugins/withNormalizer';
import { EditableProps } from 'slate-react/dist/components/editable';
import { isEmpty } from 'ramda';
import withErrorBoundary from '~/ErrorBoundary';
import { AppErrorScreen } from '..';
import EditableContainer from './components/EditableContainer';

export type Props = {
  dataTestId?: string;

  /** Calls plugins starting from right to left so the left ones might override the values you set */
  plugins?: Array<Plugin>;

  /** Additional elements to support in the editor, adds the corresponding toolbar button */
  customElements?: Array<ELEMENTS>;

  /** Value of the editor */
  value: Array<Descendant>;

  /** Updates editor value onChange */
  onChange: (value: Array<Descendant>) => void;

  /** Makes the editor and toolbar disabled */
  readOnly?: boolean;

  /** Makes the editor single line by disabling Enter key press */
  singleLine?: boolean;

  /** Hides static toolbar. Difference between singleLine is hideToolbar does not block adding new lines */
  hideToolbar?: boolean;

  /** Props to overwrite the Editable component's props */
  editableProps?: EditableProps;

  /** Extra buttons, components you want to add to the editor that needs the editor state */
  children?: (editor?: DHEditor) => React.ReactElement;

  /**
   * The placeholder text
   */
  placeholder?: string;
};

const PluginsEditor: React.FC<Props> = ({
  dataTestId,
  value,
  onChange,
  plugins = [],
  customElements = [],
  readOnly = false,
  editableProps,
  singleLine = false,
  hideToolbar = false,
  placeholder,
  children,
}) => {
  const allPlugins = [
    withHistory,
    withReact,
    withInlineVoidElements,
    withNormalizer,
    ...plugins,
  ];

  const pluginsEditor = useMemo<DHEditor>(
    () => allPlugins.reduce((prev, plugin) => plugin(prev), createEditor()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Container data-testid={dataTestId}>
      <Slate
        editor={pluginsEditor}
        value={value}
        onChange={newValue => {
          if (isEmpty(newValue)) return;

          /** Limits rerenders by not triggering onChange when the editor is only clicked */
          const isAstChange = pluginsEditor.operations.some(
            op => 'set_selection' !== op.type,
          );

          if (isAstChange) onChange(newValue);
        }}
      >
        {!singleLine && !hideToolbar && (
          <StaticToolbar customElements={customElements} readOnly={readOnly} />
        )}

        <HoveringToolbar />

        <EditableContainer
          readOnly={readOnly}
          editableProps={editableProps}
          pluginsEditor={pluginsEditor}
          singleLine={singleLine}
          hideToolbar={hideToolbar}
          customElements={customElements}
          placeholder={placeholder}
        >
          {children}
        </EditableContainer>
      </Slate>
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
`;

export default withErrorBoundary(
  PluginsEditor,
  <AppErrorScreen setBackgroundColor={false} />,
);
