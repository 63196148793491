import { FlowParameterEmailFieldsFragment } from '~/graphql/types';
import { EmailPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyEmailPointerParameterValue from './emptyEmailPointerParameterValue';

export default (
  emailParameter: FlowParameterEmailFieldsFragment | null | undefined,
): EmailPointerParameterValue => {
  if (emailParameter == null) {
    return emptyEmailPointerParameterValue();
  }

  if (emailParameter.__typename !== PARAMETER_VALUE_TYPE.EMAIL_POINTER) {
    throw new Error(
      `Should not occur: >>asEmailPointerParameterValue with a non pointer type: ${emailParameter.__typename}`,
    );
  }

  return {
    type: PARAMETER_VALUE_TYPE.EMAIL_POINTER,
    variable: {
      name: emailParameter.variable,
      field: emailParameter.field
        ? {
            name: emailParameter.field,
          }
        : null,
    },
  };
};
