import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTable, useSortBy, useFlexLayout } from 'react-table';
import {
  Header,
  Body as TableBody,
  Row,
} from '~/components/DataTables/styling';
import { HeaderCell, RowCell } from '~/components/DataTables';
import type { AppStatus_Trustoo_Token } from '~/graphql/types';
import { ACTION_COLUMN_ACCESSOR } from '~/components/DataTables/util/TableHeaderCell/action';
import ActionColumnCell from '~/components/DataTables/components/ActionColumnCell';
import useConfirmModal from '~/components/Alerts/useConfirmModal';
import TEST_ID from './index.testid';
import useOffices from '~/hooks/useOffices';
import memoizedTokensListColumns from './columns';
import uuidv1 from 'uuid/v1';
import useViewportSize from '~/components/util/useViewportSize';
import { MAIN_CONTENT_WIDTH } from '~/components/NavigationFrame';

export type Row = {
  id: string;
  name: string;
  officeName: string;
  city: string;
  street: string;
  postalCode: string;
};

export type Props = {
  tokens: Array<AppStatus_Trustoo_Token>;
  onDelete: (tokenId: string) => void;
  onClick: (tokenId: string) => void;
  loading: boolean;
};

const defaultColumn = {
  minWidth: 20,
  width: 120,
  maxWidth: 200,
};

const defaultSortColumn = 'name';
const confirmDeleteModalText = {
  title: 'De token verwijderen',
  message: 'Weet u zeker dat u de token wilt verwijderen?',
  buttonConfirmTitle: 'Verwijderen',
  buttonCancelTitle: 'Annuleren',
};

const TokensList: React.FC<Props> = ({
  onDelete,
  onClick,
  tokens,
  loading,
}) => {
  const offices = useOffices({});
  const getOfficeName = useCallback(
    (officeId: string): string =>
      offices.find(office => office.id === officeId)?.name ?? '',
    [offices],
  );

  const rowData: Array<Row> = useMemo(
    () =>
      tokens.map(token => ({
        id: token.token,
        name: token.TokenInfo?.businessName ?? '',
        officeName: getOfficeName(token.officeId),
        city: token.TokenInfo?.city ?? '',
        street: `${token.TokenInfo?.street}, ${token.TokenInfo?.streetNumber}`,
        postalCode: token.TokenInfo?.postalCode ?? '',
      })),
    [getOfficeName, tokens],
  );

  const [key] = useState(uuidv1());
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    toggleHideAllColumns,
  } = useTable(
    {
      columns: memoizedTokensListColumns(key),
      data: rowData,
      defaultColumn,
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetHiddenColumns: false,
      getRowId: ({ id }) => id,
      manualSortBy: true,
      disableSortRemove: true,
      initialState: {
        sortBy: [{ id: defaultSortColumn, desc: true }],
      },
    },
    useFlexLayout,
    useSortBy,
  );

  const [viewportSize] = useViewportSize();
  useEffect(() => {
    if (viewportSize.width > MAIN_CONTENT_WIDTH) {
      toggleHideAllColumns(false);
    } else {
      setHiddenColumns(['street', 'postalCode']);
    }
  }, [viewportSize.width, setHiddenColumns, toggleHideAllColumns]);

  const [showDeleteConfirmModal, setOnDeleteConfirmFunction] = useConfirmModal(
    confirmDeleteModalText,
    () => {},
    () => {},
    undefined,
    false,
    'destructive',
  );

  return (
    <Container {...getTableProps()} data-testid={TEST_ID.CONTAINER}>
      <Header.Large>
        {headerGroups.map(headerGroup => {
          const headerGroupProps = { ...headerGroup.getHeaderGroupProps() };
          return (
            <StyledHeaderTr key={headerGroupProps.key}>
              {headerGroup.headers.map((column, idx) => {
                const isLastColumn = idx === headerGroup.headers.length - 1;
                const headerCellKey = `header-${idx}`;

                return (
                  <HeaderCell
                    key={headerCellKey}
                    column={column}
                    isLastColumn={isLastColumn}
                  />
                );
              })}
            </StyledHeaderTr>
          );
        })}
      </Header.Large>
      <TableBody.Standard.Outer>
        <TableBody.Standard.Inner {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            const rowProps = { ...row.getRowProps() };
            const tokenId = row.id;

            return (
              <StyledRow
                {...rowProps}
                key={rowProps.key}
                data-objectid={tokenId}
                dataTestid={TEST_ID.LIST_ITEM}
              >
                {row.cells.map((cell, idx) => {
                  const isLastColumn = idx === row.cells.length - 1;

                  if (cell.column.id === ACTION_COLUMN_ACCESSOR) {
                    return (
                      <ActionColumnCell
                        key={'event-action-column-cell'}
                        loading={loading}
                        options={[
                          {
                            label: 'Aanpassen',
                            onClick: () => onClick(tokenId),
                            key: 'edit',
                          },
                          {
                            label: 'Verwijderen',
                            onClick: () => {
                              const deleteFn = () => onDelete(cell.row.id);
                              setOnDeleteConfirmFunction(deleteFn);
                              showDeleteConfirmModal();
                            },
                            key: 'delete',
                            type: 'DELETE',
                          },
                        ]}
                        cell={cell}
                      />
                    );
                  }

                  return (
                    <RowCell
                      key={`cell-${idx}`}
                      cell={cell}
                      isLastColumn={isLastColumn}
                    />
                  );
                })}
              </StyledRow>
            );
          })}
        </TableBody.Standard.Inner>
      </TableBody.Standard.Outer>
    </Container>
  );
};

const StyledRow = styled(Row.Standard)`
  cursor: default;
`;

const Container = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    width: 100%;
  `,
);

const StyledHeaderTr = styled.div<{}>`
  white-space: nowrap;
  display: flex;
`;

export default TokensList;
