import { uniqBy } from 'ramda';
import { atom, selectorFamily } from 'recoil';
import { FlowData___FlowInstanceFragment } from '~/graphql/types';
import flowActions, { actionById } from '..';
import getCommonParentsInAllPaths from '../../components/Builder/utils/getCommonParentsInAllPaths';
import getSubject, {
  SubjectMap,
} from '../../components/UpdateAction/components/Selector/utils/getSubject';
import getLabelledActions from '../../util/getLabelledActions';
import generateConditionInstanceKey from './utils/generateConditionInstanceKey';
import generateInstance from './utils/generateInstance';
import sortActionsOnParentIds from './utils/sortActionsOnParentIds';

const flowInstance = atom<Array<FlowData___FlowInstanceFragment>>({
  key: 'flowInstance',
  default: [],
});

export const instancesByActionId = selectorFamily<
  Array<FlowData___FlowInstanceFragment> | null,
  {
    /** The id of the action to get the relative instances */
    actionId: string;
    subjectMap: SubjectMap;
  }
>({
  key: 'instanceByActionId',
  get:
    ({ actionId, subjectMap }) =>
    ({ get }) => {
      const allActions = get(flowActions);
      const action = get(actionById(actionId));
      const instances: Array<FlowData___FlowInstanceFragment> = [];

      if (!action) return null;

      const labelledActions = getLabelledActions(allActions);
      const parentActions = getCommonParentsInAllPaths({
        subjectAction: action,
        actions: labelledActions,
      });

      const sortedParentActions = sortActionsOnParentIds({
        allActions,
        subjectAction: action,
        actionsToSort: parentActions,
      });

      for (const parentAction of sortedParentActions) {
        switch (parentAction.__typename) {
          case 'FlowV2_Action_Realworks_SendContact':
          case 'FlowV2_Action_Zapier_Trigger':
          case 'FlowV2_Action_Contact_Details':
          case 'FlowV2_Action_Contact_AddTag':
          case 'FlowV2_Action_Contact_DeleteTag':
          case 'FlowV2_Action_IfElse':
          case 'FlowV2_Action_Contact_Assign':
            break;
          case 'FlowV2_Action_Task_Create':
            const subject = getSubject(
              { type: 'FlowSubject_Task' },
              subjectMap,
            );

            if (!subject) break;
            const instance = generateInstance(subject, 'item', parentAction);

            instances.push(instance);
            break;
          case 'FlowV2_Action_SendEmail_Plain': {
            const subject = getSubject(
              { type: 'FlowSubject_Email' },
              subjectMap,
            );

            if (!subject) break;
            const instance = generateInstance(subject, 'item', parentAction);

            instances.push(instance);
            break;
          }

          case 'FlowV2_Action_Start':
          case 'FlowV2_Action_Wait':
            parentAction.triggers.forEach(trigger => {
              if (
                trigger.trigger?.__typename === 'Flow___SubjectFieldCondition'
              ) {
                const subject = getSubject(
                  {
                    type: trigger.trigger.type,
                    typeId: trigger.trigger.typeId,
                  },
                  subjectMap,
                );

                const creates = subject?.fields.find(({ key }) => {
                  if (
                    trigger.trigger?.__typename ===
                    'Flow___SubjectFieldCondition'
                  ) {
                    return trigger.trigger.field === key;
                  }

                  return false;
                })?.creates;

                if (creates != null) {
                  const createsSubject = getSubject(creates, subjectMap);

                  if (createsSubject) {
                    const instance = generateInstance(
                      createsSubject,
                      generateConditionInstanceKey(createsSubject),
                      parentAction,
                    );

                    instances.push(instance);
                  }
                }
              }
            });
            break;

          default:
            break;
        }
      }

      return uniqBy(instance => instance.key.join(','), instances);
    },
});

export default flowInstance;
