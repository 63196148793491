import React, { HTMLAttributes, useState } from 'react';
import { useRef } from 'react';
import styled, { css } from 'styled-components';

import copyTextToClipboard from '~/util/copyTextToClipboard';
import CodeBlock from '../CodeBlock';
import Icon from '../Icon';
import JustificationContainer from '../JustificationContainer';
import { ExternalLink } from '../Link';
import Tooltip from '../Tooltip';
import Typography, { Variant } from '../Typography/index';

export type Props = {
  dataTestId?: string;
  code: string;
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const CopyCode: React.FC<Props> = ({
  dataTestId,
  label = 'Code',
  code,
  tooltip,
  ...rest
}) => {
  const copyTarget = useRef<HTMLInputElement | null>(null);
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const onCopy = () => {
    void copyTextToClipboard(code)
      .then(() => {
        setCopied(true);
      })
      .catch(() => {
        setCopied(false);
      });

    const timer = setTimeout(() => {
      clearTimeout(timer);
      setCopied(false);
    }, 1200);
  };

  return (
    <Container data-testid={dataTestId} {...rest}>
      <JustificationContainer justification="space-between">
        <JustificationContainer justification="space-between">
          <Label variant={Variant.primary}>{label}</Label>
          {tooltip && (
            <>
              <StyledIcon
                name="question-mark"
                onMouseOver={() => !showTooltip && setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              />
              {showTooltip && <Tooltip>{tooltip}</Tooltip>}
            </>
          )}
        </JustificationContainer>
        {copied ? (
          <StatusUpdate>
            <Icon name="check" />
            &nbsp;
            <span>Gekopieerd</span>
          </StatusUpdate>
        ) : (
          <ExternalLink onClick={onCopy}>Code kopiëren</ExternalLink>
        )}
      </JustificationContainer>
      <CodeBlock>
        <span ref={copyTarget}>{code}</span>
      </CodeBlock>
    </Container>
  );
};

const Container = styled.div<{}>``;

const Label = styled(Typography)<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
  `,
);

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('s')};
    cursor: pointer;
    color: ${theme.color('info')};
    /* The alignment of the icon is a little out of whack, we will correct it here since it's the only place where it is giving us grieve. */
    margin-top: -4px;
  `,
);

const StatusUpdate = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('success')};
    display: flex;
    align-items: flex-start;
  `,
);

export default CopyCode;
