import { Flow_Action__Input } from '~/graphql/types';
import { IfElseAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import { asFlowConditionListInput } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList';
import cleanedFilename from '~/util/cleanedFilename';

export default (
  action: IfElseAction,
  treeNode: ActionTreeNode,
  inputActionDefaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, conditionList, yesPathChildId, noPathChildId } = action;
  const { accountId, flowBlueprintId } = inputActionDefaults;
  const { parentId } = treeNode;

  if (parentId == null)
    throw new Error(
      `${cleanedFilename(
        __filename,
      )} | Parentless IfElseAction found! ID: ${id}`,
    );

  const conditionInput = asFlowConditionListInput(conditionList);

  if (conditionInput == null)
    throw new Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur: Validation should have caught that there are no conditions!`,
    );

  return {
    IfElse: {
      id,
      accountId,
      flowBlueprintId,
      parentId,
      condition: conditionInput,
      trueChildId: yesPathChildId,
      falseChildId: noPathChildId,
    },
  };
};
