import { ParameterField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import {
  Flow_Parameter__String__Input,
  Flow_Parameter__TemplateString__Input,
} from '~/graphql/types';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { asFlowParameterStringInput } from './FlowParameterStringField';
import { asFlowParameterTemplateStringInput } from './FlowParameterTemplateStringField';

export default (
  parameter: ParameterField,
): Flow_Parameter__String__Input | Flow_Parameter__TemplateString__Input => {
  switch (parameter.type) {
    case FIELD_TYPE.STRING:
      return { ...asFlowParameterStringInput(parameter) };
    case FIELD_TYPE.TEMPLATE_STRING:
      return { ...asFlowParameterTemplateStringInput(parameter) };
    default:
      throw new Error(`NYI: Parameter type: ${parameter.type}`);
  }
};
