import React, { useContext } from 'react';
import { navigate } from 'gatsby';

import { ListItemForDeletion } from '~/components/Modals/components/ListItemCard';
import { useLocation } from '@reach/router';
import { DeleteUserFromAccountMutation } from '~/graphql';
import deleteUserFromAccountMutation from '~/graphql/mutation/DeleteUserFromAccount';
import AccountContext from '~/contexts/AccountContext';
import cleanedFilename from '~/util/cleanedFilename';
import DeleteEntityModal from './DeleteEntityModal';
import {
  DELETE_ENTITY_TYPE,
  DELETION_TYPE,
} from '~/components/Modals/util/constants';
import { Highlight } from '~/components/Typography';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import useCurrentUser from '~/hooks/useCurrentUser';
import useCurrentAccount from '~/hooks/useCurrentAccount';

const text = {
  modalHeader: (
    <>
      Gebruiker verwijderen uit je <Highlight>account</Highlight>
    </>
  ),
  modalDescription:
    'Wijs contacten en openstaande taken toe aan een andere gebruiker of vestiging',
};

type Props = {
  list: Array<ListItemForDeletion>;

  /** When the modal is closed */
  onClose: () => void;

  /** When the cancel button is pressed */
  onCancel: () => void;

  /** Id of user to delete */
  userId: string;
};
const DeleteUserFromAccountModal = ({
  list,
  onClose,
  onCancel,
  userId,
}: Props) => {
  const isMountedRef = useIsMountedRef();
  const location = useLocation();
  const { refetchSessionHydration } = useContext(AccountContext);
  const me = useCurrentUser();
  const account = useCurrentAccount();

  return (
    <DeleteUserFromAccountMutation
      name={cleanedFilename(__filename)}
      mutation={deleteUserFromAccountMutation}
    >
      {(deleteUserFromAccount, { error }) => (
        <DeleteEntityModal
          list={list}
          modalHeader={text.modalHeader}
          modalDescription={text.modalDescription}
          onClose={onClose}
          onCancel={onCancel}
          entityId={userId}
          entityType={DELETE_ENTITY_TYPE.USER}
          onSuccess={() => {
            if (!isMountedRef.current) return;

            // On profile page
            if (userId === me.id) {
              void navigate('/logout', {
                state: { isUserDeleted: true },
              });
              return;
            } else {
              onClose();

              // If we are not deleting from office users page
              if (!location.pathname.includes('/-/settings/offices/')) {
                void navigate('/-/settings/users');
              }

              return refetchSessionHydration();
            }
          }}
          onDelete={changeSet =>
            deleteUserFromAccount({
              variables: {
                accountId: account.id,
                userId: userId,
                changeSet,
              },
            }).then(result => {
              if (result == null || result.data == null) {
                return null;
              }

              return result.data.deleteUserFromAccount;
            })
          }
          mutationError={error}
          deletionType={DELETION_TYPE.DELETE_USER_FROM_ACCOUNT}
        />
      )}
    </DeleteUserFromAccountMutation>
  );
};

export default DeleteUserFromAccountModal;
