import { isNil } from 'ramda';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SelectGroup } from '~/components/Inputs';
import {
  AppBbWaardecheck__Input,
  AppVboWaardecheck__Input,
} from '~/graphql/types';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';

import { Device, Page } from '../Preview';
import PreviewContainer from '../PreviewContainer';

export type Props = {
  dataTestId?: string;
  page: Page;
  data: AppBbWaardecheck__Input | AppVboWaardecheck__Input;
};

const MainPreview: React.FC<Props> = ({ dataTestId, data, page, ...rest }) => {
  const options = [
    {
      value: 'desktop' as Device,
      label: 'Desktop',
    },
    {
      value: 'mobile' as Device,
      label: 'Mobile',
    },
  ];

  const [deviceIndex, setDeviceIndex] = useState(0);
  const device = options[deviceIndex].value;

  return (
    <Container>
      <Header>
        <AppEntryItemHeader>Preview</AppEntryItemHeader>
        <SelectGroup
          selectedIndex={deviceIndex}
          onChange={({ selectedOptionIdx }) => {
            if (isNil(selectedOptionIdx)) return;

            setDeviceIndex(selectedOptionIdx);
          }}
          options={options}
        />
      </Header>

      <PreviewContainer
        data-testid={dataTestId}
        data={data}
        previews={[{ device, page }]}
        {...rest}
      />
    </Container>
  );
};

const Container = styled.div<{}>(() => css``);
const Header = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.space('m')} 0;
  `,
);

export default MainPreview;
