import { FlowData___FlowInstanceFragment } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import { Flow___ConditionPartial } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor';
import { SubjectMap } from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import getInstanceForCondition from '~/scenes/Automation/v2/state/flowInstance/utils/getInstanceForCondition';
import isConditionalAction from '~/typeguards/isConditionalAction';

/**
 * Returns either the direct parent condition or all parent conditions as instances
 * Set parentCondition or allParentInstances (not both at the same time)
 *
 * keywords: parent condition as instance
 */
const getParentInstances = ({
  parentCondition,
  allParentInstances,
  action,
  subjectMap,
}: {
  parentCondition?: Flow___ConditionPartial | null;
  allParentInstances?: boolean;
  subjectMap: SubjectMap;
  action?: ClientFlowAction;
}): Array<FlowData___FlowInstanceFragment> => {
  if (!action) return [];

  if (allParentInstances && isConditionalAction(action)) {
    const parentConditions: Array<Flow___ConditionPartial | null> =
      action.triggers.map(t => t.trigger);

    return parentConditions.reduce((prev, parentCondition) => {
      if (!parentCondition) return prev;

      const instance = getInstanceForCondition(
        { actionId: action.id, condition: parentCondition },
        { subjectMap },
      );
      if (instance) prev.push(instance);
      return prev;
    }, [] as Array<FlowData___FlowInstanceFragment>);
  }

  if (parentCondition) {
    const instance = getInstanceForCondition(
      { actionId: action.id, condition: parentCondition },
      { subjectMap },
    );
    if (instance) return [instance];
  }

  return [];
};

export default getParentInstances;
