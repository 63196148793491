import React from 'react';

import { FormData } from './CompanyDetails';

import { Form, Field } from '~/components/Forms';
import { H1 } from '~/components/Typography';
import { FormUtils } from '~/components';
import { Input, InputGroup } from '~/components/Inputs';
import Button from '~/components/Button';
import { ActionWrapper, ErrorMsg, FormSection } from '../../Auth.style';
import Validation from '~/util/Validation';

type Props = {
  handleSubmit: (fields: FormData) => void;
  validate: (fields: FormData) => Object;
  submitError: string | false;
  loading: boolean;
  initialValues?: FormData;
};
const text = {
  title: 'Account inrichten',
  companyLabel: 'Kantoornaam',
  saveLabel: 'Opslaan',
};

const CompanyDetailsForm = ({
  handleSubmit,
  validate,
  submitError,
  loading,
  initialValues,
}: Props) => (
  <Form
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validate={validate}
  >
    {({ handleSubmit, submitting, pristine }) => {
      const formSubmitting = loading || submitting;
      const initialForm =
        pristine &&
        (!initialValues || Validation.String.isEmpty(initialValues.company));

      return (
        <FormSection onSubmit={handleSubmit} data-testid="step1-form">
          <H1>{text.title}</H1>

          {submitError ? <ErrorMsg>{submitError}</ErrorMsg> : null}

          <InputGroup>
            <Field name="company">
              {({ input, meta: { error, touched } }) => (
                <Input
                  large
                  label={text.companyLabel}
                  type="text"
                  error={FormUtils.showError(error, touched)}
                  disabled={formSubmitting}
                  {...input}
                />
              )}
            </Field>
          </InputGroup>

          <ActionWrapper>
            <Button
              size="large"
              appearance="secondary"
              type="submit"
              disabled={formSubmitting || initialForm}
              data-testid="save-company-details-button"
              label={text.saveLabel}
            />
          </ActionWrapper>
        </FormSection>
      );
    }}
  </Form>
);

export default CompanyDetailsForm;
