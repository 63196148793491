import { Props } from '..';
import React from 'react';

const TrustooEmblem: React.FC<Props> = ({ ...props }) => (
  <svg
    {...props}
    width="100%"
    height="100%"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.802 12.863C13.118 15.786 10.568 17.893 7.56898 18.017C6.92865 18.0432 6.2912 17.9175 5.70879 17.6501C5.12637 17.3827 4.61553 16.9812 4.21806 16.4785C3.82058 15.9757 3.54775 15.3861 3.42191 14.7577C3.29606 14.1293 3.32076 13.48 3.49398 12.863C3.77698 11.608 4.42698 10.465 5.36198 9.581L6.16998 10.346C5.5556 10.9303 5.12767 11.6832 4.93998 12.51C4.82512 12.9212 4.80905 13.3538 4.89308 13.7724C4.97711 14.191 5.15886 14.5838 5.42352 14.9189C5.68818 15.2539 6.02826 15.5217 6.41605 15.7003C6.80383 15.879 7.22834 15.9635 7.65498 15.947C8.6276 15.906 9.56008 15.5478 10.3101 14.9272C11.0602 14.3067 11.5866 13.4577 11.809 12.51C12.043 11.676 11.864 10.78 11.327 10.1C10.79 9.42 9.95998 9.04 9.09398 9.074C8.51598 9.081 7.94698 9.214 7.42598 9.466L7.14198 8.326C7.94498 7.926 8.82898 7.712 9.72598 7.702C11.026 7.652 12.271 8.224 13.076 9.244C13.881 10.264 14.151 11.609 13.801 12.862L13.802 12.863ZM17.785 13.553C16.639 18.426 12.387 21.939 7.38498 22.145C2.63098 22.145 -0.392018 18.297 0.601982 13.553C1.07577 11.4491 2.16913 9.53549 3.74098 8.059L4.54898 8.822C3.29398 10.002 2.42098 11.522 2.04198 13.212C1.24698 17.012 3.67998 20.079 7.47698 20.079C9.4218 19.998 11.2867 19.283 12.7873 18.0431C14.2879 16.8033 15.3417 15.1066 15.788 13.212C16.0187 12.3896 16.0515 11.5243 15.8836 10.6868C15.7157 9.84931 15.3518 9.06348 14.8219 8.39363C14.2919 7.72378 13.6109 7.18893 12.8345 6.83285C12.0581 6.47678 11.2084 6.3096 10.355 6.345C9.13796 6.35794 7.9403 6.6512 6.85498 7.202L6.57498 6.052C7.94295 5.3502 9.45558 4.97667 10.993 4.961C15.739 4.961 18.778 8.807 17.786 13.561L17.785 13.553Z"
      fill="#FE895E"
    />
    <path
      d="M8.45201 10.454C8.19398 10.4571 7.93911 10.5111 7.70201 10.613L5.05502 0H0.122009L8.09401 12.16L6.97001 11.097C6.67501 11.397 6.47001 11.757 6.37001 12.16C6.24801 12.578 6.33701 13.03 6.60701 13.36C6.87701 13.69 7.30701 13.89 7.73201 13.868C8.73201 13.826 9.58201 13.123 9.81201 12.148C9.86633 11.9422 9.8716 11.7266 9.82738 11.5184C9.78317 11.3102 9.69072 11.1153 9.55746 10.9493C9.42419 10.7834 9.25385 10.651 9.06011 10.5629C8.86638 10.4748 8.65467 10.4334 8.44201 10.442L8.45201 10.454Z"
      fill="#10185A"
    />
  </svg>
);

export default TrustooEmblem;
