import { FlowParameterEmailFieldsFragment } from '~/graphql/types';
import { EmailParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyEmailPrimitiveParameterValue from './emptyEmailPrimitiveParameterValue';
import asEmailPointerParameterValue from './asEmailPointerParameterValue';

export default (
  emailParameter: FlowParameterEmailFieldsFragment | null | undefined,
): EmailParameterValue => {
  if (emailParameter == null) {
    return emptyEmailPrimitiveParameterValue();
  }
  switch (emailParameter.__typename) {
    case PARAMETER_VALUE_TYPE.EMAIL_PRIMITIVE:
      return {
        type: PARAMETER_VALUE_TYPE.EMAIL_PRIMITIVE,
        value: emailParameter.emailId ? emailParameter.emailId : null,
      };
    case PARAMETER_VALUE_TYPE.EMAIL_POINTER:
      return asEmailPointerParameterValue(emailParameter);
    default:
      throw new Error(
        `Should not occur: Unknown __typename (${emailParameter.__typename}) for EmailParameter`,
      );
  }
};
