import {
  CreateTaskAction,
  TaskInput,
  InexactCreateTaskActionFieldsFragment,
} from './types.flow';
import { BaseActionProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { asOfficeParameterValue } from '../Base/FlowParameter/ParameterValue/Office';
import { asUserParameterValue } from '../Base/FlowParameter/ParameterValue/User';
import { asTemplateStringParameterValue } from '../Base/FlowParameter/ParameterValue';

export default (
  item: InexactCreateTaskActionFieldsFragment,
  baseProps: BaseActionProps,
): CreateTaskAction => {
  const { id } = item;

  return {
    ...baseProps,
    type: FLOW_ACTION_TYPE.CREATE_TASK,
    id,
    taskInput: asTaskInput(item),
  };
};

export const asTaskInput = (
  fragment: InexactCreateTaskActionFieldsFragment,
): TaskInput => {
  const { office, user, title, description, type, assignToContactOwner } =
    fragment;

  return {
    taskType: type,
    assignToContactOwner:
      assignToContactOwner == null ? false : assignToContactOwner,
    description: asTemplateStringParameterValue(description),
    title: asTemplateStringParameterValue(title),
    office: asOfficeParameterValue(office),
    user: asUserParameterValue(user),
  };
};
