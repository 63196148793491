import { navigate, RouteComponentProps } from '@reach/router';
import { assoc, map } from 'ramda';
import React, { useState } from 'react';
import { useInsertZapierEventMutation, ZapierEvent } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import Details from '../../../Details';
import uuidv1 from 'uuid/v1';
import { ZapierFields } from '../../../Details/components/FieldsContainer';
import { breadcrumbs, text } from '../text';
import { cleanedFields } from '../../../utils/cleanedFields';
import { format } from '~/util/date';

export type Props = {
  dataTestId?: string;
} & RouteComponentProps;

const NewEvent: React.FC<Props> = ({ dataTestId, ...rest }) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const [insertEvent, { loading }] = useInsertZapierEventMutation();

  const defaultEvent = {
    accountId,
    name: text.initialHeaderName,
    fields: [],
  };

  const [event, setEvent] =
    useState<Omit<ZapierEvent, '__typename' | 'id' | 'createdDate'>>(
      defaultEvent,
    );

  const onSave = async (name: string, fields: ZapierFields) => {
    setEvent({ ...event, fields, name });

    return insertEvent({
      variables: {
        name,
        accountId: event.accountId,
        fields: cleanedFields(fields),
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        return addToast([
          formatToastMessage(
            `Er is iets fout gegaan bij het toevoegen van "${event.name}" inkomende koppeling, probeer het later opnieuw.`,
            'danger',
          ),
        ]);
      }

      addToast([
        formatToastMessage(
          'Inkomende koppeling succesvol toegevoegd.',
          'success',
        ),
      ]);

      if (data) {
        const eventId = data.insertZapierEvent.id;
        return navigate(`/-/apps/zapier/events/${eventId}`);
      }
    });
  };

  const [fieldsWithIds] = useState<ZapierFields>(
    map(field => assoc('id', uuidv1(), field), event.fields),
  );

  return (
    <Details
      {...rest}
      dataTestId={dataTestId}
      goBackLink={text.goBackLink}
      headerName={`${text.initialHeaderName} ${format(
        new Date(),
        'd MMM HH:mm',
      )}`}
      onSave={(name, fields) => onSave(name, fields)}
      description={text.description}
      initialFields={fieldsWithIds}
      breadcrumbs={[...breadcrumbs, { label: event.name }]}
      loading={loading}
    >
      {text.children}
    </Details>
  );
};

export default NewEvent;
