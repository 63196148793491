import React from 'react';
import styled, { css } from 'styled-components';

import { BaseColor } from '~/theme/System/tokens';
import Icon, { IconType } from '../Icon';
import JustificationContainer from '../JustificationContainer';
import { Variant, Heading4 } from '../Typography/index';

export type Props = {
  dataTestId?: string;
  icon?: IconType;
  iconBackground?: BaseColor;
  headerText: string;
  iconColor?: string;
};

const InfoBlock: React.FC<Props> = ({
  dataTestId,
  icon = 'check',
  headerText,
  iconBackground = 'success',
  children,
  iconColor = 'white',
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <JustificationContainer
      justification="start"
      align="center"
      margin={[null, null, 'base', null]}
    >
      <IconContainer
        iconBackground={iconBackground}
        align="center"
        justification="center"
        margin={[null, 'xs', null, null]}
      >
        <Icon name={icon} color={iconColor} strokeWidth={3} />
      </IconContainer>
      <Heading4
        color={{ group: 'primary' }}
        padding={['xxxs', null, null, null]}
        variant={Variant.primary}
        withoutMargin
      >
        {headerText}
      </Heading4>
    </JustificationContainer>
    {children}
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('primary', 'translucent')};
    padding: ${theme.space('l')};
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

const IconContainer = styled(JustificationContainer)<{
  iconBackground: BaseColor;
}>(
  ({ theme, iconBackground }) => css`
    width: 2em;
    height: 2em;

    border-radius: ${theme.getTokens().border.radius.full};
    background-color: ${theme.color(iconBackground)};
  `,
);

export default InfoBlock;
