import React from 'react';

import ValueReportComponentCard from './components/ValueReportComponentCard';
import CombinedTextWithColor from './components/CombinedTextWithColor';
import ChangeColorBlock from './components/ChangeColor';
import { FinalisedReportTabProps } from './ValueReport.type';
import { RouteComponentProps } from '@reach/router';
import Icon from '~/components/Icon';

type Props = {
  /**  Id for tests */
  datatestId: string;

  /**  Tab text for current tab content */
  label: string;

  /** data contains: {
    contactButton: {
      mainText: { text, color },
      backgroundColor
    },
    contactButtonClickedText: { text, color },
    formSubtext: { text, color },
  }
   */
  data: FinalisedReportTabProps;

  /** Callback function which call data updating in container state */
  onChange: (objectName: string) => (value: $Object) => void;
} & RouteComponentProps;

const text = {
  title: 'Rapport',
  buttonMainText: 'Hoofdtekst contactknop',
  buttonMainTextColor: 'Hoofdtekst kleur contactknop',
  buttonSubText: 'Subtekst contactknop',
  buttonSubTextColor: 'Subtekst kleur contactknop',
  buttonBackgroundColor: 'Achtergrondkleur contactknop',
  formSubText: 'Formulier subtekst',
  formSubTextColor: 'Formulier subtekstkleur',
  contactButtonClickedTextColor: 'Contact button aangeklikt kleur',
  contactButtonClickedText: 'Contact button aangeklikt tekst',
};
type State = {};
class FinalisedReportTab extends React.Component<Props, State> {
  onColorChange = (backgroundColor: string) => {
    this.props.onChange('contactButton')({
      ...this.props.data.contactButton,
      backgroundColor,
    });
  };

  render() {
    const { data, onChange } = this.props;
    const { contactButton, formSubtext, contactButtonClickedText } = data;
    const { mainText, subText, backgroundColor } = contactButton;

    return (
      <ValueReportComponentCard
        datatestId={this.props.datatestId}
        title={text.title}
        cardIcon={<Icon name="clipboard" />}
      >
        <CombinedTextWithColor
          textTitle={text.buttonMainText}
          colorTitle={text.buttonMainTextColor}
          objectName="mainText"
          data={mainText}
          onChange={value =>
            onChange('contactButton')({
              mainText: value,
            })
          }
        />
        <CombinedTextWithColor
          textTitle={text.buttonSubText}
          colorTitle={text.buttonSubTextColor}
          objectName="subText"
          data={subText}
          onChange={value => {
            onChange('contactButton')({
              subText: value,
            });
          }}
          largeTextArea
        >
          <ChangeColorBlock
            onChange={this.onColorChange}
            color={backgroundColor}
            title={text.buttonBackgroundColor}
            testName="contactButton"
            name="backgroundColor"
            data-testid={'changeColorBlock'}
          />
        </CombinedTextWithColor>
        <CombinedTextWithColor
          textTitle={text.contactButtonClickedText}
          colorTitle={text.contactButtonClickedTextColor}
          objectName="contactButtonClickedText"
          data={contactButtonClickedText}
          onChange={onChange('contactButtonClickedText')}
        />
        <CombinedTextWithColor
          textTitle={text.formSubText}
          colorTitle={text.formSubTextColor}
          objectName="formSubtext"
          data={formSubtext}
          onChange={onChange('formSubtext')}
        />
      </ValueReportComponentCard>
    );
  }
}

export default FinalisedReportTab;
