import React from 'react';
import styled, { css } from 'styled-components';

import { useSpring, animated } from 'react-spring';
import { ANIMATION_CONFIG } from '~/scenes/Settings/constants';

export type Props = {
  dataTestId?: string;
  isOpen: boolean;
};

const MenuIcon: React.FC<Props> = ({ dataTestId, isOpen, ...rest }) => {
  const first = useSpring({
    transform: isOpen
      ? 'translate(-25%, 15%) rotate(45deg)'
      : 'translate(0%, 0%) rotate(0deg)',
    config: ANIMATION_CONFIG.config,
  });
  const second = useSpring({
    transform: isOpen ? 'translate(100%, 0%)' : 'translate(0%, 0%)',
    opacity: isOpen ? '0' : '1',
    config: ANIMATION_CONFIG.config,
  });
  const third = useSpring({
    transform: isOpen
      ? 'translate(-25%, -20%) rotate(-45deg)'
      : 'translate(0%, 0%) rotate(0deg)',
    config: ANIMATION_CONFIG.config,
  });

  return (
    <SVG
      data-testid={dataTestId}
      {...rest}
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M6 6H18"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={first}
      />
      <Path
        d="M6 12H18"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={second}
      />
      <Path
        d="M6 18H18"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={third}
      />
    </SVG>
  );
};

const SVG = styled.svg<{}>(
  ({ theme }) => css`
    color: ${theme.color('text')};
  `,
);

const Path = styled(animated.path)<{}>`
  transform-origin: 50% 50%;
`;

export default MenuIcon;
