import { FlowCondition } from './types.flow';
import { Flow_Condition__Input } from '~/graphql/types';

import { asFlowConditionContactDetailsInput } from './ContactDetails';
import { asFlowConditionValuationReportDetailsInput } from './AppValuationReportDetails';
import { asFlowConditionValuationRequestDetailsInput } from './AppValuationRequestDetails';
import { asFlowConditionAppFundaViewingRequestDetailsInput } from './AppFundaViewingRequestDetails';
import { asFlowConditionAppFundaContactRequestDetailsInput } from './AppFundaContactRequestDetails';
import { asFlowConditionAppFundaBrochureRequestDetailsInput } from './AppFundaBrochureRequestDetails';
import { asFlowConditionAppBBWaardecheckReportDetailsInput } from './AppBBWaardecheckReportDetails';
import { asFlowConditionAppBBWaardecheckAppraisalRequestDetailsInput } from './AppBBWaardecheckAppraisalRequestDetails';
import { asFlowConditionAppVBOWaardecheckReportDetailsInput } from './AppVBOWaardecheckReportDetails';
import { asFlowConditionAppVBOWaardecheckAppraisalRequestDetailsInput } from './AppVBOWaardecheckAppraisalRequestDetails';

import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';
import { asFlowConditionAppZapierDetailsInput } from './AppZapierDetails';
import { asFlowConditionAppHypotheekbondDetailsInput } from './AppHypotheekbondDetails';

const asFlowConditionGroupInput = (
  condition: FlowCondition,
): Array<Flow_Condition__Input> => {
  switch (condition.type) {
    case 'Flow_Condition_Contact_Details':
      return asFlowConditionContactDetailsInput(condition);
    case 'Flow_Condition_Event_Contact_App_ValuationReport_Details':
      return asFlowConditionValuationReportDetailsInput(condition);
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_Details':
      return asFlowConditionValuationRequestDetailsInput(condition);
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details':
      return asFlowConditionAppFundaViewingRequestDetailsInput(condition);
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details':
      return asFlowConditionAppFundaContactRequestDetailsInput(condition);
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details':
      return asFlowConditionAppFundaBrochureRequestDetailsInput(condition);
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details':
      return asFlowConditionAppBBWaardecheckReportDetailsInput(condition);
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details':
      return asFlowConditionAppBBWaardecheckAppraisalRequestDetailsInput(
        condition,
      );
    case 'Flow_Condition_Event_Contact_App_Zapier_Details':
      return asFlowConditionAppZapierDetailsInput(condition);
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details':
      return asFlowConditionAppVBOWaardecheckReportDetailsInput(condition);
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details':
      return asFlowConditionAppVBOWaardecheckAppraisalRequestDetailsInput(
        condition,
      );
    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details':
      return asFlowConditionAppHypotheekbondDetailsInput(condition);

    case 'Flow_Condition_Contact_Tag':
    case 'Flow_Condition_Event_Email_LinkClicked':
    case 'Flow_Condition_Event_Email_Opened':
    case 'Flow_Condition_Time_Elapsed':
    case 'Flow_Condition_Event_Contact_App_ValuationReport_New':
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_New':
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New':
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New':
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New':
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New':
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New':
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New':
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New':
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New':
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New':
    case 'Flow_Condition_Event_Contact_App_Zapier_New':
    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_New':
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | convert condition as group for type ${
          condition.type
        } to input not implemented`,
      );
    default:
      return assertNever(condition);
  }
};

export default asFlowConditionGroupInput;
