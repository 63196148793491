import React from 'react';
import ImageUploadComponent from '~/components/ImageUploadComponent';
import useImageUpload from '~/hooks/useImageUpload';
import styled, { css } from 'styled-components';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';

export type Props = {
  dataTestid?: string;
  initialUrl?: string;
  title?: string;
  description?: string;
  s3Key?: string;
  filename: string;
  onChange: (arg?: { s3key: string; url: string }) => void;
};

const ImageInput: React.FC<Props> = ({
  dataTestid,
  initialUrl,
  title,
  s3Key,
  filename,
  onChange,
}) => {
  const fallbackImage = useImageUpload({
    initialUrl,
    s3Key,
    onUpload: ({ s3Key, url }) => {
      if (onChange) onChange({ s3key: s3Key, url });
    },
    onClear: () => onChange && onChange(),
  });

  return (
    <Container>
      {title && <AppEntryItemHeader>{title}</AppEntryItemHeader>}
      <ImageUploadComponent
        onDelete={() => fallbackImage.clear()}
        uploading={fallbackImage.uploading}
        imageUrl={fallbackImage.s3Key != null ? fallbackImage.url : null}
        onUpload={file =>
          void fallbackImage.upload({ file: file[0], filename })
        }
        dataTestid={dataTestid}
      />
    </Container>
  );
};

const Container = styled.div<{}>(
  () => css`
    width: 100%;
  `,
);

export default ImageInput;
