import {
  title as enableFlowsTitle,
  id as enableFlowsId,
} from '../../EnableFlowsForAppStatus';
import {
  title as genericWidgetConfigurationTitle,
  id as genericWidgetConfigurationId,
} from '../../components/GenericWidgetConfiguration';
import {
  title as overviewTitle,
  id as overviewId,
} from '../../AppStatusOverview';
import { WizardStep } from '~/components/Wizard/context/WizardContext';
import { StepId } from '../..';
import { AppStatus__typename } from '~/graphql/types.client';

/**
 * Returns EnableFlowsForAppStatus and AppStatusOverview steps for Wizard
 * @param {AppStatus__typename} typename
 * keywords: wizard, steps, AppStatusOverview, EnableFlowsForAppStatus
 */

type Args = {
  typename: AppStatus__typename;
  withWidget?: boolean;
};
const getCommonWizardSteps = ({
  typename,
  withWidget,
}: Args): Array<WizardStep> => {
  // These are the last common steps that all app wizards have
  const steps = [
    {
      title: enableFlowsTitle,
      id: enableFlowsId as StepId,
      metadata: { typename },
    },
    {
      title: overviewTitle,
      id: overviewId as StepId,
      metadata: { typename },
    },
  ];

  if (withWidget) {
    return [
      {
        title: genericWidgetConfigurationTitle,
        id: genericWidgetConfigurationId as StepId,
        metadata: { typename },
      },
      ...steps,
    ];
  }

  return steps;
};
export default getCommonWizardSteps;
