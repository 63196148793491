import { TransitionFrom } from '@react-spring/core';

// 15 minutes in milliseconds
export const ALIAS_VERIFICATION_TIMEOUT = 15 * 60 * 1000;

export const ANIMATION_CONFIG = {
  from: {
    opacity: 0,
    transform: 'translateX(-40px)',
  } as TransitionFrom<string>,
  to: {
    opacity: 1,
    transform: 'translateX(0px)',
  } as TransitionFrom<string>,
  config: {
    mass: 1,
    tension: 110,
    friction: 14,
  },
};
