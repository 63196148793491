import type { StringFilter } from '~/components/Filters/Primitive/String/types.flow';
import { Operator } from './types.flow';

export const getSelectedOperation = (filter: StringFilter): Operator => {
  const isEq = 'eq' in filter && filter.eq !== null;
  const isSw = 'sw' in filter && filter.sw !== null;

  if (isEq) return Operator.EQUALS;
  if (isSw) return Operator.STARTS_WITH;

  return Operator.EQUALS;
};

export const getStringValue = (
  selectedOperation: Operator,
  stringPrimitiveFilter: StringFilter,
): string => stringPrimitiveFilter[selectedOperation] ?? '';

export const isValidStringPrimitiveOperator = (value: any): value is Operator =>
  Object.values(Operator).includes(value);
