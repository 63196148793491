/**
 *
 * Provides the validation methods for password validation.
 */

import { isString } from 'lodash';

const COGNITO_SPECIAL_CHARACTERS =
  /\^|\$|\*|\.|\[|\]|{|}|\(|\)|\?|-|"|!|@|#|%|&|\/|\\|,|>|<|'|:|;|\||_|~|`/;

export const containsUpperCaseLetter = (password: string) => {
  if (password == null) return false;

  return password.toLowerCase() !== password;
};

export const containsLowerCaseLetter = (password: string) => {
  if (password == null) return false;

  return password.toUpperCase() !== password;
};

export const containsSpecialCharacter = (password: string) => {
  if (password == null) return false;

  return COGNITO_SPECIAL_CHARACTERS.test(password);
};

export const containsNumber = (password: string) => {
  if (password == null) return false;

  return /\d/.test(password);
};

export const isValid = (password: string) => {
  if (password == null) return false;

  if (!isString(password)) return false;

  if (!containsNumber(password)) return false;
  if (!containsSpecialCharacter(password)) return false;
  if (!containsLowerCaseLetter(password)) return false;
  if (!containsUpperCaseLetter(password)) return false;

  return true;
};

export default {
  containsLowerCaseLetter,
  containsUpperCaseLetter,
  containsSpecialCharacter,
  containsNumber,
  isValid,
};
