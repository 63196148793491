enum ELEMENTS {
  BLOCKQUOTE = 'block-quote',
  DH_IMAGE = 'dh-image',
  DIV = 'div',
  PARAGRAPH = 'paragraph',
  IMAGE = 'image',
  LI = 'list-item',
  LINK = 'link',
  OL = 'numbered-list',
  SPAN = 'span',
  UL = 'bulleted-list',
  VARIABLE = 'dh-variable',

  /**
   * Any element that is not included in allElements object will be deserialized
   * as generic-html-element which means it will have its initial attributes and
   * it will be saved as it came. It won't be changed in any stage.
   */
  GENERIC_HTML_ELEMENT = 'generic-html-element',
}

export default ELEMENTS;
