import gql from 'graphql-tag';

export default gql`
  fragment SessionHydrationDeletedAccountRelationFields on DeletedUserToAccountRelationship {
    __typename
    accountId
    userId
    name
    role
  }
`;
