import React, { ReactElement } from 'react';

import { AssignContactAction } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

import { needsUserActionDescription } from '~/scenes/Automation/Flows/Actions/util';
import { PARAMETER_POINTER_VALUE_TYPE } from '../Base/FlowParameter/ParameterValue/constants';
import cleanedFilename from '~/util/cleanedFilename';

const describeAssignContactAction = (
  action: AssignContactAction,
  describeOptions: DescribeOptions,
): ReactElement => {
  const { actionInput } = action;
  const { office, user, overwriteAssignee } = actionInput;

  let overwriteString;
  if (overwriteAssignee) {
    overwriteString =
      ' en overschrijf de huidige eigenaar als deze al is toegewezen';
  } else {
    overwriteString = ', behalve als deze al is toegewezen';
  }

  if (office.type === PARAMETER_POINTER_VALUE_TYPE.OFFICE_POINTER) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | does not support office (${JSON.stringify(
        office,
        null,
        2,
      )})`,
    );
  }
  if (user.type === PARAMETER_POINTER_VALUE_TYPE.USER_POINTER) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | does not support office (${JSON.stringify(
        user,
        null,
        2,
      )})`,
    );
  }

  if (office.value && user.value) {
    return (
      <span>
        Wijs het contact toe aan gebruiker{' '}
        {describeParameterValue(user, describeOptions)} in vestiging{' '}
        {describeParameterValue(office, describeOptions)}
        {overwriteString}
      </span>
    );
  } else if (office.value && !user.value) {
    return (
      <span>
        Wijs het contact toe aan vestiging{' '}
        {describeParameterValue(office, describeOptions)}
        {overwriteString}
      </span>
    );
  } else {
    return <span>{needsUserActionDescription('Vul een vestiging in')}</span>;
  }
};

export default describeAssignContactAction;
