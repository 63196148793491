import {
  SendEmailPlainAction,
  InexactSendEmailPlainActionFieldsFragment,
} from './types.flow';
import { BaseActionProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { asTemplateStringParameterValue } from '../Base/FlowParameter/ParameterValue';
import { getDeduplicatedLinksFrom } from '~/components/HTMLEditor/util';
import { asFlowEmailAttachmentValue } from '../Base/FlowEmailAttachment';
import asEmailVariable from './asEmailVariable';

const asSendEmailPlainActionProps = (
  item: InexactSendEmailPlainActionFieldsFragment,
  baseProps: BaseActionProps,
): SendEmailPlainAction => {
  const { id, body, subject, from, attachments, isTransactional, signature } =
    item;

  return {
    ...baseProps,
    type: FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN,
    id,
    body: asTemplateStringParameterValue(body),
    subject: asTemplateStringParameterValue(subject),
    from: from ?? null,
    signature: signature ?? null,
    attachments: attachments
      ? attachments.map(attachment => asFlowEmailAttachmentValue(attachment))
      : [],
    links: getDeduplicatedLinksFrom(body.template || '', asEmailVariable(id)),
    isTransactional: isTransactional == null ? true : isTransactional,
  };
};

export default asSendEmailPlainActionProps;
