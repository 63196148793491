import { FlowParameterBooleanFieldsFragment } from '~/graphql/types';
import { BooleanPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyBooleanPointerParameterValue from './emptyBooleanPointerParameterValue';

export default (
  booleanParameter: FlowParameterBooleanFieldsFragment | null | undefined,
): BooleanPointerParameterValue => {
  if (booleanParameter == null) {
    return emptyBooleanPointerParameterValue();
  }

  if (booleanParameter.__typename !== PARAMETER_VALUE_TYPE.BOOLEAN_POINTER) {
    throw new Error(
      `Should not occur: >>asBooleanPointerParameterValue with a non pointer type: ${booleanParameter.__typename}`,
    );
  }

  return {
    type: PARAMETER_VALUE_TYPE.BOOLEAN_POINTER,
    variable: {
      name: booleanParameter.variable,
      field: booleanParameter.field
        ? {
            name: booleanParameter.field,
          }
        : null,
    },
  };
};
