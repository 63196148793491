import { equals } from 'ramda';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  useReactFlow,
  XYPosition,
  Position,
  useNodes,
  getSmoothStepPath,
} from 'reactflow';
import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import interactions from '~/scenes/Automation/v2/state/interactions';
import { REACT_FLOW_PANE } from '../../constants/reactFlowLayers';
import { EDGE_BORDER_RADIUS } from '../edgeTypes/components/BaseEdge';

const INITIAL_POSITION: XYPosition = {
  x: 0,
  y: 0,
};

type Props = {};

const ConnectingEdge: React.FC<Props> = ({}) => {
  const [currentInteraction] = useRecoilState(interactions);
  const nodes = useNodes();

  const { project } = useReactFlow();
  1;
  const [projectedMousePos, setProjectedMousePos] =
    useState<XYPosition>(INITIAL_POSITION);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      setProjectedMousePos(project({ x: event.offsetX, y: event.offsetY }));
    };
    const reactFlowRendererPane = document.querySelector(REACT_FLOW_PANE);

    if (reactFlowRendererPane) {
      reactFlowRendererPane.addEventListener('mousemove', handler);

      return () => {
        reactFlowRendererPane.removeEventListener('mousemove', handler);
      };
    }
    return () => {};
  }, [project]);

  const renderLayer = document.querySelector('.react-flow__edges > g');
  if (!renderLayer || currentInteraction?.type !== 'connectAction') return null;

  const currentNode = nodes.find(
    ({ id }) => id === currentInteraction.action.id,
  );

  // We could not find the the edge layer to portal into
  // or the mouse position is equal to the initial position (0, 0)
  if (!currentNode || equals(projectedMousePos, INITIAL_POSITION)) return null;

  return createPortal(
    <g id="thingy">
      <Path
        className="react-flow__edge-path"
        d={
          getSmoothStepPath({
            sourceX: currentNode.position.x + (currentNode.width ?? 0) / 2,
            sourceY: currentNode.position.y + (currentNode.height ?? 0) / 2,
            sourcePosition: Position.Bottom,
            targetX: projectedMousePos.x,
            targetY: projectedMousePos.y,
            targetPosition: Position.Right,
            borderRadius: EDGE_BORDER_RADIUS,
          })[0]
        }
        markerEnd="url(#arrow)"
      />
    </g>,
    renderLayer,
  );
};

const Path = styled.path(
  ({ theme }) => css`
    stroke: ${theme.color('primary', 'dark')};
    stroke-width: 3;
    fill: none;
  `,
);

export default ConnectingEdge;
