import { FlowConditionAppBBWaardecheckReportDetails } from './types.flow';

import { emptyBBWaardecheckReportEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionAppBBWaardecheckReportDetails = (): Omit<
  FlowConditionAppBBWaardecheckReportDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_BBWAARDECHECK_REPORT_DETAILS
    .type,
  fields: [],
  event: emptyBBWaardecheckReportEventParameterValue(),
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
});

export default emptyFlowConditionAppBBWaardecheckReportDetails;
