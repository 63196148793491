import React, { useContext } from 'react';
import { ListItemForDeletion } from '~/components/Modals/components/ListItemCard';
import { DeleteOfficeMutation } from '~/graphql';
import deleteOfficeMutation from '~/graphql/mutation/DeleteOffice';
import AccountContext from '~/contexts/AccountContext';
import cleanedFilename from '~/util/cleanedFilename';
import {
  DELETE_ENTITY_TYPE,
  DELETION_TYPE,
} from '~/components/Modals/util/constants';
import DeleteEntityModal from './DeleteEntityModal';
import { Highlight } from '~/components/Typography';
import { navigate } from 'gatsby';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import useCurrentAccount from '~/hooks/useCurrentAccount';

const text = {
  modalHeader: (
    <>
      Vestiging verwijderen uit je <Highlight>account</Highlight>{' '}
    </>
  ),
  modalDescription:
    'Wijs contacten en openstaande taken toe aan een andere gebruiker of vestiging',
};

type Props = {
  list: Array<ListItemForDeletion>;

  /** When the modal is closed */
  onClose: () => void;

  /** When the cancel button is pressed */
  onCancel: () => void;

  /** The id of the office that we want to delete */
  officeId: string;
};
const DeleteOfficeModal = ({ list, onClose, onCancel, officeId }: Props) => {
  const isMountedRef = useIsMountedRef();
  const { refetchSessionHydration } = useContext(AccountContext);
  const account = useCurrentAccount();

  return (
    <DeleteOfficeMutation
      name={cleanedFilename(__filename)}
      mutation={deleteOfficeMutation}
    >
      {(deleteOffice, { error }) => (
        <DeleteEntityModal
          list={list}
          onClose={onClose}
          onCancel={onCancel}
          modalHeader={text.modalHeader}
          modalDescription={text.modalDescription}
          entityId={officeId}
          entityType={DELETE_ENTITY_TYPE.OFFICE}
          onSuccess={() => {
            if (!isMountedRef.current) return;

            onClose();
            void navigate('/-/settings/offices');
            void refetchSessionHydration();
          }}
          onDelete={(changeSet, transferOfficeResourcesTo) =>
            deleteOffice({
              variables: {
                accountId: account.id,
                officeId: officeId,
                changeSet,
                transferOfficeResourcesTo,
              },
            }).then(result => {
              if (result == null || result.data == null) {
                return null;
              }

              return result.data.deleteOffice;
            })
          }
          mutationError={error}
          deletionType={DELETION_TYPE.DELETE_OFFICE}
        />
      )}
    </DeleteOfficeMutation>
  );
};

export default DeleteOfficeModal;
