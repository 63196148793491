import React from 'react';
import { FlexLayout, LayoutJustification } from '~/graphql/types';
import JustificationContainer, {
  Justification,
} from '~/components/JustificationContainer';

export type Props = Omit<FlexLayout, '__typename'> & {
  dataTestId?: string;
};

const Flex: React.FC<Props> = ({
  children,
  css,
  padding,
  margin,
  justification,
  align,
  gap,
  direction,
}) => (
  <JustificationContainer
    padding={padding ? padding : ['base']}
    margin={margin ? margin : undefined}
    justification={convertToFrontendType(justification)}
    align={convertToFrontendType(align)}
    gap={gap ?? undefined}
    direction={direction ?? undefined}
    wrap="wrap"
    css={css}
  >
    {children}
  </JustificationContainer>
);

const convertToFrontendType = (
  justification?: LayoutJustification | null,
): Justification => {
  switch (justification) {
    case LayoutJustification.Start:
      return 'start';
    case LayoutJustification.End:
      return 'end';
    case LayoutJustification.Center:
      return 'center';
    case LayoutJustification.SpaceBetween:
      return 'space-between';
    case LayoutJustification.SpaceAround:
      return 'space-around';
    case LayoutJustification.Stretch:
      return 'stretch';

    default:
      return 'start';
  }
};

export default Flex;
