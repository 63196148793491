import React, { createContext, Reducer } from 'react';
import { Props as ButtonProps } from '~/components/Button';
import { Props as TextButtonProps } from '~/components/TextButton';
import { IconType } from '~/components/Icon';

import { OutputMap, StepId, StepOutput } from '~/components/WizardSteps';
import { StoredState } from '../../db';
import { AppType } from '~/graphql/types';
import { initialHypotheekColorSettingsState } from '~/components/WizardSteps/Apps/Hypotheekbond/HypotheekColorSettings';
import { valueReportInitialColorSettingsState } from '~/components/WizardSteps/Apps/ValueReport/ValueReportColorSettings';
import { AppStatus__typename, WizardFlow } from '~/graphql/types.client';

export type StepMetadata = {
  appType?: AppType;
};

export type WizardStepProps = {
  step: WizardStep;
  outputMap: OutputMap;
};

export type WizardStepHooks = {
  onEnter?: () => void;
  onBeforeEnter?: () => void;
  onExit?: () => void;
  onBeforeExit?: () => void;
};

export type WizardStep = {
  id: StepId;
  title: React.ReactNode;
  subSteps?: Array<Omit<WizardStep, 'subSteps'>>;
  icon?: IconType;
  description?: React.ReactNode;
  isTouched?: boolean;

  nextButton?: Omit<ButtonProps, 'onClick'>;
  previousButton?: Omit<TextButtonProps, 'onClick'>;
  cancelButton?: {
    label: React.ReactNode;
  };
  metadata: {
    typename: AppStatus__typename;
  };
};

export type StepOptions = {
  onBeforeNext?: (output: OutputMap) => Promise<StepOutput>;
  onBeforePrevious?: (output: OutputMap) => Promise<StepOutput>;
};

export type ReducerAction<T, P extends object> = {
  type: T;
  payload: P;
};

type AddStepAction = ReducerAction<'addStep', WizardStep>;
type NextStepAction = ReducerAction<'next', {}>;
type PreviousStepAction = ReducerAction<'previous', {}>;
type ShowAction = ReducerAction<'show', WizardFlow & Partial<StoredState>>;
type HideAction = ReducerAction<'hide', {}>;

// Step actions
type FreeStepAction = ReducerAction<
  'freeStep',
  { step: StateStep; output: StepOutput }
>;
type LockStepAction = ReducerAction<
  'lockStep',
  { step: StateStep; output: StepOutput }
>;
type AddSubStepAction = ReducerAction<
  'addSubStep',
  { step: StateStep; subStep: WizardStep }
>;
type UpdateStepAction = ReducerAction<
  'updateStep',
  { step: StateStep; options: StepOptions }
>;

// Utility actions
type RestoreStateAction = ReducerAction<'restoreState', StoredState>;
type SetOutputAction = ReducerAction<'setOutput', OutputMap>;
type ClearStateAction = ReducerAction<'clear', {}>;

export type WizardAction =
  // Wizard API
  | AddStepAction
  | NextStepAction
  | PreviousStepAction
  | ShowAction
  | HideAction
  // Step API
  | FreeStepAction
  | LockStepAction
  | AddSubStepAction
  | UpdateStepAction
  // Utility actions
  | RestoreStateAction
  | SetOutputAction
  | ClearStateAction;

export type StateStep = Omit<WizardStep, 'subSteps'> & {
  subSteps?: Array<StateStep>;
  isFree?: boolean;
  isTouched?: boolean;
  options?: StepOptions;
};

export type StateStepWithSubSteps = Omit<StateStep, 'subSteps'> & {
  subSteps: Array<StateStep>;
};

export type WizardState = {
  id: string;
  show: boolean;
  currentStep: number;
  currentSubStep: null | number;
  steps: Array<StateStep>;
  outputMap: OutputMap;
  header?: string;
};

export type WizardReducer = Reducer<WizardState, WizardAction>;

export type IWizardContextProps = {
  id: string;
  dispatch: React.Dispatch<WizardAction>;
  state: WizardState;
};

export type OutputFieldItem = {
  /**
   * Used to group output types
   */
  category: string;
  type: 'item';
  label: string;
  icon?: IconType;
  value: string & { insertedId?: string };
};

export type OutputFieldList<T> = {
  /**
   * Used to group output types
   */
  category: string;
  type: 'list';
  value: Array<T & { label: string; insertedId?: string; icon?: IconType }>;
};

export type OutputFieldColor = {
  /**
   * Used to group output types
   */
  category: string;
  /**
   * Used for rendering names on the overview page.
   */
  label: string;
  type: 'color';
  value: string;
};

export type OutputFieldImage<T> = {
  /**
   * Used to group output types
   */
  category: string;
  type: 'image';
  label: string;
  value: T | null;
};

export type OutputFieldInvisible<T> = {
  type: 'invisible';
  value: T;
};

/**
 * Should not be used as an output type only for reasoning about "all" outputs
 */

export type OutputField<T> =
  | OutputFieldItem
  | OutputFieldList<T>
  | OutputFieldInvisible<T>
  | OutputFieldColor
  | OutputFieldImage<T>;

export const initialState: WizardState = {
  id: 'initialId',
  currentSubStep: null,
  currentStep: 0,
  steps: [],
  show: false,
  outputMap: {
    // <--- Start of generic steps --->
    GenericStart: {
      type: 'GenericStart',
    },

    // Enable apps for widget
    GenericWidgetConfiguration: {
      type: 'GenericWidgetConfiguration',
      selectedApps: {
        category: '',
        type: 'list',
        value: [],
      },
    },

    // Enable flows
    EnableFlowsForAppStatus: {
      type: 'EnableFlowsForAppStatus',
      appStatus: {
        type: 'invisible',
        value: null,
      },
      insertedFlows: {
        category: '',
        type: 'list',
        value: [],
      },
      selectedTemplates: {
        type: 'invisible',
        value: [],
      },
    },

    // Overview
    AppStatusOverview: {
      type: 'AppStatusOverview',
    },

    // <--- End of generic steps --->

    // Zapier
    ConnectZapier: {
      type: 'ConnectZapier',
    },
    AddZapierEvent: {
      type: 'AddZapierEvent',
      selectedEvents: {
        category: '',
        type: 'list',
        value: [],
      },
    },
    AddZapierTrigger: {
      type: 'AddZapierTrigger',
      selectedTriggers: {
        category: '',
        type: 'list',
        value: [],
      },
    },
    ZapierNextSteps: {
      type: 'ZapierNextSteps',
    },

    // Realworks
    RealworksActivationPending: {
      type: 'RealworksActivationPending',
      relatieToken: undefined,
      wonenToken: undefined,
    },
    RealworksExportContacts: {
      type: 'RealworksExportContacts',
      wonenToken: {
        type: 'invisible',
        value: '',
      },
    },
    RealworksImportContacts: {
      type: 'RealworksImportContacts',
      relatieToken: { type: 'invisible', value: '' },
    },
    RealworksSetup: {
      type: 'RealworksSetup',
      addTokenContainerInput: {
        type: 'invisible',
        value: {
          name: '',
        },
      },
    },

    // Waardecheck
    WaardecheckGeneral: {
      type: 'WaardecheckGeneral',
      route: {
        type: 'invisible',
        value: null,
      },
    },
    WaardecheckDesign: {
      type: 'WaardecheckDesign',
      data: {
        type: 'invisible',
        value: null,
      },
      logoImage: {
        type: 'image',
        category: 'Images',
        label: 'Logo',
        value: null,
      },
      fallbackImage: {
        type: 'image',
        category: 'Images',
        label: 'Background Image',
        value: null,
      },
      logoLink: {
        type: 'invisible',
        value: {
          en: null,
          nl: null,
        },
      },
      primaryBackgroundColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      primaryColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      secondaryBackgroundColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      secondaryColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      tertiaryBackgroundColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      tertiaryColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      quaternaryBackgroundColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      quaternaryColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      sameColors: false,
    },
    // Trustoo
    AddTrustooKoppeling: {
      type: 'AddTrustooKoppeling',
      trustooTokens: {
        type: 'item',
        category: '',
        value: '',
        label: '',
      },
      trustooOfficeId: {
        type: 'invisible',
        value: '',
      },
    },

    // Hypotheekbond
    HypotheekColorSettings: initialHypotheekColorSettingsState,

    // ValueReport
    ValueReportGeneralImageSettings: {
      type: 'ValueReportGeneralImageSettings',
      logoImage: {
        type: 'image',
        category: 'Report Image',
        value: null,
        label: 'Logo image',
      },
      valueReportLogoLink: undefined,
      backgroundImage: {
        category: '',
        type: 'image',
        value: null,
        label: '',
      },
      addedReport: undefined,
    },

    ValueReportColorSettings: valueReportInitialColorSettingsState,
  },
};

const WizardContext = createContext<IWizardContextProps>({
  id: 'intialID',
  dispatch: () => {},
  state: initialState,
});

export default WizardContext;
