import { Flow_Condition__Input } from '~/graphql/types';
import { asStringParameterValueInput } from '../..';
import { FlowConditionAppZapierNew } from './types.flow';

export default (
  condition: FlowConditionAppZapierNew,
): Flow_Condition__Input => ({
  EventContactApp: {
    Zapier: {
      New: {
        zapierEventId: asStringParameterValueInput(condition.zapierEventId),
      },
    },
  },
});
