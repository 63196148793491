import gql from 'graphql-tag';

export default gql`
  mutation StartEmailSyncWithCustomProvider(
    $accountId: ID!
    $account: EmailSyncAccount_CustomProvider__Input
    $office: EmailSyncOffice_CustomProvider__Input
    $user: EmailSyncUser_CustomProvider__Input
    $provider: EmailProvider__Input!
  ) {
    startEmailSyncWithCustomProvider(
      accountId: $accountId
      account: $account
      office: $office
      user: $user
      provider: $provider
    ) {
      email
      syncState
    }
  }
`;
