import { RealworksSendContactAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { emptyTemplateStringParameterValue } from '../Base';

const defaultNewCreateTaskActionProps = (
  baseActionProps: BaseActionProps,
): RealworksSendContactAction => ({
  ...baseActionProps,
  type: FLOW_ACTION_TYPE.SENDCONTACT_TO_REALWORKS,
  description: emptyTemplateStringParameterValue(),
});

export default defaultNewCreateTaskActionProps;
