import React, { ReactElement } from 'react';

import { TimeInputComponentType } from '../TimePicker';

import Field from './Field';
import TimePicker from '~/components/TimePicker';
import { Container } from './Form.style';
import FloatingLabel from '~/components/FloatingLabel';
import Icon from '../Icon';

type Props = {
  name: string;
  value: string;
  onChange: (newDate: string) => void;
  inputComponentType?: TimeInputComponentType;
  label?: string;
  error?: string;
  small?: boolean;
  large?: boolean;
  disabled?: boolean;
};

const TimeField: React.FC<Props> = ({
  error,
  small,
  large,
  label,
  ...props
}) => {
  let labelComponent: ReactElement | null = null;
  if (error || label) {
    const labelMessage = error || label || '';

    labelComponent = (
      <FloatingLabel
        actAsPlaceholder={false}
        error={error}
        small={small}
        large={large}
      >
        {labelMessage}
      </FloatingLabel>
    );
  }

  return (
    <div>
      {labelComponent}
      <Container error={error} small={small} large={large}>
        <Field {...props} component={TimeFieldComponent} />
        <Icon name="clock" />
      </Container>
    </div>
  );
};

const TimeFieldComponent = ({ input, ...rest }: any) => {
  const { name, onChange, value } = input;

  return (
    <TimePicker
      name={name}
      value={value}
      size={'6'}
      setValue={onChange}
      {...rest}
    />
  );
};

export default TimeField;
