import { TypedField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';

export const FLOW_CONDITION_CONTACT_DETAILS_FIELD: {
  [key: string]: TypedField;
} = Object.freeze({
  name: {
    name: 'name',
    label: 'Naam',
    type: FIELD_TYPE.STRING,
  },
  email: {
    name: 'email',
    label: 'Email',
    type: FIELD_TYPE.STRING,
  },
  phone: {
    name: 'phone',
    label: 'Telefoonnummer',
    type: FIELD_TYPE.STRING,
  },
  city: {
    name: 'city',
    label: 'Woonplaats',
    type: FIELD_TYPE.STRING,
  },
  postcode: {
    name: 'postcode',
    label: 'Postcode',
    type: FIELD_TYPE.STRING,
  },
  street: {
    name: 'street',
    label: 'Straat',
    type: FIELD_TYPE.STRING,
  },
  blockMarketing: {
    name: 'blockMarketing',
    label: 'Uitgeschreven voor nieuwsbrieven',
    type: FIELD_TYPE.BOOLEAN,
  },
});

export const FLOW_CONDITION_CONTACT_DETAILS_FIELDS: Array<TypedField> =
  Object.keys(FLOW_CONDITION_CONTACT_DETAILS_FIELD).map(
    key => FLOW_CONDITION_CONTACT_DETAILS_FIELD[key],
  );

export const DEFAULT_FLOW_CONDITION_CONTACT_DETAILS_FIELD =
  FLOW_CONDITION_CONTACT_DETAILS_FIELD.name;
