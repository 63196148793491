import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  dataTestId?: string;
  /** Maximum spacing between entries */
  maxGap?: string;
};

const InputGroup: React.FC<Props> = ({
  dataTestId,
  children,
  maxGap,
  ...rest
}) => {
  const child = React.Children.map(children, (child, index) => {
    if (index === 0) return child;

    return (
      <>
        <Gutter $maxGap={maxGap} />
        {child}
      </>
    );
  });

  return (
    <Container data-testid={dataTestId} {...rest}>
      {child}
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: flex-end;
    margin: ${theme.space('s')} 0;
  `,
);

const Gutter = styled.div<{ $maxGap?: string }>(
  ({ $maxGap }) => css`
    width: ${$maxGap ? $maxGap : '70px'};
  `,
);

export default InputGroup;
