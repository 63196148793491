import React, { ReactElement, useContext } from 'react';

import { BaseTemplateStringParameterValueComponentProps } from './TemplateStringParameterValueComponent';
import { FlowEmailAttachmentValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { FloatingLabel } from '~/components';
import EmailEditor from '~/components/HTMLEditor/EmailEditor';
import InputGroupElement from '~/components/Inputs/InputGroupElement';
import TemplateStringParameterHTMLHandler from './components/TemplateStringParameterHTMLHandler';
import InsertOrUpdateModal from './components/InsertOrUpdateModalHandler';
import BaseActionContext from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import {
  convertBackendHTMLToFrontend,
  convertFrontendHTMLToBackend,
} from './util/htmlStringConversion';
import { UNSUBSCRIBE_LINK_BUTTON_NAME } from '~/components/HTMLEditor/constants';

type TemplateStringEmailParameterValueComponentProps =
  BaseTemplateStringParameterValueComponentProps & {
    initialInlineAttachments: Array<FlowEmailAttachmentValue> | null;
    setGetInlineAttachments?: (
      fn: () => Array<FlowEmailAttachmentValue>,
    ) => void;
  };

const TemplateStringEmailParameterValueComponent = ({
  initialTemplateStringValue,
  initialInlineAttachments,
  label,
  setGetTemplateComponent,
  setGetInlineAttachments,
  announceChanges,
  dataTestid,
  errorMessage,
  onBlur,
}: TemplateStringEmailParameterValueComponentProps) => {
  const { isHiddenFromView } = useContext(BaseActionContext);

  let labelComponent: ReactElement | null = null;
  if (label != null || errorMessage != null) {
    labelComponent = (
      <FloatingLabel error={errorMessage}>
        {errorMessage || label}
      </FloatingLabel>
    );
  }

  return (
    <TemplateStringParameterHTMLHandler
      initialTemplateString={initialTemplateStringValue}
      onVariableInsertedOrUpdated={announceChanges}
      setGetTemplateComponent={setGetTemplateComponent}
      setGetInlineAttachments={setGetInlineAttachments}
      initialInlineAttachments={initialInlineAttachments}
    >
      {({
        initialHtml,
        registerHTMLEditorInteractionFunctions,
        useFlowInlineAttachments,
        hasChanged,
        refreshHtml,
        variableUpdaterId,
        getTemplateString,
        insertOrUpdateVariable,
      }) => (
        <InsertOrUpdateModal
          variableUpdaterId={variableUpdaterId}
          getTemplateStringValue={() => getTemplateString().templateString}
          onSaveFunction={insertOrUpdateVariable}
        >
          {({ toggleVariableFunction }) => (
            <div data-testid={dataTestid}>
              {labelComponent}
              <EmailEditor
                useFlowInlineAttachments={useFlowInlineAttachments}
                initialHtml={initialHtml}
                onBlur={() => {
                  if (hasChanged()) {
                    announceChanges();
                  }

                  if (onBlur) {
                    onBlur();
                  }
                }}
                onChange={() => {
                  if (hasChanged()) {
                    announceChanges();
                  }
                }}
                onExternalVariableAdded={() => {
                  refreshHtml();
                }}
                hasError={false}
                addFlowVariablePlugin={{
                  onVariableClick: () => {},
                  onVariableAdd: () => {
                    toggleVariableFunction();
                  },
                }}
                registerInteractionFunctions={
                  registerHTMLEditorInteractionFunctions
                }
                componentVariableUpdaterId={variableUpdaterId}
                isHiddenFromView={isHiddenFromView}
                initialButtons={[UNSUBSCRIBE_LINK_BUTTON_NAME]}
                toFroalaHtmlConverters={[convertBackendHTMLToFrontend]}
                fromFroalaHtmlConverters={[convertFrontendHTMLToBackend]}
              />
            </div>
          )}
        </InsertOrUpdateModal>
      )}
    </TemplateStringParameterHTMLHandler>
  );
};

export default InputGroupElement<TemplateStringEmailParameterValueComponentProps>(
  TemplateStringEmailParameterValueComponent,
);
