export enum PARAMETER_POINTER_VALUE_TYPE {
  STRING_POINTER = 'Flow_Parameter_String_Pointer',
  NUMBER_POINTER = 'Flow_Parameter_Number_Pointer',
  OFFICE_POINTER = 'Flow_Parameter_Office_Pointer',
  USER_POINTER = 'Flow_Parameter_User_Pointer',
  EMAIL_POINTER = 'Flow_Parameter_Email_Pointer',
  EMAIL_TEMPLATE_POINTER = 'Flow_Parameter_Template_Pointer',
  BOOLEAN_POINTER = 'Flow_Parameter_Boolean_Pointer',
  FILE_POINTER = 'Flow_Parameter_File_Pointer',
  EVENT_VALUATIONREPORT_POINTER = 'Flow_Parameter_Event_Contact_AppValuationReport_Pointer',
  EVENT_VALUATIONREQUEST_POINTER = 'Flow_Parameter_Event_Contact_AppValuationRequest_Pointer',
  EVENT_CONTACT_APP_FUNDA_CONTACTREQUEST_POINTER = 'Flow_Parameter_Event_Contact_App_Funda_ContactRequest_Pointer',
  EVENT_CONTACT_APP_FUNDA_VIEWINGREQUEST_POINTER = 'Flow_Parameter_Event_Contact_App_Funda_ViewingRequest_Pointer',
  EVENT_CONTACT_APP_FUNDA_BROCHUREREQUEST_POINTER = 'Flow_Parameter_Event_Contact_App_Funda_BrochureRequest_Pointer',
  EVENT_CONTACT_APP_BBWAARDECHECK_REPORT_POINTER = 'Flow_Parameter_Event_Contact_App_BBWaardecheck_Report_Pointer',
  EVENT_CONTACT_APP_BBWAARDECHECK_APPRAISALREQUEST_POINTER = 'Flow_Parameter_Event_Contact_App_BBWaardecheck_AppraisalRequest_Pointer',
  EVENT_CONTACT_APP_ZAPIER_POINTER = 'Flow_Parameter_Event_Contact_App_Zapier_Pointer',
  EVENT_CONTACT_APP_VBOWAARDECHECK_REPORT_POINTER = 'Flow_Parameter_Event_Contact_App_VBOWaardecheck_Report_Pointer',
  EVENT_CONTACT_APP_VBOWAARDECHECK_APPRAISALREQUEST_POINTER = 'Flow_Parameter_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Pointer',
  EVENT_CONTACT_APP_HYPOTHEEKBOND = 'Flow_Parameter_Event_Contact_App_Hypotheekbond_Pointer',
}
export enum PARAMETER_PRIMITIVE_VALUE_TYPE {
  STRING_PRIMITIVE = 'Flow_Parameter_String_Primitive',
  NUMBER_PRIMITIVE = 'Flow_Parameter_Number_Primitive',
  OFFICE_PRIMITIVE = 'Flow_Parameter_Office_Primitive',
  USER_PRIMITIVE = 'Flow_Parameter_User_Primitive',
  EMAIL_PRIMITIVE = 'Flow_Parameter_Email_Primitive',
  EMAIL_TEMPLATE_PRIMITIVE = 'Flow_Parameter_Template_Primitive',
  BOOLEAN_PRIMITIVE = 'Flow_Parameter_Boolean_Primitive',
  FILE_PRIMITIVE = 'Flow_Parameter_File_Primitive',
  APP_VALUATIONREPORT_PRIMITIVE = 'Flow_Parameter_AppValuationReport_Primitive',
}

export enum TEMPLATE_STRING_VALUE_TYPE {
  TEMPLATE_STRING = 'Flow_Parameter_TemplateString',
}

export const PARAMETER_VALUE_TYPE = Object.freeze({
  ...TEMPLATE_STRING_VALUE_TYPE,
  ...PARAMETER_POINTER_VALUE_TYPE,
  ...PARAMETER_PRIMITIVE_VALUE_TYPE,
});
export type ParameterValueType = typeof PARAMETER_VALUE_TYPE;
