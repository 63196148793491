import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import ProgressiveImgIx from '~/components/ProgressiveImgIx';
import { Heading3 } from '~/components/Typography/index';

export type Props = {
  dataTestId?: string;
  name: string;
  logo: {
    src: string;
  };
  tags?: Array<string>;
};

const LOGO_SIZE = 80;
const HeaderContainer: React.FC<Props> = ({ dataTestId, name, logo, tags }) => {
  const hasTags = tags && tags.length > 0;

  return (
    <Container>
      <JustificationContainer data-testid={dataTestId} align="center">
        <LogoContainer align="center" justification="center">
          <ProgressiveImgIx src={logo.src} width={LOGO_SIZE} alt="App logo" />
        </LogoContainer>

        <Heading3 color={{ group: 'primary' }} withoutMargin>
          {name}
        </Heading3>
      </JustificationContainer>

      {hasTags && (
        <JustificationContainer margin={['base', null, null, null]}>
          {tags?.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </JustificationContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) =>
    css`
      margin-bottom: ${theme.space('l')};
    `,
);

const LogoContainer = styled(JustificationContainer)<{}>(
  ({ theme }) =>
    css`
      padding: ${theme.space('xxs')};
      border: ${theme.getTokens().border.width.s} solid
        ${theme.color('grey', 'light')};
      border-radius: ${theme.getTokens().border.radius.s};
      margin-right: ${theme.space('m')};
      width: ${LOGO_SIZE}px;
      height: ${LOGO_SIZE}px;
    `,
);

const Tag = styled.div<{}>(
  ({ theme }) =>
    css`
      border-radius: ${theme.getTokens().border.radius.m};
      background-color: ${theme.color('tertiary', 'base')};
      margin-right: ${theme.space('base')};
      padding: ${theme.space('xxs')};
      color: ${theme.color('text', 'base')};
      font-size: ${theme.fontSize('xs')};
    `,
);

export default HeaderContainer;
