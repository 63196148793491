import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  children: React.ReactNode;
  className?: string;
  dataTestId?: string;
};

const MasonryCard = ({
  children,
  className = '',
  dataTestId = '',
  ...rest
}: Props) => (
  <Container className={className} data-testid={dataTestId} {...rest}>
    {children}
  </Container>
);

const Container = styled.div<{}>`
  min-height: 100px;
  display: inline-block;

  ${({ theme }) => css`
    width: 100%;
    max-width: 640px;
    padding: ${theme.space('l')} ${theme.space('l')};
    box-shadow: ${theme.boxShadow('s')};
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};
    /** This should be same as column-gap of MasonryGrid container */
    margin-bottom: ${theme.space('xxl')};
  `}
`;

export default MasonryCard;
