import { FlowConditionList } from './types.flow';
import {
  Flow_ConditionList__Input,
  Flow_Condition__Input,
} from '~/graphql/types';

import asFlowConditionInput from '../FlowCondition/asFlowConditionInput';
import asFlowConditionGroupInput from '../FlowCondition/asFlowConditionGroupInput';
import { AND_IDENTIFIER, OR_IDENTIFIER } from './expressionHelpers';

const asFlowConditionListInput = (
  conditionList: FlowConditionList,
): Flow_ConditionList__Input | null => {
  const { conditions } = conditionList;

  if (conditions.length === 0) return null;

  let expressionString = '';
  const conditionInput: Array<Flow_Condition__Input> = [];
  let isFirst = true;

  /** @TODO Fix yolo  */
  conditionList.conditions.forEach(condition => {
    switch (condition.type) {
      case 'Flow_Condition_Contact_Details':
      case 'Flow_Condition_Event_Contact_App_Zapier_Details':
      case 'Flow_Condition_Event_Contact_App_ValuationReport_Details':
      case 'Flow_Condition_Event_Contact_App_ValuationRequest_Details':
      case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details':
      case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details':
      case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details':
      case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details':
      case 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details':
      case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details': {
        expressionString += `${operatorIdentifierOrEmptyIfFirst(
          isFirst,
          conditionList.allShouldBeTrue,
        )}([`;
        isFirst = true;
        asFlowConditionGroupInput(condition).forEach(input => {
          expressionString += `${operatorIdentifierOrEmptyIfFirst(
            isFirst,
            condition.allShouldBeTrue,
          )}${conditionInput.length}`;
          conditionInput.push(input);
          isFirst = false;
        });
        expressionString += '])';
        break;
      }
      default:
        expressionString += `${operatorIdentifierOrEmptyIfFirst(
          isFirst,
          conditionList.allShouldBeTrue,
        )}${conditionInput.length}`;
        conditionInput.push(asFlowConditionInput(condition));
        isFirst = false;
        break;
    }
  });

  return {
    exp: expressionString,
    conditions: conditionInput,
  };
};

const operatorIdentifierOrEmptyIfFirst = (
  isFirst: boolean,
  allShouldBeTrue: boolean,
): string => {
  if (isFirst) {
    return '';
  }

  if (allShouldBeTrue) {
    return ` ${AND_IDENTIFIER} `;
  } else {
    return ` ${OR_IDENTIFIER} `;
  }
};

export default asFlowConditionListInput;
