import gql from 'graphql-tag';
import ActivityFields from './ActivityFields';

export default gql`
  fragment ContactListWithLastActivityFields on ContactWithLastActivity {
    __typename
    id
    name
    email
    address {
      street
      houseNumber
      addition
      city
      postcode
    }
    createdDate
    tags
    status
    blockMarketing
    LeadScore
    lastActivity {
      ...ActivityFields
    }
    leadActivity {
      ...ActivityFields
    }
    Metadata {
      leadScore
      oneDayChange
      threeDayChange
      sevenDayChange
    }
  }

  ${ActivityFields}
`;
