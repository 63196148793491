import type { RouteComponentProps } from '@reach/router';
import React from 'react';
import MetaTags from 'react-meta-tags';

import {
  App_Hypotheekbond_ToolFragment,
  useGetAppHypotheekbondQuery,
} from '~/graphql/types';
import DatHuisLoading from '~/components/DatHuisLoading';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import NotFound from '~/pages/404';
import ErrorScreen from '~/scenes/ErrorScreen';
import cleanedFilename from '~/util/cleanedFilename';
import useErrorReporter from '~/hooks/useErrorReporter';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import MasterDetail from '~/components/MasterDetail';
import TEST_ID_MASTER_DETAIL from '~/components/MasterDetail/index.testid';
import Settings from './components/Settings';
import useApp from '~/hooks/useApp';

const text = {
  appTitle: 'Nationale Hypotheekbond',
};

type Props = RouteComponentProps & {};

export type ToolMap = Record<string, App_Hypotheekbond_ToolFragment>;

const Hypotheekbond: React.FC<Props> = ({ uri = '' }) => {
  const errorReporter = useErrorReporter();
  const { id: accountId } = useCurrentAccount();
  const { data, error, loading, updateQuery } = useGetAppHypotheekbondQuery({
    variables: {
      accountId,
    },
  });

  const {
    app,
    loading: appStatusLoading,
    error: appStatusError,
  } = useApp('AppStatus_Hypotheekbond');

  if (error || appStatusError) {
    errorReporter.captureException(
      new Error(
        `${cleanedFilename(__filename)} | An error occured (${JSON.stringify(
          error || appStatusError,
        )})`,
      ),
    );

    return <ErrorScreen />;
  }

  if (appStatusLoading || loading) return <DatHuisLoading />;
  if (!app?.appStatus.enabled || !data || !data.getAppHypotheekbond)
    return <NotFound />;

  return (
    <>
      <MetaTags>
        <title>{text.appTitle}</title>
      </MetaTags>
      <ContentContainerDefault
        breadcrumbs={[
          {
            to: '/-/apps',
            label: 'Apps',
          },
          { label: text.appTitle },
        ]}
      >
        <MasterDetail
          basePath={uri}
          dataTestId={TEST_ID_MASTER_DETAIL.CONTAINER}
          navbar={[
            {
              type: 'link',
              to: '',
              icon: 'gear',
              name: 'Algemeen',
            },
          ]}
        >
          <Settings
            path="/"
            settings={data.getAppHypotheekbond.settings}
            updateQuery={updateData => {
              updateQuery(data => {
                if (!updateData || !data.getAppHypotheekbond) return data;

                return {
                  ...data,
                  getAppHypotheekbond: {
                    ...data.getAppHypotheekbond,
                    settings: {
                      ...data.getAppHypotheekbond?.settings,
                      ...updateData?.updateAppHypotheekbond.settings,
                    },
                  },
                };
              });
            }}
          />
        </MasterDetail>
      </ContentContainerDefault>
    </>
  );
};

export default Hypotheekbond;
