import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { linkStyles } from '~/components/Link';
import { Heading5, Variant } from '~/components/Typography/index';
import { SystemSize } from '~/theme';
import arrayToCss from '~/util/arrayToCss';
import { Issue as IssueProps } from '../../../../../state/flowIssues';

export type Props = {
  dataTestId?: string;
  level: IssueProps['level'];
  actionType: IssueProps['actionType'];
  message: IssueProps['message'];
  actionId: IssueProps['actionId'];
  margin?: Array<SystemSize | null>;
};

const Issue: React.FC<Props> = ({
  dataTestId,
  level,
  message,
  actionId,
  margin = [],
  ...rest
}) => {
  const heading = level === 'error' ? 'Foutmelding' : 'Waarschuwing';
  return (
    <Container
      issue={level}
      data-testid={dataTestId}
      {...rest}
      $margin={margin}
    >
      <Heading5 inline variant={Variant.primary}>
        {heading}:{' '}
      </Heading5>
      {message}{' '}
      {!isNil(actionId) && (
        <UpdateLink onClick={e => e.preventDefault()} href={`#${actionId}`}>
          (aanpassen)
        </UpdateLink>
      )}
    </Container>
  );
};

const Container = styled.div<{
  issue: IssueProps['level'];
  $margin?: Props['margin'];
}>(({ theme, issue, $margin = [] }) => {
  const textColor =
    issue === 'error' ? theme.color('white') : theme.color('text');
  return css`
    background: ${issue === 'error'
      ? theme.color('danger')
      : theme.color('warning')};
    padding: ${theme.spacing('xxs')} ${theme.spacing('s')};
    border-radius: ${theme.getTokens().border.radius.s};
    color: ${textColor};
    margin: ${arrayToCss($margin, theme)};
    h4 {
      color: ${textColor};
    }
  `;
});

const UpdateLink = styled.a(
  ({ theme }) => css`
    ${linkStyles}
    color: ${theme.color('danger', 'text')};
    font-weight: ${theme.fw('semiBold')};

    &:hover {
      color: ${theme.color('danger', 'text')};
    }
  `,
);

export default Issue;
