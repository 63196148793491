import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import Icon, { IconType } from '~/components/Icon';
import Tooltip from '~/components/Tooltip';
import useHover from '~/components/util/useHover';

export type Props = {
  dataTestId?: string;
  icon: IconType;
  active?: boolean;
  tooltipMessage?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
};

const BaseButton: React.FC<Props> = React.forwardRef(
  (
    {
      dataTestId,
      active,
      icon,
      tooltipMessage,
      children,
      disabled = false,
      ...rest
    },
    ref,
  ) => {
    const [showTooltip, tooltipProps] = useHover();

    const isShown = showTooltip && tooltipMessage;

    return (
      <ToolbarButton
        ref={ref}
        data-testid={dataTestId}
        $active={active}
        disabled={disabled}
        {...tooltipProps}
        {...rest}
      >
        <Icon name={icon} strokeWidth={2.5} />

        {isShown && <StyledTooltip>{tooltipMessage}</StyledTooltip>}

        {children}
      </ToolbarButton>
    );
  },
);

const StyledTooltip = styled(Tooltip)<{}>(
  ({ theme }) => css`
    z-index: ${theme.z('top')};
    font-size: ${theme.fontSize('s')};
    padding: ${theme.space('xxs')};
    white-space: nowrap;
    top: 25px;
    left: 20px;
  `,
);

export const ToolbarButton = styled.button<{
  $active?: boolean;
  disabled: boolean;
}>`
  ${({ $active, theme, disabled }) => css`
    /** Used to position tooltip component in some of the buttons */
    position: relative;
    display: flex;
    align-items: center;
    border: ${theme.getTokens().border.width.s} solid
      ${$active ? theme.color('white', 'dark') : 'transparent'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    padding: ${theme.space('xxs')};
    margin: ${theme.space('xxxs')};

    background-color: transparent;
    border-radius: ${theme.getTokens().border.radius.s};
    font-size: ${theme.fontSize('base')};

    background-color: ${$active ? theme.color('primary') : 'transparent'};
    color: ${$active
      ? theme.color('white')
      : disabled
      ? theme.color('grey')
      : theme.color('primary', 'light')};

    &:hover {
      color: ${!$active && !disabled && theme.color('primary', 'light')};
      background-color: ${!$active &&
      !disabled &&
      theme.color('white', 'dark')};
    }

    transition: all 0.3s ease-out;
  `};
`;

export default BaseButton;
