import { isNil } from 'ramda';
import React, { useCallback, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Icon from '~/components/Icon';
import JustificationContainer from '~/components/JustificationContainer';
import { Flow___ConditionPartial } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor';
import RepresentationString from '~/scenes/Automation/v2/components/RepresentationString';
import { Issue } from '~/scenes/Automation/v2/state/flowIssues';
import { ConditionType } from '../../../Selector/utils/getFieldsByPath';
import TEST_ID from './index.testid';
import useDebounce from '~/hooks/useDebounce';

export type Props = {
  condition: Flow___ConditionPartial | null;
  parentCondition?: Flow___ConditionPartial | null;
  appearance?: ConditionType;
  issue: Issue | null;
  dataTestId: string;
  actionId: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>, index?: number) => void;
};

const CollapsedConditionRow: React.FC<Props> = ({
  condition,
  parentCondition,
  appearance = 'trigger',
  issue = null,
  actionId,
  dataTestId,
  onClick,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const hasIssue = !isNil(issue);

  const debouncedMouseOver = useDebounce<boolean>(mouseOver, 200);

  const onMouseEnter = useCallback(() => {
    setMouseOver(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setMouseOver(false);
  }, []);

  const onMouseOver = useCallback(() => {
    if (mouseOver !== true) {
      setMouseOver(true);
    }
  }, [mouseOver]);

  return (
    <Container
      data-testid={dataTestId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
    >
      <Inner
        $appearance={appearance}
        $hasIssue={hasIssue}
        {...rest}
        onClick={e => {
          e.stopPropagation();
          if (onClick) onClick(e, 0);
        }}
      >
        <JustificationContainer justification="start" align="center">
          {children}

          {appearance === 'trigger' && hasIssue === true && (
            <TriggerIconContainer>
              <Icon color={theme.color('danger')} name="exclamation" />
            </TriggerIconContainer>
          )}
          <RepresentationString
            type={appearance}
            condition={condition}
            parentCondition={parentCondition}
            actionId={actionId}
            hasIssue={hasIssue}
            onClick={onClick}
          />
        </JustificationContainer>
        <IconContainer $hover={debouncedMouseOver}>
          <Icon
            name="pencil"
            color={
              hasIssue ? theme.color('danger') : theme.color('primary', 'light')
            }
          />
        </IconContainer>
      </Inner>
      {hasIssue && appearance !== 'trigger' && (
        <IssueMessage data-testid={TEST_ID.ISSUE_MESSAGE}>
          <strong>Foutmelding: </strong>
          {issue?.message}
        </IssueMessage>
      )}
    </Container>
  );
};

const IconContainer = styled.div<{ $hover: boolean }>(
  ({ theme, $hover }) => css`
    transform: ${$hover === true
      ? `translate(0, 0)`
      : `translate(${theme.space('m')}, 0)`};
    opacity: ${$hover === true ? 1 : 0};

    transition: all 0.2s ease-out;
    align-self: flex-start;
  `,
);

const Container = styled.div(
  () => css`
    width: 100%;
  `,
);

const Inner = styled.div<{
  $appearance: ConditionType;
  $hasIssue: boolean;
}>(
  ({ theme, $appearance, $hasIssue }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    cursor: pointer;
    border-radius: ${$hasIssue
      ? `${theme.getTokens().border.radius.base} ${
          theme.getTokens().border.radius.base
        } 0 0`
      : theme.getTokens().border.radius.base};

    padding: ${$appearance === 'trigger'
      ? `0`
      : `${theme.space('s')} ${theme.space('m')}`};
    background-color: ${$appearance === 'trigger'
      ? 'transparent'
      : $hasIssue
      ? theme.color('danger', 'translucent')
      : theme.color('white')};
  `,
);

const TriggerIconContainer = styled.span(
  ({ theme }) => css`
    font-size: ${theme.fs('l')};
    margin: 0 ${theme.space('base')} 0 0;
  `,
);

const IssueMessage = styled.p(
  ({ theme }) => css`
    margin: 0;
    padding: ${theme.space('xxs')};
    background-color: ${theme.color('danger')};
    color: ${theme.color('danger', 'text')};
    border-radius: 0 0 ${theme.getTokens().border.radius.base}
      ${theme.getTokens().border.radius.base};
  `,
);

export default CollapsedConditionRow;
