import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import TEST_ID from './index.testid';
import {
  GetOverviewStatisticsQueryVariables,
  OverviewStatisticType,
  useGetOverviewStatisticsQuery,
} from '~/graphql/types';
import { Body, Heading2, Heading5 } from '~/components/Typography/index';
import StatIndicator from '~/components/Charts/StatIndicator';

import Icon from '~/components/Icon';
import DropdownGroup from './components/DropdownGroup';
import DatHuisLoading from '~/components/DatHuisLoading';
import getFormatOptions from './utils/getFormatOptions';
import useToday from '~/hooks/useToday';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Link from '~/components/Link';
import getDateRangeOptionsWithEndDate from '../../utils/getDateRangeOptionsWithEndDate';

export type Props = {
  dataTestId?: string;
  today?: Date;
};

const text = {
  heading: 'Statistieken',
  noStats: 'Geen statistieken',
  noStatsBody: 'Je hebt helaas geen statistieken',
  errorHeader: 'Uh-oh 😱',
  errorBody:
    'Het lijkt er op dat er iets mis is gegaan bij het ophalen van jouw statistieken. Probeer het later opnieuw.',
};

export const types: GetOverviewStatisticsQueryVariables['types'] = [
  OverviewStatisticType.NewContacts,
  OverviewStatisticType.TasksClosed,
  OverviewStatisticType.FlowsRan,
  OverviewStatisticType.EmailsSent,
  OverviewStatisticType.EmailsOpened,
  OverviewStatisticType.EmailsClicked,
  OverviewStatisticType.WalletBalance,
];

const tooltipsMap: Record<typeof types[number], React.ReactNode> = {
  WalletBalance: (
    <>
      Je hebt nog geen saldo in je wallet. Ga direct geld verdienen met onze
      apps.&nbsp;
      <Link to="/-/apps">Bekijk hier</Link>
    </>
  ),
  ActionsAutomated: null,
  EmailsClicked: null,
  EmailsOpened: null,
  EmailsSent: null,
  FlowsRan: null,
  NewContacts: null,
  TasksClosed: null,
};

const StatisticsOverview: React.FC<Props> = ({ today: originalToday }) => {
  const theme = useTheme();
  const today = useToday(originalToday);
  const [selectedDateRangeIndex, setSelectedDateRangeIndex] =
    useState<number>(0);
  const dateRangeOptions = getDateRangeOptionsWithEndDate(today);
  const dateRange = dateRangeOptions[selectedDateRangeIndex].payload;

  const { id: accountId } = useCurrentAccount();

  const { data, loading, error } = useGetOverviewStatisticsQuery({
    variables: {
      accountId,
      types,
      ...dateRange,
    },
  });

  return (
    <>
      <Header data-testid={TEST_ID.HEADER}>
        <HeadingContainer>
          <Icon name="bar-chart" color={theme.color('primary')} />
          <Heading2
            color={{ group: 'primary' }}
            margin={[null, null, null, 'base']}
            withoutMargin
          >
            {text.heading}
          </Heading2>
        </HeadingContainer>
        <DropdownGroup
          today={today}
          selectedDateRangeIndex={selectedDateRangeIndex}
          dateRanges={dateRangeOptions}
          onDateRangeChange={index => setSelectedDateRangeIndex(index)}
        />
      </Header>
      {loading && (
        <EdgeCaseContainer>
          <DatHuisLoading />
        </EdgeCaseContainer>
      )}
      {!loading && error && (
        <EdgeCaseContainer>
          {/* Random div around the error body to group the flex item in the center */}
          <div data-testid={TEST_ID.NO_STATS}>
            <Heading5>{text.errorHeader}</Heading5>
            <Body>{text.errorBody}</Body>
          </div>
        </EdgeCaseContainer>
      )}
      {!loading && !error && (
        <Container data-testid={TEST_ID.CONTAINER}>
          {data?.getOverviewStatistics.map(
            ({ type, unit, sentiment, value, text }) => (
              <StatIndicator
                key={type}
                formatOptions={getFormatOptions(unit)}
                label={text}
                sentiment={sentiment}
                value={value}
                dataTestId={`${TEST_ID.STAT_INDICATOR}-${type}`}
                tooltip={tooltipsMap[type]}
              />
            ),
          )}
        </Container>
      )}
    </>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: ${theme.space('m')};

    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

const Header = styled.header<{}>(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.space('xl')};
  `,
);

const EdgeCaseContainer = styled(Container)<{}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
`;

const HeadingContainer = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.fs('xxl')};
  `,
);

export default StatisticsOverview;
