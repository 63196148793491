import React from 'react';

import { TaskListProps } from './types.flow';
import { TaskGroups } from '~/util/task';

import { taskGroupTypeForDueDate, taskGroupLabelForType } from '~/util/task';
import TaskInListContainer from './TaskInList/TaskInListContainer';
import TASK_LIST_TYPE from '../../util/taskListType';
import { anySatisfy } from '~/util/array';

import { TaskListContainer, GroupMarker } from './TaskList.style';

type RemovedWithComponent = {
  isRemovedFromList: boolean;
  component: React.ReactNode;
};

export default ({ tasks, hasMore, updateTask, loading }: TaskListProps) => {
  const taskGroups: {
    [key in TaskGroups]: Array<RemovedWithComponent>;
  } = {
    [TaskGroups.OVERDUE]: [],
    [TaskGroups.TODAY]: [],
    [TaskGroups.UPCOMING]: [],
    [TaskGroups.COMPLETED]: [],
    [TaskGroups.COMPLETED_END]: [],
    [TaskGroups.DELETED]: [],
  };

  tasks.forEach(taskWithNewOrDeleted => {
    const { item, isRemovedFromList } = taskWithNewOrDeleted;

    if (item.dueDate !== null) {
      taskGroups[taskGroupTypeForDueDate(item.dueDate)].push({
        isRemovedFromList,
        component: (
          <TaskInListContainer
            key={item.id}
            isRemovedFromList={isRemovedFromList}
            task={item}
            updateTask={updateTask}
            listType={TASK_LIST_TYPE.OPEN}
          />
        ),
      });
    }
  });

  const componentList: Array<React.ReactNode> = [];

  const overdueTasks = taskGroups.OVERDUE;
  const hasOverdueTasks: boolean = anySatisfy(
    overdueTasks,
    taskWithRemovedOrAdded => !taskWithRemovedOrAdded.isRemovedFromList,
  );
  if (hasOverdueTasks) {
    componentList.push(
      <GroupMarker
        taskGroupType={TaskGroups.OVERDUE}
        data-testid="task-group-overdue"
        key="task-group-overdue"
      >
        {taskGroupLabelForType(TaskGroups.OVERDUE)}
      </GroupMarker>,
    );
  }
  // always push all tasks as they could be in mid removal animation
  componentList.push(
    overdueTasks.map(removedWithComponent => removedWithComponent.component),
  );

  const todayTasks = taskGroups.TODAY;
  const hasTodayTasks: boolean = anySatisfy(
    todayTasks,
    taskWithRemovedOrAdded => !taskWithRemovedOrAdded.isRemovedFromList,
  );
  if (hasTodayTasks) {
    componentList.push(
      <GroupMarker
        taskGroupType={TaskGroups.TODAY}
        data-testid="task-group-today"
        key="task-group-today"
      >
        {taskGroupLabelForType(TaskGroups.TODAY)}
      </GroupMarker>,
    );
  }
  // always push all tasks as they could be in mid removal animation
  componentList.push(
    todayTasks.map(removedWithComponent => removedWithComponent.component),
  );

  const upcomingTasks = taskGroups.UPCOMING;
  const hasUpcomingTasks: boolean = anySatisfy(
    upcomingTasks,
    taskWithRemovedOrAdded => !taskWithRemovedOrAdded.isRemovedFromList,
  );
  if (hasUpcomingTasks) {
    componentList.push(
      <GroupMarker
        taskGroupType={TaskGroups.UPCOMING}
        data-testid="task-group-upcoming"
        key="task-group-upcoming"
      >
        {taskGroupLabelForType(TaskGroups.UPCOMING)}
      </GroupMarker>,
    );
  }
  // always push all tasks as they could be in mid removal animation
  componentList.push(
    upcomingTasks.map(removedWithComponent => removedWithComponent.component),
  );

  if (!hasMore && (hasOverdueTasks || hasTodayTasks || hasUpcomingTasks)) {
    componentList.push(
      <GroupMarker
        taskGroupType={TaskGroups.COMPLETED_END}
        data-testid="task-group-completed-end"
        key="task-group-completed-end"
      >
        {taskGroupLabelForType()}
      </GroupMarker>,
    );
  }

  return (
    <TaskListContainer
      data-testid="open-task-list"
      data-queryinflight={loading}
    >
      {componentList}
    </TaskListContainer>
  );
};
