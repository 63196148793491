import { FlowConditionAppZapierDetails } from './types.flow';

import { asConditionFieldProp } from '~/scenes/Automation/Flows/Actions/Base';
import { asZapierEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import equalsEventPointer from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event/equalsEventPointer';
import cleanedFilename from '~/util/cleanedFilename';
import { ZapierPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ConditionField } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/types.flow';
import { FlowConditionFieldsFragment } from '~/graphql/types';
import { FIELD_TYPE } from '../../constants';

type ConditionType = Extract<
  FlowConditionFieldsFragment,
  {
    __typename: 'Flow_Condition_Event_Contact_App_Zapier_Details';
  }
>;

type ExpectedConditionEventType = Extract<
  ConditionType['event'],
  {
    __typename: 'Flow_Parameter_Event_Contact_App_Zapier_Pointer';
  }
>;

type ExpectedConditionType = ConditionType & {
  event: ExpectedConditionEventType;
};

const isConditionEventAppZapierPointer = (
  event: ConditionType['event'],
): event is ExpectedConditionEventType =>
  event.__typename === 'Flow_Parameter_Event_Contact_App_Zapier_Pointer';

const isExpectedEventCondition = (
  condition: ConditionType,
): condition is ExpectedConditionType =>
  isConditionEventAppZapierPointer(condition.event);

const asFlowConditionAppZapierDetailsProp = (
  conditions: Array<ConditionType>,
  allShouldBeTrue: boolean,
): Omit<FlowConditionAppZapierDetails, 'id'> => {
  let eventParameter: ZapierPointerParameterValue | null = null;
  const fields: Array<ConditionField> = [];

  conditions.forEach(condition => {
    if (!isExpectedEventCondition(condition)) return;
    const { event } = condition;
    const newEvent = asZapierEventParameterValue(event);
    if (
      eventParameter != null &&
      !equalsEventPointer(eventParameter, newEvent)
    ) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | different events in the conditions (${JSON.stringify(
          conditions,
          null,
          2,
        )})`,
      );
    }
    eventParameter = newEvent;

    let fieldType: FIELD_TYPE.STRING | FIELD_TYPE.NUMBER | FIELD_TYPE.BOOLEAN;
    switch (condition.condition.__typename) {
      case 'Flow_ConditionPrimitive_Boolean_Eq':
        fieldType = FIELD_TYPE.BOOLEAN;
        break;
      case 'Flow_ConditionPrimitive_Number_Eq':
      case 'Flow_ConditionPrimitive_Number_Gt':
      case 'Flow_ConditionPrimitive_Number_Lt':
        fieldType = FIELD_TYPE.NUMBER;
        break;
      case 'Flow_ConditionPrimitive_String_Contains':
      case 'Flow_ConditionPrimitive_String_EndsWith':
      case 'Flow_ConditionPrimitive_String_Eq':
      case 'Flow_ConditionPrimitive_String_StartsWith':
        fieldType = FIELD_TYPE.STRING;
        break;
    }
    const setField = asConditionFieldProp(
      // @TODO label would need to be extracted from the event output in the stash. But we don't have access to that at this point yet. SAD :((((
      // We can just leave the label empty here since it will be filled once it gets down to the condition details component. (It is hacky but it would work)
      // @ts-ignore
      {
        name: `metadata.${condition.metadata}`,
        // label: condition.metadata,
        type: fieldType,
      },
      condition.condition,
    );
    fields.push(setField);
  });
  if (eventParameter == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | no event found in conditions (${JSON.stringify(
        conditions,
        null,
        2,
      )})`,
    );
  }

  return {
    type: 'Flow_Condition_Event_Contact_App_Zapier_Details',
    fields,
    event: eventParameter,
    allShouldBeTrue,
  };
};

export default asFlowConditionAppZapierDetailsProp;
