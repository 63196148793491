import React, { useState, useEffect } from 'react';

import styled, { css } from 'styled-components';

import TEST_ID from './SearchInput.testid';
import useDebounce from '~/hooks/useDebounce';
import Icon from '../Icon';
import Input from '../Input';

type Props = {
  className?: string;

  /** Should be input disabled or not */
  disabled?: boolean;

  /** get access to input value */
  onFilterChange: (text: string) => void;

  /** initial search input */
  initialInput?: string;
  placeholder?: string;
};

const text = {
  placeholder: 'Zoek...',
  search: 'Zoek',
};

const SearchInput = ({
  disabled,
  onFilterChange,
  initialInput,
  placeholder,
  className,
}: Props) => {
  const [value, setValue] = useState(initialInput);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    if (debouncedValue || debouncedValue === '') {
      onFilterChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue !== value]);

  return (
    <Container className={className}>
      <StyledSearchIcon name="looking-glass" />
      <StyledInput
        name="search"
        value={value || ''}
        type="search"
        placeholder={placeholder ?? text.placeholder}
        disabled={disabled}
        onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
          const { value } = event.target as HTMLInputElement;

          setValue(value);
        }}
        data-testid={TEST_ID.INPUT}
      />
    </Container>
  );
};

type ContainerProps = {
  disabled?: boolean;
};

const StyledInput = styled(Input)`
  border: none;
  background: transparent;
`;

const BORDER_WIDTH = 1;
export const Container = styled.label<ContainerProps>`
  display: flex;
  max-width: 350px;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    border: ${BORDER_WIDTH}px solid ${theme.color('grey')};
    border-radius: ${theme.getTokens().border.radius.s};
  `};

  svg {
    margin-right: -0.1em;
  }
  input {
    background-color: transparent;
    transition: border-color 0.2s ease-in-out;

    @media (min-width: 400px) {
      min-width: 220px;
    }

    &:hover,
    &:focus {
      ${({ theme }) => css`
        border-color: ${theme.color('grey')};
      `};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `};
`;

const StyledSearchIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
    padding-left: ${theme.space('s')};
    & > svg {
      font-size: ${theme.fontSize('m')};
    }
  `};
`;

export default SearchInput;
