import { Warning } from '~/hooks/useEntityWarnings';
import { SNOOZED_WARNINGS } from '~/util/sessionStorageKeys';
import getSnoozedWarnings from '../getSnoozedWarnings';
import SnoozedWarningType from '../types';

const snoozeWarning = (warning: Warning) => {
  const snoozedWarningHashes: SnoozedWarningType = getSnoozedWarnings();

  const atm = new Date().toISOString();
  snoozedWarningHashes[warning.hash] = atm;

  writeSnoozedWarnings(snoozedWarningHashes);
};

const writeSnoozedWarnings = (warnings: SnoozedWarningType) =>
  global.window.sessionStorage.setItem(
    SNOOZED_WARNINGS,
    JSON.stringify(warnings),
  );

export default snoozeWarning;
