import gql from 'graphql-tag';

import BillingDetailsFields from '../fragments/BillingDetailsFields';

export default gql`
  mutation UpdateBillingDetails(
    $accountId: ID!
    $billingDetails: BillingDetails_New!
  ) {
    updateBillingDetails(
      accountId: $accountId
      billingDetails: $billingDetails
    ) {
      ...BillingDetailsFields
    }
  }

  ${BillingDetailsFields}
`;
