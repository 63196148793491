import React from 'react';

import {
  TaskListTaskFieldsFragment,
  GetMyTasksQuery as GetMyTasksQueryResult,
  GetMyTasksQueryVariables,
} from '~/graphql/types';
import { TaskListType } from '~/scenes/Tasks/util/taskListType';
import { TaskGetterChildProps } from './TaskWithSubscriptionGetter';

import { GetMyTasksWithSubscriptionQuery } from '~/graphql';
import getMyTaskQuery from '~/graphql/query/GetMyTasks';
import mutatedTaskSubscription from '~/graphql/subscriptions/MutatedTask';
import { Loading } from '~/components';
import AppErrorScreen, {
  UnauthorizedAppErrorScreen,
} from '~/components/AppErrorScreen';
import { filteredAndMappedTasks, TASK_LIST_LIMIT } from '.';
import updateQueryCacheOnUpdateOrInsert from './util/updateQueryCacheOnUpdateOrInsert';
import baseQueryVariablesForListType from './util/baseQueryVariablesForListType';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import cleanedFilename from '~/util/cleanedFilename';
import ErrorTypes from '~/ErrorTypes';
import { getErrorType } from '~/util/errorHandling';

type Props = {
  accountId: string;
  filteredUserId: string;
  selectedListType: TaskListType;
  children: (props: TaskGetterChildProps) => JSX.Element;
};
type State = {};
class GetMyTasks extends React.Component<Props, State> {
  render() {
    const { accountId, filteredUserId, selectedListType, children } =
      this.props;

    const listTypeQueryVariables =
      baseQueryVariablesForListType(selectedListType);

    const baseVariables = {
      ...listTypeQueryVariables,
      accountId,
      limit: TASK_LIST_LIMIT,
      unassigned: true,
    };

    return (
      <GetMyTasksWithSubscriptionQuery
        name={cleanedFilename(__filename)}
        query={getMyTaskQuery}
        variables={baseVariables}
        subscriptionQuery={mutatedTaskSubscription}
        subscriptionVariables={{
          accountId,
        }}
        updateQuery={(previousResult, { subscriptionData }) => {
          // If the mutation errors out the previousResult is undefined
          if (previousResult == null) {
            return previousResult;
          }

          const { mutatedTask } = subscriptionData.data;

          const { items, nextToken } = previousResult.getMyTasks;
          const hasMore = Boolean(nextToken);

          const newList = updateQueryCacheOnUpdateOrInsert(
            items,
            mutatedTask, // We have to use 'this' to make sure this is always the latest reference. The SubscribedQuery component does not rerender on the selectedListType prop change
            this.props.selectedListType,
            hasMore,
          );

          return {
            ...previousResult,
            getMyTasks: {
              ...previousResult.getMyTasks,
              items: newList,
            },
          };
        }}
        doNotReportErrorTypes={[ErrorTypes.unauthorisedError]}
      >
        {({ networkStatus, data, error, fetchMore, updateQuery }) => {
          if (isInitialLoadStatus(networkStatus)) return <Loading />;
          if (error && getErrorType(error) === ErrorTypes.unauthorisedError)
            return <UnauthorizedAppErrorScreen />;

          if (!data || error) {
            return <AppErrorScreen />;
          }

          const { items, nextToken } = data.getMyTasks;
          const loadMore = () =>
            fetchMore({
              variables: {
                ...baseVariables,
                nextToken: nextToken || null,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previousResult;

                const prevData = previousResult.getMyTasks;
                const newData = fetchMoreResult.getMyTasks;

                return {
                  ...previousResult,
                  getMyTasks: {
                    ...prevData,
                    items: [...prevData.items, ...(newData.items || [])],
                    nextToken: newData.nextToken,
                  },
                };
              },
            });

          return children({
            loadMore,
            error,
            networkStatus,
            hasMore: Boolean(nextToken),
            tasks: filteredAndMappedTasks(items, filteredUserId, null),
            onTaskChanged: (
              insertedOrUpdatedTask: TaskListTaskFieldsFragment,
            ) => {
              updateQuery(
                getMyTasksUpdateQueryFunction(
                  insertedOrUpdatedTask,
                  selectedListType,
                  nextToken,
                ),
              );
            },
          });
        }}
      </GetMyTasksWithSubscriptionQuery>
    );
  }
}

const newListForMyTasks = (
  previousResult: GetMyTasksQueryResult,
  insertedOrUpdatedTask: TaskListTaskFieldsFragment | null | undefined,
  selectedListType: TaskListType,
  hasMore: boolean,
): GetMyTasksQueryResult => {
  const { items } = previousResult.getMyTasks;

  const newList = updateQueryCacheOnUpdateOrInsert(
    items,
    insertedOrUpdatedTask,
    selectedListType,
    hasMore,
  );

  return {
    ...previousResult,
    getMyTasks: { ...previousResult.getMyTasks, items: newList },
  };
};

const getMyTasksUpdateQueryFunction =
  (
    insertedOrUpdatedTask: TaskListTaskFieldsFragment,
    selectedListType: TaskListType,
    nextToken: string | null | undefined,
  ): ((
    previousQueryResult: GetMyTasksQueryResult,
    options: { variables: GetMyTasksQueryVariables },
  ) => GetMyTasksQueryResult) =>
  previousResult => {
    const hasMore = Boolean(nextToken);

    const newList = newListForMyTasks(
      previousResult,
      insertedOrUpdatedTask,
      selectedListType,
      hasMore,
    );

    return newList;
  };

export default GetMyTasks;
