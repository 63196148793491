import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { H2 } from '~/components/Typography';

const MAX_PROMO_CONTENT_WIDTH = '400px';
const LARGE_PADDING = 64;

export const BlueWrapper = styled.aside<{}>`
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 0;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  transition: width 0.1s linear;

  ${({ theme }) => css`
    background: ${theme.color('primary')};
    color: ${theme.color('white')};
    box-shadow: 20px 0 20px ${rgba(theme.color('text'), 0.15)};

    ${theme.mq.greaterThan('tablet')` 
    flex: 1 0 50%;
    padding: ${theme.space('xl')} ${LARGE_PADDING}px;
  `}
  `};

  & > * {
    text-align: center;
  }
`;

export const TextWrapper = styled.div<{}>`
  max-width: ${MAX_PROMO_CONTENT_WIDTH};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & p {
    margin-bottom: 0px;
  }

  @media screen and (min-height: 650px) {
    justify-content: center;

    & p {
      margin-bottom: 40px;
    }
  }

  h3 {
    margin-bottom: 0;
  }
`;

export const H2White = styled(H2)<{}>`
  margin-bottom: 0;

  ${({ theme }) => `
    color: ${theme.color('white')};
  `};
`;

export const LogoImage = styled.div<{}>`
  flex: 0;
  margin-bottom: auto;

  & svg {
    width: 110px;
    height: 60px;

    ${({ theme }) => `
      fill: ${theme.color('white')};
    `};
  }
`;

export const HeroImage = styled.img<{}>`
  max-width: ${MAX_PROMO_CONTENT_WIDTH};
  display: none;

  @media screen and (min-height: 650px) {
    display: block;
    margin-top: auto;

    ${({ theme }) => `
      margin-bottom: ${theme.space('xl')};
    `};
  }
`;

export const LockWrapper = styled.div<{}>`
  max-width: ${MAX_PROMO_CONTENT_WIDTH};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  a {
    ${({ theme }) => `
      color: ${theme.color('white')};
    `};
  }
`;
