import React from 'react';
import { Props } from '..';

const Indent: React.FC<Props> = ({
  color = 'currentColor',
  ...props
}: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4977_43810)">
      <path
        d="M21 10H9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6H3"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14H9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 18H3"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49999 12.2427L2.12131 9.12137L2.12131 15.364L6.49999 12.2427Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4977_43810">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Indent;
