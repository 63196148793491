import {
  getLocalStorageItem,
  INITIAL_SUBSCRIPTION,
  setLocalStorageItem,
} from '~/util/localStorageKeys';
import { BillingCycle, Plan } from '~/graphql/types';
import isPlan from '~/scenes/Settings/Subscription/util/isPlan';
import isBillingCycle from '~/scenes/Settings/Subscription/util/isBillingCycle';
import { where } from 'ramda';
import qs from 'query-string';
import { useLocation } from '@reach/router';
import cleanedFilename from '~/util/cleanedFilename';
import { reportErrorToTracker } from '~/util/assertion';
import Validation from '~/util/Validation';

export type InitialSubscriptionConfig = {
  plan: Plan;
  billingCycle: BillingCycle;
  couponCode?: string;
};

export type InitialSubscription = {
  plan: Plan;
  billingCycle: BillingCycle;
  couponCode?: string;
};

const isNotSetOrIsString = (value: any): value is string | null | undefined =>
  value == null || Validation.String.isString(value);

export const isValidInitialSubscriptionConfig = (
  value: any,
): value is InitialSubscriptionConfig =>
  where(
    {
      plan: isPlan,
      billingCycle: isBillingCycle,
      couponCode: isNotSetOrIsString,
    },
    value,
  );

export const DEFAULT_INITIAL_SUBSCRIPTION: InitialSubscription = {
  plan: Plan.Pro,
  billingCycle: BillingCycle.Yearly,
  couponCode: undefined,
};

const useInitialSubscription = (): {
  isPreset: boolean;
  initialSubscription: InitialSubscription;
} => {
  const location = useLocation();
  const parsedFromQuery = qs.parse(location.search);

  const initialSubscriptionConfigInURL = {
    plan: parsedFromQuery.plan,
    billingCycle: parsedFromQuery.billingCycle,
    couponCode: parsedFromQuery.couponCode,
  };

  if (isValidInitialSubscriptionConfig(initialSubscriptionConfigInURL)) {
    setLocalStorageItem(
      INITIAL_SUBSCRIPTION,
      JSON.stringify(initialSubscriptionConfigInURL),
    );
  }
  const localStorageItem = getLocalStorageItem(INITIAL_SUBSCRIPTION);
  if (localStorageItem == null) {
    return {
      isPreset: false,
      initialSubscription: DEFAULT_INITIAL_SUBSCRIPTION,
    };
  }

  try {
    const deserializedLocalStorageEntry = JSON.parse(localStorageItem);
    if (!isValidInitialSubscriptionConfig(deserializedLocalStorageEntry)) {
      return {
        isPreset: false,
        initialSubscription: DEFAULT_INITIAL_SUBSCRIPTION,
      };
    }

    return {
      isPreset: true,
      initialSubscription: deserializedLocalStorageEntry,
    };
  } catch (error) {
    reportErrorToTracker(
      `${cleanedFilename(
        __filename,
      )} | Malformed initial subscription config in localstorage: ${localStorageItem}.`,
    );
  }
  return {
    isPreset: false,
    initialSubscription: DEFAULT_INITIAL_SUBSCRIPTION,
  };
};

export default useInitialSubscription;
