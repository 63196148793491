import { Flow_Parameter__TemplateString__Input } from '~/graphql/types';
import { TemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { asFlowParameterMappingInput } from '../../FlowParameterMapping';
import { hasNonUploadedImagesInHtml } from '~/components/HTMLEditor/util';
import cleanedFilename from '~/util/cleanedFilename';

const asTemplateStringParameterValueInput = (
  parameterValue: TemplateStringParameterValue,
  validateHtmlForBackend?: boolean,
): Flow_Parameter__TemplateString__Input => {
  const { template, mappings } = parameterValue;

  if (validateHtmlForBackend === true) {
    const hasError = hasNonUploadedImagesInHtml(template);
    if (hasError) {
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | The html ${template} contains non uploaded images!`,
      );
    }
  }

  return {
    TemplateString: {
      template: template === '' ? null : template,
      mappings: mappings.map(mapping => asFlowParameterMappingInput(mapping)),
    },
  };
};

export default asTemplateStringParameterValueInput;
