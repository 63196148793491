import { TypedField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ConditionField } from './types.flow';
import {
  FlowConditionStringPrimitiveFieldsFragment,
  FlowConditionBooleanPrimitiveFieldsFragment,
  FlowConditionNumberPrimitiveFieldsFragment,
} from '~/graphql/types';

import { asConditionStringFieldProp } from './String';
import { asConditionBooleanFieldProp } from './Boolean';
import { asConditionNumberFieldProp } from './Number';
import { FIELD_TYPE } from '../../constants';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

type ConditionType =
  | FlowConditionStringPrimitiveFieldsFragment
  | FlowConditionBooleanPrimitiveFieldsFragment
  | FlowConditionNumberPrimitiveFieldsFragment;
const asConditionFieldProp = (
  field: TypedField,
  condition: ConditionType,
): ConditionField => {
  switch (condition.__typename) {
    case 'Flow_ConditionPrimitive_String_Contains':
    case 'Flow_ConditionPrimitive_String_EndsWith':
    case 'Flow_ConditionPrimitive_String_Eq':
    case 'Flow_ConditionPrimitive_String_StartsWith': {
      if (field.type === FIELD_TYPE.STRING) {
        return asConditionStringFieldProp(field, condition);
      } else {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | condition of type (${
            condition.__typename
          }) does not correspond with field of type (${field.type})`,
        );
      }
    }
    case 'Flow_ConditionPrimitive_Boolean_Eq': {
      if (field.type === FIELD_TYPE.BOOLEAN) {
        return asConditionBooleanFieldProp(field, condition);
      } else {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | condition of type (${
            condition.__typename
          }) does not correspond with field of type (${field.type})`,
        );
      }
    }
    case 'Flow_ConditionPrimitive_Number_Lt':
    case 'Flow_ConditionPrimitive_Number_Eq':
    case 'Flow_ConditionPrimitive_Number_Gt': {
      if (field.type === FIELD_TYPE.NUMBER) {
        return asConditionNumberFieldProp(field, condition);
      } else {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | condition of type (${
            condition.__typename
          }) does not correspond with field of type (${field.type})`,
        );
      }
    }

    default:
      return assertNever(condition, cleanedFilename(__filename));
  }
};

export default asConditionFieldProp;
