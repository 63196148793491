import React, { Component } from 'react';
import { FORM_ERROR } from 'final-form';
import MetaTags from 'react-meta-tags';
import Amplify from '../amplify';
import AuthWrapperHOC from '~/scenes/Auth/components/AuthWrapperHOC';
import AuthNav from '~/scenes/Auth/components/AuthNav';
import SetupUserDetailsForm from '~/scenes/Auth/components/SetupUserDetailsForm';

import { AuthContextConsumer } from '~/scenes/Auth/components/AuthContext';
import { H1 } from '../components/Typography';
import { AuthForm, ErrorMsg } from '~/scenes/Auth/Auth.style';
import withDelayedLoading, {
  WithDelayedLoading,
} from '~/components/util/withDelayedLoading';
import Catalog from '~/Catalog';
import { deepEquals } from '~/util/object';
import { reportErrorToTracker } from '~/util/assertion';
import { navigate, PageProps } from 'gatsby';
import { AuthContext } from '~/root/Auth';

export type FormData = {
  name: string | null;
  email: string | null;
  password: string | null;
  passwordRepeat: string | null;
  phone: string | null;
};

type Props = WithDelayedLoading & PageProps<any, any, { email?: string }>;

export type PasswordConditions = {
  charNumberValid: boolean;
  uppercaseValid: boolean;
  lowercaseValid: boolean;
  specialCharValid: boolean;
  numberValid: boolean;
};
type State = {
  email: string | null;
  routeError: boolean;
  passwordConditions: PasswordConditions;
};

const defaultState: State = {
  email: null,
  routeError: false,
  passwordConditions: {
    charNumberValid: false,
    uppercaseValid: false,
    lowercaseValid: false,
    specialCharValid: false,
    numberValid: false,
  },
};

const text = {
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
  pageTitle: 'Vul je gegevens in',
};

class SetupUserDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { location } = props;

    if (!location.state || !location.state.email) {
      this.state = {
        ...defaultState,
        routeError: true,
      };
    } else this.state = { ...defaultState, email: location.state.email };
  }

  onValidate = (passwordConditions: PasswordConditions) => {
    if (!deepEquals(passwordConditions, this.state.passwordConditions)) {
      this.setState((state: State) => ({
        passwordConditions: {
          ...state.passwordConditions,
          ...passwordConditions,
        },
      }));
    }
  };

  render() {
    const { removeLoading, setLoading } = this.props;
    const { passwordConditions, routeError, email } = this.state;
    const title = text.pageTitle;

    return (
      <AuthWrapperHOC>
        <MetaTags>
          <title>{title}</title>
        </MetaTags>

        {!routeError && <AuthNav showBackLink={true} />}

        <AuthForm>
          <H1>{title}</H1>
          <AuthContext.Consumer>
            {({ ensureLogin, tmpUser }) => (
              <AuthContextConsumer>
                {() => {
                  const onFormSubmit = ({
                    name,
                    password,
                    phone,
                  }: FormData) => {
                    setLoading();
                    Amplify.Auth.completeNewPassword(tmpUser, password || '', {
                      name,
                      'custom:phone': phone,
                    })
                      .then(() => {
                        ensureLogin();
                        void navigate('/-/');
                      })
                      .catch(err => {
                        removeLoading();
                        reportErrorToTracker(err);
                        return {
                          [FORM_ERROR]: text.genericErrorMessage,
                        };
                      });
                  };

                  const hasUnknownError = !tmpUser || routeError;

                  return (
                    <>
                      {hasUnknownError ? (
                        <ErrorMsg data-testid="setup-user-error-message">
                          {text.genericErrorMessage}
                        </ErrorMsg>
                      ) : null}
                      <SetupUserDetailsForm
                        disabled={hasUnknownError}
                        passwordConditions={passwordConditions}
                        onFormSubmit={onFormSubmit}
                        onValidate={this.onValidate}
                        initialValues={{
                          name: null,
                          password: null,
                          passwordRepeat: null,
                          phone: null,
                          email,
                        }}
                        loading={this.props.loading}
                      />
                    </>
                  );
                }}
              </AuthContextConsumer>
            )}
          </AuthContext.Consumer>
        </AuthForm>
      </AuthWrapperHOC>
    );
  }
}

export default withDelayedLoading(SetupUserDetails);
