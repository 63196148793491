import { FlowAction, TaskType } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import { assertNeverWithoutThrowing } from '~/util/assertion';
import { FlowConditionConnector } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/components/OperatorDropdown';
import generateActionId from '../../utils/generateActionId';

export type GetActionOpts = {
  flowBlueprintId: string;
  accountId: string;
  actionType: ClientFlowAction['actionType'];
};

export const EMPTY_START_ACTION_ID = 'empty_trigger_start_action';

export const getActionTemplate = ({
  flowBlueprintId,
  accountId,
  actionType,
}: GetActionOpts): ClientFlowAction | null => {
  const commonProperties = {
    id: generateActionId(),
    _v: 1,
    parentIds: [],
    accountId,
    flowBlueprintId,
  };

  switch (actionType) {
    case FlowAction.ContactDetails:
      return {
        ...commonProperties,
        __typename: 'FlowV2_Action_Contact_Details',
        actionType: FlowAction.ContactDetails,
        field: {
          __typename: 'FlowV2_Action_Contact_Details_Field',
          name: {
            __typename: 'FlowV2_TemplateString',
            mappings: [],
            template: '',
          },
          phone: {
            __typename: 'FlowV2_TemplateString',
            mappings: [],
            template: '',
          },
        },
      };

    case FlowAction.ContactAddTag:
      return {
        ...commonProperties,
        __typename: 'FlowV2_Action_Contact_AddTag',
        actionType: FlowAction.ContactAddTag,
        name: '',
      };

    case FlowAction.ContactDeleteTag:
      return {
        ...commonProperties,
        __typename: 'FlowV2_Action_Contact_DeleteTag',
        actionType: FlowAction.ContactDeleteTag,
        name: '',
      };

    case FlowAction.Wait:
      return {
        ...commonProperties,
        __typename: 'FlowV2_Action_Wait',
        actionType: FlowAction.Wait,
        condition: {
          __typename: 'FlowV2_ConditionList',
          exp: '(0)',
          conditions: [],
        },
        triggers: [
          {
            id: 'empty_trigger_wait_action',
            trigger: null,
            condition: {
              conditions: [],
              connector: FlowConditionConnector.And,
            },
          },
        ],
        connector: FlowConditionConnector.And,
      };

    case FlowAction.IfElse:
      return {
        ...commonProperties,
        __typename: 'FlowV2_Action_IfElse',
        actionType: FlowAction.IfElse,
        condition: {
          __typename: 'FlowV2_ConditionList',
          exp: '(0)',
          conditions: [],
        },
        triggers: [
          {
            id: 'empty_trigger_ifelse_action',
            trigger: null,
            condition: {
              conditions: [],
              connector: FlowConditionConnector.And,
            },
          },
        ],
        connector: FlowConditionConnector.And,
        falseChildId: null,
        trueChildId: null,
      };

    case FlowAction.ContactAssign:
      return {
        ...commonProperties,
        __typename: 'FlowV2_Action_Contact_Assign',
        actionType: FlowAction.ContactAssign,
        office: '',
        overwriteAssignee: false,
      };

    case FlowAction.SendEmailPlain:
      return {
        ...commonProperties,
        __typename: 'FlowV2_Action_SendEmail_Plain',
        actionType: FlowAction.SendEmailPlain,
        subject: {
          __typename: 'FlowV2_TemplateString',
          mappings: [],
          template: '',
        },
        isTransactional: false,
        body: {
          __typename: 'FlowV2_TemplateString',
          mappings: [],
          template: '',
        },
        attachments: [],
      };

    case FlowAction.TaskCreate:
      return {
        ...commonProperties,
        __typename: 'FlowV2_Action_Task_Create',
        actionType: FlowAction.TaskCreate,
        assignToContactOwner: true,
        title: {
          __typename: 'FlowV2_TemplateString',
          mappings: [],
          template: '',
        },
        description: {
          __typename: 'FlowV2_TemplateString',
          mappings: [],
          template: '',
        },
        type: TaskType.Call,
        assignedToOffice: '',
      };

    case FlowAction.ZapierTrigger:
      return {
        ...commonProperties,
        actionType: FlowAction.ZapierTrigger,
        __typename: 'FlowV2_Action_Zapier_Trigger',
        zapierTriggerId: '',
        mappings: [],
      };

    case FlowAction.RealworksSendContact:
      return {
        ...commonProperties,
        actionType: FlowAction.RealworksSendContact,
        __typename: 'FlowV2_Action_Realworks_SendContact',
        description: {
          __typename: 'FlowV2_TemplateString',
          mappings: [],
          template: '',
        },
      };

    case FlowAction.Start:
      return {
        ...commonProperties,
        __typename: 'FlowV2_Action_Start',
        actionType: FlowAction.Start,
        conditionList: {
          __typename: 'FlowV2_ConditionList',
          exp: '(0)',
          conditions: [],
        },
        triggers: [
          {
            id: EMPTY_START_ACTION_ID,
            trigger: null,
            condition: {
              conditions: [],
              connector: FlowConditionConnector.And,
            },
          },
        ],
        connector: FlowConditionConnector.And,
      };

    default:
      assertNeverWithoutThrowing(actionType, 'getActionTemplate');
      return null;
  }
};
