import gql from 'graphql-tag';

export default gql`
  mutation DeleteOffice(
    $accountId: ID!
    $officeId: ID!
    $changeSet: [TransferResourcesChangeSet!]!
    $transferOfficeResourcesTo: ReassignEntityInput
  ) {
    deleteOffice(
      accountId: $accountId
      officeId: $officeId
      changeSet: $changeSet
      transferOfficeResourcesTo: $transferOfficeResourcesTo
    ) {
      __typename
      success
      error {
        lockedByUserId
        reason
      }
    }
  }
`;
