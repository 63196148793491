import { Flow_Parameter__File__Input } from '~/graphql/types';
import { FileParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import cleanedFilename from '~/util/cleanedFilename';

const asFileParameterValueInput = (
  parameterValue: FileParameterValue,
): Flow_Parameter__File__Input => {
  switch (parameterValue.type) {
    case PARAMETER_VALUE_TYPE.FILE_PRIMITIVE: {
      const { contentLength, filename, s3key } = parameterValue;

      if (s3key == null || filename == null || contentLength == null) {
        throw new Error(
          `Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no null fields`,
        );
      }

      return {
        File: {
          Primitive: {
            contentLength,
            filename,
            s3key,
          },
        },
      };
    }
    case PARAMETER_VALUE_TYPE.FILE_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }

      return {
        File: {
          Pointer: {
            variable: parameterValue.variable.name,
            field: parameterValue.variable.field.name,
          },
        },
      };
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Field value input conversion cannot handle (${JSON.stringify(
          parameterValue,
          null,
          2,
        )})`,
      );
  }
};

export default asFileParameterValueInput;
