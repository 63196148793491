import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import TaskModalContext from '../TaskModalContext';
import ContactDetails from './ContactDetails';
import query from '~/graphql/query/GetContactInTask';
import { GetContactInTaskQuery } from '~/graphql/Query';
import { TaskModalContextProps } from '../TaskModalContext';

import Loading from '~/components/Loading';
import ContactSearch from './ContactSearch';
import cleanedFilename from '~/util/cleanedFilename';
import useViewportSize from '~/components/util/useViewportSize';
import { BREAK_POINT } from '~/scenes/Tasks/components/TaskModal/TaskModalComponent';
import { convertServerLeadScoreToFrontendLeadScore } from '~/scenes/Contacts/util/composeContactListData';
import useCurrentAccount from '~/hooks/useCurrentAccount';

type Props = {
  error?: string;
};

const ContactInformation = ({ error }: Props) => {
  const [viewportSize] = useViewportSize();
  const { taskDetails } = useContext<TaskModalContextProps>(TaskModalContext);

  const account = useCurrentAccount();

  return (
    <div data-testid="contact-information">
      {viewportSize.width > BREAK_POINT && <Spacer />}
      <ModalBody>
        {taskDetails !== null && taskDetails?.contactId ? (
          <GetContactInTaskQuery
            name={cleanedFilename(__filename)}
            query={query}
            variables={{
              accountId: account.id,
              contactId: taskDetails.contactId,
            }}
          >
            {({ loading, data }) => {
              if (loading) return <Loading />;

              if (!data) return null;

              const contact = data.getContact;

              if (contact == null) return null;

              const { LeadScore, ...rest } = contact;
              return (
                <ContactDetails
                  contact={{
                    ...rest,
                    leadScore:
                      convertServerLeadScoreToFrontendLeadScore(LeadScore),
                  }}
                />
              );
            }}
          </GetContactInTaskQuery>
        ) : (
          <>
            <ContactSearch error={error} />
          </>
        )}
      </ModalBody>
    </div>
  );
};

const ModalBody = styled.div<{}>`
  min-height: 100%;
  position: relative;

  ${({ theme }) => css`
    padding: ${theme.space('s')} ${theme.space('l')};
  `};
`;

const Spacer = styled.div<{}>`
  min-height: 160px;
`;

export default ContactInformation;
