import gql from 'graphql-tag';

import FlowConditionListFields from './FlowConditionListFields';

export default gql`
  fragment FlowActionWaitFields on Flow_Action_Wait {
    __typename
    id
    accountId
    parentId
    flowBlueprintId
    condition {
      ...FlowConditionListFields
    }
  }

  ${FlowConditionListFields}
`;
