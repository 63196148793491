import gql from 'graphql-tag';

export default gql`
  query GetAppValuationReportRouteAvailablity(
    $accountId: ID!
    $id: ID!
    $route: String!
  ) {
    isAvailable: getAppValuationReportRouteAvailablity(
      accountId: $accountId
      id: $id
      route: $route
    )
  }
`;
