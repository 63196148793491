import React from 'react';
import styled, { css } from 'styled-components';

import { RealworksSendContactAction } from './types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';
import { TemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import {
  withAccountContext,
  WithAccountContext,
} from '~/contexts/AccountContext';

import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import asRealworksSendContactActionInput from './asRealworksSendContactActionInput';
import { FLOW_ACTION_TYPE } from '../constants';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import ActionExplanationHeader from '../ActionExplanationHeader';
import ActionContentContainer from '../ActionContentContainer';
import FloatingLabel from '~/components/FloatingLabel';
import { InputGroup, Dropdown } from '~/components/Inputs';
import { ParameterTemplateStringFieldComponent } from '../Base/FlowParameter/FlowParameterField/FlowParameterTemplateStringField';
import TEST_ID from './RealworksSendContactActionComponent.testid';
import HelpButton from '~/components/Buttons/HelpButton';

import cleanedFilename from '~/util/cleanedFilename';
import { Loading } from '~/components';
import {
  AppStatus_Realworks,
  GetAppStatusRealworksQuery as GetAppStatusRealworksQueryType,
  RealworksTokenStatus,
  useGetAppStatusRealworksQuery,
} from '~/graphql/types';
import { Option } from '~/components/Inputs/Dropdown';
import Catalog from '~/Catalog';

const text = {
  explanation: 'Verstuur naar Realworks',
  descriptionLabel: 'Omschrijving',
  tokenSelectionLabel: 'Vestiging',
  inactiveTokenError:
    'De verbinding met deze Realworks koppeling is verbroken. Selecteer een andere koppeling',
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
  ownerOfTheContact: 'Eigenaar van het contact',
};
type MyProps = ActionSubscriberProps & {
  id: string;
  isHiddenFromView: boolean;
  positionString: string;
  initialActionProps: RealworksSendContactAction;
  data: GetAppStatusRealworksQueryType;
};
type WithoutValidatorProps = WithAccountContext & MyProps;
type Props = WithoutValidatorProps & WithValidatorListProps;
type State = {
  /** To rerender the component you can update this number. Needed to rerender the description */
  renderChangeCounter: number;
  key: string | null;

  initialDescription: TemplateStringParameterValue;
  initialTokenContainerId: string | undefined;

  tokenContainerId: string | undefined;

  variableStashVersion: string | null;

  isValid: boolean;
};
class RealworksSendContactActionComponent extends React.Component<
  Props,
  State
> {
  _isMounted = false;
  variableStash: FlowVariableStash = emptyVariableStash();

  getDescription: () => TemplateStringParameterValue = () =>
    this.props.initialActionProps.description;

  constructor(props: Props) {
    super(props);

    const { initialActionProps, data } = props;

    this.state = {
      renderChangeCounter: 0,
      key: null,

      initialDescription: initialActionProps.description,
      initialTokenContainerId: initialActionProps.tokenContainerId,

      tokenContainerId: initialActionProps.tokenContainerId,

      variableStashVersion: null,

      isValid: RealworksSendContactActionComponent.isValid(
        data.getAppStatusRealworks,
        initialActionProps.tokenContainerId,
      ),
    };
  }
  static isValid = (
    realworksStatus: AppStatus_Realworks | null,
    tokenContainerId?: string,
  ) => {
    if (!realworksStatus || !realworksStatus.enabled) return false;
    const tokenOptions = extractActiveTokenOptions(realworksStatus);

    const selectedOptionIdx = tokenOptions.findIndex(
      option => option.payload === tokenContainerId,
    );
    const hasTokenBecomeInvalid =
      tokenContainerId != null && selectedOptionIdx == -1;

    if (hasTokenBecomeInvalid) return false;
    return true;
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const data = nextProps.data ? nextProps.data : null;

    const isValidState = RealworksSendContactActionComponent.isValid(
      data?.getAppStatusRealworks ?? null,
      prevState.tokenContainerId,
    );
    if (isValidState === prevState.isValid) return null;

    return {
      isValid: isValidState,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { isHiddenFromView } = nextProps;
    const { variableStashVersion: nextVariableStashVersion } = nextState;
    const { variableStashVersion } = this.state;

    if (nextVariableStashVersion !== variableStashVersion) {
      return true;
    }

    return !isHiddenFromView;
  }

  validate = () => {
    const { validate } = this.props;

    // always call validate to make sure components know to show validation
    const componentValidation = validate();

    const { isValid } = this.state;
    if (!isValid) return false;

    return componentValidation;
  };

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asRealworksSendContactActionInput(this.getActionProp(), treeNode, defaults);

  setGetDescription = (
    getDescriptionFunction: () => TemplateStringParameterValue,
  ) => {
    this.getDescription = getDescriptionFunction;
  };

  setVariableStash = (newStash: FlowVariableStash) => {
    this.variableStash = newStash;

    if (this._isMounted) {
      this.setState({
        variableStashVersion: newStash.version,
      });
    }
  };

  getActionProp = (): RealworksSendContactAction => {
    const { id } = this.props;

    return {
      id,
      type: FLOW_ACTION_TYPE.SENDCONTACT_TO_REALWORKS,
      description: this.getDescription(),
      tokenContainerId: this.state.tokenContainerId,
    };
  };

  render() {
    const { initialDescription, tokenContainerId } = this.state;

    const { getAppStatusRealworks: realworksStatus } = this.props.data;

    const tokenOptions = extractActiveTokenOptions(realworksStatus);
    const selectedOptionIdx = tokenOptions.findIndex(
      option => option.payload === tokenContainerId,
    );
    const hasTokenBecomeInvalid =
      tokenContainerId != null && selectedOptionIdx == -1;

    const {
      subscribeValidator,
      unsubscribeValidator,
      announceChanges,
      isHiddenFromView,
      positionString,
    } = this.props;
    const action = this.getActionProp();

    return (
      <BaseActionComponent
        positionString={positionString}
        actionLabel={text.explanation}
        action={action}
        variableStash={this.variableStash}
        subscribeValidator={subscribeValidator}
        unsubscribeValidator={unsubscribeValidator}
        isHiddenFromView={isHiddenFromView}
      >
        <ActionExplanationHeader title={text.explanation} />
        <ActionContentContainer>
          <InputGroup data-testid={TEST_ID.TOKEN_DROPDOWN_INPUT_GROUP}>
            <DropdownContainer>
              {labelComponent}
              <Dropdown
                dataTestid={TEST_ID.TOKEN_DROPDOWN}
                options={tokenOptions}
                error={hasTokenBecomeInvalid ? text.inactiveTokenError : ''}
                selectedOptionIdx={selectedOptionIdx}
                onChange={({ option }) => {
                  this.setState(
                    {
                      tokenContainerId: option.payload,
                    },
                    () => {
                      this.props.announceChanges();
                    },
                  );
                }}
              />
            </DropdownContainer>
          </InputGroup>

          <InputGroup data-testid={TEST_ID.DESCRIPTION}>
            <ParameterTemplateStringFieldComponent
              label={text.descriptionLabel}
              initialTemplateString={initialDescription}
              setGetTemplateComponent={getDescriptionFunction =>
                this.setGetDescription(getDescriptionFunction)
              }
              announceChanges={announceChanges}
              textarea
              keepHtml
            />
          </InputGroup>
        </ActionContentContainer>
      </BaseActionComponent>
    );
  }
}
const DropdownContainer = styled.div<{}>`
  width: 100%;
`;

const LabelContainer = styled.div<{}>`
  display: flex;
  align-items: flex-end;
`;

const StyledHelpButton = styled(HelpButton)<{}>`
  align-self: flex-start;
  margin-bottom: 5px;

  ${({ theme }) => css`
    margin-left: 4px;
    font-size: ${theme.fontSize('base')};
  `};
`;

const labelComponent = (
  <LabelContainer>
    <FloatingLabel>Vestiging</FloatingLabel>
    <StyledHelpButton
      link={{
        link: 'https://help.dathuis.nl/nl/articles/5447060-over-de-realworks-app',
      }}
    />
  </LabelContainer>
);

const extractActiveTokenOptions = (
  realworksStatus: NonNullable<
    GetAppStatusRealworksQueryType['getAppStatusRealworks']
  >,
) => {
  const tokenOptions: Array<Option> = [
    { key: 'Closest', label: text.ownerOfTheContact, payload: undefined },
  ];
  if (!realworksStatus?.enabled) return tokenOptions;
  const activeTokens = realworksStatus.tokens.filter(
    token => token.wonen?.status === RealworksTokenStatus.Active,
  );
  tokenOptions.push(
    ...activeTokens.map(activeToken => ({
      key: activeToken.id,
      label: activeToken.name,
      payload: activeToken.id,
    })),
  );
  return tokenOptions;
};

const RealworksSendContactActionComponentContainerThingyBobby: React.FC<Props> =
  props => {
    const { data, loading, error } = useGetAppStatusRealworksQuery({
      displayName: cleanedFilename(__filename),
      variables: { accountId: props.accountContext.account.id },
    });
    if (loading) return <Loading />;

    if (error || !data) {
      return (
        <InputGroup data-testid={TEST_ID.ERROR_TOKEN_DROPDOWN_INPUT_GROUP}>
          <DropdownContainer>
            {labelComponent}
            <Dropdown
              disabled
              dataTestid={TEST_ID.ERROR_TOKEN_DROPDOWN}
              options={[]}
              error={text.genericErrorMessage}
            />
          </DropdownContainer>
        </InputGroup>
      );
    }

    return <RealworksSendContactActionComponent {...props} data={data} />;
  };

export default withAccountContext<Omit<MyProps, 'data'>>(
  withValidatorList<Omit<WithoutValidatorProps, 'data'>>(
    RealworksSendContactActionComponentContainerThingyBobby,
  ),
);
