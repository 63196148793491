import FroalaEditor from 'froala-editor/js/froala_editor.min.js';
import { reportErrorToTracker } from '~/util/assertion';

FroalaEditor.DefineIcon('addFlowVariableIcon', {
  NAME: 'plus',
  // SVG_KEY: 'codeView',
  PATH: 'M12.1134606,21.1474286 C9.18250818,21.1474286 6.1160392,20.7281551 2.99970013,19.9011266 L2.05,19.6569344 L2.05,19.0740981 L2.05,19.1855973 C2.05,15.7457117 5.34251061,12.9476371 9.38903543,12.9476371 L13.7130982,12.9476371 C15.6699575,12.9476371 17.5124408,13.5972806 18.8995884,14.777236 C20.2878202,15.9571914 21.0510495,17.5227863 21.05,19.186058 C21.05,19.186058 21.05,19.3850977 21.05,19.3850977 C20.5225349,19.6910291 19.9848051,19.9485828 19.3858219,20.1374863 C19.0876854,20.2314773 18.7879227,20.3190179 18.4832815,20.3973437 C17.612181,20.6217241 16.721024,20.7880513 15.821736,20.9078437 C14.5955821,21.0718672 13.3542503,21.1474286 12.1134606,21.1474286 Z M12.1129185,19.7652083 C16.3638023,19.7652083 18.978188,18.8087119 19.4226868,18.6322484 L19.4226868,19.1855973 C19.4237665,17.8909176 18.8291198,16.6727208 17.7487817,15.754005 C16.6684436,14.83575 15.2346782,14.3298574 13.7120141,14.3298574 L9.3879513,14.3298574 C6.23800412,14.3298574 3.67511472,16.5082366 3.67511472,19.1855973 L3.67511472,18.6322484 C6.56812248,19.3841762 9.40638155,19.7652083 12.1129185,19.7652083 Z M11.5502537,12.1915626 C8.29189327,12.1915626 5.64064707,9.9390043 5.64064707,7.17041708 C5.64064707,4.40090837 8.29189327,2.14742857 11.5502537,2.14742857 C14.8091562,2.14742857 17.4604024,4.39998689 17.4604024,7.16857412 C17.4604024,9.93854356 14.8091562,12.1915626 11.5502537,12.1915626 Z M11.5502537,3.52964885 C9.18847091,3.52964885 7.26684593,5.16297249 7.26684593,7.17041708 C7.26684593,9.17694019 9.18847091,10.8093423 11.5502537,10.8093423 C13.9125786,10.8093423 15.8342036,9.17601871 15.8342036,7.16857412 C15.8342036,5.16205101 13.9125786,3.52964885 11.5502537,3.52964885 Z',
});
FroalaEditor.RegisterCommand('addFlowVariablePlugin', {
  title: 'Personaliseer',
  icon: 'addFlowVariableIcon',
  callback: function () {
    if (this.addFlowVariablePlugin == null) {
      reportErrorToTracker(
        new Error(
          'The addFlowVariablePlugin plugin button was added to Froala but the functions were not registered',
        ),
      );
      return;
    }

    const buttonElement = this.$tb.find(
      '.fr-command[data-cmd="addFlowVariablePlugin"]',
    );

    this.addFlowVariablePlugin(buttonElement[0]);
  },
});
