import { FlowConditionAppFundaContactRequestNew } from './types.flow';

const asFlowConditionAppFundaContactRequestNewProp = (): Omit<
  FlowConditionAppFundaContactRequestNew,
  'id'
> => ({
  type: 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New',
});

export default asFlowConditionAppFundaContactRequestNewProp;
