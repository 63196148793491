import { useEffect } from 'react';
import * as Sentry from '@sentry/gatsby';
import { ApolloError } from '@apollo/client';
import { isNil } from 'ramda';
import { Scope } from '@sentry/types';
import { isLiveStage } from '~/util';
import { assertNeverWithoutThrowing } from '~/util/assertion';
import useCurrentUser from '../useCurrentUser';
import useCurrentAccount from '../useCurrentAccount';

type Severity = 'info' | 'debug' | 'warning' | 'critical' | 'fatal';
/**
 * We define our own typing for Reporter so we can potentially switch it in the future without needing to update the usage.
 */
export type Reporter = {
  /**
   * Report Error to tracking system
   */
  captureException: (
    error: Error | ApolloError,
    severity?: Severity,
    captureContext?: (scope: Scope) => Scope,
  ) => void;
  /**
   * Report only as string/message to tracking system
   */
  captureMessage: (message: string, severity: Severity) => void;
};

// When not in a live stage we want the App to crash hard on errors
// so devs and QA can easily tell when stuff is not correct instead of graceful failure in production
const staticReporter: Reporter = {
  captureException: (error, __severity) => {
    // eslint-disable-next-line no-console
    console.error(error);
  },
  captureMessage: (message, __severity) => {
    // eslint-disable-next-line no-console
    console.error(new Error(message));
  },
};

export function setScope(severity: Severity | undefined, scope: Scope): Scope;
export function setScope(
  severity: Severity | undefined,
): (scope: Scope) => Scope;
export function setScope(
  severity: Severity,
  scope?: Scope,
): Scope | ((scope: Scope) => Scope) {
  const setupScope = (scope: Scope) => {
    scope.setLevel(severityToSentryLevel(severity));
    return scope;
  };

  if (!scope) return setupScope;
  return setupScope(scope);
}

export const reporter: Reporter = {
  captureException: (error, severity, captureContext) =>
    Sentry.captureException(error, scope => {
      setScope(severity, scope);
      if (captureContext) {
        captureContext(scope);
      }

      return scope;
    }),
  captureMessage: (error, severity) =>
    Sentry.captureMessage(error, setScope(severity)),
};

const severityToSentryLevel = (severity?: Severity): Sentry.Severity => {
  switch (severity) {
    case 'debug':
      return Sentry.Severity.Debug;
    case 'info':
      return Sentry.Severity.Info;
    case 'warning':
      return Sentry.Severity.Warning;
    case 'critical':
      return Sentry.Severity.Critical;
    case 'fatal':
      return Sentry.Severity.Fatal;
    case undefined:
    case null:
      return Sentry.Severity.Error;
    default:
      assertNeverWithoutThrowing(severity);
      return Sentry.Severity.Error;
  }
};

const useErrorReporter = (): Reporter => {
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();

  useEffect(() => {
    if (!isNil(currentUser)) {
      Sentry.setUser({
        id: currentUser.id,
        username: currentUser.name,
        // Sentry also accepts IP address and email but we don't want to send these over.
      });
    }

    if (!isNil(currentAccount)) {
      Sentry.setExtras({
        accountId: currentAccount.id,
        accountName: currentAccount.name,
      });
    }
  }, [currentUser, currentAccount]);

  if (!isLiveStage()) return staticReporter;

  return reporter;
};

export default useErrorReporter;
