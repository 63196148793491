import React from 'react';
import styled, { css } from 'styled-components';

import {
  OverageItem,
  PricePerOverageItem,
} from '~/scenes/Settings/Subscription/types.flow';

import TEST_ID from './index.testid';
import { asPriceElement } from '~/util/money';
import { OVERAGE_ITEM } from '../../constants';
import OverageItemDetails from '../OverageItemDetails';
import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

const text = {
  total: 'Totaal ',
  tax: 'exclusief btw',
};
type Props = {
  amount: number;
  overages: Array<OverageItem | PricePerOverageItem>;
};
const SubscriptionOveragesInfoBlockDetails = ({ amount, overages }: Props) => (
  <Container data-testid={TEST_ID.CONTAINER}>
    <OveragesContainer>
      {overages.map((overage, idx) => (
        <OverageListItem key={`overage-${idx}`}>
          {overageComponentSwitcher(overage, idx)}{' '}
        </OverageListItem>
      ))}
    </OveragesContainer>
    <TotalAmountContainer>
      <TotalTextContainer>
        <TotalText>{text.total}</TotalText>
        <TaxText>{text.tax}</TaxText>
      </TotalTextContainer>
      <TotalPriceContainer>{asPriceElement(amount)}</TotalPriceContainer>
    </TotalAmountContainer>
  </Container>
);

const overageComponentSwitcher = (
  overage: OverageItem | PricePerOverageItem,
  idx: number,
): React.ReactNode => {
  switch (overage.type) {
    case OVERAGE_ITEM.BASIC: {
      const { name, amount } = overage;

      return (
        <OverageItemDetails
          key={`overage-${idx}`}
          title={name}
          subTitle={null}
          amount={amount}
        />
      );
    }
    case OVERAGE_ITEM.PRICE_PER: {
      const { name, quantity, pricePerUnit, amount } = overage;

      return (
        <OverageItemDetails
          key={`overage-${idx}`}
          title={name}
          subTitle={
            <>
              {quantity} x {asPriceElement(pricePerUnit)}
            </>
          }
          amount={amount}
        />
      );
    }
    case OVERAGE_ITEM.GROUPED: {
      const { name, subItems } = overage;

      return (
        <GroupedOverageContainer key={`overage-${idx}`}>
          <GroupedOverageTitle>{name}</GroupedOverageTitle>
          <GroupedOverageSubItemsContainer>
            {subItems.map(overageComponentSwitcher)}
          </GroupedOverageSubItemsContainer>
        </GroupedOverageContainer>
      );
    }
    default:
      reportErrorToTracker(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Unkown overage (${JSON.stringify(
          overage,
          null,
          2,
        )})`,
      );
      return null;
  }
};

const OverageListItem = styled.div<{}>`
  &:not(:last-child) {
    ${({ theme }) => css`
      border-bottom: 1px solid ${theme.color('grey')};
    `};
  }
`;

const GroupedOverageSubItemsContainer = styled.div<{}>(
  ({ theme }) => css`
    padding-left: ${theme.space('m')};
  `,
);
const GroupedOverageContainer = styled.div<{}>``;
const GroupedOverageTitle = styled.div<{}>`
  font-weight: bold;

  ${({ theme }) => css`
    font-size: ${theme.fontSize('m')};
  `};
`;

const OveragesContainer = styled.div<{}>`
  ${({ theme }) => css`
    > :not(:first-child) {
      margin-top: ${theme.space('s')};
    }
  `};
`;

const Container = styled.div<{}>``;

const TotalAmountContainer = styled.div<{}>`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    border-top: ${theme.getTokens().border.width.base} solid
      ${theme.color('grey')};
    padding-top: ${theme.space('l')};
  `};
`;

const TotalTextContainer = styled.div<{}>``;

const TotalText = styled.span<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('m')};
  `,
);

const TaxText = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('text', 'light')};
  `,
);

const TotalPriceContainer = styled.div<{}>`
  font-weight: bold;
  white-space: nowrap;
`;

export default SubscriptionOveragesInfoBlockDetails;
