import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterAppValuationReportFields on Flow_Parameter__AppValuationReport {
    __typename

    ... on Flow_Parameter_AppValuationReport_Primitive {
      value
    }
  }
`;
