import gql from 'graphql-tag';

export default gql`
  fragment TaskListTaskFields on Task {
    __typename
    _v
    id
    accountId
    category
    createdDate

    source {
      ... on Actor_Contact {
        __typename
        id
        name
      }

      ... on Actor_User {
        __typename
        id
        name
      }

      ... on Actor_App {
        __typename
        name
      }

      ... on Actor_Flow {
        __typename
        flowBlueprintId
        flowBlueprintActionId
      }
    }

    ... on Task_Contact {
      type
      title
      officeId
      userId
      status
      dueDate
      closedDate
      deleted
      deletedDate
      sortingDate: dueDate
      description
      contactId
      Contact {
        id
        name
        email
      }
      closedBy {
        ... on Actor_Contact {
          __typename
          id
          name
        }

        ... on Actor_User {
          __typename
          id
          name
        }

        ... on Actor_App {
          __typename
          name
        }

        ... on Actor_Flow {
          __typename
          flowBlueprintId
          flowBlueprintActionId
        }
      }
    }
  }
`;
