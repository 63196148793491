import gql from 'graphql-tag';

export default gql`
  query GetAppValuationReports($accountId: ID!) {
    getAppValuationReports(accountId: $accountId) {
      __typename

      ... on App_ValuationReport {
        id
        accountId
        name
        officeId
        route
        general {
          backgroundImage
          backgroundColor
        }
      }

      ... on App_PremiumValuationReport {
        id
        name
        algasId
        sendAgentEmail
        sendContactEmail
      }
    }
  }
`;
