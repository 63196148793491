import {
  Flow_Action__Input,
  Flow_ParameterMapping__Input,
} from '~/graphql/types';
import { ZapierTriggerAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import { asFlowParameterMappingInput } from '../Base';

export default (
  action: ZapierTriggerAction,
  treeNode: ActionTreeNode,
  defaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, zapierTriggerId, mappings } = action;

  if (zapierTriggerId == null) {
    throw new Error(
      `ZapierTriggerAction found with no zapierTriggerId! ID: ${id}`,
    );
  }
  const { parentId } = treeNode;
  const { accountId, flowBlueprintId } = defaults;

  if (parentId == null) {
    throw new Error(`Parentless ZapierTriggerAction found! ID: ${id}`);
  }

  const mappingsInput = mappings.reduce<Array<Flow_ParameterMapping__Input>>(
    (prev, mapping) => {
      if (mapping.mapping.variable == null) return prev;

      prev.push(asFlowParameterMappingInput(mapping));

      return prev;
    },
    [],
  );

  return {
    Zapier: {
      Trigger: {
        id,
        accountId,
        flowBlueprintId,
        parentId,
        zapierTriggerId,
        mappings: mappingsInput,
      },
    },
  };
};
