import React from 'react';

import type { LockContactSearchResultsByFilterV2MutationVariables } from '~/graphql/types';

import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';
import AddTags from './Actions/AddTags';
import Delete from './Actions/Delete';
import SendCsv from './Actions/SendCSV';
import { CONTACT_ACTION } from './constants';

export type Delete = 'Delete';
export type AddTags = 'AddTags';
export type SendCSV = 'SendCSV';

export type ContactAction = Delete | AddTags | SendCSV;

export type ActionModalProps = {
  lockContactsVariables: LockContactSearchResultsByFilterV2MutationVariables;
  selectedContactAmount: number;
  onComplete: () => void;
  onCancel: () => void;
};

type Props = {
  actionType: ContactAction;
  actionModalProps: ActionModalProps;
};

const actionSwitcher = ({
  actionType,
  actionModalProps,
}: Props): React.ReactElement | null => {
  switch (actionType) {
    case CONTACT_ACTION.DELETE:
      return <Delete {...actionModalProps} />;

    case CONTACT_ACTION.ADD_TAGS:
      return <AddTags {...actionModalProps} />;

    case CONTACT_ACTION.SEND_CSV:
      return <SendCsv {...actionModalProps} />;

    default:
      reportErrorToTracker(
        `${cleanedFilename(
          __filename,
        )} | Unknown component for action type ${actionType}`,
      );
      return null;
  }
};

export default actionSwitcher;
