import { UserStatus, UserRole } from '~/graphql/types';
import { Admin, User } from './Account.flow';
import getUserName from '~/util/getUserName';
import { UserLookupTable } from '~/hooks/useUserLookup';
import { isNil } from 'ramda';

export type getUsersListProps = {
  usersData: Array<any>;
  userLookupTable: UserLookupTable | null;
};
type getUsersListReturn = {
  admins: Array<Admin>;
  users: Array<User>;
};

export const getUsersList = (props: getUsersListProps): getUsersListReturn => {
  const { usersData, userLookupTable } = props;

  if (userLookupTable === null) return { admins: [], users: [] };

  const admins: Array<Admin> = [];
  const users: Array<User> = [];

  const activeUsers = usersData.filter(
    user => user.relation.status === UserStatus.Active,
  );

  activeUsers.forEach(item => {
    const user = userLookupTable && userLookupTable[item.user.id];
    const userName = !isNil(user) ? getUserName(user) : 'Onbekend';

    if (item.relation.role === UserRole.Admin) {
      admins.push({
        label: userName,
        payload: {
          id: item.user.id,
          role: item.relation.role,
        },
        dataTestid: userName,
      });
    } else {
      users.push({
        label: userName,
        payload: {
          id: item.user.id,
          role: item.relation.role,
        },
        key: userName,
      });
    }
  });

  return {
    admins,
    users,
  };
};
