import React, { HTMLAttributes, useRef } from 'react';
import uuid from 'uuid/v4';
import styled, { css } from 'styled-components';
import { Heading5, Variant } from '~/components/Typography/index';
import TEST_ID from './index.testid';
import JustificationContainer, {
  Props as JustificationContainerProps,
} from '../JustificationContainer';

type Size = 'small' | 'base';
type Sizes = { [key in Size]: SizeChart };
type SizeChart = {
  circle: string;
  container: {
    height: string;
    maxWidth: string;
    borderRadius: string;
  };
};

const sizes: Sizes = {
  base: {
    circle: 'l',
    container: {
      height: 'xl',
      maxWidth: 'xxxxl',
      borderRadius: 'xl',
    },
  },
  small: {
    circle: 'base',
    container: {
      height: 'l',
      maxWidth: 'xxl',
      borderRadius: 'l',
    },
  },
};

export type Props = HTMLAttributes<HTMLInputElement> & {
  dataTestId?: string;
  label?: React.ReactNode;
  value: boolean;
  disabled?: boolean;
  size?: Size;
  justification?: JustificationContainerProps;
};

const ToggleCheckbox: React.FC<Props> = ({
  dataTestId,
  label,
  value,
  size = 'base',
  disabled,
  justification = { direction: 'column' },
  ...rest
}) => {
  const id = useRef(uuid());
  return (
    <Container {...justification} $size={size} $disabled={disabled}>
      {label && (
        <Heading5
          withoutMargin={justification?.direction === 'row'}
          variant={Variant.primary}
        >
          {label}
        </Heading5>
      )}
      <Input
        type="checkbox"
        id={id.current}
        {...rest}
        disabled={disabled}
        checked={value}
        name="checkbox"
      />
      <FakeLabel
        htmlFor={id.current}
        $size={size}
        $disabled={disabled}
        data-testid={dataTestId || TEST_ID.LABEL}
        data-checked={value}
      >
        Toggle
      </FakeLabel>
    </Container>
  );
};

const Input = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const FakeLabel = styled.label<{ $size: string; $disabled?: boolean }>(
  ({ theme, $size, $disabled }) => css`
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    text-indent: -9999px;
    width: 100%;
    max-width: ${theme.space(sizes[$size].container.maxWidth)};
    height: ${theme.space(sizes[$size].container.height)};
    background: ${theme.color('grey')};
    display: block;
    border-radius: ${theme.space(sizes[$size].container.borderRadius)};
    position: relative;
    transition: 0.3s;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - calc(${theme.space(sizes[$size].circle)} / 2));
      left: 10%;
      width: ${theme.space(sizes[$size].circle)};
      height: ${theme.space(sizes[$size].circle)};
      background: ${theme.color('white')};
      border-radius: ${theme.space(sizes[$size].circle)};
      transition: 0.3s;
    }
  `,
);

const Container = styled(JustificationContainer)<{
  $size: string;
  $disabled?: boolean;
}>(
  ({ theme, $size, $disabled }) => css`
    min-width: ${theme.space(sizes[$size].container.maxWidth)};

    ${Input}:checked + ${FakeLabel} {
      background: ${theme.color('primary', 'light')};
      opacity: ${$disabled ? 0.5 : 1};

      &:after {
        left: 90%;
        transform: translateX(-100%);
      }
    }
  `,
);

export default ToggleCheckbox;
