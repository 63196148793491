import asAddress from '../asAddress';

/**
 * Returns property fields as blocks
 * @param {string} x - Example argument
 * keywords:
 */
const getObjectInfo = ({
  street,
  houseNumber,
  addition,
  postcode,
  city,
}: {
  street: string;
  postcode: string;
  city: string;
  houseNumber?: number | string | undefined;
  addition?: string | undefined | null;
}) => [
  {
    __typename: 'MarkdownBlock',
    value: '## Objectgegevens',
  },
  {
    __typename: 'TextBlock',
    label: 'Adres',
    value: asAddress({ street, houseNumber, houseNumberAddition: addition }),
  },
  {
    __typename: 'TextBlock',
    label: 'Postcode',
    value: postcode,
  },
  {
    __typename: 'TextBlock',
    label: 'Woonplaats',
    value: city,
  },
];

export default getObjectInfo;
