import React, { useContext } from 'react';
import { Field } from 'react-final-form';

import { FieldState } from 'final-form';
import { SelectedOption } from '~/components/Inputs/Dropdown';

import FoldableCardBlock from '../FoldableCardBlock';
import { InputGroup } from '~/components/Inputs';
import AccountContext from '~/contexts/AccountContext';
import TEST_ID from './index.testid';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import Dropdown from '~/components/Dropdown';

const text = {
  officeLabel: 'Vestiging',
  userLabel: 'Gebruiker',
  noOfficeLabel: 'Alle vestigingen',
  noUserLabel: 'Alle gebruikers',
};
type Props = {
  /** If the form is disabled */
  isFormDisabled: boolean;
  getFieldState: (arg0: string) => FieldState<any> | null | undefined;
  change: (arg0: string, arg1: any) => void;
};

const ContactSettingsBlock = ({
  isFormDisabled,
  getFieldState,
  change,
}: Props) => {
  const { getUserOptionsForOffice } = useContext(AccountContext);
  const officeFieldState = getFieldState('officeId');
  const userFieldState = getFieldState('userId');

  const officeOptions = useOfficeOptions({
    withAllOfficesOption: officeFieldState?.value === null,
  });

  const userOptions = getUserOptionsForOffice(officeFieldState?.value);

  // if (officeFieldState != null && officeFieldState.value != null) {
  //   // const usersInOffice = usersForOffice(officeFieldState.value);

  //   userOptions = getUserOptionsForOffice(officeFieldState.value);
  //   // [
  //   //   emptyUserOption,
  //   //   ...usersInOffice.map(viewableUser => asDropdownOption(viewableUser)),
  //   // ];
  // }

  // viewableOffices.map(viewableOffice =>
  //   asDropdownOption(viewableOffice),
  // );

  // const officeOptions = dropdownOfficeOptions;
  // if (officeFieldState?.value === null) {
  //   officeOptions.unshift(emptyOfficeOption);
  // }

  return (
    <FoldableCardBlock
      icon="GEAR"
      title="Eigenaar"
      dataTestId="card-block-settings"
    >
      <InputGroup>
        <Field name="officeId">
          {({ input: { onChange } }) => (
            <Dropdown
              disabled={isFormDisabled}
              dataTestId={TEST_ID.ASSIGNED_OFFICE_DROPDOWN}
              label={text.officeLabel}
              onChange={(selectedOption: SelectedOption) => {
                const { option } = selectedOption;
                const payload = option ? option.payload : null;
                const newOfficeId = payload?.id ? payload.id : null;
                onChange(newOfficeId);

                // TODO: What is this ? ( asks philipp )
                if (payload == null) {
                  change('officeId', null);
                }

                const userFieldState = getFieldState('userId');
                if (userFieldState != null) {
                  const userOptions = getUserOptionsForOffice(newOfficeId);

                  if (
                    userOptions.find(
                      user => user.payload?.id == userFieldState.value,
                    ) == null
                  ) {
                    change('userId', null);
                  }
                }
              }}
              selectedOptionIdx={officeOptions.findIndex(officeOption => {
                if (officeFieldState?.value === null)
                  return officeOption.payload === null;
                return officeOption.payload?.id == officeFieldState?.value;
              })}
              options={officeOptions}
            />
          )}
        </Field>
      </InputGroup>
      <InputGroup>
        <Field name="userId">
          {({ input: { onChange } }) => (
            <Dropdown
              disabled={isFormDisabled}
              dataTestId={TEST_ID.ASSIGNED_USER_DROPDOWN}
              label={text.userLabel}
              onChange={(selectedOption: SelectedOption) => {
                const { option } = selectedOption;
                const payload = option ? option.payload : null;
                onChange(payload?.id ? payload.id : null);
              }}
              selectedOptionIdx={userOptions.findIndex(user => {
                if (userFieldState?.value === null)
                  return user.payload === null;
                return user.payload?.id == userFieldState?.value;
              })}
              options={userOptions}
            />
          )}
        </Field>
      </InputGroup>
    </FoldableCardBlock>
  );
};

export default ContactSettingsBlock;
