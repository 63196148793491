import React from 'react';
import styled, { css } from 'styled-components';
import MetaTags from 'react-meta-tags';

import { GetContactQuery } from '~/graphql/Query';
import { GetContactQuery as GetContactQueryType } from '~/graphql/types';

import getContactQuery from '~/graphql/query/GetContact';
import { AppErrorScreen, Loading } from '~/components';

import Validation from '~/util/Validation';
import ErrorTypes from '~/ErrorTypes';
import { getErrorTypes } from '~/util/errorHandling';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import TEST_ID from './index.testid';
import { RouteComponentProps } from '@reach/router';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useMainOffice from '~/hooks/useMainOffice';
import useCurrentUser from '~/hooks/useCurrentUser';
import ActivityTabs from '~/components/ActivityTabs';
import SendMessageTab from '~/components/ActivityTabs/components/SendMessageTab';
import LogActivityTab from '~/components/ActivityTabs/components/LogActivityTab';
import CreateNewTaskTab from '~/components/ActivityTabs/components/CreateNewTaskTab';
import { TaskUpdaterContext } from '~/scenes/Tasks/components/TaskList/TaskUpdaterContext';
import ContactDetailsBlockComponent from '../ContactDetailsBlockComponent';
import ContactsContextProvider from '../../ContactsContext/ContactsContextProvider';
import { EventTimelineWithTaskModal } from '~/components/EventTimelineV2';
import useUpdateTimelineFn from '~/hooks/useUpdateTimelineFn';

export const text = {
  addLogTabLabel: Catalog.addLogTabLabel,
  pageTitle: 'Contact',
  contactListTitle: 'Contactenlijst',
  noContactFound: 'Geen contact gevonden',
  addTaskLabel: Catalog.addTaskTabLabel,
  errorTitle: 'Geen data gevonden',
};
type Props = RouteComponentProps<{ contactId: string }>;
const Contact: React.FC<Props> = ({ contactId }) => {
  const account = useCurrentAccount();

  const queryVariables = {
    accountId: account.id,
    contactId: contactId as string,
  };

  return (
    <GetContactQuery
      name={cleanedFilename(__filename)}
      query={getContactQuery}
      variables={queryVariables}
      doNotReportErrorTypes={[
        ErrorTypes.invalidContactId,
        ErrorTypes.notFoundError,
      ]}
    >
      {({ loading, data, error }) => {
        if (loading) return <Loading />;

        const errorComponent = getErrorComponent(error, data);
        if (errorComponent != null) {
          return errorComponent;
        }

        if (data == null || data.getContact == null) {
          throw Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | data is null, getErrorComponent should have found that`,
          );
        }

        const { getContact } = data;

        return <RenderingComponent getContact={getContact} />;
      }}
    </GetContactQuery>
  );
};

const getErrorComponent = (error, data): JSX.Element | null => {
  if (error != null) {
    const errorTypes = getErrorTypes(error);
    if (
      errorTypes.includes(ErrorTypes.invalidContactId) ||
      errorTypes.includes(ErrorTypes.notFoundError)
    ) {
      return <AppErrorScreen message={text.noContactFound} />;
    } else {
      return <AppErrorScreen />;
    }
  }

  if (!data || !data.getContact) {
    return <AppErrorScreen />;
  }

  if (data.getContact.status === 'DELETING') {
    return <AppErrorScreen message={text.noContactFound} />;
  }

  return null;
};

type RenderingProps = {
  getContact: NonNullable<GetContactQueryType['getContact']>;
};
const RenderingComponent = ({ getContact }: RenderingProps) => {
  const me = useCurrentUser();
  const account = useCurrentAccount();
  const { name, email, id: contactId } = getContact;
  const updateTimelineFn = useUpdateTimelineFn(contactId);

  const breadcrumbName: string = Validation.String.isNonEmptyString(name)
    ? name
    : email;

  const mainOffice = useMainOffice(me.id);

  let defaultAssignedOfficeId;
  if (mainOffice != null) {
    defaultAssignedOfficeId = mainOffice.id;
  }
  const defaultAssignedUserId = me.id;

  return (
    <ContactsContextProvider>
      <ContentContainerDefault
        breadcrumbs={[
          { to: '/-/contacts', label: text.contactListTitle },
          { label: breadcrumbName },
        ]}
      >
        <MetaTags>
          <title>{text.pageTitle}</title>
        </MetaTags>

        <Container data-testid={TEST_ID.CONTACT_PAGE}>
          <ContactDetailsBlockComponent getContact={getContact} />
          <TaskAndTimelineContainer>
            <TaskUpdaterContext.Provider
              value={{
                onTaskChanged: () => {}, // Don't do anything here
              }}
            >
              <ActivityTabs bordered>
                <SendMessageTab
                  label="Verstuur e-mail"
                  datatestId="send-message"
                  contact={{
                    id: contactId,
                    name,
                    email,
                  }}
                  onSuccess={newEmailActivity => {
                    updateTimelineFn(newEmailActivity);
                  }}
                />
                <LogActivityTab
                  label={text.addLogTabLabel}
                  datatestId="log-activity"
                  contactId={contactId}
                  onSuccess={newLogActivity => {
                    updateTimelineFn(newLogActivity);
                  }}
                />
                <CreateNewTaskTab
                  isNew={true}
                  taskDetails={null}
                  label={text.addTaskLabel}
                  datatestId="add-task"
                  contactId={contactId}
                  accountId={account.id}
                  handleSetContactError={() => {
                    /** We can ignore this call, there is no set contact error to handle */
                  }}
                  defaultAssignedOfficeId={defaultAssignedOfficeId}
                  defaultAssignedUserId={defaultAssignedUserId}
                  onSuccess={newTask => {
                    updateTimelineFn(newTask);
                  }}
                />
              </ActivityTabs>
            </TaskUpdaterContext.Provider>
            <TimelineContainer>
              <EventTimelineWithTaskModal contact={getContact} />
            </TimelineContainer>
          </TaskAndTimelineContainer>
        </Container>
      </ContentContainerDefault>
    </ContactsContextProvider>
  );
};

const TaskAndTimelineContainer = styled.div<{}>`
  grid-column: task-timeline-start / task-timeline-end;
`;

const TimelineContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('xl')};
  `,
);

const CONTACTS_BLOCK_WIDTH = 320;

const Container = styled.div<{}>`
  display: grid;
  grid-template-rows: auto;

  ${({ theme }) => css`
    grid-column-gap: ${theme.space('xl')};
    grid-template-columns:
      [detail-block-start] ${CONTACTS_BLOCK_WIDTH}px [detail-block-end task-timeline-start] calc(
        100% - ${CONTACTS_BLOCK_WIDTH}px - ${theme.space('xl')}
      )
      [task-timeline-end];
  `};
`;

export default Contact;
