import React, { Component } from 'react';
import { FORM_ERROR } from 'final-form';
import { PageProps } from 'gatsby';
import Link from 'gatsby-link';
import MetaTags from 'react-meta-tags';
import { navigate } from 'gatsby';
import { Form, Field } from '~/components/Forms';
import Amplify from '~/amplify';
import Validation from '~/util/Validation';
import AuthNav from '~/scenes/Auth/components/AuthNav';
import AuthWrapperHOC from '~/scenes/Auth/components/AuthWrapperHOC';
import PasswordInputGroup from '~/scenes/Auth/components/PasswordInputGroup';
import Catalog from '~/Catalog';
import { H1, H3 } from '~/components/Typography';
import { FormUtils } from '~/components';
import Button from '~/components/Button';
import { Input, InputGroup } from '~/components/Inputs';
import {
  ActionWrapper,
  AuthForm,
  ErrorMsg,
  OtherOptionsLink,
} from '~/scenes/Auth/Auth.style';
import withDelayedLoading, {
  WithDelayedLoading,
} from '~/components/util/withDelayedLoading';

import TEST_ID from '~/scenes/Auth/SetupPassword.testid';

type FormData = {
  verificationCode: string;
  password: string;
  passwordRepeat?: string;
};

type Props = WithDelayedLoading &
  PageProps<
    any,
    any,
    { email?: string; initialCredentials: { email?: string } }
  >;
type State = {
  errorMsg: string | null;
  passwordConditions: {
    charNumberValid: boolean;
    uppercaseValid: boolean;
    lowercaseValid: boolean;
    specialCharValid: boolean;
    numberValid: boolean;
  };
  initialCredentials: {
    email: string | null | undefined;
    code: string | null | undefined;
  };
  isFocused: boolean;
};

class SetupPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { email, code } = getParamsFromMagicLink();

    if (email && code) {
      this.proceedMagicLinkPasswordReset(email, code);
    }

    const { location } = this.props;
    const errorMsg =
      !location.state || !location.state.email
        ? Catalog.genericUnknownErrorMessage
        : null;

    this.state = {
      errorMsg: errorMsg,
      passwordConditions: {
        charNumberValid: false,
        uppercaseValid: false,
        lowercaseValid: false,
        specialCharValid: false,
        numberValid: false,
      },
      initialCredentials: {
        email: email,
        code: code,
      },
      isFocused: email && code ? true : false,
    };
  }

  proceedMagicLinkPasswordReset = (
    email: string | null | undefined,
    code: string | null | undefined,
  ) => {
    this.setState({
      initialCredentials: {
        email,
        code,
      },
    });
  };

  validate = ({ verificationCode, password, passwordRepeat }: FormData) => {
    const errors: {
      verificationCode: undefined | string;
      password: undefined | string;
      passwordRepeat: undefined | string;
    } = {
      verificationCode: undefined,
      password: undefined,
      passwordRepeat: undefined,
    };

    const didEnterCode = verificationCode != undefined;
    const didEnterPassword = password != undefined;
    const didRepeatPassword = passwordRepeat != undefined;
    if (!didEnterCode) {
      errors.verificationCode = 'Voer verificatienummer in';
    }
    if (!didEnterPassword) {
      errors.password = Catalog.noPassword;
    }
    if (!didRepeatPassword) {
      errors.passwordRepeat = Catalog.noPasswordRepeat;
    }

    if (didEnterPassword && didRepeatPassword && password !== passwordRepeat) {
      errors.passwordRepeat = Catalog.passwordsDoNotMatch;
    }

    const charNumberValid = password && password.length >= 8 ? true : false;
    const uppercaseValid =
      password && Validation.Password.containsUpperCaseLetter(password)
        ? true
        : false;
    const lowercaseValid =
      password && Validation.Password.containsLowerCaseLetter(password)
        ? true
        : false;
    const specialCharValid =
      password && Validation.Password.containsSpecialCharacter(password)
        ? true
        : false;
    const numberValid =
      password && Validation.Password.containsNumber(password) ? true : false;

    this.setState((state: State) => ({
      passwordConditions: {
        ...state.passwordConditions,
        charNumberValid,
        lowercaseValid,
        uppercaseValid,
        specialCharValid,
        numberValid,
      },
    }));

    if (
      !charNumberValid ||
      !uppercaseValid ||
      !lowercaseValid ||
      !specialCharValid ||
      !numberValid
    ) {
      errors.password = Catalog.invalidPassword;
    }

    return errors;
  };

  onFormSubmit = ({ verificationCode, password }: FormData) => {
    const { location } = this.props;
    const email = this.state.initialCredentials.email || location.state.email;

    if (!email) return;

    this.props.setLoading();
    return Amplify.Auth.forgotPasswordSubmit(email, verificationCode, password)
      .then(() => {
        this.props.removeLoading();
        void navigate('/login', {
          state: {
            message: 'Jouw wachtwoord is aangepast. Je kan nu inloggen!',
          },
        });
      })
      .catch(err => {
        this.props.removeLoading();
        if (
          err.__type === 'CodeMismatchException' ||
          err.code === 'CodeMismatchException'
        ) {
          return {
            [FORM_ERROR]:
              err.message ||
              'Het verificatienummer klopt niet. Probeer het nog eens.',
          };
        }

        return {
          [FORM_ERROR]: err.message || Catalog.genericUnknownErrorMessage,
        };
      });
  };

  render() {
    const { location } = this.props;
    const title = 'Maak nieuw wachtwoord';
    const { errorMsg, initialCredentials, isFocused } = this.state;

    const emailMissed =
      (location.state && !location.state.email) ||
      (!location.state && !initialCredentials.email);

    return (
      <AuthWrapperHOC>
        <MetaTags>
          <title>{title}</title>
        </MetaTags>

        <AuthNav selectedIdx={0} showBackLink={true} />

        <AuthForm>
          <H1>{title}</H1>

          {emailMissed && (
            <H3>
              <ErrorMsg data-testid={TEST_ID.INITIAL_ERROR_MESSAGE}>
                {errorMsg}
              </ErrorMsg>
            </H3>
          )}

          {!emailMissed && (
            <React.Fragment>
              {location.state && (
                <p>
                  Er is een mail verstuurd naar {location.state.email} met een
                  verificatienummer erin.
                </p>
              )}
              <Form
                onSubmit={this.onFormSubmit}
                validate={this.validate}
                initialValues={{
                  verificationCode: initialCredentials.code
                    ? initialCredentials.code
                    : null,
                  password: null,
                  passwordRepeat: null,
                }}
              >
                {({
                  handleSubmit,
                  submitError,
                  submitting,
                  pristine,
                  hasValidationErrors,
                }) => (
                  <form
                    id="setupPassword"
                    onSubmit={handleSubmit}
                    data-testid={TEST_ID.SETUP_PASSWORD_FORM}
                  >
                    {submitError ? (
                      <ErrorMsg
                        data-testid={TEST_ID.SETUP_PASSWORD_ERROR_MESSAGE}
                      >
                        {submitError}
                      </ErrorMsg>
                    ) : null}
                    <InputGroup>
                      <Field name="verificationCode">
                        {({ input, meta: { error, touched } }) => (
                          <Input
                            large
                            label="Verificatienummer"
                            type="text"
                            error={FormUtils.showError(error, touched)}
                            disabled={submitting}
                            {...input}
                          />
                        )}
                      </Field>
                    </InputGroup>

                    <PasswordInputGroup
                      submitting={submitting}
                      passwordConditions={this.state.passwordConditions}
                      isFocused={isFocused}
                    />

                    <ActionWrapper>
                      <Button
                        size="large"
                        appearance="secondary"
                        type="submit"
                        loading={this.props.loading}
                        disabled={
                          submitting ||
                          pristine ||
                          this.props.loading ||
                          hasValidationErrors
                        }
                        data-testid={TEST_ID.SUBMIT_PASSWORD_BUTTON}
                        label="Maak nieuw wachtwoord aan"
                      />
                    </ActionWrapper>
                  </form>
                )}
              </Form>
              <OtherOptionsLink>
                Nog geen account?{' '}
                <Link to={`/register${location.search}`}>
                  Registreer je hier
                </Link>
              </OtherOptionsLink>
            </React.Fragment>
          )}
        </AuthForm>
      </AuthWrapperHOC>
    );
  }
}

const getParamsFromMagicLink = () => {
  const searchString = global.window.location.search;

  const email = searchString.match(new RegExp(/email=.*(?=&code=)/, 'g'));
  const code = searchString.match(new RegExp(/code=.*$/, 'g'));

  return {
    email: email ? email[0].slice(6) : null,
    code: code ? code[0].slice(5) : null,
  };
};

export default withDelayedLoading(SetupPassword);
