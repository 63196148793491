import { IfElseAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { emptyFlowConditionContactDetails } from '../Base';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { generateHash } from '~/util/react';

const defaultProps = (baseActionProps: BaseActionProps): IfElseAction => {
  const condition = emptyFlowConditionContactDetails();
  return {
    ...baseActionProps,
    type: FLOW_ACTION_TYPE.IF_ELSE,
    conditionList: {
      allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
      conditions: [
        { ...condition, id: generateHash(`${JSON.stringify(condition)}-0`) },
      ],
    },
    visiblePath: true,
    yesPathChildId: null,
    noPathChildId: null,
  };
};

export default defaultProps;
