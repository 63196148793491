import { Edge, Node } from 'reactflow';
import { ClientFlowAction } from '~/graphql/types.client';
import { reporter } from '~/hooks/useErrorReporter';
import { EdgeData } from '../../../../components/edgeTypes/components/DropEdge';
import {
  Portal,
  PortalEdgeData,
} from '../../../../components/edgeTypes/components/PortalEdge';
import { generateMarkerEndId } from '../../../../components/MarkerDefinitions';
import { HandlerId } from '../../../../components/nodeTypes/components/IfElseCard';
import { HIGHLIGHTED_EDGE } from '../../../../constants/zIndexes';
import { generatePortalId, PortalData } from '../getPortalCards';

type Params = {
  id: string;
  parentId: string;
  parentNode: ClientFlowAction;
  portalCards: Array<Node<PortalData>>;
  prev: Array<Edge<EdgeData | PortalEdgeData>>;
};
/**
 * generate and portal edges to the current edges array
 * keywords: portaledge
 */
const addPortalEdges = ({
  parentId,
  id,
  parentNode,
  portalCards,
  prev,
}: Params) => {
  let sourceHandle: HandlerId | undefined;
  if (parentNode.__typename === 'FlowV2_Action_IfElse') {
    if (parentNode?.falseChildId === id) sourceHandle = HandlerId.falseChild;
    if (parentNode?.trueChildId === id) sourceHandle = HandlerId.trueChild;
  }

  const sourcePortalId = generatePortalId({
    type: 'source',
    sourceId: parentId,
    sourceHandle,
    targetId: id,
  });
  const targetPortalId = generatePortalId({
    type: 'target',
    sourceId: parentId,
    targetId: id,
    sourceHandle,
  });

  const color = portalCards.find(({ id }) => id === sourcePortalId)?.data.color;

  if (!color) {
    const err = new Error(
      `Source portal card does not exist or does not have any color |
        portalCards: ${JSON.stringify(portalCards, null, 2)} |
        sourcePortalId: ${sourcePortalId}
      `,
    );
    reporter.captureException(err, 'debug');
    return;
  }

  const markerEnd = generateMarkerEndId(color);

  const portalEdgeData = {
    sourceId: parentId,
    targetId: id,
    color,
  };

  const sourceEdge: Edge<PortalEdgeData> = {
    id: 'edgeId-' + sourcePortalId,
    source: parentId,
    target: sourcePortalId,
    data: { ...portalEdgeData, type: Portal.Source },
    markerEnd,
    type: 'portalEdge',
    sourceHandle,
  };

  const targetEdge: Edge<PortalEdgeData> = {
    id: 'edgeId-' + targetPortalId,
    source: targetPortalId,
    target: id,
    data: { ...portalEdgeData, type: Portal.Target },
    markerEnd,
    type: 'portalEdge',
  };

  /** Shown when the portal cards are highlighted */
  const highlightedEdge: Edge<PortalEdgeData> = {
    id: `${parentId}-to-${id}-${sourceHandle}-highlighted`,
    source: parentId,
    target: id,
    data: {
      ...portalEdgeData,
      isShownOnHighlight: true,
    },
    markerEnd,
    type: 'portalEdge',
    sourceHandle,
    zIndex: HIGHLIGHTED_EDGE,
  };

  prev.push(sourceEdge);
  prev.push(targetEdge);
  prev.push(highlightedEdge);
  return;
};
export default addPortalEdges;
