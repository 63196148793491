export type PathViewRights = {
  mayViewSubscription: boolean;
  mayViewInvoices: boolean;
};

const canSeeRoute = (
  { mayViewSubscription, mayViewInvoices }: PathViewRights,
  link: string,
): boolean => {
  if (link === '/-/settings/subscription') return mayViewSubscription;
  if (link === '/-/settings/invoices') return mayViewInvoices;

  return true;
};
export default canSeeRoute;
