import gql from 'graphql-tag';

export default gql`
  fragment BBWaardecheckPropertyFields on BBWaardecheck_Property {
    __typename
    id
    street
    city
    houseNumber
    houseNumberAddition
    builtInYear
    livingAreaSqm
    postcode
    location {
      lat
      lng
    }
    brainbayPropertyType: propertyType
  }
`;
