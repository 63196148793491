import getContactInfo from './utils/getContactInfo';
import getObjectInfo from './utils/getObjectInfo';
import createSimpleList from './utils/createSimpleList';
import getBbWaardecheckProperties from './utils/getBBWaardecheckProperties';
import getAlgasSoldProperties from './utils/getAlgasSoldProperties';
import asString from './utils/asString';
import {
  FileType,
  LayoutDirection,
  LayoutSize,
  EnvenProducts,
  HypotheekbondTool,
  TaskStatus,
  WozObjectionIntention,
} from '~/graphql/types';
import asSqm from './utils/asSqm';
import asAddress from './utils/asAddress';
import { asPriceString, moneyFormatter } from '~/util/money';
import convertToFrontend from '../convertToFrontend';
import {
  ActivityFE,
  ActivityV2,
  LayoutStyleBlockFE,
  StyleBlockFE,
} from '../../types';

// Utils that will be removed after switching fully to backend
const productsText = {
  energyLabel: 'Energielabel',
  nen2580: 'Woonoppervlakte berekening (NEN 2850)',
  floorPlanner: 'Plattegrond woning',
  floorPlannerFurniture: 'Plattegrond met meubilair',
  sustainabilityAdvice: 'Verduurzamingsadvies',
  wwsScoringIndependent: 'Puntentelling huurhuis (WWS)',
  wwsScoringDependent: 'Puntentelling onzelfstandige woonruimte (WWS)',
};

const getProductsValue = (products: Array<EnvenProducts>): string =>
  products.map(product => productsText[product]).join(', ');

const AlgasPropertyType = Object.freeze({
  VrijstaandeWoning: 0,
  TweeOnderEenKapwoning: 1,
  Hoekwoning: 2,
  TussenSchakelwoning: 3,
  Appartement: 4,
});

const text = {
  unknown: 'Onbekend',
};

const propertyLabelForType = (
  propertyType: number | null | undefined,
): string => {
  if (propertyType == null) return text.unknown;

  const propertyTypeLookup = {
    [AlgasPropertyType.VrijstaandeWoning]: 'Vrijstaande woning',
    [AlgasPropertyType.TweeOnderEenKapwoning]: 'Twee-onder-een-kapwoning',
    [AlgasPropertyType.Hoekwoning]: 'Hoekwoning',
    [AlgasPropertyType.TussenSchakelwoning]: 'Schakelwoning',
    [AlgasPropertyType.Appartement]: 'Appartement',
  };

  return propertyTypeLookup[propertyType] ?? text.unknown;
};

export const hypotheekbondTool: Record<HypotheekbondTool, string> = {
  [HypotheekbondTool.HypotheekBespaarcheck]:
    'Wat kan ik besparen op mijn hypotheek?',
  [HypotheekbondTool.Kidwk]: 'Kan ik dit huis betalen?',
  [HypotheekbondTool.Looptijdrente]: 'Wat kan ik besparen op mijn hypotheek?',
  [HypotheekbondTool.Maxmortgage]: 'Wat is mijn maximale hypotheek?',
};

/**
 * Generates the shape that backend will return from getActivitiesForContact query after the new fields are added
 *
 * Once backend is live, we will remove this util function and use the fields directly from the query.
 *
 * @param {ActivityFieldsFragment} activity - activity returned from query
 * keywords: newEvent, activityv2
 */
const generateActivityV2 = (
  activity: ActivityFE & { highlighted: boolean },
): ActivityV2 => {
  const sourceName =
    activity.source && activity.source.__typename !== 'Actor_Flow'
      ? activity.source.name
      : '';

  const sortingDate =
    'sortingDate' in activity ? activity.sortingDate : activity.createdDate;

  const common = {
    sortingDate,
    highlighted: activity.highlighted,
  };

  switch (activity.__typename) {
    // ---------------------------------- Event_Contact_New ----------------------------------
    case 'Event_Contact_New': {
      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Contact is aangemaakt`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Plus.svg',
        },
        body: [],
      };
    }

    // ---------------------------------- Event_Contact_Call ----------------------------------

    case 'Event_Contact_Call': {
      const { description } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# **${sourceName}** heeft een telefoongesprek gelogd`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Call.svg',
        },

        body: createSimpleList([
          {
            __typename: 'MarkdownBlock',
            value: description,
          },
        ]),
      };
    }

    // ---------------------------------- Event_Contact_Note ----------------------------------

    case 'Event_Contact_Note': {
      const { description } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# **${sourceName}** heeft een notitie toegevoegd`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Note.svg',
        },

        body: createSimpleList([
          {
            __typename: 'MarkdownBlock',
            value: description,
          },
        ]),
      };
    }

    // ---------------------------------- Event_Contact_App_SustainabilityReport ----------------------------------

    case 'Event_Contact_App_SustainabilityReport': {
      const {
        name,
        email,
        phone,
        property: {
          city,
          street,
          postcode,
          livingAreaSqm,
          builtInYear,
          houseNumber,
          houseNumberAddition,
        },
        pdfReportUrl,
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: '# Duurzaamheidsprofiel aangevraagd',
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Sus.svg',
        },
        attachments: pdfReportUrl
          ? [
              {
                __typename: 'EventCardAttachment',
                src: pdfReportUrl,
                fileType: FileType.Pdf,
                fileName: 'SusReport.pdf',
                label: 'Download rapport',
              },
            ]
          : [],
        body: createSimpleList(getContactInfo({ name, email, phone }), [
          ...getObjectInfo({
            street,
            postcode,
            city,
            houseNumber,
            addition: houseNumberAddition,
          }),
          {
            __typename: 'TextBlock',
            label: 'Bouwjaar',
            value: builtInYear,
          },
          {
            __typename: 'TextBlock',
            label: 'Woonoppervlakte',
            value: asSqm(livingAreaSqm),
          },
        ]),
      };
    }

    // ---------------------------------- Event_Contact_App_ARXGroep_TransactionCreated ----------------------------------

    case 'Event_Contact_App_ARXGroep_TransactionCreated': {
      const {
        contactName,
        contactPhone,
        city,
        street,
        postcode,
        email,
        product,
        supplier,
        amount,
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: '# ProPropertyPartners nutsvoorzieningen - Nieuw transactie aangevraagd',
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/ARX.svg',
        },
        attachments: [],
        body: [
          {
            __typename: 'GridLayout',
            id: '',
            childrenIds: [],
            blocks: createSimpleList(
              [
                ...getContactInfo({
                  name: contactName,
                  phone: contactPhone,
                  email,
                }),
                {
                  __typename: 'TextBlock',
                  label: 'Woonplaats',
                  value: city,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Straat',
                  value: street,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Postcode',
                  value: postcode,
                },
              ],

              [
                {
                  __typename: 'MarkdownBlock',
                  value: '## Transactie info',
                },

                {
                  __typename: 'TextBlock',
                  label: 'Leverancier',
                  value: supplier,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Product',
                  value: product,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Commissie',
                  value: asPriceString(amount),
                },
              ],
            ),
          },
        ] as Array<StyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_ValuationReport ----------------------------------

    case 'Event_Contact_App_ValuationReport': {
      const {
        pdfReportUrl,
        name,
        email,
        when,
        why,
        phone,
        contactRequested,
        valuation,
        property: {
          street,
          houseNumber,
          postcode,
          city: pCity,
          addition,
          location,
          livingArea,
          propertyType,
        },
        swipe,
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# **${sourceName}** via de waarderapport app - waarderapport opgevraagd`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/waarderapport.svg',
        },
        attachments: pdfReportUrl
          ? [
              {
                __typename: 'EventCardAttachment',
                src: pdfReportUrl,
                fileType: FileType.Pdf,
                fileName: 'SusReport.pdf',
                label: 'Download report',
              },
            ]
          : [],
        body: [
          {
            id: '',
            childrenIds: [],
            __typename: 'GridLayout',
            padding: [LayoutSize.Base],
            blocks: [
              ...(location
                ? [
                    {
                      __typename: 'FlexLayout',
                      blocks: [
                        {
                          __typename: 'MapsBlock',
                          mapValue: { location },
                        },
                      ],
                    },
                  ]
                : []),
              {
                id: '',
                childrenIds: [],
                __typename: 'FlexLayout',
                direction: LayoutDirection.Column,
                padding: [],
                blocks: createSimpleList(
                  [
                    ...getContactInfo({ name, email, phone }),
                    {
                      __typename: 'TextBlock',
                      label: 'Reden aanvraag',
                      value: why,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Verhuisintentie',
                      value: when,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Contactverzoek',
                      value: contactRequested ? 'Ja' : 'Nee',
                    },
                  ],
                  [
                    ...getObjectInfo({
                      street,
                      city: pCity,
                      postcode,
                      houseNumber,
                      addition,
                    }),
                    {
                      __typename: 'TextBlock',
                      label: 'Woonoppervlakte',
                      value: asSqm(livingArea),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Geselecteerd type',
                      value: propertyLabelForType(propertyType),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Waardeschatting',
                      value: valuation.predicted
                        ? moneyFormatter(valuation.predicted)
                        : '-',
                    },
                  ],
                ),
              },
            ],
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            id: '',
            childrenIds: [],
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Vergelijkbare woningen volgens aanvrager:',
            },
            blocks: swipe ? getAlgasSoldProperties(swipe.yes) : [],
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            id: '',
            childrenIds: [],
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Niet vergelijkbare woningen:',
            },
            blocks: swipe ? getAlgasSoldProperties(swipe.no) : [],
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_BBWaardecheck_ContactRequest ----------------------------------

    case 'Event_Contact_App_BBWaardecheck_ContactRequest': {
      const {
        name,
        email,
        phone,
        property,
        requestedProperty,
        comparableProperties,
        nonComparableProperties,
      } = activity;

      const { street, houseNumber, houseNumberAddition, postcode, city } =
        property;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Brainbay waardecheck contactverzoek
### ${street} ${houseNumber}`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/BBWC.svg',
        },
        body: [
          {
            __typename: 'GridLayout',
            padding: [LayoutSize.Base],
            blocks: [
              ...(property.location
                ? [
                    {
                      __typename: 'FlexLayout',
                      blocks: [
                        {
                          __typename: 'MapsBlock',
                          mapValue: { location: property.location },
                        },
                      ],
                    },
                  ]
                : []),

              {
                __typename: 'FlexLayout',
                direction: LayoutDirection.Column,
                padding: [],
                blocks: createSimpleList(
                  getContactInfo({ name, email, phone }),
                  [
                    ...getObjectInfo({
                      street,
                      city,
                      postcode,
                      houseNumber,
                      addition: houseNumberAddition,
                    }),
                    {
                      __typename: 'TextBlock',
                      label: 'Woonoppervlakte',
                      value: asSqm(property.livingAreaSqm),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Bouwjaar',
                      value: asString(property.builtInYear),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Geselecteerde type',
                      value: asString(property.brainbayPropertyType),
                    },
                  ],
                ),
              },
            ],
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'FlexLayout',
            padding: ['l', null, null, 'l'],
            blocks: [
              {
                __typename: 'MarkdownBlock',
                value: '## Meer infomatie opgevraagd over object:',
              },
            ],
          },
          ...getBbWaardecheckProperties([requestedProperty]),

          { __typename: 'DividerBlock', margin: [], id: '' },
          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Vergelijkbare woningen volgens aanvrager:',
            },
            blocks: comparableProperties
              ? getBbWaardecheckProperties(comparableProperties)
              : [],
          },

          { __typename: 'DividerBlock', margin: [], id: '' },
          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Niet vergelijkbare woningen:',
            },
            blocks: nonComparableProperties
              ? getBbWaardecheckProperties(nonComparableProperties)
              : [],
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_Zapier ----------------------------------

    case 'Event_Contact_App_Zapier': {
      const { name, metadata } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Zapier - ${name}`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/Zapier.svg',
        },
        body: createSimpleList([
          {
            __typename: 'MarkdownBlock',
            value: '## Velden:',
          },
          ...metadata.map(({ key, value }) => ({
            __typename: 'TextBlock',
            label: key,
            value: value,
          })),
        ]),
      };
    }

    // ---------------------------------- Event_Contact_App_ZapierTrigger ----------------------------------

    case 'Event_Contact_App_ZapierTrigger': {
      const { name, metadata } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Zapier - ${name}`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/Zapier.svg',
        },
        body: createSimpleList(
          [
            {
              __typename: 'MarkdownBlock',
              value: '## Uitgaande data',
            },

            {
              __typename: 'TextBlock',
              value: 'NAWTE en tags van dit contact',
            },
          ],
          [
            {
              __typename: 'MarkdownBlock',
              value: '## Extra velden:',
            },
            ...metadata.map(({ key, value }) => ({
              __typename: 'TextBlock',
              label: key,
              value: value,
            })),
          ],
        ),
      };
    }

    // ---------------------------------- Event_Contact_App_VBOWaardecheck_Report ----------------------------------

    case 'Event_Contact_App_VBOWaardecheck_Report': {
      const {
        name,
        email,
        when,
        why,
        phone,
        valuationPriceMax,
        valuationPrice,
        valuationPriceMin,
        pdfReportUrl,
        comparableProperties,
        nonComparableProperties,

        property: {
          location,
          street,
          houseNumber,
          postcode,
          city,
          livingAreaSqm,
          builtInYear,
          brainbayPropertyType,
        },
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: '# Avix waardecheck opgevraagd',
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/Avix.svg',
        },
        attachments: pdfReportUrl
          ? [
              {
                __typename: 'EventCardAttachment',
                src: pdfReportUrl,
                fileType: FileType.Pdf,
                fileName: 'Avix.pdf',
                label: 'Download rapport',
              },
            ]
          : [],
        body: [
          {
            id: '',
            childrenIds: [],
            __typename: 'GridLayout',
            padding: [LayoutSize.Base],
            blocks: [
              ...(location
                ? [
                    {
                      id: '',
                      childrenIds: [],
                      __typename: 'FlexLayout',
                      blocks: [
                        {
                          __typename: 'MapsBlock',
                          mapValue: { location: location },
                        },
                      ],
                    },
                  ]
                : []),
              {
                id: '',
                childrenIds: [],
                __typename: 'FlexLayout',
                direction: LayoutDirection.Column,
                padding: [],
                blocks: createSimpleList(
                  [
                    ...getContactInfo({ name, email, phone }),
                    {
                      __typename: 'TextBlock',
                      label: 'Reden aanvraag',
                      value: why,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Verhuisintentie',
                      value: when,
                    },
                  ],
                  [
                    ...getObjectInfo({ street, houseNumber, postcode, city }),
                    {
                      __typename: 'TextBlock',
                      label: 'Woonoppervlakte',
                      value: asSqm(livingAreaSqm),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Bouwjaar',
                      value: asString(builtInYear),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Geselecteerde type',
                      value: asString(brainbayPropertyType),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Waardeschatting',
                      value: moneyFormatter(valuationPrice),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Minimum waarde',
                      value: moneyFormatter(valuationPriceMin),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Maximum waarde',
                      value: moneyFormatter(valuationPriceMax),
                    },
                  ],
                ),
              },
            ],
          },
          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Vergelijkbare woningen volgens aanvrager:',
            },
            blocks: getBbWaardecheckProperties(comparableProperties, 'Avix'),
          },
          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Niet vergelijkbare woningen:',
            },
            blocks: getBbWaardecheckProperties(nonComparableProperties, 'Avix'),
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_Trustoo ----------------------------------

    case 'Event_Contact_App_Trustoo': {
      const { name, email, subject, trustooPhone, questionnaire } = activity;

      const extraBlocks = questionnaire.map(({ question, answers }) => ({
        label: question,
        value: answers.join(', '),
        __typename: 'TextBlock',
      }));

      const title = subject ? `Trustoo: ${subject}` : 'Trustoo';

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# ${title}`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Trustoo.svg',
        },
        body: createSimpleList([
          ...getContactInfo({ name, email, phone: trustooPhone }),
          ...extraBlocks,
        ]),
      };
    }

    // ---------------------------------- Event_Contact_App_TrustooSubmitted ----------------------------------

    case 'Event_Contact_App_TrustooSubmitted': {
      const { name, email, trustooSubmittedPhone, city, provider, subject } =
        activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: '# Trustoo prijsopgave aangevraagd: ' + subject,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Trustoo.svg',
        },
        body: createSimpleList([
          ...getContactInfo({ name, email, phone: trustooSubmittedPhone }),
          {
            __typename: 'TextBlock',
            label: 'Woonplaats',
            value: asString(city),
          },
          {
            __typename: 'TextBlock',
            label: 'Dienst',
            value: provider,
          },
        ]),
      };
    }

    // ---------------------------------- Event_Contact_App_TrustooConversion ----------------------------------

    case 'Event_Contact_App_TrustooConversion': {
      const {
        name,
        email,
        trustooConversionPhone,
        city,
        provider,
        commission,
        subject,
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: '# Trustoo commissie bevestigd: ' + subject,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Trustoo.svg',
        },
        body: createSimpleList([
          ...getContactInfo({ name, email, phone: trustooConversionPhone }),
          {
            __typename: 'TextBlock',
            label: 'Woonplaats',
            value: asString(city),
          },
          {
            __typename: 'TextBlock',
            label: 'Dienst',
            value: provider,
          },
          {
            __typename: 'TextBlock',
            label: 'Commissie',
            value: `${asPriceString(commission)}`,
          },
        ]),
      };
    }

    // ---------------------------------- Event_Contact_App_WOZComplaint_ComplaintCreated ----------------------------------

    case 'Event_Contact_App_WOZComplaint_ComplaintCreated': {
      const {
        name,
        email,
        wozPhone,
        wozPostcode,
        wozHouseNumber,
        wozHouseNumberAddition,
        wozStreet,
        wozCity,
        intention,
        wozValue,
      } = activity;

      const intentionText = {
        [WozObjectionIntention.Lower]: 'Ik wil een lagere WOZ waarde',
        [WozObjectionIntention.Higher]: 'Ik wil een hogere WOZ waarde',
      };

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# WOZ Bezwaar ingediend`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/WOZ.svg',
        },
        body: [
          {
            __typename: 'FlexLayout',
            direction: LayoutDirection.Column,
            blocks: [
              ...getContactInfo({ name, email, phone: wozPhone }),
              {
                __typename: 'TextBlock',
                label: 'Straat',
                value: wozStreet,
              },
              {
                __typename: 'TextBlock',
                label: 'Postcode',
                value: wozPostcode,
              },
              {
                __typename: 'TextBlock',
                label: 'Huisnummer',
                value: wozHouseNumber,
              },
              {
                __typename: 'TextBlock',
                label: 'Plaats',
                value: wozCity,
              },
              {
                __typename: 'TextBlock',
                label: 'Toevoegingen',
                value: asString(wozHouseNumberAddition),
              },
              {
                __typename: 'TextBlock',
                value: intentionText[intention],
                label: 'Reden bezwaar',
              },
              {
                __typename: 'TextBlock',
                value: wozValue,
                label: 'WOZ waarde',
              },
            ],
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_WOZComplaint_ComplaintSuccess ----------------------------------

    case 'Event_Contact_App_WOZComplaint_ComplaintSuccess': {
      const { commission } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# WOZ Bezwaar goedgekeurd`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/WOZ.svg',
        },
        body: [
          {
            id: '',
            childrenIds: [],
            __typename: 'FlexLayout',
            direction: LayoutDirection.Column,
            blocks: [
              {
                id: '',
                __typename: 'TextBlock',
                label: 'Commissie',
                value: new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }).format(commission / 100),
              },
            ],
          },
        ] as Array<StyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_EnvenRequest ----------------------------------

    case 'Event_Contact_App_EnvenRequest': {
      const {
        property: {
          city,
          street,
          postcode,
          houseNumber,
          addition,
          livingArea,
          buildIn,
        },
        firstName,
        lastName,
        email,
        phone,
        products,
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: '# Energielabel & Duurzaamheidsadvies - Nieuw product(en) aangevraagd',
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Enven.svg',
        },
        body: createSimpleList([
          ...getContactInfo({ email, phone, name: `${firstName} ${lastName}` }),
          {
            __typename: 'TextBlock',
            label: 'Straat',
            value: street,
          },
          {
            __typename: 'TextBlock',
            label: 'Postcode',
            value: postcode,
          },
          {
            __typename: 'TextBlock',
            label: 'Huisnummer',
            value: houseNumber,
          },
          {
            __typename: 'TextBlock',
            label: 'Woonplaats',
            value: city,
          },
          {
            __typename: 'TextBlock',
            label: 'Toevoegingen',
            value: addition,
          },
          {
            __typename: 'TextBlock',
            label: 'Woonoppervlakte',
            value: asSqm(livingArea),
          },
          {
            __typename: 'TextBlock',
            label: 'Gebouwd in',
            value: buildIn,
          },
          {
            __typename: 'TextBlock',
            label: 'Producten',
            value: getProductsValue(products),
          },
        ]),
      };
    }

    // ---------------------------------- Event_Contact_App_EnvenTransaction ----------------------------------

    case 'Event_Contact_App_EnvenTransaction': {
      const { products, transactionAmount } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: '# Energielabel & Duurzaamheidsadvies - Nieuwe transactie ontvangen',
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Enven.svg',
        },
        body: createSimpleList([
          {
            __typename: 'TextBlock',
            label: 'Producten',
            value: getProductsValue(products),
          },
          {
            __typename: 'TextBlock',
            label: 'Commissie',
            value: asPriceString(transactionAmount),
          },
        ]),
      };
    }

    // ---------------------------------- Event_Contact_App_ARXGroep_ContractCreated ----------------------------------

    case 'Event_Contact_App_ARXGroep_ContractCreated': {
      const {
        street,
        houseNumberAddition,
        product,
        supplier,
        city,
        email,
        postcode,
        contactPhone,
        contactName,
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: '# ProPropertyPartners nutsvoorzieningen - Nieuw contract aangevraagd',
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/ARX.svg',
        },
        body: [
          {
            id: '',
            childrenIds: [],
            __typename: 'GridLayout',
            blocks: createSimpleList(
              [
                ...getContactInfo({
                  email,
                  phone: contactPhone,
                  name: contactName,
                }),
                {
                  __typename: 'TextBlock',
                  label: 'Woonplaats',
                  value: city,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Straat',
                  value: street,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Postcode',
                  value: postcode,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Toevoegingen',
                  value: houseNumberAddition,
                },
              ],
              [
                {
                  __typename: 'MarkdownBlock',
                  value: '## Aangevraagd',
                },
                {
                  __typename: 'TextBlock',
                  label: 'Leverancier',
                  value: supplier,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Product',
                  value: product,
                },
              ],
            ),
          },
        ] as Array<StyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_Hypotheekbond ----------------------------------

    case 'Event_Contact_App_Hypotheekbond': {
      const { email, hypotheekbondPhone, dateOfBirth, name, tool } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Contactverzoek via Hypotheekbond: ${hypotheekbondTool[tool]}`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Hypotheekbond.svg',
        },
        body: createSimpleList(
          getContactInfo({
            email,
            phone: hypotheekbondPhone,
            name: name,
            dateOfBirth,
          }),
        ),
      };
    }

    // ---------------------------------- Event_Contact_App_Funda_BrochureRequest ----------------------------------

    case 'Event_Contact_App_Funda_BrochureRequest': {
      const {
        email,
        titlePrefix,
        phone,
        streetName,
        houseNumber,
        postcode,
        placeName,
        category,
        subjectAddress,
        comments,
        name,
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Brochure aangevraagd via Funda ${
            category ? 'voor' + category : ''
          } \n ## ${subjectAddress ? subjectAddress : ''}`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Funda.svg',
        },
        body: [
          {
            id: '',
            childrenIds: [],
            __typename: 'GridLayout',
            blocks: createSimpleList(
              [
                {
                  __typename: 'MarkdownBlock',
                  value: '## Woonadres',
                },
                {
                  __typename: 'TextBlock',
                  label: 'Straat',
                  value: streetName,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Huisnummer',
                  value: houseNumber,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Postcode',
                  value: postcode,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Plaatsnaam',
                  value: placeName,
                },
              ],
              [
                {
                  __typename: 'MarkdownBlock',
                  value: '## Contactgegevens',
                },
                {
                  __typename: 'TextBlock',
                  label: 'Aanhef',
                  value: titlePrefix,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Naam',
                  value: name,
                },
                {
                  __typename: 'TextBlock',
                  label: 'E-mailadres',
                  value: email,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Telefoonnummer',
                  value: asString(phone),
                },
              ],
            ),
          },
          {
            __typename: 'DividerBlock',
            margin: [null],
            id: '',
            childrenIds: [],
          },
          {
            __typename: 'FlexLayout',
            direction: LayoutDirection.Column,
            blocks: [
              { __typename: 'MarkdownBlock', value: '## Bericht' },
              { __typename: 'MarkdownBlock', value: comments ?? '' },
            ],
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_Funda_ContactRequest ----------------------------------

    case 'Event_Contact_App_Funda_ContactRequest': {
      const {
        email,
        titlePrefix,
        phone,
        streetName,
        houseNumber,
        postcode,
        placeName,
        category,
        subjectAddress,
        comments,
        name,
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Contactverzoek via Funda ${
            category ? 'voor' + category : ''
          } \n ## ${subjectAddress ? subjectAddress : ''}`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Funda.svg',
        },
        body: [
          {
            __typename: 'GridLayout',
            blocks: createSimpleList(
              [
                {
                  __typename: 'MarkdownBlock',
                  value: '## Woonadres',
                },
                {
                  __typename: 'TextBlock',
                  label: 'Straat',
                  value: streetName,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Huisnummer',
                  value: houseNumber,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Postcode',
                  value: postcode,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Plaatsnaam',
                  value: placeName,
                },
              ],
              [
                {
                  __typename: 'MarkdownBlock',
                  value: '## Contactgegevens',
                },
                {
                  __typename: 'TextBlock',
                  label: 'Aanhef',
                  value: titlePrefix,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Naam',
                  value: name,
                },
                {
                  __typename: 'TextBlock',
                  label: 'E-mailadres',
                  value: email,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Telefoonnummer',
                  value: asString(phone),
                },
              ],
            ),
          },
          { __typename: 'DividerBlock', margin: [null] },
          {
            __typename: 'FlexLayout',
            direction: LayoutDirection.Column,
            blocks: [
              { __typename: 'MarkdownBlock', value: '## Bericht' },
              { __typename: 'MarkdownBlock', value: comments ?? '' },
            ],
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_Funda_ViewingRequest ----------------------------------

    case 'Event_Contact_App_Funda_ViewingRequest': {
      const {
        email,
        titlePrefix,
        phone,
        streetName,
        houseNumber,
        postcode,
        placeName,
        category,
        subjectAddress,
        comments,
        day,
        timeOfDay,
        name,
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Bezichtigingsverzoek via Funda ${
            category ? 'voor' + category : ''
          } \n ## ${subjectAddress ? subjectAddress : ''}`,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Funda.svg',
        },
        body: [
          {
            __typename: 'GridLayout',
            blocks: createSimpleList(
              [
                {
                  __typename: 'MarkdownBlock',
                  value: '## Woonadres',
                },
                {
                  __typename: 'TextBlock',
                  label: 'Straat',
                  value: streetName,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Huisnummer',
                  value: houseNumber,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Postcode',
                  value: postcode,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Plaatsnaam',
                  value: placeName,
                },
              ],
              [
                {
                  __typename: 'MarkdownBlock',
                  value: '## Contactgegevens',
                },
                {
                  __typename: 'TextBlock',
                  label: 'Aanhef',
                  value: titlePrefix,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Naam',
                  value: name,
                },
                {
                  __typename: 'TextBlock',
                  label: 'E-mailadres',
                  value: email,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Telefoonnummer',
                  value: asString(phone),
                },
                {
                  __typename: 'TextBlock',
                  label: 'Voorkeursdag',
                  value: day,
                },
                {
                  __typename: 'TextBlock',
                  label: 'Voorkeursdagdeel',
                  value: timeOfDay,
                },
              ],
            ),
          },
          { __typename: 'DividerBlock', margin: [null] },
          {
            __typename: 'FlexLayout',
            direction: LayoutDirection.Column,
            blocks: [
              { __typename: 'MarkdownBlock', value: '## Bericht' },
              { __typename: 'MarkdownBlock', value: comments ?? '' },
            ],
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_ValuationRequest ----------------------------------

    case 'Event_Contact_App_ValuationRequest': {
      const {
        name,
        email,
        when,
        why,
        phone,
        property: {
          livingArea,
          propertyType,
          street,
          houseNumber,
          postcode,
          city: pCity,
          addition,
          location,
        },
        swipe,
      } = activity;
      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# **${sourceName}** via de waarderapport app - waardebepaling verzocht \n ## ${asAddress(
            {
              street,
              houseNumber,
              houseNumberAddition: addition,
            },
          )}`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/waarderapport.svg',
        },
        body: [
          {
            id: '',
            childrenIds: [],
            __typename: 'GridLayout',
            padding: [LayoutSize.Base],
            blocks: [
              ...(location
                ? [
                    {
                      __typename: 'FlexLayout',
                      blocks: [
                        {
                          __typename: 'MapsBlock',
                          mapValue: { location: location },
                        },
                      ],
                    },
                  ]
                : []),
              {
                id: '',
                childrenIds: [],
                __typename: 'FlexLayout',
                direction: LayoutDirection.Column,
                padding: [],
                blocks: createSimpleList(
                  [
                    ...getContactInfo({ name, email, phone }),
                    {
                      __typename: 'TextBlock',
                      label: 'Reden aanvraag',
                      value: why,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Verhuisintentie',
                      value: when,
                    },
                  ],
                  [
                    ...getObjectInfo({
                      street,
                      city: pCity,
                      postcode,
                      houseNumber,
                      addition,
                    }),
                    {
                      __typename: 'TextBlock',
                      label: 'Woonoppervlakte',
                      value: asSqm(livingArea),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Geselecteerd type',
                      value: propertyLabelForType(propertyType),
                    },
                  ],
                ),
              },
            ],
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Vergelijkbare woningen volgens aanvrager:',
            },
            blocks: swipe ? getAlgasSoldProperties(swipe.yes) : [],
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Niet vergelijkbare woningen:',
            },
            blocks: swipe ? getAlgasSoldProperties(swipe.no) : [],
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_BBWaardecheck_Report ----------------------------------

    case 'Event_Contact_App_BBWaardecheck_Report': {
      const {
        pdfReportUrl,
        name,
        email,
        phone,
        valuationPrice,
        valuationPriceMax,
        valuationPriceMin,
        comparableProperties,
        nonComparableProperties,
        when,
        why,
        property: {
          brainbayPropertyType,
          builtInYear,
          livingAreaSqm,
          street,
          houseNumber,
          postcode,
          city,
          location,
        },
      } = activity;
      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Brainbay waardecheck opgevraagd \n ## ${asAddress({
            street,
            houseNumber,
          })}`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/BBWC.svg',
        },
        attachments: pdfReportUrl
          ? [
              {
                __typename: 'EventCardAttachment',
                src: pdfReportUrl,
                fileType: FileType.Pdf,
                fileName: 'Bb.pdf',
                label: 'Download rapport',
              },
            ]
          : [],
        body: [
          {
            id: '',
            childrenIds: [],
            __typename: 'GridLayout',
            padding: [LayoutSize.Base],
            blocks: [
              ...(location
                ? [
                    {
                      id: '',
                      childrenIds: [],
                      __typename: 'FlexLayout',
                      blocks: [
                        {
                          __typename: 'MapsBlock',
                          mapValue: { location: location },
                        },
                      ],
                    },
                  ]
                : []),

              {
                id: '',
                childrenIds: [],
                __typename: 'FlexLayout',
                direction: LayoutDirection.Column,
                padding: [],
                blocks: createSimpleList(
                  [
                    ...getContactInfo({ name, email, phone }),
                    {
                      __typename: 'TextBlock',
                      label: 'Reden aanvraag',
                      value: why,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Verhuisintentie',
                      value: when,
                    },
                  ],
                  [
                    ...getObjectInfo({ street, houseNumber, postcode, city }),
                    {
                      __typename: 'TextBlock',
                      label: 'Woonoppervlakte',
                      value: asSqm(livingAreaSqm),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Bouwjaar',
                      value: asString(builtInYear),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Geselecteerde type',
                      value: asString(brainbayPropertyType),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Waardeschatting',
                      value: moneyFormatter(valuationPrice),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Minimum waarde',
                      value: moneyFormatter(valuationPriceMin),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Maximum waarde',
                      value: moneyFormatter(valuationPriceMax),
                    },
                  ],
                ),
              },
            ],
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Vergelijkbare woningen volgens aanvrager:',
            },
            blocks: getBbWaardecheckProperties(comparableProperties),
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Niet vergelijkbare woningen:',
            },
            blocks: getBbWaardecheckProperties(nonComparableProperties),
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_BBWaardecheck_AppraisalRequest ----------------------------------

    case 'Event_Contact_App_BBWaardecheck_AppraisalRequest': {
      const {
        name,
        email,
        phone,
        comparableProperties,
        nonComparableProperties,
        why,
        when,
        property: {
          brainbayPropertyType,
          builtInYear,
          livingAreaSqm,
          street,
          houseNumber,
          postcode,
          city: pCity,
          location,
        },
      } = activity;
      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Brainbay waardecheck - waardebepaling verzocht
        \n ## ${asAddress({
          street,
          houseNumber,
        })}`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/BBWC.svg',
        },
        body: [
          {
            __typename: 'GridLayout',
            padding: [LayoutSize.Base],
            blocks: [
              ...(location
                ? [
                    {
                      __typename: 'FlexLayout',
                      blocks: [
                        {
                          __typename: 'MapsBlock',
                          mapValue: { location: location },
                        },
                      ],
                    },
                  ]
                : []),

              {
                __typename: 'FlexLayout',
                direction: LayoutDirection.Column,
                padding: [],
                blocks: createSimpleList(
                  [
                    ...getContactInfo({ name, email, phone }),
                    {
                      __typename: 'TextBlock',
                      label: 'Reden aanvraag',
                      value: why,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Verhuisintentie',
                      value: when,
                    },
                  ],
                  [
                    ...getObjectInfo({
                      street,
                      city: pCity,
                      postcode,
                      houseNumber,
                    }),
                    {
                      __typename: 'TextBlock',
                      label: 'Woonoppervlakte',
                      value: asSqm(livingAreaSqm),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Bouwjaar',
                      value: builtInYear,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Geselecteerd type',
                      value: brainbayPropertyType,
                    },
                  ],
                ),
              },
            ],
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            id: '',
            childrenIds: [],
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Vergelijkbare woningen volgens aanvrager:',
            },
            blocks: getBbWaardecheckProperties(comparableProperties),
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Niet vergelijkbare woningen:',
            },
            blocks: getBbWaardecheckProperties(nonComparableProperties),
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_VBOWaardecheck_AppraisalRequest ----------------------------------

    case 'Event_Contact_App_VBOWaardecheck_AppraisalRequest': {
      const {
        name,
        email,
        phone,
        comparableProperties,
        nonComparableProperties,
        why,
        when,
        property: {
          brainbayPropertyType,
          builtInYear,
          livingAreaSqm,
          street,
          houseNumber,
          postcode,
          city: pCity,
          location,
        },
      } = activity;
      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Avix waardecheck - waardebepaling verzocht
        \n ## ${asAddress({
          street,
          houseNumber,
        })}`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/Avix.svg',
        },
        body: [
          {
            id: '',
            childrenIds: [],
            __typename: 'GridLayout',
            padding: [LayoutSize.Base],
            blocks: [
              ...(location
                ? [
                    {
                      id: '',
                      childrenIds: [],
                      __typename: 'FlexLayout',
                      blocks: [
                        {
                          __typename: 'MapsBlock',
                          mapValue: { location: location },
                        },
                      ],
                    },
                  ]
                : []),

              {
                id: '',
                childrenIds: [],
                __typename: 'FlexLayout',
                direction: LayoutDirection.Column,
                padding: [],
                blocks: createSimpleList(
                  [
                    ...getContactInfo({ name, email, phone }),
                    {
                      __typename: 'TextBlock',
                      label: 'Reden aanvraag',
                      value: why,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Verhuisintentie',
                      value: when,
                    },
                  ],
                  [
                    ...getObjectInfo({
                      street,
                      city: pCity,
                      postcode,
                      houseNumber,
                    }),
                    {
                      __typename: 'TextBlock',
                      label: 'Woonoppervlakte',
                      value: asSqm(livingAreaSqm),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Bouwjaar',
                      value: builtInYear,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Geselecteerd type',
                      value: brainbayPropertyType,
                    },
                  ],
                ),
              },
            ],
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Vergelijkbare woningen volgens aanvrager:',
            },
            blocks: getBbWaardecheckProperties(comparableProperties, 'Avix'),
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Niet vergelijkbare woningen:',
            },
            blocks: getBbWaardecheckProperties(nonComparableProperties, 'Avix'),
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    // ---------------------------------- Event_Contact_App_VBOWaardecheck_ContactRequest ----------------------------------

    case 'Event_Contact_App_VBOWaardecheck_ContactRequest': {
      const {
        name,
        email,
        phone,
        comparableProperties,
        nonComparableProperties,
        requestedProperty,
        property: {
          brainbayPropertyType,
          builtInYear,
          livingAreaSqm,
          street,
          houseNumber,
          postcode,
          city: pCity,
          location,
        },
      } = activity;

      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Avix waardecheck contactverzoek
        \n ## ${asAddress({
          street,
          houseNumber,
        })}`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/Avix.svg',
        },
        body: [
          {
            __typename: 'GridLayout',
            padding: [LayoutSize.Base],
            blocks: [
              ...(location
                ? [
                    {
                      __typename: 'FlexLayout',
                      blocks: [
                        {
                          __typename: 'MapsBlock',
                          mapValue: { location: location },
                        },
                      ],
                    },
                  ]
                : []),
              {
                __typename: 'FlexLayout',
                direction: LayoutDirection.Column,
                padding: [],
                blocks: createSimpleList(
                  getContactInfo({ name, email, phone }),
                  [
                    ...getObjectInfo({
                      street,
                      city: pCity,
                      postcode,
                      houseNumber,
                    }),
                    {
                      __typename: 'TextBlock',
                      label: 'Woonoppervlakte',
                      value: asSqm(livingAreaSqm),
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Bouwjaar',
                      value: builtInYear,
                    },
                    {
                      __typename: 'TextBlock',
                      label: 'Geselecteerd type',
                      value: brainbayPropertyType,
                    },
                  ],
                ),
              },
            ],
          },

          // Start Requested property
          { __typename: 'DividerBlock', margin: [], id: '' },
          {
            __typename: 'FlexLayout',
            padding: ['l', null, null, 'l'],
            blocks: [
              {
                __typename: 'MarkdownBlock',
                value: '## Meer infomatie opgevraagd over object:',
              },
            ],
          },
          ...getBbWaardecheckProperties([requestedProperty], 'Avix'),
          // End Requested property

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Vergelijkbare woningen volgens aanvrager:',
            },
            blocks: getBbWaardecheckProperties(comparableProperties, 'Avix'),
          },

          { __typename: 'DividerBlock', margin: [], id: '' },

          {
            __typename: 'ExpandableLayout',
            title: {
              __typename: 'LayoutLabel',
              expanded: 'Laat minder zien',
              collapsed: 'Niet vergelijkbare woningen:',
            },
            blocks: getBbWaardecheckProperties(nonComparableProperties, 'Avix'),
          },
        ] as Array<LayoutStyleBlockFE>,
      };
    }

    case 'Task_Contact': {
      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# ${activity.title}`,
        },
        icon: {
          __typename: 'Image',
          url:
            activity.status === TaskStatus.Closed
              ? 'https://dathuis-stash.imgix.net/event_icons/Checked.svg'
              : 'https://dathuis-stash.imgix.net/event_icons/Unchecked.svg',
        },
        body: [],
      };
    }

    case 'Event_Contact_Email': {
      const senderName = activity.from[0].name ?? activity.from[0].email;
      return {
        ...common,
        ...activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# **${senderName}** verstuurde een e-mail`,
        },
        icon: {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/Email.svg',
        },
        body: [],
      };
    }

    case 'Event_Contact_Generic':
      return {
        ...common,
        ...activity,
        header: activity.header ?? {
          text: '',
        },
        icon: activity.icon ?? {
          __typename: 'Image',

          url: 'https://dathuis-stash.imgix.net/event_icons/Plus.svg',
        },
        body: convertToFrontend(activity.body),
      };

    default:
      const _activity = activity as ActivityV2;
      return {
        ...common,
        ..._activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Not implemented \`${_activity.__typename}\``,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Plus.svg',
        },
        body: [],
      };
  }
};

export default generateActivityV2;
