import { FlowParameterEventFieldsFragment } from '~/graphql/types';
import { AppHypotheekbondPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

import { PARAMETER_VALUE_TYPE } from '../../constants';
import emptyHypotheekbondEventParameterValue from './emptyHypotheekbondEventParameterValue';

export default (
  eventParameter:
    | Extract<
        FlowParameterEventFieldsFragment,
        {
          __typename: 'Flow_Parameter_Event_Contact_App_Hypotheekbond_Pointer';
        }
      >
    | null
    | undefined,
): AppHypotheekbondPointerParameterValue => {
  if (eventParameter == null) {
    return emptyHypotheekbondEventParameterValue();
  }
  switch (eventParameter.__typename) {
    case 'Flow_Parameter_Event_Contact_App_Hypotheekbond_Pointer':
      return {
        type: PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_HYPOTHEEKBOND,
        variable: {
          name: eventParameter.variable,
          field: eventParameter.field
            ? {
                name: eventParameter.field,
              }
            : null,
        },
      };
    default:
      return assertNever(
        eventParameter.__typename,
        cleanedFilename(__filename),
      );
  }
};
