/**
 * returns the number of changes for currentObject in difference from the initialObject
 * @param {Object} initialObject
 * @param {Object} currentObject
 * keywords: count, countChanges, object, initialObject
 */
const countChanges = (initialObject: Object, currentObject: Object) => {
  let changes = 0;

  // Iterate over the keys of the initial object
  for (const key in initialObject) {
    // Check if the key exists in the current object
    if (currentObject.hasOwnProperty(key)) {
      // Compare the values of the corresponding keys
      if (initialObject[key] !== currentObject[key]) {
        changes++;
      }
    } else {
      // Key doesn't exist in the current object
      changes++;
    }
  }

  // Check for any additional keys in the current object
  for (const key in currentObject) {
    if (!initialObject.hasOwnProperty(key)) {
      changes++;
    }
  }

  return changes;
};

export default countChanges;
