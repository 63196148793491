import { useCallback, useMemo } from 'react';
import { Resetter, useResetRecoilState } from 'recoil';
import flowActions from '~/scenes/Automation/v2/state';
import addBlockMenu from '~/scenes/Automation/v2/state/addBlockMenu';
import flowChanges from '~/scenes/Automation/v2/state/flowChanges';
import flowIssues from '~/scenes/Automation/v2/state/flowIssues';
import flowSettings from '~/scenes/Automation/v2/state/flowSettings';
import interactions from '~/scenes/Automation/v2/state/interactions';
import lastAddedAction from '~/scenes/Automation/v2/state/lastAddedActionId';
import lastDeletedActionId from '~/scenes/Automation/v2/state/lastDeletedActionId';
import metadata from '~/scenes/Automation/v2/state/metadata';

/**
 * Resets all recoil atoms related to flow state
 */
const useResetFlowState = (): Resetter => {
  const resetFlowActions = useResetRecoilState(flowActions);
  const resetFlowSettings = useResetRecoilState(flowSettings);
  const resetIssues = useResetRecoilState(flowIssues);
  const resetInteraction = useResetRecoilState(interactions);
  const resetAddBlockMenu = useResetRecoilState(addBlockMenu);
  const resetFlowChanges = useResetRecoilState(flowChanges);
  const resetFlowMetadata = useResetRecoilState(metadata);
  const resetLastAddedAction = useResetRecoilState(lastAddedAction);
  const resetLastDeletedActionId = useResetRecoilState(lastDeletedActionId);

  const resetFns = useMemo(
    () => [
      resetFlowSettings,
      resetIssues,
      resetInteraction,
      resetAddBlockMenu,
      resetFlowChanges,
      resetFlowMetadata,
      resetFlowActions,
      resetLastAddedAction,
      resetLastDeletedActionId,
    ],
    [
      resetFlowSettings,
      resetIssues,
      resetInteraction,
      resetAddBlockMenu,
      resetFlowChanges,
      resetFlowMetadata,
      resetFlowActions,
      resetLastAddedAction,
      resetLastDeletedActionId,
    ],
  );

  const resetFlowState = useCallback(() => {
    resetFns.forEach(fn => fn());
  }, [resetFns]);

  return resetFlowState;
};

export default useResetFlowState;
