import Catalog from '~/Catalog';
import type { IconType } from '~/components/Icon';
import useUserRights from '~/hooks/useUserRights';

const useMenuOptions = () => {
  const { view: mayViewInvoices } = useUserRights({ category: 'Invoices' });
  const { view: mayViewSubscription } = useUserRights({
    category: 'Subscription',
  });

  return allMenuOptions.filter(({ payload }) => {
    if (payload === '/-/settings/subscription') return mayViewSubscription;
    if (payload === '/-/settings/invoices') return mayViewInvoices;

    return true;
  });
};

export default useMenuOptions;

const HELPDESK_LINK = 'https://help.dathuis.nl';

const text = {
  myProfileLabel: 'Mijn profiel',
  officesLabel: Catalog.officesLabel,
  usersLabel: Catalog.usersLabel,
  accountLabel: Catalog.accountLabel,
  subscriptionLabel: Catalog.subscriptionLabel,
  invoicesLabel: Catalog.invoicesLabel,
  logoutLabel: 'Uitloggen',
  helpLabel: 'Help',
  automationLabel: Catalog.automationLabel,
  widgetLabel: Catalog.widgetLabel,
};

const allMenuOptions = [
  {
    label: text.myProfileLabel,
    icon: 'user' as IconType,
    payload: '/-/settings/profile',
    key: 'nav-link-settings-dropdown-profile',
  },
  {
    label: text.officesLabel,
    icon: 'office' as IconType,
    payload: '/-/settings/offices',
    key: 'nav-link-settings-dropdown-offices',
  },
  {
    label: text.usersLabel,
    icon: 'users' as IconType,
    payload: '/-/settings/users',
    key: 'nav-link-settings-dropdown-users',
  },
  {
    label: text.accountLabel,
    icon: 'briefcase' as IconType,
    payload: '/-/settings/account',
    key: 'nav-link-settings-dropdown-account',
  },
  {
    label: text.subscriptionLabel,
    icon: 'file-text' as IconType,
    payload: '/-/settings/subscription',
    key: 'nav-link-settings-dropdown-subscription',
  },
  {
    label: text.invoicesLabel,
    icon: 'file' as IconType,
    payload: '/-/settings/invoices',
    key: 'nav-link-settings-dropdown-invoices',
  },
  {
    label: Catalog.walletLabel,
    icon: 'credit-card' as IconType,
    payload: '/-/settings/wallet',
    key: 'nav-link-settings-dropdown-wallet',
  },
  {
    label: text.automationLabel,
    icon: 'triangle' as IconType,
    payload: '/-/settings/automation',
    key: 'nav-link-settings-dropdown-automation',
  },
  {
    label: text.widgetLabel,
    icon: 'star' as IconType,
    payload: '/-/settings/widget',
    key: 'nav-link-settings-slide-widget',
  },
  {
    label: text.helpLabel,
    icon: 'question-mark' as IconType,
    payload: HELPDESK_LINK,
    key: 'nav-link-settings-dropdown-help',
  },
  {
    label: text.logoutLabel,
    icon: 'sign-out' as IconType,
    payload: '/logout',
    key: 'nav-link-settings-dropdown-signout',
    styleOptions: {
      lineAbove: true,
    },
  },
];
