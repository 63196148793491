import React from 'react';
import useErrorReporter from '~/hooks/useErrorReporter';
import getConfiguredWizardFlow from '../WizardSteps/utils/getConfiguredWizardFlow';
import useWizard from '~/hooks/useWizard';
import { Warning as WarningType } from '~/hooks/useEntityWarnings';
import WarningCTA from './components/WarningCTA';

export type Props = {
  dataTestId?: string;
  warning: WarningType;
  onClose: (warning: WarningType) => void;
};

const Warning: React.FC<Props> = ({ warning, onClose }) => {
  const errorReporter = useErrorReporter();
  const wizardApi = useWizard();
  const { item } = warning;

  switch (item.body.__typename) {
    case 'NotificationWizard': {
      const { id, htmlBody, header, ctaLabel } = item.body;

      const enablementFlow = getConfiguredWizardFlow(id);

      if (enablementFlow) {
        return (
          <WarningCTA
            level={item.level}
            key={warning.hash}
            header={header ?? undefined}
            cta={{
              onClick: () => wizardApi.show(enablementFlow),
              label: ctaLabel,
            }}
            onClose={() => onClose(warning)}
            htmlBody={htmlBody}
          />
        );
      } else {
        errorReporter.captureException(
          new Error(`enablementFlow was not found for the id: ${id}`),
          'critical',
        );
        return null;
      }
    }

    case 'NotificationMessage':
    default: {
      const { htmlBody } = item.body;
      return (
        <WarningCTA
          level={item.level}
          onClose={() => onClose(warning)}
          key={warning.hash}
          htmlBody={htmlBody}
        />
      );
    }
  }
};

export default Warning;
