import React, { useState } from 'react';
import styled from 'styled-components';

import { FormApi } from 'final-form';

import { InsertAppPremiumValuationReportMutation } from '~/graphql';
import mutation from '~/graphql/mutation/InsertAppPremiumValuationReport';
import Validation from '~/util/Validation';
import { Drawer, FormUtils } from '~/components';
import { LinkButton } from '~/components/Buttons';
import Button from '~/components/Button';
import { Input, InputGroup, Checkbox } from '~/components/Inputs';
import { Form, Field } from '~/components/Forms';

import Catalog from '~/Catalog';
import useIntercom from '~/hooks/useIntercom';
import TEST_ID from './AddPremiumValueReportSidebar.testid';
import cleanedFilename from '~/util/cleanedFilename';
import useCurrentAccount from '~/hooks/useCurrentAccount';

type Props = {
  onAdded: () => void;
  isShowing: boolean;
  hide: () => void;
};

type FormFields = {
  name: string | null | undefined;
  algasId: string | null | undefined;
};

const text = {
  nameLabel: Catalog.valueReports.nameLabel,
  algasIdLabel: Catalog.valueReports.algasIdHeader,
  sendContactEmailLabel: Catalog.valueReports.sendContactEmailLabel,
  sendAgentEmailLabel: Catalog.valueReports.sendAgentEmailLabel,
  submit: 'Toevoegen',
  drawerTitle: 'Waarderapport koppelen',
  noName: Catalog.valueReports.noName,
  noAlgasId: Catalog.valueReports.noAlgasId,
  submitErrorMsg:
    'Er is geen waarderapport gevonden met het gegeven identificatienummer. Controleer het identificatienummer of neem contact met ons op.',
  helpText: 'Voer hier het identificatienummer van het waarderapport in. ',
  helpLinkText: 'Ik heb geen identificatienummer.',
  initialHelpMessage:
    'Ik probeer een waarderapport te maken maar weet mijn identificatienummer niet. Kunnen jullie mij helpen?',
};

const validate = (fields: FormFields) => {
  const errors: FormFields = {
    name: undefined,
    algasId: undefined,
  };
  if (Validation.String.isEmpty(fields.name)) {
    errors.name = text.noName;
  }

  if (Validation.String.isEmpty(fields.algasId)) {
    errors.algasId = text.noAlgasId;
  }

  return errors;
};

const AddPremiumValueReportSidebar = ({ isShowing, hide, onAdded }: Props) => {
  const account = useCurrentAccount();
  const [sendAgentEmail, setSendAgentEmail] = useState(true);
  const [sendContactEmail, setSendContactEmail] = useState(true);
  const intercom = useIntercom();

  return (
    <Drawer active={isShowing} hideSidebar={hide} title={text.drawerTitle}>
      <InsertAppPremiumValuationReportMutation
        name={cleanedFilename(__filename)}
        mutation={mutation}
        onCompleted={onAdded}
      >
        {(insertPremiumValuationReportApp, { error, loading }) => {
          const handleSubmit = (
            fields: FormFields,
            form: FormApi<FormFields>,
          ) => {
            const { name, algasId } = fields;

            if (name == null)
              throw Error(
                'Should not occur: Validation should have caught that name cannot be null',
              );

            if (algasId == null)
              throw Error(
                'Should not occur: Validation should have caught that identification number cannot be null',
              );

            return insertPremiumValuationReportApp({
              variables: {
                accountId: account.id,
                app: {
                  accountId: account.id,
                  name,
                  algasId,
                  sendAgentEmail,
                  sendContactEmail,
                },
              },
            }).then(result => {
              // result is undefined if an error occured, so don't reset then
              if (result) {
                setTimeout(form.reset, 0);
              }
            });
          };

          return (
            <Form
              onSubmit={handleSubmit}
              validate={validate}
              initialValues={{
                name: null,
                algasId: null,
              }}
            >
              {form => {
                const { handleSubmit, submitting, pristine } = form;

                return (
                  <form onSubmit={handleSubmit}>
                    {error ? <ErrorMsg>{text.submitErrorMsg}</ErrorMsg> : null}

                    <InputGroup>
                      <Field name="name">
                        {({ input, meta: { error, touched } }) => (
                          <Input
                            large
                            label={text.nameLabel}
                            type="text"
                            error={FormUtils.showError(error, touched)}
                            {...input}
                            disabled={loading}
                          />
                        )}
                      </Field>
                    </InputGroup>

                    <HelpText>
                      {text.helpText}
                      <LinkButton
                        onClick={() =>
                          intercom.openChat(text.initialHelpMessage)
                        }
                      >
                        {text.helpLinkText}
                      </LinkButton>
                    </HelpText>
                    <InputGroup>
                      <Field name="algasId">
                        {({ input, meta: { error, touched } }) => (
                          <Input
                            large
                            label={text.algasIdLabel}
                            type="text"
                            error={FormUtils.showError(error, touched)}
                            {...input}
                            disabled={loading}
                          />
                        )}
                      </Field>
                    </InputGroup>
                    <InputGroup>
                      <Checkbox
                        onChange={() => {
                          setSendContactEmail(!sendContactEmail);
                        }}
                        value={sendContactEmail}
                        label={text.sendContactEmailLabel}
                        name="sendContactEmail"
                      />
                    </InputGroup>
                    <InputGroup>
                      <Checkbox
                        onChange={() => {
                          setSendAgentEmail(!sendAgentEmail);
                        }}
                        value={sendAgentEmail}
                        label={text.sendAgentEmailLabel}
                        name="sendAgentEmail"
                      />
                    </InputGroup>

                    <ActionWrapper>
                      <Button
                        onClick={handleSubmit}
                        appearance="secondary"
                        size="medium"
                        type="submit"
                        disabled={submitting || pristine || loading}
                        data-testid={TEST_ID.ADD_BUTTON}
                        label={text.submit}
                      />
                    </ActionWrapper>
                  </form>
                );
              }}
            </Form>
          );
        }}
      </InsertAppPremiumValuationReportMutation>
    </Drawer>
  );
};

const HelpText = styled.p<{}>`
  font-style: italic;

  ${({ theme }) => `
    font-size: ${theme.fontSize('s')};
  `}
`;

const ActionWrapper = styled.div<{}>`
  display: flex;
  align-items: center;
  padding-top: 1em;

  & button {
    margin-left: auto;
  }
`;

const ErrorMsg = styled.div<{}>`
  ${({ theme }) => `
      color: ${theme.color('danger')};
    `};
`;

export default AddPremiumValueReportSidebar;
