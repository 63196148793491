import React, { useState, useEffect } from 'react';

import { OnChangeFunction, Option } from '../..';

import DropdownList from '../../DropdownList';
import { isNumber } from '~/util/Validation/Number';
import DropdownPositionedComponent from '~/components/DropdownPositionedComponent';
import useKeybinding from '~/hooks/useKeybinding';
import { add, subtract } from 'ramda';

export type Props = {
  options: Array<Option>;
  selectedOptionIdx?: number | null;
  onChange: OnChangeFunction;
  dropdownListOpen: boolean;
  onClickOutside: () => void;
  dataTestid?: string;
  className?: string;
  loading?: boolean;
  /** Removes highlighting of the selected option */
  disableActiveOption?: boolean;
};

const DropdownHidableList = ({
  options,
  onChange,
  dropdownListOpen,
  onClickOutside,
  dataTestid,
  className,
  loading,
  disableActiveOption,
  ...rest
}: Props) => {
  const [selectedOptionIdx, setSelectedOptionIdx] = useState<number>(
    rest.selectedOptionIdx || 0,
  );

  useEffect(() => {
    if (!dropdownListOpen && isNumber(selectedOptionIdx))
      setSelectedOptionIdx(rest.selectedOptionIdx || 0);
  }, [dropdownListOpen, selectedOptionIdx, rest.selectedOptionIdx]);

  useKeybinding({
    keys: ['up', 'down'],
    callback: (e: KeyboardEvent, key) => {
      if (!dropdownListOpen) return;
      const maxSelectedOptionIdx = options.length - 1;

      const bottomLimitSteps = Math.min(
        maxSelectedOptionIdx,
        add(selectedOptionIdx, 1),
      );
      const topLimitSteps = Math.max(0, subtract(selectedOptionIdx, 1));

      const movePosition = key === 'up' ? topLimitSteps : bottomLimitSteps;

      e.preventDefault();
      setSelectedOptionIdx(movePosition);
    },
  });

  const enterPress = () => {
    if (dropdownListOpen)
      onChange({
        option: options[selectedOptionIdx],
        selectedOptionIdx,
      });
  };

  useKeybinding({
    keys: 'enter',
    callback: enterPress,
  });
  if (dropdownListOpen)
    return (
      <DropdownPositionedComponent
        isVisible={dropdownListOpen}
        onClickOutside={onClickOutside}
        dataTestid={dataTestid}
        className={className}
      >
        <DropdownList
          loading={loading}
          options={options}
          onChange={onChange}
          selectedOptionIdx={selectedOptionIdx}
          disableActiveOption={disableActiveOption}
        />
      </DropdownPositionedComponent>
    );
  return null;
};

export default DropdownHidableList;
