import { $Shape } from 'utility-types';

/**
 *
 * A Hook to handle validation -> save mechanics. It will give the user:
 *  [0] - the function to call when attempting a save
 *  [1] - the validation result
 *
 * It expects:
 *  validateFn - The function to call when validation is needed. Should return null or an empty object if it is valid
 *  saveFn - What to do if the validation is succesfull
 */

import { useState } from 'react';

type ReturnProps<T> = [() => void, T | $Shape<{}>];

const useSaveValidation = <T>(
  validateFn: () => T | $Shape<{}>,
  saveFn: () => any,
): ReturnProps<T> => {
  const [validationResult, setValidationResult] = useState<T | $Shape<{}>>({});

  const attemptSave = () => {
    const newValidationResult = validateFn();
    setValidationResult(newValidationResult);

    let hasValidationError = false;
    Object.keys(newValidationResult).forEach(key => {
      if (newValidationResult[key] != null) {
        hasValidationError = true;
      }
    });

    if (!hasValidationError) {
      saveFn();
    }
  };

  return [attemptSave, validationResult];
};

export default useSaveValidation;
