import { FlowParameterFileFieldsFragment } from '~/graphql/types';
import { FileParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import asFilePointerParameterValue from './asFilePointerParameterValue';
import asFilePrimitiveParameterValue from './asFilePrimitiveParameterValue';

export default (
  fileParameter: FlowParameterFileFieldsFragment,
): FileParameterValue => {
  switch (fileParameter.__typename) {
    case PARAMETER_VALUE_TYPE.FILE_PRIMITIVE:
      return asFilePrimitiveParameterValue(fileParameter);
    case PARAMETER_VALUE_TYPE.FILE_POINTER:
      return asFilePointerParameterValue(fileParameter);
    default:
      throw new Error(
        `Should not occur: Unknown __typename (${fileParameter.__typename}) for FileParameter`,
      );
  }
};
