import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';

import { LinkButton } from '../Buttons';

import TEST_ID from './index.testid';
import Icon from '../Icon';

const text = {
  uploading: 'Uploaden...',
  uploadFile: 'Upload een bestand',
};
export type Props = {
  onUpload: (files: FileList) => void;
  uploading: boolean;
};
const ImageUploadTextButton = ({ onUpload, uploading }: Props) => {
  const fileUploader = useRef<any>(null);
  const _onUpload = useCallback(
    e => {
      if (e.target != null && e.target.files?.length) {
        onUpload(e.target.files);
      }
    },
    [onUpload],
  );

  return (
    <Container data-testid={TEST_ID.CONTAINER}>
      <StyledLinkButton
        onClick={() => {
          if (fileUploader.current) fileUploader.current.click();
        }}
        disabled={uploading}
      >
        <StyledUploadIcon name="upload" />
        {uploading ? (
          <span>{text.uploading}</span>
        ) : (
          <span>{text.uploadFile}</span>
        )}
      </StyledLinkButton>

      <input
        hidden
        ref={ref => (fileUploader.current = ref)}
        type="file"
        accept="image/*"
        onChange={_onUpload}
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    font-size: ${theme.fs('m')};
    font-weight: ${theme.fw('semiBold')};
    margin-bottom: ${theme.space('m')};
    ${theme.mq.lessThan('tabletLandscape')`
      justify-content: center;
    `}
  `}
`;

const StyledUploadIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('base')};
    font-size: ${theme.fs('xl')};
  `,
);

const StyledLinkButton = styled(LinkButton)<{}>`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export default ImageUploadTextButton;
