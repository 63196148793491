import { uniq } from 'ramda';
import { Node, Position } from 'reactflow';
import { ClientFlowAction } from '~/graphql/types.client';
import getCardLabel from '../../../../components/nodeTypes/components/CardTemplate/utils/getCardLabel';
import { HandlerId } from '../../../../components/nodeTypes/components/IfElseCard';
import getPortalColor from '../../../getPortalColor';
import getTargetPortalHandlePosition from '../getTargetPortalHandlePosition';

export type PortalData = {
  sourceId: string;
  targetId: string;
  color: string;
  label: string;

  /** Used in target portals */
  handlePosition?: Position;

  /**
   * Basically the same as sourceId but `parentId`
   * field is needed in createGraphLayout
   * to pass the correct parents to the tempObj element
   */
  parentId: string;

  /** Needed for source portals */
  ifElseChildKey?: HandlerId | null;
};

/**
 * Creates nodes for portals
 */
const getPortalCards = ({
  actions,
  portalColors,
}: {
  actions: Array<ClientFlowAction>;
  portalColors: Array<string>;
}): Array<Node> => {
  const sourceActions = actions.reduce((prev, parentAction) => {
    const childActions = actions.filter(
      action =>
        'parentIds' in action && action.parentIds.includes(parentAction.id),
    );

    for (const childAction of childActions) {
      if (!childAction) return prev;

      const primaryParentId =
        'parentIds' in childAction ? childAction?.parentIds?.[0] : null;

      if (primaryParentId !== parentAction.id) {
        prev.push(parentAction);
      }
    }

    return prev;
  }, [] as Array<ClientFlowAction>);

  const sourceCards = uniq(sourceActions).reduce((prev, node) => {
    if (node.__typename !== 'FlowV2_Action_IfElse') {
      const child = actions.find(
        a => 'parentIds' in a && a?.parentIds.includes(node.id),
      );

      if (child) {
        prev.push(
          getPortalSourceCard({
            id: node.id,
            targetAction: child,
          }),
        );
      }
    } else {
      if (node.trueChildId) {
        const child = actions.find(({ id }) => id === node.trueChildId);
        if (child && 'parentIds' in child && child.parentIds[0] !== node.id) {
          prev.push(
            getPortalSourceCard({
              id: node.id,
              targetAction: child,
              sourceHandle: HandlerId.trueChild,
            }),
          );
        }
      }

      if (node.falseChildId) {
        const child = actions.find(({ id }) => id === node.falseChildId);
        if (child && 'parentIds' in child && child.parentIds[0] !== node.id) {
          prev.push(
            getPortalSourceCard({
              id: node.id,
              targetAction: child,
              sourceHandle: HandlerId.falseChild,
            }),
          );
        }
      }
    }

    return prev;
  }, [] as Array<Node>);

  const portalSourceCards = sourceCards.map(card => ({
    ...card,
    data: {
      ...card.data,
      color: getPortalColor({
        portalColors,
        id: card.data.sourceId,
        sourceHandle: card.data.ifElseChildKey,
      }),
    },
  }));

  const portalTargetCards: Array<Node> = portalSourceCards.reduce(
    (prev: Array<Node>, sourceCard) => {
      if (!sourceCard) return prev;

      const sourceHandle = sourceCard.data.ifElseChildKey;

      const sourceAction = actions.find(a => a.id === sourceCard.data.sourceId);
      const targetAction = actions.find(a => a.id === sourceCard.data.targetId);
      if (!sourceAction || !targetAction) return prev;

      prev.push({
        id: generatePortalId({
          type: 'target',
          sourceId: sourceAction.id,
          targetId: targetAction?.id,
          sourceHandle,
        }),
        type: 'target_portal',
        data: {
          parentId: targetAction.id,
          sourceId: sourceAction.id,
          targetId: targetAction.id,
          color: sourceCard.data.color,
          label: getCardLabel(sourceAction.label),
          handlePosition: getTargetPortalHandlePosition({
            actions,
            sourceAction,
            targetAction,
          }),
          ifElseChildKey: sourceHandle,
        },
        position: { x: 0, y: 0 },
      });

      return prev;
    },
    [],
  );

  return [...portalSourceCards, ...portalTargetCards];
};

export default getPortalCards;

const getPortalSourceCard = ({
  id,
  targetAction,
  sourceHandle,
}: {
  id: string;
  targetAction: ClientFlowAction;
  sourceHandle?: HandlerId | null;
}): Node<PortalData> => ({
  id: generatePortalId({
    type: 'source',
    sourceId: id,
    targetId: targetAction.id,
    sourceHandle,
  }),
  type: 'source_portal',
  data: {
    parentId: id,
    sourceId: id,
    targetId: targetAction.id,
    // Actual color will be set later on
    color: '#ddd',
    label: getCardLabel(targetAction.label),
    ifElseChildKey: sourceHandle,
  },
  position: { x: 0, y: 0 },
});

export const generatePortalId = ({
  type,
  sourceId,
  targetId,
  sourceHandle,
}: {
  type: 'source' | 'target';
  sourceId: string;
  targetId: string;
  sourceHandle?: HandlerId | null;
}) => `${type}_portal_from_${sourceId}_to_${targetId}_${sourceHandle || ''}`;
