import type {
  FlowV2_ConditionList__Input,
  Flow___ArgumentFragment,
  Flow___Argument__Input,
  Flow___Condition__Input,
} from '~/graphql/types';
import { assertNever } from '~/util/assertNever';
import { Flow___ConditionPartial, Trigger } from '../..';
import { FlowConditionConnector } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/components/OperatorDropdown';

/**
 * Convert the trigger into a condition list input
 * @param {Trigger} trigger - Trigger
 * keywords:
 */
const getConditionListInputForTrigger = (
  triggers: Array<Trigger>,
  connector: FlowConditionConnector,
):
  | { error?: undefined; result: FlowV2_ConditionList__Input }
  | { error: string } => {
  const conditions: Array<Flow___Condition__Input> = [];
  let exp = '';

  for (let triggerIndex = 0; triggerIndex < triggers.length; ++triggerIndex) {
    const { trigger, condition } = triggers[triggerIndex];
    if (trigger == null) return { error: `Missing trigger` };

    const isFirst = triggerIndex === 0;

    if (!isFirst) {
      exp += connector === FlowConditionConnector.And ? ' && ' : ' || ';
    }

    exp += `(${conditions.length}`;

    const triggerInput = getConditionInputForCondition(trigger);
    if (triggerInput.error != null) return triggerInput;
    conditions.push(triggerInput.result);

    const conditionConnector =
      condition.connector === FlowConditionConnector.And ? ' && ' : ' || ';

    for (const subCondition of condition.conditions) {
      if (subCondition == null) return { error: 'subcondition is missing' };

      exp += `${conditionConnector}${conditions.length}`;
      const conditionInput = getConditionInputForCondition(subCondition);
      if (conditionInput.error != null) return conditionInput;

      conditions.push(conditionInput.result);
    }

    exp += ')';
  }

  return {
    result: {
      exp,
      conditions,
    },
  };
};

const getConditionInputForCondition = (
  condition: Flow___ConditionPartial,
):
  | { error?: undefined; result: Flow___Condition__Input }
  | { error: string } => {
  switch (condition.__typename) {
    case 'Flow___InstanceCondition': {
      const { id, input, args, type } = condition;

      const argsInput = getArgumentInput(args);
      if (argsInput.error != null) return argsInput;

      return {
        result: {
          InstanceCondition: {
            id,
            type,
            input,
            args: argsInput.result,
          },
        },
      };
    }
    case 'Flow___SubjectFieldCondition': {
      const { id, args, field, type, typeId } = condition;

      const argsInput = getArgumentInput(args);
      if (argsInput.error != null) return argsInput;

      return {
        result: {
          SubjectFieldCondition: {
            id,
            type,
            typeId,
            field,
            args: argsInput.result,
          },
        },
      };
    }
  }
};

const getArgumentInput = (
  args: Array<Flow___ArgumentFragment | null>,
):
  | { error?: undefined; result: Array<Flow___Argument__Input> }
  | { error: string } => {
  const result: Array<Flow___Argument__Input> = [];

  for (const argument of args) {
    if (!argument) return { error: 'Missing argument' };

    switch (argument.__typename) {
      case 'Flow___Argument_Pointer': {
        const {
          pointer: { path },
        } = argument;

        result.push({
          pointer: {
            path,
          },
        });
        break;
      }
      case 'Flow___Argument_File': {
        const {
          value_file: { contentLength, filename, s3key },
        } = argument;

        result.push({
          value_file: {
            contentLength,
            filename,
            s3key,
          },
        });
        break;
      }

      case 'Flow___Argument_AWSDateTime':
      case 'Flow___Argument_Boolean':
      case 'Flow___Argument_Float':
      case 'Flow___Argument_Integer':
      case 'Flow___Argument_String': {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...rest } = argument;
        result.push(rest);
        break;
      }
      default:
        return assertNever(argument);
    }
  }

  return { result };
};

export default getConditionListInputForTrigger;
