import React from 'react';
import styled, { css } from 'styled-components';
import NeutralLink from 'gatsby-link';

import Icon from '~/components/Icon';
import icons from '~/components/Icon/components';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  name: string;
  icon?: keyof typeof icons;
  to: string;
  issues?: number;
  state?: any;
};

const MasterLink: React.FC<Props> = ({
  name,
  icon,
  issues,
  dataTestId,
  ...rest
}) => {
  const hasIssues = !!issues;

  return (
    <Container
      activeClassName="active"
      partiallyActive={false}
      data-testid={dataTestId}
      {...rest}
    >
      {icon && <StyledIcon name={icon} />}

      <Title>{name}</Title>
      {hasIssues && <Issues data-testid={TEST_ID.ISSUES} $issues={issues} />}
    </Container>
  );
};

const Container = styled(NeutralLink)<{}>(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 4px solid transparent;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    text-decoration: none;
    color: ${theme.color('text', 'light')};

    padding: ${[
      theme.space('xxs'),
      '0',
      theme.space('xxs'),
      theme.space('s'),
    ].join(' ')};

    &.active {
      color: ${theme.color('info')};
      border-left: 4px solid ${theme.color('info')};
    }
  `,
);

const Title = styled.span<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('base')};
  `,
);

const Issues = styled.span<{ $issues?: number }>(
  ({ theme, $issues }) => css`
    position: relative;
    margin-left: ${theme.space('s')};
    color: ${theme.color('white')};
    background-color: ${theme.color('accent')};
    border-radius: 50%;
    text-align: center;
    width: 18px;
    height: 18px;
    font-weight: bold;
    font-size: ${theme.fontSize('s')};
    box-shadow: 0px 0px 3px 1px ${theme.color('accent')};

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      content: '${$issues}';
    }
  `,
);

const StyledIcon = styled(Icon)<{}>(
  () => css`
    font-size: 1.5em;
  `,
);

export default MasterLink;
