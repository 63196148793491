import { RouteComponentProps } from '@reach/router';
import MetaTags from 'react-meta-tags';
import React from 'react';
import styled from 'styled-components';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import OverviewListHeader from '~/components/OverviewListHeader';
import AccountUsersTable from './components/AccountUsersTable';

type Props = RouteComponentProps<{ accountId: string }>;

const AccountPage: React.FC<Props> = ({ ...rest }) => (
  <Container {...rest}>
    <MetaTags>
      <title>Account users</title>
    </MetaTags>

    <>
      <ContentContainerDefault>
        <OverviewListHeader title={'Account users'} />

        <AccountUsersTable accountId={rest.accountId} />
      </ContentContainerDefault>
    </>
  </Container>
);

const Container = styled.div<{}>``;

export default AccountPage;
