import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { FormApi } from 'final-form';
import { StartEmailSyncWithCustomProviderMutationVariables } from '~/graphql/types';

import mutation from '~/graphql/mutation/StartEmailSyncWithCustomProvider';
import { StartEmailSyncWithCustomProviderMutation } from '~/graphql/Mutation';
import { MarginlessH4 } from '~/components/Typography';
import SlideOpenAnimationContainer from '~/components/SlideOpenAnimationContainer';
import { InputGroup, Input } from '~/components/Inputs';
import { Form, EmailField, PasswordField } from '~/components/Forms';
import { FormUtils } from '~/components';
import Catalog from '~/Catalog';
import { LinkButton } from '~/components/Buttons';
import Button from '~/components/Button';
import { OptionHeader } from './ChooseEmailSyncTypeModal';
import Validation from '~/util/Validation';
import { isString } from 'lodash';
import cleanedFilename from '~/util/cleanedFilename';
import AccountContext from '~/contexts/AccountContext';
import ErrorTypes, { errorTypeFromMutationError } from '~/ErrorTypes';
import { SynchroniseEntityType } from '~/scenes/External/Nylas/util';
import TEST_ID from './ExpandableEmailSyncForm.testid';
import { assertNever } from '~/util/assertion';
import Icon from '~/components/Icon';
import { getErrorMessages } from '~/util/errorHandling';
import ErrorMessages from '~/ErrorMessages';

const text = {
  errorMsg: Catalog.genericUnknownErrorMessage,
  submit: 'Inloggen',
  header: 'Realworks e-mailadres',
  invalidCredentials:
    'De combinatie van e-mailadres en wachtwoord is niet geldig.',
  alreadySyncedExplanation: (email: string | null) =>
    `Het e-mail account ${email || ''} is al gesynchroniseerd in het systeem.`,
  loginHelp: 'Problemen met inloggen?',
  loginHelpLink:
    'https://help.dathuis.nl/nl/articles/3666351-hoe-synchroniseer-ik-mijn-realworks-e-mailadres',
  info: 'Gebruik de logingegevens van het e-mail account. Deze gegevens vind je ',
};

type Props = {
  loading: boolean;
  accountId: string;
  entityId: string;
  entityType: SynchroniseEntityType;
  onSuccessfulSync: () => void;
};

type FormData = {
  password: string | null;
  email: string | null;
};

const ExpandableEmailSyncForm = ({
  accountId,
  entityId,
  entityType,
  onSuccessfulSync,
}: Props) => {
  const { refetchSessionHydration } = useContext(AccountContext);

  const [isExpanded, setExpanded] = useState(false);

  return (
    <StartEmailSyncWithCustomProviderMutation
      name={cleanedFilename(__filename)}
      mutation={mutation}
      doNotReportErrorTypes={[ErrorTypes.customProviderSyncInvalidCredentials]}
    >
      {(startEmailSyncWithCustomProvider, { error, loading }) => {
        const handleSubmit = (fields: FormData, form: FormApi<FormData>) => {
          const password = fields.password ? fields.password : '';
          const email = fields.email ? fields.email.toLocaleLowerCase() : '';

          const providerInfo = {
            realworks: {
              name: email,
              email: email,
              imapUsername: email,
              imapPassword: password,
              smtpUsername: email,
              smtpPassword: password,
            },
          };

          const mutationVariables = (
            state: SynchroniseEntityType,
          ): StartEmailSyncWithCustomProviderMutationVariables => {
            switch (state) {
              case SynchroniseEntityType.Account:
                return {
                  accountId,
                  account: {
                    accountId: accountId,
                  },
                  provider: providerInfo,
                };
              case SynchroniseEntityType.Office:
                return {
                  accountId,
                  office: {
                    accountId: accountId,
                    officeId: entityId,
                  },
                  provider: providerInfo,
                };
              case SynchroniseEntityType.User:
                return {
                  accountId,
                  user: {
                    accountId: accountId,
                  },
                  provider: providerInfo,
                };

              default:
                return assertNever(state);
            }
          };

          const vars = mutationVariables(entityType);

          void startEmailSyncWithCustomProvider({
            variables: vars,
          }).then(async result => {
            if (result == null) return;

            const { data } = result;

            if (data == null) return;

            await refetchSessionHydration();
            onSuccessfulSync();

            form.reset();
          });
        };

        return (
          <Container>
            <OptionHeader
              onClick={() => {
                setExpanded(!isExpanded);
              }}
              data-testid={TEST_ID.SYNC_WITH_CUSTOM_PROVIDER_HEADER}
            >
              <MarginlessH4>{text.header}</MarginlessH4>
              <Icon name="chevron" flipX={isExpanded} />
            </OptionHeader>

            <SlideOpenAnimationContainer
              isOpen={isExpanded}
              useOverflowHidden={true}
            >
              {ref => (
                <InputFormContainer ref={ref}>
                  <Form
                    initialValues={{
                      email: null,
                      password: null,
                    }}
                    onSubmit={handleSubmit}
                    validate={validate}
                  >
                    {({ handleSubmit, submitting, values }) => {
                      let submitError = '';

                      if (error) {
                        if (
                          getErrorMessages(error).some(err =>
                            err.startsWith(
                              ErrorMessages.emailAlreadySynchronised,
                            ),
                          )
                        ) {
                          submitError = text.alreadySyncedExplanation(
                            values.email,
                          );
                        } else if (
                          errorTypeFromMutationError(error) ===
                          ErrorTypes.customProviderSyncInvalidCredentials
                        ) {
                          submitError = text.invalidCredentials;
                        } else {
                          submitError = text.errorMsg;
                        }
                      }

                      return (
                        <form
                          id="custom-signup-form"
                          onSubmit={handleSubmit}
                          data-testid={TEST_ID.SYNC_WITH_CUSTOM_PROVIDER_FORM}
                        >
                          {submitError ? (
                            <ErrorMsg
                              data-testid={
                                TEST_ID.SYNC_WITH_CUSTOM_PROVIDER_ERROR
                              }
                            >
                              {submitError}
                            </ErrorMsg>
                          ) : null}

                          <InfoContainer>
                            {text.info}{' '}
                            <a
                              href={text.loginHelpLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              data-testid={TEST_ID.LOGIN_HELP_LINK}
                            >
                              <LinkButton>hier</LinkButton>
                            </a>
                            .
                          </InfoContainer>

                          <InputGroup>
                            <EmailField name="email">
                              {({ input, meta: { error, touched } }) => (
                                <Input
                                  large
                                  label={Catalog.emailLabel}
                                  error={FormUtils.showError(error, touched)}
                                  disabled={loading}
                                  dataTestid={TEST_ID.SYNC_EMAIL_INPUT_FIELD}
                                  {...input}
                                />
                              )}
                            </EmailField>
                          </InputGroup>
                          <InputGroup>
                            <PasswordField name="password">
                              {({ input, meta: { error, touched } }) => (
                                <Input
                                  large
                                  label="Wachtwoord"
                                  type="password"
                                  error={FormUtils.showError(error, touched)}
                                  disabled={loading}
                                  autocomplete="current-password"
                                  dataTestid={TEST_ID.SYNC_PASSWORD_INPUT_FIELD}
                                  {...input}
                                />
                              )}
                            </PasswordField>
                          </InputGroup>

                          <ButtonWrapper>
                            <Button
                              size="medium"
                              onClick={handleSubmit}
                              type="submit"
                              disabled={loading}
                              dataTestId={
                                TEST_ID.SYNC_WITH_CUSTOM_PROVIDER_BUTTON
                              }
                              loading={submitting || loading}
                              label={text.submit}
                            />
                          </ButtonWrapper>
                        </form>
                      );
                    }}
                  </Form>
                </InputFormContainer>
              )}
            </SlideOpenAnimationContainer>
          </Container>
        );
      }}
    </StartEmailSyncWithCustomProviderMutation>
  );
};

const validate = ({ email, password }: FormData) => {
  const errors: {
    password: string | undefined;
    email: string | undefined;
  } = {
    password: undefined,
    email: undefined,
  };

  if (Validation.String.isEmpty(email)) {
    errors.email = Catalog.noEmail;
  } else if (!Validation.Email.isValid(email)) {
    errors.email = Catalog.invalidEmail;
  }

  const validPassword =
    password !== null && isString(password) && password.length > 0;

  if (!validPassword) {
    errors.password = Catalog.noPassword;
  }

  return errors;
};

const InfoContainer = styled.div<{}>`
  ${({ theme }) => `
    margin-bottom: ${theme.space('xxs')};
  `};
`;

const InputFormContainer = styled.div<{}>`
  ${({ theme }) => `
    font-size: ${theme.fontSize('base')};
    padding: ${theme.space('m')};
  `};
`;

const ErrorMsg = styled.div<{}>`
  ${({ theme }) => `
    color: ${theme.color('danger')};
  `};
`;

const ButtonWrapper = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Container = styled.div<{}>`
  width: 100%;
`;

export default ExpandableEmailSyncForm;
