import { SIGNATURE } from '~/components/SignatureContainer/constants';
import emptyAttachmentValue from '~/components/SignatureContainer/emptyAttachmentValue';
import {
  AttachmentType,
  SignatureAttachmentValue,
} from '~/components/SignatureContainer/types';
import { FLOW_EMAILATTACHMENT_TYPE } from '~/scenes/Automation/Flows/Actions/Base/FlowEmailAttachment/constants';
import emptyFilePrimitiveParameterValue from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/File/emptyFilePrimitiveParameterValue';
import { FlowEmailAttachmentValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { EmailEventAttachment } from '../types.flow';
import { UploadImageReturnType } from './uploadImageToStorage';

type AttachmentValue = FlowEmailAttachmentValue | SignatureAttachmentValue;

export const getAttachments = (
  attachmentType: AttachmentType,
  fileDetails: UploadImageReturnType,
  inlineId: string,
): AttachmentValue => {
  switch (attachmentType) {
    case SIGNATURE.ATTACHMENT: {
      return {
        type: SIGNATURE.ATTACHMENT,
        file: emptyAttachmentValue(fileDetails),
        inlineId,
      };
    }

    default: {
      return {
        type: FLOW_EMAILATTACHMENT_TYPE.FLOW_EMAILATTACHMENT,
        file: emptyFilePrimitiveParameterValue(fileDetails),
        inlineId,
      };
    }
  }
};

export const getAttachmentsFromHtml = (
  attachmentType: AttachmentType,
  fileDetails: EmailEventAttachment,
  inlineId: string,
  url: string | null,
): AttachmentValue => {
  switch (attachmentType) {
    case SIGNATURE.ATTACHMENT: {
      return {
        type: SIGNATURE.ATTACHMENT,
        file: emptyAttachmentValue(fileDetails),
        inlineId,
        url,
      };
    }

    default: {
      return {
        type: FLOW_EMAILATTACHMENT_TYPE.FLOW_EMAILATTACHMENT,
        file: emptyFilePrimitiveParameterValue(fileDetails),
        inlineId,
        url,
      };
    }
  }
};
