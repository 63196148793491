import React from 'react';

import { FlowActionFieldsFragment } from '~/graphql/types';
import { ActionSubscriber } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { AnnounceChangesOptions } from '~/scenes/Automation/Flows/Actions/util/renderActionTree';
import {
  ActionTreeNode,
  ParentedFlowActionProps,
  TraverseCallbackFn,
  KeyedActionSubscriber,
} from './types.flow';
import { GetPositionStringFn } from '~/scenes/Automation/Flows/types.flow';

import { globalVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import newActionPropForType from '~/scenes/Automation/Flows/Actions/util/newActionPropForType';
import cleanedFilename from '~/util/cleanedFilename';
import getActionTreeVersionFrom from './getActionTreeVersionFrom';
import traverse from './traverse';
import pushVariablesDown from './pushVariablesDown';
import {
  calculateInitialActionTree,
  calculateActionTree,
} from './calculateTrees';
import assignSubscribersToAction from './assignSubscribersToAction';
import getActionTreeNode from './getActionTreeNode';
import { useApolloClient } from '@apollo/client';

type ChildProps = {
  actionTrees: Array<ActionTreeNode>;
  assignSubscribers: (subscribers: Array<KeyedActionSubscriber>) => void;
  pushVariablesDownTree: () => void; // pushVariablesDownTree
  traverseAllNodes: (arg0: TraverseCallbackFn) => void;
  findSubscriber: (id: string) => ActionSubscriber | null; // find subscriber with the given id
  addAction: (actionType: FLOW_ACTION_TYPE, underneathActionId: string) => void; // add action after the given 'underneathAction' id
  deleteAction: (id: string) => void; // delete action with given id
  announceChanges: (options?: AnnounceChangesOptions) => void;
  getPositionStringForId: GetPositionStringFn;
};
type Props = {
  initialActions: Array<FlowActionFieldsFragment>;
  children: (props: ChildProps) => React.ReactNode;
  client: ReturnType<typeof useApolloClient>;
  accountId: string;
};
type State = {
  actionTreeVersion: string;
  outputLoading: { [actionId: string]: boolean } | Record<string, never>;
};

class FlowActionTrees extends React.Component<Props, State> {
  actionTrees: Array<ActionTreeNode> = calculateInitialActionTree(
    this.props.initialActions,
    {},
  );
  actionLength: number = this.props.initialActions.length;

  constructor(props: Props) {
    super(props);

    this.state = {
      actionTreeVersion: getActionTreeVersionFrom(
        calculateInitialActionTree(props.initialActions, {}),
      ),

      outputLoading: {},
    };
  }

  setOutputLoading = (loading: boolean, actionId: string) => {
    this.setState(
      {
        outputLoading: { ...this.state.outputLoading, [actionId]: loading },
      },
      () =>
        this.setActionTrees(
          calculateActionTree(
            this.getAllActionProps(),
            this.state.outputLoading,
          ),
        ),
    );
  };

  pushVariablesDownTree = async () => {
    const initialStash = globalVariableStash();

    for (const node of this.actionTrees) {
      await pushVariablesDown(
        node,
        initialStash,
        this.props.client,
        this.props.accountId,
        this.setOutputLoading,
      );
    }
  };

  assignSubscribers = (subscribers: Array<KeyedActionSubscriber>) => {
    this.actionTrees.forEach(node =>
      assignSubscribersToAction(node, subscribers),
    );

    if (this.actionLength === subscribers.length) {
      void this.pushVariablesDownTree();
    }
  };

  setActionTrees = (newActionTrees: Array<ActionTreeNode>) => {
    this.actionTrees = newActionTrees;

    let length = 0;
    this.traverseAllNodes(() => {
      length = length + 1;
    });
    this.actionLength = length;

    this.setState({
      actionTreeVersion: getActionTreeVersionFrom(this.actionTrees),
    });
  };

  traverseAllNodes = (callback: TraverseCallbackFn) => {
    this.actionTrees.forEach(node => traverse(node, callback));
  };

  getPositionStringForId = (id: string): string => {
    let positionString: string | null = null;

    this.traverseAllNodes(node => {
      if (node.id === id) {
        positionString = node.positionString;
      }
    });

    if (positionString == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} >>getPositionStringForId | Should not occur | could not find positionString for id ${id}`,
      );
    }

    return positionString;
  };

  findSubscriber = (id: string): ActionSubscriber | null => {
    let foundSubscriber: ActionSubscriber | null = null;

    this.traverseAllNodes(actionTree => {
      if (
        actionTree.subscriber != null &&
        actionTree.subscriber.actionId === id
      ) {
        foundSubscriber = actionTree.subscriber;
      }
    });

    return foundSubscriber;
  };

  getAllActionProps = (): Array<ParentedFlowActionProps> => {
    const allActionProps: Array<ParentedFlowActionProps> = [];

    this.traverseAllNodes(actionTree => {
      allActionProps.push({
        parentId: actionTree.parentId,
        props:
          actionTree.subscriber == null
            ? actionTree.initialActionProps
            : actionTree.subscriber.getActionProp(),
        subscriber: actionTree.subscriber,
      });
    });

    return allActionProps;
  };

  addAction = (actionType: FLOW_ACTION_TYPE, underneathActionId: string) => {
    const allActions = this.getAllActionProps();
    let underneathActionNode: ActionTreeNode | undefined;

    this.actionTrees.forEach(tree => {
      const node = getActionTreeNode(tree, underneathActionId);

      if (node != null) {
        underneathActionNode = node;
      }
    });

    // Create the newAction
    const newActionProps = newActionPropForType(actionType);

    // Add the action to the new array that will be set
    const newActions: Array<ParentedFlowActionProps> = [
      {
        parentId: underneathActionId,
        props: newActionProps,
        subscriber: null,
      },
    ];
    if (underneathActionNode == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} Should not occur >> addAction. underneathActionNode is null`,
      );
    }
    const actionToPutUnderneathSubscriber = underneathActionNode.subscriber;
    const actionToPutUnderneathType =
      underneathActionNode.initialActionProps.type;

    if (actionToPutUnderneathSubscriber == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} Should not occur >> addAction. actionToPutUnderneathSubscriber is null`,
      );
    }
    const actionToPutUnderneathProp =
      actionToPutUnderneathSubscriber.getActionProp();

    // Go through the action array
    allActions.forEach(action => {
      if (action.parentId === underneathActionId) {
        // if the action had the underneathAction as its parent the parent should be the new action
        if (actionToPutUnderneathType === FLOW_ACTION_TYPE.IF_ELSE) {
          // if we are putting it underneath an if/else statement then we should put it in the correct path

          if (actionToPutUnderneathProp.type !== FLOW_ACTION_TYPE.IF_ELSE) {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | actionToPutUnderneathProp.type !== FLOW_ACTION_TYPE.IF_ELSE`,
            );
          }

          if (
            actionToPutUnderneathProp.visiblePath &&
            actionToPutUnderneathProp.yesPathChildId === action.props.id
          ) {
            // if in the yes path and that one is visible then put in between
            action.parentId = newActionProps.id;
          } else if (
            !actionToPutUnderneathProp.visiblePath &&
            actionToPutUnderneathProp.noPathChildId === action.props.id
          ) {
            // if in the no path and that one is visible then put in between
            action.parentId = newActionProps.id;
          }
        } else {
          action.parentId = newActionProps.id;
        }

        // If we are adding an if/else statement. The child of the action we are adding underneath should be in the true path as default
        if (newActionProps.type === FLOW_ACTION_TYPE.IF_ELSE) {
          if (
            actionToPutUnderneathProp.type !== FLOW_ACTION_TYPE.IF_ELSE ||
            actionToPutUnderneathProp.yesPathChildId === action.props.id
          ) {
            newActionProps.yesPathChildId = action.props.id;
          }
        }
      }

      newActions.push(action);
    });

    if (newActions.length !== allActions.length + 1) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} Should not occur >> addAction. Adding an action of type ${actionType} underneath ${underneathActionId} does not increase the length. NewActions.length === ${
          newActions.length
        }. allActions.length === ${allActions.length}`,
      );
    }

    if (
      actionToPutUnderneathSubscriber &&
      actionToPutUnderneathSubscriber.onAddedActionUnderneath
    ) {
      actionToPutUnderneathSubscriber.onAddedActionUnderneath(newActionProps);
    }

    this.setActionTrees(
      calculateActionTree(newActions, this.state.outputLoading),
    );
  };

  /** @TODO Simplify this */
  deleteAction = (toDeleteId: string) => {
    let actionToDeleteNode: ActionTreeNode | null = null;
    const allActions = this.getAllActionProps();

    for (const tree of this.actionTrees) {
      const node = getActionTreeNode(tree, toDeleteId);

      if (node != null) {
        actionToDeleteNode = node;
        break;
      }
    }

    if (actionToDeleteNode == null) {
      return;
    }

    const parentActionOfDeletedNodeId = actionToDeleteNode.parentId;
    if (parentActionOfDeletedNodeId == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} >>deleteAction | Should not occur | Deleting a node that has no parent id!`,
      );
    }

    let parentActionOfDeletedNode: ActionTreeNode | null = null;

    for (const tree of this.actionTrees) {
      const node = getActionTreeNode(tree, parentActionOfDeletedNodeId);

      if (node != null) {
        parentActionOfDeletedNode = node;
        break;
      }
    }

    if (parentActionOfDeletedNode == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} >>deleteAction | Should not occur | Cannot find parent of deleted node with id ${parentActionOfDeletedNodeId}`,
      );
    }

    const parentActionOfDeletedNodeSubscriber =
      parentActionOfDeletedNode.subscriber;

    const newActions: Array<ParentedFlowActionProps> = [];
    let childOfDeletedId: string | null;
    // can check initialActionProps as we never change type
    if (
      actionToDeleteNode.initialActionProps.type === FLOW_ACTION_TYPE.IF_ELSE
    ) {
      const idsToDelete: Array<string> = [];
      traverse(actionToDeleteNode, node => {
        idsToDelete.push(node.id);
      });

      allActions.forEach(action => {
        if (idsToDelete.includes(action.props.id)) {
          // do nothing, have them removed
        } else {
          newActions.push(action);
        }
      });

      // we always delete the whole tree, so don't have a child id
      childOfDeletedId = null;
    } else {
      // update parentIds and ignore the given action id
      allActions.forEach(action => {
        if (action.props.id === toDeleteId) {
          // do nothing!
        } else {
          if (
            actionToDeleteNode == null ||
            actionToDeleteNode.parentId == null
          ) {
            throw Error(
              `${cleanedFilename(__filename)} | Should not occur | ${
                actionToDeleteNode == null ? 'actionToDeleteNode is NULL' : ''
              } ${
                actionToDeleteNode != null &&
                actionToDeleteNode.parentId == null
                  ? 'actionToDeleteNode.parentId is NULL'
                  : ''
              }`,
            );
          }

          if (action.parentId === toDeleteId) {
            action.parentId = actionToDeleteNode.parentId;
          }

          newActions.push(action);
        }
      });

      if (newActions.length !== allActions.length - 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} Should not occur >> deleteAction. Deleting an action with id ${toDeleteId} does not reduce length. NewActions.length === ${
            newActions.length
          }. allActions.length === ${allActions.length}`,
        );
      }

      if (actionToDeleteNode.children.length === 0) {
        childOfDeletedId = null;
      } else if (actionToDeleteNode.children.length === 1) {
        childOfDeletedId = actionToDeleteNode.children[0].id;
      } else {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} >> deleteAction | Should not occur | Can not determine childOfDeletedId, as node ${JSON.stringify(
            actionToDeleteNode,
            null,
            2,
          )} has multiple children`,
        );
      }
    }
    if (
      parentActionOfDeletedNodeSubscriber &&
      parentActionOfDeletedNodeSubscriber.onDeletedActionUnderneath
    ) {
      parentActionOfDeletedNodeSubscriber.onDeletedActionUnderneath(
        toDeleteId,
        childOfDeletedId,
      );
    }

    this.setActionTrees(
      calculateActionTree(newActions, this.state.outputLoading),
    );
  };

  announceChanges = (options?: AnnounceChangesOptions) => {
    const { updateVariableStash = false } = options == null ? {} : options;
    if (updateVariableStash === true) {
      void this.pushVariablesDownTree();
    } else {
      const newTreeVersion = getActionTreeVersionFrom(this.actionTrees);
      if (newTreeVersion !== this.state.actionTreeVersion) {
        this.setState({
          actionTreeVersion: newTreeVersion,
        });
      }
    }
  };

  render() {
    const { children } = this.props;

    const childProps: ChildProps = {
      actionTrees: this.actionTrees,
      assignSubscribers: this.assignSubscribers,
      pushVariablesDownTree: this.pushVariablesDownTree,
      traverseAllNodes: this.traverseAllNodes,
      findSubscriber: this.findSubscriber,
      addAction: this.addAction,
      deleteAction: this.deleteAction,
      announceChanges: this.announceChanges,
      getPositionStringForId: this.getPositionStringForId,
    };

    return children(childProps);
  }
}

const FlowActionTreesThingyBobby: React.FC<Omit<Props, 'client'>> = props => {
  const client = useApolloClient();

  return (
    <FlowActionTrees {...props} client={client} accountId={props.accountId} />
  );
};

export default FlowActionTreesThingyBobby;
