import React from 'react';
import { isNil } from 'ramda';

import { BooleanPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { Option } from '~/components/Inputs/SelectGroup';

import { SelectGroup } from '~/components/Inputs';
import Catalog from '~/Catalog';
import TEST_ID from './BooleanPrimitiveParameterValueComponent.testid';

type Props = {
  value: BooleanPrimitiveParameterValue;
  onChange: (newValue: BooleanPrimitiveParameterValue) => void;
};
const BooleanPrimitiveParameterValueComponent = ({
  value,
  onChange,
}: Props) => {
  const { value: inputValue } = value;

  const selectedIdx = options.findIndex(option => option.value === inputValue);

  return (
    <SelectGroup
      options={options}
      selectedIndex={selectedIdx}
      dataTestid={TEST_ID.CONTAINER}
      onChange={selectedOption => {
        if (isNil(selectedOption) || isNil(selectedOption.option)) return;
        const newValue = selectedOption.option.value;

        onChange({
          ...value,
          value: newValue,
        });
      }}
    />
  );
};

const options: Array<Option> = [
  {
    label: Catalog.yes,
    value: true,
    dataTestid: 'true',
  },
  {
    label: Catalog.no,
    value: false,
    dataTestid: 'false',
  },
];

export default BooleanPrimitiveParameterValueComponent;
