import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import NewAccountSubscriptionFlow from '../ChangeOrUpdateSubscriptionFlows/NewAccountSubscriptionFlow';
import AccountContext from '~/contexts/AccountContext';
import useIntercom from '~/hooks/useIntercom';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import { INITIAL_SUBSCRIPTION } from '~/util/localStorageKeys';

const SetupSubscriptionPage: React.FC = () => {
  const isMountedRef = useIsMountedRef();
  const { refetchSessionHydration } = useContext(AccountContext);
  const intercom = useIntercom();

  return (
    <NewAccountSubscriptionFlow
      onComplete={async () => {
        localStorage.removeItem(INITIAL_SUBSCRIPTION);

        intercom.update({
          first_admin: true,
          signed_up_at: new Date().getTime() * 1000,
        });

        await refetchSessionHydration();
        if (isMountedRef.current) void navigate('/-/');
      }}
    />
  );
};

export default SetupSubscriptionPage;
