import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import {
  calculateSpaceInsideComponent,
  calculateFontSize,
} from '~/components/util/getSizeCalculation';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

type Props = {
  small?: boolean;
  large?: boolean;
  type?: 'submit' | 'reset' | 'button';
  round?: boolean;
  danger?: boolean;
  disabled?: boolean;
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  children: ReactElement;
};

const IconButton = ({
  type = 'button',
  onClick,
  disabled,
  children,
  danger,
  small,
  large,
  round,
  ...rest
}: Props) => (
  <IconButtonElement
    $danger={danger}
    $small={small}
    $large={large}
    $round={round}
    type={type}
    onClick={e => {
      if (!disabled && onClick) {
        onClick(e);
      }
    }}
    disabled={disabled}
    {...rest}
  >
    {children}{' '}
  </IconButtonElement>
);

type ButtonProps = {
  $danger: Props['small'];
  $small: Props['large'];
  $large: Props['round'];
  $round: Props['danger'];
};
const IconButtonElement = styled.button<ButtonProps>`
  border: none;
  background: transparent;
  transition: background 0.3s ease-out, color 0.3s ease-out;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    & svg {
      font-size: ${FONT_SIZE_OF_18}px;
    }
  `}

  ${({ theme, disabled }) =>
    disabled
      ? css`
          color: ${theme.color('grey')};
        `
      : css`
          cursor: pointer;
        `}



  ${({ theme, disabled, $danger }) => {
    if (!disabled) {
      if ($danger) {
        return css`
          &:hover {
            color: ${theme.color('white')};
            background: ${theme.color('danger')};
          }
        `;
      } else {
        // same as darken on the buttons
        return css`
          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }
        `;
      }
    }

    return '';
  }}

  ${({ theme, $small, $large }) => css`
    padding: ${calculateSpaceInsideComponent(theme, $small, $large)}px;
    font-size: ${calculateFontSize(theme, $small, $large)}px;
  `};

  ${({ $round }) => {
    if ($round) {
      return css`
        border-radius: 50%;
      `;
    }

    return '';
  }}
`;

export default IconButton;
