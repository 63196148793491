import { navigate, RouteComponentProps } from '@reach/router';
import { useTheme } from 'styled-components';
import React, { useEffect, useState } from 'react';
import EmptyStateComponent from '~/components/EmptyStateComponent';
import AppDetailsContainer from '~/scenes/Apps/components/AppDetailsContainer';
import TEST_ID from './index.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import {
  AppStatusRealworksFieldsFragment,
  Exact,
  GetAppStatusRealworksQuery,
  RealworksRelatieSyncType,
  UpdateRealworksAppStatusMutationVariables,
  useGetAppStatusRealworks_RealworksKenmerkenQuery,
  useUpdateRealworksAppStatusMutation,
} from '~/graphql/types';
import { Heading4, Body, Variant } from '~/components/Typography/index';
import RealworksSelectableAccordion, {
  Kenmerk,
  KenmerkLookup,
} from '../RealworksSelectableAccordion';
import { transformBackendKenmerken, transformFrontendKenmerken } from './util';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import JustificationContainer from '~/components/JustificationContainer';
import { isEmpty, isNil } from 'ramda';
import { findDifference } from '~/util/object';

import { NoResultSection } from '~/components';
import TextButton from '~/components/TextButton';
import { animated, useSpring } from 'react-spring';
import { ANIMATION_CONFIG } from '~/scenes/Settings/constants';
import { WatchQueryOptions } from '@apollo/client';
import CaretDropdownButton from '~/components/CaretDropdownButton';
import { isValid } from '~/util/Validation/Tag';
import RadioButton from '~/components/RadioButton';

type Props = {
  appStatus: AppStatusRealworksFieldsFragment;
  updateQuery: <TVars = Exact<{ accountId: string }>>(
    mapFn: (
      previousQueryResult: GetAppStatusRealworksQuery,
      options: Pick<
        WatchQueryOptions<TVars, GetAppStatusRealworksQuery>,
        'variables'
      >,
    ) => GetAppStatusRealworksQuery,
  ) => void;
} & RouteComponentProps;

const Synchronization: React.FC<Props> = ({ appStatus, updateQuery }) => {
  const theme = useTheme();
  const addToast = useAddToast();
  const { id: accountId } = useCurrentAccount();
  const [waitingForNewData, setWaitingForNewData] = useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);

  useEffect(() => {
    setWaitingForNewData(false);
  }, [appStatus]);

  const [syncType, setSyncType] = useState<RealworksRelatieSyncType>(
    appStatus.relatieSync.type,
  );

  const isRelatieSyncEnabled = appStatus.relatieSync.enabled;

  const styles = useSpring({
    from: {
      transform: isRelatieSyncEnabled ? 'translateX(-40px)' : 'translateX(0px)',
      opacity: isRelatieSyncEnabled ? 0 : 1,
    },
    to: {
      transform: isRelatieSyncEnabled ? 'translateX(0px)' : 'translateX(-40px)',
      opacity: isRelatieSyncEnabled ? 1 : 0,
    },
    config: ANIMATION_CONFIG.config,
  });

  const [initialKenmerkLookup, setInitialKenmerkLookup] =
    useState<KenmerkLookup | null>(null);
  const [updatedKenmerkLookup, setUpdatedKenmerkLookup] =
    useState<KenmerkLookup | null>(null);

  const { data, loading: fetchingRealworksKenmerken } =
    useGetAppStatusRealworks_RealworksKenmerkenQuery({
      variables: { accountId },
    });

  const realworksKenmerken = data?.getAppStatusRealworks_RealworksKenmerken;
  const kenmerkMapping = appStatus?.relatieSync?.kenmerkMapping;

  useEffect(() => {
    // We set the lookup tables in a useEffect because it has to rerun after every change to kenmerkMapping.
    // This way it estimates the differenceCount correctly.
    if (realworksKenmerken) {
      const initialLookup = transformBackendKenmerken(
        {
          kenmerkMapping,
          realworksKenmerken,
        },
        { shouldUseDefaultTags: false },
      );
      const updatedLookup = transformBackendKenmerken(
        {
          kenmerkMapping,
          realworksKenmerken,
        },
        { shouldUseDefaultTags: true },
      );

      setUpdatedKenmerkLookup(updatedLookup);
      setInitialKenmerkLookup(initialLookup);
    }
  }, [realworksKenmerken, kenmerkMapping]);

  const [updateRealworksAppStatus, { loading: updating }] =
    useUpdateRealworksAppStatusMutation();

  const original = {
    ...initialKenmerkLookup,
    syncType: appStatus.relatieSync.type,
  };
  const updated = { ...updatedKenmerkLookup, syncType };

  const { differenceCount } = findDifference(original, updated);

  const onUpdate = (
    variables: UpdateRealworksAppStatusMutationVariables,
    { errorToast, successToast }: { errorToast: string; successToast: string },
  ) => {
    setWaitingForNewData(true);

    if (updatedKenmerkLookup) {
      return updateRealworksAppStatus({
        variables,
      }).then(({ errors, data }) => {
        if (errors && errors.length !== 0) {
          addToast([formatToastMessage(errorToast, 'danger')]);
          setWaitingForNewData(false);
          return;
        }

        if (!data) {
          setWaitingForNewData(false);
          return;
        }

        updateQuery(prevRes => ({
          ...prevRes,
          getAppStatusRealworks: data.updateRealworksAppStatus,
        }));

        addToast([formatToastMessage(successToast, 'success')]);
      });
    }

    return;
  };

  const onChange = ({
    groupName,
    kenmerkList,
  }: {
    groupName: string;
    kenmerkList: Array<Kenmerk>;
  }) => {
    setHasValidationError(
      kenmerkList.some(
        // Only validate when tag is not null and empty
        ({ tag }) => !isNil(tag) && !isEmpty(tag) && !isValid(tag),
      ),
    );
    setUpdatedKenmerkLookup(prevState => ({
      ...prevState,
      [groupName]: kenmerkList,
    }));
  };

  if (fetchingRealworksKenmerken) return null;
  if (!data || appStatus?.enabled === false)
    return (
      <AppDetailsContainer
        header="Synchronisatie"
        icon="reload"
        loading={updating}
        dataTestId={TEST_ID.CONTAINER}
      >
        <EmptyStateComponent
          header={
            <>
              Je hebt nog geen koppelingen <br />
              met Realworks.
            </>
          }
          illustration="box"
          buttonLabel="Toevoegen"
          onButtonClick={() =>
            navigate('/-/apps/realworks/wizard/', { replace: true })
          }
          blobColor={theme.color('grey', 'light')}
        />
      </AppDetailsContainer>
    );

  if (
    !realworksKenmerken ||
    isNil(updatedKenmerkLookup) ||
    isEmpty(updatedKenmerkLookup)
  ) {
    return (
      <AppDetailsContainer
        header="Synchronisatie"
        loading={false}
        dataTestId={TEST_ID.CONTAINER}
      >
        <NoResultSection>
          Geen Realworks kenmerken gevonden voor de actieve relatie token. Voeg
          een andere token toe of probeer het later opnieuw.
        </NoResultSection>
      </AppDetailsContainer>
    );
  }

  return (
    <AppDetailsContainer
      header="Synchronisatie"
      loading={false}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body margin={['xl', null]}>
        Kies welke contacten je wilt synchroniseren met DatHuis. Je kunt ook
        kenmerken uit RealWorks automatisch omzetten naar een tag in DatHuis.
        <br />
      </Body>
      <Heading4 variant={Variant.primary}>
        Welke relaties wil je synchroniseren?
      </Heading4>
      <RadioButton
        checked={syncType === RealworksRelatieSyncType.Full}
        onChange={() => setSyncType(RealworksRelatieSyncType.Full)}
        label="Alle relaties"
        dataTestid={TEST_ID.FULL_OPTION}
      />
      <RadioButton
        checked={syncType === RealworksRelatieSyncType.LimitToKenmerken}
        onChange={() => setSyncType(RealworksRelatieSyncType.LimitToKenmerken)}
        label="Alleen relaties met geselecteerde kenmerken"
        dataTestid={TEST_ID.LIMITED_TO_KENMERKEN_OPTION}
      />
      <RealworksSelectableAccordion
        kenmerkLookup={updatedKenmerkLookup}
        onChange={onChange}
        selectable={syncType === RealworksRelatieSyncType.LimitToKenmerken}
      />

      <JustificationContainer
        justification={styles.opacity ? 'space-between' : 'end'}
        margin={['l', null, null]}
      >
        <TextButton
          label="Deactiveer synchronisatie"
          appearance="danger"
          disabled={updating || waitingForNewData}
          onClick={() =>
            onUpdate(
              {
                accountId,
                updateRelatieSync: {
                  enabled: false,
                  /** I have decided to discard unsaved changes here */
                  type: appStatus.relatieSync.type,
                  kenmerkMapping:
                    transformFrontendKenmerken(initialKenmerkLookup),
                },
              },
              {
                errorToast:
                  'Er is iets fout gegaan bij het deactiveren van de synchronisatie, probeer het later opnieuw',
                successToast: 'Synchronisatie gedeactiveerd',
              },
            )
          }
          dataTestId={TEST_ID.DEACTIVATE_BUTTON}
          as={animated.button}
          style={{ alignSelf: 'flex-end', ...(styles as any) }}
        />

        <CaretDropdownButton
          mainButtonOption={{
            label: isRelatieSyncEnabled ? 'Opslaan' : 'Opslaan en activeren',
            onClickAction: () =>
              onUpdate(
                {
                  accountId,
                  updateRelatieSync: {
                    enabled: true,
                    type: syncType,
                    kenmerkMapping:
                      transformFrontendKenmerken(updatedKenmerkLookup),
                  },
                },
                {
                  errorToast:
                    'Er is iets fout gegaan bij het synchroniseren, probeer het later opnieuw',
                  successToast: !isRelatieSyncEnabled
                    ? 'Synchronisatie geactiveerd'
                    : 'Wijzigingen opgeslagen',
                },
              ),
          }}
          dropdownOptions={[
            {
              label: isRelatieSyncEnabled
                ? 'Opslaan en deactiveren'
                : 'Opslaan',
              onClickAction: () =>
                onUpdate(
                  {
                    accountId,
                    updateRelatieSync: {
                      enabled: false,
                      type: syncType,
                      kenmerkMapping:
                        transformFrontendKenmerken(updatedKenmerkLookup),
                    },
                  },
                  {
                    errorToast: `Er is iets fout gegaan bij het ${
                      isRelatieSyncEnabled
                        ? 'opslaan en deactiveren van de synchronisatie'
                        : 'opslaan van de wijzigingen'
                    }, probeer het later opnieuw`,
                    successToast: isRelatieSyncEnabled
                      ? 'Synchronisatie gedeactiveerd'
                      : 'Wijzigingen opgeslagen',
                  },
                ),
              type: differenceCount === 0 ? 'DISABLED' : undefined,
            },
          ]}
          dataTestId={TEST_ID.SAVE_BUTTON}
          loading={updating || waitingForNewData}
          disabled={
            (differenceCount === 0 && isRelatieSyncEnabled) ||
            hasValidationError
          }
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default Synchronization;
