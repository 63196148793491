import { FlowConditionContactTag } from './types.flow';

import { emptyBooleanPrimitiveParameterValue } from '../../FlowParameter/ParameterValue/Boolean';
import { emptyStringPrimitiveParameterValue } from '../../FlowParameter';
import { FLOW_CONDITION_TYPES } from '../constants';

export default (): Omit<FlowConditionContactTag, 'id'> => ({
  type: FLOW_CONDITION_TYPES.CONTACT_TAG.type,
  exists: emptyBooleanPrimitiveParameterValue(true),
  tagName: emptyStringPrimitiveParameterValue(),
});
