import React from 'react';
import styled, { css } from 'styled-components';

import Modal from '~/components/Modals/Modal';
import SmallModalContainer from './components/SmallModalContainer';
import ModalInfoContainer from './components/ModalInfoContainer';
import ModalHeadline from './components/ModalHeadline';
import TEST_ID from './ErrorModal.testid';
import BottomButtonRow from '~/components/Modals/BottomButtonRow';
import Button from '~/components/Button';

const text = {
  closeButtonLabel: 'Sluiten',
};
type Props = {
  /** Title of modal */
  title: string;

  /** Message of modal */
  message: string | React.ReactNode;

  /** Called when the modal is closed */
  onClose: () => void;
};
export const ErrorModalContent = ({ title, message, onClose }: Props) => {
  let messageComponent = message;

  if (typeof message === 'string') {
    const messageParagraphs = message.split(/\r?\n/);

    messageComponent = messageParagraphs.map((par, idx) => (
      <p key={`p-${idx}`}>{par}</p>
    ));
  }

  return (
    <SmallModalContainer data-testid={TEST_ID.CONTAINER}>
      <ModalInfoContainer>
        <StyledModalHeadline>{title}</StyledModalHeadline>
        {messageComponent}
      </ModalInfoContainer>
      <BottomButtonRow>
        <Button
          data-testid={TEST_ID.CLOSE_BUTTON}
          onClick={onClose}
          label={text.closeButtonLabel}
        />
      </BottomButtonRow>
    </SmallModalContainer>
  );
};

const ErrorModal = (props: Props) => (
  <Modal small onClose={props.onClose}>
    <ErrorModalContent {...props} />
  </Modal>
);

const StyledModalHeadline = styled(ModalHeadline)<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);

export default ErrorModal;
