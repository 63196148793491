import React from 'react';

import { Highlight } from '~/components/Typography';
import { H2White } from './PromoSection.style';

const PromoDefault = () => (
  <React.Fragment>
    <H2White>
      Efficiënt van leads <Highlight>klanten</Highlight> maken
    </H2White>
  </React.Fragment>
);

export default PromoDefault;
