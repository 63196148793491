import { TemplateStringParameterField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { Flow_Parameter__TemplateString__Input } from '~/graphql/types';

import { asTemplateStringParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

export default (
  parameter: TemplateStringParameterField,
): Flow_Parameter__TemplateString__Input => {
  const { value } = parameter;
  if (value == null) {
    throw new Error(
      '>>asFlowParameterTemplateStringInput - Should not occur. Validation should have prevented input from being null!',
    );
  }

  return asTemplateStringParameterValueInput(value);
};
