import gql from 'graphql-tag';

import InvoiceItemFields from '../fragments/InvoiceItemFields';

export default gql`
  query GetApproximateUpcomingOveragesCharge($accountId: ID!) {
    getApproximateUpcomingOveragesCharge(accountId: $accountId) {
      amount
      lastUpdatedDate
      cycleEndDate
      items {
        ...InvoiceItemFields
      }
    }
  }

  ${InvoiceItemFields}
`;
