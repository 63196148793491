import React from 'react';
import styled from 'styled-components';
import { FlowV2_TemplateStringFragment } from '~/graphql/types';
import useErrorReporter from '~/hooks/useErrorReporter';
import Variable from './components/Variable';
import { TEMPLATE_STRING_VARIABLE_REGEX } from '~/components/PluginsEditor/constants';

export type Props = {
  dataTestId?: string;
  templateString: FlowV2_TemplateStringFragment;
  /** If template string contains html tags pass them as dangerouslySetInnerHTML */
  isHtml?: boolean;
  actionId: string;
};

const REGEX = /\s*(\{\{[a-zA-Z0-9_]+\}\})\s*/g;
const isVariable = (str: string) => str.includes('{{');

const TemplateString: React.FC<Props> = ({
  dataTestId,
  templateString,
  isHtml,
  actionId,
  ...rest
}) => {
  const reporter = useErrorReporter();
  const groups = templateString.template?.split(REGEX);

  if (!groups) {
    reporter.captureException(
      new Error(
        `Could not get groups from template string ${templateString.template}`,
      ),
    );
    return null;
  }

  return (
    <Container data-testid={dataTestId} {...rest}>
      {groups.map((group, index) => {
        const textBeforeVariable = group.split('{{')?.[0] || '';

        return (
          <React.Fragment key={`${group}-${index}`}>
            {isVariable(group) ? (
              <>
                {textBeforeVariable}
                <Variable
                  actionId={actionId}
                  id={
                    group
                      .match(TEMPLATE_STRING_VARIABLE_REGEX)?.[0]
                      .replace('{{', '')
                      .replace('}}', '') || ''
                  }
                  mappings={templateString.mappings}
                />
              </>
            ) : (
              <>
                {isHtml ? (
                  <span dangerouslySetInnerHTML={{ __html: group }} />
                ) : (
                  <span>{group}</span>
                )}
              </>
            )}
            {index !== groups.length - 1 && <>&nbsp;</>}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

const Container = styled.div<{}>``;

export default TemplateString;
