import { DHEditor } from '../types';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';

export const inlineElements = [
  ELEMENTS.IMAGE,
  ELEMENTS.DH_IMAGE,
  ELEMENTS.VARIABLE,
  ELEMENTS.SPAN,
  ELEMENTS.LINK,
];

const voidElements = [ELEMENTS.IMAGE, ELEMENTS.DH_IMAGE, ELEMENTS.VARIABLE];
/** Self closing tags should be the same as in GenericHtmlElement/index */
const selfClosingTags = [
  'area',
  'hr',
  'col',
  'embed',
  'hr',
  'input',
  'param',
  'source',
  'track',
];

/**
 * Declares all the inline and void elements in the editor.
 *
 * If an element is void its content is not editable. Deleting one character at the end removes the whole element.
 * See: https://docs.slatejs.org/concepts/02-nodes#voids
 *
 * Inline vs Block:
 * All elements are blocks by default. This means they can have block or inline children.
 *
 * Inline and block elements can never be siblings. If an element disappears unexpectedly when you're editing,
 * it is probably an inline element that is a sibling of a block element.
 * See: https://docs.slatejs.org/concepts/02-nodes#blocks-vs.-inlines
 *
 * Check this list to decide if an element should be inline or not:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Inline_elements#list_of_inline_elements
 *
 * @param editor DHEditor
 * @returns DHEditor
 */
const withInlineVoidElements = (editor: DHEditor): DHEditor => {
  const { isVoid, isInline } = editor;

  editor.isInline = el =>
    inlineElements.includes(el.type) ||
    (el.type === ELEMENTS.GENERIC_HTML_ELEMENT && inlines.includes(el.name)) ||
    isInline(el);

  editor.isVoid = el =>
    voidElements.includes(el.type) ||
    (el.type === ELEMENTS.GENERIC_HTML_ELEMENT &&
      selfClosingTags.includes(el.name)) ||
    isVoid(el);

  return editor;
};

export default withInlineVoidElements;

/** List made from https://developer.mozilla.org/en-US/docs/Web/HTML/Inline_elements */
export const inlines = [
  'a',
  'abbr',
  'acronym',
  'audio',
  'b',
  'bdi',
  'bdo',
  'big',
  'br',
  'button',
  'canvas',
  'cite',
  'code',
  'data',
  'datalist',
  'del',
  'dfn',
  'em',
  'embed',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'map',
  'mark',
  'meter',
  'noscript',
  'object',
  'output',
  'picture',
  'progress',
  'q',
  'ruby',
  's',
  'samp',
  'script',
  'select',
  'slot',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'svg',
  'template',
  'textarea',
  'time',
  'u',
  'tt',
  'var',
  'video',
  'wbr',
];
