import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { RouteComponentProps } from '@reach/router';

import { Heading1 } from '~/components/Typography/index';
import { UnalignedButton } from '~/components/Button';

type Props = RouteComponentProps & {
  /** Error title to render. Last word is highlighted */
  title: string;

  /** The explanation underneath the title */
  explanation: string;

  /** When the button is clicked */
  goToLink?: () => any;

  /** Text on the button */
  goToLinkText?: string;

  /** Data test id to show */
  dataTestid?: string;
};
const FullPageInformation = ({
  explanation,
  goToLink,
  goToLinkText,
  title,
  dataTestid,
}: Props) => {
  // highlighting last word works for all situations atm, if that is not the case later rewrite it
  const lastSpace = title.lastIndexOf(' ');
  const highlightedPart = lastSpace > 0 ? title.slice(lastSpace + 1) : '';

  const explanationTokens = explanation.split('\n');

  let goToButtonComponent: ReactElement | null = null;
  if (typeof goToLink === 'function' && typeof goToLinkText === 'string') {
    goToButtonComponent = (
      <UnalignedButton
        appearance="accent"
        size="medium"
        onClick={goToLink}
        data-testid={'go-to-link-button'}
        label={goToLinkText}
      />
    );
  }

  return (
    <Container data-testid={dataTestid || 'full-info-page'}>
      <TextContainer>
        <Heading1
          withoutMargin
          dataTestId="full-page-title"
          highlight={highlightedPart}
        >
          {title}
        </Heading1>
        <SmallDescriptionText data-testid="full-page-explanation">
          {explanationTokens.map((explanationString, index) => (
            <p key={`explanation-index-${index}`}>{explanationString}</p>
          ))}
        </SmallDescriptionText>

        {goToButtonComponent}
      </TextContainer>
    </Container>
  );
};

// The text is not long, so don't make it span a whole page. 420 gave the best view for dutch
const MAX_ERROR_TEXT_WIDTH = '450px';
const SmallDescriptionText = styled.div<{}>`
  max-width: ${MAX_ERROR_TEXT_WIDTH};
  text-align: center;

  ${({ theme }) => css`
    margin: ${theme.space('m')} 0;
  `};
`;

const TextContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div<{}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default FullPageInformation;
