import React from 'react';
import styled from 'styled-components';

import { asPriceElement } from '~/util/money';

type Props = {
  title: string;
  subTitle: React.ReactNode | null;
  amount: number;
  className?: string;
};
const OverageItemDetails = ({ title, subTitle, amount, className }: Props) => (
  <Container className={className}>
    <TitleSubTitleContainer>
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </TitleSubTitleContainer>
    <PriceContainer>{asPriceElement(amount)}</PriceContainer>
  </Container>
);

const PriceContainer = styled.div<{}>`
  font-weight: bold;
`;

const TitleSubTitleContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span<{}>`
  ${({ theme }) => `
    font-size: ${theme.fontSize('m')};
  `};
`;

const SubTitle = styled.span<{}>``;

const Container = styled.div<{}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => `
    padding: ${theme.space('m')} 0;
  `};
`;

export default OverageItemDetails;
