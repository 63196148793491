import gql from 'graphql-tag';

import TaskListTaskFields from '../fragments/TaskListTaskFields';

export default gql`
  mutation UpdateTask($accountId: ID!, $id: ID!, $update: Task_Update!) {
    updateTask(accountId: $accountId, id: $id, update: $update) {
      ...TaskListTaskFields
    }
  }

  ${TaskListTaskFields}
`;
