import React from 'react';
import styled from 'styled-components';

import { LinkButton } from '~/components/Buttons';
import TEST_ID from './index.testid';
import { navigate } from 'gatsby';

type Props = {};

const text = {
  explanation:
    'Er is geen e-mail account ingesteld om e-mails vanuit te sturen.',
  userLabel: 'Stel een persoonlijk e-mail account in',
  accountLabel: 'Stel een gedeeld e-mail account in',
};

const NoSyncedEmailCTA: React.FC<Props> = () => (
  <Container data-testid={TEST_ID.CONTAINER}>
    <TextContainer>{text.explanation}</TextContainer>
    <ButtonsContainer>
      <LinkButton
        testId={TEST_ID.SETUP_PERSONAL_ACCOUNT_LINK}
        onClick={() => void navigate('/-/settings/profile')}
      >
        {text.userLabel}
      </LinkButton>
      <LinkButton
        testId={TEST_ID.SETUP_OPEN_ACCOUNT_LINK}
        onClick={() => void navigate('/-/settings/account')}
      >
        {text.accountLabel}
      </LinkButton>
    </ButtonsContainer>
  </Container>
);

const ButtonsContainer = styled.div<{}>`
  display: flex;
  min-width: 100%;
  justify-content: space-around;
`;

const TextContainer = styled.div<{}>`
  text-align: center;

  ${({ theme }) => `
    margin-top: ${theme.space('m')};
    margin-bottom: ${theme.space('m')};
  `};
`;

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default NoSyncedEmailCTA;
