import React, { useContext } from 'react';

import { EmailPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import PointerComponent from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/PointerComponent';
import BaseActionContext from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import { getStashVariablesInStash } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';

type Props = {
  value: EmailPointerParameterValue;
  onChange: (newValue: EmailPointerParameterValue) => void;
};
const EmailPointerParameterComponent = ({ value, onChange }: Props) => {
  const { variableStash } = useContext(BaseActionContext);

  return (
    <PointerComponent
      possibleVariables={getStashVariablesInStash(
        variableStash,
        FLOW_OUTPUT_TYPE.FlowOutputEventEmail,
      )}
      fieldType={FLOW_OUTPUT_TYPE.FlowOutputEventEmail}
      variable={value ? value.variable : null}
      onChange={newPointerVariable => {
        const newValue = { ...value, variable: newPointerVariable };

        onChange(newValue);
      }}
      hideFieldDropdownIfOnlyOneOptionAndSelected
    />
  );
};

export default EmailPointerParameterComponent;
