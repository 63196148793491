import React, { useState, useEffect } from 'react';

import {
  LockContactSearchResultsByFilterV2MutationVariables,
  DoContactAction__Input,
  useDoContactActionMutation,
  useLockContactSearchResultsByFilterV2Mutation,
  LockedContactSearchResultsKey,
} from '~/graphql/types';

import ConfirmModal from '~/components/Alerts/ConfirmModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TEST_ID from './index.testid';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import LockedContactsMessage from '../LockedContactsMessage';
import { keys } from 'ramda';

const text = {
  title: 'Actie bevestigen',
  confirm: 'Bevestigen',
  lockContactsErrorMsg:
    'Er is een fout opgetreden bij het de selecteren van je contacten. Ververs de pagina en probeer het nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
  doActionErrorMsg:
    'Er is een fout opgetreden bij het uitvoeren van deze actie. Ververs de pagina en probeer het nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
  doActionSuccessMsg:
    'Actie bevestigd, het kan even duren voordat het op de pagina wordt weergegeven.',
  sendCSVSuccessMsG: 'De export wordt per e-mail verstuurd.',
};

export type Props = {
  dataTestId?: string;
  /** Variables to pass into LockContactSearchResultsByFilterMutationV2 */
  lockContactsVariables: LockContactSearchResultsByFilterV2MutationVariables;

  /** The amount when you first select the contacts */
  selectedContactAmount: number;

  /** Action input to pass into 'action' field of doContactAction mutation */
  actionInput: DoContactAction__Input;

  /** If the submit button in the modal is disabled */
  disabled?: boolean;

  /** Callback to call when doContactAction mutation is completed */
  onComplete: () => void;

  /** Callback to close the modal */
  onCancel: () => void;

  /** Takes lockContactSearchResultsByFilterV2 loading state as prop, this can be used to disable the components in children  */
  children?: ((loading?: boolean) => React.ReactNode) | React.ReactNode;
};

const ConfirmActionModal: React.FC<Props> = ({
  lockContactsVariables,
  onComplete,
  onCancel,
  disabled = false,
  children,
  actionInput,
  selectedContactAmount,
}) => {
  const addToast = useAddToast();
  const account = useCurrentAccount();

  const [doContactAction, { loading: doContactActionLoading }] =
    useDoContactActionMutation();

  const [lockContactSearchResultsByFilterV2, { loading: lockLoading }] =
    useLockContactSearchResultsByFilterV2Mutation();

  const [lockedContactSearchResultsKey, setLockedContactSearchResultsKey] =
    useState<LockedContactSearchResultsKey | null>(null);

  useEffect(() => {
    void lockContacts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lockContacts = async () => {
    const { data, errors } = await lockContactSearchResultsByFilterV2({
      variables: lockContactsVariables,
    });

    if (errors && errors.length > 0) {
      addToast([formatToastMessage(text.lockContactsErrorMsg, 'danger')]);
      return;
    }

    if (data) {
      const { key, total } = data.lockContactSearchResultsByFilterV2;

      setLockedContactSearchResultsKey({
        __typename: 'LockedContactSearchResultsKey',
        key,
        total,
      });
    }
  };

  const loading = lockedContactSearchResultsKey == null || lockLoading;

  const confirmDisabled = disabled || loading;

  if (
    !lockedContactSearchResultsKey ||
    lockedContactSearchResultsKey?.total === 0
  ) {
    return null;
  }

  return (
    <ConfirmModal
      title={text.title}
      buttonConfirmTitle={text.confirm}
      handleAction={async confirm => {
        if (confirm) {
          if (confirmDisabled) {
            return;
          }

          const { data, errors } = await doContactAction({
            variables: {
              accountId: account.id,
              action: actionInput,
              key: lockedContactSearchResultsKey.key,
            },
          });

          if (errors && errors.length > 0) {
            addToast([formatToastMessage(text.doActionErrorMsg, 'danger')]);
            onCancel();
            return;
          }

          if (data) {
            const isSendCSVAction = keys(actionInput).includes('SendCSV');
            addToast([
              formatToastMessage(
                isSendCSVAction
                  ? text.sendCSVSuccessMsG
                  : text.doActionSuccessMsg,
                'success',
              ),
            ]);
            onComplete();
            return;
          }
        } else {
          onCancel();
        }
      }}
      disabled={confirmDisabled}
      loading={doContactActionLoading}
      dataTestid={TEST_ID.CONTAINER}
    >
      <LockedContactsMessage
        lockedAmount={lockedContactSearchResultsKey?.total ?? 0}
        loading={loading}
        selectedContactAmount={selectedContactAmount}
      />

      {children && typeof children === 'function' ? children(loading) : null}
    </ConfirmModal>
  );
};

export default ConfirmActionModal;
