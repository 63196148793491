import gql from 'graphql-tag';

export default gql`
  fragment AlgasSoldPropertyFields on Algas_SoldProperty {
    propertyType
    propertyTypeDetail
    livingArea
    price
    location {
      lat
      lng
    }
    street
    city
    postcode
    buildIn
    dateOfSale
    rooms
    propertyArea
    image
  }
`;
