import React from 'react';
import styled, { css } from 'styled-components';

import { DesignProps, PermanentImageLinksTypes } from './ValueReport.type';
import { AppValuationReportLogoPosition } from '~/graphql/types';
import { Option } from '~/components/Inputs/SelectGroup';
import { WithAccountContext } from '~/contexts/AccountContext';

import { withAccountContext } from '~/contexts/AccountContext';
import ChangeColorBlock from './components/ChangeColor';
import SelectGroupWrapper from './components/SelectGroupWrapper';
import { RouteComponentProps } from '@reach/router';
import ValueReportComponentCard from './components/ValueReportComponentCard';
import { isValidURL } from '~/util/Validation/URL';
import { isNonEmptyString, isEmpty } from '~/util/Validation/String';
import { Input } from '~/components/Inputs';
import ColorPickerWithTransparentOption from './components/ColorPickerWithTransparentOption';
import TextEditor from './components/TextEditor';
import ImageUploadComponent from '~/components/ImageUploadComponent';
import uploadS3Image from '~/util/uploadS3Image';
import { isNil } from 'ramda';
import Icon from '~/components/Icon';

const text = {
  title: 'Ontwerp',
  logo: 'Logo',
  logoPosition: 'Logo positie',
  mainColor: 'Hoofdkleur',
  headerTextColor: 'Koptekstkleur',
  accentColor: 'Accentkleur',
  navbarBackgroundColor: 'Navbar achtergrondkleur',
  backgroundColor: 'Achtergrondkleur',
  backgroundImage: 'Achtergrond afbeelding',
  nextButtonMainText: 'Hoofdtekst volgende knop',
  nextButtonMainTextColor: 'Kleur hoofdtekst volgende knop',
  nextButtonSubText: 'Subtekst volgende knop',
  nextButtonSubTextColor: 'Kleur subtekst volgende knop',
  nextButtonBackgroundColor: 'Achtergrondkleur volgende knop',
  stepColor: 'Stap indicator tekstkleur',
  stepBackgroundColor: 'Stap indicator achtergrondkleur',
  left: 'Links',
  middle: 'Midden',
  right: 'Rechts',
  logoLinkError: 'Voer een geldige url in',
};

type UploadingImage = 'logoImage' | 'backgroundImage';

type MyProps = {
  data: DesignProps;

  /** Callback function which call data updating in container state */
  onChange: (arg0: $Object) => void;

  /** Callback function which call data updating in container state for permanent image links*/
  onImagePermanentLinkChange: (arg0: $Object) => void;

  /** Data field name for correct onChange handling */
  permanentImageLinks: PermanentImageLinksTypes;

  /** Data field for permanent image links  */
  name: string;
  onImageUploadError: () => void;
} & RouteComponentProps;
type Props = WithAccountContext & MyProps;
type State = {
  logoLink: string | null | undefined;
  invalidURLError: boolean;
  uploadingImage: UploadingImage | null;
};

const options: Array<Option> = [
  { value: 'Left', label: text.left, dataTestid: 'select-left' },
  { value: 'Middle', label: text.middle, dataTestid: 'select-middle' },
  { value: 'Right', label: text.right, dataTestid: 'select-right' },
];

// export for test
export const getLogoSelectedIndex = (
  value: AppValuationReportLogoPosition | string,
): number => {
  if (value === 'Left') return 0;
  if (value === 'Middle') return 1;
  if (value === 'Right') return 2;

  throw new Error(
    `Wrong value: "${value}" for logoPosition in ValueReport provided.`,
  );
};

class Design extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      logoLink: this.props.data.logoLink,
      invalidURLError: false,
      uploadingImage: null,
    };
  }

  onChange = (value: Object, objectName?: string) => {
    const { data, name, onChange } = this.props;

    if (objectName) {
      onChange({
        [name]: {
          ...data,
          [objectName]: {
            ...data[objectName],
            ...value,
          },
        },
      });
    } else {
      onChange({
        [name]: {
          ...data,
          ...value,
        },
      });
    }
  };

  onLogoLinkChange = (value: string) => {
    this.setState({ logoLink: value });

    const isValidValue = isValidURL(value) || isEmpty(value);

    if (isValidValue) {
      this.onChange({
        logoLink: isNonEmptyString(value) ? value : null,
      });
      this.setState({ invalidURLError: false });
    } else {
      this.setState({ invalidURLError: true });
    }
  };

  changeImageData = (
    identityId: string | null | undefined,
    permanentLink: string | undefined,
    name: string,
  ) => {
    if (identityId && permanentLink) {
      this.onChange({ [name]: identityId });
      this.props.onImagePermanentLinkChange({
        [`${name}Link`]: permanentLink,
      });
    } else {
      this.props.onImageUploadError();
    }
    this.setState({ uploadingImage: null });
  };

  onUploadImage = async (files: FileList, imageName: UploadingImage) => {
    const loggedInUserId = this.props.accountContext.me
      ? this.props.accountContext.me.id
      : '';

    try {
      this.setState({ uploadingImage: imageName });
      const { path, permanentLink } = await uploadS3Image({
        file: files[0],
        uploadImageName: `${loggedInUserId}/${imageName}`,
      });

      return this.changeImageData(path, permanentLink, imageName);
    } catch {
      this.props.onImageUploadError();
      this.setState({ uploadingImage: null });
    }
  };

  onDeleteImage = (objectName: string) => {
    const { data, name, onChange } = this.props;

    onChange({
      [name]: {
        ...data,
        [objectName]: null,
      },
    });
  };

  render() {
    const { data, permanentImageLinks } = this.props;
    const {
      logoImage,
      logoPosition,
      mainColor,
      headerTextColor,
      accentColor,
      backgroundColor,
      backgroundImage,
      navbarBackgroundColor,
      nextButton,
      step,
    } = data;

    const { mainText } = nextButton;
    const { logoImageLink, backgroundImageLink } = permanentImageLinks;

    const { logoLink, invalidURLError, uploadingImage } = this.state;

    return (
      <ValueReportComponentCard
        datatestId={'design-page'}
        cardIcon={<Icon name="layout" />}
        title={text.title}
      >
        <Row>
          <ImageUploadContainer data-testid="logo-image-upload-container">
            <p>{text.logo}</p>
            <ImageUploadComponent
              uploading={uploadingImage === 'logoImage'}
              dataTestid={'logoImage-uploader'}
              imageUrl={
                isNil(logoImage)
                  ? null
                  : logoImage?.startsWith('http')
                  ? logoImage
                  : logoImageLink
              }
              onUpload={files => this.onUploadImage(files, 'logoImage')}
              onDelete={() => this.onDeleteImage('logoImage')}
            />
          </ImageUploadContainer>
        </Row>
        <Row>
          <SelectGroupWrapper
            title={text.logoPosition}
            name={'logoPosition'}
            selectedIndex={getLogoSelectedIndex(logoPosition)}
            onChange={this.onChange}
            options={options}
          />
        </Row>
        <Row>
          <div>
            <p>Logo link</p>
            <Input
              label=""
              name="logo-link"
              onChange={e => {
                if (e.target) {
                  this.onLogoLinkChange(e.target.value);
                }
              }}
              placeholder="https://vastgoedprofessional.nl/"
              value={logoLink}
              error={invalidURLError ? text.logoLinkError : null}
            />
          </div>
        </Row>
        <ColorPickerRow>
          <ChangeColorBlock
            color={mainColor}
            title={text.mainColor}
            name="mainColor"
            testName="design"
            onChange={color => this.onChange({ ['mainColor']: color })}
          />

          <ChangeColorBlock
            color={headerTextColor}
            title={text.headerTextColor}
            name="headerTextColor"
            testName="design"
            onChange={color => this.onChange({ ['headerTextColor']: color })}
          />

          <ChangeColorBlock
            color={accentColor}
            title={text.accentColor}
            name="accentColor"
            testName="design"
            onChange={color => this.onChange({ ['accentColor']: color })}
          />

          <ChangeColorBlock
            color={backgroundColor}
            title={text.backgroundColor}
            name="backgroundColor"
            testName="design"
            onChange={color => this.onChange({ ['backgroundColor']: color })}
          />
        </ColorPickerRow>
        <Row>
          <ImageUploadContainer
            data-testid={'background-image-upload-container'}
          >
            <p>{text.backgroundImage}</p>
            <ImageUploadComponent
              uploading={uploadingImage === 'backgroundImage'}
              dataTestid="backgroundImage-uploader"
              imageUrl={
                isNil(backgroundImage)
                  ? null
                  : backgroundImageLink || backgroundImage
              }
              onUpload={files => this.onUploadImage(files, 'backgroundImage')}
              onDelete={() => this.onDeleteImage('backgroundImage')}
            />
          </ImageUploadContainer>
        </Row>
        <Row>
          <TextEditor
            testName={'mainText'}
            text={mainText.text}
            title={text.nextButtonMainText}
            onChange={value =>
              this.onChange({ mainText: { text: value } }, 'nextButton')
            }
            name="text"
            disabled={false}
          />
        </Row>
        <ColorPickerRow>
          <ChangeColorBlock
            color={mainText.color}
            title={text.nextButtonMainTextColor}
            name="color"
            testName="design-mainText"
            onChange={value =>
              this.onChange({ mainText: { ['color']: value } }, 'nextButton')
            }
          />
          <ChangeColorBlock
            color={nextButton.backgroundColor}
            title={text.nextButtonBackgroundColor}
            name="backgroundColor"
            testName="design-next"
            onChange={value =>
              this.onChange({ ['backgroundColor']: value }, 'nextButton')
            }
          />

          <ChangeColorBlock
            color={step.color}
            title={text.stepColor}
            name="color"
            testName="design-step"
            onChange={value => this.onChange({ ['color']: value }, 'step')}
          />

          <ChangeColorBlock
            color={step.backgroundColor}
            title={text.stepBackgroundColor}
            name="backgroundColor"
            testName="design-step"
            onChange={value =>
              this.onChange({ ['backgroundColor']: value }, 'step')
            }
          />
        </ColorPickerRow>
        <Row>
          <ColorPickerWithTransparentOption
            color={navbarBackgroundColor}
            title={text.navbarBackgroundColor}
            name="navbarBackgroundColor"
            testName="design-step"
            onChange={value =>
              this.onChange({ ['navbarBackgroundColor']: value })
            }
          />
        </Row>
      </ValueReportComponentCard>
    );
  }
}

export default withAccountContext<MyProps>(Design);

const Row = styled.div<{}>`
  display: flex;
  flex-direction: row;
  > div {
    flex: 1;
  }
  ${({ theme }) => css`
    margin: ${theme.space('m')} 0;
  `};
`;

const ImageUploadContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `,
);

const ColorPickerRow = styled.div<{}>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row dense;
  grid-auto-rows: 1fr;

  ${({ theme }) => css`
    grid-column-gap: ${theme.space('xl')};
    border-top: 1px solid ${theme.color('grey', 'light')};
    border-bottom: 1px solid ${theme.color('grey', 'light')};
    margin: ${theme.space('xxl')} 0;
    padding: ${theme.space('xl')} 0;

    ${theme.mq.lessThan('tabletLandscape')`
      grid-template-columns: repeat(2, 1fr);
    `};
  `}
`;
