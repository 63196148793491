import React from 'react';
import ActivitySummaryList from '~/components/ActivitySummaryList';
import { useGetEntityActivityOverviewQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import getActivities from './utils/getActivities';

export type Props = {
  dataTestId?: string;
};

const EntityActivityOverview: React.FC<Props> = ({ dataTestId, ...rest }) => {
  const { id: accountId } = useCurrentAccount();
  const { data, loading } = useGetEntityActivityOverviewQuery({
    variables: { accountId },
  });

  const activities = getActivities(data);

  return (
    <ActivitySummaryList
      activities={activities}
      dataTestId={dataTestId}
      style={{
        visibility: loading ? 'hidden' : 'visible',
      }}
      {...rest}
    />
  );
};

export default EntityActivityOverview;
