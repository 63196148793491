import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import TextButton from '~/components/TextButton';

import Button, { Props as ButtonProps } from '../../Button';
import Icon from '../../Icon';
import icons from '../../Icon/components';
import { Body, Heading3 } from '../../Typography/index';
import CloseButton from '../../CloseButton';
import Header from '../components/Header';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  icon?: keyof typeof icons;
  header: React.ReactNode;
  body: React.ReactNode;
  onConfirm: (e) => void;

  /* Method provided by an Overlay component */
  onClose?: () => false | void | undefined;

  confirmAction: Omit<ButtonProps, 'onClick'>;
  cancelAction: {
    label: React.ReactNode;
  };
  loading?: boolean;
};

const Dialog: React.FC<Props> = ({
  dataTestId,
  header,
  icon = 'triangle',
  body,
  onConfirm,
  confirmAction,
  onClose,
  loading,
  cancelAction,
  ...rest
}) => (
  <Outer>
    <Container data-testid={dataTestId} {...rest}>
      <Header justification="space-between" align="start">
        <CloseButton onClick={onClose} />
        <JustificationContainer
          nonResponsive
          justification="start"
          align="center"
        >
          <LocalIcon name={icon} background="warning" />
          <Heading3 withoutMargin>{header}</Heading3>
        </JustificationContainer>
      </Header>
      <Body>{body}</Body>

      <JustificationContainer
        nonResponsive
        gap="base"
        justification="end"
        align="center"
      >
        <TextButton
          {...cancelAction}
          onClick={onClose}
          dataTestId={TEST_ID.MODAL_CANCEL_BUTTON}
        />
        <Button
          {...confirmAction}
          onClick={onConfirm}
          dataTestId={TEST_ID.MODAL_CONFIRM_BUTTON}
          loading={loading}
        />
      </JustificationContainer>
    </Container>
  </Outer>
);

const MAX_DIALOG_WIDTH = 760;

const Outer = styled.div<{}>`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    border-top: 8px solid ${theme.color('warning')};
    border-radius: ${theme.getTokens().border.radius.base};
    box-shadow: ${theme.getTokens().boxShadow.modal};

    max-width: ${MAX_DIALOG_WIDTH}px;
    width: 100%;
    margin: 0 auto;

    padding: ${theme.space('xxxxl')};
  `,
);

const LocalIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('l')};
    margin-right: ${theme.space('s')};
    color: ${theme.color('white')};

    /* Correct position of icon */
    svg {
      margin-top: -0.1rem;
      font-size: 0.9em;
    }

    /* Make icon a little bigger */
    &::before {
      width: 1.4em;
      height: 1.4em;
    }
  `,
);

export default Dialog;
