import { FlowConditionAppBBWaardecheckAppraisalRequestDetails } from './types.flow';

import { emptyBBWaardecheckAppraisalRequestEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionAppBBWaardecheckAppraisalRequestDetails = (): Omit<
  FlowConditionAppBBWaardecheckAppraisalRequestDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES
    .EVENT_CONTACT_APP_BBWAARDECHECK_APPRAISALREQUEST_DETAILS.type,
  fields: [],
  event: emptyBBWaardecheckAppraisalRequestEventParameterValue(),
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
});

export default emptyFlowConditionAppBBWaardecheckAppraisalRequestDetails;
