import { Flow_Condition__Input } from '~/graphql/types';

const asFlowConditionAppFundaBrochureRequestNewInput =
  (): Flow_Condition__Input => ({
    EventContactApp: {
      Funda: {
        BrochureRequest: {
          New: {
            noOperation: true,
          },
        },
      },
    },
  });

export default asFlowConditionAppFundaBrochureRequestNewInput;
