import { AppType } from '~/graphql/types';
import type { AppConfig } from '../types';

const description = `## Omschrijving
Met de WOZ Bezwaar app van DatHuis bied je extra service aan leads en relaties door een WOZ Bezwaar module toe te voegen aan de Brainbay of AVIX Waardecheck. Gebruikers van de Waardecheck krijgen naast het opvragen van de actuele woningwaarde ook een indicatie of de WOZ-waarde correct is. Wijkt de eerlijke WOZ-waarde af van de waarde die de gemeente heeft bepaald? Dan kan met twee muisklikken gratis een bezwaar worden ingediend op basis van no-cure-no-pay. Wordt een bezwaar op een aanslagbiljet toegewezen, dan ontvang jij een eenmalige vergoeding van €30,- euro exclusief BTW.

## Installatie
Nadat je de app hebt geactiveerd, wordt de WOZ Bezwaar module automatisch getoond op de rapport pagina van de Waardecheck.`;

const termsOfUse = `Door het activeren van deze app geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van WOZ Consultants BV. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. [Op de dienstverlening van WOZ Consultants BV zijn hun gebruiksvoorwaarden van toepassing.](https://www.wozconsultants.nl/wp-content/uploads/2022/01/algemene-voorwaarden-2022.pdf) Voor ieder toegekend bezwaar op een aanslagbiljet wordt een eenmalige commissie uitgekeerd van €30,- exclusief BTW. De commissies die je verdient met WOZ Consultants BV worden door DatHuis periodiek uitgekeerd. Meer informatie over WOZ Consultants BV vind je op [https://wozconsultants.nl.](https://wozconsultants.nl)`;

export const woz: AppConfig = {
  __typename: 'AppStatus_WOZComplaint',
  appType: AppType.WozComplaint,
  type: 'activate_only',
  slug: 'woz',
  name: 'WOZ Bezwaar',
  tags: ['Informatievoorziening', 'Commissie ontvangen', 'E-mailcampagne'],
  provider: 'DatHuis B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/woz.png',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/woz.png',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/6839355-over-de-woz-bezwaar-app',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'default',
};
