import { pipe, map, when, is, omit } from 'ramda';

/**
 * Removes all the nested __typename properties and returns the same structure
 * @param {Array<any> | Object} o - An array or object
 * keywords:
 */
const removeTypenames = (o: Array<any> | Object) =>
  pipe(
    when(n => !Array.isArray(n), omit(['__typename'])),
    map(when(is(Object), removeTypenames)),
  )(o);

export default removeTypenames;
