import React, { ReactElement } from 'react';

import {
  UpdatingVariableProps,
  OnSaveFunction,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/TemplateString/components/TemplateStringParameterInsertOrUpdateModal';
import { TemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import TemplateStringParameterInsertOrUpdateModal from './TemplateStringParameterInsertOrUpdateModal';
import { WINDOW_VARIABLE_UPDATER_DICT_NAME } from '~/components/HTMLEditor/util/variableHTML';
import cleanedFilename from '~/util/cleanedFilename';
import { isHandledFlowParameterMapping } from '../../../FlowParameterMapping/types.flow';

type ToggleVariableFunction = (variable?: UpdatingVariableProps) => void;
type ChildProps = {
  toggleVariableFunction: ToggleVariableFunction;
};
type Props = {
  variableUpdaterId: string;
  getTemplateStringValue: () => TemplateStringParameterValue;
  onSaveFunction: OnSaveFunction;
  children: (props: ChildProps) => React.ReactNode;
};
type State = {
  showVariableInsertModal: boolean;
  updatingVariable: UpdatingVariableProps | null;
};
class useTemplateStringParameterInsertOrUpdateModal extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showVariableInsertModal: false,
      updatingVariable: null,
    };
  }

  componentDidMount() {
    const { variableUpdaterId, getTemplateStringValue } = this.props;

    if (global.window[WINDOW_VARIABLE_UPDATER_DICT_NAME] == null) {
      global.window[WINDOW_VARIABLE_UPDATER_DICT_NAME] = {};
    }

    global.window[WINDOW_VARIABLE_UPDATER_DICT_NAME][variableUpdaterId] = (
      _element,
      variableId,
    ) => {
      const templateStringValue = getTemplateStringValue();

      const variable = templateStringValue.mappings.find(
        mapping => mapping.mappingId === variableId,
      );

      if (variable == null || variable.mapping == null)
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} -> Clicked on unknown variable with id ${variableId} in ${JSON.stringify(
            templateStringValue,
            null,
            2,
          )}`,
        );

      if (!isHandledFlowParameterMapping(variable)) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} -> Cannot convert mapping from variable ${variable} since mapping type:${
            variable.mapping.type
          } is not supported`,
        );
      }
      /** @TODO Check this */
      this.showWithVariable({
        variable: variable.mapping,
        variableId,
      });
    };
  }

  closeVariableEditDropdown = () => {
    this.setState({
      showVariableInsertModal: false,
      updatingVariable: null,
    });
  };

  showWithVariable = (variable: UpdatingVariableProps | null) => {
    this.setState({
      showVariableInsertModal: true,
      updatingVariable: variable,
    });
  };

  setShowVariableInsertModal = (
    val: boolean,
    variable?: UpdatingVariableProps | null,
  ) => {
    this.setState(prevState => ({
      showVariableInsertModal: val,
      updatingVariable:
        variable !== undefined ? variable : prevState.updatingVariable,
    }));
  };

  render() {
    const { showVariableInsertModal, updatingVariable } = this.state;
    const { onSaveFunction, children } = this.props;

    let component: ReactElement | null = null;
    if (showVariableInsertModal === true) {
      component = (
        <TemplateStringParameterInsertOrUpdateModal
          onSave={(newVariable, variableId) => {
            onSaveFunction(newVariable, variableId);

            this.closeVariableEditDropdown();
          }}
          onClose={() => this.setShowVariableInsertModal(false)}
          updatingVariable={updatingVariable}
        />
      );
    }

    const toggleVariableFunction = variableProps => {
      this.setShowVariableInsertModal(
        !showVariableInsertModal,
        variableProps == null ? null : variableProps,
      );
    };

    return (
      <>
        {component}
        {children({ toggleVariableFunction })}
      </>
    );
  }
}

export default useTemplateStringParameterInsertOrUpdateModal;
