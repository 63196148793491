import React, { useEffect } from 'react';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import { Handle, Position } from 'reactflow';
import { Heading5, Variant } from '~/components/Typography/index';
import CardStatistics, { Units } from '../CardStatistics';
import Divider from '~/components/Divider';
import { Props as HidableListProps } from '~/components/Inputs/Dropdown/components/DropdownHideableList';
import Issue from '../../../Issues/InCard';
import { issuesByActionId } from '~/scenes/Automation/v2/state/flowIssues';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FlowAction } from '~/graphql/types';
import getCardHeading from '../../../../utils/getCardHeading';
import TemplateString from '../TemplateString';
import JustificationContainer from '~/components/JustificationContainer';
import { useTheme } from 'styled-components';
import {
  ClientFlowActionSendEmailPlain,
  EmailFileAttachment,
} from '~/graphql/types.client';
import uploadImageToStorage from '~/components/HTMLEditor/util/uploadImageToStorage';
import useCurrentUser from '~/hooks/useCurrentUser';
import flowActions from '~/scenes/Automation/v2/state';
import interactions from '~/scenes/Automation/v2/state/interactions';
import downloadFileWithSrc from '~/components/HTMLEditor/util/downloadFileWithSrc';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import useErrorReporter from '~/hooks/useErrorReporter';

export type Props = Omit<
  CardProps<ClientFlowActionSendEmailPlain>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
} & Omit<HidableListProps, 'dropdownListOpen' | 'onClickOutside'>;

const text = {
  subject: 'Onderwerp',
  opened: 'Geopend',
  sent: 'Verzonden',
  clicked: 'Geklikt',
  uploadFilesError:
    'Afbeeldingen of bijlagen konden niet worden ingesteld bij het dupliceren van de actie. Controleer de afbeeldingen en bijlagen in de nieuwe e-mail versturen actie en plaats ze opnieuw',
};

const EmailActionCard: React.FC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const addToast = useAddToast();
    const errorReporter = useErrorReporter();

    const issues = useRecoilValue(issuesByActionId(data.action.id));
    const theme = useTheme();
    const chartColors = theme.getTokens().colors.chart;

    const setActions = useSetRecoilState(flowActions);

    const [activeInteraction, setActiveInteraction] =
      useRecoilState(interactions);

    const user = useCurrentUser();

    useEffect(() => {
      const attachments = data.action.attachments;
      const fileAttachments = attachments.filter(
        a => a.file.__typename === 'Flow___Argument_File',
      );

      /**
       * We need to update the file in the Flow___Argument_File attachments when the action is duplicated
       * because files cannot be reused in different actions
       */
      const reuploadFiles = async () => {
        const pointerAttachments = attachments.filter(
          a => a.file.__typename === 'Flow___Argument_Pointer',
        );

        const newFileAttachments: Array<EmailFileAttachment> = [];

        try {
          await Promise.all([
            ...fileAttachments.map(async attachment => {
              if (attachment.file.__typename === 'Flow___Argument_Pointer')
                return;

              const copiedFile = attachment.file.value_file;

              if (!attachment.url) return;

              await downloadFileWithSrc(attachment.url, async file => {
                const res = await uploadImageToStorage(file, user.id);

                if (res) {
                  newFileAttachments.push({
                    __typename: 'FlowV2_EmailAttachment',
                    inlineId: attachment.inlineId || null,
                    url: res.url,
                    file: {
                      __typename: 'Flow___Argument_File',
                      value_file: {
                        __typename: 'Flow___File',
                        s3key: res.s3key,
                        filename: copiedFile.filename,
                        contentType: res.contentType,
                        contentLength: res.contentLength,
                      },
                    },
                  });
                }
              });
            }),
          ]);
        } catch (err) {
          errorReporter.captureException(
            new Error(
              'FileUploadError when duplicating SendEmail action with file attachments',
            ),
            'warning',
          );
          addToast([formatToastMessage(text.uploadFilesError, 'danger')]);
        }

        // If newFileAttachments haven't been uploaded because of an error, pass them as empty array anyway.
        // We want to remove them from the existing attachments so that the user can manually fix the
        // file attachments in the faulty action.
        const updatedAttachments = [
          ...pointerAttachments,
          ...newFileAttachments,
        ];

        if (updatedAttachments.length > 0) {
          setActions(prev =>
            [...prev].map(a => {
              if (a.id === data.action.id)
                return { ...a, attachments: updatedAttachments };

              return a;
            }),
          );
        }

        setActiveInteraction(null);
      };

      if (
        activeInteraction &&
        activeInteraction.type === 'duplicateAction' &&
        activeInteraction.action.id === data.action.id &&
        fileAttachments.length > 0
      )
        void reuploadFiles();

      // Do not add data.action.attachments, it causes unnecessary calls to uploadImageToStorage functions
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      activeInteraction,
      data.action.id,
      setActions,
      setActiveInteraction,
      user.id,
    ]);

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.SendEmailPlain}
          heading={getCardHeading(FlowAction.SendEmailPlain)}
          selected={selected}
          disabled={disabled}
          data={data}
        >
          <div>
            <JustificationContainer justification="start" align="center">
              <Heading5 inline variant={Variant.primary} withoutMargin>
                {text.subject}:&nbsp;
              </Heading5>
              <TemplateString
                templateString={data.action.subject}
                actionId={data.action.id}
              />
            </JustificationContainer>
            {issues &&
              issues.map(issue => (
                <Issue
                  key={issue.message}
                  {...issue}
                  margin={['xxs', null, null, null]}
                />
              ))}
          </div>
          {data.action.Statistics && (
            <>
              <Divider />
              <CardStatistics
                statistics={[
                  {
                    color: chartColors[0],
                    heading: text.sent,
                    total: data.action.Statistics.sentTotal,
                    value: 0,
                    unit: Units.Times,
                  },
                  {
                    color: chartColors[1],
                    heading: text.opened,
                    total: data.action.Statistics.sentTotal,
                    value: data.action.Statistics.openedTotal,
                    unit: Units.Percent,
                  },
                  {
                    color: chartColors[2],
                    heading: text.clicked,
                    total: data.action.Statistics.sentTotal,
                    value: data.action.Statistics.clickedTotal,
                    unit: Units.Percent,
                  },
                ]}
              />
            </>
          )}
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

export default EmailActionCard;
