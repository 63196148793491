import React from 'react';
import CopyCode from '~/components/CopyCode';
import GoToPath from '~/components/GoToPath';
import { Heading4, Variant } from '~/components/Typography/index';

import { text } from '../../index';

export type Props = {
  zapierApiKey: string;
};

const GetApiKey: React.FC<Props> = ({ zapierApiKey }) => (
  <>
    <CopyCode label={text.codeLabel} code={zapierApiKey} />
    <Heading4 margin={['l', null, 's', null]} variant={Variant.primary}>
      {text.secondStep}
    </Heading4>
    <GoToPath path={text.path} label={text.pathLabel} link={text.link} />
  </>
);

export default GetApiKey;
