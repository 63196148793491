import React, { useMemo, useState, useEffect } from 'react';
import { assocPath, sortBy, compose, toLower, prop, sort } from 'ramda';
import { Relation } from '~/graphql/types.client.flow';
import { ViewableUser, ViewableOffice, AppRight } from './AccountContext';

import {
  AccountMailboxAliasFieldsFragment,
  AccountMailboxFieldsFragment,
  OfficeMailboxAliasFieldsFragment,
  OfficeMailboxFieldsFragment,
  SessionHydrationAccountRelationFieldsFragment,
  SessionHydrationUserFields_User_Fragment,
  SessionHydrationMe,
  UserMailboxAliasFieldsFragment,
  UserMailboxFieldsFragment,
  UserStatus,
  SessionHydrationOfficeRelationFieldsFragment,
  SessionHydrationAccountFieldsFragment,
  NotificationListItem,
} from '~/graphql/types';

import AccountContext from './AccountContext';
import { toLookupTable } from '~/util/object';
import buildAvailableEmailsToSendFrom from './util/buildAvailableEmailsToSendFrom';
import useIntercom from '~/hooks/useIntercom';
import {
  AccountIssueFieldsFragment,
  SessionHydrationUserFieldsFragment,
  SessionHydrationOfficeFieldsFragment,
  SessionHydrationDeletedAccountRelationFieldsFragment,
  SessionHydrationDeletedOfficeRelationFieldsFragment,
} from '~/graphql/types';
import { OptionOf } from '~/components/Inputs/Dropdown';
import withErrorBoundary from '~/ErrorBoundary';
import DatHuisLoading from '~/components/DatHuisLoading';
import styled, { css } from 'styled-components';
import Button from '~/components/Button';
import JustificationContainer from '~/components/JustificationContainer';
import type { AppStatus__typename } from '~/graphql/types.client';

type UserToAccountRelationMatrix = {
  [userId: string]: SessionHydrationAccountRelationFieldsFragment | undefined;
};

type UserToOfficeRelationMatrix = {
  [userId: string]: {
    [officeId: string]:
      | SessionHydrationOfficeRelationFieldsFragment
      | undefined;
  };
};

type OfficeToUserRelationMatrix = {
  [officeId: string]: {
    [userId: string]: SessionHydrationOfficeRelationFieldsFragment | undefined;
  };
};

export type Mailbox =
  | AccountMailboxFieldsFragment
  | OfficeMailboxFieldsFragment
  | UserMailboxFieldsFragment
  | AccountMailboxAliasFieldsFragment
  | OfficeMailboxAliasFieldsFragment
  | UserMailboxAliasFieldsFragment;

export type Account = SessionHydrationAccountFieldsFragment;

export type UserLookupTable = {
  [id: string]: ViewableUser | undefined;
};

export type OfficeLookupTable = {
  [id: string]: ViewableOffice | undefined;
};

type DeletingRelation = {
  accountId: string;
  officeId?: string;
  userId: string;
};
type DeletedUser = {
  userId: string;
  name: string;
};
type DeletedOffice = {
  officeId: string;
  name: string;
};
type DeletedRelationType =
  | SessionHydrationDeletedAccountRelationFieldsFragment
  | SessionHydrationDeletedOfficeRelationFieldsFragment;

export type AppRightDict = Record<AppStatus__typename, AppRight>;

type AccountProviderProps = {
  users: Array<SessionHydrationUserFieldsFragment>;
  offices: Array<SessionHydrationOfficeFieldsFragment>;
  relations: Array<Relation>;
  deletedRelations: Array<DeletedRelationType>;
  mailboxes: Array<Mailbox>;
  account: Account;
  myId: string;
  children: React.ReactNode;
  fundaApp: boolean;
  refetchSessionHydration: () => Promise<any>;
  warningList: Array<NotificationListItem>;
  accountIssues: Array<AccountIssueFieldsFragment>;
};

const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('name')));

/**
 * Create the session information for this user.
 *
 * Calculates the auth matrix as well as the lookup tables
 * for users, offices and the account
 */
const calculateSessionInformation = (
  me: SessionHydrationMe,
  offices: Array<SessionHydrationOfficeFieldsFragment>,
  users: Array<SessionHydrationUserFieldsFragment>,
  relations: Array<Relation>,
  fundaApp: boolean,
  deletedRelations: Array<DeletedRelationType>,
) => {
  let myAccountRelation:
    | SessionHydrationAccountRelationFieldsFragment
    | undefined = undefined;
  let myMainOffice: SessionHydrationOfficeFieldsFragment | undefined =
    undefined;
  const myOfficeRelations: Array<SessionHydrationOfficeRelationFieldsFragment> =
    [];
  const officeRelations: Array<SessionHydrationOfficeRelationFieldsFragment> =
    [];

  const deletingRelations: Array<DeletingRelation> = [];
  const deletedUsersInAccount: Array<DeletedUser> = [];
  const deletedOfficesInAccount: Array<DeletedOffice> = [];

  const officeLookup = toLookupTable(offices, 'id');

  const userToAccountRelationLookup: UserToAccountRelationMatrix = {};
  const userToAccountRelationLookupWithPendingUsers: UserToAccountRelationMatrix =
    {};

  let userToOfficeRelationLookup: UserToOfficeRelationMatrix = {};
  let userToOfficeRelationLookupWithPendingUsers: UserToOfficeRelationMatrix =
    {};
  let officeToUserRelationLookup: OfficeToUserRelationMatrix = {};

  let pendingOfficeToUserRelationLookup: OfficeToUserRelationMatrix = {};
  let officeToUserRelationLookupWithPendingUsers: OfficeToUserRelationMatrix =
    {};

  for (const relation of relations) {
    if (relation.status === UserStatus.Deleting) {
      deletingRelations.push(relation);
      continue;
    }

    if (
      relation.__typename === 'OfficeRelationship' &&
      relation.status === UserStatus.InvitedPending
    ) {
      pendingOfficeToUserRelationLookup = assocPath(
        [relation.officeId, relation.userId],
        relation,
        pendingOfficeToUserRelationLookup,
      );
      userToOfficeRelationLookupWithPendingUsers = assocPath(
        [relation.userId, relation.officeId],
        relation,
        userToOfficeRelationLookupWithPendingUsers,
      );
      officeToUserRelationLookupWithPendingUsers = assocPath(
        [relation.officeId, relation.userId],
        relation,
        officeToUserRelationLookupWithPendingUsers,
      );

      continue;
    }

    if (
      relation.__typename === 'AccountRelationship' &&
      relation.status === UserStatus.InvitedPending
    ) {
      userToAccountRelationLookupWithPendingUsers[relation.userId] = relation;

      continue;
    }

    if (relation.status !== UserStatus.Active) {
      continue;
    }

    switch (relation.__typename) {
      case 'AccountRelationship': {
        if (relation.userId === me.id) {
          myAccountRelation = relation;
        }

        userToAccountRelationLookup[relation.userId] = relation;
        userToAccountRelationLookupWithPendingUsers[relation.userId] = relation;

        break;
      }
      case 'OfficeRelationship': {
        if (relation.userId === me.id) {
          myOfficeRelations.push(relation);

          if (relation.mainOffice) {
            myMainOffice = officeLookup[relation.officeId];
          }
        }

        userToOfficeRelationLookup = assocPath(
          [relation.userId, relation.officeId],
          relation,
          userToOfficeRelationLookup,
        );

        userToOfficeRelationLookupWithPendingUsers = assocPath(
          [relation.userId, relation.officeId],
          relation,
          userToOfficeRelationLookupWithPendingUsers,
        );

        officeToUserRelationLookup = assocPath(
          [relation.officeId, relation.userId],
          relation,
          officeToUserRelationLookup,
        );
        officeToUserRelationLookupWithPendingUsers = assocPath(
          [relation.officeId, relation.userId],
          relation,
          officeToUserRelationLookupWithPendingUsers,
        );
        officeRelations.push(relation);
        break;
      }
    }
  }

  deletedRelations.forEach(relation => {
    switch (relation.__typename) {
      case 'DeletedUserToAccountRelationship': {
        const { userId, name } = relation;

        deletedUsersInAccount.push({ userId, name });

        break;
      }
      case 'DeletedOfficeToAccountRelationship': {
        const { officeId, name } = relation;

        deletedOfficesInAccount.push({ officeId, name });

        break;
      }
    }
  });

  const nonDeletedUsers: Array<SessionHydrationUserFieldsFragment> = [];
  const nonDeletedNonPendingUsers: Array<SessionHydrationUserFields_User_Fragment> =
    [];

  for (const user of users) {
    nonDeletedUsers.push(user);

    if (
      userToAccountRelationLookup[user.id]?.status !== UserStatus.Active ||
      pendingOfficeToUserRelationLookup[user.id] ||
      user.__typename !== 'User'
    ) {
      continue;
    }

    nonDeletedNonPendingUsers.push(user);
  }

  // const nonDeletedOffices = offices.filter(office => office.deleted !== true);

  const userLookup = toLookupTable(nonDeletedNonPendingUsers, 'id');
  const userLookupWithPending = toLookupTable(nonDeletedUsers, 'id');

  /**
   * If the account relation would not be available
   * none of the other data should be available either.
   *
   * If this would come from the backend a serious error
   * happened there. We can assume that it will always be available.
   */
  if (myAccountRelation == null) {
    throw Error(`Missing account relation for ${me.id}`);
  }

  const myUserDetails = userLookup[me.id];

  /**
   * If we do not have user details or a main office for ourselves the
   * same applies as for the account details above.
   */
  if (myUserDetails == null) throw Error(`Missing user details for ${me.id}`);
  if (myMainOffice == null) throw Error(`Missing main office for ${me.id}`);

  const getUserOptions = (
    officeId?: string | null,
    withAllUsersOption: boolean = true,
  ): Array<OptionOf<SessionHydrationUserFields_User_Fragment | null>> => {
    const result: Array<
      OptionOf<SessionHydrationUserFields_User_Fragment | null>
    > = [];

    if (withAllUsersOption) {
      result.push({
        label: 'Alle gebruikers',
        key: 'no-selection',
        payload: null,
      });
    }

    if (officeId == null) {
      return nonDeletedNonPendingUsers.reduce(
        (userOptions, user) => [
          ...userOptions,
          {
            key: user.id,
            label: user.name,
            payload: user,
          },
        ],
        result,
      );
    }

    const userRelations = officeToUserRelationLookup[officeId];
    if (userRelations == null) return result;

    return Object.keys(userRelations).reduce((userOptions, userId) => {
      const user = userLookup[userId];
      if (user == null) return userOptions;

      return [
        ...userOptions,
        {
          key: user.id,
          label: user.name,
          payload: user,
        },
      ];
    }, result);
  };

  return {
    me: myUserDetails,
    userLookup,
    userLookupWithPending,
    myUserDetails,
    myMainOffice,
    officeLookup,

    getOfficesForUser: (userId: string) => {
      const lookupTable = userToOfficeRelationLookupWithPendingUsers[userId];
      if (lookupTable == null) return [];

      return Object.keys(lookupTable).reduce<
        Array<SessionHydrationOfficeFieldsFragment>
      >((offices, officeId) => {
        const relation = lookupTable[officeId];
        if (relation?.status === UserStatus.Deleting) return offices;

        const officeDetails = officeLookup[officeId];
        if (officeDetails == null) return offices;

        offices.push(officeDetails);
        return offices;
      }, []);
    },
    getUsersForOfficeIncludingPending: (officeId: string) => {
      const lookupTable = officeToUserRelationLookupWithPendingUsers[officeId];
      if (lookupTable == null) return [];

      const users = sort(
        ({ __typename }) => (__typename === 'User' ? -1 : 1),
        Object.keys(lookupTable).reduce<Array<ViewableUser>>(
          (users, userId) => {
            const relation = lookupTable[userId];
            if (relation?.status === UserStatus.Deleting) return users;

            const userDetails = userLookupWithPending[userId];

            if (userDetails == null) return users;

            users.push(userDetails);
            return users;
          },
          [],
        ),
      );
      return users;
    },

    // TODO: Fix sorted users
    sortedUsers: users || sortByNameCaseInsensitive(nonDeletedUsers),
    // TODO: Fix sorted offices
    sortedOffices: offices || sortByNameCaseInsensitive(offices),
    deletingRelations,
    deletedUsersInAccount,
    deletedOfficesInAccount,
    /**
     * All available, nondeleted offices
     */
    getUserOptionsForOffice: (
      officeId?: string | null,
      withAllUsersOption: boolean = true,
    ): Array<OptionOf<SessionHydrationUserFields_User_Fragment | null>> => {
      if (officeId == null) return [];

      return getUserOptions(officeId, withAllUsersOption);
    },

    /**
     * All available, user options for a given office.
     *
     * When selecting users while giving an office, only
     * the users having a relation with that office will
     * be shown.
     */
    getUserOptions,
  };
};

const AccountContextProvider: React.FC<AccountProviderProps> = ({
  account,
  children,
  deletedRelations,
  fundaApp,
  mailboxes,
  myId,
  offices,
  refetchSessionHydration,
  relations,
  users,
}) => {
  const {
    me,
    getUserOptionsForOffice,
    getUserOptions,
    myMainOffice,
    myUserDetails,
    userLookup,
    userLookupWithPending,
    getOfficesForUser,
    getUsersForOfficeIncludingPending,
    officeLookup,
    sortedUsers,
    sortedOffices,
    deletingRelations,
    deletedUsersInAccount,
    deletedOfficesInAccount,
  } = useMemo(
    () =>
      calculateSessionInformation(
        { id: myId, __typename: 'SessionHydrationMe' },
        offices,
        users,
        relations,

        fundaApp,
        deletedRelations,
      ),
    [myId, offices, users, relations, fundaApp, deletedRelations],
  );

  const intercom = useIntercom();

  intercom.boot({
    user: {
      id: myUserDetails.id,
      email: myUserDetails.email,
      name: myUserDetails.name,
      phone: myUserDetails.phone,
      mainOffice: myMainOffice.name,
      first_admin: undefined,
      signed_up_at: undefined,
    },
  });

  const availableEmailsToSendFrom = buildAvailableEmailsToSendFrom(
    mailboxes,
    myUserDetails,
    userLookup,
    officeLookup,
    getOfficesForUser,
  );

  return (
    <AccountContext.Provider
      value={{
        viewableOffices: sortedOffices,
        viewableUsers: sortedUsers,
        me, //: viewableMe,
        account, //: convertAccount(account),
        availableEmailsToSendFrom,
        getUserOptions,
        getUserOptionsForOffice,
        userWithId: (id: string): ViewableUser | null => {
          const user = userLookup[id] || userLookupWithPending[id];
          return user ?? null;
        },
        officeWithId: (id: string): ViewableOffice | null => {
          const office = officeLookup[id];
          return office ?? null;
        },
        usersForOffice: (officeId: string): Array<ViewableUser> =>
          getUsersForOfficeIncludingPending(officeId),
        officesForUser: (userId: string): Array<ViewableOffice> =>
          getOfficesForUser(userId),
        usersForOfficeWithPendingUsers: (
          officeId: string,
        ): Array<ViewableUser> => getUsersForOfficeIncludingPending(officeId),
        refetchSessionHydration,
        // auth,

        checkUserIsBeingDeletedFromOffice: (officeId: string, userId: string) =>
          deletingRelations.find(
            relation =>
              relation.userId === userId && relation.officeId === officeId,
          ) != null,
        nameForUserWithId: (userId: string): string | null => {
          const user =
            userLookup[userId] ||
            deletedUsersInAccount.find(
              deletedUser => deletedUser.userId === userId,
            );

          if (user == null) return null;

          return user.name;
        },
        nameForOfficeWithId: (officeId: string): string | null => {
          const office =
            officeLookup[officeId] ||
            deletedOfficesInAccount.find(
              deletedOffice => deletedOffice.officeId === officeId,
            );

          if (office == null) return null;

          return office.name;
        },
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

const LoadingComponent: React.FC<{}> = () => {
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    let didCancel = false;
    const timer = setTimeout(() => {
      if (!didCancel) {
        setShowReload(true);
      }
    }, 10000);

    return () => {
      didCancel = true;
      clearTimeout(timer);
    };
  }, []);

  return (
    <InPositionContainer>
      <JustificationContainer
        align="center"
        justification="center"
        direction="column"
      >
        <DatHuisLoading />
        {showReload && (
          <Button
            onClick={() => global.window.location.reload()}
            label="Herlaad de pagina"
          />
        )}
      </JustificationContainer>
    </InPositionContainer>
  );
};

const InPositionContainer = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 50px;
    max-width: ${theme.getTokens().grid.inner};
    margin: 0 auto;
  `,
);

export default withErrorBoundary(AccountContextProvider, <LoadingComponent />);
