import { FlowCondition } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';
import {
  asFlowConditionContactDetailsProp,
  emptyFlowConditionContactDetails,
} from './ContactDetails';
import { asFlowConditionContactTagProp } from './ContactTag';
import { asFlowConditionEmailLinkClickedProp } from './EmailLinkClicked';
import { asFlowConditionEmailOpenedProp } from './EmailOpened';
import { asFlowConditionTimeElapsedProp } from './TimeElapsed';
import { asFlowConditionAppValuationReportNewProp } from './AppValuationReportNew';
import { asFlowConditionValuationReportDetailsProp } from './AppValuationReportDetails';
import { asFlowConditionAppValuationRequestNewProp } from './AppValuationRequestNew';
import { asFlowConditionValuationRequestDetailsProp } from './AppValuationRequestDetails';
import { asFlowConditionAppFundaContactRequestNewProp } from './AppFundaContactRequestNew';
import { asFlowConditionAppFundaContactRequestDetailsProp } from './AppFundaContactRequestDetails';
import { asFlowConditionAppFundaViewingRequestNewProp } from './AppFundaViewingRequestNew';
import { asFlowConditionAppFundaViewingRequestDetailsProp } from './AppFundaViewingRequestDetails';
import { asFlowConditionAppFundaBrochureRequestNewProp } from './AppFundaBrochureRequestNew';
import { asFlowConditionAppFundaBrochureRequestDetailsProp } from './AppFundaBrochureRequestDetails';
import { asFlowConditionAppBBWaardecheckReportNewProp } from './AppBBWaardecheckReportNew';
import { asFlowConditionAppBBWaardecheckReportDetailsProp } from './AppBBWaardecheckReportDetails';
import { asFlowConditionAppBBWaardecheckAppraisalRequestNewProp } from './AppBBWaardecheckAppraisalRequestNew';
import { asFlowConditionAppBBWaardecheckAppraisalRequestDetailsProp } from './AppBBWaardecheckAppraisalRequestDetails';
import { asFlowConditionAppBBWaardecheckContactRequestNewProp } from './AppBBWaardecheckContactRequestNew';
import { asFlowConditionAppVBOWaardecheckReportNewProp } from './AppVBOWaardecheckReportNew';
import { asFlowConditionAppVBOWaardecheckReportDetailsProp } from './AppVBOWaardecheckReportDetails';
import { asFlowConditionAppVBOWaardecheckAppraisalRequestNewProp } from './AppVBOWaardecheckAppraisalRequestNew';
import { asFlowConditionAppVBOWaardecheckAppraisalRequestDetailsProp } from './AppVBOWaardecheckAppraisalRequestDetails';
import { asFlowConditionAppVBOWaardecheckContactRequestNewProp } from './AppVBOWaardecheckContactRequestNew';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';
import { FlowConditionFieldsFragment } from '~/graphql/types';
import { extractHandledFlowConditions } from '~/graphql/types.client';
import { asFlowConditionAppZapierNewProp } from './AppZapierNew';
import { asFlowConditionAppZapierDetailsProp } from './AppZapierDetails';
import { asFlowConditionAppHypotheekbondDetailsProp } from './AppHypotheekbondDetails';
import { asFlowConditionAppHypotheekbondNewProp } from './AppHypotheekbondNew';

export default (
  condition_?: FlowConditionFieldsFragment | null | undefined,
): Omit<FlowCondition, 'id'> => {
  if (condition_ == null) return emptyFlowConditionContactDetails();

  const condition = extractHandledFlowConditions([condition_])[0];

  switch (condition.__typename) {
    case 'Flow_Condition_Contact_New':
      throw Error(
        `${cleanedFilename(
          __filename,
        )} Flow_Condition_Contact_New not supported yet!`,
      );

    case 'Flow_Condition_Contact_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionContactDetailsProp([condition], false);
    case 'Flow_Condition_Contact_Tag':
      return asFlowConditionContactTagProp(condition);
    case 'Flow_Condition_Event_Email_LinkClicked':
      return asFlowConditionEmailLinkClickedProp(condition);
    case 'Flow_Condition_Event_Email_Opened':
      return asFlowConditionEmailOpenedProp(condition);
    case 'Flow_Condition_Time_Elapsed':
      return asFlowConditionTimeElapsedProp(condition);
    case 'Flow_Condition_Event_Contact_App_ValuationReport_New':
      return asFlowConditionAppValuationReportNewProp(condition);
    case 'Flow_Condition_Event_Contact_App_ValuationReport_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionValuationReportDetailsProp([condition], false);
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_New':
      return asFlowConditionAppValuationRequestNewProp(condition);
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionValuationRequestDetailsProp([condition], false);
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New':
      return asFlowConditionAppFundaContactRequestNewProp();
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New':
      return asFlowConditionAppFundaViewingRequestNewProp();
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New':
      return asFlowConditionAppFundaBrochureRequestNewProp();
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details':
      return asFlowConditionAppFundaContactRequestDetailsProp(
        // allShouldBeTrue does not matter in this situation as it can't be a group
        [condition],
        false,
      );
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionAppFundaViewingRequestDetailsProp(
        [condition],
        false,
      );
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionAppFundaBrochureRequestDetailsProp(
        [condition],
        false,
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New':
      return asFlowConditionAppBBWaardecheckReportNewProp();
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionAppBBWaardecheckReportDetailsProp(
        [condition],
        false,
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New':
      return asFlowConditionAppBBWaardecheckAppraisalRequestNewProp();
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionAppBBWaardecheckAppraisalRequestDetailsProp(
        [condition],
        false,
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New':
      return asFlowConditionAppBBWaardecheckContactRequestNewProp([condition]);

    case 'Flow_Condition_Event_Contact_App_Zapier_New':
      return asFlowConditionAppZapierNewProp(condition);
    case 'Flow_Condition_Event_Contact_App_Zapier_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionAppZapierDetailsProp([condition], false);
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New':
      return asFlowConditionAppVBOWaardecheckReportNewProp();
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionAppVBOWaardecheckReportDetailsProp(
        [condition],
        false,
      );
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New':
      return asFlowConditionAppVBOWaardecheckAppraisalRequestNewProp();
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details':
      // allShouldBeTrue does not matter in this situation as it can't be a group
      return asFlowConditionAppVBOWaardecheckAppraisalRequestDetailsProp(
        [condition],
        false,
      );
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New':
      return asFlowConditionAppVBOWaardecheckContactRequestNewProp([condition]);

    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details':
      return asFlowConditionAppHypotheekbondDetailsProp([condition], false);
    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_New':
      return asFlowConditionAppHypotheekbondNewProp(condition);

    default:
      return assertNever(condition);
  }
};
