import { isEmpty, uniq } from 'ramda';
import React, {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { animated, useSpring, useTransition } from 'react-spring';
import styled, { css, useTheme } from 'styled-components';
import { componentSizes, Size } from '~/styles/constants';

import { BaseColor, BaseColorVariant } from '~/theme/System/tokens';
import arrayToCss from '~/util/arrayToCss';
import Icon from '../Icon';
import TEST_ID from './index.testid';

export type Appearance = 'primary' | 'success' | 'danger' | 'accent';

type Ref = HTMLInputElement;

export type ValidationFunction = (
  value: string | number | null,
) => string | true;

export type Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'size' | 'value' | 'defaultValue' | 'onError'
> & {
  dataTestId?: string;
  size?: Size;
  appearance?: Appearance;
  validation?: Array<ValidationFunction>;
  value?: string | number | null;
  defaultValue?: string | number | null;
  validationIndicator?: boolean;
  externalErrors?: Array<string>;
  label?: string;
  ref?: React.Ref<any>;
  onError?: (error: string | null) => void;
  width?: string;
};

const Input = forwardRef<Ref, Props>(
  (
    {
      dataTestId,
      size = 'medium',
      label,
      value,
      defaultValue,
      validation = [],
      appearance,
      validationIndicator = false,
      className,
      externalErrors = [],
      onChange,
      onError,
      children,
      width,
      ...rest
    },
    ref,
  ) => {
    const [hasChanged, setHasChanged] = useState(false);
    const [hasFocus, setHasFocus] = useState<boolean>(false);
    // We need to keep a local copy of the value to be able to run validations on `defaultValue`
    const [localValue, setLocalValue] = useState(defaultValue ?? value);

    const validationErrors = !isEmpty(externalErrors)
      ? externalErrors
      : !hasChanged
      ? []
      : validation.reduce<Array<string>>((errors, validationFunction) => {
          const result = validationFunction(localValue ?? null);
          if (result === true) return errors;
          errors.push(result);
          return uniq(errors);
        }, []);

    const hasError = validationErrors.length !== 0;

    const labelStyle = useSpring({
      from: { opacity: 0 },
      to: { opacity: hasError ? 1 : 0 },
    });

    useEffect(() => {
      if (onError) {
        if (hasError) {
          onError(validationErrors[0]);
        } else {
          return onError(null);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError]);

    return (
      <Outer className={className} style={{ width }}>
        {hasError && (
          <ErrorLabel
            size={size}
            $hasError={hasError}
            style={labelStyle}
            data-testid={TEST_ID.ERROR_LABEL}
          >
            {validationErrors.length !== 0 && validationErrors[0]}
          </ErrorLabel>
        )}
        {!hasError && label != null && <Label size={size}>{label}</Label>}

        <Container
          $size={size}
          $hasError={hasError}
          $appearance={
            hasFocus && !hasError && validationIndicator
              ? 'success'
              : appearance
          }
          className={className}
        >
          <InputElement
            $size={size}
            ref={ref}
            data-testid={dataTestId}
            value={value ?? undefined}
            defaultValue={defaultValue ?? undefined}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            onChange={e => {
              setHasChanged(true);
              setLocalValue(e.target.value);
              if (onChange) onChange(e);
            }}
            data-error={hasError}
            {...rest}
          />

          {validationIndicator === true && hasChanged && (
            <SuccessIndicator $hasError={hasError} size={size} />
          )}

          {children}
        </Container>
      </Outer>
    );
  },
);

type SuccessIndicatorProps = { $hasError: boolean; size?: Props['size'] };
const SuccessIndicator: React.FC<SuccessIndicatorProps> = ({
  $hasError,
  size = 'medium',
}) => {
  const theme = useTheme();

  const transitions = useTransition(!$hasError, {
    from: { transform: 'translateX(-10px)', opacity: 0, scale: 0 },
    enter: { transform: 'translateX(0px)', opacity: 1, scale: 1 },
    leave: { transform: 'translateX(10px)', opacity: 0, scale: 0 },
    config: {
      tension: 300,
      friction: 20,
    },
  });

  return transitions((style, show) => {
    if (!show) return null;

    return (
      <Icon
        name="check"
        background="success"
        color={theme.color('white')}
        as={animated.span}
        // https://github.com/pmndrs/react-spring/issues/1102#issuecomment-803208159
        style={{
          ...(style as any),
          position: 'absolute',
          bottom: theme.space(componentSizes[size].padding[0]),
          right: theme.space(componentSizes[size].padding[1]),
        }}
      />
    );
  });
};

const appearances = {
  primary: {
    main: 'primary' as BaseColor,
    hue: 'light' as BaseColorVariant,
  },
  success: {
    main: 'success' as BaseColor,
    hue: 'base' as BaseColorVariant,
  },
  danger: {
    main: 'danger' as BaseColor,
    hue: 'light' as BaseColorVariant,
  },
  accent: {
    main: 'accent' as BaseColor,
    hue: 'base' as BaseColorVariant,
  },
};

type InputProps = { $size?: Size };
const InputElement = styled.input<InputProps>(
  ({ $size = 'medium', theme }) => css`
    border: none;
    width: 100%;

    padding: ${arrayToCss(componentSizes[$size].padding, theme)};

    border-radius: ${theme.getTokens().border.radius.s};
    color: ${theme.color('text')};

    :disabled {
      color: ${theme.color('grey')};
      background-color: ${theme.color('white', 'dark')};
    }

    ::placeholder {
      color: ${theme.color('grey', 'dark')};
    }

    :focus::placeholder {
      color: transparent;
    }

    /* stylelint-disable */
    /* Remove arrows from number input elements */
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    /* Remove arrows from number input elements on Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
    /* stylelint-enable */
  `,
);

const Outer = styled.div<{}>``;

export const Container = styled.div<{
  $appearance?: Appearance;
  $hasError?: boolean;
  $size?: Size;
}>(
  ({ theme, $size = 'medium', $appearance, $hasError }) => css`
    display: flex;
    justify-content: space-between;
    position: relative;

    font-size: ${theme.fontSize(componentSizes[$size].fontSize)};
    background-color: ${theme.color('white')};

    border-radius: ${theme.getTokens().border.radius.s};
    border-color: ${$hasError
      ? theme.color('danger')
      : $appearance
      ? theme.color(appearances[$appearance].main, appearances[$appearance].hue)
      : theme.color('grey')};
    border-style: solid;
    border-width: ${theme.getTokens().border.width.s};
  `,
);

const ErrorLabel = styled(animated.span)<{
  size: Size;
  $hasError: boolean;
}>(
  ({ size = 'medium', theme, $hasError }) => css`
    display: inline-block;
    color: ${$hasError ? theme.color('danger') : 'auto'};
    font-size: ${theme.fontSize(componentSizes[size].fontSize)};
    margin-left: ${theme.space('xxxs')};
    margin-bottom: ${theme.space('xxxs')};
  `,
);

const Label = styled.span<{
  size: Size;
}>(
  ({ size = 'medium', theme }) => css`
    display: inline-block;
    color: 'auto';
    font-weight: ${theme.fw('semiBold')};
    font-size: ${theme.fontSize(componentSizes[size].fontSize)};
    margin-left: ${theme.space('xxxs')};
    margin-bottom: ${theme.space('xxxs')};
  `,
);

export default Input;
