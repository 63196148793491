import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { Plan, BillingCycle } from '~/graphql/types';

import TEST_ID from './PriceCard.testid';
import { PRICING_INFO } from '~/scenes/Settings/Subscription/constants';
import Button from '~/components/Button';
import { asPriceString } from '~/util/money';
import Icon from '~/components/Icon';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

const text = {
  selectButtonLabel: 'Selecteer',
  bestChoiceLabel: 'Beste keuze',
  currentChoiceLabel: 'Huidige keuze',
  pricePerLabel: 'p/m',
};
type Props = {
  subscriptionPlan: Plan;
  billingCycle: BillingCycle;
  isSelected: boolean;
  bestChoice: boolean;
  currentChoice: boolean;
  onSelect: () => void;
  dataTestid?: string;
  disabled: boolean;
};
const PriceCard = ({
  subscriptionPlan,
  isSelected,
  billingCycle,
  bestChoice,
  currentChoice,
  onSelect,
  dataTestid,
  disabled,
}: Props) => {
  const pricingInfo = PRICING_INFO[subscriptionPlan];
  const { title, pricePerMonth, pricePerYear, highlights } = pricingInfo;
  const price =
    billingCycle === BillingCycle.Monthly ? pricePerMonth : pricePerYear;

  let accentComponent: ReactElement | null = null;
  if (billingCycle === BillingCycle.Yearly) {
    accentComponent = (
      <AccentLabel>{asPriceString(pricePerMonth, true)}</AccentLabel>
    );
  }

  let highlightedChoiceContainer: ReactElement | null = null;
  if (bestChoice) {
    highlightedChoiceContainer = (
      <BestChoiceContainer data-testid={TEST_ID.BEST_CHOICE_LABEL}>
        <BestChoiceLabel>{text.bestChoiceLabel}</BestChoiceLabel>
      </BestChoiceContainer>
    );
  }
  if (currentChoice) {
    highlightedChoiceContainer = (
      <BestChoiceContainer data-testid={TEST_ID.CURRENT_CHOICE_LABEL}>
        <BestChoiceLabel>{text.currentChoiceLabel}</BestChoiceLabel>
      </BestChoiceContainer>
    );
  }

  return (
    <Container
      data-testid={dataTestid}
      data-selected={isSelected}
      isSelected={isSelected}
      onClick={() => {
        if (!disabled) {
          onSelect();
        }
      }}
    >
      {highlightedChoiceContainer}
      <Title hasBestChoice={bestChoice || currentChoice}>{title}</Title>
      <PriceContainer>
        {accentComponent} <PriceLabel>{asPriceString(price, true)}</PriceLabel>{' '}
        {text.pricePerLabel}
      </PriceContainer>
      <HighlightsContainer>
        {highlights.map((highlight, idx) => (
          <HighlightContainer key={`highlight-${idx}`}>
            <StyledCheck name="check" />
            <Highlight>{highlight}</Highlight>
          </HighlightContainer>
        ))}
      </HighlightsContainer>
      <Button
        ghost={!isSelected}
        size="medium"
        disabled={disabled}
        data-testid={TEST_ID.SELECT_BUTTON}
        label={text.selectButtonLabel}
        margin={['l', null, 'xxs', null]}
      />
    </Container>
  );
};

type ContainerProps = {
  isSelected: boolean;
};
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 210px;

  ${({ theme, isSelected }) => css`
    border: ${theme.getTokens().border.width.s} solid
      ${isSelected ? theme.color('primary', 'light') : theme.color('grey')};
    border-radius: ${theme.getTokens().border.radius.s};
    padding: ${theme.space('m')};
    background-color: ${theme.color('white')};
    margin: ${theme.space('s')};
    box-shadow: ${theme.getTokens().boxShadow.card};

    &:hover {
      box-shadow: ${theme.getTokens().boxShadow.cardHover};
    }
  `};
`;

type TitleProps = {
  hasBestChoice: boolean;
};
const Title = styled.div<TitleProps>`
  ${({ theme, hasBestChoice }) => {
    if (!hasBestChoice) {
      return css`
        margin-top: ${theme.space('xxl')};
      `;
    }

    return ``;
  }}

  ${({ theme }) => css`
    color: ${theme.color('primary')};
    font-size: ${theme.fontSize('m')};
  `};
`;

const PriceLabel = styled.span<{}>`
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.color('primary')};
    font-size: ${FONT_SIZE_OF_18}px;
  `};
`;

const PriceContainer = styled.div<{}>`
  ${({ theme }) => css`
    color: ${theme.color('text')};
    font-size: ${theme.fontSize('s')};
    margin: ${theme.space('m')} 0;
  `}
`;

const HighlightsContainer = styled.div<{}>`
  align-self: flex-start;
  width: 100%;
`;
const HighlightContainer = styled.div<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color('white', 'dark')};
  `};
`;

const Highlight = styled.div<{}>`
  ${({ theme }) => css`
    font-size: ${theme.fontSize('s')};
    padding: ${theme.space('xxs')} 0;
    margin-left: ${theme.space('s')};
  `}
`;

const StyledCheck = styled(Icon)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('success')};
  `}
`;

const BestChoiceContainer = styled.div<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('xxs')};
    margin-bottom: ${theme.space('m')};
  `};
`;

const BestChoiceLabel = styled.span<{}>`
  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
    border-radius: ${theme.getTokens().border.radius.s};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('primary', 'light')};
    padding: ${theme.space('xxxs')} ${theme.space('xxs')} 0
      ${theme.space('xxs')};
    font-size: ${theme.fontSize('s')};
  `};
`;

export const AccentLabel = styled.span<{}>`
  position: relative;

  ${({ theme }) => css`
    color: ${theme.color('accent')};
    font-size: ${theme.fontSize('base')};
    margin-right: ${theme.space('xxs')};

    &:after {
      position: absolute;
      display: inline-block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: ${theme.color('accent')};
      bottom: 50%;
      left: 0;
      transform: rotate(-15deg);
    }
  `};
`;

export default PriceCard;
