import React from 'react';
import { useTransition, UseTransitionProps } from 'react-spring';
import styled from 'styled-components';
import { ANIMATION_CONFIG } from '~/scenes/Settings/constants';

import ActivitySummary from '../ActivitySummary';

type Activity = {
  icon: React.ReactNode;
  value: number;
  description: string;
  label: string;
  link: string;
};

export type Props = {
  activities: { [key: string]: Activity };
  formatOptions?: Intl.NumberFormatOptions;
  dataTestId?: string;
  style?: React.CSSProperties;
};

const ActivitySummaryList = ({
  activities,
  formatOptions,
  dataTestId = 'activity-summery-list',
  ...rest
}: Props) => {
  const activityList = Object.keys(activities);

  const transitionProps: UseTransitionProps = {
    from: ANIMATION_CONFIG.from,
    enter: ANIMATION_CONFIG.to,
    leave: ANIMATION_CONFIG.from,
    config: {
      mass: 1,
      tension: 110,
      friction: 14,
    },
    trail: 125,
    keys: item => item,
  };
  const transitions = useTransition(activityList, transitionProps);

  return (
    <Container {...rest}>
      {transitions(
        (style, item, { key }, index) =>
          item && (
            <ActivitySummary
              key={key}
              animation={style}
              formatOptions={formatOptions}
              index={index}
              dataTestId={`${dataTestId}-${item}`}
              {...activities[item]}
            />
          ),
      )}
    </Container>
  );
};

const Container = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.space('l')};
`;

export default ActivitySummaryList;
