import React from 'react';
import styled from 'styled-components';

import { InsertContactMutation as InsertContactMutationResultType } from '~/graphql/types';

import { Drawer } from '~/components';
import TEST_ID from './index.testid';
import AddSingleContact from '../components/AddSingleContact';
export type ContactsMetaType = {
  accountId: string;
  officeId: string;
  userId: string;
};
type Props = {
  contactsMeta: ContactsMetaType;
  onNewContactAdded: (newContact: InsertContactMutationResultType) => void;
  isShowing: boolean;
  hide: () => void;
};
const text = {
  addContactLabel: 'Nieuw contact',
  closeButtonLabel: 'Ok',
  singleContactExplanation: 'Voeg een contact toe',
};
const AddContactSidebar = ({
  contactsMeta,
  isShowing,
  hide,
  onNewContactAdded,
}: Props) => (
  <Container data-testid={TEST_ID.CONTAINER}>
    <Drawer active={isShowing} hideSidebar={hide} title={text.addContactLabel}>
      <ExplanationText>{text.singleContactExplanation}</ExplanationText>
      <AddSingleContact
        contactsMeta={contactsMeta}
        onNewContactAdded={onNewContactAdded}
      />
    </Drawer>
  </Container>
);

const ExplanationText = styled.p<{}>``;

const Container = styled.div<{}>``;

export default AddContactSidebar;
