import React from 'react';

import {
  ParameterField,
  TemplateStringParameterField,
  TemplateStringParameterValue,
  StringParameterField,
  NumberParameterField,
  OfficeParameterField,
  UserParameterField,
  EmailParameterField,
  BooleanParameterField,
  TypedField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { OptionOf, SelectedOptionOf } from '~/components/Inputs/Dropdown';

import { Dropdown, InputGroup } from '~/components/Inputs';
import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { ParameterTemplateStringFieldComponent } from './FlowParameterTemplateStringField';
import emptyFlowParameterField from './emptyFlowParameterField';
import TEST_ID from './ParameterFieldComponent.testid';
import cleanedFilename from '~/util/cleanedFilename';
import { PARAMETER_VALUE_TYPE } from '../ParameterValue/constants';

const text = {
  fieldLabel: 'Veld',
  fieldValueLabel: 'Waarde',
};
type InitialFieldDict = {
  Field_Type_String: StringParameterField;
  Field_Type_Number: NumberParameterField;
  Field_Type_Office: OfficeParameterField;
  Field_Type_User: UserParameterField;
  Field_Type_Email: EmailParameterField;
  Field_Type_Boolean: BooleanParameterField;
  Field_Type_Template_String: TemplateStringParameterField;
};
type Props = {
  initialField: ParameterField;
  fieldOptions: Array<TypedField>;
  setGetValueFn: (fn: () => ParameterField) => void;
  announceChanges: () => void;
};
type State = {
  initialFieldDict: InitialFieldDict;
  fieldOptions: Array<OptionOf<TypedField>>;
  selectedFieldIdx: number;
};
class ParameterFieldComponent extends React.Component<Props, State> {
  getTemplateStringValue: () => TemplateStringParameterValue | null = () => {
    const { initialField } = this.props;

    if (
      initialField.value &&
      // @TODO Check this
      initialField.value.type === PARAMETER_VALUE_TYPE.TEMPLATE_STRING
    ) {
      return initialField.value;
    }

    return null;
  };

  constructor(props: Props) {
    super(props);

    const { fieldOptions, initialField } = this.props;

    const options = fieldOptions.map(field => ({
      label: field.label,
      key: field.name,
      payload: field,
    }));

    this.state = {
      initialFieldDict: getInitialFieldDictFor(initialField, fieldOptions),
      fieldOptions: options,
      selectedFieldIdx: options.findIndex(
        option => option.payload.name === initialField.name,
      ),
    };
  }

  componentDidMount() {
    this.props.setGetValueFn(this.getValue);
  }

  getValue = (): ParameterField => {
    const field = this.getSelectedField();

    switch (field.type) {
      case FIELD_TYPE.TEMPLATE_STRING:
        return {
          type: FIELD_TYPE.TEMPLATE_STRING,
          name: field.name,
          label: field.label,
          value: this.getTemplateStringValue(),
        };
      default:
        throw Error(
          `${cleanedFilename(__filename)} >> getValue | Field of type ${
            field.type
          } not implemented yet!`,
        );
    }
  };

  onChangeField = (selectedOption: SelectedOptionOf<TypedField>) => {
    const { selectedOptionIdx } = selectedOption;

    this.setState(
      {
        selectedFieldIdx: selectedOptionIdx,
      },
      () => {
        this.props.announceChanges();
      },
    );
  };

  getSelectedField = () => {
    const { selectedFieldIdx } = this.state;
    const { fieldOptions } = this.props;

    return fieldOptions[selectedFieldIdx];
  };

  setGetTemplateStringValue = (fn: () => TemplateStringParameterValue) => {
    this.getTemplateStringValue = fn;
  };

  render() {
    const { announceChanges } = this.props;
    const { fieldOptions, selectedFieldIdx, initialFieldDict } = this.state;

    return (
      <InputGroup data-testid={TEST_ID.CONTAINER}>
        <Dropdown
          dataTestid={TEST_ID.FIELD_DROPDOWN}
          label={text.fieldLabel}
          options={fieldOptions}
          selectedOptionIdx={selectedFieldIdx}
          onChange={this.onChangeField}
        />
        {inputComponentSwitcher(
          initialFieldDict,
          this.getSelectedField(),
          this.setGetTemplateStringValue,
          announceChanges,
        )}
      </InputGroup>
    );
  }
}

const getInitialFieldDictFor = (
  initialField: ParameterField,
  fieldOptions: Array<TypedField>,
) => {
  const dict = {};

  fieldOptions.forEach(option => {
    if (initialField.type === option.type) {
      dict[initialField.type] = initialField;
    } else {
      const optedValue = emptyFlowParameterField(option);

      if (optedValue.type !== option.type) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} >> getInitialFieldDictFor - Should not occur: emptyFlowParameter type ${
            optedValue.type
          } does not equal option.type ${option.type}`,
        );
      }

      dict[option.type] = optedValue;
    }
  });

  return dict as InitialFieldDict;
};

const inputComponentSwitcher = (
  initialFieldDict: InitialFieldDict,
  selectedFieldType: TypedField,
  setGetValueFn: (arg0: () => TemplateStringParameterValue) => void,
  announceChanges: () => void,
): React.ReactNode => {
  switch (selectedFieldType.type) {
    case FIELD_TYPE.TEMPLATE_STRING: {
      const initialField = initialFieldDict[FIELD_TYPE.TEMPLATE_STRING];

      if (initialField.value == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} Should not occur | initialField value is null!`,
        );
      }

      return (
        <ParameterTemplateStringFieldComponent
          initialTemplateString={initialField.value}
          setGetTemplateComponent={setGetValueFn}
          announceChanges={announceChanges}
          type="INPUT"
          singleLine
        />
      );
    }
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} >> inputComponentSwitcher - Parameter with a field of type (${
          selectedFieldType.type
        }) has no component assigned to it`,
      );
  }
};

export default ParameterFieldComponent;
