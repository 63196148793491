import React, { ReactElement, useContext } from 'react';
import styled, { css } from 'styled-components';

import { StripeSubscription } from '../../types.flow';

import { LinkButton } from '~/components/Buttons';
import SubscriptionDetails from '../SubscriptionDetails';
import DetailsBlock from '~/components/DetailsBlock';
import UpdateSubscriptionFlow from '../ChangeOrUpdateSubscriptionFlows/UpdateSubscriptionFlow';
import ModalContext from '~/contexts/ModalContext';
import getActiveSubscription from '~/scenes/Settings/Subscription/util/getActiveSubscription';

import TEST_ID from './index.testid';
import SubscriptionContext from '../../context/SubscriptionContext';

const text = {
  blockTitle: 'Abonnement',
  changeSubscriptionButtonLabel: 'Wijzig abonnement',
};
type Props = {
  /** If the current subscription is being cancelled and there is no new one incoming */
  isCancelling: boolean;
  activeAndUpcomingSubscriptions: Array<StripeSubscription>;
};
const SubscriptionInfoBlock: React.FC<Props> = ({
  isCancelling,
  activeAndUpcomingSubscriptions,
}) => {
  const { showModal, hideModal } = useContext(ModalContext);
  const { refetchSubscriptionData } = useContext(SubscriptionContext);
  const activeSubscription = getActiveSubscription(
    activeAndUpcomingSubscriptions,
  );

  let changeSubscriptionButtonComponent: ReactElement | null = null;
  if (!isCancelling) {
    changeSubscriptionButtonComponent = (
      <ButtonContainer>
        <LinkButton
          testId={TEST_ID.CHANGE_SUBSCRIPTION_PLAN}
          onClick={() => {
            showModal(
              <UpdateSubscriptionFlow
                initialBillingCycle={
                  activeSubscription == null
                    ? null
                    : activeSubscription.billingCycle
                }
                initialSubscriptionPlan={
                  activeSubscription == null ? null : activeSubscription.plan
                }
                onComplete={() =>
                  refetchSubscriptionData().then(() => hideModal())
                }
              />,
            );
          }}
        >
          {text.changeSubscriptionButtonLabel}
        </LinkButton>
      </ButtonContainer>
    );
  }

  return (
    <DetailsBlock
      dataTestid={TEST_ID.CONTAINER}
      title={text.blockTitle}
      manualLayout
    >
      <SubscriptionContainer>
        {activeAndUpcomingSubscriptions.map((subscription, idx) => (
          <StyledSubscriptionDetails
            key={`subscription-${idx}`}
            subscription={subscription}
          />
        ))}
        {changeSubscriptionButtonComponent}
      </SubscriptionContainer>
    </DetailsBlock>
  );
};

const SubscriptionContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    margin-top: ${theme.space('s')};
  `};
`;
const StyledSubscriptionDetails = styled(SubscriptionDetails)<{}>`
  ${({ theme }) => css`
    margin-bottom: ${theme.space('m')};
  `};
`;

const ButtonContainer = styled.div<{}>`
  align-self: flex-end;
`;

export default SubscriptionInfoBlock;
