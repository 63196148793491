import { FlowConditionTypeOption } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';

import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';

/** @TODO For now, we show all conditions even though some of the apps might be disabled.
 * This is because there might be running flows that use conditions related to the disabled apps
 * We want those flows to keep running  */
const getVisibleFlowConditionTypes = (): Array<FlowConditionTypeOption> =>
  Object.values(FLOW_CONDITION_TYPES);

export default getVisibleFlowConditionTypes;
