import { FlowParameterMappingFieldsFragment } from '~/graphql/types';
import { FlowParameterMapping } from './types.flow';

import asFlowParameterMappingParameterProp from './asFlowParameterMappingParameterProp';

export default (
  fragment: FlowParameterMappingFieldsFragment,
): FlowParameterMapping => {
  const { mappingId, mapping } = fragment;

  return {
    mappingId,
    mapping: asFlowParameterMappingParameterProp(mapping),
  };
};
