import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterEventFields on Flow_Parameter__Event {
    __typename

    ... on Flow_Parameter_Event_Contact_AppValuationReport_Pointer {
      variable
      field
    }

    ... on Flow_Parameter_Event_Contact_AppValuationRequest_Pointer {
      variable
      field
    }

    ... on Flow_Parameter_Event_Contact_App_Funda_ContactRequest_Pointer {
      variable
      field
    }

    ... on Flow_Parameter_Event_Contact_App_Funda_ViewingRequest_Pointer {
      variable
      field
    }

    ... on Flow_Parameter_Event_Contact_App_Funda_BrochureRequest_Pointer {
      variable
      field
    }

    ... on Flow_Parameter_Event_Contact_App_BBWaardecheck_Report_Pointer {
      variable
      field
    }
    ... on Flow_Parameter_Event_Contact_App_BBWaardecheck_AppraisalRequest_Pointer {
      variable
      field
    }

    ... on Flow_Parameter_Event_Contact_App_Zapier_Pointer {
      variable
      field
    }
    ... on Flow_Parameter_Event_Contact_App_VBOWaardecheck_Report_Pointer {
      variable
      field
    }
    ... on Flow_Parameter_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Pointer {
      variable
      field
    }

    ... on Flow_Parameter_Event_Contact_App_Hypotheekbond_Pointer {
      variable
      field
    }
  }
`;
