import React from 'react';
import styled from 'styled-components';

import { safeFormattedDate, safeFormattedTime } from '~/util/date';
import TEST_ID from './TaskClosedBy.testid';

type Props = {
  /** The name of the person that closed the task */
  closedByName: string | null;

  /** The date the task was closed */
  date: Date;
};

const text = {
  noName: (dateValue: string, timeValue: string) =>
    `Voltooid op ${dateValue} ${timeValue}`,
  withName: (closedByName: string, dateValue: string, timeValue: string) => (
    <>
      Voltooid door <UserLabel>{closedByName}</UserLabel> op {dateValue}{' '}
      {timeValue}
    </>
  ),
};

const TaskClosedBy = ({ date, closedByName }: Props) => {
  const dateValue = safeFormattedDate(date);
  const timeValue = safeFormattedTime(date);

  return (
    <div data-testid={TEST_ID.CONTAINER}>
      {closedByName == null
        ? text.noName(dateValue, timeValue)
        : text.withName(closedByName, dateValue, timeValue)}
    </div>
  );
};

const UserLabel = styled.span<{}>`
  font-weight: 600;
  ${({ theme }) => `
    color: ${theme.color('primary')};
  `};
`;

export default TaskClosedBy;
