import { isEmpty } from 'ramda';
import React from 'react';
import styled from 'styled-components';

import TEST_ID from './index.testid';
import Button from '~/components/Button';
import Input, { ValidationFunction } from '~/components/Input';
import JustificationContainer from '~/components/JustificationContainer';

export type Props = {
  dataTestId?: string;
  onSave: () => void;
  onChange: (event: any) => void;
  value: string;
  validation: Array<ValidationFunction>;
};

const checkIsEmpty = value =>
  !isEmpty(value) && value.trim().length ? true : 'Vul een waarde in';

const EditingSection: React.FC<Props> = ({
  onSave,
  onChange,
  value,
  validation,
  ...rest
}) => (
  <>
    <Container dataTestId={TEST_ID.CONTAINER} align="end" {...rest}>
      <StyledInput
        value={value}
        placeholder="Typ hier een tekst"
        onChange={onChange}
        autoFocus
        validation={[checkIsEmpty, ...validation]}
        dataTestId={TEST_ID.INPUT}
      />
      <SubmitButton
        size="medium"
        label="Klaar"
        icon="check"
        appearance="secondary"
        onClick={onSave}
        disabled={isEmpty(value)}
        dataTestId={TEST_ID.SUBMIT_BUTTON}
      />
    </Container>
  </>
);

const Container = styled(JustificationContainer)<{}>`
  width: 100%;
`;

const SubmitButton = styled(Button)<{}>`
  margin-left: ${({ theme }) => theme.space('xxs')};
`;

const StyledInput = styled(Input)<{}>`
  width: 100%;
`;

export default EditingSection;
