import { Flow_Condition__Input } from '~/graphql/types';

const asFlowConditionAppFundaViewingRequestNewInput =
  (): Flow_Condition__Input => ({
    EventContactApp: {
      Funda: {
        ViewingRequest: {
          New: {
            noOperation: true,
          },
        },
      },
    },
  });

export default asFlowConditionAppFundaViewingRequestNewInput;
