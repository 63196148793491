type GetPointerOffset_Args<
  Parent extends HTMLElement = HTMLElement,
  Child extends HTMLElement = HTMLElement,
> = {
  baseOffset?: number;
  parentElement: Parent | null;
  childElement: Child | null;
};
const getPointerOffset = ({
  baseOffset = 23,
  parentElement,
  childElement,
}: GetPointerOffset_Args): number => {
  if (!parentElement) return baseOffset;
  if (!childElement) return baseOffset;

  const { left: parentLeft, width: parentWidth } =
    parentElement.getBoundingClientRect();

  const { left: currentLeft } = childElement.getBoundingClientRect();

  return parentWidth / 2 + (parentLeft - currentLeft);
};

export default getPointerOffset;
