import React, { useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';

import useRepositionToBeInView from '../util/useRepositionToBeInView';
import { SystemSize } from '~/theme';
import arrayToCss from '~/util/arrayToCss';

export type Props = {
  className?: string;
  padding?: Array<SystemSize | null>;
};

const Tooltip: React.FC<Props> = ({
  children,
  padding = ['base'],
  ...rest
}) => {
  const [componentRef, transform, recalculatePosition] =
    useRepositionToBeInView<HTMLDivElement>();

  useLayoutEffect(() => {
    recalculatePosition();
  }, [recalculatePosition]);

  return (
    <Container
      {...rest}
      $padding={padding}
      ref={componentRef}
      translateX={transform.x}
      translateY={transform.y}
    >
      {children}
    </Container>
  );
};

type ContainerProps = {
  translateX: number;
  translateY: number;
  $padding: Array<SystemSize | null>;
};
const Container = styled.div<ContainerProps>(
  ({ theme, translateX, translateY, $padding }) => css`
    position: absolute;
    transform: translateX(${translateX}px) translateY(${translateY}px);
    color: ${theme.color('white')};
    background-color: ${theme.color('primary')};
    border: ${theme.getTokens().border.width.s} solid ${theme.color('primary')};
    border-radius: ${theme.getTokens().border.radius.base};
    margin-top: ${theme.space('s')};
    padding: ${arrayToCss($padding, theme)};
    z-index: ${theme.z('dropdown')};
    font-size: ${theme.fs('base')};
    font-weight: normal;
    overflow-wrap: break-word;
  `,
);

export default Tooltip;
