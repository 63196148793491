import React from 'react';
import styled, { css } from 'styled-components';
import { MinimalDropdown } from '~/components/Inputs';

export type Props = {
  dataTestId: string;
  shouldShow: boolean;
  hasIssue: boolean;
  value: FlowConditionConnector;
  onChange: (payload: FlowConditionConnector) => void;
};

export const enum FlowConditionConnector {
  And = 'And',
  Or = 'Or',
}

const operatorOptions = [
  {
    label: 'en',
    payload: FlowConditionConnector.And,
    key: FlowConditionConnector.And,
  },
  {
    label: 'of',
    payload: FlowConditionConnector.Or,
    key: FlowConditionConnector.Or,
  },
];

const OperatorDropdown: React.FC<Props> = ({
  onChange,
  value,
  hasIssue,
  shouldShow,
  dataTestId,
}) => {
  if (!shouldShow) return null;

  return (
    <Container>
      <MinimalDropdown
        options={operatorOptions}
        selectedOptionIdx={operatorOptions.findIndex(
          option => option.payload === value,
        )}
        onChange={({ option }) => {
          onChange(option.payload);
          return option.payload;
        }}
        dataTestid={dataTestId}
        outline={!hasIssue}
        small
      />
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    margin-right: ${theme.space('s')};
    align-self: flex-start;
  `,
);

export default OperatorDropdown;
