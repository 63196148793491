import { Flow_Action__Input } from '~/graphql/types';
import { SetContactDetailsAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import { asFlowParameterInput } from '~/scenes/Automation/Flows/Actions/Base';

export default (
  action: SetContactDetailsAction,
  treeNode: ActionTreeNode,
  defaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, field } = action;
  const { parentId } = treeNode;
  const { accountId, flowBlueprintId } = defaults;

  if (parentId == null)
    throw new Error(`Parentless SetContactDetailsAction found! ID: ${id}`);

  const fieldInput = {};

  fieldInput[field.name] = asFlowParameterInput(field);

  return {
    Contact: {
      Details: {
        id,
        accountId,
        flowBlueprintId,
        parentId,
        field: fieldInput,
      },
    },
  };
};
