import React, { Component } from 'react';
import styled from 'styled-components';

import { ModalProps } from './TaskModalComponent';
import { DescriptionTabDetailsProps } from '../../types';

import { Alerts } from '~/components';
import Modal from '~/components/Modals/Modal';
import TaskModalComponent from './TaskModalComponent';

const text = {
  title: 'Let op: openstaande wijzigingen',
  message:
    'Door weg te navigeren van de pagina gaan de niet opgeslagen gegevens verloren.',
  buttonConfirmTitle: 'Wijzigingen vergeten',
};

type State = {
  canClose: boolean;
  showConfirm: boolean;
  updatedTaskData: DescriptionTabDetailsProps | null | undefined;
};

class TaskModal extends Component<ModalProps, State> {
  state: State = {
    canClose: true,
    showConfirm: false,
    updatedTaskData: null,
  };

  handleToggleClose = (canClose: boolean): void => {
    this.setState(prevState => ({
      ...prevState,
      canClose,
    }));
  };

  handleClose = (priorityClose?: boolean) => {
    const { canClose } = this.state;
    const { onClose } = this.props;

    if (canClose || priorityClose) {
      onClose();
    }

    if (typeof priorityClose === 'boolean') {
      this.setState({
        showConfirm: false,
      });
    } else {
      this.setState({
        showConfirm: !canClose,
      });
    }
  };

  render() {
    const {
      onClose,
      initialTaskDetails,
      account,
      selectedInFilterOfficeId,
      selectedInFilterUserId,
    } = this.props;
    const { showConfirm } = this.state;

    return (
      <Modal onClose={() => this.handleClose()}>
        {(modalContainerRef, modalWidth) => (
          <Container>
            {showConfirm && (
              <Alerts.ConfirmModal
                dataTestid="close-modal-confirm-dialog"
                title={text.title}
                message={text.message}
                handleAction={this.handleClose}
                buttonConfirmTitle={text.buttonConfirmTitle}
              />
            )}
            <TaskModalComponent
              selectedInFilterOfficeId={selectedInFilterOfficeId}
              selectedInFilterUserId={selectedInFilterUserId}
              account={account}
              onClose={onClose}
              initialTaskDetails={initialTaskDetails}
              handleToggleClose={this.handleToggleClose}
              modalContainerRef={modalContainerRef}
              modalWidth={modalWidth}
            />
          </Container>
        )}
      </Modal>
    );
  }
}

const Container = styled.div<{}>`
  height: 100%;
`;

export default TaskModal;
