import React from 'react';
import Loadable, { OptionsWithoutRender } from 'react-loadable';
import { Facebook } from 'react-content-loader';
import { Optional } from 'utility-types';
import TestId from './index.testid';

export type Props<P> = Optional<OptionsWithoutRender<P>, 'loading'> & {};

/**
 * Lazy loads the given component.
 *
 * If you override the loading container, make sure to add the testId.
 *
 * data-testid={TestId.LoadableContainer}
 *
 * We use this within our cypress tests to wait until the page is fully loaded.
 *
 * If you want to further adjust the loading animations to your needs it might
 * make more sense to add some default ones here which can be referenced.
 */
const LoadableContainer = <P,>(props: Props<P>) =>
  Loadable({
    loading: () => <Facebook data-testid={TestId.LoadableContainer} />,
    ...props,
  });

export default LoadableContainer;
