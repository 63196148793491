import React, { useContext } from 'react';

import { TableCell } from '~/components/DataTables/types.flow';

import {
  useDeleteFlowMutation,
  useDeleteFlowV2Mutation,
  useUpdateFlowBlueprintDetailsMutation,
  useUpdateFlowDetailsMutation,
} from '~/graphql/types';
import ActionColumnCell, {
  ActionListOption,
} from '~/components/DataTables/components/ActionColumnCell';
import AccountContext from '~/contexts/AccountContext';
import useConfirmModal from '~/components/Alerts/useConfirmModal';
import { navigate } from 'gatsby';
import TEST_ID from './FlowActionColumnCell.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import Catalog from '~/Catalog';
import getFlowCreatedDate from '~/scenes/Automation/v2/util/getFlowCreatedDate';
import { useSetRecoilState } from 'recoil';
import flowSettings from '../../v2/state/flowSettings';

const text = {
  deactivateLabel: 'Deactiveer',
  activateLabel: 'Activeer',
  copyLabel: 'Kopieer flow',
  deleteLabel: 'Verwijderen',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van de flow. Probeer het nog eens.',
  confirmModalTitle: 'Waarschuwing',
  confirmModalMessage:
    'Contacten die zich in deze flow bevinden zullen uit de flow worden verwijderd.',
  confirmModalConfirmButtonText: 'Flow uitzetten',
  confirmModalCancelButtonText: 'Annuleren',
  confirmDeleteTitle: 'Flow verwijderen?',
  confirmDeleteButton: 'Verwijderen',
  oneFlowInstanceExists:
    'Let op; er zit nog 1 contact in de flow. Deze wordt uit de flow verwijderd.',
  moreThanOneFlowInstanceExists: runningFlowInstances =>
    `Let op; er zitten nog ${runningFlowInstances} contacten in de flow. Deze worden uit de flow verwijderd.`,
};
const confirmDisableModalText = {
  title: text.confirmModalTitle,
  message: text.confirmModalMessage,
  buttonConfirmTitle: text.confirmModalConfirmButtonText,
  buttonCancelTitle: text.confirmModalCancelButtonText,
};
const confirmDeleteModalText = {
  title: text.confirmDeleteTitle,
  buttonConfirmTitle: text.confirmDeleteButton,
  buttonCancelTitle: text.confirmModalCancelButtonText,
};

type Props = {
  isV2?: boolean;
  flowId: string;
  cell: TableCell<any>;
  enabled: boolean;
  flowName: string;
  onSuccessfulDelete: () => void;
  onSuccessfulEnabledChange: (newStatus: boolean) => void;
  flowDescription?: string;
  runningFlowInstances: number;
};
const FlowActionColumnCell: React.FC<Props> = ({
  isV2 = false,
  flowId,
  cell,
  flowName,
  flowDescription = '',
  onSuccessfulDelete,
  onSuccessfulEnabledChange,
  enabled,
  runningFlowInstances,
}) => {
  const addToast = useAddToast();
  const { availableEmailsToSendFrom, refetchSessionHydration } =
    useContext(AccountContext);
  const account = useCurrentAccount();
  const setFlowSettings = useSetRecoilState(flowSettings);

  const [showDisableConfirmModal, setOnDisableConfirmFunction] =
    useConfirmModal(
      confirmDisableModalText,
      () => {},
      () => {},
      TEST_ID.CONFIRM_MODAL,
      false,
      'normal',
    );

  let message = '';
  if (runningFlowInstances > 0) {
    if (runningFlowInstances === 1) {
      message = text.oneFlowInstanceExists;
    } else {
      message = text.moreThanOneFlowInstanceExists(runningFlowInstances);
    }
  }

  const [showDeleteConfirmModal, setOnDeleteConfirmFunction] = useConfirmModal(
    {
      ...confirmDeleteModalText,
      message,
    },
    () => {},
    () => {},
    TEST_ID.CONFIRM_MODAL,
    false,
    'destructive',
  );

  const [deleteFlowV2, { loading: deleteLoadingV2 }] =
    useDeleteFlowV2Mutation();
  const [deleteFlow, { loading: deleteLoading }] = useDeleteFlowMutation();

  const [updateFlowDetails, { loading: updateLoading }] =
    useUpdateFlowDetailsMutation();

  const [updateFlowBlueprintDetails] = useUpdateFlowBlueprintDetailsMutation();

  const updateOption = {
    label: enabled ? text.deactivateLabel : text.activateLabel,
    key: 'update-status',
    onClick: () => {
      const saveFunction = () =>
        (isV2
          ? updateFlowBlueprintDetails({
              variables: {
                accountId: account.id,
                id: flowId,
                update: {
                  enabled: !enabled,
                },
              },
            })
          : updateFlowDetails({
              variables: {
                accountId: account.id,
                flow: {
                  id: flowId,
                  enabled: !enabled,
                },
              },
            })
        ).then(({ data, errors }) => {
          // Here we check if we need to show the error notification

          if (errors && errors.length > 0) {
            addToast([
              formatToastMessage(
                Catalog.genericUnknownErrorMessageShort,
                'danger',
              ),
            ]);
            return;
          }

          if (availableEmailsToSendFrom.length === 0) {
            void refetchSessionHydration();
          }

          if (data) {
            onSuccessfulEnabledChange(!enabled);
            return;
          }
        });

      if (enabled === true) {
        // Show warning when attempting to turn off
        setOnDisableConfirmFunction(saveFunction);
        showDisableConfirmModal();
      } else {
        void saveFunction();
      }
    },
  };

  const options: Array<ActionListOption> = [
    {
      label: text.deleteLabel,
      onClick: (_rowId: string) => {
        const mutationHandler = isV2 ? deleteFlowV2 : deleteFlow;
        const deleteFn = () =>
          mutationHandler({
            variables: {
              accountId: account.id,
              id: flowId,
            },
          }).then(({ data, errors }) => {
            if (errors && errors.length > 0) {
              addToast([formatToastMessage(text.deleteErrorMessage, 'danger')]);
              return;
            }
            if (data) {
              return onSuccessfulDelete();
            }
          });
        setOnDeleteConfirmFunction(deleteFn);
        showDeleteConfirmModal();
      },
      key: 'delete',
      type: 'DELETE',
    },
    updateOption,
    {
      label: text.copyLabel,
      onClick: (_rowId: string) => {
        if (isV2) {
          const copiedFlowName = `Kopie van ${flowName} gemaakt op ${getFlowCreatedDate()}`;

          setFlowSettings(prev => ({
            ...prev,
            flowName: copiedFlowName,
            flowDescription: flowDescription,
          }));

          return navigate(
            `/-/automation/flows/builder-v2/create/${flowId}?name=${encodeURIComponent(
              copiedFlowName,
            )}&description=${encodeURIComponent(flowDescription)}`,
          );
        }

        return navigate(
          `/-/automation/flows/builder/${flowId}?name=${encodeURIComponent(
            `Kopie van ${flowName} gemaakt op ${getFlowCreatedDate()}`,
          )}`,
        );
      },
      key: 'copy',
    },
  ];

  return (
    <ActionColumnCell
      key={'contact-action-column-cell'}
      loading={deleteLoadingV2 || updateLoading || deleteLoading}
      options={options.reverse()}
      cell={cell}
    />
  );
};

export default FlowActionColumnCell;
