import { uniqBy } from 'ramda';
import { useRecoilValue } from 'recoil';
import useBuilderContext from '~/scenes/Automation/v2/components/Builder/hooks/useBuilderContext';
import {
  DirectoryMap,
  generateDirectoryMap,
} from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getDirectory';
import {
  generateInstanceMap,
  InstanceMap,
} from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getInstance';
import { SubjectMap } from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import { actionById } from '~/scenes/Automation/v2/state';
import { instancesByActionId } from '~/scenes/Automation/v2/state/flowInstance';
import { Flow___ConditionPartial } from '../../../UpdateAction/components/ConditionEditor';
import { ConditionMap } from '../../../UpdateAction/components/Selector/utils/getConditions';
import { PrimitiveInputMap } from '../../../UpdateAction/components/Selector/utils/getPrimitiveInput';
import getParentInstances from './utils/getParentInstances';

export type RelativeMaps = {
  instanceMap: InstanceMap;
  subjectMap: SubjectMap;
  directoryMap: DirectoryMap;
  primitiveInputMap: PrimitiveInputMap;
  conditionMap: ConditionMap;
};

type UseRelativeMapsArgs = {
  /** The action until which the instances need to be collected */
  actionId: string;

  /**
   * In the case of an action containing conditions, we add the parent conditions in the case of a "condition on self"
   *
   * Trigger:
   *    New Valuationreport (parentCondition)
   *  Condition
   *      This Valuationreport (pointer to the "New Valuationreport / parentCondition" above, the condition points to "itself" or its trigger) has a value over 100k
   *
   * Must be passed without addAllParentsAsInstances.
   */
  parentCondition?: Flow___ConditionPartial | null;

  /**
   * Adds the parent conditions that are being pointed by their own
   * children to the instanceMap. Used mainly for validation purposes.
   * If true, it will add all parent conditions including 'parentCondition'
   */
  addAllParentsAsInstances?: boolean;
};

const useRelativeMaps = ({
  actionId,
  parentCondition,
  addAllParentsAsInstances = false,
}: UseRelativeMapsArgs): RelativeMaps => {
  const { opts, instanceMap, subjects, instances, primitiveInputMap } =
    useBuilderContext();
  const { subjectMap, conditionMap } = opts;

  const action = useRecoilValue(actionById(actionId));

  const extraInstances = getParentInstances({
    parentCondition,
    allParentInstances: addAllParentsAsInstances,
    action,
    subjectMap,
  });

  const actionInstances =
    useRecoilValue(
      instancesByActionId({
        actionId,
        subjectMap,
      }),
    ) ?? [];

  const relativeInstances = uniqBy(
    item => item.key.join(';;'),
    actionInstances.concat(extraInstances),
  );

  const relativeInstanceMap = relativeInstances
    ? generateInstanceMap(relativeInstances)
    : {};

  const completeInstanceMap: InstanceMap = {
    ...instanceMap,
    ...relativeInstanceMap,
  };

  const relativeDirectoryMap: DirectoryMap = generateDirectoryMap(
    subjects,
    [...relativeInstances, ...instances],
    subjectMap,
  );

  return {
    instanceMap: completeInstanceMap,
    directoryMap: relativeDirectoryMap,
    subjectMap,
    primitiveInputMap,
    conditionMap,
  };
};

export default useRelativeMaps;
