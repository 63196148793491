import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '~/components/Link';

import { ImgDefinition } from '~/graphql/types.client.flow';

import { Card } from '~/components';
import { H3 } from '~/components/Typography';
import Catalog from '~/Catalog';
import { Address } from '~/graphql/types.client';
import Icon from '~/components/Icon';
import Office from '~/components/Illustration/components/Office';
import Button from '~/components/Button';
import useUserRights from '~/hooks/useUserRights';
import useViewingModeProps from '~/hooks/useViewingModeProps';

const text = {
  noName: ' is niet ingevuld',
  officeDetailButton: 'Wijzig gegevens',
  officeUserButton: 'Beheer gebruikers',
  userInOffice: ' gebruikers actief in deze vestiging',
};
type Props = {
  name: string;
  id: string;
  img: ImgDefinition | null | undefined;
  address: Address | null;
  users: Array<any>;
  phone: string | null | undefined;
  email: string | null | undefined;
};
export default ({ name, id, img, address, users, phone, email }: Props) => {
  const viewingModeProps = useViewingModeProps();
  const { addition, city, houseNumber, postcode, street } = address || {};
  const { view: officeAdminMayView } = useUserRights({
    category: 'Offices',
    entity: { type: 'office', id },
  });
  const { view: accountAdminMayView } = useUserRights({
    category: 'Offices',
  });

  const mayView = officeAdminMayView || accountAdminMayView;

  const image = img ? (
    <OfficeImg data-testid="office-img" src={img.medium} />
  ) : (
    <NoOfficeImage>
      <OfficeIllustration />
    </NoOfficeImage>
  );
  const officeEmail = email ? (
    <div>
      <b>{email}</b>
    </div>
  ) : (
    <NoData>
      {Catalog.emailLabel} {text.noName}
    </NoData>
  );
  const officePhone = phone ? (
    <div>
      <b>{phone}</b>
    </div>
  ) : (
    <NoData>
      {Catalog.phoneLabel} {text.noName}
    </NoData>
  );

  const button = (
    <StyledButton
      size="medium"
      margin={['xxs', null]}
      disabled={!mayView}
      data-testid="office-detail-link"
      label={text.officeDetailButton}
    />
  );
  const officeDetailsButton = mayView ? (
    <Link to={`/-/settings/offices/${id}`}>{button}</Link>
  ) : (
    button
  );

  return (
    <CardElement
      key={`${id}_office`}
      data-testid="office-list-item"
      data-objectid={id}
    >
      {image}
      <Title data-testid="office-name">{name}</Title>
      <AddressContainer data-testid="office-address">
        <div>
          {street} {houseNumber}
          {addition}
        </div>
        <div>
          {postcode} {city}
        </div>
      </AddressContainer>
      <div data-testid="office-phone" {...viewingModeProps}>
        {officePhone}
      </div>
      <div data-testid="office-email" {...viewingModeProps}>
        {officeEmail}
      </div>
      <UsersBlock>
        <UserIcon name="user" />
        <UserNumber data-testid="user-number">
          {users.length} {text.userInOffice}
        </UserNumber>
      </UsersBlock>
      {officeDetailsButton}
      <Link to={`/-/settings/offices/${id}/users`}>
        <StyledButton
          margin={['xxs', null]}
          size="medium"
          ghost
          data-testid="office-user-link"
          label={text.officeUserButton}
        />
      </Link>
    </CardElement>
  );
};

const Title = styled(H3)<{}>`
  font-weight: bold;
  text-align: center;
`;

const OfficeImg = styled.img<{}>`
  border-radius: 100%;
  height: 160px;
  width: 160px;
`;

const NoOfficeImage = styled.div<{}>`
  position: relative;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 160px;
  ${({ theme }) => css`
    background: ${theme.color('white', 'dark')};
  `};
`;

const OfficeIllustration = styled(Office)<{}>`
  position: absolute;
  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
  `};

  > svg {
    height: 80px;
    width: 80px;
  }
`;

const AddressContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    ${({ theme }) => css`
      margin-bottom: ${theme.space('xxs')};
    `};
  }
`;

const UserNumber = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('text')};
  `,
);

const NoData = styled.span<{}>`
  font-style: normal;
  opacity: 0.3;
  ${({ theme }) => css`
    color: ${theme.color('grey')};
  `};
`;

const StyledButton = styled(Button)<{}>`
  width: 180px;
`;

const UsersBlock = styled.div<{}>`
  display: flex;
  align-items: center;
`;

const CardElement = styled(Card)<{}>`
  box-shadow: none;
  transition: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  ${({ theme }) => css`
    margin: ${theme.space('l')};
    box-shadow: ${theme.getTokens().boxShadow.base};
  `};
  & > div {
    align-items: center;
    ${({ theme }) => css`
      margin: ${theme.space('xxs')} 0;
    `};
  }
  & > ${UsersBlock} {
    ${({ theme }) => css`
      margin-bottom: ${theme.space('l')};
    `};
  }

  &:hover {
    ${({ theme }) => css`
      box-shadow: ${theme.getTokens().boxShadow.base};
    `};
  }
`;

const UserIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('success')};
    margin-right: ${theme.space('xxs')};
    margin-bottom: ${theme.space('xxxs')};
  `};
`;
