import gql from 'graphql-tag';

export default gql`
  mutation UpdateOffice(
    $accountId: ID!
    $officeId: ID!
    $update: Office_Update!
  ) {
    updateOffice(accountId: $accountId, officeId: $officeId, update: $update) {
      id
      name
      phone
      email
      address {
        street
        houseNumber
        addition
        postcode
        city
      }
    }
  }
`;
