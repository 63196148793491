import { CreateTaskAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { TaskType } from '~/graphql/types';
import { FLOW_ACTION_TYPE } from '../constants';
import { emptyTemplateStringParameterValue } from '../Base';
import { emptyOfficePrimitiveParameterValue } from '../Base/FlowParameter/ParameterValue/Office';
import { emptyUserPrimitiveParameterValue } from '../Base/FlowParameter/ParameterValue/User';

const defaultNewCreateTaskActionProps = (
  baseActionProps: BaseActionProps,
): CreateTaskAction => ({
  ...baseActionProps,
  type: FLOW_ACTION_TYPE.CREATE_TASK,
  taskInput: {
    assignToContactOwner: true,
    taskType: TaskType.ToDo,
    description: emptyTemplateStringParameterValue(),
    title: emptyTemplateStringParameterValue(),
    office: emptyOfficePrimitiveParameterValue(),
    user: emptyUserPrimitiveParameterValue(),
  },
});

export default defaultNewCreateTaskActionProps;
