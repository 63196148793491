import { FlowParameterOfficeFieldsFragment } from '~/graphql/types';
import { OfficeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyOfficePrimitiveParameterValue from './emptyOfficePrimitiveParameterValue';

export default (
  officeParameter: FlowParameterOfficeFieldsFragment | null | undefined,
): OfficeParameterValue => {
  if (officeParameter == null) {
    return emptyOfficePrimitiveParameterValue(null);
  }
  switch (officeParameter.__typename) {
    case PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE:
      return {
        type: PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE,
        value: officeParameter.officeId ? officeParameter.officeId : null,
      };
    case PARAMETER_VALUE_TYPE.OFFICE_POINTER:
      return {
        type: PARAMETER_VALUE_TYPE.OFFICE_POINTER,
        variable: {
          name: officeParameter.variable,
          field: officeParameter.field
            ? {
                name: officeParameter.field,
              }
            : null,
        },
      };
    default:
      throw new Error(
        `Should not occur: Unknown __typename (${officeParameter.__typename}) for FlowofficeParameter`,
      );
  }
};
