import React, { ReactElement, useState } from 'react';
import Catalog from '~/Catalog';
import ErrorModal from '~/components/Alerts/ErrorModal';
import DetailsActionList from '~/components/DetailsPage/DetailsActionList';
import { ListItemForDeletion } from '~/components/Modals/components/ListItemCard';
import DeleteUserFromAccountModal from '~/components/Modals/DeleteUserFromAccountModal';
import UserProfileHeader from '~/components/ProfileHeader/UserProfileHeader';
import withAddToastMessage from '~/components/ToastsContainer/withAddToastMessage';
import { User } from '~/hooks/useUserLookup';
import formatToastMessage, {
  FormattedToastMessage,
} from '~/util/formatToastMessage';
import { ActionButtons, Header } from '../../Profile.style';
import ChangePasswordSidebar from '../ChangePasswordSidebar';

type Props = {
  updateMyName: (name: string) => void;
  isLastAccountAdmin: boolean;
  user: any;
  me: User;
  refetchUser: () => Promise<any>;
  addToast: (value: any) => FormattedToastMessage;
};

const text = {
  imageUploadError: Catalog.imageUploadErrorMessage,
  submitBtn: 'Wijzig wachtwoord',
  deleteLabel: 'Verwijder profiel',
  errorModalTitle: 'Verwijderen niet mogelijk',
  errorModalMessage:
    'Dit is de enige administrator in dit account. Voeg eerst een nieuwe account administrator toe om deze gebruiker te verwijderen.',
};

const ProfileHeader: React.FC<Props> = ({
  user,
  refetchUser,
  me,
  updateMyName,
  isLastAccountAdmin,
  addToast,
}) => {
  const [isSidebarActive, setIsSidebarActive] = useState<boolean>(false);
  const [showDeleteUserFromAccountModal, setShowDeleteUserFromAccountModal] =
    useState<Array<ListItemForDeletion> | null>(null);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const toggleSidebar = (isSidebarActive: boolean) =>
    setIsSidebarActive(isSidebarActive);

  const showImageUpdateErrorMessage = () => {
    addToast(formatToastMessage(text.imageUploadError, 'danger'));
  };

  const handleDeleteUserFromAccountModalAction = (
    officesList: Array<ListItemForDeletion> | null,
  ) => {
    setShowDeleteUserFromAccountModal(officesList);
  };

  let errorModal: ReactElement | null = null;
  if (showErrorModal) {
    errorModal = (
      <ErrorModal
        title={text.errorModalTitle}
        message={text.errorModalMessage}
        onClose={() => setShowErrorModal(false)}
      />
    );
  }

  return (
    <Header>
      <UserProfileHeader
        edit
        user={user}
        refetchUser={() => {
          void refetchUser();
        }}
        onImageUploadError={showImageUpdateErrorMessage}
        updateMyName={updateMyName}
      />

      <ActionButtons>
        <DetailsActionList
          options={[
            {
              label: text.submitBtn,
              key: 'change-password',
              type: null,
              onClick: () => {
                toggleSidebar(!isSidebarActive);
              },
            },
            {
              label: text.deleteLabel,
              onClick: () => {
                if (isLastAccountAdmin) {
                  setShowErrorModal(true);
                } else {
                  handleDeleteUserFromAccountModalAction(
                    createOfficesForModal(user),
                  );
                }
              },
              type: 'DELETE',
              key: 'delete-user',
            },
          ]}
        />
      </ActionButtons>

      {showDeleteUserFromAccountModal && (
        <DeleteUserFromAccountModal
          list={showDeleteUserFromAccountModal}
          onCancel={() => handleDeleteUserFromAccountModalAction(null)}
          onClose={() => handleDeleteUserFromAccountModalAction(null)}
          userId={me.id}
        />
      )}

      <ChangePasswordSidebar
        user={me}
        toggleSidebar={toggleSidebar}
        active={isSidebarActive}
      />
      {errorModal}
    </Header>
  );
};

export const createOfficesForModal = (
  user: any,
): Array<ListItemForDeletion> => {
  if (user == null) {
    return [];
  }
  return user.Offices.map(office => {
    const { id, name, img } = office;

    return {
      id,
      name,
      img,
    };
  });
};

export default withAddToastMessage(ProfileHeader);
