import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const HouseTinder: React.FC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M43.2 53.1654V45.3654H39.7V49.5654L35.5 45.3654C35.1 44.9654 34.7 44.9654 34.4 45.3654L22.5 57.0654H26V67.9654H32.8H37H43.7V57.0654H47.1L43.2 53.1654Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.5 30.7652L40.9 29.6652C39.9 29.2652 39.5 28.1652 39.9 27.1652L42.5 21.0652C42.9 20.0652 44 19.6652 45 20.0652L47.6 21.1652M54.4 22.0652L55.9 18.5652C56.5 17.1652 55.8 15.4652 54.4 14.8652L47.6 21.1652L43.6 30.6652L53.4 34.7652C54.3 35.1652 55.3 34.8652 55.8 34.0652L60.3 26.7652C60.8 25.8652 60.6 24.7652 59.7 24.1652C59.6 24.0652 59.5 24.0652 59.4 24.0652L54.4 22.0652Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.9468 16.9546L42.669 5L31.5322 31.3435L59.8099 43.298L70.9468 16.9546Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4001 15.5654L24.1001 14.7654C25.3001 14.3654 26.5001 14.9654 27.0001 16.0654L29.1001 23.0654C29.3001 24.2654 28.6001 25.4654 27.4001 25.7654L24.7001 26.4654M18.4001 30.5654L19.6001 34.5654C20.1001 36.1654 21.9001 37.1654 23.5001 36.6654L24.7001 26.4654L21.3001 15.5654L10.1001 18.9654C9.10006 19.2654 8.50006 20.2654 8.60006 21.2654L10.0001 30.6654C10.2001 31.7654 11.2001 32.5654 12.4001 32.4654C12.5001 32.4654 12.6001 32.4654 12.7001 32.3654L18.4001 30.5654Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.3 34.3653L38.7 35.8653L9.4 44.8653L1 17.4653L30.3 8.46533L34.9 23.2653"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100%" height="100%" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default HouseTinder;
