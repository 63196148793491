import cleanedFilename from '~/util/cleanedFilename';

export default {
  CANCEL_BUTTON: `${cleanedFilename(__filename)}-cancel-button`,
  SAVE_BUTTON: `${cleanedFilename(__filename)}-save-button`,
  SELECT_STEP_DROPDOWN: `${cleanedFilename(__filename)}-select-step-dropdown`,
  SELECT_OBJECT_TYPE_DROPDOWN: `${cleanedFilename(
    __filename,
  )}-select-object-type-dropdown`,
  FIELD_LIST: `${cleanedFilename(__filename)}-field-list`,
};
