import getCommonWizardSteps from '~/components/WizardSteps/utils/getCommonWizardSteps';
import getWizardFlow from '~/components/WizardSteps/utils/getWizardFlow';
import { WizardFlow } from '~/graphql/types.client';
import { ExtendedAppConfig } from '~/hooks/useApps';

/**
 * Creates enablement flow for app
 * @param {ExtendedAppConfig} app
 * keywords: enablementFlow, wizard
 */

const getEnablementFlow = (app: ExtendedAppConfig): WizardFlow | undefined => {
  const { __typename } = app;
  const wizardFlow = getWizardFlow(app);

  const enablementFlow: WizardFlow | undefined = wizardFlow
    ? {
        ...wizardFlow,
        steps: [
          ...wizardFlow.steps,
          ...getCommonWizardSteps({ typename: __typename }),
        ],
      }
    : undefined;

  return enablementFlow;
};

export default getEnablementFlow;
