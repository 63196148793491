import { TypedField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';

export const FLOW_CONDITION_VALUATIONREQUEST_DETAILS_FIELD = {
  name: {
    name: 'name',
    label: 'Naam',
    type: FIELD_TYPE.STRING,
  },
  phone: {
    name: 'phone',
    label: 'Telefoonnummer',
    type: FIELD_TYPE.STRING,
  },
  email: {
    name: 'email',
    label: 'E-mailadres',
    type: FIELD_TYPE.STRING,
  },
  why: {
    name: 'why',
    label: 'Reden aanvraag',
    type: FIELD_TYPE.STRING,
    options: ['Verkoop', 'Koop', 'Verkoop & Koop', 'Ik ben nieuwsgierig'],
  },
  when: {
    name: 'when',
    label: 'Verhuisintentie',
    type: FIELD_TYPE.STRING,
    options: ['Zo snel mogelijk', '3 maanden', '6 maanden', 'Weet ik nog niet'],
  },
  postcode: {
    name: 'postcode',
    label: 'Postcode',
    type: FIELD_TYPE.STRING,
  },
  city: {
    name: 'city',
    label: 'Woonplaats',
    type: FIELD_TYPE.STRING,
  },
  livingArea: {
    name: 'livingArea',
    label: 'Woonoppervlakte',
    type: FIELD_TYPE.NUMBER,
  },
} as const;

export const FLOW_CONDITION_VALUATIONREQUEST_DETAILS_FIELDS: Array<TypedField> =
  Object.keys(FLOW_CONDITION_VALUATIONREQUEST_DETAILS_FIELD).map(
    key => FLOW_CONDITION_VALUATIONREQUEST_DETAILS_FIELD[key],
  );

export const DEFAULT_FLOW_CONDITION_VALUATIONREQUEST_DETAILS_FIELD =
  FLOW_CONDITION_VALUATIONREQUEST_DETAILS_FIELD.name;
