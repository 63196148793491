import { isNil, propEq } from 'ramda';
import { Account, GetMyAccountsQueryResult } from '~/graphql/types';
import { SELECTED_ACCOUNT_STORAGE_KEY } from '../localStorageKeys';

const getCurrentAccount = (
  data: GetMyAccountsQueryResult['data'],
): Pick<Account, 'id' | 'name' | 'status'> | null => {
  if (!data || data.getMyAccounts.length === 0) return null;
  const accounts = data.getMyAccounts;
  const selectedAccountId = global.localStorage.getItem(
    SELECTED_ACCOUNT_STORAGE_KEY,
  );

  if (accounts.length <= 1 || isNil(selectedAccountId)) return accounts[0];
  return accounts.find(propEq('id', selectedAccountId)) || accounts[0];
};

export default getCurrentAccount;
