import React from 'react';
import { ErrorBoundary } from '@sentry/gatsby';
import AppErrorScreen from './components/AppErrorScreen';
import type { FallbackRender } from '@sentry/react/dist/errorboundary';

const withErrorBoundary = <ComponentProps extends object>(
  WrappedComponent: React.ComponentType<ComponentProps>,
  Fallback?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | FallbackRender
    | undefined,
) => {
  const ComponentWithBoundary: React.FC<ComponentProps> = props => (
    <ErrorBoundary fallback={Fallback ? Fallback : <AppErrorScreen />}>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );

  return ComponentWithBoundary;
};

export default withErrorBoundary;
