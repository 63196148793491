import React, { useContext, useEffect, useRef, useState } from 'react';
import useOutsideClick from '~/hooks/useOutsideClick';
import Container from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/components/SelectorContainer/components/Container';
import { FormContext } from '~/components/ModalsV2/FormContainer';
import { DEFAULT_MAX_HEIGHT } from '~/components/Selector';
import getPointerOffset from '../SelectorContainer/components/utils/getPointerOffset';

export type Props = {
  dataTestId?: string;

  /** Button or container clicked to open the Selector */
  parentRef: HTMLElement | null;

  /** A container's ref that can limit the positioning of Selector */
  rowContainerRef?: HTMLElement | null;

  /** Function to close the selector */
  onClose: () => void;

  /** Children that receive the positioning props */
  children?: React.ReactNode;
};

// Selector's values
const POINTER_HEIGHT = 20;
const HEADER_HEIGHT = 40;

const PositionWrapper: React.FC<Props> = ({
  parentRef,
  rowContainerRef,
  children,
  dataTestId,
  onClose,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideClick(containerRef, () => onClose());

  const selectorRef = useRef<HTMLDivElement>(null);

  const { formContainerRef } = useContext(FormContext);

  const [pointerOffset, setPointerOffset] = useState(23);

  useEffect(() => {
    if (!parentRef) return;
    if (!selectorRef?.current) return;

    setPointerOffset(
      getPointerOffset({
        childElement: selectorRef.current,
        parentElement: parentRef,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef, selectorRef.current]);

  const [pointerLocation, setPointerLocation] = useState<'top' | 'bottom'>(
    'top',
  );

  const [isPositionUpdated, setIsPositionUpdated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  const [moveSelector, setMoveSelector] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!parentRef || !formContainerRef) return;

    const buttonRect = parentRef?.getBoundingClientRect();
    const formRect = formContainerRef?.getBoundingClientRect();
    const rowContainerRect = rowContainerRef?.getBoundingClientRect();

    // Set opening direction of Selector
    const offsetToFormTop = buttonRect.top - formRect.top - POINTER_HEIGHT;
    const offsetToFormBottom =
      formRect.bottom - buttonRect.bottom - POINTER_HEIGHT;
    const opensFromBottom = offsetToFormTop < offsetToFormBottom;

    setPointerLocation(opensFromBottom ? 'top' : 'bottom');

    // Set Selector max height
    const possibleSpace = opensFromBottom
      ? offsetToFormBottom
      : offsetToFormTop;

    const notEnoughSpace = DEFAULT_MAX_HEIGHT + POINTER_HEIGHT > possibleSpace;
    if (notEnoughSpace) {
      setMaxHeight(possibleSpace - HEADER_HEIGHT - POINTER_HEIGHT);
    }

    // Set Selector top or bottom additionally
    if (rowContainerRect) {
      const changeTop =
        buttonRect.bottom - (rowContainerRect?.top || 0) + POINTER_HEIGHT;
      const changeBottom =
        (rowContainerRect?.bottom || 0) - buttonRect.top + POINTER_HEIGHT;

      setMoveSelector(opensFromBottom ? changeTop : changeBottom);
    }

    setIsPositionUpdated(true);
  }, [parentRef, formContainerRef, rowContainerRef]);

  useEffect(() => {
    /**
     * We need to set the isInitialized state after setting the moveSelector value above
     * so that when initial "top" value of the Selector gets upated it does not become jumpy.
     * e.g. when the Selector overflows at the bottom
     */
    setIsInitialized(isPositionUpdated);
  }, [isPositionUpdated]);

  if (!parentRef) {
    return <></>;
  }

  return (
    <Container
      ref={containerRef}
      data-testid={dataTestId}
      $openingDirection={pointerLocation}
      $moveSelector={moveSelector}
    >
      {React.Children.map(
        children,
        child =>
          React.isValidElement(child) &&
          React.cloneElement(child, {
            ...child.props,
            selectorRef,
            pointerLocation,
            pointerOffset,
            maxHeightInPx: maxHeight,
            style: { visibility: isInitialized ? 'visible' : 'hidden' },
          }),
      )}
    </Container>
  );
};

export default PositionWrapper;
