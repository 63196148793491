import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Icon from '~/components/Icon';
import InputWrapper from './components/InputWrapper';
import ValueReportComponentCard from './components/ValueReportComponentCard';
import { AnalyticsProps } from './ValueReport.type';

const text = {
  title: 'Analytics',
  google: 'Google analytics',
  facebook: 'Facebook pixel',
};

type Props = {
  /** Data field name for correct onChange handling */
  name: string;

  /** data contains: { googleAnalytics, facebookPixel } */
  data: AnalyticsProps;

  /** Callback function which call data updating in container state */
  onChange: (arg0: $Object) => void;
} & RouteComponentProps;

const AnalyticsPage = (props: Props) => {
  const onChange = (value: { [key: string]: string }) =>
    props.onChange({
      [props.name]: { ...props.data, ...value },
    });

  const { googleAnalytics, facebookPixel } = props.data;
  return (
    <ValueReportComponentCard
      datatestId={'tab-contact-analytics'}
      title={text.title}
      cardIcon={<Icon name="bar-chart" />}
    >
      <InputWrapper
        text={text.google}
        name={'googleAnalytics'}
        value={googleAnalytics || ''}
        placeholder="UA-000000-1"
        onChange={onChange}
      />
      <InputWrapper
        text={text.facebook}
        name={'facebookPixel'}
        value={facebookPixel || ''}
        placeholder="1234567890123456"
        onChange={onChange}
      />
    </ValueReportComponentCard>
  );
};

export default AnalyticsPage;
