/**
 *
 * Provides fake authentication over Cognito
 */

import Validation from '~/util/Validation';
import {
  INVALID_PARAMETER,
  INVALID_PARAMETER_FORGOT_PASS,
  USER_DOES_NOT_EXIST,
  INCORRECT_USERNAME_OR_PASSWORD,
  USER_UNCONFIRMED,
  USERNAME_EXISTS,
  REGISTRATION_OK,
  LOGIN_OK,
  VERIFICATION_NON_EXISTENT_USER,
  VERIFICATION_INVALID_TOKEN,
  VALIDATION_ALREADY_VALIDATED,
  NEW_PASSWORD_REQUIRED,
  COGNITO_IDENTITY_CREDENTIALS,
  USER_NOT_ACTIVATED,
} from './CognitoResponse';

const DEFAULT_AUTH_DELAY = 50;

export const delayedResolve = (
  value?: any,
  delay: number = DEFAULT_AUTH_DELAY,
): Promise<any> =>
  new Promise(resolve => {
    setTimeout(() => resolve(value), delay);
  });

export const delayedReject = (
  value?: any,
  delay: number = DEFAULT_AUTH_DELAY,
): Promise<any> =>
  new Promise((_, reject) => {
    setTimeout(() => reject(value), delay);
  });

type UserType = {
  email: string;
  password: string;
  token: number;
  name?: string;
  phone?: string;
};

type CognitoUser = Object | any;

export const USER: {
  [key: string]: UserType;
} = {
  UNCONFIRMED: {
    email: 'unconfirmed@dathuis.nl',
    password: 'Pass%123',
    token: 123723,
    name: 'Philipp Beau',
    phone: '3806685012345',
  },
  NON_EXISTENT: {
    email: 'non-existent-user_mock@dathuis.nl',
    password: 'Pass%123',
    token: 123623,
    name: 'Philipp Beau',
    phone: '3806685012345',
  },
  VALID: {
    email: 'rob@dathuis.nl',
    password: 'Pass%123',
    token: 125123,
    name: 'Rob van Kruijsdijk',
    phone: '3806685012345',
  },
  INVITED_USER: {
    email: 'inviteduser@dathuis.nl',
    password: 'Pass!123',
    token: 125123,
    name: 'Rob van Kruijsdijk',
    phone: '3806685012345',
  },
};

const FakeUser = {
  getSignInUserSession: () => ({
    clockDrift: 0,
    getAccessToken: () => ({
      getJwtToken: () => 'DEV_JWT_TOKEN',
      jwtToken: 'DEV_JWT_TOKEN',
      payload: {
        sub: '4e8b7741-cb9a-432f-8e92-9bfba0e4d1ad',
        aud: '4bijda6vkc8qubfke287jokv18',
        'custom:phone': '0683830330',
        email_verified: true,
        token_use: 'id',
        auth_time: 1620900861,
        iss: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_G4pGMV682',
        name: 'Philipp Beau',
        'cognito:username': '4e8b7741-cb9a-432f-8e92-9bfba0e4d1ad',
        exp: 1622798662,
        iat: 1622795062,
        email: 'philipp@dathuis.nl',
      },
    }),
  }),
  username: 'user-1',
  attributes: {
    id: 'u---user-1',
    name: 'Bruce Banner',
    email: 'angrygreenman@gmail.com',
    //Enough for the tests
    offices: [
      {
        id: 'office-1',
        name: 'Amsterdam',
      },
      {
        id: 'office-2',
        name: 'Utrecht',
      },
    ],
    users: [
      {
        id: 'u---user-1',
        name: 'Bruce Banner',
      },
      {
        id: 'u---user-2',
        name: 'Peter Parker',
      },
      {
        id: 'u---user-3',
        name: 'Matthew Murdock',
      },
    ],
  },
};

/** Once we get */
const Auth = {
  _loggedIn: !!window.sessionStorage.getItem('dhAuth'),
  signOut: () => {
    Auth._loggedIn = false;
    window.sessionStorage.setItem('dhAuth', '');

    return delayedResolve();
  },
  currentSession: (): Promise<any> => {
    if (!Auth._loggedIn) {
      return delayedReject('No Session');
    }

    return delayedResolve(FakeUser.getSignInUserSession(), 0);
  },
  currentCredentials: () => Promise.resolve(COGNITO_IDENTITY_CREDENTIALS),
  currentAuthenticatedUser: () => {
    if (!Auth._loggedIn) {
      return delayedReject('Not Authenticated');
    }

    return delayedResolve(FakeUser, 0);
  },

  signIn: (email: string, password: string): Promise<any> => {
    if (email === USER.NON_EXISTENT.email) {
      return delayedReject(USER_DOES_NOT_EXIST);
    }

    if (email === USER.UNCONFIRMED.email) {
      return delayedReject(USER_UNCONFIRMED);
    }

    if (email === USER.VALID.email) {
      if (password !== USER.VALID.password) {
        return delayedReject(INCORRECT_USERNAME_OR_PASSWORD);
      }

      Auth._loggedIn = true;
      window.sessionStorage.setItem('dhAuth', 'loggedIn');
      return delayedResolve(LOGIN_OK);
    }

    if (
      email === USER.INVITED_USER.email &&
      password === USER.INVITED_USER.password
    ) {
      return delayedResolve(NEW_PASSWORD_REQUIRED);
    }

    return delayedReject(USER_DOES_NOT_EXIST);
  },

  signUp: ({
    username,
    password,
    attributes,
  }: {
    username: string;
    password: string;
    attributes?: $Object;
  }): Promise<any> => {
    if (!Validation.Email.isValid(username)) {
      return delayedReject(INVALID_PARAMETER);
    }

    if (!Validation.Password.isValid(password)) {
      return delayedReject(INVALID_PARAMETER);
    }

    if (attributes == null || !Validation.String.isString(attributes.name))
      return delayedReject(INVALID_PARAMETER);

    if (username === 'rob@dathuis.nl') {
      return delayedReject(USERNAME_EXISTS);
    }

    return delayedResolve(REGISTRATION_OK);
  },

  confirmSignUp: (
    email: string,
    code: number,
    // options?: { forceAliasCreation?: boolean },
  ): Promise<any> => {
    if (email === USER.VALID.email) {
      return delayedReject(VALIDATION_ALREADY_VALIDATED);
    }

    if (email === USER.NON_EXISTENT.email) {
      return delayedReject(VERIFICATION_NON_EXISTENT_USER);
    }

    if (email === USER.UNCONFIRMED.email) {
      if (code != USER.UNCONFIRMED.token) {
        return delayedReject(VERIFICATION_INVALID_TOKEN);
      }

      return delayedResolve('SUCCESS');
    }

    return delayedReject(VERIFICATION_NON_EXISTENT_USER);
  },

  resendSignUp: (email: string): Promise<any> => delayedResolve(email),

  forgotPassword: (email: string): Promise<any> => {
    if (email === USER.NON_EXISTENT.email) {
      return delayedReject(USER_DOES_NOT_EXIST);
    }

    if (email === USER.UNCONFIRMED.email) {
      return delayedReject(INVALID_PARAMETER_FORGOT_PASS);
    }

    if (email === USER.INVITED_USER.email) {
      return delayedReject(USER_NOT_ACTIVATED);
    }

    return delayedResolve('SUCCESS');
  },

  forgotPasswordSubmit: (
    username: string,
    code: string,
    password: string,
  ): Promise<void> => {
    if (code !== USER.VALID.token.toString()) {
      return delayedReject(VERIFICATION_INVALID_TOKEN);
    }
    // to make it valid for eslint:
    const result = username + code + password;
    return delayedResolve(result);
  },

  completeNewPassword: (
    user: CognitoUser | any,
    password: string,
    requiredAttributes: any,
  ): Promise<CognitoUser | any> => {
    if (user && password && requiredAttributes) {
      Auth._loggedIn = true;
      return delayedResolve(LOGIN_OK);
    } else return delayedResolve(INVALID_PARAMETER);
  },

  changePassword: (
    user: CognitoUser | any,
    oldPassword: string,
    password: string,
  ): Promise<CognitoUser | any> => {
    if (user && password && USER.VALID.password === oldPassword) {
      return delayedResolve('SUCCESS');
    } else return delayedReject(INVALID_PARAMETER);
  },
};

export default Auth;
