/**
 * Converts original html attributes of the element back into string, adds the updated values
 * @param {string} attrsToPassThrough - The initial attributes object that we get from the html elements
 * @param {Object} updatedAttributes - An object of properties to update.
 * It is important that the css attributes are passed with the css syntax.
 * So it's not 'backgroundColor' but 'background-color' e.g. { style: { ['background-color']: '#fff' } }
 *
 * keywords: Html attributes update
 */

import { isNil } from 'lodash';
import { isEmpty, mergeDeepRight, mergeRight } from 'ramda';
import convertAttrsObjToAttrsString from '~/components/PluginsEditor/utils/serialize/convertAttrsObjToAttrsString';

type StyleObject = { [key: string]: string };

type UpdatedAttributes = {
  style: StyleObject;
  width?: any;
  src?: string | null;
  href?: string;
  target?: string;
};

const updateAttrsAndConvertToString = (
  { style, ...updatedAttrs }: UpdatedAttributes,
  prevAttrs?: { [key: string]: string },
): string => {
  const updatedObj = { ...(prevAttrs ? prevAttrs : {}), style: '' };

  // if style attrs already exists convert value into obj and update corresponding values
  if (prevAttrs?.style) {
    const styleObj = convertStyleStrToObj(prevAttrs?.style);
    const updatedStyleObj = mergeRight(styleObj, style);
    // Convert it back to string
    updatedObj.style = convertStyleObjToStyleStr(updatedStyleObj);
  } else {
    // Otherwise create style attribute with passed values
    const updatedStyle = convertStyleObjToStyleStr(style);
    updatedObj.style = updatedStyle;
  }

  const merged = mergeDeepRight(updatedObj, updatedAttrs);

  return convertAttrsObjToAttrsString(merged);
};

/**
 * Creates a style object with keys as css string: { 'background-color': 'red'}
 * so that we can manipulate the values better
 * */
export const convertStyleStrToObj = (str: string): StyleObject => {
  if (!str) return {};

  const arr = str.split(';');
  const obj = {};

  for (const attr of arr) {
    if (attr) {
      const [property, value] = attr.split(':');
      obj[property.trim().toLowerCase()] = value.trim();
    }
  }

  return obj;
};

/**
 * Converts back to style string.
 * Takes a style object like { 'background-color': 'red'} and converts it to string:
 * 'background-color:red;'
 */
export const convertStyleObjToStyleStr = (obj: StyleObject): string => {
  let str = '';
  if (isEmpty(obj) || isNil(obj)) return '';

  for (const key in obj) {
    str += `${key}: ${obj[key]}; `;
  }

  // Remove extra space at the end
  return str.trim();
};

export default updateAttrsAndConvertToString;
