import cleanedFilename from '~/util/cleanedFilename';

export default {
  DESCRIPTION: `${cleanedFilename(__filename)}-description`,
  ERROR_TOKEN_DROPDOWN_INPUT_GROUP: `${cleanedFilename(
    __filename,
  )}-error-token-dropdown-input-group`,
  ERROR_TOKEN_DROPDOWN: `${cleanedFilename(__filename)}-error-token-dropdown`,
  TOKEN_DROPDOWN_INPUT_GROUP: `${cleanedFilename(
    __filename,
  )}-token-dropdown-input-group`,
  TOKEN_DROPDOWN: `${cleanedFilename(__filename)}-token-dropdown`,
};
