/**
 * Our uploading of the images are not triggered when you copy paste a text
 */

import { UploadImageReturnType } from '~/components/HTMLEditor/util/uploadImageToStorage';

import { IMG_REGEX, INLINE_IMAGE_CONTENT_TYPE_ATTR } from '../constants';
import { getAttributeValue } from './variableHTML';
import removeInlineAttachmentInformation from './removeInlineAttachmentInformation';
import cleanedFilename from '~/util/cleanedFilename';
import uploadImageToStorage from './uploadImageToStorage';
import downloadFileWithSrc from './downloadFileWithSrc';
import { reportErrorToTracker } from '~/util/assertion';

const updateInlineImageIdsForAnyImages = (
  html: string,
  getNewIdFunction: () => string,
  onUploadSucceeded: (id: string, fileDetails: UploadImageReturnType) => void,
  userId: string,
): string =>
  html.replace(IMG_REGEX, matchedValue => {
    const newId = getNewIdFunction();

    const prevInlineId = getAttributeValue('data-dhinlineid', matchedValue);
    const prevUploadId = getAttributeValue('data-uploadid', matchedValue);

    if (prevInlineId != null || prevUploadId != null) {
      const prevSrc = getAttributeValue('src', matchedValue);
      const contentType = getAttributeValue(
        INLINE_IMAGE_CONTENT_TYPE_ATTR,
        matchedValue,
      );

      if (contentType == null) {
        reportErrorToTracker(
          new Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | contentType == null in (${matchedValue})`,
          ),
        );
        return matchedValue;
      }

      if (prevSrc == null) {
        reportErrorToTracker(
          new Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | prevSrc == null in (${matchedValue})`,
          ),
        );
        return matchedValue;
      }

      void downloadFileWithSrc(
        prevSrc,
        file =>
          uploadImageToStorage(file, userId).then(
            ({ url, s3key, filename, contentType, contentLength }) =>
              onUploadSucceeded(newId, {
                url,
                s3key,
                filename,
                contentType,
                contentLength,
              }),
          ),
        contentType,
      ).catch(e => {
        reportErrorToTracker(
          new Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | upload fail: ${JSON.stringify(e, null, 2)}`,
          ),
        );
      });

      // check that it is an image that was uploaded somewhere else
      const withoutInlineImageInfo =
        removeInlineAttachmentInformation(matchedValue);

      const withNewInlineImageId = withoutInlineImageInfo.replace(
        /src="([^"]*)"/g,
        matchedValue => `${matchedValue} data-uploadid="${newId}"`,
      );

      return withNewInlineImageId;
    } else {
      // else leave alone
      return matchedValue;
    }
  });

export default updateInlineImageIdsForAnyImages;
