import cleanedFilename from '~/util/cleanedFilename';

export default {
  CODE_SNIPPET: `${cleanedFilename(__filename)}-code-snippet`,
  LOGO_INPUT: `${cleanedFilename(__filename)}-logo-input`,
  HIGHLIGHT_IMAGE_INPUT: `${cleanedFilename(__filename)}-highlight-image-input`,

  PRIMARY_BACKGROUND_COLOR_INPUT: `${cleanedFilename(
    __filename,
  )}-primary-background-color-input`,
  PRIMARY_COLOR_INPUT: `${cleanedFilename(__filename)}-primary-color-input`,

  SECONDARY_BACKGROUND_COLOR_INPUT: `${cleanedFilename(
    __filename,
  )}-secondary-background-color-input`,
  SECONDARY_COLOR_INPUT: `${cleanedFilename(__filename)}-secondary-color-input`,

  WIDGET_POSITION_MOBILE: `${cleanedFilename(
    __filename,
  )}-widget_position_mobile`,
  WIDGET_POSITION_DESKTOP: `${cleanedFilename(
    __filename,
  )}-widget_position_desktop`,

  APPEARANCE_MOBILE: `${cleanedFilename(__filename)}-widget_appearance_mobile`,
  APPEARANCE_DESKTOP: `${cleanedFilename(
    __filename,
  )}-widget_appearance_desktop`,
  APPEARANCE_OPTION_SMALL: `${cleanedFilename(
    __filename,
  )}-widget_appearance_option_small`,
  APPEARANCE_OPTION_LARGE: `${cleanedFilename(
    __filename,
  )}-widget_appearance_option_large`,

  Z_INDEX: `${cleanedFilename(__filename)}-z-index`,

  ERROR_MESSAGE: `${cleanedFilename(__filename)}-error-message`,

  APPS_CONTAINER: `${cleanedFilename(__filename)}-apps-container`,
  GOOGLE_ANALYTICS: `${cleanedFilename(__filename)}-ga-4`,
};
