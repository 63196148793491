import { Flow_Condition__Input } from '~/graphql/types';
import { FlowConditionAppHypotheekbondNew } from './types.flow';

const asFlowConditionAppHypotheekbondNewInput = (
  condition: FlowConditionAppHypotheekbondNew,
): Flow_Condition__Input => {
  if (condition.tool === null || condition.tool?.value === null) {
    return {
      EventContactApp: {
        Hypotheekbond: {
          New: {},
        },
      },
    };
  }

  return {
    EventContactApp: {
      Hypotheekbond: {
        New: {
          tool: {
            value: condition.tool.value,
          },
        },
      },
    },
  };
};

export default asFlowConditionAppHypotheekbondNewInput;
