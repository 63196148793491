import React, { useContext } from 'react';

import styled from 'styled-components';
import { DoContactAction_SendCsv__Input } from '~/graphql/types';
import ModalContext from '~/contexts/ModalContext';
import useCurrentUser from '~/hooks/useCurrentUser';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import BasePopover from '../../components/BasePopover';
import { ActionModalProps } from '../../actionSwitcher';

const text = {
  title: 'Contacten exporteren',
  confirm: 'Bevestigen',
  popoverDescription:
    'Exporteer de geselecteerde contacten naar een CSV bestand. Het bestand wordt verzonden naar ',
};

export type Props = {
  dataTestId?: string;
} & ActionModalProps;

const SendCSV: React.FC<Props> = ({
  dataTestId,
  onCancel,
  onComplete,
  ...rest
}) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const email = useCurrentUser().email;

  return (
    <Container data-testid={dataTestId}>
      <BasePopover
        title={text.title}
        handleAction={confirm => {
          if (confirm) {
            showModal(
              <ConfirmActionModal
                actionInput={{
                  SendCSV: {
                    noOperation: null,
                  } as DoContactAction_SendCsv__Input,
                }}
                onComplete={() => {
                  hideModal();
                  onComplete();
                }}
                onCancel={hideModal}
                {...rest}
              />,
            );
          } else {
            onCancel();
          }
        }}
        confirmText={text.confirm}
      >
        {text.popoverDescription} <strong>{email}</strong>
      </BasePopover>
    </Container>
  );
};

const Container = styled.div<{}>``;

export default SendCSV;
