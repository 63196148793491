import { FlowParameterNumberFieldsFragment } from '~/graphql/types';
import { NumberParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyNumberPrimitiveParameterValue from './emptyNumberPrimitiveParameterValue';
import asNumberPrimitiveParameterValue from './asNumberPrimitiveParameterValue';

export default (
  numberParameter: FlowParameterNumberFieldsFragment | null | undefined,
): NumberParameterValue => {
  if (numberParameter == null) {
    return emptyNumberPrimitiveParameterValue();
  }
  switch (numberParameter.__typename) {
    case PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE:
      return asNumberPrimitiveParameterValue(numberParameter);
    case PARAMETER_VALUE_TYPE.NUMBER_POINTER:
      return {
        type: PARAMETER_VALUE_TYPE.NUMBER_POINTER,
        variable: {
          name: numberParameter.variable,
          field: numberParameter.field
            ? {
                name: numberParameter.field,
              }
            : null,
        },
      };
    default:
      throw new Error(
        `Should not occur: Unknown __typename (${numberParameter.__typename}) for FlowNumberParameter`,
      );
  }
};
