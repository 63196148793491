import React from 'react';

import { TaskListTaskFieldsFragment } from '~/graphql/types';

import cleanedFilename from '~/util/cleanedFilename';

export type TaskUpdaterContextProps = {
  /** If something changes, call this function with the changed task to update the query */
  onTaskChanged: (newOrUpdatedTask: TaskListTaskFieldsFragment) => void;
};

export const TaskUpdaterContext = React.createContext<TaskUpdaterContextProps>({
  onTaskChanged: () => {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | Called onTaskChanged before the provider initialised!`,
    );
  },
});
