import React, { useState } from 'react';
import BaseEventCard, { EventCardState } from '../../BaseEventCard';
import JustificationContainer from '~/components/JustificationContainer';
import { Body } from '~/components/Typography/index';
import {
  ActivityFields_Event_Contact_Email_Fragment,
  EmailDeliveryStatus,
  LayoutSize,
} from '~/graphql/types';
import DividerBlock from '../../Block/components/Data/Divider';
import { isSuccessEmailDeliveryStatus } from '~/util/constants';
import StatusContainer, {
  Level,
} from '../../BaseEventCard/components/StatusContainer';
import EmailStatistics from './components/EmailStatistics';
import SendEmailBlock from '~/components/SendEmailBlock';
import { convertServerDateStringToDate } from '~/util/date';
import styled, { css } from 'styled-components';
import TEST_ID from './index.testid';
import { EventTimelineContact } from '~/components/EventTimelineV2';
import Button from '~/components/Button';
import EmailEventMessageBlock from './components/EmailEventMessageBlock';
import { ActivityV2 } from '~/components/EventTimelineV2/types';

export type Props = Omit<ActivityV2, 'highlighted'> & {
  contact: EventTimelineContact;
  state?: EventCardState | null | undefined;
} & ActivityFields_Event_Contact_Email_Fragment;

const text = {
  subject: 'Onderwerp',
  success: 'De e-mail is verzonden',
  error: 'Er is een fout opgetreden bij het verzenden van de e-mail',
  loading: 'Bezig met laden',
  replyLabel: 'Beantwoorden',
  closeReply: 'Annuleren',
};

const getStatusForEmail = (
  status: EmailDeliveryStatus,
  deliveryErrorMessage?: string | null,
): { level: Level; label: string } => {
  switch (status) {
    case EmailDeliveryStatus.Error:
      return {
        level: 'danger',
        label: `${text.error}${
          deliveryErrorMessage ? `(${deliveryErrorMessage})` : ''
        }`,
      };

    case EmailDeliveryStatus.Synced:
      return { level: 'success', label: text.success };

    default:
      return { level: 'loading', label: text.loading };
  }
};

const SendEmail: React.FC<Props> = ({
  id,
  header,
  icon,
  sortingDate,
  subject,
  snippet,
  hasMore,
  deliveryStatus,
  tracking,
  from,
  receivedAt,
  deliveryErrorMessage,
  contact,
  ...rest
}) => {
  const [isReplyOpened, setIsReplyOpened] = useState<boolean>(false);

  const status = getStatusForEmail(deliveryStatus, deliveryErrorMessage);

  return (
    <BaseEventCard
      {...rest}
      dataTestId={`${rest.__typename}_Card`}
      header={header}
      icon={icon}
      sortingDate={sortingDate}
      body={
        <JustificationContainer
          margin={['base', 'm', isReplyOpened ? 'l' : null, 'm']}
          direction="column"
        >
          <JustificationContainer gap="base">
            <Body size="base" fontWeight="semiBold" withoutMargin>
              {subject}
            </Body>
          </JustificationContainer>
          <DividerBlock margin={[LayoutSize.S, null, LayoutSize.Xxs, null]} />
          <EmailEventMessageBlock
            id={id}
            emailText={snippet ?? ''}
            hasMore={hasMore}
            canClickOpen={isSuccessEmailDeliveryStatus(deliveryStatus)}
          />

          {status.level !== 'success' && (
            <StatusContainer
              padding={['base']}
              level={status.level}
              label={status.label}
              margin={[null, null, 's', null]}
              dataTestId={TEST_ID.STATUS_CONTAINER}
            />
          )}
        </JustificationContainer>
      }
      bottomBar={
        <>
          {status.level === 'success' && (
            <>
              {isReplyOpened && (
                <JustificationContainer margin={[null, 'm']}>
                  <SendEmailBlock
                    receiver={{
                      email: contact.email,
                      name: contact.name,
                    }}
                    subject={{
                      text: subject ?? '',
                    }}
                    onHeightChange={() => {}}
                    onSuccess={() => setIsReplyOpened(false)}
                    setAfterSendActions={() => {}}
                    contactId={contact.id}
                    replyTo={
                      isReplyOpened
                        ? {
                            eventId: id,
                            from: from[0],
                            receivedAt:
                              convertServerDateStringToDate(receivedAt),
                          }
                        : undefined
                    }
                  />
                </JustificationContainer>
              )}
              <BottomBar
                margin={isReplyOpened ? ['base', null, null, null] : [null]}
                align="center"
                justification="end"
              >
                <JustificationContainer
                  align="center"
                  padding={['xxs', null, 'xxs', 'm']}
                  justification="space-between"
                  width="100%"
                >
                  <div>
                    {tracking && <EmailStatistics tracking={tracking} />}
                  </div>
                  <Button
                    size="medium"
                    appearance="neutral"
                    dataTestId={TEST_ID.REPLY_BUTTON}
                    onClick={() => setIsReplyOpened(prev => !prev)}
                    icon={isReplyOpened ? 'chevron-up' : 'reply'}
                    label={isReplyOpened ? text.closeReply : text.replyLabel}
                  />
                </JustificationContainer>
              </BottomBar>
            </>
          )}
        </>
      }
    />
  );
};

const BottomBar = styled(JustificationContainer)(
  ({ theme }) => css`
    background-color: ${theme.color('grey', 'translucent')};
  `,
);

export default SendEmail;
