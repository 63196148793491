import { Flow_Condition__Input } from '~/graphql/types';
import { FlowConditionAppVBOWaardecheckContactRequestNew } from './types.flow';
import { asEventParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';

const asFlowConditionAppVBOWaardecheckContactRequestNewInput = (
  condition: FlowConditionAppVBOWaardecheckContactRequestNew,
): Flow_Condition__Input => {
  const { event } = condition;

  return {
    EventContactApp: {
      VBOWaardecheck: {
        ContactRequest: {
          New: {
            event: event ? asEventParameterValueInput(event) : null,
          },
        },
      },
    },
  };
};

export default asFlowConditionAppVBOWaardecheckContactRequestNewInput;
