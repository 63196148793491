import React, { useRef, useState } from 'react';
import { withTypes } from 'react-final-form';
import { FormApi } from 'final-form';
import { TaskType, TaskListTaskFieldsFragment } from '~/graphql/types';
import { Task } from '~/scenes/Tasks/types';
import { useInsertTaskMutation } from '~/graphql/types';
import { convertDateToServerDateString } from '~/util/date';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import { currentTimeHourMinuteString } from '~/util/time';
import TEST_ID from './index.testid';
import useUser from '~/hooks/useUser';
import useCurrentUser from '~/hooks/useCurrentUser';
import useMainOffice from '~/hooks/useMainOffice';
import CreateNewTaskTabFields from '../CreateNewTaskTabFields';
import { ActivityTabProps } from '../..';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

export type FormType = {
  title: string | null | undefined;
  type: TaskType;
  description: string | null | undefined;
  dueDate: Date | null | undefined;
  dueDateDate: string | null | undefined;
  dueDateTime: string | null | undefined;
  userId: string | null | undefined;
  officeId: string | null | undefined;
};

const { Form } = withTypes<FormType>();

type Props = ActivityTabProps & {
  /** Contact id for InsertTask mutation */
  contactId?: string | null;

  /** Account id for InsertTask mutation */
  accountId: string;

  /** taskDetails data with fields for InsertTask mutation */
  taskDetails?: Task | null;

  /** Callback to close task modal */
  handleCloseModal?: () => void;

  /** Show is it edit or creating of new task */
  isNew: boolean;
  handleSetContactError: (error?: string) => void;

  /** Office id for default value in assigned office dropdown */
  defaultAssignedOfficeId: string | null;

  /** User id for default value in assigned user dropdown */
  defaultAssignedUserId: string | null;

  /** Called when adding the task was a success and all other sideeffects are handled */
  onSuccess: (newTask: TaskListTaskFieldsFragment) => void;
  dataTestId?: string;
};

const generateInitialValues = (): FormType => ({
  title: null,
  type: TaskType.Call,
  description: null,
  // dueDate for date validation
  dueDate: null,
  dueDateDate: new Date().toISOString(),
  dueDateTime: currentTimeHourMinuteString(),
  userId: null,
  officeId: null,
});

const text = {
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
  validation: {
    required: Catalog.requiredField,
    contact: Catalog.requiredField,
  },
};

const CreateNewTaskTab = ({
  accountId,
  contactId,
  isNew,
  handleSetContactError,
  defaultAssignedOfficeId,
  defaultAssignedUserId,
  handleCloseModal,
  onSuccess,
  dataTestId = 'add-task',
}: Props) => {
  const [validate, setValidate] = useState(false);
  const addToast = useAddToast();

  const me = useCurrentUser();

  const userId = defaultAssignedUserId || me.id;
  let officeId: string | null = null;

  const defaultUser = useUser(userId);
  const mainOffice = useMainOffice(defaultUser?.id ?? null);

  if (defaultAssignedOfficeId == null) {
    if (defaultUser) {
      const selectedOffice = mainOffice;
      officeId = selectedOffice?.id ?? null;
    }
  } else {
    officeId = defaultAssignedOfficeId;
  }

  const initialValuesRef = useRef({
    ...generateInitialValues(),
    officeId,
    userId,
  });
  const [insertTask, { loading }] = useInsertTaskMutation();

  const onFormSubmit = (
    data: FormType,
    form: FormApi<FormType, Partial<FormType>>,
  ) => {
    // for typing, should never happen as it is validated beforehand
    if (contactId == null) {
      throw Error(
        `${cleanedFilename(__filename)} | Should not occur, contactId is null`,
      );
    }

    const dueDate = convertDateToServerDateString(
      data.dueDateDate,
      data.dueDateTime,
    );

    // for typing, should never happen as it is validated
    if (dueDate === null) {
      throw Error(
        `${cleanedFilename(__filename)} | Should not occur, dueDate is null`,
      );
    }

    const { title, officeId } = data;

    if (title == null || officeId == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Validation failed to check that title (${title}) or officeId (${officeId}) is null`,
      );
    }
    return insertTask({
      variables: {
        accountId,
        contactTask: {
          accountId,
          title,
          userId: data.userId || null,
          officeId,
          contactId,
          type: data.type,
          description: data.description || null,
          dueDate,
        },
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        return addToast([
          formatToastMessage(text.genericErrorMessage, 'danger'),
        ]);
      }

      if (data) {
        const { insertTask } = data;
        onSuccess(insertTask);

        // timeout needed for final-form, 0 to defer to end of call stack
        setTimeout(form.reset, 0);

        return;
      }

      return;
    });
  };

  return (
    <Form
      dataTestId={dataTestId}
      validate={fields => validateFields(fields, contactId, userId)}
      onSubmit={(data, form) => onFormSubmit(data, form)}
      initialValues={initialValuesRef.current}
    >
      {formProp => {
        const { handleSubmit, submitting, errors, form, initialValues } =
          formProp;
        const { getFieldState, change } = form;

        const preSubmitCheck = () => {
          const shouldValidate =
            (errors && Object.keys(errors).length > 0) || !contactId;

          if (shouldValidate) {
            const contactErrorText = !contactId ? text.validation.contact : '';
            handleSetContactError(contactErrorText);
            setValidate(shouldValidate);
          }
        };

        return (
          <div data-testid={TEST_ID.CONTAINER}>
            <CreateNewTaskTabFields
              validate={validate}
              // @ts-ignore
              initialValues={initialValues}
              isNew={isNew}
              loading={loading || submitting}
              onSubmit={shouldClose => {
                preSubmitCheck();

                return handleSubmit()?.then(() => {
                  setValidate(false);
                  if (shouldClose && handleCloseModal) {
                    handleCloseModal();
                  }
                });
              }}
              getFieldState={getFieldState}
              change={change}
            />
          </div>
        );
      }}
    </Form>
  );
};

const validateFields = (
  fields: FormType,
  contactId?: string | null,
  userId?: string | null,
): $Object => {
  const errors: Omit<FormType, 'type'> & {
    contactId: string | null | undefined;
  } = {
    title: undefined,
    dueDate: undefined,
    dueDateDate: undefined,
    dueDateTime: undefined,
    userId: undefined,
    officeId: undefined,
    contactId: undefined,
    description: undefined,
  };

  if (!fields.title) errors.title = text.validation.required;

  if (!fields.dueDateDate) errors.dueDateDate = text.validation.required;

  if (!fields.dueDateTime) errors.dueDateTime = text.validation.required;

  if (!fields.userId === null && !fields.userId)
    errors.userId = text.validation.required;

  if (!fields.officeId) errors.officeId = text.validation.required;

  if (!contactId) errors.contactId = text.validation.required;

  if (!userId) errors.userId = text.validation.required;

  return errors;
};

export default CreateNewTaskTab;
