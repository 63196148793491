import React from 'react';
import styled, { css } from 'styled-components';
import { OptionOf } from '~/components/Inputs/Dropdown';
import HeadingContainer, {
  Props as HeadingProps,
} from '~/components/HeadingContainer';
import { DateRangeInput, SortFieldAdvanced } from '~/graphql/types';

import TEST_ID from './index.testid';
import Dropdown from '~/components/Dropdown';

export type Props = {
  dataTestId?: string;
  selectedOptionIndex: number;
  options: Array<OptionOf<DateRangeInput | SortFieldAdvanced | number | null>>;
  onOptionChange: ({
    selectedOptionIdx,
  }: {
    selectedOptionIdx: number;
  }) => void;
} & HeadingProps;

const CardHeader: React.FC<Props> = ({
  dataTestId,
  selectedOptionIndex,
  options,
  onOptionChange,
  ...rest
}) => (
  <Container data-testid={`${dataTestId}-${TEST_ID.CONTAINER}`} {...rest}>
    <div>
      <HeadingContainer {...rest} />
    </div>
    <DropdownRestriction>
      <Dropdown
        dataTestId={`${dataTestId}-${TEST_ID.DROPDOWN}`}
        options={options}
        selectedOptionIdx={selectedOptionIndex}
        onChange={onOptionChange}
      />
    </DropdownRestriction>
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${theme.space('l')};
  `,
);

const DropdownRestriction = styled.div<{}>`
  flex: 0 1 50%;
`;

export default CardHeader;
