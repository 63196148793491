import gql from 'graphql-tag';

export default gql`
  mutation UpdateSubscription(
    $accountId: ID!
    $plan: Plan!
    $billingCycle: BillingCycle!
    $coupon: ID
  ) {
    updateSubscription(
      accountId: $accountId
      plan: $plan
      billingCycle: $billingCycle
      coupon: $coupon
    ) {
      id
    }
  }
`;
