import { FlowEmailAttachmentValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { getAttributeValue } from '~/components/HTMLEditor/util/variableHTML';
import cleanedFilename from '~/util/cleanedFilename';
import { PARAMETER_VALUE_TYPE } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/constants';
import {
  INLINE_IMAGE_ID_ATTR,
  INLINE_IMAGE_FILENAME_ATTR,
  INLINE_IMAGE_S3KEY_ATTR,
  INLINE_IMAGE_CONTENT_TYPE_ATTR,
  INLINE_IMAGE_CONTENT_LENGTH_ATTR,
  INLINE_IMAGE_FROM_BACKEND_REGEX,
  INLINE_IMAGE_SRC_ATTR,
} from '~/components/HTMLEditor/constants';
import { SignatureAttachmentValue } from '~/components/SignatureContainer/types';

/**
 * Backend is going to give:
 *    <img src="[[inlineId]]" />
 *
 * We want to put the url in there, but also all the other information in the file:
  file: FileParameterValue,
  inlineId?: null | string,
  url?: null | string
 */
const SRC_REGEX = /src="[^"]*?"/g;
const convertHtmlToFroalaWithInlineAttachments = (
  html: string,
  inlineAttachments: Array<FlowEmailAttachmentValue | SignatureAttachmentValue>,
): string =>
  html.replace(INLINE_IMAGE_FROM_BACKEND_REGEX, matchedValue =>
    matchedValue.replace(SRC_REGEX, matchedSrc => {
      const src = getAttributeValue('src', matchedSrc);

      if (src == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | The attribute src returned null`,
        );
      }
      // [[inlineId:<lookingfor>]], so ignore first 11 chars
      const inlineId = src.substring(11, src.length - 2);

      const attachment = inlineAttachments.find(
        attachment => attachment.inlineId === inlineId,
      );

      if (attachment == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | Cannot find attachment for src (${matchedSrc}) with id (${inlineId}) in attachment list (${JSON.stringify(
            inlineAttachments,
          )})`,
        );
      }

      const url = attachment.url;

      if (url == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | inline image (${inlineId}) given without a url`,
        );
      }

      const file = attachment.file;

      if (
        file.type !== PARAMETER_VALUE_TYPE.FILE_PRIMITIVE &&
        file.type !== 'DHFile'
      ) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Only file primitives may be used as inline images`,
        );
      }

      const { contentLength, s3key, filename, contentType } = file;

      if (
        inlineId == null ||
        filename == null ||
        s3key == null ||
        contentLength == null
      ) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | cannot convert inline image to event (filename: ${
            filename || 'null'
          } | s3key: ${s3key || 'null'} | inlineId: ${
            inlineId || 'null'
          } | contentLength: ${contentLength || 'null'})`,
        );
      }

      return `src="${url}" ${INLINE_IMAGE_ID_ATTR}="${inlineId}" ${INLINE_IMAGE_S3KEY_ATTR}="${s3key}" ${INLINE_IMAGE_FILENAME_ATTR}="${filename}" ${INLINE_IMAGE_CONTENT_TYPE_ATTR}="${
        contentType || 'null'
      }" ${INLINE_IMAGE_CONTENT_LENGTH_ATTR}="${contentLength}" ${INLINE_IMAGE_SRC_ATTR}="${url}"`;
    }),
  );

export default convertHtmlToFroalaWithInlineAttachments;
