import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

export type Props = {
  dataTestId?: string;

  /** Name of the tick */
  value: string;

  /** Line amount will decrease as the tick amount increases so that there is enough space for the labels */
  totalItemAmount: number;
};

const LabelEllipsis: React.FC<Props> = ({ value, totalItemAmount }) => (
  <LinesEllipsis
    text={value}
    maxLine={Math.floor(10 / totalItemAmount)}
    ellipsis="..."
    trimRight
    basedOn="letters"
  />
);

export default LabelEllipsis;
