import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../Icon';
import JustificationContainer from '../JustificationContainer';
import copyTextToClipboard from '~/util/copyTextToClipboard';
import useHover from '../util/useHover';
import Tooltip from '../Tooltip';
import theme from '~/theme';

export type Props = {
  dataTestId?: string;
  textToCopy: string;
  label?: string;
};

const text = {
  copy: 'Kopiëren',
};

const CopyTextBlock: React.FC<Props> = ({
  dataTestId,
  label,
  textToCopy,
  ...rest
}) => {
  const [copied, setCopied] = useState(false);
  const [showTooltip, tooltipProps] = useHover();

  const onCopy = () => {
    void copyTextToClipboard(textToCopy)
      .then(() => {
        setCopied(true);
      })
      .catch(() => {
        setCopied(false);
      });

    const timer = setTimeout(() => {
      clearTimeout(timer);
      setCopied(false);
    }, 1200);
  };

  return (
    <Container data-testid={dataTestId} {...rest} align="center">
      {label && <Label>{label}:</Label>}
      {textToCopy}
      <div>
        {!copied && showTooltip && (
          <Tooltip padding={['xxs']}>{text.copy}</Tooltip>
        )}
        <StyledIcon
          {...tooltipProps}
          color={copied ? theme.color('success') : theme.color('primary')}
          onClick={onCopy}
          name={copied ? 'check' : 'copy'}
        />
      </div>
    </Container>
  );
};

const Label = styled.span(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight('semiBold')};
    margin-right: ${theme.space('xxs')};
  `,
);

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    padding: ${theme.space('xxs')};
    background: ${theme.color('primary', 'translucent')};
  `,
);

const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    cursor: pointer;
    margin-left: ${theme.space('base')};
  `,
);

export default CopyTextBlock;
