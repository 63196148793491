import qs from 'query-string';

import {
  getLocalStorageItem,
  setLocalStorageItem,
  CONTACT_LIST_FILTERSV2,
  hasLocalStorageItem,
  removeLocalStorageItem,
  CONTACT_LIST_FILTERS,
} from '~/util/localStorageKeys';
import deserializeFiltersV2 from '~/scenes/Contacts/util/deserializeFiltersV2';
import deserializeFilters from '~/scenes/Contacts/util/deserializeFilters';
import {
  ConnectorOperator,
  ContactFilters__Input,
  useGetContactFiltersTranslationLazyQuery,
} from '~/graphql/types';
import { emptyFilterGroupV2 } from '../components/ContactList/FilterBar';
import { useEffect, useMemo, useState } from 'react';
import { equals } from 'ramda';
import asNodeInput from '~/components/Filters/asNodeInput';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { useLocation } from '@reach/router';
import serializeFiltersV2 from './serializeFiltersV2';
import { navigate } from 'gatsby';
import {
  cleanInflatedContactFilters,
  inflateContactFilters,
} from '~/components/Filters/util/contactFilter/filterTranslationHelpers';

export const DEFAULT_EMPTY_FILTERS: ContactFilters__Input = {
  connector: ConnectorOperator.And,
  negate: false,
  nodes: [emptyFilterGroupV2()],
};

type QueryStringObj = {
  f?: string;
  filters?: string;
};

/**
 * 1. Get from query params
 * 1.1 Allow filters to take precedence over f
 * 1.2 if filters, call graphql and translate (if you can, default back to f if fails, if f fails, default to empty state)
 * 1.3 set using F, default back to empty state if fails
 * 2. Get from local storage
 * 2.1 basically repeat 1.1 to 1.3 with data from local storage
 */

type Result = [false, ContactFilters__Input] | [true, null];
const useContactFiltersV2 = (): [boolean, ContactFilters__Input] => {
  const { id: accountId } = useCurrentAccount();

  const [getResponse, { data, loading }] =
    useGetContactFiltersTranslationLazyQuery();

  const location = useLocation();

  const [response, setResponse] = useState<ContactFilters__Input | null>(null);
  const [result, setResult] = useState<Result>([true, null]);

  const scopedLocalStorageKey = `${CONTACT_LIST_FILTERSV2}:${accountId}`;

  const parsedFromQuery: QueryStringObj = useMemo(
    () => qs.parse(location.search),
    [location.search],
  );

  useEffect(() => {
    // Clean up legacy filters
    if (hasLocalStorageItem(CONTACT_LIST_FILTERS)) {
      removeLocalStorageItem(CONTACT_LIST_FILTERS);
    }
  }, []);

  useEffect(() => {
    if (parsedFromQuery.filters) {
      try {
        deserializeFilters(parsedFromQuery.filters);
      } catch (err) {
        // Filter cannot be serialized. Probably the customer has adjusted the query params manually
        setResult([false, DEFAULT_EMPTY_FILTERS]);
        return;
      }
      // translate
      getResponse({
        variables: {
          accountId,
          nodes: asNodeInput(
            deserializeFilters(parsedFromQuery.filters) as any,
          ),
        },
      });

      return;
    } else if (parsedFromQuery.f) {
      setLocalStorageItem(scopedLocalStorageKey, parsedFromQuery.f);
      // Retrieve previously stored filters and try to parse them
      try {
        const fromLS = getLocalStorageItem(scopedLocalStorageKey);

        // When some bad stuff got stored in localStorage we can recover from it by returning empty filters
        if (!fromLS || fromLS === '' || fromLS === 'undefined') {
          setResult([false, DEFAULT_EMPTY_FILTERS]);
          return;
        }

        const filtersInput = deserializeFiltersV2(fromLS);

        setResult([false, filtersInput ?? DEFAULT_EMPTY_FILTERS]);
        return;
      } catch (err) {
        // Oh snap, something went horribly wrong, print to the to console
        // and started the journey by returning empty filters (Customer might have adjusted the query params manually)
        // eslint-disable-next-line no-console
        console.error(err);

        setResult([false, DEFAULT_EMPTY_FILTERS]);
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, parsedFromQuery]);

  useEffect(
    () => {
      if (loading) return;
      if (data == null) return;
      if (data.getContactFiltersTranslation[0] == null) return;

      const inflatedArray = cleanInflatedContactFilters(
        inflateContactFilters({
          contactFilters: data?.getContactFiltersTranslation,
        }),
      ) as ContactFilters__Input;

      const filtersCurrent = {
        connector: ConnectorOperator.And,
        negate: false,
        nodes: [
          {
            Node: {
              ...inflatedArray,
            },
          },
        ],
      };

      if (equals(result[1], filtersCurrent)) {
        return;
      }

      setResponse(filtersCurrent);
      setResult([false, filtersCurrent]);

      const scopedLegacyLocalStorageKey = `${CONTACT_LIST_FILTERS}:${accountId}`;
      if (hasLocalStorageItem(scopedLegacyLocalStorageKey)) {
        removeLocalStorageItem(scopedLegacyLocalStorageKey);
      }
      setLocalStorageItem(
        scopedLocalStorageKey,
        serializeFiltersV2(filtersCurrent),
      );
      void navigate(`?f=${serializeFiltersV2(filtersCurrent)}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      parsedFromQuery.filters,
      accountId,
      data,
      loading,
      response,
      scopedLocalStorageKey,
    ],
  );

  const fromLS = getLocalStorageItem(scopedLocalStorageKey);
  if (parsedFromQuery.f == null && parsedFromQuery.filters == null) {
    // Retrieve previously stored filters and try to parse them
    try {
      if (fromLS && fromLS !== '' && fromLS !== 'undefined') {
        const filtersInput = deserializeFiltersV2(fromLS);
        return [false, filtersInput ?? DEFAULT_EMPTY_FILTERS];
      }
      return [false, DEFAULT_EMPTY_FILTERS];
    } catch (err) {
      // Oh snap, something went horribly wrong, print to the to console
      // and started the journey by returning empty filters (Customer might have adjusted the query params manually)
      // eslint-disable-next-line no-console
      console.error(err);

      return [false, DEFAULT_EMPTY_FILTERS];
    }
  }
  // @ts-ignore
  return result;
};

export default useContactFiltersV2;
