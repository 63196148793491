import { RouteComponentProps } from '@reach/router';
import React from 'react';
import {
  AppBbWaardecheckAppraisalRequest,
  AppVboWaardecheckAppraisalRequest,
} from '~/graphql/types';
import InputGroupDivider from '~/scenes/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import {
  AppWaardecheckFieldsFragment,
  appWaardecheckState,
  StateId,
} from '../..';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppSectionHeader from '~/scenes/Apps/components/AppSectionHeader';
import WaardecheckIntl from '../../intl';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import IntlInputComponent from '../../components/IntlInputComponent';
import MainPreview from '../../components/MainPreview';
import ImageInput from '../../components/ImageInput';
import { Page } from '../../components/Preview';
import TEST_ID from './index.testid';
import Explanation from '../../components/Explanation';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
};

const AppraisalRequest: React.FC<Props> = ({
  dataTestId: _dataTestId,
  data,
  defaultValues,
  loading,
}) => {
  const original = defaultValues.appraisalRequest;
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const updateValue = (
    value: DeepPartial<
      AppBbWaardecheckAppraisalRequest | AppVboWaardecheckAppraisalRequest
    >,
  ) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        appraisalRequest: value,
      }) as any as typeof prev;
    });
  };

  if (updated == null) return null;

  return (
    <AppDetailsContainer
      header="Waardebepaling"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Explanation>
        Op deze pagina kan een waardebepaling worden aangevraagd. Personaliseer
        de achtergrondfoto en teksten.
      </Explanation>
      <MainPreview data={updated} page={Page['/appraisal-request']} />
      <AppEntryItemHeader>{WaardecheckIntl.mainImage.title}</AppEntryItemHeader>
      <Description>{WaardecheckIntl.mainImage.description}</Description>
      <ImageInput
        initialUrl={data.appraisalRequest.image?.url}
        filename={data.__typename + 'appraisalrequest'}
        s3Key={updated?.appraisalRequest.image?.s3key}
        onChange={image => updateValue({ image })}
        dataTestid={TEST_ID.IMAGE}
      />
      <InputGroupDivider large />
      <AppSectionHeader>{WaardecheckIntl.section.text}</AppSectionHeader>
      <Description>{WaardecheckIntl.section.textDescription}</Description>
      <IntlInputComponent
        title={WaardecheckIntl.heading}
        defaultValue={original.heading}
        value={updated.appraisalRequest.heading}
        onChange={heading => updateValue({ heading })}
        dataTestId={TEST_ID.HEADING}
        description={WaardecheckIntl.variables.propertyAdress}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Disclaimer"
        defaultValue={original.disclaimer}
        value={updated.appraisalRequest.disclaimer}
        onChange={disclaimer => updateValue({ disclaimer })}
        dataTestId={TEST_ID.DISCLAIMER}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Aanvraag versturen button"
        defaultValue={original.primaryButton}
        value={updated.appraisalRequest.primaryButton}
        onChange={primaryButton => updateValue({ primaryButton })}
        dataTestId={TEST_ID.PRIMARY_BUTTON}
      />
    </AppDetailsContainer>
  );
};

export default AppraisalRequest;
