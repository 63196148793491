import { useState, useEffect } from 'react';
import { isLiveStage } from '~/util';

const shouldMockIntercom = !process.env.INTERCOM_APP_ID || !isLiveStage();

declare global {
  interface Window {
    Intercom: (command: string, options?: $Object | string) => void;
  }
}

type UserDetails = {
  id: string;
  email: string;
  name: string;
  phone: string | null | undefined;
  mainOffice: string | null | undefined;
  first_admin: boolean | null | undefined;
  signed_up_at: number | null | undefined;
};

type IntercomBootOptions = {
  user: UserDetails;
};

const getUserDetails = ({
  id,
  email,
  name,
  phone,
  mainOffice,
  first_admin,
  signed_up_at,
}) =>
  // Do not send dathuis details to intercom (as our cypress tests create loads of e-mails)
  email.endsWith('@dathuis.nl')
    ? {
        email: 'internal@dathuis.nl',
        user_id: 'internal-dathuis-person-id',
        name: 'Internal DatHuis',
        phone: '',
        main_office: '',
        first_admin: undefined,
        signed_up_at: undefined,
      }
    : {
        email,
        user_id: id,
        name,
        phone: phone ? phone : '',
        main_office: mainOffice ? mainOffice : '',
        first_admin: first_admin,
        signed_up_at: signed_up_at,
      };

type DH_Intercom = {
  boot: (options?: IntercomBootOptions) => void;
  logOff: () => void;
  openChat: (initialMessage?: string) => void;
  update: (args: $Object) => void;
};

const printActionForDev =
  (_command: string) =>
  (..._args) => {
    // eslint-disable-next-line no-console
    // console.info(`Intercom command fired "${_command}"`, ..._args);
  };

const useIntercom = () => {
  const [intercom, setIntercom] = useState<DH_Intercom>({
    boot: printActionForDev('boot'),
    logOff: printActionForDev('logOff'),
    openChat: printActionForDev('openChat'),
    update: printActionForDev('update'),
  });
  useEffect(() => {
    if (!shouldMockIntercom) {
      setIntercom({
        boot: options => {
          if ('Intercom' in global.window) {
            const userDetails =
              options && options.user ? getUserDetails(options.user) : {};

            global.window.Intercom('boot', {
              app_id: process.env.INTERCOM_APP_ID,
              ...userDetails,
            });
          }
        },
        logOff: () => {
          if ('Intercom' in global.window) {
            global.window.Intercom('shutdown');
          }
        },
        openChat: initialMessage => {
          if ('Intercom' in global.window) {
            global.window.Intercom('showNewMessage', initialMessage);
          }
        },
        update: args => {
          if ('Intercom' in global.window) {
            global.window.Intercom('update', args);
          }
        },
      });
    }
  }, []);

  return intercom;
};

export default useIntercom;
