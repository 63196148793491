import { SystemSize } from '~/theme';

export type Appearance =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'danger';

export type Size = 'medium' | 'large';
export type ButtonSize = Size | 'small';

type SizeProps = {
  fontSize: SystemSize;
  padding: [SystemSize, SystemSize];
};

type ComponentSizes = {
  [key in Size]: SizeProps;
};

type ButtonSizes = {
  [key in ButtonSize]: SizeProps;
};

export const componentSizes: ComponentSizes = {
  medium: {
    fontSize: 'base',
    padding: ['s', 'm'],
  },
  large: {
    fontSize: 'base',
    padding: ['m', 'm'],
  },
};

export const buttonSizes: ButtonSizes = {
  ...componentSizes,
  small: {
    fontSize: 'xs',
    padding: ['xs', 's'],
  },
};

// Font size of 18 is taken from the legacy theme. Making it a constant is not the best solution,
// but at least will not hold us from removing legacy theme. It's only used in old components,
// means the constant will be replaced with the theme sizes once we overwrite old components.
export const FONT_SIZE_OF_18 = 18;

// It's only used in old components,
// means the constant will be replaced with the theme sizes once we overwrite old components.
export const APPROXIMATE_HEADER_HEIGHT = 72;
