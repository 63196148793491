import React from 'react';
import styled, { css } from 'styled-components';
import HelpButton from '~/components/Buttons/HelpButton';
import ExternalLink from '~/components/ExternalLink';
import JustificationContainer from '~/components/JustificationContainer';

const CsvImportExplanation = () => (
  <JustificationContainer direction="column" align="center">
    Upload een csv bestand om meerdere contacten te importeren.
    <div>
      Maak gebruik van dit{' '}
      <ExternalLink href="https://dhstash.s3-eu-west-1.amazonaws.com/importContacts.xlsx">
        Excel template
      </ExternalLink>{' '}
      en sla deze als csv bestand op
      <StyledHelpButton
        link={{
          link: 'https://help.dathuis.nl/nl/articles/4003756-hoe-importeer-ik-contacten',
        }}
      />
    </div>
  </JustificationContainer>
);

const StyledHelpButton = styled(HelpButton)(
  ({ theme }) => css`
    margin-left: ${theme.space('xxs')};
  `,
);

export default CsvImportExplanation;
