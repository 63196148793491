import styled from 'styled-components';

import { H3 } from '~/components/Typography';

const Headline = styled(H3)<{}>`
  margin-top: 0;

  ${({ theme }) => `
    color: ${theme.color('primary')};
  `};
`;

export default Headline;
