import { isEmpty, isObject, isString } from 'lodash';
import { reporter } from '~/hooks/useErrorReporter';

type ErrorReportOptions = {
  errorInfo?: { [key: string]: string };
};
/**
 * Legacy function to report errors to tracking system
 * @deprecated use useErrorReporter instead
 */
export const reportErrorToTracker = (
  error: Error | string,
  { errorInfo }: ErrorReportOptions = {},
): void => {
  const formattedError: Error = isString(error) ? Error(error) : error;
  reporter.captureException(formattedError, undefined, scope => {
    if (isObject(errorInfo) && !isEmpty(errorInfo)) {
      scope.setExtras(errorInfo);
    }
    return scope;
  });
};

/**
 * Assertion to be used for exhaustive type checking in switch case statements.
 *
 * E.g.:
 * switch(relation.__typename) {
 *   case 'AccountRelationship':
 *     // Some handling
 *     break;
 *     case 'OfficeRelationship':
 *       // Some handling
 *       break;
 *
 *   default:
 *     assertNever(relation) <- If __typename has more cases than AccountRelationship and OfficeRelationship this will give a linting error.
 * }
 */
export const assertNever = (x: never, filename: string = ''): never => {
  throw new Error(`${filename} | Unknown object ${JSON.stringify(x, null, 2)}`);
};

export const assertType = <T>(_x: T): void => {};

export const assertNeverWithoutThrowing = (
  x: never,
  filename: string = '',
): void => {
  reportErrorToTracker(
    `${filename} | Unknown object ${JSON.stringify(x, null, 2)}`,
  );
};
