import Color from 'color';
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import { actionsSelector } from '~/scenes/Automation/v2/state';
import interactions from '~/scenes/Automation/v2/state/interactions';
import { DEFAULT_WIDTH } from '../..';
import useBuilderContext from '../../../../hooks/useBuilderContext';
import usePanToActionId from '../../../../hooks/usePanToActionId';
import onDeletePortalConnection from '../CardTemplate/utils/onDeletePortalConnection';

export type Props = {
  dataTestId?: string;
  color: string;
  sourceActionId: string;
  targetActionId: string;
  type: 'target' | 'source';
  tooltipText?: string;
};

/**
 * Source portal card positions are passed in getPortalCards (left | right | center)
 * Target portal card positions are determined in createGraphLayout (left | right)
 * Target portal handles are determined in getTargetPortalHandlePosition (left | right)
 * Portal edges and markerEnds are passed in getElementsfromActions
 */
const PortalCard: React.FC<Props> = React.memo(
  ({
    dataTestId,
    children,
    color,
    sourceActionId,
    targetActionId,
    type,
    tooltipText,
    ...rest
  }) => {
    const panToAction = usePanToActionId(
      type === 'target' ? sourceActionId : targetActionId,
    );

    const { accountId, flowBlueprintId } = useBuilderContext();

    const setActions = useSetRecoilState(
      actionsSelector({
        accountId,
        flowBlueprintId,
      }),
    );
    const [currentInteraction, setInteraction] = useRecoilState(interactions);

    const isHighlighted =
      currentInteraction?.type === 'highlightActions' &&
      currentInteraction?.highlightedActions?.[0] === sourceActionId &&
      currentInteraction?.highlightedActions?.[1] === targetActionId;

    const isDisabled =
      currentInteraction?.type === 'connectAction' ||
      (currentInteraction?.type === 'highlightActions' && !isHighlighted);

    return (
      <Container
        title={tooltipText}
        data-testid={dataTestId}
        $color={color}
        onClick={panToAction}
        onMouseEnter={() => {
          if (currentInteraction?.type !== 'highlightActions')
            setInteraction({
              type: 'highlightActions',
              highlightedActions: [sourceActionId, targetActionId],
            });
        }}
        onMouseLeave={() => {
          if (currentInteraction) setInteraction(null);
        }}
        $disabled={isDisabled}
        {...rest}
      >
        <LabelContainer>{children}</LabelContainer>

        <StyledButton
          onClick={e => {
            e.stopPropagation();
            setActions(actions =>
              onDeletePortalConnection({
                actions,
                sourceActionId,
                targetActionId,
              }),
            );
            setInteraction(null);
          }}
          $color={color}
        >
          <Icon name="close" strokeWidth={3} />
        </StyledButton>
      </Container>
    );
  },
);

const Container = styled.div<{
  $color: string;
  $disabled: boolean;
}>(
  ({ $color, theme, $disabled }) => css`
    background-color: ${$disabled ? theme.color('grey', 'light') : $color};
    color: ${theme.color('white')};
    width: ${DEFAULT_WIDTH / 2 - 70}px;
    border-radius: ${theme.getTokens().border.radius.base};
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    cursor: pointer;
    pointer-events: ${$disabled ? 'none' : 'auto'};
    text-align: left;
    transition: all 0.3s ease-out;
  `,
);

const LabelContainer = styled.span<{}>(
  ({ theme }) => css`
    padding: ${theme.space('xxs')} ${theme.space('xs')};
    flex-basis: 80%;
  `,
);

const StyledButton = styled.button<{ $color: string }>(
  ({ theme, $color }) => css`
    border: none;
    border-radius: 0 ${theme.getTokens().border.radius.base}
      ${theme.getTokens().border.radius.base} 0;
    background: transparent;
    flex-basis: 20%;
    cursor: pointer;

    &:hover {
      background-color: ${Color($color).darken(0.1).hex()};
      transition: all 0.2s ease-out;
    }
  `,
);

export default PortalCard;
