import { omit } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import DonutChart from '~/components/Charts/DonutChart';
import DatHuisLoading from '~/components/DatHuisLoading';
import JustificationContainer from '~/components/JustificationContainer';
import Link from '~/components/Link';
import { Body } from '~/components/Typography/index';
import { useGetWalletBalanceQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';

export type Props = {
  dataTestId?: string;
  label: string;
};

const text = {
  noBalance: (
    <>
      Je hebt nog geen saldo in je wallet.
      <br />
      Ga direct geld verdienen met onze apps.&nbsp;
      <Link to="/-/apps">Bekijk hier</Link>
    </>
  ),
  whenToExpect:
    'Het openstaande saldo wordt aan het begin van iedere maand uitbetaald.',
  walletBalanceKeys: {
    cleared: 'Saldo voor uitbetaling',
    invoiced: 'Uitbetaald',
  },
};

const WalletBalance: React.FC<Props> = ({ dataTestId, label, ...rest }) => {
  const { id: accountId } = useCurrentAccount();
  const { data, loading } = useGetWalletBalanceQuery({
    variables: {
      accountId,
    },
  });

  if (loading) return <DatHuisLoading />;

  if (data?.getWalletBalance.total === 0) {
    return (
      <FallbackContainer align="center" justification="center">
        <Body withoutMargin>{text.noBalance}</Body>
      </FallbackContainer>
    );
  }

  return (
    <Container data-testid={dataTestId} {...rest}>
      <br />
      <Body>{text.whenToExpect}</Body>
      {data?.getWalletBalance.total !== 0 && (
        <DonutChart
          label={label}
          data={Object.keys(
            omit(
              ['total', '__typename', 'pendingClearance'],
              data?.getWalletBalance ?? {},
            ),
          ).map(key => ({
            id: key,
            name: text.walletBalanceKeys[key],
            value: (data?.getWalletBalance[key] ?? 0) / 100,
          }))}
          formatOptions={{
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{}>``;

const FallbackContainer = styled(JustificationContainer)`
  text-align: center;
  min-height: 200px;
`;

export default WalletBalance;
