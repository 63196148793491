import React, { ReactElement, useContext } from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from '../../components/SubscriptionDetailsPage/index.testid';

import getActiveSubscription from '../../util/getActiveSubscription';
import SubscriptionContext from '../../context/SubscriptionContext';
import { AppErrorScreen } from '~/components';
import getCancelledDate from '../../util/getCancelledDate';
import ReactivateSubscriptionBanner from '../ReactivateSubscriptionBanner';
import SubscriptionInfoBlock from '../SubscriptionInfoBlock';
import SubscriptionOveragesInfoBlock from '../SubscriptionOveragesInfoBlock';
import BillingDetailsBlock from '../BillingDetailsBlock';
import { DHSubscription, StripeSubscription } from '../../types.flow';
import { SUBSCRIPTION_TYPE } from '../../constants';

const text = {
  externalSubscriptionTitle: 'Niet van toepassing',
  externalSubscriptionMessage:
    'Dit abonnement wordt handmatig beheerd. Heb je vragen over je abonnement of wil je overstappen? Neem dan contact met ons op via de chat of via 020 - 222 35 71',
};
type Props = {
  subscriptions: Array<DHSubscription>;
};
const SubscriptionDetailsPage: React.FC<Props> = ({ subscriptions }) => {
  const { refetchSubscriptionData } = useContext(SubscriptionContext);

  const stripeSubscriptions: Array<StripeSubscription> = [];

  subscriptions.forEach(subscription => {
    if (subscription.type === SUBSCRIPTION_TYPE.STRIPE) {
      stripeSubscriptions.push(subscription);
    }
  });

  if (
    stripeSubscriptions.length === 0 ||
    stripeSubscriptions.length != subscriptions.length
  ) {
    return (
      <AppErrorScreen
        message={text.externalSubscriptionMessage}
        data-testid={TEST_ID.MANUAL_SUBSCRIPTION_PAGE}
      />
    );
  }

  const cancellingDate = getCancelledDate(stripeSubscriptions);
  const activeSubscription = getActiveSubscription(stripeSubscriptions);
  let reactivationBlockComponent: ReactElement | null = null;
  if (cancellingDate != null && activeSubscription != null) {
    reactivationBlockComponent = (
      <ReactivateSubscriptionBanner
        currentPlan={activeSubscription.plan}
        currentBillingCycle={activeSubscription.billingCycle}
        cancellingDate={cancellingDate}
        onSuccess={() => refetchSubscriptionData()}
      />
    );
  }

  return (
    <Container>
      {reactivationBlockComponent}
      <SubscriptionInfoBlock
        isCancelling={cancellingDate != null}
        activeAndUpcomingSubscriptions={stripeSubscriptions}
      />
      <StyledBillingDetailsBlock />
      <SubscriptionOveragesInfoBlock />
    </Container>
  );
};

const Container = styled.div<{}>`
  display: grid;

  grid-template-columns: auto 290px;
  grid-template-rows: min-content min-content min-content;

  ${({ theme }) => css`
    grid-gap: ${theme.space('xl')};
  `}

  @media (max-width: 1050px) {
    grid-template-columns: auto;
  }
`;

const StyledBillingDetailsBlock = styled(BillingDetailsBlock)<{}>`
  grid-row: 1 / -1;
  grid-column: 2 / 2;

  @media (max-width: 1050px) {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
  }
`;

export default SubscriptionDetailsPage;
