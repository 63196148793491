// https://github.com/froala/react-froala-wysiwyg
import React from 'react';

// Import plugins to use
// https://www.froala.com/wysiwyg-editor/docs/plugins
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
// import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
// import 'froala-editor/js/third_party/image_tui.min.js';
// import 'froala-editor/js/plugins/image_manager.min.js';

import 'froala-editor/js/plugins/lists.min.js';
// import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/link.min.js';

// Import a language file.
import 'froala-editor/js/languages/nl.js';

// Custom plugins/commands
import './addVariablePlugin';
import './unsubscribeLinkButtonCommand';
import './insertHTMLPlugin';

import FroalaEditorComponent from 'react-froala-wysiwyg';

import { UploadImageReturnType } from '~/components/HTMLEditor/util/uploadImageToStorage';

import { getOptions } from './defaultFroalaOptions';
import { errorEditorClass } from '~/theme/GlobalStyle/froalaTheme';
import idGenerator from '~/scenes/Automation/Flows/util/idGenerator';
import {
  changeMappingIdsForAnyVariables,
  addDraggableClassToVariables,
  changeVariableUpdaterIds,
  hasVariableUpdaterIdsOtherThan,
  // getAttributeValue,
} from '~/components/HTMLEditor/util/variableHTML';
import { deduplicated } from '~/util/array';
import TEST_ID from './HTMLEditor.testid';
import cleanupHTML from './util/cleanupHTML';
import cleanedFilename from '~/util/cleanedFilename';
import AccountContext from '~/contexts/AccountContext';
import { updateLoadingClasses } from './util';
// import { matchAll } from '~/util/string';
import removeInlineAttachmentInformation from './util/removeInlineAttachmentInformation';
import ErrorModal from '../Alerts/ErrorModal';
import { getImageTypeFromFile } from './util/imageHelpers';
import updateInlineImageIdsForAnyImages from './util/updateInlineImageIdsForAnyImages';
import { MORTGAGE_LINK_IN_FROALA } from '~/scenes/Automation/Flows/Actions/constants';
import { Props, State } from './types.flow';
import InsertHTMLModal from '../Modals/InsertHTMLModal';

const text = {
  errorTitle: 'Oeps!',
};

const defer = time =>
  new Promise(resolve => {
    setTimeout(resolve, time);
  });

const getEditorRec = async controls => {
  const _editor = controls.getEditor();
  if (_editor && (!_editor.ready || !_editor.events)) {
    await defer(100);
    return getEditorRec(controls);
  }

  return _editor;
};

class HTMLEditor extends React.Component<Props, State> {
  editor: any;
  /**
   * In the cleanup of the html we can detect if we pasted an external variable.
   * We want to notify our parent component that this happened AFTER the paste though.
   * So use this variable to check that
   */
  pastedExternalVariable: boolean = false;
  _currentUploadingIds: Array<string> = [];
  _hasFocus: boolean = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      config: getConfig(props),
      errorMessage: null,
      showInsertHTMLModal: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { readOnly } = this.props;

    if (readOnly != null && prevProps.readOnly != readOnly) {
      this.setReadOnly();
    }
  }

  setReadOnly = () => {
    const { readOnly = false } = this.props;
    if (this.editor && this.editor.edit) {
      if (readOnly) {
        this.editor.edit.off();
      } else {
        this.editor.edit.on();
      }
    }
  };

  insertHtml = (toInsert: string) => {
    this.editor.events.focus(true);
    this.editor.html.insert(this.prepareHtmlForSet(toInsert));
    this.editor.undo.saveStep();

    return this.getHtml();
  };

  setHtml = (newHtml: string) => {
    this.editor.html.set(this.prepareHtmlForSet(newHtml));

    return newHtml;
  };

  prepareHtmlForSet = (html: string): string => {
    const { toFroalaHtmlConverters } = this.props;

    if (toFroalaHtmlConverters != null) {
      let convertedHtml = html;

      toFroalaHtmlConverters.forEach(converter => {
        convertedHtml = converter(convertedHtml);
      });

      return convertedHtml;
    } else {
      return html;
    }
  };

  prepareHtmlForGet = (html: string): string => {
    const { fromFroalaHtmlConverters } = this.props;

    const cleanedHtml = cleanupHTML(html);

    if (fromFroalaHtmlConverters == null) {
      return cleanedHtml;
    } else {
      let html = cleanedHtml;

      fromFroalaHtmlConverters.forEach(converter => {
        html = converter(html);
      });

      return html;
    }
  };

  /**
   * Only pass the editor html to the prepareHtmlForGet function here
   * For testing purposes these have to be separated and to keep it as
   * close to the original as possible, do not add any logic here!
   */
  getHtml = () => {
    const editorHtml = this.editor.html.get();

    return this.prepareHtmlForGet(editorHtml);
  };

  getInlineImageEditorList = () => this.editor.dhInlineUploadImageList;

  getUploadingId = (): string => {
    if (this._currentUploadingIds.length === 0) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | getUploadingId has no id in the list!`,
      );
    }

    const first = this._currentUploadingIds.shift();

    return first as string;
  };

  isNotEditing = () => {
    if (this.editor.selection.inEditor()) {
      const selection = this.editor.selection.blocks();

      // froala hack #328
      // If not typing the selection seems to be empty. We also don't want to change anything if you do have a selection
      return selection.length === 0;
    }

    return true;
  };

  saveSelection = () => {
    if (!this.editor.selection.inEditor()) {
      /** The selections is outside of the editor, we do not save */
      return;
    }

    this.editor.selection.save();
  };

  showError = (message: string) => {
    this.setState({
      errorMessage: message,
    });
  };

  handleErrorClose = () => {
    this.setState({
      errorMessage: null,
    });
  };

  updateLoadingStyles = () => {
    const { html, hasChanged } = updateLoadingClasses(
      this.editor.html.get(),
      this.getInlineImageEditorList(),
    );

    if (hasChanged) {
      this.setHtml(html);
    }
  };

  // Kept it here to allow easier debugging when we get the next froala bug
  // logForDebug = () => {
  //   const html = this.editor.html.get();

  //   let debugstring = '';
  //   const imageList = this.getInlineImageEditorList();
  //   const imgTags = matchAll(html, /<img[^>]*?>/g);

  //   const formatS3Key = (key?: string | null) => {
  //     if (key == null) return 'NULL';

  //     const lastIndex = key.lastIndexOf('/');

  //     return '...' + key.substring(lastIndex, key.length);
  //   };

  //   imgTags.forEach(img => {
  //     const id = getAttributeValue('data-uploadid', img);

  //     const inList = imageList.find(image => image.id === id);
  //     let loadState = 'NOT IN LIST';
  //     if (inList != null) {
  //       if (inList.fileDetails) {
  //         loadState = 'LOADED | ' + formatS3Key(inList.fileDetails.s3key);
  //       } else {
  //         loadState = 'LOADING';
  //       }
  //     }

  //     debugstring = `${debugstring}\nImage: ${id || 'NULL'} | ${loadState}`;
  //   });

  //   debugstring =
  //     debugstring +
  //     '\n[' +
  //     this.editor.dhInlineUploadImageList.length +
  //     '] UPLOADS: [' +
  //     this.editor.dhInlineUploadImageList.map(
  //       image =>
  //         `${image.id}|${formatS3Key(image.fileDetails?.s3key)}|${
  //           image.fileDetails?.contentLength
  //         } , `,
  //     ) +
  //     ']';

  //   console.log(debugstring);
  // };

  openInsertHTMLModal = () => {
    this.editor.edit.off();
    this.setState({ showInsertHTMLModal: true });
  };

  closeInsertHTMLModal = () => {
    this.editor.edit.on();
    this.setState({ showInsertHTMLModal: false });
  };

  setEditor = (editor: any, userId: string) => {
    const { config } = this.state;
    const {
      onBlur,
      onChange,
      onExternalVariableAdded,
      registerInteractionFunctions,
      addFlowVariablePlugin,
      componentVariableUpdaterId,
    } = this.props;

    if (editor != null) {
      editor.dhValues = {
        userId,
      };

      editor.events.on('blur', () => {
        this._hasFocus = false;

        if (!addFlowVariablePlugin) {
          this.saveSelection();
        }

        this.updateLoadingStyles();

        if (onBlur) onBlur();
      });

      editor.events.on(
        'drop',
        event => {
          const imageTypes = config.imageAllowedTypes;
          const transferredFiles = event.originalEvent?.dataTransfer?.files;

          if (!transferredFiles || !transferredFiles[0]) {
            // if we cannot determine the file, then just let froala do its thing
            return true;
          }

          const file = transferredFiles[0];

          const fileType = getImageTypeFromFile(file);
          if (!imageTypes || !imageTypes.includes(fileType)) {
            // if images are not allowed then do not allow dragging in images
            event.preventDefault();
            return false;
          }

          // let it through
          return true;
        },
        // trigger before other callbacks to block default froala!
        true,
      );

      editor.events.on('paste.beforeCleanup', (clipboardHTML: string) => {
        // We always reupload images, so remove any information that we use to convert the html to backend code
        const result = removeInlineAttachmentInformation(clipboardHTML).replace(
          /data-uploadid="[^"]*"\s/g,
          '',
        );

        return result;
      });
      editor.events.on('paste.afterCleanup', (clipboardHTML: string) => {
        //https://www.froala.com/wysiwyg-editor/docs/events#paste.afterCleanup
        const withDraggableClassToVariables =
          addDraggableClassToVariables(clipboardHTML);
        const withChangedIds = changeMappingIdsForAnyVariables(
          withDraggableClassToVariables,
        );

        let finalHtml = withChangedIds;

        // only do this part if it is not only an image being pasted.
        // if just one image, froala triggers the image upload, if you copy with some other stuff this does NOT happen
        // The extra <br> is because chrome puts that after
        if (finalHtml.replace(/[\s]*<img[^>]*>[\s|<br>]*/g, '').length > 0) {
          finalHtml = updateInlineImageIdsForAnyImages(
            finalHtml,
            editor.dhRegisterNewInlineImage,
            editor.dhOnUploadSucceeded,
            userId,
          );
        }

        if (componentVariableUpdaterId != null) {
          if (
            hasVariableUpdaterIdsOtherThan(
              finalHtml,
              componentVariableUpdaterId,
            )
          ) {
            finalHtml = changeVariableUpdaterIds(
              finalHtml,
              componentVariableUpdaterId,
            );

            this.pastedExternalVariable = true;
          }
        }

        return finalHtml;
      });

      editor.events.on('paste.after', () => {
        if (this.pastedExternalVariable) {
          this.pastedExternalVariable = false;

          if (onExternalVariableAdded) onExternalVariableAdded();
        }
      });

      editor.events.on('contentChanged', () => {
        this.updateLoadingStyles();

        if (onChange) {
          onChange();
        }
      });

      editor.events.on('link.beforeInsert', function (this: any, link) {
        if (link === MORTGAGE_LINK_IN_FROALA) {
          return;
        }

        try {
          new URL(link);
        } catch (e) {
          this.showError(
            'De link lijkt ongeldig. Controleer de link op typfouten.',
          );
        }
      });

      if (componentVariableUpdaterId != null) {
        /**
         * If a variable is dropped into our editor, then make sure that it has the correct variable updater id
         * This event is from the draggable plugin of froala
         */
        editor.events.on('element.dropped', function (this: any, element) {
          if (element != null && element[0] != null) {
            const droppedElement = element[0];

            // It is possible to drag between multiple editors on the same page.
            // The editor that it is being dragged away from will be triggered with an undefined element, they do not need to do anything.
            // check the html and make sure our variableUpdaterId is correct
            if (droppedElement.getAttribute('dhvariable') === 'true') {
              if (
                !droppedElement
                  .getAttribute('onclick')
                  .includes(componentVariableUpdaterId)
              ) {
                this.html.set(
                  changeVariableUpdaterIds(
                    this.html.get(),
                    componentVariableUpdaterId,
                  ),
                );

                if (onExternalVariableAdded) onExternalVariableAdded();
              }
            }
          }
        });
      }

      if (addFlowVariablePlugin != null) {
        editor.addFlowVariablePlugin = (element: HTMLElement) => {
          this.saveSelection();

          addFlowVariablePlugin.onVariableAdd(element);
        };
      }

      editor.onOpenInsertHTMLCommand = () => {
        this.saveSelection();
        this.openInsertHTMLModal();
      };

      editor.onHTMLInsert = html => {
        this.closeInsertHTMLModal();
        editor.html.insert(html);
        editor.undo.saveStep();
      };

      /** For our inline image uploads */
      editor.dhInlineUploadImageList = [];
      // editor.dhInlineFroalaBlobList = [];
      editor.dhRegisterNewInlineImage = (): string => {
        const id = idGenerator.generateUuid();

        this.editor.dhInlineUploadImageList.push({ id });
        this._currentUploadingIds.push(id);

        return id;
      };
      editor.dhOnUploadSucceeded = (
        id: string,
        fileDetails: UploadImageReturnType,
      ) => {
        const idx = this.editor.dhInlineUploadImageList.findIndex(
          image => image.id === id,
        );

        if (idx < 0) {
          throw Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | >>dhOnUploadSucceeded for an id (${id}) that is not in the list (${
              this.editor.dhInlineUploadImageList
            })`,
          );
        }

        const newList = [...this.editor.dhInlineUploadImageList];
        newList[idx] = { ...newList[idx], fileDetails };

        this.editor.dhInlineUploadImageList = newList;

        this.updateLoadingStyles();
        this.props.onChange && this.props.onChange();
      };
      editor.getUploadingId = (): string => this.getUploadingId();
      editor.markLastUploadAsFailed = () => {
        const lastId = this.getUploadingId();

        this.editor.dhInlineUploadImageList =
          this.editor.dhInlineUploadImageList.filter(
            list => list.id !== lastId,
          );
      };

      // add our own error handling
      editor.showError = (message: string) => {
        this.showError(message);
      };

      this.editor = editor;

      if (registerInteractionFunctions) {
        registerInteractionFunctions({
          insertHtml: this.insertHtml,
          setHtml: this.setHtml,
          getHtml: this.getHtml,
          getInlineImageEditorList: this.getInlineImageEditorList,
        });
      }
    }
  };

  onManualControllerReady = async (initControls: any, userId: string) => {
    initControls.initialize();

    const editor = await getEditorRec(initControls);
    this.setEditor(editor, userId);
    this.setReadOnly();
  };

  getInitialHtml = (): string => this.prepareHtmlForSet(this.props.initialHtml);

  render() {
    const { config, errorMessage, showInsertHTMLModal } = this.state;
    const { hasError, isHiddenFromView } = this.props;

    return (
      <AccountContext.Consumer>
        {({ me }) => {
          if (me == null) {
            throw Error(
              `${cleanedFilename(__filename)} | Should not occur | me == null`,
            );
          }

          return (
            <>
              <div
                data-testid={TEST_ID.CONTAINER}
                className={hasError ? errorEditorClass : ''}
                style={{
                  width: '100%',
                  visibility: isHiddenFromView ? 'hidden' : 'inherit',
                }}
              >
                <FroalaEditorComponent
                  // tag="textarea" https://github.com/froala/react-froala-wysiwyg/issues/196
                  config={config}
                  model={this.getInitialHtml()}
                  onManualControllerReady={initControls =>
                    this.onManualControllerReady(initControls, me.id)
                  }
                />
              </div>
              {showInsertHTMLModal && (
                <InsertHTMLModal
                  onClose={this.closeInsertHTMLModal}
                  onSubmit={this.editor.onHTMLInsert}
                />
              )}

              {errorMessage != null && errorMessage != '' ? (
                <ErrorModal
                  title={text.errorTitle}
                  message={errorMessage}
                  onClose={this.handleErrorClose}
                />
              ) : null}
            </>
          );
        }}
      </AccountContext.Consumer>
    );
  }
}

const getConfig = (props: Props) => {
  const {
    getExtraOptions,
    hideToolbar,
    textarea,
    addFlowVariablePlugin,
    singleLine,
  } = props;

  const config: $Object = {
    ...getOptions(),
    ...(getExtraOptions == null ? {} : getExtraOptions()),
  };

  if (hideToolbar === true) {
    config.toolbarContainer = '#hiddenDiv';
    config.heightMin = undefined;
    config.editorClass = `${config.editorClass || ''} dh-inline`;
    config.quickInsertEnabled = false;
  }

  if (textarea === true) {
    config.editorClass = `${config.editorClass || ''} dh-inline-textarea`;
  }

  if (singleLine === true) {
    config.multiLine = false;
  }

  if (Array.isArray(config.linkList) && config.linkList.length === 0) {
    config.editorClass = `${config.editorClass || ''} dh-link-list-empty`;
  }

  if (addFlowVariablePlugin != null) {
    config.toolbarButtons = {
      ...config.toolbarButtons,
      moreRich: {
        ...config.toolbarButtons.moreRich,
        buttons: deduplicated(
          ['addFlowVariablePlugin', ...config.toolbarButtons.moreRich.buttons],
          a => a,
        ),
      },
    };
  }

  return config;
};

export default HTMLEditor;
