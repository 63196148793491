import React, { useCallback, useContext } from 'react';
import GatsbyLink, { GatsbyLinkProps } from 'gatsby-link';
import styled, { css } from 'styled-components';
import RouterTransitionContext from '~/contexts/RouterTransitionContext';
import { useLocation } from '@reach/router';
import { isString } from 'lodash';
import { omit } from 'ramda';

export type Props = {
  'data-testid'?: string;
  dataTestid?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  to?: string;
} & GatsbyLinkProps<any>;

const getActiveClassName = (pathname: string, to: string): string => {
  if (to !== '/' && to !== '/-/') return 'active';
  if (pathname === '/' || (pathname === '/-/' && (to === '/' || to === '/-/')))
    return 'active';

  return '';
};

const isExternalURL = (url: string, currentHost: string) => {
  if (!isString(url)) return false;
  if (url.startsWith('http')) return new URL(url).host !== currentHost;

  return false;
};

export const ExternalLink: React.FC<Omit<Props, 'to'> & { to?: string }> = ({
  onClick,
  dataTestid,
  to,
  children,
  ...props
}) => (
  <Anchor
    href={to}
    {...omit(['ref'], props)}
    data-testid={dataTestid}
    onClick={onClick}
  >
    {children}
  </Anchor>
);

const InternalLink: React.FC<Props> = ({
  onClick,
  dataTestid,
  to,
  children,
  ...props
}) => {
  const location = useLocation();
  const dataTestId = dataTestid ?? props['data-testid'];

  return (
    <StyledGatsbyLink
      to={to}
      data-testid={dataTestId}
      activeClassName={getActiveClassName(location.pathname, to)}
      partiallyActive
      onClick={onClick}
      {...omit(['ref'], props)}
    >
      {children}
    </StyledGatsbyLink>
  );
};

export type LinkProps = {
  'data-testid'?: string;
  dataTestid?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
} & GatsbyLinkProps<any>;

export const Link: React.FC<LinkProps> = ({ onClick, to, ...props }) => {
  const { host } = useLocation();
  const { canLeave } = useContext(RouterTransitionContext);
  const clickHandler = useCallback(
    event => {
      if (!canLeave) {
        const userConfirmedLeave = global.window.confirm(
          'Let op: openstaande wijzigingen',
        );
        if (!userConfirmedLeave) event.preventDefault();
      }
      if (onClick) return onClick(event);
    },
    [canLeave, onClick],
  );

  const C = isExternalURL(to, host) ? ExternalLink : InternalLink;

  return <C {...props} to={to} onClick={clickHandler} />;
};

export const linkStyles = ({ theme }) => css`
  color: ${theme.color('primary', 'light')};
  text-decoration: underline;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    color: ${theme.color('primary')};
  }
`;

const StyledGatsbyLink = styled(GatsbyLink)<{}>(linkStyles);
const Anchor = styled.a<{}>(linkStyles);

/**
 * A link component without any link styling applied.
 */
export const NeutralLink = styled(Link)<{}>(
  ({ theme }) => css`
    color: ${theme.color('grey')};
    text-decoration: none;

    &:hover {
      color: ${theme.color('grey')};
    }
  `,
);

export default Link;
