import gql from 'graphql-tag';

import ConnectedEmailAliasFields from './ConnectedEmailAliasFields';

export default gql`
  fragment UserMailboxAliasFields on UserMailboxAlias {
    __typename
    userId
    email {
      ...ConnectedEmailAliasFields
    }
  }

  ${ConnectedEmailAliasFields}
`;
