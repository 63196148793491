import React from 'react';
import { WithNavigationFrame as NotFound } from '~/components/404';
import { Redirect, RouteComponentProps, navigate } from '@reach/router';
import Flows from './Flows/';
import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/scenes/ErrorScreen';
import Catalog from '~/Catalog';
import Wizard from './v2/components/Wizard';
import DHRouter from '~/components/DHRouter';

type Props = RouteComponentProps;
const Automation: React.FC<Props> = () => (
  <>
    <DHRouter>
      <Redirect from="/" to="flows" noThrow />
      <Flows path="/flows/*" />
      <Wizard
        path="/flows/wizard/v1/*"
        onClose={() => {
          void navigate('/-/automation/flows');
        }}
      />
      <Wizard
        path="/flows/wizard/v2/*"
        onClose={() => {
          void navigate('/-/automation/flows');
        }}
      />
      <NotFound default />
    </DHRouter>
  </>
);

export default withErrorBoundary<Props>(
  Automation,
  <ErrorScreen explanation={Catalog.genericUnknownErrorMessage} />,
);
