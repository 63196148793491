import React, { useState } from 'react';
import {
  RealworksTokenStatus,
  useGetAppStatusRealworksQuery,
  AppStatus_Realworks_RealworksTokenContainer,
  useSendContactToRealworksMutation,
} from '~/graphql/types';

import FoldableCardBlock from '../FoldableCardBlock';
import { useEffect } from 'react';
import Button from '~/components/Button';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import findClosestActiveRealworksTokenContainer from '~/scenes/Apps/Realworks/findClosestActiveRealworksTokenContainer';
import Dropdown from '~/components/Inputs/Dropdown';
import InputGroup from '~/components/Inputs/InputGroup';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import TEST_ID from './index.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';

export const text = {
  sendContactToRealworksDropdownLabel: 'Vestiging',
  sendContactToRealworks: 'Verstuur naar Realworks',
  sendContactToRealworksSubmitLabel: 'Versturen',
  sendContactToRealworksSuccess: 'Contact verzonden',
  sendContactToRealworksFailure: 'Verzenden mislukt',
};
type Props = {
  officeId: string | null | undefined;
  userId: string | null | undefined;
  contactId: string;
};
const RealworksSendContactBlock: React.FC<Props> = ({
  contactId,
  officeId,
  userId,
}) => {
  const addToast = useAddToast();
  const account = useCurrentAccount();

  const [selectedTokenContainerId, setSelectedTokenContainerId] = useState<
    string | null
  >(null);

  const {
    data: getAppStatusRealworksData,
    loading: getAppStatusRealworksLoading,
    error: getAppStatusRealworksError,
    networkStatus: getAppStatusRealworksNetworkStatus,
  } = useGetAppStatusRealworksQuery({
    variables: { accountId: account.id },
    displayName: __filename,
  });

  const [activeRealworksWonenTokens, setActiveRealworksWonenTokens] = useState<
    Array<AppStatus_Realworks_RealworksTokenContainer>
  >([]);

  const [
    sendContactToRealworks,
    {
      error: sendContactToRealworksError,
      loading: sendContactToRealworksLoading,
    },
  ] = useSendContactToRealworksMutation({
    onCompleted: data => {
      if (!data) return;
      addToast([
        formatToastMessage(text.sendContactToRealworksSuccess, 'success'),
      ]);
    },
  });
  useEffect(() => {
    if (!sendContactToRealworksError) return;
    addToast([
      formatToastMessage(text.sendContactToRealworksFailure, 'danger'),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendContactToRealworksError]);

  const realworksStatus = getAppStatusRealworksData?.getAppStatusRealworks;

  useEffect(() => {
    if (!realworksStatus?.enabled) return;
    const activeTokens = realworksStatus.tokens.filter(
      token => token.wonen?.status === RealworksTokenStatus.Active,
    );
    setActiveRealworksWonenTokens(activeTokens);
  }, [realworksStatus]);

  useEffect(() => {
    if (!realworksStatus?.enabled) return;

    if (selectedTokenContainerId == null) {
      const closestActiveTokenContainer =
        findClosestActiveRealworksTokenContainer(realworksStatus.tokens ?? [], {
          accountId: account.id,
          officeId,
          userId,
        });
      if (closestActiveTokenContainer) {
        setSelectedTokenContainerId(closestActiveTokenContainer.id);
      }
    }
  }, [account.id, realworksStatus, officeId, selectedTokenContainerId, userId]);

  const activeTokensOptions = activeRealworksWonenTokens.map(activeToken => ({
    key: activeToken.id,
    label: activeToken.name,
    payload: activeToken.id,
  }));

  const selectedActiveTokenOptionKey = selectedTokenContainerId ?? null;
  const selectedActiveTokenOption = activeTokensOptions.findIndex(
    option => option.payload === selectedActiveTokenOptionKey,
  );

  if (
    !getAppStatusRealworksLoading &&
    (!realworksStatus?.enabled || activeRealworksWonenTokens.length === 0)
  ) {
    return null;
  }
  return (
    <FoldableCardBlock
      icon="GEAR"
      title={text.sendContactToRealworks}
      loading={getAppStatusRealworksLoading}
      dataTestId="card-block-sendcontact-to-realworks"
    >
      <InputGroup>
        <Dropdown
          options={activeTokensOptions}
          onChange={({ option }) => {
            setSelectedTokenContainerId(option.payload);
          }}
          selectedOptionIdx={selectedActiveTokenOption}
          error={getAppStatusRealworksError?.message ?? ''}
          loading={getAppStatusRealworksLoading}
          disabled={
            !!getAppStatusRealworksError || sendContactToRealworksLoading
          }
          dataTestid={TEST_ID.REALWORKS_ACTIVE_TOKENS_DROPDOWN}
          hideDropdownList={getAppStatusRealworksLoading}
          label={text.sendContactToRealworksDropdownLabel}
          hideLabelOnTop={
            selectedTokenContainerId != null &&
            isInitialLoadStatus(getAppStatusRealworksNetworkStatus)
          }
        />
      </InputGroup>
      <Button
        size="medium"
        onClick={() =>
          sendContactToRealworks({
            variables: {
              accountId: account.id,
              contactId,
              // since the button is disabled when selectedTokenContainerId == null, this will be a string at this point
              tokenContainerId: selectedTokenContainerId as string,
            },
          })
        }
        appearance="secondary"
        loading={sendContactToRealworksLoading}
        disabled={
          sendContactToRealworksLoading || selectedTokenContainerId == null
        }
        data-testid="send-contact-to-realworks-submit"
        label={text.sendContactToRealworksSubmitLabel}
      />
    </FoldableCardBlock>
  );
};

export default RealworksSendContactBlock;
