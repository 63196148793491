import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import React from 'react';
import Button from '~/components/Button';
import JustificationContainer from '~/components/JustificationContainer';
import TextButton from '~/components/TextButton';
import { Body } from '~/components/Typography/index';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import AppDetailsContainer from '~/scenes/Apps/components/AppDetailsContainer';
import { LINK_PREFIX } from '~/scenes/Apps/Zapier/components/Wizard/index';

import TEST_ID from './index.testid';
import styled from 'styled-components';
import { useGetZapierApiKeysQuery } from '~/graphql/types';
import ApiKeySuccess from './components/ApiKeySuccess';
import GetApiKey from './components/GetApiKey';
import { Loading } from '~/components';
import { isNil } from 'ramda';

export const text = {
  header: 'Verbinden met Zapier',
  pageDescription:
    'Koppel jouw Zapier account aan DatHuis. Heb je nog geen Zapier account? Maak deze dan eerst aan op Zapier.com en kom hier terug.',
  codeLabel: '1. Kopieer onze API key',
  secondStep: '2. Vul deze in bij Zapier',
  path: ['Ga naar My Apps', 'Klik “Add connection”', 'Zoek op “DatHuis”'],
  pathLabel: 'Ga naar “My Apps” in Zapier',
  link: 'https://zapier.com/app/connections',
  exitButton: 'Afbreken',
  nextButton: 'Gegevens versturen',
  validateButton: 'API key bevestigen',
  errorMessage:
    'De API key wordt gecontroleerd. Ververs deze pagina om te controleren of deze is geaccepteerd.',
};

export type StepProps = {
  onClose: () => false | void | undefined;
  disableClosing?: boolean;
} & RouteComponentProps;

const Connect: React.FC<StepProps> = ({ onClose, disableClosing = false }) => {
  const { id: accountId } = useCurrentAccount();
  const { data, loading, refetch } = useGetZapierApiKeysQuery({
    variables: {
      accountId: accountId,
    },
  });

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  const zapierApiKey = data?.getZapierApiKeys[0]?.apiKey;
  const isConnected = data?.getZapierApiKeys[0]?.lastConnectedAt;

  return (
    <AppDetailsContainer
      header={text.header}
      icon="link"
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body margin={[null, null, 'l', null]}>{text.pageDescription}</Body>

      {isConnected ? (
        <ApiKeySuccess />
      ) : (
        <GetApiKey zapierApiKey={zapierApiKey ?? text.errorMessage} />
      )}

      <JustificationContainer justification="space-between" align="center">
        {!disableClosing && !isConnected && (
          <TextButton
            label={text.exitButton}
            onClick={onClose}
            withoutPadding
            appearance="danger"
            dataTestId="exit-wizard-button"
          />
        )}
        {!isConnected ? (
          <Button
            disabled={isNil(zapierApiKey)}
            icon="check"
            loading={loading}
            label={text.validateButton}
            onClick={() => refetch()}
            size="medium"
            appearance="secondary"
            dataTestId={TEST_ID.VALIDATE_BUTTON}
          />
        ) : (
          <Button
            icon="arrowRight"
            label={text.nextButton}
            onClick={() =>
              navigate(`${LINK_PREFIX}/add-zapier-trigger`, { replace: true })
            }
            size="medium"
            dataTestId={TEST_ID.NEXT_BUTTON}
          />
        )}
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

const LoadingContainer = styled.div<{}>`
  display: flex;
`;

export default Connect;
