import gql from 'graphql-tag';

export default gql`
  fragment SubscriptionFields on RunningSubscription {
    __typename

    ... on Subscription_External {
      cancelled
      cancellationDate
    }
    ... on Subscription_Stripe {
      runningPlan {
        plan
        billingCycle
      }
      cancelled
      cancellationDate
      nextPlan
      nextBillingCycle
      nextPlanStart
    }
  }
`;
