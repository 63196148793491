import type { TableHeaderDefinitionCellType } from '../../types.flow';

import cleanedFilename from '~/util/cleanedFilename';

export const CHECKBOX_COLUMN_ACCESSOR = 'checkbox-column';
type CheckboxHeaderCellOptions = {
  dataTestid?: string;
};
const CHECKBOX_COLUMN_WIDTH = 23;
const asCheckboxTableHeaderCell = ({
  dataTestid,
}: CheckboxHeaderCellOptions): TableHeaderDefinitionCellType<any> => ({
  Cell: () => {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} Should not occur! This cell needs to be rendered manually in the table, so instead of calling the Cell property on this column, a custom rendering needs to happen.`,
    );
  },
  // @ts-ignore
  Header: () => {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} Should not occur! This header needs to be rendered manually in the table, so instead of calling the Header property on this column, a custom rendering needs to happen.`,
    );
  },
  accessor: CHECKBOX_COLUMN_ACCESSOR,
  dataTestid,
  disableResizing: true,
  disableSortBy: true,
  minWidth: CHECKBOX_COLUMN_WIDTH,
  maxWidth: CHECKBOX_COLUMN_WIDTH,
  width: CHECKBOX_COLUMN_WIDTH,
  hideTooltip: true,
});

export default asCheckboxTableHeaderCell;
