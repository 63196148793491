import React from 'react';
import MasonryCard from '~/components/MasonryCard';

/**
 *
 * @param WrappedComponent
 * @param opts options
 *
 * Wraps the given component with the Masonry Card component
 */
const withMasonryCard =
  (
    WrappedComponent: typeof React.Component | React.FC,
    opts: { containerTestId?: string },
  ) =>
  props =>
    (
      <MasonryCard dataTestId={opts.containerTestId}>
        <WrappedComponent {...props} />
      </MasonryCard>
    );

export default withMasonryCard;
