import React from 'react';
import { Props } from '../';

const ARXGroepEmblem: React.FC<Props> = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    id="Laag_1"
    width="100%"
    height="100%"
    viewBox="0 0 81 81"
  >
    <g>
      <g>
        <g>
          <g>
            <circle fill="#36A9E1" cx="41.28" cy="40.97" r="35.43" />
          </g>
        </g>
      </g>
      <g>
        <path
          fill="none"
          d="M37.44,59.8h0.58c0.1,0,0.2,0.01,0.29,0.04h5.92c0.09-0.02,0.19-0.04,0.29-0.04h0.58    c0.48,0,0.9-0.27,1.12-0.65h-9.93C36.55,59.54,36.96,59.8,37.44,59.8z"
        />
        <path
          fill="none"
          d="M37.01,34.45c-0.7,0-1.27,0.57-1.27,1.27s0.57,1.27,1.27,1.27h1.27v-1.27    C38.28,35.02,37.71,34.45,37.01,34.45z"
        />
        <path
          fill="none"
          d="M46.83,35.72c0-0.7-0.57-1.27-1.27-1.27c-0.7,0-1.27,0.57-1.27,1.27v1.27h1.27    C46.26,36.99,46.83,36.42,46.83,35.72z"
        />
        <path
          fill="none"
          d="M41.28,62.81c0.75,0,1.39-0.5,1.72-1.02h-3.44C39.9,62.32,40.54,62.81,41.28,62.81z"
        />
        <rect x="40.23" y="38.94" fill="none" width="2.1" height="12.4" />
        <rect x="36.14" y="56.22" fill="none" width="10.29" height="0.98" />
        <rect x="36.14" y="53.29" fill="none" width="10.29" height="0.98" />
        <path
          fill="#18466B"
          d="M44.29,51.34h-1.95h-2.1h-1.95h-2.19h-2.6c0.03,0.32,0.05,0.65,0.05,0.97v6.18c0,1.99,1.5,3.64,3.43,3.87    c0.6,1.61,2.25,3.04,4.32,3.04s3.72-1.43,4.32-3.04c1.93-0.24,3.43-1.88,3.43-3.87v-6.18c0-0.33,0.02-0.65,0.05-0.97h-2.6H44.29z     M41.28,62.81c-0.75,0-1.39-0.5-1.72-1.02H43C42.67,62.32,42.03,62.81,41.28,62.81z M45.12,59.8h-0.58c-0.1,0-0.2,0.01-0.29,0.04    h-5.92c-0.09-0.02-0.19-0.04-0.29-0.04h-0.58c-0.48,0-0.9-0.27-1.12-0.65h9.93C46.02,59.54,45.61,59.8,45.12,59.8z M46.43,57.2    H36.14v-0.98h10.29V57.2z M46.43,54.27H36.14v-0.98h10.29V54.27z"
        />
        <path
          fill="#FFFFFF"
          d="M57.13,32.22c-0.69-7.68-7.02-13.88-14.72-14.41c-0.76-0.05-1.5-0.05-2.25,0    c-7.7,0.53-14.03,6.73-14.72,14.41c-0.44,4.97,1.39,9.75,5.03,13.13c1.71,1.59,2.78,3.73,3.02,6h2.6    c-0.25-2.99-1.61-5.83-3.86-7.9c-3.04-2.82-4.58-6.83-4.21-10.99c0.58-6.42,5.87-11.6,12.31-12.05c0.32-0.02,0.63-0.03,0.95-0.03    c0.31,0,0.63,0.01,0.95,0.03c6.44,0.44,11.73,5.62,12.31,12.05c0.37,4.16-1.16,8.16-4.21,10.99c-2.24,2.08-3.61,4.92-3.86,7.9h2.6    c0.24-2.27,1.31-4.41,3.02-6C55.74,41.97,57.57,37.18,57.13,32.22z"
        />
      </g>
      <g>
        <path
          style={{ fill: 'none', stroke: '#F6DB53', strokeMiterlimit: 10 }}
          d="M37.95,51.34l0.18-19.06c0,0-0.03-2.3-2.31-2.3s-2.41,2.3-2.41,2.3s0.03,2.36,2.36,2.36c2.33,0,7.14,0,7.14,0    "
        />
        <path
          style={{ fill: 'none', stroke: '#F6DB53', strokeMiterlimit: 10 }}
          d="M44.62,51.34l-0.18-15.62c0,0,0.03-5.74,2.31-5.74c2.28,0,2.41,2.3,2.41,2.3s-0.03,2.36-2.36,2.36    c-2.33,0-7.14,0-7.14,0"
        />
      </g>
    </g>
  </svg>
);

export default ARXGroepEmblem;
