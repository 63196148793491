import React from 'react';
import styled from 'styled-components';

import {
  Form,
  Field,
  NumberField,
  PostalCodeField,
  EmailField,
} from '~/components/Forms';
import { FormUtils } from '~/components';
import { Input, InputGroup } from '~/components/Inputs';
import Validation from '~/util/Validation';
import objectDiff from '~/util/objectDiff';
import Catalog from '~/Catalog';
import DetailsBlock from '~/components/DetailsBlock';
import FormSaveBar from '~/components/SaveBar/FormSaveBar';

const text = {
  phoneValidation: Catalog.invalidPhone,
  postcodeValidation: Catalog.invalidPostalCode,
  emailValidation: Catalog.invalidEmail,
  emailLabel: Catalog.emailLabel,
  phoneLabel: Catalog.phoneLabel,
  addressLabel: Catalog.addressLabel,
  houseNumberLabel: Catalog.houseNumberLabel,
  houseAdditionLabel: Catalog.houseAdditionLabel,
  postalCodeLabel: Catalog.postalCodeLabel,
  cityLabel: Catalog.cityLabel,
  noAddress: Catalog.noAddress,
  noPostalCode: Catalog.noPostalCode,
  noCity: Catalog.noCity,
  noHouseNumber: Catalog.noHouseNumber,
  noEmail: Catalog.noEmail,
  noPhone: Catalog.noPhone,
};
export type FormData = {
  email: string | null;
  phone: string | null;
  street: string | null;
  houseNumber: number | null;
  addition: (string | null | undefined) | null;
  postcode: string | null;
  city: string | null;
};

type Props = {
  loading: boolean;
  isUserAdmin: boolean;
  initialValues: FormData;
  onSubmitChanges: (changedFields: $Object) => any;
};
const OfficeDetailForm = ({
  onSubmitChanges,
  initialValues,
  isUserAdmin,
  loading,
}: Props) => (
  <Form
    onSubmit={(fields: FormData) => {
      const changed = objectDiff(initialValues, fields);

      if (changed != null) {
        onSubmitChanges(changed);
      }
    }}
    validate={validate}
    initialValues={initialValues}
  >
    {({ handleSubmit, submitError, submitting }) => (
      <form
        id="office-detail-form"
        data-testid="office-detail-form"
        onSubmit={handleSubmit}
      >
        {submitError ? (
          <div data-testid="office-error-message">{submitError}</div>
        ) : null}
        <DetailsBlock>
          <MarginlessInputGroup>
            <EmailField name="email">
              {({ input, meta: { error, touched } }) => (
                <Input
                  large
                  label={text.emailLabel}
                  error={FormUtils.showError(error, touched)}
                  disabled={submitting || !isUserAdmin}
                  {...input}
                />
              )}
            </EmailField>
          </MarginlessInputGroup>
          <InputGroup>
            <Field name="phone">
              {({ input, meta: { error, touched } }) => (
                <Input
                  large
                  label={text.phoneLabel}
                  error={FormUtils.showError(error, touched)}
                  disabled={submitting || !isUserAdmin}
                  {...input}
                  data-testid="user-phone-input"
                />
              )}
            </Field>
          </InputGroup>

          <InputGroup>
            <Field name="street">
              {({ input, meta: { error, touched } }) => (
                <Input
                  large
                  label={text.addressLabel}
                  type="text"
                  error={FormUtils.showError(error, touched)}
                  disabled={submitting || !isUserAdmin}
                  {...input}
                />
              )}
            </Field>
          </InputGroup>
          <InputGroup>
            <NumberField name="houseNumber">
              {({ input, meta: { error, touched } }) => (
                <Input
                  large
                  label={text.houseNumberLabel}
                  type="text"
                  error={FormUtils.showError(error, touched)}
                  disabled={submitting || !isUserAdmin}
                  {...input}
                />
              )}
            </NumberField>

            <Field name="addition">
              {({ input, meta: { error, touched } }) => (
                <Input
                  large
                  label={text.houseAdditionLabel}
                  type="text"
                  error={FormUtils.showError(error, touched)}
                  disabled={submitting || !isUserAdmin}
                  {...input}
                />
              )}
            </Field>
          </InputGroup>
          <InputGroup>
            <PostalCodeField name="postcode">
              {({ input, meta: { error, touched } }) => (
                <Input
                  large
                  label={text.postalCodeLabel}
                  type="text"
                  error={FormUtils.showError(error, touched)}
                  disabled={submitting || !isUserAdmin}
                  {...input}
                />
              )}
            </PostalCodeField>

            <Field name="city">
              {({ input, meta: { error, touched } }) => (
                <Input
                  large
                  label={text.cityLabel}
                  type="text"
                  error={FormUtils.showError(error, touched)}
                  disabled={submitting || !isUserAdmin}
                  {...input}
                />
              )}
            </Field>
          </InputGroup>

          <FormSaveBar disableSave={loading || submitting} inBlockComponent />
        </DetailsBlock>
      </form>
    )}
  </Form>
);

export const validate = (fields: FormData) => {
  const errors: {
    email: string | undefined;
    phone: string | undefined;
    street: string | undefined;
    houseNumber: number | undefined;
    addition: string | undefined;
    postcode: string | undefined;
    city: string | undefined;
  } = {
    email: undefined,
    phone: undefined,
    street: undefined,
    houseNumber: undefined,
    addition: undefined,
    postcode: undefined,
    city: undefined,
  };

  if (Validation.String.isEmpty(fields['email'])) {
    errors.email = text.noEmail;
  }

  if (Validation.String.isEmpty(fields['phone'])) {
    errors.phone = text.noPhone;
  }

  if (
    Validation.String.isNonEmptyString(fields['phone']) &&
    !Validation.Phone.isValid(fields['phone'])
  ) {
    errors.phone = text.phoneValidation;
  }
  if (
    Validation.String.isNonEmptyString(fields['postcode']) &&
    !Validation.Postcode.isValid(fields['postcode'])
  ) {
    errors.postcode = text.postcodeValidation;
  }
  if (
    Validation.String.isNonEmptyString(fields['email']) &&
    !Validation.Email.isValid(fields['email'])
  ) {
    errors.email = text.emailValidation;
  }

  return errors;
};

const MarginlessInputGroup = styled(InputGroup)<{}>`
  margin: 0;
`;

export default OfficeDetailForm;
