import { FlowConditionValuationRequestDetails } from './types.flow';

import { emptyValuationRequestEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionValuationRequestDetails = (): Omit<
  FlowConditionValuationRequestDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.APPVALUATIONREQUEST_DETAILS.type,
  fields: [],
  event: emptyValuationRequestEventParameterValue(),
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
});

export default emptyFlowConditionValuationRequestDetails;
