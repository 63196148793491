import { clone, equals, filter, pick } from 'ramda';
import React, { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import Button from '~/components/Button';
import JustificationContainer from '~/components/JustificationContainer';
import FormContainer from '~/components/ModalsV2/FormContainer';
import { withOverlay } from '~/components/ModalsV2/Overlay';
import TextButton from '~/components/TextButton';
import TEST_ID from './index.testid';

import ActionForm from './components/ActionForm';
import { ClientFlowAction } from '~/graphql/types.client';
import flowIssues from '../../state/flowIssues';
import getIssuesForAction from './utils/getIssuesForAction';
import { ActionContext } from '~/scenes/Automation/v2/components/Builder/hooks/useActionContext';
import serializeEditorValues from './utils/serializeEditorValues';
import flowChanges from '../../state/flowChanges';
import useBuilderContext from '../Builder/hooks/useBuilderContext';
import useRelativeMaps from '../Builder/hooks/useRelativeMaps';
import metadata from '../../state/metadata';
import { FlowV2_Update_Metadata } from '~/graphql/types';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import { getFlowActionToClientAction } from '~/scenes/Automation/v2/util/getFlowActionsToClientActions';
import useOffices from '~/hooks/useOffices';
import useUsers from '~/hooks/useUsers';

export type Props = {
  action: ClientFlowAction;
  onConfirm: (updatedAction: ClientFlowAction) => void;

  /**
   * Fired when user clicks cancel or close.
   */
  onClose?: () => void;

  /** we provide a root for the Portal in overlay component if we want to portal to a specific root element */
  root?: string;
};

const actionHeaderMap: Record<ClientFlowAction['__typename'], string> =
  Object.freeze({
    FlowV2_Action_Start: 'Start deze flow...',
    FlowV2_Action_Task_Create: 'Taak aanmaken',
    FlowV2_Action_SendEmail_Plain: 'Email versturen',
    FlowV2_Action_Contact_AddTag: 'Tag toevoegen',
    FlowV2_Action_Contact_DeleteTag: 'Tag verwijderen',
    FlowV2_Action_Wait: 'Wacht tot...',
    FlowV2_Action_IfElse: 'Controleer of...',
    FlowV2_Action_Contact_Details: 'Contact wijzigen',
    FlowV2_Action_Contact_Assign: 'Wijs contact toe aan...',
    FlowV2_Action_Realworks_SendContact: 'Verstuur naar Realworks',
    FlowV2_Action_Zapier_Trigger: 'Verstuur naar Zapier',
  });

const text = {
  unknown: 'Onbekend',
  cancel: 'Annuleren',
  save: 'Opslaan',
};

const UpdateAction: React.FC<Props> = ({ action, onClose, onConfirm }) => {
  const [changes, setChanges] = useState<ClientFlowAction>(action);
  const setIssues = useSetRecoilState(flowIssues);
  const setFlowHasChanges = useSetRecoilState(flowChanges);
  const setFlowMetadata = useSetRecoilState(metadata);

  const relativeMaps = useRelativeMaps({
    actionId: action.id,
    addAllParentsAsInstances: true,
  });

  const { initialFlow, availableActions } = useBuilderContext();

  const onChange = useCallback((changes: ClientFlowAction) => {
    setChanges(changes);
  }, []);

  const offices = useOffices({ onlyExistingOffices: false });
  const users = useUsers();

  const onConfirmInternal = useCallback(() => {
    const serializedChanges = serializeEditorValues(changes);
    const issues = getIssuesForAction({
      action: serializedChanges,
      relativeMaps,
      availableActions,
      opts: { offices, users },
    });
    setFlowHasChanges(prev => {
      const initialAction = initialFlow.actions.find(a => a.id === action.id);
      if (!initialAction) return prev;

      const clientAction = getFlowActionToClientAction(initialAction);
      if (!clientAction) return prev;

      // Only compare the common properties
      const keys = Object.keys(clientAction);
      const updatedActionWithCommonFields = pick(keys, serializedChanges);
      const hasChanged = !equals(clientAction, updatedActionWithCommonFields);

      const flowChanges = clone(prev);
      flowChanges.actions[action.id] = hasChanged;
      return flowChanges;
    });

    setIssues(prev => {
      const nextIssues = clone(prev);
      nextIssues[action.id] = issues;

      return nextIssues;
    });

    // When we are updating an existing (previously saved) Wait action set metadata accordingly
    if (
      changes?.__typename === 'FlowV2_Action_Wait' &&
      initialFlow.actions.find(act => act.id === serializedChanges.id)
    ) {
      if (changes.metadataAction) {
        setFlowMetadata(prev => [
          ...prev,
          {
            flowBlueprintActionId: action.id,
            action: changes.metadataAction,
          } as FlowV2_Update_Metadata,
        ]);
      } else {
        setFlowMetadata(prev =>
          filter(m => m.flowBlueprintActionId !== serializedChanges.id, prev),
        );
      }
    }

    onConfirm(serializedChanges);
    onClose && onClose();
  }, [
    action.id,
    availableActions,
    changes,
    initialFlow.actions,
    onClose,
    onConfirm,
    relativeMaps,
    setFlowHasChanges,
    setIssues,
    setFlowMetadata,
    offices,
    users,
  ]);

  useGlobalKeyBinding({
    keys: 'esc',
    enabled: true,
    callback: () => {
      onConfirmInternal();
      onClose && onClose();
    },
  });

  return (
    <ActionContext.Provider
      value={{ actionId: action.id, actionType: action.actionType }}
    >
      {/* Additional container is needed to center the contents */}
      <Container
        dataTestId={TEST_ID.CONTAINER}
        justification="center"
        align="center"
      >
        <FormContainer
          header={actionHeaderMap[action.__typename] || text.unknown}
          onClose={() => {
            onConfirmInternal();
            onClose && onClose();
          }}
        >
          <FormContainerInner>
            <ActionForm action={action} onChange={onChange} />
            <JustificationContainer
              justification="space-between"
              align="center"
              margin={['l', null, null]}
            >
              <TextButton
                label={text.cancel}
                onClick={onClose}
                withoutPadding
                appearance="danger"
                type="button"
                dataTestId={TEST_ID.CANCEL_BUTTON}
              />
              <Button
                icon="check"
                appearance="secondary"
                label={text.save}
                type="submit"
                onClick={onConfirmInternal}
                dataTestId={TEST_ID.SAVE_BUTTON}
              />
            </JustificationContainer>
          </FormContainerInner>
        </FormContainer>
      </Container>
    </ActionContext.Provider>
  );
};

const Container = styled(JustificationContainer)`
  height: 100%;
`;

const FormContainerInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export default withOverlay(UpdateAction);
