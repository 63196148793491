import { FlowConditionAppVBOWaardecheckContactRequestNew } from './types.flow';
import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';
const emptyFlowConditionAppVBOWaardecheckContactRequestNew = (): Omit<
  FlowConditionAppVBOWaardecheckContactRequestNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_VBOWAARDECHECK_CONTACTREQUEST_NEW
    .type,
  event: null,
});

export default emptyFlowConditionAppVBOWaardecheckContactRequestNew;
