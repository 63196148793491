import React from 'react';
import styled, { css } from 'styled-components';

import { SectionTitle, SectionHelpButton } from '../../../DetailsBlock';
import { CheckboxInputGroup } from '../../../Inputs';

export type Props = {
  dataTestId?: string;
};

const text = {
  notificationHeader: 'E-mail notificaties',
  subtitle: 'Type notificaties',
  dailyDigest: 'Algemeen',
  tasks: 'Taken',
  valueReport: 'Waarderapport',
  bbWaardecheck: 'Brainbay Waardecheck',
  vboWaardecheck: 'Avix Waardecheck',
  integrationHeader: 'E-mailintegratie',
  contact: 'Contacten',
};

const dailyDigestOptions = [
  { label: 'Dagelijks overzicht van nieuwe activiteiten', name: 'dailyDigest' },
];

const taskOptions = [
  {
    label: 'Taken die zijn toegewezen aan mij',
    name: 'taskGotAssignedToMe',
  },
  {
    label: 'Taken die zijn toegewezen aan mijn vestiging(en)',
    name: 'taskGotAssignedToMyOffices',
  },
  {
    label:
      'Nieuwe taken die niet zijn toegewezen aan een vestiging en gebruiker',
    name: 'taskNewUnassigned',
  },
];

const valueReportAppOptions = [
  {
    label: 'Nieuw waarderapport en contact',
    name: 'valueReportAppNewContact',
  },
  {
    label: 'Nieuw waarderapport opgevraagd door een bestaand contact van mij',
    name: 'valueReportAppForContactAssignedToMe',
  },
  {
    label:
      'Nieuw waarderapport opgevraagd door een bestaand contact van mijn vestiging',
    name: 'valueReportAppForContactAssignedToMyOffices',
  },
];

const bbWaardecheckOptions = [
  {
    label: 'Nieuwe waardecheck en contact',
    name: 'appBBWaardecheckNewContact',
  },
  {
    label: 'Nieuwe waardecheck opgevraagd door een bestaand contact van mij',
    name: 'appBBWaardecheckForContactAssignedToMe',
  },
  {
    label:
      'Nieuwe waardecheck opgevraagd door een bestaand contact van mijn vestiging',
    name: 'appBBWaardecheckForContactAssignedToMyOffices',
  },
];

const vboWaardecheckOptions = [
  {
    label: 'Nieuwe waardecheck en contact',
    name: 'appVBOWaardecheckNewContact',
  },
  {
    label: 'Nieuwe waardecheck opgevraagd door een bestaand contact van mij',
    name: 'appVBOWaardecheckForContactAssignedToMe',
  },
  {
    label:
      'Nieuwe waardecheck opgevraagd door een bestaand contact van mijn vestiging',
    name: 'appVBOWaardecheckForContactAssignedToMyOffices',
  },
];

const EmailNotificationsForm: React.FC<Props> = ({ dataTestId, ...rest }) => (
  <SectionBody data-testid={dataTestId} {...rest}>
    <SectionTitle>
      {text.notificationHeader}
      <SectionHelpButton
        link={{
          link: 'https://help.dathuis.nl/nl/articles/3939508-e-mail-notificaties-uitgelegd',
        }}
      />
    </SectionTitle>

    <CheckboxInputGroup
      $key="daily-digest-checkbox-group"
      title={text.dailyDigest}
      options={dailyDigestOptions}
    />
    <CheckboxInputGroup
      $key="task-checkbox-group"
      title={text.tasks}
      options={taskOptions}
    />
    <CheckboxInputGroup
      $key="valuereport-checkbox-group"
      title={text.valueReport}
      options={valueReportAppOptions}
    />
    <CheckboxInputGroup
      $key="bbwaardecheck-checkbox-group"
      title={text.bbWaardecheck}
      options={bbWaardecheckOptions}
    />

    <CheckboxInputGroup
      $key="vbowaardecheck-checkbox-group"
      title={text.vboWaardecheck}
      options={vboWaardecheckOptions}
    />
  </SectionBody>
);

const SectionBody = styled.div<{}>`
  display: flex;
  flex-direction: column;
  flex-grow: 0.8;

  ${({ theme }) => css`
    font-size: ${theme.fontSize('m')};
  `};
`;

export default EmailNotificationsForm;
