import { StringParameterField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { Flow_Parameter__String__Input } from '~/graphql/types';

import { asStringParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

export default (
  parameter: StringParameterField,
): Flow_Parameter__String__Input => {
  const { value } = parameter;
  if (value == null) {
    throw new Error(
      'Should not occur. Validation should have prevented input from being null!',
    );
  }

  return asStringParameterValueInput(value);
};
