import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

import {
  ConnectedEmailAliasFieldsFragment,
  StartEmailAliasVerificationMutationVariables,
  EmailAliasStatus,
} from '~/graphql/types';
import { SynchroniseEntityType } from '~/scenes/External/Nylas/util';

import Button from '~/components/Button';
import { InputGroup, Input } from '~/components/Inputs';
import { StartEmailAliasVerificationMutation } from '~/graphql';
import mutation from '~/graphql/mutation/StartEmailAliasVerification';
import useCurrentAccount from '~/hooks/useCurrentAccount';

import TEST_ID from './EmailAlias.testid';
import cleanedFilename from '~/util/cleanedFilename';
import { AppErrorScreen } from '~/components';
import Validation from '~/util/Validation';
import EmailAliasDeleteButton from './EmailAliasDeleteButton';
import { subMinutes } from '~/util/date';
import useConfirmModal from '~/components/Alerts/useConfirmModal';
import { ALIAS_VERIFICATION_TIMEOUT } from '~/scenes/Settings/constants';
import {
  InputButtonProps,
  INPUT_BUTTON_TYPE,
} from '~/components/Inputs/Input.type';
import { assertNever } from '~/util/assertion';
import Icon from '~/components/Icon';

const text = {
  verifyButtonLabel: 'Verifieer',
  verifyingTooltip: 'Het alias wordt geverifieerd',
  inputLabel: 'Alias',
  syncMutationErrorText:
    'Er is iets fout gegaan bij het verifiëren van het alias.',
  modalLabels: {
    title: 'Verificatieproces',
    message:
      'Het alias is toegevoegd. Een verificatie e-mail is gestuurd naar het alias. Let op, verificatie kan tot 15 minuten duren.',
    buttonConfirmTitle: 'Ok',
    buttonCancelTitle: 'Annuleren',
  },
};
export type EmailAliasType = {
  email: string | null;
  state: EmailAliasStatus | null;
  validatedDate: Date | null;
};
type Props = {
  mayEdit: boolean;
  entityType: SynchroniseEntityType;
  entityId: string;
  parentEmail: string;
  emailAlias: EmailAliasType | null;
  onChange: (newEmailAlias: string) => void;
  onSuccessfulDelete: () => void;
  onSuccessfulVerificationStart: (
    newFields: ConnectedEmailAliasFieldsFragment,
  ) => void;
  setRef: (ref: HTMLElement) => void;
};
const EmailAlias = ({
  parentEmail,
  emailAlias,
  setRef,
  onChange,
  entityType,
  entityId,
  onSuccessfulVerificationStart,
  onSuccessfulDelete,
  mayEdit,
}: Props) => {
  const [startedVerification, setStartedVerification] = useState(false);
  const [showModal] = useConfirmModal(
    text.modalLabels,
    undefined,
    undefined,
    undefined,
    true,
  );

  const { email, state, validatedDate } = emailAlias || {
    email: null,
    state: null,
    validatedDate: null,
  };
  const account = useCurrentAccount();

  return (
    <StartEmailAliasVerificationMutation name={__filename} mutation={mutation}>
      {(startEmailAliasVerification, { error }) => {
        if (error) {
          return (
            <StyledAppErrorScreen
              setBackgroundColor={false}
              inline={true}
              message={text.syncMutationErrorText}
            />
          );
        }

        const startVerification = () => {
          setStartedVerification(true);

          if (emailAlias == null || emailAlias.email == null) {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | cannot determine email to sync for!`,
            );
          }

          if (email == null) {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | cannot sync if no email is given`,
            );
          }

          const variables = getVariables(
            entityType,
            entityId,
            parentEmail,
            emailAlias.email,
            account.id,
          );

          void startEmailAliasVerification({
            variables,
          }).then(mutationResult => {
            // If anything went wrong error prop would handle it
            if (mutationResult && mutationResult.data) {
              const { data } = mutationResult;
              const { startEmailAliasVerification } = data;

              onSuccessfulVerificationStart(startEmailAliasVerification);
              showModal();
            }
          });
        };

        let buttonComponent: ReactElement | null = null;
        let buttonProps: InputButtonProps | null = null;
        let accentInput = false;

        const isVerifyButtonVisible = state == null;
        const isVerifyButtonDisabled =
          emailAlias == null || !Validation.Email.isValid(emailAlias.email);

        if (isVerifyButtonVisible) {
          buttonComponent = (
            <Button
              size="medium"
              appearance="secondary"
              loading={startedVerification && state == null}
              disabled={!mayEdit || isVerifyButtonDisabled}
              data-testid={TEST_ID.VERIFY_BUTTON}
              onClick={startVerification}
              label={text.verifyButtonLabel}
            />
          );
        } else {
          if (state === EmailAliasStatus.Verified) {
            buttonProps = {
              icon: <Icon name="check" strokeWidth={2.5} />,
              type: INPUT_BUTTON_TYPE.SUCCESS,
              tooltipText: null,
            };
          } else if (state === EmailAliasStatus.Unverified) {
            if (
              validatedDate &&
              validatedDate.getTime() <
                subMinutes(
                  new Date(),
                  ALIAS_VERIFICATION_TIMEOUT / 60000,
                ).getTime()
            ) {
              buttonProps = {
                icon: <Icon name="exclamation" />,
                type: INPUT_BUTTON_TYPE.ACCENT,
                tooltipText: text.syncMutationErrorText,
              };
              accentInput = true;
            } else {
              buttonProps = {
                icon: <Icon name="spinner" spin />,
                type: INPUT_BUTTON_TYPE.PENDING,
                tooltipText: text.verifyingTooltip,
              };
            }
          }
        }

        return (
          <>
            <InputGroup data-testid={TEST_ID.CONTAINER}>
              <Input
                data-testid={TEST_ID.INPUT}
                value={email}
                disabled={!mayEdit || state != null}
                label={text.inputLabel}
                onChange={e => {
                  if (e.target) {
                    onChange(e.target.value);
                  }
                }}
                accent={accentInput}
                button={buttonProps != null ? buttonProps : undefined}
                setRef={setRef}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    if (isVerifyButtonVisible && !isVerifyButtonDisabled) {
                      startVerification();
                    }
                  }
                }}
              />
              {buttonComponent}
              <EmailAliasDeleteButton
                disabled={!mayEdit}
                accountId={account.id}
                emailAlias={emailAlias}
                onSuccessfulDelete={onSuccessfulDelete}
              />
            </InputGroup>
          </>
        );
      }}
    </StartEmailAliasVerificationMutation>
  );
};

const getVariables = (
  type: SynchroniseEntityType,
  entityId: string,
  email: string,
  emailAlias: string,
  accountId: string,
): StartEmailAliasVerificationMutationVariables => {
  const variables = {
    email: email.toLocaleLowerCase(),
    emailAlias: emailAlias.toLocaleLowerCase(),
    accountId,
  };

  switch (type) {
    case SynchroniseEntityType.Account:
      return {
        accountId,
        account: variables,
      };
    case SynchroniseEntityType.User:
      return {
        accountId,
        user: variables,
      };
    case SynchroniseEntityType.Office:
      return {
        accountId,
        office: {
          ...variables,
          officeId: entityId,
        },
      };

    default:
      return assertNever(type);
  }
};

const StyledAppErrorScreen = styled(AppErrorScreen)<{}>`
  ${({ theme }) => `
    margin-top: ${theme.space('m')};
  `}
`;

export default EmailAlias;
