import {
  ZapierTriggerAction,
  InexactZapierTriggerActionFieldsFragment,
} from './types.flow';
import { BaseActionProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { asFlowParameterMappingProp } from '../Base';
import { HandledFlowParameterMapping } from '../Base/FlowParameter/FlowParameterMapping/types.flow';

export default (
  item: InexactZapierTriggerActionFieldsFragment,
  baseProps: BaseActionProps,
): ZapierTriggerAction => {
  const { id, zapierTriggerId, mappings } = item;

  return {
    ...baseProps,
    type: FLOW_ACTION_TYPE.ZAPIER_TRIGGER,
    zapierTriggerId,
    mappings: mappings.reduce<Array<HandledFlowParameterMapping>>(
      (prev, mapping) => {
        if (mapping == null || mapping.mapping == null) {
          return prev;
        }

        switch (mapping.mapping.__typename) {
          case 'Flow_Parameter_Boolean_Primitive':
          case 'Flow_Parameter_Number_Primitive':
          case 'Flow_Parameter_String_Primitive':
            throw Error(
              `${mapping.mapping.__typename} flow parameter is not supported`,
            );
          case 'Flow_Parameter_Boolean_Pointer':
          case 'Flow_Parameter_Number_Pointer':
          case 'Flow_Parameter_String_Pointer':
            prev.push(
              asFlowParameterMappingProp(
                mapping,
              ) as HandledFlowParameterMapping,
            );
        }

        return prev;
      },
      [],
    ),

    id,
  };
};
