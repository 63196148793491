import styled from 'styled-components';

const FullScreenBackgroundDiv = styled.div<{}>`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => `
      background-color: ${theme.color('white', 'dark')};
    `}
`;

export default FullScreenBackgroundDiv;
