import { FlowConditionAppFundaContactRequestDetails } from './types.flow';

import { emptyFundaContactRequestEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionAppFundaContactRequestDetails = (): Omit<
  FlowConditionAppFundaContactRequestDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_FUNDA_CONTACTREQUEST_DETAILS
    .type,
  fields: [],
  event: emptyFundaContactRequestEventParameterValue(),
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
});

export default emptyFlowConditionAppFundaContactRequestDetails;
