import gql from 'graphql-tag';

export default gql`
  fragment FlowActionContactDeleteTagFields on Flow_Action_Contact_DeleteTag {
    __typename
    id
    accountId
    flowBlueprintId
    parentId
    name {
      __typename
      value
    }
  }
`;
