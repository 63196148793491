import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import interactions from '~/scenes/Automation/v2/state/interactions';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '../../../nodeTypes';

export type Props = {
  id: string;
  sourceX: number;
  sourceY: number;
  onDrop: React.DragEventHandler<SVGRectElement>;
};

// Currently this drop area is blocking the panning functionality.
// To deal with this we could add an atom for that keeps track of the interactions
// and when the user is not dragging we can disable the pointer events on this element
const DroppableArea: React.FC<Props> = React.memo(
  ({ id, sourceX, sourceY, onDrop }) => {
    const [show, setShow] = useState(false);
    const interaction = useRecoilValue(interactions);

    return interaction?.type === 'dragging' ? (
      <Container
        width={DEFAULT_WIDTH / 2}
        height={DEFAULT_HEIGHT / 2}
        x={sourceX - DEFAULT_WIDTH / 4}
        y={sourceY}
        rx={4}
        id={id}
        onDrop={onDrop}
        onDragOver={() => setShow(true)}
        onDragLeave={() => setShow(false)}
        $show={show}
      />
    ) : null;
  },
);

const Container = styled.rect<{ $show?: boolean }>`
  fill: ${({ $show }) => ($show ? '#EEE' : 'transparent')};
  opacity: 0.5;
  transition: stroke 0.2s ease-out;
  stroke-width: 1;
  /* Turns out rect doesn't receive pointer events by default */
  pointer-events: fill;
`;

export default DroppableArea;
