import { AppType } from '~/graphql/types';
import type { AppConfig } from '../types';

const description = `
## Omschrijving
Ontvang tientallen tot wel honderden leads per maand met je eigen waarderapport. Publiceer het waarderapport op je eigen website met onze speciale widget. Bezoekers van je website kunnen voor alle Nederlandse woonobjecten een waarderapport opvragen. In het rapport staan vergelijkbare recent verkochte huizen.

Met deze app koppel je het waarderapport aan DatHuis om je leads automatisch te verwerken en deze efficiënt om te zetten naar klanten.

## Installatie
Voor het gebruiken van deze app heb je eerst een eigen waarderapport nodig. Heb je nog geen waarderapport? Neem per telefoon of chat (rechtsonder op deze pagina) contact op om een waarderapport aan te vragen. Dat is gratis.

Heb je al een eigen waarderapport? Neem contact met ons op om de identificatiecode te ontvangen voor het koppelen van je waarderapport aan deze app.`;

export const valuationReport: AppConfig = {
  __typename: 'AppStatus_ValuationReport',
  appType: AppType.ValuationReport,
  type: 'with_config',
  slug: 'value-report',
  name: 'Waarderapport',
  tags: ['Leadgeneratie', 'E-mailcampagne', 'Informatievoorziening'],
  provider: 'DatHuis B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/waarderapport.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/waarderapport.svg',
  },
  price: null,
  description,
  termsOfUse: '',
  compatibility: 'Beschikbaar voor alle gebruikers',
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'custom',
};
