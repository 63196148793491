import React from 'react';
import styled, { css } from 'styled-components';
import Label from '~/components/EventTimelineV2/components/Block/components/Data/components/Label';
import { ImageBlock } from '~/graphql/types';

export type Props = Omit<ImageBlock, '__typename'> & {
  dataTestId?: string;
};

const ImageBlockComponent: React.FC<Props> = ({
  label,
  imageValue,
  css,
  ...rest
}) => (
  <>
    {label && <Label>{label}</Label>}
    <ImageEl src={imageValue.src} css={css} {...rest} />
  </>
);

const ImageEl = styled.img(
  () => css`
    width: 100%;
    max-width: 100%;
  `,
);

export default ImageBlockComponent;
