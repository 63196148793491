import { isEmpty, isNil } from 'ramda';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ModalTemplate from '../../components/ModalTemplate';
import { AppSetupModalProps } from '../..';
import TermsOfUse from '~/components/TermsOfUse';
import CustomMarkdown from '../../components/CustomMarkdown';
import Button from '~/components/Button';
import { Checkbox } from '~/components/Inputs';
import TruncateWrapper from '~/components/TruncateWrapper';
import TEST_ID from './index.testid';
import FullWidthButton from '~/components/FullWidthButton';
import { AppType } from '~/graphql/types';

const text = {
  activate: 'Activeren',
  cancel: 'Annuleer',
  termsOfUseCheckbox: 'Ik ga akkoord met de gebruiksvoorwaarden',
};

enum SetupStep {
  Overview = 'overview',
  Terms = 'terms&conditions',
}

export type Props = AppSetupModalProps & {
  /** Disables all interaction */
  disabled: boolean;
};

const AppDisabled: React.FC<Props> = ({
  app,
  disabled,
  onOAuthCall,
  onChangeAppState,
  onClose,
}) => {
  const [step, setStep] = useState<SetupStep>(SetupStep.Overview);

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const { description, termsOfUse } = app;
  const activateApp = async () => {
    setLoading(true);

    if (app.setupFlow === 'oAuth' && onOAuthCall)
      return onOAuthCall({
        onError: () => setLoading(false),
      });

    await onChangeAppState();

    onClose();

    setLoading(false);
  };

  const hasTerms = !isNil(termsOfUse) && !isEmpty(termsOfUse);
  if (step === SetupStep.Terms) {
    return (
      <ModalTemplate
        data={app}
        onClose={onClose}
        dataTestId={TEST_ID.CONTAINER}
        main={
          <>
            <TermsContainer>
              <TermsOfUse
                termsOfUseText={<CustomMarkdown value={termsOfUse} />}
                dataTestId={TEST_ID.TEXT_AREA}
              />
              <StyledCheckbox
                value={agreedToTerms}
                onChange={() => setAgreedToTerms(prev => !prev)}
                label={text.termsOfUseCheckbox}
                dataTestid={TEST_ID.TERMS_CHECKBOX}
              />
            </TermsContainer>
            <BottomBar>
              <Button
                label={text.cancel}
                onClick={() => setStep(SetupStep.Overview)}
                size="medium"
                ghost
              />
              <Button
                label={text.activate}
                onClick={activateApp}
                icon="arrow-down-circle"
                appearance="secondary"
                size="medium"
                disabled={
                  !agreedToTerms ||
                  disabled ||
                  loading ||
                  app.appType === AppType.Enven
                }
                loading={loading}
                dataTestId={TEST_ID.ACTIVATE_BUTTON}
              />
            </BottomBar>
          </>
        }
      />
    );
  }

  return (
    <ModalTemplate
      data={app}
      onClose={onClose}
      main={
        <TruncateWrapper str={description}>
          <CustomMarkdown value={description} />
        </TruncateWrapper>
      }
      aside={
        <FullWidthButton
          label={text.activate}
          onClick={() => {
            if (hasTerms) return setStep(SetupStep.Terms);
            return activateApp();
          }}
          icon="arrow-down-circle"
          appearance="secondary"
          size="medium"
          disabled={disabled || loading || app.appType === AppType.Enven}
          loading={loading}
          dataTestId={TEST_ID.ACTIVATE_BUTTON}
        />
      }
    />
  );
};

const TermsContainer = styled.div<{}>`
  flex-grow: 2;
`;

const BottomBar = styled.div<{}>(
  () => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
);

const StyledCheckbox = styled(Checkbox)(
  ({ theme }) => css`
    margin: ${theme.space('m')} 0 ${theme.space('l')};
  `,
);

export default AppDisabled;
