import React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import { LinkElement } from '~/components/PluginsEditor/types';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import serialize from './serialize';
import TEST_ID from './index.testid';

export type Props = RenderElementProps & {
  dataTestId?: string;
  element: LinkElement;
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: '0' }}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const Link: React.FC<Props> = ({ attributes, children, element }) => (
  <Container
    {...attributes}
    {...element.attributes}
    style={{
      ...element?.attributes?.style,
      display: 'inline',
    }}
    href={element.url}
    data-testid={TEST_ID.CONTAINER}
  >
    <InlineChromiumBugfix />
    {children}
    <InlineChromiumBugfix />
  </Container>
);

const Container = styled.a<{}>``;

export default {
  nodeName: 'A',
  renderComponent: props => <Link {...props} />,
  deserialize: el => ({
    type: ELEMENTS.LINK,
    url: el.getAttribute('href'),
  }),
  serialize,
};
