import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  calculateSpaceInsideComponent,
  calculateFontSize,
} from '~/components/util/getSizeCalculation';
import { getDisabledInputStyle } from '~/components/Inputs/InputElement';
import { Button } from '~/components/Buttons';
import TEST_ID from './InputWithAddButton.testid';
import InputGroupElement from './InputGroupElement';
import { FloatingLabel } from '..';

const text = {
  addButtonLabel: 'Voeg toe',
};
type Props = {
  error?: string;
  label?: string;
  small?: boolean;
  large?: boolean;
  disabled?: boolean;
  buttonLabel?: string;
  value: string | null;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  // Function that will be called when the user presses the button or presses enter
  onSubmit: () => void;
  'data-testid'?: string;
};
const InputWithAddButton = ({
  error,
  label,
  value,
  small,
  large,
  disabled,
  buttonLabel,
  onSubmit,
  onChange,
  ...rest
}: Props) => {
  const [isFocused, setFocused] = useState(false);
  const [buttonRef, setButtonRef] = useState<HTMLDivElement | null>(null);

  let insertedButtonComponent: ReactElement | null = null;
  const buttonIsVisible = value != null && value.length > 0;

  if (buttonIsVisible) {
    insertedButtonComponent = (
      <div ref={newRef => setButtonRef(newRef)}>
        <AddButton
          secondary
          tiny={small}
          small={!small && !large}
          onClick={onSubmit}
          data-testid={TEST_ID.ADD_BUTTON}
        >
          {buttonLabel || text.addButtonLabel}
        </AddButton>
      </div>
    );
  }

  const component = (
    <InputContainer
      onClick={() => setFocused(true)}
      small={small}
      large={large}
      isFocused={isFocused}
      disabled={disabled}
      data-testid={TEST_ID.INPUT_CONTAINER}
    >
      <SearchInputElement
        small={small}
        large={large}
        type="text"
        disabled={disabled}
        onBlur={() => {
          setFocused(false);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onSubmit();

            e.preventDefault();
          }
        }}
        buttonWidth={
          buttonIsVisible && buttonRef != null
            ? buttonRef.getBoundingClientRect().width
            : 0
        }
        value={value || ''}
        onChange={onChange}
        data-testid={TEST_ID.INPUT}
        {...rest}
      />
      {insertedButtonComponent}
    </InputContainer>
  );

  if (label != null) {
    return (
      <Label>
        <FloatingLabel
          actAsPlaceholder={false}
          error={error}
          large={large}
          small={small}
        >
          {error || label}
        </FloatingLabel>
        {component}
      </Label>
    );
  }

  return component;
};

const Label = styled.label<{}>`
  display: block;
`;

type SearchInputElementProps = {
  disabled?: boolean;
  large?: boolean;
  small?: boolean;
  buttonWidth: number;
};
const SearchInputElement = styled.input<SearchInputElementProps>`
  ${({ buttonWidth }) => css`
    width: calc(100% - ${buttonWidth}px);
  `}

  ${({ theme, disabled }) => {
    if (disabled) {
      return getDisabledInputStyle(theme);
    }
    return ``;
  }}

  ${({ theme, large, small }) => {
    const spaceInsideComponent = calculateSpaceInsideComponent(
      theme,
      small,
      large,
    );

    return css`
      font-size: ${calculateFontSize(theme, small, large)}px;
      border: ${theme.getTokens().border.width.s} solid transparent;
      border-radius: ${theme.getTokens().border.radius.s};
      padding: ${spaceInsideComponent - 1}px;
    `;
  }};
`;

const AddButton = styled(Button)<{}>`
  align-self: center;
  min-width: unset;
  right: 0;

  ${({ tiny }) => {
    const space = tiny === true ? 2 : 4;

    return `
      margin: 0 ${space}px;
    `;
  }};
`;

const BORDER_SIZE = 1;

type InputContainerType = {
  large?: boolean;
  small?: boolean;
  isFocused: boolean;
  disabled?: boolean;
};
const InputContainer = styled.div<InputContainerType>`
  display: flex;
  align-items: center;
  position: relative;

  ${({ theme, disabled }) => {
    if (disabled) {
      return getDisabledInputStyle(theme);
    }
    return ``;
  }}

  ${({ theme, large, small, isFocused }) => {
    const activeBorderStyle = `${BORDER_SIZE}px solid ${theme.color('text')}`;
    let border = `${BORDER_SIZE}px solid ${theme.color('grey')}`;

    if (isFocused) {
      border = activeBorderStyle;
    }

    return css`
      font-size: ${calculateFontSize(theme, small, large)}px;
      border: ${border};
      border-radius: ${theme.getTokens().border.radius.s};
      &:hover {
        border: ${activeBorderStyle};
      }
    `;
  }};
`;

export const InputWithAddButtonInGroup = InputGroupElement(InputWithAddButton);

export default InputWithAddButton;
