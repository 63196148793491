import { FlowConditionContactDetails } from './types.flow';
import { Flow_Condition__Input } from '~/graphql/types';

import { asConditionFieldInput } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition';
import { FLOW_CONDITION_CONTACT_DETAILS_FIELD } from './constants';

const asFlowConditionContactDetailsInput = (
  condition: FlowConditionContactDetails,
): Array<Flow_Condition__Input> => {
  const { fields } = condition;

  const inputList = fields.reduce<Array<Flow_Condition__Input>>(
    (acc, field) => {
      if (
        Object.keys(FLOW_CONDITION_CONTACT_DETAILS_FIELD).includes(field.name)
      ) {
        return [
          ...acc,
          {
            Contact: {
              Details: {
                [field.name]: asConditionFieldInput(field),
              },
            },
          },
        ];
      }
      return acc;
    },
    [],
  );

  return inputList;
};

export default asFlowConditionContactDetailsInput;
