import React from 'react';
import JustificationContainer from '~/components/JustificationContainer';
import { Heading5, Variant } from '~/components/Typography/index';

export type Props = {
  dataTestId?: string;
  leads: number;
  linkTo: string;
};

const WaitStatistics: React.FC<Props> = ({ dataTestId, leads, ...rest }) => (
  <JustificationContainer dataTestId={dataTestId} {...rest}>
    <Heading5 variant={Variant.primary}>Nu wachtend:</Heading5>
    &nbsp;
    {leads > 0 ? <>{leads} contact(en) </> : '—'}
  </JustificationContainer>
);

export default WaitStatistics;
