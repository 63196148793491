import {
  LayoutStyleBlockFE,
  StyleBlockFE,
} from '~/components/EventTimelineV2/types';
import { DividerBlock } from '~/graphql/types';

/**
 * Returns blocks to create a property card.
 * Adds a divider after each property except for the last one.
 *
 * keywords: property, comparableProperty
 */
const getProperty = ({
  blocks,
  isLastCard,
}: {
  // Card content (Property image and information)
  blocks: Array<StyleBlockFE>;

  // Used to add a divider between containers
  isLastCard: boolean;
}): LayoutStyleBlockFE => ({
  id: '',
  childrenIds: [],
  __typename: 'FlexLayout',
  blocks: [
    {
      __typename: 'GridLayout',
      blocks,
      id: '',
      childrenIds: [],
    },
    ...(!isLastCard ? [{ __typename: 'DividerBlock' } as DividerBlock] : []),
  ],
});

export default getProperty;
