import gql from 'graphql-tag';

import FlowParameterTemplateStringFields from './FlowParameterTemplateStringFields';

export default gql`
  fragment FlowActionContactDetailsFields on Flow_Action_Contact_Details {
    __typename
    id
    accountId
    flowBlueprintId
    parentId
    field {
      name {
        ...FlowParameterTemplateStringFields
      }
      phone {
        ...FlowParameterTemplateStringFields
      }
    }
  }

  ${FlowParameterTemplateStringFields}
`;
