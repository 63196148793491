import React from 'react';

import { BooleanConditionField } from '../types.flow';
import { BooleanParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { BooleanOperation } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/Boolean/types.flow';

import { Input } from '~/components/Inputs';
import {
  FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION_LABELS,
  FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION,
} from './constants';
import { BooleanParameterValueComponent } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Boolean';
import cleanedFilename from '~/util/cleanedFilename';
import Catalog from '~/Catalog';
import { assertNever } from '~/util/assertion';

const text = {
  operatorLabel: Catalog.flows.operatorLabel,
};

export type ConditionPrimitiveBooleanField =
  | BooleanConditionField
  | (Omit<BooleanConditionField, 'label' | 'name'> & {
      label: undefined;
      name: undefined;
    });

type Props = {
  field: ConditionPrimitiveBooleanField;
  onChange: (newField: ConditionPrimitiveBooleanField) => void;
};
const FlowConditionPrimitiveBooleanFieldComponent = ({
  field,
  onChange,
}: Props) => {
  const { operation } = field;

  return (
    <>
      <Input
        label={text.operatorLabel}
        value={
          FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION_LABELS[
            FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS
          ].label
        }
        disabled
      />
      <BooleanParameterValueComponent
        value={operation == null ? null : operation.value}
        onChange={newValue => {
          onChange({
            ...field,
            operation: newOperationFor(
              field.operation?.type ||
                FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS,
              newValue,
            ),
          });
        }}
      />
    </>
  );
};

const newOperationFor = (
  type: FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION,
  newBooleanValue: BooleanParameterValue,
): BooleanOperation => {
  let newOperation;

  switch (type) {
    case FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS: {
      newOperation = {
        type: FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS,
        value: newBooleanValue,
      };
      break;
    }
    default:
      return assertNever(type, cleanedFilename(__filename));
  }

  return newOperation;
};

export default FlowConditionPrimitiveBooleanFieldComponent;
