import {
  Flow_Parameter_String_Primitive__Input,
  Flow_Parameter__String__Input,
} from '~/graphql/types';
import { StringPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

export default (
  parameterValue: StringPrimitiveParameterValue,
): Flow_Parameter__String__Input => ({
  String: {
    Primitive: {
      ...asPureStringPrimitiveParameterValueInput(parameterValue),
    },
  },
});

export const asPureStringPrimitiveParameterValueInput = (
  parameterValue: StringPrimitiveParameterValue,
): Flow_Parameter_String_Primitive__Input => ({
  value: parameterValue.value,
});
