/**
 * A hook to . It will give the user:
 * [0] - hasChanged : function that returns a boolean to tell the user that the stash has changed
 *
 * It expects:
 *   - initial stash
 */

import { useRef } from 'react';

import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
type ReturnProps = [(newStash: FlowVariableStash) => boolean];

const useVariableStashChangeNotifier = (
  initialStash: FlowVariableStash,
): ReturnProps => {
  const previousStashVersion = useRef(getVersionFromStash(initialStash));

  const checkForChangedStash = newStash => {
    const newVersion = getVersionFromStash(newStash);

    const hasChanged = newVersion !== previousStashVersion.current;

    previousStashVersion.current = newVersion;

    return hasChanged;
  };

  return [checkForChangedStash];
};

const getVersionFromStash = (stash: FlowVariableStash): string | null =>
  stash?.version || null;

export default useVariableStashChangeNotifier;
