import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import DetailsBlock from '~/components/DetailsBlock';
import { Signature, useUpsertSignatureMutation } from '~/graphql/types';

import Catalog from '~/Catalog';
import TEST_ID from './index.testid';
import ErrorMessage from '~/scenes/Settings/Subscription/components/ChangeOrUpdateSubscriptionFlows/steps/components/ErrorMessage';
import { find, isNil, uniqBy } from 'ramda';
import ErrorTypes from '~/ErrorTypes';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DefaultTextEditor from '../DefaultTextEditor';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import getImagesAsAttachments from '../PluginsEditor/utils/signatures/getImagesAsAttachments';
import useErrorReporter from '~/hooks/useErrorReporter';
import serializeAllElements from '../PluginsEditor/utils/serialize';
import useEditorStates from '~/components/PluginsEditor/hooks/useEditorStates';
import NewSaveBar from '../NewSaveBar';
import convertHtmlToSlateFragment from '../PluginsEditor/utils/signatures/convertHtmlToSlateFragment';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';

const text = {
  error: {
    generic: Catalog.genericUnknownErrorMessage,
    unauthorized: 'Wijzigingen niet opgeslagen. Je hebt onvoldoende rechten.',
    mutationFailure:
      'Er is iets misgegaan bij het opslaan van wijzigingen. Probeer het alsjeblieft nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
  },
  success: 'Wijzigingen opgeslagen.',
  faultyImagesError:
    'Er zijn afbeeldingen met fouten of die nog geupload worden',
};

type Props = {
  title: string;
  signature: Signature | null;
  entityTypeForMutation?: { userId: string } | { officeId: string };
  readOnly?: boolean;
  refetch: () => void;
};

const SignatureContainer = ({
  title,
  signature,
  entityTypeForMutation,
  readOnly,
  refetch,
}: Props) => {
  const html = signature?.html || '';
  const convertedHtml = convertHtmlToSlateFragment({
    html,
    attachments: signature?.attachments || [],
    customElements: [ELEMENTS.DH_IMAGE, ELEMENTS.IMAGE],
  });
  const {
    key,
    value,
    hasChanges,
    errors,
    onChange,
    updateEditor,
    resetEditor,
  } = useEditorStates({ initialValue: convertedHtml });

  const account = useCurrentAccount();
  const errorReporter = useErrorReporter();

  const [upsertSignature, { error, loading }] = useUpsertSignatureMutation({
    onCompleted: () => refetch(),
  });
  const addToast = useAddToast();

  const onSave = useCallback((updatedHtml, updatedAttachments) => {
    if (updatedHtml === html) return;

    void upsertSignature({
      variables: {
        accountId: account.id,
        html: updatedHtml || null,
        attachments: updatedAttachments,
        ...entityTypeForMutation,
      },
    }).then(({ data, errors }) => {
      if (errors || !data) {
        errorReporter.captureException(
          new Error(JSON.stringify(errors || 'No Data')),
          'critical',
        );
        addToast([formatToastMessage(text.error.mutationFailure, 'danger')]);
        return;
      }

      if (data) {
        addToast([formatToastMessage(text.success, 'success')]);
        updateEditor();
        return;
      }

      return;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _error = hasChanges ? null : error;

  const isUnauthorized =
    !isNil(_error) &&
    find(
      ({ errorType }: any) => errorType === ErrorTypes.unauthorisedError,
      error?.graphQLErrors || [],
    ).length !== 0;

  const errorMessages =
    uniqBy(e => e.errorType, errors).length > 0
      ? [{ key: text.faultyImagesError }]
      : [];

  return (
    <DetailsBlock title={title}>
      <StyledDefaultTextEditor
        key={key}
        readOnly={readOnly}
        value={value}
        onChange={onChange}
        dataTestId={TEST_ID.CONTAINER}
      >
        {!isNil(_error) && (
          <StyledErrorMessage
            errorMessage={
              isUnauthorized ? text.error.unauthorized : text.error.generic
            }
          />
        )}

        {hasChanges && !readOnly && (
          <StyledNewSaveBar
            onSave={() => {
              if (value) {
                const html = serializeAllElements({
                  fragment: value,
                  customElements: [ELEMENTS.IMAGE, ELEMENTS.DH_IMAGE],
                });
                const attachments = getImagesAsAttachments(value);

                onSave(html, attachments);
              }
            }}
            onCancel={() => resetEditor()}
            errors={errorMessages}
            loading={loading}
            size="small"
          />
        )}
      </StyledDefaultTextEditor>
    </DetailsBlock>
  );
};

const StyledErrorMessage = styled(ErrorMessage)<{}>`
  ${({ theme }) => css`
    margin: ${theme.space('l')} 0 0 0;
  `};
`;

const StyledNewSaveBar = styled(NewSaveBar)<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('m')};
  `,
);

const StyledDefaultTextEditor = styled(DefaultTextEditor)(
  ({ theme }) => css`
    margin-top: ${theme.space('m')};
  `,
);
export default SignatureContainer;
