import React from 'react';
import { Link } from '~/components/Link';

import DefaultPromo from './DefaultPromo';

import RocketImage from '~/assets/images/hero-rocket.png';

import {
  TextWrapper,
  LogoImage,
  BlueWrapper,
  HeroImage,
} from './PromoSection.style';
import Logo from '~/components/Logo';

const PromoSection = () => (
  <BlueWrapper>
    <LogoImage>
      <Link to={'/-/'}>
        <Logo name="dathuis" secondary />
      </Link>
    </LogoImage>
    <TextWrapper>
      <DefaultPromo />
    </TextWrapper>
    <HeroImage src={RocketImage} />
  </BlueWrapper>
);

export default PromoSection;
