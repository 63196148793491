import { FlowConditionEmailLinkClicked } from './types.flow';

import { asEmailPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Email';
import { asStringParameterValue } from '../../FlowParameter/ParameterValue';
import { PARAMETER_VALUE_TYPE } from '../../FlowParameter/ParameterValue/constants';

const asFlowConditionEmailLinkClickedProp = (
  condition: any,
): Omit<FlowConditionEmailLinkClicked, 'id'> => {
  const { email, link } = condition;

  if (
    email != null &&
    email.__typename !== PARAMETER_VALUE_TYPE.EMAIL_POINTER
  ) {
    throw new Error(
      'Should not occur: Email as primitive is not yet supported',
    );
  }

  return {
    type: 'Flow_Condition_Event_Email_LinkClicked',
    email: asEmailPointerParameterValue(email),
    link: asStringParameterValue(link),
  };
};

export default asFlowConditionEmailLinkClickedProp;
