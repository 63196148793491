import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';

import { Plan, BillingCycle } from '~/graphql/types';

import TEST_ID from './index.testid';
import PriceCard from '../components/PriceCard';
import LinkButton from '~/components/Buttons/LinkButton';
import TopSectionContainer from '../components/TopSectionContainer';
import MidSectionContainer from '../components/MidSectionContainer';
import BottomSectionContainer from '../components/BottomSectionContainer';
import {
  planOptions,
  billingCycleOptions,
} from '~/scenes/Settings/Subscription/constants';
import LineTabs from '~/components/LineTabs';
import JustificationContainer from '~/components/JustificationContainer';
import TextButton from '~/components/TextButton';

const text = {
  info: 'Ieder abonnement heeft een limiet aan gebruikers, vestigingen en contacten. Na het overschrijden van deze limieten, worden extra kosten in rekening gebracht van € 49,- per vestiging p/m., € 29,- per gebruiker p/m. en € 5,- per 1000 contacten p/m. Gemaakte kosten worden naar rato in rekening gebracht en maandelijks gefactureerd aan het begin van de volgende facturatiecyclus. Alle bedragen zijn excl. BTW.',
  header: 'Kies je abonnement',
  linkButton: 'Bespaar ruim 20% met een jaarabonnement',
  message: 'Je bespaart ruim 20%!',
  cancelButton: 'Abonnement opzeggen',
  tag: 'Ruim 20% Korting',
};
type Props = {
  initialSelectedPlan: Plan;
  initialSelectedBillingCycle: BillingCycle;
  // The subscription that is currently active
  currentSelectedPlan: Plan | null;
  currentSelectedBillingCycle: BillingCycle | null;
  onContinue: (chosenPlan: Plan, chosenBillingCycle: BillingCycle) => void;

  /** If null the option will not be shown to go to the unsubscribe flow */
  onGoToUnsubscribe: null | (() => void);
};
const ChoosePlanStep: React.FC<Props> = ({
  initialSelectedPlan,
  initialSelectedBillingCycle,
  currentSelectedPlan,
  currentSelectedBillingCycle,
  onContinue,
  onGoToUnsubscribe,
}) => {
  const [plan, setPlan] = useState(initialSelectedPlan);
  const [billingCycle, setBillingCycle] = useState(initialSelectedBillingCycle);

  const changeSelection = option => {
    setPlan(option.value);

    setTimeout(() => {
      onContinue(option.value, billingCycle);
    }, 500);
  };

  let buttonComponent: ReactElement | null = null;
  if (onGoToUnsubscribe != null) {
    buttonComponent = (
      <StyledTextButton
        size="medium"
        label={text.cancelButton}
        data-testid={TEST_ID.GO_TO_CANCEL_STEP}
        onClick={() => {
          onGoToUnsubscribe ? onGoToUnsubscribe() : {};
        }}
      />
    );
  }

  return (
    <Container
      data-testid={TEST_ID.CONTAINER}
      justification="center"
      direction="column"
      align="center"
    >
      <TopSectionContainer headerText={text.header} withBorder>
        <LineTabs
          selectedKey={billingCycle}
          tabs={billingCycleOptions.map(option => ({
            key: option.value,
            label: option.label,
            dataTestid: option.value,
            accentedLabel:
              option.value === BillingCycle.Yearly &&
              billingCycle === BillingCycle.Monthly
                ? text.tag
                : null,
          }))}
          onSelect={billingCycle => {
            setBillingCycle(billingCycle as BillingCycle);
          }}
        />
      </TopSectionContainer>

      <MidSectionContainer>
        <MessageContainer>
          {billingCycle === BillingCycle.Monthly ? (
            <LinkButton
              onClick={() => setBillingCycle(BillingCycle.Yearly)}
              testId={TEST_ID.SWAP_TO_YEAR_ADVERT_BUTTON}
            >
              {text.linkButton}
            </LinkButton>
          ) : (
            <span>{text.message}</span>
          )}
        </MessageContainer>
        <CardContainer>
          {planOptions.map((option, index) => (
            <PriceCard
              key={`priceCard-${index}`}
              subscriptionPlan={option.value}
              billingCycle={billingCycle}
              isSelected={option.value === plan}
              bestChoice={!currentSelectedPlan && option.value === Plan.Pro}
              currentChoice={
                currentSelectedPlan === option.value &&
                currentSelectedBillingCycle == billingCycle
              }
              disabled={
                currentSelectedBillingCycle === billingCycle &&
                currentSelectedPlan === option.value
              }
              onSelect={() => {
                changeSelection(option);
              }}
              dataTestid={option.value}
            />
          ))}
        </CardContainer>
        <InfoContainer>
          <span>{text.info}</span>
        </InfoContainer>
      </MidSectionContainer>

      <BottomSectionContainer>{buttonComponent}</BottomSectionContainer>
    </Container>
  );
};

const Container = styled(JustificationContainer)<{}>`
  height: 100%;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
  `};
`;

const MessageContainer = styled.div<{}>`
  align-self: center;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.color('success')};
  `};
`;

const CardContainer = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 120%;
`;

const InfoContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
  max-width: 670px;

  ${({ theme }) => css`
    font-size: ${theme.fontSize('s')};
    color: ${theme.color('grey')};
    line-height: ${theme.lineHeight('m')};
  `};
`;

const StyledTextButton = styled(TextButton)<{}>`
  align-self: center;
  margin-left: auto;
  margin-right: auto;
`;

export default ChoosePlanStep;
