import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
import { Form, Field } from '~/components/Forms';
import Amplify from '~/amplify';
import Validation from '~/util/Validation';
import AuthWrapperHOC from '~/scenes/Auth/components/AuthWrapperHOC';
import BackLink from '~/scenes/Auth/components/BackLink';
import { H1, H3 } from '~/components/Typography';
import { FormUtils } from '~/components';
import { LinkButton } from '~/components/Buttons';
import Button from '~/components/Button';
import { Input, InputGroup } from '~/components/Inputs';
import {
  ActionWrapper,
  AuthForm,
  ErrorMsg,
  InfoMsg,
} from '~/scenes/Auth/Auth.style';
import withDelayedLoading, {
  WithDelayedLoading,
} from '~/components/util/withDelayedLoading';
import { navigate } from 'gatsby';

type FormData = {
  code: string;
};

type MyProps = {
  history: any;
  location: any;
};
type Props = WithDelayedLoading & MyProps;
type State = {
  verifyError: string | null;
  verifyInfo: string | null;
  disableResend: boolean;
};
export const text = {
  verifyError:
    'Er is iets misgegaan met het opvragen van je e-mailadres. Probeer opnieuw.',
  noCode: 'Voer je verificatienummer in',
  emailSent: 'Er is een verificatienummer verstuurd naar ',
  emailSentTo: email =>
    `Er is opnieuw een verificatienummer verstuurd naar ${email}`,
  successMessage:
    'Je e-mailadres is succesvol geverifieerd. Je kan nu inloggen.',
  newCodeSent: 'Er is een nieuw verificatienummer verstuurd',
  resendCodeButton: 'Ik heb geen e-mail ontvangen',
  codeLabel: 'Verificatienummer',
  verifyButton: 'Verifieer',
  pageTitle: 'Verificatie',
  invalidCode:
    'Het verificatienummer lijkt niet te kloppen. Probeer het nog eens.',
};
class VerifyAccount extends Component<Props, State> {
  timer: ReturnType<typeof setTimeout> | null = null;

  constructor(props: Props) {
    super(props);
    const { location } = this.props;

    const disableResend = this.shouldDisableResend();

    if (!location.state || !location.state.email) {
      this.state = {
        verifyError: text.verifyError,
        verifyInfo: null,
        disableResend,
      };
    } else {
      this.state = {
        verifyError: null,
        verifyInfo: null,
        disableResend,
      };
    }
  }

  shouldDisableResend = (): boolean => {
    const disableResend = localStorage.getItem('disableResend');
    const timeToDisableRepeat =
      disableResend == null ? null : parseInt(disableResend);
    const currentTime = new Date().getTime();

    if (!timeToDisableRepeat || currentTime >= timeToDisableRepeat)
      return false;

    const disableTime = timeToDisableRepeat - currentTime;
    this.disableRepeatedSending(disableTime);
    return true;
  };

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
      this.setState({ disableResend: false, verifyInfo: null });
    }
  };

  disableRepeatedSending = (timeToDisable: number) => {
    const disableTime = new Date().getTime() + timeToDisable;

    setDisableToStorage(disableTime);
    this.timer = setTimeout(() => {
      removeDisableFromStorage();
      this.clearTimeout();
    }, timeToDisable);

    this.setState({ disableResend: true });
  };

  validate = ({ code }: FormData) => {
    const errors: {
      code: string | undefined;
    } = {
      code: undefined,
    };

    if (Validation.String.isEmpty(code)) errors.code = text.noCode;

    return errors;
  };

  resendCode = () => {
    const { location, removeLoading } = this.props;
    const email = location.state.email;

    const oneMinute = 60000;
    this.disableRepeatedSending(oneMinute);

    const loweredEmail = email.toLowerCase();
    Amplify.Auth.resendSignUp(loweredEmail)
      .then(() => {
        removeLoading();
        this.setState({
          verifyInfo: text.emailSentTo(email),
          verifyError: null,
        });
        return;
      })
      .catch(err =>
        handleCognitoException(
          err,
          errorMessage =>
            this.setState({
              verifyError: errorMessage,
              verifyInfo: null,
            }),
          removeLoading,
        ),
      );
  };

  onFormSubmit = ({ code }: FormData) => {
    const email = this.props.location.state.email;
    const { setLoading, removeLoading } = this.props;

    const loweredEmail = email.toLowerCase();
    setLoading();
    Amplify.Auth.confirmSignUp(loweredEmail, code)
      .then(() => {
        removeLoading();
        void navigate('/login', {
          state: {
            message: text.successMessage,
            email: loweredEmail,
          },
        });
      })
      .catch(err =>
        handleCognitoException(
          err,
          errorMessage =>
            this.setState({
              verifyError: errorMessage,
              verifyInfo: null,
            }),
          removeLoading,
        ),
      );
  };

  renderResendCode = () => {
    if (!this.state.disableResend) {
      return (
        <LinkButton onClick={this.resendCode} testId="resend-code">
          {text.resendCodeButton}
        </LinkButton>
      );
    }

    return;
  };

  render() {
    const { location, loading } = this.props;
    const { verifyError, verifyInfo } = this.state;

    const title = text.pageTitle;
    const emailMissed = !location.state || !location.state.email;
    const email = !emailMissed ? location.state.email : null;

    return (
      <AuthWrapperHOC>
        <MetaTags>
          <title>{title}</title>
        </MetaTags>

        <BackLink />

        <AuthForm>
          <H1>{title}</H1>

          {emailMissed && (
            <H3>
              <ErrorMsg>{verifyError}</ErrorMsg>
            </H3>
          )}

          {!emailMissed && (
            <React.Fragment>
              <p>
                {text.emailSent}
                <b>{email}</b>
              </p>

              {verifyInfo && (
                <InfoMsg data-testid="verify-info">{verifyInfo}</InfoMsg>
              )}
              {verifyError && <ErrorMsg>{verifyError}</ErrorMsg>}
              <Form
                onSubmit={this.onFormSubmit}
                validate={this.validate}
                initialValues={{ code: null }}
              >
                {({ handleSubmit, submitting, pristine }) => (
                  <form
                    id="verify"
                    onSubmit={handleSubmit}
                    data-testid="verify-form"
                  >
                    <InputGroup>
                      <Field name="code">
                        {({ input, meta: { error, touched } }) => (
                          <Input
                            autoFocus={true}
                            large
                            label={text.codeLabel}
                            type="text"
                            error={FormUtils.showError(error, touched)}
                            disabled={submitting}
                            {...input}
                          />
                        )}
                      </Field>
                    </InputGroup>
                    <ActionWrapper>
                      {this.renderResendCode()}

                      <VerifyButton
                        appearance="secondary"
                        size="medium"
                        type="submit"
                        loading={loading}
                        disabled={submitting || pristine || loading}
                        label={text.verifyButton}
                      />
                    </ActionWrapper>
                  </form>
                )}
              </Form>
            </React.Fragment>
          )}
        </AuthForm>
      </AuthWrapperHOC>
    );
  }
}

const VerifyButton = styled(Button)<{}>`
  margin-left: auto;
`;

const setDisableToStorage = (disableTime: number) => {
  localStorage.setItem('disableResend', disableTime.toString());
};

const removeDisableFromStorage = () => {
  localStorage.removeItem('disableResend');
};

const handleCognitoException = (
  err: { code: string; message: string },
  setError: (errorMessage: string) => void,
  removeLoading: () => void,
) => {
  removeLoading();
  let errorMessage;
  switch (err.code) {
    case 'CodeMismatchException':
      errorMessage = text.invalidCode;
      break;
    default:
      errorMessage = err.message;
  }
  setError(errorMessage);
};

export default withDelayedLoading<MyProps>(VerifyAccount);
