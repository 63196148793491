import { matchAll } from '~/util/string';
import cleanedFilename from '~/util/cleanedFilename';
import { reportErrorToTracker } from '~/util/assertion';

const hasNonUploadedImagesInHtml = (html: string | null | undefined) => {
  if (html == null) return false;

  const matches = matchAll(
    html,
    /<img.*?src="([^"]*)"[^>]*>/g,
    match => match[1],
  );

  return matches.reduce((acc, match) => {
    if (!(match.startsWith('http') || match.startsWith('[[inlineId'))) {
      reportErrorToTracker(
        new Error(
          `${cleanedFilename(
            __filename,
          )} | The html ${html} contains non uploaded images!`,
        ),
      );

      return true;
    }
    return acc;
  }, false);
};

export default hasNonUploadedImagesInHtml;
