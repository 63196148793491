import { FlowConditionAppVBOWaardecheckReportNew } from './types.flow';
import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';

const emptyFlowConditionAppVBOWaardecheckReportNew = (): Omit<
  FlowConditionAppVBOWaardecheckReportNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_VBOWAARDECHECK_REPORT_NEW.type,
});

export default emptyFlowConditionAppVBOWaardecheckReportNew;
