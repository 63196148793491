import React, { useState } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import DHRouter from '~/components/DHRouter';
import WizardLayout from '~/components/WizardLayout';
import StepList from '~/components/StepList';
import SelectTemplate from './components/SelectTemplate';
import TEST_ID from './index.testid';
import { withOverlay } from '~/components/ModalsV2/Overlay';
import DescribeFlow from './components/DescribeFlow';
import { FlowTemplates } from '~/graphql/types';
import { useSetRecoilState } from 'recoil';
import flowSettings from '../../state/flowSettings';

export type Props = {
  onClose: () => void;
} & RouteComponentProps;

export const LINK_PREFIX = '/-/automation/flows/wizard';

const steps = [
  {
    id: 'select-template',
    label: 'Kies een template',
    disabled: false,
  },
  {
    id: 'add-flow-name',
    label: 'Naam toevoegen',
    disabled: false,
  },
];

const Wizard: React.FC<
  Omit<Props, 'onClose'> & {
    onClose: () => false | void | undefined;
  }
> = ({ onClose }) => {
  const location = useLocation();
  const [template, setTemplate] = useState<FlowTemplates | null>(null);

  const isV2 = location?.pathname.includes('/flows/wizard/v2');
  const version = isV2 ? '/v2' : '/v1';

  const setFlowSettings = useSetRecoilState(flowSettings);

  const onCreate = (name: string, description: string = '') => {
    if (isV2 === true && template !== null) {
      setFlowSettings(prev => ({
        ...prev,
        flowName: name,
        flowDescription: description,
      }));

      // ID will always be on the template,
      // if it's a new flow a random ID has already be generated in `src/scenes/Automation/v2/components/FlowTemplates/index.tsx`

      return navigate(
        `/-/automation/flows/builder-v2/create/${
          template.id
        }?name=${encodeURIComponent(name)}&description=${encodeURIComponent(
          description,
        )}`,
      );
    }

    if (template !== null) {
      return navigate(
        `/-/automation/flows/builder/${template?.id}?name=${encodeURIComponent(
          name,
        )}`,
      );
    }

    return;
  };

  // Since there are only 2 steps, this is fine.🤓
  const currentStep = location.pathname.includes('add-flow-name') ? 1 : 0;

  return (
    <StyledLayout
      data-testid={TEST_ID.CONTAINER}
      layoutClassName="layout"
      header="Nieuwe flow"
    >
      <NonUpdatingStepIndicator currentStep={currentStep} />
      <DHRouter>
        <SelectTemplate
          default
          path="/*"
          onClose={onClose}
          onSelect={template => {
            setTemplate(template);
            // navigate to the next step
            return navigate(LINK_PREFIX + version + '/add-flow-name');
          }}
          template={template}
        />
        <DescribeFlow
          isV2={isV2}
          path="/add-flow-name"
          onClose={onClose}
          onCreate={onCreate}
          template={template}
        />
      </DHRouter>
    </StyledLayout>
  );
};

const StyledLayout = styled(WizardLayout)<{}>(
  ({ theme }) => css`
    /* This is the fix for the flow templates width.
       270px is the width of the wizard "MasterDetail" */

    .layout {
      grid-template-columns: 270px auto;

      ${theme.mq.lessThan('mobile')`
         grid-template-columns: 1fr;
       `}
    }
  `,
);

const NonUpdatingStepIndicator: React.FC<{ currentStep: number }> = React.memo(
  ({ currentStep }) => <StepList currentStep={currentStep} steps={steps} />,
);

export default withOverlay<Props>(Wizard);
