import { Flow_Action__Input } from '~/graphql/types';
import { AddContactTagAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import { asPureStringPrimitiveParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/String/asStringPrimitiveParameterValueInput';

export default (
  action: AddContactTagAction,
  treeNode: ActionTreeNode,
  inputActionDefaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, name } = action;
  const { accountId, flowBlueprintId } = inputActionDefaults;
  const { parentId } = treeNode;

  if (parentId == null)
    throw new Error(`Parentless AddContactTagAction found! ID: ${id}`);

  return {
    Contact: {
      AddTag: {
        id,
        accountId,
        flowBlueprintId,
        parentId,
        name: asPureStringPrimitiveParameterValueInput(name),
      },
    },
  };
};
