import React from 'react';
import {
  FlowData___ActionFragment,
  FlowData___FlowInstanceFragment,
  FlowData___PrimitiveInputFragment,
  FlowData___PrimitiveListInputFragment,
  FlowData___SubjectFragment,
  FlowData___SuperSubjectFragment,
  FlowV2,
  FlowV2_ActionFragment,
} from '~/graphql/types';
import { GetFieldsByPathOpts } from '../../UpdateAction/components/Selector/utils/getFieldsByPath';
import { InstanceMap } from '../../UpdateAction/components/Selector/utils/getInstance';
import { PrimitiveInputMap } from '../../UpdateAction/components/Selector/utils/getPrimitiveInput';

export type InitialFlow = {
  flowName: FlowV2['name'];
  flowDescription: FlowV2['description'];
  enabled: FlowV2['enabled'];
  maximumFlowRun: FlowV2['maximumFlowRun'];
  actions: Array<FlowV2_ActionFragment>;
};

export type IBuilderContext = {
  /**
   * The ID of the current flow
   */
  flowBlueprintId: string;

  /**
   * Most common options for the Selector component
   */
  opts: Omit<GetFieldsByPathOpts, 'conditionType' | 'action'>;

  /**
   * A map containing all instances in the current flow.
   * Should be updated based on the flowInstance atom.
   * Updating the flowInstance atom should update this map
   */
  instanceMap: InstanceMap;

  /**
   * Collection of primitive appearances from anything that is not a list
   * e.g. number, text, boolean etc
   */
  primitiveInput: Array<FlowData___PrimitiveInputFragment>;

  /**
   * Collection of primitive appearances from anything that can be expressed as a list
   * e.g. dropdown options
   */
  primitiveListInput: Array<FlowData___PrimitiveListInputFragment>;

  /**
   * Mapping of type - potential primitive input
   */
  primitiveInputMap: PrimitiveInputMap;

  /**
   * Used to map any "odd type" like ZapierFieldType to a SubjectIdentifier
   */
  superSubjects: FlowData___SuperSubjectFragment;

  /**
   * The complete array of subjects as it comes from BE
   * is used to generated relative maps in various places
   */
  subjects: Array<FlowData___SubjectFragment>;

  /**
   * The complete array of subjects as it comes from BE
   * is used to generated relative maps in various places
   */
  instances: Array<FlowData___FlowInstanceFragment>;

  /**
   * What actions are available to be selected by the user?
   */
  availableActions: Array<FlowData___ActionFragment>;

  /**
   * The current account ID
   * this is provided/duplicated here for ease of access since the builder context is passed into atoms and selectors
   */
  accountId: string;

  /**
   * Initial flow returned from the query
   */
  initialFlow: InitialFlow;
};

const BuilderContext = React.createContext<IBuilderContext | null>(null);
export default BuilderContext;
