import { useState } from 'react';
import { SortDirection } from '~/graphql/types';
import { setLocalStorageItem } from '~/util/localStorageKeys';
import getSortSettings, { formatParsed } from './utils/getSortSettings';

export type SortSettingsFor =
  | 'contactSortSettings'
  | 'flowSortSettings'
  | 'flowSortSettingsLegacy';

export type SortSettings = {
  id: string;
  desc: boolean;
};

export type ParsedSettings = {
  sortField: string;
  sortDirection: SortDirection;
};

type ReturnType = [SortSettings, (settings: ParsedSettings) => void];

const useSortSettings = (sortSettingsFor: SortSettingsFor): ReturnType => {
  const [sortSettings, setSortSettings] = useState<SortSettings>(
    getSortSettings(sortSettingsFor),
  );

  const updateSortSettings = (parsed: ParsedSettings) => {
    // Set sort settings to update the UI
    setSortSettings(formatParsed(parsed, sortSettingsFor));

    // Can be async because the UI has already updated.
    setLocalStorageItem(sortSettingsFor, JSON.stringify(parsed));
  };

  return [sortSettings, updateSortSettings];
};

export default useSortSettings;
