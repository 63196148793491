import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import Button from '~/components/Button';
import TEST_ID from './index.testid';
import Catalog from '~/Catalog';
const text = {
  errorMessage:
    'Er is iets fout gegaan bij het opvragen van de gegevens. Probeer het later nog eens.',
};

type Props = {
  message?: string;

  /** Render it inline (so no padding and take over the font) */
  inline?: boolean;

  /** Default: true */
  setBackgroundColor?: boolean;

  /** If given, a try again button will be displayed and the onClick function will be called if the user clicks it */
  tryAgain?: {
    buttonLabel: string;
    onClick: () => void;
  };
  errorType?: string;
};

const AppErrorScreen = ({
  message,
  inline,
  setBackgroundColor = true,
  errorType,
  tryAgain,
  ...rest
}: Props) => {
  let buttonComponent: ReactElement | null = null;

  if (tryAgain) {
    buttonComponent = (
      <StyledButton
        size="medium"
        onClick={tryAgain.onClick}
        label={tryAgain.buttonLabel}
      />
    );
  }

  return (
    <Container
      data-testid={TEST_ID.CONTAINER}
      {...rest}
      data-error={errorType}
      $setBackgroundColor={setBackgroundColor}
    >
      <Text $inline={inline}>{message || text.errorMessage}</Text>
      {buttonComponent}
    </Container>
  );
};

type ContainerProps = {
  $setBackgroundColor: boolean;
};
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  min-height: 100%;

  ${({ theme, $setBackgroundColor }) => {
    if ($setBackgroundColor) {
      return css`
        background-color: ${theme.color('white', 'dark')};
      `;
    }

    return '';
  }}
`;

type TextProps = {
  $inline?: boolean;
};
const Text = styled.p<TextProps>`
  ${({ $inline }) => {
    if (!$inline) {
      return `
        font-size: 1.5em;
        margin: 2em;
      `;
    }

    return ``;
  }}
`;

const StyledButton = styled(Button)<{}>`
  ${({ theme }) => `
    margin: ${theme.space('xl')} auto ${theme.space('base')} auto;
  `}
`;

export const UnauthorizedAppErrorScreen = (props: Props) => (
  <AppErrorScreen message={Catalog.unauthorisedErrorMessage} {...props} />
);

export default AppErrorScreen;
