import React from 'react';

import { TaskType } from '~/graphql/types';

import Icon from '~/components/Icon';
import { Option } from '~/components/Inputs/Dropdown';

export const iconForTypeInHeader = (type: TaskType): React.ReactNode => {
  switch (type) {
    case TaskType.Email:
      return <Icon name="mail" />;
    case TaskType.Call:
      return <Icon name="phone" />;
    default:
      return <Icon name="check" />;
  }
};
export const taskTypeOptions: Array<Option> = [
  {
    key: TaskType.Call,
    payload: TaskType.Call,
    label: iconForTypeInHeader(TaskType.Call),
  },
  {
    key: TaskType.Email,
    payload: TaskType.Email,
    label: iconForTypeInHeader(TaskType.Email),
  },
  {
    key: TaskType.ToDo,
    payload: TaskType.ToDo,
    label: iconForTypeInHeader(TaskType.ToDo),
  },
];
