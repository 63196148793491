import gql from 'graphql-tag';

export default gql`
  fragment SessionHydrationAccountRelationFields on AccountRelationship {
    __typename
    accountId
    userId
    status
    role
  }
`;
