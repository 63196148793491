import type { FlowPathEntry } from '..';
import type { RenderArgumentContext } from '../../../../ConditionEditor/utils/getLabelForRep/getRenderedArgument';
import renderHandlebarsTemplate from '~/util/handlebars';

const labelFields = <T extends FlowPathEntry>(
  fields: Array<T>,
  opts: { ctx: RenderArgumentContext },
): Array<T> =>
  fields.map(field => ({
    ...field,
    label: renderHandlebarsTemplate(field.label, opts),
  }));
export default labelFields;
