import { FlowConditionTimeElapsed } from './types.flow';

import {
  asNumberPrimitiveParameterValue,
  emptyNumberPrimitiveParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Number';

export default (condition: any): Omit<FlowConditionTimeElapsed, 'id'> => {
  const { minute, hour, day, month, year } = condition;

  return {
    type: 'Flow_Condition_Time_Elapsed',
    minute:
      minute == null
        ? emptyNumberPrimitiveParameterValue()
        : asNumberPrimitiveParameterValue(minute),
    hour:
      hour == null
        ? emptyNumberPrimitiveParameterValue()
        : asNumberPrimitiveParameterValue(hour),
    day:
      day == null
        ? emptyNumberPrimitiveParameterValue()
        : asNumberPrimitiveParameterValue(day),
    month:
      month == null
        ? emptyNumberPrimitiveParameterValue()
        : asNumberPrimitiveParameterValue(month),
    year:
      year == null
        ? emptyNumberPrimitiveParameterValue()
        : asNumberPrimitiveParameterValue(year),
  };
};
