import React from 'react';
import styled from 'styled-components';
import Label from '~/components/EventTimelineV2/components/Block/components/Data/components/Label';
import { MapsBlock } from '~/graphql/types';

export type Props = Omit<MapsBlock, '__typename'> & {
  dataTestId?: string;
};

const MapsBlockComponent: React.FC<Props> = ({ label, mapValue, css }) => (
  <>
    {label && <Label>{label}</Label>}
    <MapsImage
      src={`https://maps.googleapis.com/maps/api/streetview?size=720x400&location=${mapValue.location.lat},${mapValue.location.lng}&fov=100&heading=70&pitch=0&key=${process.env.GOOGLE_MAPS_API_KEY}`}
      alt="street view"
      css={css}
    />
  </>
);

const MapsImage = styled.img`
  width: 100%;
  max-width: 100%;
`;

export default MapsBlockComponent;
