import React from 'react';
import MetaTags from 'react-meta-tags';

import { useGetFlowSettings, useUpdateFlowSettingsMutation } from '~/graphql/';
import Validation from '~/util/Validation';

import withErrorBoundary from '~/ErrorBoundary';
import Catalog from '~/Catalog';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import Form from '~/components/Forms/Form';
import { Input, InputGroup } from '~/components/Inputs';
import { Field } from '~/components/Forms';
import { FormUtils } from '~/components';
import OverviewListHeader from '~/components/OverviewListHeader';
import DetailsBlock from '~/components/DetailsBlock';
import FormSaveBar from '~/components/SaveBar/FormSaveBar';
import useCurrentAccount from '~/hooks/useCurrentAccount';

import TEST_ID from './index.testid';
import useIsMountedRef from '~/hooks/useIsMountedRef';

export type FormData = {
  unsubscribeLinkText: string;
};

const text = {
  pageTitle: Catalog.automationLabel,
  unsubscribeLinkText: 'Uitschrijf link tekst',
  unsubscribeLinkTextInvalid: 'Deze tekst is verplicht',
  emailSettings: 'Email instellingen',
  loading: 'Laden...',
};

const Automation = () => {
  const isMountedRef = useIsMountedRef();
  const account = useCurrentAccount();
  const { data, loading, refetch } = useGetFlowSettings({
    variables: {
      accountId: account.id,
    },
    name: 'getFlowSettings',
  });

  const [updateFlowSettings, { loading: updateLoading }] =
    useUpdateFlowSettingsMutation({
      name: 'updateFlowSettings',
    });

  const validate = ({
    unsubscribeLinkText,
  }: FormData): { unsubscribeLinkText?: string } => {
    const errors: { unsubscribeLinkText?: string } = {};
    if (!Validation.String.isNonEmptyString(unsubscribeLinkText)) {
      errors.unsubscribeLinkText = text.unsubscribeLinkTextInvalid;
    }

    return errors;
  };

  const onFormSubmit = async (update, form) => {
    const variables = {
      accountId: account.id,
      update,
    };

    const { data } = await updateFlowSettings({ variables });

    if (!isMountedRef.current) return;

    await refetch(data?.updateFlowDetails);

    if (!isMountedRef.current) return;

    // Only reset updated fields
    Object.keys(update).forEach(key => form.resetFieldState(key));
  };

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <OverviewListHeader title={text.pageTitle} />
      <DetailsBlock
        title={text.emailSettings}
        dataTestid={TEST_ID.EMAIL_SETTINGS}
      >
        <Form
          onSubmit={onFormSubmit}
          validate={validate}
          initialValues={{
            unsubscribeLinkText: loading
              ? text.loading
              : data?.getFlowSettings.unsubscribeLinkText,
          }}
        >
          {({ form, submitError }) => (
            <form
              onSubmit={event => {
                if (event) event.preventDefault();
                return form.submit();
              }}
            >
              {submitError ? (
                <div data-testid={TEST_ID.ERROR_MESSAGE}>{submitError}</div>
              ) : null}
              <InputGroup>
                <Field name="unsubscribeLinkText">
                  {({ input, meta: { error, touched, submitting } }) => (
                    <Input
                      large
                      label={text.unsubscribeLinkText}
                      type="text"
                      error={FormUtils.showError(error, touched)}
                      disabled={submitting || loading || updateLoading}
                      {...input}
                      dataTestid={TEST_ID.UNSUBSCRIBE_TEXT_INPUT}
                    />
                  )}
                </Field>
              </InputGroup>
              {<FormSaveBar inBlockComponent />}
            </form>
          )}
        </Form>
      </DetailsBlock>
    </ContentContainerDefault>
  );
};

export default withErrorBoundary<{}>(Automation);
