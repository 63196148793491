import {
  TypedField,
  ParameterField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { FlowParameterFieldsFragment } from '~/graphql/types.client.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { asFlowParameterStringField } from './FlowParameterStringField';
import { asFlowParameterTemplateStringField } from './FlowParameterTemplateStringField';
import { PARAMETER_VALUE_TYPE } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/constants';

export default (
  field: TypedField,
  parameter: FlowParameterFieldsFragment,
): ParameterField => {
  switch (field.type) {
    case FIELD_TYPE.STRING:
      if (
        parameter.__typename === PARAMETER_VALUE_TYPE.STRING_POINTER ||
        parameter.__typename === PARAMETER_VALUE_TYPE.STRING_PRIMITIVE
      ) {
        return asFlowParameterStringField(field, parameter);
      } else {
        throw new Error(
          `Should not occur: Field type (${field.type}) not applicable with parameter type (${parameter.__typename})`,
        );
      }

    case FIELD_TYPE.TEMPLATE_STRING:
      if (parameter.__typename === PARAMETER_VALUE_TYPE.TEMPLATE_STRING) {
        return asFlowParameterTemplateStringField(field, parameter);
      } else {
        throw new Error(
          `Should not occur: Field type (${field.type}) not applicable with parameter type (${parameter.__typename})`,
        );
      }

    default:
      throw new Error(
        `NYI: asFlowParameterField field for field type (${field.type})`,
      );
  }
};
