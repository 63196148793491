import type {
  FlowAction,
  FlowData___InstanceConditionFragment,
  FlowData___InstanceFieldFragment,
  FlowData___SubjectFieldFragment,
} from '~/graphql/types';
import type { ConditionMap, SubjectToConditionMap } from '../getConditions';
import type { Flow___SubjectIdentifier, SubjectMap } from '../getSubject';
import type { HydratedField } from '../hydrateField';
import { assertNever } from '~/util/assertion';
import generateNextForDirectory from './generateNextForDirectory';
import generateNextForInstanceField from './generateNextForInstanceField';
import getDirectory, { DirectoryEntry, DirectoryMap } from '../getDirectory';
import pruneEmptyFields from './pruneEmptyFields';
import { reporter } from '~/hooks/useErrorReporter';
import labelFields from './labelFields';

export type FlowField =
  | FlowData___InstanceFieldFragment
  | FlowData___SubjectFieldFragment;

export type FlowPointerFields =
  | DirectoryEntry
  | HydratedField<FlowData___InstanceFieldFragment>;

export type FlowPathEntry =
  | DirectoryEntry
  | FlowData___InstanceConditionFragment
  | HydratedField<FlowField>;

export type FlowPath = Array<FlowPathEntry>;

export type ConditionType = 'trigger' | 'condition';

export type GetFieldsByPathOpts = {
  subjectMap: SubjectMap;
  directoryMap: DirectoryMap;
  conditionMap: ConditionMap;
  subjectToConditionMap: SubjectToConditionMap;

  action: FlowAction;
  conditionType: ConditionType;
  /**
   * Limit the returned fields to only instance fields.
   * Probably what you want when looking at arguments.
   *
   * Not sure this is necessary
   */
  onlyInstances?: boolean;
  /**
   * Limits the output to only show fields that are of a
   * given type. Use this inside the arguments to restrict
   * the possible output.
   */
  limitToInstanceSubjects?: Array<Flow___SubjectIdentifier>;

  /**
   * Removes the given field from the InstanceField options.
   * String is a key that looks like this: "global-contact-email"
   */
  usedInstanceField?: string;
};

/**
 * Generate the list of next fields based on the current path.
 */
const getFieldsByPath = (
  path: FlowPath,
  opts: GetFieldsByPathOpts,
): { result: Readonly<FlowPath>; error?: undefined } | { error: string } => {
  const { directoryMap, conditionType, usedInstanceField } = opts;
  const sortAndLabelFields = (path: FlowPath) =>
    sortFields(
      labelFields(path, {
        ctx: {
          action: opts.action,
          type: conditionType,
        },
      }),
    );

  /**
   * We do want to prevent nested cyclic resolutions.
   *
   * E.g. Contact has a field `neighbor` which in itself is a Contact.
   *
   *  Contact => neighbor => neighbor => neighbor => name
   *
   * Should then be not allowed
   *
   *  Contact => neighbor => name
   *
   * would be fine. The rule is, every Subject type can only be occurring once in the chain.
   */
  const usedSubjectMap: Record<string, number | undefined> = {};

  /**
   * If we start anew, we straight up loop over the root directory
   */
  if (path.length === 0) {
    const nextFields = generateNextForDirectory(directoryMap, {
      ...opts,
      usedSubjectMap,
    }).filter(field =>
      pruneEmptyFields(field, {
        ...opts,
        usedSubjectMap,
        usedInstanceField,
      }),
    );

    return {
      result: sortAndLabelFields(nextFields),
    };
  }

  let currentDir = directoryMap;

  /**
   * Once we left the directories, we cannot have directories again.
   * E.g. Dir - Field - Dir would be invalid
   */
  let hasLeftDirectoryStructure = false;

  /**
   * Check every `path` entry for validity and combine all next fields
   * once we hit the last part of the path.
   */
  for (let pathIndex = 0; pathIndex < path.length; ++pathIndex) {
    const pathPart = path[pathIndex];
    const isFirst = pathIndex === 0;
    const isLast = pathIndex === path.length - 1;

    switch (pathPart.__typename) {
      case 'FlowData___Directory': {
        if (hasLeftDirectoryStructure) {
          return { error: 'We have left the directory structure' };
        }

        const nextDirectory = getDirectory(pathPart.key, currentDir);
        if (nextDirectory == null) {
          return { error: `Cannot find directory with key ${pathPart.key}` };
        }

        currentDir = nextDirectory;
        if (!isLast) continue;

        const nextFields = generateNextForDirectory(currentDir, {
          ...opts,
          usedSubjectMap,
        }).filter(field =>
          pruneEmptyFields(field, {
            ...opts,
            usedSubjectMap,
            usedInstanceField,
          }),
        );

        /** We are showing the contents of a directory as the last entry */
        return {
          result: sortAndLabelFields(nextFields),
        };
      }
      case 'FlowData___InstanceField': {
        if (isFirst) return { error: `Must start with a directory` };

        hasLeftDirectoryStructure = true;
        const subjectCounter = usedSubjectMap[pathPart.parent.type] ?? 0;

        if (subjectCounter !== 0 && conditionType === 'trigger') {
          return {
            error: `Triggers allow at most 1 cycle of subjects, found ${subjectCounter} for ${pathPart.parent.type}`,
          };
        }

        if (subjectCounter > 1 && conditionType === 'condition') {
          return {
            error: `Conditions allow at most 2 cycles, found ${subjectCounter} for ${pathPart.parent.type}`,
          };
        }

        usedSubjectMap[pathPart.parent.type] = subjectCounter + 1;

        const nextFields = generateNextForInstanceField(pathPart, {
          ...opts,
          usedSubjectMap,
        });

        if (nextFields.error != null) return nextFields;
        if (!isLast) continue;

        /** We are showing the contents of an instance field as the last entry */
        return {
          result: sortAndLabelFields(
            nextFields.result.filter(field =>
              pruneEmptyFields(field, {
                ...opts,
                usedSubjectMap,
              }),
            ),
          ),
        };
      }
      case 'FlowData___InstanceCondition': {
        if (isFirst) return { error: `Must start with a directory` };

        hasLeftDirectoryStructure = true;
        if (!isLast) {
          return { error: 'FlowData___Condition can only occur at the end' };
        }

        return { result: [] };
      }
      case 'FlowData___SubjectField': {
        if (isFirst) return { error: `Must start with a directory` };

        hasLeftDirectoryStructure = true;
        if (!isLast) {
          return { error: 'FlowData___SubjectField can only occur at the end' };
        }

        return { result: [] };
      }
      default:
        assertNever(pathPart);
    }
  }

  reporter.captureMessage(
    'Reached invalid end inside `pruneEmptyFields`',
    'critical',
  );
  return { error: 'Reached invalid end' };
};

const sortFields = <T extends FlowPathEntry>(fields: Array<T>): Array<T> =>
  fields.sort((a, b) => {
    /** Directories use weight,label */
    if (
      a.__typename === 'FlowData___Directory' &&
      b.__typename === 'FlowData___Directory'
    ) {
      const weightDifference = b.weight - a.weight;
      if (weightDifference !== 0) return weightDifference;

      return a.label.localeCompare(b.label);
    }

    /** Others are sorted alphabetically */
    if (
      (a.__typename === 'FlowData___InstanceField' &&
        b.__typename === 'FlowData___InstanceField') ||
      (a.__typename === 'FlowData___InstanceCondition' &&
        b.__typename === 'FlowData___InstanceCondition') ||
      (a.__typename === 'FlowData___SubjectField' &&
        b.__typename === 'FlowData___SubjectField')
    ) {
      return a.label.localeCompare(b.label);
    }

    /** Lastly sorted by type. */
    const calculateValue = (field: FlowPathEntry): number => {
      switch (field.__typename) {
        case 'FlowData___Directory':
          return 4;
        case 'FlowData___InstanceCondition':
          return 3;
        case 'FlowData___SubjectField':
          return 2;
        case 'FlowData___InstanceField':
          return 1;
      }
    };

    return calculateValue(b) - calculateValue(a);
  });

export default getFieldsByPath;
