import {
  TableHeaderDefinitionCellType,
  TableRow,
  SortTypeFunction,
  OnSortFunction,
} from '../../types.flow';

export const getRowValueByColumnID = (row: TableRow, columnId: string) =>
  row.values[columnId];

export const compareBasic = (a: any, b: any) => (a === b ? 0 : a > b ? 1 : -1);

export type SortOptions = {
  /** Called when the column sort is changed */
  onSort?: OnSortFunction;

  /** Define your own sort function. The table will use this to sort */
  sortFunction?: SortTypeFunction;
};
export type BaseHeaderCellOptions = {
  label: string;
  dataProperty: string;
  /*  When using useFlexLayout: minWidth is only used to limit column resizing. It does not define the minimum width for a column.
   *  When using non-table-element layouts: Specifies the minimum width for the column
   */
  minWidth?: number;
  /* When using useFlexLayout: Width is used as both the flex-basis and flex-grow. This means that it essentially acts as both the minimum width and flex-ratio of the column.
   *  When using non-table-element layouts: specifies the width for the column
   */
  width?: number;
  /**
   * When using useFlexLayout: maxWidth is only used to limit column resizing. It does not define the maximum width for a column.
   * When using non-table-element layouts: Specifies the maximum width for the column
   */
  maxWidth?: number;
  sorting?: SortOptions;
  isResizable?: boolean;
  dataTestid?: string;
  hideTooltip?: boolean;
};

export const asBaseOptions = ({
  label,
  dataProperty,
  minWidth = 20,
  width = 100,
  maxWidth = 300,
  sorting,
  isResizable = false,
  hideTooltip = false,
  ...rest
}: BaseHeaderCellOptions): TableHeaderDefinitionCellType<any> => {
  let isSortable = false;
  let sortOptions = {};
  if (sorting != null) {
    isSortable = true;

    const { sortFunction, onSort } = sorting;

    sortOptions = { onSort };

    if (sortFunction != null) {
      sortOptions = {
        ...sortOptions,
        sortType: sortFunction,
      };
    }
  }

  return {
    Header: label,
    accessor: dataProperty,
    minWidth,
    width,
    maxWidth,
    disableSortBy: !isSortable,
    disableResizing: !isResizable,
    hideTooltip,
    ...sortOptions,
    ...rest,
  };
};
