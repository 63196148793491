import { FLOW_CONDITION_TYPES } from '../constants';
import { FlowConditionAppFundaContactRequestNew } from './types.flow';

const emptyFlowConditionAppFundaContactRequestNew = (): Omit<
  FlowConditionAppFundaContactRequestNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_FUNDA_CONTACTREQUEST_NEW.type,
});

export default emptyFlowConditionAppFundaContactRequestNew;
