import { atom, selector } from 'recoil';
import { ClientFlowAction } from '~/graphql/types.client';
// import { ChildType } from '../components/Builder/components/nodeTypes/components/IfElseCard';

export type InteractionUpdateAction = {
  type: 'updateAction';
  action: ClientFlowAction;
};

export type InteractionDragging = {
  type: 'dragging';
  action?: ClientFlowAction;
};

export type InteractionUpdateName = {
  type: 'updateName';
};

export type InteractionSetMetadataForWaitAction = {
  type: 'setMetadataForWaitAction';
  action: ClientFlowAction;
  onConfirm: () => void;
  onCancel: () => void;
};

export type InteractionConfirmModal = {
  type: 'confirmModal';
  onConfirm: () => void;
  onCancel: () => void;
  modalHeader: string;
  modalDescription: string;
};

// export type InteractionDuplicateIfElse = {
//   type: 'duplicateIfElse';
//   action: ClientFlowAction;
//   onConfirm: (child: ChildType) => void;
//   onCancel: () => void;
// };

export type InteractionDuplicate = {
  type: 'duplicateAction';
  // new action created
  action: ClientFlowAction;
};

export type InteractionConnectAction = {
  type: 'connectAction';
  action: ClientFlowAction;
  validTargets: Array<ClientFlowAction>;
  childIdKey?: 'trueChildId' | 'falseChildId';
};

export type InteractionHighlightActions = {
  type: 'highlightActions';
  highlightedActions: Array<string>;
};

export type Interaction =
  | InteractionDragging
  | InteractionUpdateAction
  | InteractionUpdateName
  | InteractionConnectAction
  | InteractionSetMetadataForWaitAction
  | InteractionHighlightActions
  | InteractionConfirmModal
  | InteractionDuplicate;
// | InteractionDuplicateIfElse;

export const shouldDisable = selector({
  key: 'interactionShouldDisable',
  get: ({ get }) => {
    const currentInteraction = get(interactions);

    return (
      currentInteraction?.type === 'highlightActions' ||
      currentInteraction?.type === 'connectAction'
    );
  },
});

const interactions = atom<Interaction | null>({
  key: 'flowbuilderActiveInteraction',
  default: null,
});

export default interactions;
