import { BlockElement } from '~/components/PluginsEditor/types';
import getUpdatedBlockAttrs from '~/components/PluginsEditor/utils/serialize/getUpdatedBlockAttrs';

/**
 * serializes the BlockElement into html element
 * @param {BlockElement} node - Block(div) element
 */
const serialize = (node: BlockElement, children: string): string => {
  const attrs = getUpdatedBlockAttrs(node);

  return `<div${attrs ? ` ${attrs}` : ''}>${children}</div>`;
};

export default serialize;
