import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Dropdown from '~/components/Dropdown';
import { Option, SelectedOptionOf } from '~/components/Inputs/Dropdown';
import Loading from '~/components/Loading';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import convertTemplateStringToSlateFragment from '~/components/PluginsEditor/utils/flows/convertTemplateStringToSlate';
import {
  FlowV2_Action_Realworks_SendContactFragment,
  useGetAppStatusRealworksQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useEditorStates from '~/components/PluginsEditor/hooks/useEditorStates';
import useRelativeMaps from '~/scenes/Automation/v2/components/Builder/hooks/useRelativeMaps';
import InputLabel from '~/scenes/Apps/components/InputLabel';
import ErrorScreen from '~/scenes/ErrorScreen';
import getActiveTokenOptions from '~/util/getActiveTokenOptions';
import { Props as FormProps } from '../ActionForm';
import PluginsEditor from '~/components/PluginsEditor';
import withHtml from '~/components/PluginsEditor/plugins/withHtml';
import JustificationContainer from '~/components/JustificationContainer';
import TEST_ID from './index.testid';
import { Descendant } from 'slate';
import TextEditorContainer from '../TextEditorContainer';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_Realworks_SendContactFragment;
};

type State = {
  description: FlowV2_Action_Realworks_SendContactFragment['description'];
  tokenContainerId: FlowV2_Action_Realworks_SendContactFragment['tokenContainerId'];
};

const text = {
  dropdownLabel: 'Vestiging',
  helpLink: 'https://help.dathuis.nl/nl/articles/5447060-over-de-realworks-app',
  description: 'Omschrijving',
};

const RealworksSendContact: React.FC<Props> = ({ action, onChange }) => {
  const initials = {
    tokenContainerId: action.tokenContainerId,
    description: action.description,
  };

  const [actionDetails, setActionDetails] = useState<State>(initials);
  const { id: accountId } = useCurrentAccount();

  const { data, loading } = useGetAppStatusRealworksQuery({
    variables: {
      accountId,
    },
  });

  const { description, tokenContainerId } = actionDetails;

  const realworksStatus = data?.getAppStatusRealworks;

  useEffect(() => {
    onChange({ ...action, ...actionDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  const onHandleChange = useCallback(
    (
      key: string,
      value: Array<Descendant> | SelectedOptionOf<Array<Option>>,
    ) => {
      setActionDetails(prev => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  const maps = useRelativeMaps({ actionId: action.id });
  const initialTitleValue = convertTemplateStringToSlateFragment({
    html: description.template || '',
    mappings: description.mappings,
    ...maps,
  });
  const {
    key,
    value,
    onChange: onDescriptionChange,
    hasChanges,
  } = useEditorStates({
    initialValue: initialTitleValue,
  });

  useEffect(() => {
    if (hasChanges) {
      onHandleChange('descriptionValue', value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, hasChanges]);

  if (!data || !realworksStatus) return <ErrorScreen />;
  if (loading) return <Loading />;

  const options = getActiveTokenOptions(realworksStatus);

  return (
    <>
      <Container>
        <InputLabel label={text.dropdownLabel} help={text.helpLink} />
        <Dropdown
          options={options}
          onChange={e => onHandleChange('tokenContainerId', e.option.payload)}
          selectedOptionIdx={options.findIndex(
            opt => opt.payload == tokenContainerId,
          )}
          dataTestId={TEST_ID.TOKEN_CONTAINER_DROPDOWN}
        />
      </Container>
      <Container>
        <StyledContainer direction="column" margin={['m', null, null, null]}>
          <InputLabel label={text.description} />
          <TextEditorContainer>
            <PluginsEditor
              key={key}
              value={value}
              onChange={onDescriptionChange}
              customElements={[ELEMENTS.VARIABLE]}
              plugins={[
                e =>
                  withHtml({ editor: e, customElements: [ELEMENTS.VARIABLE] }),
              ]}
              dataTestId={TEST_ID.DESCRIPTION}
            />
          </TextEditorContainer>
        </StyledContainer>
      </Container>
    </>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin: ${theme.space('xxs')} 0;
  `,
);

const StyledContainer = styled(JustificationContainer)`
  width: 100%;
`;

export default RealworksSendContact;
