import { FlowParameterEventFieldsFragment } from '~/graphql/types';
import { AppBBWaardecheckAppraisalRequestPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

import { PARAMETER_VALUE_TYPE } from '../../../constants';
import emptyBBWaardecheckAppraisalRequestEventParameterValue from './emptyBBWaardecheckAppraisalRequestEventParameterValue';

export default (
  eventBBWaardecheckAppraisalRequestParameter:
    | Extract<
        FlowParameterEventFieldsFragment,
        {
          __typename: 'Flow_Parameter_Event_Contact_App_BBWaardecheck_AppraisalRequest_Pointer';
        }
      >
    | null
    | undefined,
): AppBBWaardecheckAppraisalRequestPointerParameterValue => {
  if (eventBBWaardecheckAppraisalRequestParameter == null) {
    return emptyBBWaardecheckAppraisalRequestEventParameterValue();
  }
  switch (eventBBWaardecheckAppraisalRequestParameter.__typename) {
    case 'Flow_Parameter_Event_Contact_App_BBWaardecheck_AppraisalRequest_Pointer':
      return {
        type: PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_BBWAARDECHECK_APPRAISALREQUEST_POINTER,
        variable: {
          name: eventBBWaardecheckAppraisalRequestParameter.variable,
          field: eventBBWaardecheckAppraisalRequestParameter.field
            ? {
                name: eventBBWaardecheckAppraisalRequestParameter.field,
              }
            : null,
        },
      };
    default:
      return assertNever(
        eventBBWaardecheckAppraisalRequestParameter.__typename,
        cleanedFilename(__filename),
      );
  }
};
