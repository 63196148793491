import React from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import InputGroupElement from './InputGroupElement';
import { FloatingLabel } from '~/components';
import { getDisabledInputStyle } from './InputElement';
import { Appearance, ButtonSize, buttonSizes } from '~/styles/constants';
import arrayToCss from '~/util/arrayToCss';

export type Option = {
  /** Value to be returned when selected */
  value: any;

  /** Label for this option */
  label: string;

  /** Optional data-testid variable, for testing purposes */
  dataTestid?: string;
};

export type SelectedOption = {
  option?: Option;
  selectedOptionIdx?: number;
};

export type OnChangeFunction = (option: SelectedOption) => void;

type Props = {
  label?: string;

  /** Renders a the selected element in the secondary color */
  secondary?: boolean;

  /** Renders a the selected element in the accent color */
  accent?: boolean;

  /** Renders a the selected element in the danger color */
  danger?: boolean;

  appearance?: Exclude<Appearance, 'tertiary'>;

  /**
   * The options to select, { value: any, label: string },
   * color changes the selected color fo this individual selection
   */
  options: Array<Option>;

  /** Initially selected option */
  selectedIndex: number;

  /** Callback, fired after an option was selected. */
  onChange: OnChangeFunction;

  size?: ButtonSize;

  /** Test id for testing */
  dataTestid?: string;

  /** Styled components classname */
  className?: string;
  helpLink?: React.ReactNode;

  disabled?: boolean;
};

const SelectGroup: React.FC<Props> = ({
  options,
  selectedIndex,
  size = 'medium',
  appearance = 'primary',
  onChange,
  dataTestid,
  label,
  className,
  disabled,
  helpLink = null,
  ...rest
}: Props) => {
  const onSelect = (option: Option, index: number) => {
    onChange({ option, selectedOptionIdx: index });
  };

  const selectOptions = options.map((option, index) => {
    const isSelected: boolean = selectedIndex === index;
    return (
      <SelectGroupEntryLabel
        data-testid={option.dataTestid}
        key={option.label}
        data-selected={isSelected.toString()}
        isSelected={isSelected}
        onClick={() => onSelect(option, index)}
        $size={size}
        $appearance={appearance}
        $disabled={disabled}
        {...rest}
      >
        {option.label}
      </SelectGroupEntryLabel>
    );
  });

  return (
    <>
      {label && (
        <LabelContainer>
          <FloatingLabel
            actAsPlaceholder={false}
            large={size === 'large'}
            small={size === 'small'}
          >
            {label}
          </FloatingLabel>
          {helpLink}
        </LabelContainer>
      )}
      <SelectGroupContainer data-testid={dataTestid} className={className}>
        {selectOptions}
      </SelectGroupContainer>
    </>
  );
};

const SelectGroupContainer = styled.div<{}>`
  display: inline-block;
  white-space: nowrap;
  box-shadow: ${({ theme }) => theme.getTokens().boxShadow.base};
  border-radius: ${({ theme }) => theme.getTokens().border.radius.s};

  > :nth-child(1) {
    border-top-left-radius: ${({ theme }) => theme.getTokens().border.radius.s};
    border-bottom-left-radius: ${({ theme }) =>
      theme.getTokens().border.radius.s};
    border-right: 0;
  }

  > :last-child {
    border-top-right-radius: ${({ theme }) =>
      theme.getTokens().border.radius.s};
    border-bottom-right-radius: ${({ theme }) =>
      theme.getTokens().border.radius.s};
  }
`;

const LabelContainer = styled.div<{}>`
  display: flex;
  align-items: flex-end;
`;

type SelectGroupEntryLabelProps = {
  isSelected: boolean;
  $disabled?: boolean;
  $appearance: Appearance;
  $size: ButtonSize;
};

const SelectGroupEntryLabel = styled.label<SelectGroupEntryLabelProps>(
  ({ theme, isSelected, $appearance, $disabled, $size }) => {
    const colorByAppearance =
      $appearance === 'primary'
        ? theme.color($appearance, 'light')
        : $appearance === 'secondary'
        ? theme.color('success')
        : theme.color($appearance);

    return css`
      display: inline-block;
      cursor: pointer;
      transition: all 0.2s ease-out;

      padding: ${arrayToCss(buttonSizes[$size].padding, theme)};
      background-color: ${isSelected
        ? colorByAppearance
        : theme.color('white')};
      color: ${isSelected ? theme.color('white') : theme.color('text')};

      ${!isSelected &&
      css`
        &:hover {
          background-color: ${transparentize(0.8, colorByAppearance)};
          color: ${colorByAppearance};
        }
      `}

      ${$disabled && getDisabledInputStyle(theme)}
    `;
  },
);
export const SelectGroupComponent = SelectGroup;

export default InputGroupElement(SelectGroupComponent, null, true);
