import React from 'react';
import styled from 'styled-components';
import { TaskType } from '~/graphql/types';
import { OptionOf, SelectedOption } from '~/components/Inputs/Dropdown/';
import { FormType } from '../CreateNewTaskTab';
import { FieldState } from 'final-form';
import Dropdown from '~/components/Dropdown';
import { DateField, TimeField } from '~/components/Forms';
import Button from '~/components/Button';
import { FormUtils } from '~/components';
import { Input } from '~/components/Inputs';
import CaretDropdownButton from '~/components/CaretDropdownButton';
import InputGroup from '~/components/Inputs/InputGroup';
import TEST_ID from './index.testid';
import { Field } from '~/components/Forms';
import Editor from '~/components/Editor';
import useCurrentUser from '~/hooks/useCurrentUser';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useUserOptions from '~/hooks/useUserOptions';

const text = {
  buttons: {
    submit: 'Opslaan',
    submitAndClose: 'Opslaan & sluiten',
  },
  fields: {
    title: 'Titel',
    description: 'Omschrijf je taak hier...',
    date: 'Deadline',
    type: 'Type',
    userAndOfficeLabel: 'Toegewezen',
    selectOptionCall: TaskType.Call,
    selectOptionEmail: TaskType.Email,
    selectOptionTodo: TaskType.ToDo,
    selectOptionCallLabel: 'Bellen',
    selectOptionEmailLabel: 'Mailen',
    selectOptionTodoLabel: 'Te doen',
  },
};
type Props = {
  validate: boolean;
  initialValues: FormType;
  isNew: boolean;
  loading: boolean;
  onSubmit: (shouldClose: boolean) => void;
  getFieldState: (arg0: string) => FieldState<any> | null | undefined;
  change: (arg0: string, arg1: any) => void;
};
const CreateNewTaskTabFields = ({
  validate,
  initialValues,
  isNew,
  loading,
  onSubmit,
  getFieldState,
  change,
}: Props) => {
  const officeFieldState = getFieldState('officeId');
  const me = useCurrentUser();

  const officeChoices = useOfficeOptions({
    userId: me.id,
    withAllOfficesOption: false,
  });

  const userChoices =
    useUserOptions({
      officeId: officeFieldState?.value,
      withAllUsersOption: true,
    }) ?? [];

  return (
    <>
      <TopInputGroup>
        <Field name="title">
          {({ input, meta: { error } }) => (
            <Input
              label={text.fields.title}
              data-testid={TEST_ID.TASK_TITLE}
              type="text"
              disabled={loading}
              error={FormUtils.showError(error, validate)}
              {...input}
            />
          )}
        </Field>
        <Field name="dueDateDate">
          {({ input: { value, onChange }, meta: { error } }) => {
            const touched = initialValues.dueDateDate !== value;

            return (
              <DateField
                error={FormUtils.showError(error, touched)}
                label={text.fields.date}
                value={value}
                inputComponentProps={{
                  // readOnly does not work as expected
                  // readOnly: true,
                  disabled: loading,
                }}
                onChange={onChange}
                name="dueDateDate"
                data-testid={TEST_ID.DUE_DATE_DATE}
                inputComponentType="BORDERLESS"
              />
            );
          }}
        </Field>
        <Field name="dueDateTime">
          {({ input: { onChange, value }, meta: { error } }) => (
            <TimeField
              value={value}
              disabled={loading}
              error={FormUtils.showError(error, validate)}
              name="dueDateTime"
              onChange={onChange}
              data-testid={TEST_ID.DUE_DATE_TIME}
              inputComponentType="BORDERLESS"
            />
          )}
        </Field>
      </TopInputGroup>
      <InputGroup>
        <Field name="description">
          {({
            input: { onChange, value },
            meta: { error, submitSucceeded },
          }) => {
            const hasError = Boolean(FormUtils.showError(error, validate));

            return (
              <FieldContainer>
                <Editor
                  disabled={loading}
                  error={hasError}
                  dataTestId={TEST_ID.DESCRIPTION_TEXTAREA_FIELD}
                  value={value}
                  placeholder="Hoe kan je deze taak omschrijven?"
                  onChange={value => onChange(value)}
                  shouldReset={submitSucceeded}
                />
              </FieldContainer>
            );
          }}
        </Field>
      </InputGroup>
      <BottomInputGroup>
        <Field name="type">
          {({ input: { onChange, value }, meta: { error } }) => (
            <Dropdown
              dataTestId={TEST_ID.TYPE_FIELD}
              label={text.fields.type}
              error={FormUtils.showError(error, validate)}
              disabled={loading}
              onChange={selectedOption => {
                const { option } = selectedOption;
                onChange(
                  option ? option.payload : text.fields.selectOptionCall,
                );
              }}
              selectedOptionIdx={taskTypeOptions.findIndex(
                option => option.payload === value,
              )}
              options={taskTypeOptions}
            />
          )}
        </Field>
        <Field name="officeId">
          {({ input: { onChange, value }, meta: { error } }) => (
            <Dropdown
              dataTestId={TEST_ID.OFFICEID_FIELD}
              data-error={error}
              label={text.fields.userAndOfficeLabel}
              error={FormUtils.showError(error, validate)}
              disabled={loading}
              onChange={(selectedOption: SelectedOption) => {
                const { option } = selectedOption;
                const payload = option ? option.payload : null;
                const newOfficeId = payload?.id ? payload.id : null;

                onChange(newOfficeId);
                change('userId', null);
              }}
              selectedOptionIdx={officeChoices.findIndex(viewableOffice => {
                if (value === null) return viewableOffice.payload === null;
                return viewableOffice.payload?.id == value;
              })}
              options={officeChoices}
            />
          )}
        </Field>
        <Field name="userId">
          {({ input: { onChange, value }, meta: { error } }) => (
            <Dropdown
              dataTestId={TEST_ID.USERID_FIELD}
              data-error={error}
              label=""
              error={FormUtils.showError(error, validate)}
              disabled={loading}
              onChange={(selectedOption: SelectedOption) => {
                const { option } = selectedOption;
                const payload = option ? option.payload : null;
                onChange(payload?.id ? payload.id : null);
              }}
              selectedOptionIdx={userChoices.findIndex(user => {
                if (value === null) return user.payload === null;
                return user.payload?.id == value;
              })}
              options={userChoices}
            />
          )}
        </Field>
        <ButtonWrapper>
          {isNew ? (
            <Button
              type="submit"
              size="medium"
              disabled={loading}
              onClick={() => onSubmit(true)}
              appearance="secondary"
              data-testid={TEST_ID.ADD_NEW_TASK_AND_CLOSE_BUTTON}
              label={text.buttons.submit}
            />
          ) : (
            <CaretDropdownButton
              appearance="secondary"
              disabled={loading}
              data-testid={TEST_ID.ADD_NEW_TASK_AND_CLOSE_BUTTON}
              dropdownOptions={[
                {
                  label: text.buttons.submitAndClose,
                  onClickAction: () => {
                    onSubmit(true);
                  },
                },
              ]}
              mainButtonOption={{
                label: text.buttons.submit,
                onClickAction: () => {
                  onSubmit(false);
                },
              }}
            />
          )}
        </ButtonWrapper>
      </BottomInputGroup>
    </>
  );
};

const taskTypeOptions: Array<OptionOf<TaskType>> = [
  {
    payload: text.fields.selectOptionCall,
    key: text.fields.selectOptionCall,
    label: text.fields.selectOptionCallLabel,
  },
  {
    payload: text.fields.selectOptionEmail,
    key: text.fields.selectOptionEmail,
    label: text.fields.selectOptionEmailLabel,
  },
  {
    payload: text.fields.selectOptionTodo,
    key: text.fields.selectOptionTodo,
    label: text.fields.selectOptionTodoLabel,
  },
];

const FieldContainer = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ButtonWrapper = styled.div<{}>`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const TopInputGroup = styled(InputGroup)<{}>`
  margin-top: 0;
`;

const BottomInputGroup = styled(InputGroup)<{}>`
  margin-bottom: 0;
`;

export default CreateNewTaskTabFields;
