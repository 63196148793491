import { ParameterField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { needsUserActionDescription } from '~/scenes/Automation/Flows/Actions/util';
import { FIELD_TYPE } from '../../constants';
import { describeTypedField } from '~/scenes/Automation/Flows/Actions/Base/';
import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

const describeFlowParameterField = (
  parameter: ParameterField | null | undefined,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  if (parameter == null) {
    return needsUserActionDescription('Kies een veld om in te vullen');
  }

  switch (parameter.type) {
    case FIELD_TYPE.STRING:
    case FIELD_TYPE.TEMPLATE_STRING:
      return defaultDescription(parameter, describeOptions);
    default:
      throw new Error(
        `NYI: description for parameter of type (${parameter.type})`,
      );
  }
};

const defaultDescription = (
  parameter: ParameterField,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  const { label, value } = parameter;

  if (value == null) {
    return needsUserActionDescription(`Kies een waarde voor het veld ${label}`);
  }

  const descriptionArray: DescribeNodeType = [];
  const describedField = describeTypedField(parameter);

  if (Array.isArray(describedField)) {
    descriptionArray.push(...describedField);
  } else {
    descriptionArray.push(describedField);
  }

  descriptionArray.push(' met ');

  const describedParamValue = describeParameterValue(value, describeOptions);

  if (Array.isArray(describedParamValue)) {
    descriptionArray.push(...describedParamValue);
  } else {
    descriptionArray.push(describedParamValue);
  }

  descriptionArray.push(' te vullen');

  return descriptionArray;
};

export default describeFlowParameterField;
