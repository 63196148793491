import { Flow_Action__Input } from '~/graphql/types';
import { AssignContactAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import { asOfficeParameterValueInput } from '../Base/FlowParameter/ParameterValue/Office';
import {
  asUserParameterValueInput,
  isNullUserParameterValue,
} from '../Base/FlowParameter/ParameterValue/User';

export default (
  action: AssignContactAction,
  treeNode: ActionTreeNode,
  defaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, actionInput } = action;
  const { office, user, overwriteAssignee } = actionInput;
  const { parentId } = treeNode;
  const { accountId, flowBlueprintId } = defaults;

  if (parentId == null)
    throw new Error(`Parentless AssignContactAction found! ID: ${id}`);

  return {
    Contact: {
      Assign: {
        id,
        accountId,
        flowBlueprintId,
        parentId,
        office: asOfficeParameterValueInput(office),
        user: isNullUserParameterValue(user)
          ? null
          : asUserParameterValueInput(user),
        overwriteAssignee,
      },
    },
  };
};
