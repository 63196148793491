import React, { useEffect, useState } from 'react';

import { FlowConditionAppHypotheekbondNew } from './types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { InputGroup, Dropdown } from '~/components/Inputs';
import { HypotheekbondTool } from '~/graphql/types';
import { OptionOf } from '~/components/Inputs/Dropdown';
import { asStringPrimitiveParameterValueForValue } from '../../FlowParameter/ParameterValue/String/asStringPrimitiveParameterValue';
import { PARAMETER_PRIMITIVE_VALUE_TYPE } from '../../FlowParameter/ParameterValue/constants';
import { isNil } from 'ramda';

type MyProps = {
  condition: FlowConditionAppHypotheekbondNew;
  onChange: (newFlowCondition: FlowConditionAppHypotheekbondNew) => void;
  onDropdownOpened: () => void;
};

const text = {
  label: 'Selecteer een tool',
  appMap: {
    null: 'Iedere tool',
    [HypotheekbondTool.HypotheekBespaarcheck]:
      'Wat kan ik besparen op mijn hypotheek?',
    [HypotheekbondTool.Kidwk]: 'Kan ik dit huis betalen?',
    [HypotheekbondTool.Looptijdrente]: 'Wat worden mijn maandlasten?',
    [HypotheekbondTool.Maxmortgage]: 'Wat is mijn maximale hypotheek?',
  },
};

type Props = WithBaseActionContextProps & MyProps;

const options: Array<OptionOf<HypotheekbondTool | null>> = [
  {
    label: text.appMap.null,
    key: 'any-event',
    payload: null,
  },
  {
    label: text.appMap[HypotheekbondTool.HypotheekBespaarcheck],
    key: HypotheekbondTool.HypotheekBespaarcheck,
    payload: HypotheekbondTool.HypotheekBespaarcheck,
  },
  {
    label: text.appMap[HypotheekbondTool.Kidwk],
    key: HypotheekbondTool.Kidwk,
    payload: HypotheekbondTool.Kidwk,
  },
  {
    label: text.appMap[HypotheekbondTool.Looptijdrente],
    key: HypotheekbondTool.Looptijdrente,
    payload: HypotheekbondTool.Looptijdrente,
  },
  {
    label: text.appMap[HypotheekbondTool.Maxmortgage],
    key: HypotheekbondTool.Maxmortgage,
    payload: HypotheekbondTool.Maxmortgage,
  },
];
const FlowConditionAppHypotheekbondNewComponent: React.FC<Props> = ({
  condition,
  onChange,
  onDropdownOpened,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    condition.tool?.value ?? null,
  );

  useEffect(() => {
    if (isNil(condition.tool) || condition.tool?.value === null) {
      onChange({
        ...condition,
        tool: {
          type: PARAMETER_PRIMITIVE_VALUE_TYPE.STRING_PRIMITIVE,
          value: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InputGroup>
        <Dropdown
          onDropdownOpened={() => onDropdownOpened()}
          options={options}
          onChange={({ option }) => {
            if (option.payload == null) {
              setSelectedOption(null);

              onChange({
                ...condition,
                tool: {
                  type: PARAMETER_PRIMITIVE_VALUE_TYPE.STRING_PRIMITIVE,
                  value: option.payload,
                },
              });
              return;
            }

            setSelectedOption(option.payload);
            onChange({
              ...condition,
              tool: asStringPrimitiveParameterValueForValue(option.payload),
            });
          }}
          selectedOptionIdx={options.findIndex(
            ({ payload }) => payload === selectedOption,
          )}
          label={text.label}
        />
      </InputGroup>
    </>
  );
};

export default withBaseActionContext<MyProps>(
  FlowConditionAppHypotheekbondNewComponent,
);
