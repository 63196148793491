import React, { ReactElement, useEffect, useState } from 'react';

import {
  BillingCycle,
  InsertBillingMethodWithSignupMutationVariables,
  useInsertBillingMethodWithSignupMutation,
} from '~/graphql/types';
import type { SetupSubscriptionInfo, SetupBillingInfo } from '../types.flow';

import Modal from '~/components/ModalsV2/Modal';
import { PRICING_INFO } from '~/scenes/Settings/Subscription/constants';
import ChoosePlanStep from '../steps/ChoosePlanStep';
import PlanOverviewStep from '../steps/PlanOverviewStep';
import SetupPayment from '../steps/SetupPayment';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Catalog from '~/Catalog';
import useCurrentUser from '~/hooks/useCurrentUser';
import useInitialSubscription from '~/hooks/useInitialSubscription';
import Overlay from '~/components/ModalsV2/Overlay';
import getTapfillateRef from './utils/getTapfillateRef';

const text = {
  insertionErrorMsg: Catalog.genericUnknownErrorMessageShort,
};
type Props = {
  onComplete: () => void;
};
const NewAccountSubscriptionFlow: React.FC<Props> = ({ onComplete }) => {
  const account = useCurrentAccount();
  const me = useCurrentUser();
  const { isPreset, initialSubscription } = useInitialSubscription();
  const [insertBillingMethodWithSignup, { error, loading }] =
    useInsertBillingMethodWithSignupMutation();

  const [subscriptionInfo, setSubscriptionInfo] =
    useState<SetupSubscriptionInfo>({
      chosenPlan: initialSubscription.plan,
      chosenBillingCycle: initialSubscription.billingCycle,
      /* Initial coupon will be set later by PlanOverviewStep/CouponInput component if initialSubscription.couponCode is a valid coupon.
       * We are doing this because
       *   1 - The data is flowing both ways in the existing design. (Which is not ideal)
       *   2 - Separate the coupon logic and contain it in CouponInput
       * We will revisit this when we work on the setup wizard.
       */
      coupon: null,
    });

  const [billingInfo, setBillingInfo] = useState<SetupBillingInfo>({
    name: me.name,
    email: me.email,
  });

  const [stepNumber, setStepNumber] = useState(1);

  useEffect(() => {
    //If we preset the subscription, we skip the first step
    if (isPreset) setStepNumber(2);

    // We only want to do this once it is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pricingInfo = PRICING_INFO[subscriptionInfo.chosenPlan];
  const { pricePerMonth, pricePerYear } = pricingInfo;
  const price =
    subscriptionInfo.chosenBillingCycle === BillingCycle.Monthly
      ? pricePerMonth
      : pricePerYear;
  const totalPrice =
    subscriptionInfo.chosenBillingCycle === BillingCycle.Monthly
      ? price
      : price * 12;

  const onInsert = async (paymentId: string) => {
    const variables: InsertBillingMethodWithSignupMutationVariables = {
      accountId: account.id,
      paymentId,
      plan: subscriptionInfo.chosenPlan,
      billingCycle: subscriptionInfo.chosenBillingCycle,
      coupon:
        subscriptionInfo.coupon == null ? null : subscriptionInfo.coupon.id,
    };

    const tapfillateRef = getTapfillateRef();
    if (tapfillateRef !== null) {
      variables.referralCode = tapfillateRef;
    }

    await insertBillingMethodWithSignup({
      variables,
    }).then(result => {
      if (result?.data?.insertBillingMethodWithSignup?.id) {
        // if successful
        onComplete();
      }
    });
  };

  let stepComponent: ReactElement | null = null;

  if (stepNumber === 1) {
    stepComponent = (
      <ChoosePlanStep
        initialSelectedBillingCycle={subscriptionInfo.chosenBillingCycle}
        initialSelectedPlan={subscriptionInfo.chosenPlan}
        currentSelectedPlan={null}
        currentSelectedBillingCycle={null}
        onContinue={(chosenPlan, chosenBillingCycle) => {
          setSubscriptionInfo(prevInfo => ({
            ...prevInfo,
            chosenPlan,
            chosenBillingCycle,
          }));

          setStepNumber(2);
        }}
        onGoToUnsubscribe={null}
      />
    );
  }
  if (stepNumber === 2) {
    stepComponent = (
      <PlanOverviewStep
        initialSelectedBillingCycle={subscriptionInfo.chosenBillingCycle}
        initialSelectedPlan={subscriptionInfo.chosenPlan}
        initialSelectedCoupon={subscriptionInfo.coupon}
        initialCouponCode={initialSubscription.couponCode}
        onContinue={(billingCycle, coupon) => {
          setSubscriptionInfo(prevInfo => ({
            ...prevInfo,
            coupon,
            chosenBillingCycle: billingCycle,
          }));

          setStepNumber(3);
        }}
        onGoBack={(billingCycle, coupon) => {
          setSubscriptionInfo(prevInfo => ({
            ...prevInfo,
            coupon,
            chosenBillingCycle: billingCycle,
          }));

          setStepNumber(1);
        }}
        loading={false}
      />
    );
  }

  if (stepNumber === 3) {
    stepComponent = (
      <SetupPayment
        onContinue={onInsert}
        onGoBack={billingInfo => {
          setBillingInfo(billingInfo);
          setStepNumber(2);
        }}
        totalPriceToPay={
          totalPrice -
          (subscriptionInfo.coupon != null
            ? subscriptionInfo.coupon.calculateOff(totalPrice)
            : 0)
        }
        insertionLoading={loading}
        insertionError={error == null ? null : text.insertionErrorMsg}
        initialEmail={billingInfo.email}
        initialName={billingInfo.name}
      />
    );
  }

  return (
    <Overlay>
      <Modal>{stepComponent}</Modal>
    </Overlay>
  );
};

export default NewAccountSubscriptionFlow;
