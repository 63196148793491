import React from 'react';
import styled, { css } from 'styled-components';

import { ChartDataPoint, Formatter } from '..';
import { Body } from '../../Typography/index';

export type Props = {
  active?: boolean;
  payload?: Array<ChartDataPoint<number>>;
  formatter?: Formatter;
  omitLabel?: boolean;
};

const Tooltip = ({ active, payload, formatter, omitLabel }: Props) => {
  if (active && payload?.length) {
    return (
      <Container>
        {payload.map(({ value, name }, index) => (
          <Body withoutMargin={index === payload.length - 1} key={name}>
            {!omitLabel && <Label>{name}:&nbsp;</Label>}
            <Value>{formatter ? formatter(value) : value}</Value>
          </Body>
        ))}
      </Container>
    );
  }

  return null;
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('primary')};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('base')};
    z-index: ${theme.z('top')};
  `,
);

const Span = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('white')};
  `,
);

const Label = styled(Span)<{}>``;
const Value = styled(Span)<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
  `,
);

export default Tooltip;
