import { isNil } from 'ramda';
import {} from 'react';
import useOffice from '~/hooks/useOffice';
import useUser from '~/hooks/useUser';
import { UserOptions } from '~/hooks/useUserOptions';

type Params = {
  /** Selected user id */
  userId: string | null | undefined;

  /** Selected office id */
  officeId: string | null | undefined;

  /** User options for the selected office */
  userOptions: UserOptions;
};

/** Returns validation errors for entity dropdowns */
const useEntityValidationForInputs = ({
  userId,
  officeId,
  userOptions,
}: Params) => {
  const currentUser = useUser(userId);
  const currentOffice = useOffice(officeId);

  const userDeletedFromAccount = currentUser?.__typename === 'DeletedUser';
  const userDeletedFromOffice = !isNil(userId)
    ? !userOptions.find(user => user.key === userId)
    : null;
  const selectedOfficeDeleted = currentOffice?.deleted;

  if (userDeletedFromAccount)
    return {
      userError: `Gebruiker "${currentUser?.name}" is verwijderd van het account. Selecteer een andere gebruiker.`,
    };

  if (userDeletedFromOffice)
    return {
      userError: `Gebruiker "${currentUser?.email}" behoort niet tot de geselecteerde vestiging. Selecteer een andere gebruiker.`,
    };

  if (selectedOfficeDeleted)
    return {
      officeError: `Vestiging "${currentOffice?.name}" is verwijderd. Selecteer een andere vestiging.`,
    };

  return {};
};

export default useEntityValidationForInputs;
