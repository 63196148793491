import {
  FlowData___FlowInstanceFragment,
  GetFlowDataQuery,
} from '~/graphql/types';
import { generateConditionMap } from '../getConditions';
import { generateDirectoryMap } from '../getDirectory';
import { generateInstanceMap } from '../getInstance';
import { generatePrimitiveInputMap } from '../getPrimitiveInput';
import { generateSubjectMap } from '../getSubject';

const generateMapsCombined = (
  flowData: GetFlowDataQuery['getFlowData'],
  instance?: Array<FlowData___FlowInstanceFragment>,
) => {
  const subjectMap = generateSubjectMap(flowData.subjects);
  const conditionMap = generateConditionMap(flowData.condition ?? []);
  const directoryMap = generateDirectoryMap(
    flowData.subjects,
    flowData.instance,
    subjectMap,
  );

  const primitiveInputMap = generatePrimitiveInputMap([
    ...flowData.primitiveInput,
    ...flowData.primitiveListInput,
  ]);

  const instanceMap = generateInstanceMap(instance ?? flowData.instance);

  return {
    subjectMap,
    conditionMap,
    directoryMap,
    primitiveInputMap,
    instanceMap,
  };
};

export default generateMapsCombined;
