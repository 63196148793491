import {
  ParameterValue,
  PointerVariable,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { FlowVariableStashItem } from '~/scenes/Automation/Flows/types.flow';
import { OptionOf, SelectedOptionOf } from '~/components/Inputs/Dropdown';
import { PrimitiveVariableType } from './constants';

import pointerValueOrNull from '~/scenes/Automation/Flows/Actions/Base/util/pointerValueOrNull';
import cleanedFilename from '~/util/cleanedFilename';
import Catalog from '~/Catalog';
import { PRIMITIVE_VARIABLE_TYPE } from './constants';

const text = {
  primitiveValueLabel: Catalog.flows.primitiveValueLabel,
};
type ReturnProps = {
  variableOptions: Array<
    OptionOf<FlowVariableStashItem | PrimitiveVariableType>
  >;
  selectedVariableIdx: number;
  selectedVariableOption: null | OptionOf<
    FlowVariableStashItem | PrimitiveVariableType
  >;
  onChangeVariable: (
    selectedOption: SelectedOptionOf<
      FlowVariableStashItem | PrimitiveVariableType
    >,
  ) => void;
};

const getVariableWithPrimitiveDropdownProps = <T extends ParameterValue>(
  possibleVariables: Array<FlowVariableStashItem>,
  value: T | null,
  onChangeToPrimitive: () => void,
  onChangeToPointer: (newvariableName: string) => void,
): ReturnProps => {
  const variableOptions = getVariableOptions(possibleVariables);
  const selectedVariableIdx = getSelectedVariableIndex<T>(
    variableOptions,
    value,
  );
  const selectedVariableOption =
    selectedVariableIdx < 0 ? null : variableOptions[selectedVariableIdx];

  const onChangeVariable = ({
    option,
  }: SelectedOptionOf<FlowVariableStashItem | PrimitiveVariableType>) => {
    if (option.payload === PRIMITIVE_VARIABLE_TYPE) {
      onChangeToPrimitive();
    } else {
      if (typeof option.payload === 'string') {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not Occur | option.payload (${
            option.payload
          }) is not caught!`,
        );
      }

      const { variableName } = option.payload;

      onChangeToPointer(variableName);
    }
  };

  return {
    variableOptions,
    selectedVariableIdx,
    selectedVariableOption,
    onChangeVariable,
  };
};

const getSelectedVariableIndex = <T extends ParameterValue>(
  variableOptions: Array<
    OptionOf<FlowVariableStashItem | PrimitiveVariableType>
  >,
  value: T | null,
): number => {
  if (value == null) {
    return -1;
  }

  const pointerValue = pointerValueOrNull(value);

  if (pointerValue == null) {
    return variableOptions.findIndex(
      option => option.payload === PRIMITIVE_VARIABLE_TYPE,
    );
  }

  const variable = getVariableFromValue(value);

  if (variable == null) {
    return -1;
  }

  return variableOptions.findIndex(option => {
    if (typeof option.payload === 'string') {
      return false;
    } else {
      return option.payload.variableName === variable.name;
    }
  });
};

const getVariableOptions = (
  possibleVariables: Array<FlowVariableStashItem>,
): Array<OptionOf<FlowVariableStashItem | PrimitiveVariableType>> => {
  const possibleSteps = possibleVariables.map(possibleVariable => ({
    label: possibleVariable.variableLabel,
    payload: possibleVariable,
    key: possibleVariable.variableName,
  }));

  return [
    ...possibleSteps,
    {
      label: text.primitiveValueLabel,
      payload: PRIMITIVE_VARIABLE_TYPE,
      key: PRIMITIVE_VARIABLE_TYPE,
    },
  ];
};

const getVariableFromValue = (
  value: ParameterValue | null,
): PointerVariable | null => {
  if (value == null) {
    return null;
  }

  const castedValue = pointerValueOrNull(value);

  if (castedValue == null) {
    return null;
  } else {
    return castedValue.variable;
  }
};

export default getVariableWithPrimitiveDropdownProps;
