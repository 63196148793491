import React from 'react';
import { Task } from '~/scenes/Tasks/types';

export type TaskModalContextProps = {
  taskDetails: Task | null | undefined;
  isNew: boolean;
  updateTaskDetails: (taskDetails: $Object) => void;
  handleToggleClose: (canClose: boolean, updatedData?: Task) => void;
  closeModal: () => void;
  accountId: string | null | undefined;
};

const TaskModalContext = React.createContext<TaskModalContextProps>({
  taskDetails: null,
  isNew: false,
  updateTaskDetails: () => {},
  handleToggleClose: () => {},
  closeModal: () => {},
  accountId: null,
});

export default TaskModalContext;
