import React, { useState } from 'react';

import TEST_ID from './InsertHTMLModal.testid';
import CodeEditor from '../CodeEditor';
import Overlay from '../ModalsV2/Overlay';
import Dialog from '../ModalsV2/Dialog';
import { Body } from '../Typography/index';
import styled, { css } from 'styled-components';

const text = {
  modalHeader: 'HTML invoegen',
  modalDescription: 'Voeg HTML toe voor een handtekening of nieuwsbrief.',
  submitLabel: 'Invoegen',
  cancelLabel: 'Annuleer',
};

type Props = {
  /** When the modal is closed */
  onClose: () => void;
  onSubmit: (html: string) => void;
};
const InsertHTMLModal: React.FC<Props> = ({ onClose, onSubmit }) => {
  const [html, setHTML] = useState('');

  return (
    <Overlay onClose={onClose} dataTestId={TEST_ID.CONTAINER}>
      <Dialog
        header={text.modalHeader}
        body={
          <>
            <Body>{text.modalDescription}</Body>
            <Container>
              <CodeEditor
                value={html}
                onChange={value => setHTML(value)}
                autoFocus
                options={{ autofocus: true }}
                height="300px"
                width="100%"
              />
            </Container>
          </>
        }
        onConfirm={() => onSubmit(html)}
        onClose={onClose}
        confirmAction={{ label: text.submitLabel }}
        cancelAction={{ label: text.cancelLabel }}
      />
    </Overlay>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    width: 100%;
    max-width: 480px;
    padding: ${theme.space('base')} 0;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
  `,
);

export default InsertHTMLModal;
