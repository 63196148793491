import gql from 'graphql-tag';

export default gql`
  mutation DeleteUserFromAccount(
    $accountId: ID!
    $userId: ID!
    $changeSet: [TransferResourcesChangeSet!]!
  ) {
    deleteUserFromAccount(
      accountId: $accountId
      userId: $userId
      changeSet: $changeSet
    ) {
      __typename
      success
      error {
        lockedByUserId
        reason
      }
    }
  }
`;
