import { TypedField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ConditionField } from './types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { emptyFlowConditionStringField } from './String';
import { emptyFlowConditionNumberField } from './Number';
import { emptyFlowConditionBooleanField } from './Boolean';
import cleanedFilename from '~/util/cleanedFilename';

export default (field: TypedField): ConditionField => {
  switch (field.type) {
    case FIELD_TYPE.STRING:
      return emptyFlowConditionStringField(field);
    case FIELD_TYPE.NUMBER:
      return emptyFlowConditionNumberField(field);
    case FIELD_TYPE.BOOLEAN:
      return emptyFlowConditionBooleanField(field);
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | empty flow condition field for type ${
          field.type
        }`,
      );
  }
};
