import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterStringFields on Flow_Parameter__String {
    __typename

    ... on Flow_Parameter_String_Primitive {
      stringValue: value
    }

    ... on Flow_Parameter_String_Pointer {
      variable
      field
    }
  }
`;
