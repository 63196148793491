import { isObject } from 'lodash';
import { FlowChanges } from '~/scenes/Automation/v2/state/flowChanges';

const checkValue = (fields: Partial<FlowChanges>): boolean => {
  for (const key in fields) {
    if (fields[key] === true) {
      return true;
    }

    if (isObject(fields[key])) {
      return checkValue(fields[key]) ?? false;
    }
  }

  return false;
};

/**
 * Returns true if a value is true
 * @param {FlowChanges} obj - FlowChanges state
 * keywords: get flow changes
 */
const checkFlowHasChanges = (obj: FlowChanges): boolean =>
  checkValue(obj) ?? false;
export default checkFlowHasChanges;
