import React from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/Button';
import JustificationContainer from '~/components/JustificationContainer';
import { TaskStatus, UpdateTaskMutationVariables } from '~/graphql/types';

export type Props = {
  dataTestId?: string;
  accountId: string;

  /** task id */
  id: string;

  /** There is a mutation or query loading */
  loading: boolean;

  /** Task status */
  status: TaskStatus;

  /** Function to update task */
  onUpdate: (variables: UpdateTaskMutationVariables) => void;

  /** Function to delete a task */
  onDelete: () => void;
};

const text = {
  contactLabel: 'Contact',
  reopen: 'Zet terug',
  complete: 'Voltooien',
  delete: 'Verwijderen',
};

const BottomBar: React.FC<Props> = ({
  onUpdate,
  accountId,
  id,
  loading,
  status,
  onDelete,
}) => {
  const isOpen = status === TaskStatus.Open;
  const onTaskUpdate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    taskStatus: TaskStatus,
  ) => {
    e.stopPropagation();
    onUpdate({
      accountId,
      id,
      update: { status: taskStatus },
    });
  };

  return (
    <StyledJustificationContainer
      justification="end"
      gap={isOpen ? 'xs' : undefined}
      padding={['xxs', isOpen ? 'm' : null, 'xxs', 'm']}
    >
      <Button
        appearance="neutral"
        size="medium"
        label={text.delete}
        icon="trashcan"
        loading={loading}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onDelete();
        }}
      />

      <Button
        icon={isOpen ? 'check' : 'redo'}
        appearance={isOpen ? 'primary' : 'neutral'}
        loading={loading}
        disabled={loading}
        size="medium"
        data-testid="complete-task-button"
        onClick={e =>
          onTaskUpdate(e, isOpen ? TaskStatus.Closed : TaskStatus.Open)
        }
        label={isOpen ? text.complete : text.reopen}
      />
    </StyledJustificationContainer>
  );
};

const StyledJustificationContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('grey', 'translucent')};
  `,
);

export default BottomBar;
