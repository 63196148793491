import { omit } from 'ramda';
import { SetterOrUpdater } from 'recoil';
import { FlowV2_ActionFragment, FlowV2_Update_Metadata } from '~/graphql/types';
import {
  ClientFlowAction,
  ClientFlowActionIfElse,
} from '~/graphql/types.client';
import getChildActions from '~/scenes/Automation/v2/components/Builder/utils/getChildActions';
import { IssueMap } from '~/scenes/Automation/v2/state/flowIssues';
import { Interaction } from '~/scenes/Automation/v2/state/interactions';
import assignFlowMetadata from './utils/assignFlowMetadata';
import deleteIfElseAction from './utils/deleteIfElseAction';

const text = {
  header: 'Weet je zeker dat je deze stap wilt verwijderen?',
  description: 'Contacten worden uit de onderliggende wacht acties verwijderd.',
};

const onDeleteActionIfElse = ({
  subjectAction,
  actions,
  setActions,
  setIssues,
  setLastDeletedActionId,
  setInteraction,
  setFlowMetadata,
  initialActions,
  flowEnabled,
}: {
  subjectAction: ClientFlowActionIfElse;
  setActions: SetterOrUpdater<Array<ClientFlowAction>>;
  setIssues: SetterOrUpdater<IssueMap>;
  setLastDeletedActionId: (parentId: string | null) => void;
  actions: Array<ClientFlowAction>;
  setInteraction: SetterOrUpdater<Interaction>;
  setFlowMetadata: SetterOrUpdater<Array<FlowV2_Update_Metadata>>;
  initialActions: Array<FlowV2_ActionFragment>;
  flowEnabled: boolean;
}): void => {
  let lastDeletedActionId: string | null = null;
  let deletedChildrenIds: Array<string> = [];
  let prevActions: Array<ClientFlowAction> = [];

  setActions(actions => {
    prevActions = actions;

    const {
      actions: nextActions,
      childActionsToDelete,
      deletedActionId,
    } = deleteIfElseAction({ actions, subjectAction });

    deletedChildrenIds = childActionsToDelete;
    lastDeletedActionId = deletedActionId;

    return nextActions;
  });

  const deletedActionIds = [...deletedChildrenIds, subjectAction.id];

  const onDelete = () => {
    setIssues(prev => omit(deletedActionIds, prev));
    setLastDeletedActionId(lastDeletedActionId);
  };

  const deletedWaitActions = getChildActions(subjectAction, actions)
    .filter(
      a =>
        a.__typename === 'FlowV2_Action_Wait' &&
        deletedActionIds.includes(a.id),
    )
    .map(a => a.id);

  const savedWaitActions = deletedWaitActions.filter(id =>
    initialActions.some(savedAction => savedAction.id === id),
  );

  if (flowEnabled && savedWaitActions.length > 0) {
    setInteraction({
      type: 'confirmModal',
      onConfirm: () => {
        setFlowMetadata(prev =>
          assignFlowMetadata({
            prevMetadata: prev,
            deletedActionIds,
            deletedWaitActions: savedWaitActions,
            initialActions,
          }),
        );
        onDelete();
      },
      onCancel: () => {
        setActions(prevActions);
      },
      modalHeader: text.header,
      modalDescription: text.description,
    });

    return;
  }

  onDelete();
};

export default onDeleteActionIfElse;
