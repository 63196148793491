import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  useSlate,
} from 'slate-react';
import styled, { css } from 'styled-components';
import withErrorBoundary from '~/ErrorBoundary';
import getElements from '../elements';
import DIVelement from '../elements/DIVelement';
import leaf from '../elements/Leaf';
import handleOnKeyDown from '../../eventListeners/onKeydown';
import TEST_ID from './index.testid';
import { EditableProps } from 'slate-react/dist/components/editable';
import { DHEditor } from '../../types';
import ELEMENTS from '../elements/elementsEnum';

type Props = {
  dataTestId?: string;

  /** Makes the editor and toolbar disabled */
  readOnly: boolean;

  /** Props to overwrite the Editable component's props */
  editableProps?: EditableProps;

  /** Editor */
  pluginsEditor: DHEditor;

  /** Makes the editor single line by disabling Enter key press */
  singleLine: boolean;

  /** Hides static toolbar. Difference between singleLine is hideToolbar does not block adding new lines */
  hideToolbar: boolean;

  /** Additional elements to support in the editor, adds the corresponding toolbar button */
  customElements?: Array<ELEMENTS>;

  /** Extra buttons, components you want to add to the editor that needs the editor state */
  children?: (editor?: DHEditor) => React.ReactElement;

  /**
   * The placeholder text
   */
  placeholder?: string;
};

const text = {
  resettingErrorMessage:
    'Deze actie is niet toegestaan. De teksteditor wordt teruggezet naar de vorige staat. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};

const EditableContainer: React.FC<Props> = ({
  readOnly,
  editableProps,
  children,
  pluginsEditor,
  singleLine,
  hideToolbar,
  customElements,
  placeholder,
}) => {
  const supportedElements = useMemo(
    () => getElements(customElements),
    [customElements],
  );

  const renderElement = useCallback(
    (props: RenderElementProps) => {
      const element = supportedElements?.[props.element.type];

      if (!element) return <DIVelement.renderComponent {...props} />;
      return element.renderComponent(props);
    },
    [supportedElements],
  );
  const renderLeaf = useCallback((props: RenderLeafProps) => leaf(props), []);

  const onKeyDown = useCallback(event => {
    if (singleLine && event.key === 'Enter') {
      event.preventDefault();
      return;
    }

    handleOnKeyDown(pluginsEditor, event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      data-testid={TEST_ID.CONTAINER}
      $isRow={singleLine || hideToolbar}
    >
      <Editable
        placeholder={placeholder}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        spellCheck={false}
        readOnly={readOnly}
        style={{ flexGrow: 1 }}
        {...editableProps}
      />

      {children && children(pluginsEditor)}
    </Container>
  );
};

const Container = styled.div<{ $isRow: boolean }>`
  display: flex;
  line-height: ${({ theme }) => theme.lineHeight('l')};

  ${({ $isRow, theme }) => {
    if ($isRow) {
      return css`
        flex-direction: row;
        align-items: center;
        padding: 0.4em;
        padding-left: ${theme.space('s')};
      `;
    }

    return css`
      padding: 20px 5px;
      overflow: auto;
      max-height: 800px;
      min-height: 150px;
      display: flex;
      flex-direction: column;
    `;
  }}
`;

const LoadingContainer = styled.div<{}>`
  ${({ theme }) => css`
    background-color: ${theme.color('white', 'dark')};
    color: ${theme.color('grey')};
    padding: ${theme.space('xxs')};
    margin: ${theme.space('xxs')} 0;
    border-radius: ${theme.getTokens().border.radius.base};
  `}
`;

/**
 * To test the error state, add this component as a child to Container as:
 * <TestComponent pluginsEditor={pluginsEditor} />  and add new lines in the editor
 */
// const TestComponent = ({ pluginsEditor }) => {
//   const childrenAmount = pluginsEditor.children.length;

//   useEffect(() => {
//     if (childrenAmount > 4) {
//       throw new Error('Too many children');
//     }
//   }, [childrenAmount]);

//   return <>Testing</>;
// };

const ErrorHandlingComp = ({ resetError }) => {
  const editor = useSlate();

  useEffect(() => {
    editor.undo();

    // unmount the error component after showing the message
    // otherwise Editable component stays broken
    setTimeout(() => {
      resetError();
    }, 3000);
  }, [editor, resetError]);

  return <LoadingContainer>{text.resettingErrorMessage}</LoadingContainer>;
};

export default withErrorBoundary(EditableContainer, props => (
  <ErrorHandlingComp resetError={props.resetError} />
));
