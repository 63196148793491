import { FlowConditionContactDetails } from './types.flow';

import {
  FLOW_CONDITION_CONTACT_DETAILS_FIELD,
  DEFAULT_FLOW_CONDITION_CONTACT_DETAILS_FIELD,
} from './constants';
import {
  asConditionFieldProp,
  emptyConditionField,
} from '~/scenes/Automation/Flows/Actions/Base';
import { ConditionField } from '../Primitive/types.flow';
import { FlowConditionFieldsFragment } from '~/graphql/types';

type ConditionType = Extract<
  FlowConditionFieldsFragment,
  { __typename: 'Flow_Condition_Contact_Details' }
>;

const asFlowConditionContactDetailsProp = (
  conditions: Array<ConditionType>,
  allShouldBeTrue: boolean,
): Omit<FlowConditionContactDetails, 'id'> => {
  const fields: Array<ConditionField> = [];

  conditions.forEach(condition => {
    let setField = emptyConditionField(
      DEFAULT_FLOW_CONDITION_CONTACT_DETAILS_FIELD,
    );

    Object.keys(FLOW_CONDITION_CONTACT_DETAILS_FIELD).forEach(fieldName => {
      const field = condition[fieldName];
      if (field != null) {
        setField = asConditionFieldProp(
          FLOW_CONDITION_CONTACT_DETAILS_FIELD[fieldName],
          field,
        );
      }
    });

    fields.push(setField);
  });

  return {
    type: 'Flow_Condition_Contact_Details',
    allShouldBeTrue,
    fields,
  };
};

export default asFlowConditionContactDetailsProp;
