import {
  ParameterValue,
  PointerParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../FlowParameter/ParameterValue/constants';

// Get help with flow typing by using this function to ensure that a parametervalue is a pointer
const pointerValueOrNull = (
  value: ParameterValue,
): PointerParameterValue | null => {
  switch (value.type) {
    case PARAMETER_VALUE_TYPE.NUMBER_POINTER:
    case PARAMETER_VALUE_TYPE.STRING_POINTER:
    case PARAMETER_VALUE_TYPE.OFFICE_POINTER:
    case PARAMETER_VALUE_TYPE.USER_POINTER:
    case PARAMETER_VALUE_TYPE.EMAIL_POINTER:
    case PARAMETER_VALUE_TYPE.EMAIL_TEMPLATE_POINTER:
    case PARAMETER_VALUE_TYPE.BOOLEAN_POINTER:
      return value;
    default:
      return null;
  }
};

export default pointerValueOrNull;
