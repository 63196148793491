import { Flow_Condition__Input } from '~/graphql/types';
import { FlowConditionAppValuationReportNew } from './types.flow';

/**
 * We ignore the app pointer for now as we don't do anything with it. Is an issue for later
 */
export default (
  condition: FlowConditionAppValuationReportNew,
): Flow_Condition__Input => {
  let app = condition.app;

  if (app) {
    // @ts-ignore This is a quickfix, the typings are off here
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...rest } = app;
    app = rest;
  }

  return {
    EventContactApp: {
      ValuationReport: {
        New: {
          app: app
            ? {
                AppValuationReport: { Primitive: { ...app } },
              }
            : null,
        },
      },
    },
  };
};
