import uuidv1 from 'uuid/v1';

import { FlowActionProps } from '../Actions/types.flow';
import {
  FlowVariableStashItem,
  FlowOutputObject,
  FlowOutputObjectField,
  FlowVariableStash,
  GenericFlowOutputObject,
} from '../types.flow';
import { PointerVariable } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { FlowConditionType } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';
import { OptionOf } from '~/components/Inputs/Dropdown/';

import { FLOW_ACTION_TYPE } from '../Actions/constants';
import { FLOW_OUTPUT_OBJECT, GLOBAL_VARIABLE_STASH_ITEM } from '../constants';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';
import { ApolloClient } from '@apollo/client';
import query from '~/graphql/query/GetZapierEvent';
import {
  FlowOutputType,
  GetZapierEventQueryResult,
  ZapierFieldType,
} from '~/graphql/types';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';

export const asVariableStashItem = async (
  actionLabel: string,
  action: FlowActionProps,
  client: ApolloClient<object>,
  accountId: string,
  setOutputLoading: (loading: boolean, actionId: string) => void,
): Promise<FlowVariableStashItem | null> => {
  const outputs = await getOutputObjectsForAction(
    action,
    client,
    accountId,
    setOutputLoading,
  );

  if (outputs.length == 0) return null;

  return {
    variableName: action.id,
    variableLabel: actionLabel,
    outputObjects: outputs,
  };
};

// Should go to the server in the future
export const getOutputObjectsForAction = async (
  action: FlowActionProps,
  client: ApolloClient<object>,
  accountId: string,
  setOutputLoading: (loading: boolean, actionId: string) => void,
): Promise<Array<FlowOutputObject>> => {
  if (!('startCondition' in action) || action.startCondition == null) {
    return getOutputObjects(action.type, action);
  }

  if (
    action.startCondition.type === 'Flow_Condition_Event_Contact_App_Zapier_New'
  ) {
    if (action.startCondition.zapierEventId.value == null) return [];
    setOutputLoading(true, action.id);

    return client
      .query({
        query,
        variables: {
          accountId,
          id: action.startCondition.zapierEventId.value,
        },
      })
      .then(({ data }: GetZapierEventQueryResult) => {
        setOutputLoading(false, action.id);

        const getOutputType = (
          fieldType: ZapierFieldType,
        ):
          | FlowOutputType.FlowOutputBoolean
          | FlowOutputType.FlowOutputNumber
          | FlowOutputType.FlowOutputString => {
          switch (fieldType) {
            case ZapierFieldType.Boolean:
              return FlowOutputType.FlowOutputBoolean;
            case ZapierFieldType.String:
              return FlowOutputType.FlowOutputString;
            case ZapierFieldType.Number:
              return FlowOutputType.FlowOutputNumber;
          }
        };

        const outputObject: GenericFlowOutputObject = {
          outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppZapier,
          objectLabel: 'Inkomende koppeling',
          objectSelectLabel: 'Selecteer een inkomende koppeling',
          name: 'eventContactAppZapier',
          fields: data?.getZapierEvent.fields
            ? data?.getZapierEvent.fields.map(field => ({
                name: `metadata.${field.key}`,
                label: field.label,
                outputType: getOutputType(field.type),
              }))
            : [],
        };

        return [outputObject];
      })
      .catch(() => {
        setOutputLoading(false, action.id);
        return [];
      });
  }
  setOutputLoading(false, action.id);
  return getOutputObjects(action.type, action, action.startCondition.type);
};

export const getOutputObjects = (
  actionType: FLOW_ACTION_TYPE,
  action?: FlowActionProps | null,
  startConditionType?: FlowConditionType,
): Array<FlowOutputObject> => {
  switch (actionType) {
    case FLOW_ACTION_TYPE.SET_CONTACT_DETAILS:
      return [FLOW_OUTPUT_OBJECT.CONTACT];
    case FLOW_ACTION_TYPE.START: {
      switch (startConditionType) {
        case 'Flow_Condition_Contact_Details':
          return [FLOW_OUTPUT_OBJECT.CONTACT];
        case 'Flow_Condition_Contact_Tag':
          return [FLOW_OUTPUT_OBJECT.CONTACT];
        case 'Flow_Condition_Event_Contact_App_ValuationReport_New':
          return [FLOW_OUTPUT_OBJECT.APP_VALUATIONREPORT];
        case 'Flow_Condition_Event_Contact_App_ValuationRequest_New':
          return [FLOW_OUTPUT_OBJECT.APP_VALUATIONREQUEST];
        case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New':
          return [FLOW_OUTPUT_OBJECT.APP_FUNDA_CONTACTREQUEST];
        case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New':
          return [FLOW_OUTPUT_OBJECT.APP_FUNDA_VIEWINGREQUEST];
        case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New':
          return [FLOW_OUTPUT_OBJECT.APP_FUNDA_BROCHUREREQUEST];
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New':
          return [FLOW_OUTPUT_OBJECT.APP_BBWAARDECHECK_REPORT];
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New':
          return [FLOW_OUTPUT_OBJECT.APP_BBWAARDECHECK_APPRAISALREQUEST];
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New':
          return [FLOW_OUTPUT_OBJECT.APP_BBWAARDECHECK_CONTACTREQUEST];
        case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New':
          return [FLOW_OUTPUT_OBJECT.APP_VBOWAARDECHECK_REPORT];
        case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New':
          return [FLOW_OUTPUT_OBJECT.APP_VBOWAARDECHECK_APPRAISALREQUEST];
        case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New':
          return [FLOW_OUTPUT_OBJECT.APP_VBOWAARDECHECK_CONTACTREQUEST];
        case 'Flow_Condition_Event_Contact_App_Hypotheekbond_New':
          return [FLOW_OUTPUT_OBJECT.APP_HYPOTHEEKBOND];
        default:
          throw Error(
            `${cleanedFilename(
              __filename,
            )} | NYI: getOutputObjectsForAction.condition => ${
              startConditionType || 'No startConditionType given'
            }`,
          );
      }
    }
    case FLOW_ACTION_TYPE.WAIT:
      return [];
    case FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN: {
      if (action == null || action.type !== FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN) {
        return [FLOW_OUTPUT_OBJECT.EMAIL];
      } else {
        return [{ ...FLOW_OUTPUT_OBJECT.EMAIL, links: action.links }];
      }
    }
    case FLOW_ACTION_TYPE.CREATE_TASK:
    case FLOW_ACTION_TYPE.DELETE_CONTACT_TAG:
    case FLOW_ACTION_TYPE.ADD_CONTACT_TAG:
    case FLOW_ACTION_TYPE.IF_ELSE:
    case FLOW_ACTION_TYPE.ASSIGN_CONTACT:
    case FLOW_ACTION_TYPE.SENDCONTACT_TO_REALWORKS:
    case FLOW_ACTION_TYPE.ZAPIER_TRIGGER:
      return [];
    default:
      return assertNever(actionType, cleanedFilename(__filename));
  }
};

export const emptyVariableStash = (): FlowVariableStash => ({
  version: uuidv1(),
});

export const globalVariableStash = (): FlowVariableStash => ({
  version: uuidv1(),
  global: GLOBAL_VARIABLE_STASH_ITEM,
});

/**
 * Extract all the variables out of the given stash so it can be used in a dropdown
 */
type VariableOption = OptionOf<{
  stashItem: FlowVariableStashItem;
  outputObject: FlowOutputObject;
}>;
export const getVariableOptions = (
  stash: FlowVariableStash,
): Array<VariableOption> => {
  const objectList: Array<VariableOption> = [];

  Object.keys(stash).forEach(key => {
    if (key !== 'version') {
      const stashItem = stash[key];

      stashItem.outputObjects.forEach(outputObject => {
        objectList.push({
          label: `${outputObject.objectLabel} uit '${stashItem.variableLabel}'`,
          payload: {
            stashItem,
            outputObject,
          },
          key: `${stashItem.variableName}-${outputObject.name}`,
        });
      });
    }
  });

  return objectList;
};

/**
 * Extract all the different types of output objects from the given stash so it can be used in a dropdown
 */
export const getOutputObjectTypeOptions = (
  stash: FlowVariableStash,
): Array<OptionOf<FlowOutputObject>> => {
  const objectList: Array<OptionOf<FlowOutputObject>> = [];

  Object.keys(stash).forEach(key => {
    if (key !== 'version') {
      stash[key].outputObjects.forEach(outputObject => {
        const key = outputObject.name;

        if (objectList.findIndex(obj => obj.key === key) < 0) {
          objectList.push({
            label: outputObject.objectLabel,
            payload: outputObject,
            key,
          });
        }
      });
    }
  });

  return objectList;
};

/**
 * A pointer points to a variable (which indicates the step) and a field.
 * The field is a dot-separated concatenation of the outputobject (e.g. Contact) and field (e.g. name)
 *
 * This function splits the object name in the two parts or returns null if it can't be split
 */
const splitObjectName = (
  val: string,
): null | { objectName: string; fieldName: string } => {
  const fieldStartIndex = val.indexOf('.');

  if (fieldStartIndex == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} >> splitObjectName -> Cannot find a '.' in the field name. We expect the field to consist of at least one '.' to determine the type (before the '.') and the field (after the first '.')`,
    );
  }

  const objectName = val.slice(0, fieldStartIndex);
  const fieldName = val.slice(fieldStartIndex + 1, val.length);

  return { objectName, fieldName };
};

export const getVariableDescriptorForPointer = (
  pointer: { variable: null | PointerVariable },
  stash: FlowVariableStash,
): null | {
  stashItem: FlowVariableStashItem;
  outputObject: null | FlowOutputObject;
  field: null | FlowOutputObjectField;
} => {
  const { variable } = pointer;
  if (variable == null) return null;

  const stashItem = stash[variable.name];
  if (stashItem == null) return null;

  const splitName =
    variable.field == null ? null : splitObjectName(variable.field.name || '');

  if (splitName == null) return { stashItem, outputObject: null, field: null };

  const { objectName, fieldName } = splitName;

  const outputObject = stashItem.outputObjects.find(
    outputObject => outputObject.name === objectName,
  );
  const field =
    outputObject && outputObject.fields.find(field => field.name === fieldName);

  return {
    stashItem,
    outputObject: outputObject || null,
    field: field || null,
  };
};
