import React from 'react';
import styled, { css } from 'styled-components';
import Input from '../../../Input';
import { AppBbWaardecheckLink__Input } from '~/graphql/types';
import InputGroup from '~/scenes/Apps/components/InputGroup';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import Description from '../Description';
import ControlledInput, {
  UrlValidation,
} from '~/scenes/Apps/components/ControlledInput';

export type Props = {
  id: string;
  dataTestId?: string;
  title?: string;
  description?: string;
  value: AppBbWaardecheckLink__Input;
  onChange: (value: AppBbWaardecheckLink__Input) => void;
  defaultValue: AppBbWaardecheckLink__Input;
};

const LANG = {
  reset: 'Standaardtekst gebruiken',
  labelNl: 'Tekst Nederlands',
  labelEn: 'Tekst Engels',
};

const IntlLinkInputComponent: React.FC<Props> = ({
  dataTestId,
  id,
  title,
  description,
  value,
  defaultValue,
  onChange,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    {title && <AppEntryItemHeader>{title}</AppEntryItemHeader>}
    {description && <Description>{description}</Description>}
    <InputGroup>
      <Input
        value={value.text.nl}
        onChange={nl => onChange({ ...value, text: { ...value.text, nl } })}
        label={LANG.labelNl}
        reset={LANG.reset}
        onReset={() =>
          onChange({
            ...value,
            text: { ...value.text, nl: defaultValue.text.nl },
          })
        }
        dataTestId={`${dataTestId}-nl`}
      />
      <Input
        value={value.text.en}
        onChange={en => onChange({ ...value, text: { ...value.text, en } })}
        label={LANG.labelEn}
        reset={LANG.reset}
        onReset={() =>
          onChange({
            ...value,
            text: { ...value.text, en: defaultValue.text.en },
          })
        }
        dataTestId={`${dataTestId}-en`}
      />
    </InputGroup>
    <InputGroup>
      <ControlledInput
        label="Nederlandse link"
        placeholder="https://..."
        id={`${id}.href.nl`}
        value={value.href.nl}
        validation={[UrlValidation]}
        onChange={nl =>
          onChange && onChange({ ...value, href: { ...value.href, nl } })
        }
        dataTestId={`${dataTestId}-href-nl`}
      />
      <ControlledInput
        label="Engelse link"
        placeholder="https://..."
        id={`${id}.href.en`}
        value={value.href.en}
        validation={[UrlValidation]}
        onChange={en =>
          onChange && onChange({ ...value, href: { ...value.href, en } })
        }
        dataTestId={`${dataTestId}-href-en`}
      />
    </InputGroup>
  </Container>
);

const Container = styled.div<{}>(() => css``);

export default IntlLinkInputComponent;
