import { isEmpty, isNil, slice, take } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import RepresentationString from '~/scenes/Automation/v2/components/RepresentationString';
import { Trigger } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor';
import { ConditionIssue } from '~/scenes/Automation/v2/state/flowIssues';

export type Props = {
  dataTestId?: string;
  trigger: Trigger;
  actionId: string;
  issueMap: Record<string, Array<ConditionIssue>>;
};

const NUMBER_OF_VISIBLE_CONDITIONS = 2;
const SubCondition: React.FC<Props> = ({
  dataTestId,
  trigger,
  actionId,
  issueMap,
  ...rest
}) => {
  const conditions = trigger.condition.conditions;

  if (isEmpty(conditions)) return null;

  const visibleConditions = take(NUMBER_OF_VISIBLE_CONDITIONS, conditions);
  const collapsedConditions = slice(
    NUMBER_OF_VISIBLE_CONDITIONS,
    conditions.length,
    conditions,
  );

  return (
    <>
      {visibleConditions.map(condition => {
        const firstIssue = issueMap[trigger.id]?.find(
          ({ conditionId }) => conditionId === condition?.id,
        );
        const hasIssue = !isNil(firstIssue);
        return (
          <Container data-testid={dataTestId} {...rest} key={condition?.id}>
            <div>
              <ConditionContainer $hasIssue={hasIssue}>
                <RepresentationString
                  parentCondition={trigger.trigger}
                  condition={condition}
                  actionId={actionId}
                  hasIssue={hasIssue}
                />
              </ConditionContainer>
              {hasIssue && (
                <IssueMessage>
                  <strong>Foutmelding:</strong> {firstIssue?.message}
                </IssueMessage>
              )}
            </div>
          </Container>
        );
      })}

      {!isEmpty(collapsedConditions) && (
        <CollapsedConditionsContainer>{`... ${collapsedConditions.length} meer`}</CollapsedConditionsContainer>
      )}
    </>
  );
};

const ConditionContainer = styled.div<{ $hasIssue: boolean }>(
  ({ theme, $hasIssue }) => css`
    padding: ${theme.space('xxs')};

    background-color: ${$hasIssue
      ? theme.color('danger', 'translucent')
      : theme.color('warning', 'light')};
    border-radius: ${$hasIssue
      ? `${theme.getTokens().border.radius.base} ${
          theme.getTokens().border.radius.base
        } 0 0`
      : theme.getTokens().border.radius.base};
  `,
);

const Container = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.space('xxs')} ${theme.space('xxs')} ${theme.space('xxs')};
    display: flex;
    flex-direction: column;
    gap: ${theme.space('xxs')};
  `,
);

const IssueMessage = styled.p(
  ({ theme }) => css`
    margin: 0;
    padding: ${theme.space('xxs')};
    background-color: ${theme.color('danger')};
    color: ${theme.color('danger', 'text')};
    border-radius: 0 0 ${theme.getTokens().border.radius.base}
      ${theme.getTokens().border.radius.base};
  `,
);

const CollapsedConditionsContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.space('xxs')};
    margin-left: ${theme.space('xxxs')};
  `,
);

export default SubCondition;
