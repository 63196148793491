import React from 'react';
import { DHEditor } from '~/components/PluginsEditor/types';
import { IconType } from '~/components/Icon';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import BaseButton from '../components/Base';
import { tooltipText } from '../text';
import {
  isBlockActive,
  toggleBlock,
} from '~/components/PluginsEditor/commands/modify/block';
import { toggleList } from '~/components/PluginsEditor/commands/modify/list';

export type Props = {
  dataTestId?: string;
  editor: DHEditor;
  format: ELEMENTS.UL | ELEMENTS.OL | ELEMENTS.BLOCKQUOTE;
  icon: IconType;
};

const BlockButton: React.FC<Props> = ({ dataTestId, editor, format, icon }) => (
  <BaseButton
    dataTestId={dataTestId}
    icon={icon}
    onClick={e => {
      e.preventDefault();

      if (format === ELEMENTS.UL || format === ELEMENTS.OL)
        return toggleList(editor, format);

      toggleBlock(editor, format);
    }}
    active={isBlockActive(editor, format)}
    tooltipMessage={tooltipText[format]}
  />
);

export default BlockButton;
