import React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';

type Props = {
  /** Set data-testid */
  testId?: string;

  /** Renders a button text */
  children: React.ReactNode;

  /** Event handler for click */
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

  /** If the button is disabled */
  disabled?: boolean;

  /** Do not set the color of the text, the using component can set it */
  useParentFontColor?: boolean;

  /** For styled components */
  className?: string;
};

const LinkButton = ({
  className,
  testId,
  disabled,
  children,
  onClick,
  useParentFontColor,
}: Props) => (
  <Link
    className={className || ''}
    data-testid={testId}
    disabled={disabled}
    onClick={event => {
      if (!disabled && onClick) onClick(event);
    }}
    useParentFontColor={useParentFontColor}
  >
    {children}
  </Link>
);

const Link = styled.span<Props>`
  text-decoration: underline;
  display: inline-flex;

  ${({ disabled }) => {
    if (!disabled) {
      return css`
        cursor: pointer;
      `;
    }

    return css`
      cursor: default;
    `;
  }};

  ${({ theme, useParentFontColor, disabled }) => {
    if (!useParentFontColor) {
      return css`
        color: ${disabled
          ? theme.color('grey')
          : theme.color('primary', 'light')};

        &:hover {
          color: ${disabled
            ? theme.color('grey')
            : darken(0.2, theme.color('primary', 'light'))};
        }
      `;
    }

    return '';
  }}
`;

export default LinkButton;
