import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Color from 'color';

import { calculateSpaceInsideComponent } from '../util/getSizeCalculation';
import { ChromePicker } from 'react-color';
import HidableComponent from '../HidableComponent';
import useRepositionToBeInView from '../util/useRepositionToBeInView';
import { animated, useTransition } from 'react-spring';
import useDebounce from '~/hooks/useDebounce';
import Icon from '../Icon';

export type Props = {
  /**
   * Get picked color, this value is debounced, so between
   * changing the color and this callback being executed
   * there might be a delay.
   */
  onChange?: (color: string) => void;

  /**
   * Set initial color in hexrgb '#ffffff' format.
   *
   * If a new color is given this will reset the color
   * picker to that color.
   */
  initialColor: string;

  /** The data-testid to add to the container to identify the dropdown */
  dataTestid?: string;

  /** Disable the input */
  disabled?: boolean;

  /** Does not allow to change a color */
  readOnly?: boolean;
};
const ColorPickerComponent: React.FC<Props> = ({
  initialColor,
  onChange,
  readOnly = false,
  disabled = false,
  dataTestid,
}) => {
  const color = sanitiseColor(initialColor || '#fff');
  const initialRender = useRef(true);
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState<string>(color);
  const debouncedColor = useDebounce(selectedColor);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    onChange && onChange(debouncedColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedColor]);

  const colorRGB = Color(selectedColor).rgb().object();

  // This allows us to cancel changes
  useEffect(() => {
    setSelectedColor(color);
  }, [color]);

  const [componentRef, transform, recalculatePosition] =
    useRepositionToBeInView();

  useLayoutEffect(() => {
    if (displayColorPicker) {
      recalculatePosition();
    }
  }, [displayColorPicker, recalculatePosition]);

  const transitions = useTransition(displayColorPicker, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 110,
      friction: 14,
      mass: 1,
      duration: 250,
    },
  });

  const isWhite = Color(selectedColor).white() === 100;

  return (
    <Container>
      <LabelContainer data-testid={dataTestid}>
        <DisplayElement
          onClick={() => setDisplayColorPicker(true)}
          data-testid="color-picker-display"
          isWhite={isWhite}
          color={selectedColor}
          disabled={disabled}
          $readOnly={readOnly}
        >
          <TextWrapper data-testid="color-picker-text" $readOnly={readOnly}>
            <ColorCode color={selectedColor} isActive={displayColorPicker}>
              {selectedColor}
            </ColorCode>
          </TextWrapper>
          {readOnly === false && (
            <SlidersWrapper isWhite={isWhite}>
              <Icon name="sliders" />
            </SlidersWrapper>
          )}
        </DisplayElement>
        {displayColorPicker && readOnly === false && (
          <HidableComponent
            onClickOutside={() => setDisplayColorPicker(false)}
            allowBubble
          >
            {ref => {
              const storeAndForwardRef = (r: null | HTMLElement) => {
                componentRef.current = r;
                ref.current = r;
              };

              return (
                <>
                  {transitions(
                    (style, item) =>
                      item && (
                        <PickerElement
                          ref={storeAndForwardRef}
                          $translateX={transform.x}
                          $translateY={transform.y}
                          style={style}
                        >
                          <ChromePicker
                            // @ts-ignore
                            color={colorRGB}
                            onChange={color => {
                              setSelectedColor(
                                sanitiseColor(Color(color.hex).hex()),
                              );
                            }}
                            data-testid="color-picker-element"
                            disableAlpha
                          />
                        </PickerElement>
                      ),
                  )}
                </>
              );
            }}
          </HidableComponent>
        )}
      </LabelContainer>
    </Container>
  );
};

/**
 * The backend expects a lowercased hexrgb value
 *
 * #FFF -> #fff
 *
 * @param color A hex rgb value
 */
const sanitiseColor = (color: string): string => color.toLocaleLowerCase();

const Container = styled.div<{}>`
  display: flex;
  align-items: flex-end;
  min-width: 150px;
  width: 100%;
`;

const LabelContainer = styled.div<{}>`
  width: 100%;
`;

type DisplayElementProps = {
  color: string;
  isActive?: boolean;
  disabled?: boolean;
  isWhite: boolean;
  $readOnly?: boolean;
};

const DisplayElement = styled.div<DisplayElementProps>(
  ({ color, isWhite, theme, disabled, $readOnly }) => css`
    display: flex;
    cursor: ${$readOnly ? 'auto' : 'pointer'};
    align-items: center;
    width: 100%;
    transition: box-shadow 0.3s;
    padding-left: 0 ${theme.space('s')};
    border-radius: ${theme.getTokens().border.radius.base};
    border: ${isWhite
      ? `1px solid ${theme.color('grey')}`
      : `1px solid ${color}`};

    background: ${color};
    &:hover,
    :focus {
      ${({ theme }) =>
        !disabled &&
        css`
          box-shadow: ${theme.boxShadow('base')};
        `};
    }

    ${() => {
      if (disabled) {
        return css`
          pointer-events: none;
          opacity: 0.6;
        `;
      }
      return null;
    }};
  `,
);

const SlidersWrapper = styled.div<{ isWhite?: boolean }>`
  ${({ theme, isWhite }) => css`
    background: ${isWhite
      ? theme.color('grey', 'light')
      : theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xxs')};
    margin: 5px 5px 5px 0;
    width: ${theme.space('xxl')};
    height: ${theme.space('xxl')};

    & > span {
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: ${theme.fs('l')};
    }
  `};
`;

const TextWrapper = styled.div<{ $readOnly?: boolean }>(
  ({ theme, $readOnly }) => css`
    flex-grow: 1;
    padding: ${$readOnly
      ? theme.space('m')
      : `${calculateSpaceInsideComponent(theme)}px`};
  `,
);

const ColorCode = styled.span<{ color: string; isActive: boolean }>(
  ({ color, isActive, theme }) => {
    const isDark = Color(color).isDark();

    return css`
      display: inline-block;
      color: ${isDark ? theme.color('white') : theme.color('text')};
      transform: ${!isActive ? 'scale(1.05, 1.15)' : 'scale(1, 1)'};
      transition: transform 0.3s;
    `;
  },
);

type PickerElementProps = {
  $translateX: number;
  $translateY: number;
};

const PickerElement = styled(animated.div)<PickerElementProps>`
  position: absolute;

  ${({ $translateX, $translateY, theme }) => css`
    transform: translate(${$translateX}px, ${$translateY}px);
    z-index: ${theme.z('top')};

    & > .chrome-picker {
      border-radius: ${theme.getTokens().border.radius.base} !important;
      box-shadow: ${theme.boxShadow('base')} !important;
      padding: ${theme.space('m')};
      & > div {
        border-radius: 0 !important;
      }
    }
  `}
`;

export default ColorPickerComponent;
