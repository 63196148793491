/**
 *
 * Chevrons-down icon
 * Taken from: https://feathericons.com/
 */
import { Props } from '..';
import React from 'react';

const DoubleChevron: React.FC<Props> = ({
  color = 'currentColor',
  ...props
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="2 2 22 22"
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevrons-down"
    {...props}
  >
    <polyline points="7 13 12 18 17 13" />
    <polyline points="7 6 12 11 17 6" />
  </svg>
);

export default DoubleChevron;
