import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  dataTestId?: string;
};

const AppEntryItemHeader: React.FC<Props> = ({ dataTestId, ...rest }) => (
  <Container data-testid={dataTestId} {...rest} />
);

const Container = styled.h3<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('m')};
    margin: ${theme.space('s')} 0;
    color: ${theme.color('text')};
  `,
);

export default AppEntryItemHeader;
