import {
  Field,
  StringField,
  StringParameterField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { emptyStringPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

export default (field: Field | StringField): StringParameterField => ({
  ...field,
  type: FIELD_TYPE.STRING,
  value: emptyStringPrimitiveParameterValue(),
});
