import React from 'react';

import { ActionSubscriber } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';

import { SetContactDetailsComponent } from '../SetContactDetailsAction';
import { StartActionComponent } from '../StartAction';
import { WaitActionComponent } from '../WaitAction';
import { CreateTaskActionComponent } from '../CreateTaskAction';
import { AddContactTagActionComponent } from '../AddContactTagAction';
import { DeleteContactTagActionComponent } from '../DeleteContactTagAction';
import { SendEmailPlainActionComponent } from '../SendEmailPlainAction';
import { IfElseActionComponent } from '../IfElseAction';
import { AssignContactActionComponent } from '../AssignContactAction';
import AddAction from '~/scenes/Automation/Flows/components/AddAction';

import cleanedFilename from '~/util/cleanedFilename';
import IfElseTree from '~/scenes/Automation/Flows/Actions/IfElseAction/components/IfElseTree';
import { assertNever, assertNeverWithoutThrowing } from '~/util/assertion';
import { RealworksSendContactActionComponent } from '../RealworksSendContactAction';
import { ZapierTriggerActionComponent } from '../ZapierTriggerAction';

export type AnnounceChangesOptions = {
  updateVariableStash?: boolean;

  /** Do not trigger a data change, but do trigger a UI change */
  noDataChange?: boolean;
};
const renderActionTree = (
  actionTree: ActionTreeNode,
  subscribe: (newSubscriber: ActionSubscriber) => string,
  unsubscribe: (removeKey: string) => void,
  resetCounter: number,
  announceChanges: (options?: AnnounceChangesOptions) => void,
  addAction:
    | ((actionType: FLOW_ACTION_TYPE, underneathActionId: string) => void)
    | undefined,
  isHiddenFromView: boolean,
): Array<React.ReactNode> => {
  const { id, initialActionProps } = actionTree;
  const underneathActionProps = {
    underneathActionId: id,
    underneathActionType: initialActionProps.type,
    underneathActionVisiblePath: false,
    hasActionUnderneath: actionTree.children.length > 0,
  };
  if (initialActionProps.type === FLOW_ACTION_TYPE.IF_ELSE) {
    if (actionTree.subscriber != null) {
      const prop = actionTree.subscriber.getActionProp();

      if (prop.type === FLOW_ACTION_TYPE.IF_ELSE) {
        underneathActionProps.underneathActionVisiblePath = prop.visiblePath;
        underneathActionProps.hasActionUnderneath = prop.visiblePath
          ? prop.yesPathChildId != null
          : prop.noPathChildId != null;
      }
    } else {
      // default is true, cannot have been changed if subscriber not there yet
      underneathActionProps.underneathActionVisiblePath = true;
      underneathActionProps.hasActionUnderneath =
        initialActionProps.yesPathChildId != null;
    }
  }
  const toAddComponent = (
    <AddAction
      {...underneathActionProps}
      key={`${id}-add-action`}
      onAdd={
        addAction == null
          ? undefined
          : type =>
              new Promise<void>(resolve => {
                addAction(type, id);

                resolve();
              })
      }
    />
  );

  const actionComponent = componentSwitcher(
    actionTree,
    subscribe,
    unsubscribe,
    resetCounter,
    announceChanges,
    isHiddenFromView,
  );

  switch (initialActionProps.type) {
    case FLOW_ACTION_TYPE.SET_CONTACT_DETAILS:
    case FLOW_ACTION_TYPE.START:
    case FLOW_ACTION_TYPE.WAIT:
    case FLOW_ACTION_TYPE.CREATE_TASK:
    case FLOW_ACTION_TYPE.ADD_CONTACT_TAG:
    case FLOW_ACTION_TYPE.DELETE_CONTACT_TAG:
    case FLOW_ACTION_TYPE.ASSIGN_CONTACT:
    case FLOW_ACTION_TYPE.SENDCONTACT_TO_REALWORKS:
    case FLOW_ACTION_TYPE.ZAPIER_TRIGGER:
    case FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN: {
      if (actionTree.children.length > 1) {
        throw Error(
          `${cleanedFilename(__filename)} | Should not occur: action of type ${
            initialActionProps.type
          } has more than 1 child (${actionTree.children.length})`,
        );
      }

      let childComponent: Array<React.ReactNode> | null = null;
      if (actionTree.children.length === 1) {
        childComponent = renderActionTree(
          actionTree.children[0],
          subscribe,
          unsubscribe,
          resetCounter,
          announceChanges,
          addAction,
          isHiddenFromView,
        );
      }

      return [actionComponent, toAddComponent, childComponent];
    }
    case FLOW_ACTION_TYPE.IF_ELSE: {
      const { subscriber } = actionTree;

      let visiblePath = true;
      let yesPathChildId: string | null = null;
      let noPathChildId: string | null = null;
      if (subscriber != null) {
        const actionProp = subscriber.getActionProp();

        if (actionProp.type !== FLOW_ACTION_TYPE.IF_ELSE) {
          throw Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur: actionProp.type (${
              actionProp.type
            }) is not IF_ELSE?`,
          );
        }

        visiblePath = actionProp.visiblePath;
        yesPathChildId = actionProp.yesPathChildId;
        noPathChildId = actionProp.noPathChildId;
      } else {
        yesPathChildId = initialActionProps.yesPathChildId;
        noPathChildId = initialActionProps.noPathChildId;
      }

      const children = actionTree.children;
      const yesPathChild = children.find(child => child.id === yesPathChildId);
      const noPathChild = children.find(child => child.id === noPathChildId);

      let yesPathComponent: Array<React.ReactNode> | null = null;
      if (yesPathChild != null) {
        yesPathComponent = renderActionTree(
          yesPathChild,
          subscribe,
          unsubscribe,
          resetCounter,
          announceChanges,
          addAction,
          visiblePath === false,
        );
      }
      let noPathComponent: Array<React.ReactNode> | null = null;
      if (noPathChild != null) {
        noPathComponent = renderActionTree(
          noPathChild,
          subscribe,
          unsubscribe,
          resetCounter,
          announceChanges,
          addAction,
          visiblePath === true,
        );
      }

      return [
        <IfElseActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={`${id}-${resetCounter}`}
          initialActionProps={initialActionProps}
        />,
        toAddComponent,
        <IfElseTree
          key={`${id}-tree-container`}
          yesPathChild={yesPathComponent}
          noPathChild={noPathComponent}
          visiblePath={visiblePath}
          ifElseActionId={id}
        />,
      ];
    }
    default:
      return assertNever(initialActionProps, `${cleanedFilename(__filename)}`);
  }
};

export const getBaseActionProps = (
  actionTree: ActionTreeNode,
  subscribe: (newSubscriber: ActionSubscriber) => string,
  unsubscribe: (removeKey: string) => void,
  resetCounter: number,
  announceChanges: (options?: AnnounceChangesOptions) => void,
  isHiddenFromView: boolean,
) => {
  const { id, positionString, outputLoading } = actionTree;

  return {
    key: `${id}-${resetCounter}`,
    id: id,
    positionString,
    outputLoading,
    subscribe,
    unsubscribe,
    announceChanges,
    isHiddenFromView,
  };
};

const componentSwitcher = (
  actionTree: ActionTreeNode,
  subscribe: (newSubscriber: ActionSubscriber) => string,
  unsubscribe: (removeKey: string) => void,
  resetCounter: number,
  announceChanges: (options?: AnnounceChangesOptions) => void,
  isHiddenFromView: boolean,
): React.ReactNode => {
  const { initialActionProps } = actionTree;

  switch (initialActionProps.type) {
    case FLOW_ACTION_TYPE.SET_CONTACT_DETAILS:
      return (
        <SetContactDetailsComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.START:
      return (
        <StartActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.WAIT:
      return (
        <WaitActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.CREATE_TASK:
      return (
        <CreateTaskActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.ADD_CONTACT_TAG:
      return (
        <AddContactTagActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.DELETE_CONTACT_TAG:
      return (
        <DeleteContactTagActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN:
      return (
        <SendEmailPlainActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.IF_ELSE:
      return (
        <IfElseActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.ASSIGN_CONTACT:
      return (
        <AssignContactActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.SENDCONTACT_TO_REALWORKS:
      return (
        <RealworksSendContactActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    case FLOW_ACTION_TYPE.ZAPIER_TRIGGER:
      return (
        <ZapierTriggerActionComponent
          {...getBaseActionProps(
            actionTree,
            subscribe,
            unsubscribe,
            resetCounter,
            announceChanges,
            isHiddenFromView,
          )}
          key={initialActionProps.id}
          initialActionProps={initialActionProps}
        />
      );
    default:
      assertNeverWithoutThrowing(
        initialActionProps,
        cleanedFilename(__filename),
      );
      return null;
  }
};

export default renderActionTree;
