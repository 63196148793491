import React from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/Button';
import TEST_ID from './index.testid';
import TextButton from '~/components/TextButton';
import JustificationContainer from '~/components/JustificationContainer';

const text = {
  goBack: 'Ga Terug',
  cancel: 'Annuleer',
};
type Props = {
  onGoBack?: () => void;
  nextStepButton?: string;
  onContinue?: () => void;
  onCancel?: () => void;
  loading?: boolean;
  disabled?: boolean;
  submitButton?: boolean;
  accentButton?: boolean;
};
const BottomSectionContainer: React.FC<Props> = ({
  onGoBack,
  nextStepButton,
  onContinue,
  children,
  onCancel,
  loading,
  disabled,
  submitButton,
  accentButton,
}) => (
  <Container align="center" wrap="wrap" justification="space-between">
    {onGoBack && (
      <StyledTextButton
        size="medium"
        label={text.goBack}
        onClick={onGoBack}
        data-testid={TEST_ID.GO_BACK_BUTTON}
      />
    )}
    <ButtonContainer>
      {onCancel && (
        <Button size="medium" ghost onClick={onCancel} label={text.cancel} />
      )}
      {nextStepButton && (
        <Button
          size="medium"
          appearance={accentButton ? 'accent' : 'secondary'}
          onClick={onContinue}
          loading={loading}
          data-testid={TEST_ID.NEXT_STEP_BUTTON}
          disabled={disabled}
          type={submitButton ? 'submit' : 'button'}
          label={nextStepButton}
        />
      )}
    </ButtonContainer>
    {children}
  </Container>
);

const StyledTextButton = styled(TextButton)`
  padding-left: 0;
`;

const Container = styled(JustificationContainer)<{}>`
  align-self: flex-end;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.space('l')} ${theme.space('xxxxl')};
    background-color: ${theme.color('grey', 'translucent')};
  `};
`;

const ButtonContainer = styled.div<{}>`
  align-self: center;

  ${({ theme }) => css`
    button {
      margin-left: ${theme.space('m')};
    }
  `};
`;

export default BottomSectionContainer;
