import { isEmpty, filter, propEq } from 'ramda';
import {} from 'react';
import { AccountRelationship } from '~/graphql/types';
import useSessionHydration from '../useSessionHydration';

type Props = {
  accountId?: string;
  userId: string;
};

const useUserRelationToAccount = ({
  accountId,
  userId,
}: Props): AccountRelationship | null => {
  const [{ relations, account: currentAccount }] = useSessionHydration();

  const _accountId = accountId || currentAccount.id;

  if (isEmpty(_accountId) || isEmpty(userId) || isEmpty(relations)) return null;

  const accountRelations = filter(
    propEq('__typename', 'AccountRelationship'),
    relations,
  ) as Array<AccountRelationship>;

  const filteredRelations = accountRelations.filter(
    relation => relation.userId === userId && relation.accountId === _accountId,
  );

  return filteredRelations[0] ?? null;
};

export default useUserRelationToAccount;
