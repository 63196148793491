import React, { ReactElement } from 'react';

import {
  BooleanParameterValue,
  BooleanPrimitiveParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { PARAMETER_VALUE_TYPE } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/constants';
import { Dropdown } from '~/components/Inputs';
import Catalog from '~/Catalog';
import { emptyBooleanPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import BooleanPrimitiveParameterValueComponent from './BooleanPrimitiveParameterValueComponent';
import TEST_ID from './BooleanParameterValueComponent.testid';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import { getStashVariablesInStash } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';

import cleanedFilename from '~/util/cleanedFilename';
import getVariableWithPrimitiveDropdownProps from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/util/getVariableWithPrimitiveDropdownProps';
import getFieldDropdownProps from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/util/getFieldDropdownProps';
import validateStashVariable from '../util/validateStashVariable';

const text = {
  variableLabel: Catalog.flows.pointerVariableLabel,
  fieldLabel: Catalog.flows.pointerFieldLabel,
  requiredField: Catalog.requiredField,
};
type ValueType = null | BooleanParameterValue;
type MyProps = {
  value: ValueType;
  onChange: (newValue: BooleanParameterValue) => void;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
  primitiveValueCache: BooleanPrimitiveParameterValue;
};
class BooleanParameterValueComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { value } = this.props;
    const defaultPrimitiveValueCache =
      value != null && value.type === PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE
        ? value
        : emptyBooleanPrimitiveParameterValue();

    this.state = {
      key: null,
      primitiveValueCache: defaultPrimitiveValueCache,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  setPrimitiveValueCache = (
    newPrimitiveValue: BooleanPrimitiveParameterValue,
  ) => {
    this.setState({
      primitiveValueCache: newPrimitiveValue,
    });
  };

  validate = (): boolean => {
    const { value } = this.props;

    if (
      value == null ||
      value.type === PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE
    ) {
      return true;
    } else {
      return validateStashVariable(
        value.variable,
        this.getPossibleVariables(),
        FLOW_OUTPUT_TYPE.FlowOutputBoolean,
      );
    }
  };

  getPossibleVariables = () => {
    const { baseActionContext } = this.props;
    const { variableStash } = baseActionContext;

    return getStashVariablesInStash(
      variableStash,
      undefined,
      FLOW_OUTPUT_TYPE.FlowOutputBoolean,
    );
  };

  render() {
    const { onChange, value } = this.props;
    const { primitiveValueCache } = this.state;

    const {
      variableOptions,
      selectedVariableIdx,
      selectedVariableOption,
      onChangeVariable,
    } = getVariableWithPrimitiveDropdownProps<BooleanParameterValue>(
      this.getPossibleVariables(),
      value,
      () => {
        onChange({ ...primitiveValueCache });
      },
      newVariableName => {
        onChange({
          type: PARAMETER_VALUE_TYPE.BOOLEAN_POINTER,
          variable: { name: newVariableName, field: null },
        });
      },
    );

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => {
          let inputComponent: ReactElement | null = null;
          let dropdownComponent: ReactElement | null = null;

          if (value != null) {
            if (value.type === PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE) {
              return (
                <BooleanPrimitiveParameterValueComponent
                  value={value}
                  onChange={newPrimitiveValue => {
                    this.setPrimitiveValueCache(newPrimitiveValue);
                    onChange(newPrimitiveValue);
                  }}
                />
              );
            } else if (value.type === PARAMETER_VALUE_TYPE.BOOLEAN_POINTER) {
              const {
                fieldOptions,
                selectedFieldIdx,
                selectedFieldOption,
                onChangeField,
              } = getFieldDropdownProps(
                selectedVariableOption,
                value,
                (newFieldName: string) => {
                  if (value.variable == null) {
                    throw Error(
                      `${cleanedFilename(
                        __filename,
                      )} | Should not occur | Changing field even though variable has not been chosen yet!`,
                    );
                  }

                  const newValue: BooleanParameterValue = {
                    type: PARAMETER_VALUE_TYPE.BOOLEAN_POINTER,
                    variable: {
                      name: value.variable.name,
                      field: {
                        name: newFieldName,
                      },
                    },
                  };

                  onChange(newValue);
                },
                FLOW_OUTPUT_TYPE.FlowOutputBoolean,
                FLOW_OUTPUT_TYPE.FlowOutputBoolean,
              );

              inputComponent = (
                <Dropdown
                  dataTestid={TEST_ID.FIELD_DROPDOWN}
                  selectOnlyOptionAutomatically
                  error={
                    showValidation && selectedFieldOption == null
                      ? text.requiredField
                      : ''
                  }
                  label={text.fieldLabel}
                  options={fieldOptions}
                  selectedOptionIdx={selectedFieldIdx}
                  onChange={onChangeField}
                />
              );

              /**
               * Pointers in the comparison are a legacy option
               * Show the dropdown to change to primitive, but only if the pointer already existed
               */
              dropdownComponent = (
                <Dropdown
                  dataTestid={TEST_ID.VARIABLE_DROPDOWN}
                  selectOnlyOptionAutomatically
                  error={
                    showValidation && selectedVariableOption == null
                      ? Catalog.requiredField
                      : ''
                  }
                  label={text.variableLabel}
                  options={variableOptions}
                  selectedOptionIdx={selectedVariableIdx}
                  onChange={onChangeVariable}
                />
              );
            }
          }

          return (
            <>
              {dropdownComponent}
              {inputComponent}
            </>
          );
        }}
      </FlowContext.Consumer>
    );
  }
}

export default withBaseActionContext<MyProps>(BooleanParameterValueComponent);
