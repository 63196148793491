import React, { ReactElement } from 'react';

import { AddContactTagAction } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter';

const describeAddContactTagAction = (
  action: AddContactTagAction,
  describeOptions: DescribeOptions,
): ReactElement => {
  const { name } = action;

  return (
    <span>
      Voeg een tag toe met {describeParameterValue(name, describeOptions)}
    </span>
  );
};

export default describeAddContactTagAction;
