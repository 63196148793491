/**
 * Convert DOM attributes into the shape that React expects
 * @param {string} x - Example argument
 * keywords: html attributes, react props
 */

// Info from: https://github.com/facebook/react/blob/aa8f2bdbce0aa8d5128461190f7ea40f891cb78e/packages/react-dom/src/shared/DOMProperty.js#L212

// type PropertyType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

// A reserved attribute.
// It is handled by React separately and shouldn't be written to the DOM.
// export const RESERVED = 0;

// A simple string attribute.
// Attributes that aren't in the filter are presumed to have this type.
// const STRING = 1;

// A string attribute that accepts booleans in React. In HTML, these are called
// "enumerated" attributes with "true" and "false" as possible values.
// When true, it should be set to a "true" string.
// When false, it should be set to a "false" string.
// const BOOLEANISH_STRING = 2;

// A real boolean attribute.
// When true, it should be present (set either to an empty string or its name).
// When false, it should be omitted.
// const BOOLEAN = 3;

// An attribute that can be used as a flag as well as with a value.
// When true, it should be present (set either to an empty string or its name).
// When false, it should be omitted.
// For any other value, should be present with that value.
// const OVERLOADED_BOOLEAN = 4;

// An attribute that must be numeric or parse as a numeric.
// When falsy, it should be removed.
// const NUMERIC = 5;

// An attribute that must be positive numeric or parse as a positive numeric.
// When falsy, it should be removed.
// const POSITIVE_NUMERIC = 6;

/**
 * Taken from html-react-parser https://github.com/remarkablemark/html-react-parser/blob/master/lib/attributes-to-props.js
 */
import { AllHTMLAttributes } from 'react';

import {
  isCustomAttribute,
  getPropertyInfo,
  possibleStandardNames,
  BOOLEAN,
  OVERLOADED_BOOLEAN,
} from 'react-property';
import { setStyleProp, PRESERVE_CUSTOM_ATTRIBUTES } from './utils/index';

const convertDomAttrsToReactProps = (
  attrs: NamedNodeMap,
): { [key: string]: any } => {
  const attrsObj = [...attrs].reduce(
    (acc, attr) => ({ ...acc, [attr.name]: attr.value }),
    {},
  );

  const attributes: AllHTMLAttributes<any> = attrsObj || {};

  let attributeName;
  let attributeNameLowerCased;
  let attributeValue;
  let propName;
  let propertyInfo;
  const props = {};

  for (attributeName in attributes) {
    attributeValue = attributes[attributeName];

    // ARIA (aria-*) or custom data (data-*) attribute
    if (isCustomAttribute(attributeName)) {
      props[attributeName] = attributeValue;
      continue;
    }

    // convert HTML/SVG attribute to React prop
    attributeNameLowerCased = attributeName.toLowerCase();
    propName = getPropName(attributeNameLowerCased);

    if (propName) {
      propertyInfo = getPropertyInfo(propName);

      // convert attribute to uncontrolled component prop (e.g., `value` to `defaultValue`)
      // https://reactjs.org/docs/uncontrolled-components.html
      if (propName === 'checked' || propName === 'value') {
        propName = getPropName('default' + attributeNameLowerCased);
      }

      props[propName] = attributeValue;

      switch (propertyInfo && propertyInfo.type) {
        case BOOLEAN:
          props[propName] = true;
          break;
        case OVERLOADED_BOOLEAN:
          if (attributeValue === '') {
            props[propName] = true;
          }
          break;
      }
      continue;
    }

    // preserve custom attribute if React >=16
    if (PRESERVE_CUSTOM_ATTRIBUTES) {
      props[attributeName] = attributeValue;
    }
  }

  // transform inline style to style object
  setStyleProp(attributes.style, props);

  return props;
};

function getPropName(attributeName) {
  return possibleStandardNames[attributeName];
}

export default convertDomAttrsToReactProps;
