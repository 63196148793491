import React from 'react';
import styled, { css } from 'styled-components';
import Divider from '~/components/Divider';
import JustificationContainer from '~/components/JustificationContainer';
import { ExternalLink } from '~/components/Link';
import { Heading5, Variant } from '~/components/Typography/index';
import getPriceWithInterval, {
  PriceWithInterval,
} from '~/util/getPriceWithInterval';

const text = {
  price: 'Kosten',
  compatibility: 'Compatibiliteit',
  info: 'Informatie',
  provider: 'Aanbieder',
  learnMore: 'Meer over deze app',
};

export type Props = {
  dataTestId?: string;

  /** Price of the app */
  price: PriceWithInterval | null;

  /** Who can use the app */
  compatibility: string;

  provider: string;

  /** Link for help article */
  help?: {
    href: string;
  };
};

const Aside: React.FC<Props> = ({
  dataTestId,
  children,
  price,
  compatibility,
  provider,
  help,
  ...rest
}) => {
  const priceStr = getPriceWithInterval(price);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <JustificationContainer
        margin={[null, null, 'l', null]}
        direction="column"
      >
        {children}
      </JustificationContainer>

      <Heading5 variant={Variant.primary}>{text.price}</Heading5>
      <PriceContainer>{priceStr}</PriceContainer>

      <Heading5 variant={Variant.primary}>{text.compatibility}</Heading5>
      <span>{compatibility}</span>
      <Divider />

      <Heading5 variant={Variant.primary}>{text.provider}</Heading5>
      <span>{provider}</span>

      {help && (
        <>
          <Divider />
          <Heading5 variant={Variant.primary}>{text.info}</Heading5>
          <ExternalLink
            to={help.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text.learnMore}
          </ExternalLink>
        </>
      )}
    </Container>
  );
};

const Container = styled.aside<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('tertiary', 'light')};
    padding: ${theme.space('l')};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.space('xxxl')};
  `,
);

const PriceContainer = styled.span<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight('semiBold')};
    font-size: ${theme.fontSize('m')};
    margin-bottom: ${theme.space('l')};
  `,
);

export default Aside;
