import gql from 'graphql-tag';

import FlowParameterTemplateStringFields from './FlowParameterTemplateStringFields';
import FlowEmailAttachmentFields from './FlowEmailAttachmentFields';
import FlowParameterFilePointerFields from './FlowParameterFilePointerFields';

export default gql`
  fragment FlowActionSendEmailPlainFields on Flow_Action_SendEmail_Plain {
    __typename
    id
    accountId
    flowBlueprintId
    parentId
    isTransactional
    from
    signature
    subject {
      ...FlowParameterTemplateStringFields
    }
    body {
      ...FlowParameterTemplateStringFields
    }
    attachments {
      ...FlowEmailAttachmentFields
    }
  }

  ${FlowParameterTemplateStringFields}
  ${FlowEmailAttachmentFields}
  ${FlowParameterFilePointerFields}
`;
