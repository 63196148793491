import gql from 'graphql-tag';

import ConnectedEmailFields from './subfragments/ConnectedEmailFields';

export default gql`
  fragment UserMailboxFields on UserMailbox {
    __typename
    userId
    email {
      ...ConnectedEmailFields
    }
  }

  ${ConnectedEmailFields}
`;
