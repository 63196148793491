import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterOfficeFields on Flow_Parameter__Office {
    __typename

    ... on Flow_Parameter_Office_Primitive {
      officeId: value
    }

    ... on Flow_Parameter_Office_Pointer {
      variable
      field
    }
  }
`;
