import React, { useState } from 'react';
import qs from 'query-string';
import styled from 'styled-components';
import { SelectedOption } from '~/components/Inputs/Dropdown';
import Validation from '~/util/Validation';
import { FormUtils } from '~/components';
import { Input, InputGroup } from '~/components/Inputs';
import Dropdown from '~/components/Dropdown';
import { Form, Field } from '~/components/Forms';
import { asDropdownOption } from '~/components/Inputs/Dropdown/useDropdown';
import { ACTIVITY_TYPE } from '~/util/constants';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import CodeEditor from '../../CodeEditor';
import { useLocation } from '@reach/router';
import useQueryParams from '~/hooks/useQueryParams';
import Button from '../../Button';
import JustificationContainer from '../../JustificationContainer';

const text = {
  xApiKeyLabel: 'X-Api-Key (ask a dev for one)',
  contactIdLabel: 'Contact ID (find me via network tab)',
  activityTypeLabel: 'The type of activity you want to create',
  submit: 'Create Activity',
  noXApiKey: 'X-Api-Key is mandatory',
  noContactId: 'A contact is mandatory',
  noActivityType: 'A type of activity to create is mandatory',
  addAll: 'Add all activities',
};

type FormFields = {
  xApiKey: string | null;
  contactId: string | null;
  activityType: string | null;
  overloads: string | null;
};

type Props = {
  accountId: string;
  dataTestid: string;
};
const CreateActivitiesFullPage: React.FC<Props> = ({
  dataTestid,
  accountId,
}) => {
  const location = useLocation();
  const addToast = useAddToast();
  const [accountStateObj, setAccountStateObj] = useState<string>(
    JSON.stringify(
      {
        accountId,
        app: {
          zapier: {
            enableAllApiKeys: true,
          },
        },
      },
      null,
      2,
    ),
  );
  const [xApiKey, setXAPIKey] = useState<null | string>(
    localStorage.getItem('DEV_X_API_KEY') ?? null,
  );

  const params = qs.parse(location.search);
  const [, setParams] = useQueryParams(params);

  const handleSubmit = async (fields: FormFields) => {
    if (!fields.contactId || !xApiKey || !fields.activityType) {
      return addToast([
        formatToastMessage(
          'You are missing an api key, contactId or activity type.',
          'info',
        ),
      ]);
    }

    try {
      const response = await createActivitiesCall({
        accountId,
        xApiKey,
        contactId: fields.contactId,
        activityType: fields.activityType,
        overloads: fields.overloads,
      });
      if (response.status !== 200) {
        addToast([
          formatToastMessage('Failed to add activity to contact', 'danger'),
        ]);
      } else {
        addToast([
          formatToastMessage(
            'Successfully added activity to contact. Please refresh contact page. It could take up to 1 minute to fully propogate.',
            'success',
          ),
        ]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      addToast([
        formatToastMessage(
          'Something went wrong with calling createActivities, most likely due to malformed overloads..',
          'danger',
        ),
      ]);
    }
  };

  const loading = false;

  const activityTypeOptions = Object.keys(ACTIVITY_TYPE).map(activityType => ({
    name: ACTIVITY_TYPE[activityType],
    id: activityType,
  }));

  return (
    <Container data-testid={dataTestid || 'dev-create-activities-full-page'}>
      <div>
        <h1>Dev section</h1>
        <Input
          label={text.xApiKeyLabel}
          type="text"
          disabled={loading}
          value={xApiKey}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value) {
              localStorage.setItem('DEV_X_API_KEY', event.target.value);
              setXAPIKey(event.target.value);
            }
          }}
        />
      </div>
      <JustificationContainer
        justification="space-between"
        align="center"
        direction="row"
      >
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={{
            xApiKey: null,
            contactId: null,
            activityType:
              params.activityType ??
              ACTIVITY_TYPE.EVENT_CONTACT_FUNDA_CONTACTREQUEST,
            overloads: isString(params.overloads)
              ? decodeURIComponent(params.overloads)
              : '{}',
          }}
        >
          {form => {
            const { submitting, pristine } = form;

            return (
              <form
                onSubmit={event => {
                  if (event && event.stopPropagation) {
                    event.stopPropagation();
                    event.preventDefault();
                  }
                  void handleSubmit({
                    activityType: form.values.activityType,
                    contactId: form.values.contactId,
                    overloads: form.values.overloads,
                    xApiKey: form.values.xApiKey,
                  });
                }}
              >
                <h1>Create activity</h1>

                <InputGroup>
                  <Field name="contactId">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        label={text.contactIdLabel}
                        type="text"
                        error={FormUtils.showError(error, touched)}
                        {...input}
                        tabIndex="2"
                        disabled={loading}
                      />
                    )}
                  </Field>
                </InputGroup>

                <InputGroup>
                  <Field name="activityType">
                    {({
                      input: { onChange, value },
                      meta: { error, touched },
                    }) => (
                      <Dropdown
                        tabIndex="3"
                        error={FormUtils.showError(error, touched)}
                        dataTestId="activityType-dropdown"
                        label={text.activityTypeLabel}
                        onChange={({ option }: SelectedOption) => {
                          const payload = option ? option.payload : null;
                          onChange(payload ? payload.name : null);
                        }}
                        selectedOptionIdx={activityTypeOptions.findIndex(
                          activityType => activityType.name === value,
                        )}
                        options={activityTypeOptions.map(activityType =>
                          asDropdownOption(activityType),
                        )}
                      />
                    )}
                  </Field>
                </InputGroup>
                <InputGroup>
                  <Field name="overloads">
                    {({ input: { onChange, value } }) => (
                      <CodeEditor
                        value={value}
                        onChange={value => {
                          onChange(value);
                        }}
                        height="200px"
                        width="500px"
                        mode="json"
                      />
                    )}
                  </Field>
                </InputGroup>

                <JustificationContainer
                  justification="space-between"
                  align="center"
                  margin={['base', null]}
                >
                  <Button
                    label="Create link"
                    type="button"
                    icon="link"
                    ghost
                    onClick={e => {
                      e.preventDefault();

                      const values = form.values;
                      setParams({
                        overloads: values.overloads,
                        activityType: values.activityType,
                      });
                    }}
                  />

                  <Button
                    onClick={() => {
                      activityTypeOptions.forEach(type => {
                        void handleSubmit({
                          activityType: type.name,
                          contactId: form.values.contactId,
                          overloads: form.values.overloads,
                          xApiKey: form.values.xApiKey,
                        });
                      });
                    }}
                    label={text.addAll}
                    appearance="secondary"
                  />
                  <Button
                    type="submit"
                    disabled={submitting || pristine || loading}
                    data-testid="add-new-contact-submit"
                    label={text.submit}
                    appearance="primary"
                  />
                </JustificationContainer>
              </form>
            );
          }}
        </Form>
        <form
          style={{ marginLeft: '1rem' }}
          onSubmit={async e => {
            e.preventDefault();
            if (!xApiKey) {
              return addToast([
                formatToastMessage('You are missing an api key', 'info'),
              ]);
            }

            try {
              await setAccountStateCall({
                params: JSON.parse(
                  accountStateObj,
                ) as SetAccountStateTestParams,
                xApiKey,
              }).then(() =>
                addToast([formatToastMessage('Account state set', 'success')]),
              );
            } catch (error) {
              addToast([formatToastMessage(error.toString(), 'danger')]);
            }
          }}
        >
          <h1>Set account state</h1>
          <CodeEditor
            value={accountStateObj}
            onChange={value => {
              setAccountStateObj(value);
            }}
            height="200px"
            width="500px"
            mode="json"
          />
          <JustificationContainer
            justification="end"
            align="center"
            margin={['base', null]}
          >
            <Button
              type="submit"
              label="Set account state"
              appearance="primary"
            />
          </JustificationContainer>
        </form>
      </JustificationContainer>
    </Container>
  );
};

const isString = (x): x is string => typeof x === 'string';

const validate = (fields: FormFields) => {
  const errors: {
    xApiKey?: string;
    contactId?: string;
    activityType?: string;
  } = {};

  if (Validation.String.isEmpty(fields.xApiKey)) {
    errors.xApiKey = text.noXApiKey;
  }

  if (Validation.String.isEmpty(fields.contactId)) {
    errors.contactId = text.noContactId;
  }

  if (Validation.String.isEmpty(fields.activityType)) {
    errors.activityType = text.noActivityType;
  }

  return errors;
};

type CreateActivitiesCall = {
  accountId: string;
  contactId: string;
  activityType: string;
  xApiKey: string;
  overloads: FormFields['overloads'];
};
const createActivitiesCall = async ({
  accountId,
  contactId,
  activityType,
  xApiKey,
  overloads,
}: CreateActivitiesCall) => {
  try {
    const activity = {
      ...(overloads ? JSON.parse(overloads) : {}),
      __Type: `AC_${activityType}`,
      contactId,
    };

    const params = {
      accountId,
      activities: [activity],
    };

    return fetch(`${process.env.TESTAPI_ENDPOINT}/createActivities`, {
      method: 'POST',
      body: JSON.stringify({ params }),
      headers: {
        'x-api-key': xApiKey,
      },
      mode: 'cors',
    });
  } catch (error) {
    throw error;
  }
};

type SetAccountStateTestParams = {
  accountId: string;
  app?: {
    zapier?: {
      enableAllApiKeys: boolean;
    };
  };
};
const setAccountStateCall = async ({
  params,
  xApiKey,
}: {
  xApiKey: string;
  params: SetAccountStateTestParams;
}) => {
  try {
    return fetch(`${process.env.TESTAPI_ENDPOINT}/setAccountState`, {
      method: 'POST',
      body: JSON.stringify({ params }),
      headers: {
        'x-api-key': xApiKey,
      },
      mode: 'cors',
    });
  } catch (error) {
    throw error;
  }
};

const Container = styled.div<{}>`
  height: 100%;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
`;

export default CreateActivitiesFullPage;
