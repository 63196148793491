import { BooleanConditionField } from '../types.flow';
import { Flow_ConditionPrimitive__Boolean__Input } from '~/graphql/types';

import { FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION } from './constants';
import { asBooleanParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

export default (
  field: BooleanConditionField,
): Flow_ConditionPrimitive__Boolean__Input => {
  const { operation } = field;

  if (operation == null || operation.value == null) {
    throw new Error(
      'Should not occur: Validation should not allow a condition field without operation',
    );
  }

  const value = asBooleanParameterValueInput(operation.value);

  switch (operation.type) {
    case FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS:
      return {
        Boolean: {
          Eq: {
            value,
          },
        },
      };
    default:
      return assertNever(operation.type, cleanedFilename(__filename));
  }
};
