import { BooleanField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { BooleanConditionField } from '../types.flow';
import { FlowConditionBooleanPrimitiveFieldsFragment } from '~/graphql/types';

import { FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION } from './constants';
import { asBooleanParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

export default (
  field: BooleanField,
  condition: FlowConditionBooleanPrimitiveFieldsFragment,
): BooleanConditionField => {
  switch (condition.__typename) {
    case 'Flow_ConditionPrimitive_Boolean_Eq':
      return {
        ...field,
        operation: {
          type: FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS,
          value: asBooleanParameterValue(condition.booleanValue),
        },
      };
    default:
      return assertNever(condition.__typename, cleanedFilename(__filename));
  }
};
