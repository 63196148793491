import { Flow_Action__Input } from '~/graphql/types';
import { RealworksSendContactAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import { asTemplateStringParameterValueInput } from '../Base/FlowParameter/ParameterValue';

export default (
  action: RealworksSendContactAction,
  treeNode: ActionTreeNode,
  defaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, description, tokenContainerId } = action;

  const { parentId } = treeNode;
  const { accountId, flowBlueprintId } = defaults;

  if (parentId == null)
    throw new Error(`Parentless RealworksSendContactAction found! ID: ${id}`);

  return {
    Realworks: {
      SendContact: {
        id,
        accountId,
        flowBlueprintId,
        parentId,
        description: asTemplateStringParameterValueInput(description),
        tokenContainerId,
      },
    },
  };
};
