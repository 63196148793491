import React from 'react';
import styled, { css } from 'styled-components';
import unified from 'unified';
import parse from 'remark-parse';
import remark2react from 'remark-react';
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
} from '../Typography/index';

export type Props = {
  value: string;
  dataTestId?: string;
  options?: {
    remarkReactComponents: any;
  };
};

const Markdown: React.FC<Props> = ({ value, dataTestId, options }) => {
  const _options = options
    ? {
        ...defaultRemarkReactComponents,
        ...options,
      }
    : {
        remarkReactComponents: defaultRemarkReactComponents,
      };

  return (
    <Container data-testid={dataTestId}>
      {
        unified().use(parse).use(remark2react, _options).processSync(value)
          .result as React.ReactElement
      }
    </Container>
  );
};

const defaultRemarkReactComponents = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  p: ({ children }: React.HTMLAttributes<HTMLParagraphElement>) => (
    <>{children}</>
  ),
  a: (props: React.HTMLAttributes<HTMLAnchorElement>) => (
    <InlineAnchor {...props} target="_blank" rel="noreferrer noopener" />
  ),
};

const Container = styled.div``;

export const InlineAnchor = styled.a<React.HTMLAttributes<HTMLAnchorElement>>(
  ({ theme }) => css`
    color: ${theme.color('text')};
    margin: 0 ${theme.space('xxxs')};
  `,
);

export default Markdown;
