import { FlowConditionContactDetails } from './types.flow';

import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionContactDetails = (): Omit<
  FlowConditionContactDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.CONTACT_DETAILS.type,
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
  fields: [],
});

export default emptyFlowConditionContactDetails;
