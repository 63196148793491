import type { ContactAddressFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Contact_Details__Input } from '~/graphql/types';

import asStringFilterInput from '../../Primitive/String/asStringFilterInput';
import Validation from '~/util/Validation';
import { FILTER_SUB_TYPE } from '~/components/Filters/Contact/constants';

const asContactAddressFilterInput = (
  filter: ContactAddressFilter,
): Node_NodeContainer_Leaf_Contact_Details__Input | null => {
  const { type, stringPrimitiveFilter } = filter;

  if (
    'eq' in stringPrimitiveFilter &&
    !isNonEmptyInput(stringPrimitiveFilter.eq)
  ) {
    return null;
  }

  if (
    'sw' in stringPrimitiveFilter &&
    !isNonEmptyInput(stringPrimitiveFilter.sw)
  ) {
    return null;
  }

  if (type === FILTER_SUB_TYPE.CITY) {
    return {
      address: {
        city: asStringFilterInput(stringPrimitiveFilter),
      },
    };
  }

  if (type === FILTER_SUB_TYPE.POSTCODE) {
    const isInvalidSw =
      'sw' in stringPrimitiveFilter &&
      Validation.String.isNonEmptyString(stringPrimitiveFilter.sw) &&
      !Validation.Postcode.startsWithValidPostcode(stringPrimitiveFilter.sw);

    const isInvalidEq =
      'eq' in stringPrimitiveFilter &&
      Validation.String.isNonEmptyString(stringPrimitiveFilter.eq) &&
      !Validation.Postcode.isValid(stringPrimitiveFilter.eq);

    if (isInvalidSw || isInvalidEq) {
      return null;
    }

    return {
      address: {
        postcode: asStringFilterInput(stringPrimitiveFilter),
      },
    };
  }

  return null;
};

export const isNonEmptyInput = (value: string | null): boolean =>
  Validation.String.isNonEmptyString(value);

export default asContactAddressFilterInput;
