import React, { useRef, useEffect } from 'react';

import { PasswordField } from '~/components/Forms';
import { FormUtils } from '~/components';
import { Input, InputGroup } from '~/components/Inputs';
import { PasswordRulesText } from '~/scenes/Auth/Auth.style';

import TEST_ID from './PasswordInputGroup.testid';

type Props = {
  submitting: boolean;
  passwordText?: string;
  repeatText?: string;
  passwordConditions: {
    charNumberValid: boolean;
    uppercaseValid: boolean;
    lowercaseValid: boolean;
    specialCharValid: boolean;
    numberValid: boolean;
  };
  isFocused?: boolean;
};

const PasswordInputGroup = ({
  passwordConditions,
  submitting,
  passwordText,
  repeatText,
  isFocused,
}: Props) => {
  const passwordRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isFocused) {
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    charNumberValid,
    uppercaseValid,
    lowercaseValid,
    specialCharValid,
    numberValid,
  } = passwordConditions;

  const charNumberValidClass = charNumberValid ? 'valid' : 'invalid';
  const uppercaseValidClass = uppercaseValid ? 'valid' : 'invalid';
  const lowercaseValidClass = lowercaseValid ? 'valid' : 'invalid';
  const specialCharValidClass = specialCharValid ? 'valid' : 'invalid';
  const numberValidClass = numberValid ? 'valid' : 'invalid';

  return (
    <>
      <InputGroup>
        <PasswordField name="password">
          {({ input, meta: { error, touched } }) => (
            <Input
              large
              label={passwordText ? passwordText : 'Wachtwoord'}
              type="password"
              disabled={submitting}
              setRef={elem => {
                passwordRef.current = elem;
              }}
              error={FormUtils.showError(error, touched)}
              data-testid={TEST_ID.PASSWORD_INPUT_FIELD}
              {...input}
            />
          )}
        </PasswordField>
      </InputGroup>

      <PasswordRulesText>
        Je wachtwoord moet minimaal bestaan{' '}
        <span
          id="charNumberHint"
          data-testid={TEST_ID.CHAR_NUMBERTOKENS_VALIDATION}
          className={charNumberValidClass}
        >
          uit 8 tekens
        </span>
        ,{' '}
        <span
          id="upperCaseHint"
          data-testid={TEST_ID.CHAR_UPPERCASE_VALIDATION}
          className={uppercaseValidClass}
        >
          een hoofdletter
        </span>
        ,{' '}
        <span
          id="lowerCaseHint"
          data-testid={TEST_ID.CHAR_LOWERCASE_VALIDATION}
          className={lowercaseValidClass}
        >
          een kleine letter
        </span>
        ,{' '}
        <span
          id="numberHint"
          data-testid={TEST_ID.CHAR_NUMBER_VALIDATION}
          className={numberValidClass}
        >
          een nummer
        </span>{' '}
        en{' '}
        <span
          id="specialCharHint"
          data-testid={TEST_ID.CHAR_SPECIALCHAR_VALIDATION}
          className={specialCharValidClass}
        >
          een speciaal teken
        </span>
        .
      </PasswordRulesText>
      <InputGroup>
        <PasswordField name="passwordRepeat">
          {({ input, meta: { error, touched } }) => (
            <Input
              large
              label={repeatText ? repeatText : 'Herhaal wachtwoord'}
              type="password"
              disabled={submitting}
              error={FormUtils.showError(error, touched)}
              data-testid={TEST_ID.REPEAT_PASSWORD_INPUT_FIELD}
              {...input}
            />
          )}
        </PasswordField>
      </InputGroup>
    </>
  );
};

export default PasswordInputGroup;
