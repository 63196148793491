import { FlowConditionValuationReportDetails } from './types.flow';

import { emptyValuationReportEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionValuationReportDetails = (): Omit<
  FlowConditionValuationReportDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.APPVALUATIONREPORT_DETAILS.type,
  fields: [],
  event: emptyValuationReportEventParameterValue(),
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
});
export default emptyFlowConditionValuationReportDetails;
