import React from 'react';
import CallToActionBlock from '~/components/CallToActionBlock';

const text = {
  skipThisStep: 'Sla deze stap over',
};

export type Props = {
  dataTestId?: string;
  skipCurrentStep: () => void;
  description: string;
};

const RealworksCallToActionBlock: React.FC<Props> = ({
  dataTestId,
  description,
  skipCurrentStep,
}) => (
  <CallToActionBlock
    description={description}
    button={{
      label: text.skipThisStep,
      appearance: 'primary',
      icon: 'arrowRight',
      onClick: skipCurrentStep,
    }}
    icon={{ name: 'triangle', background: 'warning', size: 'large' }}
    margin={['l', null, null]}
    padding={['m']}
    dataTestId={dataTestId}
    align="center"
  />
);

export default RealworksCallToActionBlock;
