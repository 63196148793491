import { FlowConditionConnector } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/components/OperatorDropdown';

export type ExpressionGroup = {
  connector: FlowConditionConnector;
  expressions: Array<Expression>;
};
type Expression = {
  connector: FlowConditionConnector;
  indices: Array<number>;
};

const extractExpression = (expRaw: string): ExpressionGroup => {
  const exp = expRaw.replace(/\[*\]*/g, '');
  const mainConnectorArray = /\)(.*?)\(/g.exec(exp);
  const mainConnector =
    (mainConnectorArray?.[1] ?? '').trim() === '||'
      ? FlowConditionConnector.Or
      : FlowConditionConnector.And;
  const expressions: Array<Expression> = [];

  const regExp = /\((.*?)\)/g;
  let captureGroup: RegExpExecArray | null;
  while ((captureGroup = regExp.exec(exp)) != null) {
    const expression = captureGroup[1];
    const connector = expression.includes('||')
      ? FlowConditionConnector.Or
      : FlowConditionConnector.And;
    const indices = expression.match(/\d+/g)?.map(i => parseInt(i, 10)) ?? [];

    expressions.push({
      connector,
      indices,
    });
  }

  return {
    connector: mainConnector,
    expressions,
  };
};

export const generateExpression = (expressionGroup: ExpressionGroup): string =>
  `(${expressionGroup.expressions
    .map(expression =>
      expression.indices.join(
        ` ${expression.connector === 'And' ? '&&' : '||'} `,
      ),
    )
    .join(`) ${expressionGroup.connector === 'And' ? '&&' : '||'} (`)})`;

export default extractExpression;
