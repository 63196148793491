import gql from 'graphql-tag';

export default gql`
  mutation UpdateAppValuationReport(
    $accountId: ID!
    $id: ID!
    $update: AppValuationReport_Update!
  ) {
    updateAppValuationReport(accountId: $accountId, id: $id, update: $update) {
      id
      accountId
    }
  }
`;
