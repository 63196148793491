import React from 'react';
import { IconType } from '~/components/Icon';
import {
  isMarkActive,
  toggleMark,
} from '~/components/PluginsEditor/commands/modify/mark';
import { DHEditor } from '~/components/PluginsEditor/types';
import BaseButton from '../components/Base';
import { tooltipText } from '../text';

export enum MarkStyles {
  EM = 'italic',
  S = 'strikethrough',
  STRONG = 'bold',
  U = 'underline',
}

export type Props = {
  dataTestId?: string;
  icon: IconType;
  format: MarkStyles;
  editor: DHEditor;
};

const MarkButton: React.FC<Props> = ({ dataTestId, icon, editor, format }) => (
  <BaseButton
    dataTestId={dataTestId}
    icon={icon}
    onClick={e => {
      e.preventDefault();
      toggleMark(editor, format);
    }}
    active={isMarkActive(editor, format)}
    tooltipMessage={tooltipText[format]}
  />
);

export default MarkButton;
