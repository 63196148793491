import { ActionTreeNode } from './types.flow';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import traverse from './traverse';
import { IfElseAction } from '../../Actions/IfElseAction/types.flow';

const getActionTreeVersionFrom = (
  actionTree: Array<ActionTreeNode>,
): string => {
  let versionString = '';

  actionTree.forEach(startNode =>
    traverse(startNode, node => {
      versionString = versionString + '|' + getVersion(node);
    }),
  );

  return versionString;
};

const isIfElseActionNode = (
  actionNode: ActionTreeNode,
): actionNode is ActionTreeNode<IfElseAction> =>
  actionNode.initialActionProps.type === FLOW_ACTION_TYPE.IF_ELSE;

const getVersion = (actionNode: ActionTreeNode): string => {
  const baseString = `${actionNode.parentId || 'start'}-${actionNode.id}`;

  if (isIfElseActionNode(actionNode)) {
    const subscriber = actionNode.subscriber;

    if (subscriber == null) {
      return baseString;
    } else {
      return `${baseString}-${
        subscriber.getActionProp().visiblePath ? 'yes-open' : 'no-open'
      }`;
    }
  }

  return baseString;
};

export default getActionTreeVersionFrom;
