import gql from 'graphql-tag';

import FlowFields from '../fragments/FlowFields';

export default gql`
  query GetFlowTemplate($accountId: ID!, $templateId: ID!) {
    getFlowTemplate(accountId: $accountId, templateId: $templateId) {
      ...FlowFields
    }
  }

  ${FlowFields}
`;
