import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { AccountStatus, ImpersonationViewingMode } from '~/graphql/types';
import { useGetSessionHydrationQuery } from './graphql';
import { Loading } from './components';
import AccountContextProvider from '~/contexts/AccountContextProvider';
import AccountPaused from './scenes/AccountStatus/AccountPaused';
import AccountCancelled from './scenes/AccountStatus/AccountCancelled';
import ErrorScreen from './scenes/ErrorScreen';
import { WithNavigationFrame as NotFound } from './components/404';
import cleanedFilename from '~/util/cleanedFilename';
import ModalContextProvider from './contexts/ModalContextProvider';
import { assertNever } from './util/assertion';
import SetupSubscriptionPage from './scenes/Settings/Subscription/components/SetupSubscriptionPage';
import AccountLocked from './scenes/AccountStatus/AccountLocked';
import { RouteComponentProps } from '@reach/router';
import DHRouter from './components/DHRouter';
import Tasks from './scenes/Tasks';
import Contacts from './scenes/Contacts';
import Automation from './scenes/Automation/';
import Apps from './scenes/Apps/';
import Settings from './scenes/Settings';
import External from './scenes/External';
import CreateActivities from './scenes/CreateActivities';
import useBeforeUnload from './hooks/useBeforeUnload';
import ToastsContainer from '~/components/ToastsContainer';
import Dashboard from './scenes/Dashboard';
import SessionHydrationContext from './contexts/SessionHydrationContext';
import Accounts from './scenes/Accounts';
import { AuthContext } from './root/Auth';
import ImpersonationContainer from './components/ImpersonationContainer';
import { isNil } from 'ramda';
import NavigationFrame from './components/NavigationFrame';
import useErrorReporter from './hooks/useErrorReporter';
import { useUnleashContext } from '@unleash/proxy-client-react';
import Wizard from './components/Wizard';
import useFireTrackingEvent from './hooks/useFireTrackingEvent';
import ViewingMode from './contexts/ViewingMode';

type Props = RouteComponentProps & {
  accountId: string;
};

const MainApp: React.FC<Props> = ({ accountId }) => {
  const updateUnleashContext = useUnleashContext();
  const fireTrackingEvent = useFireTrackingEvent();

  const { loading, error, data, refetch } = useGetSessionHydrationQuery({
    name: cleanedFilename(__filename),
    variables: { accountId },
  });
  useBeforeUnload('Let op: openstaande wijzigingen');

  useEffect(() => {
    if (data && data.getSessionHydration.me) {
      updateUnleashContext({ userId: data.getSessionHydration.me.id });
    }
  }, [data, updateUnleashContext]);

  useEffect(() => {
    const width = window.innerWidth || document.body.clientWidth;
    const height = window.innerHeight || document.body.clientHeight;

    const _width = Math.round(width / 100) * 100;
    const _height = Math.round(height / 100) * 100;

    const size = `${_width}x${_height}`;

    fireTrackingEvent({
      event: 'screenSize',
      size: size,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if (!data && error) return <ErrorScreen />;
  if (!data) return <NotFound />;

  const {
    account,
    me,
    offices,
    users,
    relations,
    mailboxes,
    fundaApp,
    deletedRelations,
    warningList,
  } = data.getSessionHydration;

  return (
    <SessionHydrationContext.Provider
      value={{ data: data.getSessionHydration, refetch }}
    >
      <ViewingMode.Provider
        value={{
          viewingMode:
            data.getSessionHydration.impersonationOptions?.viewingMode ||
            ImpersonationViewingMode.Complete,
        }}
      >
        <AccountContextProvider
          account={account}
          myId={me.id}
          offices={offices}
          users={users}
          relations={relations}
          mailboxes={mailboxes}
          refetchSessionHydration={refetch}
          fundaApp={fundaApp}
          warningList={warningList}
          deletedRelations={deletedRelations}
          accountIssues={account.issues ?? []}
        >
          <AccountPageByStatus accountStatus={account.status} />
          <ToastsContainer />
          {!isNil(data.getSessionHydration.impersonatedBy) && (
            <ImpersonationContainer />
          )}
        </AccountContextProvider>
      </ViewingMode.Provider>
    </SessionHydrationContext.Provider>
  );
};

/**
 * Returns the appropriate account page for the account status.
 *
 * The account status has been null | undefined in the past which meant `Active`
 * in the future we can delete this conditional once the BE
 * has completed the migration.
 *
 * @see https://gitlab.dathuis.nl/dh/sfa-api/-/merge_requests/490
 *
 * @param accountStatus AccountStatus
 */
const AccountPageByStatus = ({
  accountStatus,
}: {
  accountStatus?: AccountStatus | null;
}): JSX.Element => {
  const { isDHAdmin } = useContext(AuthContext);
  const status = accountStatus ?? AccountStatus.Active;
  // This initialises the error reporter, ensuring contexts get set when they are available.
  useErrorReporter();

  switch (status) {
    case AccountStatus.Incomplete:
      return <SetupSubscriptionPage />;

    case AccountStatus.Locked:
      return <AccountLocked />;

    case AccountStatus.Paused:
      return <AccountPaused />;

    case AccountStatus.Cancelled:
    case AccountStatus.Deleted:
      return <AccountCancelled />;

    case AccountStatus.Active:
      return (
        <ModalContextProvider>
          <Wizard header="Global wizard" id="onboarding-wizard" steps={[]}>
            <NavigationFrame>
              <DHRouter>
                <Dashboard path="/" />
                <Tasks path="/tasks/*" />
                <Contacts path="/contacts/*" />
                <Automation path="/automation/*" />
                <Apps path="/apps/*" />
                <Settings path="/settings/*" />
                <External path="/external/*" />
                <CreateActivities path="/createActivities" />

                {isDHAdmin && <Accounts path="/accounts/*" />}
                <NotFound default />
              </DHRouter>
            </NavigationFrame>
          </Wizard>
        </ModalContextProvider>
      );
    default:
      return assertNever(status);
  }
};

const LoadingContainer = styled.div<{}>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default MainApp;
