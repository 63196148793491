import { Flow_EmailAttachment__Input } from '~/graphql/types';
import { FlowEmailAttachmentValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { asFileParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/File';

const asFlowEmailAttachmentValueInput = (
  parameterValue: FlowEmailAttachmentValue,
): Flow_EmailAttachment__Input => ({
  file: asFileParameterValueInput(parameterValue.file),
  inlineId: parameterValue.inlineId,
});

export default asFlowEmailAttachmentValueInput;
