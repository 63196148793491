import { FlowParameterStringFieldsFragment } from '~/graphql/types';
import { StringPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyStringPointerParameterValue from './emptyStringPointerParameterValue';

export default (
  stringParameter:
    | Extract<
        FlowParameterStringFieldsFragment,
        { __typename: 'Flow_Parameter_String_Pointer' }
      >
    | null
    | undefined,
): StringPointerParameterValue => {
  if (stringParameter == null) {
    return emptyStringPointerParameterValue();
  }

  return {
    type: PARAMETER_VALUE_TYPE.STRING_POINTER,
    variable: {
      name: stringParameter.variable,
      field: stringParameter.field
        ? {
            name: stringParameter.field,
          }
        : null,
    },
  };
};
