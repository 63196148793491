import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import HidableComponent from '~/components/HidableComponent';
import Icon from '~/components/Icon';
import Color from 'color';

const text = {
  deleteLabel: 'Verwijderen',
};

export type Props = {
  loading: boolean;

  /** If the button needs to be disabled */
  disabled: boolean;
  onDelete: () => any;

  /** Function for letting know parent component that deleting status is changed */
  updateDeleteStatus?: (canDelete: boolean) => void;
};

const TwoStepDeleteButton: React.FC<Props> = ({
  loading,
  disabled,
  onDelete,
  updateDeleteStatus,
  ...rest
}) => {
  const [deleteIsPossible, setDeleteIsPossible] = useState<boolean>(false);

  const deleteTask = () => {
    if (disabled) return;

    if (deleteIsPossible) {
      onDelete();
    } else {
      setDeleteIsPossible(true);
    }

    if (updateDeleteStatus) {
      updateDeleteStatus(!deleteIsPossible);
    }
  };

  const onHide = () => {
    setDeleteIsPossible(false);

    if (updateDeleteStatus) {
      updateDeleteStatus(false);
    }
  };

  return (
    <Container {...rest}>
      <HidableComponent onClickOutside={onHide} allowBubble={true}>
        {ref => (
          <DeleteContainer
            isLarge={deleteIsPossible}
            onClick={event => {
              event.stopPropagation();

              deleteTask();
            }}
            ref={ref}
            disabled={disabled}
            data-testid="delete-container"
          >
            <DeleteLabelContainer
              data-testid="delete-label"
              isVisible={deleteIsPossible && !loading}
            >
              <Label>{text.deleteLabel}</Label>
            </DeleteLabelContainer>

            {loading ? (
              <Icon name="loading" />
            ) : (
              <Icon name="trashcan" data-testid="delete-trash-icon" />
            )}
          </DeleteContainer>
        )}
      </HidableComponent>
    </Container>
  );
};

const Label = styled.div<{}>`
  white-space: nowrap;

  ${({ theme }) => css`
    margin-right: ${theme.space('s')};
    font-size: ${theme.fontSize('s')};
    font-weight: ${theme.fontWeight('semiBold')};
  `};
`;

type DeleteLabelContainerProps = {
  isVisible: boolean;
};

const DeleteLabelContainer = styled.div<DeleteLabelContainerProps>`
  transition: max-width 0.3s ease-out;
  overflow: hidden;

  ${({ isVisible }) => css`
    max-width: ${isVisible
      ? '200px' //it will not be this big, but will stop growing at width of element
      : '0px'};
  `};
`;

const Container = styled.div<{}>`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

type DeleteContainerType = {
  isLarge?: boolean;
  disabled?: boolean;
};

const DeleteContainer = styled.div<DeleteContainerType>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: border-color 0.3s ease-out;

  ${({ disabled }) => {
    if (!disabled) {
      return css`
        cursor: pointer;
      `;
    }
    return ``;
  }}

  ${({ theme, isLarge }) => {
    const borderColor = isLarge ? theme.color('danger') : theme.color('text');
    const fontColor = isLarge ? theme.color('danger') : theme.color('text');

    return css`
      padding: 0 ${theme.space('xxs')};
      border-radius: ${theme.getTokens().border.radius.s};
      border: ${theme.getTokens().border.width.s} solid ${borderColor};
      color: ${fontColor};

      :hover {

        color: ${Color(fontColor).darken(0.1).hsl() as any as string}
        border-color: ${Color(borderColor).darken(0.1).hsl() as any as string}
      }
    `;
  }};
`;

export default TwoStepDeleteButton;
