import { keyframes, css } from 'styled-components';

export const slideInFromRight = (start: string | null | undefined) => keyframes`
  0% {
    transform: translateX(${start || '100vw'});
  }
  100% {
    transform: translateX(0px);
  }
`;

export const fadeIn = () => keyframes`
  0% {
      opacity: 0;
      transform: translateY(startYposition);
  }
  100% {
      opacity: 1;
      transform: translateY(endYposition);
  }
`;

export const fadeOut = () => keyframes`
  0% {
      opacity: 1;
      transform: translateY(startYposition);
  }
  100% {
      opacity: 0;
      transform: translateY(endYposition);
  }
`;

export const slideInRight = () => keyframes`
  0% {
      transform: translateX(100vw);
  }
  100% {
      transform: translateX(0px);
  }`;

export const spin = () => keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const dash = keyframes`
    0% {
      stroke-dashoffset: -70;
    }
    100% {
      stroke-dashoffset: 0;
    }
`;

/** This is used for Synchronising icon */
export const getSpinAnimation = () => css`
  animation: ${spin} 1s linear infinite normal,
    ${dash} 4s linear infinite alternate;
  transform-origin: 50% 50%;
  animation-delay: 0;
  animation-fill-mode: forwards;
  stroke-dasharray: 70;
`;
