import { ParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { needsUserActionDescription } from '~/scenes/Automation/Flows/Actions/util';
import { PARAMETER_VALUE_TYPE } from './constants';
import { specificValueDescription } from '~/scenes/Automation/Flows/Actions/util';
import {
  getVariableLabel,
  getPointerVariableLabel,
} from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { describeTemplateString } from './TemplateString';
import cleanedFilename from '~/util/cleanedFilename';
import { getUserName } from '~/graphql/utils';

// de waarde {value}
// de waarde van de variabele {variable label}>{field label}
const describeParameterValue = (
  parValue: ParameterValue,
  describeOptions: DescribeOptions,
  pureValue?: boolean,
): DescribeNodeType => {
  const { stash } = describeOptions;

  switch (parValue.type) {
    case PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.STRING_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.EMAIL_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.EMAIL_TEMPLATE_PRIMITIVE: {
      const valueString = specificValueDescription(parValue.value);

      if (pureValue) {
        return valueString;
      }

      return ['de waarde ', valueString];
    }
    case PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE: {
      const valueString =
        parValue.value === true
          ? specificValueDescription('ja')
          : specificValueDescription('nee');

      if (pureValue) {
        return valueString;
      }

      return ['de waarde ', valueString];
    }
    case PARAMETER_VALUE_TYPE.USER_PRIMITIVE: {
      if (parValue.value) {
        const user = describeOptions.userWithId(parValue.value);

        if (user) {
          return specificValueDescription(getUserName(user));
        }
      }

      return needsUserActionDescription('Geen gebruiker gevonden');
    }
    case PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE: {
      if (parValue.value) {
        const office = describeOptions.officeWithId(parValue.value);

        if (office) {
          return specificValueDescription(office.name);
        }
      }

      return needsUserActionDescription('Geen vestiging gevonden');
    }
    case PARAMETER_VALUE_TYPE.NUMBER_POINTER:
    case PARAMETER_VALUE_TYPE.STRING_POINTER:
    case PARAMETER_VALUE_TYPE.OFFICE_POINTER:
    case PARAMETER_VALUE_TYPE.USER_POINTER:
    case PARAMETER_VALUE_TYPE.BOOLEAN_POINTER: {
      const valueString = getPointerVariableLabel(parValue.variable, stash);

      if (pureValue) {
        return valueString;
      }

      return ['de waarde van de variabele ', valueString];
    }
    case PARAMETER_VALUE_TYPE.EMAIL_POINTER:
    case PARAMETER_VALUE_TYPE.EMAIL_TEMPLATE_POINTER: {
      if (parValue.variable == null || parValue.variable.field == null) {
        return needsUserActionDescription('Vul een e-mail in');
      }

      const variableName = parValue.variable.name;
      const variableLabel = getVariableLabel(stash, variableName);

      if (pureValue) {
        return variableLabel;
      }

      return `de e-mail die in ${variableLabel} is verstuurd`;
    }

    case PARAMETER_VALUE_TYPE.TEMPLATE_STRING: {
      const description = describeTemplateString(parValue, describeOptions);

      if (pureValue) {
        return description;
      }

      const descriptionArray: DescribeNodeType = [];

      descriptionArray.push('de waarde ');
      if (Array.isArray(description)) {
        descriptionArray.push(...description);
      } else {
        descriptionArray.push(description);
      }

      return descriptionArray;
    }
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Unknown field value type ${
          parValue.type
        } for description`,
      );
  }
};

export default describeParameterValue;
