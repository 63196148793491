import React, { useState, useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTable, useSortBy, useFlexLayout } from 'react-table';
import { is } from 'ramda';

import type {
  TableColumnDefinitions,
  TableHeaderCellType,
} from '~/components/DataTables/types.flow';
import type { ReturnProps } from '~/components/DataTables/useRowSelect';

import { HeaderCell, RowCell } from '~/components/DataTables';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import { NoResultSection } from '~/components';
import Catalog from '~/Catalog';
import { ACTION_COLUMN_ACCESSOR } from '~/components/DataTables/util/TableHeaderCell/action';
import { CHECKBOX_COLUMN_ACCESSOR } from '~/components/DataTables/util/TableHeaderCell/checkbox';

import ContactActionColumnCell from '../ContactActionColumnCell';
import useViewportSize from '~/components/util/useViewportSize';
import CheckboxHeaderCell from '~/components/DataTables/components/CheckboxHeaderCell';
import CheckboxColumnCell from '~/components/DataTables/components/CheckboxColumnCell';
import { isEmptyObject } from '~/util/object';

import TEST_ID from './index.testid';
import { Header, Row } from '~/components/DataTables/styling';
import TableBody from '~/components/DataTables/styling/Body';
import LoadingBody from '~/components/DataTables/styling/LoadingBody';
import { noop } from 'lodash';
import EmptyStateComponent from '~/components/EmptyStateComponent';
import { ContactListData } from '../../util/composeContactListData';
import useSortSettings from '~/hooks/useSortSettings';
import { ApolloError } from '@apollo/client';
import { getErrorTypes } from '~/util/errorHandling';
import ErrorTypes from '~/ErrorTypes';
import DropdownListContainer from '~/components/Dropdown/components/DropdownListContainer';
import { MAIN_CONTENT_WIDTH } from '~/components/NavigationFrame';

export type Props = {
  /** Table columns, column grouping limited to at most one level */
  columns: TableColumnDefinitions;
  /** Table data*/
  data: Array<ContactListData>;
  networkStatus: number;
  loading: boolean;
  error: ApolloError | boolean;
  onSuccessfulDelete: (contactId: string) => void;
  options?: {
    renderHeaderCell?: (
      key: string,
      column: TableHeaderCellType<any>,
      isLastColumn: boolean,
    ) => JSX.Element;
  };
  selectionProps?: ReturnProps;

  /* Specify the amount of rows that should show in loading state */
  loadingAmount?: number;

  /** Toggles Add single contact sidebar */
  toggleSidebar: () => void;

  /** Total amount of contact in the account */
  totalContactsInAccount: number | null;
};

const text = {
  toggleSortBy: '',
  invalidContactFilters:
    'Je hebt ongeldige filters geselecteerd. Ververs de pagina en wijzig de filterselectie.',
  queryError: Catalog.genericUnknownErrorMessage,
  noSearchResults: Catalog.noResults,
  selectAll: 'Selecteer alles',
  selectAllOnPage: 'Selecteer alles op pagina',
  addYourFirstContact: 'Voeg je eerste contact toe',
  emptyStateDescription:
    'Voeg bestaande contacten toe of genereer nieuwe leads via apps.',
  noContacts: 'Je hebt nog geen contacten',
};

const ContactListTable = ({
  columns,
  data,
  error,
  loading,
  networkStatus,
  loadingAmount = 20,
  onSuccessfulDelete,
  options,
  selectionProps = {
    itemsSelected: [],
    allSelected: false,
    selectAllItemsOnPage: f => f,
    toggleRowSelect: () => {},
    checkIfSelected: () => false,
    selectAllItems: () => {},
    deselectAllItems: () => {},
  },
  toggleSidebar,
  totalContactsInAccount,
  ...rest
}: Props) => {
  const theme = useTheme();
  const [sortSettings] = useSortSettings('contactSortSettings');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    toggleHideAllColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetHiddenColumns: false,
      getRowId: ({ id }) => id,
      manualSortBy: true,
      disableSortRemove: true,
      initialState: {
        sortBy: [sortSettings],
      },
    },
    useFlexLayout,
    useSortBy,
  );

  const [viewportSize] = useViewportSize();
  useEffect(() => {
    if (viewportSize.width > MAIN_CONTENT_WIDTH) {
      toggleHideAllColumns(false);
    } else {
      setHiddenColumns(['tags', 'leadScore', 'createdDate']);
    }
  }, [viewportSize.width, setHiddenColumns, toggleHideAllColumns]);

  const [showSelectOptions, setShowSelectOptions] = useState<boolean>(false);

  const {
    checkIfSelected = () => false,
    toggleRowSelect = noop,
    allSelected = false,
    selectAllItemsOnPage = (f, z) => ({ f, z }),
    selectAllItems = noop,
    itemsSelected = [],
    deselectAllItems = noop,
  } = selectionProps;
  const isSelectable = !isEmptyObject(selectionProps);

  let bodyComponent;
  if (error) {
    bodyComponent = (
      <StyledNoResultSection data-testid={TEST_ID.ERROR_MESSAGE}>
        {is(Boolean, error)
          ? text.queryError
          : getErrorTypes(error).includes(ErrorTypes.invalidContactFilters)
          ? text.invalidContactFilters
          : text.queryError}
      </StyledNoResultSection>
    );
  } else if (isInitialLoadStatus(networkStatus)) {
    bodyComponent = (
      <LoadingBody
        rowAmount={rows.length || loadingAmount || 10}
        getTableBodyProps={getTableBodyProps}
      />
    );
  } else if (totalContactsInAccount === 0) {
    bodyComponent = (
      <EmptyStateComponent
        header={text.noContacts}
        description={text.emptyStateDescription}
        illustration="users"
        buttonLabel={text.addYourFirstContact}
        blobColor={theme.color('grey', 'light')}
        onButtonClick={toggleSidebar}
        dataTestId={TEST_ID.EMPTY_STATE}
      />
    );
  } else if (data.length === 0 && !loading) {
    bodyComponent = (
      <StyledNoResultSection>{text.noSearchResults}</StyledNoResultSection>
    );
  } else {
    bodyComponent = (
      <TableBody.Standard.Inner {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);

          const rowProps = { ...row.getRowProps() };
          const contactId = row.id;
          const isDeleted = row.original && row.original.isDeleted === true;
          const isRowSelected = checkIfSelected(contactId);

          return (
            <Row.Standard
              {...rowProps}
              $isSelected={row.isSelected}
              $isDeleted={isDeleted}
              key={rowProps.key}
              data-objectid={contactId}
              data-deleted={isDeleted}
              dataTestid={TEST_ID.LIST_ITEM}
              to={`/-/contacts/${contactId}`}
            >
              {row.cells.map((cell, idx) => {
                const isLastColumn = idx === row.cells.length - 1;

                if (cell.column.id === ACTION_COLUMN_ACCESSOR) {
                  return (
                    <ContactActionColumnCell
                      key={`cell-${idx}`}
                      contactId={contactId}
                      cell={cell}
                      onSuccessfulDelete={() => onSuccessfulDelete(contactId)}
                    />
                  );
                }

                if (
                  cell.column.id === CHECKBOX_COLUMN_ACCESSOR &&
                  isSelectable
                ) {
                  return (
                    <CheckboxColumnCell
                      key={`cell-${idx}`}
                      cell={cell}
                      onChange={() => toggleRowSelect(contactId)}
                      value={allSelected ? true : isRowSelected}
                      disabled={allSelected}
                    />
                  );
                }

                return (
                  <RowCell
                    key={`cell-${idx}`}
                    cell={cell}
                    isLastColumn={isLastColumn}
                  />
                );
              })}
            </Row.Standard>
          );
        })}
      </TableBody.Standard.Inner>
    );
  }

  const oneCheckboxSelected = rows.some(row => checkIfSelected(row.id));

  return (
    <Container {...getTableProps()} {...rest} data-testid={TEST_ID.CONTAINER}>
      <Header.Large>
        {headerGroups.map(headerGroup => {
          const headerGroupProps = { ...headerGroup.getHeaderGroupProps() };
          return (
            <StyledHeaderTr
              key={headerGroupProps.key}
              data-testid={TEST_ID.HEADER_ROW}
            >
              {headerGroup.headers.map((column, idx) => {
                const isLastColumn = idx === headerGroup.headers.length - 1;
                const headerCellKey = `header-${idx}`;

                if (options && options.renderHeaderCell) {
                  return options.renderHeaderCell(
                    headerCellKey,
                    column,
                    isLastColumn,
                  );
                }

                if (column.id === CHECKBOX_COLUMN_ACCESSOR) {
                  return (
                    <div
                      {...column.getHeaderProps()}
                      key={`header-${column.id}`}
                    >
                      <CheckboxHeaderCell
                        key={`header-cell-${idx}`}
                        column={column}
                        value={allSelected ? true : oneCheckboxSelected}
                        minus={oneCheckboxSelected || allSelected}
                        isFocused={showSelectOptions}
                        onChange={() => {
                          if (itemsSelected.length > 0) {
                            deselectAllItems();
                          } else {
                            setShowSelectOptions(true);
                          }
                        }}
                        noRows={rows.length === 0}
                      />

                      <DropdownListContainer
                        dropdownListOpen={showSelectOptions}
                        onClickOutside={() => setShowSelectOptions(false)}
                        onClose={() => setShowSelectOptions(false)}
                        options={checkboxOptions}
                        onChange={({ option }) => {
                          switch (option.payload) {
                            case 'select-all-on-page':
                              selectAllItemsOnPage(
                                data.map(item => item.id),
                                !oneCheckboxSelected,
                              );
                              break;
                            case 'select-all':
                              if (allSelected) {
                                deselectAllItems();
                              } else {
                                selectAllItems(data.map(item => item.id));
                              }
                              break;
                          }

                          setShowSelectOptions(false);
                        }}
                      />
                    </div>
                  );
                }

                return (
                  <HeaderCell
                    key={headerCellKey}
                    column={column}
                    isLastColumn={isLastColumn}
                    deselectAllItems={deselectAllItems}
                  />
                );
              })}
            </StyledHeaderTr>
          );
        })}
      </Header.Large>
      <TableBody.Standard.Outer>{bodyComponent}</TableBody.Standard.Outer>
    </Container>
  );
};

const checkboxOptions = [
  {
    key: 'select-all',
    label: text.selectAll,
    payload: 'select-all',
  },
  {
    key: 'select-all-on-page',
    label: text.selectAllOnPage,
    payload: 'select-all-on-page',
  },
];

const StyledNoResultSection = styled(NoResultSection)<{}>`
  max-width: 800px;
`;

const StyledHeaderTr = styled.div<{}>`
  white-space: nowrap;
  display: flex;
`;

type TableWithStyleProps = {
  onClick?: () => void;
};
const Container = styled.div<TableWithStyleProps>(
  ({ onClick, theme }) => css`
    cursor: ${onClick ? 'pointer' : 'default'};
    user-select: none;
    border-spacing: 0;
    border-radius: ${theme.getTokens().border.radius.s};
  `,
);

const defaultColumn = {
  minWidth: 20,
  width: 120,
  maxWidth: 200,
};

export default ContactListTable;
