import gql from 'graphql-tag';

import FlowFields from '~/graphql/fragments/FlowFields';

export default gql`
  mutation InsertFlow($accountId: ID!, $flow: Flow__Input!) {
    insertFlow(accountId: $accountId, flow: $flow) {
      ...FlowFields
    }
  }

  ${FlowFields}
`;
