import LoadableContainer from '../LoadableContainer';
export {
  EmailEventAttachment,
  InsertHTMLFunction,
  SetHTMLFunction,
  GetHTMLFunction,
  InlineImageEditorItem,
  HTMLConverterFunction,
  InteractionFunctions,
  Props,
  State,
} from './types.flow';

const LoadableEditor = LoadableContainer({
  loader: () => import('./ClientSideHTMLEditor'),
});

export default LoadableEditor;
