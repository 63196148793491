/**
 * A hook to show the flow is activated modal. It will give the user:
 * [0] - the function to show the modal
 * [1] - a function to update the flowId
 *
 * It expects:
 *   - flowId - the id of the flow that you are going to create or edit
 */

import { useRef } from 'react';
import { navigate } from 'gatsby';

import useConfirmModal from '~/components/Alerts/useConfirmModal';

type ReturnProps = [(flowId?: string) => void];

export const enableFlowModalText = {
  title: 'De flow is geactiveerd',
  message:
    ' De flow is direct actief. Op de overzichtspagina kan je de flow altijd pauzeren of stopzetten.',
  buttonConfirmTitle: 'Naar overzichtspagina',
  buttonCancelTitle: 'Terug naar flow',
};

const useEnabledFlowModal = (initialFlowId: string): ReturnProps => {
  const flowId = useRef<string>(initialFlowId);

  const [showFlowModalComponent] = useConfirmModal(
    enableFlowModalText,
    () => {
      void navigate('/-/automation/flows');
    },
    () => {
      void navigate(`/-/automation/flows/details/${flowId.current}`, {
        replace: true,
      });
    },
    undefined,
    false,
    'normal',
  );

  const showFlowModal = (newFlowId?: string) => {
    if (newFlowId != null) {
      flowId.current = newFlowId;
    }

    showFlowModalComponent();
  };

  return [showFlowModal];
};

export default useEnabledFlowModal;
