import { FlowConditionAppVBOWaardecheckReportDetails } from './types.flow';
import { Flow_Condition__Input } from '~/graphql/types';

import { asConditionFieldInput } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition';
import { FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD } from './constants';
import { asEventParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';

const asFlowConditionAppVBOWaardecheckReportDetailsInput = (
  condition: FlowConditionAppVBOWaardecheckReportDetails,
): Array<Flow_Condition__Input> => {
  const { fields, event } = condition;

  const inputList = fields.reduce<Array<Flow_Condition__Input>>(
    (acc, field) => {
      if (
        Object.keys(
          FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD,
        ).includes(field.name)
      ) {
        return [
          ...acc,
          {
            EventContactApp: {
              VBOWaardecheck: {
                Report: {
                  Details: {
                    [field.name]: asConditionFieldInput(field),
                    event: asEventParameterValueInput(event),
                  },
                },
              },
            },
          },
        ];
      }
      return acc;
    },
    [],
  );

  return inputList;
};

export default asFlowConditionAppVBOWaardecheckReportDetailsInput;
