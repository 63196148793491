import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import icons from '~/components/Icon/components';

import ChevronIcon from '../ChevronIcon';
import UserImage from '../UserImage';

export type Props = {
  dataTestId?: string;
  image?: string | null;
  icon?: keyof typeof icons;
  label: React.ReactNode;
  onClick: () => void;
};

const MobileDropdownMenu: React.FC<Props> = ({
  dataTestId,
  image,
  icon,
  label,
  onClick,
  ...rest
}) => (
  <Button data-testid={dataTestId} {...rest} onClick={onClick}>
    <Inner>
      <UserImage userImage={image} />
      {icon && <StyledIcon name={icon} />}
      <Label data-testid="header-username">{label}</Label>
    </Inner>
    <ChevronIcon clockWise={270} />
  </Button>
);

const SPACE_OUTSIDE_SVG = '0.25rem';

const Button = styled.button<{}>(
  ({ theme }) => css`
    background: none;
    outline: none;
    border: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: ${theme.space('base')} 0;
  `,
);

const Inner = styled.div<{}>`
  display: flex;
  align-items: center;
`;

const Label = styled.div<{}>(
  ({ theme }) => css`
    width: 100%;
    font-size: ${theme.fs('l')};
    white-space: nowrap;
  `,
);

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('grey', 'light')};
    color: ${theme.color('text')};
    border-radius: ${theme.getTokens().border.radius.full};
    padding: ${SPACE_OUTSIDE_SVG};
    margin-right: ${theme.space('xxs')};

    svg {
      width: ${theme.space('xl')};
      height: ${theme.space('xl')};
    }
  `,
);

export default MobileDropdownMenu;
