import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import type { AppsHashMap } from '~/hooks/useApps';
import AppCard, { Props as AppCardProps } from '../../../AppCard';
import { ANIMATION_CONFIG } from '~/scenes/Settings/constants';

export type Props = {
  dataTestId?: string;
  apps: AppsHashMap;
};

const AppCards: React.FC<Props> = ({ apps, ...rest }) => {
  const animation = useSpring(ANIMATION_CONFIG);

  return (
    <AppListContainer style={animation} {...rest}>
      {Object.keys(apps).map(appKey => {
        const app = apps[appKey];

        if (!app) return null;
        const props: AppCardProps = {
          'data-objectid': app.__typename,
          app,
        };

        return <AppCard {...props} key={appKey} />;
      })}
    </AppListContainer>
  );
};

const AppListContainer = styled(animated.section)<{}>(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 1fr auto;
    grid-row-gap: ${theme.space('l')};
    grid-column-gap: ${theme.space('l')};

    margin-bottom: ${theme.space('xl')};

    ${theme.mq.lessThan('tabletLandscape')`
      grid-template-columns: repeat(2, 1fr);
    `}

    ${theme.mq.lessThan('mobile')`
      grid-template-columns: repeat(1, 1fr);
    `}
  `,
);

export default AppCards;
