import React from 'react';
import styled from 'styled-components';

import { Avatar } from '~/components';
import { AvatarForTypes } from '../Avatar';

export type Props = {
  onUpload: (files: FileList) => void;
  onDelete?: () => void;
  edit: boolean | null | undefined;
  imageUrl: string | null | undefined;
  simpleImage?: boolean;
  dataTestid?: string;
  className?: string;
  imagePlaceholderText?: string;
  showDeleteButton?: boolean;
  loading?: boolean;
  avatarFor?: AvatarForTypes;
};

type State = {
  url: string | null | undefined;
  uploadError: string | null;
};

export const AVATAR_SIZE = 90;
class AvatarWithUploader extends React.Component<Props, State> {
  fileUploader: any = React.createRef();

  state: State = {
    url: null,
    uploadError: null,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const url = nextProps.imageUrl ? nextProps.imageUrl : null;

    if (url !== prevState.url) {
      return {
        url,
      };
    } else return null;
  }

  bindInputRef = (ref: any) => {
    this.fileUploader = ref;
  };

  handleClick = () => {
    if (this.fileUploader.current) this.fileUploader.current.click();
  };

  render() {
    const {
      edit = false,
      dataTestid,
      className,
      onUpload,
      loading,
      avatarFor,
      ...rest
    } = this.props;

    const { url } = this.state;
    return (
      <AvatarWrapper
        data-testid={dataTestid || 'image-uploader'}
        className={className}
      >
        {edit && (
          <input
            hidden
            ref={this.fileUploader}
            type="file"
            accept="image/*"
            onChange={e => {
              const target = e.target as HTMLInputElement;

              if (target != null && target.files?.length) {
                onUpload(target.files);
              }
            }}
          />
        )}
        <Avatar
          avatarFor={avatarFor}
          loading={loading}
          src={url}
          withUpload={!!edit}
          onClick={() => this.handleClick()}
          simpleImage={this.props.simpleImage}
          {...rest}
        />
      </AvatarWrapper>
    );
  }
}

const AvatarWrapper = styled.div<{}>`
  margin-right: 0.75em;
  height: ${AVATAR_SIZE}px;
  flex: 0 0 ${AVATAR_SIZE}px;
`;

export default AvatarWithUploader;
