import gql from 'graphql-tag';

export default gql`
  mutation UpdateContact($accountId: ID!, $id: ID!, $update: Contact_Update!) {
    updateContact(accountId: $accountId, id: $id, update: $update) {
      id
      name
      email
      phone
      address {
        addition
        city
        houseNumber
        postcode
        street
      }
      userId
      officeId
      tags
      blockMarketing
    }
  }
`;
