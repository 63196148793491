import { SetterOrUpdater } from 'recoil';
import { ClientFlowAction } from '~/graphql/types.client';
import duplicateActions from './utils/duplicateActions';
import { Interaction } from '~/scenes/Automation/v2/state/interactions';

const onDuplicateAction = (
  subjectAction: Exclude<
    ClientFlowAction,
    | { __typename: 'FlowV2_Action_Start' }
    | { __typename: 'FlowV2_Action_IfElse' }
  >,
  setActions: SetterOrUpdater<Array<ClientFlowAction>>,
  setInteraction: SetterOrUpdater<Interaction>,
): void => {
  let newAction;

  setActions(actions => {
    const res = duplicateActions({ actions, subjectAction });
    newAction = res.newAction;
    return res.updatedActions;
  });

  if (newAction) setInteraction({ type: 'duplicateAction', action: newAction });
};

export default onDuplicateAction;
