import React, { useState } from 'react';

import { StringConditionField } from '../types.flow';
import { StringOperation } from './types.flow';
import { StringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithControlledSwitcherProps } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { Dropdown } from '~/components/Inputs';
import {
  STRING_OPERATION_OPTIONS_LABELS,
  FLOW_CONDITION_PRIMITIVE_STRING_OPERATION,
  STRING_OPERATION_OPTION,
} from './constants';
import { emptyStringPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import {
  StringParameterValueComponent,
  StringPrimitiveParameterValueComponent,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/String';
import TEST_ID from './FlowConditionPrimitiveStringFieldComponent.testid';
import cleanedFilename from '~/util/cleanedFilename';
import Catalog from '~/Catalog';
import { PARAMETER_VALUE_TYPE } from '../../../FlowParameter/ParameterValue/constants';
import defaultStringOperation from './defaultStringOperation';
import { assertNever } from '~/util/assertion';

const text = {
  operatorLabel: Catalog.flows.operatorLabel,
};

export type ConditionPrimitiveStringField =
  | StringConditionField
  | (Omit<StringConditionField, 'label' | 'name'> & {
      label: undefined;
      name: undefined;
    });

type Props = {
  field: ConditionPrimitiveStringField;
  change: WithControlledSwitcherProps<ConditionPrimitiveStringField>;
};
const FlowConditionPrimitiveStringFieldComponent = ({
  field,
  change,
}: Props) => {
  const { options } = field;
  const [stringParameterValueCache, setStringParameterValueCache] =
    useState<StringParameterValue>(
      field.operation && field.operation.value
        ? field.operation.value
        : emptyStringPrimitiveParameterValue(),
    );
  const [stateOperationOption, setStateOperationOption] =
    useState<StringOperation>(
      field.operation == null ? defaultStringOperation() : field.operation,
    );

  if (
    field.options &&
    field.options.length > 0 &&
    field.operation &&
    field.operation.value.type === PARAMETER_VALUE_TYPE.STRING_POINTER
  ) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | A field with options should never have a pointer value!`,
    );
  }

  const operation = change.controlled ? field.operation : stateOperationOption;
  const value =
    operation == null ? emptyStringPrimitiveParameterValue() : operation.value;
  const prohibitEmptyValues =
    operation != null && mandatoryFieldOperationTypes.includes(operation.type);

  const changeProps: WithControlledSwitcherProps<StringParameterValue> =
    change.controlled
      ? {
          controlled: true,
          onChange: (newValue: StringParameterValue) => {
            setStringParameterValueCache(newValue);

            change.onChange({
              ...field,
              operation: newOperationFor(
                convertPrimitiveStringOperationToOption(operation),
                newValue,
              ),
            });
          },
        }
      : {
          controlled: false,
          onAdded: (newValue: StringParameterValue) => {
            if (
              prohibitEmptyValues &&
              newValue.type === PARAMETER_VALUE_TYPE.STRING_PRIMITIVE
            ) {
              if (newValue.value == null || newValue.value === '') {
                return;
              }
            }

            setStringParameterValueCache(newValue);

            change.onAdded({
              ...field,
              operation: newOperationFor(
                convertPrimitiveStringOperationToOption(stateOperationOption),
                newValue,
              ),
            });
          },
          announceChanges: change.announceChanges,
        };

  let valueComponent = (
    <StringParameterValueComponent value={value} change={changeProps} />
  );
  // Only allow pointers if already given
  if (value.type === PARAMETER_VALUE_TYPE.STRING_PRIMITIVE) {
    valueComponent = (
      <StringPrimitiveParameterValueComponent
        value={value}
        change={changeProps}
      />
    );
  }

  if (options && options.length > 0) {
    if (value.type !== PARAMETER_VALUE_TYPE.STRING_PRIMITIVE) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | options not allowed for non primitive values!`,
      );
    }

    valueComponent = (
      <StringPrimitiveParameterValueComponent
        key={field.name}
        value={value}
        options={options.map(option => ({
          label: option,
          payload: option,
          key: option,
        }))}
        allowCustom={true}
        change={changeProps}
      />
    );
  }

  return (
    <>
      <Dropdown
        dataTestid={TEST_ID.OPERATOR_DROPDOWN}
        options={operationOptions}
        label={text.operatorLabel}
        selectedOptionIdx={
          operation == null
            ? null
            : operationOptions.findIndex(
                option =>
                  option.key ===
                  convertPrimitiveStringOperationToOption(operation),
              )
        }
        onChange={({ option }) => {
          let newStringValue = stringParameterValueCache;

          if (
            field.options &&
            field.options.length > 0 &&
            newStringValue.type === PARAMETER_VALUE_TYPE.STRING_POINTER
          ) {
            newStringValue = emptyStringPrimitiveParameterValue(
              field.options[0],
            );
          }

          const newOperation = newOperationFor(option.payload, newStringValue);

          if (change.controlled) {
            change.onChange({
              ...field,
              operation: newOperation,
            });
          } else {
            setStateOperationOption(newOperation);
            change.announceChanges();
          }
        }}
      />
      {valueComponent}
    </>
  );
};

const newOperationFor = (
  type: STRING_OPERATION_OPTION,
  newStringValue: StringParameterValue,
): StringOperation => {
  switch (type) {
    case STRING_OPERATION_OPTION.STARTS_WITH:
      return {
        type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.STARTS_WITH,
        value: newStringValue,
        negate: false,
      };

    case STRING_OPERATION_OPTION.DOES_NOT_START_WITH:
      return {
        type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.STARTS_WITH,
        value: newStringValue,
        negate: true,
      };

    case STRING_OPERATION_OPTION.EQUALS:
      return {
        type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.EQUALS,
        value: newStringValue,
        negate: false,
      };

    case STRING_OPERATION_OPTION.DOES_NOT_EQUAL:
      return {
        type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.EQUALS,
        value: newStringValue,
        negate: true,
      };

    case STRING_OPERATION_OPTION.ENDS_WITH:
      return {
        type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.ENDS_WITH,
        value: newStringValue,
        negate: false,
      };

    case STRING_OPERATION_OPTION.DOES_NOT_END_WITH:
      return {
        type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.ENDS_WITH,
        value: newStringValue,
        negate: true,
      };

    case STRING_OPERATION_OPTION.CONTAINS:
      return {
        type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.CONTAINS,
        value: newStringValue,
        negate: false,
      };

    case STRING_OPERATION_OPTION.DOES_NOT_CONTAIN:
      return {
        type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.CONTAINS,
        value: newStringValue,
        negate: true,
      };

    default:
      return assertNever(type, cleanedFilename(__filename));
  }
};

const operationOptions = Object.keys(STRING_OPERATION_OPTIONS_LABELS).map(
  key => ({
    label: STRING_OPERATION_OPTIONS_LABELS[key].label,
    payload: key,
    key,
  }),
);

const mandatoryFieldOperationTypes = [
  FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.STARTS_WITH,
  FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.ENDS_WITH,
  FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.CONTAINS,
];

const convertPrimitiveStringOperationToOption = (
  operation: StringOperation | null,
): STRING_OPERATION_OPTION => {
  if (operation == null) {
    return STRING_OPERATION_OPTION.EQUALS;
  }

  switch (operation.type) {
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.CONTAINS:
      if (operation.negate) {
        return STRING_OPERATION_OPTION.DOES_NOT_CONTAIN;
      }

      return STRING_OPERATION_OPTION.CONTAINS;
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.STARTS_WITH:
      if (operation.negate) {
        return STRING_OPERATION_OPTION.DOES_NOT_START_WITH;
      }

      return STRING_OPERATION_OPTION.STARTS_WITH;
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.ENDS_WITH:
      if (operation.negate) {
        return STRING_OPERATION_OPTION.DOES_NOT_END_WITH;
      }

      return STRING_OPERATION_OPTION.ENDS_WITH;
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.EQUALS:
      if (operation.negate) {
        return STRING_OPERATION_OPTION.DOES_NOT_EQUAL;
      }

      return STRING_OPERATION_OPTION.EQUALS;
    default:
      return assertNever(operation, cleanedFilename(__filename));
  }
};

export default FlowConditionPrimitiveStringFieldComponent;
