import React from 'react';
import Text from './components/Data/Text';
import Flex from './components/Layout/Flex';
import Markdown from './components/Data/Markdown';
import Link from './components/Data/Link';
import Maps from './components/Data/Maps';
import Expandable from './components/Layout/Expandable';
import Grid from './components/Layout/Grid';
import DividerBlock from './components/Data/Divider';
import ImageBlock from './components/Data/Image';
import { isEmpty, isNil } from 'ramda';
import useErrorReporter from '~/hooks/useErrorReporter';
import { StyleBlockFE } from '../../types';

export type Props = StyleBlockFE & {
  dataTestId?: string;
};

const Block: React.FC<Props> = props => {
  const errorReporter = useErrorReporter();

  const children =
    'blocks' in props && props.blocks && props.blocks.length > 0
      ? props.blocks.map(block => {
          if (isNil(block) || isEmpty(block)) return null;

          return <Block {...block} key={block.id} />;
        })
      : null;

  switch (props.__typename) {
    // Data blocks
    case 'LinkBlock':
      return <Link {...props} />;
    case 'MapsBlock':
      return <Maps {...props} />;
    case 'ImageBlock':
      return <ImageBlock {...props} />;
    case 'TextBlock':
      return <Text {...props} />;
    case 'MarkdownBlock':
      return <Markdown {...props} />;
    case 'DividerBlock':
      return <DividerBlock {...props}>{children}</DividerBlock>;

    // Layout blocks
    case 'FlexLayout':
      return <Flex {...props}>{children}</Flex>;
    case 'GridLayout':
      return <Grid {...props}>{children}</Grid>;
    case 'ExpandableLayout':
      return <Expandable {...props}>{children}</Expandable>;

    default:
      errorReporter.captureException(
        new Error(
          `Block with type does not exist ${JSON.stringify(props, null, 2)}`,
        ),
        'warning',
      );

      return null;
  }
};

export default Block;
