import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  dataTestId?: string;
};

const AppSectionHeader: React.FC<Props> = ({ dataTestId, ...rest }) => (
  <Container data-testid={dataTestId} {...rest} />
);

const Container = styled.h2<{}>(
  ({ theme }) => css`
    font-size: 18px;
    color: ${theme.color('text')};
  `,
);

export default AppSectionHeader;
