import { FlowConditionEmailOpened } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

const describeEmailOpenedCondition = (
  condition: FlowConditionEmailOpened,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  const { email } = condition;

  const descriptionArray: DescribeNodeType = [];

  const describedParamValue = describeParameterValue(email, describeOptions);

  if (Array.isArray(describedParamValue)) {
    descriptionArray.push(...describedParamValue);
  } else {
    descriptionArray.push(describedParamValue);
  }

  descriptionArray.push(' is geopend');

  return descriptionArray;
};

export default describeEmailOpenedCondition;
