import React from 'react';
import { Descendant } from 'slate';
import Button from '../Button';
import PluginsEditor from '../PluginsEditor';
import insertVariable from '../PluginsEditor/commands/modify/variable';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import withHtml from '../PluginsEditor/plugins/withHtml';
import { Container as InputContainer } from '~/components/Input';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  value: Array<Descendant>;
  onChange: (val: Array<Descendant>) => void;
  $key: number;
};

const TemplateStringInput: React.FC<Props> = ({
  value,
  onChange,
  $key,
  ...rest
}) => (
  <InputContainer style={{ width: '100%' }}>
    <PluginsEditor
      key={$key}
      value={value}
      onChange={onChange}
      customElements={[ELEMENTS.VARIABLE]}
      singleLine
      plugins={[
        e =>
          withHtml({
            editor: e,
            customElements: [ELEMENTS.VARIABLE],
            simpleEditor: true,
          }),
      ]}
      {...rest}
    >
      {editor => (
        <div style={{ alignSelf: 'start' }}>
          <Button
            icon="user"
            onClick={() => insertVariable(editor)}
            dataTestId={TEST_ID.INSERT_VARIABLE_BUTTON}
          />
        </div>
      )}
    </PluginsEditor>
  </InputContainer>
);

export default TemplateStringInput;
