import { StartAction, InexactStartActionFieldsFragment } from './types.flow';
import { BaseActionProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import asFlowConditionProp from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/asFlowConditionProp';

import { asFlowConditionListProp } from '../Base/FlowConditionList';
import { generateHash } from '~/util/react';
import { FlowCondition } from '../Base/FlowCondition/types.flow';

export default (
  item: InexactStartActionFieldsFragment,
  baseProps: BaseActionProps,
): StartAction => {
  const { id, startCondition, conditionList } = item;

  const conditionListProp = asFlowConditionListProp(conditionList);
  return {
    ...baseProps,
    type: FLOW_ACTION_TYPE.START,
    id,
    startCondition: {
      id: generateHash(JSON.stringify(startCondition)),
      ...asFlowConditionProp(startCondition),
    } as FlowCondition,
    conditionList: {
      ...conditionListProp,
      conditions: conditionListProp.conditions.map(
        (condition, idx) =>
          ({
            id: generateHash(`${JSON.stringify(condition)}-${idx}`),
            ...condition,
          } as FlowCondition),
      ),
    },
  };
};
