import defaultDataBB, {
  AppBBWaardecheckDefaultData,
} from '~/scenes/Apps/BBWaardecheck/defaultData';
import defaultDataVBO, {
  AppVBOWaardecheckDefaultData,
} from '~/scenes/Apps/VBOWaardecheck/defaultData';
import { WaardecheckApp } from '../..';

export type WaardecheckDefaultData =
  | AppVBOWaardecheckDefaultData
  | AppBBWaardecheckDefaultData;

export type DefaultDataProps = {
  accountName: string;
  appType: WaardecheckApp;
};

const getDefaultData = ({
  appType,
  accountName,
}: DefaultDataProps): WaardecheckDefaultData => {
  switch (appType) {
    case WaardecheckApp.VBO:
      return defaultDataVBO({ accountName });
    case WaardecheckApp.BB:
    default:
      return defaultDataBB({ accountName });
  }
};

export default getDefaultData;
