import React from 'react';
import { Body } from '~/components/Typography/index';

type Props = {};

const Explanation: React.FC<Props> = ({ children }) => (
  <Body margin={['xl', null]}>{children}</Body>
);

export default Explanation;
