import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';

export type Props = {
  dataTestId?: string;
} & HTMLAttributes<HTMLButtonElement>;

const CloseButton: React.FC<Props> = ({ dataTestId, onClick, ...rest }) => (
  <Container data-testid={dataTestId} onClick={onClick} type="button" {...rest}>
    <Icon name="close" />
  </Container>
);

const Container = styled.button<{}>(
  ({ theme }) => css`
    background-color: transparent;
    border: none;
    border-radius: ${theme.getTokens().border.radius.s};
    font-size: ${theme.fs('l')};
    cursor: pointer;
    padding: ${theme.space('xxxs')};

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease;

    float: right;

    &:hover {
      background-color: ${theme.color('grey', 'translucent')};
    }
  `,
);

export default CloseButton;
