import { FilePrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';

type Options = {
  s3key?: string;
  filename?: string;
  contentLength?: number;
  contentType?: string | null;
};
const emptyFilePrimitiveParameterValue = ({
  s3key,
  filename,
  contentLength,
  contentType,
}: Options): FilePrimitiveParameterValue => ({
  type: PARAMETER_VALUE_TYPE.FILE_PRIMITIVE,
  s3key: s3key || null,
  filename: filename || null,
  contentLength: contentLength || null,
  contentType: contentType || null,
});

export default emptyFilePrimitiveParameterValue;
