import { isNil } from 'ramda';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { validImageTypes } from '~/util/uploadS3Image';
import AvatarWithUploader from '../AvatarWithUploader';
import DragAndDropContainer from '../DragAndDropContainer';
import ImageUploadTextButton from '../ImageUploadTextButton';
import ProgressBar from '../ProgressBar/index';

const text = {
  uploading: 'Uploaden...',
  info: 'Je beeld mag maximaal 5mb groot zijn. Geaccepteerde bestanden zijn JPG, JPEG, PNG, GIF en SVG.',
};

export type Props = {
  dataTestid?: string;
  imageUrl: string | null | undefined;
  onUpload: (files: FileList) => void;
  onDelete: () => void;
  imagePlaceholderText?: string;
  uploading: boolean;
  reverse?: boolean;
};

const ImageUploadComponent = ({
  dataTestid,
  imageUrl,
  imagePlaceholderText,
  uploading,
  onDelete,
  onUpload,
  reverse = false,
}: Props) => {
  const _onUpload = useCallback(
    files => {
      if (!uploading) {
        onUpload(files);
      }
    },
    [uploading, onUpload],
  );

  const showDeleteButton = !uploading && !isNil(imageUrl);

  return (
    <DragAndDropContainer
      handleDrop={files => _onUpload(files)}
      uploading={uploading}
      acceptedFileTypes={validImageTypes}
    >
      <Container reverse={reverse}>
        <TextContainer>
          <ImageUploadTextButton onUpload={_onUpload} uploading={uploading} />

          {uploading ? <ProgressBar loading={uploading} /> : text.info}
        </TextContainer>

        <StyledAvatarWithUploader
          onUpload={_onUpload}
          onDelete={onDelete}
          simpleImage
          edit={!uploading}
          dataTestid={dataTestid}
          imageUrl={imageUrl}
          showDeleteButton={showDeleteButton}
          imagePlaceholderText={
            uploading ? text.uploading : imagePlaceholderText
          }
        />
      </Container>
    </DragAndDropContainer>
  );
};

const Container = styled.div<{ reverse?: Props['reverse'] }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  justify-content: space-between;

  ${({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid ${theme.color('grey')};
    padding: ${theme.space('s')};
    border-radius: ${theme.getTokens().border.radius.base};

    ${theme.mq.lessThan('tabletLandscape')`
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      padding-bottom: ${theme.space('xl')};
    `}

    &:hover {
      border: ${theme.getTokens().border.width.s} solid ${theme.color('text')};
    }
  `};
`;

const TextContainer = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => css`
    padding: ${theme.space('l')};
  `};
`;

const StyledAvatarWithUploader = styled(AvatarWithUploader)<{}>`
  flex-basis: 200px;
  height: 140px;
  margin-right: 0;
`;

export default ImageUploadComponent;
