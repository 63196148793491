import { isNil } from 'ramda';
import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import serializeAction from '../../components/Builder/utils/serializeAction';
import getIssuesForAction, {
  IssueOptions,
} from '../../components/UpdateAction/utils/getIssuesForAction';
import { IssueMap } from '../../state/flowIssues';

/**
 * Takes an array of ClientFlowAction, serializes them and filters from null values.
 * Returns an array of serializedActions and the issueMap collected from all the actions.
 * @param {Array<ClientFlowAction>}: actions
 * keywords: issueMap, serializedActions, actions, serialized
 */

type ReturnType = {
  serializedActions: Array<FlowV2_Action__Input>;
  issueMap: IssueMap;
};
const getSerializedActions = (
  actions: Array<ClientFlowAction>,
  opts?: IssueOptions,
): ReturnType => {
  const issueMap: IssueMap = {};

  const serializedActions: Array<FlowV2_Action__Input> = actions
    .map(action => {
      const issues = getIssuesForAction({ action, opts });

      if (issues.length !== 0) {
        issueMap[action.id] = issues;
      }

      return serializeAction(action);
    })
    .filter((action): action is FlowV2_Action__Input => !isNil(action));

  return { serializedActions, issueMap };
};

export default getSerializedActions;
