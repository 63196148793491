import React from 'react';
import TEST_ID from '../../index.testid';
import { ANIMATION_CONFIG } from '~/scenes/Settings/constants';
import { animated, useSpring } from 'react-spring';
import { NavigationLink } from '~/components/Navigation';
import styled, { css } from 'styled-components';
import { Link as NavLink } from '~/components/Link/index';

type Props = {
  links: Array<NavigationLink & { onClick?: () => void }>;
};

const MenuLinks: React.FC<Props> = ({ links }) => {
  const animation = useSpring(ANIMATION_CONFIG);

  return (
    <LinksContainer style={animation}>
      {links.map(link => (
        <Link
          key={link.to}
          to={link.to}
          onClick={link.onClick}
          dataTestid={TEST_ID.asNavLink(link.to)}
        >
          {link.label}
        </Link>
      ))}
    </LinksContainer>
  );
};

const LinksContainer = styled(animated.div)<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

const Link = styled(NavLink)<{}>(
  ({ theme }) => css`
    color: ${theme.color('text')};
    text-decoration: none;
    padding: ${theme.space('xl')} 0;
    position: relative;
    font-size: ${theme.fontSize('l')};

    &.active {
      color: ${theme.color('primary', 'light')};

      &::after {
        content: '';
        position: absolute;
        background: ${theme.color('secondary', 'light')};
        width: 4px;
        height: 100%;
        display: block;
        left: -${theme.space('xxl')};
        opacity: 1;
        bottom: 0;
      }
    }
  `,
);

export default MenuLinks;
