import gql from 'graphql-tag';

import TaskListTaskFields from '../fragments/TaskListTaskFields';

export default gql`
  query GetTasks(
    $accountId: ID!
    $deleted: Boolean!
    $officeId: ID!
    $status: TaskStatus
    $userId: ID
    $limit: Int
    $unassigned: Boolean
    $nextToken: ID
  ) {
    getTasks(
      accountId: $accountId
      deleted: $deleted
      officeId: $officeId
      status: $status
      userId: $userId
      limit: $limit
      unassigned: $unassigned
      nextToken: $nextToken
    ) {
      items {
        ...TaskListTaskFields
      }
      nextToken
    }
  }

  ${TaskListTaskFields}
`;
