import React from 'react';
import { Path } from 'slate';
import Button from '~/components/Button';
import { deleteNodeAtPath } from '~/components/PluginsEditor/commands';
import { DHEditor } from '~/components/PluginsEditor/types';

export type Props = {
  dataTestId?: string;
  editor: DHEditor;
  path: Path;
  mode?: 'lowest' | 'highest' | undefined;
};

const DeleteButton: React.FC<Props> = ({ editor, path, mode, ...rest }) => (
  <Button
    appearance="danger"
    icon="trashcan"
    ghost
    onClick={() => {
      deleteNodeAtPath(editor, path, mode);
    }}
    {...rest}
  />
);

export default DeleteButton;
