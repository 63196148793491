import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import AssignBlock from '~/components/AssignBlock';
import Input from '~/components/Input';
import { Body } from '~/components/Typography/index';
import {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/Wizard/context/WizardContext';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import useWizardStep from '~/hooks/useWizardStep';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import RealworksHelpBlock from '../components/RealworksHelpBlock';
import TEST_ID from './index.testid';

const text = {
  header: 'Koppeling instellen',
  description:
    'Maak een koppeling aan en koppel de vestiging in Realworks aan een vestiging en gebruiker in DatHuis.',
  nameInputError: 'De naam van de koppeling kan niet leeg zijn',
  appEntryHeader: '1. Kies een naam voor deze koppeling',
  bodyText:
    'Kies een naam voor deze koppeling. Bijvoorbeeld de vestiging in Realworks.',
  secondAppEntryHeader: '2. Wijs contacten toe aan een vestiging in DatHuis',
  secondBodyText:
    'Wijs relaties die vanuit Realworks worden geimporteerd toe aan een vestiging en gebruiker in DatHuis.',
  inputPlaceHolder: 'Bijv. "Vestiging Amsterdam"',
  nextButton: 'Volgende',
};

export const id = 'RealworksSetup';
export const title = text.header;

export type OutputType = {
  type: typeof id;
  addTokenContainerInput: OutputFieldInvisible<UpdateRealworksAppStatus_AddTokenContainer__Input>;
};

export const Component: React.FC<WizardStepProps> = ({ step, outputMap }) => {
  const [, api] = useWizardStep(step.id);

  const previousValues =
    outputMap[id] && 'addTokenContainerInput' in outputMap[id]
      ? outputMap[id].addTokenContainerInput.value
      : {
          name: '',
          officeId: null,
          userId: null,
        };

  const [values, setValues] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input>(previousValues);

  useEffect(() => {
    const output: OutputType = {
      type: 'RealworksSetup',
      addTokenContainerInput: {
        type: 'invisible',
        value: values,
      },
    };

    if (isEmpty(values.name)) {
      api.lock(output);
    } else {
      api.free(output);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Body>{text.description}</Body>

      <RealworksHelpBlock tipId="setup-step-id" />

      <AppEntryItemHeader>{text.appEntryHeader}</AppEntryItemHeader>
      <Body>{text.bodyText}</Body>
      <Input
        appearance="primary"
        validation={[value => (value === '' ? text.nameInputError : true)]}
        value={values.name}
        placeholder={text.inputPlaceHolder}
        onChange={e => setValues(prev => ({ ...prev, name: e.target.value }))}
        dataTestId={TEST_ID.NAME_INPUT}
      />

      <AppEntryItemHeader>{text.secondAppEntryHeader}</AppEntryItemHeader>
      <Body>{text.secondBodyText}</Body>
      <AssignBlock
        userId={values.userId}
        officeId={values.officeId}
        onChange={({ userId, officeId }) =>
          setValues(prev => ({ ...prev, userId, officeId }))
        }
      />
    </>
  );
};

export default {
  id,
  title,
};
