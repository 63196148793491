import gql from 'graphql-tag';

export default gql`
  mutation InsertAppPremiumValuationReport(
    $accountId: ID!
    $app: App_PremiumValuationReport__Input!
  ) {
    insertAppPremiumValuationReport(accountId: $accountId, app: $app) {
      id
      name
      algasId
      sendAgentEmail
      sendContactEmail
    }
  }
`;
