import gql from 'graphql-tag';

import ConnectedEmailAliasFields from './ConnectedEmailAliasFields';

export default gql`
  fragment OfficeMailboxAliasFields on OfficeMailboxAlias {
    __typename
    officeId
    email {
      ...ConnectedEmailAliasFields
    }
  }

  ${ConnectedEmailAliasFields}
`;
