import type { Node__Input } from '~/graphql/types';
import type { FilterGroupType } from '~/components/Filters/types.flow';

import asLeafInput from './asLeafInput';
import { mapNotNull } from '~/util/array';

const asNodeInput = (filterGroup: FilterGroupType): Node__Input => {
  const { filters, operator } = filterGroup;

  return {
    connector: operator,
    nodes: mapNotNull(filters, filter => {
      const leafInput = asLeafInput(filter);

      if (leafInput == null) {
        // empty filter, do not use it as input
        return null;
      }

      return leafInput;
    }),
  };
};

export default asNodeInput;
