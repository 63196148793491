import cleanedFilename from '~/util/cleanedFilename';

export default {
  ATTACHMENT_LIST_ITEM: `${cleanedFilename(__filename)}-attachment-list-item`,
  DELETE_ATTACHMENT_BUTTON: `${cleanedFilename(
    __filename,
  )}-delete-attachment-button`,
  ADD_ATTACHMENT_BUTTON: `${cleanedFilename(__filename)}-add-attachment-button`,
  ADD_PRIMITIVE_ATTACHMENT_BUTTON: `${cleanedFilename(
    __filename,
  )}-add-primitive-attachment-button`,
  BODY: `${cleanedFilename(__filename)}-body`,
  SUBJECT: `${cleanedFilename(__filename)}-subject`,
  IS_TRANSACTIONAL_SWITCHER: `${cleanedFilename(
    __filename,
  )}-is-transactional-switcher`,
  MARKETING_OPTION: `${cleanedFilename(__filename)}-marketing-option`,
  TRANSACTIONAL_OPTION: `${cleanedFilename(__filename)}-transactional-option`,
  INFO_CONTAINER: `${cleanedFilename(__filename)}-info-container`,
  FROM_ADDRESS_DROPDOWN: `${cleanedFilename(__filename)}-from-address-dropdown`,
  SIGNATURE_DROPDOWN: `${cleanedFilename(__filename)}-signature-dropdown`,
};
