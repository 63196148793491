import {
  RealworksSendContactAction,
  InexactRealworksSendContactActionFieldsFragment,
} from './types.flow';
import { BaseActionProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { asTemplateStringParameterValue } from '../Base/FlowParameter/ParameterValue';

export default (
  item: InexactRealworksSendContactActionFieldsFragment,
  baseProps: BaseActionProps,
): RealworksSendContactAction => {
  const { id, description, tokenContainerId } = item;

  return {
    ...baseProps,
    type: FLOW_ACTION_TYPE.SENDCONTACT_TO_REALWORKS,
    description: asTemplateStringParameterValue(description),
    tokenContainerId: tokenContainerId ?? undefined,
    id,
  };
};
