import React from 'react';

import { FlowConditionContactDetails } from './types.flow';
import { ConditionChoiceType } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';

import {
  SingleFlowConditionPrimitiveFieldComponent,
  MultipleFlowConditionPrimitiveFieldComponent,
  emptyFlowConditionStringField,
} from '../Primitive';
import {
  FLOW_CONDITION_CONTACT_DETAILS_FIELDS,
  DEFAULT_FLOW_CONDITION_CONTACT_DETAILS_FIELD,
} from './constants';
import cleanedFilename from '~/util/cleanedFilename';
import { InputGroup } from '~/components/Inputs';
import { ConditionFieldProp } from '../Primitive/MultipleFlowConditionPrimitiveFieldComponent';

type Props = {
  condition: FlowConditionContactDetails;
  conditionChoiceType?: ConditionChoiceType | null | undefined;
  onChange: (
    newFlowCondition: Omit<FlowConditionContactDetails, 'fields'> & {
      fields: Array<ConditionFieldProp>;
    },
  ) => void;
  announceChanges: () => void;
};
const FlowConditionContactDetailsComponent = ({
  condition,
  conditionChoiceType,
  onChange,
  announceChanges,
}: Props) => {
  const { fields, allShouldBeTrue } = condition;
  const onlyAllowOneField = conditionChoiceType === 'START';
  if (onlyAllowOneField) {
    if (fields.length > 1) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | should have 1 field (${JSON.stringify(
          fields,
          null,
          2,
        )}) given to a startcondition `,
      );
    }

    if (fields.length === 0) {
      onChange({
        ...condition,
        fields: [
          emptyFlowConditionStringField(
            DEFAULT_FLOW_CONDITION_CONTACT_DETAILS_FIELD,
          ),
        ],
      });

      return null;
    }

    return (
      <InputGroup>
        <SingleFlowConditionPrimitiveFieldComponent
          field={fields[0]}
          fieldOptions={FLOW_CONDITION_CONTACT_DETAILS_FIELDS}
          onChange={newField => {
            onChange({
              ...condition,
              fields: [newField],
            });
          }}
        />
      </InputGroup>
    );
  }

  return (
    <MultipleFlowConditionPrimitiveFieldComponent
      initialField={
        fields.length === 0
          ? emptyFlowConditionStringField(
              DEFAULT_FLOW_CONDITION_CONTACT_DETAILS_FIELD,
            )
          : fields[0]
      }
      fields={fields}
      fieldOptions={FLOW_CONDITION_CONTACT_DETAILS_FIELDS}
      onChange={newFields => {
        onChange({
          ...condition,
          fields: newFields,
        });
      }}
      allShouldBeTrue={allShouldBeTrue}
      onAllShouldBeTrueChange={newAllShouldBeTrue => {
        onChange({
          ...condition,
          allShouldBeTrue: newAllShouldBeTrue,
        });
      }}
      announceChanges={announceChanges}
    />
  );
};

export default FlowConditionContactDetailsComponent;
