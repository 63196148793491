import React from 'react';
import { useTransition, animated, UseTransitionProps } from 'react-spring';
import styled, { css } from 'styled-components';

export type Direction = 'left' | 'top' | 'right' | 'bottom';

export type Props = {
  dataTestId?: string;
  /**
   * Options which can be displayed / switched between
   */
  options: Array<JSX.Element | string | number>;
  /**
   * The current position to show, if "undefined"
   * nothing is shown.
   */
  position?: number;
  /**
   * Direction of insertion. The opposite is the exit direction.
   *
   * Default: 'top'
   */
  from?: Direction;
};

/**
 * Animates the switching between one / multiple labels.
 *
 * Labels can be anything, but should be rendered in one line.
 * If you apply padding or borders make sure to translateY to
 * ensure that it is rendered in the center.
 *
 * The height of this component is set to 0px to ensure no
 * flickering / resizing of the parent component.
 */
const AnimatedLabel: React.FC<Props> = ({
  dataTestId,
  options,
  position,
  from = 'top',
  ...rest
}) => {
  let start: string;
  let end: string;

  switch (from) {
    case 'left': {
      start = 'translate3d(-100%,0,0)';
      end = 'translate3d(100%,0,0)';
      break;
    }
    case 'top': {
      start = 'translate3d(0,-100%,0)';
      end = 'translate3d(0,100%,0)';
      break;
    }
    case 'right': {
      start = 'translate3d(100%,0,0)';
      end = 'translate3d(-100%,0,0)';
      break;
    }
    case 'bottom': {
      start = 'translate3d(0,100%,0)';
      end = 'translate3d(0,-100%,0)';
      break;
    }
  }

  const transitionProps: UseTransitionProps = {
    from: { opacity: 0, transform: start },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: end, position: 'absolute' },
  };
  const itemTransition = useTransition(position, transitionProps);

  return (
    <Container data-testid={dataTestId} {...rest}>
      {itemTransition((style, item) => {
        const label = options[item];
        return (
          label != null && (
            <animated.div style={{ ...style, top: '0', right: '0' }}>
              {label}
            </animated.div>
          )
        );
      })}
    </Container>
  );
};

const Container = styled.div<{}>(
  () => css`
    position: relative;
    height: 0px;
    white-space: nowrap;
  `,
);

export default AnimatedLabel;
