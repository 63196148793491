import gql from 'graphql-tag';

import FlowConditionFields from './FlowConditionFields';
import FlowConditionListFields from './FlowConditionListFields';

export default gql`
  fragment FlowActionStartFields on Flow_Action_Start {
    __typename
    id
    accountId
    flowBlueprintId
    startCondition {
      ...FlowConditionFields
    }
    conditionList: condition {
      ...FlowConditionListFields
    }
  }

  ${FlowConditionFields}
  ${FlowConditionListFields}
`;
