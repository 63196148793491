import React from 'react';
import styled from 'styled-components';

import EditableName from '~/components/DetailsPage/EditableName';
import { Form, Field } from '~/components/Forms';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';
import { InputGroup, Input, Checkbox } from '~/components/Inputs';
import SubFieldsHeader from '~/components/Forms/SubFieldsHeader';
import { FormUtils, FormFooterSaveBar } from '~/components';
import useConfirmModal from '~/components/Alerts/useConfirmModal';
import DetailsActionList from '~/components/DetailsPage/DetailsActionList';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import cleanedFilename from '~/util/cleanedFilename';
import URLContainer from './UrlContainer';
import { AVATAR_SIZE } from '~/components/AvatarWithUploader';

const text = {
  reportListTitle: 'Waarderapporten',
  deleteLabel: 'Verwijder',
  algasIdLabel: Catalog.valueReports.algasIdHeader,
  noAlgasId: Catalog.valueReports.noAlgasId,
  settingsHeader: 'Instellingen',
  sendContactEmailLabel: Catalog.valueReports.sendContactEmailLabel,
  sendAgentEmailLabel: Catalog.valueReports.sendAgentEmailLabel,
  deleteWarningLabels: {
    title: 'Waarschuwing',
    message:
      'Door het verwijderen van dit waarderapport komen er geen nieuwe leads meer binnen.',
    buttonConfirmTitle: 'Verwijder',
    buttonCancelTitle: 'Annuleren',
  },
};
export type PremiumValueReportDetailsFields = {
  name: string;
  algasId: string;
  sendContactEmail: boolean;
  sendAgentEmail: boolean;
  domain: string | null;
};
type FormFields = {
  algasId: string | null;
  sendContactEmail: boolean;
  sendAgentEmail: boolean;
  domain: string | null;
};
type Props = {
  report: PremiumValueReportDetailsFields;

  /** If an update is running, so disable fields */
  updating: boolean;
  updateFn: (update: PremiumValueReportDetailsFields) => void;
  onDelete: () => void;
};
const PremiumValueReportDetailsComponent: React.FC<Props> = ({
  report,
  updateFn,
  updating,
  onDelete,
}) => {
  const { name } = report;

  const [showConfirmModal, setOnConfirmFunction] = useConfirmModal(
    text.deleteWarningLabels,
    () => {},
    () => {},
    undefined,
    false,
    'destructive',
  );

  return (
    <ContentContainerDefault
      breadcrumbs={[
        {
          to: '/-/apps/value-report',
          label: text.reportListTitle,
        },
        {
          label: name,
        },
      ]}
    >
      <TitleContainer>
        <EditableName
          name={name}
          edit={true}
          updateName={newName => updateFn({ ...report, name: newName })}
        />
        <DetailsActionList
          options={[
            {
              label: text.deleteLabel,
              onClick: () => {
                setOnConfirmFunction(onDelete);
                showConfirmModal();
              },
              type: 'DELETE',
              key: 'delete-premium-value-report',
            },
          ]}
        />
      </TitleContainer>
      <Form
        onSubmit={newFields => {
          if (newFields.algasId == null) {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | algasId == null`,
            );
          }

          updateFn({
            ...report,
            algasId: newFields.algasId,
            sendContactEmail: newFields.sendContactEmail,
            sendAgentEmail: newFields.sendAgentEmail,
            domain: newFields.domain === '' ? report.domain : newFields.domain,
          });
        }}
        validate={validate}
        initialValues={asFormFields(report)}
      >
        {() => (
          <Container>
            <InputGroup>
              <Field name="algasId">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    disabled
                    large
                    label={text.algasIdLabel}
                    error={FormUtils.showError(error, touched)}
                    {...input}
                  />
                )}
              </Field>
            </InputGroup>
            <InputGroup>
              <Field name="domain">
                {({ input }) => (
                  <URLContainer
                    route={input.value}
                    onChange={value => input.onChange(value)}
                  />
                )}
              </Field>
            </InputGroup>

            <SubFieldsHeader>{text.settingsHeader}</SubFieldsHeader>

            <InputGroup>
              <Field name="sendContactEmail">
                {({ input }) => {
                  const { value, onChange } = input;
                  return (
                    <Checkbox
                      onChange={() => {
                        onChange(!value);
                      }}
                      value={value}
                      label={text.sendContactEmailLabel}
                      name="sendContactEmail"
                    />
                  );
                }}
              </Field>
            </InputGroup>
            <InputGroup>
              <Field name="sendAgentEmail">
                {({ input }) => {
                  const { value, onChange } = input;
                  return (
                    <Checkbox
                      onChange={() => {
                        onChange(!value);
                      }}
                      value={value}
                      label={text.sendAgentEmailLabel}
                      name="sendAgentEmail"
                    />
                  );
                }}
              </Field>
            </InputGroup>
            <FormFooterSaveBar disableSave={updating} />
          </Container>
        )}
      </Form>
    </ContentContainerDefault>
  );
};

const validate = (fields: FormFields) => {
  const errors: {
    algasId: string | undefined;
  } = {
    algasId: undefined,
  };
  if (Validation.String.isEmpty(fields.algasId)) {
    errors.algasId = text.noAlgasId;
  }

  return errors;
};

const asFormFields = (report: PremiumValueReportDetailsFields): FormFields => {
  const { algasId, sendContactEmail, sendAgentEmail, domain } = report;
  return { algasId, sendContactEmail, sendAgentEmail, domain };
};

const TitleContainer = styled.div<{}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => `
    margin-bottom: ${theme.space('xxxxl')};
    height: ${AVATAR_SIZE}px;
  `};
`;

const CONTAINER_MAX_WIDTH = 540;
const Container = styled.section<{}>`
  ${({ theme }) => `
    width: 100%;
    max-width: ${CONTAINER_MAX_WIDTH}px;
    margin-top: ${theme.space('l')};
  `}
`;

export default PremiumValueReportDetailsComponent;
