import gql from 'graphql-tag';

import TaskListTaskFields from '../fragments/TaskListTaskFields';

export default gql`
  mutation InsertTask($accountId: ID!, $contactTask: ContactTask_New!) {
    insertTask(accountId: $accountId, contactTask: $contactTask) {
      ...TaskListTaskFields
    }
  }

  ${TaskListTaskFields}
`;
