import { FlowEmailLink } from '~/scenes/Automation/Flows/types.flow';
import { StringPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { deduplicated, mapNotNull } from '~/util/array';
import {
  unsubscribeLinkForPointer,
  UNSUBSCRIBE_LINK_IN_FROALA,
  UNSUBSCRIBE_LINK_ON_BACKEND,
  MORTGAGE_LINK_IN_FROALA,
  MORTGAGE_LINK_ON_BACKEND,
} from '~/scenes/Automation/Flows/Actions/constants';
import { emptyStringPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base';

const linkRegex = /href="[^"]*/g;

const getLinksFrom = (body: string): Array<string> => {
  const regexedTokens = body.match(linkRegex);

  if (regexedTokens == null) return [];

  // 6 as we don't want the href="
  return regexedTokens.map(token => token.slice(6));
};

export const getDeduplicatedLinksFrom = (
  body: string | null,
  pointer: StringPointerParameterValue,
): Array<FlowEmailLink> => {
  if (body === null) return [];

  const linksFromBody = deduplicated(getLinksFrom(body), a => a);

  const links = mapNotNull(linksFromBody, link => {
    if (link === MORTGAGE_LINK_IN_FROALA || link === MORTGAGE_LINK_ON_BACKEND) {
      return null;
    }

    if (
      link === UNSUBSCRIBE_LINK_IN_FROALA ||
      link === UNSUBSCRIBE_LINK_ON_BACKEND
    ) {
      return unsubscribeLinkForPointer(pointer);
    } else {
      return {
        link: link,
        parameterValue: emptyStringPrimitiveParameterValue(link),
        label: link,
      };
    }
  });

  return links;
};

export default getLinksFrom;
