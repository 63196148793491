import { filter, flatten, isEmpty, propEq } from 'ramda';
import {
  OfficeRelationship,
  SessionHydrationAccountRelationFieldsFragment,
  SessionHydrationOfficeRelationFieldsFragment,
} from '~/graphql/types';
import useCurrentAccount from '../useCurrentAccount';
import useOffices from '../useOffices';
import useSessionHydration from '../useSessionHydration';
import useUserRelationToAccount from '../useUserRelationToAccount';

type UserRelations = {
  officeRelations: Array<SessionHydrationOfficeRelationFieldsFragment> | null;
  accountRelation: SessionHydrationAccountRelationFieldsFragment | null;
};

const useRelationsForUser = (userId: string): UserRelations => {
  const me = useCurrentAccount();
  const [{ relations }] = useSessionHydration();

  const accountRelation = useUserRelationToAccount({
    userId,
    accountId: me.id,
  });

  const offices = useOffices({});
  if (accountRelation === null)
    return {
      accountRelation: null,
      officeRelations: null,
    };
  if (isEmpty(offices))
    return {
      accountRelation,
      officeRelations: null,
    };

  const officeRelationsFromSessionHydration = filter(
    propEq('__typename', 'OfficeRelationship'),
    relations,
  ) as Array<OfficeRelationship>;

  const userRelationToOffices = offices.map(office =>
    officeRelationsFromSessionHydration.filter(
      relation => relation.userId === userId && relation.officeId === office.id,
    ),
  );

  const officeRelations = flatten(userRelationToOffices);

  return { officeRelations, accountRelation };
};

export default useRelationsForUser;
