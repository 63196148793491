import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { InputGroup } from '~/components/Inputs';
import Dropdown from '~/components/Dropdown';
import { OptionOf } from '~/components/Inputs/Dropdown';
import convertTemplateStringToSlateFragment from '~/components/PluginsEditor/utils/flows/convertTemplateStringToSlate';
import TemplateStringInput from '~/components/TemplateStringInput';
import { FlowV2_Action_Contact_DetailsFragment } from '~/graphql/types';
import useEditorStates from '~/components/PluginsEditor/hooks/useEditorStates';
import useRelativeMaps from '~/scenes/Automation/v2/components/Builder/hooks/useRelativeMaps';
import InputLabel from '~/scenes/Apps/components/InputLabel';
import { Props as FormProps } from '../ActionForm';
import TEST_ID from './index.testid';
import { ClientFlowActionContactDetails } from '~/graphql/types.client';
import { isEmpty } from 'lodash';
import { isNil } from 'ramda';

type OptionT = OptionOf<'name' | 'phone'>;
const options: Array<OptionT> = [
  {
    label: 'Naam',
    key: 'name',
    payload: 'name',
  },
  {
    label: 'Telefoonnummer',
    key: 'phone',
    payload: 'phone',
  },
];

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_Contact_DetailsFragment;
};

type State = {
  field: ClientFlowActionContactDetails['field'];
};

const ContactDetails: React.FC<Props> = ({ action, onChange }) => {
  const initials = {
    field: action.field,
  };

  const isNameFilled = !(
    isNil(initials.field.name) || isEmpty(initials.field.name?.template)
  );
  const isPhoneFilled = !(
    isNil(initials.field.phone) || isEmpty(initials.field.phone?.template)
  );
  const noFieldFilled = !isNameFilled && !isPhoneFilled;
  const showNameField = noFieldFilled || isNameFilled;

  const [selectedOption, setSelectedOption] = useState<OptionT>(
    options[showNameField ? 0 : 1],
  );
  const [actionDetails, setActionDetails] = useState<State>(initials);

  const maps = useRelativeMaps({ actionId: action.id });

  const initialNameValue = convertTemplateStringToSlateFragment({
    html: initials.field.name?.template || '',
    mappings: initials.field.name?.mappings || [],
    ...maps,
  });
  const {
    key: nameKey,
    value: nameValue,
    onChange: onNameChange,
    hasChanges: hasNameChanges,
  } = useEditorStates({
    initialValue: initialNameValue,
  });

  const initialPhoneValue = convertTemplateStringToSlateFragment({
    html: initials.field.phone?.template || '',
    mappings: initials.field.phone?.mappings || [],
    ...maps,
  });
  const {
    key: phoneKey,
    value: phoneValue,
    onChange: onPhoneChange,
    hasChanges: hasPhoneChanges,
  } = useEditorStates({
    initialValue: initialPhoneValue,
  });

  useEffect(() => {
    onChange({ ...action, ...actionDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  useEffect(() => {
    if (hasNameChanges) {
      setActionDetails(prev => ({
        field: {
          ...prev.field,
          nameValue,
          // reset phone value
          phoneValue: [],
        },
      }));
    }
  }, [nameValue, hasNameChanges]);

  useEffect(() => {
    if (hasPhoneChanges) {
      setActionDetails(prev => ({
        field: {
          ...prev.field,
          phoneValue,
          // reset name value
          nameValue: [],
        },
      }));
    }
  }, [phoneValue, hasPhoneChanges]);

  return (
    <InputGroup>
      <div>
        <InputLabel label="Veld" />
        <MatchingDropdown
          options={options}
          selectedOptionIdx={options.findIndex(
            option => option.key === selectedOption.key,
          )}
          onChange={option => setSelectedOption(option.option)}
          dataTestId={TEST_ID.DROPDOWN}
        />
      </div>

      {selectedOption.key === 'phone' && (
        <TemplateStringInput
          $key={phoneKey}
          onChange={onPhoneChange}
          value={phoneValue}
          dataTestId={TEST_ID.PHONE_INPUT}
        />
      )}

      {selectedOption.key === 'name' && (
        <TemplateStringInput
          $key={nameKey}
          onChange={onNameChange}
          value={nameValue}
          dataTestId={TEST_ID.NAME_INPUT}
        />
      )}
    </InputGroup>
  );
};

const MatchingDropdown = styled(Dropdown)(
  ({ theme }) => css`
    /* Custom fixed unit because component is not inline with the theme.space system
    This makes it appear like it's inline
   */
    padding: 1.05rem;
    border-radius: ${theme.getTokens().border.radius.s};
  `,
);

export default ContactDetails;
