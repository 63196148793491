import { reportErrorToTracker } from '~/util/assertion';

export default (fieldName: string, value?: string | null): void => {
  if (value === undefined || value === '') {
    reportErrorToTracker(
      new Error(
        `The field '${fieldName}' has the value '${
          value === undefined ? 'undefined' : value
        }'. Values in the <Field> component may not be undefined or an empty string (''). We only allow null or a value. You probably forgot to set initialValues in your <Form>.`,
      ),
    );
  }
};
