import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import TagField from './components/TagField';
import Validation from '~/util/Validation';
import TEST_ID from './TagList.testid';
import InputWithButtons from '~/components/InputWithButtons';

const text = {
  addTagLabel: 'Tag invoeren',
};

type Props = {
  /** Renders a small component */
  small?: boolean;

  /** Renders a large component */
  large?: boolean;

  /** The selected values */
  selectedValues: Array<string>;
  label?: string;
  onAdded: (value: string) => void;
  onRemoved: (value: string, index: number) => void;
  disabled?: boolean;

  tagValue?: string;
  onChange?: (value: string) => void;
};

const TagList = ({
  small,
  large,
  selectedValues,
  onAdded,
  onRemoved,
  disabled,
  label,
  tagValue,
  onChange,
}: Props) => {
  const [value, setValue] = useState<string>(tagValue ?? '');
  const _onChange = onChange ? onChange : setValue;

  // This is needed to synchronize the local state with the tagValue prop
  useEffect(() => {
    setValue(tagValue ?? '');
  }, [tagValue]);
  const attemptAddTag = () => {
    if (selectedValues.includes(value)) {
      _onChange('');
      return;
    }

    if (Validation.Tag.isValid(value)) {
      onAdded(value);
      _onChange('');
    }
  };

  return (
    <Container data-testid={TEST_ID.CONTAINER}>
      <TagsContainer data-testid={TEST_ID.TAGS_CONTAINER}>
        {selectedValues.map((item, index) => (
          <TagField
            small={small}
            key={`tag-${index}`}
            large={large}
            onDelete={() => onRemoved(item, index)}
            value={item}
            isValid={true}
            useValidation={false}
          />
        ))}
      </TagsContainer>
      <InputWithButtons
        label={label}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            attemptAddTag();

            e.preventDefault();
          }
        }}
        onChange={e => {
          const target = e.target as HTMLInputElement;

          _onChange(Validation.Tag.format(target.value));
        }}
        buttonActions={[
          {
            onClick: attemptAddTag,
            icon: 'plus',
            label: text.addTagLabel,
          },
        ]}
        value={value}
        disabled={disabled}
      />
    </Container>
  );
};

const TagsContainer = styled.div<{}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default TagList;
