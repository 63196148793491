import { OptionOf } from '~/components/Inputs/Dropdown';
import {
  SessionHydrationUserFields_User_Fragment,
  UserStatus,
} from '~/graphql/types';
import useUsers from '../useUsers';

const allUsersOption = [
  { label: 'Alle gebruikers', key: 'no-selection', payload: null },
];

const addAllUsersOption = options => [...allUsersOption, ...options];

export type UserOptions = Array<
  OptionOf<SessionHydrationUserFields_User_Fragment | null>
>;

type Filter = {
  officeId?: string | null;
  withAllUsersOption?: boolean;
};

const useUserOptions = (filter?: Filter): UserOptions => {
  const _filter = {
    officeId: filter?.officeId ?? null,
    withAllUsersOption: filter?.withAllUsersOption ?? false,
  };

  const usersInOffice = useUsers({
    officeIds: [_filter.officeId],
    statuses: [UserStatus.Active],
  });
  const allActiveUsers = useUsers({ statuses: [UserStatus.Active] });

  const optionsFromUsersInOffice = usersInOffice.map(user => ({
    key: user.id,
    label: user.name,
    payload: user,
  }));

  const optionsFromAllUsers = allActiveUsers.map(user => ({
    key: user.id,
    label: user.name,
    payload: user,
  }));

  if (_filter.officeId && optionsFromUsersInOffice.length > 0)
    return _filter.withAllUsersOption
      ? addAllUsersOption(optionsFromUsersInOffice)
      : optionsFromUsersInOffice;

  return _filter.withAllUsersOption
    ? addAllUsersOption(optionsFromAllUsers)
    : optionsFromAllUsers;
};

export default useUserOptions;
