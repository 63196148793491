import { ClientFlowAction } from '~/graphql/types.client';
import { InteractionConnectAction } from '~/scenes/Automation/v2/state/interactions';

/**
 * Connect two actions to each other
 * @param {Array<ClientFlowAction>} actions - Actions state
 * @param {ClientFlowAction} currentInteraction - Current interaction that has the source action data
 * @param {ClientFlowAction} targetAction - Action to connect to
 * keywords:
 */
const onConnectActions = ({
  actions,
  currentInteraction,
  targetAction,
}: {
  actions: Array<ClientFlowAction>;
  currentInteraction: InteractionConnectAction;
  targetAction: ClientFlowAction;
}): Array<ClientFlowAction> => {
  const nextActions = [...actions];
  const targetActionIndex = nextActions.findIndex(
    ({ id }) => id === targetAction.id,
  );

  const sourceActionId =
    currentInteraction && 'action' in currentInteraction
      ? currentInteraction?.action?.id
      : null;

  if (
    targetActionIndex !== -1 &&
    'parentIds' in targetAction &&
    sourceActionId
  ) {
    const updatedTargetAction: ClientFlowAction = {
      ...targetAction,
      parentIds: [...targetAction.parentIds, sourceActionId],
    };

    nextActions[targetActionIndex] = updatedTargetAction;
  }

  // If source action is an IfElse action assign its true or false child
  if (currentInteraction.childIdKey) {
    const sourceActionIndex = nextActions.findIndex(
      ({ id }) => id === sourceActionId,
    );
    const updatedSourceAction = {
      ...nextActions[sourceActionIndex],
      [currentInteraction?.childIdKey]: targetAction.id,
    };

    nextActions[sourceActionIndex] = updatedSourceAction;
  }

  return nextActions;
};

export default onConnectActions;
