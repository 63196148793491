import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import {
  AppBbWaardecheckGeneral,
  AppVboWaardecheckGeneral,
} from '~/graphql/types';
import InputGroupDivider from '~/scenes/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import {
  AppWaardecheckFieldsFragment,
  appWaardecheckState,
  StateId,
} from '../..';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import InputGroup from '~/scenes/Apps/components/InputGroup';
import AppSectionHeader from '../../../AppSectionHeader';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import { Checkbox } from '~/components/Inputs';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import HrefInput from '../../components/HrefInput';
import MainPreview from '../../components/MainPreview';
import ImageInput from '../../components/ImageInput';
import { Page } from '../../components/Preview';
import ColorInputComponent from '../../components/ColorInputComponent';
import PreviewContainer from '../../components/PreviewContainer';
import TEST_ID from './index.testid';
import Explanation from '../../components/Explanation';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
};

const Design: React.FC<Props> = ({ data, loading }) => {
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const [sameColors, setSameColors] = useState(
    data.general.primary.background === data.general.tertiary.background &&
      data.general.primary.color === data.general.tertiary.color &&
      data.general.secondary.background ===
        data.general.quaternary.background &&
      data.general.secondary.color === data.general.quaternary.color,
  );

  const updateValue = (
    value: DeepPartial<AppBbWaardecheckGeneral | AppVboWaardecheckGeneral>,
  ) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        general: value,
      }) as any as typeof prev;
    });
  };

  if (updated == null) return null;
  const { general } = updated;

  return (
    <AppDetailsContainer
      header="Ontwerp"
      icon="layout"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Explanation>
        Pas de waardecheck aan naar je eigen huisstijl. Upload je logo en voeg
        een webadres toe zodat een klik op het logo doorverwijst naar je
        website. Voeg vervolgens een achtergrondfoto toe en kies je
        kleurenpallet. <br />
        <br />
        Tip: gebruik de hoofdkleuren van je website om een consistente huisstijl
        uit te stralen.
      </Explanation>
      <MainPreview data={updated} page={Page['/']} />
      <InputGroupDivider />

      <ImageInput
        title="Logo"
        description="Upload een logo. Voeg een link toe om bij een klik de bezoeker naar je website door te sturen. Als je
        de Engelse link niet instelt, dan wordt standaard de Nederlandse link toegepast."
        initialUrl={data.general.logoImage?.url}
        s3Key={general.logoImage?.s3key}
        filename={data.__typename + 'logo'}
        onChange={logoImage => updateValue({ logoImage })}
        dataTestid={TEST_ID.LOGO_IMAGE_UPLOADER}
      />
      <HrefInput
        id="general.logoLink"
        value={general.logoLink}
        onChange={logoLink => {
          updateValue({ logoLink });
        }}
        dataTestId={TEST_ID.LOGO_LINK}
      />
      <InputGroupDivider />
      <ImageInput
        title="Achtergrondfoto"
        description="Upload een achtergrond foto. Deze wordt standaard toegepast door de hele waardecheck. Per
        pagina kan deze worden aangepast in het menu links."
        filename={data.__typename + 'achtergrondfoto'}
        initialUrl={data.general.fallbackImage?.url}
        s3Key={general.fallbackImage?.s3key}
        onChange={fallbackImage => updateValue({ fallbackImage })}
        dataTestid={TEST_ID.FALLBACK_IMAGE_UPLOADER}
      />
      <InputGroupDivider large />
      <AppSectionHeader>Huisstijl</AppSectionHeader>
      <Description>
        Personaliseer de waardecheck met jouw huisstijlkleuren.
      </Description>
      <PreviewContainer
        data={updated}
        previews={[
          { device: 'desktop', page: Page['/'] },
          { device: 'desktop', page: Page['/report'] },
        ]}
      />
      <AppEntryItemHeader>Hoofdkleuren</AppEntryItemHeader>
      <Description>
        Accentkleuren worden toegepast in de stap indicator, laad animatie en
        kaart elementen.
      </Description>
      <InputGroup>
        <ColorInputComponent
          value={general.primary}
          onChange={primary => {
            if (sameColors) updateValue({ tertiary: primary });

            updateValue({ primary });
          }}
          dataTestId={TEST_ID.PRIMARY}
        />
        <ColorInputComponent
          value={general.secondary}
          label={['Achtergrond accent', 'Tekst accent']}
          onChange={secondary => {
            if (sameColors) updateValue({ quaternary: secondary });
            updateValue({ secondary });
          }}
          dataTestId={TEST_ID.SECONDARY}
        />
      </InputGroup>
      <InputGroupDivider />

      <AppEntryItemHeader>Steunkleuren</AppEntryItemHeader>
      <Description>
        De achtergrondkleur wordt toegepast op de hoofdvlakken op de
        contactgegevens pagina, rapport pagina en in links, zoals op de ‘geen
        matches’ pagina. Geef de buttons een andere kleur, zodat het goed
        leesbaar is.
      </Description>
      <Checkbox
        label="Gebruik hoofdkleuren"
        value={sameColors}
        onChange={() => {
          if (!sameColors) {
            updateValue({ tertiary: updated.general.primary });
            updateValue({ quaternary: updated.general.secondary });
          }

          setSameColors(!sameColors);
        }}
      />

      <InputGroup>
        <ColorInputComponent
          value={general.tertiary}
          disabled={sameColors}
          onChange={tertiary => updateValue({ tertiary })}
          dataTestId={TEST_ID.TERTIARY}
        />
        <ColorInputComponent
          value={general.quaternary}
          label={['Achtergrond accent', 'Tekst accent']}
          disabled={sameColors}
          onChange={quaternary => updateValue({ quaternary })}
          dataTestId={TEST_ID.QUARTERNARY}
        />
      </InputGroup>
    </AppDetailsContainer>
  );
};

export default Design;
