import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import icons from '~/components/Icon/components';
import Link from '~/components/Link';
import Tooltip from '~/components/Tooltip';
import useHover from '~/components/util/useHover';

export type Props = {
  icon: keyof typeof icons;
  tooltipLabel: string;
  onClick?: () => void;
  link?: string;
};

const ControlButton: React.FC<Props> = ({
  icon,
  tooltipLabel,
  link,
  onClick,
}) => {
  const [showTooltip, tootlipProps] = useHover();
  return (
    <Container>
      {showTooltip && <StyledTooltip>{tooltipLabel}</StyledTooltip>}
      {link && !onClick ? (
        <Link to={link} target="_blank">
          <StyledIcon {...tootlipProps} name={icon} strokeWidth="2.5" />
        </Link>
      ) : (
        <StyledIcon
          {...tootlipProps}
          name={icon}
          strokeWidth="2.5"
          onClick={onClick}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    position: relative;

    &:first-child ${StyledIcon} {
      border-top-left-radius: ${theme.getTokens().border.radius.base};
      border-bottom-left-radius: ${theme.getTokens().border.radius.base};
    }

    &:last-child ${StyledIcon} {
      border-top-right-radius: ${theme.getTokens().border.radius.base};
      border-bottom-right-radius: ${theme.getTokens().border.radius.base};
    }
  `,
);

const StyledTooltip = styled(Tooltip)<{}>(
  ({ theme }) => css`
    bottom: ${theme.space('xxl')};
    text-align: center;
    width: 100px;
    left: -100%;
    user-select: none;
  `,
);

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    padding: ${theme.space('xxs')};
    color: ${theme.color('primary', 'light')};
    transition: all ease-out 0.3s;
    border: ${theme.getTokens().border.width.s} solid ${theme.color('white')};
    cursor: pointer;

    > svg {
      font-size: ${theme.fontSize('m')};
    }

    &:hover {
      color: ${theme.color('white')};
      border: ${theme.getTokens().border.width.s} solid
        ${theme.color('primary', 'light')};
      background: ${theme.color('primary', 'light')};
    }
  `,
);
export default ControlButton;
