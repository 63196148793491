import { omit } from 'ramda';
import { OutputMap } from '~/components/WizardSteps';
import {
  AppStatus_Realworks__Input,
  AppStatus_Trustoo__Input,
} from '~/graphql/types';
import { AppStatus, AppStatus__typename } from '~/graphql/types.client';
import getMetadataForAppStatus from '../getMetadataForAppStatus';
import { reporter } from '~/hooks/useErrorReporter';
import getWidgetAppConfigurationUpdate from '~/util/getWidgetAppConfigurationUpdate';

const getEnableFlowsUpdate = (
  outputMap: OutputMap,
  typename: AppStatus__typename,
  currentAppStatus?: AppStatus,
) => {
  if (!currentAppStatus) return null;

  if (
    !('metadata' in currentAppStatus) ||
    currentAppStatus.metadata?.setup == null
  ) {
    reporter.captureException(
      new Error(
        `Could not find metadata in passed appStatus: ${currentAppStatus}`,
      ),
      'critical',
    );
    return;
  }

  const appStatus = omit(
    ['__typename', 'locked', 'accountId'],
    currentAppStatus,
  );

  if (typename == 'AppStatus_Realworks') {
    const update: AppStatus_Realworks__Input = {
      ...omit(['tokens', 'relatieSync'], appStatus),
      enabled: true,
      usageAgreement: true,
      metadata: getMetadataForAppStatus(currentAppStatus, outputMap),
    };
    return update;
  }

  if (typename === 'AppStatus_Trustoo') {
    const tokens =
      'tokens' in currentAppStatus
        ? currentAppStatus.tokens.map(token =>
            omit(['__typename', 'TokenInfo'], token),
          )
        : [];

    const update: AppStatus_Trustoo__Input = {
      ...appStatus,
      ...getWidgetAppConfigurationUpdate(currentAppStatus),
      enabled: true,
      tokens,
      metadata: getMetadataForAppStatus(currentAppStatus, outputMap),
    };
    return update;
  }

  const update = {
    ...appStatus,
    ...getWidgetAppConfigurationUpdate(currentAppStatus),
    enabled: true,
    metadata: getMetadataForAppStatus(currentAppStatus, outputMap),
  };
  return update;
};

export default getEnableFlowsUpdate;
