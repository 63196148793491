import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import insertImageAction from '../../../../elements/Image/insertAction';
import Input from '~/components/Inputs/Input';
import TextButton from '~/components/TextButton';
import downloadFileWithSrc from '~/components/HTMLEditor/util/downloadFileWithSrc';
import { isValidURL } from '~/util/Validation/URL';
import useErrorReporter from '~/hooks/useErrorReporter';
import TEST_ID from './index.testid';
import cleanedFilename from '~/util/cleanedFilename';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import { isNil } from 'ramda';
import { acceptedFileTypes } from '../..';
import { DHEditor } from '~/components/PluginsEditor/types';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import getCorsUrl from '~/util/getCorsUrl';

export type Props = {
  dataTestId?: string;
  editor: DHEditor;
  userId: string;
  onClose: () => void;
};

const text = {
  validationError: 'Moet beginnen met http:// of https://',
  downloadFileError: `${cleanedFilename(
    __filename,
  )} >> Image could not be downloaded as a file. This might be due to a cors error or the image url is broken.`,
  invalidImgUrlError:
    'De URL van de afbeelding is niet geldig, probeer een andere URL',
  insertImage: 'Invoegen',
};

const InsertUrlContainer: React.FC<Props> = ({
  dataTestId,
  editor,
  userId,
  onClose,
  ...rest
}) => {
  const [urlValue, setUrlValue] = useState<string>('');
  const [hasValidationError, setHasValidationError] = useState(false);

  const addToast = useAddToast();
  const errorReporter = useErrorReporter();

  const resetFields = useCallback(() => {
    setHasValidationError(false);
    setUrlValue('');
  }, []);

  const onSubmit = useCallback(() => {
    if (!isValidURL(urlValue)) {
      setHasValidationError(true);
      return;
    }

    void downloadFileWithSrc(getCorsUrl(urlValue), file => {
      if (!file || !acceptedFileTypes.includes(file.type)) {
        errorReporter.captureException(
          new Error(text.downloadFileError),
          'critical',
        );

        addToast([formatToastMessage(text.invalidImgUrlError, 'danger')]);
        return;
      }

      resetFields();
      onClose();
      insertImageAction(editor, file, userId);
    });

    resetFields();
    onClose();
  }, [addToast, editor, errorReporter, onClose, resetFields, urlValue, userId]);

  useGlobalKeyBinding({ keys: 'enter', callback: onSubmit, enabled: true });

  return (
    <Container data-testid={dataTestId} {...rest}>
      <InputContainer>
        <Input
          size="base"
          label="URL"
          value={urlValue || ''}
          onChange={e => {
            if (hasValidationError) setHasValidationError(false);

            if (!isNil(e?.target?.value)) setUrlValue(e.target.value);
          }}
          error={hasValidationError ? text.validationError : null}
          name="image-url"
        />
      </InputContainer>
      <TextButton
        label={text.insertImage}
        onClick={() => {
          onSubmit();
        }}
        dataTestId={TEST_ID.INSERT_BUTTON}
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  text-align: end;
`;

const InputContainer = styled.div<{}>`
  /* Fixes FloatingLabel overflow */
  label > div:first-child {
    white-space: normal;
    text-align: left;
  }

  ${({ theme }) => css`
    margin: ${theme.space('l')} 0 ${theme.space('m')};
  `}
`;

export default InsertUrlContainer;
