import gql from 'graphql-tag';

export default gql`
  fragment PropertyFields on Property {
    __typename
    id
    street
    city
    houseNumber
    houseNumberAddition
    builtInYear
    livingAreaSqm
    postcode
    location {
      lat
      lng
    }
    aliasPropertyType: propertyType
  }
`;
