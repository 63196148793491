import React from 'react';
import styled, { css } from 'styled-components';
import { Body, Heading4, Variant } from '~/components/Typography/index';
import ListedItem from '../ListedItem';
import getPathFromUuid from '~/util/getPathFromUUID';
import { IconType } from '~/components/Icon';

export type Items = Array<{
  value: Array<{
    label: string;
    insertedId?: string;
    icon?: IconType;
  }>;
}>;

export type Props = {
  category: React.ReactNode;
  values: Items;
};

const text = {
  ctaLabel: 'Bekijk nu',
  noItems: 'Er zijn geen dingen toegevoegd.',
};

const OutputList: React.FC<Props> = ({ category, values = [] }) => (
  <div style={{ width: '100%' }}>
    <Heading4 withoutMargin variant={Variant.primary}>
      {category}
    </Heading4>

    <ListContainer>
      {values.map(({ value }) =>
        value.length ? (
          value.map(({ label, insertedId, icon }) => (
            <ListedItem
              key={`${label}-${insertedId}`}
              label={label}
              to={insertedId ? getPathFromUuid(insertedId) : null}
              callToAction={text.ctaLabel}
              icon={icon}
            />
          ))
        ) : (
          <Body withoutMargin>{text.noItems}</Body>
        ),
      )}
    </ListContainer>
  </div>
);

const ListContainer = styled.ul(
  ({ theme }) => css`
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: ${theme.space('m')};
  `,
);

export default OutputList;
