import { atom, selectorFamily } from 'recoil';
import { HandledFlowAction } from '~/graphql/types.client';

type BaseIssue = {
  level: 'error' | 'warning' | null;
  actionId: string | null;
  actionType: HandledFlowAction | null;
  message: string;
};

export type ConditionIssue = BaseIssue & {
  type: 'ConditionIssue';
  id: string;
  argIndex?: number | null;
  conditionId?: string;
  level: 'warning' | 'error';
};

export type ValidationIssue = BaseIssue & {
  type: 'ValidationIssue';
  key: string;
  level: 'warning' | 'error';
};

export type BadVariableIssue = BaseIssue & {
  type: 'BadVariableIssue';
  level: 'error';
};

export type MissingInlineImageIssue = BaseIssue & {
  type: 'MissingInlineImageIssue';
  level: 'error';
};

export type UnavailableActionIssue = BaseIssue & {
  type: 'UnavailableActionIssue';
  level: 'error';
  pathToApp: string | null;
};

export type Issue =
  | ConditionIssue
  | ValidationIssue
  | UnavailableActionIssue
  | BadVariableIssue
  | MissingInlineImageIssue;

export type IssueMap = Record<string, Array<Issue>>;

const flowIssues = atom<IssueMap>({
  key: 'flowIssues',
  default: {},
});

export const issuesByActionId = selectorFamily<Array<Issue>, string>({
  key: 'issuesByActionId',
  get:
    (actionId: string) =>
    ({ get }) => {
      const issues = get(flowIssues);
      return issues[actionId] ?? [];
    },
});

export default flowIssues;
