import { subtract, add, isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useKeybinding from '~/hooks/useKeybinding';

import { isNumber } from '~/util/Validation/Number';
import { OnChangeFunction, Option } from '../..';
import getOptionType from '../../utils/getOptionType/index';
import DropdownList from '../DropdownList';
import PositionedComponent from '../PositionedComponent';

export type Props = {
  dataTestId?: string;
  options: Array<Option>;
  onChange: OnChangeFunction;
  selectedOptionIdx?: number | null;
  dropdownListOpen: boolean;
  withDefaultFirstOption?: boolean;
  loading?: boolean;
  dropdownWidth?: number;
  onClose: () => void;
  onClickOutside?: () => void;

  /** Goes to the child container */
  className?: string;
};

const DropdownListContainer: React.FC<Props> = ({
  dataTestId,
  options,
  onChange,
  onClickOutside,
  dropdownListOpen,
  withDefaultFirstOption,
  loading,
  dropdownWidth,
  onClose,
  className,
  ...rest
}) => {
  const selected =
    rest.selectedOptionIdx && rest.selectedOptionIdx >= 0
      ? rest.selectedOptionIdx
      : withDefaultFirstOption
      ? 0
      : null;

  const [selectedOptionIdx, setSelectedOptionIdx] = useState<number | null>(
    selected,
  );

  useEffect(() => {
    if (!dropdownListOpen && isNumber(selectedOptionIdx))
      setSelectedOptionIdx(rest.selectedOptionIdx || 0);
  }, [dropdownListOpen, selectedOptionIdx, rest.selectedOptionIdx]);

  const upDownPress = (e: KeyboardEvent, key) => {
    if (!dropdownListOpen) return;

    const maxSelectedOptionIdx = options.length - 1;
    e.preventDefault();

    if (key === 'up') {
      const disabledOptionAbove =
        getOptionType(options, selectedOptionIdx && selectedOptionIdx - 1) ===
        'DISABLED';

      const topLimitSteps = Math.max(
        0,
        subtract(selectedOptionIdx ?? 0, disabledOptionAbove ? 2 : 1),
      );

      return setSelectedOptionIdx(topLimitSteps);
    } else {
      const disabledOptionBelow =
        getOptionType(options, selectedOptionIdx && selectedOptionIdx + 1) ===
        'DISABLED';

      const bottomLimitSteps = Math.min(
        maxSelectedOptionIdx,
        add(selectedOptionIdx ?? 0, disabledOptionBelow ? 2 : 1),
      );

      return setSelectedOptionIdx(bottomLimitSteps);
    }
  };

  const enterPress = () => {
    if (dropdownListOpen && !isNil(selectedOptionIdx))
      onChange({
        option: options[selectedOptionIdx],
        selectedOptionIdx,
      });
  };

  useKeybinding({
    keys: ['up', 'down'],
    callback: (e: KeyboardEvent, key) => upDownPress(e, key),
  });

  useKeybinding({
    keys: 'enter',
    callback: enterPress,
  });

  useKeybinding({
    keys: 'esc',
    callback: onClose,
  });

  if (dropdownListOpen) {
    return (
      <Container data-testid={dataTestId}>
        <PositionedComponent
          className={className}
          onClickOutside={onClickOutside}
        >
          <DropdownList
            dropdownWidth={dropdownWidth}
            loading={loading}
            options={options}
            onChange={onChange}
            actuallySelected={rest.selectedOptionIdx}
            selectedOptionIdx={selectedOptionIdx}
          />
        </PositionedComponent>
      </Container>
    );
  }
  return null;
};

const Container = styled.div<{}>``;

export default DropdownListContainer;
