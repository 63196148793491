import { ActionTreeNode, KeyedActionSubscriber } from './types.flow';
import { FlowActionProps } from '~/scenes/Automation/Flows/Actions/types.flow';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';

const buildTree = <T>(
  item: T,
  parentActionDict: {
    [key: string]: Array<T>;
  },
  idGetter: (item: T) => string,
  getActionFor: (arg: T) => FlowActionProps,
  getSubscriberFor: (arg: T) => KeyedActionSubscriber | null,
  positionArray: Array<number>,
  parentId: string | null,
  outputLoading: { [actionId: string]: boolean } | Record<string, never>,
): ActionTreeNode => {
  const id = idGetter(item);
  const props = getActionFor(item);
  const children = parentActionDict[id];

  // default function is to add 1 to the last number in the array
  // eslint-disable-next-line
  let getPositionArray = (childId: string): Array<number> => {
    const array = [...positionArray];

    let toAddNumber = 1;
    if (props.type === FLOW_ACTION_TYPE.IF_ELSE) {
      // If under an if/else statement, make the true path the next number and the false path the number after that
      if (props.yesPathChildId === childId) {
        toAddNumber = 1;
      } else if (props.noPathChildId === childId) {
        toAddNumber = 2;
      } else {
        if (props.visiblePath) {
          toAddNumber = 1;
        } else {
          toAddNumber = 2;
        }
      }
    }
    array[array.length - 1] = array[array.length - 1] + toAddNumber;

    if (props.type === FLOW_ACTION_TYPE.IF_ELSE) {
      // if under an if/else we want to add a new step path
      array.push(1);
    }
    return array;
  };

  return {
    id,
    initialActionProps: props,
    parentId,
    positionString: positionArray.join('.'),
    outputLoading: outputLoading[id] ?? false,
    children: Array.isArray(children)
      ? children.map(child =>
          buildTree(
            child,
            parentActionDict,
            idGetter,
            getActionFor,
            getSubscriberFor,
            getPositionArray(getActionFor(child).id),
            id,
            outputLoading,
          ),
        )
      : [],
    subscriber: getSubscriberFor(item),
  };
};

export default buildTree;
