import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import MetaTags from 'react-meta-tags';

import { Task } from './types';

import {
  SessionHydrationOfficeFieldsFragment,
  SessionHydrationUserFields_User_Fragment,
  TaskListTaskFieldsFragment,
} from '~/graphql/types';
import { TaskListType } from '~/scenes/Tasks/util/taskListType';
import AccountContext from '~/contexts/AccountContext';
import { OptionOf, SelectedOptionOf } from '~/components/Inputs/Dropdown/';
import { ViewableOffice } from '~/contexts/AccountContext';

import TaskList from './components/TaskList';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import { TaskModalControllerContext } from './TaskModalControllerContext';
import { WithNavigationFrame as NotFound } from '~/components/404';
import TaskWithSubscriptionGetter from './TaskWithSubscriptionGetter';
import withErrorBoundary from '~/ErrorBoundary';
import asTaskProp from './util/asTaskProp';
import TASK_LIST_TYPE from './util/taskListType';
import useDropdown, {
  asDropdownOption,
} from '~/components/Inputs/Dropdown/useDropdown';
import getTaskFilters from './getTaskFilters';
import {
  setLocalStorageItem,
  TASK_LIST_FILTER_USER_ID,
  TASK_LIST_FILTER_LIST_TYPE,
  TASK_LIST_FILTER_OFFICE_ID,
} from '~/util/localStorageKeys';
import { RouteComponentProps, useLocation } from '@reach/router';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useCurrentUser from '~/hooks/useCurrentUser';
import useMainOffice from '~/hooks/useMainOffice';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import Dropdown from '~/components/Dropdown';
import OverviewListHeader from '~/components/OverviewListHeader';
import Button from '~/components/Button';

export const TASK_LIST_LIMIT = 20;

const text = {
  listTitle: 'Taken',
  pageTitle: 'Taken',
  noOffice: 'Alle vestigingen',
  noUser: 'Alle gebruikers',
  addTaskButtonLabel: 'Nieuwe taak',
};

type Props = RouteComponentProps;

const Tasks: React.FC<Props> = () => {
  const { getUserOptions } = useContext(AccountContext);
  const account = useCurrentAccount();
  const me = useCurrentUser();

  /** Grab defaults (Main office / no specific user) */
  const mainOffice = useMainOffice(me.id);

  // Set up office filter options
  const officeFilterOptions = useOfficeOptions({
    userId: me.id,
    withAllOfficesOption: true,
  });

  let {
    filteredListType,
    filteredOfficeId = null,
    filteredUserId = null,
  } = getTaskFilters(mainOffice);

  const location = useLocation();

  /** Check the location to see if a specific filter needs to be used there */
  const params = new URLSearchParams(location.search);
  if (params.get('officeId') != null) {
    filteredOfficeId = params.get('officeId');
  }
  if (params.get('userId') != null) {
    filteredUserId = params.get('userId');
  }
  if (params.get('status') != null) {
    filteredListType = params.get('status') as TaskListType;
  }

  /**
   * Initial is all + me
   */
  let userFilterOptions: Array<
    OptionOf<SessionHydrationUserFields_User_Fragment | null>
  > = [];
  let initialOfficeIndex = -1;
  let initialUserIndex = -1;

  initialOfficeIndex = officeFilterOptions.findIndex(
    officeOption => officeOption.payload?.id == filteredOfficeId,
  );

  if (initialOfficeIndex === -1) {
    /**
     * An office was set which cannot be found, e.g. invalid location parameter
     * we default to all offices
     */
    initialOfficeIndex = 0;
    filteredOfficeId = null;
  }

  if (filteredOfficeId == null) {
    /**
     * No Office selected, we only allow yourself.
     * This is a decision because of some earlier database constraints
     * this will likely change in the future.
     */
    initialUserIndex = 0;
    userFilterOptions = [asDropdownOption(me)];
    setLocalStorageItem(TASK_LIST_FILTER_USER_ID, me.id);
  } else {
    /**
     * We have an office filter, make sure we only allow the users available for this office.
     */
    userFilterOptions = getUserOptions(filteredOfficeId);
    initialUserIndex = userFilterOptions.findIndex(
      opts => opts.payload?.id == filteredUserId,
    );
  }

  if (initialOfficeIndex === -1) initialOfficeIndex = 0;
  if (initialUserIndex === -1) initialUserIndex = 0;

  filteredOfficeId =
    officeFilterOptions[initialOfficeIndex].payload?.id ?? null;
  filteredUserId = userFilterOptions[initialUserIndex].payload?.id ?? null;

  const [selectedOfficeIdx, setSelectedOffice, selectedOffice] =
    useDropdown<ViewableOffice | null>(
      officeFilterOptions,
      initialOfficeIndex,
      office => {
        setLocalStorageItem(TASK_LIST_FILTER_OFFICE_ID, office?.id ?? null);
      },
    );

  const [selectedUserIdx, setSelectedUser, selectedUser, setSelectedUserIdx] =
    useDropdown<SessionHydrationUserFields_User_Fragment | null>(
      userFilterOptions,
      initialUserIndex,
      user => {
        setLocalStorageItem(TASK_LIST_FILTER_USER_ID, user?.id ?? null);
      },
    );

  const [selectedTaskListTypeIdx, onTaskListTypeChange, selectedTaskListType] =
    useDropdown(
      taskListTypeFilterOptions,
      taskListTypeFilterOptions.findIndex(
        option => option.payload === filteredListType,
      ),
      type => {
        setLocalStorageItem(TASK_LIST_FILTER_LIST_TYPE, type);
      },
    );
  const [initialTaskForModal, setInitialTaskForModal] = useState<Task | null>(
    null,
  );
  const [showModal, setShowModalValue] = useState<boolean>(false);

  const setShowModal = (shouldShowModal: boolean) => {
    if (!shouldShowModal) {
      setInitialTaskForModal(null);
    }

    setShowModalValue(shouldShowModal);
  };
  const showTask = (task: Task | null) => {
    setInitialTaskForModal(task);
    setShowModal(true);
  };

  const onOfficeChange = (
    selectedOption: SelectedOptionOf<SessionHydrationOfficeFieldsFragment | null>,
  ) => {
    setSelectedOffice(selectedOption);
    setSelectedUserIdx(0);
  };

  // Should not occur
  if (!selectedTaskListType) return <NotFound />;

  const accountId = account ? account.id : '';
  const isSpecificOfficeSelected = selectedOffice != null;
  const isMyTaskList = !isSpecificOfficeSelected;

  const listSpecificFilteredOfficeId =
    isSpecificOfficeSelected && selectedOffice ? selectedOffice.id : null;
  const listSpecificFilteredUserId =
    selectedUser == null ? null : selectedUser.id;

  return (
    <>
      <ContentContainerDefault>
        <MetaTags>
          <title>{text.pageTitle}</title>
        </MetaTags>
        <TaskModalControllerContext.Provider
          value={{
            shouldShowModal: showModal,
            defaultOfficeId: listSpecificFilteredOfficeId,
            defaultUserId: listSpecificFilteredUserId,
            initialTaskForModal: initialTaskForModal,
            closeModal: () => setShowModal(false),
            showTaskModal: (task?: Task) => {
              if (task != null) {
                showTask(task);
              } else {
                showTask(null);
              }
            },
          }}
        >
          <TaskListContainer>
            <HeaderContainer>
              <OverviewListHeader
                title={text.listTitle}
                buttons={[
                  {
                    node: (
                      <Button
                        appearance="secondary"
                        onClick={() => setShowModal(true)}
                        dataTestId="add-task-button"
                        label={text.addTaskButtonLabel}
                        size="medium"
                      />
                    ),
                    key: 'add-task-button',
                  },
                ]}
                dataTestId="tasks-header"
              />
            </HeaderContainer>
            <FiltersContainer>
              <TaskFilterContainer>
                <DropdownFilterContainer data-testid="task-filter-bar-task">
                  <Dropdown
                    label="Taken filter"
                    options={taskListTypeFilterOptions}
                    selectedOptionIdx={selectedTaskListTypeIdx}
                    onChange={onTaskListTypeChange}
                  />
                </DropdownFilterContainer>
                <DropdownFilterContainer data-testid="task-filter-bar-office">
                  <Dropdown
                    label="Vestiging filter"
                    options={officeFilterOptions}
                    selectedOptionIdx={selectedOfficeIdx}
                    onChange={onOfficeChange}
                  />
                </DropdownFilterContainer>
                <DropdownFilterContainer data-testid="task-filter-bar-user">
                  <Dropdown
                    label="Gebruiker filter"
                    options={userFilterOptions}
                    selectedOptionIdx={selectedUserIdx}
                    onChange={setSelectedUser}
                    disabled={isMyTaskList}
                  />
                </DropdownFilterContainer>
              </TaskFilterContainer>
            </FiltersContainer>
            <ListContainer>
              <TaskWithSubscriptionGetter
                accountId={accountId}
                selectedUserId={listSpecificFilteredUserId}
                selectedOfficeId={listSpecificFilteredOfficeId}
                selectedListType={selectedTaskListType}
              >
                {({ loadMore, hasMore, tasks, onTaskChanged }) => (
                  <TaskList
                    listType={selectedTaskListType}
                    fetchMoreFn={loadMore}
                    hasMore={hasMore}
                    tasks={tasks}
                    onTaskChanged={onTaskChanged}
                    onAddNewTask={() => setShowModal(true)}
                  />
                )}
              </TaskWithSubscriptionGetter>
            </ListContainer>
          </TaskListContainer>
        </TaskModalControllerContext.Provider>
      </ContentContainerDefault>
    </>
  );
};

const taskListTypeFilterOptions: Array<OptionOf<TaskListType>> = [
  {
    label: 'Openstaande taken',
    payload: TASK_LIST_TYPE.OPEN,
    key: TASK_LIST_TYPE.OPEN,
  },
  {
    label: 'Voltooide taken',
    payload: TASK_LIST_TYPE.CLOSED,
    key: TASK_LIST_TYPE.CLOSED,
  },
];

const TaskFilterContainer = styled.div<{}>`
  display: flex;
`;

const DropdownFilterContainer = styled.div<{}>`
  display: inline-block;

  ${({ theme }) => css`
    margin-right: ${theme.space('m')};
  `};
`;

const TaskListContainer = styled.div<{}>`
  display: grid;

  grid-template-columns: 100%;
  grid-template-rows: [main-start header-start] min-content [header-end filter-start] min-content [filter-end list-start] 1fr [list-end main-end];
`;

const HeaderContainer = styled.div<{}>`
  grid-column: 1 / 1;
  grid-row: header-start / header-end;
`;

const FiltersContainer = styled.div<{}>`
  grid-column: 1 / 1;
  grid-row: filter-start / filter-end;
`;

const ListContainer = styled.div<{}>`
  grid-column: 1/1;
  grid-row: list-start / list-end;

  position: relative;
`;

/**
 * Export for testing
 */
export const filteredAndMappedTasks = (
  list: Array<TaskListTaskFieldsFragment>,
  filteredUserId: string | null | undefined,
  filteredOfficeId: string | null | undefined,
): Array<Task> =>
  list.reduce<Array<Task>>((filtered, task) => {
    if (
      isForUser(task, filteredUserId) &&
      isForOffice(task, filteredOfficeId)
    ) {
      const taskProp = asTaskProp(task);
      if (taskProp !== null) filtered.push(taskProp);
    }
    return filtered;
  }, []);

const isForUser = (
  task: TaskListTaskFieldsFragment,
  filteredUserId: string | null | undefined,
): boolean => !task.userId || !filteredUserId || task.userId === filteredUserId;

const isForOffice = (
  task: TaskListTaskFieldsFragment,
  filteredOfficeId: string | null | undefined,
): boolean =>
  !task.officeId || !filteredOfficeId || task.officeId === filteredOfficeId;

export default withErrorBoundary(Tasks);
