import { FlowConditionAppValuationReportNew } from './types.flow';

import { PARAMETER_VALUE_TYPE } from '../../FlowParameter/ParameterValue/constants';
import cleanedFilename from '~/util/cleanedFilename';
import { FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationReport_New_Fragment } from '~/graphql/types';

/**
 * We ignore the app pointer for now as we don't do anything with it. Is an issue for later
 */
export default (
  condition: FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationReport_New_Fragment,
): Omit<FlowConditionAppValuationReportNew, 'id'> => {
  const { app } = condition;

  if (
    app != null &&
    app.__typename !== PARAMETER_VALUE_TYPE.APP_VALUATIONREPORT_PRIMITIVE
  ) {
    throw new Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | App as pointer is not yet supported. Received ${JSON.stringify(
        condition,
        null,
        2,
      )}`,
    );
  }

  return {
    type: 'Flow_Condition_Event_Contact_App_ValuationReport_New',
    app: app ?? null,
  };
};
