import styled, { css } from 'styled-components';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

type Props = {
  /** Overrides the default font-size */
  size?: string;
};

const HeaderDefault = css`
  margin: 1em 0;
`;

export const H1 = styled.h1<Props>`
  ${HeaderDefault};
  color: ${({ theme }) => theme.color('primary')};
  font-size: ${({ theme, size }) =>
    size != null ? size : theme.fontSize('xxxl')};
`;

export const MarginlessH1 = styled(H1)<Props>`
  margin: 0;
`;

export const H2 = styled.h2<Props>`
  ${HeaderDefault};
  color: ${({ theme }) => theme.color('primary')};
  font-size: ${({ theme, size }) => (size != null ? size : theme.space('xl'))};
`;

export const MarginlessH2 = styled(H2)<Props>`
  margin: 0;
`;

export const H3 = styled.h3<Props>`
  ${HeaderDefault};
  font-weight: normal;
  font-size: ${({ theme, size }) => (size != null ? size : theme.space('l'))};
`;

export const MarginlessH3 = styled(H3)<Props>`
  margin: 0;
`;

export const H4 = styled.h4<Props>`
  ${HeaderDefault};
  font-weight: normal;
  font-size: ${({ size }) => (size != null ? size : `${FONT_SIZE_OF_18}px`)};
`;

export const MarginlessH4 = styled(H4)<Props>`
  margin: 0;
`;

export const H5 = styled.h5<Props>`
  ${HeaderDefault};
  font-weight: normal;
  font-size: ${({ theme, size }) =>
    size != null ? size : theme.fontSize('m')};
`;

export const MarginlessH5 = styled(H5)<Props>`
  margin: 0;
`;

export const H6 = styled.h6<Props>`
  ${HeaderDefault};
  font-weight: normal;
  font-size: ${({ theme, size }) =>
    size != null ? size : theme.fontSize('base')};
`;

export const MarginlessH6 = styled(H6)<Props>`
  margin: 0;
`;

export const Highlight = styled.span<Props>`
  display: inline-block;
  position: relative;
  z-index: 0;

  ::before {
    position: absolute;
    content: ' ';
    display: block;
    height: 0.2em;
    width: 100%;
    bottom: 0.25em;
    z-index: -1;
    background-color: ${({ theme }) => theme.color('accent')};
  }
`;

export const FlexRow = styled.div<Props>`
  display: flex;
`;
