import React from 'react';

import DetailsBlock from '~/components/DetailsBlock';
import { GetBillingDetailsQuery } from '~/graphql';
import query from '~/graphql/query/GetBillingDetails';
import { AppErrorScreen, Loading } from '~/components';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import BillingDetailsBlockComponent from '../BillingDetailsBlockComponent';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import cleanedFilename from '~/util/cleanedFilename';
import { BillingDetailsFieldsFragment } from '~/graphql/types';

type Props = {
  className?: string;
};
const BillingDetailsBlock: React.FC<Props> = ({ className }) => {
  const account = useCurrentAccount();

  return (
    <DetailsBlock className={className} manualLayout>
      <GetBillingDetailsQuery
        name={__filename}
        query={query}
        variables={{ accountId: account.id }}
      >
        {({ data, networkStatus, error, refetch }) => {
          if (isInitialLoadStatus(networkStatus)) {
            return <Loading />;
          }

          const billingData = data?.getBillingDetails;

          if (error || billingData == null) {
            return <AppErrorScreen inline setBackgroundColor={false} />;
          }

          return (
            <BillingDetailsBlockComponent
              billingDetails={convertBillingData(billingData)}
              onSuccessfullUpdate={() => refetch()}
            />
          );
        }}
      </GetBillingDetailsQuery>
    </DetailsBlock>
  );
};

const convertBillingData = (data: any): BillingDetailsFieldsFragment => {
  const { email, name, address, last4 } = data;

  let billingAddress: {
    line1: string | null;
    line2: string | null;
    postcode: string | null;
    city: string | null;
  } | null = null;
  if (address != null) {
    const { line1, line2, city, postcode } = address;

    billingAddress = {
      line1: line1 || null,
      line2: line2 || null,
      postcode: postcode || null,
      city: city || null,
    };
  }

  if (name == null || email == null) {
    throw Error(
      `${cleanedFilename(__filename)} | Should not occur | name (${
        name || 'null'
      }) or email (${email || 'null'}) is null`,
    );
  }

  return {
    email: email,
    name: name,
    last4: last4 || null,
    // @ts-ignore TODO: CHECK
    address: billingAddress,
  };
};

export default BillingDetailsBlock;
