import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import icons from '~/components/Icon/components';
import JustificationContainer from '~/components/JustificationContainer';
import { Heading4, Variant } from '~/components/Typography/index';

export type Props = {
  dataTestId?: string;
  heading: React.ReactNode;
  icon: keyof typeof icons;
  onClick?: () => void;
};

const AsideHeader: React.FC<Props> = ({
  dataTestId,
  heading,
  icon,
  onClick,
  ...rest
}) => (
  <Container
    dataTestId={dataTestId}
    align="center"
    justification="start"
    onClick={onClick}
    nonResponsive
    {...rest}
  >
    <ScaledIcon name={icon} margin={[null, 's', null, null]} />
    <Heading4 withoutMargin variant={Variant.primary}>
      {heading}
    </Heading4>
  </Container>
);

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    cursor: pointer;
    padding: ${theme.space('m')};
  `,
);

const ScaledIcon = styled(Icon)(
  ({ theme }) => css`
    font-size: ${theme.fs('l')};
  `,
);

export default AsideHeader;
