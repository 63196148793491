import gql from 'graphql-tag';

import SubscriptionFields from './SubscriptionFields';
import AccountIssueFields from './subfragments/AccountIssueFields';

export default gql`
  fragment SessionHydrationAccountFields on Account {
    id
    name
    status
    issues {
      ...AccountIssueFields
    }
    subscription {
      ...SubscriptionFields
    }
  }

  ${SubscriptionFields}
  ${AccountIssueFields}
`;
