import { FlowConditionListFieldsFragment } from '~/graphql/types';

import asFlowConditionProp from '../FlowCondition/asFlowConditionProp';
import { grabGroupsFromExpression } from './expressionHelpers';
import emptyConditionList from './emptyConditionList';
import cleanedFilename from '~/util/cleanedFilename';
import asFlowConditionGroupProp from '../FlowCondition/asFlowConditionGroupProp';
import { FlowConditionListWithoutId } from './types.flow';

const asFlowConditionListProp = (
  conditionList?: FlowConditionListFieldsFragment | null | undefined,
): FlowConditionListWithoutId => {
  if (conditionList == null) return emptyConditionList();

  const { exp, conditions } = conditionList;

  const { allShouldBeTrue, conditionGroups } = grabGroupsFromExpression(exp);

  let totalGroupElementsLength = 0;
  conditionGroups.forEach(group => {
    totalGroupElementsLength =
      totalGroupElementsLength + group.conditionIndexes.length;
  });
  if (conditions.length != totalGroupElementsLength) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | groups length (${totalGroupElementsLength}) is not equal to the conditions length (${
        conditions.length
      })`,
    );
  }

  return {
    allShouldBeTrue: allShouldBeTrue,
    conditions: conditionGroups.map(innerGroup => {
      const innerGroupConditions = innerGroup.conditionIndexes.map(
        conditionIdx => conditions[conditionIdx],
      );

      const typename = innerGroupConditions[0].__typename;
      if (
        new Set(innerGroupConditions.map(condition => condition.__typename))
          .size !== 1
      ) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | Cannot have different types of conditions in 1 group (${JSON.stringify(
            conditions,
            null,
            2,
          )})`,
        );
      }
      /** @TODO Fix yolo  */
      switch (typename) {
        case 'Flow_Condition_Contact_Details':
        case 'Flow_Condition_Event_Contact_App_ValuationReport_Details':
        case 'Flow_Condition_Event_Contact_App_ValuationRequest_Details':
        case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details':
        case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details':
        case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details':
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details':
        case 'Flow_Condition_Event_Contact_App_Zapier_Details':
        case 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details':
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details': {
          return asFlowConditionGroupProp(
            innerGroupConditions,
            innerGroup.allShouldBeTrue,
          );
        }
      }

      if (innerGroupConditions.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | ${typename} does not support multiple conditions!`,
        );
      }

      return asFlowConditionProp(innerGroupConditions[0]);
    }),
  };
};

export default asFlowConditionListProp;
