import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';

import AccountContext, { ViewableOffice } from '~/contexts/AccountContext';
import { NoResultSection } from '~/components';
import withErrorBoundary from '~/ErrorBoundary';
import MetaTags from 'react-meta-tags';
import { isObject } from 'lodash';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import { useSpring, animated } from 'react-spring';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useOffices from '~/hooks/useOffices';
import useUserRights from '~/hooks/useUserRights';
import OverviewListHeader from '~/components/OverviewListHeader';
import Button from '~/components/Button';
import { ANIMATION_CONFIG } from '~/scenes/Settings/constants';
import AddOfficeSidebar from '../AddOfficeSidebar';
import OfficeOverviewCard from '../OfficeOverviewCard';
import TEST_ID from './index.testid';

type Props = RouteComponentProps;

const text = {
  pageTitle: 'Vestigingen',
  title: 'Vestigingen',
  noFilterResults: 'Geen vestigingen gevonden voor de zoekterm ',
  addLabel: 'Nieuwe vestiging',
};

const OfficeList: React.FC<Props> = withErrorBoundary<Props>(() => {
  const [filter, updateFilter] = useState<string>('');
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const animation = useSpring(ANIMATION_CONFIG);

  const { refetchSessionHydration, usersForOfficeWithPendingUsers } =
    useContext(AccountContext);
  const account = useCurrentAccount();
  const offices = useOffices({});

  const toggleSidebar = (choice?: boolean) => {
    if (choice == null) {
      setShowSidebar(!showSidebar);
    } else {
      setShowSidebar(choice);
    }
  };

  const filteredOffices = filterOffices(offices, filter);
  const { create: mayCreate, update: mayUpdate } = useUserRights({
    category: 'Offices',
  });

  const accountId = account.id;

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <OverviewListHeader
        title={text.title}
        buttons={[
          mayUpdate
            ? {
                node: (
                  <Button
                    appearance="secondary"
                    onClick={toggleSidebar}
                    dataTestId={TEST_ID.ADD_OFFICE_BUTTON}
                    label={text.addLabel}
                    size="medium"
                  />
                ),
                key: 'add-office-button',
              }
            : undefined,
        ]}
        onFilterChange={updateFilter}
        dataTestId="offices-list-header"
      />

      {mayCreate && (
        <AddOfficeSidebar
          accountId={accountId}
          onOfficeAdded={() =>
            refetchSessionHydration().then(() => toggleSidebar(false))
          }
          hide={() => toggleSidebar(false)}
          isShowing={showSidebar}
        />
      )}

      {offices.length && !filteredOffices.length && (
        <NoResultSection>
          {`${text.noFilterResults} "${filter}"`}
        </NoResultSection>
      )}
      {filteredOffices.length > 0 && (
        <OfficeListContainer data-testid="offices-list" style={animation}>
          {filteredOffices.map(({ name, id, img, address, phone, email }) => {
            const users = usersForOfficeWithPendingUsers(id);

            return (
              <animated.div style={animation} key={id}>
                <OfficeOverviewCard
                  name={name}
                  id={id}
                  img={img}
                  address={address ?? null}
                  users={users}
                  phone={phone}
                  email={email}
                />
              </animated.div>
            );
          })}
        </OfficeListContainer>
      )}
    </ContentContainerDefault>
  );
});

const OfficeListContainer = styled(animated.div)<{}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
`;

// Export for testing
export const filterOffices = (
  offices: Array<ViewableOffice>,
  filter: string,
): Array<ViewableOffice> => {
  if (!filter) return offices;

  const filterLwCase = filter.toLowerCase();

  return offices.filter(office => {
    let testString = '';
    if (isObject(office.address)) {
      const { addition, city, houseNumber, postcode, street } =
        office.address || {};
      const addressStreet = `${street} ${houseNumber}${
        addition ? addition : ''
      }`;
      testString = `${testString} ${addressStreet} ${postcode} ${city}`;
    }

    if (typeof office.name === 'string') {
      testString = `${testString} ${office.name}`;
    }

    if (typeof office.phone === 'string') {
      testString = `${testString} ${office.phone}`;
    }

    if (typeof office.email === 'string') {
      testString = `${testString} ${office.email}`;
    }

    return testString.toLowerCase().includes(filterLwCase);
  });
};

export default OfficeList;
