import React from 'react';
import styled, { css } from 'styled-components';
import { AppBbWaardecheckHref__Input } from '~/graphql/types';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import ControlledInput, {
  UrlValidation,
} from '~/scenes/Apps/components/ControlledInput';
import InputGroup from '../../../InputGroup';
import Description from '../Description';

export type OnChangeCallback = (value: AppBbWaardecheckHref__Input) => void;

export type Props = {
  dataTestId?: string;
  id: string;
  title?: string;
  description?: string;
  value: AppBbWaardecheckHref__Input;
  onChange?: OnChangeCallback;
};

const HrefInput: React.FC<Props> = ({
  dataTestId,
  id,
  value,
  title,
  description,
  onChange,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    {title && <AppEntryItemHeader>{title}</AppEntryItemHeader>}
    {description && <Description>{description}</Description>}
    <InputGroup>
      <ControlledInput
        label="Nederlandse link"
        placeholder="https://..."
        id={`${id}.href.nl`}
        value={value.href.nl}
        validation={[UrlValidation]}
        onChange={nl =>
          onChange && onChange({ ...value, href: { ...value.href, nl } })
        }
        dataTestId={`${dataTestId}-nl`}
      />
      <ControlledInput
        label="Engelse link"
        placeholder="https://..."
        id={`${id}.href.en`}
        value={value.href.en}
        validation={[UrlValidation]}
        onChange={en =>
          onChange && onChange({ ...value, href: { ...value.href, en } })
        }
        dataTestId={`${dataTestId}-en`}
      />
    </InputGroup>
  </Container>
);

const Container = styled.div<{}>(
  () =>
    css`
      width: 100%;
    `,
);

export default HrefInput;
