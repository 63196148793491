import { ConditionOperation } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/types.flow';

import { FLOW_CONDITION_PRIMITIVE_STRING_OPERATION } from './String';
import { FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION } from './Boolean';
import { FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION } from './Number';

import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

type Options = {
  dropdownLabel?: boolean;
};
const describeOperation = (
  operation: ConditionOperation,
  options?: Options,
): string => {
  const asDropdownLabel =
    options == null ? false : options.dropdownLabel === true;

  switch (operation.type) {
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.EQUALS:
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.EQUALS: {
      if (operation.negate) {
        if (asDropdownLabel) {
          return 'is niet gelijk aan';
        }

        return 'niet gelijk is aan';
      }

      if (asDropdownLabel) {
        return 'is gelijk aan';
      }

      return 'gelijk is aan';
    }

    case FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS: {
      if (asDropdownLabel) {
        return 'is gelijk aan';
      }

      return 'gelijk is aan';
    }
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.STARTS_WITH: {
      if (operation.negate) {
        if (asDropdownLabel) {
          return 'begint niet met';
        }

        return 'niet begint met';
      }

      return 'begint met';
    }
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.ENDS_WITH: {
      if (operation.negate) {
        if (asDropdownLabel) {
          return 'eindigt niet met';
        }

        return 'niet eindigt met';
      }

      return 'eindigt met';
    }
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.CONTAINS: {
      if (operation.negate) {
        if (asDropdownLabel) {
          return 'bevat geen';
        }

        return 'bevat geen';
      }

      return 'bevat';
    }
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.LESS_THAN: {
      if (operation.negate) {
        if (asDropdownLabel) {
          return 'is niet minder dan';
        }

        return 'niet minder is dan';
      }

      if (asDropdownLabel) {
        return 'is minder aan';
      }

      return 'minder is dan';
    }
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.GREATER_THAN: {
      if (operation.negate) {
        if (asDropdownLabel) {
          return 'is niet meer dan';
        }

        return 'niet meer is dan';
      }

      if (asDropdownLabel) {
        return 'is meer dan';
      }

      return 'meer is dan';
    }
    default:
      return assertNever(operation, cleanedFilename(__filename));
  }
};

export default describeOperation;
