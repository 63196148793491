import { FlowConditionAppBBWaardecheckContactRequestNew } from './types.flow';
import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';
const emptyFlowConditionAppBBWaardecheckContactRequestNew = (): Omit<
  FlowConditionAppBBWaardecheckContactRequestNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_BBWAARDECHECK_CONTACTREQUEST_NEW
    .type,
  event: null,
});

export default emptyFlowConditionAppBBWaardecheckContactRequestNew;
