import styled, { css } from 'styled-components';
import Color from 'color';

const ExternalLink = styled.a<{ disabled?: boolean }>(
  ({ theme, disabled = false }) => {
    const fontColor = theme.color('primary', 'light');
    const disabledFontColor = theme.color('grey');

    return css`
      color: ${disabled ? disabledFontColor : fontColor};

      &:visited {
        color: ${disabled ? disabledFontColor : fontColor};
      }

      &:hover {
        color: ${disabled
          ? disabledFontColor
          : Color(fontColor).darken(0.2).hsl().toString()};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
      }

      &:focus {
        color: ${fontColor};
      }
    `;
  },
);

export default ExternalLink;
