import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';

import { H4 } from '~/components/Typography';
import EditButtonsTools from '~/components/Buttons/EditButtonsTools';
import TaskInfoBlock from '~/components/TaskInfoBlock';
import { Alerts, FormUtils } from '~/components/';
import { Input } from '~/components/Inputs';
import { Field, DateField, TimeField } from '~/components/Forms';
import { iconForTypeInHeader, taskTypeOptions } from '~/util/taskTypeOptions';
import { stringSlicer } from '~/util/string';
import { Task } from '~/scenes/Tasks/types';
import { TaskStatus, TaskType } from '~/graphql/types';
import Dropdown, { SelectedOption } from '~/components/Inputs/Dropdown';
import Editor from '~/components/Editor';
import TEST_ID from './index.testid';
import InputGroup from '~/components/Inputs/InputGroup';
import Button from '~/components/Button';
import useOffice from '~/hooks/useOffice';
import getUserName from '~/util/getUserName';
import useUser from '~/hooks/useUser';
import { FormRenderProps } from 'react-final-form';
import { FormType } from '~/components/ActivityTabs/components/CreateNewTaskTab';
import useCurrentUser from '~/hooks/useCurrentUser';
import AccountContext from '~/contexts/AccountContext';
import { UpdateTaskMutationFunction } from '~/graphql';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import text from '../../text';
import { convertDateToServerDate } from '~/util/date';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useViewingModeProps from '~/hooks/useViewingModeProps';

export type Props = {
  dataTestId?: string;
  formProp: FormRenderProps<FormType, Partial<FormType>>;
  taskDetails: Task;
  handleCloseModal: () => void;
  contactId?: string | null;
  loading: boolean;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  errorMsg?: string | null;
  updateTask: UpdateTaskMutationFunction;
  dueDateDate: string | null;
};

const TITLE_MAX_LENGTH = 60;

const ActualForm: React.FC<Props> = ({
  formProp,
  taskDetails,
  handleCloseModal,
  contactId,
  loading,
  isEdit,
  setIsEdit,
  errorMsg,
  updateTask,
  dueDateDate,
  ...rest
}) => {
  const viewingModeProps = useViewingModeProps();
  const accountContext = useContext(AccountContext);
  const account = useCurrentAccount();
  const [validate, setValidate] = useState<boolean>(false);

  const { handleSubmit, errors, submitting, form } = formProp;

  const disabledForm = submitting || loading;

  const { getFieldState, change } = form;
  const { getUserOptionsForOffice } = accountContext;

  const officeFieldState = getFieldState('officeId');
  const userChoice = getUserOptionsForOffice(officeFieldState?.value);

  const formValues = formProp.values;
  const reset = formProp.form.reset;

  const officeName = useOffice(formValues.officeId)?.name ?? null;
  const user = useUser(formValues.userId);
  const userName = user ? getUserName(user) : null;

  const me = useCurrentUser();
  const officeChoices = useOfficeOptions({ userId: me.id });

  const preSubmitCheck = () => {
    const hasErrors = errors && Object.keys(errors).length > 0;
    if (hasErrors) {
      setValidate(hasErrors);
    }
    return hasErrors;
  };

  const { id, status } = taskDetails;

  const toggleTaskStatus = (updateTask: UpdateTaskMutationFunction) => {
    if (!contactId) return;

    const newStatus =
      status === TaskStatus.Open ? TaskStatus.Closed : TaskStatus.Open;

    return updateTask({
      variables: {
        accountId: account.id,
        id,
        update: {
          status: newStatus,
        },
      },
    }).then(() => {
      handleCloseModal();
    });
  };

  const setEditMode = (isEdit: boolean) => {
    setIsEdit(isEdit);
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <Container
        data-testid={TEST_ID.CONTAINER}
        data-queryinflight={loading}
        {...rest}
      >
        <TitleContainer isEdit={isEdit}>
          <IconWrapper isEdit={isEdit}>
            <Field name="type">
              {({ input: { onChange, value }, meta: { error } }) => {
                if (isEdit) {
                  return (
                    <DropdownContainer>
                      <Dropdown
                        hideLabelOnTop
                        disabled={disabledForm}
                        dataTestid={TEST_ID.TYPE_FIELD}
                        error={FormUtils.showError(error, validate)}
                        onChange={selectedOption => {
                          const { option } = selectedOption;
                          return onChange(
                            option ? option.payload : TaskType.Call,
                          );
                        }}
                        selectedOptionIdx={taskTypeOptions.findIndex(
                          option => option.payload === value,
                        )}
                        options={taskTypeOptions}
                      />
                    </DropdownContainer>
                  );
                } else {
                  return iconForTypeInHeader(value);
                }
              }}
            </Field>
          </IconWrapper>
          <Field name="title">
            {({ input, meta: { error } }) => {
              if (isEdit) {
                return (
                  <div>
                    <Input
                      data-testid={TEST_ID.TASK_TITLE}
                      disabled={disabledForm}
                      type="text"
                      error={FormUtils.showError(error, validate)}
                      {...viewingModeProps}
                      {...input}
                    />
                  </div>
                );
              } else {
                return (
                  <Title
                    data-testid={TEST_ID.TASK_TITLE_TEXT}
                    {...viewingModeProps}
                  >
                    {stringSlicer(input.value, TITLE_MAX_LENGTH)}
                  </Title>
                );
              }
            }}
          </Field>
          <EditButtonsContainer>
            <EditButtonsTools
              handleSubmit={() => {
                const hasErrors = preSubmitCheck();
                if (!hasErrors) {
                  void handleSubmit();
                }
              }}
              setEditMode={setEdit => {
                if (!setEdit) {
                  reset();
                }
                setEditMode(setEdit);
              }}
              editMode={isEdit}
              saveLoading={loading}
            />
          </EditButtonsContainer>
        </TitleContainer>

        {isEdit ? (
          <EditInfoContainer>
            <InputGroup>
              <Field name="dueDateDate">
                {({ input: { value, onChange }, meta: { error } }) => {
                  const touched = dueDateDate !== value;
                  return (
                    <DateField
                      error={FormUtils.showError(error, touched)}
                      label={text.fields.date}
                      value={value}
                      inputComponentProps={{
                        // readOnly does not work as expected
                        // readOnly: true,
                        disabled: loading,
                      }}
                      onChange={onChange}
                      name="dueDateDate"
                      data-testid={TEST_ID.DUE_DATE_DATE}
                      inputComponentType="BORDERLESS"
                    />
                  );
                }}
              </Field>

              <Field name="dueDateTime">
                {({ input: { onChange, value }, meta: { error } }) => (
                  <TimeField
                    value={value}
                    disabled={disabledForm}
                    error={FormUtils.showError(error, validate)}
                    name="dueDateTime"
                    onChange={onChange}
                    data-testid={TEST_ID.DUE_DATE_TIME}
                    inputComponentType="BORDERLESS"
                  />
                )}
              </Field>

              <Field name="officeId">
                {({ input: { onChange, value }, meta: { error } }) => (
                  <Dropdown
                    dataTestid={TEST_ID.OFFICEID_FIELD}
                    disabled={disabledForm}
                    label={text.fields.userAndOfficeLabel}
                    error={FormUtils.showError(error, validate)}
                    onChange={(selectedOption: SelectedOption) => {
                      const { option } = selectedOption;
                      const payload = option ? option.payload : null;
                      const newOfficeId = payload ? payload.id : null;
                      onChange(newOfficeId);
                      change('userId', null);
                    }}
                    selectedOptionIdx={officeChoices.findIndex(office => {
                      if (value === null) return office.payload === null;
                      return office.payload?.id == value;
                    })}
                    options={officeChoices}
                  />
                )}
              </Field>

              <Field name="userId">
                {({ input: { onChange, value }, meta: { error } }) => (
                  /**
                   * This has been changed
                   * within the refactoring to ts
                   * If this creates issues (other than the obvious terrible legacy code)
                   * Talk to Philipp
                   */
                  <Dropdown
                    label={text.fields.userAndOfficeLabel}
                    dataTestid={TEST_ID.USERID_FIELD}
                    disabled={disabledForm}
                    error={FormUtils.showError(error, validate)}
                    onChange={(selectedOption: SelectedOption) => {
                      const { option } = selectedOption;
                      const payload = option ? option.payload : null;
                      onChange(payload?.id ? payload.id : null);
                      if (payload == null) {
                        change('userId', null);
                      }
                    }}
                    selectedOptionIdx={userChoice.findIndex(user => {
                      if (value === null) return user.payload === null;
                      return user.payload?.id == value;
                    })}
                    options={userChoice}
                  />
                )}
              </Field>
            </InputGroup>
          </EditInfoContainer>
        ) : (
          <TaskInfoBlock
            contactName={null}
            officeName={officeName}
            userName={userName}
            dueDate={convertDateToServerDate(
              formValues.dueDateDate,
              formValues.dueDateTime,
            )}
          />
        )}

        <DescriptionContainer {...viewingModeProps}>
          <Field name="description">
            {({ input, meta: { submitSucceeded } }) => {
              if (isEdit) {
                return (
                  <Editor
                    dataTestId={TEST_ID.DESCRIPTION_TEXTAREA_FIELD}
                    disabled={disabledForm}
                    value={input.value}
                    placeholder="Hoe kan je deze taak omschrijven?"
                    onChange={value => input.onChange(value)}
                    shouldReset={submitSucceeded}
                  />
                );
              }

              return (
                <TaskDescription
                  data-testid={TEST_ID.DESCRIPTION_TEXT}
                  dangerouslySetInnerHTML={{
                    __html: input.value,
                  }}
                />
              );
            }}
          </Field>
        </DescriptionContainer>
        <BottomBarContainer noMessage={!errorMsg}>
          {errorMsg && (
            <ErrorWrapper>
              <Alerts.SimpleText type={'error'} text={errorMsg} />
            </ErrorWrapper>
          )}
          {!isEdit && (
            <ButtonBar>
              <Button
                data-testid={TEST_ID.CHANGE_STATUS_BUTTONS}
                onClick={() => toggleTaskStatus(updateTask)}
                loading={loading}
                appearance="secondary"
                size="medium"
                label={
                  status === TaskStatus.Open
                    ? text.changeStatusButtonClose
                    : text.changeStatusButtonOpen
                }
              />
            </ButtonBar>
          )}
        </BottomBarContainer>
      </Container>
    </form>
  );
};

type WrapperProps = {
  isEdit: boolean;
};

const IconWrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: icon-start / title-start;
  ${({ isEdit }) => {
    if (!isEdit) {
      return css`
        svg {
          font-size: 20px;
        }
      `;
    }
    return ``;
  }};
`;
const Title = styled(H4)<{}>`
  grid-column: title-start / title-end;
`;

const EditButtonsContainer = styled.div<{}>`
  grid-column: edit-start / buttons-end;
  display: flex;

  ${({ theme }) => css`
    margin-left: ${theme.space('s')};
  `};
`;

const TitleContainer = styled.div<WrapperProps>`
  display: grid;
  align-items: center;
  grid-row: start / title-containe-end;
  grid-column: 1/1;
  ${({ isEdit }) => {
    const iconWidth = isEdit ? '70px' : '40px';
    return css`
      grid-template-columns:
        [icon-start] ${iconWidth} [title-start] minmax(100px, auto)
        [title-end edit-start] minmax(min-content, 75px) [buttons-end];
    `;
  }};
`;

const EditInfoContainer = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const DropdownContainer = styled.div<{}>`
  display: inline-block;
  ${({ theme }) => css`
    margin-right: ${theme.space('xxs')};
  `};
`;

const DescriptionContainer = styled.div<{}>`
  display: flex;
  word-break: break-all;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
`;

const TaskDescription = styled.div<{}>`
  border: 0;
  min-width: 100%;
  min-height: 90px;

  ${({ theme }) =>
    css`
      margin: ${theme.space('m')} 0;
      padding: ${theme.space('s')} ${theme.space('m')};
    `};

  p {
    margin: 0;
  }
`;

const ErrorWrapper = styled.div<{}>`
  > div {
    padding: 0;
  }
`;

const BottomBarContainer = styled.div<{ noMessage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ noMessage }) => {
    if (noMessage) {
      return css`
        justify-content: flex-end;
      `;
    }
    return null;
  }};
`;

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    padding: 0 ${theme.space('m')};
  `};
`;

const ButtonBar = styled.div<{}>`
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => css`
    padding: ${theme.space('s')} ${theme.space('xxs')};
  `};
`;

export default ActualForm;
