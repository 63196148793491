import gql from 'graphql-tag';

import FlowActionFields from './subfragments/FlowActionFields';

export default gql`
  fragment FlowFields on Flow {
    _v
    accountId
    id
    name
    enabled
    maximumFlowRun
    createdDate
    lastModified {
      date
    }
    Actions {
      ...FlowActionFields
    }
    Statistics {
      completedFlowInstancesTotal
      nonCompletedFlowInstancesTotal
    }
  }

  ${FlowActionFields}
`;
