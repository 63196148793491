import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';

import { StringPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { OptionOf } from '~/components/Inputs/Dropdown';
import BaseActionContext from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { WithControlledSwitcherProps } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { Dropdown } from '~/components/Inputs';
import Input from '~/components/Inputs/Input';
import TEST_ID from './StringPrimitiveParameterValueComponent.testid';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import AddParameterValueButton from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/AddParameterValueButton';
import { InputWithAddButtonInGroup } from '~/components/Inputs/InputWithAddButton';
import emptyStringPrimitiveParameterValue from './emptyStringPrimitiveParameterValue';

const CUSTOM_OPTION = 'custom-option';
const text = {
  inputLabel: 'Waarde',
  requiredError: Catalog.requiredField,
};
type Props = {
  value: StringPrimitiveParameterValue;
  options?: Array<OptionOf<string | null>>;
  /** If options are given, allowCustom adds an extra option => custom which shows the input */
  allowCustom?: boolean;
  inputLabel?: string;
  dataTestid?: string;
  error?: string;
  isMandatory?: boolean;
  /** A format function where the primitive value will be pushed through to get the final version */
  formatFunction?: (str: string) => string | null;
  change: WithControlledSwitcherProps<StringPrimitiveParameterValue>;
};

const StringPrimitiveParameterValueComponent = ({
  value,
  options,
  allowCustom,
  inputLabel,
  dataTestid,
  error,
  isMandatory,
  formatFunction,
  change,
}: Props) => {
  const baseActionContext = useContext(BaseActionContext);
  const { showValidation } = useContext(FlowContext);
  const [, setKey] = useState<string | null>(null);
  const [_value, setValueState] = useState(value);
  // need a ref for the onHandleAdd function
  const _valueRef = useRef(value);

  const setValue = newValue => {
    _valueRef.current = newValue;
    setValueState(newValue);
  };

  const [hasChosenCustom, setHasChosenCustom] = useState(
    options &&
      options.map &&
      !options.map(option => option.payload).includes(_value.value),
  );

  const validate = useCallback(() => {
    if (isMandatory === true && _value.value == null) return false;
    return true;
  }, [_value.value, isMandatory]);

  useEffect(() => {
    const _key = baseActionContext.subscribeValidator({ validate });
    setKey(_key);

    return () => {
      baseActionContext.unsubscribeValidator(_key);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      if (e.target != null) {
        const newPrimValue =
          formatFunction == null
            ? e.target.value
            : formatFunction(e.target.value);

        const convertedNewPrimValue = newPrimValue === '' ? null : newPrimValue;
        const newValue = { ..._value, value: convertedNewPrimValue };

        if (newValue.value !== _valueRef.current.value) {
          setValue(newValue);
          if (change.controlled) {
            change.onChange(newValue);
          }
        }
      }
    },
    [_value, change, formatFunction],
  );

  const handleAdding = useCallback(() => {
    if (!('onAdded' in change)) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Showed the AddParameterValueButton even though onAdded was not given`,
      );
    } else {
      change.onAdded(_valueRef.current);

      setValue(emptyStringPrimitiveParameterValue());
    }
  }, [change]);

  let shouldShowAddButton = false;
  const mandatoryErrorMessage =
    isMandatory && showValidation && !validate() ? text.requiredError : null;

  if (options != null && Array.isArray(options)) {
    let selectedIdx = options.findIndex(
      option => option.payload === _value.value,
    );

    const allOptions = [...options];
    if (allowCustom === true) {
      allOptions.push({
        label: 'Vul in...',
        payload: CUSTOM_OPTION,
        key: CUSTOM_OPTION,
      });

      if (!hasChosenCustom) {
        shouldShowAddButton = true;
      } else {
        selectedIdx = allOptions.findIndex(
          option => option.payload === CUSTOM_OPTION,
        );
      }
    } else {
      shouldShowAddButton = true;
    }

    return (
      <>
        <Dropdown
          label={inputLabel || text.inputLabel}
          error={error}
          options={allOptions}
          selectedOptionIdx={selectedIdx}
          dataTestid={dataTestid == null ? TEST_ID.DROPDOWN_INPUT : dataTestid}
          onChange={({ option }) => {
            let val = option.payload;
            if (val === CUSTOM_OPTION) {
              val = null;

              setHasChosenCustom(true);
            } else {
              if (hasChosenCustom) {
                setHasChosenCustom(false);
              }
            }

            const newValue = { ..._value, value: val };

            setValue(newValue);
          }}
        />
        {allowCustom === true && hasChosenCustom && (
          <InputComponent
            controlled={change.controlled}
            inputLabel={inputLabel}
            error={error}
            mandatoryErrorMessage={mandatoryErrorMessage}
            dataTestid={dataTestid}
            value={_value.value}
            onChange={handleInputChange}
            onSubmit={handleAdding}
          />
        )}

        <AddParameterValueButton
          isVisible={shouldShowAddButton}
          onClick={handleAdding}
        />
      </>
    );
  }

  return (
    <InputComponent
      controlled={change && change.controlled}
      inputLabel={inputLabel}
      error={error}
      mandatoryErrorMessage={mandatoryErrorMessage}
      dataTestid={dataTestid}
      value={_value.value}
      onChange={handleInputChange}
      onSubmit={handleAdding}
    />
  );
};

type InputComponentProps = {
  controlled: boolean;
  inputLabel?: string;
  error?: string | null;
  mandatoryErrorMessage: string | null;
  dataTestid?: string;
  value: string | null;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
};
const InputComponent = ({
  controlled,
  inputLabel,
  error,
  mandatoryErrorMessage,
  dataTestid,
  value,
  onChange,
  onSubmit,
}: InputComponentProps) => {
  if (controlled) {
    return (
      <Input
        label={inputLabel || text.inputLabel}
        error={error || mandatoryErrorMessage}
        data-testid={dataTestid == null ? TEST_ID.PRIMITIVE_INPUT : dataTestid}
        value={value}
        onChange={onChange}
      />
    );
  }
  return (
    <InputWithAddButtonInGroup
      label={inputLabel || text.inputLabel}
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
      data-testid={dataTestid == null ? TEST_ID.PRIMITIVE_INPUT : dataTestid}
    />
  );
};

export default StringPrimitiveParameterValueComponent;
