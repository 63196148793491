import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import { Heading4, Variant } from '~/components/Typography/index';
import { ClientFlowAction } from '~/graphql/types.client';

import { CONTROLS_LAYER } from '../../../../constants/zIndexes';
import getCardHeading from '../../../../utils/getCardHeading';
import { getCardValues } from '../../../nodeTypes/components/CardTemplate/utils/getCardValues';

const ListItem: React.FC<{
  disabled?: boolean;
  draggedType?: ClientFlowAction['actionType'] | null;
  actionType: ClientFlowAction['actionType'];
  onDragStart?: React.DragEventHandler<HTMLLIElement>;
  onDragEnd?: React.DragEventHandler<HTMLLIElement>;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
}> = ({
  disabled = false,
  draggedType,
  actionType,
  onDragStart,
  onDragEnd,
  onClick,
}) => {
  const cardValues = getCardValues({
    actionType,
    issueLevel: null,
  });

  return (
    <Container
      data-testid={actionType}
      draggable={!isNil(onDragStart)}
      $color={cardValues.color}
      $disabled={
        disabled || (!isNil(draggedType) && draggedType !== actionType)
      }
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onClick={onClick}
    >
      <Icon name={cardValues.icon} margin={[null, 'xxs', null, null]} />
      <Heading4 size="s" withoutMargin variant={Variant.primary}>
        {getCardHeading(actionType)}
      </Heading4>
    </Container>
  );
};

const Container = styled.li<{
  $disabled?: boolean;
  $color: string;
}>(
  ({ theme, $disabled, $color }) => css`
    cursor: pointer;
    margin-bottom: 8px;

    color: ${$disabled ? theme.color('grey', 'light') : theme.color('text')};
    padding: ${theme.space('base')};

    max-width: 200px;
    width: 100%;
    pointer-events: all;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: ${theme.fs('m')};

    transition: all 0.3s ease-out;

    position: relative;

    > * {
      z-index: ${CONTROLS_LAYER + 1};
    }

    &[draggable='true'] {
      cursor: grab;
    }

    &:before {
      z-index: ${CONTROLS_LAYER + 1};
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      border-left: 4px solid
        ${$disabled ? theme.color('grey', 'light') : $color};
    }

    &:after {
      z-index: 30;
      content: ' ';
      position: absolute;
      top: -${theme.space('xxs')};
      right: -${theme.space('xxs')};
      bottom: -${theme.space('xxs')};
      left: -${theme.space('xxs')};
      background-color: ${theme.color('white')};
      border-radius: ${theme.getTokens().border.radius.base};
      transition: all 0.2s ease-out;
    }

    &:hover {
      z-index: ${CONTROLS_LAYER + 2};
      &:after {
        box-shadow: ${theme.boxShadow('around')};
      }
    }
  `,
);

export default ListItem;
