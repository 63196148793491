import { isArray, isFunction, isObject } from 'lodash';
import { reportErrorToTracker } from './assertion';

/**
 * Deep merge 2 objects.
 *
 * We merge the first argument into the second.
 * That means that if there is a conflict, the second will determine the value.
 *
 * Note: Arrays are overwritten, they are not merged.
 */
export const mergeDeep = (toMerge: $Object, mergeInto: $Object): $Object => {
  const output = Object.assign({}, toMerge);
  if (isPureObject(toMerge) && isPureObject(mergeInto)) {
    Object.keys(mergeInto).forEach(key => {
      if (isPureObject(mergeInto[key])) {
        if (!(key in toMerge)) Object.assign(output, { [key]: mergeInto[key] });
        else output[key] = mergeDeep(toMerge[key], mergeInto[key]);
      } else {
        Object.assign(output, { [key]: mergeInto[key] });
      }
    });
  }
  return output;
};

/**
 * For some purposes (mergeDeep) we do not want to have arrays/functions be seen as object
 */
export const isPureObject = (item: any): boolean =>
  isObject(item) && !isArray(item) && !isFunction(item);

/**
 * Test if the object is empty. So it has to be an object, but without keys (so {})
 */
export const isEmptyObject = (item: any): boolean =>
  isObject(item) && Object.getOwnPropertyNames(item).length === 0;

/**
 * Deep comparing of two objects.
 */
export const deepEquals = (
  obj1: $Object | null | undefined,
  obj2: $Object | null | undefined,
): boolean => {
  // if either isn't an object then return simple equals as there are no properties
  if (
    obj1 === null ||
    obj1 === undefined ||
    typeof obj1 !== 'object' ||
    obj2 === null ||
    obj2 === undefined ||
    typeof obj2 !== 'object'
  ) {
    return obj1 === obj2;
  }

  for (const p in obj1) {
    switch (typeof obj1[p]) {
      case 'object':
        if (!deepEquals(obj1[p], obj2[p])) return false;
        break;

      case 'function':
        if (
          typeof obj2[p] === 'undefined' ||
          (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())
        )
          return false;
        break;
      default:
        if (obj1[p] !== obj2[p]) return false;
    }
  }

  for (const p in obj2) {
    if (typeof obj1[p] === 'undefined') return false;
  }
  return true;
};

/**
 * Helps to comparing two objects with fields of object type and find quantity of difference
 */
export const findDifference = (
  obj1: $Object,
  obj2: $Object,
): {
  differenceCount: number;
  dataIsNotEqual: boolean;
} => {
  let differenceCount = 0;
  let dataIsNotEqual = false;
  for (const key in obj1) {
    if (!isObject(obj1[key]) || !isObject(obj2[key]) || obj1[key] === null) {
      const notEqual = obj1[key] !== obj2[key];
      differenceCount = notEqual ? differenceCount + 1 : differenceCount;

      if (notEqual) {
        dataIsNotEqual = notEqual;
      }
    } else {
      const equal = deepEquals(obj1[key], obj2[key]);

      if (!equal) {
        for (const deepKey in obj1[key]) {
          if (
            isObject(obj1[key][deepKey]) &&
            isObject(obj2[key][deepKey]) &&
            obj1[key][deepKey] !== null
          ) {
            const deepDiff = findDifference(
              obj1[key][deepKey],
              obj2[key][deepKey],
            );

            differenceCount += deepDiff.differenceCount;
          } else {
            if (obj1[key][deepKey] !== obj2[key][deepKey]) {
              differenceCount += 1;
            }
          }
        }

        dataIsNotEqual = true;
      }
    }
  }

  return {
    differenceCount,
    dataIsNotEqual,
  };
};

/**
 * Can be used to encode the given Object (including nested) to url parameters. Returns string of url params.
 */
export const serialiseObject = (obj: $Object): string => {
  const pairs: Array<string> = [];

  for (const prop in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
      continue;
    }
    if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
      pairs.push(serialiseObject(obj[prop]));
      continue;
    }
    pairs.push(encodeURIComponent(prop) + '=' + encodeURIComponent(obj[prop]));
  }
  return pairs.join('&');
};

/**
 * Used for removing "__typename" from query response.
 * Return cleaned object works with any schemas objects which can be retrieved from query
 */
export const cleanObjectForTypename = (obj: $Object): $Object => {
  const dataItem = obj;
  if (typeof dataItem === 'object' && dataItem !== null) {
    delete dataItem.__typename;
    for (const nestedKey in dataItem) {
      if (!Object.prototype.hasOwnProperty.call(dataItem, nestedKey)) {
        continue;
      }
      dataItem[nestedKey] = cleanObjectForTypename(dataItem[nestedKey]);
    }
  }
  return dataItem;
};

/* Convert object to a safe one for the server.
 *
 * Rules: We do not accept functions, empty strings or undefined as a value.
 * Any violation will be thrown as error in dev mode to fix, in production it will be sent to the tracker but safely handled.
 */
export const safeMutationObject = (obj: $Object): $Object => {
  if (!isPureObject(obj)) {
    throw Error(
      `A non-object ${obj.toString()} was given to the safeMutationObject function`,
    );
  }
  const returnObject = {};
  for (const prop in obj) {
    const value = obj[prop];

    if (isPureObject(value)) {
      returnObject[prop] = safeMutationObject(value);
    } else if (value === '') {
      reportErrorToTracker(
        new Error(
          `An empty string was found in the variables for property ${prop}. The server does not accept empty strings, this should always be null`,
        ),
      );
      returnObject[prop] = null;
    } else if (typeof value === 'function') {
      // remove this prop from the object by not setting anything!
      reportErrorToTracker(
        new Error(
          `A function was found in the variables for property ${prop}. The server does not accept functions, how did this happen?`,
        ),
      );
    } else {
      returnObject[prop] = value;
    }
  }

  return returnObject;
};

/**
 * Safely get all the keys of the object. If the given parameter is not a pure object an empty array is returned
 */
export const safeGetKeys = (object: any): Array<any> => {
  if (isPureObject(object)) {
    return Object.keys(object);
  } else {
    return [];
  }
};

export const toLookupTable = <T extends any>(
  array: Array<T>,
  key: keyof T,
): { [key: string]: T | undefined } =>
  array.reduce<{ [key: string]: T }>((lookupTable, entry) => {
    const value = entry[key];
    lookupTable[value + ''] = entry;

    return lookupTable;
  }, {});
