import gql from 'graphql-tag';

const ContactFragment = gql`
  fragment ContactFields on Contact {
    id
    name
    email
    phone
    status
    address {
      addition
      city
      houseNumber
      postcode
      street
    }
    userId
    officeId
    tags
    blockMarketing
    LeadScore
  }
`;

export default gql`
  query GetContact($accountId: ID!, $contactId: ID!) {
    getContact(accountId: $accountId, contactId: $contactId) {
      ...ContactFields
    }
  }
  ${ContactFragment}
`;
