import { ClientFlowAction } from '~/graphql/types.client';
import getDirectParentIdsOfAction from '~/scenes/Automation/v2/components/Builder/utils/getDirectParentIdsOfAction';

/**
 * Sorts actions starting from the first added action towards the child actions
 * [startAction, childOfStartAction, nextChild...]
 *
 * @param {Array<ClientFlowAction>} actions - actions
 * keywords: sort actions with parent ids
 */
const sortActionsOnParentIds = ({
  allActions,
  actionsToSort,
  subjectAction,
}: {
  allActions: Array<ClientFlowAction>;
  actionsToSort: Array<ClientFlowAction>;
  subjectAction: ClientFlowAction;
}): Array<ClientFlowAction> => {
  // These ids are already sorted. We use them to sort given actions
  const directParentIds = getDirectParentIdsOfAction(allActions, subjectAction);

  const sortedActions = actionsToSort
    .map(a => ({
      ...a,
      tempIndex: directParentIds.findIndex(id => id === a.id),
    }))
    .sort((a, b) => b.tempIndex - a.tempIndex)
    .map(a => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { tempIndex, ...rest } = a;
      return rest;
    });

  return sortedActions;
};

export default sortActionsOnParentIds;
