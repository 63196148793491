import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

export type TaskListType = 'Open' | 'Closed' | 'Deleted';
enum TASK_LIST_TYPE {
  OPEN = 'Open',
  CLOSED = 'Closed',
  DELETED = 'Deleted',
}

export const ensureStringIsTaskListType = (
  str: string,
): TaskListType | null => {
  const converted = str.toUpperCase();

  if (converted === TASK_LIST_TYPE.OPEN.toUpperCase()) {
    return TASK_LIST_TYPE.OPEN;
  } else if (converted === TASK_LIST_TYPE.CLOSED.toUpperCase()) {
    return TASK_LIST_TYPE.CLOSED;
  } else if (converted === TASK_LIST_TYPE.DELETED.toUpperCase()) {
    return TASK_LIST_TYPE.DELETED;
  }

  reportErrorToTracker(
    `${cleanedFilename(
      __filename,
    )} | should not occur | converted (${converted}) is not a list type`,
  );

  return null;
};

export const dashboardTitleForTaskListType = (type: TaskListType): string => {
  switch (type) {
    case TASK_LIST_TYPE.OPEN:
      return 'Mijn taken';
    case TASK_LIST_TYPE.CLOSED:
      return 'Voltooide taken';
    case TASK_LIST_TYPE.DELETED:
      return 'Verwijderde taken';
    default:
      reportErrorToTracker(
        `${cleanedFilename(
          __filename,
        )} | should not occur | dashboardTitleForTaskListType not defined for type (${type})`,
      );
      return '';
  }
};

export default TASK_LIST_TYPE;
