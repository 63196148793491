import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import JustificationContainer from '~/components/JustificationContainer';

export type Props = {
  dataTestId?: string;
  onClose?: () => void;
  maxWidth?: number;
};

const DEFAULT_MAX_WIDTH = 1100;

const Modal: React.FC<Props> = ({
  dataTestId,
  onClose,
  children,
  maxWidth = DEFAULT_MAX_WIDTH,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest} $maxWidth={maxWidth}>
    {onClose && (
      <CloseButton onClick={onClose} data-testid="close-button">
        <Icon name="close" strokeWidth={2.5} />
      </CloseButton>
    )}
    <Inner>{children}</Inner>
  </Container>
);

const Inner = styled.div<{}>`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
`;

const Container = styled(JustificationContainer)<{ $maxWidth: number }>(
  ({ theme, $maxWidth }) => css`
    position: relative;
    background: ${theme.color('white')};
    max-width: ${$maxWidth}px;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    border-radius: ${theme.getTokens().border.radius.base};
    box-shadow: ${theme.getTokens().boxShadow.modal};

    ${theme.mq.lessThan('desktop')`
      width: ${$maxWidth}px;
      max-width: 90vw;
    `}
  `,
);

const CloseButton = styled.button<{}>(
  ({ theme }) => css`
    position: absolute;
    right: -${theme.space('m')};
    top: -${theme.space('m')};
    width: ${theme.space('xl')};
    height: ${theme.space('xl')};

    box-shadow: ${theme.getTokens().boxShadow.base};
    border-radius: ${theme.getTokens().border.radius.full};
    border: none;
    color: ${theme.color('primary', 'light')};
    background-color: ${theme.color('white')};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out;
    z-index: ${theme.getTokens().zIndex.top};
    cursor: pointer;

    &:hover {
      box-shadow: ${theme.getTokens().boxShadow.cardHover};
      background-color: ${theme.color('tertiary', 'light')};
      color: ${theme.color('danger')};
    }
  `,
);
export default Modal;
