import gql from 'graphql-tag';

import FlowParameterStringFields from './FlowParameterStringFields';
import FlowConditionFields from './FlowConditionFields';
import FlowActionContactDetailsFields from './FlowActionContactDetailsFields';
import FlowActionStartFields from './FlowActionStartFields';
import FlowActionWaitFields from './FlowActionWaitFields';
import FlowActionTaskCreateFields from './FlowActionTaskCreateFields';
import FlowActionContactAddTagFields from './FlowActionContactAddTagFields';
import FlowActionContactDeleteTagFields from './FlowActionContactDeleteTagFields';
import FlowActionSendEmailPlainFields from './FlowActionSendEmailPlainFields';
import FlowActionIfElseFields from './FlowActionIfElseFields';
import FlowActionContactAssignFields from './FlowActionContactAssignFields';
import FlowActionRealworksSendContactFields from './FlowActionRealworksSendContactFields';
import FlowActionZapierTriggerFields from './FlowActionZapierTriggerFields';

export default gql`
  fragment FlowActionFields on Flow_Action {
    __typename

    ... on Flow_Action_Contact_Details {
      ...FlowActionContactDetailsFields
    }

    ... on Flow_Action_SendEmail_Plain {
      ...FlowActionSendEmailPlainFields
    }

    ... on Flow_Action_Start {
      ...FlowActionStartFields
    }

    ... on Flow_Action_Wait {
      ...FlowActionWaitFields
    }

    ... on Flow_Action_Task_Create {
      ...FlowActionTaskCreateFields
    }

    ... on Flow_Action_Contact_AddTag {
      ...FlowActionContactAddTagFields
    }

    ... on Flow_Action_Contact_DeleteTag {
      ...FlowActionContactDeleteTagFields
    }

    ... on Flow_Action_IfElse {
      ...FlowActionIfElseFields
    }

    ... on Flow_Action_Contact_Assign {
      ...FlowActionContactAssignFields
    }

    ... on Flow_Action_Realworks_SendContact {
      ...FlowActionRealworksSendContactFields
    }

    ... on Flow_Action_Zapier_Trigger {
      ...FlowActionZapierTriggerFields
    }
  }

  ${FlowParameterStringFields}
  ${FlowConditionFields}
  ${FlowActionContactDetailsFields}
  ${FlowActionStartFields}
  ${FlowActionWaitFields}
  ${FlowActionTaskCreateFields}
  ${FlowActionContactAddTagFields}
  ${FlowActionContactDeleteTagFields}
  ${FlowActionSendEmailPlainFields}
  ${FlowActionIfElseFields}
  ${FlowActionContactAssignFields}
  ${FlowActionRealworksSendContactFields}
  ${FlowActionZapierTriggerFields}
`;
