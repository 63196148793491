import React, { HTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';
import DatHuisLoading_TestId from './index.testid';

const DatHuisLoading: React.FC<HTMLAttributes<HTMLDivElement>> = props => (
  <Container data-testid={DatHuisLoading_TestId.LoadingAnimation} {...props}>
    <SVGContainer
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      version="1.1"
      viewBox="0 0 1917.1 755.2"
      xmlSpace="preserve"
    >
      <Dat d="M1084.7,269.9V351v208v176.4c0,6.7-5.4,12.2-12.2,12.2h-56.8c-6.7,0-12.2-5.4-12.2-12.2V477.2	c0-6.7-5.4-12.2-12.2-12.2h-12.1c-23.8,0.1-47.6,0.1-71.4,0.1h-29.4c-4.8,0-9.8-0.6-14.5,0.5c-29.5,6.8-27.9,36.5-27.9,60.5v33	c0,6.7-5.4,12.2-12.2,12.2h-53c-6.7,0-12.2-5.5-12.4-12.3V446v-95v-81.1c0-6.8,5.5-12.2,12.2-12.2h51.5c6.8,0,12.2,5.5,12.2,12.2	v76.5c0,31.2,11.4,50.7,35.7,50.7c3.5,0,16.1,0.2,39.7,0.2c23.7,0,54.3,0,57.8-0.2c18-0.7,37.3-22.8,37.3-40.4v-86.8	c0-6.7,5.4-12.2,12.2-12.2h55.5C1079.2,257.7,1084.7,263.1,1084.7,269.9z M1499.2,312.7c-25.8,0-46.7,20.9-46.7,46.7	s20.9,46.7,46.7,46.7s46.7-20.9,46.7-46.7S1525,312.7,1499.2,312.7z M1404.8,454.9h-76.4v145.2c0,19.5,2.1,39.6-7.1,57.5	c-8.8,17.1-26.3,25.5-51.4,25.5s-38.4-7.1-51.4-18.8c-12.9-11.7-12.5-43.1-12.5-43.1V454.8h-75.7v0c0,0,1.3,182.7,1.9,190.8	c0.8,8,2.1,27.2,16.7,57.3s61.9,52.3,122.1,52.3s99.9-28.9,117.5-56.9c17.6-28.1,16.3-72.8,16.3-72.8V454.9z M1756.6,585.6	c-18.6-9.6-38.9-14.7-58.6-21.3c-13.7-4.6-41.5-11-37-31.1c3.6-16.3,24.7-15.2,37.6-14.4c10.4,0.7,21,3.3,30.7,6.9	c2.4,0.8,27.7,13,26.8,14.2c12.9-17.6,25.7-35.3,38.6-52.9v0c0,0-17.4-16.8-50.5-29s-73.5-8.7-96.6-1.6c-19,5.8-36.9,20.4-47.5,37	c-16.7,26.2-20.8,73.1,1,98.2c16.7,19.2,43.3,30.3,67.1,37.4c14.5,4.3,28.9,9.1,42.9,14.8c10.7,4.3,23.4,8.8,22.4,23	c-1.7,23.1-34.9,21.4-51,19c-12-1.8-23.7-5.6-34.7-10.8c-2.8-1.3-29.9-16.4-29.2-17.3l-39.3,56c24.5,16.1,51.3,31.6,80.2,37.8	c42.8,9.2,104.1,3.6,130.4-36.4c16.5-25.1,21.5-64.8,7.6-92C1788.9,606.3,1773.1,594.1,1756.6,585.6z M1888.2,697.1	c-16,0-28.9,12.9-28.9,28.9s12.9,28.9,28.9,28.9s28.9-12.9,28.9-28.9S1904.2,697.1,1888.2,697.1z M1461.1,747.1h74.4V454.8h-74.4	V747.1z" />
      <Huis d="M369.7,225.9c-0.4-12.8-1.7-25.6-3.8-38.3c-6.2-37-20.9-72.8-44.4-102.1c-13.7-17.1-30.4-31.9-49.2-43.2	C232.1,18,184.8,4.5,138.2,1.3c-38.3-2.7-77.6-2-116-1.3C14.7,0.1,7.5-0.2,0,0v458.1c61.7,0,126,5.4,186.7-7.3	c73.7-15.4,139.8-63.8,168.1-134.9C366.2,287.2,370.6,256.5,369.7,225.9L369.7,225.9z M198.9,369.8c-41.9,15.5-122,9.2-122,9.2V80.2	c41.9-4.6,102,0.9,141.2,19.1s69.2,48.3,71,130.1S240.8,354.3,198.9,369.8L198.9,369.8z M629.8,164.4v24c0,0-29.8-25.8-81.3-27.5	c-47.5-1.6-97.4,23.7-125.6,61.4c-34.7,46.3-40.1,116.2-11.3,166.4c27.5,47.9,85.5,76.4,140,76.4c48,0,77.3-26.6,77.3-26.6v19.6	h74.6V164.9L629.8,164.4L629.8,164.4z M548.5,393.1c-44.3,0-80.2-35.9-80.2-80.2s35.9-80.2,80.2-80.2s80.2,35.9,80.2,80.2	S592.8,393.1,548.5,393.1z M758.6,80.3v263.5c0,17.9,0,33.3,6.2,50.2c6.3,17.2,16,33.5,29.8,45.7c30.2,26.6,80.8,25.3,80.8,25.3	h30.2v-67.8h-33.2c-33.5,0-37.9-27.7-37.9-54.3V232.6H900c7.5,0,13.6-6.1,13.6-13.6v-44.8c0-7.5-6.1-13.6-13.6-13.6h-50.3	c-7.5,0-13.6-6.1-13.6-13.6V80.3c0-7.5-6.1-13.6-13.6-13.6h-50.3C764.7,66.7,758.6,72.8,758.6,80.3L758.6,80.3z" />
    </SVGContainer>
  </Container>
);

const Container = styled.div<{}>`
  max-width: 480px;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SVGContainer = styled.svg<{}>`
  width: 100%;
`;

const DASH_ARRAY = 2000;
const dash = keyframes`
  50% { stroke-dashoffset: 0; }
  to { stroke-dashoffset: ${DASH_ARRAY}; }
`;

const Path = styled.path<{}>`
  stroke-width: 5px;
  stroke-dasharray: ${DASH_ARRAY};
  stroke-dashoffset: ${DASH_ARRAY};
  animation: ${dash} 5s ease-in-out infinite;
  fill: transparent;
`;

const Dat = styled(Path)<{}>`
  stroke: #2676a5;
`;
const Huis = styled(Path)<{}>`
  stroke: #4a4a4a;
`;

export default DatHuisLoading;
