import gql from 'graphql-tag';

import FlowConditionFields from './FlowConditionFields';

export default gql`
  fragment FlowConditionListFields on Flow_ConditionList {
    exp
    conditions {
      ...FlowConditionFields
    }
  }

  ${FlowConditionFields}
`;
