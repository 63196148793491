/**
 * Contains all default styles.
 *
 * Some of them might be overwritten later on with user styles
 */
export type { SystemBorder, BorderArgument, BorderMap } from './border';
export { default as border } from './border';

export type { SystemBreakpoint, SystemBreakpointMap } from './breakpoints';
export { default as breakpoints } from './breakpoints';

export type { SystemFontWeight, SystemFontWeightMap } from './fontWeight';
export { default as fontWeight } from './fontWeight';

export type { SystemGrid, SystemGridMap } from './grid';
export { default as grid } from './grid';

export type { SystemLineHeight, SystemLineHeightMap } from './lineHeight';
export { default as lineHeight } from './lineHeight';

export type { SizeMap, SystemSize, SystemSizeMap } from './sizes';
export { default as sizes } from './sizes';

export type { SystemZIndex, SystemZIndexMap } from './zIndex';
export { default as zIndex } from './zIndex';

export type {
  SystemColorPalette,
  BaseColor,
  BaseColorVariant,
  ColorVariant,
} from './colorPalette';
export { default as colorPalette } from './colorPalette';

export type { SystemBoxShadow, SystemBoxShadowMap } from './boxShadow';
export { default as boxShadow } from './boxShadow';
