import { FlowParameterEventFieldsFragment } from '~/graphql/types';
import { ZapierPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../../constants';
import emptyZapierEventParameterValue from './emptyZapierEventParameterValue';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

export default (
  eventZapierParameter:
    | Extract<
        FlowParameterEventFieldsFragment,
        {
          __typename: 'Flow_Parameter_Event_Contact_App_Zapier_Pointer';
        }
      >
    | null
    | undefined,
): ZapierPointerParameterValue => {
  if (eventZapierParameter == null) {
    return emptyZapierEventParameterValue();
  }
  switch (eventZapierParameter.__typename) {
    case 'Flow_Parameter_Event_Contact_App_Zapier_Pointer':
      return {
        type: PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_ZAPIER_POINTER,
        variable: {
          name: eventZapierParameter.variable,
          field: eventZapierParameter.field
            ? {
                name: eventZapierParameter.field,
              }
            : null,
        },
      };

    default:
      return assertNever(
        eventZapierParameter.__typename,
        cleanedFilename(__filename),
      );
  }
};
