import React from 'react';

export type RouterTransitionContextProps = {
  canLeave: boolean;
  leavePageConfirmDialogOpen: boolean;
  setLeavePageConfirmDialogOpen: (isOpen: boolean, cb?: () => void) => void;
  nextRoute: string | null | undefined;
  setNextRoute: (nextRoute: string) => void;
  leaveHandler: (confirm: boolean, cb?: () => void) => void;
};

export const RouterTransitionContext =
  React.createContext<RouterTransitionContextProps>({
    canLeave: true,
    leavePageConfirmDialogOpen: false,
    setLeavePageConfirmDialogOpen: () => {},
    nextRoute: null,
    setNextRoute: () => {},
    leaveHandler: () => {},
  });

export const withRouterTransitionContext = (Component: any) =>
  function ConnectedComponent(props: any) {
    return (
      <RouterTransitionContext.Consumer>
        {(routerTransitionContext: RouterTransitionContextProps) => (
          <Component {...props} {...routerTransitionContext} />
        )}
      </RouterTransitionContext.Consumer>
    );
  };

export default RouterTransitionContext;
