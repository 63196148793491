import { INLINE_ID_FROM_IMAGE_SRC } from '~/components/PluginsEditor/constants';
import { ClientFlowAction } from '~/graphql/types.client';
import { Issue } from '~/scenes/Automation/v2/state/flowIssues';

export const text = {
  brokenImages:
    'De afbeeldingen in de e-mail zijn niet correct ge-upload, probeer het opnieuw',
};

/**
 * Return issues if the image is broken
 * @param {ClientFlowAction} action - Client action
 * keywords: emailIssue, brokenImage, inlineImage
 */
const collectIssuesForImages = (action: ClientFlowAction) => {
  const issues: Array<Issue> = [];

  if (action.__typename === 'FlowV2_Action_SendEmail_Plain') {
    if (!action.body.template) return issues;

    const match = action.body.template.match(INLINE_ID_FROM_IMAGE_SRC);

    const inlineId = match ? match[1] : null;

    if (!inlineId) return issues;

    const existsInAttachments = action.attachments.find(
      attachment => attachment.inlineId === inlineId,
    );

    if (!existsInAttachments) {
      issues.push({
        message: text.brokenImages,
        level: 'error',
        type: 'MissingInlineImageIssue',
        actionId: action.id,
        actionType: action.actionType,
      });
    }
  }

  return issues;
};

export default collectIssuesForImages;
