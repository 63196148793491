import {
  TypedField,
  ParameterField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { emptyFlowParameterStringField } from './FlowParameterStringField';
import { emptyFlowParameterTemplateStringField } from './FlowParameterTemplateStringField';

export default (field: TypedField): ParameterField => {
  switch (field.type) {
    case FIELD_TYPE.STRING:
      return emptyFlowParameterStringField(field);
    case FIELD_TYPE.TEMPLATE_STRING:
      return emptyFlowParameterTemplateStringField(field);
    default:
      throw new Error(
        `NYI: emptyFlowParameter field for field type (${field.type})`,
      );
  }
};
