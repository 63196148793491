import React from 'react';
import { RouteComponentProps } from '@reach/router';
import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/scenes/ErrorScreen';
import Catalog from '~/Catalog';
import { WithNavigationFrame as NotFound } from '~/components/404';
import FlowList from './FlowList';
import FlowDetails from './FlowDetails';
import FlowBuilder from './FlowBuilder';
import FlowBuilderV2 from '../v2/index';
import DHRouter from '~/components/DHRouter';

type Props = RouteComponentProps;
const Flows: React.FC<Props> = () => (
  <DHRouter>
    <FlowList path="/" />
    <FlowBuilder path={`/builder/`} />
    <FlowBuilder path={`/builder/:templateId`} />
    <FlowDetails path={`/details/:flowId`} />
    <FlowBuilderV2.Create path={`/builder-v2/create/:id`} />
    <FlowBuilderV2.Update path={`/builder-v2/update/:id`} />
    <NotFound default />
  </DHRouter>
);

export default withErrorBoundary<Props>(
  Flows,
  <ErrorScreen explanation={Catalog.genericUnknownErrorMessage} />,
);
