import React from 'react';
import styled, { css } from 'styled-components';
import AceEditor, { IAceEditorProps } from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-textmate';

type Props = {
  dataTestId?: string;
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  options?: Partial<IAceEditorProps['editorProps']>;
  style?: React.CSSProperties;
  id?: string;
} & IAceEditorProps;

const CodeEditor: React.FC<Props> = ({
  dataTestId,
  value,
  onChange,
  autoFocus = false,
  options = {},
  style = {},
  id = '',
  ...rest
}) => (
  <Container style={style} id={id} data-testid={dataTestId}>
    <AceEditor
      mode="html"
      theme="textmate"
      onChange={onChange}
      name={id + '_ACE_EDITOR'}
      editorProps={options}
      setOptions={{
        useWorker: false,
      }}
      width="100%"
      ref={reactAce => {
        if (reactAce && autoFocus) {
          reactAce.editor.focus();
        }
      }}
      value={value}
      showPrintMargin={false}
      {...rest}
    />
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid ${theme.color('grey')};
    border-radius: ${theme.getTokens().border.radius.base};
    overflow: hidden;
  `,
);

export default CodeEditor;
