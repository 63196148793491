import { FlowConditionAppVBOWaardecheckAppraisalRequestNew } from './types.flow';

const asFlowConditionAppVBOWaardecheckAppraisalRequestNewProp = (): Omit<
  FlowConditionAppVBOWaardecheckAppraisalRequestNew,
  'id'
> => ({
  type: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New',
});

export default asFlowConditionAppVBOWaardecheckAppraisalRequestNewProp;
