import gql from 'graphql-tag';

import ValuationReportFields from '../fragments/ValuationReportFields';
import ValuationReportPremiumFields from '../fragments/ValuationReportPremiumFields';

export default gql`
  query GetAppValuationReport($accountId: ID!, $id: ID!) {
    getAppValuationReport(accountId: $accountId, id: $id) {
      __typename

      ... on App_ValuationReport {
        ...ValuationReportFields
      }

      ... on App_PremiumValuationReport {
        ...ValuationReportPremiumFields
      }
    }
  }

  ${ValuationReportFields}
  ${ValuationReportPremiumFields}
`;
