import React from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './IfElseBottomBar.testid';

const text = {
  yesPathLabel: 'Waar',
  noPathLabel: 'Niet waar',
};
type Props = {
  visiblePath: boolean;

  /** Called when the user clicks on one of the different paths (true or false) */
  onChange: (value: boolean) => void;
};
const IfElseBottomBar = ({ visiblePath, onChange }: Props) => (
  <Container>
    <Choice
      data-testid={TEST_ID.YES_PATH_BUTTON}
      data-selected={visiblePath ? 'true' : 'false'}
      active={visiblePath}
      onClick={() => onChange(true)}
      isYesPath={true}
    >
      {text.yesPathLabel}
    </Choice>
    <Choice
      data-testid={TEST_ID.NO_PATH_BUTTON}
      data-selected={visiblePath ? 'false' : 'true'}
      active={!visiblePath}
      onClick={() => onChange(false)}
      isYesPath={false}
    >
      {text.noPathLabel}
    </Choice>
  </Container>
);

type ChoiceProps = {
  active: boolean;
  isYesPath: boolean;
};
const BORDER_BOTTOM_SIZE = 4;
// Have a fixed size so that the lines in between the actions can be lined up
const CHOICE_WIDTH = '100px';
const Choice = styled.div<ChoiceProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: ${CHOICE_WIDTH};

  ${({ theme }) => css`
    padding: ${theme.space('s')} ${theme.space('xxs')};
  `}

  ${({ active, theme, isYesPath }) => {
    if (active) {
      const borderColor = isYesPath ? theme.color('success') : '#311D6B';
      return css`
        font-weight: bold;
        border-bottom: ${BORDER_BOTTOM_SIZE}px solid ${borderColor};
        padding-bottom: ${theme.space('m')};
      `;
    }

    return '';
  }};
`;

const Container = styled.div<{}>`
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.color('grey', 'light')};
  `};
`;

export default IfElseBottomBar;
