import gql from 'graphql-tag';

import BillingDetailsFields from '../fragments/BillingDetailsFields';

export default gql`
  query GetBillingDetails($accountId: ID!) {
    getBillingDetails(accountId: $accountId) {
      ...BillingDetailsFields
    }
  }

  ${BillingDetailsFields}
`;
