import React from 'react';
import styled, { css } from 'styled-components';
import TipBanner from '~/components/TipBanner';
import ChatLink from '~/components/TipBanner/ChatLink';
import Input, { Props as InputProps } from '~/components/Input';
import Dropdown, { Props as DropdownProps } from '~/components/Dropdown';
import TextButton from '~/components/TextButton';
import { ZapierField, ZapierFieldType } from '~/graphql/types';

import withTooltip from '~/util/withTooltip';
import { ErrorObj } from '../..';
import TEST_ID from './index.testid';

export type Props = {
  /** Id for tests */
  dataTestId?: string;

  /** Current active Zapier field  */
  field: Omit<ZapierField, '__typename'> | undefined;

  errors: ErrorObj;

  /** Save changes made in Zapier field  */
  onChange: (newValue: string, key: keyof ZapierField) => void;

  /** Delete Zapier field  */
  onDelete: () => void;
};

const text = {
  tipHeader: 'Wat zijn velden?',
  tip: (
    <>
      Met velden bepaal je welke gegevens je beschikbaar wil maken voor de
      koppeling met Zapier. <br />
      <br />
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
};

const FieldEditingSection: React.FC<Props> = ({
  dataTestId,
  field,
  onChange,
  onDelete,
  errors,
  ...rest
}) => {
  if (!field)
    return (
      <Container>
        <TipBanner
          id="field-editing-section-tip"
          dismissible={false}
          withoutBorder
          headerText={text.tipHeader}
        >
          {text.tip}
        </TipBanner>
      </Container>
    );

  const { label, key, type } = field;

  return (
    <Container data-testid={dataTestId} {...rest}>
      <NameInput
        label="Naam"
        tooltipMessage="Naam van het veld"
        value={label}
        onChange={e => onChange(e.target.value, 'label')}
        externalErrors={errors.label ?? []}
        dataTestId={TEST_ID.INPUT_NAME}
      />

      <KeyInput
        label="Key"
        tooltipMessage="Key voor het veld"
        value={key}
        onChange={e => onChange(e.target.value, 'key')}
        externalErrors={errors.key ?? []}
        dataTestId={TEST_ID.INPUT_KEY}
      />

      <DropdownWithTooltip
        label="Type"
        tooltipMessage="Type voor het veld"
        options={fieldTypeOptions}
        selectedOptionIdx={fieldTypeOptions.findIndex(
          option => option.payload === type,
        )}
        onChange={({ option }) => onChange(option.payload, 'type')}
      />

      <TextButton
        label="Verwijder dit veld"
        appearance="danger"
        onClick={onDelete}
        dataTestId={TEST_ID.DELETE_FIELD_BUTTON}
      />
    </Container>
  );
};

const NameInput = withTooltip<InputProps>(Input);
const KeyInput = withTooltip<InputProps>(Input);
const DropdownWithTooltip = withTooltip<DropdownProps>(Dropdown);

const fieldTypeOptions = [
  {
    key: 'string',
    label: 'String (tekst)',
    payload: ZapierFieldType.String,
  },
  {
    key: 'number',
    label: 'Nummer',
    payload: ZapierFieldType.Number,
  },
  {
    key: 'boolean',
    label: 'Boolean',
    payload: ZapierFieldType.Boolean,
  },
];

const Container = styled.div<{}>`
  flex: 1;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    box-shadow: ${theme.getTokens().boxShadow.card};
    background-color: ${theme.color('white')};
    padding: ${theme.space('base')};
    margin-left: ${theme.space('base')};
  `}
`;

export default FieldEditingSection;
