import React from 'react';

import { IfElseAction } from './types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import {
  FlowConditionList,
  FlowConditionListWithoutId,
} from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';
import { FlowActionProps } from '~/scenes/Automation/Flows/Actions/types.flow';

import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import getActionLabel from '../../util/getActionLabel';
import asIfElseActionInput from './asIfElseActionInput';
import IfElseBottomBar from './components/IfElseBottomBar';
import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import FlowConditionListComponent from '../Base/FlowConditionList/FlowConditionListComponent';
import { emptyFlowConditionContactDetails } from '../Base';
import ActionExplanationHeader from '../ActionExplanationHeader';
import { generateHash } from '~/util/react';
import { FlowCondition } from '../Base/FlowCondition/types.flow';
import { getBaseActionProps } from '../util/renderActionTree';

const text = {
  explanation: 'Controleer op conditie',
};
type MyProps = ActionSubscriberProps & {
  id: string;
  positionString: string;
  initialActionProps: IfElseAction;
  isHiddenFromView: boolean;
};
type Props = WithValidatorListProps &
  MyProps &
  ReturnType<typeof getBaseActionProps>;
type State = {
  conditionListState: FlowConditionList;
  variableStashVersion: string | null;
  actionLabel: string;
  key: string | null;
};
class IfElseActionComponent extends React.Component<Props, State> {
  _isMounted = false;
  variableStash: FlowVariableStash = emptyVariableStash();
  // The rerendering after these changes happens elsewhere, so do not put these variables in the state
  visiblePath: boolean = this.props.initialActionProps.visiblePath;
  yesPathChildIdState: string | null =
    this.props.initialActionProps.yesPathChildId;
  noPathChildIdState: string | null =
    this.props.initialActionProps.noPathChildId;

  constructor(props: Props) {
    super(props);

    const { initialActionProps, positionString } = props;
    const { conditionList } = initialActionProps;

    this.state = {
      conditionListState: conditionList,
      variableStashVersion: null,
      actionLabel: getActionLabel(positionString),
      key: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.props.validate,
      onAddedActionUnderneath: this.onAddedActionUnderneath,
      onDeletedActionUnderneath: this.onDeletedActionUnderneath,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { isHiddenFromView } = nextProps;
    const { variableStashVersion: nextVariableStashVersion } = nextState;
    const { variableStashVersion } = this.state;

    if (nextVariableStashVersion !== variableStashVersion) {
      return true;
    }

    return !isHiddenFromView;
  }

  setConditionListState = (
    newFieldState: FlowConditionList | FlowConditionListWithoutId,
  ) => {
    if (this._isMounted) {
      this.setState({
        conditionListState: {
          ...newFieldState,
          conditions: newFieldState.conditions.map(
            (
              condition: FlowCondition | Omit<FlowCondition, 'id'>,
              idx: number,
            ) =>
              ({
                id: generateHash(`${JSON.stringify(condition)}-${idx}`),
                ...condition,
              } as FlowCondition),
          ),
        },
      });
    }
  };

  onDropdownOpened = () => {
    if (this._isMounted) {
      const { conditionListState } = this.state;
      const shouldRefreshStash = conditionListState.conditions.some(
        condition =>
          condition.type === 'Flow_Condition_Event_Contact_App_Zapier_New' ||
          condition.type === 'Flow_Condition_Event_Contact_App_Zapier_Details',
      );
      this.props.announceChanges({
        updateVariableStash: shouldRefreshStash,
      });
    }
  };

  setVariableStash = (newStash: FlowVariableStash) => {
    this.variableStash = newStash;

    if (this._isMounted) {
      this.setState({
        variableStashVersion: newStash.version,
      });
    }
  };

  setVisiblePath = (newPath: boolean) => {
    this.visiblePath = newPath;

    this.props.announceChanges({
      noDataChange: true,
    });
  };

  onAddedActionUnderneath = (newAction: FlowActionProps) => {
    if (this.visiblePath) {
      this.yesPathChildIdState = newAction.id;
    } else {
      this.noPathChildIdState = newAction.id;
    }
  };

  onDeletedActionUnderneath = (
    deletedId: string,
    childOfDeletedId: string | null,
  ) => {
    if (this.yesPathChildIdState === deletedId) {
      this.yesPathChildIdState = childOfDeletedId;
    }

    if (this.noPathChildIdState === deletedId) {
      this.noPathChildIdState = childOfDeletedId;
    }
  };

  getActionProp = (): IfElseAction => {
    const { conditionListState } = this.state;
    const { id } = this.props;

    return {
      id,
      type: FLOW_ACTION_TYPE.IF_ELSE,
      conditionList: conditionListState,
      yesPathChildId: this.yesPathChildIdState,
      noPathChildId: this.noPathChildIdState,
      visiblePath: this.visiblePath,
    };
  };

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asIfElseActionInput(this.getActionProp(), treeNode, defaults);

  render() {
    const {
      announceChanges,
      subscribeValidator,
      unsubscribeValidator,
      isHiddenFromView,
      positionString,
      outputLoading,
    } = this.props;
    const { conditionListState } = this.state;
    const action = this.getActionProp();

    return (
      <BaseActionComponent
        positionString={positionString}
        actionLabel={text.explanation}
        action={action}
        variableStash={this.variableStash}
        subscribeValidator={subscribeValidator}
        unsubscribeValidator={unsubscribeValidator}
        isHiddenFromView={isHiddenFromView}
        renderBottomBar={() => (
          <IfElseBottomBar
            visiblePath={this.visiblePath}
            onChange={this.setVisiblePath}
          />
        )}
      >
        <ActionExplanationHeader title={text.explanation} />
        <>
          <FlowConditionListComponent
            outputLoading={outputLoading}
            onDropdownOpened={this.onDropdownOpened}
            conditionChoiceType={'IF_ELSE_CONDITION'}
            conditionList={conditionListState}
            onChange={newConditionList => {
              this.setConditionListState(newConditionList);

              announceChanges();
            }}
            getNewConditionToAdd={() => emptyFlowConditionContactDetails()}
            announceChanges={announceChanges}
          />
        </>
      </BaseActionComponent>
    );
  }
}

export default withValidatorList<MyProps>(IfElseActionComponent);
