import gql from 'graphql-tag';

import TaskListTaskFields from '../fragments/TaskListTaskFields';

export default gql`
  query GetMyTasks(
    $accountId: ID!
    $deleted: Boolean!
    $status: TaskStatus
    $limit: Int
    $unassigned: Boolean
    $nextToken: ID
  ) {
    getMyTasks(
      accountId: $accountId
      deleted: $deleted
      status: $status
      limit: $limit
      unassigned: $unassigned
      nextToken: $nextToken
    ) {
      items {
        ...TaskListTaskFields
      }
      nextToken
    }
  }

  ${TaskListTaskFields}
`;
