import { FlowParameterTemplateStringFieldsFragment } from '~/graphql/types';
import {
  TemplateStringParameterField,
  TemplateStringField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { asTemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

export default (
  field: TemplateStringField,
  stringParameter: FlowParameterTemplateStringFieldsFragment,
): TemplateStringParameterField => ({
  ...field,
  value: asTemplateStringParameterValue(stringParameter),
});
