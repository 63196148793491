import { ParagraphElement } from '~/components/PluginsEditor/types';
import getUpdatedBlockAttrs from '~/components/PluginsEditor/utils/serialize/getUpdatedBlockAttrs';

/**
 * serializes the Paragraph element into html element
 * @param {ParagraphElement} node - Paragraph element
 */
const serialize = (node: ParagraphElement, children: string): string => {
  const attrs = getUpdatedBlockAttrs(node);

  return `<p${attrs ? ` ${attrs}` : ''}>${children}</p>`;
};

export default serialize;
