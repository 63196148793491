import { last, pluck } from 'ramda';
import { Step } from '~/components/StepList';

/**
 * takes array of steps and the pathname and returns the current step index
 * @param {string} x - Example argument
 * keywords: step, pathname, defaultStep
 */
const pathnameToStep = (
  pathname: string,
  steps: Array<Step>,
  defaultStep: string,
): number => {
  const trail = last(pathname.split('/'));
  const ids = pluck('id', steps);
  return ids.findIndex(id => id === (trail === '' ? defaultStep : trail));
};
export default pathnameToStep;
