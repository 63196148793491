import { FlowConditionAppBBWaardecheckReportNew } from './types.flow';

const asFlowConditionAppBBWaardecheckReportNewProp = (): Omit<
  FlowConditionAppBBWaardecheckReportNew,
  'id'
> => ({
  type: 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New',
});

export default asFlowConditionAppBBWaardecheckReportNewProp;
