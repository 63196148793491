import { Attachment } from '../..';

const getAllContentLength = (attachments: Array<Attachment>): number =>
  attachments?.reduce((acc, { file }) => {
    if (
      file &&
      file.__typename === 'Flow___Argument_File' &&
      file.value_file.contentLength
    ) {
      return acc + file.value_file.contentLength;
    }
    return acc;
  }, 0);

export default getAllContentLength;
