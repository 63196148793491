import React from 'react';
import Icon from '../Icon';

import IconButton from './IconButton';

type Props = {
  /** Show edit buttons mode */
  editMode: boolean;

  /** Set name to use in data-testid of buttons */
  testName?: string;

  /** Function callback for save button click */
  handleSubmit: (e: React.SyntheticEvent<HTMLButtonElement>) => void;

  /** Edit mode callback toggler  */
  setEditMode: (setEdit: boolean) => void;

  /** large size of icons in buttons */
  large?: boolean;

  /** if the save command is loading */
  saveLoading?: boolean;
};

const EditButtonsTools = (props: Props) => {
  const {
    large,
    editMode,
    handleSubmit,
    setEditMode,
    testName = 'name',
    saveLoading,
  } = props;

  return editMode ? (
    <React.Fragment>
      <IconButton
        large={large}
        onClick={() => setEditMode(false)}
        data-testid={`editable-${testName}-cancel-update`}
      >
        <Icon name="close" />
      </IconButton>
      <IconButton
        large={large}
        onClick={handleSubmit}
        data-testid={`editable-${testName}-submit-update`}
      >
        {saveLoading === true ? <Icon name="loading" /> : <Icon name="check" />}
      </IconButton>
    </React.Fragment>
  ) : (
    <IconButton
      large={large}
      onClick={() => setEditMode(true)}
      data-testid={`editable-${testName}-editmode`}
    >
      <Icon name="pencil" />
    </IconButton>
  );
};

export default EditButtonsTools;
