import {
  EmailAliasStatus,
  SessionHydrationOfficeFieldsFragment,
  SessionHydrationUserFields_User_Fragment,
} from '~/graphql/types';
import { SendableMailbox } from '../AccountContext';
import {
  UserLookupTable,
  Mailbox,
  OfficeLookupTable,
} from '../AccountContextProvider';

import { reportErrorToTracker } from '~/util/assertion';
import { AUTHORISE_LEVEL, MAILBOX_TYPE } from '~/util/constants';
import cleanedFilename from '~/util/cleanedFilename';
import { getUserName } from '~/graphql/utils';

const buildAvailableEmailsToSendFrom = (
  mailboxes: Array<Mailbox>,
  me: SessionHydrationUserFields_User_Fragment,
  userLookup: UserLookupTable,
  officeLookup: OfficeLookupTable,
  getOfficesForUser: (
    userId: string,
  ) => Array<SessionHydrationOfficeFieldsFragment>,
): Array<SendableMailbox> => {
  const myOfficeIds: Array<string> = getOfficesForUser(me.id).map(
    office => office.id,
  );
  const availableEmails: Array<SendableMailbox> = [];

  const primaryMailboxes: Array<Mailbox> = [];
  const aliasMailboxes: Array<Mailbox> = [];

  for (const mailbox of mailboxes) {
    switch (mailbox.__typename) {
      case 'AccountMailbox':
      case 'OfficeMailbox':
      case 'UserMailbox':
        primaryMailboxes.push(mailbox);
        break;

      case 'AccountMailboxAlias':
      case 'OfficeMailboxAlias':
      case 'UserMailboxAlias':
        aliasMailboxes.push(mailbox);
        break;

      default:
        reportErrorToTracker(
          `${cleanedFilename(
            __filename,
          )} | Unknown mailbox type (${JSON.stringify(
            mailbox,
            null,
            2,
          )}) encountered`,
        );
    }
  }

  primaryMailboxes.forEach(mailbox => {
    switch (mailbox.__typename) {
      case MAILBOX_TYPE.AccountMailbox: {
        const primaryEmail = mailbox.email.email;
        const aliasEmail = findAliasEmail(aliasMailboxes, primaryEmail);

        // all account mailboxes are available to everyone
        availableEmails.push({
          authoriseLevel: AUTHORISE_LEVEL.ACCOUNT,
          namedEmail: {
            email: primaryEmail,
            emailToShow: aliasEmail == null ? primaryEmail : aliasEmail,
          },
        });
        break;
      }
      case MAILBOX_TYPE.OfficeMailbox:
        if (myOfficeIds.includes(mailbox.officeId)) {
          const primaryEmail = mailbox.email.email;
          const aliasEmail = findAliasEmail(aliasMailboxes, primaryEmail);

          availableEmails.push({
            authoriseLevel: AUTHORISE_LEVEL.OFFICE,
            namedEmail: {
              name: officeLookup[mailbox.officeId]?.name,
              email: primaryEmail,
              emailToShow: aliasEmail == null ? primaryEmail : aliasEmail,
            },
          });
        }

        break;
      case MAILBOX_TYPE.UserMailbox:
        if (me.id === mailbox.userId) {
          const primaryEmail = mailbox.email.email;
          const aliasEmail = findAliasEmail(aliasMailboxes, primaryEmail);

          availableEmails.push({
            authoriseLevel: AUTHORISE_LEVEL.USER,
            namedEmail: {
              name: getUserName(userLookup[mailbox.userId]),
              email: primaryEmail,
              emailToShow: aliasEmail == null ? primaryEmail : aliasEmail,
            },
          });
        }

        break;
      default:
        reportErrorToTracker(
          `${cleanedFilename(__filename)} | Unknown mailbox type (${
            mailbox.__typename
          }) encountered`,
        );
    }
  });

  return availableEmails;
};

const findAliasEmail = (
  aliasMailboxes: Array<Mailbox>,
  primaryEmail: string,
): string | null => {
  let result: string | null = null;

  aliasMailboxes.forEach(mailbox => {
    switch (mailbox.__typename) {
      case MAILBOX_TYPE.OfficeMailboxAlias:
      case MAILBOX_TYPE.UserMailboxAlias:
      case MAILBOX_TYPE.AccountMailboxAlias: {
        if (
          mailbox.email.email === primaryEmail &&
          mailbox.email.status === EmailAliasStatus.Verified
        ) {
          result = mailbox.email.emailAlias;
        }

        break;
      }
    }
  });

  return result;
};

export default buildAvailableEmailsToSendFrom;
