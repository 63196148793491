import React, { ReactElement } from 'react';

import { DeleteContactTagAction } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter';

export default (
  action: DeleteContactTagAction,
  describeOptions: DescribeOptions,
): ReactElement => {
  const { name } = action;

  return (
    <span>
      Verwijder een tag met {describeParameterValue(name, describeOptions)}
    </span>
  );
};
