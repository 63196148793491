import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import {
  FilePrimitiveParameterValue,
  FlowEmailAttachmentValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { DeleteInputButton } from '../Buttons';
import TEST_ID from './index.testid';
import Icon from '../Icon';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

type Attachment = FlowEmailAttachmentValue & {
  file: FilePrimitiveParameterValue;
};

export type Props = {
  attachment: Attachment;
  onDelete: () => void;
};

export const isEmailFileAttachment = (
  attachment: FlowEmailAttachmentValue,
): attachment is Attachment => attachment.file && 'filename' in attachment.file;

const EmailFileAttachment = ({ attachment, onDelete }: Props) => {
  const [deleting, setDeleting] = useState(false);

  const style = useSpring({
    from: {
      opacity: deleting ? 1 : 0,
      transform: `translate3d(${deleting ? 0 : 20}px,0,0)`,
    },
    to: {
      opacity: deleting ? 0 : 1,
      transform: `translate3d(${deleting ? 20 : 0}px,0,0)`,
    },
    onRest: () => {
      if (deleting) onDelete();
    },
  });

  return (
    <Container style={style} data-testid={TEST_ID.CONTAINER}>
      <StyledIcon name="file-text" />
      <Anchor
        href={attachment.url as string}
        target="_blank"
        rel="noopener noreferrer"
        key={attachment.file.filename}
        download={attachment.file.filename}
      >
        {attachment.file.filename}
      </Anchor>
      <DeleteInputButton
        onClick={() => setDeleting(true)}
        dataTestId={TEST_ID.DELETE_ATTACHMENT_BUTTON}
      />
    </Container>
  );
};

const Container = styled(animated.div)<{}>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`;

const Anchor = styled.a<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary', 'light')};
    margin-right: ${theme.space('s')};
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

const StyledIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    font-size: ${FONT_SIZE_OF_18}px;
    margin-right: ${theme.space('s')};
  `}
`;

export default EmailFileAttachment;
