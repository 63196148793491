import { TemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { convertTemplateStringToDescription } from './util/templateStringConversion';
import { specificValueDescription } from '../../../../util';

const describeTemplateString = (
  parValue: TemplateStringParameterValue,
  describeOptions: DescribeOptions,
): DescribeNodeType =>
  specificValueDescription(
    convertTemplateStringToDescription(parValue, describeOptions),
  );

export default describeTemplateString;
