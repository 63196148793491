import React, { ReactElement } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { darken, rgba } from 'polished';

import {
  calculateSpaceInsideComponent,
  calculateFontSize,
} from '~/components/util/getSizeCalculation';
import Icon from '../Icon';
import { Theme } from '~/theme';

type ColorProps = {
  /** Renders a button in the secondary color */
  secondary?: boolean;

  /** Renders a button in the accent color */
  accent?: boolean;

  /** Renders a button in the danger color */
  danger?: boolean;

  /** Renders a button in the subprimary color */
  subPrimary?: boolean;
};
export type Props = ColorProps & {
  /** what happens when you click the button */
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;

  /** If the border should be dotted, only works with outline */
  dotted?: boolean;

  /** Renders a button with only an outline */
  outline?: boolean;

  /** Renders a button w/o an outline and background */
  simple?: boolean;

  /** Renders a tiny button */
  tiny?: boolean;

  /** Renders a small button */
  small?: boolean;

  /** Renders a large button */
  large?: boolean;

  /** If the button is disabled */
  disabled?: boolean;

  /** If the button is disabled and with spinner */
  loading?: boolean;

  /** If the background should be a light version */
  lightBackground?: boolean;
  children?: React.ReactNode;
  dataTestid?: string;
  icon?: React.ReactElement<typeof Icon>;
  type?: 'button' | 'submit' | 'reset';
};

const DEFAULT_BORDER = '1px';
const ButtonImplementation = styled.button<Props>`
  display: inline-flex;
  border-radius: ${({ theme }) => theme.getTokens().border.radius.s};
  align-self: flex-end;
  transition-property: background-color, border;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  position: relative;
  white-space: nowrap;
  justify-content: center;

  ${({ disabled }) => {
    if (!disabled) {
      return css`
        cursor: pointer;
      `;
    }
    return null;
  }};

  ${({ theme, small, large }) => {
    const padding = calculateSpaceInsideComponent(theme, small, large);
    const fontSize = calculateFontSize(theme, small, large);

    return `
      font-size: ${fontSize}px;
      padding: calc(${padding}px - ${DEFAULT_BORDER});
    `;
  }};

  ${props => {
    const { theme, outline, simple, lightBackground, dotted } = props;

    const color = mainColorForProps(props, theme);
    const backgroundColor = lightBackground ? rgba(color, 0.2) : color;
    if (outline) {
      return css`
        border: ${DEFAULT_BORDER} ${dotted ? 'dotted' : 'solid'} ${color};
        color: ${color};
        background-color: ${lightBackground
          ? backgroundColor
          : theme.color('white')};
        :hover {
          border-color: ${darken(0.05, color)};
          color: ${darken(0.1, color)};
        }

        :active {
          border-color: ${darken(0.2, color)};
          color: ${darken(0.2, color)};
        }

        :disabled {
          border-color: ${theme.color('grey')};
          color: ${theme.color('grey')};
        }
      `;
    }

    if (simple) {
      return css`
        border-color: transparent;
        background: transparent;
        box-shadow: none;
        color: '#adb5bd';

        :hover {
          border-color: transparent;
          color: ${darken(0.1, '#adb5bd')};
          background-color: ${'#f8f9fa'};
        }

        :active {
          border-color: transparent;
          background-color: ${'#e9ecef'};
          color: ${darken(0.2, '#dee2e6')};
        }

        :disabled,
        :disabled:active,
        :disabled:hover {
          border-color: transparent;
          background-color: transparent;
          background-color: ${'#f8f9fa'};
          color: ${'#dee2e6'};
        }
      `;
    }

    return css`
      box-shadow: ${theme.getTokens().boxShadow.base};
      border: ${DEFAULT_BORDER} solid transparent;
      color: ${lightBackground ? color : theme.color('white')};
      background-color: ${backgroundColor};

      :hover {
        background-color: ${darken(0.05, backgroundColor)};
      }

      :active {
        background-color: ${darken(0.1, backgroundColor)};
      }

      :disabled {
        background-color: ${theme.color('grey')};
      }
    `;
  }};
`;
const ButtonTitle = styled.span<{ visible: boolean }>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;
export const Spinner = styled(Icon)<{}>`
  display: block;
  & svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

/**
 * @component
 */
const Button: React.FC<Props> = props => {
  const { loading, children, icon, onClick, ...rest } = props;
  const theme = useTheme();
  const disabled = props.disabled || loading;

  let iconComponent: ReactElement | null = null;
  if (loading) {
    iconComponent = (
      <IconContainer>
        <Spinner name="loading" color={loaderColorForProps(props, theme)} />
      </IconContainer>
    );
  } else if (icon != null) {
    iconComponent = <IconContainer>{icon}</IconContainer>;
  }

  return (
    <ButtonImplementation
      type="button"
      data-testid={props.dataTestid ?? ''}
      disabled={disabled}
      onClick={(e: React.SyntheticEvent<HTMLButtonElement, Event>) => {
        if (!disabled && !loading && onClick) {
          onClick(e);
        }
      }}
      {...rest}
    >
      {iconComponent}
      <ButtonTitle visible={!loading}>{children}</ButtonTitle>
    </ButtonImplementation>
  );
};

export const mainColorForProps = (
  { secondary, accent, danger, subPrimary }: ColorProps,
  theme: Theme,
): string => {
  let color = theme.color('primary', 'light');
  if (secondary) color = theme.color('success');
  if (accent) color = theme.color('accent');
  if (danger) color = theme.color('danger');
  if (subPrimary) color = theme.color('info');

  return color;
};

const loaderColorForProps = (props: Props, theme: Theme): string => {
  const { outline } = props;
  if (outline) {
    return mainColorForProps(props, theme);
  }

  return theme.color('white');
};

/**
 * Without alignment
 */
export const UnalignedButton = styled(Button)<{}>`
  align-self: inherit;
`;

const IconContainer = styled.div<{}>`
  display: flex;
  ${({ theme }) => css`
    padding-right: ${theme.space('xxs')};
  `}
`;

export default Button;
