import React from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './index.testid';
import Divider from '~/components/Divider';
import TextButton from '~/components/TextButton';
import {
  ButtonsContainer,
  emptyFilterGroupV2,
  TitleContainer,
  GroupContainer,
  FiltersContainer,
} from '../..';
import { ContactFilters__Input } from '~/graphql/types';
import { Heading4, Variant } from '~/components/Typography/index';

export type Props = {
  dataTestId?: string;
  filters: ContactFilters__Input;
  onChange: (newFilters: ContactFilters__Input) => void;
  loading?: boolean;
};

const text = {
  filtersTitle: 'Filters',
  addGroup: 'Groep toevoegen',
  deleteFilters: 'Alle filters wissen',
  invalidFilter: 'Er is iets misgegaan. Probeer het nog eens.',
};

const BrokenFilterBar: React.FC<
  Omit<Props, 'filterOptions' | 'onAddFilterGroup'> & {
    boundaryProps: {
      error: Error;
      componentStack: string | null;
      eventId: string | null;
      resetError(): void;
    };
  }
> = ({ filters, onChange, boundaryProps }) => (
  <Container data-testid={TEST_ID.CONTAINER}>
    <TitleContainer>
      <Heading4 withoutMargin variant={Variant.primary}>
        {text.filtersTitle}
      </Heading4>
      <ButtonsContainer>
        <TextButton disabled label={text.addGroup} icon="plus" />

        <TextButton
          label={text.deleteFilters}
          icon="delete"
          onClick={e => {
            e.currentTarget.blur();

            onChange({ ...filters, nodes: [emptyFilterGroupV2()] });
            boundaryProps.resetError();
          }}
        />
      </ButtonsContainer>
    </TitleContainer>
    <Divider margin={['base', null]} />
    <div style={{ position: 'relative' }}>
      <FiltersContainer>
        <GroupContainer>
          <ErrorContainer>
            <span>{text.invalidFilter}</span>
          </ErrorContainer>
        </GroupContainer>
      </FiltersContainer>
    </div>
  </Container>
);

const ErrorContainer = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 50px;
    padding-left: ${theme.space('xxs')};
    color: ${theme.color('danger')};
    position: relative;

    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};

    max-width: ${1200}px;
    width: 100%;
    margin: 0 auto;
  `,
);

const Container = styled.div<{}>``;

export default BrokenFilterBar;
