import React from 'react';
import styled, { css } from 'styled-components';
import TEST_ID from './index.testid';
import Button from '../Button';
import Icon from '../Icon';
import ImageOrPlaceholder from './components/ImageOrPlaceholder';

export type AvatarForTypes = 'userProfile' | 'officeProfile';

export type Props = {
  /** Depends on this prop we set the default icon either user icon or office */
  avatarFor?: AvatarForTypes;

  /** Image source URI */
  src?: string | null;

  /** Show Upload button or not */
  withUpload?: boolean;

  /** Action. I.e. upload image to server */
  onClick?: () => void;

  /** Show loading spinner */
  loading?: boolean;
  simpleImage?: boolean;
  imagePlaceholderText?: string;

  showDeleteButton?: boolean;
  onDelete?: () => void;
};

export const text = {
  change: 'Wijzig',
  placeholderText: 'Je hebt nog geen bestand geupload',
  deleteFile: 'Verwijderen',
};

const Avatar = ({
  src,
  onClick,
  loading,
  withUpload,
  simpleImage,
  imagePlaceholderText,
  showDeleteButton,
  onDelete,
  avatarFor,
}: Props) => (
  <AvatarElement $isLoading={loading} $simpleImage={simpleImage}>
    <ImageOrPlaceholder
      avatarFor={avatarFor}
      src={src}
      simpleImage={simpleImage}
      imagePlaceholderText={imagePlaceholderText}
    />

    {withUpload && (
      <>
        <UploadSection
          className="uploading-section"
          onClick={onClick}
          $simpleImage={simpleImage}
        >
          {!simpleImage && <Icon name="camera" className="camera" />}
          {!simpleImage && <span>{text.change}</span>}
        </UploadSection>

        {showDeleteButton && (
          <StyledButton
            onClick={onDelete}
            data-testid={TEST_ID.DELETE_BUTTON}
            icon="trashcan"
            label={text.deleteFile}
          />
        )}
      </>
    )}

    <LoadingIcon name="spinner" className="loading-element" color="#fff" />
  </AvatarElement>
);

Avatar.defaultProps = {
  loading: false,
  withUpload: false,
};

type AvatarElementProps = {
  $isLoading?: boolean;
  $simpleImage?: boolean;
};

const AvatarElement = styled.div<AvatarElementProps>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;

  & .camera {
    transform: scale(1.5);
    margin-bottom: 3px;
    transition: all 0.2s ease-out;
  }

  ${({ theme, $simpleImage }) => css`
    border-radius: ${$simpleImage
      ? theme.getTokens().border.radius.base
      : '50%'};
  `};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      img {
        filter: blur(3px);
      }
      .profile-placeholder {
        filter: blur(3px);
      }
      .uploading-section {
        display: none;
      }
      .loading-element {
        display: flex;
      }
    `};

  &:hover {
    & .camera {
      transform: scale(2.5) translateY(-30%);
    }
  }
`;

const LoadingIcon = styled(Icon)<{}>`
  font-size: 3em;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

const UploadSection = styled.div<AvatarElementProps>`
  cursor: pointer;
  position: absolute;
  height: 100%;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.color('white')};
  ${({ theme, $simpleImage }) =>
    $simpleImage
      ? css`
          background: none;
        `
      : css`
          background: linear-gradient(
            transparent 30%,
            ${theme.color('primary', 'light')} 90%
          );
        `};

  justify-content: flex-end;
  padding-bottom: ${({ theme }) => theme.space('xs')};
  user-select: none;

  &:hover {
    background: linear-gradient(
      transparent 0%,
      ${({ theme, $simpleImage }) =>
          $simpleImage ? 'transparent' : theme.color('primary', 'light')}
        100%
    );
  }

  & > span {
    font-size: 0.75em;
  }
`;

const StyledButton = styled(Button)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.space('s')};
  `,
);

export default Avatar;
