import { FlowConditionAppBBWaardecheckAppraisalRequestNew } from './types.flow';

const asFlowConditionAppBBWaardecheckAppraisalRequestNewProp = (): Omit<
  FlowConditionAppBBWaardecheckAppraisalRequestNew,
  'id'
> => ({
  type: 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New',
});

export default asFlowConditionAppBBWaardecheckAppraisalRequestNewProp;
