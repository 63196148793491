import React from 'react';
import { Props } from '..';

const Redo: React.FC<Props> = ({ color = 'currentColor', ...props }: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.3 4.5L21 9.2L16.3 13.9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 9.2002H7.7C5.1 9.2002 3 11.3002 3 13.9002C3 16.5002 5.1 18.6002 7.7 18.6002H15.8"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Redo;
