import type { AppTypeFilter } from '~/components/Filters/Primitive/AppType/types.flow';
import type { IDFilter } from '~/components/Filters/Primitive/ID/types.flow';
import type { HasFilter } from '~/components/Filters/Primitive/Has/types.flow';

export type FILTER_CONTACT_SOURCE =
  | FILTER_CONTACT_SOURCE__APP_TYPE
  | FILTER_CONTACT_SOURCE__USER;

export type FILTER_CONTACT_SOURCE__APP_TYPE = 'FILTER_CONTACT_SOURCE__APP_TYPE';
export type FILTER_CONTACT_SOURCE__USER = 'FILTER_CONTACT_SOURCE__USER';

export enum Type {
  FILTER_CONTACT_SOURCE__APP_TYPE = 'FILTER_CONTACT_SOURCE__APP_TYPE',
  FILTER_CONTACT_SOURCE__USER = 'FILTER_CONTACT_SOURCE__USER',
}

export type ContactSourceFilter_App = {
  appSource: AppTypeFilter;
  type: Type.FILTER_CONTACT_SOURCE__APP_TYPE;
};
export type ContactSourceFilter_User = {
  userSource: IDFilter | HasFilter;
  type: Type.FILTER_CONTACT_SOURCE__USER;
};

export type ContactSourceFilter =
  | ContactSourceFilter_App
  | ContactSourceFilter_User;
