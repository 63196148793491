import { AssignContactAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { emptyOfficePrimitiveParameterValue } from '../Base/FlowParameter/ParameterValue/Office';
import { emptyUserPrimitiveParameterValue } from '../Base/FlowParameter/ParameterValue/User';

const defaultNewAssignContactActionProps = (
  baseActionProps: BaseActionProps,
): AssignContactAction => ({
  ...baseActionProps,
  type: FLOW_ACTION_TYPE.ASSIGN_CONTACT,
  actionInput: {
    office: emptyOfficePrimitiveParameterValue(),
    user: emptyUserPrimitiveParameterValue(),
    overwriteAssignee: true,
  },
});

export default defaultNewAssignContactActionProps;
