import React from 'react';
import styled, { css } from 'styled-components';
import { RouteComponentProps } from '@reach/router';
import MetaTags from 'react-meta-tags';

import { Heading1, Heading2, Heading3 } from '~/components/Typography/index';
import ContentContainerDefault from '~/components/ContentContainer/Default';

import EntityActivityOverview from './components/EntityActivityOverview';
import getGreeting from './utils/getGreeting';
import TEST_ID from './index.testid';
import EmailEffectiveness from './components/EmailEffectiveness';
import ChangeInLeadScore from './components/ChangeInLeadScore';
import MasonryGrid from '~/components/MasonryGrid';
import ContactsCountByLeadSource from './components/ContactsCountByLeadSource';
import StatisticsOverview from './components/StatisticsOverview';
import useCurrentUser from '~/hooks/useCurrentUser';
import FlowEffectiveness from './components/FlowEffectiveness';

// Holiday cheer!!
import Snowfall from 'react-snowfall';
import useDHFlag from '~/hooks/useDHFlag';
import Button from '~/components/Button';
import RevenuePerSourceGroup from './components/RevenuePerSourceGroup';
import useViewingModeProps from '~/hooks/useViewingModeProps';

type Props = {
  today?: Date;
};

const text = {
  pageTitle: 'Dashboard',
  performanceHeader: 'Prestaties',
};

const Dashboard: React.FC<RouteComponentProps & Props> = ({ today }) => {
  const hasHolidayCheer = useDHFlag('holiday-cheer');
  const me = useCurrentUser();
  const hasDismissedCheer2022 = getDismissCheerStatus();
  const viewingModeProps = useViewingModeProps();

  return (
    <>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      {hasHolidayCheer && !hasDismissedCheer2022 && <Snowfall />}

      <ContentContainerDefault
        data-testid={TEST_ID.CONTAINER}
        maxContentWidth={'1300px'}
      >
        {hasHolidayCheer && (
          <Button
            ghost
            type="button"
            label={hasDismissedCheer2022 ? 'Let it snow!' : 'Zet de sneeuw uit'}
            style={{ float: 'right' }}
            size="small"
            onClick={toggleDismissCheerStatus}
          />
        )}
        <Heading1 color={{ group: 'primary' }} withoutMargin>
          {text.pageTitle}
        </Heading1>
        <Heading2 color={{ group: 'grey', variant: 'dark' }}>
          {getGreeting(new Date().getHours())}
          &nbsp;
          {hasHolidayCheer && <>en fijne feestdagen&nbsp;</>}
          <span {...viewingModeProps}>{me.name}</span>
        </Heading2>
        <EntityActivityOverview dataTestId={TEST_ID.ENTITY_ACTIVITY_OVERVIEW} />
        <StatisticsOverview dataTestId={TEST_ID.OVERVIEW} today={today} />
        <HeaderContainer>
          <Heading3>{text.performanceHeader}</Heading3>
        </HeaderContainer>
        <MasonryGrid>
          <FlowEffectiveness dataTestId={TEST_ID.FLOW_EFFECTIVENESS} />
          <EmailEffectiveness
            dataTestId={TEST_ID.EMAIL_EFFECTIVENESS}
            today={today}
          />
          <ChangeInLeadScore dataTestId={TEST_ID.CHANGE_IN_LEADSCORE} />
          <ContactsCountByLeadSource
            dataTestId={TEST_ID.CONTACT_COUNT_BY_LEADSCORE}
            today={today}
          />
          <RevenuePerSourceGroup
            dataTestId={TEST_ID.REVENUE_PER_SOURCE_GROUP}
            today={today}
          />
        </MasonryGrid>
      </ContentContainerDefault>
    </>
  );
};

const HeaderContainer = styled.div<{}>(
  ({ theme }) => css`
    margin: ${theme.space('xxl')} 0 ${theme.space('xl')} 0;
  `,
);

const getDismissCheerStatus = () => {
  try {
    const stored = localStorage.getItem('dismissHolidayCheer2022');
    if (stored === '1') return true;
    return false;
  } catch (error) {
    return true;
  }
};

const toggleDismissCheerStatus = () => {
  const dismissed = getDismissCheerStatus();
  try {
    if (dismissed) {
      localStorage.setItem('dismissHolidayCheer2022', '0');
    } else {
      localStorage.setItem('dismissHolidayCheer2022', '1');
    }
    // Just reload the window no need to keep a state and sync it
    window.location.reload();
  } catch {
    // nothing...
  }
};

export default Dashboard;
