import React, { useState, useEffect } from 'react';
import Amplify from '../../amplify';
import possibleTypes from '~/graphql/possibleTypes.json';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  from,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { ErrorLink, onError } from '@apollo/client/link/error';

import isSubscriptionOperation from './utils/isSubscriptionOperation';
import createSubscriptionWebsocketLink from './CognitoOpenID/createSubscriptionWebsocketLink';
import createHttpLink from './CognitoOpenID/createHttpLink';
import { navigate } from 'gatsby';
import ErrorTypes from '~/ErrorTypes';
import { getErrorType } from '~/util/errorHandling';
import { cond, equals, T } from 'ramda';
import {
  mergeInfinitePaginatedItems,
  readInfinitePaginatedItems,
} from './utils/pagination';

const useDHApolloClient = () => {
  const [client, setClient] = useState<ApolloClient<any> | null>(null);

  useEffect(() => {
    const createApolloClient = async () => {
      const region = process.env.AWS_REGION;
      const url = process.env.API_ENDPOINT;

      if (!url || !region) {
        throw Error(`Some environment variables are missing, you probably forgot to make your .env file.
          API_ENDPOINT=${url}
          AWS_REGION=${region}`);
      }

      const appSyncApiUrl = url;
      const getJWTToken = async () => {
        const session = await Amplify.Auth.currentSession();
        return session.getAccessToken().getJwtToken();
      };

      const subscriptionLink = ApolloLink.split(
        isSubscriptionOperation,
        await createSubscriptionWebsocketLink({
          appSyncApiUrl,
          getJWTToken,
        }),
        createHttpLink({ appSyncApiUrl, getJWTToken }),
      );

      const errorLink = onError(errorHandler);

      setClient(
        new ApolloClient({
          defaultOptions: {
            mutate: {
              errorPolicy: 'all',
            },
            query: {
              errorPolicy: 'all',
              fetchPolicy: 'network-only',
            },
            watchQuery: {
              errorPolicy: 'all',
              fetchPolicy: 'cache-and-network',
              nextFetchPolicy: 'cache-first',
            },
          },
          link: from([errorLink, subscriptionLink]),
          cache: new InMemoryCache({
            possibleTypes,
            typePolicies: {
              Flow___InstanceCondition: {
                keyFields: false,
              },
              Flow___SubjectFieldCondition: {
                keyFields: false,
              },
              Query: {
                fields: {
                  getInvoices: {
                    keyArgs: false,
                    merge: mergeInfinitePaginatedItems,
                    read: readInfinitePaginatedItems,
                  },
                  getCreditInvoices: {
                    keyArgs: false,
                    merge: mergeInfinitePaginatedItems,
                    read: readInfinitePaginatedItems,
                  },
                },
              },
            },
          }),
        }),
      );
    };

    void createApolloClient();
  }, []);

  return client;
};

const handleGraphQLError = (errorType: string | null) =>
  cond([
    [equals(ErrorTypes.accountLockedError), () => navigate('/-/locked')],
    [equals(ErrorTypes.accountPausedError), () => navigate('/-/paused')],
    [equals(ErrorTypes.accountCancelledError), () => navigate('/-/cancelled')],
    [equals(ErrorTypes.refreshRequiredError), () => window.location.reload()],

    // Default
    [T, () => {}],
  ])(errorType);

const handleNetworkError = (error: Error | ServerError | ServerParseError) =>
  cond([
    [equals('No current user'), () => navigate('/logout')],

    // Default
    [T, () => {}],
  ])(error);

const errorHandler: ErrorLink.ErrorHandler = ({
  graphQLErrors,
  networkError,
}) => {
  if (networkError) return handleNetworkError(networkError);
  if (Array.isArray(graphQLErrors)) {
    for (const graphQLError of graphQLErrors) {
      return handleGraphQLError(getErrorType(graphQLError));
    }
  }
};

const AppSyncProvider = ({
  children,
}: {
  children: any;
}): JSX.Element | null => {
  const client = useDHApolloClient();
  if (!client) return null;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default AppSyncProvider;
