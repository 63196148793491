import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { isNil } from 'ramda';
import sanitize from 'sanitize-html';

import { getDisabledInputStyle } from '../Inputs/InputElement';
import PluginsEditor, { Props as EditorProps } from '../PluginsEditor';
import parseAndDeserialize, {
  EMPTY_EDITOR_VALUE,
} from '../PluginsEditor/utils/parseAndDeserialize';
import serialize from '../PluginsEditor/utils/serialize';
import useEditorStates from '../PluginsEditor/hooks/useEditorStates';
import ELEMENTS from '../PluginsEditor/components/elements/elementsEnum';
import { Descendant } from 'slate';
import { defaultSanitizeOptions } from '../PluginsEditor/utils/flows/convertTemplateStringToSlate';
import replaceTags from './utils/replaceTags';

export type Props = Omit<EditorProps, 'value' | 'onChange'> & {
  dataTestId?: string;

  /** Editor value as html string */
  value: string;

  /** Error state  */
  error?: boolean;

  /** Disabled state  */
  disabled?: boolean;

  /** Sets the editor's value to the empty editor value, e.g. after submitting the form */
  shouldReset?: boolean;

  /** onChange function of form field */
  onChange: (change: string) => void;
};

const Editor: React.FC<Props> = ({
  dataTestId,
  error,
  disabled,
  value: initialValue,
  onChange: _onChange,
  shouldReset,
  ...rest
}) => {
  /**
   * Moving from Quill to Slate:
   *
   * In Slate the base block should be a div element. This is important especially
   * when creating empty lines.
   *
   * Here we convert p elements into div elements in order to keep the spaces as they are.
   */
  const modifiedHtml = replaceTags(initialValue);

  const sanitized = sanitize(modifiedHtml, defaultSanitizeOptions);

  const textFragment = parseAndDeserialize({
    html: sanitized ?? '',
    customElements: [],
  });

  const initial = isNil(initialValue)
    ? EMPTY_EDITOR_VALUE
    : ([{ type: ELEMENTS.DIV, children: textFragment }] as Array<Descendant>);

  const { key, value, onChange, hasChanges, resetEditor } = useEditorStates({
    initialValue: initial,
  });

  useEffect(() => {
    if (hasChanges) {
      const serialized = serialize({ fragment: value, customElements: [] });
      _onChange(serialized);
    }
  }, [_onChange, hasChanges, value]);

  useEffect(() => {
    if (hasChanges && shouldReset) {
      resetEditor({ value: EMPTY_EDITOR_VALUE });
    }
  }, [resetEditor, hasChanges, shouldReset]);

  return (
    <EditorContainer
      data-testid={dataTestId}
      data-error={error}
      $error={error}
      $disabled={disabled}
    >
      <PluginsEditor
        {...rest}
        key={key}
        value={value}
        onChange={onChange}
        customElements={[]}
        plugins={[]}
        hideToolbar
      />
    </EditorContainer>
  );
};

const EditorContainer = styled.div<{ $disabled?: boolean; $error?: boolean }>(
  ({ theme, $error, $disabled }) => css`
    display: flex;
    border-radius: ${theme.getTokens().border.radius.s};
    min-height: 150px;
    width: 100%;

    border: ${theme.getTokens().border.width.s} solid
      ${$error ? theme.color('danger') : theme.color('grey')};

    ${$disabled && getDisabledInputStyle(theme)}
  `,
);

export default Editor;
