import { FlowConditionTimeElapsed } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { NumberParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import {
  DescribeNodeElementType,
  DescribeNodeType,
} from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import { needsUserActionDescription } from '../../../util';
import { isNonZeroNumberParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Number';
import {
  PARAMETER_PRIMITIVE_VALUE_TYPE,
  PARAMETER_VALUE_TYPE,
} from '../../FlowParameter/ParameterValue/constants';

const text = {
  yearLabel: {
    singular: 'jaar',
    plural: 'jaar',
  },
  monthLabel: {
    singular: 'maand',
    plural: 'maanden',
  },
  dayLabel: {
    singular: 'dag',
    plural: 'dagen',
  },
  hourLabel: {
    singular: 'uur',
    plural: 'uren',
  },
  minuteLabel: {
    singular: 'minuut',
    plural: 'minuten',
  },
  fillInTime: 'Vul een tijd in',
  lastConjunction: ' en ',
};

const describeTimeElapsedCondition = (
  condition: FlowConditionTimeElapsed,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  const { minute, hour, day, month, year } = condition;

  const toShowArray: Array<DescribeToShow> = [];

  if (year != null && isNonZeroNumberParameterValue(year)) {
    toShowArray.push({ value: year, label: text.yearLabel });
  }
  if (month != null && isNonZeroNumberParameterValue(month)) {
    toShowArray.push({ value: month, label: text.monthLabel });
  }
  if (day != null && isNonZeroNumberParameterValue(day)) {
    toShowArray.push({ value: day, label: text.dayLabel });
  }
  if (hour != null && isNonZeroNumberParameterValue(hour)) {
    toShowArray.push({ value: hour, label: text.hourLabel });
  }
  if (minute != null && isNonZeroNumberParameterValue(minute)) {
    toShowArray.push({ value: minute, label: text.minuteLabel });
  }

  const descriptionArray: DescribeNodeType = [];

  const toShowDescription = describeToShowArray(toShowArray, describeOptions);
  if (Array.isArray(toShowDescription)) {
    descriptionArray.push(...toShowDescription);
  } else {
    descriptionArray.push(toShowDescription);
  }

  descriptionArray.push(' ');

  if (
    toShowArray.length === 1 &&
    toShowArray[0].value.type ===
      PARAMETER_PRIMITIVE_VALUE_TYPE.NUMBER_PRIMITIVE &&
    toShowArray[0].value.value === 1
  ) {
    descriptionArray.push('is verstreken');
  } else {
    descriptionArray.push('zijn verstreken');
  }

  return descriptionArray;
};

type DescribeToShow = {
  value: NumberParameterValue;
  label: { singular: string; plural: string };
};
const describeToShowArray = (
  array: Array<DescribeToShow>,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  if (array.length === 0) {
    return needsUserActionDescription(text.fillInTime);
  }

  const str: Array<DescribeNodeElementType> = [];

  array.forEach((item, idx) => {
    const { value, label } = item;

    if (idx > 0) {
      if (idx === array.length - 1) {
        // is last one
        str.push(text.lastConjunction);
      } else {
        str.push(', ');
      }
    }

    let amountLabel = label.plural;
    if (
      value.type === PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE &&
      value.value === 1
    ) {
      amountLabel = label.singular;
    }

    const valueDescription = describeParameterValue(
      value,
      describeOptions,
      true,
    );

    if (Array.isArray(valueDescription)) {
      str.push(...valueDescription);
    } else {
      str.push(valueDescription);
    }

    str.push(' ');
    str.push(amountLabel);
  });

  return str;
};

export default describeTimeElapsedCondition;
