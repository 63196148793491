import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterEmailFields on Flow_Parameter__Email {
    __typename

    ... on Flow_Parameter_Email_Primitive {
      emailId: value
    }

    ... on Flow_Parameter_Email_Pointer {
      variable
      field
    }
  }
`;
