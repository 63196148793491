import { ViewableAccount } from '~/contexts/AccountContext';
import {
  PricingInfoBlock,
  PRICING_INFO,
} from '~/scenes/Settings/Subscription/constants';

const getSubscriptionInfo = (account: ViewableAccount): PricingInfoBlock => {
  if (
    account &&
    account.subscription &&
    account.subscription.__typename === 'Subscription_Stripe' &&
    account.subscription.runningPlan &&
    account.subscription.runningPlan.plan
  ) {
    return PRICING_INFO[account.subscription.runningPlan.plan];
  }

  return PRICING_INFO.Pro;
};

export default getSubscriptionInfo;
