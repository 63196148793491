// https://github.com/froala/react-froala-wysiwyg
import React from 'react';

import {
  Props as HTMLEditorProps,
  InteractionFunctions,
  GetHTMLFunction,
  InlineImageEditorItem,
} from './HTMLEditor';
import { ReturnType as ConvertWithEventInlineAttachmentsReturnType } from './util/convertFroalaToHtmlWithEventInlineAttachments';
import { ReturnType as ConvertWithInlineAttachmentsReturnType } from './util/convertFroalaToHtmlWithInlineAttachments';
import { UnsubscribeLinkButton } from './types.flow';

import { UNSUBSCRIBE_LINK_BUTTON_NAME } from './constants';
import defaultEmailOptions from './defaultEmailOptions';
import HTMLEditor from './HTMLEditor';
import emailEventHandlers from './emailEventHandlers';
import {
  convertHtmlToFroalaWithEventInlineAttachments,
  convertHtmlToFroalaWithInlineAttachments,
  convertFroalaToHtmlWithInlineAttachments,
  convertFroalaToHtmlWithEventInlineAttachments,
} from './util';
import cleanedFilename from '~/util/cleanedFilename';
import { IMAGE_ALLOWED_TYPES } from './constants';

import {
  UseEventInlineAttachmentsType,
  UseInlineAttachmentsType,
} from './types.flow';
import { SIGNATURE } from '../SignatureContainer/constants';
import { getAttachmentsFromHtml } from './util/getAttachments';
import { FLOW_EMAILATTACHMENT_TYPE } from '~/scenes/Automation/Flows/Actions/Base/FlowEmailAttachment/constants';
import { FlowEmailAttachmentValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

type ButtonType = UnsubscribeLinkButton;
type Props = HTMLEditorProps & {
  initialButtons?: Array<ButtonType>;
  useEventInlineAttachments?: UseEventInlineAttachmentsType;
  useFlowInlineAttachments?: UseInlineAttachmentsType;
  isSignatureEditor?: boolean;
};
type State = {
  convertedInitialHtml: string;
};
class EmailEditor extends React.Component<Props, State> {
  // toInsert;
  // toSet;
  // The function that also runs it through any converters
  toGetHtml: GetHTMLFunction;
  getInlineImageEditorList: () => Array<InlineImageEditorItem>;

  constructor(props: Props) {
    super(props);

    const { useEventInlineAttachments, useFlowInlineAttachments, initialHtml } =
      props;

    if (useEventInlineAttachments && useFlowInlineAttachments) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Not allowed to use event and flow inline images at the same time!`,
      );
    }

    let convertedInitialHtml = initialHtml;

    if (useEventInlineAttachments) {
      const { registerEventInlineAttachmentsInteractionFunctions } =
        useEventInlineAttachments;

      convertedInitialHtml =
        convertHtmlToFroalaWithEventInlineAttachments(initialHtml);

      this.toGetHtml = () => convertedInitialHtml;

      registerEventInlineAttachmentsInteractionFunctions({
        convertFroalaToHtmlWithEventInlineAttachments:
          this.convertFroalaToHtmlWithEventInlineAttachments,
      });
    }
    if (useFlowInlineAttachments) {
      const {
        initialInlineAttachments,
        registerInlineAttachmentsInteractionFunctions,
      } = useFlowInlineAttachments;

      convertedInitialHtml = convertHtmlToFroalaWithInlineAttachments(
        initialHtml,
        initialInlineAttachments,
      );

      this.toGetHtml = () => convertedInitialHtml;

      registerInlineAttachmentsInteractionFunctions({
        convertFroalaToHtmlWithInlineAttachments:
          this.convertFroalaToHtmlWithInlineAttachments,
      });
    }

    this.toGetHtml = () => convertedInitialHtml;
    this.getInlineImageEditorList = () => [];

    this.state = {
      convertedInitialHtml,
    };
  }

  registerHTMLEditorInteractionFunctions = (
    interactionFunctions: InteractionFunctions,
  ) => {
    const { registerInteractionFunctions } = this.props;

    this.toGetHtml = interactionFunctions.getHtml;
    this.getInlineImageEditorList =
      interactionFunctions.getInlineImageEditorList;

    if (registerInteractionFunctions) {
      registerInteractionFunctions({
        ...interactionFunctions,
        getHtml: this.toGetHtml,
      });
    }
  };

  convertFroalaToHtmlWithInlineAttachments =
    (): ConvertWithInlineAttachmentsReturnType =>
      convertFroalaToHtmlWithInlineAttachments(
        this.toGetHtml(),
        this.getInlineImageEditorList(),
        this.props.isSignatureEditor ? SIGNATURE.ATTACHMENT : undefined,
      );

  convertFroalaToHtmlWithEventInlineAttachments =
    (): ConvertWithEventInlineAttachmentsReturnType =>
      convertFroalaToHtmlWithEventInlineAttachments(
        this.toGetHtml(),
        this.getInlineImageEditorList(),
      );

  render() {
    const {
      getExtraOptions,
      // eslint-disable-next-line
      registerInteractionFunctions,
      // eslint-disable-next-line
      initialHtml,
      // eslint-disable-next-line
      useEventInlineAttachments,
      // eslint-disable-next-line
      useFlowInlineAttachments,
      initialButtons,
      toFroalaHtmlConverters,
      ...rest
    } = this.props;
    const { convertedInitialHtml } = this.state;

    return (
      <HTMLEditor
        {...rest}
        toFroalaHtmlConverters={[
          ...(toFroalaHtmlConverters ?? []),
          html => {
            const newlyAddedInlineImages = this.getInlineImageEditorList()
              .map(inlineImage => {
                if (!inlineImage.fileDetails) return null;

                const {
                  fileDetails: { url },
                  id,
                } = inlineImage;

                return getAttachmentsFromHtml(
                  FLOW_EMAILATTACHMENT_TYPE.FLOW_EMAILATTACHMENT,
                  inlineImage.fileDetails,
                  id,
                  url,
                );
              })
              .filter(item => item != null) as Array<FlowEmailAttachmentValue>;

            return convertHtmlToFroalaWithInlineAttachments(html, [
              ...(useFlowInlineAttachments?.initialInlineAttachments ?? []),
              ...newlyAddedInlineImages,
            ]);
          },
        ]}
        initialHtml={convertedInitialHtml}
        registerInteractionFunctions={
          this.registerHTMLEditorInteractionFunctions
        }
        getExtraOptions={() => {
          const propOptions = getExtraOptions == null ? {} : getExtraOptions();

          let options = {
            ...defaultEmailOptions,
            ...propOptions,
          };

          if (initialButtons && initialButtons.length > 0) {
            initialButtons.forEach(button => {
              switch (button) {
                case UNSUBSCRIBE_LINK_BUTTON_NAME: {
                  options = {
                    ...options,
                    toolbarButtons: {
                      ...options.toolbarButtons,
                      moreRich: {
                        ...options.toolbarButtons.moreRich,
                        buttons: [
                          UNSUBSCRIBE_LINK_BUTTON_NAME,
                          ...options.toolbarButtons.moreRich.buttons,
                        ],
                      },
                    },
                  };
                  break;
                }

                default:
                  throw Error(
                    `${cleanedFilename(
                      __filename,
                    )} | Should not occur | Unknown button ${button}`,
                  );
              }
            });
          }

          return {
            ...options,
            imageAllowedTypes: IMAGE_ALLOWED_TYPES,
            events: emailEventHandlers(),
          };
        }}
      />
    );
  }
}

export default EmailEditor;
