/**
 * Page component imported by gatsby in wrapPageElement
 * @see gatsby-browser.js
 */

import React from 'react';
import Auth from './Auth';

const WrapPageElement = ({ element }: { element: React.ReactElement }) => (
  <Auth>{element}</Auth>
);
export default WrapPageElement;
