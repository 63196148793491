import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import TEST_ID from './index.testid';
import FilterGroupV2, {
  FilterGroupV2Type,
} from '~/components/Filters/components/FilterGroupV2';
import { ConnectorOperator, ContactFilters__Input } from '~/graphql/types';

import Divider from '~/components/Divider';
import TextButton from '~/components/TextButton';
import { clone, lensPath, set } from 'ramda';
import { generateSubjectMap } from '~/components/Filters/util/contactFilter/getSubject';
import { generateCommandMap } from '~/components/Filters/util/contactFilter/getCommand';
import { generateTopicMap } from '~/components/Filters/util/contactFilter/getTopic';
import { generateTypeMap } from '~/components/Filters/util/contactFilter/getType';
import { generateOptionTypeMap } from '~/components/Filters/util/contactFilter/getOptionType';
import { FilterOptions } from '~/hooks/useContactFilterOptionsV2';
import { ErrorBoundary } from '@sentry/gatsby';
import { StyledOperatorDropdown } from '~/components/Filters/components/OperatorComponent';
import BrokenFilterBar from './components/BrokenFilterBar';
import { Heading4, Variant } from '~/components/Typography/index';

const text = {
  filtersTitle: 'Filters',
  addGroup: 'Groep toevoegen',
  deleteFilters: 'Alle filters wissen',
};

type Props = {
  filters: ContactFilters__Input;
  onAddFilterGroup: () => void;
  onChange: (newFilters: ContactFilters__Input) => void;
  loading?: boolean;
  filterOptions: FilterOptions;
};

export const emptyFilterGroupV2 = (): { Node: FilterGroupV2Type } => ({
  Node: {
    connector: ConnectorOperator.And,
    negate: false,
    nodes: [],
  },
});

const operatorOptions = [
  {
    label: 'en',
    payload: ConnectorOperator.And,
    key: ConnectorOperator.And,
  },
  {
    label: 'of',
    payload: ConnectorOperator.Or,
    key: ConnectorOperator.Or,
  },
];

const ContactListFilterBar: React.FC<Props> = ({
  filters,
  onAddFilterGroup,
  filterOptions,
  onChange,
}) => {
  const theme = useTheme();

  const shouldShowGroupConnector = filters.nodes.length > 1 ? true : false;
  const { subjects, topics, commands, types, optionTypes } = filterOptions;

  const subjectMap = generateSubjectMap(subjects);

  const { commandMap, typeIdToCommandsMap } = generateCommandMap(commands);
  const { typeMap } = generateTypeMap(types);
  const { optionTypeMap } = generateOptionTypeMap(optionTypes);
  const topicMap = generateTopicMap(topics);

  const hasEmptyFilterGroup =
    filters.nodes.find(
      node => node.Node == null || node.Node.nodes.length == 0,
    ) != null;

  return (
    <ErrorBoundary
      fallback={boundaryProps => (
        <BrokenFilterBar
          filters={filters}
          onChange={onChange}
          boundaryProps={boundaryProps}
        />
      )}
    >
      <Container data-testid={TEST_ID.CONTAINER}>
        <TitleContainer>
          <Heading4 withoutMargin variant={Variant.primary}>
            {text.filtersTitle}
          </Heading4>
          <ButtonsContainer>
            <TextButton
              disabled={hasEmptyFilterGroup ? true : false}
              label={text.addGroup}
              icon="plus"
              onClick={e => {
                e.currentTarget.blur();
                onAddFilterGroup();
              }}
            />
            <TextButton
              label={text.deleteFilters}
              icon="delete"
              onClick={e => {
                e.currentTarget.blur();
                return onChange({ ...filters, nodes: [emptyFilterGroupV2()] });
              }}
            />
          </ButtonsContainer>
        </TitleContainer>
        <Divider margin={['xxxs', null, 'l', null]} />

        <div style={{ position: 'relative' }}>
          <FiltersContainer>
            {filters.nodes.map(
              (node, idx) =>
                node.Node && (
                  <GroupContainer key={JSON.stringify({ ...node, __idx: idx })}>
                    <FilterGroupV2
                      filterGroupIndex={idx}
                      filterGroup={node.Node}
                      subjectMap={subjectMap}
                      commandMap={commandMap}
                      topicMap={topicMap}
                      typeMap={typeMap}
                      optionTypeMap={optionTypeMap}
                      typeIdToCommandsMap={typeIdToCommandsMap}
                      filterOptions={filterOptions}
                      onConfirm={newLeafs => {
                        const clonedNodes = clone(filters.nodes);
                        // If there is an empty filter group except the first one, remove it
                        if (idx != 0 && newLeafs.nodes.length === 0) {
                          clonedNodes.splice(idx, 1);
                          return onChange({
                            ...filters,
                            nodes: clonedNodes,
                          });
                        }
                        return onChange({
                          ...filters,
                          nodes: set(
                            lensPath([idx]),
                            { Node: newLeafs },
                            clonedNodes,
                          ),
                        });
                      }}
                    />
                    {shouldShowGroupConnector && <LineElement />}
                  </GroupContainer>
                ),
            )}
          </FiltersContainer>
          {shouldShowGroupConnector && (
            <GroupConnectorContainer>
              <StyledOperatorDropdown
                outline
                outlineStyles={{
                  active: css`
                    background-color: ${theme.color('primary', 'light')};
                    color: ${theme.color('white')};
                  `,
                  notActive: css`
                    border-color: ${theme.color('primary', 'light')};
                    color: ${theme.color('primary', 'light')};
                  `,
                }}
                small
                options={operatorOptions}
                selectedOptionIdx={operatorOptions.findIndex(
                  option => option.payload === filters.connector,
                )}
                onChange={selectedOption =>
                  onChange({
                    ...filters,
                    connector: selectedOption.option.payload,
                  })
                }
              />
            </GroupConnectorContainer>
          )}
        </div>
      </Container>
    </ErrorBoundary>
  );
};

export const TitleContainer = styled.div<{}>`
  display: flex;
  align-items: center;
`;

const GroupConnectorContainer = styled.div<{}>(
  ({ theme }) => css`
    position: absolute;
    top: calc(50% - 1.5rem);
    right: 0;
    max-width: 5rem;
    width: 100%;
    background: ${theme.color('white')};
  `,
);

export const FiltersContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

const LineElement = styled.div<{}>(
  ({ theme }) =>
    css`
      display: flex;
      max-width: 5rem;
      width: 100%;
      margin-left: 1rem;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 100%;

      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';

        border-right: ${theme.getTokens().border.width.base} solid
          ${theme.color('grey')};
        border-bottom: 5px solid transparent;
        height: calc(100% + 5px + ${theme.space('base')});
        width: 50%;
      }
    `,
);

export const GroupContainer = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    min-width: 700px;
    width: 100%;
    position: relative;
    margin-bottom: ${theme.space('base')};

    &:first-child ${LineElement}:after {
      border-top-right-radius: 5px;
      border-top: ${theme.getTokens().border.width.base} solid
        ${theme.color('grey')};
    }

    &:last-child ${LineElement}:after {
      border-bottom-right-radius: 5px;
      border-bottom: ${theme.getTokens().border.width.base} solid
        ${theme.color('grey')};

      height: 100%;
    }
  `,
);

export const ButtonsContainer = styled.div<{}>(
  ({}) => css`
    display: flex;
    width: 100%;
    justify-content: flex-end;
  `,
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin: ${theme.space('xxs')} 0 ${theme.space('xxs')} 0;
  `,
);

export default ContactListFilterBar;
