import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterNumberFields on Flow_Parameter__Number {
    __typename

    ... on Flow_Parameter_Number_Primitive {
      numberValue: value
    }

    ... on Flow_Parameter_Number_Pointer {
      variable
      field
    }
  }
`;
