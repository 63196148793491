import gql from 'graphql-tag';
import ZapierEventFields from '../fragments/ZapierEventFields';

export default gql`
  query GetZapierEvent($accountId: ID!, $id: ID!) {
    getZapierEvent(accountId: $accountId, id: $id) {
      ...ZapierEventFields
    }
  }
  ${ZapierEventFields}
`;
