import React from 'react';
import { Handle, Position } from 'reactflow';
// import { useRecoilState, useSetRecoilState } from 'recoil';
import styled, { css, useTheme } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
// import Dialog from '~/components/ModalsV2/Dialog';
// import Overlay from '~/components/ModalsV2/Overlay';
// import RadioButton from '~/components/RadioButton';
import { FlowAction } from '~/graphql/types';

import { ClientFlowActionIfElse } from '~/graphql/types.client';
// import { ifElseActionState } from '~/scenes/Automation/v2/state/ifElseAction';
// import interactions from '~/scenes/Automation/v2/state/interactions';
import getCardHeading from '../../../../utils/getCardHeading';
import { StatisticsProps, Units } from '../CardStatistics';
import StatisticsItem from '../CardStatistics/components/StatisticsItem';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import ConditionList from '../ConditionList';

export enum HandlerId {
  trueChild = 'trueChild',
  falseChild = 'falseChild',
}

// export type ChildType = 'trueChild' | 'falseChild' | null;

export type Props = Omit<
  CardProps<ClientFlowActionIfElse>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

// const text = {
//   confirmModalHeader: 'Choose what child you want to put your action under',
//   trueChild: 'trueChild',
//   falseChild: 'falseChild',
//   confirm: 'Ok',
//   cancel: 'Annuleer',
// };

const IfElseCard: React.FC<Props> = React.memo(
  ({ selected, disabled, ...props }) => {
    const theme = useTheme();
    const stats = props.data.action.Statistics;
    const trueBranchStat = stats?.trueBranch ?? 0;
    const falseBranchStat = stats?.falseBranch ?? 0;

    const totalStat = trueBranchStat + falseBranchStat;

    const statistics: Array<StatisticsProps> = [
      {
        color: theme.color('success'),
        heading: 'Waar',
        value: trueBranchStat,
        unit: Units.Leads,
        icon: 'check',
        total: totalStat,
      },
      {
        color: theme.color('danger'),
        heading: 'Niet waar',
        value: falseBranchStat,
        unit: Units.Leads,
        total: totalStat,
        icon: 'error',
      },
    ];

    return (
      //Commented out due to lack of time, here's the ticket to implement duplication of ifElse action
      //https://app.shortcut.com/dathuis/story/7311/implement-duplication-of-ifelse-action-in-flows-v2
      // const [activeInteraction, setActiveInteraction] =
      //   useRecoilState(interactions);
      // const [placeUnder, setPlaceUnder] = useState<ChildType>(null);
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.IfElse}
          heading={getCardHeading(FlowAction.IfElse)}
          selected={selected}
          disabled={disabled}
        >
          <JustificationContainer
            justification="start"
            margin={[null, null, 'xxxl', null]}
          >
            <ConditionList
              triggers={props.data.action.triggers}
              actionId={props.data.action.id}
            />
          </JustificationContainer>
          <br />
          <Statistics>
            {statistics.map(stat => (
              <StatisticsItemContainer key={stat.heading}>
                <StatisticsItem {...stat} />
              </StatisticsItemContainer>
            ))}
          </Statistics>
        </CardTemplate>
        <Handle
          type="source"
          id={HandlerId.trueChild}
          position={Position.Bottom}
          style={{ background: 'transparent', left: '20%' }}
          isConnectable={false}
        />
        <Handle
          type="source"
          id={HandlerId.falseChild}
          position={Position.Bottom}
          style={{ background: 'transparent', left: '80%' }}
          isConnectable={false}
        />
        {/* {activeInteraction?.type === 'duplicateIfElse' && (
          <Overlay onClose={() => setActiveInteraction(null)}>
            <Dialog
              header={text.confirmModalHeader}
              body={
                <>
                  <RadioButton
                    checked={placeUnder === 'trueChild'}
                    onChange={() => setPlaceUnder('trueChild')}
                    label={text.trueChild}
                  />
                  <RadioButton
                    checked={placeUnder === 'falseChild'}
                    onChange={() => setPlaceUnder('falseChild')}
                    label={text.falseChild}
                  />
                </>
              }
              onConfirm={() => {
                activeInteraction.onConfirm(placeUnder);
                setActiveInteraction(null);
              }}
              confirmAction={{
                label: text.confirm,
              }}
              cancelAction={{
                label: text.cancel,
              }}
            />
          </Overlay>
        )} */}
      </>
    );
  },
);

const StatisticsItemContainer = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing('m')};
    width: 100%;
    border-radius: ${theme.getTokens().border.radius.base};
    background: ${theme.color('white')};
    box-shadow: ${theme.getTokens().boxShadow.s};
  `,
);

const Statistics = styled.div(
  ({ theme }) => css`
    max-width: 420px;
    width: 100%;
    position: absolute;
    left: 18px;
    bottom: -22px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${theme.spacing('m')};
  `,
);

export default IfElseCard;
