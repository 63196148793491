import { FlowConditionEmailOpened } from './types.flow';
import { Flow_Condition__Input } from '~/graphql/types';

import { asEmailParameterValueInput } from '../../FlowParameter/ParameterValue/Email';

export default (condition: FlowConditionEmailOpened): Flow_Condition__Input => {
  const { email } = condition;

  return {
    EventEmail: {
      Opened: {
        email: asEmailParameterValueInput(email),
      },
    },
  };
};
