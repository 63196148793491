import { keys } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import TEST_ID from './index.testid';
import restrictedFields from '../DataTables/components/restrictedFields';
import useViewingModeProps from '~/hooks/useViewingModeProps';

export type Columns = Record<
  string,
  {
    header: React.ReactNode;
    format?: (
      value: React.ReactNode,
      row?: Row,
      columnName?: string,
    ) => React.ReactNode;
  }
>;

export type Row = Record<string, React.ReactNode>;

export type Props = {
  dataTestId?: string;
  columns: Columns;
  rows: Array<Row>;
  className?: string;
};

const format = (columns: Columns, columnName: string, row: Row) => {
  const formatter = columns[columnName].format;
  return formatter
    ? formatter(row[columnName], row, columnName)
    : row[columnName];
};

/*
  If you need column sorting or pagination, this is not the table you're looking for (http://gph.is/1W9lKoB)
*/
const SimpleTable: React.FC<Props> = ({
  dataTestId,
  columns,
  rows,
  ...rest
}) => {
  const viewingModeProps = useViewingModeProps();

  return (
    <ContainerTable data-testid={dataTestId} {...rest}>
      <thead>
        <tr>
          {keys(columns).map((column, index) => (
            <HeaderCell key={`header-${index}`} data-testid={TEST_ID.HEADER}>
              {columns[column].header}
            </HeaderCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index} data-testid={TEST_ID.ROW}>
            {keys(columns).map(columnName => (
              <td
                key={columnName}
                data-redacted={
                  viewingModeProps['data-redacted'] &&
                  restrictedFields.includes(columnName)
                }
              >
                {format(columns, columnName, row)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </ContainerTable>
  );
};

const ContainerTable = styled.table<{}>(
  ({ theme }) => css`
    border-spacing: ${theme.space('xs')};
  `,
);

const HeaderCell = styled.th<{}>(
  () => css`
    text-align: left;
  `,
);

export default SimpleTable;
