import { Flow_Action__Input } from '~/graphql/types';
import { CreateTaskAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import {
  asOfficeParameterValueInput,
  isNullOfficeParameterValue,
} from '../Base/FlowParameter/ParameterValue/Office';
import {
  asUserParameterValueInput,
  isNullUserParameterValue,
} from '../Base/FlowParameter/ParameterValue/User';
import { asTemplateStringParameterValueInput } from '../Base/FlowParameter/ParameterValue';

export default (
  action: CreateTaskAction,
  treeNode: ActionTreeNode,
  defaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, taskInput } = action;
  const { title, description, taskType, office, user, assignToContactOwner } =
    taskInput;
  const { parentId } = treeNode;
  const { accountId, flowBlueprintId } = defaults;

  if (parentId == null)
    throw new Error(`Parentless CreateTaskAction found! ID: ${id}`);

  return {
    Task: {
      Create: {
        id,
        accountId,
        flowBlueprintId,
        parentId,
        assignToContactOwner,
        office: isNullOfficeParameterValue(office)
          ? null
          : asOfficeParameterValueInput(office),
        user: isNullUserParameterValue(user)
          ? null
          : asUserParameterValueInput(user),
        title: asTemplateStringParameterValueInput(title),
        description: asTemplateStringParameterValueInput(description),
        type: taskType,
      },
    },
  };
};
