import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { CreateTaskAction } from './types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { TaskType } from '~/graphql/types';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';
import {
  OfficeParameterValue,
  UserParameterValue,
  TemplateStringParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithAccountContext } from '~/contexts/AccountContext';

import { withAccountContext } from '~/contexts/AccountContext';
import FieldLabel from '~/scenes/Automation/Flows/Actions/FieldLabel';
import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import asCreateTaskActionInput from './asCreateTaskActionInput';
import { FLOW_ACTION_TYPE } from '../constants';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import ActionExplanationHeader from '../ActionExplanationHeader';
import ActionContentContainer from '../ActionContentContainer';
import { taskTypeOptions } from '~/util/taskTypeOptions';
import { MinimalDropdown, Checkbox } from '~/components/Inputs';
import {
  OfficeParameterValueComponent,
  OfficePrimitiveParameterValueComponent,
} from '../Base/FlowParameter/ParameterValue/Office';
import {
  UserParameterValueComponent,
  isNullUserParameterValue,
  emptyUserPrimitiveParameterValue,
  UserPrimitiveParameterValueComponent,
} from '../Base/FlowParameter/ParameterValue/User';
import { InputGroup } from '~/components/Inputs';
import { ParameterTemplateStringFieldComponent } from '../Base/FlowParameter/FlowParameterField/FlowParameterTemplateStringField';
import TEST_ID from './CreateTaskActionComponent.testid';
import { PARAMETER_VALUE_TYPE } from '../Base/FlowParameter/ParameterValue/constants';

const text = {
  explanation: 'Taak aanmaken',
  descriptionLabel: 'Omschrijving',
  titleLabel: 'Titel',
  officeWithNoAccountLabel: 'Vestiging (indien geen eigenaar)',
  userWithNoAccountLabel: 'Gebruiker (indien geen eigenaar)',
  userLabel: 'Gebruiker',
  officeLabel: 'Vestiging',
  typeLabel: 'Type taak',
  assignToOwnerLabel: 'Aan de eigenaar van het contact toewijzen',
};
type MyProps = ActionSubscriberProps & {
  id: string;
  isHiddenFromView: boolean;
  positionString: string;
  initialActionProps: CreateTaskAction;
};
type WithoutValidatorProps = WithAccountContext & MyProps;
type Props = WithoutValidatorProps & WithValidatorListProps;
type State = {
  /** To rerender the component you can update this number. Needed to rerender the description */
  renderChangeCounter: number;
  key: string | null;
  assignToContactOwner: boolean;
  office: OfficeParameterValue;
  user: UserParameterValue;
  initialTitle: TemplateStringParameterValue;
  initialDescription: TemplateStringParameterValue;
  taskType: TaskType;
  variableStashVersion: string | null;
};
class SetContactDetailsComponent extends React.Component<Props, State> {
  _isMounted = false;
  variableStash: FlowVariableStash = emptyVariableStash();
  getTitle: () => TemplateStringParameterValue = () =>
    this.props.initialActionProps.taskInput.title;
  getDescription: () => TemplateStringParameterValue = () =>
    this.props.initialActionProps.taskInput.description;

  constructor(props: Props) {
    super(props);

    const { initialActionProps } = props;
    const { taskInput } = initialActionProps;

    this.state = {
      renderChangeCounter: 0,
      key: null,
      assignToContactOwner: taskInput.assignToContactOwner,
      initialTitle: taskInput.title,
      initialDescription: taskInput.description,
      taskType: taskInput.taskType,
      office: taskInput.office,
      user: taskInput.user,
      variableStashVersion: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.props.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { isHiddenFromView } = nextProps;
    const { variableStashVersion: nextVariableStashVersion } = nextState;
    const { variableStashVersion } = this.state;

    if (nextVariableStashVersion !== variableStashVersion) {
      return true;
    }

    return !isHiddenFromView;
  }

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asCreateTaskActionInput(this.getActionProp(), treeNode, defaults);

  setGetTitle = (getTitleFunction: () => TemplateStringParameterValue) => {
    this.getTitle = getTitleFunction;
  };

  setGetDescription = (
    getDescriptionFunction: () => TemplateStringParameterValue,
  ) => {
    this.getDescription = getDescriptionFunction;
  };

  setAssignToContactOwner = () => {
    if (this._isMounted) {
      this.setState(
        (state: State) => ({
          assignToContactOwner: !state.assignToContactOwner,
        }),
        () => {
          this.props.announceChanges();
        },
      );
    }
  };

  updateOffice = (newOffice: OfficeParameterValue) => {
    if (this._isMounted) {
      const { user } = this.state;

      this.props.announceChanges();

      let newState = {
        office: newOffice,
        user: user,
      };

      if (
        newOffice.type === PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE &&
        user.type === PARAMETER_VALUE_TYPE.USER_PRIMITIVE
      ) {
        if (newOffice.value != null && user.value != null) {
          const possibleUsers = this.props.accountContext.usersForOffice(
            newOffice.value,
          );

          if (
            possibleUsers.find(
              possibleUser => possibleUser.id === user.value,
            ) == null
          ) {
            newState = {
              ...newState,
              user: emptyUserPrimitiveParameterValue(),
            };
          }
        }
      }

      this.setState(newState);
    }
  };

  updateUser = (newUser: UserParameterValue) => {
    if (this._isMounted) {
      this.props.announceChanges();

      this.setState({
        user: newUser,
      });
    }
  };

  setVariableStash = (newStash: FlowVariableStash) => {
    this.variableStash = newStash;

    if (this._isMounted) {
      this.setState({
        variableStashVersion: newStash.version,
      });
    }
  };

  getActionProp = (): CreateTaskAction => {
    const { id } = this.props;
    const { taskType, user, office, assignToContactOwner } = this.state;

    return {
      id,
      type: FLOW_ACTION_TYPE.CREATE_TASK,
      taskInput: {
        assignToContactOwner,
        title: this.getTitle(),
        description: this.getDescription(),
        taskType,
        user,
        office,
      },
    };
  };

  render() {
    const {
      initialTitle,
      initialDescription,
      taskType,
      user,
      office,
      assignToContactOwner,
    } = this.state;
    const {
      subscribeValidator,
      unsubscribeValidator,
      announceChanges,
      isHiddenFromView,
      positionString,
    } = this.props;
    const action = this.getActionProp();

    let officeFieldComponent: ReactElement | null = null;
    if (office.type === PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE) {
      officeFieldComponent = (
        <OfficePrimitiveParameterValueComponent
          value={office}
          onChange={this.updateOffice}
          isMandatory={!isNullUserParameterValue(user)}
        />
      );
    } else {
      officeFieldComponent = (
        <OfficeParameterValueComponent
          value={office}
          onChange={this.updateOffice}
          isMandatory={!isNullUserParameterValue(user)}
        />
      );
    }
    let userFieldComponent: ReactElement | null = null;
    if (user.type === PARAMETER_VALUE_TYPE.USER_PRIMITIVE) {
      userFieldComponent = (
        <UserPrimitiveParameterValueComponent
          value={user}
          onChange={this.updateUser}
          officeId={getOfficeId(office)}
        />
      );
    } else {
      userFieldComponent = (
        <UserParameterValueComponent
          value={user}
          onChange={this.updateUser}
          officeId={getOfficeId(office)}
        />
      );
    }

    return (
      <BaseActionComponent
        positionString={positionString}
        actionLabel={text.explanation}
        action={action}
        variableStash={this.variableStash}
        subscribeValidator={subscribeValidator}
        unsubscribeValidator={unsubscribeValidator}
        isHiddenFromView={isHiddenFromView}
      >
        <ActionExplanationHeader title={text.explanation} />
        <ActionContentContainer>
          <InputGroup data-testid={TEST_ID.TYPE_AND_TITLE_BAR}>
            <MinimalDropdown
              label={text.typeLabel}
              dataTestid={TEST_ID.TYPE_FIELD}
              onChange={({ option }) => {
                announceChanges();

                this.setState({ taskType: option.payload });
              }}
              selectedOptionIdx={taskTypeOptions.findIndex(
                option => option.payload === taskType,
              )}
              options={taskTypeOptions}
            />
            <ParameterTemplateStringFieldComponent
              label={text.titleLabel}
              initialTemplateString={initialTitle}
              setGetTemplateComponent={getTitleFunction =>
                this.setGetTitle(getTitleFunction)
              }
              announceChanges={() => {
                this.setState(prevState => ({
                  renderChangeCounter: prevState.renderChangeCounter + 1,
                }));

                announceChanges();
              }}
              singleLine
              isMandatory
            />
          </InputGroup>
          <InputGroup data-testid={TEST_ID.DESCRIPTION}>
            <ParameterTemplateStringFieldComponent
              label={text.descriptionLabel}
              initialTemplateString={initialDescription}
              setGetTemplateComponent={getDescriptionFunction =>
                this.setGetDescription(getDescriptionFunction)
              }
              announceChanges={announceChanges}
              textarea
              keepHtml
            />
          </InputGroup>
          <AssignTitle>Taak toewijzen</AssignTitle>
          <InputGroup>
            <Checkbox
              value={assignToContactOwner}
              data-testid={TEST_ID.ASSIGN_TO_CONTACT_OWNER}
              onChange={this.setAssignToContactOwner}
              label={text.assignToOwnerLabel}
              name="overwriteAssignee"
            />
          </InputGroup>
          <InputGroup data-testid={TEST_ID.OFFICE}>
            <FieldLabel>
              {assignToContactOwner
                ? text.officeWithNoAccountLabel
                : text.officeLabel}
            </FieldLabel>
            {officeFieldComponent}
          </InputGroup>
          <InputGroup data-testid={TEST_ID.USER}>
            <FieldLabel>
              {assignToContactOwner
                ? text.userWithNoAccountLabel
                : text.userLabel}
            </FieldLabel>
            {userFieldComponent}
          </InputGroup>
        </ActionContentContainer>
      </BaseActionComponent>
    );
  }
}

const AssignTitle = styled.div<{}>`
  font-weight: bold;
`;

const getOfficeId = (
  office: OfficeParameterValue,
): string | null | undefined => {
  if (office.type === PARAMETER_VALUE_TYPE.OFFICE_POINTER) {
    return null;
  }

  return office.value;
};

export default withAccountContext<MyProps>(
  withValidatorList<WithoutValidatorProps>(SetContactDetailsComponent),
);
