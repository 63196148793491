import { FlowConditionAppFundaViewingRequestNew } from './types.flow';

const asFlowConditionAppFundaViewingRequestNewProp = (): Omit<
  FlowConditionAppFundaViewingRequestNew,
  'id'
> => ({
  type: 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New',
});

export default asFlowConditionAppFundaViewingRequestNewProp;
