import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';
import { ConditionField } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/types.flow';

import describeTypedField from '~/scenes/Automation/Flows/Actions/Base/describeTypedField';
import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import { needsUserActionDescription } from '~/scenes/Automation/Flows/Actions/util';
import { describeOperation } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/';

const describeMultipleFields = (
  fields: Array<ConditionField>,
  allShouldBeTrue: boolean,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  const descriptionArray: DescribeNodeType = [];

  if (fields.length === 0) {
    return needsUserActionDescription('Voeg een veld toe');
  }

  const describedTypedField = describeTypedField(fields[0]);

  if (Array.isArray(describedTypedField)) {
    descriptionArray.push(...describedTypedField);
  } else {
    descriptionArray.push(describedTypedField);
  }

  let isFirst = true;

  fields.forEach(field => {
    const { operation } = field;

    if (!isFirst) {
      descriptionArray.push(allShouldBeTrue ? ' en ' : ' of ');
    } else {
      descriptionArray.push(' ');
      isFirst = false;
    }

    if (operation == null || operation.value == null) {
      return needsUserActionDescription('Vul een veld in');
    }

    descriptionArray.push(describeOperation(operation));
    descriptionArray.push(' ');

    const describedParamValue = describeParameterValue(
      operation.value,
      describeOptions,
    );

    if (Array.isArray(describedParamValue)) {
      descriptionArray.push(...describedParamValue);
    } else {
      descriptionArray.push(describedParamValue);
    }

    return;
  });

  return descriptionArray;
};

export default describeMultipleFields;
