import { NotificationLevel, SubscriptionFieldsFragment } from '~/graphql/types';

import cleanedFilename from '~/util/cleanedFilename';
import {
  isAfter,
  convertServerDateStringToDate,
  safeFormattedDate,
} from '~/util/date';
import TEST_ID from './index.testid';
import { Warning } from '~/hooks/useEntityWarnings';
import { generateHash } from '~/util/react';
import { reporter } from '~/hooks/useErrorReporter';

const errorMessagesForSubscription = (
  subscription: SubscriptionFieldsFragment | null | undefined,
): Array<Warning> => {
  if (subscription == null) return [];

  const result: Array<Warning> = [];

  switch (subscription.__typename) {
    case 'Subscription_Stripe': {
      const { cancelled, cancellationDate, nextPlan } = subscription;
      const convertedCancellationDate =
        convertServerDateStringToDate(cancellationDate);

      if (
        cancelled &&
        nextPlan == null &&
        convertedCancellationDate != null &&
        isAfter(convertedCancellationDate, new Date())
      ) {
        result.push(willBeCancelledMessage(convertedCancellationDate));
      }

      break;
    }
    case 'Subscription_External': {
      const { cancelled, cancellationDate } = subscription;
      const convertedCancellationDate =
        convertServerDateStringToDate(cancellationDate);

      if (
        cancelled &&
        convertedCancellationDate != null &&
        isAfter(convertedCancellationDate, new Date())
      ) {
        result.push(willBeCancelledMessage(convertedCancellationDate));
      }
      break;
    }
    default:
      reporter.captureException(
        new Error(
          `${cleanedFilename(
            __filename,
          )} | Unrecognised subscription __typename in (${JSON.stringify(
            subscription,
            null,
            2,
          )})`,
        ),
        'debug',
      );

      return [];
  }

  return result;
};

const willBeCancelledMessage = (onDate: Date): Warning => {
  const error = `Het abonnement van dit account zal op ${safeFormattedDate(
    onDate,
  )} aflopen. <a
    href="/-/settings/subscription/"
    data-testid="${
      TEST_ID.WILL_BE_CANCELLED_LINK
    }"> Klik hier </a> om je abonnement te heractiveren.`;
  return {
    item: {
      __typename: 'NotificationListItem',
      level: NotificationLevel.Info,
      body: {
        __typename: 'NotificationMessage',
        htmlBody: error,
      },
    },
    hash: generateHash(error),
  };
};

export default errorMessagesForSubscription;
