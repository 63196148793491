import { NumberField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { NumberConditionField } from '../types.flow';
import { FlowConditionNumberPrimitiveFieldsFragment } from '~/graphql/types';

import { FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION } from './constants';
import { asNumberParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import cleanedFilename from '~/util/cleanedFilename';

const asConditionNumberFieldProp = (
  field: NumberField,
  condition: FlowConditionNumberPrimitiveFieldsFragment,
): NumberConditionField => {
  switch (condition.__typename) {
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.EQUALS:
      return {
        ...field,
        operation: {
          type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.EQUALS,
          value: asNumberParameterValue(condition.numberValue),
          negate: condition && condition.negate ? condition.negate : false,
        },
      };
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.LESS_THAN:
      return {
        ...field,
        operation: {
          type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.LESS_THAN,
          value: asNumberParameterValue(condition.numberValue),
          negate: condition && condition.negate ? condition.negate : false,
        },
      };
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.GREATER_THAN:
      return {
        ...field,
        operation: {
          type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.GREATER_THAN,
          value: asNumberParameterValue(condition.numberValue),
          negate: condition && condition.negate ? condition.negate : false,
        },
      };
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur: [>>asConditionNumberFieldProp] unknown __typename ${
          condition.__typename
        }`,
      );
  }
};

export default asConditionNumberFieldProp;
