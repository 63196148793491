import React from 'react';
import TEST_ID from './index.testid';
import FullPageInformation from '~/components/FullPageInformation';
import { navigate } from 'gatsby';

const text = {
  title: 'Account niet gevonden',
  explanation:
    'We kunnen geen account vinden die aan dit e-mailadres is gekoppeld. Als er iets recentelijk is gewijzigd kan dit nog niet op deze computer zijn bijgewerkt, probeer dan opnieuw in te loggen.',
  goToLinkText: 'Opnieuw inloggen',
};

const NoAccountErrorScreen: React.FC = () => (
  <>
    <FullPageInformation
      title={text.title}
      explanation={text.explanation}
      goToLink={() => void navigate('/login')}
      goToLinkText={text.goToLinkText}
      dataTestid={TEST_ID.CONTAINER}
    />
  </>
);

export default NoAccountErrorScreen;
