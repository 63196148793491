import React, { ReactElement } from 'react';

import { IfElseAction } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { describeFlowConditionList } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList';
import { needsUserActionDescription } from '~/scenes/Automation/Flows/Actions/util';

const describeIfElseAction = (
  action: IfElseAction,
  describeOptions: DescribeOptions,
): ReactElement => {
  const { yesPathChildId, noPathChildId, conditionList } = action;

  return (
    <span>
      Als {describeFlowConditionList(conditionList, describeOptions, false)}{' '}
      {describePaths(yesPathChildId, noPathChildId, describeOptions)}
    </span>
  );
};

const describePaths = (
  yesPathChildId: string | null,
  noPathChildId: string | null,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  if (yesPathChildId == null && noPathChildId == null) {
    return needsUserActionDescription('vul een pad in');
  }

  return `${describePath(
    yesPathChildId,
    describeOptions,
  )}, anders ${describePath(noPathChildId, describeOptions)}`;
};

const describePath = (
  pathId: string | null,
  describeOptions: DescribeOptions,
) => {
  if (pathId == null) {
    return 'stop de flow';
  }

  return `ga verder met stap ${describeOptions.getPositionStringForId(pathId)}`;
};

export default describeIfElseAction;
