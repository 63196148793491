import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import ToggleCheckbox from '~/components/ToggleCheckbox';

type Row = {
  id: string;
  name: string;
  checked: boolean;
};

export type Props = {
  dataTestId?: string;
  row: Row;
  checkboxTitle: string;
  selectable: boolean;
  disabled: boolean;
  onChange: (value: boolean) => void;
  children?: React.ReactNode;
};

const SelectableRow: React.FC<Props> = ({
  dataTestId,
  row: { id, name, checked },
  onChange,
  disabled,
  children,
  ...rest
}) => (
  <Container
    justification="space-between"
    align="center"
    padding={['base', 'l']}
    data-testid={dataTestId}
    data-objectid={id}
    $disabled={disabled}
    {...rest}
  >
    <JustificationContainer align="center" gap="base">
      {children}
      <span>{name}</span>
    </JustificationContainer>
    <ToggleCheckbox
      value={checked}
      onChange={() => onChange(!checked)}
      size="small"
      disabled={disabled}
    />
  </Container>
);

const Container = styled(JustificationContainer)<{ $disabled: boolean }>`
  ${({ theme, $disabled }) => css`
    border: 1px solid transparent;

    background-color: ${theme.color('white')};

    &:first-child {
      border-top-right-radius: ${theme.getTokens().border.radius.m};
      border-top-left-radius: ${theme.getTokens().border.radius.m};
    }

    &:last-child {
      border-bottom-right-radius: ${theme.getTokens().border.radius.m};
      border-bottom-left-radius: ${theme.getTokens().border.radius.m};
    }

    &:hover {
      background-color: ${$disabled
        ? theme.color('white')
        : theme.color('white', 'dark')};
    }

    ${theme.mq.lessThan('mobile')`
      & > * {
        margin-bottom: ${theme.space('m')};
      }
    `};
  `}
`;

export default SelectableRow;
