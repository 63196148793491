import React, { useState } from 'react';

import { ImgDefinition } from '~/graphql/types.client.flow';

import EditableName from '~/components/DetailsPage/EditableName';
import { TitleContainer } from './ProfileHeader.style';
import { UpdateOfficePictureMutation } from '~/graphql/Mutation';
import mutation from '~/graphql/mutation/UpdateOfficePicture';

import cleanedFilename from '~/util/cleanedFilename';
import uploadS3Image from '~/util/uploadS3Image';
import AvatarWithUploader from '../AvatarWithUploader';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import JustificationContainer from '../JustificationContainer';
import CopyTextBlock from '../CopyTextBlock';

type Props = {
  edit?: boolean | null | undefined;
  office: {
    id: string;
    img: ImgDefinition | null | undefined;
    name: string;
  };
  onSuccess: () => void;
  onImageUploadError: () => void;
  updateName: (name: string) => void;
};

const text = {
  officeIdLabel: 'Office ID',
};
const OfficeProfileHeader = ({
  edit,
  office,
  onSuccess,
  onImageUploadError,
  updateName,
}: Props) => {
  const account = useCurrentAccount();
  const [loading, setLoading] = useState(false);

  const imageUrl = office && office.img ? office.img.medium : '';
  const officeId = office.id;

  return (
    <UpdateOfficePictureMutation
      name={cleanedFilename(__filename)}
      mutation={mutation}
      onCompleted={onSuccess}
    >
      {updateProfilePicture => {
        const onUploadImageDone = (
          identityId: string | null | undefined,
          permanentLink: string | null | undefined,
          filename: string,
        ) => {
          if (!identityId) return;

          const variables = {
            accountId: account.id,
            officeId,
            identityId,
            filename,
          };
          return updateProfilePicture({
            variables,
          }).then(() => {
            setLoading(false);
          });
        };

        const onUploadImage = async (file: File) => {
          try {
            setLoading(true);
            const { identityId, permanentLink, filename } = await uploadS3Image(
              {
                file,
              },
            );

            return onUploadImageDone(identityId, permanentLink, filename);
          } catch {
            setLoading(false);
            onImageUploadError();
          }
        };

        return (
          <TitleContainer>
            <AvatarWithUploader
              avatarFor="officeProfile"
              edit={edit}
              imageUrl={imageUrl}
              onUpload={files => onUploadImage(files[0])}
              loading={loading}
            />
            <JustificationContainer direction="column" gap="xxs">
              <EditableName
                edit={edit}
                updateName={updateName}
                name={office.name}
              />
              <CopyTextBlock textToCopy={officeId} label={text.officeIdLabel} />
            </JustificationContainer>
          </TitleContainer>
        );
      }}
    </UpdateOfficePictureMutation>
  );
};

export default OfficeProfileHeader;
