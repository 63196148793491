import React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
};
const ActionExplanationHeader = ({ title }: Props) => (
  <Container>{title}</Container>
);

const Container = styled.div<{}>`
  font-weight: bold;

  ${({ theme }) => `
    padding: ${theme.space('s')} ${theme.space('l')};
    border-bottom: 1px solid ${theme.color('grey')};
  `};
`;

export default ActionExplanationHeader;
