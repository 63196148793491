import { Trigger } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor';
import { FlowConditionConnector } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/components/OperatorDropdown';
import { assertType } from '~/util/assertion';
import type {
  Mailbox,
  SessionHydrationAccountRelationFieldsFragment,
  SessionHydrationOfficeRelationFieldsFragment,
  Address as _Address,
  GetAppValuationReportsQuery,
  GetAppStatusesQuery,
  FlowActionFieldsFragment,
  ActivityFields_Task_Contact_Fragment,
  ActivityFields_Event_Contact_App_BbWaardecheck_ContactRequest_Fragment,
  ActivityFields_Event_Contact_App_BbWaardecheck_AppraisalRequest_Fragment,
  ActivityFields_Event_Contact_App_BbWaardecheck_Report_Fragment,
  ActivityFields_Event_Contact_App_Funda_BrochureRequest_Fragment,
  ActivityFields_Event_Contact_App_Funda_ContactRequest_Fragment,
  ActivityFields_Event_Contact_App_Funda_ViewingRequest_Fragment,
  ActivityFields_Event_Contact_App_ValuationRequest_Fragment,
  ActivityFields_Event_Contact_App_ValuationReport_Fragment,
  ActivityFields_Event_Contact_New_Fragment,
  ActivityFields_Event_Contact_Email_Fragment,
  ActivityFields_Event_Contact_Note_Fragment,
  ActivityFields_Event_Contact_Call_Fragment,
  ActivityFields_Event_Contact_App_Zapier_Fragment,
  ActivityFieldsFragment,
  FlowConditionFieldsFragment,
  FlowConditionFields_Flow_Condition_Contact_New_Fragment,
  FlowConditionFields_Flow_Condition_Contact_Details_Fragment,
  FlowConditionFields_Flow_Condition_Contact_Tag_Fragment,
  FlowConditionFields_Flow_Condition_Event_Email_LinkClicked_Fragment,
  FlowConditionFields_Flow_Condition_Event_Email_Opened_Fragment,
  FlowConditionFields_Flow_Condition_Time_Elapsed_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationReport_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationReport_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationRequest_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationRequest_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_ContactRequest_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_Report_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_Report_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_AppraisalRequest_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_AppraisalRequest_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_ContactRequest_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Zapier_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Zapier_Details_Fragment,
  ActivityFields_Event_Contact_App_VboWaardecheck_Report_Fragment,
  ActivityFields_Event_Contact_App_VboWaardecheck_AppraisalRequest_Fragment,
  ActivityFields_Event_Contact_App_VboWaardecheck_ContactRequest_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_Report_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_Report_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_AppraisalRequest_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_AppraisalRequest_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_ContactRequest_New_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Hypotheekbond_Details_Fragment,
  FlowConditionFields_Flow_Condition_Event_Contact_App_Hypotheekbond_New_Fragment,
  ActivityFields_Event_Contact_App_ArxGroep_ContractCreated_Fragment,
  ActivityFields_Event_Contact_App_ArxGroep_TransactionCreated_Fragment,
  ActivityFields_Event_Contact_App_ZapierTrigger_Fragment,
  ActivityFields_Event_Contact_App_Hypotheekbond_Fragment,
  ActivityFields_Event_Contact_App_Trustoo_Fragment,
  ActivityFields_Event_Contact_App_WozComplaint_ComplaintCreated_Fragment,
  ActivityFields_Event_Contact_App_WozComplaint_ComplaintSuccess_Fragment,
  ActivityFields_Event_Contact_App_EnvenRequest_Fragment,

  // Flow v2
  Flow___ArgumentFragment,
  FlowV2_Action_IfElseFragment,
  FlowV2_Action_StartFragment,
  FlowV2_Action_WaitFragment,
  FlowV2_Action_Contact_AddTagFragment,
  FlowV2_Action_Contact_AssignFragment,
  FlowV2_Action_Contact_DeleteTagFragment,
  FlowV2_Action_Contact_DetailsFragment,
  FlowV2_Action_Realworks_SendContactFragment,
  FlowV2_Action_SendEmail_PlainFragment,
  FlowV2_Action_Task_CreateFragment,
  FlowV2_Action_Zapier_TriggerFragment,
  FlowV2_Update_Action,
  AccountMailbox,
  OfficeMailbox,
  UserMailbox,
  ActivityFields_Event_Contact_App_EnvenTransaction_Fragment,
  ActivityFields_Event_Contact_App_SustainabilityReport_Fragment,
  ActivityFields_Event_Contact_App_TrustooSubmitted_Fragment,
  ActivityFields_Event_Contact_App_TrustooConversion_Fragment,
  Flow___File,
  ActivityFields_Event_Contact_Generic_Fragment,
} from './types';
import { FlowAction } from '~/graphql/types';
import { Descendant } from 'slate';
import { WizardStep } from '~/components/Wizard/context/WizardContext';

export type MailboxAlias = Extract<
  Mailbox,
  | { __typename: 'AccountMailboxAlias' }
  | { __typename: 'OfficeMailboxAlias' }
  | { __typename: 'UserMailboxAlias' }
>;

export type NonAliasMailbox = AccountMailbox | OfficeMailbox | UserMailbox;

export type Address = Omit<_Address, 'countryCode'>;

export type EntityRelation =
  | SessionHydrationOfficeRelationFieldsFragment
  | SessionHydrationAccountRelationFieldsFragment;

type GetAppValuationReportsItem =
  GetAppValuationReportsQuery['getAppValuationReports'][0];

export type ExtractedApp_PremiumValuationReport = Extract<
  NonNullable<GetAppValuationReportsItem>,
  { __typename: 'App_PremiumValuationReport' }
>;

export type ExtractedApp_ValuationReport = Extract<
  NonNullable<GetAppValuationReportsItem>,
  { __typename: 'App_ValuationReport' }
>;

export type HandledFlowActionFieldsFragment = Exclude<
  FlowActionFieldsFragment,
  $ExcludeByMissingKey<FlowActionFieldsFragment, 'id'>
>;

/**
 * Filters out all actions which we currently do not have a fragment for.
 * This is mainly to prevent our ts linter to throw errors when we add new
 * flow actions on the backend, but it also has the advantage of ensuring that we
 * filter out the currently not implemented flow actions on the frontend.
 */
export const extractHandledFlowActions = (
  actions: Array<FlowActionFieldsFragment>,
): Array<HandledFlowActionFieldsFragment> =>
  actions.filter(
    (action): action is HandledFlowActionFieldsFragment => 'id' in action,
  );

export type AppStatus = GetAppStatusesQuery['getAppStatuses'][number];

export type AppStatus__typename =
  GetAppStatusesQuery['getAppStatuses'][number]['__typename'];

/**
 * List of all handled Activities within the app.
 */
export type HandledActivityFieldsFragment =
  | ActivityFields_Event_Contact_Generic_Fragment
  | ActivityFields_Event_Contact_Call_Fragment
  | ActivityFields_Event_Contact_Note_Fragment
  | ActivityFields_Event_Contact_Email_Fragment
  | ActivityFields_Event_Contact_New_Fragment
  | ActivityFields_Event_Contact_App_ValuationReport_Fragment
  | ActivityFields_Event_Contact_App_ValuationRequest_Fragment
  | ActivityFields_Event_Contact_App_Funda_ViewingRequest_Fragment
  | ActivityFields_Event_Contact_App_Funda_ContactRequest_Fragment
  | ActivityFields_Event_Contact_App_Funda_BrochureRequest_Fragment
  | ActivityFields_Event_Contact_App_BbWaardecheck_Report_Fragment
  | ActivityFields_Event_Contact_App_BbWaardecheck_AppraisalRequest_Fragment
  | ActivityFields_Event_Contact_App_BbWaardecheck_ContactRequest_Fragment
  | ActivityFields_Event_Contact_App_VboWaardecheck_Report_Fragment
  | ActivityFields_Event_Contact_App_VboWaardecheck_AppraisalRequest_Fragment
  | ActivityFields_Event_Contact_App_VboWaardecheck_ContactRequest_Fragment
  | ActivityFields_Task_Contact_Fragment
  | ActivityFields_Event_Contact_App_Zapier_Fragment
  | ActivityFields_Event_Contact_App_ZapierTrigger_Fragment
  | ActivityFields_Event_Contact_App_Hypotheekbond_Fragment
  | ActivityFields_Event_Contact_App_ArxGroep_ContractCreated_Fragment
  | ActivityFields_Event_Contact_App_ArxGroep_TransactionCreated_Fragment
  | ActivityFields_Event_Contact_App_Trustoo_Fragment
  | ActivityFields_Event_Contact_App_TrustooSubmitted_Fragment
  | ActivityFields_Event_Contact_App_TrustooConversion_Fragment
  | ActivityFields_Event_Contact_App_WozComplaint_ComplaintCreated_Fragment
  | ActivityFields_Event_Contact_App_WozComplaint_ComplaintSuccess_Fragment
  | ActivityFields_Event_Contact_App_EnvenRequest_Fragment
  | ActivityFields_Event_Contact_App_EnvenTransaction_Fragment
  | ActivityFields_Event_Contact_App_SustainabilityReport_Fragment;

type UnHandledActivityFieldsFragment = Exclude<
  ActivityFieldsFragment,
  HandledActivityFieldsFragment
>;

/**
 * Filters out all activities which we currently do not handle.
 * This is mainly to prevent our ts linter to throw errors when we add new
 * flow activities on the backend, but it also has the advantage of ensuring that we
 * filter out the currently not implemented flow actions on the frontend.
 */
export const extractHandledActivities = (
  activities: Array<ActivityFieldsFragment>,
): Array<HandledActivityFieldsFragment> =>
  activities.filter((activity): activity is HandledActivityFieldsFragment => {
    switch (activity.__typename) {
      case 'Event_Contact_New':
      case 'Event_Contact_Call':
      case 'Event_Contact_Note':
      case 'Event_Contact_Email':
      case 'Task_Contact':
      case 'Event_Contact_App_ValuationReport':
      case 'Event_Contact_App_ValuationRequest':
      case 'Event_Contact_App_Funda_ContactRequest':
      case 'Event_Contact_App_Funda_ViewingRequest':
      case 'Event_Contact_App_Funda_BrochureRequest':
      case 'Event_Contact_App_BBWaardecheck_Report':
      case 'Event_Contact_App_BBWaardecheck_AppraisalRequest':
      case 'Event_Contact_App_BBWaardecheck_ContactRequest':
      case 'Event_Contact_App_VBOWaardecheck_Report':
      case 'Event_Contact_App_VBOWaardecheck_AppraisalRequest':
      case 'Event_Contact_App_VBOWaardecheck_ContactRequest':
      case 'Event_Contact_App_Zapier':
      case 'Event_Contact_App_ZapierTrigger':
      case 'Event_Contact_App_Hypotheekbond':
      case 'Event_Contact_App_ARXGroep_TransactionCreated':
      case 'Event_Contact_App_ARXGroep_ContractCreated':
      case 'Event_Contact_App_Trustoo':
      case 'Event_Contact_App_TrustooSubmitted':
      case 'Event_Contact_App_TrustooConversion':
      case 'Event_Contact_App_WOZComplaint_ComplaintCreated':
      case 'Event_Contact_App_WOZComplaint_ComplaintSuccess':
      case 'Event_Contact_App_EnvenRequest':
      case 'Event_Contact_App_EnvenTransaction':
      case 'Event_Contact_App_SustainabilityReport':
      case 'Event_Contact_Generic':
        assertType<HandledActivityFieldsFragment>(activity);
        return true;
      default: {
        assertType<UnHandledActivityFieldsFragment>(activity);
      }
    }

    return false;
  });

/**
 * List of all handled Activities within the app.
 */
export type HandledFlowConditionFieldsFragment =
  | FlowConditionFields_Flow_Condition_Contact_New_Fragment
  | FlowConditionFields_Flow_Condition_Contact_Details_Fragment
  | FlowConditionFields_Flow_Condition_Contact_Tag_Fragment
  | FlowConditionFields_Flow_Condition_Event_Email_LinkClicked_Fragment
  | FlowConditionFields_Flow_Condition_Event_Email_Opened_Fragment
  | FlowConditionFields_Flow_Condition_Time_Elapsed_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationReport_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationReport_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationRequest_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationRequest_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_ContactRequest_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_Report_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_Report_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_AppraisalRequest_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_AppraisalRequest_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_BbWaardecheck_ContactRequest_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Zapier_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Zapier_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_Report_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_Report_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_AppraisalRequest_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_AppraisalRequest_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_VboWaardecheck_ContactRequest_New_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Hypotheekbond_Details_Fragment
  | FlowConditionFields_Flow_Condition_Event_Contact_App_Hypotheekbond_New_Fragment;

type UnHandledFlowConditionFieldsFragment = Exclude<
  FlowConditionFieldsFragment,
  HandledFlowConditionFieldsFragment
>;

/**
 * Filters out all activities which we currently do not handle.
 * This is mainly to prevent our ts linter to throw errors when we add new
 * flow activities on the backend, but it also has the advantage of ensuring that we
 * filter out the currently not implemented flow actions on the frontend.
 */
export const extractHandledFlowConditions = (
  conditions: Array<FlowConditionFieldsFragment>,
): Array<HandledFlowConditionFieldsFragment> =>
  conditions.filter(
    (condition): condition is HandledFlowConditionFieldsFragment => {
      switch (condition.__typename) {
        case 'Flow_Condition_Contact_New':
        case 'Flow_Condition_Contact_Details':
        case 'Flow_Condition_Contact_Tag':
        case 'Flow_Condition_Event_Email_LinkClicked':
        case 'Flow_Condition_Event_Email_Opened':
        case 'Flow_Condition_Time_Elapsed':
        case 'Flow_Condition_Event_Contact_App_ValuationReport_New':
        case 'Flow_Condition_Event_Contact_App_ValuationReport_Details':
        case 'Flow_Condition_Event_Contact_App_ValuationRequest_New':
        case 'Flow_Condition_Event_Contact_App_ValuationRequest_Details':
        case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New':
        case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New':
        case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New':
        case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details':
        case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details':
        case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details':
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New':
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details':
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New':
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details':
        case 'Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New':
        case 'Flow_Condition_Event_Contact_App_Zapier_New':
        case 'Flow_Condition_Event_Contact_App_Zapier_Details':
        case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New':
        case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details':
        case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New':
        case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details':
        case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New':
        case 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details':
        case 'Flow_Condition_Event_Contact_App_Hypotheekbond_New':
          assertType<HandledFlowConditionFieldsFragment>(condition);
          return true;
        default: {
          assertType<UnHandledFlowConditionFieldsFragment>(condition);
        }
      }

      return false;
    },
  );

/**
 * Flow Builder V2
 */

export const HandledFlowActionMap: {
  [key in Exclude<FlowAction, FlowAction.Goal>]: key;
} = Object.freeze({
  Contact_Details: FlowAction.ContactDetails,
  Contact_Assign: FlowAction.ContactAssign,
  Contact_AddTag: FlowAction.ContactAddTag,
  Contact_DeleteTag: FlowAction.ContactDeleteTag,
  SendEmail_Plain: FlowAction.SendEmailPlain,
  Start: FlowAction.Start,
  Wait: FlowAction.Wait,
  Task_Create: FlowAction.TaskCreate,
  IfElse: FlowAction.IfElse,
  Zapier_Trigger: FlowAction.ZapierTrigger,
  Realworks_SendContact: FlowAction.RealworksSendContact,
});

export type HandledFlowAction = keyof typeof HandledFlowActionMap;

/** Used when an attachment is not a pointer attachment */
export type EmailFileAttachment = {
  __typename: 'FlowV2_EmailAttachment';
  file: {
    __typename: 'Flow___Argument_File';
    value_file: Flow___File;
  };
  inlineId?: string | null;
  url?: string;
};

export type Flow___PrimitiveArgument = Exclude<
  Flow___ArgumentFragment,
  | { __typename: 'Flow___Argument_Pointer' }
  | { __typename: 'Flow___Argument_File' }
>;

type ConditionalFlowActionAttributes = {
  connector: FlowConditionConnector;
  triggers: Array<Trigger>;
};

export type ClientFlowActionIfElse = FlowV2_Action_IfElseFragment &
  ConditionalFlowActionAttributes & {
    actionType: FlowAction.IfElse;
  };

export type ClientFlowActionStart = FlowV2_Action_StartFragment &
  ConditionalFlowActionAttributes & {
    actionType: FlowAction.Start;
  };

export type ClientFlowActionWait = FlowV2_Action_WaitFragment &
  ConditionalFlowActionAttributes & {
    actionType: FlowAction.Wait;
    metadataAction?: FlowV2_Update_Action | null;
  };

export type ConditionalFlowAction =
  | ClientFlowActionIfElse
  | ClientFlowActionWait
  | ClientFlowActionStart;

export type ClientFlowActionContactDetails =
  FlowV2_Action_Contact_DetailsFragment & {
    actionType: FlowAction.ContactDetails;
    field: FlowV2_Action_Contact_DetailsFragment['field'] & {
      nameValue?: Array<Descendant>;
      phoneValue?: Array<Descendant>;
    };
  };
export type ClientFlowActionContactAssign =
  FlowV2_Action_Contact_AssignFragment & {
    actionType: FlowAction.ContactAssign;
  };
export type ClientFlowActionContactAddTag =
  FlowV2_Action_Contact_AddTagFragment & {
    actionType: FlowAction.ContactAddTag;
  };
export type ClientFlowActionContactDeleteTag =
  FlowV2_Action_Contact_DeleteTagFragment & {
    actionType: FlowAction.ContactDeleteTag;
  };

export type ClientFlowActionSendEmailPlain =
  FlowV2_Action_SendEmail_PlainFragment & {
    actionType: FlowAction.SendEmailPlain;
    bodyValue?: Array<Descendant>;
    subjectValue?: Array<Descendant>;
  };
export type ClientFlowActionTaskCreate = FlowV2_Action_Task_CreateFragment & {
  actionType: FlowAction.TaskCreate;
  titleValue?: Array<Descendant>;
  descriptionValue?: Array<Descendant>;
};
export type ClientFlowActionZapierTrigger =
  FlowV2_Action_Zapier_TriggerFragment & {
    actionType: FlowAction.ZapierTrigger;
  };
export type ClientFlowActionRealworksSendContact =
  FlowV2_Action_Realworks_SendContactFragment & {
    actionType: FlowAction.RealworksSendContact;
    descriptionValue?: Array<Descendant>;
  };

export type ClientFlowAction = (
  | ConditionalFlowAction
  | ClientFlowActionContactDetails
  | ClientFlowActionContactAssign
  | ClientFlowActionContactAddTag
  | ClientFlowActionContactDeleteTag
  | ClientFlowActionSendEmailPlain
  | ClientFlowActionTaskCreate
  | ClientFlowActionZapierTrigger
  | ClientFlowActionRealworksSendContact
) & {
  label?: {
    letterIdx: number;
    number: number;
  };
  appSlug?: string | null;
};

export type BasePrimitive = string | number | boolean | null;

// Wizard
export type WizardFlow = {
  /**
   * Used to store specific predefined flows
   */
  id: string;
  /**
   * The steps this wizard includes
   */
  steps: Array<WizardStep>;
};
