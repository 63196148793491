import { FlowConditionAppZapierDetails } from './types.flow';
import { Flow_Condition__Input } from '~/graphql/types';

import { asConditionFieldInput } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition';
import { asEventParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';

const asFlowConditionAppZapierDetailsInput = (
  condition: FlowConditionAppZapierDetails,
): Array<Flow_Condition__Input> => {
  const { fields, event } = condition;

  const inputList = fields.map(field => ({
    EventContactApp: {
      Zapier: {
        Details: {
          condition: asConditionFieldInput(field),
          metadata: field.name.split('metadata.')[1],
          event: asEventParameterValueInput(event),
        },
      },
    },
  }));

  return inputList;
};

export default asFlowConditionAppZapierDetailsInput;
