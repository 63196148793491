import { omit } from 'ramda';
import { OutputMap } from '~/components/WizardSteps';
import {
  AppStatus_Hypotheekbond_Metadata__Input,
  AppStatus_Realworks_Metadata__Input,
  AppStatus_Simple_Metadata__Input,
  AppStatus_Trustoo_Metadata__Input,
  AppStatus_Zapier_Metadata__Input,
} from '~/graphql/types';
import { AppStatus } from '~/graphql/types.client';

export type AppTypeMetadataInput =
  | AppStatus_Zapier_Metadata__Input
  | AppStatus_Hypotheekbond_Metadata__Input
  | AppStatus_Trustoo_Metadata__Input
  | AppStatus_Simple_Metadata__Input
  | undefined;

const getMetadataForAppStatus = (
  appStatus: AppStatus,
  outputMap: OutputMap,
): AppTypeMetadataInput => {
  const addedFlows =
    'selectedTemplates' in outputMap.EnableFlowsForAppStatus
      ? outputMap.EnableFlowsForAppStatus.selectedTemplates.value
      : [];

  if (appStatus.__typename === 'AppStatus_Realworks') {
    const addedTokens: Array<string> = [];
    if (
      'relatieToken' in outputMap.RealworksImportContacts &&
      outputMap.RealworksImportContacts.relatieToken?.value
    ) {
      addedTokens.push(outputMap.RealworksImportContacts.relatieToken.value);
    }
    if (
      'wonenToken' in outputMap.RealworksExportContacts &&
      outputMap.RealworksExportContacts.wonenToken?.value
    ) {
      addedTokens.push(outputMap.RealworksExportContacts.wonenToken.value);
    }

    const metadata: AppStatus_Realworks_Metadata__Input = {
      setup: {
        ...omit(
          ['__typename'],
          appStatus.metadata?.setup ?? { completed: false },
        ),
        addedFlows,
        addedTokens,
      },
    };

    return metadata;
  }

  const metadata: AppTypeMetadataInput = {
    setup: {
      completed: false,
      ...omit(
        ['__typename'],
        ('metadata' in appStatus && appStatus.metadata?.setup) ?? {
          completed: false,
        },
      ),
      addedFlows,
    },
  };

  return metadata;
};

export default getMetadataForAppStatus;
