import React from 'react';

import Catalog from '~/Catalog';
import { GetFlowTemplateQuery } from '~/graphql';
import { getErrorTypes } from '~/util/errorHandling';
import ErrorTypes from '~/ErrorTypes';
import query from '~/graphql/query/GetFlowTemplate';
import { AppErrorScreen, Loading } from '~/components';
import cleanedFilename from '~/util/cleanedFilename';
import FlowBuilderController from './components/FlowBuilderController';
import { RouteComponentProps } from '@reach/router';
import useCurrentAccount from '~/hooks/useCurrentAccount';

const text = {
  flowListBreadcrumbLabel: Catalog.flowsLabel,
  newFlowName: 'Flow builder',
  unauthorisedUser: 'Je hebt onvoldoende rechten',
  noFlowFound: 'Geen flow gevonden',
  noTemplateFound: 'Geen template gevonden',
};

type Props = RouteComponentProps<{ templateId: string }>;
const FlowBuilder = ({ templateId }: Props) => {
  const account = useCurrentAccount();

  if (!templateId) {
    return <FlowBuilderController accountId={account.id} />;
  }

  const variables = {
    accountId: account.id,
    templateId: templateId ? templateId : '',
  };

  return (
    <GetFlowTemplateQuery
      name={cleanedFilename(__filename)}
      query={query}
      variables={variables}
    >
      {({ data, error, loading }) => {
        if (loading) return <Loading />;

        if (error != null || !data) {
          const errorTypes = getErrorTypes(error);

          if (errorTypes.includes(ErrorTypes.invalidFlowBlueprintId)) {
            return (
              <AppErrorScreen
                message={text.noFlowFound}
                errorType={ErrorTypes.invalidFlowBlueprintId}
              />
            );
          } else if (errorTypes.includes(ErrorTypes.unauthorisedError)) {
            return <AppErrorScreen message={text.unauthorisedUser} />;
          } else if (errorTypes.includes(ErrorTypes.notFoundError)) {
            return (
              <AppErrorScreen
                message={text.noTemplateFound}
                errorType={ErrorTypes.notFoundError}
              />
            );
          } else {
            return <AppErrorScreen />;
          }
        }

        const { getFlowTemplate } = data;

        const flow = { ...getFlowTemplate, enabled: true };

        return <FlowBuilderController flow={flow} accountId={account.id} />;
      }}
    </GetFlowTemplateQuery>
  );
};

export default FlowBuilder;
