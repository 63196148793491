import { NumberOperation } from './types.flow';

import { FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION } from './constants';
import { emptyNumberPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter';

const defaultNumberOperation = (): NumberOperation => ({
  type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.EQUALS,
  value: emptyNumberPrimitiveParameterValue(),
  negate: false,
});

export default defaultNumberOperation;
