import React from 'react';
import styled, { css } from 'styled-components';
import Button, { Props as ButtonProps } from '../Button';

const FullWidthButton: React.FC<ButtonProps> = ({ ...rest }) => (
  <FullButton {...rest} />
);

const FullButton = styled(Button)(
  ({ theme }) => css`
    width: 100%;
    justify-content: start;

    ${theme.mq.lessThan('tabletLandscape')`
      justify-content: center;
    `}
  `,
);

export default FullWidthButton;
