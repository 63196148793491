import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Catalog from '~/Catalog';
import Button from '~/components/Button';
import { TagList, InputGroup } from '~/components/Inputs';
import Dropdown from '~/components/Dropdown';
import useDropdown from '~/components/Inputs/Dropdown/useDropdown';
import JustificationContainer from '~/components/JustificationContainer';
import S3FileUpload, { UploadedFileDetails } from '~/components/S3FileUpload';
import TextButton from '~/components/TextButton';
import ToggleCheckbox from '~/components/ToggleCheckbox';
import { Body } from '~/components/Typography/index';
import {
  ImportContactsFileFormat,
  ImportContactsV2MutationVariables,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useUserOptions from '~/hooks/useUserOptions';
import cleanedFilename from '~/util/cleanedFilename';
import TEST_ID from './index.testid';
import { FileType } from '~/util/constants';
import CsvImportExplanation from './components/CsvImportExplanation';
import { reporter } from '~/hooks/useErrorReporter';

const text = {
  genericErrorMessage: Catalog.genericUnknownErrorMessageShort,
  importButtonLabel: 'Importeer',
  tagFieldLabel: 'Typ een tag en klik op de plusknop',
  officeFieldLabel: 'Wijs vestiging toe',
  userFieldLabel: 'Wijs gebruiker toe',
  noUser: 'Alle gebruikers',
  uploadButtonLabel: 'Upload bestand',
  fileTypeFieldLabel: 'Bestandtype',
  addTagsToExistingContacts: 'Tags ook toevoegen aan bestaande contacten',
  cancel: 'Annuleren',
  explanation: {
    [ImportContactsFileFormat.Realworks]:
      'Gebruik een XLS of XLSX bestand uit Realworks',
    [ImportContactsFileFormat.Csv]: <CsvImportExplanation />,
    [ImportContactsFileFormat.Accelerate]:
      'Gebruik een XLSX bestand uit Accelerate',
  },
};
export type Props = {
  dataTestId?: string;
  onClose?: () => void;
  allowedFileExtensions: Array<FileType>;
  loading: boolean;
  fileFormat: ImportContactsFileFormat;
  onImport: (
    variables: ImportContactsV2MutationVariables,
  ) => Promise<{ errors: Array<string> | null; success: boolean }>;
};

const ImportContactsContent: React.FC<Props> = ({
  onImport,
  allowedFileExtensions,
  loading,
  fileFormat,
  onClose,
}) => {
  const account = useCurrentAccount();
  const [
    shouldAddTagsForExistingContacts,
    setShouldAddTagsForExistingContacts,
  ] = useState<boolean>(true);
  const [uploadingFileDetails, setUploadingFileDetails] =
    useState<UploadedFileDetails | null>(null);

  const [tagList, setTagList] = useState<Array<string>>([]);

  const [error, setError] = useState<string | null>(null);
  const [importS3Key, setImportS3Key] = useState<string | null>(null);

  const [tagValue, setTagValue] = useState<string>('');

  useEffect(() => {
    if (isEmpty(tagValue) && error === Catalog.dirtyTagInputField) {
      setError(null);
    }
  }, [error, tagValue]);

  const officeOptions = useOfficeOptions();

  const [selectedOfficeIdx, onOfficeChange, selectedOffice] = useDropdown(
    officeOptions,
    0,
  );

  const userOptionsFromOffice = useUserOptions({
    officeId: selectedOffice?.id,
    withAllUsersOption: true,
  });

  const userOptions =
    selectedOffice == null
      ? [
          {
            label: text.noUser,
            payload: null,
            isEmptySelectionOption: true,
            key: 'no-selection',
          },
        ]
      : userOptionsFromOffice;

  const [selectedUserIdx, onUserChange, selectedUser, setUserSelectedIdx] =
    useDropdown(userOptions, 0);

  if (
    selectedUser != null &&
    userOptions.find(option => option.payload?.id == selectedUser.id) == null
  ) {
    setUserSelectedIdx(0);
  }

  if (selectedUser == null && selectedUserIdx !== 0) {
    // the selected option has dropped out of the list
    setUserSelectedIdx(0);
  }

  const canImport = importS3Key != null;

  const onImportContactsV2 = async () => {
    if (!isEmpty(tagValue)) {
      return setError(Catalog.dirtyTagInputField);
    }
    if (importS3Key == null) {
      reporter.captureMessage(
        `${cleanedFilename(__filename)} | Should not occur | s3key is null`,
        'critical',
      );

      return;
    }
    setError(null);

    const variables: ImportContactsV2MutationVariables = {
      accountId: account.id,
      fileLocation: importS3Key,
      fileType: fileFormat,
      officeId: selectedOffice == null ? null : selectedOffice.id,
      userId: selectedUser == null ? null : selectedUser.id,
      addTags: tagList,
      addTagsForExistingContacts: shouldAddTagsForExistingContacts,
    };

    try {
      const result = await onImport(variables);
      if (result.success) return;

      if (!result.errors) {
        setError(text.genericErrorMessage);
        return;
      }

      setError(result.errors.join('\n'));
    } catch (error) {
      setError(text.genericErrorMessage);
      setUploadingFileDetails(null);
      setImportS3Key(null);
    }
  };

  return (
    <>
      <JustificationContainer
        width="100%"
        margin={['m', null]}
        direction="column"
        align="center"
      >
        <JustificationContainer direction="column">
          <Body>{text.explanation[fileFormat] ?? ''}</Body>

          <StyledS3FileUpload
            fileDetails={uploadingFileDetails}
            allowedFileExtensions={allowedFileExtensions}
            setUploadingFile={details => setUploadingFileDetails(details)}
            onUploadSucceeded={({ key }) => setImportS3Key(key)}
            onFileRemoved={() => {
              setImportS3Key(null);
              setUploadingFileDetails(null);
              setError(null);
            }}
            uploadButtonLabel={text.uploadButtonLabel}
          />
        </JustificationContainer>

        <JustificationContainer
          width="100%"
          direction="column"
          gap="xxs"
          margin={['xs', null, null, null]}
        >
          <TagList
            tagValue={tagValue}
            onChange={setTagValue}
            label={text.tagFieldLabel}
            selectedValues={tagList}
            onAdded={newTag => {
              setTagList(prevList => [...prevList, newTag]);
            }}
            onRemoved={removedTag => {
              setTagList(prevList =>
                prevList.filter(tag => tag !== removedTag),
              );
            }}
          />
          <ToggleCheckbox
            dataTestId={TEST_ID.TAGS_FOR_EXISTING_CONTACTS_CHECKBOX}
            size="small"
            justification={{
              justification: 'space-between',
              direction: 'row',
              align: 'center',
              margin: [null, null, 's', null],
              width: '100%',
            }}
            value={shouldAddTagsForExistingContacts}
            label={text.addTagsToExistingContacts}
            onClick={() => setShouldAddTagsForExistingContacts(prev => !prev)}
          />
        </JustificationContainer>
        <StyledInputGroup>
          <Dropdown
            dataTestId={TEST_ID.OFFICE_FIELD}
            label={text.officeFieldLabel}
            options={officeOptions}
            selectedOptionIdx={selectedOfficeIdx}
            onChange={onOfficeChange}
          />
        </StyledInputGroup>
        <StyledInputGroup>
          <Dropdown
            dataTestId={TEST_ID.USER_FIELD}
            label={text.userFieldLabel}
            options={userOptions}
            selectedOptionIdx={selectedUserIdx}
            onChange={onUserChange}
          />
        </StyledInputGroup>
        {error != null && <ErrorText>{error}</ErrorText>}
      </JustificationContainer>
      <JustificationContainer width="100%" justification="space-between">
        <StyledCancelButton
          size="medium"
          appearance="danger"
          label={text.cancel}
          onClick={onClose}
        />
        <Button
          size="medium"
          disabled={!canImport}
          loading={loading}
          data-testid={TEST_ID.IMPORT_BUTTON}
          onClick={onImportContactsV2}
          label={text.importButtonLabel}
        />
      </JustificationContainer>
    </>
  );
};

const StyledCancelButton = styled(TextButton)`
  padding-left: 0;
`;

const ErrorText = styled.p<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);

// Inside a flex container there is not margin collapsing
const StyledInputGroup = styled(InputGroup)<{}>`
  margin-top: 0;
`;

const StyledS3FileUpload = styled(S3FileUpload)<{}>(
  ({ theme }) => css`
    margin: ${theme.space('m')} 0;
  `,
);

export default ImportContactsContent;
