import React from 'react';
import styled from 'styled-components';
import { FORM_ERROR } from 'final-form';
import Amplify from '~/amplify';

import { FormApi } from 'final-form';

import { Form, PasswordField } from '~/components/Forms';
import Validation from '~/util/Validation';
import { FormUtils, Drawer } from '~/components';
import Button from '~/components/Button';
import { Input, InputGroup } from '~/components/Inputs';
import withDelayedLoading, {
  WithDelayedLoading,
} from '~/components/util/withDelayedLoading';
import Catalog from '~/Catalog';
import ErrorTypes, { errortypeFromCognitoError } from '~/ErrorTypes';
import PasswordInputGroup from '~/scenes/Auth/components/PasswordInputGroup';

import TEST_ID from './ChangePasswordSidebar.testid';

type FormData = {
  oldPassword: string | null;
  password: string | null;
  passwordRepeat: string | null;
};

type MyProps = {
  toggleSidebar: (value: boolean) => void;
  active: boolean;
  user: any;
};
type Props = WithDelayedLoading & MyProps;

type State = {
  passwordConditions: {
    charNumberValid: boolean;
    uppercaseValid: boolean;
    lowercaseValid: boolean;
    specialCharValid: boolean;
    numberValid: boolean;
  };
};

const text = {
  screenTitle: 'Wijzig wachtwoord',
  wrongOldPassword: Catalog.wrongOldPassword,
  serverError: Catalog.genericUnknownErrorMessage,
  oldPassword: Catalog.oldPasswordLabel,
  newPassword: Catalog.newPasswordLabel,
  repeatPassword: Catalog.newPasswordRepeatLabel,
  emptyOldPassword: Catalog.noOldPassword,
  emptyPasswordError: Catalog.noPassword,
  emptyRepeatPassword: Catalog.noPasswordRepeat,
  passwordRepeatError: Catalog.passwordsDoNotMatch,
  unvalid: Catalog.invalidPassword,
  limitExceeded:
    'Er is te vaak een verkeerd wachtwoord ingevoerd. Uit veiligheidsredenen wordt het wijzigen van het wachtwoord tijdelijk geblokkeerd. Probeer het later nog eens.',
};
class ProfileSidebar extends React.Component<Props, State> {
  state: State = {
    passwordConditions: {
      charNumberValid: false,
      uppercaseValid: false,
      lowercaseValid: false,
      specialCharValid: false,
      numberValid: false,
    },
  };

  validate = ({ oldPassword, password, passwordRepeat }: FormData) => {
    const errors: {
      oldPassword: string | undefined;
      password: string | undefined;
      passwordRepeat: string | undefined;
    } = {
      oldPassword: undefined,
      password: undefined,
      passwordRepeat: undefined,
    };

    const didEnterOldPassword = Validation.String.isNonEmptyString(oldPassword);
    const didEnterPassword = Validation.String.isNonEmptyString(password);
    const didRepeatPassword =
      Validation.String.isNonEmptyString(passwordRepeat);
    if (!didEnterOldPassword) {
      errors.oldPassword = text.emptyOldPassword;
    }
    if (!didEnterPassword) {
      errors.password = text.emptyPasswordError;
    }
    if (!didRepeatPassword) {
      errors.passwordRepeat = text.emptyRepeatPassword;
    }

    if (didEnterPassword && didRepeatPassword && password !== passwordRepeat) {
      errors.passwordRepeat = text.passwordRepeatError;
    }

    const charNumberValid = password && password.length >= 8 ? true : false;
    const uppercaseValid =
      password && Validation.Password.containsUpperCaseLetter(password)
        ? true
        : false;
    const lowercaseValid =
      password && Validation.Password.containsLowerCaseLetter(password)
        ? true
        : false;
    const specialCharValid =
      password && Validation.Password.containsSpecialCharacter(password)
        ? true
        : false;
    const numberValid =
      password && Validation.Password.containsNumber(password) ? true : false;

    this.setState((state: State) => ({
      passwordConditions: {
        ...state.passwordConditions,
        charNumberValid,
        uppercaseValid,
        lowercaseValid,
        specialCharValid,
        numberValid,
      },
    }));

    if (
      !charNumberValid ||
      !uppercaseValid ||
      !lowercaseValid ||
      !specialCharValid ||
      !numberValid
    ) {
      errors.password = text.unvalid;
    }

    return errors;
  };

  onFormSubmit = (formData: FormData, form: FormApi<FormData>, callback) => {
    const { toggleSidebar } = this.props;
    this.props.setLoading();
    return Amplify.Auth.currentAuthenticatedUser().then(user =>
      Amplify.Auth.changePassword(
        user,
        formData.oldPassword || '',
        formData.password || '',
      )
        .then(() => {
          toggleSidebar(false);
          this.props.removeLoading();
          callback();
          const fieldNames = Object.keys(formData) as Array<
            keyof typeof formData
          >;
          fieldNames.forEach(name => {
            form.change(name, null);
            form.resetFieldState(name);
          });
        })
        .catch(err => {
          this.props.removeLoading();

          const errortype = errortypeFromCognitoError(err);
          if (errortype === ErrorTypes.notAuthorized) {
            callback({ [FORM_ERROR]: text.wrongOldPassword });
            return;
          } else if (errortype === ErrorTypes.limitExceeded) {
            callback({ [FORM_ERROR]: text.limitExceeded });
            return;
          } else if (errortype === ErrorTypes.invalidPassword) {
            callback({ [FORM_ERROR]: text.wrongOldPassword });
            return;
          }
          callback({
            [FORM_ERROR]: text.serverError,
          });
          return;
        }),
    );
  };

  closeBtnHandler = () => {
    this.props.toggleSidebar(false);
  };

  render() {
    const { active } = this.props;
    const title = text.screenTitle;
    return (
      <React.Fragment>
        <Drawer
          active={active}
          hideSidebar={this.closeBtnHandler}
          title={title}
        >
          <Form
            initialValues={{
              oldPassword: null,
              password: null,
              passwordRepeat: null,
            }}
            onSubmit={this.onFormSubmit}
            validate={this.validate}
          >
            {({
              handleSubmit,
              submitError,
              submitting,
              pristine,
              hasValidationErrors,
            }) => (
              <form
                id="change-pass-form"
                onSubmit={handleSubmit}
                data-testid={TEST_ID.CHANGE_PASS_FORM}
              >
                {submitError ? (
                  <ErrorMsg data-testid={TEST_ID.CHANGE_PASS_ERROR_MESSAGE}>
                    {submitError}
                  </ErrorMsg>
                ) : null}

                <InputGroup>
                  <PasswordField name="oldPassword">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        large
                        label={text.oldPassword}
                        type="password"
                        disabled={submitting}
                        error={FormUtils.showError(error, touched)}
                        data-testid={TEST_ID.FORM_OLD_PASS}
                        {...input}
                      />
                    )}
                  </PasswordField>
                </InputGroup>

                <PasswordInputGroup
                  passwordText={text.newPassword}
                  repeatText={text.repeatPassword}
                  passwordConditions={this.state.passwordConditions}
                  submitting={submitting}
                />

                <ActionWrapper>
                  <Button
                    size="medium"
                    loading={this.props.loading}
                    appearance="secondary"
                    type="submit"
                    disabled={
                      submitting ||
                      pristine ||
                      hasValidationErrors ||
                      this.props.loading
                    }
                    data-testid={TEST_ID.SUBMIT_PASSWORD_BUTTON}
                    label="Wijzig"
                  />
                </ActionWrapper>
              </form>
            )}
          </Form>
        </Drawer>
      </React.Fragment>
    );
  }
}

export const ErrorMsg = styled.div<{}>`
  ${({ theme }) => `
    color: ${theme.color('danger')};
  `};
`;

export const PasswordRulesText = styled.p<{}>`
  font-style: italic;

  ${({ theme }) => `
    font-size: ${theme.fontSize('s')};

    .invalid {
      color: ${theme.color('danger')};
    }

    .valid {
      color: ${theme.color('success')};
    }
  `};
`;

export const ActionWrapper = styled.div<{}>`
  display: flex;
  align-items: center;

  & button {
    margin-left: auto;
  }
`;

export default withDelayedLoading<MyProps>(ProfileSidebar);
