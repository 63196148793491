import { Flow_Condition__Input } from '~/graphql/types';

const asFlowConditionAppBBWaardecheckReportNewInput =
  (): Flow_Condition__Input => ({
    EventContactApp: {
      BBWaardecheck: {
        Report: {
          New: {
            noOperation: true,
          },
        },
      },
    },
  });

export default asFlowConditionAppBBWaardecheckReportNewInput;
