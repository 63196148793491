import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';

import {
  format,
  parse,
  convertDateToServerDateString,
  convertServerDateStringToDate,
  asUTCNoon,
} from '../../util/date';
import Input from './Input';
import { Container } from '~/components/Forms/Form.style';
import Icon from '../Icon';

/**
 * The input and output of the date picker is an ISO string.
 * This is also what needs to be sent back to the server. We convert to Date only when needed.
 */
export type DateInputComponentType = 'DEFAULT' | 'BORDERLESS';
type Props = {
  /** The name to give to the input */
  name: string;

  /** The date value as ISO string */
  value: string;

  /** Called when the date is changed, gives the date as an ISO string */
  onChange: (val: string | null) => any;

  /** The component that renders the input */
  inputComponentType?: DateInputComponentType;

  /** Props to pass to the inputComponent */
  inputComponentProps?: $Object;

  /** Provided by styled-component to style the input */
  className?: string;
};
type State = {};
class DatePicker extends Component<Props, State> {
  /**
   * Pass the new date object form our widget back to the onChange function.
   * We always send back the date with 12:00:00.000 as the time. This is to
   * ensure that in all time zones between -12 and +12 the date is the same.
   *
   * We ignore the few islands that have +13, +14 for now.
   */
  passDateBack = (date: Date | null | undefined) => {
    const { onChange } = this.props;

    if (date === null || date === undefined) {
      onChange(null);
    } else {
      const convertedDate = asUTCNoon(date);
      onChange(convertDateToServerDateString(convertedDate));
    }
  };

  render() {
    const {
      // XXX: If you get an error here with the onchange,
      // This has been changed in the typescript conversion
      // Check those files
      // https://gitlab.dathuis.nl/dh/sfa-app/-/blob/7c5ab2ea1405589ddc99082b0b748c5d1550cc62/src/components/Inputs/DatePicker.js
      // https://gitlab.dathuis.nl/dh/sfa-app/-/blob/7c5ab2ea1405589ddc99082b0b748c5d1550cc62/src/components/DatePicker.js
      // ignore here, we use the passDateBack function
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      value,
      inputComponentType = 'DEFAULT',
      inputComponentProps,
      name,
      ...rest
    } = this.props;

    const inputProps = { ...inputComponentProps, name, inputComponentType };
    const convertedDate = convertServerDateStringToDate(value);

    // The input is not a controlled component, so we can not send null to it (even via our DayPickerInput).
    const valueAsDate = convertedDate === null ? '' : convertedDate;

    return (
      <Container>
        <DayPickerInput
          {...rest}
          format={FORMAT}
          formatDate={dayPickerFormat}
          parseDate={dayPickerParse}
          placeholder={
            isDefaultComponent(inputComponentType) ? '' : 'dd-mm-jjjj'
          }
          onDayChange={this.passDateBack}
          value={valueAsDate}
          inputProps={inputProps}
          component={DateInputFieldClass}
        />
        <Icon name="calendar" />
      </Container>
    );
  }
}

const isDefaultComponent = (
  inputComponentType?: DateInputComponentType,
): boolean => !inputComponentType || inputComponentType === 'DEFAULT';

export default styled(DatePicker)<Props>`
  ${({ inputComponentType }) => {
    if (isDefaultComponent(inputComponentType))
      return css`
        width: 100%;
      `;

    return null;
  }};
`;

/**
 * Parse: HTML input => DayPickerInput component
 */
const dayPickerParse = (str: string, formatStr: string): Date | void => {
  if (str === null) return;

  /**
   * Only start converting when the date is completely filled in.
   * Problem would be that 201 as year would be seen as the year 0201
   * and the value would change to xx-xx-0201 while the user is typing
   */
  const tokens = str.split(/-|\/|\\/);
  if (tokens.length !== 3) return;

  if (tokens[2].length !== 4) return;

  const parsed = parse(str, formatStr);
  if (DateUtils.isDate(parsed)) {
    return asUTCNoon(parsed);
  }
  return;
};

/**
 * Format: DayPickerInput => HTML input
 */
export const dayPickerFormat = (
  date: Date | null | undefined,
  formatStr: string,
): string => {
  if (!date) return '';
  return format(date, formatStr);
};

export const FORMAT = 'd-M-yyyy';

/**
 * There is a problem with ref warnings when using functional components in the component prop of DayPickerInput:
 * https://github.com/gpbl/react-day-picker/issues/748
 *
 * Workaround for now is to use a full class component, @rob is following the issue
 *
 * Update 26-10-2021:
 * The issue seems to be closed and resolved we can remove this in time
 */
class DateInputFieldClass extends Component<Props, State> {
  render() {
    const { value, onChange, inputComponentType, ...rest } = this.props;
    const isDefault = isDefaultComponent(inputComponentType);

    if (isDefault)
      return <Input size="10" value={value} onChange={onChange} {...rest} />;
    return (
      // @ts-expect-error onChanges don't match but component seems to work as expected So not screwing with it now.
      <DateInputField size={10} value={value} onChange={onChange} {...rest} />
    );
  }
}

const DateInputField = styled.input<{
  onChange?: (arg0: React.SyntheticEvent<any> | any) => void;
}>`
  border: 0;
  text-align: center;
  ${({ theme }) => css`
    margin: 0 ${theme.space('s')};
    color: ${theme.color('primary', 'light')};
  `};
`;
