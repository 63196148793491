import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Dropdown, { SelectedOptionOf } from '~/components/Dropdown';
import Input from '~/components/Input';
import Link from '~/components/Link';
import TipBanner from '~/components/TipBanner';
import ChatLink from '~/components/TipBanner/ChatLink';
import { Body } from '~/components/Typography/index';
import {
  OutputFieldItem,
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/Wizard/context/WizardContext';
import { OutputMap } from '~/components/WizardSteps';
import {
  AppStatus_Trustoo_Token__Input,
  Office,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useWizardStep from '~/hooks/useWizardStep';
import formatToastMessage from '~/util/formatToastMessage';
import { isNonEmptyString } from '~/util/Validation/String';
import TEST_ID from './index.testid';
import useApp from '~/hooks/useApp';
import getWidgetAppConfigurationUpdate from '~/util/getWidgetAppConfigurationUpdate';
import Loading from '~/components/Loading';
import useWizard from '~/hooks/useWizard';
import SkipStepButtonBlock from '~/components/WizardSteps/components/SkipStepButtonBlock';

export const id = 'AddTrustooKoppeling';
export const title = 'Leads ontvangen van trustoo.nl (optioneel)';

export type OutputType = {
  type: typeof id;
  trustooTokens: OutputFieldItem;
  trustooOfficeId: OutputFieldInvisible<
    AppStatus_Trustoo_Token__Input['token']
  >;
};

export const text = {
  category: 'Token toegevoegd',
  tokenPlaceholder: 'Trustoo token invoeren',
  officeLabel: 'Eigenaar van dit Trustoo account',
  formHeader: 'Trustoo koppeling toevoegen',
  emptyFieldError: 'Veld mag niet leeg zijn',
  skipButtonText: 'Heb je nog geen account bij Trustoo.nl?',
  addTokenDescription: (
    <>
      Ben je al klant van Trustoo en ontvang je leads van trustoo.nl? Koppel dan
      hier je Trustoo account en volg automatisch nieuwe Trustoo leads op met
      marketing automation.
      <br />
      <br />
      Koppel je Trustoo account met behulp van de token.{' '}
      <Link target="_blank" to="https://trustoo.nl/login/">
        Deze vind je in jouw Trustoo account.
      </Link>
    </>
  ),
  errorMessage:
    'Er is iets misgegaan tijdens het toevoegen van de token. Zorg ervoor dat je een valide token invoert.',
  successfullyAdded: 'Token is opgeslagen',
  addMoreTokens: 'Voeg meer tokens toe',
  tip: (
    <>
      Kom je er niet uit? Lees de volledige instructie in{' '}
      <Link to="https://help.dathuis.nl/nl/articles/6435229-over-de-trustoo-app">
        onze kennisbank
      </Link>{' '}
      of <ChatLink linkText="start een chat met ons." />
    </>
  ),
};

export const Component: React.FC<WizardStepProps> = ({ step, outputMap }) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const wizardApi = useWizard();
  const [currentOutput, setCurrentOutput] = useState(
    outputMap[id] as OutputType,
  );
  const { app, loading } = useApp(step.metadata.typename);
  const tokenId = currentOutput.trustooTokens.value;
  const officeOptions = useOfficeOptions();
  const officeId = !isEmpty(currentOutput.trustooOfficeId.value)
    ? currentOutput.trustooOfficeId.value
    : officeOptions[0]?.payload?.id ?? '';

  const [updateAppStatus] = useUpdateAppStatusMutation();
  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const updatedOutput = outputMap[id] as OutputType;

      const token = updatedOutput.trustooTokens.value;
      const office = !isEmpty(updatedOutput.trustooOfficeId.value)
        ? updatedOutput.trustooOfficeId.value
        : officeId;

      if (token && office) {
        await updateAppStatus({
          variables: {
            accountId,
            update: {
              AppStatus_Trustoo: {
                ...getWidgetAppConfigurationUpdate(app?.appStatus),
                enabled: true,
                tokens: [{ token, officeId: office }],
                metadata: {
                  setup: {
                    addedTokens: [token],
                    completed: false,
                  },
                },
              },
            },
          },
        }).then(({ data, errors }) => {
          if (!data && errors?.length) {
            return addToast([formatToastMessage(text.errorMessage, 'danger')]);
          } else {
            return addToast([
              formatToastMessage(text.successfullyAdded, 'success'),
            ]);
          }
        });
      }

      const output: OutputType = {
        ...updatedOutput,
        trustooTokens: {
          category: text.category,
          type: 'item',
          label: token,
          value: token,
        },
        trustooOfficeId: {
          type: 'invisible',
          value: office,
        },
      };

      return output;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [officeId, updateAppStatus, accountId],
  );

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );

  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    api.free(currentOutput);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOutput]);

  if (loading) return <Loading />;

  return (
    <>
      <Body margin={[null, null, 'xxs', null]}>{text.addTokenDescription}</Body>

      <SkipStepButtonBlock
        description={text.skipButtonText}
        skipCurrentStep={() => wizardApi.next()}
        margin={['m', null]}
      />
      <TipBanner
        id="trustoo-add-token"
        headerText="Hulp nodig?"
        margin={['base', null, 'm', null]}
      >
        {text.tip}
      </TipBanner>

      <StyledInput
        dataTestId={TEST_ID.ADD_TOKEN_INPUT}
        label={text.tokenPlaceholder}
        placeholder={text.tokenPlaceholder}
        value={tokenId}
        onChange={e => {
          const value = e.target.value;
          setCurrentOutput(prevOutput => ({
            ...prevOutput,
            trustooTokens: {
              value: value,
              type: 'item',
              category: text.category,
              label: value,
            },
          }));
        }}
        validation={[
          (value: string) =>
            isNonEmptyString(value) ? true : text.emptyFieldError,
        ]}
        name="token"
      />
      <OfficeLabel>{text.officeLabel}</OfficeLabel>
      <Dropdown
        options={officeOptions}
        selectedOptionIdx={officeOptions.findIndex(
          office => office.payload?.id === officeId,
        )}
        onChange={({ option }: SelectedOptionOf<Office>) => {
          const value = option.payload.id;
          setCurrentOutput(prevOutput => ({
            ...prevOutput,
            trustooOfficeId: {
              value: value,
              type: 'invisible',
            },
          }));
        }}
      />
    </>
  );
};

export const OfficeLabel = styled.span<{}>`
  ${({ theme }) => css`
    display: inline-block;
    font-weight: ${theme.fw('semiBold')};
    font-size: ${theme.fs('base')};
    margin-left: ${theme.space('xxxs')};
    margin-bottom: ${theme.space('xxxs')};
  `};
`;

const StyledInput = styled(Input)<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `,
);

export default {
  id,
  title,
};
