/**
 * Return date range options until the end date
 * @param {Date} end - Until this date
 * keywords: dateRangeOptions
 */
import { sub } from 'date-fns';
import { OptionOf } from '~/components/Inputs/Dropdown';
import { GetEmailEffectivenessGroupedByFlowBlueprintQueryVariables } from '~/graphql/types';

const getDateRangeOptionsWithEndDate = (
  end: Date,
): Array<
  OptionOf<{
    start: GetEmailEffectivenessGroupedByFlowBlueprintQueryVariables['start'];
    end: GetEmailEffectivenessGroupedByFlowBlueprintQueryVariables['end'];
  }>
> => [
  {
    key: 'last-90-days',
    label: 'Afgelopen 90 dagen',
    payload: {
      end: end.toISOString(),
      start: sub(end, { days: 90 }).toISOString(),
    },
  },
  {
    key: 'last-two-week',
    label: 'Afgelopen 14 dagen',
    payload: {
      end: end.toISOString(),
      start: sub(end, { days: 14 }).toISOString(),
    },
  },
  {
    key: 'last-week',
    label: 'Afgelopen week',
    payload: {
      end: end.toISOString(),
      start: sub(end, { weeks: 1 }).toISOString(),
    },
  },
];
export default getDateRangeOptionsWithEndDate;
