import { sortBy } from 'lodash';

import { TaskListTaskFieldsFragment } from '~/graphql/types';

import TASK_LIST_TYPE, { TaskListType } from '~/scenes/Tasks/util/taskListType';
import { reportErrorToTracker } from '~/util/assertion';

/**
 * This should be the same as the sorting on server side. Which is:
 *
 * When state === Open -> sort on dueDate with the lowest date first
 * When state === Closed or Deleted -> sort on closedDate with the highest date first
 *
 * In other words, when we are looking at open tasks we want the ones due first to be first.
 * When we are looking at closed or deleted tasks we want the most recently closed/deleted to be first.
 */
export const sortTaskList = (
  taskList: Array<TaskListTaskFieldsFragment>,
  status: TaskListType,
): Array<TaskListTaskFieldsFragment> => {
  switch (status) {
    case TASK_LIST_TYPE.OPEN:
      return sortBy(taskList, task => task.dueDate);
    case TASK_LIST_TYPE.CLOSED:
      return sortBy(taskList, task => task.closedDate).reverse();
    case TASK_LIST_TYPE.DELETED:
      return sortBy(taskList, task => task.deletedDate).reverse();
    default:
      reportErrorToTracker(new Error(`Unknown status: ${status} to sort on!`));
      return taskList;
  }
};
