import {
  DeleteContactTagAction,
  InexactDeleteContactTagActionFieldsFragment,
} from './types.flow';
import { BaseActionProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { asStringPrimitiveParameterValueForValue } from '../Base/FlowParameter/ParameterValue/String/asStringPrimitiveParameterValue';

export default (
  item: InexactDeleteContactTagActionFieldsFragment,
  baseProps: BaseActionProps,
): DeleteContactTagAction => {
  const { id, name } = item;

  return {
    ...baseProps,
    type: FLOW_ACTION_TYPE.DELETE_CONTACT_TAG,
    id,
    name: asStringPrimitiveParameterValueForValue(
      name == null ? null : name.value,
    ),
  };
};
