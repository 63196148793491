import React, { useState } from 'react';
import {
  EventContactEmail_New,
  TaskStatus,
  useInsertEmailMutation,
} from '~/graphql/types';
import { NamedEmail } from '~/util/namedEmailFormatter';
import TaskModalContext from '~/scenes/Tasks/components/TaskModal/TaskModalContext';
import EmailInputFields from './components/EmailInputFields';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Catalog from '~/Catalog';
import getInitialEmailValue from './utils/getInitialEmailValue';
import { GetFullEmailMessage } from '../FullEmailMessage';

const text = {
  replyMessageText: (
    date: Date | null,
    email: string,
    name: string | null | undefined,
  ) => {
    const endName = name == null ? `(${email})` : `${name} &lt;${email}&gt;`;

    if (date) {
      return `Op ${date.toLocaleString()} heeft ${endName} het volgende geschreven:`;
    } else {
      return `${endName} heeft het volgende geschreven:`;
    }
  },
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
};

export type EmailProps = {
  readOnly?: boolean;
  namedEmail?: NamedEmail;
};

export type EmailListProps = {
  readOnly?: boolean;
  namedEmailList?: Array<NamedEmail>;
};

export type TextProps = {
  readOnly?: boolean;
  text?: string;
};

export type PassthroughProps = {
  sender?: EmailProps;
  receiver: NamedEmail;
  cc?: EmailListProps;
  bcc?: EmailListProps;
  subject?: TextProps;
  body?: TextProps;

  /** called whenever the height of the component changes to help the animation component recalculate */
  onHeightChange: () => void;
};
type Props = PassthroughProps & {
  /** called when the e-mail has been sent successfully */
  onSuccess?: () => void;

  /** Needed for the mutation */
  contactId: string;

  /** Function for close modal if its no timeline */
  handleCloseModal?: () => void;

  /** If the component should be in the loading state */
  loading?: boolean;

  /** Shows error message. Usually a mutation error */
  error?: boolean;

  /** If replying to an email give the activity id, from and when the mail was received of that e-mail. The component will grab the full message and add it to the body */
  replyTo?: {
    eventId: string;
    from: NamedEmail;
    receivedAt: Date | null;
  };

  /** Function to set if needed to change task status or close a modal */
  setAfterSendActions: (arg0: {
    allowUpdateStatus: boolean;
    allowClose: boolean;
  }) => void;
};
const SendEmailBlock = ({
  onSuccess,
  handleCloseModal,
  contactId,
  replyTo,
  body,
  setAfterSendActions,
  loading = false,
  error = false,
  ...rest
}: Props) => {
  const [hasError, setError] = useState(false);
  const account = useCurrentAccount();
  const addToast = useAddToast();

  const [insertEmail, { loading: insertEmailLoading }] =
    useInsertEmailMutation();

  const emailComponent = (appendBody: string) => {
    const fullBody = getInitialEmailValue(body?.text, appendBody);

    return (
      <TaskModalContext.Consumer>
        {({ taskDetails }) => {
          const isCompleted = taskDetails
            ? taskDetails.status === TaskStatus.Closed
            : false;
          return (
            <EmailInputFields
              {...rest}
              body={{ readOnly: body?.readOnly, text: fullBody }}
              hasError={error || hasError}
              canClose={handleCloseModal != null}
              loading={insertEmailLoading || loading}
              isCompleted={isCompleted}
              setAfterSendActions={setAfterSendActions}
              onSendPressed={(
                sendEmailBlockVariables: EmailInputFieldsVariables,
              ) => {
                setError(false);

                const variables = {
                  accountId: account.id,
                  email: {
                    ...sendEmailBlockVariables,
                    contactId,
                  },
                };

                return insertEmail({
                  variables,
                }).then(({ errors, data }) => {
                  if (errors && errors.length > 0) {
                    setError(true);
                    return addToast([
                      formatToastMessage(text.genericErrorMessage, 'danger'),
                    ]);
                  }
                  if (data && onSuccess) {
                    return onSuccess();
                  }
                  return;
                });
              }}
            />
          );
        }}
      </TaskModalContext.Consumer>
    );
  };

  if (replyTo) {
    return (
      <GetFullEmailMessage
        eventId={replyTo.eventId}
        renderProp={fullBody =>
          emailComponent(
            `${text.replyMessageText(
              replyTo.receivedAt,
              replyTo.from.email,
              replyTo.from.name,
            )}<blockquote>${fullBody}</blockquote>`,
          )
        }
      />
    );
  } else {
    return emailComponent('');
  }
};

export type EmailInputFieldsVariables = Omit<
  EventContactEmail_New,
  'contactId'
>;

export default SendEmailBlock;
