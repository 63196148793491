import cleanedFilename from '~/util/cleanedFilename';
import { isSafari } from './browserDetection';

/**
 * This code can be loaded outside of a browser, so we lazy initialize this
 */
let hiddenDiv;

/**
 * Gets the inner text for the given html.
 */
export const getInnerText = (html: string): string => {
  if (hiddenDiv == null) {
    hiddenDiv = document.getElementById('hidden-div');
    // Does this make an issue ? If not delete the old code
    // process.env.IS_TEST === true
    //   ? document.createElement('div')
    //   : document.getElementById('hidden-div');
  }

  if (!(hiddenDiv instanceof HTMLDivElement)) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} -> divManipulators cannot find the hidden div element in the dom`,
    );
  }

  hiddenDiv.innerHTML = html;

  if (isSafari()) {
    // safari is smarter and sees that our div is not visible
    hiddenDiv.style.removeProperty('height');
    hiddenDiv.style.removeProperty('overflow');
  }

  // We use innertext as we have to keep the <br /> tags in it
  // https://kellegous.com/j/2013/02/27/innertext-vs-textcontent/
  const text = hiddenDiv.innerText;

  if (isSafari()) {
    // put the hidden stuff back
    hiddenDiv.style.height = '0';
    hiddenDiv.style.overflow = 'hidden';
  }

  if (text == null) {
    throw Error(
      `${cleanedFilename(__filename)} -> getInnertText could not get text`,
    );
  }

  return text;
};
