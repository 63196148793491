import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { Plan, BillingCycle } from '~/graphql/types';

import { formattedDateWithFullMonth } from '~/util/date';
import DetailsBlock from '~/components/DetailsBlock';
import Button from '~/components/Button';
import mutation from '~/graphql/mutation/UpdateSubscription';
import { UpdateSubscriptionMutation } from '~/graphql/Mutation';
import AccountContext from '~/contexts/AccountContext';
import {
  subscriptionPlanLabel,
  billingCycleWithSubscriptionLabel,
} from '../../util/pricingLabelHelpers';

import TEST_ID from './index.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';

const text = {
  buttonLabel: 'Heractiveer',
  messageStart: (currentPlan: Plan, currentBillingCycle: BillingCycle) =>
    `Het ${subscriptionPlanLabel(
      currentPlan,
    )} ${billingCycleWithSubscriptionLabel(
      currentBillingCycle,
    )} is opgezegd. Tot `,
  messageEnd: ' kan je gebruik blijven maken van DatHuis.',
  errorMsg:
    'Er is iets misgegaan bij het heractiveren van je abonnement. Probeer het nog een keer of neem contact op met ons.',
};
type Props = {
  currentPlan: Plan;
  currentBillingCycle: BillingCycle;
  cancellingDate: Date;
  onSuccess: () => void;
};
const ReactivateSubscriptionBanner = ({
  currentPlan,
  currentBillingCycle,
  cancellingDate,
  onSuccess,
}: Props) => {
  const { refetchSessionHydration } = useContext(AccountContext);
  const account = useCurrentAccount();

  return (
    <UpdateSubscriptionMutation
      name={__filename}
      mutation={mutation}
      onCompleted={() => refetchSessionHydration().then(() => onSuccess())}
    >
      {(updateSubscription, { loading, error }) => {
        let textComponent = (
          <TextContainer>
            {text.messageStart(currentPlan, currentBillingCycle)}
            <DateLabel>{formattedDateWithFullMonth(cancellingDate)}</DateLabel>
            {text.messageEnd}
          </TextContainer>
        );

        if (error) {
          textComponent = <ErrorMessage>{text.errorMsg}</ErrorMessage>;
        }

        return (
          <Container manualLayout dataTestid={TEST_ID.CONTAINER}>
            {textComponent}
            <ButtonContainer>
              <Button
                data-testid={TEST_ID.REACTIVATE_BUTTON}
                appearance="secondary"
                size="medium"
                loading={loading}
                onClick={() =>
                  updateSubscription({
                    variables: {
                      accountId: account.id,
                      plan: currentPlan,
                      billingCycle: currentBillingCycle,
                    },
                  })
                }
                label={text.buttonLabel}
              />
            </ButtonContainer>
          </Container>
        );
      }}
    </UpdateSubscriptionMutation>
  );
};

const Container = styled(DetailsBlock)<{}>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('primary', 'light')};
  `};
`;

const DateLabel = styled.span<{}>`
  font-weight: bold;
`;

const ErrorMessage = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);
const TextContainer = styled.div<{}>``;
const ButtonContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('s')};
  `,
);

export default ReactivateSubscriptionBanner;
