import React from 'react';

import type { AppStatus__Input } from '~/graphql/types';
import getAppStatusUpdate from '../../utils/getAppStatusUpdate';
import AppSetupModal, {
  Props as AppSetupModalProps,
} from '~/components/AppSetupModal';
import { AppsHashMap } from '~/hooks/useApps';
import AppSetupModalWithOAuthFlow from './components/AppSetupModalWithOAuthFlow';
import { AppStatus__typename } from '~/graphql/types.client';

export type Props = {
  apps: AppsHashMap;
  updateAppStatus: (args: {
    type: AppStatus__typename;
    update: AppStatus__Input;
  }) => Promise<void>;
  onClose: () => void;
};

const AppModals: React.FC<Props> = ({ updateAppStatus, apps, onClose }) => (
  <>
    {Object.keys(apps).map(appKey => {
      const app = apps[appKey];
      if (!app) return null;

      const onChangeAppState = () => {
        const update = getAppStatusUpdate(app);
        return updateAppStatus({ type: app.__typename, update });
      };

      const props: AppSetupModalProps = {
        onClose,
        app,
        onChangeAppState,
        path: `setup/${app.slug}`,
      };

      if (app.setupFlow === 'oAuth') {
        return <AppSetupModalWithOAuthFlow key={app.__typename} {...props} />;
      }

      return <AppSetupModal key={app.__typename} {...props} />;
    })}
  </>
);

export default AppModals;
