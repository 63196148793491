import React from 'react';
import styled from 'styled-components';
import EditableName from '~/components/DetailsPage/EditableName';

type Props = {
  /** Handling function to call change name mutation */
  updateReportName: (name: string) => void;

  /** Handler to show preview mod */
  switchPreviewModeOn: () => void;

  /** Text name value */
  name: string;

  /** Show is preview mode active */
  isPreviewModeOn: boolean;
};

class Header extends React.Component<Props, {}> {
  shouldComponentUpdate(nextProps: Props) {
    const { isPreviewModeOn, name } = this.props;
    if (
      nextProps.isPreviewModeOn !== isPreviewModeOn ||
      nextProps.name !== name
    ) {
      return true;
    }
    return false;
  }
  render() {
    const { updateReportName, name } = this.props;

    return (
      <HeaderElement>
        <div>
          <EditableName edit={true} updateName={updateReportName} name={name} />
        </div>
      </HeaderElement>
    );
  }
}

const HeaderElement = styled.div<{}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default Header;
