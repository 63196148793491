import React from 'react';

import { TableCell } from '~/components/DataTables/types.flow';

import { MarkContactForDeletionMutation } from '~/graphql/Mutation';
import mutation from '~/graphql/mutation/MarkContactForDeletion';
import ActionColumnCell from '~/components/DataTables/components/ActionColumnCell';
import markedForDeletionContactList from '~/scenes/Contacts/util/markedForDeletionContactList';
import useErrorModal from '~/components/Alerts/useErrorModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useConfirmModal from '~/components/Alerts/useConfirmModal';
import TEST_ID from './index.testid';

const text = {
  deleteLabel: 'Verwijderen',
  deleteErrorTitle: 'Oeps!',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van het contact. Probeer het nog eens.',
  confirmModalCancelButtonText: 'Annuleren',
  confirmDeleteTitle: 'Contact verwijderen?',
  confirmDeleteMessage: 'Weet je zeker dat je dit contact wilt verwijderen?',
  confirmDeleteButton: 'Verwijderen',
};
const confirmDeleteModalText = {
  title: text.confirmDeleteTitle,
  message: text.confirmDeleteMessage,
  buttonConfirmTitle: text.confirmDeleteButton,
  buttonCancelTitle: text.confirmModalCancelButtonText,
};

type Props = {
  contactId: string;
  cell: TableCell<any>;
  onSuccessfulDelete: () => void;
};
const ContactActionColumnCell = ({
  contactId,
  cell,
  onSuccessfulDelete,
}: Props) => {
  const [errorModal, showErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.deleteErrorMessage,
  });
  const account = useCurrentAccount();

  const [showDeleteConfirmModal, setOnDeleteConfirmFunction] = useConfirmModal(
    confirmDeleteModalText,
    () => {},
    () => {},
    TEST_ID.CONFIRM_MODAL,
    false,
    'destructive',
  );

  return (
    <MarkContactForDeletionMutation
      name={__filename}
      mutation={mutation}
      onError={() => {
        showErrorModal();
      }}
    >
      {(markContactForDeletion, { loading }) => (
        <>
          <ActionColumnCell
            key={'contact-action-column-cell'}
            loading={loading}
            options={[
              {
                label: text.deleteLabel,
                onClick: async () => {
                  const deleteFn = async () => {
                    const mutationResult = await markContactForDeletion({
                      variables: {
                        accountId: account.id,
                        id: contactId,
                      },
                    });
                    if (isErroredDeletionResult(mutationResult)) {
                      showErrorModal();
                    } else {
                      markedForDeletionContactList.writeMarkedForDeletionContact(
                        contactId,
                      );

                      onSuccessfulDelete();
                    }
                  };

                  setOnDeleteConfirmFunction(deleteFn);
                  showDeleteConfirmModal();
                },
                key: 'delete',
                type: 'DELETE',
              },
            ]}
            cell={cell}
          />
          {errorModal}
        </>
      )}
    </MarkContactForDeletionMutation>
  );
};

const isErroredDeletionResult = mutationResult =>
  !mutationResult ||
  !mutationResult.data ||
  mutationResult.data.markContactForDeletion.success !== true;

export default ContactActionColumnCell;
