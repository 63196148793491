import React from 'react';

import { FlowConditionTimeElapsed } from './types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { InputGroup } from '~/components/Inputs';
import {
  NumberPrimitiveParameterValueComponent,
  isNonZeroNumberParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';

const text = {
  minuteLabel: 'Minuten',
  hourLabel: 'Uren',
  dayLabel: 'Dagen',
  monthLabel: 'Maanden',
  yearLabel: 'Jaren',
  noValueError: 'Vul minimaal één waarde in',
};
type MyProps = {
  condition: FlowConditionTimeElapsed;
  onChange: (newCondition: FlowConditionTimeElapsed) => void;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
};
class FlowConditionTimeElapsedComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  validate = (): boolean => {
    const { condition } = this.props;
    const { minute, hour, day, month, year } = condition;

    if (
      isNonZeroNumberParameterValue(minute) ||
      isNonZeroNumberParameterValue(hour) ||
      isNonZeroNumberParameterValue(day) ||
      isNonZeroNumberParameterValue(month) ||
      isNonZeroNumberParameterValue(year)
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { condition, onChange } = this.props;
    const { minute, hour, day, month, year } = condition;

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => {
          let error;
          if (showValidation && !this.validate()) {
            error = text.noValueError;
          }

          return (
            <>
              <InputGroup>
                <NumberPrimitiveParameterValueComponent
                  value={hour}
                  change={{
                    controlled: true,
                    onChange: newHour => {
                      onChange({
                        ...condition,
                        hour: newHour,
                      });
                    },
                  }}
                  inputLabel={text.hourLabel}
                  error={error}
                />
                <NumberPrimitiveParameterValueComponent
                  value={minute}
                  change={{
                    controlled: true,
                    onChange: newMinute => {
                      onChange({
                        ...condition,
                        minute: newMinute,
                      });
                    },
                  }}
                  inputLabel={text.minuteLabel}
                  error={error}
                />
              </InputGroup>
              <InputGroup>
                <NumberPrimitiveParameterValueComponent
                  value={day}
                  change={{
                    controlled: true,
                    onChange: newDay => {
                      onChange({
                        ...condition,
                        day: newDay,
                      });
                    },
                  }}
                  inputLabel={text.dayLabel}
                  error={error}
                />
                <NumberPrimitiveParameterValueComponent
                  value={month}
                  change={{
                    controlled: true,
                    onChange: newMonth => {
                      onChange({
                        ...condition,
                        month: newMonth,
                      });
                    },
                  }}
                  inputLabel={text.monthLabel}
                  error={error}
                />
                <NumberPrimitiveParameterValueComponent
                  value={year}
                  change={{
                    controlled: true,
                    onChange: newYear => {
                      onChange({
                        ...condition,
                        year: newYear,
                      });
                    },
                  }}
                  inputLabel={text.yearLabel}
                  error={error}
                />
              </InputGroup>
            </>
          );
        }}
      </FlowContext.Consumer>
    );
  }
}

export default withBaseActionContext<MyProps>(
  FlowConditionTimeElapsedComponent,
);
