import { navigate } from 'gatsby';
import { pluck } from 'ramda';
import React, { useContext, useEffect } from 'react';
import JustificationContainer from '~/components/JustificationContainer';
import type {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/Wizard/context/WizardContext';
import { useUpdateAppStatusMutation } from '~/graphql/types';
import { AppStatus } from '~/graphql/types.client';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useErrorReporter from '~/hooks/useErrorReporter';
import useWizard from '~/hooks/useWizard';
import useWizardStep from '~/hooks/useWizardStep';
import formatToastMessage from '~/util/formatToastMessage';
import AppTypeCosts from './components/AppTypeCosts';
import getUpdateForAppStatus from './utils/getUpdateForAppStatus';
import getOutputsForAppOverview from './utils/getOutputsForAppOverview';
import OutputColorGrid from './components/OutputColorGrid';
import OutputImageGrid from './components/OutputImageGrid';
import OutputItem from './components/OutputItem';
import OutputList from './components/OutputList';
import useApp from '~/hooks/useApp';
import ErrorScreen from '~/scenes/ErrorScreen';
import DatHuisLoading from '~/components/DatHuisLoading';
import getEnablementFlow from '~/components/Wizard/utils/getEnablementFlow';
import AccountContext from '~/contexts/AccountContext';

const text = {
  title: 'Overzicht',
  emptyListMessage: {
    'Geactiveerde flows': 'Er zijn geen flows toegevoegd.',
    'Logo link': 'Link is niet toegevoegd.',
  },
};

export const id = 'AppStatusOverview';
export const title = text.title;

export type OutputType = {
  type: typeof id;
  appStatus?: OutputFieldInvisible<AppStatus>;
};

export const Component: React.FC<WizardStepProps> = ({ step, outputMap }) => {
  const addToast = useAddToast();
  const wizardAPI = useWizard();
  const reporter = useErrorReporter();
  const { id: accountId } = useCurrentAccount();
  const { app, loading } = useApp(step.metadata.typename);
  const { refetchSessionHydration } = useContext(AccountContext);

  const [updateAppStatus] = useUpdateAppStatusMutation();
  const onBeforeNext = async outputMap => {
    const currentOutput: OutputType = outputMap[id];
    const update = getUpdateForAppStatus({
      typename: step.metadata.typename,
      completed: true,
      currentAppStatus: currentOutput.appStatus?.value,
    });

    await updateAppStatus({
      variables: {
        accountId,
        update: {
          [step.metadata.typename]: update,
        },
      },
    }).catch(err => {
      reporter.captureException(err);
      addToast([
        formatToastMessage(
          `Er is iets mis gegaan bij het aanzetten van de app. Probeer het later opnieuw.`,
          'danger',
        ),
      ]);
    });
    if (app) {
      const appPath = app.type === 'with_config' ? app.slug : null;

      if (appPath) {
        // We don't want Trustoo users to land inside the app when they finish the wizard
        if (appPath !== 'trustoo') {
          void navigate(`/-/apps/${appPath}`);
        }
      }

      // Clear indexedDB states and the output when wizard is completed
      const enablementFlow = getEnablementFlow(app);
      if (enablementFlow) {
        void wizardAPI.clear(enablementFlow.id);
      }
    }

    await refetchSessionHydration();

    return outputMap[id];
  };

  const [, api] = useWizardStep(step.id, { onBeforeNext });

  const stepsInWizard = wizardAPI.getSteps();
  const stepIDs = pluck('id', stepsInWizard);

  const { images, list, colors, item } = getOutputsForAppOverview(
    outputMap,
    stepIDs,
  );

  useEffect(() => {
    if (app?.appStatus) {
      api.free({
        type: id,
        appStatus: {
          type: 'invisible',
          value: app.appStatus,
        },
      });
    } else {
      api.lock({ type: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.appStatus]);

  if (loading) return <DatHuisLoading />;
  if (!app) {
    return <ErrorScreen />;
  }
  return (
    <JustificationContainer gap="m" direction="column">
      {step.metadata.typename && (
        <AppTypeCosts typename={step.metadata.typename} />
      )}
      {images.map((grid, index) => (
        <OutputImageGrid key={index} {...grid} />
      ))}
      {colors.map((grid, index) => (
        <OutputColorGrid key={index} {...grid} />
      ))}
      {item.map((grid, index) => (
        <OutputItem key={index} {...grid} />
      ))}
      {list.map((grid, index) => (
        <OutputList key={index} {...grid} />
      ))}
    </JustificationContainer>
  );
};

export default {
  id,
  title,
};
