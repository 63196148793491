import { WithTheme, Theme } from '~/theme';
import { css, FlattenSimpleInterpolation } from 'styled-components';

type FroalaThemeType = {
  activeTabBackgroundColor: string;
  activeButtonHighlightColor: string;
};
const defaults = (theme: Theme): FroalaThemeType => ({
  activeTabBackgroundColor: theme.color('white', 'dark'),
  activeButtonHighlightColor: theme.color('info'),
});

type Variables = WithTheme & {
  froalaTheme?: FroalaThemeType;
};

export const defaultEditorClass = 'dh-default-theme';
export const errorEditorClass = 'dh-validation-error';

// It is unclear how these styles came into existence and what they are actually doing.
// Stylelint is complaining about this bit after we added the `css` function though. So we will disable stylelint for these styles
// until we get the chance to clean to this up.
/* stylelint-disable */
export default ({
  theme,
  froalaTheme,
}: Variables): FlattenSimpleInterpolation => {
  const { activeTabBackgroundColor, activeButtonHighlightColor } = {
    ...defaults(theme),
    ...froalaTheme,
  };

  return css`
    .${defaultEditorClass} {
      width: 100%;
    }

    .${defaultEditorClass} .fr-wrapper {
      border-radius: ${theme.getTokens().border.radius.s};
    }

    .${defaultEditorClass} .fr-more-toolbar {
      background-color: ${activeTabBackgroundColor};
    }

    .${defaultEditorClass} .fr-popup .fr-buttons.fr-tabs {
      background-color: ${activeTabBackgroundColor};
    }

    .${defaultEditorClass}
      .fr-popup
      .fr-checkbox
      input:not(:checked):active
      + span {
      background-color: ${activeTabBackgroundColor};
    }

    .${defaultEditorClass}
      .fr-toolbar
      .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
      background-color: ${activeTabBackgroundColor};
    }

    .${defaultEditorClass} .fr-modal .fr-modal-head .fr-modal-tags a {
      background-color: ${activeTabBackgroundColor};
    }

    .${defaultEditorClass} .fr-view .fr-class-code {
      background-color: ${activeTabBackgroundColor};
    }

    .${defaultEditorClass}
      .fr-modal
      .fr-modal-wrapper
      div.fr-modal-body
      button.fr-command {
      color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass}
      .fr-modal
      .fr-modal-wrapper
      div.fr-modal-body
      button.fr-command:hover,
    .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:focus {
      color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass}
      .fr-toolbar
      .fr-btn-grp
      .fr-command.fr-btn.fr-active:not(.fr-dropdown)
      svg
      path,
    .fr-toolbar
      .fr-more-toolbar
      .fr-command.fr-btn.fr-active:not(.fr-dropdown)
      svg
      path,
    .fr-toolbar
      .fr-buttons:not(.fr-tabs)
      .fr-command.fr-btn.fr-active:not(.fr-dropdown)
      svg
      path,
    .fr-popup
      .fr-btn-grp
      .fr-command.fr-btn.fr-active:not(.fr-dropdown)
      svg
      path,
    .fr-popup
      .fr-more-toolbar
      .fr-command.fr-btn.fr-active:not(.fr-dropdown)
      svg
      path,
    .fr-popup
      .fr-buttons:not(.fr-tabs)
      .fr-command.fr-btn.fr-active:not(.fr-dropdown)
      svg
      path,
    .fr-modal
      .fr-btn-grp
      .fr-command.fr-btn.fr-active:not(.fr-dropdown)
      svg
      path,
    .fr-modal
      .fr-more-toolbar
      .fr-command.fr-btn.fr-active:not(.fr-dropdown)
      svg
      path,
    .fr-modal
      .fr-buttons:not(.fr-tabs)
      .fr-command.fr-btn.fr-active:not(.fr-dropdown)
      svg
      path {
      fill: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass}
      .fr-modal
      .fr-modal-wrapper
      div.fr-modal-body
      button.fr-command {
      color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass}
      .fr-modal
      .fr-modal-wrapper
      div.fr-modal-body
      button.fr-command:hover,
    .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:focus {
      color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass} .fr-popup .fr-input-line input[type='text']:focus,
    .fr-popup .fr-input-line textarea:focus {
      border-color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass}
      .fr-modal
      .fr-modal-wrapper
      div.fr-modal-body
      button.fr-command:active {
      color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass} .fr-popup .fr-action-buttons button.fr-command {
      color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass} .fr-popup .fr-action-buttons button.fr-command:hover,
    .fr-popup .fr-action-buttons button.fr-command:focus {
      color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass}
      .fr-popup
      .fr-action-buttons
      button.fr-command:active {
      color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass} .fr-popup .fr-checkbox input:checked + span {
      background: ${activeButtonHighlightColor};
      border-color: ${activeButtonHighlightColor};
    }

    .${defaultEditorClass} .fr-newline {
      background: theme.color('grey', 'light');
    }
    .${errorEditorClass} .fr-view {
      border: ${theme.getTokens().border.width.s} solid ${theme.color('danger')};
    }

    .second-toolbar {
      border: none;
    }

    .${defaultEditorClass} .fr-view blockquote {
      color: inherit;
      border-left: ${theme.getTokens().border.width.base} solid
        ${theme.color('grey', 'light')};
    }

    .${defaultEditorClass}.fr-box.fr-basic .fr-element {
      color: inherit;
    }
  `;
};

/* stylelint-enable */
