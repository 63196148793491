import React from 'react';
import md5 from 'md5';
import uuidv1 from 'uuid/v1';

import { InteractionFunctions } from '~/components/HTMLEditor/HTMLEditor';
import {
  HandledFlowParameterMappingParameter,
  TemplateStringParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { FlowEmailAttachmentValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { GetTemplateComponentReturnType } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/TemplateString/types.flow';

import {
  GetHTMLFunction,
  SetHTMLFunction,
  InsertHTMLFunction,
} from '~/components/HTMLEditor/HTMLEditor';

import {
  UseInlineAttachmentsType,
  GetInlineEmailAttachmentsForFlowFunctionType,
  InlineAttachmentsInteractionFunctions,
} from '~/components/HTMLEditor/EmailEditor';

import {
  convertTemplateStringToHTML,
  convertHTMLToTemplateString,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/TemplateString/util/templateStringConversion';
import {
  htmlToInsertForPointerVariable,
  replaceVariableTagsWithHandlebars,
} from '~/components/HTMLEditor/util';
import { createBase64ImageForVariableText } from '~/util/canvasDrawers';
import getNewMappingId from '~/util/getNewMappingId';
import { getInnerText } from '~/util/divManipulators';
import { HTML_VARIABLE_REGEX } from '~/components/HTMLEditor/constants';
import cleanedFilename from '~/util/cleanedFilename';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { SignatureAttachmentValue } from '~/components/SignatureContainer/types';

/*
 * [0] - the initialHtml to send to the HTMLEditor
 * [1] - the registerHTMLEditorInteractionFunctions to send to the HTMLEditor
 * [2] - a function to get the latest template string representation of the html in the editor
 * [3] - a function to add or update a variable into the string
 * [4] - a function to check if the html has changed from the initial
 * [5] - the canvas node that is used to create the variable image sources. Place it in the dom, it is invisible
 * [6] - a function to forcefully refresh the html, used when variables have changed */
type GetConvertedHTMLFunction = () => {
  html: string;
  isStillUploading: boolean;
};
type ChildProps = {
  initialHtml: string;
  registerHTMLEditorInteractionFunctions: (arg0: InteractionFunctions) => void;
  useFlowInlineAttachments: UseInlineAttachmentsType;
  getTemplateString: () => GetTemplateComponentReturnType;
  getInlineAttachments: () => Array<
    FlowEmailAttachmentValue | SignatureAttachmentValue
  >;
  insertOrUpdateVariable: (
    newVariable: HandledFlowParameterMappingParameter,
    variableId: string | null,
  ) => void;
  hasChanged: () => boolean;
  refreshHtml: () => void;
  variableUpdaterId: string;
};
export type UseTemplateStringParameterHTMLHandlerOptions = {
  stripHtml?: boolean;
};
type MyProps = {
  initialInlineAttachments: Array<
    FlowEmailAttachmentValue | SignatureAttachmentValue
  > | null;
  setGetInlineAttachments?: (
    fn: () => Array<FlowEmailAttachmentValue | SignatureAttachmentValue>,
  ) => void;
  initialTemplateString: TemplateStringParameterValue;
  onVariableInsertedOrUpdated: () => void;
  options?: UseTemplateStringParameterHTMLHandlerOptions;

  /** The component will call the function with a function that can be called to get the latest template string parameter */
  setGetTemplateComponent: (fn: () => GetTemplateComponentReturnType) => any;
  children: (props: ChildProps) => React.ReactNode;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  variableUpdaterId: string;
  initialHtml: string;
};
class TemplateStringParameterHTMLHandler extends React.Component<Props, State> {
  shouldRefreshHtml: boolean = false;
  initialHtmlHash: string | null = null;
  stashVersion: string | null = null;

  /** Interaction with the HTML Editor functions */
  getHtmlFn: GetHTMLFunction | null = null;
  getConvertedHtmlFn: GetConvertedHTMLFunction | null = null;
  setHtmlFn: SetHTMLFunction | null = null;
  toInsertFn: InsertHTMLFunction | null = null;

  /** Interaction with the Email Editor functions */
  convertFroalaToHtmlWithFlowInlineAttachments: GetInlineEmailAttachmentsForFlowFunctionType | null =
    null;

  constructor(props: Props) {
    super(props);

    const { initialTemplateString, options, baseActionContext } = props;

    const variableUpdaterId = uuidv1();
    this.stashVersion = baseActionContext.variableStash.version;
    this.state = {
      variableUpdaterId,
      initialHtml: convertTemplateStringToHTML(
        initialTemplateString,
        variableUpdaterId,
        baseActionContext.variableStash,
        withThemeCreateBase64ImageForVariableText,
        options,
      ),
    };
  }

  componentDidMount() {
    const { setGetTemplateComponent, setGetInlineAttachments } = this.props;

    setGetTemplateComponent(this.getLatestTemplateString);
    if (setGetInlineAttachments)
      setGetInlineAttachments(this.getInlineAttachments);
  }

  registerHTMLEditorInteractionFunctions = (
    interactionFunctions: InteractionFunctions,
  ) => {
    this.toInsertFn = interactionFunctions.insertHtml;
    this.setHtmlFn = interactionFunctions.setHtml;
    this.getHtmlFn = interactionFunctions.getHtml;

    if (this.getConvertedHtmlFn == null) {
      // if we have a converter later then it will overwrite it, but don't overwrite with basic
      this.getConvertedHtmlFn = () => ({
        html: interactionFunctions.getHtml(),
        isStillUploading: false,
      });
    }

    this.initialHtmlHash = md5(normalizeHtml(this.getHtmlFn()));

    if (this.shouldRefreshHtml) {
      this.shouldRefreshHtml = false;

      this.refreshHtml();
    }
  };

  registerFlowInlineAttachmentsInteractionFunctions = (
    interactionFunctions: InlineAttachmentsInteractionFunctions,
  ) => {
    this.convertFroalaToHtmlWithFlowInlineAttachments =
      interactionFunctions.convertFroalaToHtmlWithInlineAttachments;

    this.getConvertedHtmlFn = () => {
      if (this.convertFroalaToHtmlWithFlowInlineAttachments == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | this.convertFroalaToHtmlWithFlowInlineAttachments is null in the getHtml`,
        );
      }

      const { html, isStillUploading } =
        this.convertFroalaToHtmlWithFlowInlineAttachments();

      return { html, isStillUploading };
    };
  };

  refreshHtml = () => {
    if (this.getHtmlFn == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} -> refreshHtml called while the getHtmlFn is not set`,
      );
    }

    const { variableUpdaterId } = this.state;
    const { baseActionContext, options } = this.props;
    const { variableStash } = baseActionContext;

    const html = this.getHtmlFn();

    let strippedHtml;
    if (options && options.stripHtml) {
      strippedHtml = getInnerText(replaceVariableTagsWithHandlebars(html));
    }

    const convertedTemplateString = convertHTMLToTemplateString(
      html,
      strippedHtml,
    );

    const convertedHtml = convertTemplateStringToHTML(
      convertedTemplateString,
      variableUpdaterId,
      variableStash,
      withThemeCreateBase64ImageForVariableText,
      options,
    );

    if (this.setHtmlFn == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} -> refreshHtml called while the setHtmlFn is not set`,
      );
    }

    this.setHtmlFn(convertedHtml);
  };

  /** Refresh the html, a boolean is needed as the getHtmlFn may not be set yet (because froala initialising takes a tick) */
  attemptRefreshHtml = () => {
    if (this.getHtmlFn == null || this.setHtmlFn == null) {
      this.shouldRefreshHtml = true;
    } else {
      this.refreshHtml();
    }
  };

  getLatestTemplateString = (
    refresh?: boolean,
  ): GetTemplateComponentReturnType => {
    const { initialTemplateString, options } = this.props;

    if (this.getConvertedHtmlFn == null) {
      // If the html editor has not registered yet, return the initial as it can't have changed yet
      return {
        templateString: initialTemplateString,
        isStillUploading: false,
      };
    }

    const { html, isStillUploading } = this.getConvertedHtmlFn();
    let strippedHtml;

    if (options && options.stripHtml) {
      strippedHtml = getInnerText(replaceVariableTagsWithHandlebars(html));
    }

    if (refresh === true) {
      this.refreshHtml();
    }

    return {
      templateString: convertHTMLToTemplateString(html, strippedHtml),
      isStillUploading,
    };
  };

  getInlineAttachments = () => {
    if (this.convertFroalaToHtmlWithFlowInlineAttachments == null) {
      return [];
    }

    return this.convertFroalaToHtmlWithFlowInlineAttachments()
      .inlineAttachments;
  };

  addOrUpdateVariable = (
    newVariable: HandledFlowParameterMappingParameter,
    variableId: string | null,
  ) => {
    if (newVariable.variable == null) return;

    const { variableUpdaterId } = this.state;
    const { baseActionContext, onVariableInsertedOrUpdated } = this.props;
    const { variableStash } = baseActionContext;

    if (variableId == null) {
      const newMappingId = getNewMappingId();

      if (this.toInsertFn == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} -> addOrUpdateVariable called while the toInsertFn is not set`,
        );
      }

      // @ts-ignore
      this.toInsertFn(
        htmlToInsertForPointerVariable(
          { mapping: newVariable, mappingId: newMappingId },
          variableStash,
          variableUpdaterId,
          withThemeCreateBase64ImageForVariableText,
        ),
      );
    } else {
      const templateString = this.getLatestTemplateString().templateString;
      const mappings = templateString.mappings;

      const foundMappingIdx = mappings.findIndex(
        mapping => mapping.mappingId === variableId,
      );

      if (foundMappingIdx == -1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} -> addOrUpdateVariable can't find the mapping with mappingId ${variableId} in the latest template string ${JSON.stringify(
            templateString,
            null,
            2,
          )}`,
        );
      }

      const newMappings = [...mappings];
      newMappings[foundMappingIdx] = {
        mappingId: variableId,
        mapping: newVariable,
      };

      const newTemplateString = { ...templateString, mappings: newMappings };

      if (this.setHtmlFn == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} -> addOrUpdateVariable called while the toInsertFn is not set`,
        );
      }
      // @ts-ignore
      this.setHtmlFn(
        convertTemplateStringToHTML(
          newTemplateString,
          variableUpdaterId,
          variableStash,
          withThemeCreateBase64ImageForVariableText,
        ),
      );
    }

    onVariableInsertedOrUpdated();
  };

  hasHTMLChanged = () => {
    if (this.getHtmlFn == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} -> hasHTMLChanged called while the getHtmlFn is not set`,
      );
    }

    return this.initialHtmlHash !== md5(normalizeHtml(this.getHtmlFn()));
  };

  render() {
    const { initialHtml, variableUpdaterId } = this.state;
    const { baseActionContext, initialInlineAttachments, children } =
      this.props;

    const { variableStash } = baseActionContext;

    // refresh the html if the stash changed, to show if variables are suddenly unknown
    const hasStashChanged =
      (this.stashVersion == null && variableStash.version != null) ||
      (this.stashVersion != null &&
        this.stashVersion !== variableStash.version);

    if (hasStashChanged) {
      this.stashVersion = variableStash.version;

      this.attemptRefreshHtml();
    }

    const childProps: ChildProps = {
      initialHtml,
      registerHTMLEditorInteractionFunctions:
        this.registerHTMLEditorInteractionFunctions,
      useFlowInlineAttachments: {
        initialInlineAttachments: initialInlineAttachments || [],
        registerInlineAttachmentsInteractionFunctions:
          this.registerFlowInlineAttachmentsInteractionFunctions,
      },
      getTemplateString: this.getLatestTemplateString,
      getInlineAttachments: this.getInlineAttachments,
      insertOrUpdateVariable: this.addOrUpdateVariable,
      hasChanged: this.hasHTMLChanged,
      refreshHtml: this.attemptRefreshHtml,
      variableUpdaterId,
    };

    return children(childProps);
  }
}

const withThemeCreateBase64ImageForVariableText = text =>
  createBase64ImageForVariableText(text);

/** To check for changes in html we have to normalize some things */
const normalizeHtml = (html: string): string => {
  // Froala will add nbsp characters in front of images
  const withNormalizedSpaces = html.replace(/&nbsp;/g, ' ');

  // The data urls for the same images are not identical, so remove them from the comparison
  const withoutDataUrls = withNormalizedSpaces.replace(
    HTML_VARIABLE_REGEX,
    matchedValue => matchedValue.replace(/src="[^"]*/g, 'src="removed'),
  );

  return withoutDataUrls;
};

export default withBaseActionContext<MyProps>(
  TemplateStringParameterHTMLHandler,
);
