import {
  ContactFilters__Input,
  ContactFiltersContainer__Input,
} from '~/graphql/types';
import { clone, pick } from 'ramda';
import { isEmptyObject } from '~/util/object';

/**
 * Clean filters. Makes sure correct properties are passed to Leaf and removes null values
 * @param {ContactFilters__Input} filters - Contact filters
 * keywords:
 */
const cleanFilters = (
  filters: ContactFilters__Input,
): ContactFilters__Input => {
  const clonedNewFilters = clone(filters);

  clonedNewFilters.nodes = clonedNewFilters.nodes?.reduce<
    Array<ContactFiltersContainer__Input>
  >((prev, curr): Array<ContactFiltersContainer__Input> => {
    if (curr.Node == null) {
      prev.push(curr);
      return prev;
    }
    if (
      // Remove the empty filter groups only if we have more than 1 group
      clonedNewFilters.nodes.length !== 1 &&
      curr.Node.nodes.every(
        node => isEmptyObject(node) || node.Leaf === undefined,
      )
    ) {
      return prev;
    }

    curr.Node.nodes = curr.Node.nodes.map(node => {
      if (node.Leaf == null) return node;

      if (node.Leaf.attribute != null) {
        const keysToAdd = ['actionKey', 'subjectId'];
        if (node.Leaf.attribute.eventId) {
          keysToAdd.push('eventId'); // do not add eventId if it is null
        }
        node.Leaf.attribute = pick(keysToAdd, node.Leaf.attribute);
      }

      if (node.Leaf.instanceAttribute != null) {
        const keysToAdd = ['commands', 'connector', 'subjectId', 'negate'];
        if (node.Leaf.instanceAttribute.eventId) {
          keysToAdd.push('eventId'); // do not add eventId if it is null
        }
        node.Leaf.instanceAttribute = pick(
          keysToAdd,
          node.Leaf.instanceAttribute,
        );
      }

      return node;
    });
    prev.push(curr);
    return prev;
  }, []);

  return clonedNewFilters;
};

export default cleanFilters;
