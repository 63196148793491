import { SNOOZED_WARNINGS } from '~/util/sessionStorageKeys';
import SnoozedWarningType from '../types';

const getSnoozedWarnings = (): SnoozedWarningType => {
  const snoozedWarnings =
    global.window.sessionStorage.getItem(SNOOZED_WARNINGS);

  if (!snoozedWarnings) return {};

  return JSON.parse(snoozedWarnings);
};

export default getSnoozedWarnings;
