import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  children: React.ReactNode;
};
const ActionContentContainer = ({ children }: Props) => (
  <Container>{children}</Container>
);

const Container = styled.div<{}>`
  flex-grow: 1;

  ${({ theme }) => css`
    padding: 0 ${theme.space('l')} ${theme.space('l')} ${theme.space('l')};
  `}
`;

export default ActionContentContainer;
