import { StringPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../Base/FlowParameter/ParameterValue/constants';

const asEmailVariable = (id: string): StringPointerParameterValue => ({
  type: PARAMETER_VALUE_TYPE.STRING_POINTER,
  variable: {
    name: id,
    field: {
      name: 'email',
    },
  },
});

export default asEmailVariable;
