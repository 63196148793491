import { FlowParameterTemplateStringFieldsFragment } from '~/graphql/types';
import { TemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyTemplateStringParameterValue from './emptyTemplateStringParameterValue';
import { mapNotNull } from '~/util/array';
import { asFlowParameterMappingProp } from '../../FlowParameterMapping';
import { hasNonUploadedImagesInHtml } from '~/components/HTMLEditor/util';
import cleanedFilename from '~/util/cleanedFilename';

export default (
  parameter: FlowParameterTemplateStringFieldsFragment | null | undefined,
  validateHtmlForBackend?: boolean,
): TemplateStringParameterValue => {
  if (parameter == null) {
    return emptyTemplateStringParameterValue();
  }

  const { mappings, template } = parameter;

  if (validateHtmlForBackend === true) {
    const hasError = hasNonUploadedImagesInHtml(template);
    if (hasError) {
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | The html ${template} contains non uploaded images!`,
      );
    }
  }

  return {
    type: PARAMETER_VALUE_TYPE.TEMPLATE_STRING,
    template: template || '',
    mappings: mapNotNull(mappings, mapping => {
      if (mapping.mapping == null) {
        return null;
      }

      return asFlowParameterMappingProp(mapping);
    }),
  };
};
