import { FlowConditionContactTag } from './types.flow';

import { asBooleanPrimitiveParameterValue } from '../../FlowParameter/ParameterValue/Boolean';
import { asStringPrimitiveParameterValue } from '../../FlowParameter';

export default (condition: any): Omit<FlowConditionContactTag, 'id'> => {
  const { exists, tagName } = condition;

  return {
    type: 'Flow_Condition_Contact_Tag',
    exists: asBooleanPrimitiveParameterValue(exists),
    tagName: asStringPrimitiveParameterValue(tagName),
  };
};
