import { FlowCondition } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { describeContactDetailsCondition } from './ContactDetails';
import { describeContactTagCondition } from './ContactTag';
import { describeEmailLinkClickedCondition } from './EmailLinkClicked';
import { describeEmailOpenedCondition } from './EmailOpened';
import { describeTimeElapsedCondition } from './TimeElapsed';
import { describeAppValuationReportNewCondition } from './AppValuationReportNew';
import { describeValuationReportDetailsCondition } from './AppValuationReportDetails';
import { describeAppValuationRequestNewCondition } from './AppValuationRequestNew';
import { describeValuationRequestDetailsCondition } from './AppValuationRequestDetails';
import { describeAppFundaContactRequestNewCondition } from './AppFundaContactRequestNew';
import { describeAppFundaViewingRequestNewCondition } from './AppFundaViewingRequestNew';
import { describeAppFundaBrochureRequestNewCondition } from './AppFundaBrochureRequestNew';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';
import { describeAppFundaContactRequestDetailsCondition } from './AppFundaContactRequestDetails';
import { describeAppFundaViewingRequestDetailsCondition } from './AppFundaViewingRequestDetails';
import { describeAppFundaBrochureRequestDetailsCondition } from './AppFundaBrochureRequestDetails';

import { describeAppBBWaardecheckReportNewCondition } from './AppBBWaardecheckReportNew';
import { describeAppBBWaardecheckReportDetailsCondition } from './AppBBWaardecheckReportDetails';
import { describeAppBBWaardecheckAppraisalRequestNewCondition } from './AppBBWaardecheckAppraisalRequestNew';
import { describeAppBBWaardecheckAppraisalRequestDetailsCondition } from './AppBBWaardecheckAppraisalRequestDetails';
import { describeAppBBWaardecheckContactRequestNewCondition } from './AppBBWaardecheckContactRequestNew';
import { describeAppZapierNewCondition } from './AppZapierNew';
import { describeAppZapierDetailsCondition } from './AppZapierDetails';

import { describeAppVBOWaardecheckReportNewCondition } from './AppVBOWaardecheckReportNew';
import { describeAppVBOWaardecheckReportDetailsCondition } from './AppVBOWaardecheckReportDetails';
import { describeAppVBOWaardecheckAppraisalRequestNewCondition } from './AppVBOWaardecheckAppraisalRequestNew';
import { describeAppVBOWaardecheckAppraisalRequestDetailsCondition } from './AppVBOWaardecheckAppraisalRequestDetails';
import { describeAppVBOWaardecheckContactRequestNewCondition } from './AppVBOWaardecheckContactRequestNew';
import { describeAppHypotheekbondDetailsCondition } from './AppHypotheekbondDetails';
import { describeAppHypotheekbondNewCondition } from './AppHypotheekbondNew';

const describeFlowCondition = (
  condition: FlowCondition,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  switch (condition.type) {
    case 'Flow_Condition_Contact_Details':
      return describeContactDetailsCondition(condition, describeOptions);
    case 'Flow_Condition_Contact_Tag':
      return describeContactTagCondition(condition, describeOptions);
    case 'Flow_Condition_Event_Email_LinkClicked':
      return describeEmailLinkClickedCondition(condition, describeOptions);
    case 'Flow_Condition_Event_Email_Opened':
      return describeEmailOpenedCondition(condition, describeOptions);
    case 'Flow_Condition_Time_Elapsed':
      return describeTimeElapsedCondition(condition, describeOptions);
    case 'Flow_Condition_Event_Contact_App_ValuationReport_New':
      return describeAppValuationReportNewCondition();
    case 'Flow_Condition_Event_Contact_App_ValuationReport_Details':
      return describeValuationReportDetailsCondition(
        condition,
        describeOptions,
      );
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_New':
      return describeAppValuationRequestNewCondition();
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_Details':
      return describeValuationRequestDetailsCondition(
        condition,
        describeOptions,
      );
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New':
      return describeAppFundaContactRequestNewCondition();
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New':
      return describeAppFundaViewingRequestNewCondition();
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New':
      return describeAppFundaBrochureRequestNewCondition();

    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details':
      return describeAppFundaContactRequestDetailsCondition(
        condition,
        describeOptions,
      );
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details':
      return describeAppFundaViewingRequestDetailsCondition(
        condition,
        describeOptions,
      );
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details':
      return describeAppFundaBrochureRequestDetailsCondition(
        condition,
        describeOptions,
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New':
      return describeAppBBWaardecheckReportNewCondition();
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details':
      return describeAppBBWaardecheckReportDetailsCondition(
        condition,
        describeOptions,
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New':
      return describeAppBBWaardecheckAppraisalRequestNewCondition();
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details':
      return describeAppBBWaardecheckAppraisalRequestDetailsCondition(
        condition,
        describeOptions,
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New':
      return describeAppBBWaardecheckContactRequestNewCondition();
    case 'Flow_Condition_Event_Contact_App_Zapier_New':
      return describeAppZapierNewCondition();
    case 'Flow_Condition_Event_Contact_App_Zapier_Details':
      return describeAppZapierDetailsCondition(condition, describeOptions);

    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New':
      return describeAppVBOWaardecheckReportNewCondition();
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details':
      return describeAppVBOWaardecheckReportDetailsCondition(
        condition,
        describeOptions,
      );
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New':
      return describeAppVBOWaardecheckAppraisalRequestNewCondition();
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details':
      return describeAppVBOWaardecheckAppraisalRequestDetailsCondition(
        condition,
        describeOptions,
      );
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New':
      return describeAppVBOWaardecheckContactRequestNewCondition();

    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_New':
      return describeAppHypotheekbondNewCondition();
    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details':
      return describeAppHypotheekbondDetailsCondition(
        condition,
        describeOptions,
      );
    default:
      return assertNever(condition, cleanedFilename(__filename));
  }
};

export default describeFlowCondition;
