import { Flow_Condition__Input } from '~/graphql/types';

const asFlowConditionAppFundaContactRequestNewInput =
  (): Flow_Condition__Input => ({
    EventContactApp: {
      Funda: {
        ContactRequest: {
          New: {
            noOperation: true,
          },
        },
      },
    },
  });

export default asFlowConditionAppFundaContactRequestNewInput;
