import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import {
  OverageItem,
  PricePerOverageItem,
  BasicOverageItem,
} from '../../types.flow';
import { InvoiceItemFieldsFragment } from '~/graphql/types';

import { GetApproximateUpcomingOveragesChargeQuery } from '~/graphql/Query';
import getApproximateUpcomingOveragesCharge from '~/graphql/query/GetApproximateUpcomingOveragesCharge';
import DetailsBlock from '~/components/DetailsBlock';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import { AppErrorScreen } from '~/components';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import cleanedFilename from '~/util/cleanedFilename';
import SubscriptionOveragesInfoBlockDetails from '../SubscriptionOveragesInfoBlockDetails';

import TEST_ID from './index.testid';
import { OVERAGE_ITEM } from '../../constants';
import { reportErrorToTracker } from '~/util/assertion';
import { mapNotNull } from '~/util/array';
import { convertServerDateStringToDate, safeFormattedDate } from '~/util/date';

const text = {
  title: 'Overige kosten',
  invoiceLabel: 'Facturatie op',
  lastUpdateLabel: 'Laatst bijgewerkt op',
  informationText:
    'Specificatie van extra kosten buiten je huidige abonnement.',
};
const SubscriptionOveragesInfoBlock: React.FC = () => {
  const account = useCurrentAccount();

  return (
    <GetApproximateUpcomingOveragesChargeQuery
      name={__filename}
      query={getApproximateUpcomingOveragesCharge}
      variables={{ accountId: account.id }}
    >
      {({ data, networkStatus, error }) => {
        if (isInitialLoadStatus(networkStatus)) {
          // If there are no overages we do not want to show anything, so also don't show loading
          return null;
        }

        const overages = data?.getApproximateUpcomingOveragesCharge;

        if (error || overages == null) {
          return (
            <DetailsBlock
              dataTestid={TEST_ID.CONTAINER}
              title={text.title}
              manualLayout
            >
              <AppErrorScreen inline setBackgroundColor={false} />
            </DetailsBlock>
          );
        }

        if (overages.amount === 0) {
          // if no overages do not show anything
          return null;
        }

        const { cycleEndDate, lastUpdatedDate: lastUpdatedDateString } =
          overages;
        const invoiceDate = convertServerDateStringToDate(cycleEndDate);
        const lastUpdatedDate = convertServerDateStringToDate(
          lastUpdatedDateString,
        );

        const infoTextComponent = <InfoText>{text.informationText}</InfoText>;

        let invoiceDateComponent: ReactElement | null = null;
        if (invoiceDate != null) {
          invoiceDateComponent = (
            <InvoiceDate>
              {text.invoiceLabel} {safeFormattedDate(invoiceDate)}
            </InvoiceDate>
          );
        }
        let lastUpdatedComponent: ReactElement | null = null;
        if (lastUpdatedDate != null) {
          lastUpdatedComponent = (
            <LastUpdateDate>
              {text.lastUpdateLabel} {safeFormattedDate(lastUpdatedDate)}
            </LastUpdateDate>
          );
        }

        return (
          <DetailsBlock
            dataTestid={TEST_ID.CONTAINER}
            title={text.title}
            manualLayout
          >
            <DetailsContainer>
              {infoTextComponent}
              <SubscriptionOveragesInfoBlockDetails
                amount={overages.amount}
                overages={convertInvoiceToOverages(overages.items)}
              />
              {invoiceDateComponent}
              {lastUpdatedComponent}
            </DetailsContainer>
          </DetailsBlock>
        );
      }}
    </GetApproximateUpcomingOveragesChargeQuery>
  );
};

const convertInvoiceToOverages = (
  overages: Array<InvoiceItemFieldsFragment>,
): Array<OverageItem | PricePerOverageItem> =>
  mapNotNull(overages, overage => {
    switch (overage.__typename) {
      case 'TieredInvoiceItem': {
        const { name, tiers } = overage;

        const subItems: Array<PricePerOverageItem> = mapNotNull(tiers, tier => {
          const { description, quantity, pricePerUnit, unitLabel, amount } =
            tier;

          if (amount === 0) {
            return null;
          }

          return {
            type: OVERAGE_ITEM.PRICE_PER,
            name: description,
            quantity,
            pricePerUnit,
            unitLabel: unitLabel == null ? null : unitLabel,
            amount,
          };
        });

        if (subItems.length === 0) {
          return null;
        }

        return {
          type: OVERAGE_ITEM.GROUPED,
          name,
          subItems,
        };
      }
      case 'BasicInvoiceItem': {
        const { amount, name } = overage;

        if (amount === 0) {
          return null;
        }

        return {
          type: OVERAGE_ITEM.BASIC,
          name,
          amount,
        };
      }
      case 'CombinedInvoiceItem': {
        const { name, subItems } = overage;

        const mappedSubItems: Array<BasicOverageItem | PricePerOverageItem> =
          mapNotNull(subItems, item => {
            const { amount, description, quantity, pricePerUnit } = item;

            if (amount === 0) {
              return null;
            }

            if (quantity != null && pricePerUnit != null) {
              return {
                type: OVERAGE_ITEM.PRICE_PER,
                name: description,
                quantity,
                pricePerUnit,
                unitLabel: null,
                amount,
              };
            } else {
              return {
                type: OVERAGE_ITEM.BASIC,
                name: description,
                amount,
              };
            }
          });

        if (mappedSubItems.length === 0) {
          return null;
        }

        return {
          type: OVERAGE_ITEM.GROUPED,
          name,
          subItems: mappedSubItems,
        };
      }
      default:
        reportErrorToTracker(
          `${cleanedFilename(
            __filename,
          )} | Unknown invoice item of given overage (${JSON.stringify(
            overage,
            null,
            2,
          )})`,
        );
        return null;
    }
  });

const InfoText = styled.p<{}>(
  ({ theme }) => css`
    color: ${theme.color('text', 'light')};
    font-size: ${theme.fontSize('s')};
  `,
);

const DetailsContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('s')};
  `,
);

const InvoiceDate = styled.div<{}>`
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => css`
    margin-top: ${theme.space('s')};
    font-size: ${theme.fontSize('s')};
    color: ${theme.color('text', 'light')};
  `};
`;

const LastUpdateDate = styled.div<{}>`
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => css`
    margin-top: ${theme.space('s')};
    font-size: ${theme.fontSize('s')};
    color: ${theme.color('text', 'light')};
  `};
`;

export default SubscriptionOveragesInfoBlock;
