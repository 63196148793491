import { FlowParameterStringFieldsFragment } from '~/graphql/types';
import {
  StringParameterField,
  StringField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { asStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

export default (
  field: StringField,
  stringParameter: FlowParameterStringFieldsFragment,
): StringParameterField => ({
  ...field,
  value: asStringParameterValue(stringParameter),
});
