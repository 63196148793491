import gql from 'graphql-tag';

export default gql`
  fragment ConnectedEmailAliasFields on ConnectedEmailAlias {
    __typename
    email
    emailAlias
    status
    createdDate
    validatedDate
  }
`;
