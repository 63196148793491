import React from 'react';
import styled, { css } from 'styled-components';

import { stringSlicer } from '~/util/string';

import Icon from '../Icon';

type Props = {
  /** The test id for text in popover preview */
  dataTestid?: string;

  /** Text value which need to show in short and full version */
  text: string;

  /** maximum length of string after which text will be sliced */
  maxTextLength: number;
};

type State = {
  showFullText: boolean;
};

class TextPopover extends React.Component<Props, State> {
  state = {
    showFullText: false,
  };

  handleToggle = () => {
    this.setState({
      showFullText: !this.state.showFullText,
    });
  };

  render() {
    const { showFullText } = this.state;
    const { text, maxTextLength, dataTestid } = this.props;

    return (
      <Container>
        <SlicedText>
          <span data-testid={dataTestid || ''}>
            {' '}
            {stringSlicer(text, maxTextLength)}
          </span>
          {text.length >= maxTextLength && (
            <IconWrapper
              data-testid="popover-show-button"
              onClick={this.handleToggle}
            >
              <Icon name="question-mark" />
            </IconWrapper>
          )}
        </SlicedText>

        {showFullText && (
          <ModalPopover data-testid="modal-popover">{text}</ModalPopover>
        )}
      </Container>
    );
  }
}

const SlicedText = styled.p<{}>`
  position: relative;
`;

const IconWrapper = styled.span<{}>(
  ({ theme }) => css`
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    right: ${theme.space('m')};
    bottom: -2px;
    color: ${theme.color('primary')};
    & > i {
      font-size: ${theme.fontSize('m')};
      &:hover {
        color: ${theme.color('grey')};
      }
    }
  `,
);

const MODAL_MIN_HEIGHT = 120;
const MODAL_WIDTH = 300;

export const ModalPopover = styled.div<{}>`
  position: absolute;
  top: 50px;
  left: 50px;
  display: flex;
  ${({ theme }) => css`
    z-index: ${theme.getTokens().zIndex.top};
    padding: ${theme.space('l')};
    min-height: ${MODAL_MIN_HEIGHT}px;
    width: ${MODAL_WIDTH}px;
    border-radius: ${theme.getTokens().border.radius.s};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('grey', 'light')};
    background-color: ${theme.color('white', 'dark')};
  `}
`;

const Container = styled.div<{}>`
  position: relative;
`;

export default TextPopover;
