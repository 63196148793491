import React from 'react';
import MetaTags from 'react-meta-tags';

import { GetFlowQuery } from '~/graphql';
import query from '~/graphql/query/GetFlow';
import { Loading, AppErrorScreen } from '~/components';
import { getErrorTypes } from '~/util/errorHandling';
import ErrorTypes from '~/ErrorTypes';
import flowQueryToFlowProp from './util/flowQueryToFlowProp';
import FlowDetailsComponent from './components/FlowDetailsComponent';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import useEnabledFlowModal from '~/scenes/Automation/Flows/util/useEnabledFlowModal';
import { RouteComponentProps } from '@reach/router';
import useCurrentAccount from '~/hooks/useCurrentAccount';

const text = {
  flowListBreadcrumbLabel: Catalog.flowsLabel,
  pageTitle: Catalog.flowLabel,
  noFlowFound: 'Geen flow gevonden',
  unauthorisedUser: 'Je hebt onvoldoende rechten',
};
type Props = RouteComponentProps<{ flowId: string }>;

const FlowDetails = ({ flowId }: Props) => {
  const account = useCurrentAccount();
  const [showEnabledFlowModal] = useEnabledFlowModal(flowId as string);
  const variables = { accountId: account.id, id: flowId as string };

  return (
    <>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <GetFlowQuery
        name={cleanedFilename(__filename)}
        query={query}
        variables={variables}
      >
        {({ data, error, loading }) => {
          if (loading) return <Loading />;

          if (error != null || !data) {
            const errorTypes = getErrorTypes(error);
            if (errorTypes.includes(ErrorTypes.invalidFlowBlueprintId)) {
              return (
                <AppErrorScreen
                  message={text.noFlowFound}
                  errorType={ErrorTypes.invalidFlowBlueprintId}
                />
              );
            } else if (errorTypes.includes(ErrorTypes.unauthorisedError)) {
              return <AppErrorScreen message={text.unauthorisedUser} />;
            } else {
              return <AppErrorScreen />;
            }
          }

          const { getFlow } = data;

          if (!getFlow) return <AppErrorScreen />;

          return (
            <>
              <ContentContainerDefault
                breadcrumbs={[
                  {
                    to: '/-/automation/flows',
                    label: text.flowListBreadcrumbLabel,
                  },
                  { label: getFlow.name },
                ]}
              >
                <FlowDetailsComponent
                  initialFlow={flowQueryToFlowProp(getFlow)}
                  showEnabledFlowModal={showEnabledFlowModal}
                />
              </ContentContainerDefault>
            </>
          );
        }}
      </GetFlowQuery>
    </>
  );
};

export default FlowDetails;
