import React, { useContext } from 'react';

import { Task } from '~/scenes/Tasks/types';
import { TaskListType } from '~/scenes/Tasks/util/taskListType';
import { TaskListTaskFieldsFragment } from '~/graphql/types';

import { TaskListDelayer } from '~/components/ListDelayer';

import TASK_LIST_TYPE from '../../util/taskListType';

import OpenTaskList from './OpenTaskList';
import CompletedTaskList from './CompletedTaskList';
import UpdateTaskWrapper from './UpdateTaskWrapper';
import InfiniteScroll from '~/components/InfiniteScroll';
import { TaskUpdaterContext } from './TaskUpdaterContext';
import { TaskModalControllerContext } from '~/scenes/Tasks/TaskModalControllerContext';
import TaskModal from '~/scenes/Tasks/components/TaskModal/';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import EmptyStateComponent from '~/components/EmptyStateComponent';
import TEST_ID from './index.testid';
import { convertEmptyOrUndefinedToNull } from '~/components/Forms/Field';

const text = {
  noResults: 'Je hebt nog geen taken',
  emptyStateDescription:
    'Maak een taak voor jezelf of een collega voor een goede opvolging van leads en relaties.',
};
type TaskListProps = {
  /** The list type being shown */
  listType: TaskListType;

  /** The tasks to show */
  tasks: Array<Task>;

  /** callback call fetchMore function from apollo if shouldLoad === true  */
  fetchMoreFn: () => Promise<any>;

  /** If there are more items */
  hasMore: boolean;

  /** If something changes, call this function with the changed task to update the query */
  onTaskChanged: (newOrUpdatedTask: TaskListTaskFieldsFragment) => void;

  /** Function to call to open the add new task modal */
  onAddNewTask: () => void;
};

const TaskList = ({
  tasks,
  fetchMoreFn,
  listType,
  onTaskChanged,
  hasMore,
  onAddNewTask,
}: TaskListProps) => {
  const {
    shouldShowModal,
    closeModal,
    initialTaskForModal,
    defaultOfficeId,
    defaultUserId,
  } = useContext(TaskModalControllerContext);
  const account = useCurrentAccount();

  const formattedTasks = tasks.map(task => ({
    ...task,
    description: convertEmptyOrUndefinedToNull(task.description),
  }));

  return (
    <TaskUpdaterContext.Provider value={{ onTaskChanged }}>
      {shouldShowModal && (
        <TaskModal
          selectedInFilterOfficeId={defaultOfficeId}
          selectedInFilterUserId={defaultUserId}
          account={account}
          initialTaskDetails={initialTaskForModal}
          onClose={closeModal}
        />
      )}
      {tasks.length === 0 && (
        <EmptyStateComponent
          header={text.noResults}
          buttonLabel={'Voeg je eerste taak toe'}
          description={text.emptyStateDescription}
          illustration="list"
          onButtonClick={onAddNewTask}
          dataTestId={TEST_ID.EMPTY_STATE}
        />
      )}
      <InfiniteScroll fetchMoreFn={fetchMoreFn} hasMore={hasMore}>
        <TaskListDelayer
          items={formattedTasks}
          childComponent={({ updatedItemList }) => (
            <UpdateTaskWrapper
              childComponent={({ updateTask, loading }) => {
                if (listType === TASK_LIST_TYPE.OPEN) {
                  return (
                    <OpenTaskList
                      tasks={updatedItemList}
                      hasMore={hasMore}
                      updateTask={updateTask}
                      loading={loading}
                    />
                  );
                } else if (listType === TASK_LIST_TYPE.CLOSED) {
                  return (
                    <CompletedTaskList
                      tasks={updatedItemList}
                      hasMore={hasMore}
                      updateTask={updateTask}
                      loading={loading}
                    />
                  );
                } else return null;
              }}
            />
          )}
        />
      </InfiniteScroll>
    </TaskUpdaterContext.Provider>
  );
};

export default TaskList;
