import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  ContactFilters__Input,
  SegmentFieldsFragment,
  useGetMySegmentsQuery,
  useInsertSegmentMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useCurrentUser from '~/hooks/useCurrentUser';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import { descend, prop, sort } from 'ramda';
import SegmentRow from './components/SegmentRow';
import { useRecoilState } from 'recoil';
import TEST_ID from './index.testid';
import {
  FilterBlockContainer,
  ShowMoreContainer,
  ListContainer,
} from '../../commonComponents';
import { useTransition, animated } from 'react-spring';
import TipBanner from '~/components/TipBanner';
import { ExternalLink } from '~/components/Link';
import InputWithButtons from '~/components/InputWithButtons';
import activeContactSegment from './state/activeFilter';
import { isEmpty as isEmptyString } from '~/util/Validation/String';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import { Heading4, Variant } from '~/components/Typography/index';

export type Props = {
  dataTestId?: string;
  currentFilters: ContactFilters__Input;
  onFilterChangeV2: (params: ContactFilters__Input) => void;
};

const text = {
  showMore: 'Toon meer',
  showLess: 'Toon minder',
  inputPlaceholder: 'Naam filter',
  inputLabel: 'Deze filter opslaan',
  title: 'Opgeslagen filters',
  insertErrorMessage: 'Er is iets misgegaan bij het toevoegen van het filter',
  insertSuccessMessage: 'Filter is succesvol toegevoegd',
  tipMessage: 'Sla filters op om deze met één muisklik aan te roepen.',
  moreInfo: 'Meer in ons kenniscentrum',
};

const ITEM_COUNT_TO_DISPLAY = 15;

const Segments: React.FC<Props> = ({
  dataTestId,
  currentFilters,
  onFilterChangeV2,
}) => {
  const { id: accountId } = useCurrentAccount();
  const { id: userId } = useCurrentUser();
  const addToast = useAddToast();

  const [showAllItems, setShowAllItems] = useState<boolean>(false);
  const [filterName, setFilterName] = useState<string>('');

  const [
    { segmentId: activeSegmentId, filterId: activeFilterId },
    setActiveFilter,
  ] = useRecoilState(activeContactSegment);

  const { data: segmentData, updateQuery } = useGetMySegmentsQuery({
    variables: { accountId },
  });

  const [insertSegment, { loading: insertLoading }] =
    useInsertSegmentMutation();

  const sortByCreatedDate = sort(descend(prop('createdDate')));

  const hasCurrentFilters = !!currentFilters.nodes[0]?.Node?.nodes.length;
  const hasSelectedFromLeftFilterBar = activeSegmentId || activeFilterId;

  const inputTransition = useTransition(
    !hasSelectedFromLeftFilterBar && hasCurrentFilters,
    {
      from: { opacity: 0, height: '0px' },
      enter: { opacity: 1, height: '70px' },
      leave: { opacity: 0, height: '0px' },
    },
  );

  const onInsert = useCallback(() => {
    if (!filterName) return;

    void insertSegment({
      variables: {
        accountId,
        userId,
        name: filterName,
        filters: currentFilters,
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        return addToast([
          formatToastMessage(text.insertErrorMessage, 'danger'),
        ]);
      }

      if (data && data.insertSegment) {
        updateQuery(prev => ({
          ...prev,
          getMySegments: [data.insertSegment, ...prev.getMySegments],
        }));

        setActiveFilter({
          segmentId: data.insertSegment.id,
          filterId: null,
        });

        setFilterName('');

        return addToast([
          formatToastMessage(text.insertSuccessMessage, 'success'),
        ]);
      }
    });
  }, [
    accountId,
    addToast,
    currentFilters,
    filterName,
    insertSegment,
    setActiveFilter,
    updateQuery,
    userId,
  ]);

  useGlobalKeyBinding({
    keys: 'enter',
    callback: onInsert,
    enabled: true,
  });

  if (!segmentData || !segmentData.getMySegments) {
    return null;
  }

  const sortedSegments = sortByCreatedDate(
    segmentData.getMySegments,
  ) as Array<SegmentFieldsFragment>;

  const segmentList = showAllItems
    ? sortedSegments
    : sortedSegments.slice(0, ITEM_COUNT_TO_DISPLAY);

  return (
    <FilterBlockContainer data-testid={dataTestId}>
      <Heading4 withoutMargin variant={Variant.primary}>
        {text.title}
      </Heading4>

      <TipBanner
        id="contact-segments-tip"
        headerText={text.title}
        margin={['xxs', 'xs', 'xs', null]}
        dismissButtonPosition="start"
        fontSize="base"
      >
        {text.tipMessage}
        <br />
        <br />
        <ExternalLink
          to="https://help.dathuis.nl/nl/articles/5991139-het-filteren-van-de-contactenlijst"
          target="_blank"
          rel="noopener noreferrer"
        >
          {text.moreInfo}
        </ExternalLink>
      </TipBanner>

      {inputTransition((style, item: boolean) => (
        <>
          {item && (
            <InputContainer style={style}>
              <InputWithButtons
                value={filterName}
                onChange={e => {
                  setFilterName(e.target.value);
                }}
                buttonActions={[
                  {
                    onClick: onInsert,
                    loading: insertLoading,
                    disabled: isEmptyString(filterName),
                    dataTestId: TEST_ID.INSERT_SEGMENT_BUTTON,
                  },
                ]}
                name="insert-segment-input"
                placeholder={text.inputPlaceholder}
                label={text.inputLabel}
                disabled={insertLoading}
              />
            </InputContainer>
          )}
        </>
      ))}

      <ListContainer data-testid={TEST_ID.SEGMENT_LIST}>
        {segmentList.map(({ id, name, filters }) => (
          <SegmentRow
            key={id}
            id={id}
            name={name}
            filters={filters}
            currentFilters={currentFilters}
            onFilterChangeV2={onFilterChangeV2}
            updateQuery={updateQuery}
          />
        ))}
      </ListContainer>

      {segmentData.getMySegments.length > ITEM_COUNT_TO_DISPLAY && (
        <ShowMoreContainer onClick={() => setShowAllItems(!showAllItems)}>
          {showAllItems ? text.showLess : text.showMore}
        </ShowMoreContainer>
      )}
    </FilterBlockContainer>
  );
};

const InputContainer = styled(animated.div)(
  ({ theme }) => css`
    width: 100%;
    margin: 0 ${theme.space('xxs')} 0 0;
  `,
);

export default Segments;
