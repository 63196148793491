import { Flow_Action__Input } from '~/graphql/types';
import { WaitAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import { asFlowConditionListInput } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList';

export default (
  action: WaitAction,
  treeNode: ActionTreeNode,
  inputActionDefaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, conditionList } = action;
  const { accountId, flowBlueprintId } = inputActionDefaults;
  const { parentId } = treeNode;

  if (parentId == null)
    throw new Error(`Parentless WaitAction found! ID: ${id}`);

  const conditionInput = asFlowConditionListInput(conditionList);

  if (conditionInput == null)
    throw new Error(
      'Should not occur: Validation should have caught that there are no conditions!',
    );

  return {
    Wait: {
      id,
      accountId,
      flowBlueprintId,
      parentId,
      condition: conditionInput,
    },
  };
};
