import { FromAddress } from '~/graphql/types';
import { SendEmailPlainAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { emptyTemplateStringParameterValue } from '../Base';

const defaultProps = (
  baseActionProps: BaseActionProps,
): SendEmailPlainAction => ({
  ...baseActionProps,
  type: FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN,
  body: emptyTemplateStringParameterValue(),
  subject: emptyTemplateStringParameterValue(),
  attachments: [],
  links: [],
  isTransactional: false,
  from: FromAddress.ClosestToContact,
  signature: null,
});

export default defaultProps;
