import { navigate, RouteComponentProps } from '@reach/router';
import { assoc, map } from 'ramda';
import React, { useState } from 'react';
import { useInsertZapierTriggerMutation, ZapierTrigger } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import Details from '../../../Details';
import uuidv1 from 'uuid/v1';
import { ZapierFields } from '../../../Details/components/FieldsContainer';
import { breadcrumbs, text } from '../text';
import { cleanedFields } from '../../../utils/cleanedFields';
import { format } from '~/util/date';

export type Props = {
  dataTestId?: string;
} & RouteComponentProps;

const NewTrigger: React.FC<Props> = ({ dataTestId, ...rest }) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const [insertTrigger, { loading }] = useInsertZapierTriggerMutation();

  const defaultTrigger = {
    accountId,
    name: text.initialHeaderName,
    fields: [],
  };

  const [trigger, setTrigger] =
    useState<
      Omit<ZapierTrigger, '__typename' | 'id' | 'subscriptions' | 'createdDate'>
    >(defaultTrigger);

  const onSave = async (name: string, fields: ZapierFields) =>
    insertTrigger({
      variables: {
        accountId: trigger.accountId,
        name,
        fields: cleanedFields(fields),
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        return addToast([
          formatToastMessage(
            `Er is iets fout gegaan bij het toevoegen van "${trigger.name}" uitgaande koppeling, probeer het later opnieuw.`,
            'danger',
          ),
        ]);
      }

      addToast([
        formatToastMessage(
          'Uitgaande koppeling succesvol toegevoegd.',
          'success',
        ),
      ]);

      setTrigger({
        ...trigger,
        name,
        fields,
      });

      if (data) {
        const triggerId = data.insertZapierTrigger.id;
        return navigate(`/-/apps/zapier/triggers/${triggerId}`);
      }
    });

  const fieldsWithIds = map(
    field => assoc('id', uuidv1(), field),
    trigger.fields,
  );

  return (
    <Details
      {...rest}
      dataTestId={dataTestId}
      goBackLink={text.goBackLink}
      headerName={`${text.initialHeaderName} ${format(
        new Date(),
        'd MMM HH:mm',
      )}`}
      onSave={onSave}
      description={text.description}
      initialFields={fieldsWithIds}
      breadcrumbs={[...breadcrumbs, { label: trigger.name }]}
      loading={loading}
    >
      {text.children}
    </Details>
  );
};

export default NewTrigger;
