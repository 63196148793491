import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import CopyCode from '~/components/CopyCode';
import GoToPath from '~/components/GoToPath';
import Input from '~/components/Input';
import JustificationContainer from '~/components/JustificationContainer';
import Screenshot from '~/components/Screenshot';
import { Body } from '~/components/Typography/index';
import {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/Wizard/context/WizardContext';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import useWizard from '~/hooks/useWizard';
import useWizardStep from '~/hooks/useWizardStep';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import { isNonEmptyString } from '~/util/Validation/String';
import SkipStepButtonBlock from '~/components/WizardSteps/components/SkipStepButtonBlock';
import RealworksHelpBlock from '../components/RealworksHelpBlock';
import TEST_ID from './index.testid';

export const importContactsScreenshotLink =
  'https://dathuis-stash.imgix.net/realworks_api_manager.png';

const text = {
  header: 'Contacten importeren',
  description:
    'Om contacten te importeren uit RealWorks gebruiken we de Relaties API. Vul de API Manager in zoals op de onderstaande screenshot. Het is belangrijk dat de instellingen exact overeenkomen.',
  firstAppEntryHeader: '1. Ga in RealWorks naar',
  copyCodeLabel: '2. Plak ons Developer ID in Realworks',
  thirdAppEntryHeader: '3. Kopieer de “Token” en plak deze in dit veld',
  code: 'eb92a256-b27d-4747-80c4-4d4b45485cc8',
  skipStep: 'Stap overslaan',
  skipStepDescription:
    'Wil je geen contacten uit RealWorks importeren, alleen maar exporteren naar RealWorks?',
};

export const id = 'RealworksImportContacts';
export const title = text.header;

export type OutputType = {
  type: typeof id;
  relatieToken?: OutputFieldInvisible<
    UpdateRealworksAppStatus_AddTokenContainer__Input['relatie']
  >;
};

export const Component: React.FC<WizardStepProps> = ({ step, outputMap }) => {
  const [, api] = useWizardStep(step.id);
  const wizardAPI = useWizard();

  const previousToken =
    outputMap[id] && 'relatieToken' in outputMap[id]
      ? outputMap[id]?.relatieToken?.value ?? null
      : null;

  const [relatie, setRelatie] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input['relatie']>(
      previousToken,
    );

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const skipCurrentStep = () => wizardAPI.next();

  useEffect(() => {
    if (isNonEmptyString(relatie)) {
      api.free({
        type: id,
        relatieToken: {
          type: 'invisible',
          value: relatie,
        },
      });
    } else {
      api.free({
        type: id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatie]);

  return (
    <>
      <Body>{text.description}</Body>
      <SkipStepButtonBlock
        description={text.skipStepDescription}
        skipCurrentStep={skipCurrentStep}
        dataTestId={TEST_ID.NOTIFICATION_BUTTON}
        margin={['m', null, null]}
      />
      <RealworksHelpBlock tipId="import-contacts-step-tip" />

      <form onSubmit={onSubmit} data-testid={TEST_ID.FORM}>
        <StyledAppEntryItemHeader>
          {text.firstAppEntryHeader}
        </StyledAppEntryItemHeader>
        <GoToPath
          path={[
            'Servicedesk',
            'Marketplace',
            "API's",
            'Relatie API',
            'Bestellen',
          ]}
          label="Ga naar marketplace"
          link="https://crm.realworks.nl/servlets/objects/admin.marketplace/overview"
        />

        <JustificationContainer
          justification="start"
          margin={['s', null, null, null]}
        >
          <CopyCode
            label={text.copyCodeLabel}
            code={text.code}
            style={{ width: '100%' }}
          />
        </JustificationContainer>

        <JustificationContainer justification="center" margin={['s', null]}>
          <Screenshot
            src={importContactsScreenshotLink}
            alt="screenshot"
            withoutShadow
          />
        </JustificationContainer>
        <AppEntryItemHeader>{text.thirdAppEntryHeader}</AppEntryItemHeader>
        <Input
          appearance="primary"
          validation={[
            value =>
              value === '' || isNil(value) ? 'Vul een waarde in' : true,
          ]}
          validationIndicator
          value={relatie ?? ''}
          onChange={e => setRelatie(e.target.value)}
          dataTestId={TEST_ID.TOKEN_INPUT}
        />
      </form>
    </>
  );
};

const StyledAppEntryItemHeader = styled(AppEntryItemHeader)<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('l')};
  `,
);

export default {
  id,
  title,
};
