import uuidv1 from 'uuid/v1';

/**
 * Backend does not allow a '-' so remove it.
 */
const getNewMappingId = () => {
  const id = uuidv1();
  const replaced = id.replace(/-/g, '');

  return replaced;
};

export default getNewMappingId;
