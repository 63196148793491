import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  BOLD_BUTTON: `${cleanedFilename(__filename)}-bold-button`,
  ITALIC_BUTTON: `${cleanedFilename(__filename)}-italic-button`,
  UNDERLINE_BUTTON: `${cleanedFilename(__filename)}-underline-button`,
  OL_BUTTON: `${cleanedFilename(__filename)}-ol-button`,
  UL_BUTTON: `${cleanedFilename(__filename)}-ul-button`,
  INDENT_BUTTON: `${cleanedFilename(__filename)}-indent-button`,
  OUTDENT_BUTTON: `${cleanedFilename(__filename)}-outdent-button`,
  ALIGN_BUTTON: `${cleanedFilename(__filename)}-align-button`,
  COLOR_BUTTON: `${cleanedFilename(__filename)}-color-button`,
  BACKGROUND_COLOR_BUTTON: `${cleanedFilename(
    __filename,
  )}-background-color-button`,
  FONT_SIZE_BUTTON: `${cleanedFilename(__filename)}-font-size-button`,
  FONT_FAMILY_BUTTON: `${cleanedFilename(__filename)}-font-family-button`,
  INSERT_IMAGE_BUTTON: `${cleanedFilename(__filename)}-insert-image-button`,
  INSERT_LINK_BUTTON: `${cleanedFilename(__filename)}-insert-link-button`,
  INSERT_HTML_BUTTON: `${cleanedFilename(__filename)}-insert-html-button`,
  INSERT_VARIABLE_BUTTON: `${cleanedFilename(
    __filename,
  )}-insert-variable-button`,
  BLOCKQUOTE_BUTTON: `${cleanedFilename(__filename)}-blockquote-button`,
  UNDO_BUTTON: `${cleanedFilename(__filename)}-undo-button`,
  REDO_BUTTON: `${cleanedFilename(__filename)}-redo-button`,
};
