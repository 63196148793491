import hash from '~/util/hash';
import { HandlerId } from '../../components/nodeTypes/components/IfElseCard';

/**
 * Return portal color depending on action index
 * @param {number} index -portal index
 * keywords: portal color
 */
const getPortalColor = ({
  portalColors,
  id,
  sourceHandle,
}: {
  portalColors: Array<string>;
  id: string;
  sourceHandle: HandlerId | undefined;
}): string => {
  let sourceId = '0';
  if (sourceHandle) {
    if (sourceHandle === HandlerId.trueChild) sourceId = '1';
    if (sourceHandle === HandlerId.falseChild) sourceId = '2';
  }

  const uniqueStr: string = id + sourceId;
  const hashedValue = hash(uniqueStr);

  const index = hashedValue % portalColors.length;

  return portalColors[index];
};

export default getPortalColor;
