import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import S3FileUpload, {
  UploadedFileDetails,
  UploadSuccessResponse,
} from '~/components/S3FileUpload';
import { Attachment } from '../..';
import blobToDataURL from '../../utils/blobToDataURL';
import getAllContentLength from '../../utils/getAllContentLength';
import TEST_ID from './index.testid';
import { ListItem } from '../../../Selector';
import { FlowPath } from '../../../Selector/utils/getFieldsByPath';

export type Props = {
  dataTestId?: string;
  attachments: Array<Attachment>;
  onFileUploaded: (newAttachment: Attachment) => void;
  onError: (error: string | null) => void;
  isPointerUpdating: boolean;
  /** Passed from Selector component */
  currentPath?: FlowPath;
};

const SINGLE_FILE_SIZE_LIMIT = 10000000; // 10MB in bytes
const TOTAL_FILE_SIZE_LIMIT = SINGLE_FILE_SIZE_LIMIT * 2; // 20MB in bytes

const text = {
  attachFile: 'Upload een bestand',
  fileSizeError:
    'De bijlagen zijn te groot, een e-mail mag maximaal 20MB aan bijlagen bevatten',
};

const UploadAttachment: React.FC<Props> = ({
  attachments,
  onFileUploaded,
  onError,
  isPointerUpdating,
  ...rest
}) => {
  const uploadedAttachments = useRef<Array<UploadedFileDetails>>([]);

  const setUploadingFile = (newFile: UploadedFileDetails) => {
    const allContentLength = getAllContentLength(attachments);

    if (
      newFile.contentLength >= SINGLE_FILE_SIZE_LIMIT ||
      allContentLength + newFile.contentLength >= TOTAL_FILE_SIZE_LIMIT
    ) {
      return onError(text.fileSizeError);
    }
    onError(null);
    uploadedAttachments.current.push(newFile);
  };

  const onUploadSucceeded = useCallback(
    async ({
      fileId,
      Body,
      key,
      filename,
      ContentLength,
      ContentType,
    }: UploadSuccessResponse) => {
      const allUploadingFileIds = uploadedAttachments.current.map(
        file => file.fileId,
      );

      if (allUploadingFileIds.includes(fileId)) {
        const url = await blobToDataURL(Body);
        const newAttachment = {
          __typename: 'FlowV2_EmailAttachment' as const,
          url,
          file: {
            __typename: 'Flow___Argument_File' as const,
            value_file: {
              __typename: 'Flow___File' as const,
              s3key: key,
              filename,
              contentLength: ContentLength,
              contentType: ContentType,
            },
          },
        };

        onFileUploaded(newAttachment);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (rest.currentPath?.length !== 0 || !isPointerUpdating) return null;

  return (
    <ListItem>
      <S3FileUpload
        fileDetails={null}
        onUploadSucceeded={onUploadSucceeded}
        setUploadingFile={setUploadingFile}
        allowedFileExtensions={['*']}
        uploadButtonLabel={text.attachFile}
        onFileRemoved={() => {}}
        uploadButton={({ onClick, ...props }) => (
          <StyledButton
            {...props}
            data-testid={TEST_ID.ADD_PRIMITIVE_ATTACHMENT_BUTTON}
            onClick={onClick}
          >
            {text.attachFile}
          </StyledButton>
        )}
        dataTestId={TEST_ID.CONTAINER}
      />
    </ListItem>
  );
};

const StyledButton = styled.button<{}>(
  () => css`
    border: none;
    background: inherit;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    cursor: pointer;
  `,
);

export default UploadAttachment;
