import gql from 'graphql-tag';

export default gql`
  fragment SessionHydrationOfficeFields on Office {
    __typename
    id
    name
    email
    phone
    address {
      street
      houseNumber
      addition
      postcode
      city
    }
    img {
      medium
    }
    deleted
  }
`;
