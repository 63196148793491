export enum FLOW_CONDITION_PRIMITIVE_STRING_OPERATION {
  ENDS_WITH = 'Flow_ConditionPrimitive_String_EndsWith',
  EQUALS = 'Flow_ConditionPrimitive_String_Eq',
  STARTS_WITH = 'Flow_ConditionPrimitive_String_StartsWith',
  CONTAINS = 'Flow_ConditionPrimitive_String_Contains',
}

export enum STRING_OPERATION_OPTION {
  ENDS_WITH = 'String_EndsWith',
  EQUALS = 'String_Equals',
  STARTS_WITH = 'String_StartsWith',
  CONTAINS = 'String_Contains',
  DOES_NOT_END_WITH = 'String_DoesNotEndWith',
  DOES_NOT_EQUAL = 'String_DoesNotEqual',
  DOES_NOT_START_WITH = 'String_DoesNotStartWith',
  DOES_NOT_CONTAIN = 'String_DoesNotContain',
}

export const STRING_OPERATION_OPTIONS_LABELS: {
  [key: string]: { label: string };
} = Object.freeze({
  [STRING_OPERATION_OPTION.STARTS_WITH]: {
    label: 'Begint met',
  },
  [STRING_OPERATION_OPTION.DOES_NOT_START_WITH]: {
    label: 'Begint niet met',
  },
  [STRING_OPERATION_OPTION.EQUALS]: {
    label: 'Is gelijk aan',
  },
  [STRING_OPERATION_OPTION.DOES_NOT_EQUAL]: {
    label: 'Is niet gelijk aan',
  },
  [STRING_OPERATION_OPTION.ENDS_WITH]: {
    label: 'Eindigt op',
  },
  [STRING_OPERATION_OPTION.DOES_NOT_END_WITH]: {
    label: 'Eindigt niet op',
  },
  [STRING_OPERATION_OPTION.CONTAINS]: {
    label: 'Bevat',
  },
  [STRING_OPERATION_OPTION.DOES_NOT_CONTAIN]: {
    label: 'Bevat geen',
  },
});
