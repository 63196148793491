import React from 'react';

import {
  TaskListTaskFieldsFragment,
  GetTasksQueryVariables,
  GetTasksQuery as GetTasksQueryResult,
} from '~/graphql/types';
import { TaskListType } from '~/scenes/Tasks/util/taskListType';
import { TaskGetterChildProps } from './TaskWithSubscriptionGetter';

import {
  GetTasksWithSubscriptionQuery,
  GetTasksWithSubscriptionUpdateQueryOptions,
} from '~/graphql';
import getTaskQuery from '~/graphql/query/GetTasks';
import mutatedTaskSubscription from '~/graphql/subscriptions/MutatedTask';
import { Loading } from '~/components';
import AppErrorScreen, {
  UnauthorizedAppErrorScreen,
} from '~/components/AppErrorScreen';
import { filteredAndMappedTasks, TASK_LIST_LIMIT } from '.';
import updateQueryCacheOnUpdateOrInsert from './util/updateQueryCacheOnUpdateOrInsert';
import baseQueryVariablesForListType from './util/baseQueryVariablesForListType';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import cleanedFilename from '~/util/cleanedFilename';
import ErrorTypes from '~/ErrorTypes';
import { getErrorType } from '~/util/errorHandling';

type Props = {
  accountId: string;
  filteredOfficeId: string;
  filteredUserId: string;
  selectedListType: TaskListType;
  children: (props: TaskGetterChildProps) => JSX.Element;
};
type State = {};
class GetOfficeUserTasks extends React.Component<Props, State> {
  render() {
    const {
      accountId,
      filteredOfficeId,
      filteredUserId,
      selectedListType,
      children,
    } = this.props;

    const listTypeQueryVariables =
      baseQueryVariablesForListType(selectedListType);

    const baseVariables: GetTasksQueryVariables = {
      ...listTypeQueryVariables,
      accountId,
      officeId: filteredOfficeId,
      limit: TASK_LIST_LIMIT,
      unassigned: true,
      userId: undefined,
    };

    if (filteredUserId != null && filteredUserId !== 'no-selection') {
      baseVariables.userId = filteredUserId;
    }

    return (
      <GetTasksWithSubscriptionQuery
        name={cleanedFilename(__filename)}
        query={getTaskQuery}
        variables={baseVariables}
        subscriptionQuery={mutatedTaskSubscription}
        subscriptionVariables={{
          accountId,
        }}
        updateQuery={(previousResult, { subscriptionData }) => {
          // If the mutation errors out the previousResult is undefined
          if (previousResult == null) {
            return previousResult;
          }

          const { mutatedTask } = subscriptionData.data;

          const { nextToken } = previousResult.getTasks;
          const hasMore = Boolean(nextToken);

          return newListForGetTasks(
            previousResult,
            mutatedTask, // We have to use 'this' to make sure this is always the latest reference. The SubscribedQuery component does not rerender on the selectedListType prop change
            this.props.selectedListType,
            hasMore,
          );
        }}
        doNotReportErrorTypes={[ErrorTypes.unauthorisedError]}
      >
        {({ networkStatus, data, error, fetchMore, updateQuery }) => {
          if (isInitialLoadStatus(networkStatus)) return <Loading />;
          if (error && getErrorType(error) === ErrorTypes.unauthorisedError)
            return <UnauthorizedAppErrorScreen />;
          if (!data || error) {
            return <AppErrorScreen />;
          }

          const { items, nextToken } = data.getTasks;

          const loadMore = () =>
            fetchMore({
              variables: {
                ...baseVariables,
                nextToken: nextToken || null,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previousResult;

                const prevData = previousResult.getTasks;
                const newData = fetchMoreResult.getTasks;

                return {
                  ...previousResult,
                  getTasks: {
                    ...prevData,
                    items: [...prevData.items, ...(newData.items || [])],
                    nextToken: newData.nextToken,
                  },
                };
              },
            });

          const updateQueryWithTaskFunction = (
            insertedOrUpdatedTask: TaskListTaskFieldsFragment,
          ) => {
            updateQuery(
              getUpdateQueryFunction(
                insertedOrUpdatedTask,
                selectedListType,
                nextToken,
              ),
            );
          };

          return children({
            loadMore,
            error,
            networkStatus,
            hasMore: Boolean(nextToken),
            tasks: filteredAndMappedTasks(
              items,
              filteredUserId,
              filteredOfficeId,
            ),
            onTaskChanged: updateQueryWithTaskFunction,
          });
        }}
      </GetTasksWithSubscriptionQuery>
    );
  }
}

const newListForGetTasks = (
  previousResult: GetTasksQueryResult,
  insertedOrUpdatedTask: TaskListTaskFieldsFragment | null | undefined,
  selectedListType: TaskListType,
  hasMore: boolean,
): GetTasksQueryResult => {
  const { items } = previousResult.getTasks;

  const newList = updateQueryCacheOnUpdateOrInsert(
    items,
    insertedOrUpdatedTask,
    selectedListType,
    hasMore,
  );

  return {
    ...previousResult,
    getTasks: { ...previousResult.getTasks, items: newList },
  };
};

const getUpdateQueryFunction =
  (
    insertedOrUpdatedTask: TaskListTaskFieldsFragment,
    selectedListType: TaskListType,
    nextToken: string | null | undefined,
  ): ((
    previousQueryResult: GetTasksQueryResult,
    options: GetTasksWithSubscriptionUpdateQueryOptions,
  ) => GetTasksQueryResult) =>
  previousResult => {
    const hasMore = Boolean(nextToken);

    const newList = newListForGetTasks(
      previousResult,
      insertedOrUpdatedTask,
      selectedListType,
      hasMore,
    );

    return newList;
  };

export default GetOfficeUserTasks;
