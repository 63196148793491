import {
  Flow_Parameter__String__Input,
  Flow_Parameter__Office__Input,
  Flow_Parameter__User__Input,
  Flow_Parameter__Email__Input,
  Flow_Parameter__Number__Input,
  Flow_Parameter__Boolean__Input,
  Flow_Parameter__File__Input,
} from '~/graphql/types';
import { ParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from './constants';
import { asStringParameterValueInput } from './String';
import { asOfficeParameterValueInput } from './Office';
import { asUserParameterValueInput } from './User';
import { asEmailParameterValueInput } from './Email';
import { asNumberParameterValueInput } from './Number';
import { asBooleanParameterValueInput } from './Boolean';
import { asFileParameterValueInput } from './File';

import cleanedFilename from '~/util/cleanedFilename';

type FlowParameterInput =
  | Flow_Parameter__String__Input
  | Flow_Parameter__Office__Input
  | Flow_Parameter__User__Input
  | Flow_Parameter__Email__Input
  | Flow_Parameter__Number__Input
  | Flow_Parameter__Boolean__Input
  | Flow_Parameter__File__Input;

export default (parValue: ParameterValue): FlowParameterInput => {
  switch (parValue.type) {
    case PARAMETER_VALUE_TYPE.STRING_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.STRING_POINTER:
      return asStringParameterValueInput(parValue);
    case PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.OFFICE_POINTER:
      return asOfficeParameterValueInput(parValue);
    case PARAMETER_VALUE_TYPE.USER_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.USER_POINTER:
      return asUserParameterValueInput(parValue);
    case PARAMETER_VALUE_TYPE.EMAIL_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.EMAIL_POINTER:
      return asEmailParameterValueInput(parValue);
    case PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.NUMBER_POINTER:
      return asNumberParameterValueInput(parValue);
    case PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.BOOLEAN_POINTER:
      return asBooleanParameterValueInput(parValue);

    case PARAMETER_VALUE_TYPE.FILE_PRIMITIVE:
    case PARAMETER_VALUE_TYPE.FILE_POINTER:
      return asFileParameterValueInput(parValue);
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Field value input conversion cannot handle type ${
          parValue.type
        }`,
      );
  }
};
