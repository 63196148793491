import { ActionTreeNode } from './types.flow';

import traverse from './traverse';

const getActionTreeNode = (
  startNode: ActionTreeNode,
  findId: string,
): ActionTreeNode | null => {
  let foundNode: ActionTreeNode | null = null;

  traverse(startNode, node => {
    if (node.id === findId) {
      foundNode = node;
    }
  });

  return foundNode;
};

export default getActionTreeNode;
