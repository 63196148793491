import { FlowParameterUserFieldsFragment } from '~/graphql/types';
import { UserParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyUserPrimitiveParameterValue from './emptyUserPrimitiveParameterValue';

export default (
  userParameter: FlowParameterUserFieldsFragment | null | undefined,
): UserParameterValue => {
  if (userParameter == null) {
    return emptyUserPrimitiveParameterValue(null);
  }
  switch (userParameter.__typename) {
    case PARAMETER_VALUE_TYPE.USER_PRIMITIVE:
      return {
        type: PARAMETER_VALUE_TYPE.USER_PRIMITIVE,
        value: userParameter.userId ? userParameter.userId : null,
      };
    case PARAMETER_VALUE_TYPE.USER_POINTER:
      return {
        type: PARAMETER_VALUE_TYPE.USER_POINTER,
        variable: {
          name: userParameter.variable,
          field: userParameter.field
            ? {
                name: userParameter.field,
              }
            : null,
        },
      };
    default:
      throw new Error(
        `Should not occur: Unknown __typename (${userParameter.__typename}) for UserParameter`,
      );
  }
};
