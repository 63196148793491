import { OfficeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';

export default (parameterValue: OfficeParameterValue): boolean => {
  switch (parameterValue.type) {
    case PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE:
      return parameterValue.value == null;
    case PARAMETER_VALUE_TYPE.OFFICE_POINTER:
      return (
        parameterValue.variable == null || parameterValue.variable.field == null
      );
    default:
      throw new Error(
        `Incorrect office parameter value type (${JSON.stringify(
          parameterValue,
          null,
          2,
        )})`,
      );
  }
};
