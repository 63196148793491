import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { EmailSignature, FromAddress } from '~/graphql/types';
import { SendEmailPlainAction } from './types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import {
  FlowVariableStash,
  FlowEmailLink,
} from '~/scenes/Automation/Flows/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';
import {
  TemplateStringParameterValue,
  FlowEmailAttachmentValue,
  StringPointerParameterValue,
  FilePointerParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { AccountContextProps } from '~/contexts/AccountContext';

import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import asSendEmailPlainActionInput from './asSendEmailPlainActionInput';
import { FLOW_ACTION_TYPE } from '../constants';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import ActionExplanationHeader from '../ActionExplanationHeader';
import ActionContentContainer from '../ActionContentContainer';
import {
  emptyFilePointerParameterValue,
  FilePointerParameterValueComponent,
} from '../Base/FlowParameter/ParameterValue';
import { InputGroup, SelectGroup, Dropdown } from '~/components/Inputs';
import { ParameterTemplateStringFieldComponent } from '../Base/FlowParameter/FlowParameterField/FlowParameterTemplateStringField';
import { LinkButton, DeleteInputButton } from '~/components/Buttons';
import {
  copyWithoutIndex,
  areStringArraysEqual,
  mapNotNull,
} from '~/util/array';
import { INPUT_GROUP_TOP_BOTTOM_MARGIN } from '~/components/Inputs/InputGroup';
import {
  getDeduplicatedLinksFrom,
  isOverMaxEmailSize,
} from '~/components/HTMLEditor/util';
import asEmailVariable from './asEmailVariable';
import TEST_ID from './SendEmailPlainActionComponent.testid';
import { PARAMETER_VALUE_TYPE } from '../Base/FlowParameter/ParameterValue/constants';
import { FLOW_EMAILATTACHMENT_TYPE } from '../Base/FlowEmailAttachment/constants';
import { AUTHORISE_LEVEL } from '~/util/constants';
import AccountContext from '~/contexts/AccountContext';
import HelpButton from '~/components/Buttons/HelpButton';
import FloatingLabel from '~/components/FloatingLabel';
import S3FileUpload, {
  UploadedFileDetails,
  UploadSuccessResponse,
} from '~/components/S3FileUpload';
import EmailFileAttachment, {
  isEmailFileAttachment,
} from '~/components/EmailFileAttachment';
import EmailFileAttachmentUploading from '~/components/EmailFileAttachment/Uploading';
import { deepEquals } from '~/util/object';
import ErrorModal from '~/components/Alerts/ErrorModal';

const SINGLE_FILE_SIZE_LIMIT = 10000000; // 10MB in bytes
const TOTAL_FILE_SIZE_LIMIT = SINGLE_FILE_SIZE_LIMIT * 2; // 20MB in bytes

const text = {
  emailTypeLabel: 'Type',
  explanation: 'E-mail versturen',
  subjectLabel: 'Onderwerp',
  addAttachmentButtonLabel: 'uit een stap',
  selectAttachmentPointerStepLabel: 'Selecteer stap',
  selectAttachmentPointerFieldLabel: 'Selecteer bijlage',
  attachmentsHeader: 'Bijlagen toevoegen',
  attachFile: 'upload een bestand',
  marketingLabel: 'Marketing',
  transactionalLabel: 'Transactioneel',
  needsUnsubscribeLinkText:
    'Bij marketing e-mails is het verplicht om een uitschrijflink toe te voegen. Voeg een uitschrijflink toe met de button in de toolbar.',
  isOverMaxEmailSizeErrorMessage:
    'De bijlagen zijn te groot, een e-mail mag maximaal 20MB aan bijlagen bevatten',
  fromClosestEntity: 'Eigenaar van het contact',
  signatureOptions: 'Handtekening',
  noSignature: 'Geen handtekening',
  fromAddressSignatureLabel: 'Hetzelfde als vanaf adres',
  closestEntitySignatureLabel: 'Eigenaar van het contact',
  warningMessage:
    'Zorg ervoor dat alle gebruikers een handtekening hebben ingesteld als je deze optie selecteert',
};
type MyProps = ActionSubscriberProps & {
  id: string;
  positionString: string;
  initialActionProps: SendEmailPlainAction;
  isHiddenFromView: boolean;
};
type Props = WithValidatorListProps & MyProps;
type State = {
  /** To rerender the component you can update this number. Needed to rerender the description */
  renderChangeCounter: number;
  key: string | null;
  initialBody: TemplateStringParameterValue;
  initialSubject: TemplateStringParameterValue;
  initialInlineAttachments: Array<FlowEmailAttachmentValue>;
  attachments: Array<FlowEmailAttachmentValue>;
  prevLinks: Array<FlowEmailLink>;
  variableStashVersion: string | null;
  isTransactional: boolean;
  fromAddress: FromAddress | null;
  selectedSignature: EmailSignature | null;
  uploadingAttachments: Array<UploadedFileDetails>;
  fileUploadError: boolean;
};

const blobToDataURL = (blob: Blob): Promise<string> =>
  new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = function (
      this: FileReader,
      ev: ProgressEvent<FileReader>,
    ) {
      if (typeof ev.target?.result === 'string') {
        resolve(ev.target.result);
      }
    };
    fileReader.readAsDataURL(blob);
  });
class SendEmailPlainActionComponent extends React.Component<Props, State> {
  _isMounted = false;
  variableStash: FlowVariableStash = emptyVariableStash();
  getBody: () => TemplateStringParameterValue = () =>
    this.props.initialActionProps.body;
  getBodyInlineAttachments: () => Array<FlowEmailAttachmentValue> = () =>
    this.props.initialActionProps.attachments;
  getSubject: () => TemplateStringParameterValue = () =>
    this.props.initialActionProps.subject;

  constructor(props: Props) {
    super(props);

    const { initialActionProps } = props;

    const {
      body,
      subject,
      attachments,
      links,
      isTransactional,
      from,
      signature,
    } = initialActionProps;

    this.state = {
      renderChangeCounter: 0,
      key: null,
      initialBody: body,
      initialSubject: subject,
      attachments: attachments.filter(
        attachment => attachment.inlineId == null,
      ),
      initialInlineAttachments: attachments.filter(
        attachment => attachment.inlineId != null,
      ),
      prevLinks: links,
      isTransactional,
      variableStashVersion: null,
      fromAddress: from,
      selectedSignature: signature,
      uploadingAttachments: [],
      fileUploadError: false,
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { isHiddenFromView } = nextProps;
    const { variableStashVersion: nextVariableStashVersion } = nextState;
    const { variableStashVersion } = this.state;

    if (nextVariableStashVersion !== variableStashVersion) {
      return true;
    }

    return !isHiddenFromView;
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asSendEmailPlainActionInput(this.getActionProp(), treeNode, defaults);

  setGetSubject = (getSubjectFn: () => TemplateStringParameterValue) => {
    this.getSubject = getSubjectFn;
  };

  setGetBody = (getBodyFn: () => TemplateStringParameterValue) => {
    this.getBody = getBodyFn;
  };

  setGetBodyInlineAttachments = (
    getBodyInlineAttachmentsFn: () => Array<FlowEmailAttachmentValue>,
  ) => {
    this.getBodyInlineAttachments = getBodyInlineAttachmentsFn;
  };

  bodyUpdated = () => {
    this.updateBody(this.getBody());
  };

  asVariable = (): StringPointerParameterValue =>
    asEmailVariable(this.props.id);

  updateBody = (newBody: TemplateStringParameterValue) => {
    if (this._isMounted) {
      const { prevLinks } = this.state;

      const newLinks = getDeduplicatedLinksFrom(
        newBody.template,
        this.asVariable(),
      );

      const hasChangedLinks = !areStringArraysEqual(
        newLinks.map(namedLink => namedLink.link),
        prevLinks.map(namedLink => namedLink.link),
      );

      this.setState(
        {
          prevLinks: newLinks,
        },
        () => {
          this.props.announceChanges({
            updateVariableStash: hasChangedLinks,
          });
        },
      );
    }
  };

  handleTransactionalChange = ({ option }) => {
    if (this._isMounted) {
      this.setState(
        {
          isTransactional: option.value,
        },
        () => {
          this.props.announceChanges();
        },
      );
    }
  };

  updateAttachments = (newAttachments: Array<FlowEmailAttachmentValue>) => {
    if (this._isMounted) {
      this.props.announceChanges();

      const attachmentFilenames = newAttachments
        .map(
          attachment =>
            isEmailFileAttachment(attachment) && attachment.file.filename,
        )
        .filter(x => x);

      this.setState(({ uploadingAttachments }) => ({
        attachments: newAttachments,
        uploadingAttachments: uploadingAttachments.filter(
          ({ filename }) => !attachmentFilenames.includes(filename),
        ),
      }));
    }
  };

  setVariableStash = (newStash: FlowVariableStash) => {
    this.variableStash = newStash;

    if (this._isMounted) {
      this.setState({
        variableStashVersion: newStash.version,
      });
    }
  };

  isOverMaxEmailSize = (): boolean => {
    const { attachments } = this.state;

    const body = this.getBody().template;
    const inlineAttachments = this.getBodyInlineAttachments();
    const attachmentLengths = [...inlineAttachments, ...attachments].map(
      attachment => {
        const { file } = attachment;

        if (file.type === PARAMETER_VALUE_TYPE.FILE_PRIMITIVE) {
          return file.contentLength || 0;
        } else {
          // cannot determine size of pointers;
          return 0;
        }
      },
    );

    return isOverMaxEmailSize(body, attachmentLengths);
  };

  validate = () => {
    // call this so children know we are validating
    const childResult = this.props.validate();

    if (this.isOverMaxEmailSize()) {
      return false;
    }

    return childResult;
  };

  getLinks = () =>
    getDeduplicatedLinksFrom(this.getBody().template, this.asVariable());

  getActionProp = (): SendEmailPlainAction => {
    const { id } = this.props;
    const { attachments, isTransactional, fromAddress, selectedSignature } =
      this.state;

    return {
      id,
      type: FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN,
      subject: this.getSubject(),
      body: this.getBody(),
      attachments: [...this.getBodyInlineAttachments(), ...attachments],
      links: this.getLinks(),
      isTransactional,
      from: fromAddress,
      signature: selectedSignature,
    };
  };

  onUploadAttachment = (file: UploadedFileDetails) => {
    const allContentLength = this.state.attachments
      .filter(isEmailFileAttachment)
      .reduce((acc, { file }) => {
        if (file && file.contentLength) {
          return acc + file.contentLength;
        }
        return acc;
      }, 0);

    if (
      file.contentLength >= SINGLE_FILE_SIZE_LIMIT ||
      allContentLength + file.contentLength >= TOTAL_FILE_SIZE_LIMIT
    ) {
      this.setState(() => ({ fileUploadError: true }));
      return;
    }

    this.setState(({ uploadingAttachments }) => ({
      uploadingAttachments: [...uploadingAttachments, file],
    }));
  };

  onAttachmentUploaded = async (uploadResponse: UploadSuccessResponse) => {
    const allUploadingFileIds = this.state.uploadingAttachments.map(
      ({ fileId }) => fileId,
    );

    // Only add files that are validated to be able to upload
    if (allUploadingFileIds.includes(uploadResponse.fileId)) {
      const url = await blobToDataURL(uploadResponse.Body);
      const newAttachmentList = [
        ...this.state.attachments,
        {
          type: FLOW_EMAILATTACHMENT_TYPE.FLOW_EMAILATTACHMENT,
          url,
          file: {
            type: PARAMETER_VALUE_TYPE.FILE_PRIMITIVE,
            s3key: uploadResponse.key,
            filename: uploadResponse.filename,
            contentLength: uploadResponse.ContentLength,
            contentType: uploadResponse.ContentType,
          },
        } as FlowEmailAttachmentValue,
      ];

      this.updateAttachments(newAttachmentList);
    }
  };

  render() {
    const {
      initialSubject,
      initialBody,
      attachments,
      initialInlineAttachments,
      isTransactional,
      fromAddress,
      selectedSignature,
      uploadingAttachments,
      fileUploadError,
    } = this.state;
    const {
      subscribeValidator,
      unsubscribeValidator,
      announceChanges,
      isHiddenFromView,
      positionString,
    } = this.props;
    const action = this.getActionProp();

    const pointerAttachments = attachments.filter(
      attachment => attachment.file.type === PARAMETER_VALUE_TYPE.FILE_POINTER,
    );

    const fileAttachments = attachments.filter(
      attachment =>
        attachment.file.type === PARAMETER_VALUE_TYPE.FILE_PRIMITIVE &&
        attachment.url != null,
    );

    const pointerAttachmentsList = mapNotNull(
      pointerAttachments,
      attachment => {
        const originalIndex = attachments.findIndex(origin =>
          deepEquals(origin, attachment),
        );

        return (
          <InputGroup
            key={`attachment-${originalIndex}`}
            data-testid={TEST_ID.ATTACHMENT_LIST_ITEM}
          >
            <FilePointerParameterValueComponent
              value={attachment.file as FilePointerParameterValue}
              onChange={changedFilePointer => {
                const newAttachmentList = [...attachments];

                newAttachmentList[originalIndex] = {
                  ...newAttachmentList[originalIndex],
                  file: changedFilePointer,
                };

                this.updateAttachments(newAttachmentList);
              }}
              variableLabel={text.selectAttachmentPointerStepLabel}
              fieldLabel={text.selectAttachmentPointerFieldLabel}
            />
            <DeleteInputButton
              onClick={() => {
                const newAttachmentList = copyWithoutIndex(
                  attachments,
                  originalIndex,
                );

                this.updateAttachments(newAttachmentList);
              }}
              dataTestId={TEST_ID.DELETE_ATTACHMENT_BUTTON}
            />
          </InputGroup>
        );
      },
    );

    const fileAttachmentsList = mapNotNull(fileAttachments, attachment => {
      const originalIndex = attachments.findIndex(origin =>
        deepEquals(origin, attachment),
      );

      if (isEmailFileAttachment(attachment)) {
        return (
          <EmailFileAttachment
            attachment={attachment}
            onDelete={() => {
              const newAttachmentList = copyWithoutIndex(
                attachments,
                originalIndex,
              );
              this.updateAttachments(newAttachmentList);
            }}
            key={attachment.file.filename}
          />
        );
      }
      return null;
    });

    const uploadingAttachmentsList = uploadingAttachments.map(
      (filename, index) => (
        <EmailFileAttachmentUploading key={`${filename}-${index}-uploading`} />
      ),
    );

    return [
      <AccountContext.Consumer key="consumer">
        {({ availableEmailsToSendFrom }: AccountContextProps) => {
          const accountEmail = availableEmailsToSendFrom.find(
            email => email.authoriseLevel === AUTHORISE_LEVEL.ACCOUNT,
          );

          let emailText = '';
          if (accountEmail != null) {
            const { emailToShow } = accountEmail.namedEmail;

            emailText = `- ${emailToShow ? emailToShow : ''}`;
          }

          const labelComponent = (
            <LabelContainer>
              <FloatingLabel>Van</FloatingLabel>
              <StyledHelpButton
                link={{
                  link: 'https://help.dathuis.nl/nl/articles/3959108-wat-is-het-verschil-tussen-een-marketing-en-transactionele-e-mail',
                }}
              />
            </LabelContainer>
          );

          const fromAddressOptions = [
            {
              key: FromAddress.ClosestToContact,
              label: text.fromClosestEntity,
              payload: FromAddress.ClosestToContact,
            },
            {
              key: 'Account',
              label: `Account ${emailText}`,
              payload: null,
            },
          ];

          return (
            <FlowContext.Consumer>
              {({ showValidation }) => {
                let infoComponent: ReactElement | null = null;
                if (showValidation) {
                  if (this.isOverMaxEmailSize()) {
                    infoComponent = (
                      <InfoContainer
                        error={true}
                        data-testid={TEST_ID.INFO_CONTAINER}
                      >
                        {text.isOverMaxEmailSizeErrorMessage}
                      </InfoContainer>
                    );
                  }
                }

                return (
                  <BaseActionComponent
                    positionString={positionString}
                    actionLabel={text.explanation}
                    action={action}
                    variableStash={this.variableStash}
                    subscribeValidator={subscribeValidator}
                    unsubscribeValidator={unsubscribeValidator}
                    isHiddenFromView={isHiddenFromView}
                  >
                    <ActionExplanationHeader title={text.explanation} />
                    <ActionContentContainer>
                      <InputGroup>
                        <DropdownContainer>
                          {labelComponent}
                          <Dropdown
                            dataTestid={TEST_ID.FROM_ADDRESS_DROPDOWN}
                            options={fromAddressOptions}
                            selectedOptionIdx={fromAddressOptions.findIndex(
                              option => option.payload === fromAddress,
                            )}
                            onChange={({ option }) => {
                              this.setState({
                                fromAddress: option.payload,
                              });

                              announceChanges();
                            }}
                          />
                        </DropdownContainer>
                        <SelectGroup
                          dataTestid={TEST_ID.IS_TRANSACTIONAL_SWITCHER}
                          label={text.emailTypeLabel}
                          options={transactionalOptions}
                          selectedIndex={transactionalOptions.findIndex(
                            option => option.value === isTransactional,
                          )}
                          onChange={this.handleTransactionalChange}
                          helpLink={
                            <StyledHelpButton
                              link={{
                                link: 'https://help.dathuis.nl/nl/articles/4247794-automatisch-e-mail-sturen-vanuit-de-eigenaar-van-het-contact',
                              }}
                            />
                          }
                        />
                      </InputGroup>
                      <InputGroup data-testid={TEST_ID.SUBJECT}>
                        <ParameterTemplateStringFieldComponent
                          label={text.subjectLabel}
                          initialTemplateString={initialSubject}
                          setGetTemplateComponent={this.setGetSubject}
                          announceChanges={() => {
                            this.setState(prevState => ({
                              renderChangeCounter:
                                prevState.renderChangeCounter + 1,
                            }));

                            announceChanges();
                          }}
                          singleLine
                          isMandatory
                        />
                      </InputGroup>
                      {infoComponent}
                      <InputGroup data-testid={TEST_ID.BODY}>
                        <ParameterTemplateStringFieldComponent
                          type="EMAIL"
                          initialTemplateString={initialBody}
                          initialInlineAttachments={initialInlineAttachments}
                          setGetTemplateComponent={this.setGetBody}
                          setGetInlineAttachments={
                            this.setGetBodyInlineAttachments
                          }
                          announceChanges={this.bodyUpdated}
                        />
                      </InputGroup>

                      <AttachmentsContainer>
                        <SignatureOptionsContainer>
                          <Dropdown
                            dataTestid={TEST_ID.SIGNATURE_DROPDOWN}
                            options={signatureOptions}
                            label={text.signatureOptions}
                            onChange={({ option }) => {
                              this.setState({
                                selectedSignature: option.payload,
                              });

                              announceChanges();
                            }}
                            selectedOptionIdx={
                              0 ||
                              signatureOptions.findIndex(
                                option => option.payload === selectedSignature,
                              )
                            }
                          />

                          {selectedSignature ===
                            EmailSignature.ClosestEntity && (
                            <WarningContainer>
                              {text.warningMessage}
                            </WarningContainer>
                          )}
                        </SignatureOptionsContainer>

                        {pointerAttachmentsList}
                        <FileAttachmentsContainer>
                          {fileAttachmentsList}
                          {uploadingAttachmentsList}
                        </FileAttachmentsContainer>
                        <InputGroup>{text.attachmentsHeader}</InputGroup>
                        <LinkContainer>
                          <LinkButton
                            testId={TEST_ID.ADD_ATTACHMENT_BUTTON}
                            onClick={() => {
                              const newAttachmentList = [
                                ...attachments,
                                {
                                  type: FLOW_EMAILATTACHMENT_TYPE.FLOW_EMAILATTACHMENT,
                                  file: emptyFilePointerParameterValue(),
                                },
                              ];

                              this.updateAttachments(newAttachmentList);
                            }}
                          >
                            {text.addAttachmentButtonLabel}
                          </LinkButton>
                          <span>&nbsp;of&nbsp;</span>
                          <div>
                            <S3FileUpload
                              fileDetails={null}
                              onUploadSucceeded={this.onAttachmentUploaded}
                              setUploadingFile={this.onUploadAttachment}
                              allowedFileExtensions={['*']}
                              uploadButtonLabel={text.attachFile}
                              onFileRemoved={() => {}}
                              uploadButton={({ onClick, ...props }) => (
                                <LinkButton
                                  testId={
                                    TEST_ID.ADD_PRIMITIVE_ATTACHMENT_BUTTON
                                  }
                                  onClick={onClick}
                                  {...props}
                                >
                                  {text.attachFile}
                                </LinkButton>
                              )}
                            />
                          </div>
                        </LinkContainer>
                      </AttachmentsContainer>
                    </ActionContentContainer>
                  </BaseActionComponent>
                );
              }}
            </FlowContext.Consumer>
          );
        }}
      </AccountContext.Consumer>,
      fileUploadError && (
        <ErrorModal
          key="modal"
          title="Uploaden mislukt"
          message={text.isOverMaxEmailSizeErrorMessage}
          onClose={() => this.setState(() => ({ fileUploadError: false }))}
        />
      ),
    ];
  }
}

const transactionalOptions = [
  {
    label: text.marketingLabel,
    value: false,
    dataTestid: TEST_ID.MARKETING_OPTION,
  },
  {
    label: text.transactionalLabel,
    value: true,
    dataTestid: TEST_ID.TRANSACTIONAL_OPTION,
  },
];

const signatureOptions = [
  {
    key: 'no-signature',
    label: text.noSignature,
    payload: null,
  },
  {
    key: EmailSignature.FromAddressEntity as string,
    label: text.fromAddressSignatureLabel,
    payload: EmailSignature.FromAddressEntity,
  },
  {
    key: EmailSignature.ClosestEntity as string,
    label: text.closestEntitySignatureLabel,
    payload: EmailSignature.ClosestEntity,
  },
];

const LinkContainer = styled.div<{}>`
  display: flex;
  margin-top: ${INPUT_GROUP_TOP_BOTTOM_MARGIN};

  span {
    display: inline-block;
  }
`;

const AttachmentsContainer = styled.div<{}>`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.color('grey', 'light')};
  `};
`;

const FileAttachmentsContainer = styled.div<{}>`
  display: grid;
  grid-template-columns: 380px auto;
  grid-template-rows: auto;

  ${({ theme }) => css`
    margin-top: ${theme.space('xxs')};
    row-gap: ${theme.space('s')};
    column-gap: ${theme.space('l')};

    @media screen and (max-width: ${theme.bp('tabletLandscape')}) {
      grid-template-columns: auto;
    }
  `};
`;

type InfoContainerType = {
  error: boolean;
};
const InfoContainer = styled.div<InfoContainerType>`
  ${({ theme, error }) => {
    if (error) {
      return css`
        color: ${theme.color('danger')};
      `;
    }
    return ``;
  }}
  ${({ theme }) => css`
    margin-top: ${theme.space('s')};
    margin-bottom: ${theme.space('s')};
  `};
`;

const DropdownContainer = styled.div<{}>`
  width: 100%;
`;

const StyledHelpButton = styled(HelpButton)<{}>`
  align-self: flex-start;
  margin-bottom: 5px;

  ${({ theme }) => css`
    margin-left: 4px;
    font-size: ${theme.fontSize('base')};
  `};
`;

const LabelContainer = styled.div<{}>`
  display: flex;
  align-items: flex-end;
`;

const SignatureOptionsContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('m')};
  `,
);

const WarningContainer = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('warning')};
    margin-top: ${theme.space('xxs')};
    font-size: ${theme.fontSize('s')};
  `,
);

export default withValidatorList<MyProps>(SendEmailPlainActionComponent);
