import { RouteComponentProps } from '@reach/router';
import { assoc, map, isNil } from 'ramda';
import React, { useState, useEffect } from 'react';
import {
  GetZapierTriggerQuery,
  useDeleteZapierTriggerMutation,
  useGetZapierTriggerQuery,
  useGetZapierTriggersQuery,
  useUpdateZapierTriggerMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import Details from '../../../Details';
import uuidv1 from 'uuid/v1';
import { ZapierFields } from '../../../Details/components/FieldsContainer';
import { AppErrorScreen, Loading } from '~/components';
import { breadcrumbs, text } from '../text';
import { cleanedFields } from '../../../utils/cleanedFields';
import { navigate } from 'gatsby-link';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import isValidTriggerId from './util/isValidTriggerId';

export type EditTriggerProps = {
  dataTestId?: string;
  triggerId: string;
};

const EditTrigger: React.FC<EditTriggerProps> = ({
  dataTestId,
  triggerId,
  ...rest
}) => {
  const isMountedRef = useIsMountedRef();
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const [updateTrigger, { loading: saveLoading }] =
    useUpdateZapierTriggerMutation();
  const {
    data,
    loading: triggerLoading,
    updateQuery,
  } = useGetZapierTriggerQuery({
    variables: {
      accountId,
      id: triggerId as string,
    },
  });

  const { updateQuery: updateAllTriggers } = useGetZapierTriggersQuery({
    variables: {
      accountId,
    },
  });

  const [deleteTrigger, { loading: deleteLoading }] =
    useDeleteZapierTriggerMutation();

  const [trigger, setTrigger] = useState<
    GetZapierTriggerQuery['getZapierTrigger'] | null
  >(null);

  const [fieldsWithIds, setFieldsWithId] = useState<ZapierFields>([]);

  useEffect(() => {
    if (!isNil(data) && !isNil(data.getZapierTrigger)) {
      setTrigger(data.getZapierTrigger);
      setFieldsWithId(
        map(
          field => assoc('id', uuidv1(), field),
          data.getZapierTrigger.fields,
        ),
      );
    }
  }, [data]);

  if (!trigger || triggerLoading) return <Loading />;

  const onError = (name: string) =>
    addToast([
      formatToastMessage(
        `Er is iets fout gegaan bij het opslaan van "${name}" uitgaande koppeling, probeer het later opnieuw.`,
        'danger',
      ),
    ]);

  const onSave = async (name: string, fields: ZapierFields) => {
    const updatedTrigger = {
      ...trigger,
      name,
      fields: cleanedFields(fields),
    };

    return updateTrigger({
      variables: {
        ...updatedTrigger,
      },
    }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        return onError(name);
      }

      return updateQuery(prev => ({
        ...prev,
        getZapierTrigger: {
          ...prev.getZapierTrigger,
          ...updatedTrigger,
          fields,
        },
      }));
    });
  };

  const onConfirmDeletion = async () => {
    await deleteTrigger({
      variables: {
        accountId,
        id: trigger.id,
      },
    }).then(({ data: update, errors }) => {
      if (isNil(update) || errors?.length) {
        return onError(trigger.name);
      }
      return updateAllTriggers(prev => ({
        ...prev,
        getZapierTriggers: prev.getZapierTriggers.filter(
          ({ id }) => id !== trigger.id,
        ),
      }));
    });

    if (!isMountedRef.current) return;
    return navigate('/-/apps/zapier', { replace: true });
  };

  return (
    <Details
      {...rest}
      dataTestId={dataTestId}
      goBackLink={text.goBackLink}
      headerName={trigger.name}
      onSave={(name, fields) => onSave(name, fields)}
      description={text.description}
      initialFields={fieldsWithIds}
      breadcrumbs={[...breadcrumbs, { label: trigger.name }]}
      loading={saveLoading || deleteLoading}
      onDelete={onConfirmDeletion}
    >
      {text.children}
    </Details>
  );
};

export type Props = {
  dataTestId?: string;
} & RouteComponentProps<{ triggerId: string }>;
const FacadeComponent: React.FC<Props> = ({ triggerId, ...rest }) => {
  if (!triggerId) {
    return <AppErrorScreen />;
  }

  if (!isValidTriggerId(triggerId)) {
    return <AppErrorScreen message={text.noTriggerFound} />;
  }

  return <EditTrigger triggerId={triggerId} {...rest} />;
};

export default FacadeComponent;
