import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { OptionOf } from '~/components/Inputs/Dropdown';

import { IconButton } from '~/components/Buttons';
import cleanedFilename from '~/util/cleanedFilename';
import { MarkContactForDeletionMutation } from '~/graphql/Mutation';
import mutation from '~/graphql/mutation/MarkContactForDeletion';
import useErrorModal from '~/components/Alerts/useErrorModal';
import ConfirmModal from '~/components/Alerts/ConfirmModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TEST_ID from './index.testid';
import Icon from '~/components/Icon';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import ContactsContext from '../../ContactsContext/ContactsContext';
import markedForDeletionContactList from '../../util/markedForDeletionContactList';
import DropdownListContainer from '~/components/Dropdown/components/DropdownListContainer';

type ButtonType = 'SUBSCRIBE' | 'UNSUBSCRIBE' | 'DELETE';

const text = {
  agree: 'Ok',
  subscribeModalTitle: 'Contact ingeschreven',
  unsubscribeModalTitle: 'Contact uitgeschreven',
  subscribeModalMessage:
    'Een ingeschreven contact kan door flows gestuurde marketing e-mail ontvangen. Het contact dient hiervoor toestemming te hebben gegeven.',
  unsubscribeModalMessage:
    'Een uitgeschreven contact ontvangt geen marketing e-mail vanuit flows. Actieve flows met een e-mail actie zullen worden gestopt.',
  deleteErrorTitle: 'Oeps!',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van het contact. Probeer het nog eens.',
  updateErrorMessage:
    'Er is iets misgegaan bij het updaten van het contact. Probeer het nog eens.',
  buttonConfirmTitle: 'I understand',
};

type Props = {
  blockMarketing: boolean | null | undefined;
  contactId: string;
};

const ContactInformationSettings = ({ blockMarketing, contactId }: Props) => {
  const isMountedRef = useIsMountedRef();
  const { updateContactFn } = useContext(ContactsContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState({
    display: false,
    title: text.subscribeModalTitle,
    message: text.subscribeModalMessage,
  });
  const [errorModal, showErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.deleteErrorMessage,
  });
  const [updateErrorModal, showUpdateErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.updateErrorMessage,
  });
  const account = useCurrentAccount();

  const dropdownOptions: Array<OptionOf<ButtonType>> = [
    {
      label: blockMarketing === true ? 'Inschrijven' : 'Uitschrijven',
      key: blockMarketing === true ? 'subscribe' : 'unsubscribe',
      payload: blockMarketing === true ? 'SUBSCRIBE' : 'UNSUBSCRIBE',
      type: null,
    },
    {
      label: 'Verwijderen',
      key: 'delete',
      payload: 'DELETE',
      type: 'DANGER',
    },
  ];

  return (
    <MarkContactForDeletionMutation
      name={cleanedFilename(__filename)}
      mutation={mutation}
      onCompleted={() => {
        markedForDeletionContactList.writeMarkedForDeletionContact(contactId);

        if (isMountedRef.current) void navigate('/-/contacts');
      }}
      onError={() => {
        showErrorModal();
      }}
    >
      {markContactForDeletion => (
        <ButtonContainer>
          <IconButton
            data-testid={TEST_ID.MENU_BUTTON}
            onClick={() => {
              setDropdownOpen(prev => !prev);
            }}
          >
            <Icon name="dot-menu" inline />
          </IconButton>
          <StyledDropdownHideableList
            options={dropdownOptions}
            selectedOptionIdx={-1}
            dropdownListOpen={dropdownOpen}
            onChange={({ option }) => {
              if (option.payload === 'DELETE') {
                void markContactForDeletion({
                  variables: {
                    accountId: account.id,
                    id: contactId,
                  },
                });
              } else if (
                option.payload === 'SUBSCRIBE' ||
                option.payload === 'UNSUBSCRIBE'
              ) {
                if (blockMarketing) {
                  setModal(() => ({
                    display: true,
                    title: text.subscribeModalTitle,
                    message: text.subscribeModalMessage,
                  }));
                } else {
                  setModal(() => ({
                    display: true,
                    title: text.unsubscribeModalTitle,
                    message: text.unsubscribeModalMessage,
                  }));
                }

                setDropdownOpen(false);
                updateContactFn({
                  variables: {
                    accountId: account.id,
                    id: contactId,
                    update: {
                      blockMarketing: !blockMarketing,
                    },
                  },
                }).catch(() => {
                  showUpdateErrorModal();
                  throw Error(
                    `${cleanedFilename(
                      __filename,
                    )} | Should not occur | Something went wrong with (${option})`,
                  );
                });
              } else {
                throw Error(
                  `${cleanedFilename(
                    __filename,
                  )} | Should not occur | Received an option other than delete, subscribe or unsubscribe (${option})`,
                );
              }
            }}
            onClickOutside={() => setDropdownOpen(false)}
            onClose={() => setDropdownOpen(false)}
            dataTestId={TEST_ID.NAV_SETTINGS_DROPDOWN}
          />
          {modal.display && (
            <ConfirmModal
              title={modal.title}
              message={modal.message}
              buttonConfirmTitle={text.agree}
              hideCancel={true}
              handleAction={() => {
                setModal(prevState => ({
                  ...prevState,
                  display: false,
                }));
              }}
            />
          )}
          {errorModal}
          {updateErrorModal}
        </ButtonContainer>
      )}
    </MarkContactForDeletionMutation>
  );
};

const StyledDropdownHideableList = styled(DropdownListContainer)<{}>`
  right: 0;
`;

const ButtonContainer = styled.div<{}>`
  position: relative;
`;

export default ContactInformationSettings;
