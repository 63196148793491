import {
  Field,
  NumberField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { NumberConditionField } from '../types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION } from './constants';
import { emptyNumberPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter';

export default (field: Field | NumberField): NumberConditionField => ({
  ...field,
  type: FIELD_TYPE.NUMBER,
  operation: {
    type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.EQUALS,
    value: emptyNumberPrimitiveParameterValue(),
    negate: false,
  },
});
