import React from 'react';
import styled, { css } from 'styled-components';

import MetaTags from 'react-meta-tags';
import { navigate } from 'gatsby';
import FullPageInformation from '~/components/FullPageInformation';
import { RouteComponentProps } from '@reach/router';
import TEST_ID from './index.testid';
import { APPROXIMATE_HEADER_HEIGHT } from '~/styles/constants';
import { FOOTER_HEIGHT } from '../NavigationFrame/components/Footer';
import JustificationContainer from '../JustificationContainer';

export const text = {
  pageTitle: 'Pagina niet gevonden!',
  title: 'Pagina niet gevonden!',
  explanation:
    'Helaas kunnen we deze pagina niet vinden, mocht het nogmaals gebeuren laat het ons weten. Dan gaan wij op zoek naar de verloren pagina.',
  goToLinkText: 'Terug naar dashboard',
};

export const NotFound: React.FC<RouteComponentProps> = () => (
  <MainContainer align="center" justification="center" direction="column">
    <ContentContainer data-testid={TEST_ID.PAGE_CONTAINER} direction="column">
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <FullPageInformation
        title={text.title}
        explanation={text.explanation}
        goToLink={() => void navigate('/-/')}
        goToLinkText={text.goToLinkText}
        dataTestid={TEST_ID.NOT_FOUND_PAGE}
      />
    </ContentContainer>
  </MainContainer>
);

export const WithNavigationFrame: React.FC<RouteComponentProps> = () => (
  <>
    <MetaTags>
      <title>{text.pageTitle}</title>
    </MetaTags>
    <FullPageInformation
      title={text.title}
      explanation={text.explanation}
      goToLink={() => void navigate('/-/')}
      goToLinkText={text.goToLinkText}
      dataTestid={TEST_ID.NOT_FOUND_PAGE}
    />
  </>
);

const MainContainer = styled(JustificationContainer)<{}>`
  margin-top: ${APPROXIMATE_HEADER_HEIGHT}px;
  height: calc(100vh - ${APPROXIMATE_HEADER_HEIGHT * 2 + FOOTER_HEIGHT}px);
`;

const ContentContainer = styled(JustificationContainer)<{}>`
  max-width: 100vw;

  ${({ theme }) => css`
    background-color: ${theme.color('white', 'dark')};
  `}
`;

export default NotFound;
