import { RouteComponentProps } from '@reach/router';
import React from 'react';

import {
  useGetAppBbWaardecheckQuery,
  useUpdateAppBbWaardecheckMutation,
  useGetAppBbWaardecheckRouteAvailabilityLazyQuery,
} from '~/graphql/types';
import DatHuisLoading from '~/components/DatHuisLoading';
import { useRecoilState, useSetRecoilState } from 'recoil';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import NotFound from '~/pages/404';
import ErrorScreen from '~/scenes/ErrorScreen';
import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';
import WaardecheckTemplate, {
  appWaardecheckState,
  appWaardecheckImageStash,
  StateId,
  WaardecheckApp,
  getImageStash,
} from '../components/WaardecheckTemplate';
import transformToInput from '../components/WaardecheckTemplate/utils/transformToInput';
import useErrorReporter from '~/hooks/useErrorReporter';
import useApp from '~/hooks/useApp';

const text = {
  appTitle: 'Waardecheck - Powered by Brainbay',
  updateError: 'Opslaan niet gelukt door foutieve waarden in het formulier',
};

type Props = RouteComponentProps & {};

const BBWaardecheck: React.FC<Props> = ({ uri = '' }) => {
  const { id: accountId } = useCurrentAccount();
  const errorReporter = useErrorReporter();

  const {
    app,
    loading: appLoading,
    error: appError,
  } = useApp('AppStatus_BBWaardecheck');

  const [, setOriginal] = useRecoilState(appWaardecheckState(StateId.original));

  const [updated, setUpdated] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const addToast = useAddToast();

  const setImageStash = useSetRecoilState(appWaardecheckImageStash);

  const { data, error, loading, updateQuery } = useGetAppBbWaardecheckQuery({
    variables: { accountId },
    onCompleted: ({ getAppBBWaardecheck }) => {
      if (!getAppBBWaardecheck) return;

      const inputFormatted = transformToInput(getAppBBWaardecheck);

      setUpdated(inputFormatted);
      setOriginal(inputFormatted);
      setImageStash(getImageStash(getAppBBWaardecheck));
    },
  });

  const [updateAppBbWaardecheck, { loading: updating }] =
    useUpdateAppBbWaardecheckMutation();

  const [
    getRouteAvailability,
    {
      loading: getRouteAvailabilityLoading,
      data: getRouteAvailabilityData,
      variables: getRouteAvailabilityDataVariables,
    },
  ] = useGetAppBbWaardecheckRouteAvailabilityLazyQuery();

  if (appLoading) return <DatHuisLoading />;
  if (!app?.appStatus.enabled) return <NotFound />;

  if (error || appError) {
    reportErrorToTracker(
      `${cleanedFilename(__filename)} | An error occured (${JSON.stringify(
        error || appError,
      )})`,
    );

    return <ErrorScreen />;
  }

  if (!data || loading || !updated) return <DatHuisLoading />;

  return (
    <WaardecheckTemplate
      uri={uri}
      appTitle={text.appTitle}
      appType={WaardecheckApp.BB}
      queryData={data.getAppBBWaardecheck}
      queryLoading={loading}
      mutationLoading={updating}
      onSave={async () => {
        const { errors, data } = await updateAppBbWaardecheck({
          variables: {
            accountId,
            update: updated,
          },
        });

        if (errors || !data) {
          errorReporter.captureException(
            new Error(JSON.stringify(errors || 'No Data')),
            'critical',
          );
          addToast([formatToastMessage(text.updateError, 'danger')]);
          return;
        }

        const { updateAppBBWaardecheck } = data;

        updateQuery(data => ({
          ...data,
          getAppBBWaardecheck: updateAppBBWaardecheck,
        }));

        return updateAppBBWaardecheck;
      }}
      routeAvailability={{
        loading: getRouteAvailabilityLoading,
        data: getRouteAvailabilityData,
        variables: getRouteAvailabilityDataVariables,
      }}
      getRouteAvailability={route =>
        getRouteAvailability({
          variables: {
            accountId,
            route,
          },
        })
      }
    />
  );
};

export default BBWaardecheck;
