import uuid from 'uuid/v4';
import { Element, Location, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import downloadFileWithSrc from '~/components/HTMLEditor/util/downloadFileWithSrc';
import uploadImageToStorage from '~/components/HTMLEditor/util/uploadImageToStorage';
import formatToastMessage, {
  FormattedToastMessage,
} from '~/util/formatToastMessage';
import getCorsUrl from '~/util/getCorsUrl';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import cleanedFilename from '~/util/cleanedFilename';
import { DHEditor, DhImageElement, ImageElement } from '../../types';
import { Reporter } from '~/hooks/useErrorReporter';

const text = {
  downloadErrorMessage: `${cleanedFilename(
    __filename,
  )} | Image could not be downloaded. This might be due to a cors error. Check the request url.`,
  insertionErrorMessage:
    'Een of meerdere afbeeldingen konden niet worden ingevoegd. Probeer het alsjeblieft nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};

/**
 * Downloads an image via its url and uploads the file to S3
 * @param {DHEditor} editor - Example argument
 * @param {ImageElement | DhImageElement} element - Example argument
 * @param {Location} path - Example argument
 * @param {string} userId - Example argument
 * @param {string} errorReporter - Example argument
 * @param {string} addToast - Example argument
 * keywords: upload to S3, pending image
 */

type Params = {
  editor: DHEditor;
  element: ImageElement | DhImageElement;
  url: string;
  path: Location;
  userId: string;
  errorReporter: Reporter;
  addToast: (val: Array<FormattedToastMessage>) => void;
};
export const uploadPendingImageToStorage = ({
  editor,
  element,
  url,
  path,
  userId,
  errorReporter,
  addToast,
}: Params): Promise<{ success: boolean }> =>
  downloadFileWithSrc(getCorsUrl(url), file => {
    if (!file) {
      errorReporter.captureException(
        new Error(text.downloadErrorMessage),
        'warning',
      );
      addToast([formatToastMessage(text.insertionErrorMessage, 'danger')]);
      return { success: false };
    }

    return uploadImageToStorage(file, userId)
      .then(({ url, s3key, filename, contentType, contentLength }) => {
        HistoryEditor.withoutSaving(editor, () => {
          Transforms.setNodes(
            editor,
            {
              url,
              s3key,
              filename,
              contentType,
              contentLength,
              inlineId: uuid(),
              /** Set pending to false so that it doesn't trigger the useEffect again */
              pending: false,
              loading: false,
              type: ELEMENTS.DH_IMAGE,
            },
            {
              at: path,
              match: node =>
                Element.isElement(node) &&
                node.type === element.type &&
                element.pending === true,
            },
          );
        });

        return { success: true };
      })
      .catch(e => {
        errorReporter.captureException(e, 'warning');
        addToast([formatToastMessage(text.insertionErrorMessage, 'danger')]);
        return { success: false };
      });
  })
    .then(res => res)
    .catch(e => e);

export default uploadPendingImageToStorage;
