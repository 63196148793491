import { StringOperation } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/String/types.flow';
import { StringField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { emptyStringPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter';
import { FLOW_CONDITION_PRIMITIVE_STRING_OPERATION } from './constants';

const defaultStringOperation = (field?: StringField): StringOperation => {
  let initialValue;
  if (field && field.options && field.options.length > 0) {
    initialValue = field.options[0];
  }

  return {
    type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.EQUALS,
    value: emptyStringPrimitiveParameterValue(initialValue),
    negate: false,
  };
};

export default defaultStringOperation;
