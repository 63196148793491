import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/Button';
import Input from '~/components/Input';
import Dropdown from '~/components/Dropdown';
import { SelectedOptionOf } from '~/components/Inputs/Dropdown';
import JustificationContainer from '~/components/JustificationContainer';
import { Link } from '~/components/Link';
import FormContainer from '~/components/ModalsV2/FormContainer';
import { withOverlay } from '~/components/ModalsV2/Overlay';
import TextButton from '~/components/TextButton';
import TipBanner from '~/components/TipBanner';
import ChatLink from '~/components/TipBanner/ChatLink';
import { Office } from '~/graphql/types';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import { isNonEmptyString } from '~/util/Validation/String';
import TEST_ID from './index.testid';

export type TokenPair = {
  tokenId: string;
  officeId: string;
};

export type Props = {
  loading?: boolean;
  dataTestId?: string;
  onAddToken: ({ tokenId, officeId }: TokenPair) => void;
  onClose: () => void;
};

const text = {
  tokenPlaceholder: 'Trustoo token invoeren',
  officeLabel: 'Eigenaar van dit Trustoo account',
  formHeader: 'Trustoo koppeling toevoegen',
  emptyFieldError: 'Veld mag niet leeg zijn',
  cancel: 'Afbreken',
  save: 'Opslaan',
  tip: (
    <>
      Koppel je Trustoo account met behulp van de token. Deze vind je in jouw
      Trustoo account. Kom je er niet uit? Lees de volledige instructie in{' '}
      <Link to="https://help.dathuis.nl/nl/articles/6435229-over-de-trustoo-app">
        onze kennisbank
      </Link>
      <br />
      <br />
      Kom je er niet uit?
      <br />
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
};

const AddTokenModal: React.FC<Props> = ({
  dataTestId,
  onClose,
  onAddToken,
  loading,
  ...rest
}) => {
  const officeOptions = useOfficeOptions();

  const [officeId, setOfficeId] = useState<string>(
    officeOptions[0]?.payload?.id ?? '',
  );
  const [tokenId, setTokenId] = useState<string>('');

  return (
    <Container data-testid={dataTestId} {...rest}>
      <FormContainer header={text.formHeader} onClose={onClose}>
        <TipBanner id="trustoo-add-token" headerText="Hulp nodig?">
          {text.tip}
        </TipBanner>
        <StyledInput
          dataTestId={TEST_ID.ADD_TOKEN_INPUT}
          label={text.tokenPlaceholder}
          placeholder={text.tokenPlaceholder}
          value={tokenId}
          onChange={e => setTokenId(e.target.value)}
          validation={[
            (value: string) =>
              isNonEmptyString(value) ? true : text.emptyFieldError,
          ]}
          name="token"
        />
        <Dropdown
          label={text.officeLabel}
          dataTestId={TEST_ID.OFFICE_DROPDOWN}
          options={officeOptions}
          selectedOptionIdx={officeOptions.findIndex(
            office => office.payload?.id === officeId,
          )}
          onChange={({ option }: SelectedOptionOf<Office>) =>
            setOfficeId(option.payload.id)
          }
        />

        <JustificationContainer
          justification="space-between"
          align="center"
          margin={['l', null, null]}
        >
          <TextButton
            label={text.cancel}
            onClick={onClose}
            withoutPadding
            appearance="danger"
            type="button"
            dataTestId={TEST_ID.CANCEL}
          />

          <Button
            icon="check"
            label={text.save}
            type="submit"
            appearance="secondary"
            loading={loading}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              onAddToken({ tokenId, officeId });
            }}
            disabled={isEmpty(tokenId)}
            dataTestId={TEST_ID.SAVE}
          />
        </JustificationContainer>
      </FormContainer>
    </Container>
  );
};

const StyledInput = styled(Input)<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `,
);

const Container = styled.form<{}>`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default withOverlay(AddTokenModal);
