import type { FlowV2_ConditionListFragment } from '~/graphql/types';
import { Trigger } from '../..';
import { FlowConditionConnector } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/components/OperatorDropdown';
import extractExpression from '../../../../../Builder/utils/expressionHelpers';

/**
 * Convert the condition list from the BE into a FE trigger type
 * @param {FlowV2_ConditionListFragment} conditionList - FlowV2_ConditionListFragment
 * keywords:
 */
const getTriggerForConditionList = (
  conditionList: FlowV2_ConditionListFragment,
):
  | {
      error?: undefined;
      result: { triggers: Array<Trigger>; connector: FlowConditionConnector };
    }
  | { error: string } => {
  const expressionGroup = extractExpression(conditionList.exp);
  const connector = expressionGroup.connector;

  const triggers = expressionGroup.expressions.map((expression): Trigger => {
    const [triggerIndex, ...subConditionIndices] = expression.indices;
    const trigger = conditionList.conditions[triggerIndex];
    const conditions = subConditionIndices.map(subConditionIndex => {
      const subCondition = conditionList.conditions[subConditionIndex];
      return subCondition;
    });

    return {
      id: trigger.id,
      trigger,
      condition: {
        conditions,
        connector: expression.connector,
      },
    };
  });

  return {
    result: {
      connector,
      triggers,
    },
  };
};

export default getTriggerForConditionList;
