import { EventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import cleanedFilename from '~/util/cleanedFilename';

export default (parameterValue: EventParameterValue): boolean => {
  switch (parameterValue.type) {
    case PARAMETER_VALUE_TYPE.EVENT_VALUATIONREPORT_POINTER:
      return (
        parameterValue.variable == null || parameterValue.variable.field == null
      );
    default:
      throw new Error(
        `${cleanedFilename(__filename)} Incorrect user parameter value type ${
          parameterValue.type
        }`,
      );
  }
};
