import gql from 'graphql-tag';

export default gql`
  query GetFlows($accountId: ID!, $nextToken: ID, $limit: Int) {
    getFlows(accountId: $accountId, nextToken: $nextToken, limit: $limit) {
      items {
        id
        _v
        accountId
        name
        enabled
        maximumFlowRun
        createdDate
        lastModified {
          __typename
          v
          date
        }
        Statistics {
          __typename
          completedFlowInstancesTotal
          nonCompletedFlowInstancesTotal
          leadScoreAverage
        }
      }
      nextToken
    }
  }
`;
