/* eslint-disable @typescript-eslint/no-unused-vars */

import { AppBbWaardecheck__Input, AppType } from '~/graphql/types';
import { assertNever } from '~/util/assertion';
import { Page } from '..';
import saveGet from '../../../utils/saveGet';
import { WaardecheckApp } from '~/scenes/Apps/components/WaardecheckTemplate';

type Args = {
  input: AppBbWaardecheck__Input;
  imageStash: { [s3Key: string]: string };
  page: Page;
  accountName: string;
  appType: WaardecheckApp;
};
const mergeWithDefault = ({
  input,
  imageStash,
  page,
  accountName,
  appType,
}: Args): $Object => {
  const getImage = saveGet(imageStash);

  const {
    about,
    address,
    appraisalRequest,
    appraisalRequestSuccess,
    comparison,
    general,
    propertyType,
    report,
    valuationFailure,
    valuationSuccess,
    when,
    why,
  } = input;

  const fallbackImageSrc = getImage(general.fallbackImage?.s3key);

  const footer =
    appType === WaardecheckApp.VBO
      ? {
          nl: 'Deze waardecheck is opgesteld door [DatHuis](https://dathuis.nl) in samenwerking met Avix',
          en: 'This valuation report is created by [DatHuis](https://dathuis.nl) in cooperation with Avix',
        }
      : {
          nl: 'Deze waardecheck is opgesteld door [DatHuis](https://dathuis.nl) in samenwerking met brainbay',
          en: 'This valuation report is created by [DatHuis](https://dathuis.nl) in cooperation with brainbay',
        };

  const notice =
    appType === WaardecheckApp.VBO
      ? {
          nl: '',
          en: '',
        }
      : {
          nl: `${accountName} is lid van de NVM.`,
          en: `${accountName} is a member of the NVM.`,
        };

  const propertySalePrice =
    appType === WaardecheckApp.VBO
      ? {
          nl: 'Geïndexeerde transactieprijs',
          en: 'Adjusted transaction price',
        }
      : {
          nl: 'Verkocht voor',
          en: 'Sold for',
        };

  const base = {
    general: {
      logo: {
        url: general.logoLink.href,
        image: {
          src: getImage(general.logoImage?.s3key),
        },
      },
      fallbackImage: fallbackImageSrc
        ? {
            src: fallbackImageSrc,
          }
        : undefined,
      footer,
      notice,
    },
  };

  switch (page) {
    case Page['/']: {
      const src = getImage(address.image?.s3key);
      if (src) delete base.general.fallbackImage;

      const { image: _image, ...rest } = address;

      return {
        ...base,
        address: {
          ...rest,
          image: {
            src,
          },
        },
      };
    }
    case Page['/property-type']: {
      const src = getImage(propertyType.image?.s3key);
      if (src) delete base.general.fallbackImage;

      const { image: _image, ...rest } = propertyType;

      return {
        ...base,
        propertyType: {
          ...rest,
          image: {
            src,
          },
        },
      };
    }
    case Page['/when']: {
      const src = getImage(when.image?.s3key);
      if (src) delete base.general.fallbackImage;

      const { image: _image, ...rest } = when;

      return {
        ...base,
        when: {
          ...rest,
          image: {
            src,
          },
        },
      };
    }
    case Page['/why']: {
      const src = getImage(why.image?.s3key);
      if (src) delete base.general.fallbackImage;

      const { image: _image, ...rest } = why;

      return {
        ...base,
        why: {
          ...rest,
          image: {
            src,
          },
        },
      };
    }
    case Page['/swiping']: {
      return {
        ...base,
        swiping: {
          ...comparison,
        },
      };
    }
    case Page['/failure']: {
      const src = getImage(valuationFailure.image?.s3key);
      if (src) delete base.general.fallbackImage;

      const { image: _image, ...rest } = valuationFailure;

      return {
        ...base,
        failure: {
          ...rest,
          image: {
            src,
          },
        },
      };
    }
    case Page['/success']: {
      const src = getImage(valuationSuccess.image?.s3key);
      if (src) delete base.general.fallbackImage;

      const { image: _image, ...rest } = valuationSuccess;

      return {
        ...base,
        success: {
          ...rest,
          image: {
            src,
          },
        },
      };
    }
    case Page['/appraisal-request']: {
      const src = getImage(appraisalRequest.image?.s3key);
      if (src) delete base.general.fallbackImage;

      const { image: _image, ...rest } = appraisalRequest;

      return {
        ...base,
        appraisalRequest: {
          ...rest,
          image: {
            src,
          },
        },
      };
    }
    case Page['/appraisal-request-success']: {
      const src = getImage(appraisalRequestSuccess.image?.s3key);
      if (src) delete base.general.fallbackImage;

      const { primaryButton, image: _image, ...rest } = appraisalRequestSuccess;

      const primaryButtonLink = primaryButton.href;
      const primaryButtonText = primaryButton.text;

      return {
        ...base,
        appraisalRequestSuccess: {
          ...rest,
          primaryButtonLink: primaryButtonLink,
          primaryButton: primaryButtonText,
          image: {
            src,
          },
        },
      };
    }
    case Page['/report']: {
      const src = getImage(report.image?.s3key);
      if (src) delete base.general.fallbackImage;

      const { image: _reportImage, ...reportRest } = report;
      const { image: _aboutImage, primaryButton, ...aboutRest } = about;

      const primaryButtonLink = primaryButton.href;
      const primaryButtonText = primaryButton.text;
      return {
        ...base,
        report: {
          ...reportRest,
          propertySalePrice,
          image: {
            src,
          },
        },
        about: {
          ...aboutRest,
          primaryButtonLink: primaryButtonLink,
          primaryButton: primaryButtonText,
          image: {
            src: getImage(about.image?.s3key),
          },
        },
      };
    }
    default:
      return assertNever(page);
  }
};

export default mergeWithDefault;
