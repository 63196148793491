import gql from 'graphql-tag';

export default gql`
  fragment BillingDetailsFields on BillingDetails {
    name
    email
    last4
    address {
      line1
      line2
      postcode
      city
      state
    }
  }
`;
