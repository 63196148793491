import cleanedFilename from '~/util/cleanedFilename';

export default {
  REGISTER_FORM: `${cleanedFilename(__filename)}-register-form`,
  REGISTER_ERROR_MESSAGE: `${cleanedFilename(
    __filename,
  )}-register-error-message`,
  SUBMIT_PASSWORD_BUTTON: 'submit-password-button',
  PDF_LINK: `${cleanedFilename(__filename)}-pdf-link`,
  PDF_LINK_PROCESSING: `${cleanedFilename(__filename)}-pdf-link-verwerking`,
  HAS_WAARDERAPPORT_BUTTON: `${cleanedFilename(
    __filename,
  )}-has-waarderapport-button`,
};
