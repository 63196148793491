import { RouteComponentProps } from '@reach/router';
import React from 'react';
import AppDetailsContainer from '~/scenes/Apps/components/AppDetailsContainer';
import Connections from './components/Connections';

export type Props = {
  dataTestId?: string;
} & RouteComponentProps;

const text = {
  header: 'Instellingen',
};

const Settings: React.FC<Props> = ({}: Props) => (
  <AppDetailsContainer header={text.header} icon="gear">
    <Connections />
  </AppDetailsContainer>
);

export default Settings;
