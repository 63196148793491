import React from 'react';
import { ConditionField } from './types.flow';
import { TypedField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ConditionOperation } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/types.flow';

import { Dropdown } from '~/components/Inputs';
import { FIELD_TYPE } from '../../constants';
import {
  FlowConditionPrimitiveStringFieldComponent,
  defaultStringOperation,
} from './String';
import {
  FlowConditionPrimitiveBooleanFieldComponent,
  defaultBooleanOperation,
} from './Boolean';
import {
  FlowConditionPrimitiveNumberFieldComponent,
  defaultNumberOperation,
} from './Number';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import TEST_ID from './FlowConditionPrimitiveFieldComponent.testid';
import { ConditionFieldProp } from './MultipleFlowConditionPrimitiveFieldComponent';

type Props = {
  field: ConditionFieldProp;
  fieldOptions: Array<TypedField>;
  onChange: (newField: ConditionField) => void;
};

const SingleFlowConditionPrimitiveFieldComponent = ({
  field,
  onChange,
  fieldOptions,
}: Props) => (
  <>
    <Dropdown
      dataTestid={TEST_ID.FIELD_DROPDOWN}
      label={Catalog.flows.fieldLabel}
      error={fieldOptions.length === 0 ? 'Geen opties' : ''}
      helpLink={
        field.name
          ? getFieldOptionsForSelectedField(field.name, fieldOptions)?.helpLink
          : undefined
      }
      options={fieldOptions.map(fieldOption => ({
        label: fieldOption.label,
        payload: fieldOption,
        key: fieldOption.name,
      }))}
      selectedOptionIdx={fieldOptions.findIndex(
        fieldOption => fieldOption.name === field.name,
      )}
      onChange={({ option }) => {
        const newField = {
          ...field,
          ...option.payload,
          options: option.payload.options || undefined,
          operation: newOperation(field, option.payload),
        };

        onChange(newField);
      }}
    />
    {componentSwitcher(field, onChange)}
  </>
);

const getFieldOptionsForSelectedField = (
  selectedFieldName: string,
  fieldOptions: Array<TypedField>,
) => {
  const selectedFieldOption = fieldOptions.find(
    fieldOption => fieldOption.name === selectedFieldName,
  );
  return selectedFieldOption;
};

const componentSwitcher = (
  field: ConditionFieldProp,
  onChange: (newField: ConditionField) => void,
): React.ReactNode => {
  switch (field.type) {
    case FIELD_TYPE.STRING:
      return (
        <FlowConditionPrimitiveStringFieldComponent
          field={field}
          change={{
            controlled: true,
            //@ts-ignore
            onChange: onChange,
          }}
        />
      );
    case FIELD_TYPE.BOOLEAN:
      return (
        <FlowConditionPrimitiveBooleanFieldComponent
          field={field}
          //@ts-ignore
          onChange={onChange}
        />
      );
    case FIELD_TYPE.NUMBER:
      return (
        <FlowConditionPrimitiveNumberFieldComponent
          field={field}
          change={{
            controlled: true,
            //@ts-ignore
            onChange: onChange,
          }}
        />
      );
    default: {
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | can't find component for primitive condition of field ${JSON.stringify(
          field,
          null,
          2,
        )}`,
      );
    }
  }
};

const newOperation = (
  _prevField: ConditionFieldProp,
  newField: TypedField,
): ConditionOperation | null => {
  switch (newField.type) {
    case FIELD_TYPE.STRING:
      return defaultStringOperation(newField);
    case FIELD_TYPE.BOOLEAN:
      return defaultBooleanOperation();
    case FIELD_TYPE.NUMBER:
      return defaultNumberOperation();
    default:
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | newOperation is not sure what to do with field type ${
          newField.type
        }`,
      );
  }
};

export default SingleFlowConditionPrimitiveFieldComponent;
