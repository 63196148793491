import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

type Props = {};

const shouldLoadStripe = global.window?.DHLoadStripe !== false;

const stripePromise = shouldLoadStripe
  ? loadStripe(process.env.STRIPE_KEY ?? '', {
      apiVersion: '2020-08-27',
    })
  : new Promise<null>(res => res(null));

const StripeWrapper: React.FC<Props> = ({ children }) => (
  <Elements stripe={stripePromise} options={{ locale: 'nl' }}>
    {children}
  </Elements>
);
export default StripeWrapper;
