import { FlowConditionAppVBOWaardecheckContactRequestNew } from './types.flow';

import { asVBOWaardecheckReportEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import equalsEventPointer from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event/equalsEventPointer';
import cleanedFilename from '~/util/cleanedFilename';
import { AppVBOWaardecheckReportPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { FlowConditionFieldsFragment } from '~/graphql/types';

type ConditionType = Extract<
  FlowConditionFieldsFragment,
  {
    __typename: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New';
  }
>;

type ExpectedConditionEventType = Extract<
  ConditionType['reportEvent'],
  {
    __typename: 'Flow_Parameter_Event_Contact_App_VBOWaardecheck_Report_Pointer';
  }
>;

type ExpectedConditionType = ConditionType & {
  reportEvent: ExpectedConditionEventType;
};

const isConditionEventAppVBOWaardecheckReportPointer = (
  reportEvent: ConditionType['reportEvent'],
): reportEvent is ExpectedConditionEventType =>
  reportEvent?.__typename ===
  'Flow_Parameter_Event_Contact_App_VBOWaardecheck_Report_Pointer';

const isExpectedEventCondition = (
  condition: ConditionType,
): condition is ExpectedConditionType =>
  isConditionEventAppVBOWaardecheckReportPointer(condition.reportEvent);

const asFlowConditionAppVBOWaardecheckContactRequestNewProp = (
  conditions: Array<ConditionType>,
): Omit<FlowConditionAppVBOWaardecheckContactRequestNew, 'id'> => {
  let eventParameter: AppVBOWaardecheckReportPointerParameterValue | null =
    null;

  conditions.forEach(condition => {
    if (!isExpectedEventCondition(condition)) return;
    const { reportEvent } = condition;
    const newEvent = asVBOWaardecheckReportEventParameterValue(reportEvent);
    if (
      eventParameter != null &&
      !equalsEventPointer(eventParameter, newEvent)
    ) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | different events in the conditions (${JSON.stringify(
          conditions,
          null,
          2,
        )})`,
      );
    }
    eventParameter = newEvent;
  });

  return {
    type: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New',
    event: eventParameter,
  };
};

export default asFlowConditionAppVBOWaardecheckContactRequestNewProp;
