import React from 'react';
import styled from 'styled-components';

import Button from '~/components/Button';

const text = {
  completeButtonText: 'Voltooien',
  completed: 'Zet terug',
  deleted: 'Verwijderd',
};

type Props = {
  /** if the task is completed */
  isCompleted: boolean;

  /** when the task is completed. Can send in empty function if completed */
  onComplete: () => void;

  /** If the bar is disabled for clicks */
  disabled: boolean;

  /** If a change is loading so disable clicking */
  loading: boolean;
};
export default ({ onComplete, isCompleted, disabled, loading }: Props) => {
  let completeComponent;
  if (isCompleted) {
    completeComponent = (
      <StyledButton
        ghost
        loading={loading}
        disabled={disabled}
        onClick={event => {
          event.stopPropagation();
          onComplete();
        }}
        label={text.completed}
      />
    );
  } else {
    completeComponent = (
      <StyledButton
        loading={loading}
        disabled={disabled}
        data-testid="complete-task-button"
        onClick={event => {
          event.stopPropagation();
          onComplete();
        }}
        label={text.completeButtonText}
      />
    );
  }
  return completeComponent;
};

const StyledButton = styled(Button)<{}>`
  ${({ theme }) => `
    margin-left: ${theme.space('s')};
    min-width: max-content;
  `}
`;
