import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';

import Button from '../Button';
import Icon from '../Icon';
import JustificationContainer from '../JustificationContainer';
import Link from '../Link';
import Toolbar from '../Toolbar';
import TEST_ID from './index.testid';

type Size = 'small' | 'medium';

export type ChangeError = {
  /* Used to link to the field that has an error. */
  url?: string;

  /* Name of the field or any other uniq string */
  key: string | null;
};

export type Props = {
  dataTestId?: string;

  /** Set it to true if we want to navigate back */
  withGoBackLink?: boolean;

  /** Pass the link where to navigate */
  goBackLink?: string;

  /** If changes count, Cancel button will be shown, navigation back not allowed */
  changes?: number;

  /** If errors, navigation back not allowed, Save button disabled */
  errors?: Array<ChangeError>;

  /** What to do when the user clicks save */
  onSave: () => void;

  /** What to do when the user clicks cancel */
  onCancel: () => void;

  /** Loading state */
  loading?: boolean;

  /** Size of the buttons and text on the left */
  size?: Size;

  /** Disables the save button */
  disabled?: boolean;
};

const NewSaveBar: React.FC<Props> = ({
  dataTestId,
  goBackLink,
  changes,
  errors = [],
  onSave,
  onCancel,
  withGoBackLink,
  disabled,
  loading = false,
  size = 'medium',
  ...rest
}) => {
  const hasChanges = changes !== 0;
  const hasErrors = errors.length !== 0;
  const hasValidBackLink = withGoBackLink && !isNil(goBackLink);

  return (
    <Toolbar dataTestId={dataTestId} {...rest}>
      {!hasChanges && !hasErrors && hasValidBackLink && (
        <GoBackLinkContainer
          justification="start"
          align="center"
          dataTestId={TEST_ID.GO_BACK_BUTTON}
        >
          <StyledLink to={goBackLink}>
            <StyledIcon name="arrowLeft" strokeWidth={3} />
            Terug naar overzicht
          </StyledLink>
        </GoBackLinkContainer>
      )}
      <StyledContainer
        justification="end"
        align="center"
        dataTestId={TEST_ID.SAVE_BAR_CHANGES}
      >
        {!hasErrors && hasChanges && (
          <ChangeCountContainer
            data-testid={TEST_ID.CHANGE_COUNT_CONTAINER}
            size={size}
          >
            Je hebt {changes} niet opgeslagen aanpassingen.
          </ChangeCountContainer>
        )}
        {hasErrors && (
          <ErrorContainer
            justification="center"
            align="center"
            dataTestId={TEST_ID.SAVE_BAR_ERROR}
          >
            <Icon name="slash" strokeWidth={3} />
            <ErrorMessage>
              {errors[0].key}&nbsp;
              {errors[0].url && <a href={errors[0].url}>Bekijk fout</a>}
            </ErrorMessage>
          </ErrorContainer>
        )}

        <ButtonsContainer>
          {hasChanges && !hasErrors && (
            <Button
              label="Afbreken"
              ghost
              size={size}
              onClick={onCancel}
              dataTestId={TEST_ID.CANCEL_BUTTON}
            />
          )}
          <StyledButton
            label="Aanpassingen opslaan"
            icon="save"
            size={size}
            appearance="secondary"
            disabled={disabled}
            onClick={onSave}
            dataTestId={TEST_ID.SAVE_BUTTON}
            loading={loading}
          />
        </ButtonsContainer>
      </StyledContainer>
    </Toolbar>
  );
};

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('xxs')};
    color: ${theme.color('primary', 'light')};
  `,
);

const StyledLink = styled(Link)<{}>(
  ({ theme }) => css`
    text-decoration: none;
    padding-top: ${theme.space('xxxs')};
    font-weight: ${theme.fw('semiBold')};
    display: flex;
  `,
);

const GoBackLinkContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    width: 100%;

    &:hover {
      color: ${theme.color('primary')};

      svg {
        color: ${theme.color('primary')};
      }
    }
  `,
);

const StyledButton = styled(Button)<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('m')};
    width: max-content;
  `,
);

const ChangeCountContainer = styled.div<{ size: Size }>(
  ({ theme, size }) => css`
    margin-right: ${theme.space('m')};
    font-size: ${theme.fs(size === 'small' ? 'base' : 'm')};
    color: ${theme.color('text', 'light')};
    width: 100%;
  `,
);

const ButtonsContainer = styled.div<{}>`
  display: flex;
`;

const StyledContainer = styled(JustificationContainer)<{}>`
  width: 100%;
`;

const ErrorContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);

const ErrorMessage = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
    font-weight: ${theme.fw('semiBold')};
    margin-left: ${theme.space('xxs')};
    margin-right: ${theme.space('m')};
  `,
);

export default NewSaveBar;
