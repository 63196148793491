import React from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Link as NavLink } from '~/components/Link';

import { OfficeInList } from '~/scenes/Settings/Profile/components/FormContainer';
import { UserStatus } from '~/graphql/types';

import { Field, EmailField } from '~/components/Forms';
import { FormUtils } from '~/components';
import { Input, InputGroup } from '~/components/Inputs';
import Catalog from '~/Catalog';
import SubFieldsHeader from '~/components/Forms/SubFieldsHeader';
import { DeleteInputButton } from '~/components/Buttons';
import cleanedFilename from '~/util/cleanedFilename';
import useErrorModal from '~/components/Alerts/useErrorModal';
import RadioButton from '~/components/RadioButton';
import useCurrentUser from '~/hooks/useCurrentUser';
import useUserRights from '~/hooks/useUserRights';
import useViewingModeProps from '~/hooks/useViewingModeProps';

type Props = {
  officesList: Array<OfficeInList>;
  readOnly: boolean | null | undefined;
  onMainOfficeUpdate: (name: string, value: string) => any;
  showDeleteModal: (arg0: string) => void;
  mayEdit: boolean;

  /** users/userId needs this prop*/
  userId?: string;
};

const StyledNavLink = styled(NavLink)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};

    :hover {
      color: ${darken(0.1, theme.color('primary', 'light'))};
    }
  `};
`;
const text = {
  headoffice: Catalog.mainOfficeLabel,
  office: Catalog.officeLabel,
  phone: Catalog.phoneLabel,
  email: Catalog.emailLabel,
  role: Catalog.roleLabel,
  deleteErrorTitle: 'Verwijderen uit vestiging niet mogelijk',
  deleteErrorMessage: (
    <>
      Een gebruiker dient aan tenminste één vestiging gekoppeld te zijn. Ga naar{' '}
      <StyledNavLink to={'/-/settings/users'}>Gebruikers</StyledNavLink> om deze
      gebruiker te wijzigen of te verwijderen.
    </>
  ),
};
const FormFields = ({
  officesList,
  readOnly,
  onMainOfficeUpdate,
  showDeleteModal,
  mayEdit,
}: Props) => {
  const { update: mayUpdate } = useUserRights({
    category: 'Users',
  });

  const viewingModeProps = useViewingModeProps();

  const me = useCurrentUser();
  const numberOfOffices = officesList.length;

  if (me == null) {
    throw new Error(
      `${cleanedFilename(__filename)} | Should not occur | User is null`,
    );
  }

  const [errorModal, showErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.deleteErrorMessage,
  });

  return (
    <>
      <InputGroup>
        <EmailField name="email">
          {({ input, meta: { error, touched } }) => (
            <Input
              large
              label={text.email}
              error={FormUtils.showError(error, touched)}
              disabled
              {...input}
              data-testid="user-email-input"
              {...viewingModeProps}
            />
          )}
        </EmailField>
      </InputGroup>

      <InputGroup>
        <Field name="phone">
          {({ input, meta: { error, touched, submitting } }) => (
            <Input
              large
              label={text.phone}
              type="tel"
              error={FormUtils.showError(error, touched)}
              disabled={submitting || readOnly}
              {...input}
              data-testid="user-phone-input"
              {...viewingModeProps}
            />
          )}
        </Field>
      </InputGroup>

      <StyledSubFieldsHeader>{text.office}</StyledSubFieldsHeader>

      {officesList.map((office, index) => {
        const isDeleting = office.relation?.status === UserStatus.Deleting;
        const fieldIndex = index + 1;
        const officeId = office.id;

        return (
          <InputGroupWithRadio key={index} data-testid="office-list-item">
            <InputGroup>
              <Field name={`officeName-${fieldIndex}`}>
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label={text.office}
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    disabled
                    {...input}
                    data-testid={`office-name-${fieldIndex}-input`}
                  />
                )}
              </Field>
              <Field name={`officeRole-${fieldIndex}`}>
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label={text.role}
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    disabled
                    {...input}
                  />
                )}
              </Field>
              <Field name="selectedMainOfficeId">
                {({ input }) => (
                  <React.Fragment>
                    <RadioButton
                      onChange={e => {
                        const { value } = e.target;
                        if (value === 'on') {
                          onMainOfficeUpdate('selectedMainOfficeId', officeId);
                        }
                      }}
                      checked={input.value === officeId}
                      disabled={readOnly || !mayUpdate}
                      dataTestid={`mainOffice-radio-${index}`}
                      dataObjectId={officeId}
                    />
                    {index === 0 && (
                      <RadioBtnLabel>{text.headoffice}</RadioBtnLabel>
                    )}
                  </React.Fragment>
                )}
              </Field>

              <DeleteInputButton
                dataTestId={`delete-user-button-${officeId}`}
                onClick={() => {
                  numberOfOffices === 1
                    ? showErrorModal()
                    : showDeleteModal(officeId);
                }}
                disabled={isDeleting || !mayEdit}
                loading={isDeleting}
                large
              />
            </InputGroup>
          </InputGroupWithRadio>
        );
      })}
      {errorModal}
    </>
  );
};

const RadioBtnLabel = styled.div<{}>`
  position: absolute;
  top: -8px;
  right: 25px;
`;

const InputGroupWithRadio = styled.div<{}>`
  position: relative;
`;

const StyledSubFieldsHeader = styled(SubFieldsHeader)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('primary')};
  `}
`;

export default FormFields;
