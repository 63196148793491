import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterMappingFields on Flow_ParameterMapping {
    mappingId
    mapping {
      __typename

      ... on Flow_Parameter_Number_Primitive {
        numberValue: value
      }

      ... on Flow_Parameter_Number_Pointer {
        variable
        field
      }

      ... on Flow_Parameter_String_Primitive {
        stringValue: value
      }

      ... on Flow_Parameter_String_Pointer {
        variable
        field
      }

      ... on Flow_Parameter_Boolean_Primitive {
        booleanValue: value
      }

      ... on Flow_Parameter_Boolean_Pointer {
        variable
        field
      }
    }
  }
`;
