import gql from 'graphql-tag';

export default gql`
  mutation UpdateAppPremiumValuationReport(
    $accountId: ID!
    $id: ID!
    $update: App_PremiumValuationReport__Input!
  ) {
    updateAppPremiumValuationReport(
      accountId: $accountId
      id: $id
      update: $update
    ) {
      id
      name
      algasId
      sendAgentEmail
      sendContactEmail
      domain
    }
  }
`;
