import { FlowParameterEventFieldsFragment } from '~/graphql/types';
import { AppBBWaardecheckReportPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

import { PARAMETER_VALUE_TYPE } from '../../../constants';
import emptyBBWaardecheckReportEventParameterValue from './emptyBBWaardecheckReportEventParameterValue';

export default (
  eventBBWaardecheckReportParameter:
    | Extract<
        FlowParameterEventFieldsFragment,
        {
          __typename: 'Flow_Parameter_Event_Contact_App_BBWaardecheck_Report_Pointer';
        }
      >
    | null
    | undefined,
): AppBBWaardecheckReportPointerParameterValue => {
  if (eventBBWaardecheckReportParameter == null) {
    return emptyBBWaardecheckReportEventParameterValue();
  }
  switch (eventBBWaardecheckReportParameter.__typename) {
    case 'Flow_Parameter_Event_Contact_App_BBWaardecheck_Report_Pointer':
      return {
        type: PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_BBWAARDECHECK_REPORT_POINTER,
        variable: {
          name: eventBBWaardecheckReportParameter.variable,
          field: eventBBWaardecheckReportParameter.field
            ? {
                name: eventBBWaardecheckReportParameter.field,
              }
            : null,
        },
      };
    default:
      return assertNever(
        eventBBWaardecheckReportParameter.__typename,
        cleanedFilename(__filename),
      );
  }
};
