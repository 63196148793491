import React, { useState, useRef, useEffect } from 'react';

import {
  GetMyTasksQuery,
  GetMyTasksQueryVariables,
  GetTasksQuery,
  GetTasksQueryVariables,
  GetActivitiesForContactQuery,
  GetActivitiesForContactQueryVariables,
  MutatedTaskSubscription,
  MutatedTaskSubscriptionVariables,
  MutatedActivitySubscription,
  MutatedActivitySubscriptionVariables,
} from './types';

import Query, { QueryArgs } from './Query';
import { deepEquals } from '~/util/object';
import { isEmpty } from 'lodash';
import { DocumentNode } from 'graphql';

/**
 * This component automatically subscribes and re-queries
 * if the internet connection was lost. We will be hopefully be
 * able to remove this in the future.
 *
 * @see https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/230
 * @see https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/132
 * @see https://github.com/awslabs/aws-mobile-appsync-sdk-js/pull/234
 * @see https://github.com/aws-amplify/amplify-js/issues/1448
 */

type UpdateQueryOptions<TVariables, TSubscriptionData> = {
  variables: TVariables;
  subscriptionData: {
    data: TSubscriptionData;
  };
};

type SubscribedQueryArgs<
  TData,
  TVariables,
  TSubscriptionData,
  TSubscriptionVariables,
> = QueryArgs<TData, TVariables> & {
  subscriptionQuery: DocumentNode;
  subscriptionVariables: TSubscriptionVariables;
  updateQuery: (
    previousResult: TData | null | undefined,
    options: UpdateQueryOptions<TVariables, TSubscriptionData>,
  ) => TData | null | undefined;
};
const SubscribedQuery = <
  TData,
  TVariables,
  TSubscriptionData,
  TSubscriptionVariables,
>({
  subscriptionQuery,
  subscriptionVariables,
  updateQuery,
  children,
  ...forwardingProps
}: SubscribedQueryArgs<
  TData,
  TVariables,
  TSubscriptionData,
  TSubscriptionVariables
>): JSX.Element | null => {
  const [error, setError] = useState<$Object | null>(null);

  const _unsubscribe = useRef<(() => void | null | undefined) | null>(null);
  const _currentVariables = useRef<$Object | null | undefined>(null);
  const _failedCount = useRef<number | null | undefined>(null);
  const _isMounted = useRef<boolean>(false);

  /**
   * There seems to be a bug in the way appsync is handling the
   * subscriptions if the come in in rapid succession.
   *
   * @see https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/510
   *
   * We are currently handling deduplication ourselves.
   */
  const _tmpCache = useRef<$Object>({});

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
      if (_unsubscribe.current != null) {
        _unsubscribe.current();
      }
    };
  }, []);

  const internalSubscribeToMore = (
    subscribeToMore: any,
    stopPolling: () => void,
  ) => {
    stopPolling();

    if (_unsubscribe.current != null) {
      if (
        _currentVariables.current != null &&
        deepEquals(_currentVariables.current, subscriptionVariables)
      ) {
        return;
      } else {
        // new variables, so unsubscribe and continue with subscribing below
        if (_unsubscribe.current != null) _unsubscribe.current();
      }
    }

    _currentVariables.current = subscriptionVariables;

    _unsubscribe.current = subscribeToMore({
      onError: error => {
        // eslint-disable-next-line no-console
        console.error('_failedCount.current', _failedCount.current);
        // eslint-disable-next-line no-console
        console.error('_unsubscribe.current', _unsubscribe.current);
        // eslint-disable-next-line no-console
        console.error('SubscribedToMoreError - Starting polling', error);

        if (_unsubscribe.current != null) _unsubscribe.current();
        _unsubscribe.current = null;

        _failedCount.current =
          _failedCount.current != null ? _failedCount.current + 1 : 1;
      },
      document: subscriptionQuery,
      variables: subscriptionVariables,
      updateQuery: (previousResult, options) => {
        _failedCount.current = 0;
        let result;

        /**
         * The previousResult could be an empty object, if the query did not return data yet.
         *
         * E.g. The query is underway, but the subscription has already returned data.
         *
         * In this case we are ignoring the subscription result. If this makes problems, having a cache and
         * merging the results later might be a better way.
         */
        if (isEmpty(previousResult)) {
          return previousResult;
        }

        /** Subscription de-duplication code */
        const {
          subscriptionData: { data },
        } = options;

        if (!isEmpty(data)) {
          const [mutatedDataKey] = Object.keys(data);
          const mutatedData = data[mutatedDataKey];

          if (!_tmpCache.current[mutatedData.id]) {
            _tmpCache.current[mutatedData.id] = mutatedData;
          }

          const highestVersion = _tmpCache.current[mutatedData.id];

          if (highestVersion._v > mutatedData._v) {
            /** We have already seen a higher version earlier */
            data[mutatedDataKey] = highestVersion;
          } else {
            /** Update the currently highest version in our cache */
            _tmpCache.current[mutatedData.id] = data.mutatedTask;
          }
        }

        try {
          result = updateQuery(previousResult, options);
        } catch (error) {
          setError({ error });
          return previousResult;
        }

        return result;
      },
    });
  };

  useEffect(() => {
    if (error != null) {
      setError(null);

      throw error;
    }
  }, [error]);

  return (
    <Query partialRefetch {...forwardingProps}>
      {({
        subscribeToMore,
        stopPolling,
        startPolling,
        data,
        updateQuery,

        ...rest
      }) => {
        internalSubscribeToMore(subscribeToMore, stopPolling);

        return children({
          data,
          updateQuery: (...args) => {
            if (_isMounted.current) {
              updateQuery(...args);
            }
          },
          subscribeToMore,
          stopPolling,
          startPolling,
          ...rest,
        });
      }}
    </Query>
  );
};

export const GetMyTasksWithSubscriptionQuery = (
  props: SubscribedQueryArgs<
    GetMyTasksQuery,
    GetMyTasksQueryVariables,
    MutatedTaskSubscription,
    MutatedTaskSubscriptionVariables
  >,
) => SubscribedQuery(props);
export type GetMyTasksWithSubscriptionUpdateQueryOptions = UpdateQueryOptions<
  GetMyTasksQueryVariables,
  MutatedTaskSubscription
>;

export const GetTasksWithSubscriptionQuery = (
  props: SubscribedQueryArgs<
    GetTasksQuery,
    GetTasksQueryVariables,
    MutatedTaskSubscription,
    MutatedTaskSubscriptionVariables
  >,
) => SubscribedQuery(props);
export type GetTasksWithSubscriptionUpdateQueryOptions = UpdateQueryOptions<
  GetTasksQueryVariables,
  MutatedTaskSubscription
>;

export const GetActivitiesWithSubscriptionQuery = (
  props: SubscribedQueryArgs<
    GetActivitiesForContactQuery,
    GetActivitiesForContactQueryVariables,
    MutatedActivitySubscription,
    MutatedActivitySubscriptionVariables
  >,
) => SubscribedQuery(props);

export default SubscribedQuery;
