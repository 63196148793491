import { FlowAction } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import String from '~/util/Validation/String';

/**
 * Check if an action is empty, used for showing the CTA prompting the user to update the action.
 * not to be confused with regular validation of each action
 * @param {ClientFlowAction} action - The action you want to check
 * keywords: empty action
 */
const isEmptyAction = (action: ClientFlowAction | null): boolean => {
  if (action === null) return true;

  switch (action.actionType) {
    case FlowAction.ContactAddTag:
    case FlowAction.ContactDeleteTag:
      if (String.isNonEmptyString(action.name)) return false;
      return true;
    case FlowAction.ContactAssign:
      if (String.isNonEmptyString(action.office)) return false;
      return true;
    case FlowAction.ContactDetails:
      if (
        action.field.name &&
        String.isNonEmptyString(action.field.name.template)
      )
        return false;
      if (
        action.field.phone &&
        String.isNonEmptyString(action.field.phone.template)
      )
        return false;
      return true;
    case FlowAction.IfElse:
    case FlowAction.Start:
    case FlowAction.Wait:
      if (
        action.triggers.length >= 1 &&
        (action.triggers[0].trigger !== null ||
          action.triggers[0].condition.conditions.length !== 0)
      ) {
        return false;
      }
      return true;
    case FlowAction.RealworksSendContact:
      if (String.isNonEmptyString(action.description.template)) return false;
      return true;
    case FlowAction.SendEmailPlain:
      if (String.isNonEmptyString(action.subject.template)) return false;
      if (String.isNonEmptyString(action.body.template)) return false;
      return true;
    case FlowAction.TaskCreate:
      if (String.isNonEmptyString(action.title.template)) return false;
      if (String.isNonEmptyString(action.description.template)) return false;
      return true;
    case FlowAction.ZapierTrigger:
      if (String.isNonEmptyString(action.zapierTriggerId)) return false;
      return true;

    default:
      return false;
  }
};
export default isEmptyAction;
