import { unnest } from 'ramda';
import {
  LayoutStyleBlockFE,
  StyleBlockFE,
} from '~/components/EventTimelineV2/types';

/**
 * Return simple list layout that is a vertical section
 * @param {string} fields - Example argument
 * keywords:
 */
const createSimpleList = (...rest): Array<LayoutStyleBlockFE> =>
  unnest(
    rest.map((fields: Array<StyleBlockFE>) => [
      {
        __typename: 'FlexLayout',
        direction: 'column',
        blocks: [...fields],
        id: '',
        childrenIds: [],
      } as LayoutStyleBlockFE,
    ]),
  );

export default createSimpleList;
