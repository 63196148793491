import React, { Component } from 'react';
import styled from 'styled-components';

import { EmailSyncStatus } from '~/graphql/types';

import { Loading } from '~/components';
import FullPageInformation from '~/components/FullPageInformation';
import ErrorMessages from '~/ErrorMessages';

const text = {
  alreadySynced: 'Al gesynchroniseerd',
  alreadySyncedExplanation: (email: string | null) =>
    `Het e-mail account ${email || ''} is al gesynchroniseerd in het systeem.`,
  invalidStateTitle: 'Oeps!',
  invalidState: (email: string): string =>
    `Er is iets misgegaan bij het synchroniseren van het e-mail account ${email}. Probeer het nog eens.`,
  invalidStateNoEmail:
    'Er is iets misgegaan bij het synchroniseren van het e-mail account. Probeer het nog eens.',
  successTitle: 'E-mail gekoppeld',
  successExplanation:
    'Het synchroniseren van je e-mail account is gestart. Vanaf nu zullen alle e-mails van en naar contacten automatisch bij het contact getoond worden.',
  goToLinkText: 'Voltooien',
  goToLinkErrorText: 'Probeer opnieuw',
};
export type Props = {
  /* The function to call to start the email syncing */
  startEmailSync: () => void;

  /* The error messages from the mutation */
  error: Array<string>;

  /* If the mutation is still loading */
  loading: boolean;

  /* The email address being synced */
  email: string | null;

  /* Sync status */
  syncState: EmailSyncStatus | null;

  /* Function called when the link button is pressed */
  goToLink: () => any;
};
type State = {};
class EmailSyncStatusDisplayer extends Component<Props, State> {
  componentDidMount() {
    this.props.startEmailSync();
  }

  render() {
    const { error, loading, email, goToLink } = this.props;

    if (error.length !== 0) {
      if (
        error.some(err =>
          err.startsWith(ErrorMessages.emailAlreadySynchronised),
        )
      ) {
        return (
          <FullPageInformation
            dataTestid="email-sync-error"
            title={text.alreadySynced}
            explanation={text.alreadySyncedExplanation(email)}
            goToLink={goToLink}
            goToLinkText={text.goToLinkErrorText}
          />
        );
      }

      return (
        <FullPageInformation
          dataTestid="email-sync-error"
          title={text.invalidStateTitle}
          explanation={
            typeof email === 'string'
              ? text.invalidState(email)
              : text.invalidStateNoEmail
          }
          goToLink={goToLink}
          goToLinkText={text.goToLinkErrorText}
        />
      );
    }

    if (loading) {
      return (
        <Container data-testid="email-sync-loading">
          <Loading />
        </Container>
      );
    }

    return (
      <FullPageInformation
        title={text.successTitle}
        explanation={text.successExplanation}
        dataTestid="email-sync-success"
        goToLink={goToLink}
        goToLinkText={text.goToLinkText}
      />
    );
  }
}

const Container = styled.div<{}>`
  position: relative;
  min-height: 100%;

  ${({ theme }) => `
    background-color: ${theme.color('white', 'dark')};
  `};
`;

export default EmailSyncStatusDisplayer;
