import { FlowV2_ParameterMappingFragment } from '~/graphql/types';
import { DirectoryMap } from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getDirectory';
import { InstanceMap } from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getInstance';
import getPathForPointer from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getPathForPointer';
import { SubjectMap } from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import getValueForArgument from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getValueForArgument';
import { getPathRepresentation } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/utils/getLabelForRep';
import isPointerArgument from '~/scenes/Automation/v2/util/isPointerArgument';
import isPrimitiveArgument from '~/scenes/Automation/v2/util/isPrimitiveArgument';
import { TEMPLATE_STRING_VARIABLE_REGEX } from '~/components/PluginsEditor/constants';

export const BAD_VARIABLE_NAME = 'bad-variable';

/**
 * Converts variables into dhvariable element in the editor
 *
 * keywords: convert template string, mappings, slate
 */
type Params = {
  str: string;
  mappings: Array<FlowV2_ParameterMappingFragment>;
  subjectMap: SubjectMap;
  instanceMap: InstanceMap;
  directoryMap: DirectoryMap;
};
const convertVariablesInHtml = ({
  str,
  mappings,
  subjectMap,
  instanceMap,
  directoryMap,
}: Params): string => {
  if (!str) return '';

  return str.replace(TEMPLATE_STRING_VARIABLE_REGEX, matchedVariable => {
    // Without the {{ }} tags
    const mappingId = matchedVariable.substring(2, matchedVariable.length - 2);

    const templateStringVariable = mappings.find(
      mapping => mapping.mappingId === mappingId,
    );

    if (templateStringVariable == null) {
      // If we can't find it the user could have typed something that resembles our variable matching. Just treat it as text
      return matchedVariable;
    }

    const argument = templateStringVariable.mapping;

    if (isPrimitiveArgument(argument)) {
      const variableName =
        templateStringVariable && argument ? getValueForArgument(argument) : '';
      /** We pass a random tag here, later on it will be deserialized and render the Variable component */
      return `<dhvariable dhvariablename="${variableName}" dhvariabletype="${templateStringVariable.mapping?.__typename}" dhmappingid="${mappingId}">`;
    } else if (isPointerArgument(argument)) {
      const path = getPathForPointer(argument.pointer, {
        subjectMap,
        instanceMap,
        directoryMap,
      });

      // Replace double quotes with single quotes so that it can be passed as an html attributes.
      // Later we convert it back to double quotes in deserialize function
      const arg = JSON.stringify(argument).replace(/"/g, "'");

      if (path.error === undefined) {
        return `<dhvariable dhvariablename="${getPathRepresentation(
          path.fullResult,
        )}" dhvariabletype="${
          templateStringVariable.mapping?.__typename
        }" dhmappingid="${mappingId}" dhvariableinfo="${arg}"></dhvariable>`;
      } else {
        return `<dhvariable dhvariablename="${BAD_VARIABLE_NAME}" dhvariabletype="bad" dhmappingid="${mappingId}" dhvariableinfo="${arg}"></dhvariable>`;
      }
    }

    return '';
  });
};

export default convertVariablesInHtml;
