import { TableHeaderDefinitionCellType } from '~/components/DataTables/types.flow';

import TEST_ID from './index.testid';
import TableHeaderCell from '~/components/DataTables/util/TableHeaderCell';
import { truncate } from 'lodash';
import { memoize } from 'underscore';

export const tokensListColumns = (
  _key,
): Array<TableHeaderDefinitionCellType<any>> => [
  TableHeaderCell.string({
    label: 'Naam',
    dataProperty: 'name',
  }),
  TableHeaderCell.string({
    label: 'Vestigingen',
    dataProperty: 'officeName',
    dataTestid: TEST_ID.OFFICE_COLUMN,
    renderFunction: (value: string) =>
      truncate(value, {
        length: 50,
        separator: ' ',
      }),
  }),
  TableHeaderCell.string({
    label: 'Token ID',
    dataProperty: 'id',
    renderFunction: (value: string) =>
      truncate(value, {
        length: 150,
        separator: ' ',
      }),
  }),
  TableHeaderCell.string({
    label: 'City',
    dataProperty: 'city',
    renderFunction: (value: string) =>
      truncate(value, {
        length: 50,
        separator: ' ',
      }),
  }),
  TableHeaderCell.string({
    label: 'Straat',
    dataProperty: 'street',
    renderFunction: (value: string) =>
      truncate(value, {
        length: 50,
        separator: ' ',
      }),
  }),
  TableHeaderCell.string({
    label: 'Postcode',
    dataProperty: 'postalCode',
    renderFunction: (value: string) =>
      truncate(value, {
        length: 50,
        separator: ' ',
      }),
  }),

  TableHeaderCell.action({
    dataTestid: TEST_ID.ACTION,
  }),
];

const memoizedTokensListColumns = memoize(tokensListColumns, key => key);

export default memoizedTokensListColumns;
