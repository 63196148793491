import { FlowConditionAppVBOWaardecheckAppraisalRequestDetails } from './types.flow';

import { emptyVBOWaardecheckAppraisalRequestEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionAppVBOWaardecheckAppraisalRequestDetails = (): Omit<
  FlowConditionAppVBOWaardecheckAppraisalRequestDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES
    .EVENT_CONTACT_APP_VBOWAARDECHECK_APPRAISALREQUEST_DETAILS.type,
  fields: [],
  event: emptyVBOWaardecheckAppraisalRequestEventParameterValue(),
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
});

export default emptyFlowConditionAppVBOWaardecheckAppraisalRequestDetails;
