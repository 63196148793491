import React, { useState } from 'react';
import styled from 'styled-components';

import { Signature, UserRole } from '~/graphql/types';

import { Admin, User } from '../Account.flow';
import { Mailboxes } from '~/graphql/types.client.flow';

import {
  UpdateAccountRelationMutation,
  UpdateAccountMutation,
} from '~/graphql/';
import mutationUpdateAccountRelation from '~/graphql/mutation/UpdateAccountRelation';
import mutationUpdateAccount from '~/graphql/mutation/UpdateAccount';

import AccountDrawer from './AccountDrawer';
import AccountAdministrators from './AccountAdministrators';
import SynchronisedMailboxes from '~/scenes/Settings/components/SynchronisedMailboxes';
import cleanedFilename from '~/util/cleanedFilename';
import { SynchroniseEntityType } from '~/scenes/External/Nylas/util';
import DetailsBlock from '~/components/DetailsBlock';
import SignatureContainer from '~/components/SignatureContainer';
import JustificationContainer from '~/components/JustificationContainer';
import CopyTextBlock from '~/components/CopyTextBlock';
import EditableName from '~/components/DetailsPage/EditableName';

type AccountRelation = {
  role: UserRole;
  userId: string;
};

type Props = {
  refetch: () => void;
  accountId: string;
  accountName: string;
  mayEdit: boolean;
  users: Array<User>;
  admins: Array<Admin>;
  mailboxes: Mailboxes;
  setErrorMsg: (msg: string | null) => void;
  startPolling: (interval: number) => void;
  stopPolling: () => void;
  signature: Signature | null;
};

export const text = {
  accountNameUpdateError: 'Account update failed',
  accountUsersRalationError: 'Account users role update failed',
  emailIntegration: 'E-mailintegratie',
  signature: 'Handtekening account',
  accountIdLabel: 'Account ID',
};

const AccountComponent = ({
  accountId,
  accountName,
  mayEdit,
  refetch,
  users,
  admins,
  mailboxes,
  startPolling,
  stopPolling,
  setErrorMsg,
  signature,
}: Props) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const filteredMailboxes = mailboxes.filter(
    mailbox =>
      mailbox.__typename === 'AccountMailbox' ||
      mailbox.__typename === 'AccountMailboxAlias',
  );

  return (
    <React.Fragment>
      <AccountDetailsForm data-testid="accounts-page">
        <UpdateAccountMutation
          name={cleanedFilename(__filename)}
          mutation={mutationUpdateAccount}
          onCompleted={refetch}
          onError={() => setErrorMsg(text.accountNameUpdateError)}
        >
          {updateAccount => {
            const updateAccountName = (value: string) =>
              updateAccount({
                variables: {
                  accountId,
                  update: {
                    name: value,
                  },
                },
              });

            return (
              <JustificationContainer
                direction="column"
                gap="xxs"
                margin={[null, null, 'xxl', null]}
              >
                <EditableName
                  edit={mayEdit}
                  updateName={updateAccountName}
                  name={accountName}
                  loading={false}
                />
                <CopyTextBlock
                  textToCopy={accountId}
                  label={text.accountIdLabel}
                />
              </JustificationContainer>
            );
          }}
        </UpdateAccountMutation>
        <UpdateAccountRelationMutation
          name={cleanedFilename(__filename)}
          mutation={mutationUpdateAccountRelation}
          onCompleted={refetch}
          onError={() => setErrorMsg(text.accountUsersRalationError)}
        >
          {(updateAccountRelation, { loading: updateAccountIsLoading }) => {
            const updateUserRole = ({ role, userId }: AccountRelation) =>
              updateAccountRelation({
                variables: {
                  accountId,
                  userId,
                  update: {
                    role,
                  },
                },
              });

            return (
              <>
                <AccountAdministrators
                  loading={updateAccountIsLoading}
                  handleToggleDrawer={setOpenDrawer}
                  admins={admins}
                  updateUserRole={updateUserRole}
                  isAdmin={mayEdit}
                />
                <DetailsBlock title={text.emailIntegration}>
                  <SynchronisedMailboxes
                    mailboxes={filteredMailboxes}
                    entityType={SynchroniseEntityType.Account}
                    entityId={accountId}
                    onSuccessfulDelete={refetch}
                    onSuccessfulSync={refetch}
                    onSuccessfulAliasVerificationStart={() => {
                      refetch();
                      startPolling(3000);
                    }}
                    onSuccessfulAliasVerificationEnd={() => {
                      stopPolling();
                    }}
                    mayEdit={mayEdit}
                  />
                </DetailsBlock>

                <AccountDrawer
                  handleToggleDrawer={setOpenDrawer}
                  usersList={users}
                  updateUserRole={updateUserRole}
                  isOpen={openDrawer}
                  loading={updateAccountIsLoading}
                />

                <SignatureContainer
                  title={text.signature}
                  signature={signature}
                  refetch={refetch}
                  readOnly={!mayEdit}
                />
              </>
            );
          }}
        </UpdateAccountRelationMutation>
      </AccountDetailsForm>
    </React.Fragment>
  );
};

const ACCOUNT_DETAILS_FORM_MAX_WIDTH = 540;

const AccountDetailsForm = styled.section<{}>`
  width: 100%;
  max-width: ${ACCOUNT_DETAILS_FORM_MAX_WIDTH}px;
`;

export default AccountComponent;
