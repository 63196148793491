import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Icon from '~/components/Icon';

import InputWrapper from './components/InputWrapper';
import ValueReportComponentCard from './components/ValueReportComponentCard';
import { PrivacyProps } from './ValueReport.type';

const text = {
  title: 'Privacy',
  cookieStatement: 'Link cookiestatement',
  privacyStatement: 'Link privacystatement',
};

type Props = {
  /** Data field name for correct onChange handling */
  name: string;

  /** data contains: { cookiePolicy, privacyPolicy } */
  data: PrivacyProps;

  /** Callback function which call data updating in container state */
  onChange: (arg0: $Object) => void;
} & RouteComponentProps;

const Privacy = (props: Props) => {
  const onChange = (value: $Object) => {
    props.onChange({
      [props.name]: { ...props.data, ...value },
    });
  };
  const { cookiePolicy, privacyPolicy } = props.data;

  return (
    <ValueReportComponentCard
      datatestId={'tab-contact-privacy'}
      title={text.title}
      cardIcon={<Icon name="key" />}
    >
      <InputWrapper
        text={text.cookieStatement}
        name={'cookiePolicy'}
        value={cookiePolicy || ''}
        onChange={onChange}
      />
      <InputWrapper
        text={text.privacyStatement}
        name={'privacyPolicy'}
        value={privacyPolicy || ''}
        onChange={onChange}
      />
    </ValueReportComponentCard>
  );
};

export default Privacy;
