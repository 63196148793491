import React from 'react';
import styled, { css } from 'styled-components';

import { MarginlessH4, MarginlessH5 } from '~/components/Typography';
import { ContactInformationBlockContainer } from '../ContactBlock.style';

import TEST_ID from './index.testid';
import LeadScoreDisplayer from '~/components/LeadScoreDisplayer';
import Icon from '~/components/Icon';
import ContactInformationSettings from '../ContactInformationSettings';
import useViewingModeProps from '~/hooks/useViewingModeProps';

const text = {
  contactDetailsTitle: 'Contactgegevens',
  unsubscribed: 'Uitgeschreven',
};
type Props = {
  contactId: string;
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  blockMarketing?: boolean | null;
  leadScore: number;
};
const ContactInformationBlock = ({
  contactId,
  name,
  phone,
  email,
  blockMarketing,
  leadScore,
}: Props) => {
  const viewingModeProps = useViewingModeProps();

  return (
    <ContactInformationBlockContainer data-testid={TEST_ID.CONTAINER}>
      <HeaderContainer>
        <TitleContainer>
          <Title {...viewingModeProps}>{name}</Title>
        </TitleContainer>

        <SettingsWithLeadScoreContainer>
          <LeadScoreDisplayer leadScore={leadScore} />
          <StyledContactInformationSettings
            contactId={contactId}
            blockMarketing={blockMarketing}
          />
        </SettingsWithLeadScoreContainer>
      </HeaderContainer>
      {blockMarketing && (
        <TagContainer data-testid={TEST_ID.SUBSCRIBE_TAG}>
          {text.unsubscribed}
        </TagContainer>
      )}

      <SubsectionTitle>{text.contactDetailsTitle}</SubsectionTitle>
      <SectionField>
        <IconWrapper>
          <Icon name="phone" />
        </IconWrapper>
        <span {...viewingModeProps}>{phone}</span>

        <IconWrapper>
          <Icon name="mail" />
        </IconWrapper>
        <span {...viewingModeProps}>{email}</span>
      </SectionField>
    </ContactInformationBlockContainer>
  );
};

const StyledContactInformationSettings = styled(
  ContactInformationSettings,
)<any>`
  display: flex;
  justify-self: end;
`;

const SettingsWithLeadScoreContainer = styled.div<{}>`
  display: flex;
`;

const HeaderContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled(MarginlessH4)<{}>`
  width: 100%;
`;

const TitleContainer = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TagContainer = styled.div<{}>`
  text-align: center;
  padding: 5px 7px 4px;
  width: 100px;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    color: ${theme.color('primary', 'light')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('primary', 'light')};
    font-size: ${theme.fontSize('s')};
    margin-top: ${theme.space('xxs')};
  `};
`;

const IconWrapper = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('success')};
    margin-right: ${theme.space('xxs')};
  `,
);

const SectionField = styled.div<{}>`
  display: grid;
  grid-template-columns: 40px auto;
  ${({ theme }) => css`
    grid-row-gap: ${theme.space('s')};
  `};
`;

const SubsectionTitle = styled(MarginlessH5)<{}>(
  ({ theme }) => css`
    border-bottom: solid 1px ${theme.color('grey', 'light')};
    padding-top: ${theme.space('m')};
    padding-bottom: ${theme.space('xxs')};
    margin-bottom: ${theme.space('s')};
  `,
);

export default ContactInformationBlock;
