import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';

import { Crumb } from '~/components/Selector/components/Breadcrumbs';
import { ContactFiltersSubjectFragment } from '~/graphql/types';

import { clone, last, lensPath, set } from 'ramda';
import getInnerFieldsByPath, {
  InnerContactFilterFieldOutput,
} from '../../util/contactFilter/getInnerFieldsByPath';
import { OuterContactFilterField } from '../../util/contactFilter/getOuterFieldsByPath';
import { getPathForCommand } from '../../util/contactFilter/getPathForLeaf';

import getCommand from '../../util/contactFilter/getCommand';
import getLabelForRepresentation from '../../util/contactFilter/getLabelForRepresentation';

import TextButton from '~/components/TextButton';
import { Leaf } from '../FilterGroupV2';
import { TopicMap } from '../../util/contactFilter/getTopic';
import { OptionTypeMap } from '../../util/contactFilter/getOptionType';
import {
  CommandMap,
  TypeIdToCommandsMap,
} from '../../util/contactFilter/getCommand';
import { SubjectMap } from '../../util/contactFilter/getSubject';
import { TypeMap } from '../../util/contactFilter/getType';
import { CurrentCommand } from '../FilterActionSelector';
import CommandRepresentation from '../CommandRepresentationComponent';
import OperatorComponent from '../OperatorComponent';
import { ElementSize } from '~/hooks/useSize';
import ErrorScreen from '~/scenes/ErrorScreen';

export type SelectedInnerField = Array<InnerContactFilterFieldOutput>;

const text = {
  addCommand: 'Eigenschap toevoegen',
};

export type Props = {
  dataTestId?: string;
  translateX?: number;
  basePath: Array<OuterContactFilterField>;
  leaf: Leaf;
  leafs: Array<Leaf>;
  setLeafs: React.Dispatch<React.SetStateAction<Props['leafs']>>;
  currentCommand: CurrentCommand;
  setCurrentCommand: React.Dispatch<React.SetStateAction<CurrentCommand>>;

  subject: ContactFiltersSubjectFragment | null;
  filterGroupIndex: number;
  currentLeafIndex: number;
  subjectMap: SubjectMap;
  topicMap: TopicMap;
  typeMap: TypeMap;
  optionTypeMap: OptionTypeMap;
  commandMap: CommandMap;
  typeIdToCommandsMap: TypeIdToCommandsMap;

  debouncedActionSelectorSize: ElementSize;
  setPortalledSelectorRef: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<HTMLDivElement | null>>
  >;
};

const FilterInnerSelector: React.FC<Props> = ({
  dataTestId,
  setPortalledSelectorRef,
  debouncedActionSelectorSize,
  currentCommand,
  setCurrentCommand,
  basePath,
  subjectMap,
  typeMap,
  topicMap,
  commandMap,
  typeIdToCommandsMap,
  leaf,
  filterGroupIndex,
  currentLeafIndex,
  leafs,
  setLeafs,
  subject,
  optionTypeMap,
  ...rest
}) => {
  const initialSelectedField = useMemo(() => {
    const command = currentCommand
      ? leaf.instanceAttribute?.commands[currentCommand.commandIdx]
      : null;

    if (
      !command ||
      !command.commandId ||
      !command.path ||
      command.path.length === 0
    ) {
      return [];
    }
    const path = getPathForCommand(
      {
        commandId: command.commandId,
        path: command.path,
      },
      subject,
      { commandMap, topicMap },
    );

    if (path.error != null) return [];

    const commandBlock = getCommand(command.commandId, commandMap);
    if (!commandBlock) return [];
    const clonedResult = clone(path.result);
    clonedResult.push(commandBlock);
    return clonedResult;
  }, [
    commandMap,
    currentCommand,
    leaf.instanceAttribute?.commands,
    subject,
    topicMap,
  ]) as SelectedInnerField;

  const [selectedField, setSelectedField] = useState<SelectedInnerField>(
    initialSelectedField ?? [],
  );
  useEffect(() => {
    setSelectedField(initialSelectedField);
  }, [
    initialSelectedField,
    currentCommand?.commandIdx,
    leaf.instanceAttribute?.commands,
  ]);

  const initialRepresentations =
    leaf.instanceAttribute?.commands.map(command =>
      getLabelForRepresentation(
        {
          command,
          subjectId: subject?.subjectId,
          eventId: subject?.eventId ?? undefined,
        },
        { subjectMap, commandMap, topicMap, typeMap, optionTypeMap },
      ),
    ) ?? [];

  const [representations, setRepresentations] = useState<
    Array<{ error?: undefined; result: string } | { error: string }>
  >(initialRepresentations);

  const currentFields = useMemo(
    () =>
      getInnerFieldsByPath(selectedField, basePath, {
        subjectMap,
        topicMap,
        commandMap,
        typeIdToCommandsMap,
      }),
    [
      selectedField,
      basePath,
      subjectMap,
      topicMap,
      commandMap,
      typeIdToCommandsMap,
    ],
  );

  const onFieldSelect = (field: InnerContactFilterFieldOutput) =>
    setSelectedField(prev => prev.concat(field));

  const onBreadcrumbClick = useCallback(
    (_crumb: Crumb, index: number) => {
      if (_crumb.key === 'root') {
        return setSelectedField([]);
      }

      return setSelectedField(selectedField.slice(0, index));
    },
    [selectedField],
  );

  const buttonRefs = useRef<{ [key: string]: null | HTMLButtonElement }>({});

  if (leaf.instanceAttribute == null) return null;

  if (currentFields.error !== undefined) return <ErrorScreen />;

  const lastSelectedField = last(selectedField);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <>
        {leaf.instanceAttribute.commands.map((command, idx) => {
          const isCommandSelected = currentCommand?.commandIdx === idx;

          const representation = representations[idx] ?? { result: '...' };
          return (
            <LineContainer key={idx}>
              <CommandRepresentation
                setPortalledSelectorRef={setPortalledSelectorRef}
                debouncedActionSelectorSize={debouncedActionSelectorSize}
                key={idx}
                representation={representation}
                selectedField={selectedField}
                currentFields={currentFields}
                buttonRefs={buttonRefs}
                isCommandSelected={isCommandSelected}
                commandIdx={idx}
                lastSelectedField={lastSelectedField}
                onFieldSelect={onFieldSelect}
                onBreadcrumbClick={onBreadcrumbClick}
                currentCommand={currentCommand}
                setCurrentCommand={setCurrentCommand}
                subjectMap={subjectMap}
                setRepresentations={setRepresentations}
                topicMap={topicMap}
                commandMap={commandMap}
                typeIdToCommandsMap={typeIdToCommandsMap}
                leaf={leaf}
                currentLeafIndex={currentLeafIndex}
                leafs={leafs}
                setLeafs={setLeafs}
                subject={subject}
                typeMap={typeMap}
                optionTypeMap={optionTypeMap}
              />
              <OperatorComponent
                reserveLabelSpace
                withMargin
                disabled={currentCommand != null}
                blockIndex={idx}
                blockCount={leaf.instanceAttribute?.commands.length ?? 0}
                connector={leaf.instanceAttribute?.connector ?? null}
                leafIndex={currentLeafIndex}
                filterGroupIndex={filterGroupIndex}
                onChange={newConnector => {
                  setLeafs(prev => {
                    const clonedPrev = set(
                      lensPath([
                        currentLeafIndex,
                        'instanceAttribute',
                        'connector',
                      ]),

                      newConnector,

                      clone(prev),
                    );

                    return clonedPrev;
                  });
                }}
              />
            </LineContainer>
          );
        })}

        <ButtonContainer>
          <TextButton
            label={text.addCommand}
            icon="plus"
            margin={['s', null, null, null]}
            withoutPadding
            onClick={e => {
              e.currentTarget.blur();
              setLeafs(prev => {
                const clonedPrev = set(
                  lensPath([currentLeafIndex]),
                  {
                    ...prev[currentLeafIndex],
                    instanceAttribute: {
                      ...prev[currentLeafIndex].instanceAttribute,
                      // @ts-ignore
                      commands:
                        prev[currentLeafIndex].instanceAttribute?.commands !=
                        null
                          ? [
                              // @ts-ignore
                              ...prev[currentLeafIndex].instanceAttribute
                                .commands,
                              {},
                            ]
                          : [{}],
                    },
                  },
                  clone(prev),
                );

                setCurrentCommand({
                  commandIdx:
                    // @ts-ignore
                    clonedPrev[currentLeafIndex].instanceAttribute.commands
                      .length - 1,
                });

                return clonedPrev;
              });
            }}
          />
        </ButtonContainer>
      </>
    </Container>
  );
};

const LineContainer = styled.div<{}>(
  ({ theme }) =>
    css`
      display: flex;
      width: 100%;
      align-items: center;
      padding-bottom: ${theme.space('xxs')};
    `,
);

const Container = styled.div<{ $translateX?: number }>`
  ${({ $translateX }) => css`
    ${$translateX != null && `transform: translate(${$translateX}px);`}
  `}
`;

const ButtonContainer = styled.div<{}>(
  ({}) =>
    css`
      display: flex;
      align-self: flex-end;
    `,
);

export default FilterInnerSelector;
