import { FlowConditionContactTag } from './types.flow';
import { Flow_Condition__Input } from '~/graphql/types';

import { asBooleanParameterValueInput } from '../../FlowParameter/ParameterValue/Boolean';
import { asStringParameterValueInput } from '../../FlowParameter';

export default (condition: FlowConditionContactTag): Flow_Condition__Input => {
  const { exists, tagName } = condition;

  return {
    Contact: {
      Tag: {
        exists: asBooleanParameterValueInput(exists),
        name: asStringParameterValueInput(tagName),
      },
    },
  };
};
