import { FlowConditionAppBBWaardecheckAppraisalRequestNew } from './types.flow';
import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';

const emptyFlowConditionAppBBWaardecheckAppraisalRequestNew = (): Omit<
  FlowConditionAppBBWaardecheckAppraisalRequestNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES
    .EVENT_CONTACT_APP_BBWAARDECHECK_APPRAISALREQUEST_NEW.type,
});
export default emptyFlowConditionAppBBWaardecheckAppraisalRequestNew;
