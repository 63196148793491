import { useCallback, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { navigate } from '@reach/router';
import flowSettings from '~/scenes/Automation/v2/state/flowSettings';
import useBuilderContext from '../../hooks/useBuilderContext';
import flowChanges, {
  defaultChangesState,
} from '~/scenes/Automation/v2/state/flowChanges';
import { useLocation } from '@reach/router';
import flowIssues from '~/scenes/Automation/v2/state/flowIssues';
import { isEmpty, uniqBy } from 'ramda';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import {
  FlowV2_Action__Input,
  FlowV2__Input,
  useInsertFlowBlueprintMutation,
  useUpdateFlowBlueprintMutation,
} from '~/graphql/types';
import getSerializedActions from '~/scenes/Automation/v2/util/getSerializedActions';
import metadata from '~/scenes/Automation/v2/state/metadata';
import flowActions from '~/scenes/Automation/v2/state';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import getFlowActionsToClientActions from '~/scenes/Automation/v2/util/getFlowActionsToClientActions';
import useUsers from '~/hooks/useUsers';
import useOffices from '~/hooks/useOffices';

const text = {
  success: 'Aanpassingen opgeslagen',
  unresolvedIssues:
    'Deze flow bevat fouten. Corrigeer de fouten en probeer opnieuw.',
  createError: 'Er is iets fout gegaan tijdens het opslaan van flow',
  updateError:
    'Er is iets mis gegaan bij het opslaan van de aanpassingen, probeer het later opnieuw',
};

type ReturnProps = {
  /** Function to pass to the save button */
  onSave: () => void;

  /** Function to pass to the cancel button */
  onCancel: () => void;

  /** Loading state of the mutation */
  loading: boolean;
};

const useControlFunctions = (): ReturnProps => {
  const location = useLocation();
  const addToast = useAddToast();
  const { id: accountId } = useCurrentAccount();
  const { initialFlow, flowBlueprintId } = useBuilderContext();

  const setIssues = useSetRecoilState(flowIssues);
  const setFlowChanges = useSetRecoilState(flowChanges);
  const [actions, setActions] = useRecoilState(flowActions);
  const [flowMetadata, setFlowMetadata] = useRecoilState(metadata);

  const offices = useOffices({ onlyExistingOffices: false });
  const users = useUsers();

  const [
    { flowDescription, flowName, maximumFlowRun, enabled },
    setFlowSettings,
  ] = useRecoilState(flowSettings);

  const [updateFlowBlueprint, { loading: updateFlowLoading }] =
    useUpdateFlowBlueprintMutation();

  const flowVariables: Omit<FlowV2__Input, 'Actions'> = useMemo(
    () => ({
      accountId,
      id: flowBlueprintId,
      name: flowName,
      description: flowDescription || null,
      enabled,
      maximumFlowRun,
    }),
    [
      accountId,
      enabled,
      flowBlueprintId,
      flowDescription,
      flowName,
      maximumFlowRun,
    ],
  );

  const updateFlow = useCallback(() => {
    const { serializedActions, issueMap } = getSerializedActions(actions, {
      offices,
      users,
    });

    if (!isEmpty(issueMap)) {
      setIssues(issueMap);
      return addToast([formatToastMessage(text.unresolvedIssues, 'danger')]);
    }

    void updateFlowBlueprint({
      variables: {
        accountId,
        flow: {
          ...flowVariables,
          Actions: serializedActions as Array<FlowV2_Action__Input>,
        },
        metadata: uniqBy(action => action.flowBlueprintActionId, flowMetadata),
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        return addToast([formatToastMessage(text.updateError, 'danger')]);
      }

      if (data) {
        addToast([formatToastMessage(text.success, 'success')]);
        setFlowMetadata([]);
        setFlowChanges(defaultChangesState);
        return;
      }
    });
  }, [
    actions,
    accountId,
    flowVariables,
    flowMetadata,
    updateFlowBlueprint,
    setIssues,
    addToast,
    setFlowMetadata,
    setFlowChanges,
    offices,
    users,
  ]);

  const [insertFlowBlueprint, { loading: insertFlowLoading }] =
    useInsertFlowBlueprintMutation({
      fetchPolicy: 'no-cache',
    });

  const createFlow = useCallback(() => {
    const { serializedActions, issueMap } = getSerializedActions(actions, {
      offices,
      users,
    });

    if (!isEmpty(issueMap)) {
      setIssues(issueMap);
      return addToast([formatToastMessage(text.unresolvedIssues, 'danger')]);
    }

    void insertFlowBlueprint({
      variables: {
        accountId,
        flow: {
          ...flowVariables,
          Actions: serializedActions as Array<FlowV2_Action__Input>,
        },
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        return addToast([formatToastMessage(text.createError, 'danger')]);
      }

      if (data) {
        addToast([formatToastMessage(text.success, 'success')]);
        setFlowChanges(defaultChangesState);

        return navigate(
          `/-/automation/flows/builder-v2/update/${flowBlueprintId}`,
        );
      }
    });
  }, [
    actions,
    insertFlowBlueprint,
    accountId,
    flowVariables,
    setIssues,
    addToast,
    setFlowChanges,
    flowBlueprintId,
    offices,
    users,
  ]);

  const onCancel = useCallback(() => {
    const clientActions = getFlowActionsToClientActions(
      initialFlow.actions ?? [],
    );
    setActions(clientActions);
    setFlowChanges(defaultChangesState);

    const { enabled, maximumFlowRun, flowDescription, flowName } = initialFlow;
    setFlowSettings({
      enabled: enabled,
      maximumFlowRun: maximumFlowRun || null,
      flowDescription: flowDescription || null,
      flowName: flowName,
    });
    setIssues({});
  }, [initialFlow, setFlowChanges, setFlowSettings, setIssues, setActions]);

  if (location.pathname.includes('update')) {
    return {
      loading: updateFlowLoading,
      onSave: updateFlow,
      onCancel,
    };
  }

  return {
    loading: insertFlowLoading,
    onSave: createFlow,
    onCancel,
  };
};

export default useControlFunctions;
