import { OptionOf } from '~/components/Dropdown';
import { GetFlowsV2Query } from '~/graphql/types';

const formatFlowsAsOptions = (
  flows: GetFlowsV2Query['getFlowsV2']['items'],
): Array<OptionOf<GetFlowsV2Query['getFlowsV2']['items'][number]>> =>
  flows.map(flow => ({
    label: flow.name,
    payload: flow,
    key: flow.id,
  }));

export default formatFlowsAsOptions;
