import { useMemo } from 'react';
import { Plugin } from '~/components/PluginsEditor/types';
import useCurrentUser from '~/hooks/useCurrentUser';
import insertImage from '../components/elements/Image/insertAction';

/**
 * Inserts drag & dropped image into the editor.
 * If multiple images are dropped, it inserts the first image from the Files array
 */
const useWithImages = (): Plugin => {
  const { id: userId } = useCurrentUser();

  return useMemo(
    () => editor => {
      const { insertData } = editor;

      editor.insertData = async data => {
        const { files } = data;

        if (files && files.length > 0) {
          insertImage(editor, files, userId);
        } else {
          insertData(data);
        }
      };

      return editor;
    },
    [userId],
  );
};

export default useWithImages;
