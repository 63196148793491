import React from 'react';

import { TemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { FlowEmailAttachmentValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { GetTemplateComponentReturnType } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/TemplateString/types.flow';

import {
  TemplateStringParameterValueComponent,
  TemplateStringEmailParameterValueComponent,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/TemplateString';
import isValidTemplateString from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/TemplateString/util/isValidTemplateString';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';

const text = {
  requiredField: Catalog.requiredField,
  invalidString: 'Er is een onbekend veld',
  imagesStillUploading: 'Er worden nog afbeeldingen geladen',
};
type MyProps = {
  initialTemplateString: TemplateStringParameterValue;
  // If any files are inserted into the body
  initialInlineAttachments?: Array<FlowEmailAttachmentValue>;

  /** The component will call the function with a function that can be called to get the latest inline attachments */
  setGetInlineAttachments?: (fn: () => Array<FlowEmailAttachmentValue>) => void;

  /** The component will call the function with a function that can be called to get the latest template string parameter */
  setGetTemplateComponent: (fn: () => TemplateStringParameterValue) => void;

  /** If it is not allowed to be empty */
  isMandatory?: boolean;
  announceChanges: () => void;
  onBlur?: () => void;
  textarea?: boolean;
  singleLine?: boolean;
  keepHtml?: boolean;
  label?: string;
  type?: 'INPUT' | 'EMAIL';
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  validationErrorMessage: string | null;
  key: string | null;
};
class ParameterTemplateStringFieldComponent extends React.Component<
  Props,
  State
> {
  getTemplateString: (refresh?: boolean) => GetTemplateComponentReturnType =
    () => ({
      templateString: this.props.initialTemplateString,
      isStillUploading: false,
    });

  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
      validationErrorMessage: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  validate = () => {
    const { baseActionContext, isMandatory } = this.props;

    const { templateString, isStillUploading } = this.getTemplateString(true);

    if (isStillUploading) {
      this.setState({ validationErrorMessage: text.imagesStillUploading });

      return false;
    }

    if (isMandatory === true && templateString.template.length === 0) {
      this.setState({ validationErrorMessage: text.requiredField });

      return false;
    }

    if (
      !isValidTemplateString(templateString, baseActionContext.variableStash)
    ) {
      this.setState({ validationErrorMessage: text.invalidString });

      return false;
    }

    this.setState({ validationErrorMessage: null });

    return true;
  };

  onBlur = () => {
    if (this.state.validationErrorMessage != null) {
      this.validate();
    }
  };

  render() {
    const {
      type,
      // eslint-disable-next-line
      baseActionContext,
      // eslint-disable-next-line
      isMandatory,
      initialTemplateString,
      textarea,
      singleLine,
      setGetTemplateComponent,
      initialInlineAttachments,
      setGetInlineAttachments,
      ...rest
    } = this.props;
    const { validationErrorMessage } = this.state;

    const setGetTemplateComponentFn = fn => {
      this.getTemplateString = fn;
      setGetTemplateComponent(() => this.getTemplateString().templateString);
    };

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => {
          const errorMessage = showValidation ? validationErrorMessage : null;

          if (type != null && type === 'EMAIL') {
            return (
              <TemplateStringEmailParameterValueComponent
                initialInlineAttachments={initialInlineAttachments || null}
                initialTemplateStringValue={initialTemplateString}
                setGetTemplateComponent={setGetTemplateComponentFn}
                setGetInlineAttachments={setGetInlineAttachments}
                errorMessage={errorMessage}
                onBlur={this.onBlur}
                {...rest}
              />
            );
          }

          if (setGetInlineAttachments != null) {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | setGetInlineAttachments not supported on non-email parameters`,
            );
          }

          return (
            <TemplateStringParameterValueComponent
              initialTemplateStringValue={initialTemplateString}
              textarea={textarea}
              singleLine={singleLine}
              setGetTemplateComponent={setGetTemplateComponentFn}
              errorMessage={errorMessage}
              onBlur={this.onBlur}
              {...rest}
            />
          );
        }}
      </FlowContext.Consumer>
    );
  }
}

export default withBaseActionContext<MyProps>(
  ParameterTemplateStringFieldComponent,
);
