import {
  Field,
  BooleanField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { BooleanConditionField } from '../types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION } from './constants';
import { emptyBooleanPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter';

export default (field: Field | BooleanField): BooleanConditionField => ({
  ...field,
  type: FIELD_TYPE.BOOLEAN,
  operation: {
    type: FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS,
    value: emptyBooleanPrimitiveParameterValue(),
  },
});
