import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { darken } from 'polished';

import { SelectedOptionOf } from '~/components/Inputs/Dropdown';

import {
  SessionHydrationUserFieldsFragment,
  SessionHydrationUserFields_PendingUser_Fragment,
  useResendUserInvitationMutation,
  UserRole,
} from '~/graphql/types';
import { ListItemForDeletion } from '~/components/Modals/components/ListItemCard';
import Button from '~/components/Button';
import AccountContext from '~/contexts/AccountContext';
import TEST_ID from './index.testid';
import DeleteUserFromOfficeModal from '~/components/Modals/DeleteUserFromOfficeModal';
import { DeleteInputButton } from '~/components/Buttons';
import useErrorModal from '~/components/Alerts/useErrorModal';
import { Link as NavLink } from '~/components/Link';
import { convertViewableUserOfficeToListItemForDeletion } from '~/scenes/Settings/Users/utils';
import useCountdown from '~/util/useCountdown';
import useUserRelationToOffice from '~/hooks/useUserRelationToOffice';
import useUserLookup from '~/hooks/useUserLookup';
import {
  ButtonContainer,
  CardElement,
  UserEmailElement,
  UserPhoneElement,
  OfficeCounter,
  OfficeIcon,
  UserNameContainer,
  EnvelopeIcon,
  UserRoleElement,
} from '~/scenes/Settings/Users/components/UserCard.style';
import { isPendingStatus } from '~/util/constants';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useCurrentUser from '~/hooks/useCurrentUser';
import { createOfficeItemForModal } from '~/scenes/Settings/Profile/utils/createOfficeItemForModal';
import Dropdown from '~/components/Dropdown';
import { isNil } from 'ramda';
import useViewingModeProps from '~/hooks/useViewingModeProps';

type Props = {
  /** Handle user role dropdown change */
  onRoleChange?: (userId: string, role: UserRole) => any;

  /** If the role change is loading */
  loading: boolean;

  /** Used in delete user from office modal and getting userToOffice relations */
  officeId: string;

  userId: string;

  /** Can the logged in user interact with buttons and dropdowns */
  canEdit: boolean;
};

const StyledNavLink = styled(NavLink)<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary', 'light')};

    :hover {
      color: ${darken(0.1, theme.color('primary', 'light'))};
    }
  `,
);

const text = {
  user: 'Gebruiker',
  admin: 'Admin',
  noName: 'Er is geen naam ingevoerd',
  deleteErrorTitle: 'Verwijderen uit vestiging niet mogelijk',
  deleteErrorMessage: (
    <>
      Een gebruiker dient aan tenminste één vestiging gekoppeld te zijn. Ga naar{' '}
      <StyledNavLink to={'/-/settings/users'}>Gebruikers</StyledNavLink> om deze
      gebruiker te wijzigen of te verwijderen.
    </>
  ),
  sendInvitationAgain: 'Uitnodiging opnieuw versturen',
};

type RoleOption = { label: string; key: UserRole; payload: UserRole };
const roleOptions: Array<RoleOption> = [
  {
    label: text.user,
    key: UserRole.User,
    payload: UserRole.User,
  },
  {
    label: text.admin,
    key: UserRole.Admin,
    payload: UserRole.Admin,
  },
];

const isPending = (
  user,
): user is SessionHydrationUserFields_PendingUser_Fragment =>
  user?.__typename === 'PendingUser';

const OfficeUserCard = ({
  onRoleChange,
  loading,
  officeId,
  userId,
  canEdit,
}: Props) => {
  const viewingModeProps = useViewingModeProps();
  const [errorModal, showErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.deleteErrorMessage,
  });

  const { refetchSessionHydration, officesForUser } =
    useContext(AccountContext);
  const account = useCurrentAccount();
  const me = useCurrentUser();

  const userLookup = useUserLookup(['User', 'PendingUser']) as {
    [userId: string]: SessionHydrationUserFieldsFragment;
  };

  const user = userLookup?.[userId];
  const relation = useUserRelationToOffice({
    officeId,
    userId,
  });
  const offices = officesForUser(userId);

  const [showDeleteUserFromOfficeModal, setShowDeleteUserFromOfficeModal] =
    useState<Array<ListItemForDeletion> | null>(null);

  const handleDeleteUserFromOfficeModalAction = (
    office: Array<ListItemForDeletion> | null,
  ) => {
    setShowDeleteUserFromOfficeModal(office);
  };

  let userDetails: {
    name: string;
    phone: string;
    lastInvitationTime?: SessionHydrationUserFields_PendingUser_Fragment['lastInvitationTime'];
    dataTestid: string;
  };

  if (isPending(user)) {
    userDetails = {
      name: user?.email,
      phone: '---',
      lastInvitationTime: user?.lastInvitationTime,
      dataTestid: 'user-list-item-inactive',
    };
  } else {
    userDetails = {
      name: user?.name,
      phone: user?.phone,
      lastInvitationTime: undefined,
      dataTestid: 'user-list-item-active',
    };
  }

  const _isPending = isPending(user) || isPendingStatus(relation?.status);
  const { name, phone, lastInvitationTime, dataTestid } = userDetails;

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [resendUserInvitation] = useResendUserInvitationMutation({
    onCompleted: () => refetchSessionHydration(),
  });

  const [secondsLeft] = useCountdown(lastInvitationTime, 60);

  const buttonLabel = `${text.sendInvitationAgain} ${
    secondsLeft ? `(${secondsLeft})` : ''
  }`;

  useEffect(() => {
    setButtonDisabled(!isNil(secondsLeft));
  }, [secondsLeft]);

  return (
    <>
      <CardElement
        data-testid={dataTestid}
        isPending={_isPending}
        onClick={() => {
          if (userId === me.id) return navigate('/-/settings/profile');
          if (!_isPending) void navigate(`/-/settings/users/${userId}`);
          return;
        }}
        data-objectid={userId}
      >
        <UserNameContainer data-testid="user-name" {...viewingModeProps}>
          {_isPending && <EnvelopeIcon name="mail" title="Invited User" />}
          {name}
        </UserNameContainer>
        <UserPhoneElement data-testid="user-phone" {...viewingModeProps}>
          {phone}
        </UserPhoneElement>
        <UserEmailElement data-testid="user-email" {...viewingModeProps}>
          {user?.email}
        </UserEmailElement>

        <ButtonContainer>
          {lastInvitationTime && (
            <Button
              ghost
              disabled={buttonDisabled || !canEdit}
              onClick={() => {
                setButtonDisabled(true);
                return resendUserInvitation({
                  variables: {
                    userId,
                    accountId: account.id,
                  },
                });
              }}
              data-testid={TEST_ID.RESEND_INVITATION_BUTTON}
              label={buttonLabel}
            />
          )}

          {!lastInvitationTime && (
            <UserRoleElement title="Vestiging role">
              <Dropdown
                dataTestId="user-role"
                options={roleOptions}
                selectedOptionIdx={roleOptions.findIndex(
                  role => role.key === relation?.role,
                )}
                disabled={!canEdit || loading}
                onChange={({ option }: SelectedOptionOf<UserRole>) => {
                  if (!option) return;

                  const newRole = option.payload;
                  if (relation?.role === newRole) {
                    return;
                  } else {
                    if (typeof onRoleChange === 'function')
                      onRoleChange(userId, newRole);
                  }
                }}
              />
            </UserRoleElement>
          )}

          <OfficeCounter>
            <OfficeIcon name="office" />
            <span data-testid="user-officeNumber">{offices.length}</span>
          </OfficeCounter>

          <DeleteInputButton
            dataTestId={TEST_ID.DELETE_USER_BUTTON}
            onClick={() => {
              if (offices.length <= 1) {
                showErrorModal();
              } else {
                handleDeleteUserFromOfficeModalAction([
                  createOfficeItemForModal(
                    offices.map(convertViewableUserOfficeToListItemForDeletion),
                    officeId ? officeId : '',
                  ),
                ]);
              }
            }}
            disabled={!canEdit}
          />
        </ButtonContainer>
      </CardElement>

      {showDeleteUserFromOfficeModal && (
        <DeleteUserFromOfficeModal
          list={showDeleteUserFromOfficeModal}
          onCancel={() => handleDeleteUserFromOfficeModalAction(null)}
          onClose={() => handleDeleteUserFromOfficeModalAction(null)}
          userId={userId}
        />
      )}

      {errorModal}
    </>
  );
};

export default OfficeUserCard;
