import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import MasonryGrid from '~/components/MasonryGrid';
import SelectBlock from '~/components/SelectBlock';
import { Body } from '~/components/Typography/index';
import {
  OutputFieldList,
  WizardStepProps,
} from '~/components/Wizard/context/WizardContext';
import {
  WidgetSettingsAppConfigurationItem,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import useApp from '~/hooks/useApp';
import useWizardStep from '~/hooks/useWizardStep';
import ErrorScreen from '~/scenes/ErrorScreen';
import TEST_ID from './index.testid';
import { OutputMap } from '../..';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Loading from '~/components/Loading';
import getUWAppConfigurationItems from '~/util/getUWAppConfigurationItems';
import getUpdateForAppStatus from '../../AppStatusOverview/utils/getUpdateForAppStatus';
import { ExtendedAppConfig } from '~/hooks/useApps';
import { isEmpty } from 'ramda';
import TextButton from '~/components/TextButton';

export const id = 'GenericWidgetConfiguration';
export const title = 'Diensten toevoegen aan de widget';

export type OutputType = {
  type: typeof id;
  selectedApps: OutputFieldList<WidgetSettingsAppConfigurationItem>;
};

const text = {
  successMsg: 'Apps zijn ingeschakeld in de Widget',
  category: 'Ingeschakelde apps',
  pageDescription: (
    <>
      Laat de bezoeker van jouw website gemakkelijk offertes opvragen van
      dienstverleners die nodig zijn in de woonreis.
      <br />
      <br />
      Kies hier welke categorieën aan dienstverleners je beschikbaar wil maken
      in de widget. Dit kan je later aanpassen in de widget instellingen.
    </>
  ),
  selectAll: 'Alles selecteren',
  deselectAll: 'Alles deselecteren',
};

export const Component: React.FC<WizardStepProps> = props => {
  const { app, loading } = useApp(props.step.metadata.typename);

  if (loading) {
    return <Loading />;
  }

  if (!app) {
    return <ErrorScreen />;
  }

  return <Child {...props} app={app} />;
};

const Child: React.FC<WizardStepProps & { app: ExtendedAppConfig }> = ({
  step,
  outputMap,
  app,
}) => {
  const { id: accountId } = useCurrentAccount();
  const currentOutput = outputMap[id] as OutputType | undefined;

  const addToast = useAddToast();
  const [enabledApps, setEnabledApps] = useState<
    Array<WidgetSettingsAppConfigurationItem>
  >(
    currentOutput && 'selectedApps' in currentOutput
      ? currentOutput.selectedApps.value
      : [],
  );

  const [updateAppStatus] = useUpdateAppStatusMutation();
  const items = getUWAppConfigurationItems(app.appStatus);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  useEffect(() => {
    if (items.length === enabledApps.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [items, enabledApps]);

  const toggleSelection = () => {
    if (!allSelected) {
      setEnabledApps(items);
      setAllSelected(true);
    } else {
      setEnabledApps([]);
      setAllSelected(false);
    }
  };
  useEffect(() => {
    const initiallyEnabled = items.filter(item => item.visible === true);

    if (isEmpty(enabledApps)) {
      setEnabledApps(initiallyEnabled);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const updatedOutput = outputMap[id] as OutputType;
      const updatedItems = items.map(item => {
        const isEnabled = updatedOutput.selectedApps.value.some(
          app => app.id === item.id,
        );

        return {
          ...item,
          visible: isEnabled,
        };
      });

      const update = getUpdateForAppStatus({
        typename: step.metadata.typename,
        completed: false,
        currentAppStatus: app.appStatus,
        updatedItems,
      });

      const { data, errors } = await updateAppStatus({
        variables: {
          accountId,
          update: {
            [step.metadata.typename]: update,
          },
        },
      });

      if (data && !errors?.length) {
        addToast([formatToastMessage(text.successMsg, 'success')]);
      }

      const output: OutputType = {
        type: 'GenericWidgetConfiguration',
        selectedApps: {
          category: text.category,
          type: 'list',
          value: updatedItems
            .filter(item => item.visible === true)
            .map(item => ({
              ...item,
              label: item.name,
            })),
        },
      };
      return output;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, app, step.metadata.typename, updateAppStatus],
  );

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );

  useEffect(() => {
    const output: OutputType = {
      type: 'GenericWidgetConfiguration',
      selectedApps: {
        category: text.category,
        type: 'list',
        value: enabledApps.map(selectedApp => ({
          ...selectedApp,
          label: selectedApp.name,
        })),
      },
    };
    // Step ready to go from mount so free it up
    api.free(output);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledApps]);

  const [, api] = useWizardStep(step.id, stepOptions);

  return (
    <>
      <Body withoutMargin>{text.pageDescription}</Body>
      <TextButton
        icon={allSelected ? 'check-square' : 'square'}
        size="large"
        margin={['m', null, null, null]}
        withoutPadding
        onClick={toggleSelection}
        label={allSelected ? text.deselectAll : text.selectAll}
      />
      <Container>
        {items.map(item => {
          const isSelected = enabledApps.some(app => app.id === item.id);
          return (
            <SelectBlock
              key={item.id}
              type="checkbox"
              checked={isSelected}
              data-checked={isSelected}
              checkboxTitle={item.name}
              dataTestId={TEST_ID.TEMPLATE}
              data-objectid={item.id}
              onClick={() => {
                if (isSelected) {
                  setEnabledApps(
                    enabledApps.filter(
                      selectedApp => selectedApp.id !== item.id,
                    ),
                  );
                } else {
                  setEnabledApps([...enabledApps, item]);
                }
              }}
            />
          );
        })}
      </Container>
    </>
  );
};

const Container = styled(MasonryGrid)<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
    margin-bottom: ${theme.space('base')};
    column-gap: ${theme.space('l')};
  `}
`;

export default {
  id,
  title,
};
