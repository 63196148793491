import React from 'react';
import Illustration from '~/components/Illustration';
import { AvatarForTypes } from '../..';
import styled, { css } from 'styled-components';

import { assertNever } from '~/util/assertion';

type Props = {
  avatarFor: AvatarForTypes;
};

export const AvatarForComponent: React.FC<Props> = ({ avatarFor }) => {
  switch (avatarFor) {
    case 'officeProfile': {
      return (
        <OfficeIllustration
          name="office"
          className="profile-placeholder"
          strokeWidth={3}
          data-testid="profile-picture"
        />
      );
    }
    case 'userProfile': {
      return (
        <UserIllustration
          name="user"
          className="profile-placeholder"
          strokeWidth={3}
          data-testid="profile-picture"
        />
      );
    }
    default:
      return assertNever(avatarFor);
  }
};

const OfficeIllustration = styled(Illustration)<{}>(
  ({ theme }) => css`
    padding: ${theme.space('base')};
  `,
);

const UserIllustration = styled(Illustration)<{}>(
  ({ theme }) => css`
    padding: ${theme.space('xxs')};
  `,
);

export default AvatarForComponent;
