import React from 'react';
import styled from 'styled-components';

export type BankIconType =
  | 'ing'
  | 'abn'
  | 'volks'
  | 'nibc'
  | 'achmea'
  | 'vlk'
  | 'tridos'
  | 'rabo'
  | 'bunq'
  | 'hand'
  | 'knab'
  | 'regio'
  | 'sns';

export type Props = {
  name: BankIconType;
};

const BankIcon: React.FC<Props> = ({ name }) => (
  <Container>
    <svg>
      <use href={`/images/bank-icons.svg#${name}`} />
    </svg>
  </Container>
);

const Container = styled.div<{}>`
  display: inline-flex;
`;

export default BankIcon;
