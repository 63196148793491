import React from 'react';
import { RouteComponentProps } from '@reach/router';
import DHRouter from '~/components/DHRouter';

import UserList from './components/UserList';
import UserDetails from './UserDetails';
import withErrorBoundary from '~/ErrorBoundary';

type Props = RouteComponentProps;

const User: React.FC<Props> = () => (
  <DHRouter>
    <UserList path="/" />
    <UserDetails path="/:userId" />
  </DHRouter>
);

export default withErrorBoundary(User);
