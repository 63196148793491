import { SortFieldAdvanced } from '~/graphql/types';

/**
 * returns field name in camelCase
 * @param {SortFieldAdvanced} field - field to sort by
 * keywords: sortSettings, sortField, camelize
 */

const camelizeContactSortField = (field: string): string => {
  /**  it must handle the same cases as in getSortFieldName from src/scenes/Contacts/util/contactListColumns.tsx */
  switch (field) {
    case SortFieldAdvanced.Created:
      return 'createdDate';
    case SortFieldAdvanced.LastLeadActivity:
      return 'lastLeadActivity';
    case SortFieldAdvanced.LeadScore:
      return 'leadScore';
    default:
      return 'lastLeadActivity';
  }
};

export default camelizeContactSortField;
