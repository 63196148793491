import { FlowConditionAppFundaViewingRequestDetails } from './types.flow';

import { emptyFundaViewingRequestEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionAppFundaViewingRequestDetails = (): Omit<
  FlowConditionAppFundaViewingRequestDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_FUNDA_VIEWINGREQUEST_DETAILS
    .type,
  fields: [],
  event: emptyFundaViewingRequestEventParameterValue(),
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
});

export default emptyFlowConditionAppFundaViewingRequestDetails;
