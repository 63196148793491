import React from 'react';
import JustificationContainer from '~/components/JustificationContainer';
import { Body, Heading4, Variant } from '~/components/Typography/index';
import { AppStatus__typename } from '~/graphql/types.client';
import useApp from '~/hooks/useApp';
import { reporter } from '~/hooks/useErrorReporter';
import getPriceWithInterval from '~/util/getPriceWithInterval';

export type Props = {
  dataTestId?: string;
  typename: AppStatus__typename;
};

const text = {
  header: 'Kosten',
};

const AppTypeCosts: React.FC<Props> = ({ dataTestId, typename, ...rest }) => {
  const { app } = useApp(typename);
  if (!app) {
    reporter.captureException(
      new Error(`Unable to render price for app with typename: "${typename}"`),
    );
  }

  return (
    <JustificationContainer
      dataTestId={dataTestId}
      direction="column"
      {...rest}
    >
      <Heading4 variant={Variant.primary}>{text.header}</Heading4>
      <Body>{getPriceWithInterval(app?.price || null)}</Body>
    </JustificationContainer>
  );
};

export default AppTypeCosts;
