import { NumberParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';

export default (numberParameter: null | NumberParameterValue): boolean => {
  if (numberParameter == null) return false;

  switch (numberParameter.type) {
    case PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE:
      return numberParameter.value != null && numberParameter.value > 0;
    case PARAMETER_VALUE_TYPE.NUMBER_POINTER:
      // We cannot know if it is not 0 so always true
      return true;
    default:
      throw new Error(
        `Should not occur: Unknown param (${JSON.stringify(
          numberParameter,
          null,
          2,
        )}) for isNonZeroNumberParameterValue`,
      );
  }
};
