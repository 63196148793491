import { RouteComponentProps } from '@reach/router';
import React from 'react';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import OverviewListHeader from '~/components/OverviewListHeader';
import AccountsTable from './components/AccountsTable';

type Props = RouteComponentProps & { dataTestId?: string };

const AccountsList: React.FC<Props> = ({ dataTestId, ...rest }) => (
  <Container data-testid={dataTestId} {...rest}>
    <MetaTags>
      <title>Accounts</title>
    </MetaTags>

    <>
      <ContentContainerDefault>
        <OverviewListHeader title={'Accounts'} />
        <AccountsTable />
      </ContentContainerDefault>
    </>
  </Container>
);

const Container = styled.div<{}>``;

export default AccountsList;
