import React from 'react';

import MasterDetails from '~/components/ContentContainer/MasterDetails';
import Profile from './Profile';
import Account from './Account';
import Subscription from './Subscription';
import Invoices from './Invoices';
import Automation from './Automation';
import Offices from './Offices';
import Users from './Users';
import WidgetSettings from './Widget';
import type { RouteComponentProps } from '@reach/router';

import Catalog from '~/Catalog';
import { IconType } from '~/components/Icon';
import canSeeRoute, { PathViewRights } from '~/util/canSeeRoute';
import Wallet from './Wallet';
import useUserRights from '~/hooks/useUserRights';

const text = {
  subscriptionLabel: Catalog.subscriptionLabel,
  invoicesLabel: Catalog.invoicesLabel,
  automationLabel: Catalog.automationLabel,
  widgetLabel: Catalog.widgetLabel,
  walletLabel: Catalog.walletLabel,
};
const SettingsPage: React.FC<RouteComponentProps> = () => {
  const { view: mayViewInvoices } = useUserRights({ category: 'Invoices' });
  const { view: mayViewSubscription } = useUserRights({
    category: 'Subscription',
  });

  return (
    <MasterDetails
      routes={getSideMenuOptions({
        mayViewInvoices,
        mayViewSubscription,
      })}
    />
  );
};

const getSideMenuOptions = (auth: PathViewRights) =>
  allOptions.filter(({ link }) => canSeeRoute(auth, link));

const allOptions = [
  {
    path: '/-/settings/profile',
    link: '/-/settings/profile',
    label: {
      dataTestid: 'nav-link-settings-slide-profile',
      text: 'Mijn profiel',
      icon: 'user' as IconType,
    },
    detail: Profile,
  },
  {
    path: '/-/settings/offices/*',
    link: '/-/settings/offices',
    label: {
      dataTestid: 'nav-link-settings-slide-offices',
      text: 'Vestigingen',
      icon: 'office' as IconType,
    },
    detail: Offices,
  },
  {
    path: '/-/settings/users/*',
    link: '/-/settings/users',
    label: {
      dataTestid: 'nav-link-settings-slide-users',
      text: 'Gebruikers',
      icon: 'users' as IconType,
    },
    detail: Users,
  },
  {
    path: '/-/settings/account/*',
    link: '/-/settings/account',
    label: {
      dataTestid: 'nav-link-settings-slide-account',
      text: 'Account',
      icon: 'briefcase' as IconType,
    },
    detail: Account,
  },
  {
    path: '/-/settings/subscription/*',
    link: '/-/settings/subscription',
    label: {
      dataTestid: 'nav-link-settings-slide-subscription',
      text: text.subscriptionLabel,
      icon: 'file-text' as IconType,
    },
    detail: Subscription,
  },
  {
    path: '/-/settings/invoices/*',
    link: '/-/settings/invoices',
    label: {
      dataTestid: 'nav-link-settings-slide-invoices',
      text: text.invoicesLabel,
      icon: 'file' as IconType,
    },
    detail: Invoices,
  },
  {
    path: '/-/settings/wallet/*',
    link: '/-/settings/wallet',
    label: {
      dataTestid: 'nav-link-settings-slide-wallet',
      text: text.walletLabel,
      icon: 'credit-card' as IconType,
    },
    detail: Wallet,
  },
  {
    path: '/-/settings/automation/*',
    link: '/-/settings/automation',
    label: {
      dataTestid: 'nav-link-settings-slide-automation',
      text: text.automationLabel,
      icon: 'triangle' as IconType,
    },
    detail: Automation,
  },
  {
    path: '/-/settings/widget/*',
    link: '/-/settings/widget',
    label: {
      dataTestid: 'nav-link-settings-slide-widget',
      text: text.widgetLabel,
      icon: 'star' as IconType,
    },
    detail: WidgetSettings,
  },
];

export default SettingsPage;
