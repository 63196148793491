import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  App_Hypotheekbond_SettingsFragment,
  App_Hypotheekbond__Input,
  UpdateAppHypotheekbondMutation,
  useUpdateAppHypotheekbondMutation,
} from '~/graphql/types';

import InputGroup from '~/scenes/Apps/components/InputGroup';
import TEST_ID from './index.testid';
import Description from '~/scenes/Apps/components/WaardecheckTemplate/components/Description';
import AppDetailsContainer from '~/scenes/Apps/components/AppDetailsContainer';
import AppSectionHeader from '~/scenes/Apps/components/AppSectionHeader';
import ColorInput from '~/scenes/Apps/components/ColorInput';
import FooterSaveBar from '~/components/SaveBar/FooterSaveBar';
import { findDifference } from '~/util/object';
import { omit } from 'ramda';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Explanation from '~/scenes/Apps/components/WaardecheckTemplate/components/Explanation';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  settings: App_Hypotheekbond_SettingsFragment;
  updateQuery: (
    data: UpdateAppHypotheekbondMutation | null | undefined,
  ) => void;
};

type ColorProperty = Exclude<
  keyof App_Hypotheekbond_SettingsFragment,
  '__typename'
>;
export const colorProperties: Array<ColorProperty> = [
  'primaryColor',
  'primaryFontColor',
  'secondaryColor',
  'secondaryFontColor',

  'successColor',
  'failureColor',

  'backgroundColor',
  'buttonColor',
  'borderColor',
  'cardColor',
  'highlightColor',
];

const translationsMap: Record<ColorProperty, string> = {
  backgroundColor: 'Achtergrond kleur',
  borderColor: 'Border kleur',
  buttonColor: 'Knop kleur',
  cardColor: 'Kaart kleur',
  failureColor: 'Fout kleur',
  highlightColor: 'Uitlicht kleur',
  primaryColor: 'Primaire kleur',
  primaryFontColor: 'Primaire tekst kleur',
  secondaryColor: 'Secondaire kleur',
  secondaryFontColor: 'Secondaire tekst kleur',
  successColor: 'Succes kleur',
};

const Settings: React.FC<Props> = ({ settings, updateQuery }) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const [updatedSettings, setUpdatedSettings] =
    useState<App_Hypotheekbond_SettingsFragment>(settings);

  const [updateHypotheekbond, { loading }] =
    useUpdateAppHypotheekbondMutation();

  const updateValue = (key: ColorProperty, value: string) => {
    setUpdatedSettings(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const changeCount = findDifference(updatedSettings, settings).differenceCount;

  return (
    <>
      <AppDetailsContainer
        header="Algemeen"
        icon="gear"
        dataTestId={TEST_ID.CONTAINER}
      >
        <Explanation>
          Pas hier de kleuren van de app aan, alleen als deze moeten afwijken
          van de instellingen in de widget. Wil je voor alle apps de kleuren
          instellen? Ga dan naar de widget en wijzig deze daar.
          <br />
          <br />
          Tip: gebruik de hoofdkleuren van je website om een consistente
          huisstijl uit te stralen.
        </Explanation>
        <AppSectionHeader>Huisstijl</AppSectionHeader>
        <Description>
          Personaliseer de waardecheck met jouw huisstijlkleuren.
        </Description>

        <SettingsContainer>
          {colorProperties.map(property => (
            <InputGroup key={property}>
              <ColorInput
                value={updatedSettings[property] ?? '#fff'}
                onChange={value => {
                  updateValue(property, value);
                }}
                label={translationsMap[property]}
                dataTestId={`${TEST_ID.COLOR_INPUT}-${property}`}
              />
            </InputGroup>
          ))}
        </SettingsContainer>
      </AppDetailsContainer>
      {changeCount !== 0 && (
        <FooterSaveBar
          disableSave={false}
          loading={loading}
          onSave={async () => {
            const inputFormatted = transformToInput(updatedSettings);
            await updateHypotheekbond({
              variables: {
                accountId,
                update: inputFormatted,
              },
            })
              .then(({ data }) => updateQuery(data))
              .catch(() =>
                addToast([
                  formatToastMessage(
                    'Er is iets mis gegaan bij het opslaan, probeer het later nog eens',
                    'danger',
                  ),
                ]),
              );
          }}
          onCancel={() => {
            setUpdatedSettings(settings);
          }}
          numberOfChanges={changeCount}
        />
      )}
    </>
  );
};

const transformToInput = (
  settings: App_Hypotheekbond_SettingsFragment,
): App_Hypotheekbond__Input => ({
  settings: omit(['__typename'], settings),
});

const SettingsContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${theme.space('base')};
  `,
);

export default Settings;
