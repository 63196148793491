import { InlineImageEditorItem } from '~/components/HTMLEditor/HTMLEditor';

import { DH_UPLOADING_IMAGE_CLASS } from '~/theme/GlobalStyle';
import {
  IMG_REGEX,
  INLINE_IMAGE_CONTENT_TYPE_ATTR,
} from '~/components/HTMLEditor/constants';
import { getAttributeValue } from '~/components/HTMLEditor/util/variableHTML';

const CLASS_REGEX = /class="[^"]*?"/g;
const SRC_REGEX = /src="[^"]*?"/g;
type ReturnType = {
  html: string;
  hasChanged: boolean;
};
const updateLoadingClasses = (
  html: string,
  dhInlineImages: Array<InlineImageEditorItem>,
): ReturnType => {
  if (dhInlineImages.length === 0) return { html, hasChanged: false };

  let hasChanged = false;

  const convertedHtml = html.replace(IMG_REGEX, matchedValue => {
    const uploadid = getAttributeValue('data-uploadid', matchedValue);

    const inlineImage = dhInlineImages.find(image => image.id === uploadid);
    if (inlineImage) {
      const classes = getAttributeValue('class', matchedValue);
      // if we need to add the uploading class
      let classNeeded = false;
      let withContentType = matchedValue;
      const { fileDetails } = inlineImage;

      if (fileDetails == null) {
        classNeeded = true;
      } else {
        if (
          getAttributeValue(INLINE_IMAGE_CONTENT_TYPE_ATTR, withContentType) ==
          null
        ) {
          withContentType = matchedValue.replace(
            SRC_REGEX,
            matchedSrc =>
              `${matchedSrc} ${INLINE_IMAGE_CONTENT_TYPE_ATTR}="${fileDetails.contentType}"`,
          );
        }
      }

      if (classes == null) {
        if (classNeeded) {
          hasChanged = true;
          // if we do not have any class attribute but need it, then add it
          return withContentType.replace(
            SRC_REGEX,
            val => `${val} class="${DH_UPLOADING_IMAGE_CLASS}"`,
          );
        }
      } else {
        if (classes.includes(DH_UPLOADING_IMAGE_CLASS)) {
          if (!classNeeded) {
            hasChanged = true;
            // if it is there, but not needed anymore remove it
            return withContentType.replace(DH_UPLOADING_IMAGE_CLASS, '');
          }
        } else {
          if (classNeeded) {
            hasChanged = true;
            // if it is not there but needed and class attribute exists then add it
            return withContentType.replace(
              CLASS_REGEX,
              val =>
                `${val.substring(
                  0,
                  val.length - 1,
                )} ${DH_UPLOADING_IMAGE_CLASS}"`,
            );
          }
        }
      }
    }

    return matchedValue;
  });

  return {
    html: convertedHtml,
    hasChanged,
  };
};

export default updateLoadingClasses;
