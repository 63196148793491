// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-setup-newpassword-tsx": () => import("./../../../src/pages/setup-newpassword.tsx" /* webpackChunkName: "component---src-pages-setup-newpassword-tsx" */),
  "component---src-pages-setup-user-details-tsx": () => import("./../../../src/pages/setup-user-details.tsx" /* webpackChunkName: "component---src-pages-setup-user-details-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/-.tsx" /* webpackChunkName: "component---src-pages-tsx" */),
  "component---src-pages-verify-tsx": () => import("./../../../src/pages/verify.tsx" /* webpackChunkName: "component---src-pages-verify-tsx" */),
  "component---src-templates-redirects-ts": () => import("./../../../src/templates/Redirects.ts" /* webpackChunkName: "component---src-templates-redirects-ts" */)
}

