import { TemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';

import {
  getVariableAndFieldLabel,
  UNKNOWN_FIELD_NAME,
} from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { PARAMETER_PRIMITIVE_VALUE_TYPE } from '../../constants';

const isValidTemplateString = (
  templateString: TemplateStringParameterValue,
  stash: FlowVariableStash,
): boolean => {
  const { mappings } = templateString;

  let isValid = true;

  mappings.forEach(mappingItem => {
    const { mapping } = mappingItem;
    //@TODO WE PROBABLY WANT TO ALLOW PRIMITIVE MAPPINGS TOO IN THE FUTURE.
    switch (mapping.type) {
      case PARAMETER_PRIMITIVE_VALUE_TYPE.BOOLEAN_PRIMITIVE:
      case PARAMETER_PRIMITIVE_VALUE_TYPE.NUMBER_PRIMITIVE:
      case PARAMETER_PRIMITIVE_VALUE_TYPE.STRING_PRIMITIVE:
        isValid = false;
        return;
    }

    const { variable } = mapping;

    if (
      variable == null ||
      variable.name == null ||
      variable.field == null ||
      variable.field.name == null
    ) {
      isValid = false;
    } else {
      // Check if the variable is not unknown
      const label = getVariableAndFieldLabel(
        stash,
        variable.name,
        variable.field.name,
      );

      if (label === UNKNOWN_FIELD_NAME) {
        isValid = false;
      }
    }
  });

  return isValid;
};

export default isValidTemplateString;
