import React from 'react';
import Icon, { IconType } from '../Icon';
import styled, { css, keyframes } from 'styled-components';

import { SystemSize } from '~/theme/System/tokens';
import { isNil } from 'ramda';
import arrayToCss from '~/util/arrayToCss';
import { buttonSizes, ButtonSize } from '~/styles/constants';
import { ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Direction = 'ltr' | 'rtl';
export type Appearance =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'danger'
  | 'neutral';

export type Props = {
  dataTestId?: string;

  size?: ButtonSize;
  appearance?: Appearance;
  disabled?: boolean;
  /* Replaces the icon for our loading icon and forces the disabled state as well */
  loading?: boolean;
  icon?: IconType;
  label?: React.ReactNode;

  /* Outlined only version */
  ghost?: boolean;

  /* always show the active style */
  active?: boolean;

  /* Changes the order of the icon and the label if both provided */
  direction?: 'ltr' | 'rtl';
  type?: 'button' | 'submit';
  pulse?: boolean;
  margin?: Array<SystemSize | null>;
} & React.HtmlHTMLAttributes<HTMLButtonElement>;

const Button = React.forwardRef<Props, any>(
  (
    {
      ghost = false,
      size = 'small',
      disabled = false,
      appearance = 'primary',
      label,
      dataTestId,
      loading = false,
      direction = 'ltr',
      icon,
      type = 'button',
      pulse = false,
      margin = [null, null, null, null],
      active = false,
      ...rest
    },
    ref,
  ) => {
    const ButtonImpl = ghost ? GhostButton : DefaultButton;
    const localIcon = loading ? 'spinner' : icon;
    const isDisabled = disabled || loading;

    const props = {
      ghost,
      disabled: isDisabled,
      'data-testid': dataTestId,
      $size: size,
      $appearance: appearance,
      $direction: direction,
      $shouldPulse: pulse,
      label,
      ...rest,
    };

    const onlyIconButton = !isNil(icon) && isNil(label);

    return (
      <ButtonImpl
        type={type}
        $margin={margin}
        $active={active}
        ref={ref}
        onlyIconButton={onlyIconButton}
        {...props}
      >
        {localIcon && (
          <IconContainer $direction={direction} $addMargin={!isNil(label)}>
            <Icon name={localIcon} strokeWidth={2.5} />
          </IconContainer>
        )}
        <Label>{label}</Label>
      </ButtonImpl>
    );
  },
);

const Label = styled.span<{}>`
  height: 1em;
  width: max-content;
`;

const buttonAppearance: {
  [key in Appearance]: ThemeColor;
} = {
  primary: {
    group: 'primary',
    variant: 'light',
  },
  secondary: {
    group: 'success',
    variant: 'base',
  },
  tertiary: {
    group: 'grey',
    variant: 'base',
  },
  accent: {
    group: 'accent',
    variant: 'base',
  },
  danger: {
    group: 'danger',
    variant: 'light',
  },
  neutral: {
    group: 'text',
    variant: 'light',
  },
};

const IconContainer = styled.div<{
  $direction: Direction;
  $addMargin: boolean;
}>(
  ({ $direction, $addMargin, theme }) => css`
    /* Scale the icon to the font-size of the button */
    font-size: 1em;
    margin: ${$addMargin &&
    ($direction === 'ltr'
      ? [0, theme.space('xxs'), 0, 0]
      : [0, 0, 0, theme.space('xxs')]
    ).join(' ')};
    display: flex;
    /** Center the icon */
    & svg {
      transform: translateY(-1px);
    }
  `,
);

export const outline = color => css`
  &:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px ${color};
    outline: 2px solid transparent;
  }
`;

const pulse = keyframes`
from {
  transform: scale(1.5, 2);
}

to {
  transform: scale(1, 1);
}
`;

type BaseButtonProps = {
  $size: ButtonSize;
  $direction: Direction;
  $appearance: Appearance;
  $shouldPulse: boolean;
  $margin: Array<SystemSize | null>;
  $active: boolean;
  onlyIconButton?: boolean;
};

const ButtonBase = styled.button<BaseButtonProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;

  ${({
    theme,
    $direction,
    disabled,
    $size,
    $appearance,
    $shouldPulse,
    $margin,
    onlyIconButton,
  }) => {
    const { group, variant } = buttonAppearance[$appearance];
    const isNeutral = $appearance === 'neutral';
    return css`
      flex-direction: ${$direction === 'rtl' ? 'row-reverse' : 'row'};
      border-radius: ${theme.getTokens().border.radius.s};
      font-weight: ${isNeutral ? theme.fw('regular') : theme.fw('semiBold')};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};

      /* Only add margin to buttons of there is margin provided */
      ${$margin.filter(m => !isNil(m)).length !== 0
        ? `margin: ${arrayToCss($margin, theme)};`
        : ''}

      font-size: ${theme.fontSize(buttonSizes[$size].fontSize)};
      padding: ${!onlyIconButton
        ? arrayToCss(buttonSizes[$size].padding, theme)
        : theme.space(buttonSizes[$size].padding[0])};

      ${$shouldPulse &&
      css`
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: ${theme.color(group, 'translucent')};
          border-radius: ${theme.getTokens().border.radius.s};
          transform: scale(1.5, 2);
          animation: ${pulse} 0.7s ease-in alternate infinite;
        }
      `}

      ${!isNeutral && outline(theme.color(group, variant))};
    `;
  }}
`;

const GhostButton = styled(ButtonBase)<BaseButtonProps>`
  background: none;
  ${({ theme, $appearance, $margin, $active }) => {
    const { group, variant } = buttonAppearance[$appearance];
    const color = theme.color(group, variant);
    const grey = theme.color('grey');

    return css`
      border: ${theme.getTokens().border.width.s} solid ${color};
      margin: ${arrayToCss($margin, theme)};

      color: ${$active ? theme.color('white') : color};
      background-color: ${$active ? color : 'transparent'};

      &:hover,
      &:active,
      &:focus {
        &:not([disabled]) {
          background: ${color};
          color: ${theme.color('white')};
        }
      }

      &:disabled {
        border: ${theme.getTokens().border.width.s} solid ${grey};
        color: ${grey};
      }
    `;
  }};
`;

const DefaultButton = styled(ButtonBase)`
  border: none;
  ${({ theme, $appearance, disabled }) => {
    const { group, variant } = buttonAppearance[$appearance];
    const color = theme.color(group, variant);
    const isNeutral = $appearance === 'neutral';
    return css`
      color: ${isNeutral ? theme.color(group, variant) : theme.color('white')};
      background: ${isNeutral ? 'none' : color};
      /* We need to add the border to default button so they can appear next to a ghost button */
      border: ${theme.getTokens().border.width.s} solid
        ${isNeutral ? 'transparent' : color};
      &:hover,
      &:active {
        background: ${isNeutral ? 'none' : theme.color(group, 'dark')};

        ${isNeutral &&
        !disabled &&
        css`
          color: ${theme.color(group, 'dark')};
        `}
      }

      &:disabled {
        ${!isNeutral &&
        css`
          background: ${theme.color('grey')};
          border: ${theme.getTokens().border.width.s} solid
            ${theme.color('grey')};
        `}
      }
    `;
  }}
`;

/**
 * Without alignment
 */
export const UnalignedButton = styled(Button)<{}>`
  align-self: inherit;
`;

export default Button;
