import { groupBy, sort, uniq } from 'ramda';

import { ActivityFieldsFragment } from '~/graphql/types';
import { formattedDateWithFullMonth } from '~/util/date';
import { GroupedActivities } from '~/state/activities';
import generateActivityV2 from '../generateActivityV2';
import { ActivityV2 } from '../../types';

const sortByCreatedDate = (a: string, b: string) =>
  new Date(b).getTime() - new Date(a).getTime();

const groupActivities = (
  events: Array<ActivityFieldsFragment>,
): GroupedActivities => {
  const v2Activities = events.map(generateActivityV2);

  // Sort the dates separately so that when there is a new action from the
  // subscription we make sure that new date is sorted as well
  const dates: Array<string> = v2Activities.map(
    activity => activity.sortingDate,
  );
  const sortedDates = uniq(
    dates
      .sort(sortByCreatedDate)
      .map(date => formattedDateWithFullMonth(new Date(date))),
  );

  const groupedActivities = groupBy(
    activity => formattedDateWithFullMonth(new Date(activity.sortingDate)),
    v2Activities,
  );

  return sortedDates.reduce((acc, curr) => {
    acc[curr] = sort(
      (a, b) => sortByCreatedDate(a.sortingDate, b.sortingDate),
      groupedActivities[curr],
    );

    return acc;
  }, {} as Record<string, Array<ActivityV2>>);
};

export default groupActivities;
