import { BillingCycle, Plan } from '~/graphql/types';
import { Option } from '~/components/Inputs/Dropdown/';

export enum SUBSCRIPTION_TYPE {
  STRIPE = 'STRIPE',
  EXTERNAL = 'EXTERNAL',
}

export const SUBSCRIPTION_PLANS: Array<Plan> = [
  Plan.Starter,
  Plan.Pro,
  Plan.Premium,
];

export enum COUPON_TYPE {
  FIXED_AMOUNT_COUPON = 'FIXED_AMOUNT_COUPON',
  PERCENT_AMOUNT_COUPON = 'PERCENT_AMOUNT_COUPON',
}

export const BILLING_CYCLES: Array<BillingCycle> = [
  BillingCycle.Monthly,
  BillingCycle.Yearly,
];

export type PricingInfoBlock = {
  title: string;
  pricePerMonth: number;
  pricePerYear: number;
  highlights: Array<string>;
  description: string;
  numberOfOfficesAllowed: number;
  numberOfUsersAllowed: number;
  numberOfContactsAllowed: number;
};

export const PRICING_INFO = Object.freeze({
  Starter: {
    title: 'STARTER',
    pricePerMonth: 12900,
    pricePerYear: 9900,
    highlights: [
      '3 gebruikers',
      '1 vestiging',
      '2.000 contacten',
      '5 e-mail koppelingen',
    ],
    description: '1 vestiging, 3 gebruikers, 2.000 contacten',
    numberOfOfficesAllowed: 1,
    numberOfUsersAllowed: 3,
    numberOfContactsAllowed: 2000,
  },
  Pro: {
    title: 'PRO',
    pricePerMonth: 25900,
    pricePerYear: 19900,
    highlights: [
      '9 gebruikers',
      '6 vestigingen',
      '25.000 contacten',
      '13 e-mail koppelingen',
    ],
    description: '6 vestigingen, 9 gebruikers, 25.000 contacten',
    numberOfOfficesAllowed: 6,
    numberOfUsersAllowed: 9,
    numberOfContactsAllowed: 25000,
  },
  Premium: {
    title: 'PREMIUM',
    pricePerMonth: 129900,
    pricePerYear: 109900,
    highlights: [
      '25 gebruikers',
      '12 vestigingen',
      '100.000 contacten',
      '35 e-mail koppelingen',
    ],
    description: '12 vestiging, 25 gebruikers, 100.000 contacten',
    numberOfOfficesAllowed: 12,
    numberOfUsersAllowed: 25,
    numberOfContactsAllowed: 100000,
  },
});

export enum OVERAGE_ITEM {
  BASIC = 'BASIC_OVERAGE_ITEM',
  PRICE_PER = 'PRICE_PER_OVERAGE_ITEM',
  GROUPED = 'GROUPED_OVERAGE_ITEM',
}

export const planOptions = [
  { value: Plan.Starter, label: 'Starter' },
  { value: Plan.Pro, label: 'Pro' },
  { value: Plan.Premium, label: 'Premium' },
];

export const billingCycleOptions = [
  { value: BillingCycle.Monthly, label: 'Maandelijks' },
  { value: BillingCycle.Yearly, label: 'Jaarlijks' },
];

export const PAYMENT_OPTIONS: Array<Option> = [
  {
    label: 'SEPA automatische incasso',
    payload: 'SEPA',
    key: 'SEPA',
  },
];
