import React from 'react';
import styled, { css } from 'styled-components';

import {
  calculateSpaceInsideComponent,
  calculateFontSize,
} from '~/components/util/getSizeCalculation';
import TEST_ID from './TagField.testid';
import Icon from '~/components/Icon';

type State = {};
type Props = {
  /** Renders a small component */
  small?: boolean;

  /** Renders a large component */
  large?: boolean;

  /** Function for deleting emails form selected list */
  onDelete: (value: string) => void;

  /** Current email value */
  value: string;

  /** If the validation should be colorized */
  useValidation?: boolean;

  /** Show is email valid */
  isValid: boolean;

  /** If given, it will render this instead of the value */
  renderValue?: React.ReactNode;
};
class TagField extends React.Component<Props, State> {
  onWrapperClick = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  render() {
    const {
      value,
      onDelete,
      isValid,
      large,
      small,
      useValidation,
      renderValue,
      ...rest
    } = this.props;

    return (
      <SelectedFieldElement
        {...rest}
        data-objectid={value}
        onClick={this.onWrapperClick}
        large={large}
        small={small}
        isValid={isValid}
        useValidation={useValidation}
      >
        <Label large={large} small={small} data-testid={TEST_ID.LABEL}>
          {renderValue || value}
        </Label>
        <RemoveButton
          data-testid={TEST_ID.DELETE_BUTTON}
          large={large}
          small={small}
          onClick={(e: React.SyntheticEvent<any>) => {
            this.onWrapperClick(e);
            onDelete(value);
          }}
        >
          <Icon name="close" />
        </RemoveButton>
      </SelectedFieldElement>
    );
  }
}

type SelectedFieldType = Omit<Props, 'onDelete' | 'value'> & {
  isValid?: boolean;
  useValidation?: boolean;
};
const BORDER_SIZE = 1;
const SelectedFieldElement = styled.div<SelectedFieldType>`
  display: flex;
  box-sizing: border-box;
  min-width: max-content;
  ${({ theme, isValid, large, small, useValidation }) => {
    let color = theme.color('primary', 'light');

    if (useValidation !== false) {
      color = isValid ? theme.color('success') : theme.color('danger');
    }

    return css`
      border-radius: ${theme.getTokens().border.radius.s};
      background-color: ${color};
      color: ${theme.color('white')};
      margin: ${theme.space('xxxs')};
      font-size: ${calculateFontSize(theme, small, large)}px;

      ${useValidation === false
        ? `margin-bottom: ${
            calculateSpaceInsideComponent(theme, small, large) / 2
          }px;`
        : ''}
    `;
  }};
`;

const Label = styled.div<Omit<SelectedFieldType, 'isValid'>>`
  ${({ theme, large, small }) => {
    const spaceInsideComponent = calculateSpaceInsideComponent(
      theme,
      small,
      large,
    );

    return css`
      flex-basis: 100%;
      padding: calc((${spaceInsideComponent}px - ${BORDER_SIZE}px) / 2);
    `;
  }};
`;

const RemoveButton = styled.div<Omit<SelectedFieldType, 'isValid'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ theme, large, small }) => {
    const padding = calculateSpaceInsideComponent(theme, small, large);
    const fontSize = calculateFontSize(theme, small, large);

    return css`
      width: calc(${padding}px + ${fontSize}px);
      min-width: calc(${padding}px + ${fontSize}px);
      border-left: 1px solid ${theme.color('white')};
      svg {
        font-size: ${fontSize}px;
      }
    `;
  }};
`;

export default TagField;
