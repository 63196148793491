import { SpanElement } from '~/components/PluginsEditor/types';
import updateAttrsAndConvertToString from '~/components/PluginsEditor/utils/serialize/updateAttrsAndConvertToString';

/**
 * serializes the OrderedListElement into html element
 * @param {SpanElement} node - SpanElement
 */
const serialize = (node: SpanElement, children: string): string => {
  const attrsAsString = updateAttrsAndConvertToString(
    {
      style: {
        display: node.attributes?.style?.display || 'inline',
        ...(node.color ? { ['color']: node.color } : {}),
        ...(node.backgroundColor
          ? { ['background-color']: node.backgroundColor }
          : {}),
        ...(node.fontSize ? { ['font-size']: node.fontSize } : {}),
        ...(node.fontFamily ? { ['font-family']: node.fontFamily } : {}),
      },
    },
    node?.attrsToPassThrough,
  );

  return `<span${attrsAsString ? ` ${attrsAsString}` : ''}>${children}</span>`;
};

export default serialize;
