import React from 'react';
import styled, { keyframes, css } from 'styled-components';

type Props = {
  isLoading: boolean;
  height?: number;
  maxWidth?: number;
};
const ProgressBar = ({ isLoading, height = 8, maxWidth = 90 }: Props) => (
  <Outer isLoading={isLoading} height={height} maxWidth={maxWidth}>
    <Inner isLoading={isLoading} height={height} maxWidth={maxWidth} />
  </Outer>
);

type OuterProps = {
  isLoading: boolean;
  height: number;
  maxWidth: number;
};
const Outer = styled.div<OuterProps>`
  overflow: hidden;
  align-self: center;

  ${({ theme, height, maxWidth }) => css`
    height: ${height}px;
    width: ${maxWidth}px;
    background-color: ${theme.color('primary', 'light')};
    border-radius: ${theme.getTokens().border.radius.s};
  `};
`;

type InnerProps = {
  isLoading: boolean;
  maxWidth: number;
  height: number;
};
const Inner = styled.div<InnerProps>`
  ${({ theme, maxWidth, height }) => css`
    margin-left: -${maxWidth}px;
    height: ${height}px;
    width: ${maxWidth}px;
    background-color: ${theme.color('grey')};
  `};

  ${({ maxWidth, isLoading }) => {
    if (isLoading) {
      return css`
        animation: ${animation(maxWidth)} 2s ease-in-out infinite;
      `;
    }
    return null;
  }}
`;

const animation = width => keyframes`
  0% {
    margin-left: -${width}px;
  }
  100% {
    margin-left: ${width}px;
  }
`;

export default ProgressBar;
