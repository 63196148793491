import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import JustificationContainer from '~/components/JustificationContainer';
import Tooltip from '~/components/Tooltip';
import { Heading4, Variant } from '~/components/Typography/index';
import useHover from '~/components/util/useHover';

export type Props = {
  dataTestId?: string;
  label: string;
  tooltipMessage: string;
};

const withTooltip = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  //To show nice name in React Dev Tools
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithTooltip: React.FC<Props & P> = ({
    dataTestId,
    label,
    tooltipMessage,
    ...rest
  }) => {
    const [showTooltip, tooltipProps] = useHover();
    return (
      <Container {...rest}>
        <JustificationContainer justification="start" nonResponsive>
          <Heading4 variant={Variant.primary}>{label}</Heading4>
          <HelpIcon name="question-mark" {...tooltipProps} strokeWidth={2.5} />

          {showTooltip && <Tooltip>{tooltipMessage}</Tooltip>}
        </JustificationContainer>
        <WrappedComponent dataTestId={dataTestId} {...(rest as P)} />
      </Container>
    );
  };

  ComponentWithTooltip.displayName = `withTooltip(${displayName})`;
  return ComponentWithTooltip;
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('l')};
  `,
);

const HelpIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('primary')};
    margin-left: ${theme.space('xxs')};
  `}
`;

export default withTooltip;
