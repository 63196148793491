import {
  Field,
  TemplateStringField,
  TemplateStringParameterField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { emptyTemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

export default (
  field: Field | TemplateStringField,
): TemplateStringParameterField => ({
  ...field,
  type: FIELD_TYPE.TEMPLATE_STRING,
  value: emptyTemplateStringParameterValue(),
});
