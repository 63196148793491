/* eslint-disable no-console */
import { DH_UPLOADING_IMAGE_CLASS } from '~/theme/GlobalStyle';
import { reportErrorToTracker } from '~/util/assertion';
import { IMAGE_ALLOWED_TYPES } from './constants';
import { getImageTypeFromFile } from './util/imageHelpers';
import uploadImageToStorage from './util/uploadImageToStorage';

const emailEventHandlers = () => ({
  // https://www.froala.com/wysiwyg-editor/docs/events#image.beforeUpload
  // @ts-ignore
  'image.beforeUpload': function (this: any, images) {
    if (images.length > 0) {
      const image = images[0];

      const imageType = getImageTypeFromFile(image);
      if (
        !image ||
        images.length > 1 ||
        imageType == null ||
        !IMAGE_ALLOWED_TYPES.includes(imageType)
      ) {
        // stop uploading!
        this.popups.hideAll();

        this.showError(
          'Het bestand lijkt geen afbeelding te zijn. Controleer het bestandstype en probeer opnieuw.',
        );

        return false;
      }

      const id = this.dhRegisterNewInlineImage();

      return uploadImageToStorage(image, this.dhValues.userId)
        .then(({ url, s3key, filename, contentType, contentLength }) =>
          this.dhOnUploadSucceeded(id, {
            url,
            s3key,
            filename,
            contentType,
            contentLength,
          }),
        )
        .catch(err => console.error(err));
    }
  },

  // @ts-ignore
  'image.error': function (this: any, error) {
    reportErrorToTracker(error);
    this.markLastUploadAsFailed();
  },

  /**
   * Don't need to use this, this gets an img tag with a base64 image.
   * We always go to the beforeUpload function afterwards so all logic in there.
   */

  // 'image.beforePasteUpload': function(images) {
  //   console.log('image.beforePasteUpload', images);
  // },

  /**
   * Does not get called, this is when it is uploaded via the froala uploader to a server
   * But we cannot use that.
   */

  // 'image.uploaded': function(img) {
  //   console.log('image.uploaded', img[0].currentSrc);
  // },
  // @ts-ignore
  'image.inserted': function (this: any, img) {
    if (img.length !== 1) {
      throw Error(
        `>>emailEventHandlers - image.loaded - img.length (${img.length}) does not equal 1...`,
      );
    }
    if (img[0].getAttribute('dhvariable') === 'true') {
      // do not update inline image list if it is a variable
      return;
    }

    const id = this.getUploadingId();
    img[0].setAttribute('data-uploadid', id);
    // add the uploading classname!
    img[0].setAttribute(
      'class',
      (img[0].className || '') + ' ' + DH_UPLOADING_IMAGE_CLASS,
    );
  },

  /**
   * Happens immediately on copy paste, safer to overwrite image.inserted as that happens when the image
   * is actually inserted into the dom
   */

  // 'image.loaded': function(img) {
  //   console.log('image.loaded', img);
  //   return false;
  // },
});

export default emailEventHandlers;
