import React from 'react';
import styled, { css } from 'styled-components';
import { CARD_MIN_COLUMN_WIDTH } from '~/components/EventTimelineV2/components/BaseEventCard';
import { GridLayout } from '~/graphql/types';
import GridContainer from '~/components/GridContainer';

export type Props = Omit<GridLayout, '__typename'> & {
  dataTestId?: string;
};

const Grid: React.FC<Props> = ({
  dataTestId,
  children,
  css,
  margin,
  padding,
  gap,
  ...rest
}) => (
  <Container
    data-testid={dataTestId}
    margin={margin ?? undefined}
    padding={padding ?? undefined}
    gap={gap ?? undefined}
    css={css || ''}
    {...rest}
  >
    {children}
  </Container>
);

/**
 * CARD_MIN_COLUMN_WIDTH = 300px fits 2 columns in our existing cards.
 * If card width changes we might need to adjust this.
 */
const Container = styled(GridContainer)<{}>(
  () => css`
    grid-template-columns: repeat(
      auto-fit,
      minmax(${CARD_MIN_COLUMN_WIDTH}px, 1fr)
    );
  `,
);

export default Grid;
