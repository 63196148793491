/**
 * A hook to . It will give the user:
 * [0] - The ref to give the the component that should be repositioned
 * [1] - { x, y }  <- the amount the component needs to be translated
 * [2] - Function to recalculate the position
 *
 * It expects:
 *   - elementToPlaceUnder - If you want to place it under something specific
 */

import { useState, useRef, useCallback } from 'react';

type ReturnProps<T extends HTMLElement> = [
  { current: null | T },
  { x: number; y: number },
  () => void,
];

type ElementToPlaceUnderType = {
  element: HTMLElement | null;
  placement: 'bottom-left' | 'bottom-right';
};

/**
  - No footer / Footer is not visible at that moment / Footer is visible
   In any case we don't position any dropdown option over that area by default.
   You can adjust footerHeightFactor to your need.
  */
const DEFAULT_FOOTER_HEIGHT_FACTOR = 0.07;

const useRepositionToBeInView = <T extends HTMLElement>(
  elementToPlaceUnder?: ElementToPlaceUnderType,
  footerHeightFactor: number = DEFAULT_FOOTER_HEIGHT_FACTOR,
): ReturnProps<T> => {
  const [transform, setTransform] = useState({
    x: 0,
    y: 0,
  });
  const componentRef = useRef<T | null>(null);

  const recalculate = useCallback(() => {
    const { current } = componentRef;

    if (current != null && current.getBoundingClientRect) {
      const {
        bottom: currentBottom,
        right: currentRight,
        left: currentLeft,
        top: currentTop,
      } = current.getBoundingClientRect();

      // Calculate the position we want it
      let elementXTransform = 0;
      let elementYTransform = 0;
      if (elementToPlaceUnder != null && elementToPlaceUnder.element != null) {
        const {
          left: elementLeft,
          right: elementRight,
          bottom: elementBottom,
        } = elementToPlaceUnder.element.getBoundingClientRect();

        elementYTransform = elementBottom - currentTop;
        if (elementToPlaceUnder.placement === 'bottom-left') {
          elementXTransform = elementLeft - currentLeft;
        } else {
          elementXTransform = elementRight - currentRight;
        }
      }

      const elementTransform = {
        x: elementXTransform,
        y: elementYTransform,
      };

      // Calculate the bottom right corner the dropdown will end up at after element transform
      const endPosition = {
        right: currentRight + elementTransform.x,
        bottom: currentBottom * (1 + footerHeightFactor) + elementTransform.y,
      };

      // Check if we have to move it into view
      const { clientWidth, clientHeight } = document.documentElement || {};
      const windowWidth = global.window.innerWidth || clientWidth;
      const windowHeight = global.window.innerHeight || clientHeight;
      let { x, y } = transform;

      const overflowBottom = endPosition.bottom - windowHeight;
      const overflowsInTheBottom = overflowBottom > 0;

      const overflowRight = endPosition.right - windowWidth;
      const overflowsOnTheRight = overflowRight > 0;

      if (overflowsInTheBottom) {
        // extra number to ensure a little space from end of screen
        y -= overflowBottom + 16;
      }

      if (overflowsOnTheRight) {
        // extra number to ensure a little space from end of screen
        x -= overflowRight + 16;
      }

      const newX = x + elementTransform.x;
      const newY = y + elementTransform.y;

      if (newX != transform.x || newY != transform.y) {
        setTransform({
          x: newX,
          y: newY,
        });
      }
    }
  }, [elementToPlaceUnder, footerHeightFactor, transform]);

  return [componentRef, transform, recalculate];
};

export default useRepositionToBeInView;
