import React from 'react';
import { Props } from '..';

const WOZ: React.FC<Props> = ({
  secondaryColor: _secondaryColor,
  ...props
}) => (
  <svg
    x={0}
    y={0}
    width="100%"
    height="100%"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.2 25.2998V21.6998M39.8 14.3998L55.6 19.2998M16.4 7.2998L31.9 11.9998M16.4 7.2998L13.8 16.6998M16.4 7.2998L18.7 15.4998M55.7 19.4998L53.3 28.1998M55.7 19.4998L58.2 28.4998M22.5 39.3998V30.3998H25.2C25.5 30.3998 25.6 30.0998 25.4 29.7998L17 20.2998C16.7 19.9998 16.2 19.9998 16 20.2998L7.4 29.8998C7.2 30.0998 7.3 30.3998 7.5 30.3998H10.2V39.3998H22.5ZM26.3 43.3998C26.3 46.4998 21.9 48.9998 16.4 48.9998C10.9 48.9998 6.5 46.4998 6.5 43.3998H26.3V43.3998ZM65.6 55.3998C65.6 58.4998 61.2 60.9998 55.7 60.9998C50.2 60.9998 45.8 58.4998 45.8 55.3998H65.6Z"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.5 51.4H48.8V32H56.5L62.5 38V51.4Z"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.5 38H56.5V32"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.7 16.9001V65.9001H34.3V16.9001M34.3 9.7001V7.8001C34.3 6.9001 35 6.1001 36 6.1001C36.9 6.1001 37.7 6.8001 37.7 7.8001V9.7001M16.4 65.9001H55.7"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 10.3999C37.6 10.3999 38.9 11.6999 38.9 13.2999C38.9 14.8999 37.6 16.1999 36 16.1999C34.4 16.1999 33.1 14.8999 33.1 13.2999C33.1 11.6999 34.4 10.3999 36 10.3999M36 8.3999C33.3 8.3999 31.1 10.5999 31.1 13.2999C31.1 15.9999 33.3 18.1999 36 18.1999C38.7 18.1999 40.9 15.9999 40.9 13.2999C40.9 10.5999 38.7 8.3999 36 8.3999Z"
      fill="#4A4A4A"
    />
    <path
      d="M14.5 30.7998H18.3V39.3998H14.5V30.7998Z"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WOZ;
