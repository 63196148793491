import React from 'react';
import {
  OutputField,
  OutputFieldList,
} from '~/components/Wizard/context/WizardContext';
import { OutputMap } from '~/components/WizardSteps';
import { ColorList } from '../../components/OutputColorGrid';
import { Items } from '../../components/OutputList';
import { ImageList } from '../../components/OutputImageGrid';
import { Item } from '../../components/OutputItem';

type WithCategory<T> = {
  category: React.ReactNode;
  values: T;
};

type ReturnValue = {
  images: Array<WithCategory<ImageList>>;
  colors: Array<WithCategory<ColorList>>;
  list: Array<WithCategory<Items>>;
  item: Array<WithCategory<Item>>;
};

const getKeyForType = (
  type: Exclude<OutputField<any>['type'], 'invisible'>,
): keyof ReturnValue => {
  switch (type) {
    case 'color':
      return 'colors';
    case 'image':
      return 'images';
    case 'list':
      return 'list';
    case 'item':
      return 'item';
  }
};

const getOutputsForAppOverview = (
  outputMap: OutputMap,
  stepIDs: Array<string> = [],
): ReturnValue => {
  const accBase: ReturnValue = {
    colors: [],
    images: [],
    item: [],
    list: [],
  };

  const outputs = stepIDs.reduce((acc, stepId) => {
    const currentOutput = outputMap[stepId];
    if (!currentOutput) return acc;

    Object.keys(currentOutput).forEach(entryKey => {
      if (entryKey === 'type') return;
      const entry: OutputField<any> = currentOutput[entryKey];

      if (entry && entry.type !== 'invisible') {
        const accKey = getKeyForType(entry.type);

        if (!accKey) return;

        const categoryIndex = (() => {
          const index = acc[accKey].findIndex(
            ({ category }) => category === entry.category,
          );
          return index !== -1 ? index : acc[accKey].length;
        })();

        acc[accKey][categoryIndex] = acc[accKey][categoryIndex] ?? {
          category: entry.category,
          values: [],
        };

        if (!isListOutput(entry)) {
          acc[accKey][categoryIndex].values.push({
            label: entry.label,
            value: entry.value,
            icon: 'icon' in entry ? entry.icon : undefined,
          });
        }

        if (isListOutput(entry) && accKey === 'list') {
          acc[accKey][categoryIndex].values.push({
            value: entry.value,
          });
        }
      }
    });

    return acc;
  }, accBase);

  return outputs;
};

const isListOutput = (
  field: OutputField<any>,
): field is OutputFieldList<any> => {
  if (field.type === 'list') return true;

  return false;
};

export default getOutputsForAppOverview;
