import gql from 'graphql-tag';

export default gql`
  query GetFlowSettings($accountId: ID!) {
    getFlowSettings(accountId: $accountId) {
      __typename
      unsubscribeLinkText
    }
  }
`;
