import styled, { css } from 'styled-components';

const MODAL_MAX_WIDTH = 467;
const MODAL_MIN_WIDTH = 150;

const SmallModalContainer = styled.div<{}>`
  ${({ theme }) => css`
    max-width: ${MODAL_MAX_WIDTH}px;
    min-height: ${MODAL_MIN_WIDTH}px;
    box-shadow: ${theme.getTokens().boxShadow.base};
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  padding: 0;
`;

export default SmallModalContainer;
