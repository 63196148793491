import React, { ReactElement, useContext, memo } from 'react';
import styled, { css } from 'styled-components';

import { Theme } from '~/theme';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { ValidationSubscriberActions } from './BaseActionContext';
import { FlowActionProps } from '~/scenes/Automation/Flows/Actions/types.flow';

import { getCardRadius } from '~/components/Card';
import BaseActionContext from './BaseActionContext';
import FlowContext from '../FlowContext';
import AccountContext from '~/contexts/AccountContext';
import { getDescriptionForAction } from '~/scenes/Automation/Flows/Actions/util';
import TEST_ID from './BaseActionComponent.testid';
import { IconButton } from '~/components/Buttons';
import { FLOW_ACTION_TYPE } from './constants';
import Icon from '~/components/Icon';

const text = {
  step: 'Stap ',
};
type Props = ValidationSubscriberActions & {
  positionString: string;
  actionLabel: string;
  action: FlowActionProps;
  variableStash: FlowVariableStash;
  children: React.ReactNode;
  isHiddenFromView: boolean;
  renderBottomBar?: () => React.ReactNode;
};
const BaseActionComponent = ({
  positionString,
  actionLabel,
  action,
  variableStash,
  subscribeValidator,
  unsubscribeValidator,
  children,
  isHiddenFromView,
  renderBottomBar,
}: Props) => {
  // No templates yet
  // const { emailTemplates } = useContext(FlowContext);
  const { isInitialised, onDeleteAction, getPositionStringForId } =
    useContext(FlowContext);
  const { officeWithId, userWithId } = useContext(AccountContext);

  if (!isInitialised) {
    return null;
  }

  let deleteButtonComponent: ReactElement | null = null;
  if (onDeleteAction != null) {
    deleteButtonComponent = (
      <DeleteButtonContainer>
        <IconButton
          data-testid={TEST_ID.DELETE_BUTTON}
          round
          danger
          disabled={action.type === FLOW_ACTION_TYPE.START}
          onClick={() => {
            onDeleteAction(action.id);
          }}
        >
          <Icon name="trashcan" />
        </IconButton>
      </DeleteButtonContainer>
    );
  }

  const describeOptions = {
    stash: variableStash,
    // emailTemplates
    emailTemplates: [],
    getPositionStringForId,
    userWithId: userWithId,
    officeWithId: officeWithId,
    actionType: action.type,
  };

  return (
    <BaseActionContext.Provider
      value={{
        actionType: action.type,
        variableStash,
        subscribeValidator,
        unsubscribeValidator,
        isHiddenFromView,
        describeOptions,
      }}
    >
      <Container
        data-testid={TEST_ID.CONTAINER}
        data-elementtype={action.type}
        data-objectid={action.id}
      >
        <StepContainer>
          <StepDetailsContainer>
            <TitleContainer>
              <HeaderContainer>
                <StepTitleContainer>
                  {text.step}
                  <StepNumber>{positionString}</StepNumber>
                </StepTitleContainer>
                <ActionTitle>{actionLabel}</ActionTitle>
              </HeaderContainer>
              <Description data-testid={TEST_ID.DESCRIPTION}>
                {getDescriptionForAction(action, describeOptions)}
              </Description>
            </TitleContainer>
            <StepContentContainer>{children}</StepContentContainer>
          </StepDetailsContainer>
          {renderBottomBar && renderBottomBar()}
        </StepContainer>
        {deleteButtonComponent}
      </Container>
    </BaseActionContext.Provider>
  );
};

const StepTitleContainer = styled.div<{}>``;
const ActionTitle = styled.div<{}>`
  font-weight: bold;

  ${({ theme }) => css`
    margin-top: ${theme.space('xxs')};
  `};
`;
const StepNumber = styled.span<{}>`
  font-weight: bold;
`;

const StepContentContainer = styled.div<{}>`
  grid-column: content-start / content-end;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-left: 1px solid ${theme.color('grey')};
  `};
`;

const TitleContainer = styled.div<{}>`
  grid-column: title-start / title-end;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-top-left-radius: ${getCardRadius(theme)};
    border-bottom-left-radius: ${getCardRadius(theme)};
  `};
`;

const HeaderContainer = styled.div<{}>`
  ${({ theme }) => css`
    margin: ${theme.space('l')} ${theme.space('l')} 0 ${theme.space('l')};
    padding-bottom: ${theme.space('m')};
    border-bottom: 1px solid ${theme.color('grey', 'light')};
  `};
`;

const Description = styled.div<{}>`
  flex-grow: 1;
  word-wrap: break-word;

  ${({ theme }) => css`
    margin: 0 ${theme.space('l')} ${theme.space('l')} ${theme.space('l')};
    padding-top: ${theme.space('m')};
  `};
`;
const StepDetailsContainer = styled.div<{}>`
  flex-grow: 1;
  display: grid;
  grid-template-columns:
    [title-start]
    200px
    [title-end content-start]
    calc(100% - 200px)
    [content-end];
  grid-template-rows: auto;

  ${({ theme }) => css`
    border-top-right-radius: ${theme.getTokens().border.radius.s};
    border-bottom-right-radius: ${theme.getTokens().border.radius.s};
  `}
`;

const StepContainer = styled.div<{}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    border: ${theme.getTokens().border.width.s} solid ${theme.color('grey')};
    background-color: ${theme.color('white')};
  `}
`;

export const getDeleteButtonSize = (theme: Theme) => theme.space('xxl');
const DeleteButtonContainer = styled.div<{}>`
  ${({ theme }) => css`
    padding: ${theme.space('xxs')};

    height: ${getDeleteButtonSize(theme)};
    width: ${getDeleteButtonSize(theme)};
  `};
`;

const Container = styled.div<{}>`
  display: flex;
  align-items: center;
  width: 100%;
`;

export default memo<Props>(BaseActionComponent, (prevProps, nextProps) => {
  const { isHiddenFromView: nextHidden, variableStash: nextVariableStash } =
    nextProps;
  const { isHiddenFromView: prevHidden, variableStash: prevVariableStash } =
    prevProps;

  if (prevVariableStash.version !== nextVariableStash.version) {
    return false;
  }

  if (nextHidden === prevHidden && prevHidden === true) {
    return true;
  }

  return false;
});
