import gql from 'graphql-tag';

import SubscriptionFields from '../fragments/SubscriptionFields';
import AccountIssueFields from '../fragments/subfragments/AccountIssueFields';

export default gql`
  query GetAccountForSubscription($accountId: ID!) {
    getAccount(accountId: $accountId) {
      id
      status
      issues {
        ...AccountIssueFields
      }
      subscription {
        ...SubscriptionFields
      }
    }
  }

  ${SubscriptionFields}
  ${AccountIssueFields}
`;
