import { FlowAction, FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import serializeContactAddTagAction from './utils/serializeContactAddTagAction';
import serializeContactAssignAction from './utils/serializeContactAssignAction';
import serializeContactDeleteTagAction from './utils/serializeContactDeleteTagAction';
import serializeContactDetailsAction from './utils/serializeContactDetailsAction';
import serializeIfElseAction from './utils/serializeIfElseAction';
import serializeRealworksSendContactAction from './utils/serializeRealworksSendContactAction';
import serializeSendEmailPlainAction from './utils/serializeSendEmailPlainAction';
import serializeStartAction from './utils/serializeStartAction';
import serializeTaskCreateAction from './utils/serializeTaskCreateAction';
import serializeWaitAction from './utils/serializeWaitAction';
import serializeZapierTriggerAction from './utils/serializeZapierTriggerAction';

/**
 * Serialize client flow action into action input
 * @param {ClientFlowAction} action - ClientFlowAction
 * keywords:
 */
const serializeAction = (
  action: ClientFlowAction,
): FlowV2_Action__Input | null => {
  switch (action.actionType) {
    case FlowAction.ContactDetails:
      return serializeContactDetailsAction(action);

    case FlowAction.ContactAssign:
      return serializeContactAssignAction(action);

    case FlowAction.ContactAddTag:
      return serializeContactAddTagAction(action);

    case FlowAction.ContactDeleteTag:
      return serializeContactDeleteTagAction(action);

    case FlowAction.SendEmailPlain:
      return serializeSendEmailPlainAction(action);

    case FlowAction.Start:
      return serializeStartAction(action);

    case FlowAction.Wait:
      return serializeWaitAction(action);

    case FlowAction.TaskCreate:
      return serializeTaskCreateAction(action);

    case FlowAction.IfElse:
      return serializeIfElseAction(action);

    case FlowAction.ZapierTrigger:
      return serializeZapierTriggerAction(action);

    case FlowAction.RealworksSendContact:
      return serializeRealworksSendContactAction(action);

    default:
      return null;
  }
};
export default serializeAction;
