import gql from 'graphql-tag';

export default gql`
  fragment ValuationReportFields on App_ValuationReport {
    id
    accountId
    name
    officeId
    assignedUserId
    route
    general {
      logoImage
      logoPosition
      logoLink
      backgroundImage
      mainColor
      headerTextColor
      accentColor
      nextButton {
        mainText {
          text
          color
        }
        backgroundColor
      }
      step {
        color
        backgroundColor
      }
      backgroundColor
      navbarBackgroundColor
      googleAnalytics
      facebookPixel
      cookiePolicy
      privacyPolicy
    }
    address {
      mainHeader {
        text
      }
      subHeader {
        text
      }
      why {
        text
      }
      when {
        text
      }
    }
    livingArea {
      mainHeader {
        text
      }
      subHeader {
        text
      }
    }
    houseType {
      mainHeader {
        text
      }
      subHeader {
        text
      }
    }
    comparableProperties {
      mainHeader {
        text
      }
    }
    userData {
      mainHeader {
        text
      }
      subHeader {
        text
      }
      formSubtext {
        text
        color
      }
    }
    report {
      contactButton {
        mainText {
          text
          color
        }
        subText {
          text
          color
        }
        backgroundColor
      }
      contactButtonClickedText {
        text
        color
      }
      formSubtext {
        text
        color
      }
    }
  }
`;
