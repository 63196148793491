import gql from 'graphql-tag';

import FlowParameterOfficeFields from './FlowParameterOfficeFields';
import FlowParameterUserFields from './FlowParameterUserFields';
import FlowParameterTemplateStringFields from './FlowParameterTemplateStringFields';

export default gql`
  fragment FlowActionTaskCreateFields on Flow_Action_Task_Create {
    __typename
    id
    accountId
    parentId
    flowBlueprintId
    type
    assignToContactOwner
    title {
      ...FlowParameterTemplateStringFields
    }
    description {
      ...FlowParameterTemplateStringFields
    }
    user {
      ...FlowParameterUserFields
    }
    office {
      ...FlowParameterOfficeFields
    }
  }

  ${FlowParameterOfficeFields}
  ${FlowParameterUserFields}
  ${FlowParameterTemplateStringFields}
`;
