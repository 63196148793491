import styled, { css } from 'styled-components';
import Link, { Props as LinkProps } from '~/components/Link';

type StyledTrProps = LinkProps & {
  $isSelected: boolean;
  $isDeleted: boolean;
};

const Standard = styled(Link)<StyledTrProps>(
  ({ theme, $isSelected, $isDeleted }) => css`
    display: flex;
    cursor: pointer;
    padding: ${theme.space('s')} ${theme.space('m')};
    background-color: ${$isSelected
      ? theme.color('white', 'dark')
      : theme.color('white')};
    font-weight: ${$isSelected ? 'bold' : 'normal'};
    border-top: 1px solid ${theme.color('grey')};
    height: ${$isDeleted ? 0 : 'auto'};
    overflow-y: ${$isDeleted ? 'hidden' : 'unset'};

    width: 100%;
    text-decoration: none;
    color: inherit;

    &:hover {
      background-color: ${theme.color('tertiary', 'light')};
    }

    &:first-of-type {
      border-top: none;
    }

    &:last-of-type {
      border-bottom-left-radius: ${theme.getTokens().border.radius.s};
      border-bottom-right-radius: ${theme.getTokens().border.radius.s};
    }
  `,
);

export default {
  Standard: Standard,
};
