import { useEffect } from 'react';
import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';

type Action = 'keypress' | 'keydown' | 'keyup';
const useKeybinding = ({
  keys,
  callback,
  action,
}: {
  /*
   * The key combo e.g. ['ctrl+s', 'command+s'] or 'ctrl+s'
   * For exact syntax of key combinations check https://craig.is/killing/mice
   */
  keys: string | Array<string>;

  /*
   * The function you want run when keys get triggered
   */
  callback: (event: ExtendedKeyboardEvent, triggeredKey: string) => void;
  /*
   * Specify the type of action it should fire on, if non is provided all will be watched
   */
  action?: Action;
}) => {
  useEffect(() => {
    Mousetrap.bind(keys, callback, action);

    return () => {
      Mousetrap.unbind(keys);
    };
  }, [action, callback, keys]);
};

export default useKeybinding;
