import React from 'react';
import styled from 'styled-components';
import { Descendant } from 'slate';
import PluginsEditor, {
  Props as EditorProps,
} from '~/components/PluginsEditor';
import useWithImages from '../PluginsEditor/plugins/useWithImages';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import withHtml from '../PluginsEditor/plugins/withHtml';
import { Plugin } from '../PluginsEditor/types';

export type Props = Omit<EditorProps, 'plugins' | 'children'> & {
  dataTestId?: string;

  /** Editor value in slate format */
  value: Array<Descendant>;

  /** Function to change editor value */
  onChange: (value: Array<Descendant>) => void;

  /** If you want to add more elements to the editor */
  customElements?: Array<ELEMENTS>;

  /** Additional plugins to add other functionality to the editor */
  plugins?: Array<Plugin>;

  /** Makes the editor readOnly */
  readOnly?: boolean;

  /** Classname for outside container */
  className?: string;
};

/**
 * Adds withHtml and withImages plugins to the PluginsEditor.
 */
const DefaultTextEditor: React.FC<Props> = ({
  dataTestId,
  value,
  onChange,
  customElements = [],
  plugins = [],
  children,
  className,
  ...rest
}) => {
  const withImages = useWithImages();

  const extraElements = [...customElements, ELEMENTS.DH_IMAGE, ELEMENTS.IMAGE];
  return (
    <Container data-testid={dataTestId} className={className}>
      <PluginsEditor
        plugins={[
          e => withHtml({ editor: e, customElements: extraElements }),
          withImages,
          ...plugins,
        ]}
        customElements={extraElements}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {children}
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
`;

export default DefaultTextEditor;
