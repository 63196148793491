/**
 *
 * Example return:
 * https://devsfa.dathuis.nl/external/nylas/auth/v1?state=User%7Cu---747d204b-f25c-4db7-b495-93ae6f052bb5%7Ca---cfe9654c-a9d9-4db7-bd01-b78ba3a083ab&code=rltRsUZQEcUscIoW1cNHMXAApuamlW#
 *
 * as localhost (for testing):
 * http://localhost:8080/external/nylas/auth/v1?state=User%7Cu---747d204b-f25c-4db7-b495-93ae6f052bb5%7Ca---cfe9654c-a9d9-4db7-bd01-b78ba3a083ab&code=rltRsUZQEcUscIoW1cNHMXAApuamlW#
 */

import React, { useContext } from 'react';
import queryString from 'query-string';

import {
  NylasState,
  SynchroniseEntityType,
} from '~/scenes/External/Nylas/util';

import { parseStateString, mutationVariablesForState } from './util';
import ErrorScreen from '~/scenes/ErrorScreen';
import Validation from '~/util/Validation';
import { StartEmailSyncMutation } from '~/graphql/Mutation';
import startEmailSyncMutation from '~/graphql/mutation/StartEmailSync';
import EmailSyncStatusDisplayer from './components/EmailSyncStatusDisplayer';
import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';
import ErrorMessages from '~/ErrorMessages';
import { getErrorMessages } from '~/util/errorHandling';
import AccountContext from '~/contexts/AccountContext';

import { navigate } from 'gatsby';
import {
  RouteComponentProps,
  useLocation,
  WindowLocation,
} from '@reach/router';
import useIsMountedRef from '~/hooks/useIsMountedRef';

const text = {
  invalidStateTitle: 'Oeps!',
  invalidState:
    'Er is iets misgegaan bij het synchroniseren van het e-mail account. Probeer het nog eens.',
};

const NylasAuthoriseEndpointV1: React.FC<RouteComponentProps> = () => {
  const isMountedRef = useIsMountedRef();

  const location = useLocation() as WindowLocation<{
    code: string;
    stateString: string;
  }>;

  const { refetchSessionHydration } = useContext(AccountContext);

  if (!location.state) {
    // grab all information from the address bar and remove it
    const parsedSearchObject = queryString.parse(location.search ?? '');

    const stateString = parsedSearchObject.state;
    const code = parsedSearchObject.code;

    if (!stateString || !code) {
      return (
        <ErrorScreen
          explanation={text.invalidState}
          title={text.invalidStateTitle}
        />
      );
    }

    void navigate(location.pathname, {
      replace: true,
      state: { code, stateString },
    });

    return null;
  } else {
    const { code, stateString } = location.state;

    if (
      !Validation.String.isNonEmptyString(stateString) ||
      !Validation.String.isNonEmptyString(code)
    ) {
      return (
        <ErrorScreen
          explanation={text.invalidState}
          title={text.invalidStateTitle}
        />
      );
    }

    const state = parseStateString(stateString);

    if (state === null) {
      return (
        <ErrorScreen
          explanation={text.invalidState}
          title={text.invalidStateTitle}
        />
      );
    }

    const mutationVariables = mutationVariablesForState(state, code);

    if (mutationVariables === null) {
      return (
        <ErrorScreen
          explanation={text.invalidState}
          title={text.invalidStateTitle}
        />
      );
    }

    return (
      <>
        <StartEmailSyncMutation
          name={cleanedFilename(__filename)}
          mutation={startEmailSyncMutation}
          doNotReportErrorMessages={[ErrorMessages.emailAlreadySynchronised]}
        >
          {(startEmailSync, { data, error, loading }) => (
            <EmailSyncStatusDisplayer
              startEmailSync={() => {
                void startEmailSync({
                  variables: mutationVariables,
                }).then(() => {
                  if (!isMountedRef.current) return;

                  void refetchSessionHydration();
                });
              }}
              error={getErrorMessages(error)}
              loading={loading}
              email={data?.startEmailSync.email ?? null}
              syncState={data?.startEmailSync.syncState ?? null}
              goToLink={() => void navigate(returnPathForState(state))}
            />
          )}
        </StartEmailSyncMutation>
      </>
    );
  }
};

export const returnPathForState = (state: NylasState): string =>
  returnPathForEntityType(state.entityType, state.entityId);

// export for cypress
export const returnPathForEntityType = (
  entityType: SynchroniseEntityType,
  entityId: string,
): string => {
  switch (entityType) {
    case SynchroniseEntityType.Account:
      return '/-/settings/account';
    case SynchroniseEntityType.Office:
      return `/-/settings/offices/${entityId}`;
    case SynchroniseEntityType.User:
      return '/-/settings/profile';
    default:
      reportErrorToTracker(
        `Invalid entityType ${entityType} while trying to determine returnPathForState!`,
      );
      return '/-/';
  }
};

export default NylasAuthoriseEndpointV1;
