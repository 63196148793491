import React, { useState } from 'react';

import styled from 'styled-components';
import { Input } from '~/components/Inputs';
import { DoContactAction_Delete__Input } from '~/graphql/types';
import { ActionModalProps } from '../../actionSwitcher';
import ConfirmActionModal from '../../components/ConfirmActionModal';

const text = {
  cancelButtonText: 'Annuleer',
  title: 'Verwijder contacten',
  message: 'Contacten kunnen niet worden hersteld',
  deleteModalLabel: "Typ 'verwijder' om te bevestigen",
  confirmButtonText: 'Verwijder',
  confirm: 'Verwijder',
  delete: 'verwijder',
};

export type Props = {
  dataTestId?: string;
} & ActionModalProps;

const Delete: React.FC<Props> = ({ dataTestId, ...rest }) => {
  const [deleteModalValue, setDeleteModalValue] = useState<string>('');
  const textTypedCorrectly =
    deleteModalValue.toLowerCase() === text.delete ? true : false;

  return (
    <Container data-testid={dataTestId}>
      <ConfirmActionModal
        disabled={!textTypedCorrectly}
        actionInput={{
          Delete: {
            delete: true,
          } as DoContactAction_Delete__Input,
        }}
        {...rest}
      >
        {loading => (
          <Input
            onChange={event => {
              if (event.target) {
                setDeleteModalValue(event.target.value);
              }
            }}
            value={deleteModalValue}
            label={text.deleteModalLabel}
            disabled={loading}
            name="typeDeleteInputField"
          />
        )}
      </ConfirmActionModal>
    </Container>
  );
};

const Container = styled.div<{}>``;

export default Delete;
