/**
 *
 * Given two objects, return a new object containing only the fields
 * that are different in objB. So compare all fields in objB and keep
 * the ones that are different to objA.
 *
 * Note that this is built for forms which are just 1 level deep!
 */

const objectDiff = (
  objA: $Object,
  objB: $Object,
  isNestesObj?: boolean,
): $Object | null => {
  const diffObj = {};

  Object.keys(objB).forEach(key => {
    const valueA = objA[key];
    const valueB = objB[key];
    if (typeof valueA === 'object' && valueA !== null) {
      const nestedDiffObj = objectDiff(valueA, valueB, true);
      if (nestedDiffObj) {
        diffObj[key] = nestedDiffObj;
      }
    } else if (valueA !== valueB) {
      diffObj[key] = valueB;
    }
  });
  // need to check if it recursion call and no chang
  const diffObjectKeys = Object.keys(diffObj);
  return isNestesObj && diffObjectKeys.length === 0 ? null : diffObj;
};

export default objectDiff;
