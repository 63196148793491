import React, { useCallback } from 'react';
import { Input } from '~/components/Inputs';
import styled, { css } from 'styled-components';

type Props = {
  name: string;
  onChange: (arg0: { [x: string]: string | null }) => any;
  text: string;
  value: string;
  inline?: boolean;
  placeholder?: string;
};

const InputWrapper = ({
  text,
  placeholder,
  value,
  name,
  inline,
  onChange,
}: Props) => {
  const _onChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement | string>) => {
      if (typeof e !== 'string') {
        const target = e.target;
        const value = (e.target as HTMLInputElement).value;

        onChange({
          [name]: target && value && value !== '' ? value : null,
        });
      }
    },
    [name, onChange],
  );

  return (
    <InputContainer inline={inline}>
      <Label htmlFor={text}>{text} </Label>
      <Input
        dataTestid={`input_${name}`}
        placeholder={placeholder || text}
        name={name}
        value={value}
        onChange={_onChange}
      />
    </InputContainer>
  );
};

export default InputWrapper;

const Label = styled.div<{ htmlFor?: string }>`
  cursor: pointer;
  ${({ theme }) => css`
    margin: ${theme.space('xxs')} 0;
  `};
`;
const InputContainer = styled.div<{ inline: Props['inline'] }>`
  display: flex;
  flex-direction: ${props => (props.inline ? 'row' : 'column')};
  > div {
    flex: 1;
  }

  ${({ theme }) => css`
    margin-bottom: ${theme.space('m')};
  `};
`;
