import React from 'react';
import styled, { css } from 'styled-components';
import { ConnectorOperator } from '~/graphql/types';
import {
  MinimalDropdown,
  SELECTED_ELEMENT_BORDER_IN_PX,
} from '~/components/Inputs/Dropdown';
import DROPDOWN_TEST_ID from '~/components/Inputs/Dropdown/index.testid';
import theme from '~/theme';

export type Props = {
  dataTestId?: string;
  disabled?: boolean;
  withMargin?: boolean;
  reserveLabelSpace?: boolean;
  blockIndex: number;
  blockCount: number;
  connector: ConnectorOperator | null;
  filterGroupIndex: number;
  leafIndex?: number;
  onChange: (operator: ConnectorOperator) => void;
};

const operatorOptions = [
  {
    label: 'en',
    payload: ConnectorOperator.And,
    key: ConnectorOperator.And,
  },
  {
    label: 'of',
    payload: ConnectorOperator.Or,
    key: ConnectorOperator.Or,
  },
];

const OperatorComponent: React.FC<Props> = ({
  disabled,
  reserveLabelSpace,
  withMargin,
  dataTestId,
  blockIndex,
  blockCount,
  connector,
  filterGroupIndex,
  leafIndex,
  onChange,
  ...rest
}) => {
  if (blockIndex < blockCount - 1) {
    // if not the last one
    if (blockIndex === 0) {
      // if first, make it changeable
      return (
        <DropdownContainer
          data-testid={dataTestId}
          $withMargin={withMargin}
          {...rest}
        >
          <StyledOperatorDropdown
            key={
              leafIndex != null
                ? `operator-${filterGroupIndex}-${leafIndex}-${blockIndex}`
                : `operator-${filterGroupIndex}-${blockIndex}`
            }
            outline
            outlineStyles={{
              active: css`
                background-color: ${theme.color('primary', 'light')};
                color: ${theme.color('white')};
              `,
              notActive: css`
                border-color: ${theme.color('primary', 'light')};
                color: ${theme.color('primary', 'light')};
              `,
            }}
            label={''}
            small
            options={operatorOptions}
            selectedOptionIdx={operatorOptions.findIndex(
              option => option.payload === connector,
            )}
            onChange={selectedOption => {
              onChange(selectedOption.option.payload);
            }}
          />
        </DropdownContainer>
      );
    }
    return (
      <DropdownContainer
        data-testid={dataTestId}
        $withMargin={withMargin}
        {...rest}
      >
        <Label
          data-testid={dataTestId}
          $disabled={disabled}
          $withMargin={withMargin}
          key={`operator-${blockIndex}`}
        >
          {connector === ConnectorOperator.And ? 'en' : 'of'}
        </Label>
      </DropdownContainer>
    );
  }
  return reserveLabelSpace ? (
    <DropdownContainer
      data-testid={dataTestId}
      $withMargin={withMargin}
      {...rest}
    >
      <Label
        data-testid={dataTestId}
        $disabled={disabled}
        $withMargin={withMargin}
        $invisible
        key={`operator-${blockIndex}`}
      >
        {connector === ConnectorOperator.And ? 'en' : 'of'}
      </Label>
    </DropdownContainer>
  ) : null;
};

export const StyledOperatorDropdown = styled(MinimalDropdown)<{}>`
  ${({ theme }) => css`
    & [data-testid='${DROPDOWN_TEST_ID.DROPDOWN_TOGGLE}'] {
      font-weight: ${theme.fw('semiBold')};

      border: ${theme.getTokens().border.width.s} solid currentColor;
      font-size: ${theme.fs('s')};
      padding-right: calc(${theme.space('xxs')});
      padding-left: calc(${theme.space('xxs')});
      padding-top: calc(${theme.space('xxs')} + 1px);
      padding-bottom: calc(${theme.space('xxs')} + 1px);

      &:hover,
      &:active,
      &:focus {
        background-color: ${theme.color('primary', 'light')};
        color: ${theme.color('white')};
      }
    }
  `};
`;
const DropdownContainer = styled.div<{ $withMargin?: boolean }>(
  ({ theme, $withMargin }) => css`
    display: flex;
    align-items: center;
    max-width: 60px;
    font-size: ${theme.fs('s')};
    ${$withMargin && `margin-left: ${theme.space('xxs')};`};
  `,
);

const Label = styled.span<{
  $disabled?: boolean;
  $invisible?: boolean;
  $withMargin?: boolean;
}>(
  ({ theme, $disabled, $invisible, $withMargin }) => css`
    display: flex;
    max-width: 60px;
    align-items: center;
    justify-content: center;
    ${$disabled && `color: ${theme.color('grey')};`}
    ${$invisible && `visibility: hidden;`}

    padding-right: ${$withMargin
      ? `calc(${theme.space('xxs')} * 3);`
      : `${theme.space('xxs')};`};

    padding-bottom: calc(
      ${theme.space('xxs')} + ${SELECTED_ELEMENT_BORDER_IN_PX}px
    );
    padding-top: calc(
      ${theme.space('xxs')} + ${SELECTED_ELEMENT_BORDER_IN_PX}px
    );

    margin-left: calc(
      ${theme.space('xxs')} + ${SELECTED_ELEMENT_BORDER_IN_PX * 2}px
    );
  `,
);

export default OperatorComponent;
