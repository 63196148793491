/**
 *
 * Spinner component
 * Made by Adrian https://codepen.io/adrianegger/pen/GRrrRyL
 *
 */
import React from 'react';
import { Props } from '..';

const Spinner: React.FC<Props> = ({
  color = 'currentColor',
  ...props
}: Props) => (
  <svg
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="9"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

export default Spinner;
