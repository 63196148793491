import React, { ReactElement, Component } from 'react';
import styled, { css } from 'styled-components';

import { Task } from '../../types';
import { ViewableAccount } from '~/contexts/AccountContext';
import { ActivityFieldsFragment } from '~/graphql/types';
import TaskModalContext from './TaskModalContext';
import ContactInformation from './components/ContactInformation';
import Catalog from '~/Catalog';
import ActivityTabs, { ActivityTabProps } from '~/components/ActivityTabs';
import SendMessageTab from '~/components/ActivityTabs/components/SendMessageTab';
import CreateNewTaskTab from '~/components/ActivityTabs/components/CreateNewTaskTab';
import DescriptionTab from '~/components/ActivityTabs/components/DescriptionTab';
import LogActivityTab from '~/components/ActivityTabs/components/LogActivityTab';
import useUpdateTimelineFn from '~/hooks/useUpdateTimelineFn';
import EventTimelineV2 from '~/components/EventTimelineV2';

export type ModalProps = {
  /** Task details to fill up the task state with, the rest will be queried based on id.
   * If null we will create a new task.
   */
  initialTaskDetails: Task | null;

  /** When closing the modal */
  onClose: () => void;

  /** Get access to account data form context */
  account: ViewableAccount;

  /** Office id for default dropdown value in insert task modal */
  selectedInFilterOfficeId: string | null;

  /** User id for default dropdown value in insert task modal */
  selectedInFilterUserId: string | null;
};

type Props = ModalProps & {
  /** Handling access to close modal if data updated. Using only in TaskModalComponent */
  handleToggleClose: (canClose: boolean) => void;

  /** The modal container - used to capture scroll events when the body is fixed */
  modalContainerRef: React.RefObject<any> | undefined;
  modalWidth: string;
  updateTimelineFn?: (newActivity: ActivityFieldsFragment) => void;
};
type State = {
  isNew: boolean;
  taskDetails: Task | null;
  isChanged: boolean;
  contactError?: string;
};

const text = {
  createNewTaskLabel: Catalog.addTaskTabLabel,
  updateDescriptionLabel: Catalog.descriptionTabLabel,
  addLogTabLabel: Catalog.addLogTabLabel,
};

export type InsertOrUpdateEventFunction = (
  newOrUpdatedActivity: ActivityFieldsFragment,
) => void;

class TaskModalComponent extends Component<Props, State> {
  updateTimelineFn: InsertOrUpdateEventFunction | null = null;

  constructor(props: Props) {
    super(props);

    const { initialTaskDetails } = props;

    this.state = {
      isNew: !initialTaskDetails,
      taskDetails:
        initialTaskDetails == null
          ? null
          : {
              ...initialTaskDetails,
            },
      isChanged: false,
      contactError: '',
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (!nextProps.initialTaskDetails) {
      return null;
    }

    const { title, type, dueDate, description, userId, officeId } =
      nextProps.initialTaskDetails;

    if (!prevState.taskDetails) {
      return {
        taskDetails: prevState.taskDetails,
        isChanged: true,
      };
    }

    const isChanged =
      title !== prevState.taskDetails.title ||
      type !== prevState.taskDetails.type ||
      dueDate !== prevState.taskDetails.dueDate ||
      description !== prevState.taskDetails.description ||
      userId !== prevState.taskDetails.userId ||
      officeId !== prevState.taskDetails.officeId;

    if (isChanged !== prevState.isChanged) {
      nextProps.handleToggleClose(!isChanged);
    }

    const taskDetails = isChanged
      ? nextProps.initialTaskDetails
      : prevState.taskDetails;
    return {
      taskDetails,
      isChanged,
    };
  }

  updateTaskDetails = (updatedTaskDetails: Task) => {
    this.setState(prevState => ({
      taskDetails: {
        ...prevState.taskDetails,
        ...updatedTaskDetails,
      },
    }));
  };

  setContactError = (errorText?: string) => {
    this.setState({
      contactError: errorText,
    });
  };

  handleNewActivity = (newActivity: ActivityFieldsFragment) => {
    if (
      this.props.updateTimelineFn &&
      typeof this.props.updateTimelineFn === 'function'
    ) {
      this.props.updateTimelineFn(newActivity);
    }
  };

  render() {
    const { taskDetails, isNew, contactError } = this.state;
    const {
      account,
      onClose,
      handleToggleClose,
      selectedInFilterOfficeId,
      selectedInFilterUserId,
      modalWidth,
    } = this.props;

    const { contactId, Contact } = taskDetails || {};

    let sendMessageTabComponent: ReactElement<ActivityTabProps> | null = null;

    if (contactId && Contact) {
      const { name: contactName, email: contactEmail } = Contact;
      sendMessageTabComponent = (
        <SendMessageTab
          label="Verstuur e-mail"
          datatestId="send-message"
          handleCloseModal={onClose}
          contact={{
            id: contactId,
            name: contactName,
            email: contactEmail,
          }}
          onSuccess={this.handleNewActivity}
        />
      );
    }

    const createNewTaskTabComponent: ReactElement<ActivityTabProps> = (
      <CreateNewTaskTab
        handleSetContactError={this.setContactError}
        isNew={isNew}
        label={text.createNewTaskLabel}
        datatestId="add-task"
        key="add-task"
        handleCloseModal={onClose}
        accountId={account.id}
        taskDetails={taskDetails}
        contactId={contactId}
        defaultAssignedOfficeId={selectedInFilterOfficeId}
        defaultAssignedUserId={selectedInFilterUserId}
        onSuccess={this.handleNewActivity}
      />
    );

    return (
      <TaskModalContext.Provider
        value={{
          taskDetails,
          isNew,
          updateTaskDetails: this.updateTaskDetails,
          handleToggleClose,
          closeModal: onClose,
          accountId: account.id,
        }}
      >
        <GridContainer
          data-testid="task-modal"
          data-objectid={
            isNew || taskDetails == null
              ? 'modal-new'
              : `modal-${taskDetails.id}`
          }
          modalWidth={modalWidth}
        >
          <TimelineContainer>
            {Contact && <EventTimelineV2 contact={Contact} disabled />}
          </TimelineContainer>
          <TaskContainer>
            {isNew || taskDetails == null || contactId == null ? (
              <ActivityTabs>{[createNewTaskTabComponent]}</ActivityTabs>
            ) : (
              <ActivityTabs>
                <DescriptionTab
                  contactId={contactId}
                  datatestId="update-description"
                  handleCloseModal={onClose}
                  label={text.updateDescriptionLabel}
                  taskDetails={taskDetails}
                  handleToggleClose={handleToggleClose}
                />
                {sendMessageTabComponent}
                <LogActivityTab
                  label={text.addLogTabLabel}
                  datatestId="log-activity"
                  handleCloseModal={onClose}
                  contactId={contactId}
                  taskDetails={taskDetails}
                  onSuccess={this.handleNewActivity}
                />
                {createNewTaskTabComponent}
              </ActivityTabs>
            )}
          </TaskContainer>
          <ContactContainer>
            <ContactInformation error={contactError} />
          </ContactContainer>
        </GridContainer>
      </TaskModalContext.Provider>
    );
  }
}

export const BREAK_POINT = 900;

const TimelineContainer = styled.div<{}>`
  grid-column: content-start / content-end;
  grid-row: timeline-start / timeline-end;

  ${({ theme }) => css`
    padding: ${theme.space('xl')} ${theme.space('xl')} 0 ${theme.space('m')};
    background-color: ${theme.color('white')};
  `};
`;

const TaskContainer = styled.div<{}>`
  grid-column: content-start / content-end;
  grid-row: task-start / task-end;
`;

const ContactContainer = styled.div<{}>`
  grid-column: contact-start / contact-end;
  grid-row: start / end;
  height: 100%;

  ${({ theme }) => css`
    background-color: ${theme.color('white', 'dark')};
  `};

  @media (max-width: ${BREAK_POINT}px) {
    grid-column: content-start / content-end;
    grid-row: contact-start / contact-end;
  }
`;

type GridContainerProps = {
  modalWidth: string;
};
const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  height: 100%;

  grid-template-rows: [start task-start] min-content [task-end timeline-start] auto [timeline-end end];
  grid-template-columns:
    [start contact-start] 280px [contact-end content-start] minmax(
      auto,
      calc(${({ modalWidth }: GridContainerProps) => modalWidth} - 280px)
    )
    [content-end end];

  @media (max-width: ${BREAK_POINT}px) {
    grid-template-rows: [start contact-start] min-content [contact-end task-start] min-content [task-end timeline-start] auto [timeline-end end];
    grid-template-columns: [start content-start] auto [content-end end];
  }
`;

const WithUpdateTimelineFn: React.FC<Props> = props => {
  const updateTimelineFn = useUpdateTimelineFn(
    props.initialTaskDetails?.contactId ?? null,
  );

  return <TaskModalComponent {...props} updateTimelineFn={updateTimelineFn} />;
};

export default WithUpdateTimelineFn;
