import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const Trustoo: React.FC<Props> = ({
  color = 'currentColor',
  strokeWidth,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1881 57.2619H4.83002V31.5078H68V57.2619H39.8352"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4333 53.34H8.74976V35.4258H26.6737V42.6777"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2115 36.8882H60.1603"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2115 40.9248H43.5005"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5358 53.4203C53.5316 53.4203 54.339 52.613 54.339 51.6171C54.339 50.6213 53.5316 49.814 52.5358 49.814C51.5399 49.814 50.7326 50.6213 50.7326 51.6171C50.7326 52.613 51.5399 53.4203 52.5358 53.4203Z"
        fill={color}
      />
      <path
        d="M38.0318 53.4203C39.0277 53.4203 39.835 52.613 39.835 51.6171C39.835 50.6213 39.0277 49.814 38.0318 49.814C37.0359 49.814 36.2286 50.6213 36.2286 51.6171C36.2286 52.613 37.0359 53.4203 38.0318 53.4203Z"
        fill={color}
      />
      <path
        d="M42.9317 53.4203C43.9276 53.4203 44.7349 52.613 44.7349 51.6171C44.7349 50.6213 43.9276 49.814 42.9317 49.814C41.9358 49.814 41.1285 50.6213 41.1285 51.6171C41.1285 52.613 41.9358 53.4203 42.9317 53.4203Z"
        fill={color}
      />
      <path
        d="M47.832 53.4203C48.8279 53.4203 49.6352 52.613 49.6352 51.6171C49.6352 50.6213 48.8279 49.814 47.832 49.814C46.8361 49.814 46.0288 50.6213 46.0288 51.6171C46.0288 52.613 46.8361 53.4203 47.832 53.4203Z"
        fill={color}
      />
      <path
        d="M8.74976 31.02V17.1924H64.0798V31.02"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1801 28.0773V20.6294H27.8794V28.0773"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8582 21.915H57.2202"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8582 25.4531H50.8013"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6702 16.7006V4.84277H60.1603V16.7006"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6199 13.7587V7.79053H29.0849V13.7587"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5048 8.89111H48.2532"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5048 11.9268H55.5443"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1053 64.0027C31.9939 64.0027 36.7676 59.2291 36.7676 53.3405C36.7676 47.4519 31.9939 42.6782 26.1053 42.6782C20.2167 42.6782 15.4431 47.4519 15.4431 53.3405C15.4431 59.2291 20.2167 64.0027 26.1053 64.0027Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5337 60.8477L40.325 67.639"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1968 53.3413H26.6741V47.0596"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4805 57.2637H31.4956"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default Trustoo;
