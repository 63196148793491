import React from 'react';

import { Task } from '~/scenes/Tasks/types';
import { UpdateTaskMutationFunction } from '~/graphql/Mutation';
import { TaskListType } from '~/scenes/Tasks/util/taskListType';
import { UpdateTaskMutationVariables } from '~/graphql/types';

import SlideOpenAnimationContainer from '~/components/SlideOpenAnimationContainer';
import { TaskModalControllerContext } from '~/scenes/Tasks/TaskModalControllerContext';
import withDelayedLoading, {
  WithDelayedLoading,
} from '~/components/util/withDelayedLoading';

import TASK_LIST_TYPE from '~/scenes/Tasks/util/taskListType';
import TaskInList from './TaskInList';
import useIsMountedRef from '~/hooks/useIsMountedRef';

type MyProps = {
  /** The task to show: { dueDate, type, status, descriptionSnippet, officeId?, userId?, Contact? } */
  task: Task;

  /** Update task mutation callback function */
  updateTask: UpdateTaskMutationFunction;

  /** If the task is not part of the list anymore. So animate removal! */
  isRemovedFromList?: boolean;

  /** In what list am I being shown */
  listType: TaskListType;
};
type Props = WithDelayedLoading & MyProps;

// export for testing
export const TaskInListContainer = ({
  task,
  updateTask,
  isRemovedFromList = false,
  listType,
  setLoading,
  removeLoading,
  loading,
}: Props) => {
  const isMountedRef = useIsMountedRef();
  const isCompleted = listType === TASK_LIST_TYPE.CLOSED;

  return (
    <SlideOpenAnimationContainer
      isOpen={!isRemovedFromList}
      useOverflowHidden={true}
    >
      {ref => (
        <TaskModalControllerContext.Consumer>
          {({ showTaskModal }) => (
            <div ref={ref}>
              <TaskInList
                isRemovedFromList={isRemovedFromList}
                isCompleted={isCompleted}
                loading={loading}
                task={task}
                onOpenTask={showTaskModal}
                onTaskChange={async (
                  variables: UpdateTaskMutationVariables,
                ) => {
                  setLoading();

                  await updateTask({
                    variables,
                  });

                  if (isMountedRef.current) removeLoading();
                }}
              />
            </div>
          )}
        </TaskModalControllerContext.Consumer>
      )}
    </SlideOpenAnimationContainer>
  );
};

export default withDelayedLoading<MyProps>(TaskInListContainer);
