import { FlowConditionAppHypotheekbondDetails } from './types.flow';

import {
  DEFAULT_FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELD,
  FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELDS,
} from './constants';
import {
  asConditionFieldProp,
  emptyConditionField,
} from '~/scenes/Automation/Flows/Actions/Base';
import equalsEventPointer from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event/equalsEventPointer';
import cleanedFilename from '~/util/cleanedFilename';
import { AppHypotheekbondPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ConditionField } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/types.flow';
import { FlowConditionFieldsFragment } from '~/graphql/types';
import asHypotheekbondEventParameterValue from '../../FlowParameter/ParameterValue/Event/Hypotheekbond/asHypotheekbondEventParameterValue';

type ConditionType = Extract<
  FlowConditionFieldsFragment,
  {
    __typename: 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details';
  }
>;

type ExpectedConditionEventType = Extract<
  ConditionType['event'],
  {
    __typename: '';
  }
>;

type ExpectedConditionType = ConditionType & {
  event: ExpectedConditionEventType;
};

const isConditionEventAppHypotheekbondPointer = (
  event: ConditionType['event'],
): event is ExpectedConditionEventType =>
  event.__typename === 'Flow_Parameter_Event_Contact_App_Hypotheekbond_Pointer';

const isExpectedEventCondition = (
  condition: ConditionType,
): condition is ExpectedConditionType =>
  isConditionEventAppHypotheekbondPointer(condition.event);

const asFlowConditionAppHypotheekbondDetailsProp = (
  conditions: Array<ConditionType>,
  allShouldBeTrue: boolean,
): Omit<FlowConditionAppHypotheekbondDetails, 'id'> => {
  const fields: Array<ConditionField> = [];
  let eventParameter: AppHypotheekbondPointerParameterValue | null = null;

  conditions.forEach(condition => {
    if (!isExpectedEventCondition(condition)) return;
    const { event } = condition;
    const newEvent = asHypotheekbondEventParameterValue(event);
    if (
      eventParameter != null &&
      !equalsEventPointer(eventParameter, newEvent)
    ) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | different events in the conditions (${JSON.stringify(
          conditions,
          null,
          2,
        )})`,
      );
    }
    eventParameter = newEvent;

    let setField: ConditionField = emptyConditionField(
      DEFAULT_FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELD,
    );

    Object.keys(FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELDS).forEach(
      fieldIndex => {
        const field =
          FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELDS[fieldIndex];
        if (condition[field.name] != null) {
          setField = asConditionFieldProp(field, condition[field.name]);
          fields.push(setField);
        }
      },
    );
  });

  if (eventParameter == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | no event found in conditions (${JSON.stringify(
        conditions,
        null,
        2,
      )})`,
    );
  }

  return {
    type: 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details',
    event: eventParameter,
    fields,
    allShouldBeTrue,
  };
};

export default asFlowConditionAppHypotheekbondDetailsProp;
