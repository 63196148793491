import React, { ReactElement } from 'react';

import {
  FlowVariableStashItem,
  FlowOutputObject,
  FlowOutputObjectField,
} from '~/scenes/Automation/Flows/types.flow';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import { PointerVariable } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { Dropdown } from '~/components/Inputs';
import Catalog from '~/Catalog';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import TEST_ID from './AppBBWaardecheckReportPointerComponent.testid';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import validateStashVariable from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/util/validateStashVariable';

type MyProps = {
  possibleVariables: Array<FlowVariableStashItem>;
  fieldType: FLOW_OUTPUT_TYPE;
  variable: PointerVariable | null;
  onChange: (newPointerVariable: PointerVariable | null) => void;
  variableLabel?: string;
  fieldLabel?: string;
  hideVariableDropdownIfOnlyOneOptionAndSelected?: boolean;
  hideFieldDropdownIfOnlyOneOptionAndSelected?: boolean;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
};
class AppBBWaardecheckReportPointerComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  validate = (): boolean => {
    const { variable, possibleVariables, fieldType } = this.props;

    if (variable == null) return true;

    return validateStashVariable(variable, possibleVariables, fieldType);
  };

  render() {
    const {
      possibleVariables,
      variable,
      onChange,
      variableLabel,
      hideVariableDropdownIfOnlyOneOptionAndSelected,
    } = this.props;

    const selectedVariableIndex =
      variable == null ? -1 : findVariableIndexIn(possibleVariables, variable);
    const selectedVariable = possibleVariables[selectedVariableIndex];

    let variableOptions: Array<{
      key: string;
      label: String;
      payload: FlowVariableStashItem | null;
    }> = [];

    variableOptions = [
      {
        key: 'no-selection',
        label: 'Alle brainbay waardechecks',
        payload: null,
      },
    ];

    variableOptions = variableOptions.concat(
      possibleVariables.map(possibleVariable => ({
        label: possibleVariable.variableLabel,
        payload: possibleVariable,
        key: possibleVariable.variableName,
      })),
    );

    const selectedOptionIdx = selectedVariableIndex + 1;

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => {
          let variableDropdownComponent: ReactElement | null = null;
          if (
            hideVariableDropdownIfOnlyOneOptionAndSelected === true &&
            selectedVariableIndex >= 0 &&
            variableOptions.length === 1
          ) {
            variableDropdownComponent = null;
          } else {
            variableDropdownComponent = (
              <Dropdown
                dataTestid={TEST_ID.VARIABLE_DROPDOWN}
                selectOnlyOptionAutomatically
                error={
                  showValidation && variable != null && selectedVariable == null
                    ? Catalog.requiredField
                    : ''
                }
                label={
                  variableLabel == null
                    ? Catalog.flows.pointerVariableLabel
                    : variableLabel
                }
                options={variableOptions}
                selectedOptionIdx={selectedOptionIdx}
                onChange={({ option }) => {
                  option.payload == null
                    ? onChange(null)
                    : onChange({
                        ...variable,
                        name: option.payload.variableName,
                        field: option.payload
                          ? {
                              name: asPointerName(
                                option.payload.outputObjects[0],
                              ),
                            }
                          : null,
                      });
                }}
              />
            );
          }

          return <>{variableDropdownComponent}</>;
        }}
      </FlowContext.Consumer>
    );
  }
}

const findVariableIndexIn = (
  possibleVariables: Array<FlowVariableStashItem>,
  variable: PointerVariable | null,
): number => {
  if (variable == null) return -1;

  return possibleVariables.findIndex(
    possibleVariable => possibleVariable.variableName === variable.name,
  );
};

export const asPointerName = (
  outputObject: FlowOutputObject,
  outputField?: FlowOutputObjectField,
) => {
  if (outputField == null) return outputObject.name;

  return `${outputObject.name}.${outputField.name}`;
};

export default withBaseActionContext<MyProps>(
  AppBBWaardecheckReportPointerComponent,
);
