import React, { useState } from 'react';

import ModalContext from './ModalContext';

type Props = {
  children: React.ReactNode;
};
const ModalContextProvider = ({ children }: Props) => {
  const [modal, setModal] = useState<React.ReactNode>(null);

  return (
    <ModalContext.Provider
      value={{
        showModal: modal => {
          setModal(modal);
        },
        hideModal: () => {
          setModal(null);
        },
      }}
    >
      {children}
      {modal}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
