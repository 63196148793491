import React from 'react';
import { GetPositionStringFn } from '~/scenes/Automation/Flows/types.flow';
import cleanedFilename from '~/util/cleanedFilename';

export type FlowContextProps = {
  isInitialised: boolean;
  showValidation: boolean;
  getPositionStringForId: GetPositionStringFn;
  onDeleteAction?: (actionId: string) => void;
};

const FlowContext = React.createContext<FlowContextProps>({
  isInitialised: false,
  showValidation: false,
  getPositionStringForId: () => {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | called getPositionStringForId before flow context was initialised`,
    );
  },
});

export type WithFlowContextProps = {
  flowContext: FlowContextProps;
};

export const withFlowContext = <ComponentProps extends Object>(
  WrappedComponent: React.ComponentType<ComponentProps & WithFlowContextProps>,
): React.ComponentType<ComponentProps> =>
  function ConnectedComponent(props: ComponentProps) {
    return (
      <FlowContext.Consumer>
        {(flowContext: FlowContextProps) => (
          <WrappedComponent {...props} flowContext={flowContext} />
        )}
      </FlowContext.Consumer>
    );
  };

export default FlowContext;
