import { EventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

const equalsEventPointer = (
  first: EventParameterValue,
  second: EventParameterValue,
): boolean => {
  if (first.variable == null || second.variable == null) {
    return false;
  }

  if (first.variable.name !== second.variable.name) {
    return false;
  }

  if (first.variable.field === null && second.variable.field === null) {
    return true;
  } else if (first.variable.field == null || second.variable.field == null) {
    return false;
  }

  return first.variable.field.name === second.variable.field.name;
};

export default equalsEventPointer;
