import React, { useEffect, useState } from 'react';
import uuidv1 from 'uuid/v1';
import styled, { css } from 'styled-components';
import { useTable, useSortBy, useFlexLayout } from 'react-table';

import {
  AdminAccountInfoSortField,
  AdminGetAccountsQueryVariables,
  SortDirection,
  useAdminGetAccountsQuery,
} from '~/graphql/types';

import { HeaderCell } from '~/components/DataTables';
import { Header } from '~/components/DataTables/styling';
import useDebounce from '~/hooks/useDebounce';
import { memoize } from 'underscore';
import { isEmpty, isNil } from 'ramda';
import { SearchInput } from '~/components/Inputs';
import memoizedAccountListColumns from './utils/accountsListColumns';
import TEST_ID from './index.testid';
import TableBody from '../TableBody/index';
import useCurrentBreakpoint from '~/hooks/useCurrentBreakpoint';
import Pagination from '~/components/DataTables/components/Pagination';
import TableContainer from '../TableContainer';
import useAddToast from '~/hooks/useAddToast';

export type Props = {
  dataTestId?: string;
};

const PAGE_LIMIT = 50;

const AccountsTable: React.FC<Props> = ({ ...rest }) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [version] = useState<string>(uuidv1());
  const [key] = useState(uuidv1());
  const addToast = useAddToast();

  const columns = memoizedAccountListColumns(
    (sortField: AdminAccountInfoSortField, sortDirection: SortDirection) => {
      setQueryVariables(
        (prevQueryVariables: AdminGetAccountsQueryVariables) => ({
          ...prevQueryVariables,
          page: 0,
          sortBy:
            sortDirection == null
              ? null
              : {
                  field: sortField,
                  direction: sortDirection,
                },
        }),
      );
    },
    key,
    addToast,
  );

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    toggleHideAllColumns,
    rows,
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      getRowId: ({ id }) => id,
      manualSortBy: true,
      disableSortRemove: true,
      disableMultiSort: true,
      autoResetSortBy: false,
    },
    useFlexLayout,
    useSortBy,
  );

  const [queryVariables, setQueryVariables] =
    useState<AdminGetAccountsQueryVariables>({
      limit: PAGE_LIMIT,
      // On development, "query" must be an accountId so use your accountId to test this. Otherwise admin_getAccounts.data will be undefined.
      query: null,
      page: 0,
      sortBy: null,
    });
  const debouncedQueryVariables = useDebounce(queryVariables, 300);
  const { data, loading, error } = useAdminGetAccountsQuery({
    variables: debouncedQueryVariables,
  });
  useEffect(() => {
    if (!isNil(data) && !isEmpty(data) && !loading) {
      setTableData(
        memoizedAccountListData(
          data?.admin_getAccounts?.items,
          debouncedQueryVariables,
          version,
        ),
      );
    }
  }, [data, version, loading, debouncedQueryVariables, queryVariables]);

  useEffect(() => {
    if (currentBreakpoint === 'desktop' || currentBreakpoint === 'desktopBig') {
      toggleHideAllColumns(false);
    } else {
      setHiddenColumns(['status', 'issues']);
    }
  }, [setHiddenColumns, toggleHideAllColumns, currentBreakpoint]);

  const totalAmount = data?.admin_getAccounts.total;
  const queriedAmount = tableData.length;

  return (
    <Container data-testid={TEST_ID.CONTAINER} {...rest}>
      <StyledSearchInput
        onFilterChange={newQuery => {
          setQueryVariables(prevVariables => ({
            ...prevVariables,
            query: newQuery || null,
            page: 0,
          }));
        }}
        placeholder="Type the name or the id of the account"
      />

      <AmountContainer>
        Toont {queriedAmount} van{' '}
        {queryVariables.query ? queriedAmount : totalAmount}
      </AmountContainer>
      <TableContainer>
        <Header.Large>
          {headerGroups.map(headerGroup => {
            const key = headerGroup.getHeaderGroupProps().key;
            return (
              <StyledHeaderTr key={key} data-testid={TEST_ID.HEADER_ROW}>
                {headerGroup.headers.map((column, idx) => {
                  const isLastColumn = idx === headerGroup.headers.length - 1;
                  const headerCellKey = `header-${idx}`;

                  return (
                    <HeaderCell
                      key={headerCellKey}
                      column={column}
                      isLastColumn={isLastColumn}
                    />
                  );
                })}
              </StyledHeaderTr>
            );
          })}
        </Header.Large>
        <TableBody
          rowCount={tableData.length}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          page={rows}
          error={error}
          loading={loading}
          dataTestId={TEST_ID.TABLE_BODY}
        />
      </TableContainer>

      <PaginationContainer>
        <Pagination
          totalItems={queryVariables.query ? queriedAmount : totalAmount || 0}
          currentPage={Number(queryVariables.page) + 1 ?? 0}
          itemsPerPage={PAGE_LIMIT}
          onSelect={value => {
            setQueryVariables(prevVariables => ({
              ...prevVariables,
              page: value - 1,
            }));
          }}
        />
      </PaginationContainer>
    </Container>
  );
};

//  data and columns fields must be memoized according to react-table documentation.
//  We define _variables and _version in the first callback even though we don't use them directly to avoid Typescript errors
const memoizedAccountListData = memoize(
  (composedData, _variables, _version) => composedData,
  (
    items: Array<any>,
    variables: AdminGetAccountsQueryVariables,
    version: string,
  ) =>
    `${version}*@@*${items.length}*@@*${variables.query || 'null'}*@@*${
      variables.page
    }*@@*${
      variables.sortBy == null
        ? ''
        : variables.sortBy.field + variables.sortBy.direction
    }`,
);

const defaultColumn = {
  minWidth: 20,
  width: 120,
  maxWidth: 200,
};

const StyledSearchInput = styled(SearchInput)<{}>(
  ({ theme }) => css`
    display: inline-flex;
    margin-bottom: ${theme.space('l')};
    background-color: ${theme.color('white')};
    min-width: 400px;
    & input {
      min-width: 360px;
    }
  `,
);

const StyledHeaderTr = styled.div<{}>`
  white-space: nowrap;
  display: flex;
`;

const PaginationContainer = styled.div<{}>`
  display: flex;
  flex-direction: row-reverse;

  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
  `}
`;

const AmountContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
    margin-top: ${theme.space('base')};
    font-weight: bold;
  `,
);

const Container = styled.div<{}>``;

export default AccountsTable;
