import React from 'react';
import styled from 'styled-components';

import { Field } from 'react-final-form';

import Checkbox from '~/components/Inputs/Checkbox';
import { FormUtils } from '~/components';

type Props = {
  $key: string;
  title: string;
  options: Array<{ label: string; name: string }>;
};

const CheckboxInputGroup = ({ $key, title, options }: Props) => (
  <Container>
    <Subheader>{title}</Subheader>
    {options.map(item => (
      <Field key={`${$key}${item.name}-field`} name={item.name}>
        {({ input, meta: { error, touched } }) => {
          const { value, onChange } = input;
          return (
            <StyledCheckbox
              key={`${$key}${item.name}-checkbox`}
              onChange={() => onChange(!value)}
              value={value}
              label={item.label}
              name={item.name}
              error={FormUtils.showError(error, touched)}
            />
          );
        }}
      </Field>
    ))}
  </Container>
);

const Container = styled.div<{}>`
  width: 100%;

  ${({ theme }) => `
    margin: ${theme.space('xxs')} 0;
  `}
`;

const StyledCheckbox = styled(Checkbox)<{}>`
  ${({ theme }) => `
    margin: ${theme.space('s')} 0;
    font-size: ${theme.fontSize('base')};
  `}
`;

const Subheader = styled.div<{}>`
  ${({ theme }) => `
    font-size: ${theme.fontSize('m')};
    color: ${theme.color('grey')};
    margin-top: ${theme.space('xxs')};
    margin-bottom: ${theme.space('l')};
  `}
`;

export default CheckboxInputGroup;
