import type {
  FlowData___SubjectFragment,
  FlowData___FlowInstanceFragment,
  FlowData___DirectoryFragment,
} from '~/graphql/types';
import { reporter } from '~/hooks/useErrorReporter';
import { getSubject, SubjectMap } from '../getSubject';

export type DirectoryEntry = FlowData___DirectoryFragment & {
  children: { [key in string]: DirectoryEntry };
  subjects: Array<FlowData___SubjectFragment>;
  instance?: {
    subject: FlowData___SubjectFragment;
    instance: FlowData___FlowInstanceFragment;
  };
};
export type DirectoryMap = {
  children: { [key in string]: DirectoryEntry };
  subjects: Array<FlowData___SubjectFragment>;
  instance?: {
    subject: FlowData___SubjectFragment;
    instance: FlowData___FlowInstanceFragment;
  };
};

/**
 * Hydrates all directories of a subject
 */
export const hydrateDirectories = (
  directories: Array<FlowData___DirectoryFragment>,
  {
    directoryMap,
  }: {
    directoryMap: DirectoryMap;
  },
): Array<DirectoryEntry> | null => {
  const [first, ...remaining] = directories;
  let currentDir: DirectoryEntry | undefined = getDirectory(
    first.key,
    directoryMap,
  );

  if (currentDir == null) return null;

  const result: Array<DirectoryEntry> = [currentDir];
  for (const dir of remaining) {
    currentDir = getDirectory(dir.key, currentDir);
    if (currentDir == null) break;
    result.push(currentDir);
  }

  return result;
};

/**
 * Generate the subject map
 */
export const generateDirectoryMap = (
  subjects: Array<FlowData___SubjectFragment>,
  instances: Array<FlowData___FlowInstanceFragment>,
  subjectMap: SubjectMap,
): DirectoryMap => {
  const directoryMap: DirectoryMap = {
    children: {},
    subjects: [],
  };

  for (const subject of subjects) {
    const subjectDirectory = subject.dir ?? [];
    let currentDir: DirectoryMap | DirectoryEntry = directoryMap;

    for (
      let directoryIndex = 0;
      directoryIndex < subjectDirectory.length;
      ++directoryIndex
    ) {
      const dir = subjectDirectory[directoryIndex];
      const isLast = directoryIndex === subjectDirectory.length - 1;

      /** Children */
      if (currentDir.children[dir.key] == null) {
        currentDir.children[dir.key] = {
          ...dir,
          children: {},
          subjects: [],
        };
      }

      if (isLast) currentDir.children[dir.key].subjects.push(subject);

      currentDir = currentDir.children[dir.key];
    }
  }

  for (const instance of instances) {
    const instanceDirectory = instance.dir ?? [];
    let currentDir: DirectoryMap | DirectoryEntry = directoryMap;

    for (
      let directoryIndex = 0;
      directoryIndex < instanceDirectory.length;
      ++directoryIndex
    ) {
      const dir = instanceDirectory[directoryIndex];
      const isLast = directoryIndex === instanceDirectory.length - 1;

      if (currentDir.children[dir.key] == null) {
        currentDir.children[dir.key] = {
          ...dir,
          children: {},
          subjects: [],
        };
      }

      if (isLast) {
        const resolvedSubject = getSubject(instance.instanceType, subjectMap);

        if (resolvedSubject != null) {
          currentDir.children[dir.key].instance = {
            subject: resolvedSubject,
            instance,
          };
        } else {
          reporter.captureMessage(
            `Could not resolve subject: ${instance.instanceType}`,
            'critical',
          );
        }
      }

      currentDir = currentDir.children[dir.key];
    }
  }

  return directoryMap;
};

export const getDirectory = (
  key: string,
  entry: DirectoryEntry | DirectoryMap,
): DirectoryEntry | undefined => entry.children[key];

export default getDirectory;
