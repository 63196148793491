import { FlowConditionContactTag } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import {
  needsUserActionDescription,
  specificValueDescription,
} from '~/scenes/Automation/Flows/Actions/util';
import { FLOW_ACTION_TYPE } from '../../../constants';

const describeContactTagCondition = (
  condition: FlowConditionContactTag,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  const { exists, tagName } = condition;
  const { actionType } = describeOptions;

  if (tagName.value == null || tagName.value.length === 0)
    return needsUserActionDescription('Vul een tag in');

  let stateString = '';
  if (actionType === FLOW_ACTION_TYPE.START) {
    stateString = exists.value ? 'toegevoegd is' : 'verwijderd is';
  } else {
    stateString = exists.value ? 'aanwezig is' : 'afwezig is';
  }

  return ['de tag ', specificValueDescription(tagName.value), ' ', stateString];
};

export default describeContactTagCondition;
