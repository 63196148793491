import { Flow_Parameter__Event__Input } from '~/graphql/types';
import { EventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

export default (
  parameterValue: EventParameterValue,
): Flow_Parameter__Event__Input => {
  switch (parameterValue.type) {
    case PARAMETER_VALUE_TYPE.EVENT_VALUATIONREPORT_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }

      return {
        Event: {
          AppValuationReport: {
            Pointer: {
              variable: parameterValue.variable.name,
              field: parameterValue.variable.field.name,
            },
          },
        },
      };
    case PARAMETER_VALUE_TYPE.EVENT_VALUATIONREQUEST_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }

      return {
        Event: {
          AppValuationRequest: {
            Pointer: {
              variable: parameterValue.variable.name,
              field: parameterValue.variable.field.name,
            },
          },
        },
      };
    case PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_FUNDA_CONTACTREQUEST_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }
      return {
        Event: {
          Funda: {
            ContactRequest: {
              Pointer: {
                variable: parameterValue.variable.name,
                field: parameterValue.variable.field.name,
              },
            },
          },
        },
      };

    case PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_FUNDA_VIEWINGREQUEST_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }
      return {
        Event: {
          Funda: {
            ViewingRequest: {
              Pointer: {
                variable: parameterValue.variable.name,
                field: parameterValue.variable.field.name,
              },
            },
          },
        },
      };
    case PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_FUNDA_BROCHUREREQUEST_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }
      return {
        Event: {
          Funda: {
            BrochureRequest: {
              Pointer: {
                variable: parameterValue.variable.name,
                field: parameterValue.variable.field.name,
              },
            },
          },
        },
      };
    case PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_BBWAARDECHECK_REPORT_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }
      return {
        Event: {
          BBWaardecheck: {
            Report: {
              Pointer: {
                variable: parameterValue.variable.name,
                field: parameterValue.variable.field.name,
              },
            },
          },
        },
      };
    case PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_BBWAARDECHECK_APPRAISALREQUEST_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }
      return {
        Event: {
          BBWaardecheck: {
            AppraisalRequest: {
              Pointer: {
                variable: parameterValue.variable.name,
                field: parameterValue.variable.field.name,
              },
            },
          },
        },
      };
    case PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_ZAPIER_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }
      return {
        Event: {
          Zapier: {
            Pointer: {
              variable: parameterValue.variable.name,
              field: parameterValue.variable.field.name,
            },
          },
        },
      };
    case PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_VBOWAARDECHECK_REPORT_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }
      return {
        Event: {
          VBOWaardecheck: {
            Report: {
              Pointer: {
                variable: parameterValue.variable.name,
                field: parameterValue.variable.field.name,
              },
            },
          },
        },
      };

    case PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_VBOWAARDECHECK_APPRAISALREQUEST_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }
      return {
        Event: {
          VBOWaardecheck: {
            AppraisalRequest: {
              Pointer: {
                variable: parameterValue.variable.name,
                field: parameterValue.variable.field.name,
              },
            },
          },
        },
      };

    case PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_HYPOTHEEKBOND: {
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `${cleanedFilename(
            __filename,
          )} >> Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }
      return {
        Event: {
          Hypotheekbond: {
            Pointer: {
              variable: parameterValue.variable.name,
              field: parameterValue.variable.field.name,
            },
          },
        },
      };
    }
    default:
      assertNever(parameterValue, cleanedFilename(__filename));
      throw new Error(
        `Field value input conversion cannot handle (${JSON.stringify(
          parameterValue,
          null,
          2,
        )})`,
      );
  }
};
