import React from 'react';
import uuidv1 from 'uuid/v1';

import { ValidationSubscriber } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { ValidationSubscriberActions } from './BaseActionContext';

import FlowContext from '~/scenes/Automation/Flows/FlowContext';

type State = {};
export type WithValidatorListProps = ValidationSubscriberActions & {
  validators: Array<
    ValidationSubscriber & {
      key: string;
    }
  >;
  validate: () => boolean;
  showValidation: boolean;
};

const withValidatorList = <ComponentProps extends $Object>(
  WrappedComponent: React.ComponentType<ComponentProps>,
): React.ComponentType<ComponentProps> =>
  class WithValidatorList extends React.Component<ComponentProps, State> {
    validators: Array<
      ValidationSubscriber & {
        key: string;
      }
    > = [];

    addValidator = (newValidator: ValidationSubscriber): string => {
      const newId = uuidv1();

      this.validators = [...this.validators, { ...newValidator, key: newId }];

      return newId;
    };

    removeValidator = (toRemoveKey: string) => {
      this.validators = this.validators.filter(
        validator => validator.key !== toRemoveKey,
      );
    };

    validate = (): boolean => {
      const anyFails = this.validators.some(validator => !validator.validate());

      return !anyFails;
    };

    render() {
      return (
        <FlowContext.Consumer>
          {({ showValidation }) => (
            <WrappedComponent
              {...this.props}
              validators={this.validators}
              showValidation={showValidation}
              subscribeValidator={this.addValidator}
              unsubscribeValidator={this.removeValidator}
              validate={this.validate}
            />
          )}
        </FlowContext.Consumer>
      );
    }
  };

export default withValidatorList;
