import React from 'react';
import { Handle, Position } from 'reactflow';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';

import getCardHeading from '../../../../utils/getCardHeading';
import ConditionList from '../ConditionList';
import { ClientFlowActionStart } from '~/graphql/types.client';
import { FlowAction } from '~/graphql/types';

export type Props = Omit<
  CardProps<ClientFlowActionStart>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const text = {
  emptyTriggerHeading: 'Stel je nieuwe trigger in',
};

const TriggerCard: React.FC<Props> = React.memo(
  ({ selected, disabled, ...props }) => (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={handleStyle}
        isConnectable={false}
      />
      <CardTemplate
        {...props}
        actionType={FlowAction.Start}
        heading={
          props.data.action.triggers.length !== 0
            ? getCardHeading(FlowAction.Start)
            : text.emptyTriggerHeading
        }
        selected={selected}
        disabled={disabled}
      >
        {props.data.action.triggers.length !== 0 && (
          <ConditionList
            triggers={props.data.action.triggers}
            actionId={props.data.action.id}
          />
        )}
      </CardTemplate>
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        isConnectable={false}
      />
    </>
  ),
);

export default TriggerCard;
