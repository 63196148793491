import gql from 'graphql-tag';

export default gql`
  mutation InviteUsersToOffice(
    $users: [String!]!
    $accountId: ID!
    $officeId: ID!
  ) {
    inviteUsersToOffice(
      users: $users
      accountId: $accountId
      officeId: $officeId
    ) {
      user
      error
      position
    }
  }
`;
