import { Flow___ArgumentFragment } from '~/graphql/types';
import {
  convertServerDateStringToDate,
  safeFormattedDateTime,
} from '~/util/date';

/**
 * Returns Dutch text or the string as it is
 *
 * @param {Flow___ArgumentFragment} argument - Flow argument
 * keywords: Flow argument as (translated) string
 */
const getArgumentValueAsText = (argument: Flow___ArgumentFragment): string => {
  switch (argument.__typename) {
    case 'Flow___Argument_Pointer':
      // TODO: Check if this is OK 😅 probably not
      return argument.pointer.path.join(' > ');
    case 'Flow___Argument_AWSDateTime':
      // TODO: Not sure if this is the correct format
      return safeFormattedDateTime(
        convertServerDateStringToDate(argument.value_date),
      );
    case 'Flow___Argument_Boolean':
      return argument.value_boolean === true ? 'Waar' : 'Niet waar';
    case 'Flow___Argument_Float':
      return `${argument.value_float}`;
    case 'Flow___Argument_Integer':
      return `${argument.value_integer}`;
    case 'Flow___Argument_String':
      return argument.value_string ?? '';

    default:
      return '';
  }
};
export default getArgumentValueAsText;
