import React from 'react';

import DatePicker, {
  DateInputComponentType,
} from '~/components/Inputs/DatePicker';
import Field from './Field';
import FloatingLabel from '~/components/FloatingLabel';
import { DayPickerInputProps } from 'react-day-picker';

/**
 * In the ecosystem of the forms we handle date as an ISO string.
 * This is also what is sent back to the server.
 */
type DateFieldProps = {
  name: string;
  value: string;
  onChange: (newDate: string) => void;
  inputComponentType?: DateInputComponentType;
  error?: string;
  small?: boolean;
  large?: boolean;
  label?: string;
  inputComponentProps?: DayPickerInputProps['inputProps'];
};
export default ({ error, small, large, label, ...props }: DateFieldProps) => (
  <div>
    {(error || label) && (
      <FloatingLabel
        actAsPlaceholder={false}
        error={error}
        small={small}
        large={large}
      >
        {error || label || ''}
      </FloatingLabel>
    )}
    <Field {...props} component={DateFieldComponent} />
  </div>
);

const DateFieldComponent = ({
  input: { name, value, onChange },
  ...rest
}: any) => (
  <DatePicker
    name={name}
    size={10}
    value={value}
    onChange={onChange}
    {...rest}
  />
);
