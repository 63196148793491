import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  x?: boolean;
  y?: boolean;
  style?: any;
  children: JSX.Element | Array<JSX.Element>;
};

const OverflowScrollWrapper = ({ y = false, x = false, ...props }: Props) => (
  <Wrapper
    {...props}
    style={{
      overflowX: x ? 'scroll' : 'auto',
      overflowY: y ? 'scroll' : 'auto',
      ...props.style,
    }}
  />
);

export const scrollBarStyles = css`
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(14 68 99);
    outline: none;
  }
`;

const Wrapper = styled.div<{}>`
  overflow: scroll;
  ${scrollBarStyles}
`;

export default OverflowScrollWrapper;
