import { createPortal } from 'react-dom';
import usePortal from '~/hooks/usePortal';

type Props = {
  root?: string;
  key?: string;
};

const Portal: React.FC<Props> = ({ children, root = 'modal-portal' }) => {
  const target = usePortal(root);
  //We provide root as a key for createPortal
  return createPortal(children, target, root);
};

export default Portal;
