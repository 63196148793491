import React from 'react';
import useErrorReporter from '~/hooks/useErrorReporter';
import FlowActionCreateTask from '../CreateTask';
import ZapierTrigger from '../ZapierTrigger';
import AssignContact from '../AssignContact';
import ContactTagAction from '../ContactTagAction';
import SendEmailPlain from '../SendEmailPlain';
import IfElseAction from '../IfElseAction';
import WaitAction from '../WaitAction';
import StartAction from '../StartAction';
import ContactDetails from '../ContactDetails';
import RealworksSendContact from '../RealworksSendContact';
import { ClientFlowAction } from '~/graphql/types.client';

export type Props = {
  action: ClientFlowAction;
  onChange: (changes: ClientFlowAction) => void;
};

const ActionForm: React.FC<Props> = ({ action, ...rest }) => {
  const reporter = useErrorReporter();

  // Any common dependencies can be calculated/got here.
  switch (action.__typename) {
    case 'FlowV2_Action_Start':
      return <StartAction action={action} {...rest} />;
    case 'FlowV2_Action_IfElse':
      return <IfElseAction action={action} {...rest} />;
    case 'FlowV2_Action_Wait':
      return <WaitAction action={action} {...rest} />;

    case 'FlowV2_Action_Realworks_SendContact':
      return <RealworksSendContact action={action} {...rest} />;
    case 'FlowV2_Action_SendEmail_Plain':
      return <SendEmailPlain action={action} {...rest} />;
    case 'FlowV2_Action_Zapier_Trigger':
      return <ZapierTrigger action={action} {...rest} />;
    case 'FlowV2_Action_Task_Create':
      return <FlowActionCreateTask action={action} {...rest} />;
    case 'FlowV2_Action_Contact_Details':
      return <ContactDetails action={action} {...rest} />;
    case 'FlowV2_Action_Contact_AddTag':
      return <ContactTagAction action={action} {...rest} />;
    case 'FlowV2_Action_Contact_DeleteTag':
      return <ContactTagAction action={action} {...rest} />;
    case 'FlowV2_Action_Contact_Assign':
      return <AssignContact action={action} {...rest} />;
    default:
      reporter.captureMessage(
        // @ts-expect-error
        `Unsupported type ${action.__typename} for action in ActionForm`,
        'critical',
      );
      // We don't have a UI for the type
      return (
        <>
          <h1>
            There is no form the the action with typename &quot;
            {
              // @ts-expect-error
              action.__typename
            }
            &quot;
          </h1>
          <p>
            To add action forms go to
            <pre>
              src/scenes/Automation/v2/components/UpdateAction/components/ActionForm/index.tsx
            </pre>
          </p>
        </>
      );
  }
};

export default ActionForm;
