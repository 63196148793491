import { AppType } from '~/graphql/types';
import { AppConfig } from '../types';

const description = `
## Omschrijving
Enven is een onderscheidend, bouwtechnisch adviesbureau, BRL 9500 gecertificeerd voor het leveren van energielabels, verduurzamingsadviezen en aanverwante rapportages. Enven levert haar diensten door heel Nederland.

Door deze app te activeren worden vier diensten in je widget toegevoegd: Duurzaamheidsadvies, Energielabel, WWS puntentelling rapport en Woonoppervlakte (NEN 2580). Deze diensten kunnen direct besteld en ingepland worden bij de adviseur via jouw widget. Vervolgens verdien jij een aantrekkelijke commissie van €25,- exclusief btw per aangebrachte opdracht. Commissies worden automatisch maandelijks gecrediteerd op jouw DatHuis factuur.

## Installatie
Nadat je de app hebt geactiveerd, worden de vier diensten automatisch getoond in de widget op je website. Dit kan worden aangepast in de widget instellingen.`;

const termsOfUse =
  'Door het activeren van deze app geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van Enven. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Op de dienstverlening van Enven zijn de gebruiksvoorwaarden van Enven van toepassing. Meer informatie vind je op [ https://www.enven.nl/]( https://www.enven.nl/)';

export const enven: AppConfig = {
  __typename: 'AppStatus_Enven',
  appType: AppType.Enven,
  type: 'activate_only',
  slug: 'enven',
  name: 'Energielabel & Duurzaamheidsadvies',
  tags: ['Leadgeneratie', 'E-mailcampagne', 'Commissie ontvangen'],
  provider: 'DatHuis B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/enven.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/enven.svg',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/7041435-over-de-energielabel-duurzaamheidsadvies-app',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'default',
};
