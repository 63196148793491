import React from 'react';
import styled from 'styled-components';

import { H5 } from '~/components/Typography';
import NoResultSection from '../NoResultSection';

type Props = {
  /** The list of strings to show */
  stepsList: Array<string>;

  /** Message to show if list is empty */
  emptyListText: string;

  /** Headline of list to show */
  title?: string;
};

const NumeralList = ({ stepsList, emptyListText, title }: Props) => (
  <div>
    {stepsList.length > 0 ? (
      <React.Fragment>
        {title && <ListHeadH5>{title}</ListHeadH5>}
        <NumeralListElement>
          {stepsList.map((step, idx) => (
            <li key={`step-${idx}`}> {step}</li>
          ))}
        </NumeralListElement>
      </React.Fragment>
    ) : (
      <NoResultSection>{emptyListText}</NoResultSection>
    )}
  </div>
);

const NumeralListElement = styled.ol<{}>`
  ${({ theme }) => `
    padding-left: ${theme.space('m')};
    & > li {
      padding-left: ${theme.space('s')};
      color: ${theme.color('text')};
    }
  `};
`;
const ListHeadH5 = styled(H5)<{}>`
  font-weight: bold;
`;

export default NumeralList;
