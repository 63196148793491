import React from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './FlowInstanceList.testid';
import { FlowInstanceStatus, FlowV2InstanceStatus } from '~/graphql/types';
import TextButton from '~/components/TextButton';
import { Body } from '~/components/Typography/index';
import JustificationContainer from '~/components/JustificationContainer';
export type FlowInstance = {
  id: string;
  status: FlowInstanceStatus | FlowV2InstanceStatus;
  name: string;
};

type Props = {
  /** List of flow instances */
  value: Array<FlowInstance>;
  onClick: (value: FlowInstance) => void;
};

const FlowInstanceList = ({ value, onClick }: Props) => {
  const flowInstance = value.map((item, index) => (
    <FlowInstanceContainer
      align="center"
      justification="space-between"
      onClick={() => onClick(item)}
      key={item.id}
      margin={['xxs', null, null, null]}
    >
      <JustificationContainer>
        <FlowInstanceNumber size="base" margin={[null, 'xxs', null, null]}>
          {index + 1}.
        </FlowInstanceNumber>
        <Body size="base" margin={[null, 'xxs', null, null]}>
          {item.name}
        </Body>
      </JustificationContainer>
      <DeleteButton
        withoutPadding
        icon="trashcan"
        appearance="danger"
        size="small"
      />
    </FlowInstanceContainer>
  ));

  return (
    <FlowInstanceListContainer data-testid={TEST_ID.FLOW_INSTANCE_LIST}>
      {flowInstance}
    </FlowInstanceListContainer>
  );
};

const DeleteButton = styled(TextButton)`
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }
`;

const FlowInstanceContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    transition: transform 0.3s;

    &:hover {
      cursor: pointer;

      p {
        color: ${theme.color('primary')};
      }
    }
  `,
);

const FlowInstanceNumber = styled(Body)(
  ({ theme }) => css`
    /* In order to align numbers and text we set a fixed width */
    width: ${theme.space('base')};
  `,
);

const FlowInstanceListContainer = styled.div<{}>`
  width: 100%;
`;

export default FlowInstanceList;
