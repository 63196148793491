import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { ActionTreeNode } from './types.flow';

import { asVariableStashItem } from '~/scenes/Automation/Flows/util/variableHelpers';
import getActionLabel from '~/scenes/Automation/Flows/util/getActionLabel';
import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { ApolloClient } from '@apollo/client';

const pushVariablesDown = async (
  actionNode: ActionTreeNode,
  stash: FlowVariableStash,
  client: ApolloClient<object>,
  accountId: string,
  setOutputLoading: (loading: boolean, actionId: string) => void,
) => {
  const { subscriber, children } = actionNode;
  const stashToSendToChild = { ...stash };

  if (subscriber) {
    const actionProp = subscriber.getActionProp();

    const output = await asVariableStashItem(
      getActionLabel(actionNode.positionString),
      actionProp,
      client,
      accountId,
      setOutputLoading,
    );

    let stashToSendToAction = { ...stashToSendToChild };

    if (output != null) {
      stashToSendToChild[output.variableName] = output;
    }

    // You can reference your own variables in the start action, so change the stash to include own output
    if (actionProp.type === FLOW_ACTION_TYPE.START) {
      stashToSendToAction = { ...stashToSendToChild };
    }

    subscriber.updateVariableStash(stashToSendToAction);
  }

  for (const child of children) {
    await pushVariablesDown(
      child,
      stashToSendToChild,
      client,
      accountId,
      setOutputLoading,
    );
  }
};

export default pushVariablesDown;
