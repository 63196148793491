import { Flow_Condition__Input } from '~/graphql/types';

const asFlowConditionAppBBWaardecheckAppraisalRequestNewInput =
  (): Flow_Condition__Input => ({
    EventContactApp: {
      BBWaardecheck: {
        AppraisalRequest: {
          New: {
            noOperation: true,
          },
        },
      },
    },
  });

export default asFlowConditionAppBBWaardecheckAppraisalRequestNewInput;
