import gql from 'graphql-tag';

export default gql`
  mutation UpdateAccount($accountId: ID!, $update: Account_Update!) {
    updateAccount(accountId: $accountId, update: $update) {
      id
      name
    }
  }
`;
