import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionRealworksSendContact } from '~/graphql/types.client';
import removeTypenames from '../../../removeTypenames';

/**
 *
 * @param {ClientFlowActionRealworksSendContact} action - RealworksSendContact client action
 * keywords:
 */
const serializeRealworksSendContactAction = (
  action: ClientFlowActionRealworksSendContact,
): FlowV2_Action__Input => {
  const {
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    description,
    tokenContainerId,
  } = action;

  return {
    Realworks: {
      SendContact: {
        id,
        accountId,
        flowBlueprintId,
        parentIds,
        description: removeTypenames(description),
        tokenContainerId,
      },
    },
  };
};
export default serializeRealworksSendContactAction;
