import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import { scrollBarStyles } from '~/components/OverflowScrollWrapper';
const ListContainer = styled(animated.ul)(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
    margin: 0;
    list-style: none;
    overflow-y: scroll;

    ${scrollBarStyles}
  `,
);

export default ListContainer;
