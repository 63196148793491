import styled, { css } from 'styled-components';

export const TitleContainer = styled.div<{}>`
  order: 2;
  width: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    ${theme.mq.greaterThan('tabletLandscape')`
      width: 540px;
      order: 1;
    `}
  `};
`;
