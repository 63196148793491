import React from 'react';
import styled from 'styled-components';

import { FormApi } from 'final-form';
import { InsertOfficeMutationVariables } from '~/graphql/types';

import { ApolloError } from '@apollo/client';

import { Field, NumberField, Form, PostalCodeField } from '~/components/Forms';
import { FormUtils } from '~/components';
import { Input, InputGroup } from '~/components/Inputs';
import Button from '~/components/Button';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';
import Disclaimer from '~/components/Disclaimer';
import cleanedFilename from '~/util/cleanedFilename';

export type FormData = {
  name: string | null;
  street: string | null;
  houseNumber: string | null;
  addition: string | null;
  postcode: string | null;
  city: string | null;
};
type Props = {
  accountId: string;
  loading: boolean;
  onSubmit: (variables: InsertOfficeMutationVariables) => Promise<any>;
  insertionError?: ApolloError;
};
const text = {
  btnTitle: 'Toevoegen',
  noName: Catalog.noName,
  noAddress: Catalog.noAddress,
  noPostalCode: Catalog.noPostalCode,
  noCity: Catalog.noCity,
  noHouseNumber: Catalog.noHouseNumber,
  postcodeValidation: Catalog.invalidPostalCode,
  officeInsertError: Catalog.genericUnknownErrorMessage,
  disclaimer: Catalog.mayIncreaseCostsWarning('Vestigingen'),
};
export default ({ accountId, loading, onSubmit, insertionError }: Props) => (
  <Form
    onSubmit={async (formData: FormData, form: FormApi<FormData>) => {
      await onSubmit(
        convertFieldsToMutationVariablesObject(accountId, formData),
      );
      const fieldNames = Object.keys(formData) as Array<keyof typeof formData>;

      fieldNames.forEach(name => {
        form.change(name, null);
        form.resetFieldState(name);
      });
    }}
    validate={validate}
    initialValues={{
      name: null,
      street: null,
      houseNumber: null,
      addition: null,
      postcode: null,
      city: null,
    }}
  >
    {({ handleSubmit, submitError, submitting, pristine }) => {
      const addOfficeError =
        insertionError || submitError ? text.officeInsertError : false;
      return (
        <>
          {addOfficeError && (
            <ErrorMessage data-testid="add-office-error-message">
              {addOfficeError}
            </ErrorMessage>
          )}
          <form data-testid="new-office-form" onSubmit={handleSubmit}>
            <InputGroup>
              <Field name="name">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label="Naam"
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    disabled={submitting}
                    {...input}
                  />
                )}
              </Field>
            </InputGroup>
            <InputGroup>
              <Field name="street">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label="Adres"
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    disabled={submitting}
                    {...input}
                  />
                )}
              </Field>
            </InputGroup>
            <InputGroup>
              <NumberField name="houseNumber">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label="Huisnummer"
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    disabled={submitting}
                    {...input}
                  />
                )}
              </NumberField>
              <Field name="addition">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label="Toevoeging"
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    disabled={submitting}
                    {...input}
                  />
                )}
              </Field>
            </InputGroup>
            <InputGroup>
              <PostalCodeField name="postcode">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label="Postcode"
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    disabled={submitting}
                    {...input}
                  />
                )}
              </PostalCodeField>
              <Field name="city">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label="Plaatsnaam"
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    disabled={submitting}
                    {...input}
                  />
                )}
              </Field>
            </InputGroup>
            <ActionWrapper>
              <Button
                size="medium"
                loading={loading}
                appearance="secondary"
                type="submit"
                disabled={submitting || pristine}
                data-testid="save-new-office-btn"
                label={text.btnTitle}
              />
            </ActionWrapper>
            <Disclaimer>{text.disclaimer}</Disclaimer>
          </form>
        </>
      );
    }}
  </Form>
);

const ActionWrapper = styled.div<{}>`
  display: flex;
  align-items: center;

  & button {
    margin-left: auto;
  }
`;

const ErrorMessage = styled.div<{}>`
  ${({ theme }) => `
    color: ${theme.color('danger')};
  `};
`;

export const convertFieldsToMutationVariablesObject = (
  accountId: string,
  fields: FormData,
): InsertOfficeMutationVariables => {
  const { name, street, postcode, city, houseNumber, addition } = fields;
  const houseNumberInt = houseNumber == null ? null : parseInt(houseNumber);

  if (
    name == null ||
    street == null ||
    postcode == null ||
    city == null ||
    houseNumberInt == null
  ) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | name (${name}) == null || street (${street}) == null || postcode (${postcode}) == null || city (${city}) == null || houseNumberInt (${houseNumberInt}) == null`,
    );
  }

  return {
    accountId: accountId,
    office: {
      name: name,
      address: {
        street: street,
        postcode: postcode,
        city: city,
        houseNumber: houseNumberInt,
        addition: addition,
      },
    },
  };
};

export const validate = (fields: FormData) => {
  const errors: {
    name: string | undefined;
    street: string | undefined;
    houseNumber: string | undefined;
    addition: string | undefined;
    postcode: string | undefined;
    city: string | undefined;
  } = {
    name: undefined,
    street: undefined,
    houseNumber: undefined,
    addition: undefined,
    postcode: undefined,
    city: undefined,
  };

  if (Validation.String.isEmpty(fields['name'])) {
    errors.name = text.noName;
  }

  if (Validation.String.isEmpty(fields['street'])) {
    errors.street = text.noAddress;
  }

  if (Validation.String.isEmpty(fields['postcode'])) {
    errors.postcode = text.noPostalCode;
  }

  if (Validation.String.isEmpty(fields['city'])) {
    errors.city = text.noCity;
  }

  if (Validation.String.isEmpty(fields['houseNumber'])) {
    errors.houseNumber = text.noHouseNumber;
  }

  if (
    Validation.String.isNonEmptyString(fields['postcode']) &&
    !Validation.Postcode.isValid(fields['postcode'])
  ) {
    errors.postcode = text.postcodeValidation;
  }
  return errors;
};
