import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionIfElse } from '~/graphql/types.client';
import getConditionListInputForTrigger from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/utils/getConditionListInputForTrigger';
import removeTypenames from '../../../removeTypenames';

/**
 *
 * @param {ClientFlowActionIfElse} action - IfElse client action
 * keywords:
 */
const serializeIfElseAction = (
  action: ClientFlowActionIfElse,
): FlowV2_Action__Input => {
  const {
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    trueChildId,
    falseChildId,
    triggers,
    connector,
  } = action;

  const convertedCondition = getConditionListInputForTrigger(
    triggers,
    connector,
  );

  return {
    IfElse: {
      id,
      accountId,
      flowBlueprintId,
      parentIds,
      trueChildId,
      falseChildId,
      condition:
        'result' in convertedCondition
          ? removeTypenames(convertedCondition.result)
          : {
              exp: '(0)',
              conditions: [],
            },
    },
  };
};

export default serializeIfElseAction;
