import React, { Component } from 'react';

import MetaTags from 'react-meta-tags';
import { FORM_ERROR } from 'final-form';
import styled from 'styled-components';
import { Form, EmailField, PasswordField } from '~/components/Forms';
import Amplify from '~/amplify';
import Validation from '~/util/Validation';
import AuthWrapperHOC from '~/scenes/Auth/components/AuthWrapperHOC';
import { AuthContext } from '~/root/Auth';
import AuthNav from '~/scenes/Auth/components/AuthNav';
import { navigate, PageProps } from 'gatsby';
import { FormUtils } from '~/components';
import Button from '~/components/Button';
import { Input, InputGroup } from '~/components/Inputs';
import { H1 } from '~/components/Typography';
import { Link } from '~/components/Link';

import { text as verifyText } from './verify';
import {
  ActionWrapper,
  OtherOptionsLink,
  AuthForm,
  ErrorMsg,
  InfoMsg,
} from '~/scenes/Auth/Auth.style';
import { isString } from 'lodash';
import Catalog from '~/Catalog';
import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';
import { fireTrackingEvent } from '~/hooks/useFireTrackingEvent';
import { TAPFILLIATE_REF, setLocalStorageItem } from '~/util/localStorageKeys';

type FormData = {
  password: string;
  email: string;
};

type Props = PageProps<
  any,
  any,
  { from?: string; email?: string; message?: string }
>;

type State = {
  infoMsg: string;
  initialCredentials: {
    email: string | null | undefined;
    password: string | null | undefined;
  };
};

const text = {
  title: 'Inloggen',
  noEmail: Catalog.noEmail,
  invalidEmail: Catalog.invalidEmail,
  noPassword: Catalog.noPassword,
  userNotConfirmed: 'Dit e-mailadres is nog niet geverifiëerd.',
  emailFieldLabel: Catalog.emailLabel,
  passwordFieldLabel: 'Wachtwoord',
  forgotPassword: 'Wachtwoord vergeten?',
  loginButton: 'Inloggen',
  noAccount: 'Nog geen account?',
  registerHere: 'Registreer je hier',
  loginFailMessage:
    'De combinatie van e-mailadres en wachtwoord is niet geldig.',
  unknownLoginError: Catalog.genericUnknownErrorMessage,
};

class Login extends Component<Props, State> {
  state = {
    infoMsg: '',
    initialCredentials: {
      email: null,
      password: null,
    },
  };

  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const tapfillateRef = params.get('ref');

    if (tapfillateRef) {
      setLocalStorageItem(
        TAPFILLIATE_REF,
        JSON.stringify({ date: Date.now(), ref: tapfillateRef }),
      );
    }

    if (location.state) {
      // email just got verified and we landed on login again. Send event
      if (location.state.message === verifyText.successMessage) {
        fireTrackingEvent({
          event: 'register',
          message: location.state.message,
        });
      }

      this.setState({
        infoMsg: location.state.message || '',
        initialCredentials: {
          email: location.state.email || null,
          password: null,
        },
      });
    }

    const { email, password } = getParamsFromMagicLink();
    if (email) this.proceedMagicLinkLogin(email, password);
  }

  proceedMagicLinkLogin = (
    email: string,
    password: string | null | undefined,
  ) => {
    this.setState({
      initialCredentials: {
        email,
        password: password ? password : null,
      },
    });

    if (password) {
      this.autoSubmitForm();
    }
  };

  autoSubmitForm = () => {
    setTimeout(() => {
      if (document != null && document.getElementById !== null) {
        // @ts-ignore
        const element = document.getElementById('signin-form');

        if (element != null) {
          element.dispatchEvent(new Event('submit', { cancelable: true }));
        }
      }
    }, 0);
  };

  validate = ({ email, password }: FormData) => {
    const errors: {
      email: string | undefined;
      password: string | undefined;
    } = {
      email: undefined,
      password: undefined,
    };

    if (Validation.String.isEmpty(email)) {
      errors.email = text.noEmail;
    } else if (!Validation.Email.isValid(email)) {
      errors.email = text.invalidEmail;
    }

    const noPassword = isString(password) && password.length > 0;
    if (!noPassword) {
      errors.password = text.noPassword;
    }

    return errors;
  };

  login = () => {
    this.setState({ infoMsg: '' });
  };

  render() {
    const { infoMsg, initialCredentials } = this.state;
    const title = text.title;

    return (
      <AuthWrapperHOC>
        <MetaTags>
          <title>{title}</title>
          <meta
            name="description"
            content="Inloggen op het portaal van DatHuis"
          />
        </MetaTags>

        <AuthNav selectedIdx={0} location={this.props.location} />

        <AuthForm>
          <H1>{title}</H1>

          <AuthContext.Consumer>
            {({ ensureLogin, setTmpUser }) => {
              const onFormSubmit = ({
                email,
                password,
              }: {
                email: string;
                password: string;
              }) => {
                const loweredEmail = email.toLowerCase();

                return Amplify.Auth.signIn(loweredEmail, password)
                  .then(user => {
                    /**
                     * We might get a challenge from cognito that a new
                     * password is required. This means this user has
                     * been invited. Setup their user details.
                     *
                     * I do not think that we should do this in a seperate route.
                     */
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                      setTmpUser(user);
                      return navigate('/setup-user-details', {
                        state: { email },
                      });
                    }

                    ensureLogin();

                    // When the submit button gets clicked and there is no password challenge
                    // we should fire an event to GTM
                    fireTrackingEvent({
                      event: 'login',
                      loginMethod: 'email',
                    });
                    return;
                  })
                  .catch(err => {
                    if (
                      err.__type === 'UserNotConfirmedException' ||
                      err.code === 'UserNotConfirmedException'
                    ) {
                      void navigate('/verify', { state: { email } });
                      return {
                        [FORM_ERROR]: text.userNotConfirmed,
                      };
                    } else {
                      return authErrorMsg(err);
                    }
                  });
              };

              return (
                <Form
                  initialValues={initialCredentials}
                  onSubmit={onFormSubmit}
                  validate={this.validate}
                >
                  {({ handleSubmit, submitError, submitting, pristine }) => {
                    const submitDisabled =
                      (pristine && // make it available for automagical link submit ->
                        !initialCredentials.email &&
                        !initialCredentials.password) ||
                      submitting;

                    return (
                      <form
                        id="signin-form"
                        onSubmit={handleSubmit}
                        data-testid="sign-in-form"
                      >
                        {submitError ? (
                          <ErrorMsg data-testid="sign-in-error-message">
                            {submitError}
                          </ErrorMsg>
                        ) : null}
                        {infoMsg && (
                          <InfoMsg data-testid="sign-in-info-message">
                            {infoMsg}
                          </InfoMsg>
                        )}

                        <InputGroup>
                          <EmailField name="email">
                            {({ input, meta: { error, touched } }) => (
                              <Input
                                large
                                label={text.emailFieldLabel}
                                error={FormUtils.showError(error, touched)}
                                disabled={submitting}
                                {...input}
                                autoFocus
                                autoComplete="username"
                              />
                            )}
                          </EmailField>
                        </InputGroup>
                        <InputGroup>
                          <PasswordField name="password">
                            {({ input, meta: { error, touched } }) => (
                              <Input
                                large
                                label={text.passwordFieldLabel}
                                type="password"
                                autoComplete="current-password"
                                error={FormUtils.showError(error, touched)}
                                disabled={submitting}
                                {...input}
                              />
                            )}
                          </PasswordField>
                        </InputGroup>
                        <ActionWrapper>
                          <ForgotPasswordLink
                            to={'/forgot-password'}
                            dataTestid="forgot-password-link"
                          >
                            {text.forgotPassword}
                          </ForgotPasswordLink>
                          <Button
                            onClick={this.login}
                            appearance="secondary"
                            size="medium"
                            type="submit"
                            disabled={submitDisabled || submitting}
                            dataTestid="login-button"
                            loading={submitting}
                            label={text.loginButton}
                          />
                        </ActionWrapper>

                        <OtherOptionsLink>
                          {text.noAccount}{' '}
                          <Link
                            to={`/register/${location.search}`}
                            dataTestid="register-link"
                          >
                            {text.registerHere}
                          </Link>
                        </OtherOptionsLink>
                      </form>
                    );
                  }}
                </Form>
              );
            }}
          </AuthContext.Consumer>
        </AuthForm>
      </AuthWrapperHOC>
    );
  }
}

const ForgotPasswordLink = styled(Link)<{}>`
  justify-self: flex-start;
  flex: 1;
`;

const authErrorMsg = (err: { __type: string; code: string }) => {
  if (
    err.__type === 'UserNotFoundException' ||
    err.__type === 'NotAuthorizedException' ||
    err.code === 'UserNotFoundException' ||
    err.code === 'NotAuthorizedException'
  ) {
    return { [FORM_ERROR]: text.loginFailMessage };
  }

  return {
    [FORM_ERROR]: text.unknownLoginError,
  };
};

const getParamsFromMagicLink = () => {
  const url = window.location.href;

  let emailMatch = url.match(new RegExp(/email=.*(?=&password=)/, 'g'));
  const passwordMatch = url.match(new RegExp(/password=.*/, 'g'));

  if (!passwordMatch) {
    emailMatch = url.match(new RegExp(/email=.*$/, 'g'));
  }

  let email = emailMatch ? emailMatch[0].slice(6) : null;
  let password = passwordMatch ? passwordMatch[0].slice(9) : null;

  // decode if possible, don't break if malformed
  if (email != null) {
    try {
      email = decodeURIComponent(email);
    } catch (e) {
      // send us a message but just null out the email
      reportErrorToTracker(
        `${cleanedFilename(__filename)} | Could not decode email ${email}`,
      );

      email = null;
    }
  }

  // decode if possible, don't break if malformed
  if (password != null) {
    try {
      password = decodeURIComponent(password);
    } catch (e) {
      // send us a message but just null out the password
      reportErrorToTracker(
        `${cleanedFilename(
          __filename,
        )} | Could not decode password ${password}`,
      );

      password = null;
    }
  }

  return {
    email,
    password,
  };
};

export default Login;
