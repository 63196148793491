import React from 'react';
import uuidv1 from 'uuid/v1';
import { debounce } from 'lodash';
import styled from 'styled-components';

import { ApolloClient, ApolloConsumer } from '@apollo/client';
import { GetContactsQuery, ConnectorOperator } from '~/graphql/types';
import { WithAccountContext } from '~/contexts/AccountContext';
import { OptionOf, Option } from '~/components/Inputs/Dropdown';
import { NamedEmail } from '~/util/namedEmailFormatter';

import GetContacts from '~/graphql/query/GetContacts';
import { withAccountContext } from '~/contexts/AccountContext';
import Validation from '~/util/Validation';
import { sortByAlphabet } from '~/util/string';
import Catalog from '~/Catalog';
import TagField from './components/TagField';
import namedEmailFormatter from '~/util/namedEmailFormatter';
import { reportErrorToTracker } from '~/util/assertion';

import TEST_ID from './EmailSelectInput.testid';
import asNodeInput from '~/components/Filters/asNodeInput';
import { getUserName } from '~/graphql/utils';
import DropdownListContainer from '~/components/Dropdown/components/DropdownListContainer';
import Input from '~/components/Input';

const noFoundEmailOption = [
  {
    label: Catalog.noResults,
    key: Catalog.noResults,
    payload: {
      email: Catalog.noResults,
    },
  },
];
type EmailOption = OptionOf<NamedEmail>;
export type EmailItemType = {
  label: string;
  namedEmail: NamedEmail;
  isValid: boolean;
  key: string;
};
type State = {
  typedValue: string;
  listToShowInDropdown: Array<EmailOption>;
  showList: boolean;
};
type MyProps = {
  /** Renders a small component */
  small?: boolean;

  /** Renders a large component */
  large?: boolean;

  /** The selected values */
  selectedValues: Array<EmailItemType>;
  onSelected: (value: EmailItemType) => void;
  onRemoved: (value: EmailItemType) => void;
  showName?: boolean;
  disabled?: boolean;
};
type Props = WithAccountContext & MyProps;

type DropdownHandler = {
  selectedOptionIdx: number;
};

class EmailSelectInput extends React.Component<Props, State> {
  inputRef: any = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      typedValue: '',
      showList: false,
      listToShowInDropdown: [],
    };
  }

  callQueryFunction = debounce(
    (searchedValue: string, client: ApolloClient<GetContactsQuery>) => {
      const { accountContext } = this.props;
      const { account } = accountContext;
      const accountId = account ? account.id : '';

      client
        .query<GetContactsQuery>({
          query: GetContacts,
          variables: {
            nodes: asNodeInput({
              operator: ConnectorOperator.And,
              filters: [],
            }),
            accountId,
            query: searchedValue,
          },
        })
        .then(({ data }) => {
          const dataFromContext = this.getUsersList(searchedValue);
          this.onNewData(data, dataFromContext);
        })
        .catch(err => {
          reportErrorToTracker(err);

          this.setState({
            listToShowInDropdown: noFoundEmailOption,
            showList: true,
          });
        });
    },
    500,
  );

  hideList = () => {
    this.setState({
      listToShowInDropdown: [],
      showList: false,
    });
  };

  onNewData = (
    contactQueryData: GetContactsQuery,
    sessionHydrationData: Array<EmailOption>,
  ) => {
    const { showName, selectedValues } = this.props;

    const dataFromQuery: Array<EmailOption> =
      contactQueryData.getContacts.items.map(item => {
        const namedEmail = {
          email: item.email,
          name: item.name,
        };
        return {
          label: showName ? namedEmailFormatter(namedEmail) : item.email,
          key: item.id,
          payload: namedEmail,
        };
      });

    const mergedList = [...dataFromQuery, ...sessionHydrationData]
      .filter(item => {
        const payload = item ? item.payload : null;
        const emailItem = payload ? payload.email : '';
        const alreadyInList = selectedValues.some(
          selectedItem => selectedItem.namedEmail.email === emailItem,
        );

        if (!alreadyInList) {
          return true;
        }

        return false;
      })
      .sort((a, b) => sortByAlphabet('label')(a, b));

    this.setState({
      listToShowInDropdown:
        mergedList.length > 0 ? mergedList : noFoundEmailOption,
      showList: true,
    });
  };

  handleSearch = (e: React.SyntheticEvent<any>, client: ApolloClient<any>) => {
    const target = e.target as HTMLInputElement;

    let searchedValue = target.value;
    this.setState({
      typedValue: searchedValue,
    });

    searchedValue = searchedValue.toLowerCase();

    if (searchedValue.length === 0) {
      // @ts-ignore
      this.callQueryFunction.cancel();
    } else {
      this.callQueryFunction(searchedValue, client);
    }
  };

  getUsersList = (searchedValue: string) => {
    const { accountContext, showName } = this.props;
    const { viewableUsers } = accountContext;
    const foundEmails: Array<EmailOption> = [];

    viewableUsers.forEach(item => {
      const { email } = item;
      const name = getUserName(item);

      const checkEmail = email.toLowerCase().includes(searchedValue);
      const checkName = name.toLowerCase().includes(searchedValue);

      if (showName ? checkEmail || checkName : checkEmail) {
        const showedLabel = showName ? `${name} (${email})` : email;

        foundEmails.push({
          label: showedLabel,
          key: item.id,
          payload: {
            email: item.email,
            name: name,
          },
        });
      }
    });

    return foundEmails;
  };

  addEmailToSelectedList = (namedEmail: NamedEmail) => {
    const { email } = namedEmail;
    if (email.length === 0) return;
    if (email === noFoundEmailOption[0].label) return;

    const { onSelected } = this.props;

    const isValid = Validation.Email.isValid(email);
    const uniqKey = uuidv1();
    const newSelectedValue = {
      label: namedEmailFormatter(namedEmail),
      namedEmail,
      key: uniqKey,
      isValid: isValid, // check if this email has valid format
    };

    onSelected(newSelectedValue);

    this.setState({
      listToShowInDropdown: [],
      showList: false,
      typedValue: '',
    });
  };

  handleChangeOption = ({ selectedOptionIdx }: DropdownHandler) => {
    const { listToShowInDropdown } = this.state;

    const selectedOption = listToShowInDropdown[selectedOptionIdx];

    if (selectedOption && selectedOption.key !== noFoundEmailOption[0].key) {
      this.addEmailToSelectedList(getNamedEmailFromPayload(selectedOption));
    }
  };

  onEnterPress = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      const { listToShowInDropdown, typedValue, showList } = this.state;

      let foundEmails;

      if (showList && listToShowInDropdown.length > 0) {
        foundEmails = listToShowInDropdown.find(item => {
          // Due to label being typed as a React.ReactNode
          // We need to check to ensure .includes is there
          if (typeof item.label === 'string') {
            return item.label?.includes(typedValue);
          }
          return false;
        });
      }

      this.addEmailToSelectedList(
        foundEmails
          ? getNamedEmailFromPayload(foundEmails)
          : { email: typedValue },
      );
    }
  };

  handleDelete = (emailData: EmailItemType) => {
    const { onRemoved } = this.props;

    onRemoved(emailData);
  };

  render() {
    const { selectedValues, disabled } = this.props;

    const { typedValue, listToShowInDropdown, showList } = this.state;

    return (
      <ApolloConsumer>
        {client => (
          <Container>
            <StyledInputContainer data-testid={TEST_ID.EMAIL_SELECT_CONTAINER}>
              {selectedValues.map(item => {
                const { key, label, isValid } = item;

                return (
                  <TagField
                    key={key}
                    onDelete={() => this.handleDelete(item)}
                    value={label}
                    isValid={isValid}
                  />
                );
              })}

              <Input
                type="text"
                disabled={disabled}
                ref={elem => {
                  this.inputRef = elem;
                }}
                data-testid={TEST_ID.EMAIL_SELECT_SEARCH_INPUT}
                onKeyPress={this.onEnterPress}
                value={typedValue}
                onChange={e => this.handleSearch(e, client)}
              />
            </StyledInputContainer>
            <DropdownListContainer
              options={listToShowInDropdown.map(
                (listItem: EmailOption): Option => ({
                  label: listItem.label,
                  payload: listItem.payload,
                  key: listItem.key,
                }),
              )}
              dropdownListOpen={listToShowInDropdown.length > 0 && showList}
              onChange={this.handleChangeOption}
              onClickOutside={this.hideList}
              onClose={this.hideList}
            />
          </Container>
        )}
      </ApolloConsumer>
    );
  }
}

const getNamedEmailFromPayload = (item: EmailOption): NamedEmail =>
  item.payload;

const Container = styled.div<{}>`
  width: 100%;
`;

const StyledInputContainer = styled.div`
  flex-wrap: wrap;
`;

export default withAccountContext<MyProps>(EmailSelectInput);
