import {
  BlockElement,
  ListItemElement,
  OrderedListElement,
  ParagraphElement,
  UnorderedListElement,
} from '~/components/PluginsEditor/types';
import updateAttrsAndConvertToString from '../updateAttrsAndConvertToString';

/**
 *
 * @param node The element that will be serialized
 * @returns The updated attributes string that will be passed in element's serializer
 */
const getUpdatedBlockAttrs = (
  node:
    | BlockElement
    | UnorderedListElement
    | OrderedListElement
    | ListItemElement
    | ParagraphElement,
): string => {
  if (!node) return '';

  const updatedMarginLeft = node?.attributes?.style?.marginLeft;
  const updatedTextAlign = node?.attributes?.style?.textAlign;

  const attrsAsString = updateAttrsAndConvertToString(
    {
      style: {
        ...(updatedTextAlign
          ? { ['text-align']: updatedTextAlign.toString() }
          : {}),
        ...(updatedMarginLeft
          ? { ['margin-left']: updatedMarginLeft.toString() }
          : {}),
      },
    },
    node?.attrsToPassThrough,
  );

  return attrsAsString;
};

export default getUpdatedBlockAttrs;
