import { filter } from 'ramda';
import { ZapierFieldType, ZapierField__Input } from '~/graphql/types';
import { FLOW_OUTPUT_OBJECT } from '~/scenes/Automation/Flows/constants';
import { FlowOutputObjectField } from '~/scenes/Automation/Flows/types.flow';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import { camelToSnakeCase } from '~/util/string';

export type ZapierTriggerTemplate = {
  id: string;
  name: string;
  description?: string;
  fields: Array<ZapierField__Input>;
  insertedId?: string;
};
export type ZapierTriggerTemplates = Array<ZapierTriggerTemplate>;

const flowOutputTypeToZapierFieldType = (
  flowFieldType: Exclude<FLOW_OUTPUT_TYPE, 'FlowOutputFile'>,
): ZapierFieldType => {
  if (flowFieldType === FLOW_OUTPUT_TYPE.FlowOutputNumber)
    return ZapierFieldType.Number;
  if (flowFieldType === FLOW_OUTPUT_TYPE.FlowOutputBoolean)
    return ZapierFieldType.Boolean;

  return ZapierFieldType.String;
};

const removeUnsupportedFields = filter(
  ({ outputType }) => outputType !== FLOW_OUTPUT_TYPE.FlowOutputFile,
);

const outputFieldsToZapierTemplateFields = (
  outputFields: Array<FlowOutputObjectField>,
): Array<ZapierField__Input> =>
  outputFields.map(({ label, name, outputType }) => ({
    label,
    key: camelToSnakeCase(name.replace(/\./g, '_')),
    type: flowOutputTypeToZapierFieldType(outputType),
  }));

const zapierTriggerTemplates: ZapierTriggerTemplates = [
  {
    id: 'brainbay_waardecheck_report',
    name: 'Brainbay waardecheck - Rapport',
    fields: outputFieldsToZapierTemplateFields(
      removeUnsupportedFields(
        FLOW_OUTPUT_OBJECT.APP_BBWAARDECHECK_REPORT.fields,
      ),
    ),
  },

  {
    id: 'brainbay_waardecheck_appraisal_request',
    name: 'Brainbay waardecheck - Waardebepaling',
    fields: outputFieldsToZapierTemplateFields(
      removeUnsupportedFields(
        FLOW_OUTPUT_OBJECT.APP_BBWAARDECHECK_APPRAISALREQUEST.fields,
      ),
    ),
  },
  {
    id: 'brainbay_waardecheck_contact_request',
    name: 'Brainbay waardecheck - Contactverzoek',
    fields: outputFieldsToZapierTemplateFields(
      removeUnsupportedFields(
        FLOW_OUTPUT_OBJECT.APP_BBWAARDECHECK_CONTACTREQUEST.fields,
      ),
    ),
  },
  {
    id: 'funda_viewing_request',
    name: 'Funda - Bezichtingsaanvraag',
    fields: outputFieldsToZapierTemplateFields(
      removeUnsupportedFields(
        FLOW_OUTPUT_OBJECT.APP_FUNDA_VIEWINGREQUEST.fields,
      ),
    ),
  },
  {
    id: 'funda_brochure_request',
    name: 'Funda - Brochureaanvraag',
    fields: outputFieldsToZapierTemplateFields(
      removeUnsupportedFields(
        FLOW_OUTPUT_OBJECT.APP_FUNDA_BROCHUREREQUEST.fields,
      ),
    ),
  },
  {
    id: 'funda_contact_request',
    name: 'Funda - Contactaanvraag',
    fields: outputFieldsToZapierTemplateFields(
      removeUnsupportedFields(
        FLOW_OUTPUT_OBJECT.APP_FUNDA_CONTACTREQUEST.fields,
      ),
    ),
  },
  {
    id: 'waarderappot_valuation_report',
    name: 'Waarderapport',
    fields: outputFieldsToZapierTemplateFields(
      removeUnsupportedFields(FLOW_OUTPUT_OBJECT.APP_VALUATIONREPORT.fields),
    ),
  },
  {
    id: 'waarderappot_valuation_request',
    name: 'Waarderapport - waardebepaling',
    fields: outputFieldsToZapierTemplateFields(
      removeUnsupportedFields(FLOW_OUTPUT_OBJECT.APP_VALUATIONREQUEST.fields),
    ),
  },
];

export default zapierTriggerTemplates;
