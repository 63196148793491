import React from 'react';
import styled, { css } from 'styled-components';
import { useSlate } from 'slate-react';
import { Element, Path, Transforms } from 'slate';
import OptionsButton from '~/components/PluginsEditor/components/Buttons/components/Options';
import DeleteButton from '~/components/PluginsEditor/components/Buttons/Delete';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import {
  DHEditor,
  DhImageElement,
  ImageElement,
} from '~/components/PluginsEditor/types';
import TEST_ID from './index.testid';
import { OptionOf } from '~/components/Dropdown';

export type Props = {
  dataTestId?: string;
  path: Path;
  element: ImageElement | DhImageElement;
};

const ImageToolbar: React.FC<Props> = ({ path, element }) => {
  const editor = useSlate();

  const displayOptions = getOptions(editor, path);

  if (element.hasError || element.pending) {
    return (
      <DeleteButton
        editor={editor}
        path={path}
        dataTestId={TEST_ID.DELETE_BUTTON}
      />
    );
  }

  return (
    <>
      <OptionsButton
        options={displayOptions}
        icon="align-left"
        selectedOption={element.display || undefined}
        dataTestId={TEST_ID.OPTIONS_BUTTON}
        persistFocus={false}
        editor={editor}
      />
      <StyledDeleteButton
        editor={editor}
        path={path}
        dataTestId={TEST_ID.DELETE_BUTTON}
      />
    </>
  );
};

const StyledDeleteButton = styled(DeleteButton)(
  ({ theme }) => css`
    margin-left: ${theme.space('xxs')};
  `,
);

const getOptions = (
  editor: DHEditor,
  path: Path,
): Array<OptionOf<'block' | 'inline-block'>> => [
  {
    key: 'inline',
    label: 'inline',
    payload: 'inline-block',
    onClickAction: () => onSelect(editor, path, 'inline-block'),
  },
  {
    key: 'block',
    label: 'block',
    payload: 'block',
    onClickAction: () => onSelect(editor, path, 'block'),
  },
];

const onSelect = (
  editor: DHEditor,
  path: Path,
  value: 'block' | 'inline-block',
) => {
  Transforms.setNodes<Element>(
    editor,
    { display: value, float: value === 'inline-block' ? 'left' : undefined },
    {
      at: path,
      match: node =>
        Element.isElement(node) &&
        (node.type === ELEMENTS.IMAGE || node.type === ELEMENTS.DH_IMAGE),
    },
  );
};

export default ImageToolbar;
