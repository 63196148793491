import React, { useState, useRef, useEffect, useContext } from 'react';
import MetaTags from 'react-meta-tags';
import { RouteComponentProps } from '@reach/router';
import { WithDelayedLoading } from '~/components/util/withDelayedLoading';

import { GetAccountQuery } from '~/graphql/';
import query from '~/graphql/query/GetAccount';
import { UserRole } from '~/graphql/types';

import AccountContext from '~/contexts/AccountContext';
import Loading from '~/components/Loading';
import AppErrorScreen from '~/components/AppErrorScreen';
import { Alerts } from '~/components';
import withErrorBoundary from '~/ErrorBoundary';
import cleanedFilename from '~/util/cleanedFilename';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import AccountComponent from './components/AccountComponent';
import convertMailboxes from '~/util/converters/convertMailboxes';
import { ALIAS_VERIFICATION_TIMEOUT } from '~/scenes/Settings/constants';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useUserLookup from '~/hooks/useUserLookup';
import { getUsersList } from './getUserList';

type Props = WithDelayedLoading & RouteComponentProps;

const text = {
  pageTitle: 'Account',
};

const Account: React.FC<Props> = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const pollingTimeout = useRef<number | null>(null);
  const { refetchSessionHydration } = useContext(AccountContext);
  const { id: accountId } = useCurrentAccount();
  const userLookupTable = useUserLookup();

  useEffect(
    () => () => {
      if (pollingTimeout.current) {
        clearTimeout(pollingTimeout.current);
      }
    },
    [],
  );

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>

      <Alerts.SimpleText type={'error'} text={errorMsg} />
      <GetAccountQuery
        name={cleanedFilename(__filename)}
        query={query}
        variables={{
          accountId,
        }}
      >
        {({ data, networkStatus, refetch, startPolling, stopPolling }) => {
          if (isInitialLoadStatus(networkStatus)) return <Loading />;

          if (!data || !data.getAccount) return <AppErrorScreen />;

          const account = data.getAccount;
          const userIsAdmin = account.relation.role === UserRole.Admin;
          const mailboxes = account.Mailboxes;
          const signature = account.Signature ?? null;

          const { users, admins } = getUsersList({
            usersData: account.Users,
            userLookupTable,
          });

          const refetchAccount = () =>
            refetch().then(() => refetchSessionHydration());

          return (
            <AccountComponent
              accountId={account.id}
              accountName={account.name}
              mayEdit={userIsAdmin}
              mailboxes={convertMailboxes(mailboxes)}
              refetch={refetchAccount}
              users={users}
              admins={admins}
              setErrorMsg={(msg: string) => setErrorMsg(msg)}
              startPolling={interval => {
                startPolling(interval);

                if (pollingTimeout.current) {
                  clearTimeout(pollingTimeout.current);
                }

                pollingTimeout.current = global.window.setTimeout(
                  stopPolling,
                  ALIAS_VERIFICATION_TIMEOUT,
                );
              }}
              stopPolling={() => {
                stopPolling();
              }}
              signature={signature}
            />
          );
        }}
      </GetAccountQuery>
    </ContentContainerDefault>
  );
};

export default withErrorBoundary(Account);
