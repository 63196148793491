import gql from 'graphql-tag';

export default gql`
  mutation MarkContactForDeletion($accountId: ID!, $id: ID!) {
    markContactForDeletion(accountId: $accountId, id: $id) {
      __typename
      success
    }
  }
`;
