import React from 'react';
import styled, { css } from 'styled-components';

import TEST_ID_MASTER_DETAIL from '~/components/MasterDetail/index.testid';
import { navigate } from 'gatsby';
import type { RouteComponentProps } from '@reach/router';
import MetaTags from 'react-meta-tags';
import DHRouter from '~/components/DHRouter';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import MasterDetail from '~/components/MasterDetail';
import Triggers from './components/Triggers';
import Events from './components/Events';
import Settings from './components/Settings';
import { Heading2 } from '~/components/Typography/index';

import Wizard from './components/Wizard';
import NewTrigger from './components/Triggers/components/NewTrigger';
import EditTrigger from './components/Triggers/components/EditTrigger';
import NewEvent from './components/Events/components/NewEvent';
import EditEvent from './components/Events/components/EditEvent';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import {
  useGetZapierEventsQuery,
  useGetZapierTriggersQuery,
} from '~/graphql/types';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import NotFound from '~/components/404';
import DatHuisLoading from '~/components/DatHuisLoading';
import useApp from '~/hooks/useApp';

type Props = RouteComponentProps & {};

const Zapier: React.FC<Props> = ({ uri = '' }) => {
  const { app, loading } = useApp('AppStatus_Zapier');

  if (loading) return <DatHuisLoading />;
  if (!app?.appStatus.enabled) return <NotFound />;

  return (
    <DHRouter>
      <NewTrigger path="/triggers/new" />
      <EditTrigger path="/triggers/:triggerId" />

      <NewEvent path="/events/new" />
      <EditEvent path="/events/:eventId" />

      <WithMasterDetails path="/*" uri={uri} />
    </DHRouter>
  );
};

const WithMasterDetails: React.FC<Props> = ({ uri = '' }) => {
  const isMountedRef = useIsMountedRef();
  const { id: accountId } = useCurrentAccount();

  const { refetch: refetchTriggers } = useGetZapierTriggersQuery({
    variables: {
      accountId,
    },
  });
  const { refetch: refetchEvents } = useGetZapierEventsQuery({
    variables: {
      accountId,
    },
  });
  // We refetch the events and triggers once the wizard is completed to update the internal cache of Apollo
  // causing the underlying pages(/events and /triggers) to show a populated table
  const refetch = async () => Promise.all([refetchTriggers(), refetchEvents()]);

  return (
    <>
      <MetaTags>
        <title>Zapier</title>
      </MetaTags>

      <ContentContainerDefault
        breadcrumbs={[
          {
            to: '/-/apps',
            label: 'Apps',
          },
          { label: 'Zapier' },
        ]}
      >
        <Heading>Zapier</Heading>
        <MasterDetail
          basePath={uri}
          dataTestId={TEST_ID_MASTER_DETAIL.CONTAINER}
          navbar={[
            {
              type: 'link',
              to: '',
              icon: 'externalLink',
              name: 'Uitgaande koppelingen',
            },
            {
              type: 'link',
              to: '/events',
              icon: 'incomingLink',
              name: 'Inkomende koppelingen',
            },
            {
              type: 'link',
              to: '/settings',
              icon: 'gear',
              name: 'Instellingen',
            },
          ]}
        >
          <Events path="/events" />
          <Settings path="/settings" />
          <Triggers path="/" default />
        </MasterDetail>
      </ContentContainerDefault>

      <DHRouter>
        <Wizard
          path="/wizard/*"
          onClose={() =>
            void refetch().then(() => {
              if (!isMountedRef.current) return;

              void navigate('/-/apps/zapier');
            })
          }
        />
      </DHRouter>
    </>
  );
};

const Heading = styled(Heading2)<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary')};
    margin-bottom: ${theme.space('xxl')};
  `,
);

export default Zapier;
