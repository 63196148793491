import { AlgasSoldPropertyFieldsFragment } from '~/graphql/types';
import createSimpleList from '../createSimpleList';
import getProperty from '../getProperty';
import formatDate from '../formatDate';
import asString from '../asString';
import asSqm from '../asSqm';
import { moneyFormatter } from '~/util/money';
import { LayoutStyleBlockFE } from '~/components/EventTimelineV2/types';

const text = {
  unknown: 'Onbekend',
};

/**
 * Returns blocks for Event_Contact_App_ValuationReport swiped (sold) properties
 * @param {Array<AlgasSoldPropertyFieldsFragment>} properties - Valuation report comparable properties
 * keywords: comparableProperty
 */
const getAlgasSoldProperties = (
  properties: Array<AlgasSoldPropertyFieldsFragment>,
): Array<LayoutStyleBlockFE> =>
  [
    ...properties?.map((property, index) =>
      getProperty({
        isLastCard: index === properties.length - 1,
        blocks: [
          {
            id: '',
            childrenIds: [],
            __typename: 'FlexLayout',
            blocks: [
              {
                id: '',
                __typename: 'ImageBlock',
                imageValue: {
                  __typename: 'ImageBlockValue',
                  // Pass a string even if the image is undefined so that 2 columns are created
                  src: property.image ?? '',
                },
              },
            ],
          },
          ...createSimpleList([
            {
              __typename: 'TextBlock',
              label: 'Adres',
              value: asString(property.street),
            },
            {
              __typename: 'TextBlock',
              label: 'Postcode',
              value: asString(property.postcode),
            },
            {
              __typename: 'TextBlock',
              label: 'Woonplaats',
              value: asString(property.city),
            },
            {
              __typename: 'TextBlock',
              label: 'Woonoppervlakte',
              value: asSqm(property.livingArea),
            },
            {
              __typename: 'TextBlock',
              label: 'Perceeloppervlakte',
              value: asSqm(property.propertyArea),
            },
            {
              __typename: 'TextBlock',
              label: 'Kamers',
              value: asString(property.rooms),
            },
            {
              __typename: 'TextBlock',
              label: 'Bouwjaar',
              value: asString(property.buildIn),
            },
            {
              __typename: 'TextBlock',
              label: 'Type woning',
              value: propertyLabelForType(property.propertyType),
            },
            {
              __typename: 'TextBlock',
              label: 'Vraagprijs',
              value: property.price ? moneyFormatter(property.price) : '-',
            },
            {
              __typename: 'TextBlock',
              label: 'Verkocht op',
              value: property.dateOfSale
                ? formatDate(property.dateOfSale)
                : '-',
            },
          ]),
        ],
      }),
    ),
  ] as Array<LayoutStyleBlockFE>;

export default getAlgasSoldProperties;

const AlgasPropertyType = Object.freeze({
  VrijstaandeWoning: 0,
  TweeOnderEenKapwoning: 1,
  Hoekwoning: 2,
  TussenSchakelwoning: 3,
  Appartement: 4,
});

const propertyLabelForType = (
  propertyType: number | null | undefined,
): string => {
  if (propertyType == null) return text.unknown;

  const propertyTypeLookup = {
    [AlgasPropertyType.VrijstaandeWoning]: 'Vrijstaande woning',
    [AlgasPropertyType.TweeOnderEenKapwoning]: 'Twee-onder-een-kapwoning',
    [AlgasPropertyType.Hoekwoning]: 'Hoekwoning',
    [AlgasPropertyType.TussenSchakelwoning]: 'Schakelwoning',
    [AlgasPropertyType.Appartement]: 'Appartement',
  };

  return propertyTypeLookup[propertyType] ?? text.unknown;
};
