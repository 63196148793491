import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import TEST_ID from './HelpButton.testid';
import Icon from '../Icon';

type LinkProps = {
  link: string;
};
type Props = {
  link: LinkProps;
};
const HelpButton = ({ link, ...rest }: Props) => (
  <StyledQuestionMark
    name="question-mark"
    {...rest}
    data-testid={TEST_ID.BUTTON}
    onClick={() => {
      const linkToGoTo = link.link;

      if (linkToGoTo.startsWith('http')) {
        // if it is a whole url, do it the old fashioned way and open in new tab. Does not seem like there is a better way: https://stackoverflow.com/questions/42914666/react-router-external-link
        global.window.open(linkToGoTo, '_blank');
      } else {
        void navigate(linkToGoTo);
      }
    }}
  />
);

const StyledQuestionMark = styled(Icon)<{}>`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
  `};
`;

export default HelpButton;
