import gql from 'graphql-tag';

import SessionHydrationUserFields from '../fragments/SessionHydrationUserFields';
import SessionHydrationOfficeFields from '../fragments/SessionHydrationOfficeFields';
import SessionHydrationOfficeRelationFields from '../fragments/SessionHydrationOfficeRelationFields';
import SessionHydrationAccountRelationFields from '../fragments/SessionHydrationAccountRelationFields';
import SessionHydrationAccountFields from '../fragments/SessionHydrationAccountFields';
import SessionHydrationDeletedAccountRelationFields from '../fragments/SessionHydrationDeletedAccountRelationFields';
import SessionHydrationDeletedOfficeRelationFields from '../fragments/SessionHydrationDeletedOfficeRelationFields';
import AccountMailboxFields from '../fragments/AccountMailboxFields';
import OfficeMailboxFields from '../fragments/OfficeMailboxFields';
import UserMailboxFields from '../fragments/UserMailboxFields';
import AccountMailboxAliasFields from '../fragments/AccountMailboxAliasFields';
import OfficeMailboxAliasFields from '../fragments/OfficeMailboxAliasFields';
import UserMailboxAliasFields from '../fragments/UserMailboxAliasFields';

export default gql`
  query GetSessionHydration($accountId: ID!) {
    getSessionHydration(accountId: $accountId) {
      account {
        ...SessionHydrationAccountFields
      }
      me {
        id
      }
      impersonationOptions {
        viewingMode
      }
      impersonatedBy {
        id
        name
        email
      }
      offices {
        ...SessionHydrationOfficeFields
      }
      users {
        ...SessionHydrationUserFields
      }
      relations {
        ... on OfficeRelationship {
          ...SessionHydrationOfficeRelationFields
        }
        ... on AccountRelationship {
          ...SessionHydrationAccountRelationFields
        }
      }
      deletedRelations {
        ... on DeletedUserToAccountRelationship {
          ...SessionHydrationDeletedAccountRelationFields
        }
        ... on DeletedOfficeToAccountRelationship {
          ...SessionHydrationDeletedOfficeRelationFields
        }
      }
      mailboxes {
        ... on AccountMailbox {
          ...AccountMailboxFields
        }
        ... on OfficeMailbox {
          ...OfficeMailboxFields
        }
        ... on UserMailbox {
          ...UserMailboxFields
        }
        ... on AccountMailboxAlias {
          ...AccountMailboxAliasFields
        }
        ... on OfficeMailboxAlias {
          ...OfficeMailboxAliasFields
        }
        ... on UserMailboxAlias {
          ...UserMailboxAliasFields
        }
      }
      fundaApp
      warningList: notificationList {
        level
        message
        body {
          ... on NotificationMessage {
            htmlBody
          }

          ... on NotificationWizard {
            id
            htmlBody
            ctaLabel
            header
          }
        }
      }
    }
  }

  ${SessionHydrationUserFields}
  ${SessionHydrationOfficeFields}
  ${SessionHydrationOfficeRelationFields}
  ${SessionHydrationAccountRelationFields}
  ${SessionHydrationAccountFields}
  ${SessionHydrationDeletedAccountRelationFields}
  ${SessionHydrationDeletedOfficeRelationFields}
  ${AccountMailboxFields}
  ${OfficeMailboxFields}
  ${UserMailboxFields}
  ${AccountMailboxAliasFields}
  ${OfficeMailboxAliasFields}
  ${UserMailboxAliasFields}
`;
