import { useState, useCallback, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { QUERY_PARAMS_OPTIONS } from '~/scenes/Contacts/constants';

const useQueryParams = (params: queryString.StringifiableRecord) => {
  const location = useLocation();
  const [search, setSearch] = useState(
    queryString.stringify(params, QUERY_PARAMS_OPTIONS.queryStringOptions),
  );

  // Sync up updates to URL by hand.
  useEffect(() => {
    if (location.search !== `?${search}` && location.search !== '') {
      setSearch(location.search);
    }
  }, [location.search, search]);

  useEffect(() => {
    if (location.search !== `?${search}` || location.search === '') {
      void navigate(`?${search}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only fire on mount

  const setParams = useCallback(
    (
      newParams: queryString.StringifiableRecord,
      { next = '' }: { next?: string } = {},
    ) => {
      const newSearch = queryString.stringify(
        {
          ...queryString.parse(location.search),
          ...newParams,
        },
        QUERY_PARAMS_OPTIONS.queryStringOptions,
      );

      if (newSearch !== search) {
        setSearch(newSearch);
        const to =
          next === location.pathname ? `?${newSearch}` : `${next}?${newSearch}`;

        void navigate(to);
      }
    },
    [location.search, search, location.pathname],
  );

  return [search, setParams] as const;
};

export default useQueryParams;
