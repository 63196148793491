import uuid from 'uuid/v4';
import { LevelType, LinkObj } from '~/components/Toast';

export type FormattedToastMessage = {
  id: string;
  message: string;
  level: LevelType;
  link?: LinkObj;
};

/**
 * Formats a toast message ready to be put into the atom
 */
const formatToastMessage = (
  message: string,
  level: LevelType,
  link?: LinkObj,
): FormattedToastMessage => ({
  id: uuid(),
  message,
  level,
  link,
});

export default formatToastMessage;
