/**
 * Converts html attributes object into html attributes string
 * @param {string} obj - Attributes object. Keys are passed in css syntax e.g. 'background-color'
 * keywords: css attributes, to attributes object
 */
export const convertAttrsObjToAttrsString = (obj?: {
  [key: string]: string;
}): string => {
  if (!obj) return '';

  let str = '';
  for (const key in obj) {
    const value = obj[key];
    if (value) str += `${key}="${value}" `;
  }

  // remove extra space at the end
  return str.trim();
};
export default convertAttrsObjToAttrsString;
