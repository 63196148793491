import { Operator } from '~/components/Filters/Primitive/Number/types.flow';

export const OPERATOR = {
  EQUALS: 'eq',
  GREATER_THAN: 'gt',
  LESS_THAN: 'lt',
  GREATER_THAN_EQUAL: 'gte',
  LESS_THAN_EQUAL: 'lte',
};

export const operatorOptions = [
  {
    key: Operator.EQUALS,
    payload: Operator.EQUALS,
    label: 'Gelijk aan',
  },
  {
    key: Operator.GREATER_THAN_EQUAL,
    payload: Operator.GREATER_THAN_EQUAL,
    label: 'Groter dan of gelijk aan',
  },
  {
    key: Operator.GREATER_THAN,
    payload: Operator.GREATER_THAN,
    label: 'Groter dan',
  },
  {
    key: Operator.LESS_THAN_EQUAL,
    payload: Operator.LESS_THAN_EQUAL,
    label: 'Kleiner dan of gelijk aan',
  },
  {
    key: Operator.LESS_THAN,
    payload: Operator.LESS_THAN,
    label: 'Kleiner dan',
  },
];
