import { ReactElement } from 'react';

import { FlowActionProps } from '~/scenes/Automation/Flows/Actions/types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { describeSetContactDetails } from '~/scenes/Automation/Flows/Actions/SetContactDetailsAction';
import { describeStartAction } from '~/scenes/Automation/Flows/Actions/StartAction/';
import { describeWaitAction } from '~/scenes/Automation/Flows/Actions/WaitAction/';
import { describeCreateTaskAction } from '~/scenes/Automation/Flows/Actions/CreateTaskAction/';
import { describeAddContactTagAction } from '~/scenes/Automation/Flows/Actions/AddContactTagAction/';
import { describeDeleteContactTagAction } from '~/scenes/Automation/Flows/Actions/DeleteContactTagAction/';
import { describeSendEmailPlainAction } from '~/scenes/Automation/Flows/Actions/SendEmailPlainAction';
import { describeIfElseAction } from '~/scenes/Automation/Flows/Actions/IfElseAction';
import { describeAssignContactAction } from '~/scenes/Automation/Flows/Actions/AssignContactAction';

import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

export default (
  action: FlowActionProps,
  describeOptions: DescribeOptions,
): ReactElement | null => {
  switch (action.type) {
    case FLOW_ACTION_TYPE.SET_CONTACT_DETAILS:
      return describeSetContactDetails(action, describeOptions);
    case FLOW_ACTION_TYPE.START:
      return describeStartAction(action, describeOptions);
    case FLOW_ACTION_TYPE.WAIT:
      return describeWaitAction(action, describeOptions);
    case FLOW_ACTION_TYPE.CREATE_TASK:
      return describeCreateTaskAction(action, describeOptions);
    case FLOW_ACTION_TYPE.ADD_CONTACT_TAG:
      return describeAddContactTagAction(action, describeOptions);
    case FLOW_ACTION_TYPE.DELETE_CONTACT_TAG:
      return describeDeleteContactTagAction(action, describeOptions);
    case FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN:
      return describeSendEmailPlainAction(action, describeOptions);
    case FLOW_ACTION_TYPE.IF_ELSE:
      return describeIfElseAction(action, describeOptions);
    case FLOW_ACTION_TYPE.ASSIGN_CONTACT:
      return describeAssignContactAction(action, describeOptions);
    case FLOW_ACTION_TYPE.SENDCONTACT_TO_REALWORKS:
      return null;
    case FLOW_ACTION_TYPE.ZAPIER_TRIGGER:
      return null;
    default:
      return assertNever(action, `${cleanedFilename(__filename)} `);
  }
};
