import React, { useState } from 'react';
import Button from '~/components/Button';
import { Props as WizardProps } from '../../index';
import JustificationContainer from '~/components/JustificationContainer';
import TextButton from '~/components/TextButton';
import useWizard from '~/hooks/useWizard';
import { OutputMap } from '~/components/WizardSteps';
import TEST_ID from './index.testid';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';

export type Props = {
  dataTestId?: string;
  completeButton?: WizardProps['completeButton'];
  onComplete?: () => void;
  outputMap: OutputMap;
};

const text = {
  defaultCompleteLabel: 'Voltooien',
  defaultNextLabel: 'Volgende stap',
  defaultPreviousLabel: 'Ga een stap terug',
  defaultCancelLabel: 'Afbreken',
};

const NavigationControls: React.FC<Props> = ({
  dataTestId,
  completeButton = {},
  onComplete,
  outputMap,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const wizard = useWizard();
  const step = wizard.getCurrentStep();
  const addToast = useAddToast();

  const onBeforeNext = async e => {
    e.preventDefault();
    if (step.options && step.options.onBeforeNext) {
      try {
        setLoading(true);
        const stepOutput = await step.options.onBeforeNext(outputMap);
        // Update the output map with the returned value
        const nextOutputMap = { ...outputMap, [step.id]: stepOutput };

        wizard.setOutput(nextOutputMap);
        return Promise.resolve(outputMap);
      } catch (error) {
        if ('message' in error) {
          addToast([formatToastMessage(error.message, 'danger')]);
        }
        // Don't move to next
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    }
    return Promise.resolve(outputMap);
  };

  return (
    <JustificationContainer
      justification="space-between"
      margin={['m', null, null, null]}
    >
      <TextButton
        size="medium"
        type="button"
        style={{ marginLeft: '-0.8rem' }}
        appearance="danger"
        dataTestId={TEST_ID.CANCEL_BUTTON}
        label={
          step.cancelButton && step.cancelButton.label
            ? step.cancelButton.label
            : text.defaultCancelLabel
        }
        onClick={e => {
          e.preventDefault();
          wizard.hide();
        }}
      />
      <JustificationContainer data-testid={dataTestId} gap="base" {...rest}>
        {wizard.hasPrevious() && (
          <TextButton
            size="medium"
            type="button"
            appearance="primary"
            dataTestId={TEST_ID.PREVIOUS_BUTTON}
            onClick={async e => {
              e.preventDefault();
              if (step.options?.onBeforePrevious) {
                await step.options.onBeforePrevious(outputMap);
              }
              wizard.previous();
            }}
            {...{
              label: text.defaultPreviousLabel,
              ...step.previousButton,
            }}
          />
        )}
        {wizard.hasNext() ? (
          <Button
            size="medium"
            loading={loading ? loading : undefined}
            type="button"
            appearance="primary"
            disabled={step.isFree !== true}
            dataTestId={TEST_ID.NEXT_BUTTON}
            onClick={async e => {
              await onBeforeNext(e).then(() => {
                wizard.next();
              });
            }}
            {...{
              label: text.defaultNextLabel,
              ...step.nextButton,
            }}
          />
        ) : (
          <Button
            size="medium"
            type="button"
            appearance="secondary"
            dataTestId={TEST_ID.COMPLETE_BUTTON}
            disabled={step.isFree !== true}
            onClick={async e => {
              await onBeforeNext(e).then(() => {
                wizard.hide();
                return wizard.clear();
              });
              if (onComplete) onComplete();
            }}
            {...{
              label: text.defaultCompleteLabel,
              icon: 'check',
              ...completeButton,
            }}
          />
        )}
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default NavigationControls;
