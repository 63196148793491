import React, { useState } from 'react';

import { FlowConditionAppValuationReportNew } from './types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import AppValuationReportDropdown from '~/components/Inputs/Dropdown/AppValuationReportDropdown';
import useCurrentAccount from '~/hooks/useCurrentAccount';

type MyProps = {
  condition: FlowConditionAppValuationReportNew;
  onChange: (newFlowCondition: FlowConditionAppValuationReportNew) => void;
};
type Props = WithBaseActionContextProps & MyProps;
const FlowConditionValuationReportNewComponent = ({
  condition,
  onChange,
}: Props) => {
  const account = useCurrentAccount();
  const [selectedId, setSelectedId] = useState<string | null>(
    condition.app ? condition.app?.value : null,
  );

  return (
    <AppValuationReportDropdown
      onChange={({ option }) => {
        if (option.payload == null) {
          setSelectedId(null);

          onChange({
            ...condition,
            app: null,
          });
        } else {
          setSelectedId(option.payload);

          onChange({
            ...condition,
            app: {
              value: option.payload,
            },
          });
        }
      }}
      variables={{ accountId: account.id }}
      selectedOptionId={selectedId}
    />
  );
};

export default withBaseActionContext<MyProps>(
  FlowConditionValuationReportNewComponent,
);
