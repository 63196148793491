import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/Button';
import DatHuisLoading from '~/components/DatHuisLoading';
import Dropdown, { OptionOf } from '~/components/Dropdown';
import JustificationContainer from '~/components/JustificationContainer';
import TextButton from '~/components/TextButton';
import {
  FlowAction,
  FlowV2_Action_Zapier_TriggerFragment,
  Flow___ArgumentFragment,
  useGetZapierTriggersQuery,
  ZapierTriggerFieldsFragment,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import getZapierTriggerOptions from '~/util/getZapierTriggerOptions';
import isPointerArgument from '~/scenes/Automation/v2/util/isPointerArgument';
import isPrimitiveArgument from '~/scenes/Automation/v2/util/isPrimitiveArgument';
import Variable from '../../../Builder/components/nodeTypes/components/TemplateString/components/Variable';
import useBuilderContext from '../../../Builder/hooks/useBuilderContext';
import { Props as FormProps } from '../ActionForm';
import Selector from '../Selector';
import { FlowPath } from '../Selector/utils/getFieldsByPath';
import getPathForArgument from '../Selector/utils/getPathForArgument';
import TEST_ID from './index.testid';
import PositionWrapper from '../ConditionEditor/components/PositionWrapper';
import useRelativeMaps from '../../../Builder/hooks/useRelativeMaps';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_Zapier_TriggerFragment;
};

type State = Omit<
  FlowV2_Action_Zapier_TriggerFragment,
  | 'id'
  | 'accountId'
  | 'flowBlueprintId'
  | '__typename'
  | '_v'
  | 'actionType'
  | 'parentIds'
>;

type InvertedMap = Record<string, Flow___ArgumentFragment>;

const text = {
  selectTriggerLabel: 'Selecteer een uitgaande koppeling',
  selectAValue: 'Ken een waarde toe',
};

const ZapierTrigger: React.FC<Props> = ({
  dataTestId,
  action,
  onChange,
  ...rest
}) => {
  const {
    opts,
    primitiveInput,
    primitiveListInput,
    superSubjects,
    instanceMap,
  } = useBuilderContext();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [actionDetails, setActionDetails] = useState<State>({
    mappings: action.mappings,
    zapierTriggerId: action.zapierTriggerId,
  });
  const maps = useRelativeMaps({ actionId: action.id });

  const account = useCurrentAccount();

  const parentRefs = useRef<{
    [key: string]: null | HTMLElement;
  }>({});

  const { data, loading } = useGetZapierTriggersQuery({
    variables: {
      accountId: account.id,
    },
  });

  const triggerOptions: Array<OptionOf<ZapierTriggerFieldsFragment>> =
    getZapierTriggerOptions(data?.getZapierTriggers);

  const handleChange = useCallback((key, value: Flow___ArgumentFragment) => {
    setActionDetails(prev => {
      const mutableMappings = Array.from(prev.mappings);
      const currentIndex = mutableMappings.findIndex(
        ({ mappingId }) => mappingId === key,
      );

      if (currentIndex < 0) {
        mutableMappings.push({
          __typename: 'FlowV2_ParameterMapping',
          mappingId: key,
          mapping: value,
        });
      } else {
        mutableMappings[currentIndex] = {
          ...mutableMappings[currentIndex],
          mapping: value,
        };
      }

      return { ...prev, mappings: mutableMappings };
    });
    setSelectedKey(null);
  }, []);

  const onClear = useCallback((key: string) => {
    setActionDetails(details => ({
      ...details,
      mappings: details.mappings.filter(({ mappingId }) => mappingId !== key),
    }));
  }, []);

  const onVariableSelect = useCallback((key: string) => {
    setSelectedKey(key);
  }, []);

  useEffect(() => {
    onChange({ ...action, ...actionDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  const selectedOptionIdx = triggerOptions.findIndex(
    option => option.payload.id === actionDetails.zapierTriggerId,
  );
  const selectedTrigger = triggerOptions[selectedOptionIdx]?.payload;

  // We create this map as a part of the render loop so that the map always reflects the latest state of the actionDetails
  const invertedMapping = actionDetails.mappings.reduce((acc, mapping) => {
    if (mapping.mapping) acc[mapping.mappingId] = mapping.mapping;
    return acc;
  }, {} as InvertedMap);

  if (loading) return <DatHuisLoading />;

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Dropdown
        dataTestId={TEST_ID.DROPDOWN}
        options={triggerOptions}
        label={text.selectTriggerLabel}
        selectedOptionIdx={selectedOptionIdx}
        onChange={({ option }) => {
          setActionDetails(prev => ({
            ...prev,
            zapierTriggerId: option.payload.id,
          }));
          // If we don't reset the selected key, switching back and forth causes selector to open on previous state
          setSelectedKey(null);
        }}
      />
      {selectedTrigger && (
        <MappingsContainer>
          {selectedTrigger.fields.map(({ key, type, label }, index) => {
            const mappingValue = invertedMapping[key];
            const argument = isPrimitiveArgument(mappingValue)
              ? mappingValue
              : undefined;

            let selectorPath: FlowPath = [];

            if (mappingValue && isPointerArgument(mappingValue)) {
              const pathRes = getPathForArgument(mappingValue, {
                ...opts,
                instanceMap,
                argumentType: superSubjects[type].subjectIds[0],
              });

              // When error isn't undefined, we could not get the path for the pointer
              // most likely because the pointer is missing from the instances
              // Therefore we cannot open the selector at a non existing path,
              // so go from root to correct
              if (pathRes.error === undefined) {
                selectorPath = pathRes.result;
              }
            }

            return (
              <JustificationContainer
                justification="space-between"
                align="center"
                key={key}
              >
                <MappingRow>
                  <FieldLabel>{label}</FieldLabel>
                  <VariableContainer>
                    {invertedMapping[key] ? (
                      <Variable
                        id={key}
                        mappings={actionDetails.mappings}
                        onClick={e => {
                          e.stopPropagation();
                          onVariableSelect(key);
                        }}
                        actionId={action.id}
                        ref={element => {
                          parentRefs.current[index] = element;
                        }}
                      />
                    ) : (
                      <TextButton
                        dataTestId={TEST_ID.TEXT_BUTTON}
                        label={text.selectAValue}
                        withoutPadding
                        onClick={e => {
                          e.stopPropagation();
                          onVariableSelect(key);
                        }}
                        ref={element => {
                          parentRefs.current[index] = element;
                        }}
                      />
                    )}

                    {selectedKey !== null && selectedKey === key && (
                      <PositionWrapper
                        onClose={() => setSelectedKey(null)}
                        parentRef={parentRefs.current[index]}
                        rowContainerRef={parentRefs.current[index]}
                      >
                        <Selector
                          onClose={() => setSelectedKey(null)}
                          onSelect={value => {
                            // This won't ever be true because the values are limited by `limitToInstanceSubjects` but Typescript doesn't know that
                            if (
                              value.__typename === 'Flow___Argument_File' ||
                              value.__typename === 'Flow___InstanceCondition' ||
                              value.__typename ===
                                'Flow___SubjectFieldCondition'
                            )
                              return;

                            handleChange(key, value);
                          }}
                          inputPrimitives={primitiveInput}
                          inputListPrimitives={primitiveListInput}
                          argument={argument}
                          initialPath={selectorPath}
                          opts={{
                            ...opts,
                            directoryMap: maps.directoryMap,
                            action: FlowAction.ZapierTrigger,
                            conditionType: 'condition',
                            limitToInstanceSubjects:
                              superSubjects[type].subjectIds ?? [],
                          }}
                        />
                      </PositionWrapper>
                    )}
                  </VariableContainer>
                </MappingRow>
                <JustificationContainer>
                  <Button
                    icon="delete"
                    appearance="danger"
                    ghost
                    onClick={() => onClear(key)}
                  />
                </JustificationContainer>
              </JustificationContainer>
            );
          })}
        </MappingsContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{}>``;

const MappingsContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

const FieldLabel = styled.p(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    margin: 0 0 ${theme.space('s')} 0;
    padding: 0;
  `,
);

const MappingRow = styled.li(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 0 ${theme.space('s')} 0;
    flex: 1 0;
  `,
);

const VariableContainer = styled.div`
  position: relative;
  width: 100%;
`;

export default ZapierTrigger;
