import React from 'react';
import styled from 'styled-components';

import RowLabel from './RowLabel';
import Input from '~/components/Input';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';

const text = {
  subjectLabel: 'Onderwerp:',
  validationError: Catalog.requiredField,
};
type Props = {
  readOnly?: boolean;
  shouldValidate: boolean;
  subject?: string | null | undefined;
  disabled?: boolean;
  onChange: (newSubject: string) => void;
  dataTestId: string;
};
const SubjectRow = ({
  subject,
  readOnly = false,
  disabled,
  shouldValidate,
  onChange,
  dataTestId,
}: Props) => {
  let inputComponent;
  if (readOnly) {
    inputComponent = subject;
  } else {
    let error: Array<string> | undefined = undefined;
    if (shouldValidate && !Validation.String.isNonEmptyString(subject)) {
      error = [text.validationError];
    }

    inputComponent = (
      <Input
        value={subject}
        disabled={disabled}
        type="text"
        externalErrors={error}
        onChange={(e: React.SyntheticEvent<any>) => {
          const target = e.target as HTMLInputElement;

          if (target) {
            onChange(target.value);
          }
        }}
        dataTestId={dataTestId}
      />
    );
  }

  return (
    <>
      <SubjectLabel>{text.subjectLabel}</SubjectLabel>
      <SubjectInput>{inputComponent}</SubjectInput>
    </>
  );
};

const SubjectLabel = styled(RowLabel)<{}>`
  grid-row: subject-start / subject-end;
  grid-column: label-start / label-end;
`;

const SubjectInput = styled.div<{}>`
  grid-row: subject-start / subject-end;
  grid-column: input-start / button-end;
`;

export default SubjectRow;
