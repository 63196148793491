import { isNil } from 'ramda';
import React from 'react';
import { Handle, Position } from 'reactflow';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import { Heading5, Variant } from '~/components/Typography/index';
import { FlowAction, useGetAppStatusRealworksQuery } from '~/graphql/types';
import { ClientFlowActionRealworksSendContact } from '~/graphql/types.client';
import useApp from '~/hooks/useApp';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { issuesByActionId } from '~/scenes/Automation/v2/state/flowIssues';
import getActiveTokenOptions from '~/util/getActiveTokenOptions';
import getCardHeading from '../../../../utils/getCardHeading';
import Issue from '../../../Issues/InCard';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';

export type Props = Omit<
  CardProps<ClientFlowActionRealworksSendContact>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const SendRealworksContactActionCard: React.FC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const issues = useRecoilValue(issuesByActionId(data.id));
    const { id: accountId } = useCurrentAccount();

    const { data: realworksData } = useGetAppStatusRealworksQuery({
      variables: {
        accountId,
      },
    });

    const { app } = useApp('AppStatus_Realworks');

    const realworksStatus = realworksData?.getAppStatusRealworks;
    const { tokenContainerId } = data.action;

    const tokenOptions = realworksStatus
      ? getActiveTokenOptions(realworksStatus)
      : [];

    const tokenName = tokenOptions.find(
      option => option.payload == tokenContainerId,
    )?.label;

    const realworksCardData = {
      ...data,
      action: {
        ...data.action,
        appSlug: app?.slug ? `/-/apps/setup/${app.slug}` : null,
      },
    };
    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.RealworksSendContact}
          heading={getCardHeading(FlowAction.RealworksSendContact)}
          selected={selected}
          disabled={disabled}
          data={realworksCardData}
        >
          {
            <JustificationContainer direction="column">
              <WithMargins>
                <Heading5 inline variant={Variant.primary}>
                  Vestiging:{' '}
                </Heading5>
                {isNil(tokenName) ? '-' : tokenName}
              </WithMargins>
              {issues &&
                issues.map(issue => <Issue key={issue.message} {...issue} />)}
            </JustificationContainer>
          }
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

const WithMargins = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

export default SendRealworksContactActionCard;
