import { FlowParameterMappingFieldsFragment } from '~/graphql/types';
import { FlowParameterMappingParameter } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/FlowParameterMapping/types.flow';
import { assertNever } from '~/util/assertion';
import {
  asBooleanParameterValue,
  asNumberParameterValue,
} from '../ParameterValue';
import {
  emptyStringPointerParameterValue,
  asStringParameterValue,
} from '../ParameterValue/String';
import cleanedFilename from '~/util/cleanedFilename';

export default (
  fragment: FlowParameterMappingFieldsFragment['mapping'] | null | undefined,
): FlowParameterMappingParameter => {
  if (fragment == null) return emptyStringPointerParameterValue();

  switch (fragment.__typename) {
    case 'Flow_Parameter_String_Pointer':
    case 'Flow_Parameter_String_Primitive':
      return asStringParameterValue(fragment);
    case 'Flow_Parameter_Boolean_Pointer':
    case 'Flow_Parameter_Boolean_Primitive':
      return asBooleanParameterValue(fragment);
    case 'Flow_Parameter_Number_Pointer':
    case 'Flow_Parameter_Number_Primitive':
      return asNumberParameterValue(fragment);
    default:
      return assertNever(fragment, cleanedFilename(__filename));
  }
};
