/**
 * Root component imported by gatsby in wrapRootElement
 * @see gatsby-browser.js
 */

import React from 'react';
import FlagProvider from '@unleash/proxy-client-react';
import GlobalStyle from '~/theme/GlobalStyle';
import theme from '~/theme';
import { ThemeProvider } from 'styled-components';
import { RecoilRoot } from 'recoil';
import AppSyncProvider from './AppSync';
import { ErrorBoundary } from '@sentry/gatsby';

import RouterTransitionContextProvider from '~/contexts/RouterTransitionContextProvider';
import LocaleContext from '~/contexts/LocaleContext';
import AppErrorScreen from '~/components/AppErrorScreen';
import 'normalize.css';

// TODO: Move these into the components that require these styles
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'react-day-picker/lib/style.css';

import { isProduction } from '~/util';

const Root: React.FC<{ element: React.ReactNode }> = ({ element }) => (
  <ThemeProvider theme={theme}>
    <ErrorBoundary fallback={<AppErrorScreen />}>
      <FlagProvider
        config={{
          url: process.env.UNLEASH_URL as string,
          clientKey: process.env.UNLEASH_SECRET as string,
          appName: 'sfa-app',
          // env.STAGE_NAME for production is set to `prod` however that does not match the name of the Gitlab environment
          environment: isProduction() ? 'production' : process.env.STAGE_NAME,
        }}
      >
        <AppSyncProvider>
          <RecoilRoot>
            <LocaleContext.Provider value={{ locale: 'nl-NL' }}>
              <RouterTransitionContextProvider>
                <GlobalStyle />
                {element}
              </RouterTransitionContextProvider>
            </LocaleContext.Provider>
          </RecoilRoot>
        </AppSyncProvider>
      </FlagProvider>
    </ErrorBoundary>
  </ThemeProvider>
);

const WrapRootElement = ({ element }: { element: React.ReactElement }) => (
  <Root element={element} />
);

export default WrapRootElement;
