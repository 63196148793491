import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import { DHEditor } from '~/components/PluginsEditor/types';
import convertHtmlToSlateFragment from '~/components/PluginsEditor/utils/signatures/convertHtmlToSlateFragment';
import removeWhiteSpaceInHtml from './util/removeWhiteSpaceInHtml';

const insertHtml = (
  editor: DHEditor,
  html: string,
  customElements: Array<ELEMENTS>,
) => {
  const whiteSpaceRemoved = removeWhiteSpaceInHtml(html);

  const fragment = convertHtmlToSlateFragment({
    html: whiteSpaceRemoved,
    markAsPendingImage: true,
    customElements,
  });

  /**
   *  Add an empty line before and after the html to allow focusing on the editor
   *  also makes it easy to delete a table and have a clean empty line
   * */
  Transforms.insertNodes(editor, [
    /** Do not use the same object ref for same elements because under the hook there's a
     * WeakMap that relies on a unique object reference. If you encounter "Two chilren with
     * the same key" error of React this is probably why it is happening. */
    {
      type: ELEMENTS.DIV,
      children: [{ text: '' }],
    },
    ...fragment,
    {
      type: ELEMENTS.DIV,
      children: [{ text: '' }],
    },
  ]);

  Transforms.move(editor, {
    distance: 1,
    unit: 'offset',
  });
  ReactEditor.focus(editor);
};

export default insertHtml;
