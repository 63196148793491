import React from 'react';
import { useSlate } from 'slate-react';
import BaseButton from '../components/Base';
import { tooltipText } from '../text';
import insertVariable from '~/components/PluginsEditor/commands/modify/variable';

export type Props = {
  dataTestId?: string;
};

const InsertVariableButton: React.FC<Props> = ({ dataTestId }) => {
  const editor = useSlate();

  return (
    <BaseButton
      icon="user-plus"
      onClick={e => {
        e.preventDefault();
        insertVariable(editor);
      }}
      tooltipMessage={tooltipText.insertVariable}
      dataTestId={dataTestId}
    />
  );
};

export default InsertVariableButton;
