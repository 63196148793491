import React from 'react';
import styled from 'styled-components';

import { Flow } from '~/scenes/Automation/Flows/types.flow';

import { UpdateFlowMutation } from '~/graphql';
import mutation from '~/graphql/mutation/UpdateFlow';
import flowPropToFlowInput from '../util/flowPropToFlowInput';
import extractFlowDetailsFromFlow from '../util/extractFlowDetailsFromFlow';
import FooterSaveBar from '~/components/SaveBar/FooterSaveBar';
import flowQueryToFlowProp from '../util/flowQueryToFlowProp';
import FlowDetailsHeader from './FlowDetailsHeader';
import renderActionTree from '~/scenes/Automation/Flows/Actions/util/renderActionTree';
import FlowContextProvider from '~/scenes/Automation/Flows/FlowContextProvider';
import TEST_ID from './FlowDetailsComponent.testid';
import cleanedFilename from '~/util/cleanedFilename';
import getActionLabel from '../util/getActionLabel';
import FlowActionTrees from './FlowActionTrees/';
import { FlowActionSubscriberList } from '~/components/util/SubscriberList';
import AccountContext from '~/contexts/AccountContext';
import RunXTimesContainer from '~/scenes/Automation/Flows/components/RunXtimesContainer';
import { Checkbox, InputGroup } from '~/components/Inputs';
import { ActionTreeNode } from './FlowActionTrees/types.flow';

const validationErrorMsgForActions = actions => {
  if (actions.length === 1) {
    return `${getActionLabel(
      actions[0].positionString,
    )} kan niet worden opgeslagen`;
  } else {
    return `De stappen (${actions
      .map(action => getActionLabel(action.positionString))
      .join(', ')}) kunnen niet worden opgeslagen`;
  }
};

const text = {
  validationErrorMsgForActions,
  checkbox: 'Activeer deze flow na opslaan',
};
type Props = {
  initialFlow: Flow;
  showEnabledFlowModal: () => void;
};
type State = {
  isInitialised: boolean;
  showValidation: boolean;
  validationErrorMessage: string | null;
  flow: Flow;
  resetCounter: number;
  hasChanges: boolean;
};
class FlowDetailsComponent extends React.Component<Props, State> {
  private _isMounted = false;

  private set isMounted(mounted: boolean) {
    this._isMounted = mounted;
  }

  private get isMounted() {
    return this._isMounted;
  }

  constructor(props: Props) {
    super(props);

    this._isMounted = true;
    this.state = {
      isInitialised: false,
      showValidation: false,
      validationErrorMessage: null,
      flow: props.initialFlow,
      resetCounter: 0,
      hasChanges: false,
    };
  }

  componentWillUnmount = () => {
    this.isMounted = false;
  };

  setChanges = (changes: boolean) => {
    this.setState({
      hasChanges: changes,
      validationErrorMessage: null,
    });
  };

  setFlow = (newFlow: Flow) => {
    this.setState({
      flow: newFlow,
      hasChanges: false,
    });
  };

  setInitialised = (initialised: boolean) => {
    this.setState({
      isInitialised: initialised,
    });
  };

  setValidationErrorMsg = (newMessage: string | null) => {
    this.setState({
      showValidation: newMessage == null ? false : true,
      validationErrorMessage: newMessage,
    });
  };

  setRunTimes = (value: number | null) => {
    this.setState({
      hasChanges: true,
      flow: { ...this.state.flow, maximumFlowRun: value },
    });
  };

  cancel = () => {
    this.setState(prevState => ({
      isInitialised: false,
      resetCounter: prevState.resetCounter + 1,
      hasChanges: false,
      showValidation: false,
    }));
  };

  onCheckboxChecked = (value: boolean) => {
    this.setState({
      flow: { ...this.state.flow, enabled: value },
    });
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const {
      isInitialised: nextIsInitialised,
      showValidation: nextShowValidation,
      validationErrorMessage: nextValidationErrorMessage,
      resetCounter: nextResetCounter,
      hasChanges: nextHasChanges,
      flow: nextFlow,
    } = nextState;

    const {
      isInitialised,
      showValidation,
      validationErrorMessage,
      resetCounter,
      hasChanges,
      flow,
    } = this.state;

    return (
      isInitialised !== nextIsInitialised ||
      showValidation !== nextShowValidation ||
      validationErrorMessage !== nextValidationErrorMessage ||
      resetCounter !== nextResetCounter ||
      hasChanges !== nextHasChanges ||
      flow.name != nextFlow.name ||
      flow.enabled != nextFlow.enabled
    );
  }

  render() {
    const {
      flow,
      resetCounter,
      isInitialised,
      hasChanges,
      showValidation,
      validationErrorMessage,
    } = this.state;
    const { id, name, initialActionFragments, maximumFlowRun, enabled } = flow;
    return (
      <AccountContext.Consumer>
        {({ account }) => (
          <Container
            data-testid={TEST_ID.CONTAINER}
            data-objectid={id}
            data-isinitialised={isInitialised}
          >
            <UpdateFlowMutation
              name={cleanedFilename(__filename)}
              mutation={mutation}
            >
              {(updateFlow, { loading }) => (
                <FlowActionTrees
                  accountId={account.id}
                  key={`tree-${resetCounter}`}
                  initialActions={initialActionFragments}
                >
                  {({
                    actionTrees,
                    announceChanges,
                    assignSubscribers,
                    traverseAllNodes,
                    getPositionStringForId,
                  }) => {
                    if (actionTrees.length > 1) {
                      throw new Error(
                        `${cleanedFilename(
                          __filename,
                        )} | We do not support flows with ${
                          actionTrees.length
                        } start actions`,
                      );
                    }

                    return (
                      <>
                        <FlowDetailsHeader
                          id={id}
                          name={name}
                          hasChanges={hasChanges}
                          onNameChange={async newName => {
                            await updateFlow({
                              variables: {
                                accountId: account.id,
                                flow: {
                                  ...flowPropToFlowInput(
                                    extractFlowDetailsFromFlow(flow),
                                    actionTrees,
                                  ),
                                  name: newName,
                                },
                              },
                            });

                            if (!this.isMounted) return;

                            this.setState(prevState => ({
                              flow: {
                                ...prevState.flow,
                                name: newName,
                              },
                            }));
                          }}
                        />
                        <RunXTimesContainer
                          runTimes={maximumFlowRun ? maximumFlowRun : null}
                          onSetRunTimes={this.setRunTimes}
                        />
                        <FlowActionSubscriberList
                          key={`subscriber-${resetCounter}`}
                          onSubscribersChanged={newSubscribers => {
                            assignSubscribers(newSubscribers);

                            if (
                              newSubscribers.length ===
                              initialActionFragments.length
                            ) {
                              this.setInitialised(true);
                            }
                          }}
                        >
                          {({ subscribeTo, unsubscribeFrom }) => (
                            <FlowContextProvider
                              isInitialised={isInitialised}
                              showValidation={showValidation}
                              getPositionStringForId={getPositionStringForId}
                            >
                              {renderActionTree(
                                actionTrees[0],
                                subscribeTo,
                                unsubscribeFrom,
                                resetCounter,
                                options => {
                                  if (
                                    options &&
                                    options.noDataChange === true
                                  ) {
                                    // do nothing
                                  } else {
                                    this.setChanges(true);
                                  }

                                  announceChanges(options);
                                },
                                undefined,
                                false,
                              )}
                              {hasChanges && (
                                <FooterSaveBar
                                  key={'flow-details-footer'}
                                  disableSave={
                                    loading || validationErrorMessage != null
                                  }
                                  onSave={async () => {
                                    const actionsWithErrors: Array<ActionTreeNode> =
                                      [];

                                    traverseAllNodes(actionTree => {
                                      if (actionTree.subscriber) {
                                        if (!actionTree.subscriber.validate()) {
                                          actionsWithErrors.push(actionTree);
                                        }
                                      }
                                    });

                                    const hasValidationErrors =
                                      actionsWithErrors.length > 0;

                                    const previousFlowStatus =
                                      this.props.initialFlow.enabled;

                                    if (!hasValidationErrors) {
                                      const result = await updateFlow({
                                        variables: {
                                          accountId: account.id,
                                          flow: {
                                            ...flowPropToFlowInput(
                                              extractFlowDetailsFromFlow(flow),
                                              actionTrees,
                                            ),
                                          },
                                        },
                                      });

                                      if (!this.isMounted) return;

                                      if (result && result.data) {
                                        const newFlow = flowQueryToFlowProp(
                                          result.data.updateFlow,
                                        );

                                        this.setFlow(newFlow);

                                        if (
                                          flow.enabled &&
                                          previousFlowStatus !== true
                                        ) {
                                          this.props.showEnabledFlowModal();
                                        }
                                      }
                                    } else {
                                      this.setValidationErrorMsg(
                                        text.validationErrorMsgForActions(
                                          actionsWithErrors,
                                        ),
                                      );
                                    }
                                  }}
                                  onCancel={this.cancel}
                                  saveErrorMessage={validationErrorMessage}
                                >
                                  <InputGroup>
                                    <Checkbox
                                      // defaultChecked={enabled}
                                      onChange={() => {
                                        this.onCheckboxChecked(!enabled);
                                      }}
                                      value={enabled}
                                      label={text.checkbox}
                                      name="saveAndEnableFlow"
                                      data-testid={
                                        TEST_ID.SAVE_AND_ENABLE_CHECKBOX
                                      }
                                    />
                                  </InputGroup>
                                </FooterSaveBar>
                              )}
                            </FlowContextProvider>
                          )}
                        </FlowActionSubscriberList>
                      </>
                    );
                  }}
                </FlowActionTrees>
              )}
            </UpdateFlowMutation>
          </Container>
        )}
      </AccountContext.Consumer>
    );
  }
}

const Container = styled.div<{}>`
  /* Will be removed with flows v1 */
  margin-bottom: 64px;
`;

export default FlowDetailsComponent;
