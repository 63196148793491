import gql from 'graphql-tag';

import FlowParameterBooleanFields from './FlowParameterBooleanFields';

export default gql`
  fragment FlowConditionBooleanPrimitiveFields on Flow_ConditionPrimitive_Boolean {
    __typename

    ... on Flow_ConditionPrimitive_Boolean_Eq {
      booleanValue: value {
        ...FlowParameterBooleanFields
      }
    }
  }

  ${FlowParameterBooleanFields}
`;
