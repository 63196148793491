import gql from 'graphql-tag';

import FlowParameterTemplateStringFields from './FlowParameterTemplateStringFields';

export default gql`
  fragment FlowActionRealworksSendContactFields on Flow_Action_Realworks_SendContact {
    __typename
    id
    accountId
    parentId
    flowBlueprintId
    tokenContainerId

    description {
      ...FlowParameterTemplateStringFields
    }
  }

  ${FlowParameterTemplateStringFields}
`;
