import React from 'react';
import styled from 'styled-components';

import { NamedEmail } from '~/util/namedEmailFormatter';

import NamedEmailLabel from './NamedEmailLabel';
import RowLabel from './RowLabel';

const text = {
  receiverLabel: 'Aan:',
};
type Props = {
  namedEmail: NamedEmail;
  dataTestId: string;
};

const ReceiverRow: React.FC<Props> = ({
  namedEmail,
  dataTestId,
}): React.ReactElement => {
  const { name, email } = namedEmail;

  return (
    <>
      <ReceiverLabel>{text.receiverLabel}</ReceiverLabel>
      <ReceiverInput data-testid={dataTestId}>
        <NamedEmailLabel name={name} email={email} />
      </ReceiverInput>
    </>
  );
};

const ReceiverLabel = styled(RowLabel)<{}>`
  grid-row: receiver-start / receiver-end;
  grid-column: label-start / label-end;
`;

const ReceiverInput = styled.div<{}>`
  grid-row: receiver-start / receiver-end;
  grid-column: input-start / input-end;
`;

export default ReceiverRow;
