import { FlowConditionTimeElapsed } from './types.flow';

import { emptyNumberPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Number';
import { FLOW_CONDITION_TYPES } from '../constants';

export default (): Omit<FlowConditionTimeElapsed, 'id'> => ({
  type: FLOW_CONDITION_TYPES.TIME_ELAPSED.type,
  minute: emptyNumberPrimitiveParameterValue(),
  hour: emptyNumberPrimitiveParameterValue(),
  day: emptyNumberPrimitiveParameterValue(),
  month: emptyNumberPrimitiveParameterValue(),
  year: emptyNumberPrimitiveParameterValue(),
});
