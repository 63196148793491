import React from 'react';
import TipBanner, { TipId } from '~/components/TipBanner';
import { Body } from '~/components/Typography/index';
import Link from '~/components/Link';
import ChatLink from '~/components/TipBanner/ChatLink';

export type Props = {
  dataTestId?: string;
  tipId: TipId;
};

const RealworksHelpBlock: React.FC<Props> = ({ dataTestId, tipId }) => (
  <TipBanner dataTestId={dataTestId} id={tipId} margin={['xl', null]}>
    <Body withoutMargin>
      Je kunt meer lezen over hoe je de koppeling moet instellen in onze{' '}
      <Link to="https://help.dathuis.nl/">kennisbank</Link>. Kom je er niet uit?{' '}
      <ChatLink text="Ik heb hulp nodig" linkText="Start een chat met ons." />
    </Body>
  </TipBanner>
);

export default RealworksHelpBlock;
