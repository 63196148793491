import { Flow__Input, Flow_Action__Input } from '~/graphql/types';
import { FlowDetails } from '~/scenes/Automation/Flows/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';

import cleanedFilename from '~/util/cleanedFilename';

const flowPropToFlowInput = (
  flow: FlowDetails,
  actionTrees: Array<ActionTreeNode>,
): Flow__Input => {
  const { id, _v, accountId, name, enabled, maximumFlowRun } = flow;

  const flowAsInput = {
    id,
    _v,
    accountId,
    name,
    enabled,
    maximumFlowRun,
  };

  const inputActions: Array<Flow_Action__Input> = [];

  const addTreeNodeToInputActions = actionTreeNode => {
    const subscriber = actionTreeNode.subscriber;

    if (subscriber == null) {
      throw new Error(
        `${cleanedFilename(__filename)} | Action with id ${
          actionTreeNode.id
        } does not have a subscriber!`,
      );
    }

    inputActions.push(
      subscriber.asInput(actionTreeNode, {
        accountId,
        flowBlueprintId: id,
      }),
    );

    actionTreeNode.children.forEach(addTreeNodeToInputActions);
  };

  actionTrees.forEach(addTreeNodeToInputActions);

  return {
    ...flowAsInput,
    Actions: inputActions,
  };
};

export default flowPropToFlowInput;
