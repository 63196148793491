import { UserParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

import { PARAMETER_VALUE_TYPE } from '../constants';

export default (parameterValue: UserParameterValue): boolean => {
  switch (parameterValue.type) {
    case PARAMETER_VALUE_TYPE.USER_PRIMITIVE:
      return parameterValue.value == null;
    case PARAMETER_VALUE_TYPE.USER_POINTER:
      return (
        parameterValue.variable == null || parameterValue.variable.field == null
      );
    default:
      return assertNever(parameterValue, `${cleanedFilename(__filename)}`);
  }
};
