import { FlowActionProps } from '~/scenes/Automation/Flows/Actions/types.flow';
import { asSetContactDetailsProps } from '~/scenes/Automation/Flows/Actions/SetContactDetailsAction';
import { asStartActionProps } from '~/scenes/Automation/Flows/Actions/StartAction';
import { asWaitActionProps } from '~/scenes/Automation/Flows/Actions/WaitAction';
import { asCreateTaskActionProps } from '~/scenes/Automation/Flows/Actions/CreateTaskAction';
import { asAddContactTagActionProps } from '~/scenes/Automation/Flows/Actions/AddContactTagAction';
import { asDeleteContactTagActionProps } from '~/scenes/Automation/Flows/Actions/DeleteContactTagAction';
import { asSendEmailPlainActionProps } from '~/scenes/Automation/Flows/Actions/SendEmailPlainAction';
import { asIfElseActionProps } from '~/scenes/Automation/Flows/Actions/IfElseAction';
import { asAssignContactActionProps } from '~/scenes/Automation/Flows/Actions/AssignContactAction';
import { HandledFlowActionFieldsFragment } from '~/graphql/types.client';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';
import { asRealworksSendContactActionProps } from '../RealworksSendContactAction';
import { asZapierTriggerActionProps } from '../ZapierTriggerAction';

export default (
  actionFragment: HandledFlowActionFieldsFragment,
): FlowActionProps => {
  switch (actionFragment.__typename) {
    case 'Flow_Action_Contact_Details':
      return asSetContactDetailsProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_Start':
      return asStartActionProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_Wait':
      return asWaitActionProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_Task_Create':
      return asCreateTaskActionProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_Contact_AddTag':
      return asAddContactTagActionProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_Contact_DeleteTag':
      return asDeleteContactTagActionProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_SendEmail_Plain':
      return asSendEmailPlainActionProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_IfElse':
      return asIfElseActionProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_Contact_Assign':
      return asAssignContactActionProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_Realworks_SendContact':
      return asRealworksSendContactActionProps(actionFragment, {
        id: actionFragment.id,
      });
    case 'Flow_Action_Zapier_Trigger':
      return asZapierTriggerActionProps(actionFragment, {
        id: actionFragment.id,
      });
    default:
      return assertNever(actionFragment, cleanedFilename(__filename));
  }
};
