import { FlowParameterEventFieldsFragment } from '~/graphql/types';
import { AppVBOWaardecheckAppraisalRequestPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

import { PARAMETER_VALUE_TYPE } from '../../../constants';
import emptyVBOWaardecheckAppraisalRequestEventParameterValue from './emptyVBOWaardecheckAppraisalRequestEventParameterValue';

export default (
  eventVBOWaardecheckAppraisalRequestParameter:
    | Extract<
        FlowParameterEventFieldsFragment,
        {
          __typename: 'Flow_Parameter_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Pointer';
        }
      >
    | null
    | undefined,
): AppVBOWaardecheckAppraisalRequestPointerParameterValue => {
  if (eventVBOWaardecheckAppraisalRequestParameter == null) {
    return emptyVBOWaardecheckAppraisalRequestEventParameterValue();
  }
  switch (eventVBOWaardecheckAppraisalRequestParameter.__typename) {
    case 'Flow_Parameter_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Pointer':
      return {
        type: PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_VBOWAARDECHECK_APPRAISALREQUEST_POINTER,
        variable: {
          name: eventVBOWaardecheckAppraisalRequestParameter.variable,
          field: eventVBOWaardecheckAppraisalRequestParameter.field
            ? {
                name: eventVBOWaardecheckAppraisalRequestParameter.field,
              }
            : null,
        },
      };
    default:
      return assertNever(
        eventVBOWaardecheckAppraisalRequestParameter.__typename,
        cleanedFilename(__filename),
      );
  }
};
