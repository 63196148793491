import gql from 'graphql-tag';

export default gql`
  mutation InsertAppValuationReport(
    $name: String!
    $accountId: ID!
    $officeId: ID
    $assignedUserId: ID
    $copyFromAppValuationReportId: ID
  ) {
    insertAppValuationReport(
      name: $name
      accountId: $accountId
      officeId: $officeId
      assignedUserId: $assignedUserId
      copyFromAppValuationReportId: $copyFromAppValuationReportId
    ) {
      id
      name
    }
  }
`;
