import React, { useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { useRecoilValue } from 'recoil';
import styled, { css, useTheme } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import { scrollBarStyles } from '~/components/OverflowScrollWrapper';
import useSize from '~/hooks/useSize';
import flowIssues from '~/scenes/Automation/v2/state/flowIssues';

import { CONTROLS_LAYER } from '../../../constants/zIndexes';
import AsideHeader from '../../AsideHeader';
import Issue from './components/Issue';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const Issues: React.FC<Props> = ({
  dataTestId,
  expanded = true,
  onToggleExpand,
  ...rest
}) => {
  const issues = useRecoilValue(flowIssues);
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const [size, recalculate] = useSize(listContainerRef);
  const theme = useTheme();
  const spring = useSpring({
    height: expanded ? size.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  useEffect(() => {
    recalculate();
  }, [issues, recalculate]);

  const allIssues = Object.values(issues).reduce(
    (allIssues, issues) => [...allIssues, ...issues],
    [],
  );

  return (
    <Container data-testid={dataTestId} {...rest}>
      <HeadingContainer align="center" onClick={() => onToggleExpand()}>
        <AsideHeader
          dataTestId={TEST_ID.ASIDE_HEADER}
          heading="Foutherstel"
          icon="exclamation"
        />
        {allIssues.length !== 0 && <IssueIndicator />}
      </HeadingContainer>
      <OverflowContainer style={spring}>
        <Inner data-testid={TEST_ID.INNER_CONTAINER} ref={listContainerRef}>
          {allIssues.length === 0 && <p>Geen fouten</p>}
          {allIssues.map((issue, index) => (
            <Issue
              dataTestId={TEST_ID.ISSUE}
              key={`${issue.actionType}-${issue.message}-${index}`}
              {...issue}
            />
          ))}
        </Inner>
      </OverflowContainer>
    </Container>
  );
};

const IssueIndicator = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.full};
    background: ${theme.color('danger')};
    height: ${theme.space('m')};
    width: ${theme.space('m')};
    margin-left: ${theme.space('xl')};
  `,
);

const HeadingContainer = styled(JustificationContainer)`
  position: relative;
  cursor: pointer;
`;

const Container = styled.aside(
  ({ theme }) => css`
    background: ${theme.color('white')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('grey', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};
    z-index: ${CONTROLS_LAYER};
    user-select: none;
    width: 100%;
  `,
);

const OverflowContainer = styled(animated.div)<{}>`
  overflow: hidden;
`;

const Inner = styled(animated.div)(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
    display: grid;
    row-gap: ${theme.spacing('xxs')};
    max-height: 35vh;
    overflow-y: scroll;

    ${scrollBarStyles}
  `,
);

export default Issues;
