import { FlowConditionAppValuationRequestNew } from './types.flow';

import { PARAMETER_VALUE_TYPE } from '../../FlowParameter/ParameterValue/constants';
import { FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationRequest_New_Fragment } from '~/graphql/types';

/**
 * We ignore the app pointer for now as we don't do anything with it. Is an issue for later
 */
export default (
  condition: FlowConditionFields_Flow_Condition_Event_Contact_App_ValuationRequest_New_Fragment,
): Omit<FlowConditionAppValuationRequestNew, 'id'> => {
  const { app } = condition;

  if (
    app != null &&
    app.__typename !== PARAMETER_VALUE_TYPE.APP_VALUATIONREPORT_PRIMITIVE
  ) {
    throw new Error('Should not occur: App as pointer is not yet supported');
  }

  return {
    type: 'Flow_Condition_Event_Contact_App_ValuationRequest_New',
    app: app ?? null,
  };
};
