import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '../JustificationContainer';

export type ActivityTabProps = {
  label: string;
  datatestId: string;
};

type ActivityTabComponent = ReactElement<ActivityTabProps>;

export type Props = {
  /** Adds a border to the tab list */
  bordered?: boolean;
  initiallySelectedTab?: number;
  children: Array<ActivityTabComponent | null>;
};

const ActivityTabs = ({ bordered, initiallySelectedTab, children }: Props) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState<number>(
    initiallySelectedTab || 0,
  );

  const selectTab = (idx: number) => {
    setSelectedTabIdx(idx);
  };
  if (React.Children.count(children) === 1) {
    return <TabContainer>{children}</TabContainer>;
  } else {
    return (
      <Container>
        <TabBar bordered={bordered}>
          {React.Children.map(children, (child: ActivityTabComponent, idx) => {
            const childProps = child.props;

            return (
              <TabHeader
                key={`activity-tab-bar-${childProps.datatestId}`}
                onClick={() => selectTab(idx)}
                active={idx === selectedTabIdx}
                dataTestId={`activity-tab-${childProps.datatestId}`}
                align="center"
                justification="center"
              >
                <span>{childProps.label}</span>
              </TabHeader>
            );
          })}
        </TabBar>
        <TabContainer>{children[selectedTabIdx]}</TabContainer>
      </Container>
    );
  }
};

type TabHeaderProps = {
  active: boolean;
};

const HOVER_LINE_HEIGHT = '6px';
const ACTIVE_LINE_HEIGHT = '4px';

const TabHeader = styled(JustificationContainer)<TabHeaderProps>`
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 0;
    display: block;
    transition: all ease 0.3s;
    background: ${({ theme }) => theme.color('primary', 'light')};
  }

  ${({ theme }) => css`
    margin-right: ${theme.space('m')};
  `};

  ${({ active, theme }) => {
    if (active) {
      return css`
        color: ${theme.color('primary', 'light')};

        &::after {
          height: ${ACTIVE_LINE_HEIGHT};
        }
      `;
    }

    return css`
      &:hover {
        color: ${theme.color('primary', 'light')};

        &::after {
          height: ${HOVER_LINE_HEIGHT};
        }
      }
    `;
  }};
`;

const TabContainer = styled.div<{}>`
  grid-row: tabs-end / end;
  justify-self: stretch;

  ${({ theme }) => css`
    padding: ${theme.space('l')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('grey', 'light')};

    border-bottom-right-radius: ${theme.getTokens().border.radius.s};
    border-bottom-left-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('white')};
  `};
`;

type TabBarProps = {
  bordered?: boolean;
};
const TabBar = styled.div<TabBarProps>`
  grid-row: start / tabs-end;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ${({ theme, bordered }) => {
    if (bordered) {
      return css`
        border: ${theme.getTokens().border.width.s} solid
          ${theme.color('grey', 'light')};
        border-bottom-width: 0px;
      `;
    }
    return null;
  }};

  ${({ theme }) => css`
    border-top-right-radius: ${theme.getTokens().border.radius.s};
    border-top-left-radius: ${theme.getTokens().border.radius.s};
    padding: 0 ${theme.space('l')};
    background-color: ${theme.color('white')};
  `};
`;

const Container = styled.div<{}>`
  display: grid;

  ${({ theme }) => css`
    grid-template-rows: [start] ${theme.space('xxxl')} [tabs-end] min-content [end];
    grid-template-columns: auto;
    border-radius: ${theme.getTokens().border.radius.s};
    box-shadow: ${theme.getTokens().boxShadow.card};
  `};
`;

export default ActivityTabs;
