import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Link } from '~/components/Link';
import MetaTags from 'react-meta-tags';

import { Address, Office_Update, Signature } from '~/graphql/types';
import { ImgDefinition, Mailboxes } from '~/graphql/types.client.flow';
import { ListItemForDeletion } from '~/components/Modals/components/ListItemCard';

import { UpdateOfficeMutation } from '~/graphql';
import mutation from '~/graphql/mutation/UpdateOffice';
import LinkButton from '~/components/Buttons/LinkButton';
import OfficeDetailForm from './OfficeDetailForm';
import OfficeProfileHeader from '~/components/ProfileHeader/OfficeProfileHeader';
import Catalog from '~/Catalog';
import SynchronisedMailboxes from '../../components/SynchronisedMailboxes';
import cleanedFilename from '~/util/cleanedFilename';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import Validation from '~/util/Validation';
import { ActionButtons, Header } from '~/scenes/Settings/Profile/Profile.style';
import DetailsActionList from '~/components/DetailsPage/DetailsActionList';
import DeleteOfficeModal from '~/components/Modals/DeleteOfficeModal';
import ErrorModal from '~/components/Alerts/ErrorModal';
import { SynchroniseEntityType } from '~/scenes/External/Nylas/util';
import DetailsBlock from '~/components/DetailsBlock';
import SignatureContainer from '~/components/SignatureContainer';
import formatToastMessage, {
  FormattedToastMessage,
} from '~/util/formatToastMessage';
import withAddToastMessage from '~/components/ToastsContainer/withAddToastMessage';
import Icon from '~/components/Icon';

const text = {
  imageUploadError: Catalog.imageUploadErrorMessage,
  officeUpdateError: Catalog.genericUnknownErrorMessage,
  userList: 'Gebruikerslijst',
  officesListLink: 'Vestigingen',
  deleteOffice: 'Verwijder vestiging',
  errorModalTitle: 'Verwijderen niet mogelijk',
  errorModalMessage: 'Een account moet minimaal één vestiging hebben.',
  emailIntegration: 'E-mailintegratie',
  signature: 'Handtekening vestiging',
};
export type UpdateOfficeType = {
  id: string;
  name: string;
  email: string | null;
  phone: string | null;
  img: ImgDefinition | null;
  address: Omit<Address, 'countryCode'> | null;
};
type Props = {
  accountId: string;
  office: UpdateOfficeType;
  mailboxes: Mailboxes;
  canEdit: boolean;
  onSuccess: () => void;
  onSuccessfulMailboxDelete: () => void;
  onSuccessfulAliasVerificationStart: () => void;
  onSuccessfulAliasVerificationEnd: () => void;

  /** List of users to delete from the office */
  officeUsersList: Array<ListItemForDeletion>;

  /** Number of offices in account */
  officesLength: number;
  mayDeleteOffice: boolean;
  signature: Signature | null;

  /** Refetch getOffice query */
  refetch: () => void;

  addToast: (value: any) => FormattedToastMessage;
};
type State = {
  showDeleteOfficeModal: Array<ListItemForDeletion> | null;
  showErrorModal: boolean;
};
class UpdateOfficeComponent extends Component<Props, State> {
  state: State = {
    showDeleteOfficeModal: null,
    showErrorModal: false,
  };

  onSuccess = () => {
    this.props.onSuccess();
  };

  onImageUploadError = () =>
    this.props.addToast(formatToastMessage(text.imageUploadError, 'danger'));

  onOfficeUpdateError = () =>
    this.props.addToast(formatToastMessage(text.officeUpdateError, 'danger'));

  handleDeleteOfficeModalAction = (
    usersList: Array<ListItemForDeletion> | null,
  ) => {
    this.setState({
      showDeleteOfficeModal: usersList,
    });
  };

  render() {
    const { showDeleteOfficeModal, showErrorModal } = this.state;
    const {
      office,
      canEdit,
      accountId,
      onSuccessfulMailboxDelete,
      onSuccessfulAliasVerificationStart,
      onSuccessfulAliasVerificationEnd,
      mailboxes,
      officeUsersList,
      officesLength,
      mayDeleteOffice,
      signature,
      refetch,
    } = this.props;
    const { phone, email, img, id, address, name } = office;
    const { addition, city, houseNumber, postcode, street } = address || {};
    const filteredMailboxes = mailboxes.filter(
      mailbox =>
        mailbox.__typename === 'OfficeMailbox' ||
        mailbox.__typename === 'OfficeMailboxAlias',
    );

    return (
      <UpdateOfficeMutation
        name={cleanedFilename(__filename)}
        mutation={mutation}
        onError={this.onOfficeUpdateError}
        onCompleted={this.onSuccess}
      >
        {(updateOffice, { loading: mutationLoading }) => {
          const updateOfficeName = (name: string) =>
            updateOffice({
              variables: {
                accountId,
                officeId: id,
                update: {
                  name,
                },
              },
            });

          const onFormSubmit = fields => {
            const {
              street,
              postcode,
              city,
              houseNumber,
              addition,
              email = '',
              phone,
            } = fields;

            const update: Office_Update = {
              email: email.toLocaleLowerCase(),
              phone,
              address: undefined,
            };
            if (
              Validation.String.isNonEmptyString(street) ||
              Validation.String.isNonEmptyString(postcode) ||
              Validation.String.isNonEmptyString(city) ||
              houseNumber != null
            ) {
              update.address = {
                street,
                postcode,
                city,
                addition,
                houseNumber,
              };
            }

            return updateOffice({
              variables: {
                accountId,
                officeId: id,
                update,
              },
            });
          };

          return (
            <ContentContainerDefault
              data-testid="office-details-page"
              data-objectid={id}
              breadcrumbs={[
                {
                  to: '/-/settings/offices',
                  label: text.officesListLink,
                },
                { label: office.name },
              ]}
            >
              <MetaTags>
                <title>{name}</title>
              </MetaTags>

              <Header data-testid="office-details-header">
                <OfficeProfileHeader
                  edit={canEdit}
                  office={{
                    id,
                    img,
                    name,
                  }}
                  onSuccess={this.onSuccess}
                  onImageUploadError={this.onImageUploadError}
                  updateName={updateOfficeName}
                />

                {mayDeleteOffice && (
                  <ActionButtons>
                    <DetailsActionList
                      options={[
                        {
                          label: text.deleteOffice,
                          onClick: () => {
                            if (officesLength === 1) {
                              this.setState({
                                showErrorModal: true,
                              });
                            } else {
                              this.handleDeleteOfficeModalAction(
                                officeUsersList,
                              );
                            }
                          },
                          type: 'DELETE',
                          key: 'delete-office',
                        },
                      ]}
                    />
                  </ActionButtons>
                )}
              </Header>

              <OfficeUserLink to={`/-/settings/offices/${id}/users`}>
                <ButtonContainer>
                  <LinkButton testId="user-list-link">
                    <Icon name="users" /> &nbsp; {text.userList}
                  </LinkButton>
                </ButtonContainer>
              </OfficeUserLink>

              <OfficeDetailsForm>
                <OfficeDetailForm
                  loading={mutationLoading}
                  isUserAdmin={canEdit}
                  onSubmitChanges={onFormSubmit}
                  initialValues={{
                    email: email || null,
                    phone: phone || null,
                    street: street || null,
                    houseNumber: houseNumber || null,
                    addition: addition || null,
                    postcode: postcode || null,
                    city: city || null,
                  }}
                />
              </OfficeDetailsForm>

              <DetailsBlock title={text.emailIntegration}>
                <SynchronisedMailboxes
                  mailboxes={filteredMailboxes}
                  entityType={SynchroniseEntityType.Office}
                  entityId={id}
                  onSuccessfulDelete={onSuccessfulMailboxDelete}
                  onSuccessfulSync={this.onSuccess}
                  mayEdit={canEdit}
                  onSuccessfulAliasVerificationStart={
                    onSuccessfulAliasVerificationStart
                  }
                  onSuccessfulAliasVerificationEnd={
                    onSuccessfulAliasVerificationEnd
                  }
                />
              </DetailsBlock>

              <SignatureContainer
                title={text.signature}
                signature={signature}
                entityTypeForMutation={{ officeId: id }}
                refetch={refetch}
                readOnly={!canEdit}
              />

              {showDeleteOfficeModal && (
                <DeleteOfficeModal
                  list={showDeleteOfficeModal}
                  onCancel={() => this.handleDeleteOfficeModalAction(null)}
                  onClose={() => this.handleDeleteOfficeModalAction(null)}
                  officeId={id}
                />
              )}

              {showErrorModal && (
                <ErrorModal
                  title={text.errorModalTitle}
                  message={text.errorModalMessage}
                  onClose={() => this.setState({ showErrorModal: false })}
                />
              )}
            </ContentContainerDefault>
          );
        }}
      </UpdateOfficeMutation>
    );
  }
}

const OFFICE_DETAILS_FORM_MAX_WIDTH = 540;

const OfficeDetailsForm = styled.section<{}>`
  width: 100%;
  max-width: ${OFFICE_DETAILS_FORM_MAX_WIDTH}px;
`;

const OfficeUserLink = styled(Link)<{}>`
  margin-left: auto;
  order: 1;

  ${({ theme }) => css`
    ${theme.mq.greaterThan('tabletLandscape')`
      order: 2;
    `}
  `};
`;

const ButtonContainer = styled.div<{}>`
  ${({ theme }) => css`
    padding-bottom: ${theme.space('m')};
  `}
`;

export default withAddToastMessage(UpdateOfficeComponent);
