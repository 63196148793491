/**
 * Returns initial html for the Email to be sent, sometimes it is filled with the reply blockquote
 * Adds one empty line after the first text block
 * @param {string | undefined} text1 - Some text to add
 * @param {string} text2 - Some text to add
 * keywords: emailValue, emailEditor, initialValue
 */
const getInitialEmailValue = (
  text1: string | undefined,
  text2: string,
): string => `<div>${text1 ?? ''}</div><div></div><div>${text2}</div>`;

export default getInitialEmailValue;
