import type { AppStatus__typename } from '~/graphql/types.client';
import useApps, {
  ExtendedAppConfig,
  ReturnType as AppsReturnType,
} from '../useApps';
import { reporter } from '../useErrorReporter';

type ReturnType = {
  app: ExtendedAppConfig | null;
} & Omit<AppsReturnType, 'apps'>;
const useApp = (typename: AppStatus__typename | undefined): ReturnType => {
  const { apps, ...rest } = useApps();

  if (!typename) {
    reporter.captureException(
      new Error(`Typename was not provided for useApp`),
      'critical',
    );
    return { app: null, ...rest };
  }

  const app = apps[typename];

  if (!rest.loading && !app) {
    reporter.captureException(
      new Error(`Unable to get the app for ${typename}`),
      'critical',
    );
  }
  return { app: app ?? null, ...rest };
};

export default useApp;
