import { FlowConditionAppHypotheekbondDetails } from './types.flow';

import { emptyHypotheekbondEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionAppHypotheekbondDetails = (): Omit<
  FlowConditionAppHypotheekbondDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_HYPOTHEEKBOND_DETAILS.type,
  fields: [],
  event: emptyHypotheekbondEventParameterValue(),
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
});

export default emptyFlowConditionAppHypotheekbondDetails;
