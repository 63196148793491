import gql from 'graphql-tag';
import FlowParameterFilePointerFields from './FlowParameterFilePointerFields';
import FlowParameterFilePrimitiveFields from './FlowParameterFilePrimitiveFields';

export default gql`
  fragment FlowParameterFileFields on Flow_Parameter__File {
    ... on Flow_Parameter_File_Pointer {
      ...FlowParameterFilePointerFields
    }

    ... on Flow_Parameter_File_Primitive {
      ...FlowParameterFilePrimitiveFields
    }
  }
  ${FlowParameterFilePointerFields}
  ${FlowParameterFilePrimitiveFields}
`;
