import { pluck, uniq, uniqBy, prop } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import DatHuisLoading from '~/components/DatHuisLoading';
import SelectBlock from '~/components/SelectBlock';
import type {
  OutputFieldList,
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/Wizard/context/WizardContext';
import {
  FlowTemplates,
  InsertedTemplateId,
  InsertFlowBlueprintsByTemplateIdsMutation,
  useGetFlowV2TemplatesQuery,
  useInsertFlowBlueprintsByTemplateIdsMutation,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import { AppStatus } from '~/graphql/types.client';
import useAddToast from '~/hooks/useAddToast';
import useApp from '~/hooks/useApp';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import formatToastMessage from '~/util/formatToastMessage';
import type { OutputMap } from '..';
import { Body, Heading5, Variant } from '../../Typography/index';
import getEnableFlowsUpdate from './utils/getEnableFlowsUpdate';
import ErrorScreen from '~/scenes/ErrorScreen';
import { ExtendedAppConfig } from '~/hooks/useApps';

export const id = 'EnableFlowsForAppStatus';
export const title = 'Selecteer flows';

export type OutputType = {
  type: typeof id;
  appStatus: OutputFieldInvisible<AppStatus | null>;
  selectedTemplates: OutputFieldInvisible<Array<FlowTemplates['id']>>;
  insertedFlows: OutputFieldList<InsertedTemplateId>;
};

const text = {
  body: (
    <>
      Selecteer de flows die je wil gebruiken voor het inzetten van een campagne
      naar bestaande contacten, of het automatisch opvolgen van nieuwe leads. Na
      het voltooien van deze installatie kan je de toegevoegde flows altijd
      aanpassen. Deze vind je onder Automation in het menu.
    </>
  ),
  activatedFlowsCategoryHeader: 'Geactiveerde flows',
  label: 'Flows',
  removedTemplate: 'Verwijderd template',
  noTemplateForThisApp:
    'Helaas hebben wij nog geen templates voor deze app. Je kan door naar de volgende stap',
};

export const Component: React.FC<WizardStepProps> = props => {
  const { app, loading } = useApp(props.step.metadata.typename);

  if (loading) return <DatHuisLoading />;
  if (!app) return <ErrorScreen />;

  return <Child {...props} app={app} />;
};

const Child: React.FC<WizardStepProps & { app: ExtendedAppConfig }> = ({
  step,
  outputMap,
  app,
}) => {
  const account = useCurrentAccount();
  const addToast = useAddToast();

  const appStatus = app.appStatus;

  const currentOutput = outputMap[id] as OutputType;

  const { loading, data } = useGetFlowV2TemplatesQuery({
    variables: {
      accountId: account.id,
      appTypes: [app.appType],
    },
  });

  const [insertByTemplateIds] = useInsertFlowBlueprintsByTemplateIdsMutation({
    variables: {
      accountId: account.id,
      templateIds: [],
    },
  });

  const [updateAppStatus] = useUpdateAppStatusMutation();

  const [selectedTemplateIds, setSelectedTemplateIds] = useState<
    Array<FlowTemplates['id']>
  >(
    outputMap[id] && 'selectedTemplates' in outputMap[id]
      ? outputMap[id].selectedTemplates.value
      : [],
  );

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap): Promise<OutputType> => {
      const currentOutput = outputMap[id] as OutputType;
      const currentAppStatus = currentOutput.appStatus.value;

      if (!currentAppStatus) return currentOutput;

      const filteredSelectedTemplates =
        currentOutput.selectedTemplates.value.filter(template => {
          if (
            currentAppStatus &&
            'metadata' in currentAppStatus &&
            currentAppStatus.metadata?.setup &&
            'addedFlows' in currentAppStatus.metadata?.setup &&
            currentAppStatus.metadata.setup.addedFlows?.includes(template)
          ) {
            return false;
          }

          return true;
        });

      let _data: InsertFlowBlueprintsByTemplateIdsMutation | null | undefined;

      if (filteredSelectedTemplates.length !== 0) {
        await insertByTemplateIds({
          variables: {
            accountId: account.id,
            templateIds: filteredSelectedTemplates,
          },
        }).then(({ data }) => {
          _data = data;
        });
      }

      const update = getEnableFlowsUpdate(
        outputMap,
        step.metadata.typename,
        currentAppStatus,
      );

      await updateAppStatus({
        variables: {
          accountId: account.id,
          update: { [step.metadata.typename]: update },
        },
      });

      if (filteredSelectedTemplates.length)
        addToast([
          formatToastMessage(
            `${filteredSelectedTemplates.length} flows toegevoegd.`,
            'success',
          ),
        ]);

      const output: OutputType = {
        ...currentOutput,
        type: 'EnableFlowsForAppStatus',
        selectedTemplates: {
          type: 'invisible',
          value: currentOutput.selectedTemplates.value,
        },
        insertedFlows: {
          type: 'list',
          category: text.activatedFlowsCategoryHeader,
          value: uniqBy(prop('templateId'), [
            ...(_data != null
              ? _data.insertFlowBlueprintsByTemplateIds ?? []
              : []
            ).map(template => ({
              ...template,
              label: template.name,
              insertedId: template.flowBlueprintId,
            })),
            ...currentOutput.insertedFlows.value,
          ]),
        },
      };

      return Promise.resolve(output);
    },
    [
      account.id,
      addToast,
      insertByTemplateIds,
      step.metadata.typename,
      updateAppStatus,
    ],
  );

  const [, api] = useWizardStep(step.id, {
    onBeforeNext,
  });

  // data can be returned after the step is touched so we have a separate state to keep track of it
  const [canBeSet, setCanBeSet] = useState(false);

  useEffect(() => {
    if (canBeSet && data) {
      setSelectedTemplateIds(prev =>
        uniq([...prev, ...pluck('id', data.getFlowV2Templates)]),
      );
      return setCanBeSet(false);
    }

    if (!step.isTouched) setCanBeSet(true);
  }, [data, step.isTouched, canBeSet]);

  useEffect(() => {
    if (appStatus != undefined) {
      const templates = {
        ...currentOutput.selectedTemplates,
        value: selectedTemplateIds,
      };

      api.free({
        ...currentOutput,
        selectedTemplates: templates,
        appStatus: {
          type: 'invisible',
          value: appStatus,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStatus, selectedTemplateIds]);

  useEffect(() => {
    if (
      appStatus &&
      'metadata' in appStatus &&
      appStatus.metadata?.setup &&
      'addedFlows' in appStatus.metadata.setup
    ) {
      const _previouslyEnabledFlows =
        appStatus.metadata?.setup.addedFlows ?? [];
      setSelectedTemplateIds(prev =>
        uniq([...prev, ..._previouslyEnabledFlows]),
      );
    }
  }, [appStatus]);

  if (loading) return <DatHuisLoading />;
  if (!data) return <ErrorScreen />;

  return (
    <>
      <Body>{text.body}</Body>
      {data && data.getFlowV2Templates.length !== 0 && (
        <GridContainer>
          {data.getFlowV2Templates.map(template => {
            const disabled =
              (appStatus &&
                'metadata' in appStatus &&
                appStatus.metadata?.setup &&
                'addedFlows' in appStatus.metadata?.setup &&
                appStatus.metadata?.setup.addedFlows?.includes(template.id)) ??
              false;

            return (
              <SelectBlock
                type="checkbox"
                key={template.id}
                disabled={disabled}
                checked={selectedTemplateIds.includes(template.id)}
                onClick={() =>
                  setSelectedTemplateIds(prev => {
                    if (prev.includes(template.id)) {
                      return prev.filter(id => id !== template.id);
                    }

                    return uniq([...prev, template.id]);
                  })
                }
                description={template.description}
                checkboxTitle={template.name}
              />
            );
          })}
        </GridContainer>
      )}
      {data && data.getFlowV2Templates.length === 0 && (
        <Heading5 variant={Variant.primary}>
          {text.noTemplateForThisApp}
        </Heading5>
      )}
    </>
  );
};

const GridContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.space('m')};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: masonry;
    gap: 0 ${theme.space('m')};
    width: 100%;
  `,
);

export default {
  id,
  title,
};
