import React from 'react';
import uuidv1 from 'uuid/v1';

import { ActionSubscriber } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import {
  FlowActionProps,
  FlowInitialActionProps,
} from '~/scenes/Automation/Flows/Actions/types.flow';

type SubscribeToFn<T> = (newSubscriber: T) => string;
type UnsubscribeFromFn = (toRemoveSubscriberKey: string) => void;
export type KeyedItem<T> = T & {
  key: string;
};
type ChildProps<T> = {
  subscribers: Array<KeyedItem<T>>;
  subscribeTo: SubscribeToFn<T>;
  unsubscribeFrom: UnsubscribeFromFn;
  resetSubscribers: () => void; // reset
};

type Props<T> = {
  onSubscribersChanged?: (newSubscribers: Array<KeyedItem<T>>) => void;
  children: (props: ChildProps<T>) => React.ReactNode;
};
type State<T> = {
  subscribers: Array<KeyedItem<T>>;
};

class SubscriberList<T> extends React.Component<Props<T>, State<T>> {
  subscribers: Array<KeyedItem<T>> = [];

  subscribeTo = (sub: T) => {
    const newId = uuidv1();
    const { onSubscribersChanged } = this.props;

    this.subscribers = [...this.subscribers, { ...sub, key: newId }];

    if (onSubscribersChanged) {
      onSubscribersChanged(this.subscribers);
    }

    return newId;
  };

  unsubscribeFrom = (key: string) => {
    const { onSubscribersChanged } = this.props;

    this.subscribers = this.subscribers.filter(sub => sub.key !== key);

    if (onSubscribersChanged) {
      onSubscribersChanged(this.subscribers);
    }
  };

  resetSubscribers = () => {
    const { onSubscribersChanged } = this.props;
    this.subscribers = [];

    if (onSubscribersChanged) {
      onSubscribersChanged(this.subscribers);
    }
  };

  render() {
    const { children } = this.props;

    const childProps: ChildProps<T> = {
      subscribers: this.subscribers,
      // @ts-ignore
      subscribeTo: this.subscribeTo,
      unsubscribeFrom: this.unsubscribeFrom,
      resetSubscribers: this.resetSubscribers,
    };

    return children(childProps);
  }
}

export class FlowActionSubscriberList<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  FlowActionType extends FlowActionProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  FlowInitialActionType extends FlowInitialActionProps,
> extends SubscriberList<ActionSubscriber> {}

export default SubscriberList;
