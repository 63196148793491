import gql from 'graphql-tag';

import FlowParameterMappingFields from './FlowParameterMappingFields';

export default gql`
  fragment FlowActionZapierTriggerFields on Flow_Action_Zapier_Trigger {
    __typename
    id
    _v
    accountId
    flowBlueprintId
    parentId
    zapierTriggerId
    mappings {
      ...FlowParameterMappingFields
    }
  }

  ${FlowParameterMappingFields}
`;
