import gql from 'graphql-tag';

export default gql`
  fragment AccountIssueFields on AccountIssue {
    ... on AccountIssue_MissingPayment {
      __typename
      invoiceId
      hostedInvoiceUrl
      transitions {
        to
        date
      }
    }
    ... on AccountIssue_UnsignedDocument {
      __typename
      documentId
      transitions {
        to
        date
      }
    }
  }
`;
