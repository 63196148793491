import { RouteComponentProps } from '@reach/router';
import React from 'react';
import {
  AppBbWaardecheckComparison,
  AppVboWaardecheckComparison,
} from '~/graphql/types';
import InputGroupDivider from '~/scenes/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import {
  AppWaardecheckFieldsFragment,
  appWaardecheckState,
  StateId,
} from '../..';

import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppSectionHeader from '~/scenes/Apps/components/AppSectionHeader';
import WaardecheckIntl from '../../intl';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import IntlInputComponent from '../../components/IntlInputComponent';
import MainPreview from '../../components/MainPreview';
import { Page } from '../../components/Preview';
import TEST_ID from './index.testid';
import Explanation from '../../components/Explanation';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
};

const Comparison: React.FC<Props> = ({ defaultValues, loading }) => {
  const original = defaultValues.comparison;
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const updateValue = (
    value: DeepPartial<
      AppBbWaardecheckComparison | AppVboWaardecheckComparison
    >,
  ) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        comparison: value,
      }) as any as typeof prev;
    });
  };

  if (original == null || updated == null) return null;

  return (
    <AppDetailsContainer
      header="Swipen"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Explanation>
        Op deze pagina wordt gevraagd om vergelijkbare objecten te selecteren.
        Personaliseer de achtergrondfoto en teksten.
      </Explanation>
      <MainPreview data={updated} page={Page['/swiping']} />
      <InputGroupDivider large />
      <AppSectionHeader>{WaardecheckIntl.section.text}</AppSectionHeader>
      <Description>{WaardecheckIntl.section.textDescription}</Description>
      <IntlInputComponent
        title={WaardecheckIntl.heading}
        defaultValue={original.heading}
        value={updated.comparison.heading}
        onChange={heading => updateValue({ heading })}
        dataTestId={TEST_ID.HEADING}
      />
    </AppDetailsContainer>
  );
};

export default Comparison;
