/**
 * Function that checks if the insertedOrUpdatedTask needs to update the currentList
 * and returns the updated list.
 */
import shouldBeInTaskList from './shouldBeInTaskList';
import { sortTaskList } from './taskListManipulation';

import { TaskListTaskFieldsFragment } from '~/graphql/types';
import { TaskListType } from '~/scenes/Tasks/util/taskListType';

export default (
  currentList: Array<TaskListTaskFieldsFragment>,
  insertedOrUpdatedTask: TaskListTaskFieldsFragment | null | undefined,
  taskListType: TaskListType,
  queryHasMoreResults: boolean,
): Array<TaskListTaskFieldsFragment> => {
  if (insertedOrUpdatedTask == null) return currentList;

  const insertedOrUpdatedTaskId = insertedOrUpdatedTask.id;
  const currentInsertedOrUpdatedTask = currentList.find(
    task => task.id === insertedOrUpdatedTaskId,
  );

  let otherItems;
  // If we already have this task, check the version.
  if (currentInsertedOrUpdatedTask != null) {
    if (insertedOrUpdatedTask._v < currentInsertedOrUpdatedTask._v) {
      // See explanation in GetTasks.test.js on why we do not ignore same version
      return currentList;
    }

    otherItems = currentList.filter(
      task => task.id !== currentInsertedOrUpdatedTask.id,
    );
  } else {
    otherItems = [...currentList];
  }

  if (
    !shouldBeInTaskList(
      currentList,
      insertedOrUpdatedTask,
      taskListType,
      queryHasMoreResults,
    )
  ) {
    return otherItems;
  }

  return sortTaskList([...otherItems, insertedOrUpdatedTask], taskListType);
};
