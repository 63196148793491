import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  dataTestId?: string;
};

const Description: React.FC<Props> = ({ dataTestId, ...rest }) => (
  <Container data-testid={dataTestId} {...rest} />
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('s')};
    margin-bottom: ${theme.space('base')};
    line-height: ${theme.lineHeight('m')};
  `,
);

export default Description;
