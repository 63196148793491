import { memoize } from 'underscore';
import type {
  ContactListWithLastActivityFieldsFragment,
  ContactStatus,
  GetContactsV2QueryVariables,
} from '~/graphql/types';

import { generateConcatenatedNonNullString } from '~/util/textConversion';
import { convertServerDateStringToDate } from '~/util/date';
import { mapNotNull } from '~/util/array';
import markedForDeletionContactList from '~/scenes/Contacts/util/markedForDeletionContactList';
import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';
import expressActivity from '~/util/expressActivity';
import { capitalizeFirstLetter } from '~/util/string';
import { extractHandledActivities } from '~/graphql/types.client';

export const isDeleted = (contact: {
  status?: ContactStatus | null;
  id: string;
}): boolean => {
  const markedForDeletionContactIds =
    markedForDeletionContactList.getMarkedForDeletionContacts();

  return (
    (contact.status && contact.status === 'DELETING') ||
    markedForDeletionContactIds.includes(contact.id)
  );
};
export type ContactListData = {
  id: string;
  name: string;
  address: string;
  createdDate: Date | null;
  tags: Array<string>;
  leadScore: number | null;
  leadActivity: string;
  lastLeadActivity: Date | null;
};

const composeContactListData = (
  items: Array<ContactListWithLastActivityFieldsFragment> | null | undefined,
  // We need to add these params in order to refresh the list even though we don't use them
  _variables: GetContactsV2QueryVariables,
  _version: string,
): Array<ContactListData> => {
  if (items == null) return [];

  return mapNotNull(items, item => {
    const { name, LeadScore, createdDate, tags, id, leadActivity, address } =
      item;

    if (isDeleted(item)) {
      return null;
    }

    const handledLeadActivity = leadActivity
      ? extractHandledActivities([leadActivity])[0]
      : null;

    return {
      id,
      name,
      tags: tags || [],
      address: generateConcatenatedNonNullString(
        [address?.street, address?.city],
        ', ',
      ),
      leadActivity: handledLeadActivity
        ? capitalizeFirstLetter(expressActivity(handledLeadActivity))
        : '',
      lastLeadActivity: convertServerDateStringToDate(
        handledLeadActivity?.createdDate,
      ),
      createdDate: convertServerDateStringToDate(createdDate),
      leadScore: convertServerLeadScoreToFrontendLeadScore(LeadScore),
    };
  });
};

export const convertServerLeadScoreToFrontendLeadScore = (
  leadScore?: number | null,
): number => {
  if (
    leadScore == null ||
    !Number.isInteger(leadScore) ||
    leadScore < 0 ||
    leadScore > 99
  ) {
    reportErrorToTracker(
      `${cleanedFilename(__filename)}
      Invalid lead score from the server: ${
        leadScore ? leadScore : 'null or undefined'
      }`,
    );

    if (leadScore != null && leadScore > 99) return 99;

    return 0;
  }
  return leadScore;
};

const memoizedComposeContactListData = memoize(
  composeContactListData,
  (items, variables: GetContactsV2QueryVariables, version: string) => {
    const result = `${version}*@@*${items.length}*@@*${
      variables.query || ''
    }*@@*${JSON.stringify(variables.filters, null, 2)}*@@*${
      variables.sortBy == null
        ? ''
        : variables.sortBy.field + variables.sortBy.direction
    }*@@*${variables.page || ''}`;

    return result;
  },
);

export default memoizedComposeContactListData;
