import { isNil } from 'ramda';
import { useContext, useEffect } from 'react';
import WizardContext, {
  StateStep,
  StepOptions,
  WizardStep,
} from '~/components/Wizard/context/WizardContext';
import { StepId, StepOutput } from '~/components/WizardSteps';
import getStepById from './utils/getStepById';

type WizardStepAPI = {
  /**
   * Adds additional sub steps to the current step
   * @param step WizardStep - The step you want to add
   */
  addSubStep: (step: WizardStep) => void;
  free: (output: StepOutput) => void;
  lock: (output: StepOutput) => void;
};

const useWizardStep = (
  stepId: StepId,
  options?: StepOptions,
): [StateStep, WizardStepAPI] => {
  const wizardContext = useContext(WizardContext);
  const { state, dispatch } = wizardContext;
  const step = getStepById(stepId, state.steps);

  if (isNil(step)) {
    throw new Error(`Unable to find step with ID: ${stepId}`);
  }

  useEffect(() => {
    if (options) {
      dispatch({ type: 'updateStep', payload: { step, options } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const api: WizardStepAPI = {
    addSubStep: subStep =>
      dispatch({
        type: 'addSubStep',
        payload: {
          step,
          subStep,
        },
      }),
    free: output => dispatch({ type: 'freeStep', payload: { output, step } }),
    lock: output => dispatch({ type: 'lockStep', payload: { step, output } }),
  };

  return [step, api];
};

export default useWizardStep;
