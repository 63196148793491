import React, { ReactElement } from 'react';
import qs from 'query-string';
import styled, { css } from 'styled-components';

import { navigate } from 'gatsby';
import { FlowActionStartFieldsFragment, Flow__Input } from '~/graphql/types';

import { Flow } from '~/scenes/Automation/Flows/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';

import FlowActionTrees from './FlowActionTrees/';
import renderActionTree from '~/scenes/Automation/Flows/Actions/util/renderActionTree';
import EditableName from '~/components/DetailsPage/EditableName';
import idGenerator from '../util/idGenerator';
import flowPropToFlowInput from '../util/flowPropToFlowInput';
import extractFlowDetailsFromFlow from '../util/extractFlowDetailsFromFlow';
import FlowContextProvider from '../FlowContextProvider';
import TEST_ID from './FlowBuilderComponent.testid';
import { FlowActionSubscriberList } from '~/components/util/SubscriberList';
import cleanedFilename from '~/util/cleanedFilename';
import getActionLabel from '../util/getActionLabel';
import Catalog from '~/Catalog';
import ErrorModal from '~/components/Alerts/ErrorModal';
import FooterSaveBar from '~/components/SaveBar/FooterSaveBar';
import RunXTimesContainer from '~/scenes/Automation/Flows/components/RunXtimesContainer';
import { InputGroup, Checkbox } from '~/components/Inputs';

const validationErrorMsgForActions = (
  actions: Array<ActionTreeNode>,
  nodeCount: number,
) => {
  if (nodeCount < 2) {
    return 'Voeg minimaal één actie toe aan de flow';
  }

  if (actions.length === 1) {
    return `${getActionLabel(
      actions[0].positionString,
    )} kan niet worden opgeslagen`;
  } else {
    return `De stappen (${actions
      .map(action => getActionLabel(action.positionString))
      .join(', ')}) kunnen niet worden opgeslagen`;
  }
};

const text = {
  saveLabel: 'Opslaan',
  errorModalTitle: Catalog.genericUnknownErrorModalTitle,
  errorModalMessage: Catalog.genericUnknownErrorMessage,
  validationErrorMsgForActions,
  checkbox: 'Activeer deze flow na opslaan',
};
type Props = {
  initialFlow?: Flow | null;
  accountId: string;
  loading: boolean;
  onCreate: (flow: Flow__Input) => Promise<any>;
  onErrorClear: () => void;
  // If the mutation generated an unknown error
  error: boolean;
};
type State = {
  flow: Flow;
  resetCounter: number;
  showValidation: boolean;
  validationErrorMessage: string | null;
  hasChanges: boolean;
  showModal: boolean;
};
class FlowBuilderComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { accountId, initialFlow } = props;

    this.state = {
      flow: initialFlow ? initialFlow : defaultFlow(accountId),
      resetCounter: 0,
      validationErrorMessage: null,
      showValidation: false,
      hasChanges: true,
      showModal: false,
    };
  }

  setChanges = () => {
    this.setState({
      validationErrorMessage: null,
    });
  };

  setFlowState = (newFlow: Flow) => {
    this.setState({
      flow: newFlow,
    });
  };

  setValidationErrorMessage = (message: string | null) => {
    this.setState({
      showValidation: message == null ? false : true,
      validationErrorMessage: message,
    });
  };

  setRunTimes = (value: number | null) => {
    this.setState({
      flow: { ...this.state.flow, maximumFlowRun: value },
    });
  };

  onCheckboxChecked = (value: boolean) => {
    this.setState({
      flow: { ...this.state.flow, enabled: value },
    });
  };

  render() {
    const { onCreate, loading, error } = this.props;

    const {
      flow,
      resetCounter,
      validationErrorMessage,
      showValidation,
      hasChanges,
    } = this.state;
    const { name, initialActionFragments, enabled, accountId } = flow;

    let errorModal: ReactElement | null = null;
    if (error) {
      errorModal = (
        <ErrorModal
          title={text.errorModalTitle}
          message={text.errorModalMessage}
          onClose={() => this.props.onErrorClear()}
        />
      );
    }

    return (
      <Container data-testid={TEST_ID.CONTAINER}>
        <HeaderContainer>
          <EditableName
            name={name}
            edit={true}
            hasChangesOnPage={this.state.hasChanges}
            updateName={newName => {
              this.setFlowState({
                ...flow,
                name: newName,
              });
            }}
            onNameChange={newName => {
              this.setFlowState({
                ...flow,
                name: newName,
              });
            }}
          />
          <RunXTimesContainer
            onSetRunTimes={this.setRunTimes}
            runTimes={flow.maximumFlowRun || null}
          />
        </HeaderContainer>
        <FlowActionTrees
          initialActions={initialActionFragments}
          accountId={accountId}
        >
          {({
            actionTrees,
            announceChanges,
            assignSubscribers,
            traverseAllNodes,
            deleteAction,
            addAction,
            getPositionStringForId,
          }) => (
            <FlowActionSubscriberList onSubscribersChanged={assignSubscribers}>
              {({ subscribeTo, unsubscribeFrom }) => {
                if (actionTrees.length === 0 || actionTrees.length > 1) {
                  throw new Error(
                    `${cleanedFilename(
                      __filename,
                    )} | We do not support flows with ${
                      actionTrees.length
                    } start actions`,
                  );
                }

                return (
                  <>
                    <FlowContextProvider
                      isInitialised={true}
                      showValidation={showValidation}
                      onDeleteAction={deleteAction}
                      getPositionStringForId={getPositionStringForId}
                    >
                      <ActionListContainer>
                        {renderActionTree(
                          actionTrees[0],
                          subscribeTo,
                          unsubscribeFrom,
                          resetCounter,
                          options => {
                            if (options && options.noDataChange === true) {
                              // do nothing
                            } else {
                              this.setChanges();
                            }

                            announceChanges(options);
                          },
                          addAction,
                          false,
                        )}
                      </ActionListContainer>
                    </FlowContextProvider>
                    <ButtonRowContainer>
                      {hasChanges && (
                        <FooterSaveBar
                          key={'flow-builder-footer'}
                          disableSave={loading}
                          onSave={() => {
                            const actionsWithErrors: Array<ActionTreeNode> = [];
                            let nodeCount = 0;

                            traverseAllNodes(actionTree => {
                              nodeCount = nodeCount + 1;

                              if (actionTree.subscriber) {
                                if (!actionTree.subscriber.validate()) {
                                  actionsWithErrors.push(actionTree);
                                }
                              }
                            });

                            const hasValidationErrors =
                              nodeCount < 2 || actionsWithErrors.length > 0;

                            if (hasValidationErrors) {
                              this.setValidationErrorMessage(
                                text.validationErrorMsgForActions(
                                  actionsWithErrors,
                                  nodeCount,
                                ),
                              );
                            } else {
                              void onCreate(
                                flowPropToFlowInput(
                                  extractFlowDetailsFromFlow(flow),
                                  actionTrees,
                                ),
                              ).then(() => {
                                this.setState({
                                  hasChanges: false,
                                });
                              });
                            }
                          }}
                          onCancel={() => void navigate('/-/automation/flows')}
                          saveErrorMessage={validationErrorMessage}
                        >
                          <InputGroup>
                            <Checkbox
                              onChange={() => {
                                this.onCheckboxChecked(!enabled);
                              }}
                              value={enabled}
                              label={text.checkbox}
                              name="saveAndEnableFlow"
                              data-testid={TEST_ID.SAVE_AND_ENABLE_CHECKBOX}
                            />
                          </InputGroup>
                        </FooterSaveBar>
                      )}
                    </ButtonRowContainer>
                  </>
                );
              }}
            </FlowActionSubscriberList>
          )}
        </FlowActionTrees>

        {errorModal}
      </Container>
    );
  }
}

const ButtonRowContainer = styled.div<{}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${({ theme }) => css`
    margin-top: ${theme.space('m')};
    margin-bottom: ${theme.space('m')};
  `};
`;

const ActionListContainer = styled.div<{}>(
  ({ theme }) => css`
    padding-bottom: ${theme.space('xl')} 0;
  `,
);

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div<{}>`
  /** We subtract the width of the delete button to make RunXTimesContainer align with the action containers */
  width: calc(100% - 40px);
`;

const defaultFlow = (accountId: string) => {
  const flowBlueprintId = idGenerator.generateFlowBlueprintId();
  const { name } = qs.parse(global.window?.location?.search || '');
  const flowName = (Array.isArray(name) ? null : name) ?? 'Nieuwe flow';

  return {
    id: flowBlueprintId,
    _v: 1,
    accountId,
    name: flowName,
    enabled: true,
    maximumFlowRun: 1,
    initialActionFragments: [getDefaultStartAction(accountId, flowBlueprintId)],
  };
};

const getDefaultStartAction = (
  accountId: string,
  flowBlueprintId: string,
): FlowActionStartFieldsFragment => ({
  __typename: 'Flow_Action_Start',
  id: idGenerator.generateFlowBlueprintActionId(),
  accountId,
  flowBlueprintId,
  startCondition: {
    __typename: 'Flow_Condition_Contact_Tag',
    exists: {
      __typename: 'Flow_Parameter_Boolean_Primitive',
      booleanValue: true,
    },
    tagName: {
      __typename: 'Flow_Parameter_String_Primitive',
      stringValue: '',
    },
  },
  conditionList: null,
});

export default FlowBuilderComponent;
