import { NamedEmail } from '~/util/namedEmailFormatter';
import { EmailReducerState } from '../../reducers/sendEmail';

/**
 * Returns NamedEmail of the selected Sender option
 * @param {EmailReducerState} emailState - EmailReducerState
 * keywords: sender, from, selectedSender
 */
const getSenderNamedEmailValue = (
  emailState: EmailReducerState,
): NamedEmail | null => {
  const { senderOptions, selectedSenderIdx } = emailState;

  if (selectedSenderIdx == null || senderOptions[selectedSenderIdx] == null) {
    return null;
  }

  return senderOptions[selectedSenderIdx].payload;
};

export default getSenderNamedEmailValue;
