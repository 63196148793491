import { FlowConditionAppHypotheekbondNew } from './types.flow';
import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';
import { PARAMETER_PRIMITIVE_VALUE_TYPE } from '../../FlowParameter/ParameterValue/constants';

const emptyFlowConditionAppHypotheekbondNew = (): Omit<
  FlowConditionAppHypotheekbondNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_HYPOTHEEKBOND_NEW.type,
  tool: { type: PARAMETER_PRIMITIVE_VALUE_TYPE.STRING_PRIMITIVE, value: null },
});

export default emptyFlowConditionAppHypotheekbondNew;
