import React, {
  MutableRefObject,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from 'react';

type Props = {
  /** If true it will not block the click, so anything the user clicked on will still be executed. */
  allowBubble?: boolean;

  /** Callback, triggered when the referenced component should hide */
  onClickOutside?: () => void;

  /** Sends down the reference to be attached to the element that should hide based on outside clicks  */
  children: (ref: MutableRefObject<any>) => ReactNode;
};

/**
 * HOC providing a callback if this component should hide
 * based on clicking outside of its box.
 */
const HidableComponent: React.FC<Props> = ({
  allowBubble,
  onClickOutside,
  children,
}) => {
  const componentRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [canClose, setCanClose] = useState<boolean>(false);

  const handleClick = (event: MouseEvent) => {
    if (
      componentRef &&
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      // only if allowBubble is explicitely sent
      if (!allowBubble) {
        event.cancelBubble = true;
      }

      if (canClose) {
        onClickOutside && onClickOutside();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    /**
     * Delay closing for a bit to avoid the same click event to trigger the component to already hide it
     * Mostly needed for third party things where we cannot prevent propagation of the event (froala as example)
     */

    const canCloseTimeout = setTimeout(() => {
      setCanClose(true);
    }, 10);

    return () => {
      document.removeEventListener('click', handleClick, true);
      if (canCloseTimeout != null) {
        clearTimeout(canCloseTimeout);
      }
    };
  });

  return <>{children(componentRef)}</>;
};

export default HidableComponent;
