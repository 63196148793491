import sanitize from 'sanitize-html';
import convertAttachments from '../convertAttachments';
import { Descendant } from 'slate';
import parseAndDeserialize from '../../parseAndDeserialize';
import { Attachment } from '~/graphql/types';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import { defaultSanitizeOptions } from '../../flows/convertTemplateStringToSlate';

/**
 * This is used when we have html and attachments in an editor. No mappings (like in Signature editor)
 *
 *
 * @param {string} html - Example argument
 * @param {Array<Attachment>} attachments - Example argument
 * @param {boolean} markAsPendingImage - Example argument
 * keywords:
 */

type Params = {
  html: string;
  attachments?: Array<Attachment>;
  markAsPendingImage?: boolean;
  customElements?: Array<ELEMENTS>;
};
const convertHtmlToSlateFragment = ({
  html,
  attachments = [],
  markAsPendingImage = false,
  customElements,
}: Params): Array<Descendant> => {
  const sanitized = sanitize(html, defaultSanitizeOptions);
  const convertedHtml = convertAttachments({
    str: sanitized,
    attachments,
  });
  const parsed = parseAndDeserialize({
    html: convertedHtml,
    markAsPendingImage,
    customElements,
  });

  return parsed;
};

export default convertHtmlToSlateFragment;
