import gql from 'graphql-tag';

export default gql`
  mutation InsertOffice($accountId: ID!, $office: Office_New!) {
    insertOffice(accountId: $accountId, office: $office) {
      id
      name
      phone
      email
      address {
        street
        houseNumber
        addition
        postcode
        city
      }
    }
  }
`;
