import { FlowConditionEmailLinkClicked } from './types.flow';
import { Flow_Condition__Input } from '~/graphql/types';

import { asEmailParameterValueInput } from '../../FlowParameter/ParameterValue/Email';
import { asStringParameterValueInput } from '../../FlowParameter/ParameterValue/String';

export default (
  condition: FlowConditionEmailLinkClicked,
): Flow_Condition__Input => {
  const { email, link } = condition;

  return {
    EventEmail: {
      LinkClicked: {
        email: asEmailParameterValueInput(email),
        link: asStringParameterValueInput(link),
      },
    },
  };
};
