/**
 * Create an array of mappings from Variable elements in the editor
 * @param {Array<Descendant>} nodes - Editor value in slate format
 * keywords: convert variables into mappings
 */

import { uniqBy, unnest } from 'ramda';
import { Element, Node, Text } from 'slate';
import { FlowV2_ParameterMappingFragment } from '~/graphql/types';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';

const convertVariablesInHtml = (
  nodes: Array<Node>,
): Array<FlowV2_ParameterMappingFragment> => {
  const res = nodes.reduce((acc, node) => {
    if (Element.isElement(node) && node.type === ELEMENTS.VARIABLE) {
      const { mappingId, pending, variableInfo } = node;

      if (!mappingId || pending || !variableInfo) {
        return acc;
      }

      return [
        ...acc,
        {
          __typename: 'FlowV2_ParameterMapping' as const,
          mappingId,
          mapping: variableInfo,
        },
      ];
    }

    /** Get images in nested elements */
    if (!Text.isText(node) && node.children) {
      const childNode = unnest(convertVariablesInHtml(node.children));

      if (childNode.length > 0) return [...acc, ...childNode];
    }

    return acc;
  }, []);

  return res;
};

/** Make sure that there are no duplicate mappings */
const getVariablesAsMappings = value =>
  uniqBy(item => item.mappingId, convertVariablesInHtml(value));

export default getVariablesAsMappings;
