import React, { ReactElement, useEffect, useRef } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Option, OnChangeFunction } from './';

import Loading from '~/components/Loading';
import Icon from '~/components/Icon';

type Props = {
  /** Options to be displayed */
  options: Array<Option>;

  /** The currently selected options index  */
  selectedOptionIdx?: number | null | undefined;

  /** Callback, triggered when a selection is made. */
  onChange: OnChangeFunction;

  /** Loading to state to show that dropdown list is being loaded */
  loading?: boolean;

  /** Removes highlighting of the selected option */
  disableActiveOption?: boolean;
};

/**
 * Dropdown list component
 */
const DEFAULT_ITEM_LIST_HEIGHT = 40;

const DropdownList = ({
  options,
  selectedOptionIdx,
  onChange,
  loading,
  disableActiveOption = false,
  ...rest
}: Props) => {
  const listRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (listRef.current !== null) {
      const listItemHeight =
        listRef.current.children.length > 0
          ? listRef.current.children[0].clientHeight
          : DEFAULT_ITEM_LIST_HEIGHT;

      let scrollIndex = selectedOptionIdx ? selectedOptionIdx : 0;
      scrollIndex = scrollIndex > 10 ? scrollIndex - 3 : scrollIndex;
      listRef.current.scrollTop = listItemHeight * scrollIndex;
    }
  }, [listRef, selectedOptionIdx]);

  const optionsList = options.map((option, index) => {
    const { icon, label, key, styleOptions, type, isEmptySelectionOption } =
      option;
    const isCurrentlyActive: boolean =
      selectedOptionIdx === index && !disableActiveOption;
    const shouldShowLineAbove = Boolean(styleOptions && styleOptions.lineAbove);
    const disabled = type === 'DISABLED';

    let labelComponent: ReactElement | React.ReactNode | null = null;
    if (icon) {
      labelComponent = (
        <LabelContainer>
          <Icon name={icon} />
          <WithIconLabel>{label}</WithIconLabel>
        </LabelContainer>
      );
    } else {
      labelComponent = label;
    }

    return (
      <Item
        isCurrentlyActiveItem={isCurrentlyActive}
        isEmptySelectionOption={isEmptySelectionOption}
        lineAbove={shouldShowLineAbove}
        key={key}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          if (!disabled) {
            onChange({ option: options[index], selectedOptionIdx: index });
          }
        }}
        danger={type === 'DANGER'}
        disabled={disabled}
        data-objectid={key}
        data-selected={isCurrentlyActive.toString()}
        data-testid="dropdown-list-item"
      >
        {labelComponent}
      </Item>
    );
  });

  return (
    <ItemList data-testid="dropdown-list" {...rest} ref={listRef}>
      {loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        optionsList
      )}
    </ItemList>
  );
};

const ItemList = styled.ul<{}>`
  user-select: none;
  max-height: 50vh;
  display: inline-block;
  padding-left: 0;
  margin-top: 3px;
  border-radius: ${({ theme }) => theme.getTokens().border.radius.s};
  overflow-y: auto;

  ${({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('grey', 'light')};
    border-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('white')};
  `};
`;

type ItemProps = {
  isEmptySelectionOption?: boolean;
  isCurrentlyActiveItem?: boolean;
  lineAbove?: boolean;
  danger?: boolean;
  disabled?: boolean;
};
const Item = styled.li<ItemProps>`
  white-space: nowrap;
  transition: background-color 0.3s, color 0.3s;

  ${({ theme }) => css`
    padding: ${theme.space('s')} 2em;
  `};

  ${({
    theme,
    isCurrentlyActiveItem,
    isEmptySelectionOption,
    disabled,
    danger,
  }) => {
    const hover = theme.color('white', 'dark');
    let fontColor;
    if (disabled) {
      fontColor = theme.color('grey');
    } else if (isEmptySelectionOption) {
      fontColor = theme.color('text');
    } else {
      fontColor = theme.color('text', 'light');
    }

    let backgroundColor;
    if (disabled) {
      backgroundColor = '';
    } else if (isCurrentlyActiveItem) {
      backgroundColor = css`
        background-color: ${hover};
      `;
      fontColor = theme.color('text');

      if (danger) {
        backgroundColor = css`
          background-color: ${theme.color('danger')};
        `;
      }
    } else {
      backgroundColor = '';
    }

    let hoverCss: FlattenSimpleInterpolation | null = null;
    if (!disabled) {
      hoverCss = css`
        &:hover {
          color: ${theme.color('text')};
          background-color: ${hover};
        }
      `;
    }

    return css`
      cursor: ${disabled ? 'default' : 'pointer'};
      color: ${danger && isCurrentlyActiveItem
        ? theme.color('white')
        : fontColor};
      ${backgroundColor}

      ${hoverCss}
    `;
  }};

  ${({ theme, lineAbove }) => {
    if (lineAbove) {
      return css`
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 6px;
          height: 1px;
          width: calc(100% - 12px);
          border-top: 1px solid ${theme.color('grey', 'light')};
        }
      `;
    }

    return null;
  }};

  ${({ disabled, danger, theme }) => {
    if (disabled !== true && danger === true) {
      return css`
        &:hover {
          background-color: ${theme.color('danger')};
          color: ${theme.color('white')};
        }
      `;
    }

    return '';
  }}
`;

const WithIconLabel = styled.span<{}>(
  ({ theme }) => css`
    padding-left: ${theme.space('s')};
  `,
);

const LoadingContainer = styled.div<{}>`
  width: 200px;
`;

const LabelContainer = styled.div<{}>`
  display: flex;
  align-items: center;
`;

export default DropdownList;
