import { InexactSetContactDetailsActionFieldsFragment } from './types.flow';
import { ParameterField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import {
  SET_CONTACT_DETAILS_FIELD_OPTIONS,
  SET_CONTACT_DETAILS_DEFAULT_FIELD,
} from './constants';
import { asFlowParameterField, emptyFlowParameterField } from '../Base';

export default (
  item?: InexactSetContactDetailsActionFieldsFragment | null | undefined,
): ParameterField => {
  if (item == null || item.field == null)
    return emptyFlowParameterField(SET_CONTACT_DETAILS_DEFAULT_FIELD);

  const { field } = item;

  let setField = emptyFlowParameterField(SET_CONTACT_DETAILS_DEFAULT_FIELD);

  if (field != null) {
    Object.keys(SET_CONTACT_DETAILS_FIELD_OPTIONS).forEach(fieldName => {
      const fieldParam = field[fieldName];
      if (fieldParam != null) {
        setField = asFlowParameterField(
          SET_CONTACT_DETAILS_FIELD_OPTIONS[fieldName],
          fieldParam,
        );
      }
    });
  }

  return setField;
};
