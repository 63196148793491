import type { FlowData___ConditionRepresentation } from '~/graphql/types';
import type { Flow___ConditionPartial } from '../..';
import type { InstanceMap } from '../../../Selector/utils/getInstance';
import type { DirectoryMap } from '../../../Selector/utils/getDirectory';
import { getSubject, SubjectMap } from '../../../Selector/utils/getSubject';
import getPathForPointer from '../../../Selector/utils/getPathForPointer';
import { FlowPointerFields } from '../../../Selector/utils/getFieldsByPath';
import { PrimitiveInputMap } from '../../../Selector/utils/getPrimitiveInput';
import { ConditionMap } from '../../../Selector/utils/getConditions';
import getRenderedArgument, {
  RenderArgumentContext,
} from './getRenderedArgument';
import renderHandlebarsTemplate from '~/util/handlebars';

export const getPathRepresentation = (path: Array<FlowPointerFields>): string =>
  path.reduce((prev, curr, index) => {
    if (index === 0) return curr.label;

    return `${prev} > ${curr.label}`;
  }, '');

export const text = {
  invalidVariable: 'Foute variabele',
  emptyArg: '""',
};

export type GetLabelForRepReturnType =
  | { error?: undefined; result: string }
  | { error: string };

export type Args = {
  representation: FlowData___ConditionRepresentation;
  condition?: Flow___ConditionPartial | null;
  ctx: RenderArgumentContext;
};

export type Opts = {
  instanceMap: InstanceMap;
  subjectMap: SubjectMap;
  directoryMap: DirectoryMap;
  conditionMap: ConditionMap;
  primitiveInputMap: PrimitiveInputMap;
};

/**
 * Returns a label for a condition or an error if it cannot be resolved.
 */
const getLabelForRep = (
  { representation, condition, ctx }: Args,
  opts: Opts,
): GetLabelForRepReturnType => {
  const {
    instanceMap,
    subjectMap,
    directoryMap,
    conditionMap,
    primitiveInputMap,
  } = opts;
  const { variable, rep } = representation;

  if (condition == null) {
    return {
      result: renderHandlebarsTemplate(rep, { ctx }),
    };
  }
  if (variable == null) {
    return {
      result: renderHandlebarsTemplate(rep, { ctx }),
    };
  }

  switch (condition.__typename) {
    case 'Flow___InstanceCondition': {
      if (variable === 0) {
        const path = getPathForPointer(condition.input, {
          subjectMap,
          instanceMap,
          directoryMap,
        });

        if (path.error != null) {
          return { error: text.invalidVariable };
        }

        return {
          result: renderHandlebarsTemplate(rep, {
            pointer: getPathRepresentation(path.fullResult),
            ctx,
          }),
        };
      }

      const argumentIdx = variable - 1;
      const argument = condition.args[argumentIdx];
      if (argument == null) return { result: text.emptyArg };

      const conditionFlowData = conditionMap[condition.type];
      const argumentFlowData = conditionFlowData.args[argumentIdx];
      const primitiveInput = primitiveInputMap[argumentFlowData.type];

      return getRenderedArgument(
        {
          argument,
          rep,
          variable,
          primitiveInput,
          condition,
          ctx,
        },
        opts,
      );
    }
    case 'Flow___SubjectFieldCondition': {
      if (variable == 0) return { result: rep };

      const argumentIdx = variable - 1;
      const argument = condition.args[argumentIdx];
      if (argument == null) return { result: text.emptyArg };

      const subject = getSubject(
        {
          type: condition.type,
          typeId: condition.typeId,
        },
        subjectMap,
      );

      const subjectFieldCondition = subject?.fields.find(
        field => field.key === condition.field,
      );
      if (!subjectFieldCondition) return { error: text.invalidVariable };

      const argumentFlowData = subjectFieldCondition.args[argumentIdx];
      const primitiveInput = primitiveInputMap[argumentFlowData.type];

      return getRenderedArgument(
        {
          argument,
          rep,
          variable,
          primitiveInput,
          condition,
          ctx,
        },
        opts,
      );
    }
  }
};

export default getLabelForRep;
