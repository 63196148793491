import { FlowConditionFields_Flow_Condition_Event_Contact_App_Hypotheekbond_New_Fragment } from '~/graphql/types';
import { PARAMETER_PRIMITIVE_VALUE_TYPE } from '../../FlowParameter/ParameterValue/constants';
import { FlowConditionAppHypotheekbondNew } from './types.flow';

const asFlowConditionAppHypotheekbondNewProp = (
  condition: FlowConditionFields_Flow_Condition_Event_Contact_App_Hypotheekbond_New_Fragment,
): Omit<FlowConditionAppHypotheekbondNew, 'id'> => ({
  type: 'Flow_Condition_Event_Contact_App_Hypotheekbond_New',
  tool: {
    type: PARAMETER_PRIMITIVE_VALUE_TYPE.STRING_PRIMITIVE,
    value: condition.tool?.stringValue ?? null,
  },
});

export default asFlowConditionAppHypotheekbondNewProp;
