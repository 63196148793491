import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import { Attachment } from '../..';
import Button from '~/components/Button';
import JustificationContainer from '~/components/JustificationContainer';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  attachment: Attachment;
  onDelete: () => void;
};

const FileAttachment: React.FC<Props> = ({
  attachment,
  onDelete,
  dataTestId,
}: Props) => {
  const { file } = attachment;

  const fileName =
    file && file.__typename === 'Flow___Argument_File'
      ? file.value_file.filename
      : '';

  return (
    <StyledJustificationContainer
      dataTestId={dataTestId}
      justification="space-between"
      margin={['xxxs', null]}
    >
      <StyledIcon name="file-text" />
      <Anchor
        href={attachment.url as string}
        target="_blank"
        rel="noopener noreferrer"
        key={fileName}
        download={fileName}
      >
        {fileName}
      </Anchor>
      <Button
        icon="delete"
        appearance="danger"
        ghost
        onClick={onDelete}
        dataTestId={TEST_ID.DELETE_BUTTON}
      />
    </StyledJustificationContainer>
  );
};

const StyledJustificationContainer = styled(JustificationContainer)<{}>(
  () => css`
    width: 100%;
  `,
);

const Anchor = styled.a<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary', 'light')};
    margin-right: ${theme.space('s')};
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

const StyledIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    font-size: ${theme.space('m')};
    margin-right: ${theme.space('s')};
  `}
`;

export default FileAttachment;
