import { WizardFlow } from '~/graphql/types.client';
import hypotheekbondEnablementFlow from '../../Apps/Hypotheekbond/enablementFlow';
import realworksEnablementFlow from '../../Apps/Realworks/enablementFlow';
import trustooEnablementFlow from '../../Apps/Trustoo/enablementFlow';
import valueReportEnablementFlow from '../../Apps/ValueReport/enablementFlow';
import zapierEnablementFlow from '../../Apps/Zapier/enablementFlow';
import { reporter } from '~/hooks/useErrorReporter';
import getWaardecheckEnablementFlow from '../../Apps/Waardecheck/utils/getWaardecheckEnablementFlow';
import { ExtendedAppConfig } from '~/hooks/useApps';
import getGenericWizardFlow from '../getGenericWizardFlow';

/**
 * Returns a wizard enablement flow by typename of the app
 */

const getWizardFlow = (app: ExtendedAppConfig): WizardFlow | undefined => {
  if (app.wizard === 'custom') {
    switch (app.__typename) {
      case 'AppStatus_BBWaardecheck':
        return getWaardecheckEnablementFlow(app.__typename);
      case 'AppStatus_Hypotheekbond':
        return hypotheekbondEnablementFlow;
      case 'AppStatus_Realworks':
        return realworksEnablementFlow;
      case 'AppStatus_Trustoo':
        return trustooEnablementFlow;
      case 'AppStatus_ValuationReport':
        return valueReportEnablementFlow;
      case 'AppStatus_VBOWaardecheck':
        return getWaardecheckEnablementFlow(app.__typename);
      case 'AppStatus_Zapier':
        return zapierEnablementFlow;

      default:
        reporter.captureException(
          new Error(
            `No enablementFlow found for ${app.__typename}. You probably forgot to create one.`,
          ),
          'critical',
        );
        return;
    }
  }

  return getGenericWizardFlow(app);
};

export default getWizardFlow;
