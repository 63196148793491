import uuid from 'uuid/v4';

/**
 * Our UUID's are prefixed to ensure uniqueness and easy validation.
 *
 * You might see `$ID_PREFIX-uuid` written in certain parts of the documentation,
 * this always refers to a certain Id.
 *
 * e.g. a-uuid -> accountId
 */
export const ID_PREFIX = {
  FLOW_BLUEPRINT: 'fbp-',
  FLOW_BLUEPRINT_ACTION: 'fbpa',
};
export const ID_PREFIX_LENGTH = 4;

const transformUuidToFlowBlueprintId = (uuid: string) =>
  `${ID_PREFIX.FLOW_BLUEPRINT}${uuid}`;

const transformUuidToFlowBlueprintActionId = (uuid: string) =>
  `${ID_PREFIX.FLOW_BLUEPRINT_ACTION}${uuid}`;

const transformEntityIdToUuid = (entityId: string) =>
  transformIdToUuid(entityId);

/**
 * Transform an internal uuid ( prefix + uuid ) to the default uuid
 *
 * @param {string} id
 */
const transformIdToUuid = (id: string) => id.substr(ID_PREFIX_LENGTH);

const generateUuid = () => uuid();

const generateFlowBlueprintId = () => transformUuidToFlowBlueprintId(uuid());

const generateFlowBlueprintActionId = () =>
  transformUuidToFlowBlueprintActionId(uuid());

export default {
  transformIdToUuid,
  transformEntityIdToUuid,
  transformUuidToFlowBlueprintId,
  transformUuidToFlowBlueprintActionId,
  generateUuid,
  generateFlowBlueprintId,
  generateFlowBlueprintActionId,
};
