import uuidv1 from 'uuid/v1';
import namedEmailFormatter, { NamedEmail } from '~/util/namedEmailFormatter';
import { EmailListProps } from '../..';
import { EmailItemType } from '~/components/Inputs/TagListInputs/EmailSelectInput';
import Validation from '~/util/Validation';

/**
 *
 * @param {EmailListProps} prop - EmailListProps
 * keywords: namedEmailList, cc, bcc
 */
const getNamedEmailListFromProp = (
  prop: EmailListProps,
): Array<EmailItemType> => {
  const mapFunction = (namedEmail: NamedEmail) => ({
    label: namedEmailFormatter(namedEmail),
    namedEmail,
    isValid: Validation.Email.isValid(namedEmail.email),
    key: uuidv1(),
  });

  return prop.namedEmailList && prop.namedEmailList.length > 0
    ? prop.namedEmailList.map(mapFunction)
    : [];
};

export default getNamedEmailListFromProp;
