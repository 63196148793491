import React from 'react';
import { Props } from '..';

const TextColor: React.FC<Props> = ({
  color = 'currentColor',
  ...props
}: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.90002 17.4002L9.30002 4.2002H10L13.6 14.9002"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.40002 12.9004H12.8"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1 18.0004C19.1 19.0004 18.3 19.8004 17.3 19.8004C16.3 19.8004 15.5 19.0004 15.5 18.0004C15.5 16.6004 17.3 14.4004 17.3 14.4004C17.3 14.4004 19.1 16.7004 19.1 18.0004Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TextColor;
