import cleanedFilename from '~/util/cleanedFilename';

export default {
  TYPE_FIELD: `${cleanedFilename(__filename)}-type-field`,
  DESCRIPTION: `${cleanedFilename(__filename)}-description`,
  TYPE_AND_TITLE_BAR: `${cleanedFilename(__filename)}-type-and-title-bar`,
  OFFICE: `${cleanedFilename(__filename)}-office`,
  USER: `${cleanedFilename(__filename)}-user`,
  ASSIGN_TO_CONTACT_OWNER: `${cleanedFilename(
    __filename,
  )}-assign-to-contact-owner`,
};
