import type { FlowData___FlowInstanceFragment } from '~/graphql/types';

export type InstanceMap = {
  [flowActionId in string]: {
    [variableName in string]: FlowData___FlowInstanceFragment;
  };
};

/**
 * Generate the subject map
 */
export const generateInstanceMap = (
  instances: Array<FlowData___FlowInstanceFragment>,
): InstanceMap =>
  instances.reduce((prev, curr) => {
    if (prev[curr.key[0]] == null) prev[curr.key[0]] = {};
    prev[curr.key[0]][curr.key[1]] = curr;

    return prev;
  }, {} as InstanceMap);

export const getInstance = (
  {
    flowActionId,
    variableName,
  }: { flowActionId: string; variableName: string },
  instanceMap: InstanceMap,
): FlowData___FlowInstanceFragment | undefined =>
  instanceMap[flowActionId]?.[variableName];

export default getInstance;
