import React from 'react';
import styled, { css } from 'styled-components';

import { Avatar } from '~/components';
import Icon from '~/components/Icon';

export type Props = {
  userImage?: string | null;
};

const UserImage: React.FC<Props> = ({ userImage }) => (
  //if userImage is undefined then we do not show this component at all
  <>
    {userImage !== undefined && (
      <Container>
        {userImage === null ? (
          <StyledIcon name="user" />
        ) : (
          <Avatar avatarFor="userProfile" src={userImage} />
        )}
      </Container>
    )}
  </>
);

const SPACE_OUTSIDE_SVG = '0.3rem';

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${SPACE_OUTSIDE_SVG};
    svg {
      width: ${theme.space('l')};
      height: ${theme.space('l')};

      ${theme.mq.lessThan('mobile')`
        height: ${theme.space('xl')};
        width: ${theme.space('xl')};
    `}
    }
  `,
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('xxs')};
    height: ${theme.space('xxl')};
    width: ${theme.space('xxl')};
    flex: 1 0 ${theme.space('xxl')};
    background-color: ${theme.color('grey', 'light')};
    border-radius: ${theme.getTokens().border.radius.full};
  `,
);

export default UserImage;
