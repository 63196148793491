import React from 'react';
import styled, { css } from 'styled-components';
import Dropdown from '~/components/Dropdown';
import { OptionOf } from '~/components/Inputs/Dropdown';
import { Heading5, Variant } from '~/components/Typography/index';
import { GetOverviewStatisticsQueryVariables } from '~/graphql/types';

export type Props = {
  dataTestId?: string;
  today: Date;
  dateRanges: Array<
    OptionOf<{
      start: GetOverviewStatisticsQueryVariables['start'];
      end: GetOverviewStatisticsQueryVariables['end'];
    }>
  >;
  selectedDateRangeIndex: number;
  onDateRangeChange: (dateRangeIndex: number) => void;
};

const DropdownGroup: React.FC<Props> = ({
  dataTestId,
  dateRanges,
  selectedDateRangeIndex,
  onDateRangeChange,
}) => (
  <Container data-testid={dataTestId}>
    <Label
      margin={[null, 'l', null, null]}
      whiteSpace="nowrap"
      variant={Variant.primary}
    >
      Toon statistieken voor
    </Label>
    <Dropdown
      options={dateRanges}
      selectedOptionIdx={selectedDateRangeIndex}
      onChange={({ selectedOptionIdx }) => {
        onDateRangeChange(selectedOptionIdx);
      }}
    />
  </Container>
);

const Label = styled(Heading5)<{}>(
  ({ theme }) => css`
    :nth-of-type(2) {
      margin-left: ${theme.space('l')};
    }
  `,
);

const Container = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default DropdownGroup;
