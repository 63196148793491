import React, { useContext } from 'react';
import MetaTags from 'react-meta-tags';
import { navigate } from 'gatsby';
import DHRouter from '~/components/DHRouter';
import { RouteComponentProps } from '@reach/router';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import OverviewListHeader from '~/components/OverviewListHeader';
import TestId from './index.testid';
import Wizard from '~/components/Wizard';
import AppCards from './components/AppCards';
import AccountContext from '~/contexts/AccountContext';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import {
  AppStatusLockedReason,
  AppStatus__Input,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import Loading from '~/components/Loading';
import FullPageInformation from '~/components/FullPageInformation';
import useAddToast from '~/hooks/useAddToast';
import useErrorReporter from '~/hooks/useErrorReporter';
import formatToastMessage from '~/util/formatToastMessage';
import appModals from './components/AppModals';
import useApps from '~/hooks/useApps';
import { AppStatus__typename } from '~/graphql/types.client';
import useWizard from '~/hooks/useWizard';
import getEnablementFlow from '~/components/Wizard/utils/getEnablementFlow';

const text = {
  appsTitle: 'Apps',
  errorTitle: 'Geen data gevonden',
  errorExplanation:
    'Er is iets misgegaan bij het ophalen van de data van het account. Waarschijnlijk kan er geen verbinding gemaakt worden met de server. Probeer het nog een keer',
  disablingExplanation:
    'App is al gedeïnstalleerd. Het kan even duren voordat het op de pagina wordt weergegeven. Ververs de pagina en probeer het nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};
type Props = RouteComponentProps & { testDataId?: string };

const AppsList: React.FC<Props> = ({ uri = '' }) => {
  const { refetchSessionHydration } = useContext(AccountContext);
  const { id: accountId } = useCurrentAccount();
  const errorReporter = useErrorReporter();
  const addToast = useAddToast();
  const wizardApi = useWizard();

  const { apps, error, updateQuery, refetch, loading } = useApps();

  /**
   * Reach router defines uri as potentially undefined here, this seems
   * to never be the case as long as we render this inside of the Router
   * component which is where it should be rendered.
   */
  const onClose = () => void navigate(uri as string);

  const [updateAppStatusImpl] = useUpdateAppStatusMutation();

  const updateAppStatus = async ({
    type,
    update,
  }: {
    type: AppStatus__typename;
    update: AppStatus__Input;
  }) => {
    const app = apps[type];

    const { data, errors } = await updateAppStatusImpl({
      variables: {
        accountId,
        update,
      },
    });

    if (errors?.length || !data) {
      errorReporter.captureException(
        new Error(
          JSON.stringify(errors || 'No data received from updateAppStatus'),
        ),
        'critical',
      );
      return;
    }

    if (data.updateAppStatus.locked === AppStatusLockedReason.Disabling) {
      updateQuery(data => ({
        ...data,
        getAppStatuses: data.getAppStatuses.map(appStatus => {
          if (appStatus.__typename !== type) return appStatus;

          return {
            ...appStatus,
            // Update the query result to disable the deinstall button
            locked: AppStatusLockedReason.Disabling,
          };
        }),
      }));

      return addToast([
        formatToastMessage(text.disablingExplanation, 'danger'),
      ]);
    }

    const { updateAppStatus } = data;
    updateQuery(data => ({
      ...data,
      getAppStatuses: data.getAppStatuses.map(appStatus => {
        if (appStatus.__typename !== type) {
          return appStatus;
        }

        return updateAppStatus;
      }),
    }));

    const enablementFlow = getEnablementFlow({
      ...app,
      appStatus: updateAppStatus,
    });

    if (enablementFlow) {
      if (update[type]?.enabled) {
        wizardApi.show({
          header: app.name,
          ...enablementFlow,
        });
      }

      // Remove enablementFlow.id from db states and clear the output when disabling the app
      if (update[type]?.enabled === false) {
        void wizardApi.clear(enablementFlow.id);
      }
    }
    await refetchSessionHydration();
  };

  if (loading) return <Loading />;
  if (error || !apps) {
    return (
      <FullPageInformation
        title={text.errorTitle}
        explanation={text.errorExplanation}
      />
    );
  }

  return (
    <>
      <MetaTags>
        <title>{text.appsTitle}</title>
      </MetaTags>

      <ContentContainerDefault>
        <OverviewListHeader title={text.appsTitle} />
        <Wizard
          header="App enablement [SHOULD BE OVERWRITTEN]"
          id="app-enablement-wizard"
          steps={[]}
          onComplete={async () => {
            await refetchSessionHydration();
            await refetch();
          }}
        >
          <DHRouter height="auto">
            {appModals({ apps, updateAppStatus, onClose })}
          </DHRouter>
        </Wizard>
        <AppCards apps={apps} data-testid={TestId.CONTAINER} />
      </ContentContainerDefault>
    </>
  );
};

export default AppsList;
