import React from 'react';

import TEST_ID from './AddParameterValueButton.testid';
import { Button } from '~/components/Buttons';

const text = {
  buttonLabel: 'Voeg toe',
};
type Props = {
  isVisible: boolean;
  onClick: () => void;
};
const AddParameterValueButton = ({ isVisible, onClick }: Props) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Button onClick={onClick} secondary data-testid={TEST_ID.BUTTON}>
      {text.buttonLabel}
    </Button>
  );
};

export default AddParameterValueButton;
