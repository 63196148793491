import { isNil } from 'ramda';
import React from 'react';
import { Handle, Position } from 'reactflow';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import { Heading5, Variant } from '~/components/Typography/index';
import { FlowAction } from '~/graphql/types';
import { ClientFlowActionContactDetails } from '~/graphql/types.client';
import { issuesByActionId } from '~/scenes/Automation/v2/state/flowIssues';
import getCardHeading from '../../../../utils/getCardHeading';
import Issue from '../../../Issues/InCard';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import TemplateString from '../TemplateString';

export type Props = Omit<
  CardProps<ClientFlowActionContactDetails>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const ContactDetailsCard: React.FC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const { field } = data.action;
    const issues = useRecoilValue(issuesByActionId(data.id));

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.ContactDetails}
          heading={getCardHeading(FlowAction.ContactDetails)}
          selected={selected}
          disabled={disabled}
          data={data}
        >
          {
            <JustificationContainer direction="column">
              <WithMargins>
                {!field.phone?.template ? (
                  <JustificationContainer align="center">
                    <Heading5 withoutMargin inline variant={Variant.primary}>
                      Naam:&nbsp;
                    </Heading5>
                    {isNil(field.name) ? (
                      '-'
                    ) : (
                      <TemplateString
                        templateString={field.name}
                        actionId={data.action.id}
                      />
                    )}
                  </JustificationContainer>
                ) : (
                  <JustificationContainer align="center">
                    <Heading5 withoutMargin inline variant={Variant.primary}>
                      Telefoonnummer:&nbsp;
                    </Heading5>

                    {isNil(field.phone.template) ? (
                      '-'
                    ) : (
                      <TemplateString
                        templateString={field.phone}
                        actionId={data.action.id}
                      />
                    )}
                  </JustificationContainer>
                )}
              </WithMargins>

              {issues &&
                issues.map(issue => <Issue key={issue.message} {...issue} />)}
            </JustificationContainer>
          }
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

const WithMargins = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

export default ContactDetailsCard;
