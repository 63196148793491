import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { isEmpty, isNil } from 'ramda';
import React, { useState } from 'react';
import Button from '~/components/Button';
import CopyCode from '~/components/CopyCode';
import GoToPath from '~/components/GoToPath';
import Input from '~/components/Input';
import JustificationContainer from '~/components/JustificationContainer';
import Screenshot from '~/components/Screenshot';
import TextButton from '~/components/TextButton';
import { Body } from '~/components/Typography/index';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import AppDetailsContainer from '~/scenes/Apps/components/AppDetailsContainer';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import { LINK_PREFIX, StepProps } from '../..';
import RealworksHelpBlock from '../RealworksHelpBlock';
import TEST_ID from './index.testid';
import { isNonEmptyString } from '~/util/Validation/String';
import RealworksCallToActionBlock from '../RealworksCallToActionBlock';

export const exportContactsScreenshotLink =
  'https://dathuis-stash.imgix.net/realworks_export_contacts.png';

const text = {
  description:
    'Om contact te exporteren naar Realworks gebruiken we de Wonen API. Vul de API Manager in zoals op de onderstaande screenshot. Het is belangrijk dat de instellingen exact overeenkomen.',
  code: 'eb92a256-b27d-4747-80c4-4d4b45485cc8',
  firstAppEntryHeader: '1. Ga in RealWorks naar',
  secondAppEntryHeader: '2. Plak onze Developer ID in Realworks',
  thirdAppEntryHeader: '3. Kopieer de “token” en plak deze hier',
  inputPlaceHolder: 'Bijv. "Vestiging Amsterdam"',
  skipStep: 'Stap overslaan',
  skipStepDescription: 'Wil je geen contacten naar RealWorks exporteren?',
};

// Make it looks the same size with the screenshot on the prev page
const SCREENSHOT_MAX_WIDTH = 720;

const validate = (value, relatieToken) => {
  if (isNil(value) || isEmpty(value)) return 'Vul een waarde in';
  if (value === relatieToken)
    return 'Kan niet hetzelfde zijn als de relatie token';
  return true;
};
const ExportContactsStep: React.FC<StepProps> = ({ onCancel }) => {
  const [wonen, setWonen] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input['wonen']>(null);

  // @ts-ignore WindowLocation<S = unknown>. S is the type of state but we cannot type it ourselves because the types come from types/reach__router
  const location: WindowLocation<{
    values: UpdateRealworksAppStatus_AddTokenContainer__Input;
  }> = useLocation();
  const locationValues = location?.state?.values || {};

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    void navigate(`${LINK_PREFIX}/activation-pending`, {
      replace: true,
      state: { values: { ...location?.state.values, wonen } },
    });
  };

  const skipCurrentStep = () =>
    navigate(`${LINK_PREFIX}/activation-pending`, {
      replace: true,
      state: { values: { ...locationValues, wonen: null } },
    });

  const relatieToken = locationValues.relatie;

  return (
    <AppDetailsContainer
      header="Contacten exporteren"
      icon="outgoingUser"
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body>{text.description}</Body>
      <RealworksCallToActionBlock
        description={text.skipStepDescription}
        skipCurrentStep={skipCurrentStep}
        dataTestId={TEST_ID.NOTIFICATION_BUTTON}
      />
      <RealworksHelpBlock tipId="export-contacts-step-tip" />
      <form onSubmit={onSubmit} data-testid={TEST_ID.FORM}>
        <StyledAppEntryItemHeader>
          {text.firstAppEntryHeader}
        </StyledAppEntryItemHeader>

        <GoToPath
          path={[
            'Servicedesk',
            'Marketplace',
            "API's",
            'Wonen API',
            'Bestellen',
          ]}
          label="Ga naar marketplace"
          link="https://crm.realworks.nl/servlets/objects/admin.marketplace/overview"
        />
        <JustificationContainer
          justification="start"
          margin={['base', null, null, null]}
        >
          <CopyCode
            label={text.secondAppEntryHeader}
            code={text.code}
            style={{ width: '100%' }}
          />
        </JustificationContainer>

        <JustificationContainer justification="center" margin={['base', null]}>
          <Screenshot
            maxWidth={SCREENSHOT_MAX_WIDTH}
            src={exportContactsScreenshotLink}
            alt="screenshot"
            withoutShadow
          />
        </JustificationContainer>

        <AppEntryItemHeader>{text.thirdAppEntryHeader}</AppEntryItemHeader>
        <Input
          appearance="primary"
          validation={[value => validate(value, relatieToken)]}
          validationIndicator
          value={wonen ?? ''}
          onChange={e => setWonen(e.target.value)}
          dataTestId={TEST_ID.TOKEN_INPUT}
        />

        <JustificationContainer
          justification="space-between"
          align="center"
          margin={['l', null, null]}
        >
          <TextButton
            label="Afbreken"
            onClick={onCancel}
            withoutPadding
            appearance="danger"
            type="button"
            dataTestId="exit-wizard-button"
          />

          <JustificationContainer justification="start">
            <TextButton
              appearance="primary"
              label={text.skipStep}
              onClick={() =>
                navigate(`${LINK_PREFIX}/activation-pending`, {
                  replace: true,
                  state: { values: { ...locationValues, wonen: null } },
                })
              }
              dataTestId={TEST_ID.SKIP_BUTTON}
            />
            <Button
              icon="check"
              label="API instellen"
              type="submit"
              size="medium"
              loading={false}
              disabled={relatieToken === wonen || !isNonEmptyString(wonen)}
              dataTestId={TEST_ID.NEXT_BUTTON}
            />
          </JustificationContainer>
        </JustificationContainer>
      </form>
    </AppDetailsContainer>
  );
};

const StyledAppEntryItemHeader = styled(AppEntryItemHeader)<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('xl')};
  `,
);

export default ExportContactsStep;
