import { FlowVariableStashItem } from '~/scenes/Automation/Flows/types.flow';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import { PointerVariable } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { OptionOf } from '~/components/Inputs/Dropdown';
import { FieldType } from './getFieldOptions';

import getFieldOptions from './getFieldOptions';

const validateStashVariable = (
  variable: PointerVariable | null,
  possibleVariables: Array<FlowVariableStashItem>,
  fieldType: FLOW_OUTPUT_TYPE,
): boolean => {
  if (variable == null) return false;
  const selectedField = variable.field;

  if (selectedField == null) return false;

  const selectedVariableIdx = findVariableIndexIn(possibleVariables, variable);
  if (selectedVariableIdx < 0) {
    return false;
  }

  const selectedVariable = possibleVariables[selectedVariableIdx];
  const fieldOptions = getFieldOptions(selectedVariable, undefined, fieldType);

  const selectedFieldIdx = findFieldIndexIn(fieldOptions, selectedField);

  if (selectedFieldIdx < 0) {
    return false;
  }

  return true;
};

const findVariableIndexIn = (
  possibleVariables: Array<FlowVariableStashItem>,
  variable: PointerVariable | null,
): number => {
  if (variable == null) return -1;

  return possibleVariables.findIndex(
    possibleVariable => possibleVariable.variableName === variable.name,
  );
};

const findFieldIndexIn = (
  fieldOptions: Array<OptionOf<FieldType>>,
  selectedField: null | { name: string },
): number => {
  if (selectedField == null) return -1;

  return fieldOptions.findIndex(option => option.key === selectedField.name);
};

export default validateStashVariable;
