import React from 'react';
import AppSetupModal, { AppSetupModalProps } from '~/components/AppSetupModal';
import { NvmAppType, useStartNvmOauthMutation } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useErrorReporter from '~/hooks/useErrorReporter';

type Props = AppSetupModalProps & {
  dataTestId?: string;
};

const AppSetupModalWithOAuthFlow: React.FC<Props> = ({ ...rest }) => {
  const errorReporter = useErrorReporter();
  const account = useCurrentAccount();

  const [startNvmOAuth] = useStartNvmOauthMutation();

  const onOAuthCall = async ({ onError }) => {
    // This will be replaced with startOAuthForAppType mutation
    const { data, errors } = await startNvmOAuth({
      variables: {
        accountId: account.id,
        participatingOffices: 1,
        // Redirect back into the setup modal so the user can click "finish installation"
        callbackUrl: `${origin}/-/apps/setup/${rest.app.slug}`,
        appType: NvmAppType.BbWaardecheck,
      },
    });

    if (errors || !data) {
      errorReporter.captureException(
        new Error(JSON.stringify(errors || 'No Data')),
        'critical',
      );
      return onError();
    }

    global.window.location.href = data.startNVMOauth.redirectUrl;
  };

  return <AppSetupModal {...rest} onOAuthCall={onOAuthCall} />;
};

export default AppSetupModalWithOAuthFlow;
