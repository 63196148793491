import React from 'react';

import { Textarea } from '~/components/Inputs';

type Props = {
  text: string | null | undefined;
  title: string;
  name: string;
  testName: string;
  onChange: (value: string | null) => void;
  disabled: boolean;
  large?: boolean;
};
type State = {};

class TextEditor extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
  };

  shouldComponentUpdate(nextProps: Props) {
    return (
      nextProps.text !== this.props.text ||
      nextProps.disabled !== this.props.disabled
    );
  }

  render() {
    const { disabled, title, text, name, testName, large } = this.props;

    return (
      <div className="textContainer">
        <p>{title}</p>
        <Textarea
          dataTestid={`text-edit-${testName}-${name}`}
          name={name}
          value={text !== undefined ? text : null}
          onChange={value => this.props.onChange(value || null)}
          disabled={disabled}
          large={large}
        />
      </div>
    );
  }
}

export default TextEditor;
