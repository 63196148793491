import { OptionOf } from '~/components/Dropdown';
import { setTextStyle } from '~/components/PluginsEditor/commands/modify/text';
import { DHEditor } from '~/components/PluginsEditor/types';

const fontFamilies: Array<OptionOf<string>> = [
  { payload: 'Arial, sans-serif', label: 'Arial', key: 'arial' },
  {
    payload: 'Arial Black, sans-serif',
    label: 'Arial black',
    key: 'arial-black',
  },
  {
    payload: 'Times New Roman, serif',
    label: 'Times new roman',
    key: 'times-new-roman',
  },
  { payload: 'Courier, monospace', label: 'Courier', key: 'courier' },
  { payload: 'Georgia, serif', label: 'Georgia', key: 'georgia' },
  { payload: 'Helvetica, sans-serif', label: 'Helvetica', key: 'helvetica' },
];

const getFontFamilyOptions = (editor: DHEditor): Array<OptionOf<string>> =>
  fontFamilies.map(({ key, payload, label }) => ({
    key,
    label,
    payload,
    onClickAction: () => setTextStyle(editor, 'fontFamily', `${payload}`),
  }));

export default getFontFamilyOptions;
