import {
  ClientFlowAction,
  ConditionalFlowAction,
} from '~/graphql/types.client';

/**
 * Returns true if action is a conditional action
 * @param {ClientFlowAction} action - ClientFlowAction
 * keywords: check action conditional
 */
const isConditionalAction = (
  action: ClientFlowAction,
): action is ConditionalFlowAction => {
  if (
    action.__typename === 'FlowV2_Action_IfElse' ||
    action.__typename === 'FlowV2_Action_Start' ||
    action.__typename === 'FlowV2_Action_Wait'
  ) {
    return true;
  }

  return false;
};

export default isConditionalAction;
