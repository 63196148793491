import { FlowParameterFieldsFragment } from '~/graphql/types';
import { ParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import {
  asStringParameterValue,
  emptyStringPrimitiveParameterValue,
} from './String';
import { asOfficeParameterValue } from './Office';
import { asUserParameterValue } from './User';
import { asEmailParameterValue } from './Email';
import { asNumberParameterValue } from './Number';
import { asBooleanParameterValue } from './Boolean';

export default (
  fragment: FlowParameterFieldsFragment | null | undefined,
): ParameterValue => {
  if (fragment == null) return emptyStringPrimitiveParameterValue();

  switch (fragment.__typename) {
    case 'Flow_Parameter_String_Pointer':
    case 'Flow_Parameter_String_Primitive':
      return asStringParameterValue(fragment);
    case 'Flow_Parameter_Office_Pointer':
    case 'Flow_Parameter_Office_Primitive':
      return asOfficeParameterValue(fragment);
    case 'Flow_Parameter_User_Pointer':
    case 'Flow_Parameter_User_Primitive':
      return asUserParameterValue(fragment);
    case 'Flow_Parameter_Email_Pointer':
    case 'Flow_Parameter_Email_Primitive':
      return asEmailParameterValue(fragment);
    case 'Flow_Parameter_Number_Pointer':
    case 'Flow_Parameter_Number_Primitive':
      return asNumberParameterValue(fragment);
    case 'Flow_Parameter_Boolean_Pointer':
    case 'Flow_Parameter_Boolean_Primitive':
      return asBooleanParameterValue(fragment);
    default:
      throw new Error(
        `NYI: asParameterValueProp for __typename (${fragment.__typename})`,
      );
  }
};
