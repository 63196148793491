import { FlowConditionAppVBOWaardecheckAppraisalRequestNew } from './types.flow';
import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';

const emptyFlowConditionAppVBOWaardecheckAppraisalRequestNew = (): Omit<
  FlowConditionAppVBOWaardecheckAppraisalRequestNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES
    .EVENT_CONTACT_APP_VBOWAARDECHECK_APPRAISALREQUEST_NEW.type,
});

export default emptyFlowConditionAppVBOWaardecheckAppraisalRequestNew;
