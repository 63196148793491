import React from 'react';
import MetaTags from 'react-meta-tags';

import FullPageInformation from '~/components/FullPageInformation';
import FullScreenBackgroundDiv from './FullScreenBackgroundDiv';
import { RouteComponentProps } from '@reach/router';
import TEST_ID from './AccountLocked.testid';

export const text = {
  pageTitle: 'Geblokkeerd',
  title: 'Account geblokkeerd',
  explanation:
    'Dit account is geblokkeerd. Neem contact op met onze helpdesk om toegang te herstellen.  Wij zijn te bereiken via de chat, op contact@dathuis.nl of per telefoon op 020 - 222 35 71.',
};

const AccountLocked: React.FC<RouteComponentProps> = () => (
  <FullScreenBackgroundDiv>
    <MetaTags>
      <title>{text.pageTitle}</title>
    </MetaTags>
    <FullPageInformation
      dataTestid={TEST_ID.CONTAINER}
      title={text.title}
      explanation={text.explanation}
    />
  </FullScreenBackgroundDiv>
);

export default AccountLocked;
