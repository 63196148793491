import React, { Component } from 'react';
import { FORM_ERROR } from 'final-form';
import Link from 'gatsby-link';
import MetaTags from 'react-meta-tags';
import { navigate } from 'gatsby';
import { Form, EmailField } from '~/components/Forms';
import Amplify from '~/amplify';
import Validation from '~/util/Validation';
import AuthNav from '~/scenes/Auth/components/AuthNav';
import AuthWrapperHOC from '~/scenes/Auth/components/AuthWrapperHOC';
import { H1 } from '~/components/Typography';
import { FormUtils } from '~/components';
import Button from '~/components/Button';
import { InputGroup, Input } from '~/components/Inputs';
import ErrorTypes from '~/ErrorTypes';

import {
  ActionWrapper,
  AuthForm,
  ErrorMsg,
  OtherOptionsLink,
} from '~/scenes/Auth/Auth.style';
import withDelayedLoading, {
  WithDelayedLoading,
} from '~/components/util/withDelayedLoading';
import { reportErrorToTracker } from '~/util/assertion';
import Catalog from '~/Catalog';

type FormData = {
  email: string | null;
};

type Props = WithDelayedLoading;
type State = {};
const text = {
  emailLabel: Catalog.emailLabel,
  noEmail: Catalog.noEmail,
  invalidEmail: Catalog.invalidEmail,
  userNotFound: 'Er is nog geen account met dit e-mailadres aangemaakt.',
  explanation:
    'Om je wachtwoord te resetten, hebben we je e-mailadres nodig. Je ontvangt een code waarmee je je wachtwoord kan resetten.',
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
  pageTitle: 'Reset wachtwoord',
  sendEmailButton: 'Verstuur reset e-mail',
  noAccount: 'Nog geen account?',
  registerHere: 'Registreer je hier',
  incompleteUser: 'Dit account is nog niet geverifieerd.',
  inactiveUser:
    'Een reset van je wachtwoord is niet mogelijk. Controleer of je een uitnodiging voor het activeren van je account hebt ontvangen.',
};
class ForgotPassword extends Component<Props, State> {
  validate = ({ email }: FormData) => {
    const errors: {
      email: string | undefined;
    } = {
      email: undefined,
    };

    if (Validation.String.isEmpty(email)) {
      errors.email = text.noEmail;
    }
    if (!Validation.Email.isValid(email)) {
      errors.email = text.invalidEmail;
    }

    return errors;
  };

  onFormSubmit = ({ email }: { email: string }) => {
    const loweredEmail = email.toLowerCase();

    return Amplify.Auth.forgotPassword(loweredEmail)
      .then(() => {
        this.props.setLoading();
        void navigate('/setup-newpassword', {
          state: { email: loweredEmail },
        });
      })
      .catch(err => {
        this.props.removeLoading();

        if (
          err.__type === ErrorTypes.notAuthorized ||
          err.code === ErrorTypes.notAuthorized
        ) {
          return { [FORM_ERROR]: text.inactiveUser };
        }

        if (
          err.__type === 'UserNotFoundException' ||
          err.code === 'UserNotFoundException'
        ) {
          return { [FORM_ERROR]: text.userNotFound };
        }

        if (
          err.__type === 'InvalidParameterException' ||
          err.code === 'InvalidParameterException'
        ) {
          return { [FORM_ERROR]: text.incompleteUser };
        }

        reportErrorToTracker(err.message);
        return {
          [FORM_ERROR]: text.genericErrorMessage,
        };
      });
  };

  render() {
    const { loading } = this.props;
    const title = text.pageTitle;

    return (
      <AuthWrapperHOC>
        <MetaTags>
          <title>{title}</title>
          <meta name="description" content="Vraag een nieuw wachtwoord aan" />
        </MetaTags>

        <AuthNav selectedIdx={0} showBackLink={true} />

        <AuthForm>
          <H1>{title}</H1>

          <p>{text.explanation}</p>
          <Form
            initialValues={initialValues}
            onSubmit={this.onFormSubmit}
            validate={this.validate}
          >
            {({ handleSubmit, submitError, submitting, pristine }) => (
              <form
                id="forgotPassword"
                onSubmit={handleSubmit}
                data-testid="forgot-password-form"
              >
                {submitError ? (
                  <ErrorMsg data-testid="forgot-password-error-message">
                    {submitError}
                  </ErrorMsg>
                ) : null}
                <InputGroup>
                  <EmailField name="email">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        large
                        label={text.emailLabel}
                        error={FormUtils.showError(error, touched)}
                        disabled={submitting}
                        {...input}
                      />
                    )}
                  </EmailField>
                </InputGroup>
                <ActionWrapper>
                  <Button
                    size="large"
                    appearance="secondary"
                    loading={loading}
                    type="submit"
                    disabled={submitting || pristine || loading}
                    label={text.sendEmailButton}
                  />
                </ActionWrapper>
              </form>
            )}
          </Form>
          <OtherOptionsLink>
            {`${text.noAccount} `}
            <Link to={`/register/${location.search}`}>{text.registerHere}</Link>
          </OtherOptionsLink>
        </AuthForm>
      </AuthWrapperHOC>
    );
  }
}

const initialValues: FormData = {
  email: null,
};

export default withDelayedLoading(ForgotPassword);
