import { flatten, values, uniqBy, prop } from 'ramda';
import {} from 'react';
import { useSetRecoilState } from 'recoil';
import groupActivities from '~/components/EventTimelineV2/utils/groupActivities';
import { ActivityFieldsFragment } from '~/graphql/types';
import { activitiesByContactId } from '~/state/activities';

const useUpdateTimelineFn = (contactId: string | null) => {
  const setGroupedActivities = useSetRecoilState(
    activitiesByContactId(contactId),
  );

  const updateTimelineFn = (newActivity: ActivityFieldsFragment) => {
    setGroupedActivities(prev => {
      const allActivities = [
        ...flatten(values(prev)),
        {
          ...newActivity,
          highlighted: true,
        },
      ];

      return groupActivities(uniqBy(prop('id'), allActivities));
    });
  };

  return contactId ? updateTimelineFn : () => {};
};

export default useUpdateTimelineFn;
