import React from 'react';

import InputGroupButton from './InputGroupButton';
import TEST_ID from './DeleteInputButton.testid';
import Icon from '../Icon';

type Props = {
  /** Event handler for click */
  onClick: () => void;

  /** If the button is disabled */
  disabled?: boolean;

  /** String id for test */
  dataTestId?: string;

  /** Renders a small button */
  small?: boolean;

  /** Renders a large button */
  large?: boolean;

  /** If the delete action is loading */
  loading?: boolean;
};
const DeleteInputButton = ({
  onClick,
  small,
  large,
  disabled,
  dataTestId,
  loading,
}: Props) => (
  <InputGroupButton
    dangerOnHover
    small={small}
    large={large}
    dataTestId={dataTestId}
    disabled={disabled}
    onClick={onClick}
  >
    {loading === true ? (
      <Icon name="loading" data-testid={TEST_ID.LOADING_ICON} />
    ) : (
      <Icon name="trashcan" />
    )}
  </InputGroupButton>
);

export default DeleteInputButton;
