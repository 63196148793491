import React, { useState, useEffect } from 'react';
import { FORM_ERROR } from 'final-form';
import MetaTags from 'react-meta-tags';
import { isNil } from 'ramda';
import { navigate } from 'gatsby';
import { Form, Field, EmailField } from '~/components/Forms';
import Amplify from '~/amplify';
import Validation from '~/util/Validation';
import AuthWrapperHOC from '~/scenes/Auth/components/AuthWrapperHOC';
import AuthNav from '~/scenes/Auth/components/AuthNav';
import PasswordInputGroup from '~/scenes/Auth/components/PasswordInputGroup';
import { H1 } from '~/components/Typography';
import { FormUtils } from '~/components';

import { Input, Checkbox, InputGroup } from '~/components/Inputs';
import { ActionWrapper, AuthForm, ErrorMsg } from '~/scenes/Auth/Auth.style';
import { reportErrorToTracker } from '~/util/assertion';
import Catalog from '~/Catalog';
import ExternalLink from '~/components/ExternalLink';
import useDelayedLoading from '~/components/util/useDelayedLoading';
import { useLocation } from '@reach/router';
import TEST_ID from '~/scenes/Auth/Register.testid';
import useIntercom from '~/hooks/useIntercom';
import styled, { css } from 'styled-components';
import {
  INITIAL_SUBSCRIPTION,
  TAPFILLIATE_REF,
  setLocalStorageItem,
} from '~/util/localStorageKeys';
import { isValidInitialSubscriptionConfig } from '~/hooks/useInitialSubscription';
import Button from '~/components/Button';
import { isEmpty } from 'lodash';

type FormData = {
  name: string | null;
  password: string | null;
  passwordRepeat: string | null;
  email: string | null;
  phone: string | null;
};

type PasswordConditionsState = {
  charNumberValid: boolean;
  uppercaseValid: boolean;
  lowercaseValid: boolean;
  specialCharValid: boolean;
  numberValid: boolean;
};

const text = {
  noName: Catalog.noName,
  nameLabel: 'Voor- en achternaam',
  invalidEmail: Catalog.invalidEmail,
  noPassword: Catalog.noPassword,
  invalidPassword: Catalog.invalidPassword,
  noPasswordRepeat: Catalog.noPasswordRepeat,
  passwordsDoNotMatch: Catalog.passwordsDoNotMatch,
  unknownError: Catalog.genericUnknownErrorMessage,
  invalidPasswordFromCognito:
    'Het wachtwoord wordt niet geaccepteerd, probeer een ander wachtwoord',
  invalidEmailFromCognito:
    'Het e-mailadres wordt niet geaccepteerd, probeer een ander e-mailadres',
  title: 'Registreren',
  emailLabel: Catalog.emailLabel,
  phoneLabel: Catalog.phoneLabel,
  invalidPhone: Catalog.invalidPhone,
  noPhone: Catalog.noPhone,
  passwordLabel: Catalog.passwordLabel,
  passwordRepeatLabel: Catalog.passwordRepeatLabel,
  acceptTerms: 'Ik ga akkoord met de ',
  termsAndConditionsPdf: 'algemene voorwaarden',
  processingAgreementPdf: '(verwerkers)overeenkomst',
  registerButton: 'Registreren',
  userAlreadyExists:
    'Er bestaat al een gebruiker met dit e-mailadres. Je kunt met dit e-mailadres inloggen.',
  and: ' en ',
  acceptTermsPost: ' van DatHuis',
};

const Register = () => {
  const [passwordConditions, setPasswordConditions] =
    useState<PasswordConditionsState>({
      charNumberValid: false,
      uppercaseValid: false,
      lowercaseValid: false,
      specialCharValid: false,
      numberValid: false,
    });
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [loading, startLoading, stopLoading] = useDelayedLoading();
  const [title, setTitle] = useState<string>(text.title);
  const intercom = useIntercom();

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  /** Check the location to see if a signupcode or name is used */
  const signupCode = params.get('signupCode');

  useEffect(() => {
    const tapfillateRef = params.get('ref');
    if (tapfillateRef) {
      setLocalStorageItem(
        TAPFILLIATE_REF,
        JSON.stringify({ date: Date.now(), ref: tapfillateRef }),
      );
    }

    const accountName = params.get('accountName');
    if (accountName != null) {
      setTitle(`Registreer ${accountName}`);
    }

    /** Check the location to see if a billingCycle, plan and couponCode is set */
    const billingCycle = params.get('billingCycle');
    const plan = params.get('plan');
    const couponCode = params.get('couponCode');

    const initialSubscriptionConfigInURL = { billingCycle, plan, couponCode };
    if (isValidInitialSubscriptionConfig(initialSubscriptionConfigInURL)) {
      setLocalStorageItem(
        INITIAL_SUBSCRIPTION,
        JSON.stringify(initialSubscriptionConfigInURL),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = ({
    name,
    email,
    phone,
    password,
    passwordRepeat,
  }: FormData) => {
    const errors: {
      name: string | undefined;
      email: string | undefined;
      phone: string | undefined;
      password: string | undefined;
      passwordRepeat: string | undefined;
    } = {
      name: undefined,
      email: undefined,
      phone: undefined,
      password: undefined,
      passwordRepeat: undefined,
    };
    if (Validation.String.isEmpty(name)) {
      errors.name = text.noName;
    }
    if (Validation.String.isEmpty(phone)) {
      errors.phone = text.noPhone;
    }
    if (!Validation.Phone.isValid(phone)) {
      errors.phone = text.invalidPhone;
    }
    if (!Validation.Email.isValid(email)) {
      errors.email = text.invalidEmail;
    }

    const didEnterPassword = Validation.String.isNonEmptyString(password);
    const didRepeatPassword =
      Validation.String.isNonEmptyString(passwordRepeat);
    if (!didEnterPassword) {
      errors.password = text.noPassword;
    }
    if (!didRepeatPassword) {
      errors.passwordRepeat = text.noPasswordRepeat;
    }

    if (didEnterPassword && didRepeatPassword && password !== passwordRepeat) {
      errors.passwordRepeat = text.passwordsDoNotMatch;
    }

    const charNumberValid = password && password.length >= 8 ? true : false;
    const uppercaseValid =
      password && Validation.Password.containsUpperCaseLetter(password)
        ? true
        : false;
    const lowercaseValid =
      password && Validation.Password.containsLowerCaseLetter(password)
        ? true
        : false;
    const specialCharValid =
      password && Validation.Password.containsSpecialCharacter(password)
        ? true
        : false;
    const numberValid =
      password && Validation.Password.containsNumber(password) ? true : false;

    if (
      charNumberValid !== passwordConditions.charNumberValid ||
      uppercaseValid !== passwordConditions.uppercaseValid ||
      specialCharValid !== passwordConditions.specialCharValid ||
      lowercaseValid !== passwordConditions.lowercaseValid ||
      numberValid !== passwordConditions.numberValid
    ) {
      setPasswordConditions({
        charNumberValid,
        uppercaseValid,
        specialCharValid,
        lowercaseValid,
        numberValid,
      });
    }

    if (
      !charNumberValid ||
      !uppercaseValid ||
      !lowercaseValid ||
      !specialCharValid ||
      !numberValid
    ) {
      errors.password = text.invalidPassword;
    }

    return errors;
  };

  const onFormSubmit = ({ name, email, password, phone }: FormData) => {
    if (email === null || password === null) return;
    startLoading();
    const lowerCaseEmail = email.toLocaleLowerCase();
    const signupAttributes = {
      name,
      'custom:phone': phone,
      'custom:acceptedTNCDate': new Date().toISOString(),
    };

    if (isValidSignupCode(signupCode)) {
      signupAttributes['custom:signupCode'] = signupCode;
    }

    const signupOptions = {
      username: lowerCaseEmail,
      password,
      attributes: signupAttributes,
    };

    return Amplify.Auth.signUp(signupOptions)
      .then((result: any) => {
        if (!result.userConfirmed) {
          void navigate('/verify', {
            state: { email: lowerCaseEmail },
          });
          return;
        }
        stopLoading();
        void navigate('/login');
      })
      .catch(err => {
        stopLoading();
        if (err.code === 'UserLambdaValidationException') {
          return { [FORM_ERROR]: text.invalidEmailFromCognito };
        }
        if (err.code === 'InvalidPasswordException') {
          // report this to us, we should always validate the password beforehand so if we get this error something else went wrong
          reportErrorToTracker(err);
          return {
            [FORM_ERROR]: text.invalidPasswordFromCognito,
          };
        }
        if (err.code === 'UsernameExistsException') {
          return {
            [FORM_ERROR]: text.userAlreadyExists,
          };
        }
        reportErrorToTracker(err);
        return { [FORM_ERROR]: text.unknownError };
      });
  };

  const labelComponent = (
    <div>
      {text.acceptTerms}
      <ExternalLink
        href="https://dhstash.s3-eu-west-1.amazonaws.com/Nederland+ICT+Voorwaarden+2014+-+NL.pdf"
        target="_blank"
        download="NederlandICTVoorwaarden.pdf"
        data-testid={TEST_ID.PDF_LINK}
      >
        {text.termsAndConditionsPdf}
      </ExternalLink>
      {text.and}
      <ExternalLink
        href="https://dhstash.s3-eu-west-1.amazonaws.com/DatHuis+(verwerkers)overeenkomst+v20201125.pdf"
        target="_blank"
        download="DatHuis verwerkers overeenkomst.pdf"
        data-testid={TEST_ID.PDF_LINK_PROCESSING}
      >
        {text.processingAgreementPdf}
      </ExternalLink>
      {text.acceptTermsPost}
    </div>
  );

  return (
    <AuthWrapperHOC>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content="Maak een nieuw DatHuis account aan" />
      </MetaTags>

      <AuthNav selectedIdx={1} location={location} />

      <AuthForm>
        <H1>{title}</H1>

        {!isValidSignupCode(signupCode) && (
          <InputGroup>
            <FullWidthButton
              onClick={() => {
                intercom.openChat(
                  'Hallo, ik maak gebruik van het waarderapport. Kan je me een uitnodiging sturen voor het registreren van een account?',
                );
              }}
              icon="arrowRight"
              label="Heb je al een waarderapport abonnement? Klik dan hier"
              dataTestId={TEST_ID.HAS_WAARDERAPPORT_BUTTON}
            />
          </InputGroup>
        )}

        <Form
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validate={validate}
        >
          {({ handleSubmit, submitError, submitting, pristine }) => (
            <form
              id="register"
              onSubmit={handleSubmit}
              data-testid={TEST_ID.REGISTER_FORM}
            >
              {submitError ? (
                <ErrorMsg data-testid={TEST_ID.REGISTER_ERROR_MESSAGE}>
                  {submitError}
                </ErrorMsg>
              ) : null}
              <InputGroup>
                <Field name="name">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      large
                      label={text.nameLabel}
                      disabled={submitting}
                      error={FormUtils.showError(error, touched)}
                      {...input}
                    />
                  )}
                </Field>
              </InputGroup>
              <InputGroup>
                <EmailField name="email">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      large
                      label={text.emailLabel}
                      disabled={submitting}
                      error={FormUtils.showError(error, touched)}
                      {...input}
                    />
                  )}
                </EmailField>
              </InputGroup>
              <InputGroup>
                <Field name="phone">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      large
                      label={text.phoneLabel}
                      disabled={submitting}
                      error={FormUtils.showError(error, touched)}
                      {...input}
                    />
                  )}
                </Field>
              </InputGroup>

              <PasswordInputGroup
                passwordText={text.passwordLabel}
                repeatText={text.passwordRepeatLabel}
                passwordConditions={passwordConditions}
                submitting={submitting}
              />

              <InputGroup>
                <Checkbox
                  onChange={() => setTermsAccepted(!termsAccepted)}
                  value={termsAccepted}
                  labelComponent={labelComponent}
                  name="termsAccepted"
                />
              </InputGroup>
              <ActionWrapper>
                <FullWidthButton
                  appearance="secondary"
                  type="submit"
                  loading={loading}
                  disabled={submitting || !termsAccepted || pristine || loading}
                  dataTestId={TEST_ID.SUBMIT_PASSWORD_BUTTON}
                  label={text.registerButton}
                  size="large"
                  style={{ justifyContent: 'center' }}
                />
              </ActionWrapper>
            </form>
          )}
        </Form>
      </AuthForm>
    </AuthWrapperHOC>
  );
};

const isValidSignupCode = (signupCode: string | null | undefined) => {
  if (isNil(signupCode)) return false;
  if (isEmpty(signupCode)) return false;
  return true;
};

const FullWidthButton = styled(Button)<{}>(
  () =>
    css`
      justify-content: left;
      width: 100%;
    `,
);

const initialValues: FormData = {
  name: null,
  password: null,
  passwordRepeat: null,
  email: null,
  phone: null,
};

export default Register;
