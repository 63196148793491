import React from 'react';
import MetaTags from 'react-meta-tags';
import { animated, useSpring } from 'react-spring';
import { ANIMATION_CONFIG } from '../constants';

import ContentContainerDefault from '~/components/ContentContainer/Default';
import Catalog from '~/Catalog';

import TEST_ID from './index.testid';

import StandardInvoices from './components/StandardInvoices';
import CreditInvoices from './components/CreditInvoices';
import { Heading3 } from '~/components/Typography/index';

const INVOICES_LIST_LIMIT = 6;

const text = {
  pageTitle: Catalog.invoicesLabel,
  title: Catalog.invoicesLabel,
  creditTitle: 'Creditfacturen',
};
const Invoices = () => {
  const animation = useSpring(ANIMATION_CONFIG);

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <animated.section style={animation} data-testid={TEST_ID.CONTAINER}>
        <Heading3>{text.title}</Heading3>
        <StandardInvoices limit={INVOICES_LIST_LIMIT} />
        <Heading3>{text.creditTitle}</Heading3>
        <CreditInvoices limit={INVOICES_LIST_LIMIT} />
      </animated.section>
    </ContentContainerDefault>
  );
};

export default Invoices;
