import isSameDay from 'date-fns/isSameDay';

import { isBefore } from '~/util/date';

/**
 * Freezing object tells flow to resolve types
 *
 * @see https://stackoverflow.com/questions/51163305/defining-a-union-type-from-object-values-in-flow/51166430#51166430
 */
export enum TaskGroups {
  OVERDUE = 'OVERDUE',
  TODAY = 'TODAY',
  UPCOMING = 'UPCOMING',
  COMPLETED = 'COMPLETED',
  COMPLETED_END = 'COMPLETED_END',
  DELETED = 'DELETED',
}

// Export for testing
export const text = {
  [TaskGroups.OVERDUE]: 'Verlopen',
  [TaskGroups.TODAY]: 'Vandaag',
  [TaskGroups.UPCOMING]: 'Komende',
  [TaskGroups.COMPLETED]: 'Voltooid',
  [TaskGroups.COMPLETED_END]: 'Klaar',
  [TaskGroups.DELETED]: 'Verwijderd',
};

export const taskGroupTypeForDueDate = (date: Date | null): TaskGroups => {
  /** If unsure (should not happen), then put in the overdue pile so the user is shown */
  if (date === null) return TaskGroups.OVERDUE;
  const today = new Date();

  if (isSameDay(date, today)) return TaskGroups.TODAY;
  if (isBefore(date, today)) {
    return TaskGroups.OVERDUE;
  } else {
    return TaskGroups.UPCOMING;
  }
};

export const taskGroupLabelForType = (type?: TaskGroups): string => {
  if (!type) return text.COMPLETED_END;

  return text[type];
};
