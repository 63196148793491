import uuid from 'uuid/v4';
import { clone, isNil } from 'ramda';
import { ClientFlowAction } from '~/graphql/types.client';

/**
 * Return the actions with the duplicated action
 * @param {Array<ClientFlowAction>} actions - Actions
 * @param {ClientFlowAction} subjectAction - Action to be duplicated
 * keywords: duplicate actions
 */
const duplicateActions = ({
  actions,
  subjectAction,
}: {
  actions: Array<ClientFlowAction>;
  subjectAction: ClientFlowAction;
}): {
  updatedActions: Array<ClientFlowAction>;
  newAction?: ClientFlowAction;
} => {
  if (subjectAction.__typename === 'FlowV2_Action_Start')
    return {
      updatedActions: actions,
    };

  const mutableActions = [...actions];

  const copiedSubject = clone(subjectAction);
  copiedSubject.parentIds = [subjectAction.id];
  copiedSubject.id = 'fbpa' + uuid();

  if ('Statistics' in copiedSubject) {
    delete copiedSubject.Statistics;
  }

  const nextActionIndex = mutableActions.findIndex(
    action =>
      'parentIds' in action && action.parentIds.includes(subjectAction.id),
  );
  const nextAction = mutableActions[nextActionIndex];

  if (!isNil(nextAction)) {
    const prevParentIds = 'parentIds' in nextAction ? nextAction.parentIds : [];

    const updatedNextAction = {
      ...nextAction,
      parentIds: [...prevParentIds, copiedSubject.id].filter(
        id => id !== subjectAction.id,
      ),
    };

    mutableActions[nextActionIndex] = updatedNextAction;
  }

  return {
    newAction: copiedSubject,
    updatedActions: [...mutableActions, copiedSubject],
  };
};
export default duplicateActions;
