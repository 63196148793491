import { FlowEmailAttachmentFieldsFragment } from '~/graphql/types';
import { FlowEmailAttachmentValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { asFileParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/File';
import { FLOW_EMAILATTACHMENT_TYPE } from './constants';
import cleanedFilename from '~/util/cleanedFilename';

// Have to do this __typename trick as flow does not understand that the __typename is not just a string
const asFlowEmailAttachmentValue = (
  flowEmailAttachmentParameter: FlowEmailAttachmentFieldsFragment & {
    __typename: string;
  },
): FlowEmailAttachmentValue => {
  if (
    flowEmailAttachmentParameter.__typename !==
    FLOW_EMAILATTACHMENT_TYPE.FLOW_EMAILATTACHMENT
  ) {
    throw Error(
      `${cleanedFilename(__filename)} | attachment type: ${
        flowEmailAttachmentParameter.__typename
      } must be ${FLOW_EMAILATTACHMENT_TYPE}`,
    );
  }

  return {
    type: FLOW_EMAILATTACHMENT_TYPE.FLOW_EMAILATTACHMENT,
    file: asFileParameterValue(flowEmailAttachmentParameter.file),
    inlineId:
      flowEmailAttachmentParameter.inlineId == null
        ? null
        : flowEmailAttachmentParameter.inlineId,
    url:
      flowEmailAttachmentParameter.url == null
        ? null
        : flowEmailAttachmentParameter.url,
  };
};

export default asFlowEmailAttachmentValue;
