import { findLastIndex, propEq, unnest } from 'ramda';
import { jsx } from 'slate-hyperscript';
import getElements from '../../components/elements';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import convertDomAttrsToReactProps from './utils/convertDOMAttrsToReactProps';
import { reporter } from '~/hooks/useErrorReporter';

const TEXT_TAGS = {
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

const deserializers = customElements => {
  const els = getElements(customElements);
  return Object.values(els).reduce((acc, { deserialize, nodeName }) => {
    if (nodeName) return { ...acc, [nodeName]: deserialize };
    return acc;
  }, {});
};

const deserialize = ({
  el,
  markAsPendingImage,
  customElements = [],
  initialValue,
}: {
  el: Element | ChildNode;
  markAsPendingImage?: boolean;
  customElements: Array<ELEMENTS>;
  /** Added for debugging purposes */
  initialValue: string;
}) => {
  if (el.nodeType === 3) {
    return el.textContent; // is text node
  } else if (el.nodeType !== 1) {
    return null; // is not element node
  } else if (el.nodeName === 'BR') {
    return '\n';
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0];
  }

  let children = unnest(
    Array.from(parent.childNodes).map(item =>
      deserialize({
        el: item,
        markAsPendingImage,
        customElements,
        initialValue,
      }),
    ),
  );

  // @ts-ignore TS is expecting an Element but el can be ChildNode too
  const elAttributes = el.attributes;

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  if (children == null) {
    reporter.captureException(
      new Error(
        `children in deserialize is null or undefined ${JSON.stringify(
          initialValue,
        )}`,
      ),
      'critical',
    );
  }

  // Add extra empty text at the end of Variable and image elements to be able to focus after them
  const variableIndex = findLastIndex(propEq('type', ELEMENTS.VARIABLE))(
    children,
  );
  if (variableIndex !== -1) {
    children.splice(variableIndex + 1, 0, '');
  }

  const imageIndex = findLastIndex(
    propEq('type', ELEMENTS.DH_IMAGE) || propEq('type', ELEMENTS.IMAGE),
  )(children);
  if (imageIndex !== -1) {
    children.splice(imageIndex + 1, 0, '');
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  const customDeserializers = deserializers(customElements);

  if (customDeserializers[nodeName]) {
    const attrs = {
      ...customDeserializers[nodeName](el),
      /** Do not overwrite these in custom deserializers */
      attributes: convertDomAttrsToReactProps(elAttributes),
      attrsToPassThrough: convertHtmlAttrsToObject(elAttributes),
    };

    /** If an image is pasted while inserting html, mark it as pending so that it can be uploaded to S3 */
    if (nodeName == 'IMG' && markAsPendingImage) {
      return jsx('element', { ...attrs, pending: true }, children);
    }

    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);

    return children.map(child => {
      if (child.children) {
        return addAttrsToChildren(child, attrs);
      }

      return jsx('text', attrs, child);
    });
  }

  /** For all other elements return GenericHtmlElement, pass all the attrs directly */
  if (
    nodeName &&
    nodeName.length > 0 &&
    nodeName !== 'IMG' &&
    nodeName !== 'DHVARIABLE'
  ) {
    const element = {
      type: ELEMENTS.GENERIC_HTML_ELEMENT,
      name: nodeName.toLowerCase(),
      attributes: convertDomAttrsToReactProps(elAttributes),
      attrsToPassThrough: convertHtmlAttrsToObject(elAttributes),
    };

    return jsx('element', element, children);
  }

  return children;
};

export default deserialize;

const addAttrsToChildren = (child: any, attrs: any) => {
  if (child.children) {
    child.children = child.children.map((item: any) => {
      const itemWithAttrs = addAttrsToChildren(item, attrs);
      return { ...itemWithAttrs, ...attrs };
    });
  }
  return child;
};

const convertHtmlAttrsToObject = (attrs: NamedNodeMap) => {
  const obj: { [key: string]: string } = {};
  const arr = [...attrs];

  for (const attr of arr) {
    obj[attr.name] = attr.value;
  }
  return obj;
};
