import React, { ReactElement } from 'react';

import { StartAction } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { describeFlowCondition } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition';
import { describeFlowConditionList } from '../Base/FlowConditionList';

const describeStartAction = (
  action: StartAction,
  describeOptions: DescribeOptions,
): ReactElement => {
  const { startCondition, conditionList } = action;

  const descriptionArray = [
    'Start deze flow zodra ',
    describeFlowCondition(startCondition, describeOptions),
  ];

  if (conditionList.conditions.length !== 0) {
    return (
      <span>
        {descriptionArray}
        {describeFlowConditionList(
          conditionList,
          {
            ...describeOptions,
            // quick fix to ignore action type if in condition list
            actionType: null,
          },
          true,
        )}
      </span>
    );
  } else {
    return <span>{descriptionArray}</span>;
  }
};

export default describeStartAction;
