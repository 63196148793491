import React from 'react';

import { FlowConditionEmailLinkClicked } from './types.flow';
import {
  EmailPointerParameterValue,
  StringParameterValue,
} from '../../types.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { OptionOf } from '~/components/Inputs/Dropdown';

import Dropdown from '~/components/Inputs/Dropdown';
import EmailPointerParameterComponent from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Email/EmailPointerParameterValueComponent';
import { InputGroup } from '~/components/Inputs';
import { getVariableStashOutputObject } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import TEST_ID from './FlowConditionEmailLinkClickedComponent.testid';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import FieldLabel from '~/scenes/Automation/Flows/Actions/FieldLabel';
import { PARAMETER_VALUE_TYPE } from '../../FlowParameter/ParameterValue/constants';
import cleanedFilename from '~/util/cleanedFilename';
import equalsStringPointer from '../../FlowParameter/ParameterValue/String/equalsStringPointer';
import { emptyStringPrimitiveParameterValue } from '../../FlowParameter';
import { mapNotNull } from '~/util/array';
import { UNSUBSCRIBE_LINK_ON_BACKEND } from '../../../constants';

const text = {
  invalidLink: 'Voer een geldige link in',
  linkLabel: 'Link',
  emailLabel: 'Welke e-mail?',
};

type MyProps = {
  condition: FlowConditionEmailLinkClicked;
  onChange: (newCondition: FlowConditionEmailLinkClicked) => void;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
};

class FlowConditionEmailLinkClickedComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  validate = (): boolean => {
    const { condition, baseActionContext } = this.props;
    const { variableStash } = baseActionContext;
    const { email, link } = condition;

    const linkOptions = getLinkOptions(email, variableStash);

    if (!linkOptionsIncludesLink(linkOptions, link)) return false;

    return true;
  };

  render() {
    const { condition, onChange, baseActionContext } = this.props;
    const { variableStash } = baseActionContext;

    const { email, link } = condition;
    const linkOptions = getLinkOptions(email, variableStash);
    const linkErrorText = linkOptionsIncludesLink(linkOptions, link)
      ? ''
      : text.invalidLink;

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => (
          <>
            <InputGroup>
              <FieldLabel>{text.emailLabel}</FieldLabel>
              <EmailPointerParameterComponent
                value={email}
                onChange={newValue => {
                  onChange({
                    ...condition,
                    email: newValue,
                  });
                }}
              />
            </InputGroup>
            <InputGroup>
              <Dropdown
                dataTestid={TEST_ID.LINK_DROPDOWN}
                label={text.linkLabel}
                error={showValidation && !this.validate() ? linkErrorText : ''}
                options={linkOptions}
                selectedOptionIdx={findLinkOptionIndex(linkOptions, link)}
                onChange={({ option }) => {
                  onChange({
                    ...condition,
                    link: option.payload,
                  });
                }}
              />
            </InputGroup>
          </>
        )}
      </FlowContext.Consumer>
    );
  }
}

const getLinkOptions = (
  email: EmailPointerParameterValue,
  stash: FlowVariableStash,
): Array<OptionOf<StringParameterValue>> => {
  const defaultLinks: Array<OptionOf<StringParameterValue>> = [
    {
      label: 'Elke link',
      payload: emptyStringPrimitiveParameterValue(),
      key: 'any-link',
    },
  ];
  const emailOutputObject = getVariableStashOutputObject(email.variable, stash);

  if (
    emailOutputObject == null ||
    emailOutputObject.outputType !== FLOW_OUTPUT_TYPE.FlowOutputEventEmail
  ) {
    return defaultLinks;
  }

  const outputObjectLinks: Array<OptionOf<StringParameterValue>> = mapNotNull(
    emailOutputObject.links,
    flowEmailLink => {
      if (flowEmailLink.link === UNSUBSCRIBE_LINK_ON_BACKEND) {
        // we ignore unsubscribe links, they are system links and will not be tracked
        return null;
      }

      return {
        label: flowEmailLink.label,
        payload: flowEmailLink.parameterValue,
        key: flowEmailLink.link,
      };
    },
  );

  return defaultLinks.concat(outputObjectLinks);
};

const findLinkOptionIndex = (
  linkOptions: Array<OptionOf<StringParameterValue>>,
  link: StringParameterValue,
) => {
  switch (link.type) {
    case PARAMETER_VALUE_TYPE.STRING_POINTER: {
      const foundLinkOption = linkOptions.findIndex(linkOption => {
        if (
          linkOption.payload == null ||
          linkOption.payload.type !== PARAMETER_VALUE_TYPE.STRING_POINTER
        ) {
          return false;
        } else {
          return equalsStringPointer(linkOption.payload, link);
        }
      });

      return foundLinkOption;
    }
    case PARAMETER_VALUE_TYPE.STRING_PRIMITIVE: {
      const stringOrNullToCheck = link.value;

      const foundLinkOption = linkOptions.findIndex(
        linkOption =>
          linkOption.payload &&
          linkOption.payload.type === PARAMETER_VALUE_TYPE.STRING_PRIMITIVE &&
          linkOption.payload.value === stringOrNullToCheck,
      );

      return foundLinkOption;
    }
    default:
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Unknown link ${JSON.stringify(link, null, 2)}.`,
      );
  }
};
const linkOptionsIncludesLink = (
  linkOptions: Array<OptionOf<StringParameterValue>>,
  link: StringParameterValue,
): boolean => findLinkOptionIndex(linkOptions, link) >= 0;

export default withBaseActionContext<MyProps>(
  FlowConditionEmailLinkClickedComponent,
);
