import React from 'react';
import { Redirect } from '@reach/router';
import { RouteComponentProps } from '@reach/router';

import withErrorBoundary from '~/ErrorBoundary';
import DHRouter from '~/components/DHRouter';
import serializeFiltersV2 from './util/serializeFiltersV2';
import ContactList from './components/ContactList';
import Contact from './components/Contact';
import useContactFilters from './util/useContactFilters';
import Loading from '~/components/Loading';

type Props = RouteComponentProps;

const Contacts: React.FC<Props> = () => {
  const [loading, storedFiltersV2] = useContactFilters();

  if (loading) return <Loading />;

  return (
    <DHRouter height="auto">
      <Redirect
        from="/"
        to={`page/1?f=${serializeFiltersV2(storedFiltersV2)}`}
        noThrow
      />

      <Redirect
        from="/page"
        to={`1?f=${serializeFiltersV2(storedFiltersV2)}`}
        noThrow
      />

      <ContactList path="/page/:currentPage" storedFilters={storedFiltersV2} />

      <Contact path="/:contactId" />
    </DHRouter>
  );
};

export default withErrorBoundary(Contacts);
