import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { AppBbWaardecheckWhy, AppVboWaardecheckWhy } from '~/graphql/types';
import InputGroupDivider from '~/scenes/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import {
  AppWaardecheckFieldsFragment,
  appWaardecheckState,
  StateId,
} from '../..';

import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppSectionHeader from '~/scenes/Apps/components/AppSectionHeader';
import BBWaardecheckIntl from '../../intl';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import TEST_ID from './index.testid';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import IntlInputComponent from '../../components/IntlInputComponent';
import MainPreview from '../../components/MainPreview';
import ImageInput from '../../components/ImageInput';
import { Page } from '../../components/Preview';
import Explanation from '../../components/Explanation';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
};

const Why: React.FC<Props> = ({ data, defaultValues, loading }) => {
  const original = defaultValues.why;
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const updateValue = (
    value: DeepPartial<AppBbWaardecheckWhy | AppVboWaardecheckWhy>,
  ) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        why: value,
      }) as any as typeof prev;
    });
  };

  if (original == null || updated == null) return null;

  return (
    <AppDetailsContainer
      header="Reden waardecheck"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Explanation>
        Op deze pagina wordt de reden voor het opvragen van de waardecheck
        gevraagd. Personaliseer de achtergrondfoto en teksten.
      </Explanation>
      <MainPreview data={updated} page={Page['/why']} />
      <AppEntryItemHeader>
        {BBWaardecheckIntl.mainImage.title}
      </AppEntryItemHeader>
      <Description>{BBWaardecheckIntl.mainImage.description}</Description>
      <ImageInput
        initialUrl={data.why.image?.url}
        filename={data.__typename + 'why'}
        s3Key={updated?.why.image?.s3key}
        onChange={image => updateValue({ image })}
        dataTestid={TEST_ID.IMAGE}
      />
      <InputGroupDivider large />
      <AppSectionHeader>{BBWaardecheckIntl.section.text}</AppSectionHeader>
      <Description>{BBWaardecheckIntl.section.textDescription}</Description>
      <IntlInputComponent
        title={BBWaardecheckIntl.heading}
        defaultValue={original.heading}
        value={updated.why.heading}
        onChange={heading => updateValue({ heading })}
        dataTestId={TEST_ID.HEADING}
      />
    </AppDetailsContainer>
  );
};

export default Why;
