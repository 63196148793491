import gql from 'graphql-tag';

import TaskListTaskFields from '../fragments/TaskListTaskFields';

export default gql`
  subscription MutatedTask($accountId: ID!) {
    mutatedTask(accountId: $accountId) {
      ...TaskListTaskFields
    }
  }

  ${TaskListTaskFields}
`;
