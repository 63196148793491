import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const CalendarView: React.FC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.5 5.1001H9.2C6.9 5.1001 5 7.1001 5 9.6001V42.2001V66.4001C5 69.0001 6.9 71.0001 9.2 71.0001H19.4H52.4H62.6C64.9 71.0001 66.8 69.0001 66.8 66.5001V42.3001V9.7001C66.8 7.2001 64.9 5.1001 62.5 5.1001Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 19.6001H66.8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9004 9.2V1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.9004 9.2V1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9004 9.2V1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.9004 9.2V1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6992 44.0002C21.6992 44.0002 26.8992 33.7002 35.8992 33.7002C44.8992 33.7002 50.0992 44.0002 50.0992 44.0002C50.0992 44.0002 44.8992 54.3002 35.8992 54.3002C26.8992 54.3002 21.6992 44.0002 21.6992 44.0002Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9 47.9002C38.0539 47.9002 39.8 46.1541 39.8 44.0002C39.8 41.8463 38.0539 40.1002 35.9 40.1002C33.7461 40.1002 32 41.8463 32 44.0002C32 46.1541 33.7461 47.9002 35.9 47.9002Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default CalendarView;
