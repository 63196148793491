import gql from 'graphql-tag';

import ConnectedEmailAliasFields from './ConnectedEmailAliasFields';

export default gql`
  fragment AccountMailboxAliasFields on AccountMailboxAlias {
    __typename
    email {
      ...ConnectedEmailAliasFields
    }
  }

  ${ConnectedEmailAliasFields}
`;
