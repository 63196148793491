import cleanedFilename from '~/util/cleanedFilename';

export default {
  SETUP_PASSWORD_FORM: `${cleanedFilename(__filename)}-setup-password-form`,
  SETUP_PASSWORD_ERROR_MESSAGE: `${cleanedFilename(
    __filename,
  )}-setup-password-error-message`,
  SUBMIT_PASSWORD_BUTTON: 'submit-password-button',
  INITIAL_ERROR_MESSAGE: `${cleanedFilename(__filename)}-initial-error-message`,
};
