import gql from 'graphql-tag';

import AccountMailboxFields from '../fragments/AccountMailboxFields';
import OfficeMailboxFields from '../fragments/OfficeMailboxFields';
import UserMailboxFields from '../fragments/UserMailboxFields';
import AccountMailboxAliasFields from '../fragments/AccountMailboxAliasFields';
import OfficeMailboxAliasFields from '../fragments/OfficeMailboxAliasFields';
import UserMailboxAliasFields from '../fragments/UserMailboxAliasFields';
import AccountIssueFields from '../fragments/subfragments/AccountIssueFields';
import SignatureFields from '../fragments/SignatureFields';

export default gql`
  query GetAccount($accountId: ID!) {
    getAccount(accountId: $accountId) {
      id
      name
      status
      issues {
        ...AccountIssueFields
      }
      relation {
        status
        role
      }
      Users {
        user {
          id
          email
          createdDate
        }
        relation {
          status
          role
        }
      }
      Mailboxes {
        ... on AccountMailbox {
          ...AccountMailboxFields
        }
        ... on OfficeMailbox {
          ...OfficeMailboxFields
        }
        ... on UserMailbox {
          ...UserMailboxFields
        }
        ... on AccountMailboxAlias {
          ...AccountMailboxAliasFields
        }
        ... on OfficeMailboxAlias {
          ...OfficeMailboxAliasFields
        }
        ... on UserMailboxAlias {
          ...UserMailboxAliasFields
        }
      }
      Signature {
        ... on Signature {
          ...SignatureFields
        }
      }
    }
  }

  ${AccountMailboxFields}
  ${OfficeMailboxFields}
  ${UserMailboxFields}
  ${AccountMailboxAliasFields}
  ${OfficeMailboxAliasFields}
  ${UserMailboxAliasFields}
  ${AccountIssueFields}
  ${SignatureFields}
`;
