import { RouteComponentProps } from '@reach/router';
import React from 'react';
import {
  AppBbWaardecheckPropertyType,
  AppVboWaardecheckPropertyType,
} from '~/graphql/types';
import InputGroupDivider from '~/scenes/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import {
  AppWaardecheckFieldsFragment,
  appWaardecheckState,
  StateId,
} from '../..';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppSectionHeader from '~/scenes/Apps/components/AppSectionHeader';
import WaardecheckIntl from '../../intl';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import ImageInput from '../../components/ImageInput';
import MainPreview from '../../components/MainPreview';
import { Page } from '../../components/Preview';
import IntlInputComponent from '../../components/IntlInputComponent';
import TEST_ID from './index.testid';
import Explanation from '../../components/Explanation';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
};

const PropertyType: React.FC<Props> = ({ data, defaultValues, loading }) => {
  const original = defaultValues.propertyType;
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const updateValue = (
    value: DeepPartial<
      AppBbWaardecheckPropertyType | AppVboWaardecheckPropertyType
    >,
  ) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        propertyType: value,
      }) as any as typeof prev;
    });
  };

  if (updated == null) return null;

  return (
    <AppDetailsContainer
      header="Type Huis"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Explanation>
        Op deze pagina wordt het type huis geselecteerd. Personaliseer de
        achtergrondfoto en teksten.
      </Explanation>
      <MainPreview data={updated} page={Page['/property-type']} />
      <InputGroupDivider />
      <AppEntryItemHeader>{WaardecheckIntl.mainImage.title}</AppEntryItemHeader>
      <Description>{WaardecheckIntl.mainImage.description}</Description>
      <ImageInput
        initialUrl={data.propertyType.image?.url}
        filename={data.__typename + 'propertytype'}
        s3Key={updated?.propertyType.image?.s3key}
        onChange={image => updateValue({ image })}
        dataTestid={TEST_ID.IMAGE}
      />
      <InputGroupDivider large />
      <AppSectionHeader>{WaardecheckIntl.section.text}</AppSectionHeader>
      <Description>{WaardecheckIntl.section.textDescription}</Description>
      <IntlInputComponent
        title={WaardecheckIntl.heading}
        defaultValue={original.heading}
        value={updated.propertyType.heading}
        onChange={heading => updateValue({ heading })}
        dataTestId={TEST_ID.HEADING}
      />
    </AppDetailsContainer>
  );
};

export default PropertyType;
