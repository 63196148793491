import { Router } from '@reach/router';
import React from 'react';
import styled, { css } from 'styled-components';
import Master, { NAV_WIDTH, Props as MasterProps } from './components/Master';
import TEST_ID_MASTER from '~/components/MasterDetail/components/Master/index.testid';

export type Props = MasterProps & {
  dataTestId?: string;
};

/**
 * Master detail container.
 *
 * All children must have a corresponding `path` property to
 * work with our router.
 */
const MasterDetail: React.FC<Props> = ({
  dataTestId,
  navbar,
  children,
  basePath,
  ...rest
}) => (
  <Container data-testid={dataTestId}>
    <Master
      dataTestId={TEST_ID_MASTER.CONTAINER}
      basePath={basePath}
      navbar={navbar}
      {...rest}
    />
    <Details>{children}</Details>
  </Container>
);

const Container = styled.div<{}>(
  () => css`
    display: grid;
    grid-template-columns: ${NAV_WIDTH} auto;
  `,
);

const Details = styled(Router)<{}>(
  () => css`
    display: flex;
    flex-grow: 1;
  `,
);

export default MasterDetail;
