import React from 'react';
import styled from 'styled-components';

export type Props = {
  dataTestId?: string;
  label: React.ReactNode;
};

const TabBody: React.FC<Props> = ({ dataTestId, label, ...rest }) => (
  <Container data-testid={dataTestId} label={label} {...rest} />
);

const Container = styled.section<{ label: React.ReactNode }>``;

export default TabBody;
