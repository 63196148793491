import React, { useEffect, useState } from 'react';
import styled, {
  css,
  FlattenSimpleInterpolation,
  useTheme,
} from 'styled-components';

import InputGroupElement from '../InputGroupElement';
import {
  calculateSpaceInsideComponent,
  calculateFontSize,
} from '~/components/util/getSizeCalculation';
import { NON_ZERO_WIDTH_WHITESPACE } from '../../Values';
import FloatingLabel from '../../FloatingLabel';
import DropdownHideableList from './components/DropdownHideableList';
import { getDisabledInputStyle } from '../InputElement';
import TEST_ID from './index.testid';
import HelpButton from '~/components/Buttons/HelpButton';
import Icon from '~/components/Icon';
import icons from '~/components/Icon/components';

type OptionBase = {
  label: React.ReactNode;
  icon?: keyof typeof icons;
  key: string | number;
  onClickAction?: (e?: React.SyntheticEvent<any>) => void;
  isEmptySelectionOption?: boolean;
  styleOptions?: {
    lineAbove?: boolean;
  };

  /** Styling will change based on the type */
  type?: 'DANGER' | 'DISABLED' | null;
  dataTestid?: string;
};
export type Option = OptionBase & {
  payload: any;
};
export type OptionOf<T> = OptionBase & {
  payload: T;
};

export type SelectedOptionOf<T> = {
  option: OptionOf<T>;
  selectedOptionIdx: number;
};
export type SelectedOption = {
  option: Option;
  selectedOptionIdx: number;
};

export type OnChangeFunction = (option: SelectedOptionOf<any>) => void;
export type OnChangeFunctionOf<T> = (option: SelectedOptionOf<T>) => void;

export type DropdownProps = {
  /** Displayed if nothing is selected. */
  label?: string;

  /** Custom label instead for selected value of dropdown */
  labelComponent?: React.ReactNode;

  /** The options to display. */
  options: Array<Option>;

  /** If the dropdown should initially select the only option if nothing is selected */
  selectOnlyOptionAutomatically?: boolean;

  /** The index of the selected option. */
  selectedOptionIdx?: number | null;

  /** Callback, fired after an option was selected. */
  onChange?: OnChangeFunction;

  /** Error, this replaces the label if its set. */
  error?: string | null;

  /** Renders a small component */
  small?: boolean;

  /** Renders a large component */
  large?: boolean;

  /** Does not show any label on top */
  hideLabelOnTop?: boolean;

  /** The data-testid to add to the container to identify the dropdown */
  dataTestid?: string;

  /** Disable Dropdown */
  disabled?: boolean;

  /** tab index order */
  tabIndex?: string;

  /** Removed the border */
  borderless?: boolean;

  /** An action to do when selected option container is clicked */
  onDropdownOpened?: () => void;

  /** An action to do when clicking outside the dropdown */
  onClickOutside?: () => void;

  /** Loading state for selected option container and dropdown list */
  loading?: boolean;
  hideDropdownList?: boolean;
  /** Gives it blue outline */
  outline?: boolean;
  /** Label acts as placeholder */
  actAsPlaceholder?: boolean;
  helpLink?: string;

  outlineStyles?: {
    active: FlattenSimpleInterpolation;
    notActive: FlattenSimpleInterpolation;
  };

  /** Will be forwarded to the trigger element */
  className?: string;
};

/**
 * Dropdown implementation
 */
const Dropdown = ({
  options,
  selectedOptionIdx,
  label,
  error,
  small,
  large = false,
  hideLabelOnTop,
  dataTestid,
  disabled,
  labelComponent,
  tabIndex,
  onChange,
  selectOnlyOptionAutomatically,
  borderless,
  onDropdownOpened,
  loading,
  hideDropdownList,
  onClickOutside,
  outline,
  helpLink,
  outlineStyles,
  className,
  ...rest
}: DropdownProps) => {
  const theme = useTheme();
  const [dropdownListOpen, setDropdownListOpen] = useState(false);
  const [isFirstClick, setFirstClick] = useState(tabIndex ? true : false);

  useEffect(() => {
    if (
      selectOnlyOptionAutomatically &&
      options.length === 1 &&
      (selectedOptionIdx == null || selectedOptionIdx < 0) &&
      onChange
    ) {
      onChange({ option: options[0], selectedOptionIdx: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.map(option => option.key).join(';')]);

  const isSelected = selectedOptionIdx != null && selectedOptionIdx >= 0;
  const selectedOption =
    selectedOptionIdx != null ? options[selectedOptionIdx] : null;

  const labelActsAsPlaceholder = !isSelected && !dropdownListOpen && !error;

  const labelMessage = error || label;

  const outlineChevronColor =
    dropdownListOpen && !disabled
      ? theme.color('white')
      : theme.color('primary', 'light');

  return (
    <Container data-testid={dataTestid} disabled={disabled} {...rest}>
      {!hideLabelOnTop && labelMessage && (
        <LabelContainer>
          <FloatingLabel
            data-testid={
              error ? TEST_ID.DROPDOWN_ERROR : TEST_ID.DROPDOWN_LABEL
            }
            actAsPlaceholder={labelActsAsPlaceholder}
            error={error}
            large={large}
            small={small}
          >
            {labelMessage}
          </FloatingLabel>
          {helpLink && <StyledHelpButton link={{ link: helpLink }} />}
        </LabelContainer>
      )}

      <SelectedOptionContainer
        borderless={borderless}
        tabIndex={tabIndex ? parseInt(tabIndex) : undefined}
        onFocus={() => {
          if (!disabled && options.length > 0) {
            setTimeout(() => {
              setDropdownListOpen(true);
            }, 200);
          }
        }}
        onClick={e => {
          if (onDropdownOpened) onDropdownOpened();

          if (!isFirstClick) {
            e.preventDefault();
            e.stopPropagation();

            setDropdownListOpen(!dropdownListOpen);
          } else {
            setFirstClick(false);
          }
        }}
        isSelected={dropdownListOpen}
        error={error}
        small={small}
        large={large}
        disabled={disabled}
        outline={outline}
        outlineStyles={outlineStyles}
        data-testid={TEST_ID.DROPDOWN_TOGGLE}
        data-objectid={selectedOption && selectedOption.key}
        className={className}
      >
        {labelComponent ? (
          labelComponent
        ) : (
          <SelectedOptionText
            large={large}
            small={small}
            isEmptySelectionOption={
              selectedOption != null &&
              selectedOption.isEmptySelectionOption === true
            }
          >
            {selectedOption ? selectedOption.label : NON_ZERO_WIDTH_WHITESPACE}
          </SelectedOptionText>
        )}
        <Icon
          name="chevron"
          strokeWidth={3}
          flipX={dropdownListOpen}
          color={outline && !disabled ? outlineChevronColor : 'currentColor'}
        />
      </SelectedOptionContainer>
      <DropdownHideableList
        options={options}
        dropdownListOpen={hideDropdownList ? false : dropdownListOpen}
        selectedOptionIdx={selectedOptionIdx}
        onChange={result => {
          onChange && onChange(result);
          setDropdownListOpen(false);
          setFirstClick(false);
        }}
        onClickOutside={() => {
          setDropdownListOpen(false);
          setFirstClick(false);

          if (onClickOutside) onClickOutside();
        }}
        loading={loading}
      />
    </Container>
  );
};

type ContainerProps = {
  disabled?: boolean;
};
const Container = styled.div<ContainerProps>`
  ${({ disabled }) => {
    if (!disabled) {
      return css`
        cursor: pointer;
      `;
    }

    return '';
  }};
`;

type SelectedOptionTextProps = {
  small?: boolean;
  large?: boolean;
  isEmptySelectionOption?: boolean;
};
export const SelectedOptionText = styled.span<SelectedOptionTextProps>`
  display: flex;
  flex-grow: 1;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme, isEmptySelectionOption }) => {
    if (isEmptySelectionOption) {
      return css`
        color: ${theme.color('text')};
      `;
    }

    return '';
  }}
`;

export const SELECTED_ELEMENT_BORDER_IN_PX = 1;
type SelectedOptionContainerProps = {
  error?: string | null;
  isSelected: boolean;
  large?: boolean;
  small?: boolean;
  disabled?: boolean;
  borderless?: boolean;
  outline?: boolean;

  outlineStyles?: {
    active: FlattenSimpleInterpolation;
    notActive: FlattenSimpleInterpolation;
  };
};

const SelectedOptionContainer = styled.div<SelectedOptionContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({
    theme,
    large,
    small,
    error,
    disabled,
    borderless,
    outline,
    isSelected,
    outlineStyles,
  }) => {
    const spaceInsideComponent = calculateSpaceInsideComponent(
      theme,
      small,
      large,
    );
    const fontSize = calculateFontSize(theme, small, large);

    const borderStyles = css`
      border: ${SELECTED_ELEMENT_BORDER_IN_PX}px solid;
      border-radius: ${theme.getTokens().border.radius.s};
      border-color: ${error ? theme.color('danger') : theme.color('grey')};

      :hover,
      :focus {
        ${!disabled &&
        css`
          border: ${SELECTED_ELEMENT_BORDER_IN_PX}px solid
            ${theme.color('text')};
        `};
      }
    `;

    if (borderless) {
      return css`
        border: none;
      `;
    }

    const outlineStyle = isSelected
      ? outlineStyles?.active ??
        css`
          background-color: ${theme.color('primary')};
          color: ${theme.color('white')};
        `
      : outlineStyles?.notActive ??
        css`
          border-color: ${theme.color('primary', 'light')};
        `;

    return css`
      ${borderStyles};
      ${outline && !disabled && outlineStyle}
      padding: calc(${spaceInsideComponent}px - ${SELECTED_ELEMENT_BORDER_IN_PX}px);
      font-size: ${fontSize}px;
      line-height: ${theme.lineHeight('s')};
    `;
  }};

  ${({ theme, error, isSelected, disabled }) => {
    if (!error && isSelected) {
      return css`
        border-color: ${theme.color('success')};
      `;
    }
    if (disabled) {
      return getDisabledInputStyle(theme);
    }

    return null;
  }};
`;

const LabelContainer = styled.div<{}>`
  display: flex;
  justify-content: flex-start;
`;

const StyledHelpButton = styled(HelpButton)<{}>(
  ({ theme }) => css`
    align-self: flex-start;
    margin-bottom: 5px;
    margin-left: ${theme.space('xxs')};
    font-size: ${theme.fontSize('s')};
  `,
);

export const MinimalDropdown = Dropdown;

export default InputGroupElement(Dropdown);
