import React from 'react';

import { ApolloError } from '@apollo/client';
import ContactsContext from './ContactsContext';
import { UpdateContactMutation } from '~/graphql/Mutation';
import updateMutation from '~/graphql/mutation/UpdateContact';
import cleanedFilename from '~/util/cleanedFilename';
import { getErrorTypes } from '~/util/errorHandling';
import Catalog from '~/Catalog';
import ErrorTypes from '~/ErrorTypes';
import { AppErrorScreen } from '~/components';

type Props = {
  children: React.ReactNode;
};

const selectErrorMessage = (error: ApolloError) => {
  const errorTypes = getErrorTypes(error);
  if (errorTypes.includes(ErrorTypes.notFoundError)) {
    return 'Dit contact is verwijderd';
  }

  return Catalog.genericUnknownErrorMessageShort;
};

const ContactsContextProvider = ({ children }: Props) => (
  <UpdateContactMutation
    name={cleanedFilename(__filename)}
    mutation={updateMutation}
    doNotReportErrorTypes={[ErrorTypes.notFoundError]}
  >
    {(updateContact, { loading, error }) => {
      if (error) return <AppErrorScreen message={selectErrorMessage(error)} />;

      return (
        <ContactsContext.Provider
          value={{
            updateContactFn: updateContact,
            loading: loading,
          }}
        >
          {children}
        </ContactsContext.Provider>
      );
    }}
  </UpdateContactMutation>
);

export default ContactsContextProvider;
