import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionStart } from '~/graphql/types.client';
import getConditionListInputForTrigger from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/utils/getConditionListInputForTrigger';
import removeTypenames from '../../../removeTypenames';

/**
 *
 * @param {ClientFlowActionStart} action - Start client action
 * keywords:
 */
const serializeStartAction = (
  action: ClientFlowActionStart,
): FlowV2_Action__Input => {
  const { id, accountId, flowBlueprintId, triggers, connector } = action;

  const convertedCondition = getConditionListInputForTrigger(
    triggers,
    connector,
  );

  return {
    Start: {
      id,
      accountId,
      flowBlueprintId,
      condition:
        'result' in convertedCondition
          ? removeTypenames(convertedCondition.result)
          : {
              exp: '(0)',
              conditions: [],
            },
    },
  };
};

export default serializeStartAction;
