import { StringField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { StringConditionField } from '../types.flow';
import { FlowConditionStringPrimitiveFieldsFragment } from '~/graphql/types';

import { FLOW_CONDITION_PRIMITIVE_STRING_OPERATION } from './constants';
import { asStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

export default (
  field: StringField,
  condition: FlowConditionStringPrimitiveFieldsFragment,
): StringConditionField => {
  const baseVariables = {
    value: asStringParameterValue(condition.stringValue),
    negate: condition?.negate ?? false,
  };

  switch (condition.__typename) {
    case 'Flow_ConditionPrimitive_String_Eq':
      return {
        ...field,
        operation: {
          type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.EQUALS,
          ...baseVariables,
        },
      };
    case 'Flow_ConditionPrimitive_String_EndsWith':
      return {
        ...field,
        operation: {
          type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.ENDS_WITH,
          ...baseVariables,
        },
      };
    case 'Flow_ConditionPrimitive_String_StartsWith':
      return {
        ...field,
        operation: {
          type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.STARTS_WITH,
          ...baseVariables,
        },
      };
    case 'Flow_ConditionPrimitive_String_Contains':
      return {
        ...field,
        operation: {
          type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.CONTAINS,
          ...baseVariables,
        },
      };

    default:
      return assertNever(condition, cleanedFilename(__filename));
  }
};
