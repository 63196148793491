import React from 'react';
import { useSpring, animated } from 'react-spring';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import TEST_ID from './index.testid';

const EmailFileAttachment = props => {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(0,20px,0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0,0px,0)',
    },
  });
  return (
    <Container data-testid={TEST_ID.UPLOADING} style={style}>
      <ContentLoader
        speed={2}
        width="100%"
        height={40}
        viewBox="0 0 380 40"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="0" rx="3" ry="3" width="40" height="40" />
        <rect x="50" y="0" rx="3" ry="3" width="350" height="40" />
      </ContentLoader>
    </Container>
  );
};

const Container = styled(animated.div)<{}>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;

  svg {
    max-width: 380px;
  }
`;

export default EmailFileAttachment;
