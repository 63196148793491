import React from 'react';
import { Props } from '..';

const HtmlTag: React.FC<Props> = ({
  color = 'currentColor',
  ...props
}: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 18 3 12 9 6M17 18 23 12 17 6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HtmlTag;
