import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  ifElseActionId: string;
  visiblePath: boolean;
  yesPathChild: React.ReactNode;
  noPathChild: React.ReactNode;
};
const IfElseTree = ({
  yesPathChild,
  noPathChild,
  ifElseActionId,
  visiblePath,
}: Props) => {
  let yesPath: ReactElement | null = null;
  if (yesPathChild != null) {
    yesPath = (
      <IfElseTreePathContainerSliding
        key={`${ifElseActionId}-yes-container`}
        isActive={visiblePath}
        isYesPath={true}
      >
        {yesPathChild}
      </IfElseTreePathContainerSliding>
    );
  }
  let noPath: ReactElement | null = null;
  if (noPathChild != null) {
    noPath = (
      <IfElseTreePathContainerSliding
        key={`${ifElseActionId}-no-container`}
        isActive={!visiblePath}
        isYesPath={false}
      >
        {noPathChild}
      </IfElseTreePathContainerSliding>
    );
  }

  return (
    <IfElseTreesContainer>
      {yesPath}
      {noPath}
    </IfElseTreesContainer>
  );
};

type IfElseTreePathContainerComponentProps = {
  isYesPath: boolean;
  isActive: boolean;
};
const IfElseTreePathContainerSliding = styled.div<IfElseTreePathContainerComponentProps>`
  transition: width 0.3s ease-out;

  ${({ theme, isActive, isYesPath }) => {
    if (isActive) {
      return css`
        border: ${theme.getTokens().border.width.s} solid
          ${isYesPath ? theme.color('success') : '#311D6B'};
        border-radius: ${theme.getTokens().border.radius.s};
        padding: ${theme.space('s')};
      `;
    }

    return '';
  }}

  ${({ isActive }: IfElseTreePathContainerComponentProps) => {
    if (isActive) {
      return css`
        width: 100%;
      `;
    } else {
      return css`
        width: 0%;
        height: 0;
        overflow-x: hidden;
      `;
    }
  }}
`;

const IfElseTreesContainer = styled.div<{}>`
  display: flex;
  align-items: flex-start;
`;

export default IfElseTree;
