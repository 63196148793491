import { keys, isNil } from 'ramda';
import { useLayoutEffect, useState } from 'react';
import breakpoints from '~/theme/System/tokens/breakpoints';

const breakpointKeys = keys(breakpoints);
export type BreakpointKeys = keyof typeof breakpoints;

const useCurrentBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] =
    useState<keyof typeof breakpoints>('desktop');

  useLayoutEffect(() => {
    let didCancel = false;
    const onChange = (key: BreakpointKeys) => (evt: MediaQueryListEvent) => {
      if (evt.media.includes(breakpoints[key])) {
        if (!didCancel) {
          setCurrentBreakpoint(key);
        }
      }
    };
    const points = breakpointKeys.map(key => {
      const mql = window.matchMedia(`(max-width: ${breakpoints[key]})`);
      if (mql.matches) setCurrentBreakpoint(key);

      return {
        mql,
        key,
        listener: !isNil(mql.addEventListener)
          ? mql.addEventListener('change', onChange(key))
          : null,
      };
    }, {});

    const initialBreakpoint = points.find(({ mql }) => mql.matches);
    if (initialBreakpoint) setCurrentBreakpoint(initialBreakpoint.key);

    return () => {
      didCancel = true;
      points.forEach(({ mql, key }) => {
        if (!isNil(mql.removeEventListener))
          mql.removeEventListener('change', onChange(key));
      });
    };
  }, []);

  return currentBreakpoint;
};

export default useCurrentBreakpoint;
