import { AddContactTagAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { emptyStringPrimitiveParameterValue } from '../Base';

const defaultNewAddContactTagProps = (
  baseActionProps: BaseActionProps,
): AddContactTagAction => ({
  ...baseActionProps,
  type: FLOW_ACTION_TYPE.ADD_CONTACT_TAG,
  name: emptyStringPrimitiveParameterValue(),
});

export default defaultNewAddContactTagProps;
