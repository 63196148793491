import cleanedFilename from '~/util/cleanedFilename';

export default {
  CHANGE_PASS_FORM: `${cleanedFilename(__filename)}-change-pass-form`,
  CHANGE_PASS_ERROR_MESSAGE: `${cleanedFilename(
    __filename,
  )}-change-pass-error-message`,
  FORM_OLD_PASS: `${cleanedFilename(__filename)}-form-old-pass}`,
  SUBMIT_PASSWORD_BUTTON: 'submit-password-button',
};
