import { set, lensIndex } from 'ramda';
import { ClientFlowAction } from '~/graphql/types.client';

type Opts = {
  /**
   * Where did the edge start
   */
  source: string;

  /**
   * Where did the edge end
   */
  target: string;

  /**
   * The data that was dropped
   */
  subject: ClientFlowAction;

  /**
   * Is drop target the true path
   */
  isTruePath: boolean;

  /**
   * Must be true when adding the action on secondary edge (after the last node on a parallel path)
   */
  keepPrevParentsOfTarget?: boolean;
};
const onUpdateActions = (
  actions: Array<ClientFlowAction>,
  {
    source,
    target,
    subject,
    isTruePath,
    keepPrevParentsOfTarget = false,
  }: Opts,
): Array<ClientFlowAction> => {
  const mutableActions = [...actions];

  const subjectActionIndex = mutableActions.findIndex(
    ({ id }) => id === subject.id,
  );

  let subjectAction = mutableActions[subjectActionIndex];

  const newParentActionIndex = mutableActions.findIndex(
    ({ id }) => id === source,
  );
  let newParentAction = mutableActions[newParentActionIndex];

  const newChildActionIndex = mutableActions.findIndex(
    ({ id }) => id === target,
  );
  let newChildAction = mutableActions[newChildActionIndex];

  // New action added
  if (!subjectAction) {
    if (subject.__typename !== 'FlowV2_Action_Start') {
      subject.parentIds = [newParentAction.id];
    }

    if (newParentAction.__typename === 'FlowV2_Action_IfElse') {
      const ifElseKey =
        newParentAction.trueChildId === newChildAction.id
          ? 'trueChildId'
          : 'falseChildId';
      newParentAction = {
        ...newParentAction,
        [ifElseKey]: subject.id,
      };
    }

    const conditionalProps =
      newChildAction.__typename === 'FlowV2_Action_IfElse'
        ? {
            trueChildId: newChildAction.trueChildId,
            falseChildId: newChildAction.falseChildId,
          }
        : {};

    const prevParents =
      'parentIds' in newChildAction ? [...newChildAction.parentIds] : [];
    const indexOfSubject = prevParents.findIndex(id => id === source);

    // It's important to keep the same order of parentIds
    prevParents.splice(indexOfSubject, 1, subject.id);

    const parentIds = keepPrevParentsOfTarget ? prevParents : [subject.id];

    const updatedNewChildAction = {
      ...newChildAction,
      ...conditionalProps,
      parentIds,
    };

    // Update the actions array
    const updatedActions = set(
      lensIndex(newChildActionIndex),
      updatedNewChildAction,
      // Update parent
      set(lensIndex(newParentActionIndex), newParentAction, actions),
    );

    if (subject.__typename !== 'FlowV2_Action_Start') {
      if (subject.__typename === 'FlowV2_Action_IfElse') {
        subjectAction = {
          ...subject,
          //Ideally we want user to choose what child to assign to, but this functionality is to be developed
          //For now we always assign to the true child if it's an ifElse parent action
          trueChildId: updatedNewChildAction.id,
          falseChildId: null,
        };
        return [
          ...updatedActions,
          {
            ...subjectAction,
            parentIds: [source],
          },
        ];
      }
      return [
        ...updatedActions,
        {
          ...subject,
          parentIds: [source],
        },
      ];
    }

    return [...updatedActions, subject];
  }

  // Moving an existing action
  const subjectActionParentIndex = mutableActions.findIndex(
    action =>
      'parentIds' in subjectAction &&
      subjectAction.parentIds.includes(action.id),
  );
  let subjectActionParent = mutableActions[subjectActionParentIndex];

  const subjectActionChildIndex = mutableActions.findIndex(
    action =>
      'parentIds' in action && action.parentIds.includes(subjectAction.id),
  );
  let subjectActionChild = mutableActions[subjectActionChildIndex];

  // Update the previous position first
  // First connect droppedActionChild to droppedActionParent
  if (
    subjectActionChild != null &&
    'parentIds' in subjectAction &&
    'parentIds' in subjectActionChild // Last conditions is only for Typescript
  ) {
    subjectActionChild = {
      ...subjectActionChild,
      parentIds: subjectAction.parentIds,
    };

    mutableActions[subjectActionChildIndex] = subjectActionChild;
  }

  // Update the parent of the dropped action
  if ('parentIds' in subjectAction) {
    subjectAction = {
      ...subjectAction,
      parentIds: [source],
    };

    mutableActions[subjectActionIndex] = subjectAction;
  }

  if (
    subjectActionParent != null &&
    subjectActionParent.__typename === 'FlowV2_Action_IfElse'
  ) {
    if (isTruePath) {
      subjectActionParent = {
        ...subjectActionParent,
        trueChildId: subjectActionChild.id,
      };
    } else {
      subjectActionParent = {
        ...subjectActionParent,
        falseChildId: subjectActionChild.id,
      };
    }

    mutableActions[subjectActionParentIndex] = subjectActionParent;
  }

  // Update the next position
  // Update new parent if it is an ifElse
  if (
    newParentAction != null &&
    newParentAction.__typename === 'FlowV2_Action_IfElse'
  ) {
    if (isTruePath) {
      newParentAction = {
        ...newParentAction,
        trueChildId: subjectAction.id,
      };
    } else {
      newParentAction = {
        ...newParentAction,
        falseChildId: subjectAction.id,
      };
    }

    mutableActions[newParentActionIndex] = newParentAction;
  }

  // Update new child to dropped action id
  if (newChildAction != null && 'parentIds' in newChildAction) {
    newChildAction = {
      ...newChildAction,
      parentIds: [subjectAction.id],
    };

    if (newChildAction.__typename === 'FlowV2_Action_IfElse') {
      if (subjectAction.id === newChildAction.trueChildId) {
        newChildAction = {
          ...newChildAction,
          trueChildId: subjectActionChild.id,
        };
      } else {
        newChildAction = {
          ...newChildAction,
          falseChildId: subjectActionChild.id,
        };
      }
    }

    mutableActions[newChildActionIndex] = newChildAction;
  }

  return mutableActions;
};

export default onUpdateActions;
