import { reporter } from '~/hooks/useErrorReporter';

/**
 * parse JSON with error handling
 * @param {string} string
 * keywords: json, parse, JSON.parse
 */

/** There are some reasons why parsing JSON might fail:
 * - Invalid JSON Syntax: The JSON string may have invalid syntax, such as missing or mismatched brackets, quotes, or colons. This can cause a parsing error.
 * - Unexpected Characters: The JSON string may contain unexpected characters that are not valid in JSON, such as control characters or invalid escape sequences.
 * - Trailing Commas: JSON does not allow trailing commas at the end of arrays or objects. If the JSON string has trailing commas, it will result in a parsing error.
 * - Circular References: If the JSON string contains circular references, where an object refers back to itself or creates an infinite loop of references, the parsing process will fail.
 * - Large JSON Strings: Extremely large JSON strings can consume a significant amount of memory during parsing, potentially leading to performance issues or memory limitations.
 * - Malicious Content: JSON strings obtained from untrusted sources might contain malicious content, such as code injection attempts or unexpected data types that can cause security vulnerabilities.
 */
const parseJSONSafe = (string: string) => {
  try {
    const parsed = JSON.parse(string);

    return parsed;
  } catch (error) {
    reporter.captureMessage(
      `Failed to parse JSON: ${{ input: string, error }}`,
      'critical',
    );
    return null;
  }
};

export default parseJSONSafe;
