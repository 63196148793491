import React from 'react';
import styled, { css } from 'styled-components';
import { ThemeColor } from '~/theme/System/tokens/colorPalette';
import { SystemSize } from '~/theme/System/tokens';
import arrayToCss from '~/util/arrayToCss';

export type Props = {
  dataTestId?: string;
  margin?: Array<SystemSize | null>;
  vertical?: boolean;
  width?: string;
  color?: ThemeColor;
  css?: string;
};

const Divider: React.FC<Props> = ({
  dataTestId,
  margin = ['l', null],
  vertical = false,
  width,
  css,
  color = { group: 'grey', variant: 'base' },
  ...rest
}) => (
  <Container
    data-testid={dataTestId}
    $margin={margin}
    vertical={vertical}
    $width={width}
    $color={color}
    css={css}
    {...rest}
  />
);

const Container = styled.hr<{
  $margin?: Props['margin'];
  vertical?: boolean;
  $width?: string;
  $color?: ThemeColor;
}>(
  ({
    theme,
    $margin = ['m', null],
    vertical = false,
    $width = '100%',
    $color = { group: 'grey' },
  }) => css`
    border: 0;
    height: ${vertical ? '100%' : '1px'};
    width: ${vertical ? '1px' : $width};
    background: ${theme.color($color.group, $color.variant || 'base')};
    margin: ${arrayToCss($margin, theme)};
  `,
);

export default Divider;
