import { Flow_Parameter__Number__Input } from '~/graphql/types';
import { NumberParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import asNumberPrimitiveParameterValueInput from './asNumberPrimitiveParameterValueInput';

export default (
  parameterValue: NumberParameterValue,
): Flow_Parameter__Number__Input => {
  switch (parameterValue.type) {
    case PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE:
      return asNumberPrimitiveParameterValueInput(parameterValue);
    case PARAMETER_VALUE_TYPE.NUMBER_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }

      return {
        Number: {
          Pointer: {
            variable: parameterValue.variable.name,
            field: parameterValue.variable.field.name,
          },
        },
      };
    default:
      throw new Error(
        `Field value input conversion cannot handle (${JSON.stringify(
          parameterValue,
          null,
          2,
        )})`,
      );
  }
};
