import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import { ConditionField } from './types.flow';
import { TypedField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import BaseActionContext, {
  WithBaseActionContextProps,
} from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { Dropdown, InputGroup, MinimalDropdown } from '~/components/Inputs';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { FIELD_TYPE } from '../../constants';
import { FlowConditionPrimitiveStringFieldComponent } from './String';
import { FlowConditionPrimitiveBooleanFieldComponent } from './Boolean';
import { FlowConditionPrimitiveNumberFieldComponent } from './Number';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import TEST_ID from './FlowConditionPrimitiveFieldComponent.testid';
import FieldTag from './FieldTag';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import { expressionOptions } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { emptyConditionField } from '.';
import { ConditionPrimitiveStringField } from './String/FlowConditionPrimitiveStringFieldComponent';
import { ConditionPrimitiveNumberField } from './Number/FlowConditionPrimitiveNumberFieldComponent';
import { ConditionPrimitiveBooleanField } from './Boolean/FlowConditionPrimitiveBooleanFieldComponent';
import { assertNeverWithoutThrowing } from '~/util/assertion';

const text = {
  noFieldsErrorMessage: 'Voeg minimaal één waarde toe',
};

export type ConditionFieldProp =
  | ConditionPrimitiveStringField
  | ConditionPrimitiveNumberField
  | ConditionPrimitiveBooleanField;

type MyProps = {
  initialField: ConditionField;
  allShouldBeTrue: boolean;
  fields: Array<ConditionFieldProp>;
  fieldOptions: Array<TypedField>;
  onChange: (newFields: Array<ConditionFieldProp>) => void;
  onAllShouldBeTrueChange: (newAllShouldBeTrue: boolean) => void;
  announceChanges: () => void;
};
type Props = WithBaseActionContextProps & MyProps;

const areConditionFieldPropsEqual = (
  firstCondition: ConditionFieldProp,
  secondCondition: ConditionFieldProp,
): boolean => undefinedInsensitiveDeepEquals(firstCondition, secondCondition);

export const undefinedInsensitiveDeepEquals = (
  obj1: $Object | null | undefined,
  obj2: $Object | null | undefined,
): boolean => {
  // if either isn't an object then return simple equals as there are no properties
  if (
    obj1 === null ||
    obj1 === undefined ||
    typeof obj1 !== 'object' ||
    obj2 === null ||
    obj2 === undefined ||
    typeof obj2 !== 'object'
  ) {
    return obj1 === obj2;
  }

  for (const p in obj1) {
    switch (typeof obj1[p]) {
      case 'object':
        if (!undefinedInsensitiveDeepEquals(obj1[p], obj2[p])) return false;
        break;

      case 'function':
        if (
          typeof obj2[p] === 'undefined' ||
          (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())
        )
          return false;
        break;
      default:
        if (obj1[p] !== obj2[p]) return false;
    }
  }

  for (const p in obj2) {
    // If the field is undefined or not part of the object, consider them as the same object.
    // This is needed for our field label hack where we allow customers to keep their field selection when changing the condition(or the trigger).
    if (obj2[p] !== undefined && typeof obj1[p] === 'undefined') return false;
  }
  return true;
};

const MultipleFlowConditionPrimitiveFieldComponent = ({
  fieldOptions,
  initialField,
  fields,
  onChange,
  allShouldBeTrue,
  onAllShouldBeTrueChange,
  announceChanges,
}: Props) => {
  const baseActionContext = useContext(BaseActionContext);
  const { showValidation } = useContext(FlowContext);
  const [, setValidatorKey] = useState<string | null>(null);
  const [typedFieldState, setTypedFieldState] = useState<ConditionFieldProp>(
    fields.length > 0
      ? fields[0]
      : emptyConditionField(
          fieldOptions.find(
            fieldOption => fieldOption?.name === initialField.name,
          ) || fieldOptions[0],
        ),
  );

  if (
    new Set(
      fields.map(field => {
        if ('name' in field) return field.name;
        return;
      }),
    ).size > 1
  ) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Can't have different fields in a group (${JSON.stringify(
        fields,
        null,
        2,
      )})`,
    );
  }

  const selectedFieldOption = typedFieldState.name
    ? getFieldOptionsForSelectedField(typedFieldState.name, fieldOptions)
    : undefined;

  /**
   * If current state of the condition has no fields yet, show all the field options.
   * Otherwise only show the same type of condition primitive fields
   * */
  const fieldOptionsToShow = fieldOptions.filter(
    fieldOption =>
      fields.length === 0 || fieldOption.type === typedFieldState.type,
  );

  const hasFieldOptionsToShow = fieldOptionsToShow.length !== 0;

  const selectedIdx = fieldOptionsToShow.findIndex(
    fieldOption => fieldOption.name === typedFieldState.name,
  );

  const handleFieldSelection = useCallback(() => {
    if (selectedFieldOption == null && typedFieldState.name != null) {
      setTypedFieldState({
        ...typedFieldState,
        name: undefined,
        label: undefined,
      });
      return;
    }

    if (
      fields.length > 0 &&
      fields[0].name == null &&
      selectedFieldOption != null
    ) {
      onChange(
        fields.map(field => ({
          ...field,
          name: selectedFieldOption.name,
          label: selectedFieldOption.label,
        })) as Array<ConditionField>,
      );
      setTypedFieldState({
        ...typedFieldState,
        name: selectedFieldOption.name,
        label: selectedFieldOption.label,
      } as ConditionField);
    }
  }, [fields, onChange, selectedFieldOption, typedFieldState]);

  const validate = useCallback(() => {
    if (fields.length < 1) {
      return false;
    }

    if (fieldOptions.length < 1) {
      return false;
    }

    if (typedFieldState.type === FIELD_TYPE.BOOLEAN && fields.length > 1) {
      return false;
    }

    if (typedFieldState != null && typedFieldState.name == null) return false;

    const selectedFieldOption = getFieldOptionsForSelectedField(
      typedFieldState.name,
      fieldOptions,
    );

    if (fields.length > 0 && selectedFieldOption == null) return false;

    return true;
  }, [fieldOptions, fields, typedFieldState]);

  useEffect(() => {
    const _key = baseActionContext.subscribeValidator({ validate });
    setValidatorKey(_key);
    handleFieldSelection();

    return () => {
      baseActionContext.unsubscribeValidator(_key);
    };
  }, [baseActionContext, handleFieldSelection, validate]);

  if (typedFieldState.type === FIELD_TYPE.BOOLEAN) {
    if (fields.length > 1) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Boolean field is not allowed to have multiple`,
      );
    }
    let booleanField;
    if (fields.length === 0) {
      booleanField = typedFieldState;
    } else {
      const potentialField = fields[0];

      if (potentialField.type !== FIELD_TYPE.BOOLEAN) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | Field is not a boolean field?!`,
        );
      }

      booleanField = potentialField;
    }

    return (
      <InputGroup>
        <FieldChoiceDropdown
          fields={fields}
          dropdownError={
            !hasFieldOptionsToShow
              ? 'Er zijn geen velden beschikbaar voor dit type'
              : selectedFieldOption == null &&
                showValidation &&
                fields.length > 0
              ? Catalog.flows.fieldLabel
              : undefined
          }
          selectedFieldOption={selectedFieldOption}
          fieldOptions={fieldOptionsToShow}
          selectedIdx={selectedIdx}
          setTypedFieldState={setTypedFieldState}
          onChange={onChange}
        />
        {hasFieldOptionsToShow && (
          <FlowConditionPrimitiveBooleanFieldComponent
            field={booleanField}
            onChange={newField => {
              onChange([newField]);
            }}
          />
        )}
      </InputGroup>
    );
  }

  return (
    <>
      <InputGroup>
        <FieldChoiceDropdown
          fields={fields}
          dropdownError={
            !hasFieldOptionsToShow
              ? 'Er zijn geen velden beschrikbaar voor dit type'
              : selectedFieldOption == null &&
                showValidation &&
                fields.length > 0
              ? Catalog.flows.fieldLabel
              : undefined
          }
          selectedFieldOption={selectedFieldOption}
          fieldOptions={fieldOptionsToShow}
          selectedIdx={selectedIdx}
          setTypedFieldState={setTypedFieldState}
          onChange={onChange}
        />
        {hasFieldOptionsToShow &&
          componentSwitcher(
            typedFieldState,
            (newField: ConditionField) => {
              if (
                !fields.some(field =>
                  areConditionFieldPropsEqual(field, newField),
                )
              ) {
                onChange([...fields, newField]);
              }
            },
            announceChanges,
          )}
      </InputGroup>
      <FieldListContainer>
        <FieldListComponent
          fields={fields}
          validate={validate}
          showValidation={showValidation}
          typedFieldState={typedFieldState}
          allShouldBeTrue={allShouldBeTrue}
          onChange={onChange}
          onAllShouldBeTrueChange={onAllShouldBeTrueChange}
        />
      </FieldListContainer>
    </>
  );
};

type FieldChoiceDropdownProps = {
  fields: Props['fields'];
  dropdownError: string | undefined;
  selectedFieldOption: TypedField | undefined;
  fieldOptions: Props['fieldOptions'];
  selectedIdx: number;
  setTypedFieldState: React.Dispatch<React.SetStateAction<ConditionField>>;
  onChange: Props['onChange'];
};
const FieldChoiceDropdown = ({
  fields,
  dropdownError,
  selectedFieldOption,
  fieldOptions,
  selectedIdx,
  setTypedFieldState,
  onChange,
}: FieldChoiceDropdownProps) => (
  <Dropdown
    dataTestid={TEST_ID.FIELD_DROPDOWN}
    error={dropdownError}
    disabled={
      (selectedIdx != -1 && fields.length > 0) || fieldOptions.length === 0
    }
    helpLink={selectedFieldOption?.helpLink}
    label={Catalog.flows.fieldLabel}
    options={fieldOptions.map(fieldOption => ({
      label: fieldOption.label,
      payload: fieldOption,
      key: fieldOption.name,
    }))}
    selectedOptionIdx={selectedIdx}
    onChange={({ option }) => {
      const defaultFieldState = emptyConditionField(option.payload);
      setTypedFieldState(defaultFieldState);
      /* @TODO: https://app.clubhouse.io/dathuis/story/4550
        if (fields.length === 0) onChange([defaultFieldState]);
        */
      onChange([]);
    }}
  />
);

type FieldListComponentProps = {
  fields: Props['fields'];
  validate: () => boolean;
  showValidation: boolean;
  typedFieldState: ConditionFieldProp;
  allShouldBeTrue: Props['allShouldBeTrue'];
  onChange: Props['onChange'];
  onAllShouldBeTrueChange: Props['onAllShouldBeTrueChange'];
};
const FieldListComponent = ({
  fields,
  validate,
  showValidation,
  typedFieldState,
  allShouldBeTrue,
  onChange,
  onAllShouldBeTrueChange,
}: FieldListComponentProps) => {
  if (fields.length > 0) {
    return (
      <>
        <FieldLabel>{typedFieldState.label}</FieldLabel>
        {fields.map((field, idx) => {
          let andOrComponent: ReactElement | null = null;

          if (idx > 0) {
            andOrComponent = (
              <AndOrComponentContainer key={`and-or-${field.name}-${idx}`}>
                <MinimalDropdown
                  small
                  options={expressionOptions}
                  selectedOptionIdx={expressionOptions.findIndex(
                    option => option.payload === allShouldBeTrue,
                  )}
                  onChange={({ option }) => {
                    onAllShouldBeTrueChange(option.payload);
                  }}
                  dataTestid={TEST_ID.AND_OR_COMPONENT}
                />
              </AndOrComponentContainer>
            );
          }

          return (
            <React.Fragment key={`field-container-${field.name}-${idx}`}>
              {andOrComponent}
              <StyledFieldTag
                key={`field-${field.name}-${idx}`}
                // Name is made optional because it could be missing when switching trigger in an existing flow.
                // @ts-expect-error
                field={field}
                onDelete={() => {
                  onChange([
                    ...fields.filter(
                      fieldInList =>
                        !areConditionFieldPropsEqual(field, fieldInList),
                    ),
                  ]);
                }}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  }
  if (showValidation && !validate()) {
    return <ErrorMessage>{text.noFieldsErrorMessage}</ErrorMessage>;
  }
  return null;
};

const ErrorMessage = styled.div<{}>`
  ${({ theme }) => css`
    color: ${theme.color('danger')};
  `};
`;

const FieldLabel = styled.div<{}>`
  ${({ theme }) => css`
    margin-right: ${theme.space('s')};
  `};
`;

const FieldListContainer = styled.div<{}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  ${({ theme }) => css`
    padding-bottom: ${theme.space('s')};
  `};
`;

const AndOrComponentContainer = styled.div<{}>`
  max-width: 65px;

  ${({ theme }) => css`
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.s};
    margin: 0 ${theme.space('s')};
  `};
`;

const StyledFieldTag = styled(FieldTag)<{}>`
  margin: 0;
`;

const getFieldOptionsForSelectedField = (
  selectedFieldName: string,
  fieldOptions: Array<TypedField>,
) => {
  const selectedFieldOption = fieldOptions.find(
    fieldOption => fieldOption.name === selectedFieldName,
  );
  return selectedFieldOption;
};

const componentSwitcher = (
  field: ConditionFieldProp,
  onAdded: (newField: ConditionFieldProp) => void,
  announceChanges: () => void,
): React.ReactNode => {
  switch (field.type) {
    case FIELD_TYPE.STRING: {
      return (
        <FlowConditionPrimitiveStringFieldComponent
          key={`multiple-flow-condition-${field.name}`}
          field={field}
          change={{
            controlled: false,
            onAdded: onAdded,
            announceChanges,
          }}
        />
      );
    }

    case FIELD_TYPE.BOOLEAN:
      throw Error(
        `${cleanedFilename(__filename)} | BOOLEAN FIELDS SHOULD NOT GET HERE`,
      );

    case FIELD_TYPE.NUMBER:
      return (
        <FlowConditionPrimitiveNumberFieldComponent
          key={`multiple-flow-condition-${field.name}`}
          field={field}
          change={{
            controlled: false,
            onAdded: onAdded,
            announceChanges,
          }}
        />
      );
    default: {
      assertNeverWithoutThrowing(field, cleanedFilename(__filename));
      return null;
    }
  }
};

export default withBaseActionContext<MyProps>(
  MultipleFlowConditionPrimitiveFieldComponent,
);
