import React from 'react';

import ValueReportComponentCard from './components/ValueReportComponentCard';
import { RequestAddressTabProps } from './ValueReport.type';
import { RouteComponentProps } from '@reach/router';
import TextEditor from './components/TextEditor';
import Icon from '~/components/Icon';

type Props = {
  /** Id for tests */
  datatestId: string;

  /**  Tab text for current tab content */
  label: string;

  /** data contains: {
    mainHeader: { text },
    subHeader: { text }
    why: { text }
    when: { text }
  }
   */
  data: RequestAddressTabProps;

  /** Callback function which call data updating in container state */
  onChange: (objectName?: string) => (value: $Object) => void;
} & RouteComponentProps;

const text = {
  title: 'Adres',
  mainText: 'Koptekst',
  subText: 'Subtekst',
  whyText: 'Waarom tekst',
  whenText: 'Wanneer tekst',
};

const RequestAddressTab = ({ data, onChange, datatestId }: Props) => {
  const { mainHeader, subHeader, why, when } = data;
  return (
    <ValueReportComponentCard
      datatestId={datatestId}
      title={text.title}
      cardIcon={<Icon name="map-pin" />}
    >
      <TextEditor
        testName={'mainHeader'}
        text={mainHeader.text}
        title={text.mainText}
        onChange={value => onChange('mainHeader')({ text: value })}
        name="text"
        disabled={false}
      />
      <TextEditor
        testName={'subHeader'}
        text={subHeader.text}
        title={text.subText}
        onChange={value => onChange('subHeader')({ text: value })}
        name="text"
        disabled={false}
      />
      <TextEditor
        testName={'why'}
        text={why.text}
        title={text.whyText}
        onChange={value => onChange('why')({ text: value })}
        name="text"
        disabled={false}
      />
      <TextEditor
        testName={'when'}
        text={when.text}
        title={text.whenText}
        onChange={value => onChange('when')({ text: value })}
        name="text"
        disabled={false}
      />
    </ValueReportComponentCard>
  );
};

export default RequestAddressTab;
