/**
 * Returns a card heading based on action type
 * @param {FlowAction} actionType - The flow action enum
 * keywords: flowaction, heading
 */

import { FlowAction } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import { assertNeverWithoutThrowing } from '~/util/assertion';

const getCardHeading = (actionType: ClientFlowAction['actionType']): string => {
  switch (actionType) {
    case FlowAction.Start:
      return 'Start deze flow...';
    case FlowAction.ZapierTrigger:
      return 'Verstuur naar Zapier';
    case FlowAction.ContactDetails:
      return 'Contact wijzigen';
    case FlowAction.TaskCreate:
      return 'Taak aanmaken';
    case FlowAction.ContactAssign:
      return 'Contact toewijzen';
    case FlowAction.ContactAddTag:
      return 'Tag toevoegen';
    case FlowAction.ContactDeleteTag:
      return 'Tag verwijderen';
    case FlowAction.RealworksSendContact:
      return 'Stuur naar Realworks';
    case FlowAction.SendEmailPlain:
      return 'E-mail versturen';
    case FlowAction.IfElse:
      return 'Controleer of...';
    case FlowAction.Wait:
      return 'Wacht tot...';
    // case FlowAction.Goal:
    //   return 'Doel behaald';
    default:
      assertNeverWithoutThrowing(actionType);
      return 'Trigger';
  }
};

export default getCardHeading;
