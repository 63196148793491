import gql from 'graphql-tag';

import FlowConditionStringPrimitiveFields from './FlowConditionStringPrimitiveFields';
import FlowConditionBooleanPrimitiveFields from './FlowConditionBooleanPrimitiveFields';
import FlowConditionNumberPrimitiveFields from './FlowConditionNumberPrimitiveFields';
import FlowParameterEmailFields from './FlowParameterEmailFields';
import FlowParameterStringFields from './FlowParameterStringFields';
import FlowParameterOfficeFields from './FlowParameterOfficeFields';
import FlowParameterBooleanFields from './FlowParameterBooleanFields';
import FlowParameterNumberFields from './FlowParameterNumberFields';
import FlowParameterEventFields from './FlowParameterEventFields';
import FlowParameterAppValuationReportFields from './FlowParameterAppValuationReportFields';

export default gql`
  fragment FlowConditionFields on Flow_Condition {
    __typename

    ... on Flow_Condition_Contact_Details {
      name {
        ...FlowConditionStringPrimitiveFields
      }
      email {
        ...FlowConditionStringPrimitiveFields
      }
      phone {
        ...FlowConditionStringPrimitiveFields
      }
      city {
        ...FlowConditionStringPrimitiveFields
      }
      postcode {
        ...FlowConditionStringPrimitiveFields
      }
      street {
        ...FlowConditionStringPrimitiveFields
      }
      blockMarketing {
        ...FlowConditionBooleanPrimitiveFields
      }
    }

    ... on Flow_Condition_Contact_New {
      office {
        ...FlowParameterOfficeFields
      }
    }

    ... on Flow_Condition_Contact_Tag {
      exists {
        ...FlowParameterBooleanFields
      }
      tagName: name {
        ...FlowParameterStringFields
      }
    }

    ... on Flow_Condition_Event_Email_Opened {
      email {
        ...FlowParameterEmailFields
      }
    }

    ... on Flow_Condition_Event_Email_LinkClicked {
      email {
        ...FlowParameterEmailFields
      }
      link {
        ...FlowParameterStringFields
      }
    }

    ... on Flow_Condition_Time_Elapsed {
      minute {
        ...FlowParameterNumberFields
      }
      hour {
        ...FlowParameterNumberFields
      }
      day {
        ...FlowParameterNumberFields
      }
      month {
        ...FlowParameterNumberFields
      }
      year {
        ...FlowParameterNumberFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_ValuationReport_Details {
      name {
        ...FlowConditionStringPrimitiveFields
      }
      phone {
        ...FlowConditionStringPrimitiveFields
      }
      why {
        ...FlowConditionStringPrimitiveFields
      }
      when {
        ...FlowConditionStringPrimitiveFields
      }
      email {
        ...FlowConditionStringPrimitiveFields
      }
      postcode {
        ...FlowConditionStringPrimitiveFields
      }
      contactRequested {
        ...FlowConditionBooleanPrimitiveFields
      }
      livingArea {
        ...FlowConditionNumberPrimitiveFields
      }
      predicted {
        ...FlowConditionNumberPrimitiveFields
      }
      city {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_ValuationRequest_Details {
      name {
        ...FlowConditionStringPrimitiveFields
      }
      phone {
        ...FlowConditionStringPrimitiveFields
      }
      why {
        ...FlowConditionStringPrimitiveFields
      }
      when {
        ...FlowConditionStringPrimitiveFields
      }
      email {
        ...FlowConditionStringPrimitiveFields
      }
      postcode {
        ...FlowConditionStringPrimitiveFields
      }
      livingArea {
        ...FlowConditionNumberPrimitiveFields
      }
      city {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_ValuationReport_New {
      app {
        ...FlowParameterAppValuationReportFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_ValuationRequest_New {
      app {
        ...FlowParameterAppValuationReportFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New {
      noOperation
    }
    ... on Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details {
      subjectAddress {
        ...FlowConditionStringPrimitiveFields
      }
      day {
        ...FlowConditionStringPrimitiveFields
      }
      timeOfDay {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_Funda_ContactRequest_New {
      noOperation
    }
    ... on Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details {
      subjectAddress {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New {
      noOperation
    }
    ... on Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details {
      subjectAddress {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New {
      noOperation
    }
    ... on Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details {
      name {
        ...FlowConditionStringPrimitiveFields
      }
      phone {
        ...FlowConditionStringPrimitiveFields
      }
      why {
        ...FlowConditionStringPrimitiveFields
      }
      when {
        ...FlowConditionStringPrimitiveFields
      }
      email {
        ...FlowConditionStringPrimitiveFields
      }
      postcode {
        ...FlowConditionStringPrimitiveFields
      }
      livingArea {
        ...FlowConditionNumberPrimitiveFields
      }
      valuationPrice {
        ...FlowConditionNumberPrimitiveFields
      }
      city {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New {
      noOperation
    }
    ... on Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details {
      name {
        ...FlowConditionStringPrimitiveFields
      }
      phone {
        ...FlowConditionStringPrimitiveFields
      }
      why {
        ...FlowConditionStringPrimitiveFields
      }
      when {
        ...FlowConditionStringPrimitiveFields
      }
      email {
        ...FlowConditionStringPrimitiveFields
      }
      postcode {
        ...FlowConditionStringPrimitiveFields
      }
      livingArea {
        ...FlowConditionNumberPrimitiveFields
      }
      city {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }
    ... on Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New {
      reportEvent: event {
        ...FlowParameterEventFields
      }
    }
    ... on Flow_Condition_Event_Contact_App_Zapier_New {
      zapierEventId {
        ...FlowParameterStringFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_Zapier_Details {
      condition {
        ...FlowConditionStringPrimitiveFields
        ...FlowConditionBooleanPrimitiveFields
        ...FlowConditionNumberPrimitiveFields
      }
      metadata
      event {
        ...FlowParameterEventFields
      }
    }

    ###

    ... on Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New {
      noOperation
    }
    ... on Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details {
      name {
        ...FlowConditionStringPrimitiveFields
      }
      phone {
        ...FlowConditionStringPrimitiveFields
      }
      why {
        ...FlowConditionStringPrimitiveFields
      }
      when {
        ...FlowConditionStringPrimitiveFields
      }
      email {
        ...FlowConditionStringPrimitiveFields
      }
      postcode {
        ...FlowConditionStringPrimitiveFields
      }
      livingArea {
        ...FlowConditionNumberPrimitiveFields
      }
      valuationPrice {
        ...FlowConditionNumberPrimitiveFields
      }
      city {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New {
      noOperation
    }
    ... on Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details {
      name {
        ...FlowConditionStringPrimitiveFields
      }
      phone {
        ...FlowConditionStringPrimitiveFields
      }
      why {
        ...FlowConditionStringPrimitiveFields
      }
      when {
        ...FlowConditionStringPrimitiveFields
      }
      email {
        ...FlowConditionStringPrimitiveFields
      }
      postcode {
        ...FlowConditionStringPrimitiveFields
      }
      livingArea {
        ...FlowConditionNumberPrimitiveFields
      }
      city {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }
    ... on Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New {
      reportEvent: event {
        ...FlowParameterEventFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_Hypotheekbond_New {
      tool {
        ...FlowParameterStringFields
      }
    }

    ... on Flow_Condition_Event_Contact_App_Hypotheekbond_Details {
      name {
        ...FlowConditionStringPrimitiveFields
      }
      phone {
        ...FlowConditionStringPrimitiveFields
      }
      email {
        ...FlowConditionStringPrimitiveFields
      }
      event {
        ...FlowParameterEventFields
      }
    }
  }

  ${FlowConditionBooleanPrimitiveFields}
  ${FlowConditionStringPrimitiveFields}
  ${FlowParameterEmailFields}
  ${FlowParameterStringFields}
  ${FlowParameterOfficeFields}
  ${FlowParameterBooleanFields}
  ${FlowParameterNumberFields}
  ${FlowParameterEventFields}
  ${FlowConditionNumberPrimitiveFields}
  ${FlowParameterAppValuationReportFields}
`;
