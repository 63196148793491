import { StringConditionField } from '../types.flow';
import { Flow_ConditionPrimitive__String__Input } from '~/graphql/types';

import { FLOW_CONDITION_PRIMITIVE_STRING_OPERATION } from './constants';
import { asStringParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

const asConditionStringFieldInput = (
  field: StringConditionField,
): Flow_ConditionPrimitive__String__Input => {
  const { operation } = field;

  if (operation == null || operation.value == null) {
    throw new Error(
      'Should not occur: Validation should not allow a condition field without operation',
    );
  }

  let operationObject;
  const value = asStringParameterValueInput(operation.value);
  const negate = operation.negate;

  switch (operation.type) {
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.EQUALS:
      operationObject = {
        Eq: {
          value,
          negate,
        },
      };
      break;
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.ENDS_WITH:
      operationObject = {
        EndsWith: {
          value,
          negate,
        },
      };
      break;
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.STARTS_WITH:
      operationObject = {
        StartsWith: {
          value,
          negate,
        },
      };
      break;
    case FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.CONTAINS:
      operationObject = {
        Contains: {
          value,
          negate,
        },
      };
      break;
    default:
      assertNever(operation, cleanedFilename(__filename));
  }

  return {
    String: operationObject,
  };
};

export default asConditionStringFieldInput;
