import gql from 'graphql-tag';

import ActivityFields from '../fragments/ActivityFields';
import GenericEventBodyFields from '../fragments/GenericEventBodyFields';

export default gql`
  mutation InsertActivity(
    $accountId: ID!
    $email: EventContactEmail_New
    $call: EventContactCall_New
    $note: EventContactNote_New
  ) {
    insertActivity(
      accountId: $accountId
      email: $email
      call: $call
      note: $note
    ) {
      ...ActivityFields

      ... on Event_Contact_Generic {
        body {
          ...GenericEventBodyFields
        }
      }
    }
  }

  ${GenericEventBodyFields}
  ${ActivityFields}
`;
