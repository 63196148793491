import styled from 'styled-components';

export const ActionWrapper = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AuthForm = styled.div<{}>`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;

  padding-bottom: 100px;
  margin: 0 auto;
  width: 100%;
  max-width: 550px;
`;

export const PasswordRulesText = styled.p<{}>`
  font-style: italic;

  ${({ theme }) => `
    font-size: ${theme.fontSize('s')};

    .invalid {
      color: ${theme.color('danger')};
    }

    .valid {
      color: ${theme.color('success')};
    }
  `};
`;

export const OtherOptionsLink = styled.div<{}>`
  text-align: center;
  margin-top: 32px;
`;

export const ErrorMsg = styled.div<{}>`
  ${({ theme }) => `
    color: ${theme.color('danger')};
  `};
`;

export const InfoMsg = styled.div<{}>`
  ${({ theme }) => `
    color: ${theme.color('primary', 'light')};
  `};
`;

export const Link = styled.a<{}>`
  ${({ theme }) => `
    color: ${theme.color('primary')};
  `};

  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const FormSection = styled.form<{}>`
  width: 100%;
`;
