import React from 'react';
import DHRouter from '~/components/DHRouter';

import { WithNavigationFrame as NotFound } from '~/components/404';
import AppsList from './components/AppsList';
import ValueReport from './ValueReport';
import { RouteComponentProps } from '@reach/router';
import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/scenes/ErrorScreen';
import Catalog from '~/Catalog';
import PremiumValueReportDetails from './PremiumValueReport/PremiumValueReportDetails';
import BBWaardecheck from './BBWaardecheck';
import Realworks from './Realworks';
import Zapier from './Zapier';
import VBOWaardecheck from './VBOWaardecheck';
import Hypotheekbond from './Hypotheekbond';
import Trustoo from './Trustoo';

type Props = RouteComponentProps;
const Apps: React.FC<Props> = () => (
  <DHRouter>
    <AppsList path={'/*'} />
    <BBWaardecheck path="bb-waardecheck/*" />
    <ValueReport path="value-report/*" />
    <PremiumValueReportDetails path={`premium-value-report/:reportId`} />
    <Realworks path="realworks/*" />
    <Zapier path="zapier/*" />
    <VBOWaardecheck path="avix-waardecheck/*" />
    <Hypotheekbond path="hypotheekbond/*" />
    <Trustoo path="trustoo/*" />
    <NotFound default />
  </DHRouter>
);

export default withErrorBoundary<Props>(
  Apps,
  <ErrorScreen explanation={Catalog.genericUnknownErrorMessage} />,
);
