import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import type { ContactInNewTaskDetail } from '~/scenes/Tasks/types';

import { H3, H4 } from '~/components/Typography';
import Button from '~/components/Button';
import Icon from '~/components/Icon/';
import TaskModalContext from '../TaskModalContext';
import LeadScoreDisplayer from '~/components/LeadScoreDisplayer';
import useOffice from '~/hooks/useOffice';
import getUserName from '~/util/getUserName';
import useUser from '~/hooks/useUser';
import useViewingModeProps from '~/hooks/useViewingModeProps';

type Props = {
  contact: ContactInNewTaskDetail;
};

const text = {
  headline: 'Bekijk profiel',
  subsectionTitle: 'Contactgegevens',
  subsectionTitle2: 'Eigenaar',
  officeDefault: 'Er is geen vestiging toegewezen',
  userDefault: 'Er is geen gebruiker toegewezen',
  phoneDefault: 'Er is geen telefoonnummer ingevuld',
};

const ContactDetails = ({ contact }: Props) => {
  const { closeModal } = useContext(TaskModalContext);
  const viewingModeProps = useViewingModeProps();

  const location = useLocation();

  const officeName = useOffice(contact.officeId)?.name;

  const user = useUser(contact.userId);
  const userName = user ? getUserName(user) : null;

  return (
    <div data-testid="task-modal-contact-details">
      <ContactNameHeadline>
        <ContactTextContainer {...viewingModeProps}>
          {contact.name}
        </ContactTextContainer>
        <StyledLeadScoreDisplayer leadScore={contact.leadScore || 0} />
      </ContactNameHeadline>

      <Button
        ghost
        onClick={() => {
          const path = `/-/contacts/${contact.id}`;

          if (location.pathname === path) {
            closeModal();
          } else {
            void navigate(path);
          }
        }}
        label={text.headline}
      />
      <SubsectionTitle>{text.subsectionTitle}</SubsectionTitle>
      <SectionField>
        <IconWrapper section={'CONTACT'}>
          <Icon name="phone" />
        </IconWrapper>
        {contact.phone ? (
          <ContactTextContainer {...viewingModeProps}>
            {contact.phone}
          </ContactTextContainer>
        ) : (
          <Placeholder>{text.phoneDefault}</Placeholder>
        )}
      </SectionField>
      <SectionField>
        <IconWrapper section={'CONTACT'}>
          <Icon name="mail" />
        </IconWrapper>
        <ContactTextContainer {...viewingModeProps}>
          {contact.email}
        </ContactTextContainer>
      </SectionField>
      <SubsectionTitle>{text.subsectionTitle2}</SubsectionTitle>
      <SectionField>
        <IconWrapper section={'ASSIGNED'}>
          <Icon name="office" strokeWidth={1} />
        </IconWrapper>
        {officeName ? (
          officeName
        ) : (
          <Placeholder>{text.officeDefault}</Placeholder>
        )}
      </SectionField>
      <SectionField>
        <IconWrapper section={'ASSIGNED'}>
          <Icon name="user" />
        </IconWrapper>
        {userName ? userName : <Placeholder>{text.userDefault}</Placeholder>}
      </SectionField>
    </div>
  );
};

const Placeholder = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('text')};
  `,
);

type IconProps = {
  section: 'CONTACT' | 'MOVE' | 'ASSIGNED';
};
const IconWrapper = styled.span<IconProps>`
  margin-right: ${({ theme }) => theme.space('xs')};
  ${({ theme, section }) => {
    if (section === 'CONTACT') {
      return css`
        color: ${theme.color('success')};
      `;
    }
    if (section === 'MOVE') {
      return css`
        color: ${theme.color('accent')};
      `;
    }
    if (section === 'ASSIGNED') {
      return css`
        color: ${theme.color('primary')};
      `;
    }
    return css``;
  }};
`;

const ContactTextContainer = styled.span<{}>(
  () => css`
    display: flex;
  `,
);

const StyledLeadScoreDisplayer = styled(LeadScoreDisplayer)<{}>`
  ${({ theme }) => css`
    margin-top: 0;
    padding-left: ${theme.space('m')};
  `};
`;

const ContactNameHeadline = styled(H3)<{}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 899px) {
    justify-content: flex-start;
  }

  ${({ theme }) => css`
    margin-top: 0;
    margin-bottom: ${theme.space('m')};
  `};
`;

const SectionField = styled.p<{}>`
  display: grid;
  grid-template-columns: 40px auto;
`;

const SubsectionTitle = styled(H4)<{}>`
  margin: 0;

  ${({ theme }) => css`
    border-bottom: solid 1px ${theme.color('grey')};
    padding-top: ${theme.space('m')};
  `};
`;

export default ContactDetails;
