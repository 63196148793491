import React from 'react';
import { OptionOf } from '~/components/Dropdown';
import Icon from '~/components/Icon';
import { setBlockStyle } from '~/components/PluginsEditor/commands/modify/block';
import { DHEditor } from '~/components/PluginsEditor/types';
import { OptionLabel } from '../..';

const options: Array<OptionOf<'left' | 'right' | 'center' | 'justify'>> = [
  {
    key: 'left',
    payload: 'left',
    label: 'Links',
  },
  {
    key: 'right',
    payload: 'right',
    label: 'Rechts',
  },
  {
    key: 'center',
    payload: 'center',
    label: 'Centreren',
  },
  {
    key: 'justify',
    payload: 'justify',
    label: 'Uitvullen',
  },
];

const getAlignmentOptions = (editor: DHEditor): Array<OptionOf<string>> =>
  options.map(({ key, payload, label }) => ({
    key,
    label: (
      <OptionLabel>
        <Icon name={`align-${payload}`} />
        {label}
      </OptionLabel>
    ),
    payload,
    onClickAction: () => setBlockStyle(editor, 'textAlign', payload),
  }));

export default getAlignmentOptions;
