import { FlowConditionEmailLinkClicked } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import { PARAMETER_VALUE_TYPE } from '../../FlowParameter/ParameterValue/constants';

const describeEmailLinkClickedCondition = (
  condition: FlowConditionEmailLinkClicked,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  const { email, link } = condition;

  if (
    link.type === PARAMETER_VALUE_TYPE.STRING_PRIMITIVE &&
    link.value == null
  ) {
    const descriptionArray: DescribeNodeType = [];
    const describedParamValue = describeParameterValue(email, describeOptions);

    descriptionArray.push('op een link in ');

    if (Array.isArray(describedParamValue)) {
      descriptionArray.push(...describedParamValue);
    } else {
      descriptionArray.push(describedParamValue);
    }

    descriptionArray.push(' is geklikt');

    return descriptionArray;
  }

  const describedLinkValue = describeParameterValue(
    link,
    describeOptions,
    true,
  );
  const describedEmailValue = describeParameterValue(email, describeOptions);

  const descriptionArray: DescribeNodeType = [];

  descriptionArray.push('op de link ');

  if (Array.isArray(describedLinkValue)) {
    descriptionArray.push(...describedLinkValue);
  } else {
    descriptionArray.push(describedLinkValue);
  }

  descriptionArray.push(' in ');

  if (Array.isArray(describedEmailValue)) {
    descriptionArray.push(...describedEmailValue);
  } else {
    descriptionArray.push(describedEmailValue);
  }

  descriptionArray.push(' is geklikt');

  return descriptionArray;
};

export default describeEmailLinkClickedCondition;
