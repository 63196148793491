import React, { ReactElement } from 'react';

import {
  StringParameterValue,
  StringPrimitiveParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { WithControlledSwitcherProps } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/constants';
import { Dropdown } from '~/components/Inputs';
import Catalog from '~/Catalog';
import { emptyStringPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import StringPrimitiveParameterValueComponent from './StringPrimitiveParameterValueComponent';
import TEST_ID from './StringParameterValueComponent.testid';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import { getStashVariablesInStash } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import AddParameterValueButton from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/AddParameterValueButton';
import cleanedFilename from '~/util/cleanedFilename';
import getVariableWithPrimitiveDropdownProps from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/util/getVariableWithPrimitiveDropdownProps';
import getFieldDropdownProps from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/util/getFieldDropdownProps';
import validateStashVariable from '../util/validateStashVariable';

const text = {
  variableLabel: Catalog.flows.pointerVariableLabel,
  fieldLabel: Catalog.flows.pointerFieldLabel,
  requiredField: Catalog.requiredField,
};
type MyProps = {
  value: StringParameterValue;
  isMandatory?: boolean;
  change: WithControlledSwitcherProps<StringParameterValue>;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
  stateValue: StringParameterValue | null;
  primitiveValueCache: StringPrimitiveParameterValue;
};
class StringParameterValueComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let defaultPrimitiveValueCache = emptyStringPrimitiveParameterValue();
    let stateValue: StringParameterValue | null = null;

    if (props.change.controlled) {
      if (props.value.type === PARAMETER_VALUE_TYPE.STRING_PRIMITIVE) {
        defaultPrimitiveValueCache = props.value;
      }
    } else {
      stateValue = props.value;
    }

    this.state = {
      key: null,
      stateValue,
      primitiveValueCache: defaultPrimitiveValueCache,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  getValue = (): StringParameterValue => {
    if (this.props.change.controlled) {
      return this.props.value;
    } else {
      if (this.state.stateValue == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | stateValue is null`,
        );
      }
      return this.state.stateValue;
    }
  };

  setValue = (newValue: StringParameterValue) => {
    if (this.props.change.controlled) {
      this.props.change.onChange(newValue);
    } else {
      this.setState({
        stateValue: newValue,
      });
    }
  };

  setPrimitiveValueCache = (
    newPrimitiveValue: StringPrimitiveParameterValue,
  ) => {
    this.setState({
      primitiveValueCache: newPrimitiveValue,
    });
  };

  validate = (): boolean => {
    if (this.props.change.controlled) {
      if (this.props.value.type === PARAMETER_VALUE_TYPE.STRING_PRIMITIVE) {
        return true;
      } else {
        return validateStashVariable(
          this.props.value.variable,
          this.getPossibleVariables(),
          FLOW_OUTPUT_TYPE.FlowOutputString,
        );
      }
    } else {
      return true;
    }
  };

  getPossibleVariables = () => {
    const { baseActionContext } = this.props;
    const { variableStash } = baseActionContext;

    return getStashVariablesInStash(
      variableStash,
      undefined,
      FLOW_OUTPUT_TYPE.FlowOutputString,
    );
  };

  handleAdded = (
    newPrimitiveValue?: StringPrimitiveParameterValue | null | undefined,
  ) => {
    let newValue = this.getValue();

    if (newPrimitiveValue != null) {
      newValue = newPrimitiveValue;
    }

    if (this.props.change.controlled || !this.props.change.onAdded) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Showed the AddParameterValueButton even though onAdded was not given`,
      );
    } else {
      this.props.change.onAdded(newValue);
    }
  };

  render() {
    const { isMandatory } = this.props;
    const { primitiveValueCache } = this.state;
    const value = this.getValue();
    const shouldShowAddButton = !this.props.change.controlled;

    const {
      variableOptions,
      selectedVariableIdx,
      selectedVariableOption,
      onChangeVariable,
    } = getVariableWithPrimitiveDropdownProps<StringParameterValue>(
      this.getPossibleVariables(),
      value,
      () => {
        this.setValue({ ...primitiveValueCache });
      },
      newVariableName => {
        this.setValue({
          type: PARAMETER_VALUE_TYPE.STRING_POINTER,
          variable: { name: newVariableName, field: null },
        });
      },
    );

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => {
          let inputComponent: ReactElement | null = null;
          let dropdownComponent: ReactElement | null = null;

          if (value != null) {
            if (value.type === PARAMETER_VALUE_TYPE.STRING_PRIMITIVE) {
              if (this.props.change.controlled) {
                inputComponent = (
                  <StringPrimitiveParameterValueComponent
                    value={value}
                    change={{
                      controlled: true,
                      onChange: newPrimitiveValue => {
                        this.setPrimitiveValueCache(newPrimitiveValue);
                        this.setValue(newPrimitiveValue);
                      },
                    }}
                    isMandatory={isMandatory}
                  />
                );
              } else {
                inputComponent = (
                  <StringPrimitiveParameterValueComponent
                    value={value}
                    change={{
                      controlled: false,
                      onAdded: newPrimitiveValue => {
                        this.handleAdded(newPrimitiveValue);
                      },
                      announceChanges: this.props.change.announceChanges,
                    }}
                    isMandatory={isMandatory}
                  />
                );
              }
            } else if (value.type === PARAMETER_VALUE_TYPE.STRING_POINTER) {
              const {
                fieldOptions,
                selectedFieldIdx,
                selectedFieldOption,
                onChangeField,
              } = getFieldDropdownProps(
                selectedVariableOption,
                value,
                (newFieldName: string) => {
                  if (value.variable == null) {
                    throw Error(
                      `${cleanedFilename(
                        __filename,
                      )} | Should not occur | Changing field even though variable has not been chosen yet!`,
                    );
                  }

                  const newValue: StringParameterValue = {
                    type: PARAMETER_VALUE_TYPE.STRING_POINTER,
                    variable: {
                      name: value.variable.name,
                      field: {
                        name: newFieldName,
                      },
                    },
                  };

                  this.setValue(newValue);
                },
                FLOW_OUTPUT_TYPE.FlowOutputString,
                FLOW_OUTPUT_TYPE.FlowOutputString,
              );

              inputComponent = (
                <>
                  <Dropdown
                    dataTestid={TEST_ID.FIELD_DROPDOWN}
                    selectOnlyOptionAutomatically
                    error={
                      showValidation && selectedFieldOption == null
                        ? text.requiredField
                        : ''
                    }
                    label={text.fieldLabel}
                    options={fieldOptions}
                    selectedOptionIdx={selectedFieldIdx}
                    onChange={onChangeField}
                  />
                  <AddParameterValueButton
                    isVisible={shouldShowAddButton}
                    onClick={this.handleAdded}
                  />
                </>
              );

              /**
               * Pointers in the comparison are a legacy option
               * Show the dropdown to change to primitive, but only if the pointer already existed
               */
              dropdownComponent = (
                <Dropdown
                  dataTestid={TEST_ID.VARIABLE_DROPDOWN}
                  selectOnlyOptionAutomatically
                  error={
                    showValidation && selectedVariableOption == null
                      ? Catalog.requiredField
                      : ''
                  }
                  label={text.variableLabel}
                  options={variableOptions}
                  selectedOptionIdx={selectedVariableIdx}
                  onChange={onChangeVariable}
                />
              );
            }
          }

          return (
            <>
              {dropdownComponent}
              {inputComponent}
            </>
          );
        }}
      </FlowContext.Consumer>
    );
  }
}

export default withBaseActionContext<MyProps>(StringParameterValueComponent);
