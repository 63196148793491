import { FlowConditionAppVBOWaardecheckReportDetails } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import describeMultipleFields from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/util/describeMultipleFields';

const describeVBOWaardecheckReportDetailsCondition = (
  condition: FlowConditionAppVBOWaardecheckReportDetails,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  const { fields, allShouldBeTrue } = condition;

  return describeMultipleFields(fields, allShouldBeTrue, describeOptions);
};

export default describeVBOWaardecheckReportDetailsCondition;
