import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { AppBbWaardecheckWhen, AppVboWaardecheckWhen } from '~/graphql/types';
import InputGroupDivider from '~/scenes/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import {
  AppWaardecheckFieldsFragment,
  appWaardecheckState,
  StateId,
} from '../..';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppSectionHeader from '~/scenes/Apps/components/AppSectionHeader';
import WaardecheckIntl from '../../intl';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import MainPreview from '../../components/MainPreview';
import ImageInput from '../../components/ImageInput';
import IntlInputComponent from '../../components/IntlInputComponent';
import { Page } from '../../components/Preview';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import TEST_ID from './index.testid';
import Explanation from '../../components/Explanation';
import styled, { css } from 'styled-components';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
};

const When: React.FC<Props> = ({ data, defaultValues, loading }) => {
  const original = defaultValues.when;
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const updateValue = (
    value: DeepPartial<AppBbWaardecheckWhen | AppVboWaardecheckWhen>,
  ) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        when: value,
      }) as any as typeof prev;
    });
  };

  if (original == null || updated == null) return null;

  return (
    <AppDetailsContainer
      header="Verhuisintentie"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Explanation>
        Op deze pagina wordt de verhuisintentie gevraagd. Personaliseer de
        achtergrondfoto en teksten.
      </Explanation>
      <MainPreview data={updated} page={Page['/when']} />

      <AppEntryItemHeader>{WaardecheckIntl.mainImage.title}</AppEntryItemHeader>
      <Description>{WaardecheckIntl.mainImage.description}</Description>
      <ImageInput
        initialUrl={data.when.image?.url}
        filename={data.__typename + 'when'}
        s3Key={updated?.when.image?.s3key}
        onChange={image => updateValue({ image })}
        dataTestid={TEST_ID.IMAGE}
      />

      <InputGroupDivider large />
      <AppSectionHeader>{WaardecheckIntl.section.text}</AppSectionHeader>
      <Description>{WaardecheckIntl.section.textDescription}</Description>
      <IntlInputComponent
        title={WaardecheckIntl.heading}
        defaultValue={original.heading}
        value={updated.when.heading}
        onChange={heading => updateValue({ heading })}
        dataTestId={TEST_ID.HEADING}
      />
    </AppDetailsContainer>
  );
};

export const Description = styled.div<{}>`
  grid-column: content-start / content-end;
  grid-row: description;
  text-align: left;
  ${({ theme }) => css`
    font-size: ${theme.fontSize('base')};
    color: ${theme.color('text')};
    padding-bottom: ${theme.space('m')};
  `};
`;

export default When;
