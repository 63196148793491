import React from 'react';
import { Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import { PortalData } from '../../../../utils/getElementsFromActions/utils/getPortalCards';
import { handleStyle } from '../CardTemplate';
import { HandlerId } from '../IfElseCard';
import PortalCard from '../PortalCard';

export type Props = {
  data: PortalData;
};

const text = {
  [HandlerId?.trueChild]: '(WAAR)',
  [HandlerId?.falseChild]: '(NIET WAAR)',
  goTo: 'Ga naar',
};

const TargetPortalCard: React.FC<Props> = React.memo(
  ({
    data: { color, sourceId, parentId, label, handlePosition, ifElseChildKey },
  }) => (
    <>
      <PortalCard
        type="target"
        color={color}
        sourceActionId={sourceId}
        targetActionId={parentId}
        tooltipText={`${text.goTo} ${label}`}
      >
        Verbindt uit <strong>{label}</strong>
        {ifElseChildKey ? (
          <>
            <br />
            <Label>{text[ifElseChildKey]}</Label>
          </>
        ) : null}
      </PortalCard>

      <Handle
        type="source"
        position={handlePosition || Position.Bottom}
        style={{ ...handleStyle, stroke: color }}
        isConnectable={false}
      />
    </>
  ),
);

const Label = styled.span<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight('semiBold')};
    font-size: ${theme.fontSize('xs')};
  `,
);

export default TargetPortalCard;
