import {
  ActivityCategory,
  ActivityFieldsFragment,
  ActivityFields_Event_Contact_Email_Fragment,
} from '~/graphql/types';
import { HandledActivityFieldsFragment } from '~/graphql/types.client';
import { assertNeverWithoutThrowing } from '../assertion';
import cleanedFilename from '../cleanedFilename';

const text = {
  sentAnEmail: 'verstuurde een e-mail:',
  taskWasCreated: (title: string, name: string) =>
    `de taak ${title} was aangemaakt door ${name}`,
  contactCreated: 'is als contact aangemaakt',
  note: (note: string) => `notitie toegevoegd: ${note}`,
  call: (call: string) => `telefoongesprek gelogd: ${call}`,
  fundaBrochureRequest: 'brochure aangevraagd via Funda',
  fundaContactRequest: 'contactverzoek via Funda',
  fundaViewingRequest: 'bezichtigingsverzoek via Funda',
  brainbayContactRequest: 'contactverzoek via brainbay waardecheck',
  brainbayAppraisalRequest: 'waardebepaling verzocht via brainbay waardecheck',
  brainbayReport: 'brainbay waardecheck opgevraagd',
  vboContactRequest: 'contactverzoek via Avix waardecheck',
  vboAppraisalRequest: 'waardebepaling verzocht via Avix waardecheck',
  vboReport: 'Avix waardecheck opgevraagd',
  valuationRequest: 'waardebepaling verzocht',
  valuationReport: 'waarderapport opgevraagd',
  zapierEvent: 'Zapier event ontvangen',
  trustooEvent: 'Trustoo aanvraag ontvangen',
  trustooSubmittedEvent: 'Trustoo prijsopgave aangevraagd',
  trustooConversionEvent: 'Trustoo commissie bevestigd',
  hypotheekbondEvent: 'contactverzoek via Hypotheekbond',
  arxGroepContractCreated: 'contract aangevraagd bij ProPropertyPartners',
  arxGroepTransactionCreated: 'transactie aangemaakt via ProPropertyPartners',
  wozComplaintEventCreated: 'WOZ bezwaar gemaakt via WOZ app',
  wozComplaintEventSuccess: 'WOZ bezwaar goedgekeurd',
  envenContactRequest: 'contactverzoek via Energielabel & Duurzaamheidsadvies',
  envenTransaction:
    'transactie ontvangen van Energielabel & Duurzaamheidsadvies',
  susReport: 'duurzaamheidsprofiel aangevraagd',
};

const expressActivity = (activity: HandledActivityFieldsFragment): string => {
  switch (activity.__typename) {
    case 'Event_Contact_New':
      return text.contactCreated;
    case 'Event_Contact_Note':
      return text.note(activity.description || '');
    case 'Event_Contact_Email':
      return getEmailMessage(activity);
    case 'Event_Contact_Call':
      return text.call(activity.description || '');
    case 'Event_Contact_App_ValuationRequest':
      return text.valuationRequest;
    case 'Event_Contact_App_ValuationReport':
      return text.valuationReport;
    case 'Event_Contact_App_Funda_ViewingRequest':
      return text.fundaViewingRequest;
    case 'Event_Contact_App_Funda_ContactRequest':
      return text.fundaContactRequest;
    case 'Event_Contact_App_Funda_BrochureRequest':
      return text.fundaBrochureRequest;
    case 'Event_Contact_App_BBWaardecheck_Report':
      return text.brainbayReport;
    case 'Event_Contact_App_BBWaardecheck_ContactRequest':
      return text.brainbayContactRequest;
    case 'Event_Contact_App_BBWaardecheck_AppraisalRequest':
      return text.brainbayAppraisalRequest;

    case 'Event_Contact_App_VBOWaardecheck_Report':
      return text.vboReport;
    case 'Event_Contact_App_VBOWaardecheck_ContactRequest':
      return text.vboContactRequest;
    case 'Event_Contact_App_VBOWaardecheck_AppraisalRequest':
      return text.vboAppraisalRequest;

    case 'Event_Contact_App_Hypotheekbond':
      return text.hypotheekbondEvent;

    case 'Event_Contact_App_Trustoo':
      return text.trustooEvent;
    case 'Event_Contact_App_TrustooSubmitted':
      return text.trustooSubmittedEvent;
    case 'Event_Contact_App_TrustooConversion':
      return text.trustooConversionEvent;

    case 'Event_Contact_App_Zapier':
    case 'Event_Contact_App_ZapierTrigger':
      return text.zapierEvent;

    case 'Event_Contact_App_ARXGroep_ContractCreated':
      return text.arxGroepContractCreated;
    case 'Event_Contact_App_ARXGroep_TransactionCreated':
      return text.arxGroepTransactionCreated;

    case 'Event_Contact_App_WOZComplaint_ComplaintCreated': {
      return text.wozComplaintEventCreated;
    }

    case 'Event_Contact_App_WOZComplaint_ComplaintSuccess': {
      return text.wozComplaintEventSuccess;
    }

    case 'Event_Contact_App_EnvenRequest': {
      return text.envenContactRequest;
    }
    case 'Event_Contact_App_EnvenTransaction': {
      return text.envenTransaction;
    }

    case 'Event_Contact_App_SustainabilityReport': {
      return text.susReport;
    }

    case 'Task_Contact':
      if (activity?.source?.__typename === 'Actor_Flow') {
        return text.taskWasCreated(activity.title, 'een flow');
      }
      if (
        activity?.source?.__typename === 'Actor_Contact' ||
        activity?.source?.__typename === 'Actor_User'
      ) {
        return text.taskWasCreated(
          activity.title,
          activity.source?.name || 'een gebruiker',
        );
      }
      if (activity?.source?.__typename === 'Actor_App') {
        return text.taskWasCreated(
          activity.title,
          activity.source?.name || 'een app',
        );
      }

      // Doesn't occur
      return '';

    case 'Event_Contact_Generic': {
      return activity.summary || '';
    }

    default:
      assertNeverWithoutThrowing(activity, cleanedFilename(__filename));
      return '';
  }
};

const isEmailEvent = (
  activity: ActivityFieldsFragment,
): activity is ActivityFields_Event_Contact_Email_Fragment =>
  activity.category === ActivityCategory.EventEmail;
export const getEmailMessage = (activity: ActivityFieldsFragment): string => {
  // if statement for autocomplete this will always be true
  if (isEmailEvent(activity)) {
    const { from, subject } = activity;
    const sourceName = from[0].name;
    return `${sourceName ? sourceName : from[0].email} ${
      text.sentAnEmail
    } ${subject}`;
  }

  return '';
};

export default expressActivity;
