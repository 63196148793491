import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterUserFields on Flow_Parameter__User {
    __typename

    ... on Flow_Parameter_User_Primitive {
      userId: value
    }

    ... on Flow_Parameter_User_Pointer {
      variable
      field
    }
  }
`;
