import cleanedFilename from '~/util/cleanedFilename';

/**
 * This code can be loaded outside of a browser, so we lazy initialize this
 */
let canvas;

/**
 * Our variables are rendered as image tags because firefox does not play well with the contenteditable tag:
 *   https://github.com/froala/wysiwyg-editor/issues/3763
 *
 * This will give the base64 string for the editor
 */
export type CreateBase64ImageFunction = (text: string) => string;
export const createBase64ImageForVariableText: CreateBase64ImageFunction = (
  text: string,
) => {
  if (canvas == null) {
    canvas = document.getElementById('drawing-canvas');
  }

  if (!(canvas instanceof HTMLCanvasElement)) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} -> canvasDrawers trying to create an image where the canvas element is not of type canvas`,
    );
  }

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  const canvasHeight = 50;
  ctx.textBaseline = 'bottom';
  ctx.font = `${canvasHeight - 10}px Metropolis`;
  const textWidth = ctx.measureText(text).width;
  canvas.width = textWidth + 20;
  canvas.height = canvasHeight;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.textBaseline = 'bottom';
  ctx.font = `${canvasHeight - 10}px Metropolis`;
  ctx.fillText(text, 10, canvasHeight);

  return canvas.toDataURL();
};

export const measureTextWidth = (text: string, fontSize: number): number => {
  if (canvas == null) {
    canvas = document.getElementById('drawing-canvas');
  }

  if (!(canvas instanceof HTMLCanvasElement)) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} -> canvasDrawers trying to create an image where the canvas element is not of type canvas`,
    );
  }

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  ctx.font = `${fontSize}px Metropolis`;

  return ctx.measureText(text).width;
};
