import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Form, Field } from 'react-final-form';

import { TaskStatus, InsertActivityMutationVariables } from '~/graphql/types';

import { Task } from '~/scenes/Tasks/types';
import { SelectedOption as SelectGroupSelectedOption } from '~/components/Inputs/SelectGroup';
import {
  UpdateTaskMutationFunction,
  InsertActivityMutationFunction,
} from '~/graphql/Mutation';
import { ActivityFieldsFragment } from '~/graphql/types';
import { WithAccountContext } from '~/contexts/AccountContext';

import { InsertActivityMutation } from '~/graphql/Mutation';
import mutation from '~/graphql/mutation/InsertActivity';
import { UpdateTaskMutation } from '~/graphql/Mutation';
import updateTaskMutation from '~/graphql/mutation/UpdateTask';
import Catalog from '~/Catalog';
import { convertDateToServerDateString } from '~/util/date';
import { DateField, TimeField } from '~/components/Forms';
import { FormUtils, Alerts } from '~/components';
import InputGroup from '~/components/Inputs/InputGroup';
import { SelectGroup, Textarea } from '~/components/Inputs';
import CaretDropdownButton from '~/components/CaretDropdownButton';
import Button from '~/components/Button';
import cleanedFilename from '~/util/cleanedFilename';
import { withAccountContext } from '~/contexts/AccountContext';

import TEST_ID from './index.testid';
import { TaskUpdaterContext } from '~/scenes/Tasks/components/TaskList/TaskUpdaterContext';
import { currentTimeHourMinuteString } from '~/util/time';
import { ActivityTabProps } from '../..';

enum EventType {
  Note = 'NOTE',
  PhoneCall = 'PHONECALL',
}

type FormType = {
  eventType?: EventType;
  dueDateDate?: string | null;
  dueDateTime?: string | null;
  description?: string | null;
};
export type MyProps = ActivityTabProps & {
  /** taskDetails data with fields for InsertActivity mutation. If none given only the create button will be shown */
  taskDetails?: Task;

  /** Callback to close task modal */
  handleCloseModal?: () => void;

  /** Contact id for UpdateTask mutation */
  contactId: string;

  /** Called when adding the log was a success and all other sideeffects are handled */
  onSuccess: (newEmailActivity: ActivityFieldsFragment) => void;
};
type Props = WithAccountContext & MyProps;
type State = {
  validate: boolean;
  errorMsg?: string | null;
  resetFn: () => void;
  markAsCompleted: boolean;
};

const text = {
  fields: {
    description: 'Omschrijf je activiteit hier...',
    date: 'Datum',
    type: 'Type',
  },
  validation: {
    required: Catalog.requiredField,
    date: Catalog.invalidDate,
  },
  buttons: {
    submit: 'Opslaan',
    submitAndComplete: 'Opslaan & deze taak voltooien',
    submitAndOpen: 'Opslaan & deze taak heropenen',
  },
  genericErrorMessage: Catalog.genericUnknownErrorMessageShort,
};

class LogActivityTab extends Component<Props, State> {
  static defaultProps = {
    label: 'Log activiteit',
    datatestId: 'log-activity',
  };

  state: State = {
    errorMsg: null,
    validate: false,
    resetFn: () => {},
    markAsCompleted: false,
  };

  formInitialValues = {
    eventType: EventType.PhoneCall,
    dueDateDate: new Date().toISOString(),
    dueDateTime: currentTimeHourMinuteString(),
    description: '',
  };

  validate = (fields: FormType) => {
    const errors: {
      description: undefined | string;
      dueDateDate: undefined | string;
      dueDateTime: undefined | string;
      eventType: undefined | string;
    } = {
      description: undefined,
      dueDateDate: undefined,
      dueDateTime: undefined,
      eventType: undefined,
    };

    if (!fields.eventType) errors.eventType = text.validation.required;

    if (!fields.description) errors.description = text.validation.required;

    if (!fields.dueDateDate) errors.dueDateDate = text.validation.required;

    if (!fields.dueDateTime) errors.dueDateTime = text.validation.required;

    return errors;
  };

  handleUpdateStatusMutationSuccess = () => {
    this.props.handleCloseModal && this.props.handleCloseModal();
  };

  handleMutationStatusError = () => {
    const { resetFn } = this.state;
    resetFn();
    this.handleMutationError();
  };
  handleInsertMutationSuccess = (updateTask: UpdateTaskMutationFunction) => {
    const { resetFn, markAsCompleted } = this.state;
    if (markAsCompleted) {
      return this.toggleTaskStatus(updateTask);
    } else {
      return resetFn();
    }
  };

  handleMutationError = () => {
    this.setState({
      errorMsg: text.genericErrorMessage,
    });
  };

  toggleTaskStatus = (updateTask: UpdateTaskMutationFunction) => {
    const { contactId, taskDetails, accountContext } = this.props;
    const { account } = accountContext;
    if (!contactId || !taskDetails || !account) return;
    const { status, id } = taskDetails;

    const newStatus =
      status === TaskStatus.Open ? TaskStatus.Closed : TaskStatus.Open;

    return updateTask({
      variables: {
        accountId: account.id,
        id,
        update: {
          status: newStatus,
        },
      },
    });
  };

  handleSubmit = (
    formData: FormType,
    insertActivity: InsertActivityMutationFunction,
  ) => {
    const { contactId, taskDetails, onSuccess, accountContext } = this.props;
    const { account } = accountContext;

    if (!contactId || !account) return;

    const loggedDate = convertDateToServerDateString(
      formData.dueDateDate,
      formData.dueDateTime,
    );
    if (!loggedDate) return;
    const relatedTaskId = taskDetails ? taskDetails.id : null;
    const activity = {
      contactId,
      relatedTaskId,
      description: formData.description || '',
      loggedDate,
    };

    let variables: InsertActivityMutationVariables | null = null;
    if (formData.eventType === EventType.Note) {
      variables = {
        accountId: account.id,
        note: activity,
      };
    } else if (formData.eventType === EventType.PhoneCall) {
      variables = {
        accountId: account.id,
        call: activity,
      };
    } else {
      throw Error(
        `${cleanedFilename(
          __filename,
        )}>>handleSubmit | Should not occur | Unknown eventType (${
          formData.eventType
        })`,
      );
    }

    return insertActivity({
      variables,
    }).then(mutationResult => {
      // If anything went wrong our onError would handle it
      if (mutationResult && mutationResult.data) {
        const { data } = mutationResult;
        const { insertActivity } = data;

        onSuccess(insertActivity);

        this.setState({
          validate: false,
        });
      }
    });
  };

  getButtonComponent = (
    disabled: boolean,
    loading: boolean,
    preSubmitCheck: () => void,
    handleSubmit: () => any,
    reset: () => void,
  ) => {
    const { taskDetails } = this.props;

    const baseButtonOnClickAction = () => {
      preSubmitCheck();
      handleSubmit();
      this.setState({
        resetFn: reset,
        markAsCompleted: false,
      });
    };

    if (taskDetails) {
      const taskIsOpen = taskDetails.status === TaskStatus.Open;

      return (
        <CaretDropdownButton
          appearance="secondary"
          disabled={disabled}
          loading={loading}
          dataTestId={TEST_ID.INSERT_ACTIVITY_BUTTON}
          dropdownOptions={[
            {
              label: taskIsOpen
                ? text.buttons.submitAndComplete
                : text.buttons.submitAndOpen,
              onClickAction: () => {
                preSubmitCheck();
                handleSubmit();
                this.setState({
                  markAsCompleted: true,
                });
              },
            },
          ]}
          mainButtonOption={{
            label: text.buttons.submit,
            onClickAction: baseButtonOnClickAction,
          }}
        />
      );
    } else {
      return (
        <Button
          size="medium"
          appearance="secondary"
          disabled={disabled}
          loading={loading}
          data-testid={TEST_ID.INSERT_ACTIVITY_BUTTON}
          onClick={baseButtonOnClickAction}
          label={text.buttons.submit}
        />
      );
    }
  };

  render() {
    const { validate, errorMsg } = this.state;

    return (
      <TaskUpdaterContext.Consumer>
        {({ onTaskChanged }) => (
          <UpdateTaskMutation
            name={cleanedFilename(__filename)}
            mutation={updateTaskMutation}
            onCompleted={data => {
              this.handleUpdateStatusMutationSuccess();

              onTaskChanged(data.updateTask);
            }}
            onError={this.handleMutationStatusError}
          >
            {(updateTask, updateTaskProp) => {
              const updateTaskLoading = updateTaskProp.loading;

              return (
                <InsertActivityMutation
                  name={cleanedFilename(__filename)}
                  mutation={mutation}
                  onCompleted={() =>
                    this.handleInsertMutationSuccess(updateTask)
                  }
                  onError={this.handleMutationError}
                >
                  {(insertActivity, { loading }) => (
                    <Form
                      validate={this.validate}
                      onSubmit={formData =>
                        this.handleSubmit(formData, insertActivity)
                      }
                      initialValues={this.formInitialValues}
                    >
                      {({ handleSubmit, submitting, form, errors }) => {
                        const disabledForm =
                          submitting || loading || updateTaskLoading;
                        const reset = () => setTimeout(form.reset, 0);

                        const preSubmitCheck = () => {
                          const shouldValidate =
                            errors && Object.keys(errors).length > 0;

                          if (shouldValidate) {
                            this.setState({
                              validate: shouldValidate,
                            });
                          }
                        };
                        return (
                          <Container>
                            <form
                              onSubmit={e => {
                                e.preventDefault();
                              }}
                            >
                              <TopInputGroup>
                                <Field name="eventType">
                                  {({ input: { onChange, value } }) => (
                                    <SelectGroup
                                      disabled={disabledForm}
                                      selectedIndex={options.findIndex(
                                        option => option.value === value,
                                      )}
                                      onChange={(
                                        selectedOption: SelectGroupSelectedOption,
                                      ) => {
                                        const { option } = selectedOption;
                                        if (option) {
                                          onChange(option.value);
                                        }
                                      }}
                                      options={options}
                                      dataTestid={TEST_ID.TYPE_FIELD}
                                    />
                                  )}
                                </Field>
                                <InputGroupSpacer />
                                <Field name="dueDateDate">
                                  {({
                                    input: { value, onChange },
                                    meta: { error },
                                  }) => {
                                    const touched =
                                      this.formInitialValues.dueDateDate !==
                                        value && validate;

                                    return (
                                      <DateField
                                        error={FormUtils.showError(
                                          error,
                                          touched,
                                        )}
                                        inputComponentProps={{
                                          // readOnly does not work as expected
                                          // readOnly: true,
                                          disabled: disabledForm,
                                        }}
                                        data-error={error}
                                        value={value}
                                        onChange={onChange}
                                        name="dueDateDate"
                                        data-testid={TEST_ID.DUE_DATE_DATE}
                                        inputComponentType="BORDERLESS"
                                      />
                                    );
                                  }}
                                </Field>
                                <Field name="dueDateTime">
                                  {({
                                    input: { onChange, value },
                                    meta: { error },
                                  }) => (
                                    <TimeField
                                      value={value}
                                      disabled={disabledForm}
                                      error={FormUtils.showError(
                                        error,
                                        validate,
                                      )}
                                      name="dueDateTime"
                                      data-error={error}
                                      onChange={onChange}
                                      data-testid={TEST_ID.DUE_DATE_TIME}
                                      inputComponentType="BORDERLESS"
                                    />
                                  )}
                                </Field>
                              </TopInputGroup>

                              <InputGroup>
                                <Field name="description">
                                  {({ input, meta: { error } }) => {
                                    const hasError = Boolean(
                                      FormUtils.showError(error, validate),
                                    );

                                    return (
                                      <FieldContainer>
                                        <Textarea
                                          disabled={disabledForm}
                                          error={hasError ? error : null}
                                          data-error={hasError}
                                          data-testid={
                                            TEST_ID.DESCRIPTION_TEXTAREA_FIELD
                                          }
                                          placeholder={text.fields.description}
                                          {...input}
                                        />
                                      </FieldContainer>
                                    );
                                  }}
                                </Field>
                              </InputGroup>
                              <BottomRow alignRight={!errorMsg}>
                                {errorMsg && (
                                  <Alerts.SimpleText
                                    type={'error'}
                                    text={errorMsg}
                                  />
                                )}
                                {this.getButtonComponent(
                                  disabledForm,
                                  loading || updateTaskLoading,
                                  preSubmitCheck,
                                  handleSubmit,
                                  reset,
                                )}
                              </BottomRow>
                            </form>
                          </Container>
                        );
                      }}
                    </Form>
                  )}
                </InsertActivityMutation>
              );
            }}
          </UpdateTaskMutation>
        )}
      </TaskUpdaterContext.Consumer>
    );
  }
}

const options = [
  {
    value: EventType.PhoneCall,
    label: 'Telefoongesprek',
    dataTestid: TEST_ID.PHONE_CALL_OPTION,
  },
  {
    value: EventType.Note,
    label: 'Notitie',
    dataTestid: TEST_ID.NOTE_OPTION,
  },
];

type BottomRowProps = {
  alignRight: boolean;
};

const BottomRow = styled.div<BottomRowProps>`
  display: flex;
  align-items: center;
  ${({ theme, alignRight }) => css`
    justify-content: ${alignRight ? 'flex-end' : 'space-between'};
    padding-left: ${theme.space('m')};
  `};
`;

const FieldContainer = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Container = styled.div<{}>`
  display: grid;
  grid-template-rows: min-content auto min-content;
  height: 100%;
`;

const InputGroupSpacer = styled.div<{}>`
  width: 100%;
`;

const TopInputGroup = styled(InputGroup)<{}>`
  margin-top: 0;
`;

export default withAccountContext<MyProps>(LogActivityTab);
