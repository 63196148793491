import type { Ref } from 'react';

import React, { useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import HidableComponent from '~/components/HidableComponent';
import useRepositionToBeInView from '~/components/util/useRepositionToBeInView';

type Props = {
  isVisible: boolean;
  onClickOutside: () => void;
  dataTestid?: string;
  className?: string;
  children: React.ReactNode;

  /** Specify exactly what element the dropdown has to be positioned under. If none given it will be absolutely positioned where it is */
  elementToPlaceUnder?: {
    element: HTMLElement | null;
    placement: 'bottom-left' | 'bottom-right';
  };
};

const DropdownPositionedComponent = ({
  isVisible,
  onClickOutside,
  dataTestid,
  className,
  children,
  elementToPlaceUnder,
}: Props) => {
  const [dropdownComponentRef, dropdownListTransform, recalculatePosition] =
    useRepositionToBeInView(elementToPlaceUnder);

  useLayoutEffect(() => {
    recalculatePosition();
  }, [isVisible, recalculatePosition]);

  const storeAndForwardRef = (ref: null | HTMLElement, parentRef: Ref<any>) => {
    dropdownComponentRef.current = ref;

    // @ts-ignore
    parentRef.current = ref;
  };

  if (isVisible) {
    return (
      <HidableComponent onClickOutside={onClickOutside}>
        {ref => (
          <ChildContainer
            className={className}
            data-testid={dataTestid || null}
            translateX={dropdownListTransform.x}
            translateY={dropdownListTransform.y}
            ref={r => storeAndForwardRef(r, ref)}
          >
            {children}
          </ChildContainer>
        )}
      </HidableComponent>
    );
  }
  return null;
};

type ChildContainerProps = {
  translateX: number;
  translateY: number;
};
const ChildContainer = styled.div<ChildContainerProps>`
  position: absolute;

  ${({ translateX, translateY, theme }) => css`
    transform: translateX(${translateX}px) translateY(${translateY}px);
    z-index: ${theme.z('dropdown')};
  `};
`;

export default DropdownPositionedComponent;
