import { FlowFieldsFragment } from '~/graphql/types';
import { Flow } from '~/scenes/Automation/Flows/types.flow';

export default (item: FlowFieldsFragment): Flow => {
  const { id, _v, accountId, enabled, name, maximumFlowRun, Actions } = item;

  return {
    id,
    _v,
    accountId,
    name,
    enabled,
    maximumFlowRun,
    initialActionFragments: Actions,
  };
};
