import { NumberConditionField } from '../types.flow';
import { Flow_ConditionPrimitive__Number__Input } from '~/graphql/types';

import { FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION } from './constants';
import { asNumberParameterValueInput } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

export default (
  field: NumberConditionField,
): Flow_ConditionPrimitive__Number__Input => {
  const { operation } = field;

  if (operation == null || operation.value == null) {
    throw new Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | Validation should not allow a condition field without operation`,
    );
  }

  let operationObject;
  const value = asNumberParameterValueInput(operation.value);
  const negate = operation.negate;

  switch (operation.type) {
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.EQUALS:
      operationObject = {
        Eq: {
          value: {
            ...value,
          },
          negate,
        },
      };
      break;
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.LESS_THAN:
      operationObject = {
        Lt: {
          value: {
            ...value,
          },
          negate,
        },
      };
      break;
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.GREATER_THAN:
      operationObject = {
        Gt: {
          value: {
            ...value,
          },
          negate,
        },
      };
      break;
    default:
      assertNever(operation, cleanedFilename(__filename));
  }

  return {
    Number: operationObject,
  };
};
