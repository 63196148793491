import { FlowParameterFileFieldsFragment } from '~/graphql/types';
import { FilePrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';

export default (
  fileParameter: FlowParameterFileFieldsFragment,
): FilePrimitiveParameterValue => {
  if (fileParameter.__typename !== PARAMETER_VALUE_TYPE.FILE_PRIMITIVE) {
    throw new Error(
      `Should not occur: >>asFilePrimitiveParameterValue with a non primitive type: ${fileParameter.__typename}`,
    );
  }

  return {
    type: PARAMETER_VALUE_TYPE.FILE_PRIMITIVE,
    contentType: fileParameter.contentType || null,
    filename: fileParameter.filename,
    s3key: fileParameter.s3key,
    contentLength: fileParameter.contentLength,
  };
};
