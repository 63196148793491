import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { TemplateStringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { GetTemplateComponentReturnType } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/TemplateString/types.flow';

import HTMLEditor from '~/components/HTMLEditor/HTMLEditor';
import InputContainer from '~/components/Inputs/TagListInputs/components/InputContainer';
import { UnalignedButton } from '~/components/Buttons';
import { INPUT_ELEMENT_BORDER } from '~/components/Inputs/InputElement';
import InputGroupElement from '~/components/Inputs/InputGroupElement';
import { FloatingLabel } from '~/components';
import { calculateSpaceInsideComponent } from '~/components/util/getSizeCalculation';
import TemplateStringParameterHTMLHandler from './components/TemplateStringParameterHTMLHandler';
import InsertOrUpdateModal from './components/InsertOrUpdateModalHandler';
import TEST_ID from './TemplateStringParameterValueComponent.testid';
import Icon from '~/components/Icon';

export type BaseTemplateStringParameterValueComponentProps = {
  initialTemplateStringValue: TemplateStringParameterValue;

  /** Error message to show, will replace the label */
  errorMessage: string | null;

  /** The label of the field, shown at the top */
  label?: string;

  /** The component will call the function with a function that can be called to get the latest template string parameter */
  setGetTemplateComponent: (fn: () => GetTemplateComponentReturnType) => any;

  /** Function the component will call when the html has changed */
  announceChanges: () => void;

  /** Called when blurring */
  onBlur?: () => void;
  dataTestid?: string;
};
type TemplateStringParameterValueComponentProps =
  BaseTemplateStringParameterValueComponentProps & {
    /** If enters should be blocked (will ensure a single line of text) */
    singleLine?: boolean;

    /** If it should render as a field with multiple lines */
    textarea?: boolean;

    /** If the resulting templatestring should keep the html */
    keepHtml?: boolean;
  };
const TemplateStringParameterValueComponent = ({
  initialTemplateStringValue,
  textarea,
  label,
  announceChanges,
  setGetTemplateComponent,
  keepHtml,
  singleLine,
  dataTestid,
  errorMessage,
  onBlur,
}: TemplateStringParameterValueComponentProps) => {
  const hasError = errorMessage != null && errorMessage.length > 0;
  let labelComponent: ReactElement | null = null;
  if (hasError || label != null) {
    labelComponent = (
      <FloatingLabel
        error={errorMessage}
        data-testid={TEST_ID.LABEL}
        data-error={errorMessage}
      >
        {errorMessage || label}
      </FloatingLabel>
    );
  }

  return (
    <TemplateStringParameterHTMLHandler
      initialInlineAttachments={null}
      initialTemplateString={initialTemplateStringValue}
      onVariableInsertedOrUpdated={announceChanges}
      setGetTemplateComponent={setGetTemplateComponent}
      options={{ stripHtml: keepHtml !== true }}
    >
      {({
        initialHtml,
        registerHTMLEditorInteractionFunctions,
        hasChanged,
        refreshHtml,
        variableUpdaterId,
        getTemplateString,
        insertOrUpdateVariable,
      }) => (
        <InsertOrUpdateModal
          variableUpdaterId={variableUpdaterId}
          getTemplateStringValue={() => getTemplateString().templateString}
          onSaveFunction={insertOrUpdateVariable}
        >
          {({ toggleVariableFunction }) => (
            <>
              {labelComponent}
              <ComplexStringInputContainer data-testid={dataTestid}>
                <StyledInputContainer hasError={hasError}>
                  <HTMLEditor
                    initialHtml={initialHtml}
                    onBlur={() => {
                      if (hasChanged()) {
                        announceChanges();
                      }

                      if (onBlur) {
                        onBlur();
                      }
                    }}
                    onChange={() => {
                      if (hasChanged()) {
                        announceChanges();
                      }
                    }}
                    onExternalVariableAdded={() => {
                      refreshHtml();
                    }}
                    hasError={false}
                    textarea={textarea}
                    singleLine={singleLine}
                    hideToolbar
                    registerInteractionFunctions={
                      registerHTMLEditorInteractionFunctions
                    }
                    componentVariableUpdaterId={variableUpdaterId}
                  />
                </StyledInputContainer>
                <StyledButton
                  lightBackground
                  subPrimary
                  data-testid={TEST_ID.PERSONALISE_BUTTON}
                  onClick={() => {
                    toggleVariableFunction();
                  }}
                >
                  <Icon name="user" />
                </StyledButton>
              </ComplexStringInputContainer>
            </>
          )}
        </InsertOrUpdateModal>
      )}
    </TemplateStringParameterHTMLHandler>
  );
};

const ComplexStringInputContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  position: relative;
`;

type StyledInputContainerProps = {
  hasError?: boolean;
};
const StyledInputContainer = styled(InputContainer)<StyledInputContainerProps>`
  flex-grow: 1;

  border-right: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  ${({ theme, hasError }) => {
    if (hasError) {
      return css`
        border-color: ${theme.color('danger')};
      `;
    }

    return null;
  }}
`;

const StyledButton = styled(UnalignedButton)<{}>`
  ${({ theme }) => css`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    display: flex;
    flex-direction: column;
    align-self: stretch;

    padding: calc(
      ${calculateSpaceInsideComponent(theme, false, false)}px -
        ${INPUT_ELEMENT_BORDER}
    );
    border: ${INPUT_ELEMENT_BORDER} solid ${theme.color('info')};
  `};

  & > span:first-of-type {
    display: inline-flex;
    margin: 1px 0;
  }
`;

export default InputGroupElement<TemplateStringParameterValueComponentProps>(
  TemplateStringParameterValueComponent,
);
