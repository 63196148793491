import { isEmpty, isNil } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { componentSizes, Size } from '~/styles/constants';
import { SystemSize } from '~/theme';
import arrayToCss from '~/util/arrayToCss';
import Icon, { IconType } from '../Icon';
import JustificationContainer from '../JustificationContainer';
import { Body } from '../Typography/index';
import DropdownListContainer from './components/DropdownListContainer';
import dropdownAppearances from './dropdownAppearances';
import TEST_ID from './index.testid';
import getOptionType from './utils/getOptionType/index';

type OptionBase = {
  label: React.ReactNode;
  icon?: IconType;
  key: string | number;
  onClickAction?: (e?: React.SyntheticEvent<any>) => void;
  isEmptySelectionOption?: boolean;
  styleOptions?: {
    lineAbove?: boolean;
  };

  /** Styling will change based on the type */
  type?: 'DANGER' | 'DISABLED' | null;
  dataTestid?: string;
};
export type Option = OptionBase & {
  payload: any;
};
export type OptionOf<T> = OptionBase & {
  payload: T;
};

export type SelectedOptionOf<T> = {
  option: OptionOf<T>;
  selectedOptionIdx: number;
};
export type SelectedOption = {
  option: Option;
  selectedOptionIdx: number;
};
export type OnChangeFunction = (option: SelectedOptionOf<any>) => void;

export type Appearance = 'outline' | 'borderless' | 'filled' | 'default';

export type Props = {
  dataTestId?: string;

  /** Small, medium or large dropdown, defaults to medium. */
  size?: Size;

  /** Disable Dropdown. */
  disabled?: boolean;

  /** The options to display. */
  options: Array<Option>;

  /** Error, this replaces the label if its set. */
  error?: string | null;

  /** The index of the selected option. */
  selectedOptionIdx?: number | null;

  /** The Label to display above the dropdown. */
  label?: React.ReactNode;

  appearance?: Appearance;

  /** An array of margins for the dropdown base. */
  margin?: Array<SystemSize | null>;

  /** Callback, fired after an option was selected. */
  onChange: OnChangeFunction;

  /** An action to do when clicking outside the dropdown. */
  onClickOutside?: () => void;

  /** If the dropdown should initially select the only option if nothing is selected. */
  selectOnlyOptionAutomatically?: boolean;

  /** If the first option should be selected when nothing is selected, defaults to true. */
  withDefaultFirstOption?: boolean;

  /** Loading state for dropdown list. */
  loading?: boolean;

  placeholder?: string;

  /** Tab index order */
  tabIndex?: string;
};

const DEFAULT_LABEL = 'Kies een optie';

const Dropdown: React.FC<Props> = ({
  dataTestId,
  size = 'medium',
  disabled = false,
  options,
  error,
  selectedOptionIdx,
  onChange,
  appearance = 'default',
  label,
  margin = [null, null, null, null],
  onClickOutside,
  selectOnlyOptionAutomatically,
  withDefaultFirstOption = true,
  loading,
  placeholder,
  tabIndex,
  ...rest
}) => {
  const [dropdownListOpen, setDropdownListOpen] = useState<boolean>(false);
  const labelMessage = error || label;

  const errorInOption = getOptionType(options, selectedOptionIdx) === 'DANGER';
  const hasError = (!isNil(error) && !isEmpty(error)) || errorInOption;

  const dropdownBaseRef = useRef<HTMLDivElement | null>(null);
  const dropdownWidth: number | undefined =
    dropdownBaseRef.current?.clientWidth;

  useEffect(() => {
    if (
      selectOnlyOptionAutomatically &&
      options.length === 1 &&
      (selectedOptionIdx == null || selectedOptionIdx < 0) &&
      onChange
    ) {
      onChange({ option: options[0], selectedOptionIdx: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const onClose = () => setDropdownListOpen(false);

  const selectedOption =
    selectedOptionIdx != null ? options[selectedOptionIdx] : null;

  const labelToShow =
    selectedOption?.label ?? label ?? placeholder ?? DEFAULT_LABEL;

  return (
    <Container direction="column" margin={margin} data-testid={dataTestId}>
      {labelMessage && (
        <Label
          error={hasError}
          $size={size}
          data-testid={
            hasError ? TEST_ID.DROPDOWN_ERROR : TEST_ID.DROPDOWN_LABEL
          }
          withoutMargin
        >
          {labelMessage}
        </Label>
      )}
      <DropdownBase
        {...rest}
        tabIndex={tabIndex ? parseInt(tabIndex) : undefined}
        ref={dropdownBaseRef}
        data-testid={TEST_ID.DROPDOWN_TOGGLE}
        data-objectid={selectedOption && selectedOption.key}
        $size={size}
        appearance={appearance}
        disabled={disabled}
        error={hasError}
        isOpen={dropdownListOpen}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          !disabled && setDropdownListOpen(!dropdownListOpen);
        }}
      >
        <OptionLabel $size={size}>{labelToShow}</OptionLabel>
        <Icon name="chevron" strokeWidth={3} flipX={dropdownListOpen} />
      </DropdownBase>

      <DropdownListContainer
        onClose={onClose}
        dropdownWidth={dropdownWidth}
        withDefaultFirstOption={withDefaultFirstOption}
        selectedOptionIdx={selectedOptionIdx}
        options={options}
        loading={loading}
        onChange={option => {
          onChange(option);
          onClose();
        }}
        dropdownListOpen={dropdownListOpen}
        onClickOutside={() => {
          onClose();
          onClickOutside && onClickOutside();
        }}
      />
    </Container>
  );
};

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    cursor: pointer;
    width: 100%;
    line-height: ${theme.lineHeight('s')};
  `,
);

const Label = styled(Body)<{ error?: boolean; $size?: Size }>(
  ({ theme, error, $size = 'medium' }) => css`
    width: 100%;
    margin-bottom: ${theme.space('xxxs')};
    font-size: ${theme.fontSize(componentSizes[$size].fontSize)};
    font-weight: ${theme.fw('semiBold')};
    color: ${error ? theme.color('danger') : theme.color('text')};
  `,
);

type BaseDropdownProps = {
  $size: Size;
  disabled?: boolean;
  error?: boolean;
  appearance: Appearance;
  isOpen?: boolean;
};

const OptionLabel = styled.span<{ $size: Size }>(
  ({ theme, $size }) => css`
    margin-right: ${theme.space('xxs')};
    font-weight: ${theme.fontWeight('regular')};
    font-size: ${theme.fontSize(componentSizes[$size].fontSize)};
  `,
);

const DropdownBase = styled.div<BaseDropdownProps>(
  ({ theme, $size, disabled, error, appearance, isOpen }) => {
    const colorAndBackground =
      error && isOpen
        ? dropdownAppearances[appearance].error.hover
        : error
        ? dropdownAppearances[appearance].error.base
        : disabled
        ? dropdownAppearances[appearance].disabled.base
        : isOpen
        ? dropdownAppearances[appearance].main.hover
        : dropdownAppearances[appearance].main.base;

    const hoverStyles =
      !disabled &&
      (error
        ? dropdownAppearances[appearance].error.hover
        : dropdownAppearances[appearance].main.hover);

    const primaryAppearance = dropdownAppearances['default'];
    const iconColorWhenPrimary =
      error && isOpen
        ? primaryAppearance.error.hover?.color
        : error
        ? primaryAppearance.error.base.color
        : disabled
        ? primaryAppearance.disabled.base.color
        : theme.color('primary', 'light');

    return css`
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      user-select: none;
      font-weight: ${theme.fw('semiBold')};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};

      border-radius: ${theme.getTokens().border.radius.s};
      padding: ${arrayToCss(componentSizes[$size].padding, theme)};

      ${appearance === 'borderless' &&
      css`
        padding-left: 0;
      `};

      transition: all 0.3s, color 0.3s;

      ${colorAndBackground}

      svg {
        color: ${appearance === 'default' ? iconColorWhenPrimary : 'inherit'};
        font-size: ${theme.fontSize(componentSizes[$size].fontSize)};
      }

      &:hover {
        ${hoverStyles}
      }
    `;
  },
);

export default Dropdown;
