import { safeFormattedDate, convertServerDateStringToDate } from '~/util/date';
import asString from '../asString';

/**
 * Return contact info as blocks
 * @param {string} x - Example argument
 * keywords:
 */
const getContactInfo = ({
  name,
  email,
  phone,
  dateOfBirth,
}: {
  name: string;
  email: string;
  phone: string | undefined | null;
  dateOfBirth?: string | null;
}) => [
  {
    __typename: 'MarkdownBlock',
    value: '## Contactgegevens',
  },
  {
    __typename: 'TextBlock',
    label: 'Naam',
    value: name,
  },
  {
    __typename: 'TextBlock',
    label: 'E-mailadres',
    value: email,
  },
  {
    __typename: 'TextBlock',
    label: 'Telefoonnummer',
    value: asString(phone),
  },
  dateOfBirth
    ? {
        __typename: 'TextBlock',
        label: 'Geboortedatum',
        value: safeFormattedDate(convertServerDateStringToDate(dateOfBirth)),
      }
    : {},
];
export default getContactInfo;
