import { RenderElementProps } from 'slate-react';
import Image from './Image';
import Div from './DIVelement';
import BlockQuote from './BlockQuote';
import OrderedList from './OrderedList';
import UnorderedList from './UnorderedList';
import ListItem from './ListItem';
import LinkElement from './LinkElement';
import { CustomElement } from '../../types';
import Span from './Span';
import GenericHtmlElement from './GenericHtmlElement';
import VariableElement from './VariableElement';
import Paragraph from './Paragraph';
import ELEMENTS from './elementsEnum';

type ElementProps = {
  renderComponent: (props: RenderElementProps) => JSX.Element;
  nodeName?: string;
  serialize: (node?: CustomElement, children?: any) => string;
  deserialize?: (el?: Node) => void;
};

const getElements = (customElements: Array<ELEMENTS> = []) => {
  const obj: {
    [key in ELEMENTS]?: ElementProps;
  } = {
    [ELEMENTS.BLOCKQUOTE]: BlockQuote,
    [ELEMENTS.DIV]: Div,
    [ELEMENTS.PARAGRAPH]: Paragraph,
    [ELEMENTS.GENERIC_HTML_ELEMENT]: GenericHtmlElement,
    [ELEMENTS.LI]: ListItem,
    [ELEMENTS.LINK]: LinkElement,
    [ELEMENTS.OL]: OrderedList,
    [ELEMENTS.UL]: UnorderedList,
    [ELEMENTS.SPAN]: Span,
  };

  /** Add custom elements that you want to render in the editor */
  if (customElements.length > 0) {
    customElements.forEach(elName => {
      switch (elName) {
        case ELEMENTS.VARIABLE:
          obj[ELEMENTS.VARIABLE] = VariableElement;
          break;
        case ELEMENTS.IMAGE:
          obj[ELEMENTS.IMAGE] = Image;
          break;
        case ELEMENTS.DH_IMAGE:
          obj[ELEMENTS.DH_IMAGE] = Image;
          break;
        default:
          return;
      }
    });
  }

  return obj;
};

export default getElements;
