import React from 'react';
import { animated } from 'react-spring';
import styled, { css, useTheme } from 'styled-components';
import Icon from '~/components/Icon';
import JustificationContainer from '~/components/JustificationContainer';
import useCollapseAnimation from '~/hooks/useCollapseAnimation';

import { BaseColor, SystemColorPalette } from '~/theme/System/tokens';

export type Props = {
  dataTestId?: string;
  header: React.ReactNode;
  variant?: keyof SystemColorPalette;
  isOpen: boolean;
  disabled?: boolean;
  onToggle: () => void;
};

const CollapsibleChevronBlock: React.FC<Props> = ({
  dataTestId,
  header,
  variant = 'primary',
  children,
  isOpen,
  disabled,
  onToggle,
  ...rest
}) => {
  const theme = useTheme();

  const { innerRef, outer, inner } = useCollapseAnimation({
    isOpen,
  });

  return (
    <Container
      data-testid={dataTestId}
      {...rest}
      color={disabled ? 'grey' : variant}
    >
      <StyledJustificationContainer
        justification="space-between"
        align="center"
      >
        {header}

        <Chevron
          name="chevron"
          strokeWidth={2.5}
          color={theme.color(disabled ? 'grey' : variant)}
          onClick={() => !disabled && onToggle && onToggle()}
          flipX={isOpen}
          disabled={disabled}
        />
      </StyledJustificationContainer>

      <animated.div
        style={{
          opacity: outer.opacity,
          height: outer.height,
          overflow: 'hidden',
        }}
      >
        <ChildrenContainer ref={innerRef} style={{ y: inner.y }}>
          {children}
        </ChildrenContainer>
      </animated.div>
    </Container>
  );
};

const Container = styled.div<{ color: BaseColor }>`
  ${({ color, theme }) => css`
    margin: ${theme.space('xxxs')} 0;
    background-color: ${theme.color(color, 'translucent')};
    padding: ${theme.space('m')};
    border-radius: ${theme.getTokens().border.radius.s};

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

const StyledJustificationContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    ${theme.mq.lessThan('mobile')`
      flex-direction: row;
    `};
  `,
);

const ChildrenContainer = styled(animated.div)<{}>`
  ${({ theme }) => css`
    padding: ${theme.space('m')} 0 ${theme.space('base')} ${theme.space('xxl')};

    ${theme.mq.lessThan('mobile')`
      padding: ${theme.space('s')};
    `}
  `}
`;

const Chevron = styled(Icon)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  /** Because of use spring's initial value it is not accessible without z-index */
  z-index: 100;

  ${({ theme }) => css`
    font-size: ${theme.fs('l')};
  `};
`;

export default CollapsibleChevronBlock;
