import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../Icon';
import icons from '../Icon/components';
import { Heading5, Variant } from '../Typography/index';
import JustificationContainer, {
  Justification,
} from '../JustificationContainer';
import useTipBanner from './hooks/useTipBanner';
import { animated } from 'react-spring';
import SlideOpenAnimationContainer from '../SlideOpenAnimationContainer';
import { SystemSize } from '~/theme/System/tokens';
import arrayToCss from '~/util/arrayToCss';

export type TipId =
  | 'realworks-connections-tip'
  | 'export-contacts-step-tip'
  | 'import-contacts-step-tip'
  | 'setup-step-id'
  | 'zapier-details-tip'
  | 'events-tip'
  | 'triggers-tip'
  | 'add-event-tip'
  | 'add-trigger-tip'
  | 'field-editing-section-tip'
  | 'contact-segments-tip'
  | 'trustoo-add-token'
  | 'generic-wizard';

export type Props = {
  id: TipId;
  dataTestId?: string;
  children: React.ReactNode;
  icon?: keyof typeof icons;
  headerText?: string;
  withoutBorder?: boolean;
  /* Can the user dismiss this tip? defaults to true */
  dismissible?: boolean;
  margin?: Array<SystemSize | null>;
  /** Justify dismiss tip button */
  dismissButtonPosition?: Justification;
  /** Font size */
  fontSize?: SystemSize;
};

const text = {
  dismissTip: 'Sluit deze tip',
};

const TipBanner: React.FC<Props> = ({
  dataTestId,
  id,
  children,
  icon = 'question-mark',
  headerText = 'Hulp nodig?',
  withoutBorder = false,
  dismissible = true,
  dismissButtonPosition = 'end',
  margin = [null, null, 'm', null],
  fontSize = 'base',
  ...rest
}) => {
  const [showTip, onDismiss] = useTipBanner(id);

  return (
    <SlideOpenAnimationContainer isOpen={showTip} useOverflowHidden={true}>
      {ref => (
        <Container
          $margin={margin}
          data-testid={dataTestId}
          $withoutBorder={withoutBorder}
          {...rest}
          ref={ref}
        >
          <TipHeader align="center" justification="start">
            <StyledIcon name={icon} strokeWidth={3} />
            <HeaderText withoutMargin variant={Variant.primary}>
              {headerText}
            </HeaderText>
          </TipHeader>

          <ChildrenContainer $size={fontSize}>{children}</ChildrenContainer>

          {dismissible && (
            <JustificationContainer justification={dismissButtonPosition}>
              <DismissTip onClick={onDismiss} $size={fontSize}>
                {text.dismissTip}
              </DismissTip>
            </JustificationContainer>
          )}
        </Container>
      )}
    </SlideOpenAnimationContainer>
  );
};

const SVG_SIZE = '1.2em';
const HEADER_TOP_PADDING = '1px';

const Container = styled(animated.div)<{
  $withoutBorder: boolean;
  $margin?: Array<SystemSize | null>;
}>(
  ({ theme, $withoutBorder, $margin = [null, null, 'base', null] }) => css`
    border: ${$withoutBorder ? 'none' : `1px solid ${theme.color('grey')}`};
    padding: ${theme.space('m')};
    transition: all 0.3s ease;
    margin: ${arrayToCss($margin, theme)};
  `,
);

const DismissTip = styled.span<{ $size?: SystemSize }>(
  ({ theme, $size = 'base' }) => css`
    color: ${theme.color('primary', 'light')};
    font-weight: ${theme.fw('semiBold')};
    margin-top: ${theme.space('xxs')};
    font-size: ${theme.fontSize($size)};
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
      color: ${theme.color('primary', 'dark')};
    }
  `,
);

const TipHeader = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight('semiBold')};
    padding-bottom: ${theme.space('xxs')};
  `,
);

const HeaderText = styled(Heading5)<{}>`
  padding-top: ${HEADER_TOP_PADDING};
`;

const ChildrenContainer = styled.span<{ $size?: SystemSize }>(
  ({ theme, $size = 'base' }) => css`
    font-size: ${theme.fontSize($size)};
  `,
);

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    padding-bottom: ${theme.space('xxxs')};
    padding-right: ${theme.space('s')};

    svg {
      height: ${SVG_SIZE};
      width: ${SVG_SIZE};
    }
  `,
);

export default TipBanner;
