import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import { ListItemForDeletion } from '~/components/Modals/components/ListItemCard';
import Button from '~/components/Button';
import { ViewableOffice } from '~/contexts/AccountContext';
import TEST_ID from './index.testid';
import DeleteUserFromAccountModal from '~/components/Modals/DeleteUserFromAccountModal';
import { DeleteInputButton } from '~/components/Buttons';
import useCountdown from '~/util/useCountdown';
import useUserLookup from '~/hooks/useUserLookup';
import {
  ButtonContainer,
  CardElement,
  UserEmailElement,
  UserPhoneElement,
  OfficeCounter,
  OfficeIcon,
  UserNameContainer,
  EnvelopeIcon,
  UserRoleElement,
} from '../UserCard.style';
import {
  SessionHydrationUserFieldsFragment,
  SessionHydrationUserFields_PendingUser_Fragment,
  useResendUserInvitationMutation,
  UserRole,
  UserStatus,
  useUpdateAccountRelationMutation,
} from '~/graphql/types';
import { isPendingStatus } from '~/util/constants';
import useUserRelationToAccount from '~/hooks/useUserRelationToAccount';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useCurrentUser from '~/hooks/useCurrentUser';
import { SelectedOptionOf } from '~/components/Inputs/Dropdown';
import useConfirmModal from '~/components/Alerts/useConfirmModal';
import useUsers from '~/hooks/useUsers';
import useOffices from '~/hooks/useOffices';
import Dropdown from '~/components/Dropdown';
import { isNil } from 'ramda';
import useUserRights from '~/hooks/useUserRights';
import useSessionHydration from '~/hooks/useSessionHydration';
import useViewingModeProps from '~/hooks/useViewingModeProps';

const text = {
  sendInvitationAgain: 'Uitnodiging opnieuw versturen',
  user: 'Gebruiker',
  admin: 'Admin',
  save: 'Opslaan',
  confirmRoleChangeTitle: 'Weet je het zeker?',
  confirmRoleChangeMsg:
    'Je gaat de rol van deze gebruiker wijzigen. Weet je het zeker?',
  confirmRoleChangeMsgForMe:
    'Let op, deze actie kan niet ongedaan worden gemaakt. Weet je het zeker?',
  isLastAccountAdminWarning:
    'De rol kan niet gewijzigd worden omdat deze gebruiker de laatste accountbeheerder is.\nWijs een andere accountbeheerder toe om de rol van deze gebruiker te wijzigen.',
};

type Props = {
  userId: string;
  refetch: () => void;
};

const isPending = (
  user,
): user is SessionHydrationUserFields_PendingUser_Fragment =>
  user?.__typename === 'PendingUser';

const AccountUserCard = ({ userId, refetch }: Props) => {
  const viewingModeProps = useViewingModeProps();
  const [showDeleteUserFromAccountModal, setShowDeleteUserFromAccountModal] =
    useState<Array<ListItemForDeletion> | null>(null);

  const handleDeleteUserFromAccountModalAction = (
    officesList: Array<ListItemForDeletion> | null,
  ) => {
    setShowDeleteUserFromAccountModal(officesList);
  };

  const [, refetchSessionHydration] = useSessionHydration();
  const { id: accountId } = useCurrentAccount();
  const me = useCurrentUser();
  const { update: mayUpdate, delete: mayDelete } = useUserRights({
    category: 'Users',
  });

  const userLookup = useUserLookup(['User', 'PendingUser']) as {
    [userId: string]: SessionHydrationUserFieldsFragment;
  };
  const user = userLookup[userId];
  const offices = useOffices({ userIds: [userId] });
  const relation = useUserRelationToAccount({ accountId: accountId, userId });

  let userDetails: {
    name: string;
    phone: string;
    lastInvitationTime: SessionHydrationUserFields_PendingUser_Fragment['lastInvitationTime'];
    dataTestid: string;
  };
  if (isPending(user)) {
    userDetails = {
      name: user?.email,
      phone: '---',
      lastInvitationTime: user?.lastInvitationTime,
      dataTestid: 'user-list-item-inactive',
    };
  } else {
    userDetails = {
      name: user?.name,
      phone: user?.phone,
      lastInvitationTime: undefined,
      dataTestid: 'user-list-item-active',
    };
  }

  const _isPending = isPending(user) || isPendingStatus(relation?.status);
  const { name, phone, lastInvitationTime, dataTestid } = userDetails;

  const accountAdmins = useUsers({
    statuses: [UserStatus.Active],
    officeIds: [],
  }).filter(user => user.role === UserRole.Admin);

  const lastAccountAdmin = accountAdmins.length === 1 ? accountAdmins[0] : null;
  const userIsLastAccountAdmin =
    accountAdmins.length === 1 && lastAccountAdmin?.id === user?.id;

  const [showConfirmModal, setShowConfirmModal] = useConfirmModal(
    {
      title: text.confirmRoleChangeTitle,
      message:
        user?.id === me?.id
          ? text.confirmRoleChangeMsgForMe
          : text.confirmRoleChangeMsg,
      buttonConfirmTitle: text.save,
    },
    () => {},
    () => {},
    undefined,
    false,
    userId === me.id ? 'destructive' : 'normal',
  );

  const [updateAccountRelation, { loading: userRoleLoading }] =
    useUpdateAccountRelationMutation();

  const onRoleChange = (userId, newRole) =>
    updateAccountRelation({
      variables: {
        accountId,
        userId,
        update: { role: newRole },
      },
    }).then(() => refetch());

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [resendUserInvitation] = useResendUserInvitationMutation({
    onCompleted: () => refetchSessionHydration(),
  });

  const [secondsLeft] = useCountdown(lastInvitationTime, 60);

  const buttonLabel = `${text.sendInvitationAgain} ${
    secondsLeft ? `(${secondsLeft})` : ''
  }`;

  useEffect(() => {
    setButtonDisabled(!isNil(secondsLeft));
  }, [secondsLeft]);

  return (
    <>
      <CardElement
        data-testid={dataTestid}
        isPending={_isPending}
        onClick={() => {
          if (userId === me.id) return navigate('/-/settings/profile');
          if (!_isPending) void navigate(`/-/settings/users/${userId}`);
          return;
        }}
        data-objectid={userId}
      >
        <UserNameContainer data-testid="user-name" {...viewingModeProps}>
          {_isPending && <EnvelopeIcon name="mail" title="Invited User" />}
          {name}
        </UserNameContainer>
        <UserPhoneElement data-testid="user-phone" {...viewingModeProps}>
          {phone}
        </UserPhoneElement>
        <UserEmailElement data-testid="user-email" {...viewingModeProps}>
          {user?.email}
        </UserEmailElement>

        <ButtonContainer>
          {lastInvitationTime && (
            <Button
              ghost
              disabled={buttonDisabled || !mayUpdate}
              onClick={() => {
                setButtonDisabled(true);
                return resendUserInvitation({
                  variables: {
                    userId,
                    accountId,
                  },
                });
              }}
              data-testid={TEST_ID.RESEND_INVITATION_BUTTON}
              label={buttonLabel}
            />
          )}

          {!lastInvitationTime && (
            <UserRoleElement title="Account role">
              <Dropdown
                disabled={
                  !mayUpdate || userIsLastAccountAdmin || userRoleLoading
                }
                selectedOptionIdx={accountRoleOptions.findIndex(
                  role => role.key === relation?.role,
                )}
                onChange={({ option }: SelectedOptionOf<UserRole>) => {
                  if (!option) return;

                  const newRole = option.payload;
                  if (relation?.role === newRole) return;

                  setShowConfirmModal(() => onRoleChange(userId, newRole));
                  showConfirmModal();
                }}
                options={accountRoleOptions}
                dataTestId={TEST_ID.USER_ROLE_DROPDOWN}
              />
            </UserRoleElement>
          )}

          <OfficeCounter>
            <OfficeIcon name="office" />
            <span data-testid="user-officeNumber">{offices.length}</span>
          </OfficeCounter>

          <DeleteInputButton
            dataTestId={TEST_ID.DELETE_USER_BUTTON}
            onClick={() => {
              handleDeleteUserFromAccountModalAction(
                convertOfficesForModal(offices),
              );
            }}
            disabled={!mayDelete}
          />
        </ButtonContainer>
      </CardElement>

      {showDeleteUserFromAccountModal && (
        <DeleteUserFromAccountModal
          list={showDeleteUserFromAccountModal}
          onCancel={() => handleDeleteUserFromAccountModalAction(null)}
          onClose={() => handleDeleteUserFromAccountModalAction(null)}
          userId={userId}
        />
      )}
    </>
  );
};

type RoleOption = { label: string; key: UserRole; payload: UserRole };
const accountRoleOptions: Array<RoleOption> = [
  {
    label: text.user,
    key: UserRole.User,
    payload: UserRole.User,
  },
  {
    label: text.admin,
    key: UserRole.Admin,
    payload: UserRole.Admin,
  },
];

type ConvertOfficesForModalReturnType = {
  id: string;
  img:
    | {
        medium: string;
      }
    | null
    | undefined;
  name: string;
};
const convertOfficesForModal = (
  offices: Array<ViewableOffice>,
): Array<ConvertOfficesForModalReturnType> => {
  const convertedOffices: Array<ConvertOfficesForModalReturnType> = [];

  offices.map(office => {
    const { name, id, img } = office;

    convertedOffices.push({
      id,
      img,
      name,
    });
  });

  return convertedOffices;
};

export default AccountUserCard;
