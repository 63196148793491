import React from 'react';
import styled, { css } from 'styled-components';
import Button, { Props as ButtonProps } from '~/components/Button';
import Input, { Props as InputProps } from '~/components/Input';
import { Size } from '~/styles/constants';
import TEST_ID from './index.testid';

export type Props = InputProps & {
  /** Buttons in the input component */
  buttonActions: Array<ButtonProps>;

  /** Size of the buttons */
  buttonSize?: Size;
};

const InputWithButtons: React.FC<Props> = ({
  buttonActions,
  buttonSize = 'small',
  ...inputProps
}) => (
  <Container>
    <Input {...inputProps} dataTestId={TEST_ID.INPUT}>
      {buttonActions.map(({ icon, appearance, ...rest }) => (
        <StyledButton
          dataTestId={TEST_ID.BUTTON}
          key={`button-${icon}`}
          icon={icon || 'check'}
          appearance={appearance || 'secondary'}
          size={buttonSize}
          {...rest}
        />
      ))}
    </Input>
  </Container>
);

const StyledButton = styled(Button)(
  () => css`
    margin: 4px 4px 4px 0;
  `,
);

const Container = styled.div(
  () => css`
    width: 100%;
    height: 100%;
  `,
);

export default InputWithButtons;
