import React, { useEffect, useState } from 'react';
import {
  ActivityFieldsFragment,
  useGetActivitiesForContactQuery,
  useMutatedActivitySubscription,
} from '~/graphql/types';

import styled, { css } from 'styled-components';
import groupActivities from './utils/groupActivities';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { uniqBy, prop, isNil, filter, keys, values, flatten } from 'ramda';

import CardGroup from './components/CardGroup';
import InfiniteScroll from '../InfiniteScroll';
import { sleep } from '~/util';
import { useRecoilState } from 'recoil';
import { GroupedActivities, activitiesByContactId } from '~/state/activities';
import TaskModal from '~/scenes/Tasks/components/TaskModal';
import { Task } from '~/scenes/Tasks/types';

export type EventTimelineContact = {
  id: string;
  name: string;
  email: string;
};

export type BaseProps = {
  dataTestId?: string;

  /** Contact that activities belong to */
  contact: EventTimelineContact;

  /** Makes the timeline cards disabled (apart from Date labels). Used in Task modal's event timeline */
  disabled?: boolean;
};

type Props = BaseProps & { onOpenTaskModal?: (task: Task | null) => void };
export const FETCH_LIMIT = 20;

const EventTimelineV2: React.FC<Props> = ({
  onOpenTaskModal,
  contact,
  disabled,
}) => {
  const contactId = contact.id;
  const { id: accountId } = useCurrentAccount();
  const [groupedActivities, setGroupedActivities] = useRecoilState(
    activitiesByContactId(contact.id),
  );

  const {
    data: initialActivityData,
    loading,
    fetchMore,
  } = useGetActivitiesForContactQuery({
    variables: {
      accountId,
      contactId,
      limit: FETCH_LIMIT,
      nextToken: null,
    },
  });

  useEffect(() => {
    if (initialActivityData?.getActivitiesForContact.items) {
      const existingActivities =
        initialActivityData.getActivitiesForContact.items.filter(
          activity => !isDeleted(activity),
        );

      setGroupedActivities(
        groupActivities(uniqBy(prop('id'), existingActivities)),
      );
    }
  }, [initialActivityData, setGroupedActivities]);

  const { data: mutatedActivityData } = useMutatedActivitySubscription({
    variables: {
      accountId,
      contactId,
    },
  });

  useEffect(() => {
    if (
      mutatedActivityData &&
      mutatedActivityData?.mutatedActivity &&
      mutatedActivityData?.mutatedActivity.id
    ) {
      const newActivity = mutatedActivityData.mutatedActivity;

      if (isDeleted(newActivity)) {
        const newActivities = keys(groupedActivities).reduce((acc, date) => {
          acc[date] = filter(
            item => item.id !== newActivity?.id,
            groupedActivities[date],
          );

          return acc;
        }, {} as GroupedActivities);
        setGroupedActivities(newActivities);

        return;
      }

      return setGroupedActivities(prev => {
        const existingActivities = flatten(values(prev)).filter(
          activity => activity.id !== newActivity.id,
        );

        const allActivities = [
          ...existingActivities,
          {
            ...newActivity,
            highlighted: true,
          },
        ];

        return groupActivities(uniqBy(prop('id'), allActivities));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutatedActivityData]);

  return (
    <Container $disabled={disabled}>
      <InfiniteScroll
        initialLoad={loading}
        fetchMoreFn={async () => {
          if (isNil(initialActivityData?.getActivitiesForContact.nextToken))
            return;

          await fetchMore({
            variables: {
              nextToken: initialActivityData?.getActivitiesForContact.nextToken,
              limit: FETCH_LIMIT,
            },

            // concatenate old and new entries
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const mutatedActivity = mutatedActivityData?.mutatedActivity;

              if (fetchMoreResult?.getActivitiesForContact) {
                return {
                  __typename: 'Query',
                  getActivitiesForContact: {
                    ...fetchMoreResult?.getActivitiesForContact,
                    items: uniqBy(prop('id'), [
                      ...(mutatedActivity ? [mutatedActivity] : []),
                      ...previousResult.getActivitiesForContact.items,
                      ...fetchMoreResult.getActivitiesForContact.items,
                    ]),
                  },
                };
              }

              return previousResult;
            },
          });

          await sleep(1000);
        }}
        hasMore={!isNil(initialActivityData?.getActivitiesForContact.nextToken)}
      >
        {Object.keys(groupedActivities).map(date => (
          <CardGroup
            onOpenTaskModal={onOpenTaskModal}
            date={date}
            items={groupedActivities[date]}
            contact={contact}
            key={date}
          />
        ))}
      </InfiniteScroll>
    </Container>
  );
};

const isDeleted = (activity: ActivityFieldsFragment): boolean =>
  'deleted' in activity && activity.deleted;

const Container = styled.div<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => css`
    position: relative;

    &:before {
      content: '';
      position: absolute;

      /* Account for the height of the FIRST date label */
      top: 100px;
      left: ${theme.space('l')};
      /** Account for the height of the LAST icon */
      bottom: 100px;
      border-left: 1px solid ${theme.color('grey')};
    }

    ${$disabled &&
    css`
      pointer-events: none;
      opacity: 50%;
      cursor: not-allowed;
    `};
  `,
);

export const EventTimelineWithTaskModal: React.FC<BaseProps> = props => {
  const account = useCurrentAccount();
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false);
  const [taskDetails, setTaskDetails] = useState<Task | null>(null);
  const onOpenTaskModal = (task: Task | null) => {
    if (task) {
      setTaskDetails(task);
      setShowTaskModal(true);
    }
  };
  return (
    <>
      {showTaskModal && (
        <TaskModal
          account={account}
          initialTaskDetails={taskDetails}
          onClose={() => {
            setShowTaskModal(false);
            setTaskDetails(null);
          }}
          selectedInFilterOfficeId={null}
          selectedInFilterUserId={null}
        />
      )}
      <EventTimelineV2 {...props} onOpenTaskModal={onOpenTaskModal} />
    </>
  );
};

export default EventTimelineV2;
