import { FlowParameterBooleanFieldsFragment } from '~/graphql/types';
import { BooleanPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyBooleanPrimitiveParameterValue from './emptyBooleanPrimitiveParameterValue';

export default (
  booleanParameter: FlowParameterBooleanFieldsFragment | null | undefined,
): BooleanPrimitiveParameterValue => {
  if (booleanParameter == null) {
    return emptyBooleanPrimitiveParameterValue();
  }

  if (booleanParameter.__typename !== PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE) {
    throw new Error(
      `Should not occur: >>asBooleanPrimitiveParameterValue with a non primitive type: ${booleanParameter.__typename}`,
    );
  }

  return {
    type: PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE,
    value: booleanParameter.booleanValue,
  };
};
