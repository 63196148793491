import { isEmpty, isNil } from 'ramda';
import { StyleBlockFE } from '../../types';
import { StyleBlock } from '~/graphql/types';

const isFEItem = (block?: StyleBlock | StyleBlockFE): block is StyleBlockFE =>
  (block as StyleBlockFE) != null;

/**
 * Nest the blocks we get from backend
 *
 * keywords: event body, convertBlocks, backendShape, to frontendShape
 */
const addBlocks = (
  blocks: Array<StyleBlock | StyleBlockFE>,
  parentId: string,
): StyleBlockFE | null => {
  const item = blocks.find(item => item.id === parentId);

  if (!isFEItem(item)) {
    return null;
  }

  if (!item) {
    return null;
  }

  // childrenIds property is passed but value is null, undefined or empty string
  if (
    'childrenIds' in item &&
    (isNil(item.childrenIds) || isEmpty(item.childrenIds))
  ) {
    return item;
  }

  // @ts-ignore childrenIds property is not passed at all
  if (!item.childrenIds) {
    return item;
  }

  return {
    ...item,
    // @ts-ignore blocks cannot be null because we filter null values
    blocks:
      'childrenIds' in item
        ? item.childrenIds
            .map(childId => addBlocks(blocks, childId))
            .filter(block => !isNil(block))
        : [],
  };
};

/**
 * Creates the nested shape for the frontend
 */
const convertToFrontend = (body: Array<StyleBlock>): Array<StyleBlockFE> => {
  const rootIds: Array<string> = [];

  body.forEach(b => {
    const idAfterEventId = b.id?.split('_')?.[1];
    if (idAfterEventId?.startsWith('root')) {
      rootIds.push(b.id);
    }
  });

  const nestedBlocks: Array<StyleBlockFE> = [];

  rootIds.forEach(rootId => {
    const block = addBlocks(body, rootId);
    if (block) nestedBlocks.push(block);
  });

  return nestedBlocks;
};

export default convertToFrontend;
