import React, { useState } from 'react';

import {
  GetZapierEventsQuery as GetZapierEventsQueryProps,
  GetZapierEventsQueryVariables,
} from '~/graphql/types';
import cleanedFilename from '~/util/cleanedFilename';
import TEST_ID from './AppZapierEventsDropdown.testid';
import { useGetZapierEventsQuery } from '~/graphql/types';

import Dropdown, { Option, SelectedOption } from './index';
import InputGroup from '../InputGroup';
import Catalog from '~/Catalog';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';

const text = {
  label: 'Selecteer een inkomende koppeling',
  noSelectionLabel: 'Iedere inkomende koppeling',
};

type Props = {
  outputLoading: boolean;
  onChange: (option: SelectedOption) => void;
  onDropdownOpened: () => void;
  /** Variables for the query */
  variables?: GetZapierEventsQueryVariables;
  /** Dropdown list item's id */
  selectedOptionId: string | null;
};

const AppZapierEventsDropdown: React.FC<Props> = ({
  outputLoading,
  onChange,
  onDropdownOpened,
  variables,
  selectedOptionId,
}) => {
  const [dropdownListOpen, setDropdownListOpen] = useState(false);

  const {
    data,
    loading: queryLoading,
    error,
    networkStatus,
    refetch,
  } = useGetZapierEventsQuery({
    variables,
    skip: selectedOptionId == null && dropdownListOpen === false,
    displayName: __filename,
  });

  const hasNoSelectionOption = !error;

  let options: Array<Option> = [];
  if (hasNoSelectionOption) {
    options = [
      {
        key: 'no-selection',
        label: text.noSelectionLabel,
        payload: null,
      },
    ];
  }

  if (data) {
    options = [...options, ...convertDataToOptions(data)];
  }
  const selectedOptionKey = selectedOptionId != null ? selectedOptionId : null;
  const selectedOption = options.findIndex(
    option => option.payload === selectedOptionKey,
  );

  const errorText = error
    ? Catalog.genericUnknownErrorMessage
    : selectedOptionKey != null && selectedOption == -1
    ? Catalog.requiredField
    : '';

  return (
    <InputGroup>
      <Dropdown
        onDropdownOpened={() => {
          setDropdownListOpen(true);
          onDropdownOpened();
          return refetch({ accountId: variables?.accountId });
        }}
        options={options}
        onChange={onChange}
        selectedOptionIdx={selectedOption}
        error={errorText}
        loading={queryLoading || outputLoading}
        disabled={!!error}
        dataTestid={TEST_ID.ZAPIER_EVENTS_DROPDOWN}
        label={text.label}
        hideLabelOnTop={
          selectedOptionId != null && isInitialLoadStatus(networkStatus)
        }
      />
    </InputGroup>
  );
};

const convertDataToOptions = (
  data: GetZapierEventsQueryProps,
): Array<Option> => {
  const { getZapierEvents } = data;

  const newArray = getZapierEvents.map(item => {
    if (!item) {
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | function convertDataToOptions cannot have item as null`,
      );
    }

    const { id, name } = item;

    return {
      label: name,
      key: id,
      payload: id,
    };
  });

  return [...newArray];
};

export default AppZapierEventsDropdown;
