import { createGlobalStyle, css, keyframes } from 'styled-components';
import { rgba } from 'polished';

import { WithTheme } from '../';

import { calculateSpaceInsideComponent } from '~/components/util/getSizeCalculation';
import { INPUT_ELEMENT_BORDER } from '~/components/Inputs/InputElement';

/**
 * The css and fonts for froala
 */
import froalaTheme from './froalaTheme';
export const DH_UPLOADING_IMAGE_CLASS = 'dh-uploading-image';

const froalaLoadingImage = keyframes`
  0% { filter: blur(1px) grayscale() opacity(45%); }
  50% { filter: blur(1px) grayscale() opacity(70%); }
  100% { filter: blur(1px) grayscale() opacity(45%); }
`;

type GlobalStyleProps = WithTheme;
export default createGlobalStyle<GlobalStyleProps>`
  *, *:after, *:before {
    box-sizing: border-box;
  }

  *:focus { outline: none; }

  html {
    overflow-y: scroll;
  }

  html, body {
    ${({ theme }) => css`
      font-family: 'Metropolis', 'Helvetica Neue', 'Helvetica', 'Arial',
        sans-serif;
      color: ${theme.color('text')};
      font-size: ${theme.getTokens().type.baseFontSize};
      line-height: ${theme.lineHeight('base')};
    `}
    margin: 0;
    padding: 0;
  }

  #___gatsby, #modal-portal {
    ${({ theme }) => css`
      font-size: ${theme.getTokens().type.baseFontSize};
    `}
  }

  button, input {
    margin: 0;
    ${({ theme }) => css`
      line-height: ${theme.lineHeight('s')};
    `}
  }

   optgroup, select, textarea  {
    margin: 0;
  }

  input, select, textarea, button {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }

  /** Needed to fix min-height issue as the minHeight setting is not working */
  .fr-wrapper.show-placeholder {
    min-height: 150px;
  }
  .dh-inline .fr-wrapper.show-placeholder {
    min-height: 2.7rem;
  }

  /*🙈  We target .fr-view elements inside .fr-wrapper to win specificity wars with Froala */
  .fr-wrapper {
    .fr-view table td, .fr-view table th { border: none; }
  }

  /*** Froala ***/
  ${froalaTheme}

  /** Loading styles for image */
  .${DH_UPLOADING_IMAGE_CLASS} {
    background: #ccc;
    /** when froala still uploading show this */
    animation: ${froalaLoadingImage} 3s infinite;
  }

  /** The dutch language for froala does not cover this error and custom popups don't work so use css */
  .fr-image-progress-bar-layer.fr-error {
    visibility: hidden;
  }
  .fr-image-progress-bar-layer.fr-error:after {
    content: 'Kan de afbeelding niet laden. Controleer of het bestand een afbeelding is met een maximale grootte van 20Mb.';
    display: block;
    visibility: visible;
  }
  .fr-image-progress-bar-layer.fr-error .fr-message {
    display: none;
  }
  .fr-image-progress-bar-layer.fr-error .fr-action-buttons {
    display: none !important;
  }

  /** Hide link list button if it is defined that the list is empty */
  .dh-link-list-empty button[data-cmd="linkList"] {
    display: none !important;
  }

  /** EmailEditor specific css */
  .froala-email-editor .fr-toolbar {
    border: 0 !important;
  }

  .froala-email-editor .fr-wrapper {
    border: 0 !important;
  }

  .froala-email-editor p {
    margin: 0;
  }

  /** Inline HTMLEditor specific css */
  .dh-inline { flex-grow: 1; }

  .dh-inline .fr-toolbar,
  .dh-inline .fr-wrapper { border: 0 !important; }
  .dh-inline p { margin: 0; }

  .dh-inline .fr-element.fr-view {
    ${({ theme }) =>
      css`
        line-height: ${theme.lineHeight('base')};
        padding: calc(
          ${calculateSpaceInsideComponent(theme, false, false)}px -
            ${INPUT_ELEMENT_BORDER}
        );
      `}
    min-height: 0px;
  }

  .dh-inline.dh-inline-textarea .fr-element.fr-view {
    height: 150px;
    max-height: 150px;
  }

  /** DatHuis variable styling */
  img[dhvariable="true"] {
    display: inline-block;
    cursor: pointer;
    vertical-align: sub;
    background-color: ${({ theme }) => `${rgba(theme.color('info'), 0.2)}`};
    border: ${({ theme }) =>
      `${theme.getTokens().border.width.s} solid ${theme.color('info')}`};
    border-radius: ${({ theme }) => theme.getTokens().border.radius.s};
    height: 1.2em;
  }

  img[dhvariable="true"][dherror="true"] {
    background-color: ${({ theme }) => `${rgba(theme.color('danger'), 0.2)}`};
    border: ${({ theme }) =>
      `${theme.getTokens().border.width.s} solid ${theme.color('danger')}`};
    color: ${({ theme }) => `${theme.color('danger')}`};
  }

  /** To disable auto-fill background color in stripe elements */
  .StripeElement--webkit-autofill {
    background-color: transparent !important;
  }

  #modal-portal {
    float: left;
  }

  [data-redacted="true"] {
    filter: blur(5px);
  }

`;
