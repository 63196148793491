import React from 'react';

import { Link as NavLink } from '~/components/Link';

import DHRouter from '../DHRouter';
import styled, { css } from 'styled-components';
import NotFound from '~/components/404';
import Icon from '../Icon';
import icons from '../Icon/components';
import { MAIN_CONTENT_WIDTH } from '../NavigationFrame';

const HELPDESK_LINK = 'https://help.dathuis.nl';

type Props = {
  routes: Array<{
    path: string;
    link: string;
    exact?: boolean;
    label: {
      text: string;
      icon?: keyof typeof icons;
      dataTestid?: string;
    };
    detail: React.ComponentType<any>;
  }>;
};

const MasterDetail = ({ routes }: Props) => {
  const MasterLinks = routes.map(route => (
    <LinkItem
      key={route.link}
      dataTestid={route.label.dataTestid}
      to={route.link}
    >
      {route.label.icon && <Icon name={route.label.icon} />}
      <Label>{route.label.text}</Label>
    </LinkItem>
  ));

  const Details = routes.map(route => {
    const D = route.detail;
    return <D key={route.path} path={route.path} />;
  });

  return (
    <MasterDetailContainer>
      <Master>
        {MasterLinks}
        <LinkItem
          key="madeupkey"
          dataTestid="external-help-link"
          target="_blank"
          to={HELPDESK_LINK}
        >
          <Icon name="question-mark" inline />
          <Label>Help</Label>
        </LinkItem>
        <LinkItem key="signout" dataTestid="settings-link-signout" to="/logout">
          <Icon name="sign-out" inline />
          <Label>Uitloggen</Label>
        </LinkItem>
      </Master>
      <Detail>
        <DetailContent>
          <DHRouter basepath="/">
            {Details}
            <NotFound default />
          </DHRouter>
        </DetailContent>
      </Detail>
    </MasterDetailContainer>
  );
};
const SIDEBAR_PADDING = '50px';
const SIDEBAR_WIDTH = '250px';
const MasterDetailContainer = styled.div<{}>`
  height: 100%;
  display: grid;

  ${({ theme }) => css`
    grid-template-columns: [master-start] ${SIDEBAR_WIDTH} [master-end detail-start] 1fr [detail-end];

    @media (min-width: calc(${MAIN_CONTENT_WIDTH} + ${SIDEBAR_WIDTH} + 2 * ${theme.space(
        'l',
      )})) {
      grid-template-columns: [master-start] ${SIDEBAR_WIDTH} [master-end detail-start] ${MAIN_CONTENT_WIDTH} [detail-end];
    }

    @media (min-width: calc(${MAIN_CONTENT_WIDTH} + 2 * ${SIDEBAR_WIDTH})) {
      grid-template-columns: [master-start] ${SIDEBAR_WIDTH} [master-end detail-start] 1fr [detail-end] ${SIDEBAR_WIDTH};
    }
  `}
`;

const Master = styled.ul<{}>`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  ${({ theme }) => css`
    padding-top: ${SIDEBAR_PADDING};
    background-color: ${theme.color('white')};
  `}

  grid-column: master-start / master-end;
`;

const Label = styled.span<{}>(
  ({ theme }) => css`
    padding-left: ${theme.space('s')};
  `,
);

const LinkItem = styled(NavLink)<{}>`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s ease-out;

  ${({ theme }) => css`
    margin-bottom: ${theme.space('xxs')};
    padding-top: ${theme.space('m')};
    padding-bottom: ${theme.space('m')};
    padding-left: ${theme.space('xl')};
    color: ${theme.color('text')};
    font-size: ${theme.space('m')};
    border-left: 5px solid transparent;

    &.active {
      color: ${theme.color('primary')};
      padding-left: 27px;
      border-left: 5px solid ${theme.color('primary')};
      font-weight: bold;
    }

    &:hover {
      color: ${theme.color('text')};
      background-color: ${theme.color('white', 'dark')};
    }
  `};
`;

const Detail = styled.div<{}>`
  grid-column: detail-start / detail-end;
`;

const DetailContent = styled.div<{}>`
  margin: 0 auto;
  min-height: 100%;
`;

export default MasterDetail;
