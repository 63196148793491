import React, { ReactElement } from 'react';

import { WaitAction } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { describeFlowConditionList } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList';

const describeWaitAction = (
  action: WaitAction,
  describeOptions: DescribeOptions,
): ReactElement => (
  <span>
    Wacht tot{' '}
    {describeFlowConditionList(action.conditionList, describeOptions, false)}
  </span>
);

export default describeWaitAction;
