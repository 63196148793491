import gql from 'graphql-tag';

export default gql`
  fragment SessionHydrationOfficeRelationFields on OfficeRelationship {
    __typename
    accountId
    officeId
    userId
    mainOffice
    status
    role
  }
`;
