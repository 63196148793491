import React, { useContext } from 'react';

import { FilePointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import BaseActionContext from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import PointerComponent from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/PointerComponent';
import { getStashVariablesInStash } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';

type Props = {
  value: FilePointerParameterValue;
  onChange: (newValue: FilePointerParameterValue) => void;
  variableLabel?: string;
  fieldLabel?: string;
};
const FilePointerParameterValueComponent = ({
  value,
  onChange,
  ...rest
}: Props) => {
  const { variableStash } = useContext(BaseActionContext);

  return (
    <PointerComponent
      {...rest}
      possibleVariables={getStashVariablesInStash(
        variableStash,
        undefined,
        FLOW_OUTPUT_TYPE.FlowOutputFile,
      )}
      fieldType={FLOW_OUTPUT_TYPE.FlowOutputFile}
      variable={value ? value.variable : null}
      onChange={newPointerVariable => {
        const newValue = { ...value, variable: newPointerVariable };

        onChange(newValue);
      }}
    />
  );
};

export default FilePointerParameterValueComponent;
