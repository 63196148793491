import { FlowConditionAppBBWaardecheckReportNew } from './types.flow';
import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';

const emptyFlowConditionAppBBWaardecheckReportNew = (): Omit<
  FlowConditionAppBBWaardecheckReportNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_BBWAARDECHECK_REPORT_NEW.type,
});

export default emptyFlowConditionAppBBWaardecheckReportNew;
