import React, { useState } from 'react';
import styled from 'styled-components';

import { ContactFilters__Input } from '~/graphql/types';
import { actionOptions, CONTACT_ACTION } from './constants';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TEST_ID from './index.testid';
import actionSwitcher, { ContactAction } from './actionSwitcher';
import Dropdown from '~/components/Dropdown';

const text = {
  actionsDropdownLabel: 'Acties',
};

export type Props = {
  /** Array of selected contact id's */
  selectedContacts: Array<string>;

  /** If all contacts on all pages are selected or not */
  allSelected: boolean;

  /** Number of selected contacts */
  selectedContactAmount: number;

  /** query and filters fields returned from GetContactsV2Query, used to lock contact search result */
  queryVariables: {
    query?: string;
    filters: ContactFilters__Input;
  };

  /** Callback that removes contact from the list and refreshes the table */
  onDeleteContact: () => void;

  /** Callback that refreshes the list after adding tags */
  onAddTags: () => void;

  dataTestId?: string;
};

const ActionsContainer: React.FC<Props> = ({
  selectedContacts,
  allSelected,
  queryVariables,
  selectedContactAmount,
  dataTestId,
  onDeleteContact,
  onAddTags,
  ...rest
}) => {
  const account = useCurrentAccount();
  const [actionType, setActionType] = useState<ContactAction | null>(null);

  const queryString = queryVariables.query === '' ? null : queryVariables.query;

  const lockContactsVariables = !allSelected
    ? {
        accountId: account?.id,
        contactIds: selectedContacts,
        query: queryString,
      }
    : {
        accountId: account?.id,
        filters: queryVariables.filters,
        query: queryString,
      };

  const onComplete = () => {
    setActionType(null);

    if (actionType === CONTACT_ACTION.DELETE) {
      onDeleteContact();
    }

    if (actionType === CONTACT_ACTION.ADD_TAGS) {
      onAddTags();
    }
  };

  const onCancel = () => {
    setActionType(null);
  };

  let actionComponent: JSX.Element | null = null;
  if (actionType != null) {
    actionComponent = actionSwitcher({
      actionType,
      actionModalProps: {
        lockContactsVariables,
        selectedContactAmount,
        onComplete,
        onCancel,
      },
    });
  }

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Dropdown
        options={actionOptions}
        onChange={({ option }) => setActionType(option.payload)}
        disabled={selectedContacts.length === 0}
        dataTestId={TEST_ID.ACTIONS_DROPDOWN}
        placeholder={text.actionsDropdownLabel}
      />
      {actionComponent}
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100px;
`;

export default ActionsContainer;
