import gql from 'graphql-tag';

export default gql`
  fragment SessionHydrationDeletedOfficeRelationFields on DeletedOfficeToAccountRelationship {
    __typename
    accountId
    officeId
    name
  }
`;
