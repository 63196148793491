import React, { useContext } from 'react';

import { UpdateTaskMutationFunction } from '~/graphql/Mutation';

import { UpdateTaskMutation } from '~/graphql';
import updateTaskMutation from '~/graphql/mutation/UpdateTask';
import cleanedFilename from '~/util/cleanedFilename';
import { TaskUpdaterContext } from './TaskUpdaterContext';

type Props = {
  /** Component which get update mutation function  */
  childComponent: (childProps: {
    updateTask: UpdateTaskMutationFunction;
    loading: boolean;
  }) => JSX.Element | null;
};

const UpdateTaskWrapper = ({ childComponent }: Props) => {
  const { onTaskChanged } = useContext(TaskUpdaterContext);

  return (
    <UpdateTaskMutation
      name={cleanedFilename(__filename)}
      mutation={updateTaskMutation}
      onCompleted={data => {
        if (data) {
          onTaskChanged(data.updateTask);
        }
      }}
    >
      {(updateTask, { loading }) =>
        childComponent({
          updateTask,
          loading,
        })
      }
    </UpdateTaskMutation>
  );
};

export default UpdateTaskWrapper;
