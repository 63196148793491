import React, { useState } from 'react';
import Tabs from '~/components/Tabs';
import TabBody from '~/components/Tabs/components/TabBody';
import { Heading5, Body } from '~/components/Typography/index';
import TEST_ID from './index.testid';
import { navigate } from '@reach/router';

import {
  useGetFlowsStatisticsQuery,
  useGetFlowsV2StatisticsQuery,
} from '~/graphql/types';
import Button from '~/components/Button';
import Loading from '~/components/SimpleTable/components/Loading';
import CardHeader from '../CardHeader';
import getDateRangeOptions from './utils/getDateRangeOptions';
import BarChart from '~/components/Charts/BarChart';
import { prop, sort, take, descend } from 'ramda';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { ChartDataPoint } from '~/components/Charts';
import { BUILDER_V2_PATH, LEGACY_FLOW_PATH } from '../EmailEffectiveness';
import withMasonryCard from '../withMasonryCard';
import { LINK_PREFIX } from '~/scenes/Automation/v2/components/Wizard';

export type Props = {
  dataTestId?: string;
};

const text = {
  heading: 'Flow effectiviteit',

  noFlowHeader: 'Geen flows',
  noFlowBody: 'Je hebt nog geen flows.',
  noFlowCTA: 'Maak een flow',

  errorHeader: 'Uh-oh 😱',
  errorBody:
    'Het lijkt er op dat er iets mis is gegaan bij het ophalen van jouw contacten. Probeer het later opnieuw.',

  tabsHeader: 'Positieve impact op score',
};

const FlowEffectiveness: React.FC<Props> = () => {
  const { id: accountId } = useCurrentAccount();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
  const options = getDateRangeOptions();
  const selectedField = options[selectedOptionIndex].payload;
  const variables = {
    accountId,
    limit: 100,
    statisticsLimit: selectedField,
  };

  const {
    data: flows,
    error: flowsError,
    loading: flowsLoading,
  } = useGetFlowsStatisticsQuery({
    variables,
  });

  const {
    data: flowsV2,
    error: flowsV2Error,
    loading: flowsV2loading,
  } = useGetFlowsV2StatisticsQuery({
    variables,
  });

  const error = flowsError || flowsV2Error;
  const loading = flowsLoading || flowsV2loading;

  const FilledCardHeader = () => (
    <CardHeader
      headingText={text.heading}
      onOptionChange={({ selectedOptionIdx }) =>
        setSelectedOptionIndex(selectedOptionIdx)
      }
      options={options}
      selectedOptionIndex={selectedOptionIndex}
      dataTestId={TEST_ID.CARD_HEADER}
    />
  );

  if (error) {
    return (
      <>
        <FilledCardHeader />
        <Heading5>{text.errorHeader}</Heading5>
        <Body>{text.errorBody}</Body>
      </>
    );
  }

  const noFlowsExist =
    flows &&
    flows.getFlows.items.length <= 0 &&
    flowsV2 &&
    flowsV2.getFlowsV2.items.length <= 0;

  if (noFlowsExist) {
    return (
      <>
        <FilledCardHeader />
        <Heading5>{text.noFlowHeader}</Heading5>
        <Body>{text.noFlowBody}</Body>
        <Button
          size="medium"
          dataTestId={TEST_ID.NO_FLOWS_CTA}
          onClick={() => navigate(`${LINK_PREFIX}/v2/`)}
          label={text.noFlowCTA}
        />
      </>
    );
  }

  // Remove when legacy flows have gone
  const rows =
    flows?.getFlows.items.map(({ name, id, Statistics }) => ({
      id,
      name,
      value: Statistics?.leadScoreAverage ?? 0,
      link: `${LEGACY_FLOW_PATH}${id}`,
    })) || [];

  const rowsV2 =
    flowsV2?.getFlowsV2.items.map(({ name, id, Statistics }) => ({
      id,
      name,
      value: Statistics?.leadScoreAverage ?? 0,
      link: `${BUILDER_V2_PATH}${id}`,
    })) ?? [];

  const mergedRows: Array<ChartDataPoint<number>> = rows.concat(rowsV2);

  return (
    <>
      <FilledCardHeader />
      <Tabs>
        {[
          <TabBody label={text.tabsHeader} key="thing">
            {loading ? (
              <Loading />
            ) : (
              <BarChart
                dataTestId={TEST_ID.CHART}
                data={take(5, sort(byValue, mergedRows))}
                formatOptions={{
                  style: 'decimal',
                  minimumFractionDigits: 0,
                  signDisplay: 'exceptZero',
                }}
                tooltipLabel="Impact score"
              />
            )}
          </TabBody>,
        ]}
      </Tabs>
    </>
  );
};

const byValue = descend(prop('value'));

export default withMasonryCard(FlowEffectiveness, {
  containerTestId: TEST_ID.CONTAINER,
});
