import React from 'react';
import styled, { css } from 'styled-components';
import { AppBbWaardecheck__Input, AppType } from '~/graphql/types';

import Preview, { Device, Page } from '../Preview';
import StoryPreview, { Story } from '../StoryPreview';

type StoryPreview_Type = {
  device: Device;
  story: Story;
};

type PagePreview_Type = {
  device: Device;
  page: Page;
  height?: number;
  width?: number;
};

type Preview_Type = StoryPreview_Type | PagePreview_Type;

export type Props = {
  dataTestId?: string;
  previews?: Array<Preview_Type>;
  data: AppBbWaardecheck__Input;
  appType?: AppType;
};

const PreviewContainer: React.FC<Props> = ({
  dataTestId,
  data,
  previews = [{ page: Page['/'], device: 'desktop' }],
  appType,
  ...rest
}) => {
  const previewComponents = previews.map((preview, index) => {
    if ('story' in preview) {
      const { story } = preview;
      return <StoryPreview key={index} data={data} story={story} />;
    }

    const { page, device, height, width } = preview;
    return (
      <Preview
        key={index}
        height={height ?? 300}
        width={width ?? 350}
        device={device}
        data={data}
        page={page}
        appType={appType}
      />
    );
  });

  return (
    <Container data-testid={dataTestId} {...rest}>
      {previewComponents}
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    justify-content: space-around;
    background-color: ${theme.color('white', 'dark')};
    overflow: hidden;
    border-bottom: ${theme.getTokens().border.width.base} solid
      ${theme.color('grey', 'light')};
  `,
);

export default PreviewContainer;
