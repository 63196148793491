import { FlowVariableStashItem } from '~/scenes/Automation/Flows/types.flow';
import { ParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { OptionOf, SelectedOptionOf } from '~/components/Inputs/Dropdown';
import { PrimitiveVariableType } from './constants';
import { FieldType } from './getFieldOptions';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';

import { asPointerName } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/PointerComponent';
import pointerValueOrNull from '~/scenes/Automation/Flows/Actions/Base/util/pointerValueOrNull';
import getFieldOptions from './getFieldOptions';

type ReturnProps = {
  fieldOptions: Array<OptionOf<FieldType>>;
  selectedFieldIdx: number;
  selectedFieldOption: OptionOf<FieldType> | null;
  onChangeField: (selectedOption: SelectedOptionOf<FieldType>) => void;
};
const getFieldDropdownProps = <T extends ParameterValue>(
  selectedVariable: OptionOf<
    FlowVariableStashItem | PrimitiveVariableType
  > | null,
  value: T | null,
  onFieldChange: (newFieldName: string) => void,
  objectType?: FLOW_OUTPUT_TYPE,
  fieldType?: FLOW_OUTPUT_TYPE,
): ReturnProps => {
  const fieldOptions = getFieldOptions(
    selectedVariable == null ? null : selectedVariable.payload,
    objectType,
    fieldType,
  );
  const selectedFieldIdx = getSelectedFieldIndex<T>(fieldOptions, value);
  const selectedFieldOption =
    selectedFieldIdx < 0 ? null : fieldOptions[selectedFieldIdx];

  const onChangeField = ({ option }: SelectedOptionOf<FieldType>) => {
    const { outputObject, outputField } = option.payload;

    onFieldChange(asPointerName(outputObject, outputField));
  };

  return {
    fieldOptions,
    selectedFieldIdx,
    selectedFieldOption,
    onChangeField,
  };
};

const getSelectedFieldIndex = <T extends ParameterValue>(
  fieldOptions: Array<OptionOf<FieldType>>,
  value: T | null,
): number => {
  if (value == null) {
    return -1;
  }

  const castedValue = pointerValueOrNull(value);
  if (castedValue == null) {
    return -1;
  }

  if (castedValue.variable == null || castedValue.variable.field == null) {
    return -1;
  } else {
    const fieldName = castedValue.variable.field.name;

    return fieldOptions.findIndex(option => option.key === fieldName);
  }
};

export default getFieldDropdownProps;
