import { omit } from 'ramda';
import {
  AppStatus_Realworks__Input,
  AppStatus_Trustoo__Input,
  WidgetSettingsAppConfigurationItem,
} from '~/graphql/types';
import { AppStatus, AppStatus__typename } from '~/graphql/types.client';
import getWidgetAppConfigurationUpdate from '~/util/getWidgetAppConfigurationUpdate';

type Args = {
  typename: AppStatus__typename;
  completed: boolean;
  currentAppStatus?: AppStatus;
  updatedItems?: Array<WidgetSettingsAppConfigurationItem>;
};
const getUpdateForAppStatus = ({
  typename,
  completed,
  currentAppStatus,
  updatedItems,
}: Args) => {
  if (!currentAppStatus) return null;

  const currentMetadata =
    'metadata' in currentAppStatus
      ? currentAppStatus.metadata
      : {
          setup: {
            completed,
          },
        };

  const setup = {
    ...omit(['__typename'], currentMetadata?.setup ?? {}),
    completed,
  };

  const appStatus = omit(
    ['__typename', 'accountId', 'locked'],
    currentAppStatus,
  );

  if (typename === 'AppStatus_Realworks') {
    const update: AppStatus_Realworks__Input = {
      ...omit(['tokens', 'relatieSync'], appStatus),
      enabled: true,
      usageAgreement: true,
      metadata: {
        ...omit(['__typename'], currentMetadata),
        setup,
      },
    };
    return update;
  }

  if (typename === 'AppStatus_Trustoo') {
    const tokens =
      'tokens' in currentAppStatus
        ? currentAppStatus.tokens.map(token =>
            omit(['__typename', 'TokenInfo'], token),
          )
        : [];

    const update: AppStatus_Trustoo__Input = {
      ...appStatus,
      ...getWidgetAppConfigurationUpdate(currentAppStatus, updatedItems),
      enabled: true,
      tokens,
      metadata: {
        ...omit(['__typename'], currentMetadata),
        setup,
      },
    };
    return update;
  }

  const update = {
    ...appStatus,
    ...getWidgetAppConfigurationUpdate(currentAppStatus, updatedItems),
    enabled: true,
    metadata: {
      ...omit(['__typename'], currentMetadata),
      setup,
    },
  };
  return update;
};

export default getUpdateForAppStatus;
