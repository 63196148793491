import { Plan, UserRole } from '~/graphql/types';

export type Category =
  | 'Apps'
  | 'Users'
  | 'Offices'
  | 'Office'
  | 'Invoices'
  | 'Subscription'
  | 'Wallet';
type SubscriptionType = Plan | 'External';
type Entity = 'office' | 'account';
export type Action = 'create' | 'view' | 'update' | 'delete';

type AuthorizationMatrix = {
  [c in Category]: {
    [a in Action]: {
      [s in SubscriptionType]: {
        [e in Entity]: {
          [r in UserRole]: boolean;
        };
      };
    };
  };
};

const onlyAccountAdmin = {
  Pro: {
    account: { Admin: true, User: false },
    office: { Admin: false, User: false },
  },
  Starter: {
    account: { Admin: true, User: false },
    office: { Admin: false, User: false },
  },
  Premium: {
    account: { Admin: true, User: false },
    office: { Admin: false, User: false },
  },
  External: {
    account: { Admin: true, User: false },
    office: { Admin: false, User: false },
  },
};

const bothAccountAndOfficeAdmin = {
  Pro: {
    account: { Admin: true, User: false },
    office: { Admin: true, User: false },
  },
  Starter: {
    account: { Admin: true, User: false },
    office: { Admin: true, User: false },
  },
  Premium: {
    account: { Admin: true, User: false },
    office: { Admin: true, User: false },
  },
  External: {
    account: { Admin: true, User: false },
    office: { Admin: true, User: false },
  },
};

const allUsers = {
  Pro: {
    account: { Admin: true, User: true },
    office: { Admin: true, User: true },
  },
  Starter: {
    account: { Admin: true, User: true },
    office: { Admin: true, User: true },
  },
  Premium: {
    account: { Admin: true, User: true },
    office: { Admin: true, User: true },
  },
  External: {
    account: { Admin: true, User: true },
    office: { Admin: true, User: true },
  },
};

const externalSubscriptionNotAllowed = {
  External: {
    account: { Admin: false, User: false },
    office: { Admin: false, User: false },
  },
};

const authorizationMatrix: AuthorizationMatrix = {
  Offices: {
    update: bothAccountAndOfficeAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },
  Office: {
    update: bothAccountAndOfficeAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },
  Users: {
    update: onlyAccountAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },
  Apps: {
    update: onlyAccountAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },

  // Setttings
  Invoices: {
    update: {
      ...onlyAccountAdmin,
      ...externalSubscriptionNotAllowed,
    },
    create: {
      ...onlyAccountAdmin,
      ...externalSubscriptionNotAllowed,
    },
    delete: {
      ...onlyAccountAdmin,
      ...externalSubscriptionNotAllowed,
    },
    view: {
      ...onlyAccountAdmin,
      ...externalSubscriptionNotAllowed,
    },
  },
  Subscription: {
    update: onlyAccountAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: onlyAccountAdmin,
  },
  Wallet: {
    update: onlyAccountAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },
};

export default authorizationMatrix;
