import { Ref, useRef } from 'react';
import { isNil } from 'ramda';

type Props = {
  /** Allows to configure the vertical alignment of scroll behaviour */
  isCentered?: boolean;
};

type ReturnProps<T> = [() => void, Ref<T>];

const useScrollIntoView = <T extends HTMLDivElement>({
  isCentered,
}: Props): ReturnProps<T> => {
  const containerRef = useRef<null | T>(null);

  const scrollToContainer = () => {
    // setTimeout is needed to make scrollIntoView work on the first click
    setTimeout(
      () =>
        !isNil(containerRef.current) &&
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: isCentered ? 'center' : 'start',
        }),
      0,
    );
  };

  return [scrollToContainer, containerRef];
};

export default useScrollIntoView;
