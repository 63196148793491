import React, { ReactElement, useState } from 'react';

import {
  Plan,
  BillingCycle,
  useUpdateSubscriptionMutation,
} from '~/graphql/types';

import { Coupon } from '../../../types.flow';
import { isNil } from 'ramda';
import Modal from '~/components/ModalsV2/Modal';
import ChoosePlanStep from '../steps/ChoosePlanStep';
import PlanOverviewStep from '../steps/PlanOverviewStep';
import CancelSubscriptionStep from '../steps/CancelSubscriptionStep';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import InfoStep from '../steps/InfoStep';
import Overlay from '~/components/ModalsV2/Overlay';
import type { SetupSubscriptionInfo } from '../types.flow';

const text = {
  planOverviewNextStepLabel: 'Bevestigen',
  updateFailedTitle: 'Oeps!',
  updateFailed:
    'Er is iets misgegaan bij het wijzigen van je abonnement. Probeer het nog eens. Mocht het vaker misgaan, neem dan contact met ons op.',
};
type Props = {
  initialSubscriptionPlan: Plan | null;
  initialBillingCycle: BillingCycle | null;
  onComplete: () => void;
  onClose?: () => void;
};
const UpdateSubscriptionFlow: React.FC<Props> = ({
  initialSubscriptionPlan,
  initialBillingCycle,
  onComplete,
  onClose,
}) => {
  const account = useCurrentAccount();
  const [subscriptionInfo, setSubscriptionInfo] =
    useState<SetupSubscriptionInfo>({
      chosenPlan: initialSubscriptionPlan || Plan.Pro,
      chosenBillingCycle: initialBillingCycle || BillingCycle.Yearly,
      coupon: null,
    });

  const [updateSubscription, { error, loading }] =
    useUpdateSubscriptionMutation();

  const [hasError, setHasError] = useState<boolean>(!isNil(error));
  const [stepNumber, setStepNumber] = useState(1);

  const onUpdate = async (
    billingCycle: BillingCycle,
    coupon: Coupon | null,
  ) => {
    setSubscriptionInfo(prevInfo => ({
      ...prevInfo,
      chosenBillingCycle: billingCycle,
      coupon,
    }));

    await updateSubscription({
      variables: {
        accountId: account.id,
        plan: subscriptionInfo.chosenPlan,
        billingCycle: billingCycle,
        coupon: coupon == null ? null : coupon.id,
      },
    }).then(({ errors, data }) => {
      if (!data && errors?.length) {
        return setHasError(true);
      }
      return onComplete();
    });
  };

  let stepComponent: ReactElement | null = null;
  if (stepNumber === 1) {
    stepComponent = (
      <ChoosePlanStep
        initialSelectedBillingCycle={subscriptionInfo.chosenBillingCycle}
        initialSelectedPlan={subscriptionInfo.chosenPlan}
        currentSelectedBillingCycle={initialBillingCycle}
        currentSelectedPlan={initialSubscriptionPlan}
        onContinue={(chosenPlan, chosenBillingCycle) => {
          setSubscriptionInfo(prevInfo => ({
            ...prevInfo,
            chosenPlan,
            chosenBillingCycle,
          }));
          setStepNumber(2);
        }}
        onGoToUnsubscribe={() => setStepNumber(11)}
      />
    );
  } else if (stepNumber === 2) {
    if (hasError) {
      stepComponent = (
        <InfoStep
          title={text.updateFailedTitle}
          message={text.updateFailed}
          onGoBack={() => {
            setHasError(false);
          }}
        />
      );
    } else {
      stepComponent = (
        <PlanOverviewStep
          loading={loading}
          initialSelectedCoupon={subscriptionInfo.coupon}
          initialSelectedBillingCycle={subscriptionInfo.chosenBillingCycle}
          initialSelectedPlan={subscriptionInfo.chosenPlan}
          nextStepLabel={text.planOverviewNextStepLabel}
          onContinue={onUpdate}
          onGoBack={(billingCycle, coupon) => {
            setSubscriptionInfo(prevInfo => ({
              ...prevInfo,
              chosenBillingCycle: billingCycle,
              coupon,
            }));

            setStepNumber(1);
          }}
        />
      );
    }
  } else if (stepNumber === 11) {
    stepComponent = (
      <CancelSubscriptionStep
        onGoBack={() => setStepNumber(1)}
        onSubscriptionCancelled={() => {
          onComplete();
        }}
      />
    );
  }

  return (
    <Overlay
      onClose={() => {
        if (onClose) {
          onClose();
        } else {
          onComplete();
        }
      }}
    >
      <Modal onClose={onComplete}>{stepComponent}</Modal>
    </Overlay>
  );
};

export default UpdateSubscriptionFlow;
