import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';

import { Plan, BillingCycle } from '~/graphql/types';
import { isNil } from 'ramda';
import type { Coupon } from '~/scenes/Settings/Subscription/types.flow';

import TEST_ID from './index.testid';

import Dropdown from '~/components/Dropdown';
import {
  PRICING_INFO,
  planOptions,
  billingCycleOptions,
} from '~/scenes/Settings/Subscription/constants';
import { AccentLabel } from '~/scenes/Settings/Subscription/components/ChangeOrUpdateSubscriptionFlows/steps/components/PriceCard';
import { asPriceString } from '~/util/money';
import cleanedFilename from '~/util/cleanedFilename';
import TextButton from '~/components/TextButton';
import BottomSectionContainer from '../components/BottomSectionContainer';
import CouponInput from '../components/CouponInput';
import MidSectionContainer from '../components/MidSectionContainer';
import TopSectionContainer from '../components/TopSectionContainer';
import { animated, useSpring } from 'react-spring';
import JustificationContainer from '~/components/JustificationContainer';

const text = {
  content: 'Content',
  header: 'Facturatie',
  note: '* Alle bedragen zijn excl. BTW',
  nextStep: 'Ga naar betalen',
  invalidCode: 'Ongeldige kortingscode',
  totalPrice: 'Totaal gefactureerd ',
  couponLabel: 'Kortingscode',
};

type Props = {
  initialSelectedBillingCycle: BillingCycle;
  initialSelectedPlan: Plan;
  initialSelectedCoupon: Coupon | null;
  initialCouponCode?: string;
  onGoBack: (billingCycle: BillingCycle, coupon: Coupon | null) => void;
  onContinue: (billingCycle: BillingCycle, coupon: Coupon | null) => void;
  loading: boolean;
  nextStepLabel?: string;
};
const PlanOverviewStep: React.FC<Props> = ({
  onGoBack,
  onContinue,
  initialSelectedCoupon,
  initialCouponCode,
  initialSelectedBillingCycle,
  initialSelectedPlan,
  loading,
  nextStepLabel,
}) => {
  const [couponInfo, setCouponInfo] = useState<Coupon | null>(
    initialSelectedCoupon,
  );
  const [showCouponInput, setShowCouponInput] = useState<boolean>(
    !isNil(initialSelectedCoupon) || !isNil(initialCouponCode),
  );

  const styles = useSpring({
    transformOrigin: 'left',
    scale: showCouponInput ? 1 : 0,
    config: {
      tension: 300,
    },
  });
  const [billingCycle, setBillingCycle] = useState(initialSelectedBillingCycle);

  const selectedPlanOption = planOptions.find(
    option => option.value === initialSelectedPlan,
  );
  const selectedBillingCycleOption = billingCycleOptions.find(
    option => option.value === billingCycle,
  );

  if (selectedPlanOption == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | selectedPlanOption == null`,
    );
  }
  if (selectedBillingCycleOption == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | selectedBillingCycleOption == null`,
    );
  }
  const labelString = `${selectedPlanOption.label} ${selectedBillingCycleOption.label}`;

  const pricingInfo = PRICING_INFO[initialSelectedPlan];
  const { pricePerMonth, pricePerYear } = pricingInfo;

  const price =
    billingCycle === BillingCycle.Monthly ? pricePerMonth : pricePerYear;
  const totalPrice = billingCycle === BillingCycle.Monthly ? price : price * 12;

  let accentComponent: ReactElement | null = null;
  if (billingCycle === BillingCycle.Yearly) {
    accentComponent = <AccentLabel>&euro; {pricePerMonth / 100}</AccentLabel>;
  }

  const dropdownOptions = [
    {
      label: `Jaarlijks - ${asPriceString(pricePerYear, true)} per maand`,
      key: BillingCycle.Yearly,
      payload: BillingCycle.Yearly,
    },
    {
      label: `Maandelijks - ${asPriceString(pricePerMonth, true)} per maand`,
      key: BillingCycle.Monthly,
      payload: BillingCycle.Monthly,
    },
  ];

  return (
    <Container
      align="center"
      direction="column"
      data-testid={TEST_ID.CONTAINER}
    >
      <TopSectionContainer headerText={text.header} withBorder />
      <MidSectionContainer>
        <ContentContainer>
          <StyledRowContainer>
            <Dropdown
              options={dropdownOptions}
              appearance="borderless"
              onChange={({ option }) => {
                setBillingCycle(option.payload);
              }}
              label={
                <DropdownLabel
                  data-testid={TEST_ID.PLAN_CYCLE_LABEL}
                  data-selectedplan={initialSelectedPlan}
                >
                  {labelString}
                </DropdownLabel>
              }
              selectedOptionIdx={dropdownOptions.findIndex(
                option => option.payload === billingCycle,
              )}
              dataTestId={TEST_ID.BILLING_CYCLE_DROPDOWN}
            />

            <PriceContainer>
              {accentComponent}
              <BoldText>{asPriceString(price)}</BoldText>
            </PriceContainer>
          </StyledRowContainer>

          {showCouponInput ? (
            <animated.div style={styles}>
              <CouponInput
                amount={totalPrice}
                initialCouponCode={initialCouponCode}
                couponInfo={couponInfo}
                setCouponInfo={setCouponInfo}
              />
            </animated.div>
          ) : (
            <StyledButton
              dataTestId={TEST_ID.SHOW_COUPON_BUTTON}
              label={text.couponLabel}
              icon="plus"
              margin={['s', null, null, null]}
              onClick={() => setShowCouponInput(true)}
            />
          )}

          <TotalPriceContainer>
            <BoldText>{text.totalPrice}</BoldText>
            <BoldText data-testid={TEST_ID.TOTAL_PRICE}>
              {asPriceString(
                totalPrice -
                  (couponInfo != null
                    ? couponInfo.calculateOff(totalPrice)
                    : 0),
              )}
            </BoldText>
          </TotalPriceContainer>
        </ContentContainer>
      </MidSectionContainer>
      <NoteContainer>{text.note}</NoteContainer>
      <BottomSectionContainer
        nextStepButton={nextStepLabel == null ? text.nextStep : nextStepLabel}
        onContinue={() => {
          onContinue(billingCycle, couponInfo);
        }}
        onGoBack={() => {
          onGoBack(billingCycle, couponInfo);
        }}
        loading={loading}
      />
    </Container>
  );
};

const StyledButton = styled(TextButton)<{}>`
  padding-left: 0;
`;

const Container = styled(JustificationContainer)<{}>`
  height: 100%;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
  `};
`;

const ContentContainer = styled.div<{}>`
  height: 100%;

  ${({ theme }) => css`
    padding: ${theme.space('xl')} 0 ${theme.space('xxxl')} 0;
  `};
`;

const DropdownLabel = styled.div<{}>`
  font-weight: bold;

  ${({ theme }) => css`
    font-size: ${theme.fontSize('m')};
    padding-right: ${theme.space('xxs')};
  `};
`;

const PriceContainer = styled.div<{}>`
  width: 200px;
  text-align: end;
`;

const RowContainer = styled.div<{}>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
const TotalPriceContainer = styled(RowContainer)<{}>`
  ${({ theme }) => css`
    padding-top: ${theme.space('m')};
  `};
`;

const StyledRowContainer = styled(RowContainer)<{}>`
  ${({ theme }) => css`
    padding-bottom: ${theme.space('xxxl')};
    border-bottom: 1px solid ${theme.color('grey')};
    margin-top: ${theme.space('m')};
  `};
`;

const BoldText = styled.span<{}>`
  font-weight: bold;

  ${({ theme }) => css`
    font-size: ${theme.fontSize('m')};
  `};
`;

const NoteContainer = styled.div<{}>`
  align-self: flex-start;

  ${({ theme }) => css`
    padding: ${theme.space('l')} ${theme.space('xxxxl')};
    margin-top: ${theme.space('xxl')};
    font-size: ${theme.fontSize('s')};
    color: ${theme.color('text', 'light')};
  `};
`;

export default PlanOverviewStep;
