import React, { useState, useEffect, useMemo } from 'react';
import uuidv4 from 'uuid/v4';
import MetaTags from 'react-meta-tags';

import {
  FlowListData,
  FlowListDataLegacy,
  memoizedComposeFlowListLegacyData,
} from '~/scenes/Automation/Flows/util/composeFlowListData';
import {
  FlowData___ActionFragment,
  GetFlowsQuery,
  GetFlowsQueryVariables,
  GetFlowsV2Query,
  GetFlowsV2QueryVariables,
  SortDirection,
  useDeleteFlowV2Mutation,
  useGetFlowsQuery,
  useGetFlowsV2Query,
} from '~/graphql/types';

import TEST_ID from './index.testid';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import FlowListTable from '../components/FlowListTable';
import composeFlowListData from '../util/composeFlowListData';
import flowListColumns from '../util/flowListColumns';
import InfiniteScroll from '~/components/InfiniteScroll';
import { globalHistory, navigate, RouteComponentProps } from '@reach/router';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { Loading } from '~/components';
import Button from '~/components/Button';
import Toolbar from '~/components/Toolbar';
import {
  Body,
  Heading1,
  Heading2,
  Heading3,
} from '~/components/Typography/index';
import styled, { css, useTheme } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import useSortSettings from '~/hooks/useSortSettings';
import Divider from '~/components/Divider';
import useResetFlowState from '../../v2/components/Builder/hooks/useResetFlowState';
import Illustration from '~/components/Illustration';
import Toast from '~/components/Toast';
import useDHFlag from '~/hooks/useDHFlag';
import { LINK_PREFIX } from '../../v2/components/Wizard';
import { isNil } from 'ramda';

import { useRecoilState } from 'recoil';
import flowActions from '../../v2/state';
import getFlowActionsToClientActions from '~/scenes/Automation/v2/util/getFlowActionsToClientActions';
import DatHuisLoading from '~/components/DatHuisLoading';
import generateMapsCombined from '../../v2/components/UpdateAction/components/Selector/utils/generateMapsCombined';

const text = {
  pageTitle: 'Automation',
  title: 'Automation',
  addFlowLabelv1: 'Nieuwe flow v1',
  addFlowLabelv2: 'Nieuwe flow v2',
  noFlows: 'Je hebt nog geen flows',
  emptyStateDescription:
    'Benader leads en relaties met gepersonaliseerde marketing campagnes.',
  notification:
    'Maak kennis met de nieuwe flowbuilder v2! Al je bestaande flows zijn beschikbaar en vind je onderaan deze pagina. Binnenkort wordt de bestaande flowbuilder vervangen. Al je bestaande flows worden dan automatisch overgezet.',
  deleteAllFlows: 'Delete all flows',
};

export const FLOW_LIST_LIMIT = 10;

const FlowList: React.FC<RouteComponentProps> = () => {
  const account = useCurrentAccount();
  const [version, setVersion] = useState<string>(uuidv4());
  const isDeveloper = useDHFlag('is-developer');
  const [legacySortSettings, legacyUpdateSortSettings] = useSortSettings(
    'flowSortSettingsLegacy',
  );

  const [sortSettings, updateSortSettings] =
    useSortSettings('flowSortSettings');

  const resetFlowState = useResetFlowState();

  useEffect(() => {
    resetFlowState();
  }, [resetFlowState]);

  const baseVariables = useMemo(
    () => ({
      accountId: account.id,
      limit: FLOW_LIST_LIMIT,
    }),
    [account],
  );

  const { data, fetchMore, networkStatus, loading, error, updateQuery } =
    useGetFlowsV2Query({
      variables: baseVariables,
      fetchPolicy: 'network-only',
    });

  const [deleteFlow] = useDeleteFlowV2Mutation();

  const onDeleteAllFlows = () => {
    data &&
      data.getFlowsV2 &&
      data.getFlowsV2.items.forEach(async item => {
        await deleteFlow({
          variables: {
            id: item.id,
            accountId: account.id,
          },
        });
      });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const [tableData, setTableData] = useState<Array<FlowListData>>([]);

  const {
    data: legacyData,
    fetchMore: legacyFetchMore,
    networkStatus: legacyNetworkStatus,
    loading: legacyLoading,
    error: legacyError,
    updateQuery: legacyUpdateQuery,
  } = useGetFlowsQuery({
    variables: baseVariables,
  });
  const [legacyTableData, setLegacyTableData] = useState<
    Array<FlowListDataLegacy>
  >([]);

  useEffect(() => {
    if (data?.getFlowsV2) {
      const {
        subjectMap,
        conditionMap,
        directoryMap,
        primitiveInputMap,
        instanceMap,
      } = generateMapsCombined(data.getFlowData);

      const availableActions =
        data?.getFlowData.availableActions.filter(
          (action): action is FlowData___ActionFragment => !isNil(action),
        ) ?? [];

      setTableData(
        composeFlowListData(
          data?.getFlowsV2?.items || [],
          baseVariables,
          version,
          sortSettings,
          {
            flowBlueprintId: data.getFlowsV2.items[0]?.id ?? '',
            opts: {
              subjectMap,
              ...conditionMap,
              directoryMap,
            },
            instanceMap,
            primitiveInputMap,
            primitiveInput: data.getFlowData.primitiveInput,
            primitiveListInput: data.getFlowData.primitiveListInput,
            superSubjects: data.getFlowData.superSubjects,
            subjects: data.getFlowData.subjects,
            instances: data.getFlowData.instance,
            accountId: account.id,
            availableActions,
            initialFlow: {
              flowName: data.getFlowsV2.items[0]?.name ?? 'no name',
              flowDescription:
                data.getFlowsV2.items[0]?.description ?? 'no description',
              enabled: data.getFlowsV2.items[0]?.enabled ?? true,
              maximumFlowRun: data.getFlowsV2.items[0]?.maximumFlowRun ?? 0,
              actions: [],
            },
          },
        ),
      );
    }
  }, [data, baseVariables, version, sortSettings, account.id]);

  useEffect(() => {
    setLegacyTableData(
      memoizedComposeFlowListLegacyData(
        legacyData?.getFlows?.items || [],
        baseVariables,
        version,
        legacySortSettings,
      ),
    );
  }, [
    baseVariables,
    legacyData?.getFlows?.items,
    legacySortSettings,
    sortSettings,
    version,
  ]);
  const nextToken = data?.getFlowsV2?.nextToken;

  const loadMore = () =>
    fetchMore({
      variables: {
        ...baseVariables,
        nextToken: nextToken || null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        const prevData = previousResult.getFlowsV2;
        const newData = fetchMoreResult.getFlowsV2;

        return {
          ...previousResult,
          getFlowsV2: {
            ...prevData,
            items: [...prevData.items, ...(newData.items || [])],
            nextToken: newData.nextToken,
          },
        };
      },
    });
  const loadMoreLegacy = () =>
    legacyFetchMore({
      variables: {
        ...baseVariables,
        nextToken: legacyData?.getFlows?.nextToken || null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        const prevData = previousResult.getFlows;
        const newData = fetchMoreResult.getFlows;

        return {
          ...previousResult,
          getFlows: {
            ...prevData,
            items: [...prevData.items, ...(newData.items || [])],
            nextToken: newData.nextToken,
          },
        };
      },
    });

  const [actions, setActions] = useRecoilState(flowActions);

  useEffect(() => {
    if (data && data?.getFlowsV2.items.length !== 0 && actions.length === 0) {
      const actions = data?.getFlowsV2.items
        .map(({ StartAction }) => StartAction)
        .filter(x => x);
      // @ts-ignore
      setActions(getFlowActionsToClientActions(actions ?? []));
    }
  }, [actions.length, data, data?.getFlowsV2.items, setActions]);

  useEffect(
    () =>
      globalHistory.listen(() => {
        setActions(() => []);
      }),
    [],
  );

  if (!data) return <DatHuisLoading />;

  return (
    <ContentContainerDefault style={{ maxWidth: '1400px' }}>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <Heading1 color={{ group: 'primary' }} margin={[null, null, 'xl', null]}>
        {text.title}
      </Heading1>
      <Heading3 margin={[null, null, 'l', null]}>
        Flows <Superscript>v2 - Nieuw!</Superscript>
      </Heading3>

      <Toast
        id="flows-v2-before-migration"
        message={text.notification}
        dismissable={false}
      />

      <JustificationContainer
        align="start"
        justification="start"
        margin={['xl', null, 'xxl', null]}
      >
        <Toolbar>
          <Button
            size="medium"
            appearance="secondary"
            label={text.addFlowLabelv2}
            icon="plus"
            onClick={() => navigate(`${LINK_PREFIX}/v2/`)}
            dataTestId={TEST_ID.OVERVIEW_HEADER}
          />
          {isDeveloper && (
            <Button
              size="medium"
              appearance="danger"
              label={text.deleteAllFlows}
              icon="trashcan"
              onClick={onDeleteAllFlows}
            />
          )}
        </Toolbar>
      </JustificationContainer>

      {tableData.length === 0 ? (
        loading ? (
          <StyledLoading />
        ) : (
          <TemporaryEmptyState dataTestId={TEST_ID.EMPTY_STATE} />
        )
      ) : (
        <InfiniteScroll
          fetchMoreFn={loadMore}
          hasMore={nextToken != null}
          data-testid={TEST_ID.CONTAINER}
        >
          <FlowListTable
            isV2={true}
            columns={flowListColumns(
              (key: string, direction: SortDirection) => {
                updateSortSettings({
                  sortField: key,
                  sortDirection: direction,
                });
                setVersion(uuidv4());
              },
              version,
              true,
            )}
            data={tableData}
            loading={loading}
            networkStatus={networkStatus}
            error={error != null}
            onSuccessfulDelete={flowId => {
              updateQuery(getFlowsUpdateAfterRemoveQueryFunction(flowId));

              const nextID = uuidv4();
              setVersion(nextID);
            }}
            onSuccessfulEnabledChange={(newStatus, flowId) => {
              updateQuery(
                getFlowsUpdateAfterUpdatingStatusFunction(flowId, newStatus),
              );

              setVersion(uuidv4());
            }}
          />
        </InfiniteScroll>
      )}
      <>
        <Divider margin={['xl', null, 'l', null]} />
        <Heading3 margin={[null, null, 'l', null]}>
          Flows <Superscript>v1</Superscript>
        </Heading3>

        <JustificationContainer
          align="start"
          justification="start"
          margin={[null, null, 'xxl', null]}
        >
          <Toolbar>
            <Button
              size="medium"
              appearance="secondary"
              label={text.addFlowLabelv1}
              icon="plus"
              onClick={() => navigate(`${LINK_PREFIX}/v1/`)}
            />
          </Toolbar>
        </JustificationContainer>
        {legacyTableData.length !== 0 ? (
          <InfiniteScroll
            fetchMoreFn={loadMoreLegacy}
            initialLoad={legacyLoading}
            hasMore={legacyData?.getFlows.nextToken != null}
            data-testid={TEST_ID.CONTAINER}
          >
            <FlowListTable
              isV2={false}
              columns={flowListColumns(
                (key: string, direction: SortDirection) => {
                  legacyUpdateSortSettings({
                    sortField: key,
                    sortDirection: direction,
                  });
                  setVersion(uuidv4());
                },
                version,
              )}
              data={legacyTableData}
              loading={legacyLoading}
              networkStatus={legacyNetworkStatus}
              error={legacyError != null}
              onSuccessfulDelete={flowId => {
                legacyUpdateQuery(
                  getFlowsUpdateAfterRemoveQueryFunctionLegacy(flowId),
                );

                setVersion(uuidv4());
              }}
              onSuccessfulEnabledChange={(newStatus, flowId) => {
                legacyUpdateQuery(
                  getFlowsUpdateAfterUpdatingStatusFunctionLegacy(
                    flowId,
                    newStatus,
                  ),
                );

                setVersion(uuidv4());
              }}
            />
          </InfiniteScroll>
        ) : legacyLoading ? (
          <StyledLoading />
        ) : (
          <TemporaryEmptyState dataTestId={TEST_ID.EMPTY_STATE} />
        )}
      </>
    </ContentContainerDefault>
  );
};

const getFlowsUpdateAfterRemoveQueryFunction =
  (
    flowId: string,
  ): ((
    previousQueryResult: GetFlowsV2Query,
    options: { variables: GetFlowsV2QueryVariables },
  ) => GetFlowsV2Query) =>
  previousResult => {
    const newList = previousResult.getFlowsV2.items.filter(
      flow => flow.id !== flowId,
    );

    return {
      ...previousResult,
      getFlowsV2: {
        ...previousResult.getFlowsV2,
        items: newList,
      },
    };
  };

const getFlowsUpdateAfterRemoveQueryFunctionLegacy =
  (
    flowId: string,
  ): ((
    previousQueryResult: GetFlowsQuery,
    options: { variables: GetFlowsQueryVariables },
  ) => GetFlowsQuery) =>
  previousResult => {
    const newList = previousResult.getFlows.items.filter(
      flow => flow.id !== flowId,
    );

    return {
      ...previousResult,
      getFlows: {
        ...previousResult.getFlows,
        items: newList,
      },
    };
  };

const getFlowsUpdateAfterUpdatingStatusFunction =
  (
    newStatus: boolean,
    flowId: string,
  ): ((
    previousQueryResult: GetFlowsV2Query,
    options: { variables: GetFlowsV2QueryVariables },
  ) => GetFlowsV2Query) =>
  previousResult => {
    const newList = previousResult.getFlowsV2.items.map(flow => {
      if (flow.id === flowId) {
        return {
          ...flow,
          enabled: newStatus,
        };
      }

      return flow;
    });

    return {
      ...previousResult,
      getFlowsV2: {
        ...previousResult.getFlowsV2,
        items: newList,
      },
    };
  };

const getFlowsUpdateAfterUpdatingStatusFunctionLegacy =
  (
    newStatus: boolean,
    flowId: string,
  ): ((
    previousQueryResult: GetFlowsQuery,
    options: { variables: GetFlowsQueryVariables },
  ) => GetFlowsQuery) =>
  previousResult => {
    const newList = previousResult.getFlows.items.map(flow => {
      if (flow.id === flowId) {
        return {
          ...flow,
          enabled: newStatus,
        };
      }

      return flow;
    });

    return {
      ...previousResult,
      getFlows: {
        ...previousResult.getFlows,
        items: newList,
      },
    };
  };

const StyledLoading = styled(Loading)<{}>(
  ({ theme }) => css`
    max-width: 150px;
    margin: 0 auto ${theme.space('m')} auto;
  `,
);

const Superscript = styled.sup<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('base')};
  `,
);

// TODO: Delete this component and use EmptyStateComponent after removing flows v1
const TemporaryEmptyState = ({ dataTestId }) => {
  const theme = useTheme();

  return (
    <div style={{ marginTop: '70px' }}>
      <StyledSvg
        width="150"
        height="160"
        viewBox="-100 -50 200 140"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.7,-76.2C59,-69.3,72.2,-59.3,80.7,-46.1C89.2,-32.9,92.9,-16.4,92.6,-0.2C92.2,16.1,87.9,32.1,79.5,45.6C71.2,59,58.9,69.8,44.9,76.9C31,84.1,15.5,87.6,0,87.5C-15.4,87.4,-30.8,83.8,-44.6,76.6C-58.4,69.3,-70.4,58.5,-79.2,45.1C-87.9,31.8,-93.3,15.9,-92.9,0.2C-92.5,-15.4,-86.3,-30.9,-77.7,-44.4C-69,-57.9,-57.9,-69.4,-44.5,-76.8C-31.2,-84.2,-15.6,-87.4,-0.2,-87.1C15.3,-86.8,30.5,-83,44.7,-76.2Z"
          fill="#ffffff"
        />
      </StyledSvg>

      <JustificationContainer
        dataTestId={dataTestId}
        align="center"
        direction="column"
        style={{ position: 'relative', minHeight: '180px' }}
      >
        <div style={{ maxWidth: '3rem' }}>
          <Illustration
            name="automation"
            secondaryColor={theme.color('danger')}
          />
        </div>
        <Heading2 size="base">{text.noFlows}</Heading2>
        <Body>{text.emptyStateDescription}</Body>
      </JustificationContainer>
    </div>
  );
};

const StyledSvg = styled.svg<{}>`
  position: absolute;
  z-index: 0;
  left: 50%;
  transform: translate(-50%, 0);
`;

export default FlowList;
