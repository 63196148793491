import React from 'react';
import styled, { css } from 'styled-components';
import { linkStyles } from '~/components/Link';
import { ApolloClient } from '@apollo/client';
import query from '~/graphql/query/GetZapierTrigger';
import {
  FlowOutputType,
  GetZapierTriggerQueryResult,
  GetZapierTriggersQuery,
  ZapierFieldType,
} from '~/graphql/types';
import { ZapierTriggerAction } from './types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';
import { HandledFlowParameterMappingParameter } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import {
  withAccountContext,
  WithAccountContext,
} from '~/contexts/AccountContext';

import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import asZapierTriggerActionInput from './asZapierTriggerActionInput';
import { FLOW_ACTION_TYPE } from '../constants';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import ActionExplanationHeader from '../ActionExplanationHeader';
import ActionContentContainer from '../ActionContentContainer';
import FloatingLabel from '~/components/FloatingLabel';
import { InputGroup, Dropdown } from '~/components/Inputs';

import cleanedFilename from '~/util/cleanedFilename';
import { Loading } from '~/components';
import {
  AppStatus_Zapier,
  useGetAppStatusesQuery,
  useGetZapierTriggersQuery,
} from '~/graphql/types';
import Catalog from '~/Catalog';
import { useApolloClient } from '@apollo/client';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { HandledFlowParameterMapping } from '../Base/FlowParameter/FlowParameterMapping/types.flow';
import ZapierInsertOrUpdateModal from '../Base/FlowParameter/ParameterValue/TemplateString/components/ZapierInsertOrUpdateModal';
import {
  emptyBooleanPointerParameterValue,
  emptyNumberPointerParameterValue,
  emptyStringPointerParameterValue,
} from '../Base';
import Button from '~/components/Button';
import {
  getVariableAndFieldLabel,
  UNKNOWN_FIELD_NAME,
} from '../util/stashHelpers';
import { deepEquals } from '~/util/object';

const text = {
  explanation: 'Verstuur naar Zapier',
  invalidZapierTriggerError:
    'De verbinding met de uitgaande koppeling is verbroken',
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
  noTriggersFound: 'Geen uitgaande koppelingen gevonden',
  clickHereToPersonalise: 'Geef dit veld een waarde',
};
type MyProps = ActionSubscriberProps & {
  id: string;
  loading: boolean;
  isHiddenFromView: boolean;
  positionString: string;
  initialActionProps: ZapierTriggerAction;
  zapierAppStatus: AppStatus_Zapier | undefined;
  client: ApolloClient<object>;
  accountId: string;
  zapierTriggers: GetZapierTriggersQuery['getZapierTriggers'] | undefined;
  hasGenericError: boolean;
  onTriggersDropdownOpened: () => void;
};
type WithoutValidatorProps = WithAccountContext & MyProps;
type Props = WithoutValidatorProps & WithValidatorListProps;
type State = {
  /** To rerender the component you can update this number. Needed to rerender the description */
  renderChangeCounter: number;
  key: string | null;

  zapierTriggerId: string | null;
  fieldsLoading: boolean;

  variableStashVersion: string | null;

  variableStash: FlowVariableStash;

  mappings: Array<HandledFlowParameterMapping>;

  zapierTriggerFields: Array<{
    name: string;
    label: string;
    type:
      | FlowOutputType.FlowOutputString
      | FlowOutputType.FlowOutputNumber
      | FlowOutputType.FlowOutputBoolean;
  }>;

  isValid: boolean;
};
class ZapierTriggerActionComponent extends React.Component<Props, State> {
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    const { initialActionProps, zapierAppStatus, zapierTriggers } = props;

    const initialVariableStash = emptyVariableStash();
    this.state = {
      renderChangeCounter: 0,
      key: null,
      fieldsLoading: false,

      mappings: initialActionProps.mappings,
      zapierTriggerId:
        initialActionProps.zapierTriggerId ??
        (zapierTriggers && zapierTriggers.length > 0
          ? zapierTriggers[0].id
          : null),

      variableStashVersion: null,

      variableStash: initialVariableStash,

      zapierTriggerFields: [],
      isValid: ZapierTriggerActionComponent.isValid(
        zapierAppStatus,
        zapierTriggers,
        initialActionProps.zapierTriggerId,
        initialActionProps.mappings,
        initialVariableStash,
      ),
    };
  }
  static isValid = (
    zapierAppStatus: AppStatus_Zapier | null | undefined,
    zapierTriggers: GetZapierTriggersQuery['getZapierTriggers'] | undefined,
    zapierTriggerId: string | null,
    mappings: Array<HandledFlowParameterMapping>,
    variableStash: FlowVariableStash,
  ) => {
    if (!zapierAppStatus || !zapierAppStatus.enabled) return false;
    const zapierTriggersOptions = extractZapierTriggersOptions(
      zapierAppStatus,
      zapierTriggers,
    );

    const selectedOptionIdx = zapierTriggersOptions.findIndex(
      option => option.payload === zapierTriggerId,
    );
    const hasNoZapierTriggerSelected = selectedOptionIdx == -1;

    if (hasNoZapierTriggerSelected) return false;

    if (
      mappings.some(
        mapping =>
          mapping.mapping.variable?.field &&
          getVariableAndFieldLabel(
            variableStash,
            mapping.mapping.variable.name,
            mapping.mapping.variable.field.name,
          ) === UNKNOWN_FIELD_NAME,
      )
    ) {
      return false;
    }
    return true;
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const zapierAppStatus = nextProps.zapierAppStatus
      ? nextProps.zapierAppStatus
      : null;
    const zapierTriggers = nextProps.zapierTriggers
      ? nextProps.zapierTriggers
      : [];

    const isValidState = ZapierTriggerActionComponent.isValid(
      zapierAppStatus,
      zapierTriggers,
      prevState.zapierTriggerId,
      prevState.mappings,
      prevState.variableStash,
    );
    if (isValidState === prevState.isValid) return null;

    return {
      isValid: isValidState,
    };
  }

  componentDidUpdate(_prevProps, prevState) {
    const { zapierTriggers, loading } = this.props;
    if (loading) return;

    const currentMappings =
      prevState.mappings ?? this.props.initialActionProps.mappings;

    const selectedTriggerDetails = zapierTriggers?.find(
      trigger => trigger.id === this.state.zapierTriggerId,
    );

    if (!selectedTriggerDetails) return;
    const fields = selectedTriggerDetails.fields.map(field => ({
      name: field.key,
      label: field.label,
      type: getOutputType(field.type),
    }));

    const fieldsWithNoVariablesSet = fields.filter(
      field =>
        !currentMappings.some(mapping => mapping.mappingId === field.name),
    );

    const mappings = [
      // Filter out unknown mappings. (In this case mappings related to removed fields)
      ...currentMappings.filter(m => fields.some(f => f.name === m.mappingId)),
      // Initialise all possible fields that are not set with an empty pointer of their type
      ...fieldsWithNoVariablesSet.map(field => ({
        mappingId: field.name,
        mapping:
          field.type === FlowOutputType.FlowOutputString
            ? emptyStringPointerParameterValue()
            : field.type === FlowOutputType.FlowOutputNumber
            ? emptyNumberPointerParameterValue()
            : emptyBooleanPointerParameterValue(),
      })),
    ];

    if (
      !deepEquals(mappings, prevState.mappings) ||
      !deepEquals(fields, prevState.zapierTriggerFields)
    )
      this.setState({ mappings, zapierTriggerFields: fields }, () => {
        this.props.announceChanges();
      });
  }
  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.validate,
    });

    this.setState({
      key,
    });

    this.setState({
      fieldsLoading: true,
    });
    this.props.client
      .query({
        query,
        variables: {
          accountId: this.props.accountId,
          id: this.state.zapierTriggerId,
        },
      })
      .then(({ data }: GetZapierTriggerQueryResult) => {
        this.setState({
          fieldsLoading: false,
        });

        const fields = data?.getZapierTrigger.fields
          ? data?.getZapierTrigger.fields.map(field => ({
              name: field.key,
              label: field.label,
              type: getOutputType(field.type),
            }))
          : [];

        const fieldsWithNoVariablesSet = fields.filter(
          field =>
            !this.state.mappings.some(
              mapping => mapping.mappingId === field.name,
            ),
        );

        const mappings = [
          ...this.state.mappings,
          // Initialise all possible fields that are not set with an empty pointer of their type
          ...fieldsWithNoVariablesSet.map(field => ({
            mappingId: field.name,
            mapping:
              field.type === FlowOutputType.FlowOutputString
                ? emptyStringPointerParameterValue()
                : field.type === FlowOutputType.FlowOutputNumber
                ? emptyNumberPointerParameterValue()
                : emptyBooleanPointerParameterValue(),
          })),
        ];

        this.setState(
          {
            zapierTriggerFields: fields,
            mappings,
          },
          () => {
            this.props.announceChanges({ noDataChange: true });
          },
        );
      })
      .catch(() => {
        this.setState({
          fieldsLoading: false,
        });
        return [];
      });
    this.props.announceChanges({ noDataChange: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { isHiddenFromView } = nextProps;
    const { variableStashVersion: nextVariableStashVersion } = nextState;
    const { variableStashVersion } = this.state;

    if (nextVariableStashVersion !== variableStashVersion) {
      return true;
    }

    return !isHiddenFromView;
  }

  validate = () => {
    const { validate } = this.props;

    // always call validate to make sure components know to show validation
    const componentValidation = validate();

    const { isValid } = this.state;
    if (!isValid) return false;

    return componentValidation;
  };

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asZapierTriggerActionInput(this.getActionProp(), treeNode, defaults);

  setVariableStash = (newStash: FlowVariableStash) => {
    if (this._isMounted) {
      this.setState({
        variableStashVersion: newStash.version,
        variableStash: newStash,
      });
    }
  };

  getActionProp = (): ZapierTriggerAction => {
    const { id } = this.props;

    return {
      id,
      type: FLOW_ACTION_TYPE.ZAPIER_TRIGGER,
      mappings: this.state.mappings,
      zapierTriggerId: this.state.zapierTriggerId,
    };
  };

  insertOrUpdateVariable = (
    newVariable: HandledFlowParameterMappingParameter,
    _selectedVariableId: string | null,
    mappingId: string,
  ) => {
    if (newVariable.variable == null) return;

    const { mappings } = this.state;

    const foundMappingIdx = mappings.findIndex(
      mapping => mapping.mappingId === mappingId,
    );

    if (foundMappingIdx == -1) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} -> insertOrUpdateVariable can't find the mapping with mappingId ${mappingId} in the latest mappings ${JSON.stringify(
          mappings,
          null,
          2,
        )}`,
      );
    }

    const newMappings = [...mappings];
    newMappings[foundMappingIdx] = {
      mappingId: mappingId,
      mapping: newVariable,
    };

    this.setState({ mappings: newMappings }, () => {
      this.props.announceChanges();
    });
  };

  onTriggerChange = ({ option }) => {
    // Whenever the selected zapier trigger changes, we request the possible fields from BE by calling getZapierTrigger
    this.setState(
      {
        zapierTriggerId: option.payload,
      },
      () => {
        this.setState({
          fieldsLoading: true,
        });
        this.props.client
          .query({
            query,
            variables: {
              accountId: this.props.accountId,
              id: option.payload,
            },
          })
          .then(({ data }: GetZapierTriggerQueryResult) => {
            this.setState({
              fieldsLoading: false,
            });

            const fields = data?.getZapierTrigger.fields
              ? data?.getZapierTrigger.fields.map(field => ({
                  name: field.key,
                  label: field.label,
                  type: getOutputType(field.type),
                }))
              : [];

            this.setState(
              {
                zapierTriggerFields: fields,
                // We reset the mappings by initializing all possible mappings with empty pointers
                mappings: fields.map(field => ({
                  mappingId: field.name,
                  mapping:
                    field.type === FlowOutputType.FlowOutputString
                      ? emptyStringPointerParameterValue()
                      : field.type === FlowOutputType.FlowOutputNumber
                      ? emptyNumberPointerParameterValue()
                      : emptyBooleanPointerParameterValue(),
                })),
              },
              () => {
                this.props.announceChanges();
              },
            );
          })
          .catch(() => {
            this.setState({
              fieldsLoading: false,
            });
            return [];
          });
        this.props.announceChanges();
      },
    );
  };

  render() {
    const {
      fieldsLoading,
      zapierTriggerId,
      mappings,
      zapierTriggerFields,
      variableStash,
    } = this.state;

    const { loading, zapierAppStatus, zapierTriggers } = this.props;

    const zapierTriggersOptions = extractZapierTriggersOptions(
      zapierAppStatus,
      zapierTriggers,
    );

    const selectedOptionIdx = zapierTriggersOptions.findIndex(
      option => option.payload === zapierTriggerId,
    );
    const hasZapierTriggerBeenRemoved =
      this.props.initialActionProps.zapierTriggerId && selectedOptionIdx == -1;

    const {
      subscribeValidator,
      unsubscribeValidator,
      isHiddenFromView,
      positionString,
      hasGenericError,
    } = this.props;
    const action = this.getActionProp();

    if (loading) return <Loading />;

    if (hasGenericError) {
      return (
        <BaseActionComponent
          positionString={positionString}
          actionLabel={text.explanation}
          action={action}
          variableStash={variableStash}
          subscribeValidator={subscribeValidator}
          unsubscribeValidator={unsubscribeValidator}
          isHiddenFromView={isHiddenFromView}
        >
          <ActionExplanationHeader title={text.explanation} />
          <ActionContentContainer>
            <InputGroup>
              <DropdownContainer>
                {labelComponent}
                <Dropdown
                  disabled
                  options={[]}
                  error={text.genericErrorMessage}
                />
              </DropdownContainer>
            </InputGroup>
          </ActionContentContainer>
        </BaseActionComponent>
      );
    }
    if (!zapierTriggers || zapierTriggers.length < 1) {
      return (
        <BaseActionComponent
          positionString={positionString}
          actionLabel={text.explanation}
          action={action}
          variableStash={variableStash}
          subscribeValidator={subscribeValidator}
          unsubscribeValidator={unsubscribeValidator}
          isHiddenFromView={isHiddenFromView}
        >
          <ActionExplanationHeader title={text.explanation} />
          <ActionContentContainer>
            <InputGroup>
              <DropdownContainer>
                {labelComponent}
                <Dropdown disabled options={[]} error={text.noTriggersFound} />
                <EditTriggerLabelComponent zapierTriggerId={null} />
              </DropdownContainer>
            </InputGroup>
          </ActionContentContainer>
        </BaseActionComponent>
      );
    }

    return (
      <BaseActionComponent
        positionString={positionString}
        actionLabel={text.explanation}
        action={action}
        variableStash={variableStash}
        subscribeValidator={subscribeValidator}
        unsubscribeValidator={unsubscribeValidator}
        isHiddenFromView={isHiddenFromView}
      >
        <ActionExplanationHeader title={text.explanation} />
        <ActionContentContainer>
          <InputGroup>
            <DropdownContainer>
              {labelComponent}
              <Dropdown
                options={zapierTriggersOptions}
                error={
                  hasZapierTriggerBeenRemoved
                    ? text.invalidZapierTriggerError
                    : ''
                }
                selectedOptionIdx={selectedOptionIdx}
                onDropdownOpened={this.props.onTriggersDropdownOpened}
                onChange={this.onTriggerChange}
              />

              <EditTriggerLabelComponent zapierTriggerId={zapierTriggerId} />
            </DropdownContainer>
          </InputGroup>
          <ActionFieldsComponent
            variableStash={variableStash}
            insertOrUpdateVariable={this.insertOrUpdateVariable}
            zapierTriggerFields={zapierTriggerFields}
            fieldsLoading={fieldsLoading}
            mappings={mappings}
            onDelete={mappingIdToDelete =>
              this.setState(
                {
                  mappings: mappings.map(m =>
                    m.mappingId === mappingIdToDelete
                      ? {
                          mapping: getEmptyMappingForType(m.mapping.type),
                          mappingId: mappingIdToDelete,
                        }
                      : m,
                  ),
                },
                () => this.props.announceChanges(),
              )
            }
          />
        </ActionContentContainer>
      </BaseActionComponent>
    );
  }
}

const DropdownContainer = styled.div<{}>`
  width: 100%;
`;

const LabelContainer = styled.div<{}>`
  display: flex;
  align-items: flex-end;
`;

const EditTriggerLabelContainer = styled.div<{}>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  ${({ theme }) => css`
    margin-top: ${theme.space('s')};
    font-size: ${theme.fs('s')};
  `};
`;

const labelComponent = (
  <LabelContainer>
    <FloatingLabel>Uitgaande Koppeling</FloatingLabel>
  </LabelContainer>
);

const EditTriggerLabelComponent: React.FC<{ zapierTriggerId: string | null }> =
  ({ zapierTriggerId }) => {
    if (zapierTriggerId == null) {
      return (
        <EditTriggerLabelContainer>
          <NewTabLink
            href={`/-/apps/zapier/triggers/new`}
            style={{ whiteSpace: 'nowrap' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Nieuwe koppeling maken
          </NewTabLink>
        </EditTriggerLabelContainer>
      );
    }

    return (
      <EditTriggerLabelContainer>
        <NewTabLink
          style={{ whiteSpace: 'nowrap' }}
          href={`/-/apps/zapier/triggers/${zapierTriggerId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Koppeling aanpassen
        </NewTabLink>
      </EditTriggerLabelContainer>
    );
  };

const extractZapierTriggersOptions = (
  zapierAppStatus: AppStatus_Zapier | undefined,
  zapierTriggers: GetZapierTriggersQuery['getZapierTriggers'] | undefined,
) => {
  if (!zapierAppStatus?.enabled) return [];
  if (!zapierTriggers) return [];
  const zapierTriggersOptions = zapierTriggers.map(zapierTrigger => ({
    key: zapierTrigger.id,
    label: zapierTrigger.name,
    payload: zapierTrigger.id,
  }));

  return zapierTriggersOptions;
};

const ZapierTriggerActionComponentContainerThingyBobby: React.FC<Props> =
  props => {
    const client = useApolloClient();
    const accountId = useCurrentAccount().id;

    const {
      data: appStatusesData,
      loading: appStatusesLoading,
      error: appStatusesError,
    } = useGetAppStatusesQuery({
      displayName: cleanedFilename(__filename),
      variables: { accountId },
    });

    const {
      data: zapierTriggersData,
      loading: zapierTriggersLoading,
      error: zapierTriggersError,
      refetch: refetchZapierTriggers,
    } = useGetZapierTriggersQuery({
      displayName: cleanedFilename(__filename),
      variables: { accountId },
    });

    return (
      <ZapierTriggerActionComponent
        {...props}
        accountId={accountId}
        client={client}
        zapierTriggers={zapierTriggersData?.getZapierTriggers}
        loading={appStatusesLoading || zapierTriggersLoading}
        hasGenericError={
          !!appStatusesError ||
          !!zapierTriggersError ||
          (!appStatusesLoading && !appStatusesData) ||
          (!zapierTriggersLoading && !zapierTriggersData)
        }
        zapierAppStatus={appStatusesData?.getAppStatuses.find(
          (appStatus): appStatus is AppStatus_Zapier =>
            appStatus.__typename === 'AppStatus_Zapier',
        )}
        onTriggersDropdownOpened={() => refetchZapierTriggers({ accountId })}
      />
    );
  };

const ComplexStringInputContainer = styled.div<{}>`
  display: flex;

  ${({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `};
  position: relative;
`;

const ButtonContainer = styled.div<{}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${({ theme }) => css`
    margin-right: ${theme.space('base')};
  `};
`;

const FieldMappingContainer = styled.div<{}>`
  display: flex;
  width: 100%;
`;

type ActionFieldsComponentProps = {
  zapierTriggerFields: Array<{
    name: string;
    label: string;
    type:
      | FlowOutputType.FlowOutputString
      | FlowOutputType.FlowOutputNumber
      | FlowOutputType.FlowOutputBoolean;
  }>;
  fieldsLoading: boolean;
  mappings: Array<HandledFlowParameterMapping>;
  variableStash: FlowVariableStash;
  insertOrUpdateVariable: (
    newVariable: HandledFlowParameterMappingParameter,
    _selectedVariableId: string | null,
    mappingId: string,
  ) => void;
  onDelete: (key: string) => void;
};

const NewTabLink = styled.a<{}>(linkStyles);
const ActionFieldsComponent: React.FC<ActionFieldsComponentProps> = ({
  variableStash,
  insertOrUpdateVariable,
  zapierTriggerFields,
  fieldsLoading,
  mappings,
  onDelete,
}) => {
  if (fieldsLoading) return <Loading />;

  const fieldMappingsComponent = mappings.map(mapping => (
    <>
      <ZapierInsertOrUpdateModal
        key={mapping.mappingId}
        variableUpdaterId={mapping.mappingId}
        triggerOutputType={
          zapierTriggerFields.find(field => field.name === mapping.mappingId)
            ?.type ?? FlowOutputType.FlowOutputString
        }
        mappings={mappings}
        onSaveFunction={(...args) =>
          insertOrUpdateVariable(...args, mapping.mappingId)
        }
      >
        {({ toggleVariableFunction }) => {
          const label =
            mapping.mapping.variable == null ||
            mapping.mapping.variable.field == null
              ? text.clickHereToPersonalise
              : getVariableAndFieldLabel(
                  variableStash,
                  mapping.mapping.variable.name,
                  mapping.mapping.variable.field.name,
                );

          return (
            <>
              <ComplexStringInputContainer key={mapping.mappingId}>
                <FieldMappingContainer>
                  <ButtonContainer>
                    <MappingFieldTitleContainer>
                      {zapierTriggerFields.find(
                        triggerField => triggerField.name === mapping.mappingId,
                      )?.label || UNKNOWN_FIELD_NAME}
                    </MappingFieldTitleContainer>
                    <Label
                      appearance={
                        label === UNKNOWN_FIELD_NAME
                          ? 'danger'
                          : mapping.mapping.variable?.field?.name == null
                          ? 'primary'
                          : 'secondary'
                      }
                      onClick={() => {
                        toggleVariableFunction();
                      }}
                    >
                      {label === UNKNOWN_FIELD_NAME
                        ? 'Onbekend veld - klik hier om aan te passen'
                        : label}
                    </Label>
                  </ButtonContainer>
                  <Button
                    ghost
                    disabled={fieldsLoading || mapping.mapping.variable == null}
                    size="small"
                    appearance="danger"
                    icon="delete"
                    onClick={e => {
                      e.preventDefault();
                      onDelete(mapping.mappingId);
                    }}
                  />
                </FieldMappingContainer>
              </ComplexStringInputContainer>
              <LineDivider />
            </>
          );
        }}
      </ZapierInsertOrUpdateModal>
    </>
  ));

  return (
    <>
      <HeaderLabel>{'Velden'}</HeaderLabel>
      {fieldMappingsComponent}
    </>
  );
};

const MappingFieldTitleContainer = styled.div<{}>`
  font-weight: bold;
  ${({ theme }) => css`
    margin-bottom: ${theme.space('xxs')};
  `};
`;

const HeaderLabel = styled.div<{}>`
  font-weight: bold;
  ${({ theme }) => css`
    margin-bottom: ${theme.space('base')};
    font-size: ${theme.fs('base')};
  `};
`;

const Label = styled.span<{
  appearance: 'danger' | 'primary' | 'secondary';
}>`
  white-space: nowrap;
  cursor: pointer;
  grid-column: label-start / label-end;

  ${({ theme, appearance }) => css`
    margin-right: ${theme.space('s')};
    font-size: ${theme.fs('base')};
    text-decoration: ${appearance === 'secondary' ? 'none' : 'underline'};
    color: ${appearance === 'danger'
      ? `${theme.color('danger')};`
      : appearance === 'primary'
      ? `${theme.color('primary', 'light')};`
      : theme.color('success')};
  `};
`;

const LineDivider = styled.div<{}>`
  &:not(:last-child) {
    display: flex;
    height: 1px;
    flex-grow: 1;

    ${({ theme }) => css`
      margin-bottom: ${theme.space('xxs')};
      background: #f0f0f0;
    `};
  }
`;

const getEmptyMappingForType = type =>
  type === FlowOutputType.FlowOutputString
    ? emptyStringPointerParameterValue()
    : type === FlowOutputType.FlowOutputNumber
    ? emptyNumberPointerParameterValue()
    : emptyBooleanPointerParameterValue();

const getOutputType = (
  fieldType: ZapierFieldType,
):
  | FlowOutputType.FlowOutputBoolean
  | FlowOutputType.FlowOutputNumber
  | FlowOutputType.FlowOutputString => {
  switch (fieldType) {
    case ZapierFieldType.Boolean:
      return FlowOutputType.FlowOutputBoolean;
    case ZapierFieldType.String:
      return FlowOutputType.FlowOutputString;
    case ZapierFieldType.Number:
      return FlowOutputType.FlowOutputNumber;
  }
};

export default withAccountContext<
  Omit<
    MyProps,
    | 'zapierTriggers'
    | 'zapierAppStatus'
    | 'client'
    | 'accountId'
    | 'hasGenericError'
    | 'loading'
    | 'onTriggersDropdownOpened'
  >
>(
  withValidatorList<
    Omit<
      WithoutValidatorProps,
      | 'zapierTriggers'
      | 'zapierAppStatus'
      | 'client'
      | 'accountId'
      | 'hasGenericError'
      | 'loading'
      | 'onTriggersDropdownOpened'
    >
  >(ZapierTriggerActionComponentContainerThingyBobby),
);
