import { isArray } from 'lodash';

import { ApolloError } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { reportErrorToTracker } from '~/util/assertion';
import { getErrorType, getErrorMessage } from '~/util/errorHandling';
import { getOperationNameFromDocument } from './documentParsers';

type Options = {
  name: string;
  doc: DocumentNode;
  doNotReportErrorTypes?: Array<string>;
  doNotReportErrorMessages?: Array<string>;
  ignoreAutomaticErrorReporting?: boolean;
  onError?: (error: ApolloError) => void;
  variables?: $Object;
};
const errorReporter = (error: ApolloError, options: Options) => {
  const {
    doNotReportErrorTypes = [],
    doNotReportErrorMessages = [],
    ignoreAutomaticErrorReporting = false,
    onError,
    variables,
    doc,
  } = options;

  if (ignoreAutomaticErrorReporting) {
    if (onError !== undefined) onError(error);

    return null;
  }

  const operationName = getOperationNameFromDocument(doc);

  // --- Define beforeSend function ---
  // TODO: Check if this is still needed, I don't expect so.
  // If this has not presented a problem yet after 01-01-2022, remove this.
  //
  // const beforeSend = (event: BugsnagEvent) => {
  //   // We set our own groupingHash to avoid all queries and mutations to be seen as the same error in bugsnag
  //   // Bugsnag groups by surrounding code and that is all the same because of our abstraction
  //   event.groupingHash = `${name || 'UndefinedName'}--${operationName}`;
  // };

  // --- Define the error reporter options object ---
  const errorOptions = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    errorInfo: {
      ...error.extraInfo,
      operationName,
      documentVariables: variables,
    },
  };

  if (error.networkError)
    reportErrorToTracker(error.networkError, errorOptions);

  if (isArray(error.graphQLErrors)) {
    error.graphQLErrors.forEach(graphqlError => {
      let shouldIgnore = false;

      const errorType = getErrorType(graphqlError);

      if (errorType != null && doNotReportErrorTypes.includes(errorType)) {
        shouldIgnore = true;
      }

      if (
        doNotReportErrorMessages.some(message => {
          const errorMessage = getErrorMessage(graphqlError);
          return errorMessage != null && errorMessage.includes(message);
        })
      ) {
        shouldIgnore = true;
      }

      if (!shouldIgnore) {
        reportErrorToTracker(
          new Error(`[GraphQL] ${graphqlError.message}`),
          errorOptions,
        );
      }
    });
  }

  if (typeof onError === 'function') onError(error);

  return;
};

export default errorReporter;
