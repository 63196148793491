import {
  Field,
  StringField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { StringConditionField } from '../types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { FLOW_CONDITION_PRIMITIVE_STRING_OPERATION } from './constants';
import { emptyStringPrimitiveParameterValue } from '../../../FlowParameter';

const emptyFlowConditionStringField = (
  field: Field | StringField,
): StringConditionField => {
  let initialValue;
  if ('options' in field && field.options != null) {
    initialValue = field.options[0];
  }

  return {
    ...field,
    type: FIELD_TYPE.STRING,
    operation: {
      type: FLOW_CONDITION_PRIMITIVE_STRING_OPERATION.EQUALS,
      value: emptyStringPrimitiveParameterValue(initialValue),
      negate: false,
    },
  };
};

export default emptyFlowConditionStringField;
