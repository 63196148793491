import gql from 'graphql-tag';

export default gql`
  mutation UpdateAccountRelation(
    $accountId: ID!
    $userId: ID!
    $update: AccountRelationship_Update!
  ) {
    updateAccountRelation(
      accountId: $accountId
      userId: $userId
      update: $update
    ) {
      accountId
      userId
      original {
        status
        role
      }
      updated {
        status
        role
      }
    }
  }
`;
