import theme from '~/theme';
import { Appearance } from '.';

type AppearanceState = 'main' | 'error' | 'disabled';
type Styles = {
  color: string;
  background: string;
  border: string;
};
type DropdownAppearance = {
  [key in Appearance]: {
    [key in AppearanceState]: {
      base: Styles;
      hover?: Styles;
    };
  };
};

const border = theme.getTokens().border.width.s;
const primaryLight = theme.color('primary', 'light');
const primary = theme.color('primary');
const white = theme.color('white');
const danger = theme.color('danger');
const dangerDark = theme.color('danger', 'dark');
const grey = theme.color('grey');
const secondaryDark = theme.color('secondary', 'dark');

const transparentBorder = `${border} solid transparent`;
const errorBorder = `${border} solid ${danger}`;
const hoverErrorBorder = `${border} solid ${dangerDark}`;
const disabledBorder = `${border} solid ${grey}`;
const text = theme.color('text');
const whiteDark = theme.color('white', 'dark');

const dropdownAppearances: DropdownAppearance = {
  outline: {
    main: {
      base: {
        color: primaryLight,
        background: white,
        border: `${theme.getTokens().border.width.s} solid ${primaryLight}`,
      },
      hover: {
        color: white,
        background: primaryLight,
        border: `${border} solid ${primaryLight}`,
      },
    },
    error: {
      base: {
        color: danger,
        background: white,
        border: errorBorder,
      },
      hover: {
        color: white,
        background: dangerDark,
        border: hoverErrorBorder,
      },
    },
    disabled: {
      base: { background: white, color: grey, border: disabledBorder },
    },
  },
  borderless: {
    main: {
      base: {
        color: primaryLight,
        background: white,
        border: transparentBorder,
      },
      hover: {
        color: primary,
        background: white,
        border: transparentBorder,
      },
    },
    error: {
      base: {
        color: danger,
        background: white,
        border: transparentBorder,
      },
      hover: {
        color: dangerDark,
        background: white,
        border: transparentBorder,
      },
    },
    disabled: {
      base: { background: white, color: grey, border: transparentBorder },
    },
  },
  filled: {
    main: {
      base: {
        color: white,
        background: primary,
        border: `${border} solid ${primary}`,
      },
      hover: {
        color: white,
        background: secondaryDark,
        border: `${border} solid ${secondaryDark}`,
      },
    },
    error: {
      base: {
        color: white,
        background: danger,
        border: errorBorder,
      },
      hover: {
        color: white,
        background: dangerDark,
        border: hoverErrorBorder,
      },
    },
    disabled: {
      base: {
        color: white,
        background: grey,
        border: disabledBorder,
      },
    },
  },
  default: {
    main: {
      base: {
        color: text,
        background: white,
        border: `${border} solid ${grey}`,
      },
      hover: {
        color: text,
        background: white,
        border: `${border} solid ${primaryLight}`,
      },
    },
    error: {
      base: {
        color: danger,
        background: white,
        border: errorBorder,
      },
      hover: {
        color: dangerDark,
        background: white,
        border: hoverErrorBorder,
      },
    },
    disabled: {
      base: {
        color: grey,
        background: whiteDark,
        border: disabledBorder,
      },
    },
  },
};

export default dropdownAppearances;
