import React from 'react';
import DHRouter from '~/components/DHRouter';

import ValueReportList from './ValueReportList';
import ValueReportDetailsContainer from './ValueReportDetailsContainer';
import withErrorBoundary from '~/ErrorBoundary';
import { RouteComponentProps, useLocation } from '@reach/router';
import SaveBarBuster from '~/components/SaveBarBuster';
import NotFound from '~/components/404';
import DatHuisLoading from '~/components/DatHuisLoading';
import useApp from '~/hooks/useApp';

type Props = RouteComponentProps;
const ValueReport: React.FC<Props> = withErrorBoundary<Props>(() => {
  const location = useLocation();
  const { app, loading } = useApp('AppStatus_ValuationReport');

  if (loading) return <DatHuisLoading />;
  if (!app?.appStatus.enabled) return <NotFound />;

  return (
    <>
      <DHRouter>
        <ValueReportList path="/" location={location} />
        <ValueReportDetailsContainer path="/:reportId/*" location={location} />
      </DHRouter>
      <SaveBarBuster />
    </>
  );
});

export default ValueReport;
