import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import BaseButton from '../components/Base';
import EditUrlBlock from './components/EditUrlBlock';
import { tooltipText } from '../text';
import { insertLink } from '~/components/PluginsEditor/commands/modify/link';
import ResponsivePopup, {
  BoxShadowContainer,
} from '~/components/ResponsivePopup';
import { Editor } from 'slate';
import { getSelectedElement } from '~/components/PluginsEditor/commands';
import { DHEditor } from '~/components/PluginsEditor/types';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  editor: DHEditor;
};

const InsertLinkButton: React.FC<Props> = ({ dataTestId, editor, ...rest }) => {
  const [showPopup, setShowPopup] = useState(false);
  const baseButtonRef = useRef<HTMLButtonElement | null>(null);

  const otherElementsSelected =
    getSelectedElement(editor, 'all')?.element != null;

  const urlText =
    editor.selection && !otherElementsSelected
      ? Editor.string(editor, editor.selection)
      : '';

  return (
    <Container {...rest}>
      <BaseButton
        icon="link"
        onClick={() => setShowPopup(true)}
        tooltipMessage={showPopup ? '' : tooltipText.insertLink}
        dataTestId={dataTestId}
        active={showPopup}
        ref={baseButtonRef}
      />

      <ResponsivePopup
        onClickOutside={() => setShowPopup(false)}
        isVisible={showPopup}
        elementToPlaceUnder={baseButtonRef.current}
        dataTestId={TEST_ID.INSERT_LINK_POPUP}
      >
        <BoxShadowContainer>
          <EditUrlBlock
            onSave={({ url, text }) => {
              insertLink(editor, url, text);
              setShowPopup(false);
            }}
            textValue={urlText}
          />
        </BoxShadowContainer>
      </ResponsivePopup>
    </Container>
  );
};

const Container = styled.div<{}>`
  position: relative;
`;

export default InsertLinkButton;
