import { isEmpty, isNil, slice, take } from 'ramda';
import React from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import { Trigger } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor';
import RepresentationString from '~/scenes/Automation/v2/components/RepresentationString';
import {
  ConditionIssue,
  issuesByActionId,
} from '~/scenes/Automation/v2/state/flowIssues';
import SubCondition from './components/SubCondition';

export type Props = {
  dataTestId?: string;
  triggers: Array<Trigger>;
  actionId: string;
};

const NUMBER_OF_VISIBLE_TRIGGERS = 1;

const ConditionList: React.FC<Props> = ({ dataTestId, actionId, triggers }) => {
  const issues = useRecoilValue(issuesByActionId(actionId));

  const issueMap = issues.reduce((acc, issue) => {
    if (issue.type === 'ConditionIssue')
      acc[issue.id] = [...(acc[issue.id] || []), issue];

    return acc;
  }, {} as Record<string, Array<ConditionIssue>>);

  const visibleTriggers = take(NUMBER_OF_VISIBLE_TRIGGERS, triggers);
  const collapsedTriggers = slice(
    NUMBER_OF_VISIBLE_TRIGGERS,
    triggers.length,
    triggers,
  );

  return (
    <Container data-testid={dataTestId}>
      {visibleTriggers.map(trigger => {
        const hasIssue = !isNil(issueMap[trigger.id]);
        return (
          <GroupContainer key={trigger.id} $hasIssue={hasIssue}>
            <TriggerContainer
              $hasIssue={issueMap[trigger.id]?.some(({ conditionId }) =>
                isNil(conditionId),
              )}
            >
              <RepresentationString
                condition={trigger.trigger}
                actionId={actionId}
                hasIssue={hasIssue}
              />
            </TriggerContainer>
            <SubCondition
              trigger={trigger}
              issueMap={issueMap}
              actionId={actionId}
            />
          </GroupContainer>
        );
      })}
      {!isEmpty(collapsedTriggers) && (
        <FieldContainer withIssue={!isEmpty(issueMap)}>
          {`+ ${collapsedTriggers.length} meer`}
          {!isEmpty(issueMap) && ' (met problemen)'}
        </FieldContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space('xxs')};
    width: 100%;
  `,
);

const FieldContainer = styled.div<{ withIssue?: boolean }>(
  ({ theme, withIssue = false }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xxs')};
    margin-top: ${theme.space('base')};
    border: ${theme.getTokens().border.width.s} solid
      ${withIssue ? theme.color('danger') : theme.color('grey', 'light')};
  `,
);

const GroupContainer = styled.div<{ $hasIssue: boolean }>(
  ({ theme, $hasIssue }) => css`
    padding: ${theme.space('xxs')};
    border-radius: ${theme.getTokens().border.radius.base};

    display: flex;
    flex-direction: column;
    gap: ${theme.space('xxs')};
    border: ${theme.getTokens().border.width.s} solid
      ${$hasIssue ? theme.color('danger') : theme.color('grey', 'light')};
  `,
);

const TriggerContainer = styled.div<{ $hasIssue: boolean }>(
  ({ theme }) => css`
    padding: ${theme.space('xxs')} ${theme.space('s')};
  `,
);

export default ConditionList;
