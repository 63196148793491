import React from 'react';
import { Props } from '..';

const TextBackground: React.FC<Props> = ({
  color = 'currentColor',
  ...props
}: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.09998 16.3002L12 7.7002H12.4L15.3 16.3002"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1 13.3999H14.2"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6 21H4.4C3.6 21 3 20.4 3 19.6V4.4C3 3.6 3.6 3 4.4 3H19.6C20.4 3 21 3.6 21 4.4V19.6C21 20.4 20.4 21 19.6 21Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TextBackground;
