import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Logo from '../Logo';
import { Option, SelectedOption } from '~/components/Inputs/Dropdown';
import { NavigationLink } from '../..';
import MenuIcon from '../MenuIcon/index';
import MenuList from './components/MenuList';

export type Props = {
  dataTestid?: string;
  links: Array<NavigationLink>;
};

export type State = {
  options: Array<Option>;
  onChange: (selectedOption: SelectedOption) => void;
};

const MobileMenu: React.FC<Props> = ({ dataTestid, links, ...rest }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleMenuOpen = () => {
    setMenuOpen(prev => !prev);
  };

  return (
    <Container data-testid={dataTestid}>
      <Navigation menuOpen={menuOpen} {...rest}>
        <Logo />
        <Menu onClick={handleMenuOpen}>
          <MenuIcon isOpen={menuOpen} />
          <Label>Menu</Label>
        </Menu>
      </Navigation>
      <MenuList
        menuOpen={menuOpen}
        links={links.map(link => ({
          ...link,
          onClick: () => setMenuOpen(false),
        }))}
        setMenuOpen={setMenuOpen}
      />
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    position: relative;
    z-index: ${theme.z('top')};
  `,
);

type NavigationProps = {
  menuOpen: boolean;
};

const Navigation = styled.header<NavigationProps>(
  ({ theme, menuOpen }) => css`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: ${theme.color('white')};
    z-index: ${theme.z('top')};
    padding: 0 ${theme.space('xxl')};
    font-size: ${theme.fs('l')};
    box-shadow: ${!menuOpen && theme.boxShadow('base')};
  `,
);

const Menu = styled.div<{}>`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('xl')} 0;
    margin-left: ${theme.space('base')};
    font-size: ${theme.space('l')};
    line-height: ${theme.space('l')};
  `,
);

export default MobileMenu;
