import gql from 'graphql-tag';
import FlowParameterFileFields from './FlowParameterFileFields';

export default gql`
  fragment FlowEmailAttachmentFields on Flow_EmailAttachment {
    __typename
    file {
      ...FlowParameterFileFields
    }
    inlineId
    url
  }

  ${FlowParameterFileFields}
`;
