import {
  FlowAction,
  FlowFieldsV2Fragment,
  FlowV2_ActionFragment,
} from '~/graphql/types';
import {
  ClientFlowAction,
  ClientFlowActionIfElse,
  ClientFlowActionStart,
  ClientFlowActionWait,
  ClientFlowActionContactAddTag,
  ClientFlowActionZapierTrigger,
  ClientFlowActionTaskCreate,
  ClientFlowActionSendEmailPlain,
  ClientFlowActionContactDetails,
  ClientFlowActionContactDeleteTag,
  ClientFlowActionContactAssign,
  ClientFlowActionRealworksSendContact,
} from '~/graphql/types.client';
import { assertNeverWithoutThrowing } from '~/util/assertion';
import { FlowConditionConnector } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/components/OperatorDropdown';
import getTriggerForConditionList from '../../components/UpdateAction/components/ConditionEditor/utils/getTriggerForConditionList';

export const getFlowActionToClientAction = (
  action: FlowV2_ActionFragment,
): ClientFlowAction | null => {
  switch (action.__typename) {
    case 'FlowV2_Action_IfElse': {
      const getTriggerResult = getTriggerForConditionList(action.condition);
      const clientAction: ClientFlowActionIfElse = {
        ...action,
        actionType: FlowAction.IfElse,
        connector:
          getTriggerResult.error == null
            ? getTriggerResult.result.connector
            : FlowConditionConnector.And,
        triggers:
          getTriggerResult.error == null
            ? getTriggerResult.result.triggers
            : [],
      };

      return clientAction;
    }

    case 'FlowV2_Action_Wait': {
      const getTriggerResult = getTriggerForConditionList(action.condition);

      const clientAction: ClientFlowActionWait = {
        ...action,
        actionType: FlowAction.Wait,
        connector:
          getTriggerResult.error == null
            ? getTriggerResult.result.connector
            : FlowConditionConnector.And,
        triggers:
          getTriggerResult.error == null
            ? getTriggerResult.result.triggers
            : [],
      };

      return clientAction;
    }

    case 'FlowV2_Action_Start': {
      const conditionList = getTriggerForConditionList(
        action.conditionList ?? {
          __typename: 'FlowV2_ConditionList',
          exp: '(0)',
          conditions: [],
        },
      );
      const clientAction: ClientFlowActionStart = {
        ...action,
        actionType: FlowAction.Start,
        connector:
          conditionList.error == null
            ? conditionList.result.connector
            : FlowConditionConnector.And,
        triggers:
          conditionList.error == null ? conditionList.result.triggers : [],
      };

      return clientAction;
    }

    case 'FlowV2_Action_Contact_AddTag':
      const clientAction: ClientFlowActionContactAddTag = {
        ...action,
        actionType: FlowAction.ContactAddTag,
      };

      return clientAction;
    case 'FlowV2_Action_Contact_Assign': {
      const clientAction: ClientFlowActionContactAssign = {
        ...action,
        actionType: FlowAction.ContactAssign,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Contact_DeleteTag': {
      const clientAction: ClientFlowActionContactDeleteTag = {
        ...action,
        actionType: FlowAction.ContactDeleteTag,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Contact_Details': {
      const clientAction: ClientFlowActionContactDetails = {
        ...action,
        actionType: FlowAction.ContactDetails,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Realworks_SendContact': {
      const clientAction: ClientFlowActionRealworksSendContact = {
        ...action,
        actionType: FlowAction.RealworksSendContact,
      };
      return clientAction;
    }
    case 'FlowV2_Action_SendEmail_Plain': {
      const clientAction: ClientFlowActionSendEmailPlain = {
        ...action,
        actionType: FlowAction.SendEmailPlain,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Task_Create': {
      const clientAction: ClientFlowActionTaskCreate = {
        ...action,
        actionType: FlowAction.TaskCreate,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Zapier_Trigger': {
      const clientAction: ClientFlowActionZapierTrigger = {
        ...action,
        actionType: FlowAction.ZapierTrigger,
      };

      return clientAction;
    }

    default: {
      assertNeverWithoutThrowing(action, '');
      return null;
    }
  }
};

const getFlowActionsToClientActions = (
  actions: FlowFieldsV2Fragment['Actions'],
): Array<ClientFlowAction> => {
  const clientActions = actions
    .map(getFlowActionToClientAction)
    .filter((action): action is ClientFlowAction => action !== null);

  return clientActions;
};

export default getFlowActionsToClientActions;
