import { Flow_Parameter__Boolean__Input } from '~/graphql/types';
import { BooleanParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import cleanedFilename from '~/util/cleanedFilename';

export default (
  parameterValue: BooleanParameterValue,
): Flow_Parameter__Boolean__Input => {
  switch (parameterValue.type) {
    case PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE:
      if (parameterValue.value == null) {
        throw new Error(
          `Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} value is not identified`,
        );
      }

      return {
        Boolean: {
          Primitive: {
            value: parameterValue.value,
          },
        },
      };
    case PARAMETER_VALUE_TYPE.BOOLEAN_POINTER:
      if (
        parameterValue.variable == null ||
        parameterValue.variable.field == null
      ) {
        throw new Error(
          `Validation should have caught that the parameterValue ${JSON.stringify(
            parameterValue,
            null,
            2,
          )} has no field`,
        );
      }

      return {
        Boolean: {
          Pointer: {
            variable: parameterValue.variable.name,
            field: parameterValue.variable.field.name,
          },
        },
      };
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Field value input conversion cannot handle (${JSON.stringify(
          parameterValue,
          null,
          2,
        )})`,
      );
  }
};
