import gql from 'graphql-tag';

import ConnectedEmailAliasFields from '../fragments/ConnectedEmailAliasFields';

export default gql`
  mutation StartEmailAliasVerification(
    $account: EmailAliasAccountVerification
    $office: EmailAliasOfficeVerification
    $user: EmailAliasUserVerification
    $accountId: ID!
  ) {
    startEmailAliasVerification(
      accountId: $accountId
      account: $account
      office: $office
      user: $user
    ) {
      ...ConnectedEmailAliasFields
    }
  }

  ${ConnectedEmailAliasFields}
`;
