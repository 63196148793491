import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';

export type Props = {
  clockWise: number;
};

const ChevronIcon: React.FC<Props> = ({ clockWise }) => (
  <StyledChevron
    name="chevron"
    inline
    data-testid="nav-link-settings"
    clockwise={clockWise}
  />
);

const StyledChevron = styled(Icon)<{}>(
  ({ theme }) => css`
    display: flex;
    cursor: pointer;
    color: ${theme.color('text')};
    font-size: ${theme.fs('l')};

    &:hover,
    &.active {
      color: ${theme.color('primary', 'light')};
      svg {
        stroke: ${theme.color('primary', 'light')};
      }
    }

    ${theme.mq.lessThan('mobile')`
    font-size: ${theme.fs('xl')};
      `}
  `,
);

export default ChevronIcon;
