import React from 'react';
import Label from '~/components/EventTimelineV2/components/Block/components/Data/components/Label';
import Link from '~/components/Link';
import Markdown from '~/components/Markdown';
import {
  Body,
  Heading4,
  Heading5,
  Heading6,
  Strong,
  Variant,
} from '~/components/Typography/index';
import { MarkdownBlock } from '~/graphql/types';

export type Props = Omit<MarkdownBlock, '__typename' | 'id'> & {
  dataTestId?: string;
  withoutMargin?: boolean;
};

const MarkdownBlockComponent: React.FC<Props> = ({
  css,
  label,
  value,
  withoutMargin,
}) => (
  <>
    {label && <Label>{label}</Label>}

    <Markdown
      value={value}
      options={{
        remarkReactComponents: getComponents({ css, withoutMargin }),
      }}
    />
  </>
);

export default MarkdownBlockComponent;

const getComponents = (extraProps?: {
  css?: string | null;
  withoutMargin?: boolean;
}) => ({
  h1: props => (
    <Heading4
      {...props}
      {...extraProps}
      variant={Variant.primary}
      color={{ group: 'primary' }}
      css={extraProps?.css}
    />
  ),
  h2: props => (
    <Heading5
      {...props}
      {...extraProps}
      fontWeight="semiBold"
      color={{ group: 'text' }}
      css={extraProps?.css}
    />
  ),
  h3: props => (
    <Heading6
      {...props}
      {...extraProps}
      color={{ group: 'text', variant: 'light' }}
      fontWeight="regular"
      css={extraProps?.css}
    />
  ),
  strong: props => (
    <Strong
      {...props}
      {...extraProps}
      inline
      color={{ group: 'primary', variant: 'light' }}
      css={extraProps?.css}
      style={{ fontSize: 'inherit' }}
    />
  ),
  p: props => (
    <Body size="s" {...props} {...extraProps} css={extraProps?.css} />
  ),
  a: props => (
    <Link {...props} {...extraProps} to={props.href} css={extraProps?.css} />
  ),
});
