import { isNil } from 'ramda';
import React from 'react';
import { Handle, Position } from 'reactflow';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import { Heading5, Variant } from '~/components/Typography/index';
import { FlowAction } from '~/graphql/types';
import { ClientFlowActionContactAssign } from '~/graphql/types.client';
import useOffices from '~/hooks/useOffices';
import useUsers from '~/hooks/useUsers';
import { issuesByActionId } from '~/scenes/Automation/v2/state/flowIssues';
import getCardHeading from '../../../../utils/getCardHeading';
import Issue from '../../../Issues/InCard';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';

export type Props = Omit<
  CardProps<ClientFlowActionContactAssign>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const AssignContactCard: React.FC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const { office: officeId, user: userId } = data.action;

    const officeName = useOffices({}).find(
      office => office.id === officeId,
    )?.name;
    const userName = useUsers({ officeIds: [officeId] }).find(
      user => user.id === userId,
    )?.name;

    const issues = useRecoilValue(issuesByActionId(data.id));

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.ContactAssign}
          heading={getCardHeading(FlowAction.ContactAssign)}
          selected={selected}
          disabled={disabled}
          data={data}
        >
          {
            <JustificationContainer direction="column">
              <WithMargins>
                <Heading5 inline variant={Variant.primary}>
                  Vestiging:{' '}
                </Heading5>
                {isNil(officeName) ? '-' : officeName}
              </WithMargins>
              <div>
                <Heading5 inline variant={Variant.primary}>
                  Gebruiker:{' '}
                </Heading5>
                {userId == null ? 'Alle gebruikers' : userName ?? '-'}
              </div>
              {issues &&
                issues.map(issue => <Issue key={issue.message} {...issue} />)}
            </JustificationContainer>
          }
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

const WithMargins = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

export default AssignContactCard;
