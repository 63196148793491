export enum FIELD_TYPE {
  STRING = 'Field_Type_String',
  TEMPLATE_STRING = 'Field_Type_Template_String',
  NUMBER = 'Field_Type_Number',
  USER = 'Field_Type_User',
  OFFICE = 'Field_Type_Office',
  EMAIL = 'Field_Type_Email',
  EMAIL_TEMPLATE = 'Field_Type_EmailTemplate',
  BOOLEAN = 'Field_Type_Boolean',
}
