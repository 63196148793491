import { TableHeaderDefinitionCellType } from '~/components/DataTables/types.flow';

import TEST_ID from './index.testid';
import TableHeaderCell from '~/components/DataTables/util/TableHeaderCell';
import { SortDirection } from '~/graphql/types';
import { truncate } from 'lodash';

export type SortToggleFunction = (
  sortField: string,
  sortDirection: SortDirection | null,
) => void;

export const columns = (
  onSort: SortToggleFunction,
): Array<TableHeaderDefinitionCellType<any>> => [
  TableHeaderCell.string({
    label: 'Naam',
    dataProperty: 'name',
    width: 100,
    dataTestid: TEST_ID.NAME,
    sorting: {
      onSort: sortDirection => onSort('name', sortDirection),
    },
  }),
  TableHeaderCell.string({
    label: 'Velden',
    dataProperty: 'fields',
    dataTestid: TEST_ID.FIELDS,
    width: 250,
    renderFunction: (value: string) =>
      truncate(value, {
        length: 50,
        separator: ' ',
      }),
  }),

  TableHeaderCell.action({
    dataTestid: TEST_ID.ACTION,
  }),
];
