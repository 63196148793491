import { FlowConditionAppFundaBrochureRequestDetails } from './types.flow';

import { emptyFundaBrochureRequestEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';
import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';

const emptyFlowConditionAppFundaBrochureRequestDetails = (): Omit<
  FlowConditionAppFundaBrochureRequestDetails,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_FUNDA_BROCHUREREQUEST_DETAILS
    .type,
  fields: [],
  event: emptyFundaBrochureRequestEventParameterValue(),
  allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
});

export default emptyFlowConditionAppFundaBrochureRequestDetails;
