import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';

const Header = styled(JustificationContainer)(
  ({ theme }) => css`
    flex-direction: row-reverse;
    margin-bottom: ${theme.space('xl')};
  `,
);

export default Header;
