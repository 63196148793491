import React, { useRef } from 'react';
import { GetOfficeQuery, UserStatus, useGetOfficeQuery } from '~/graphql/types';

import { AppErrorScreen, Loading } from '~/components';
import convertMailboxes from '~/util/converters/convertMailboxes';
import { ALIAS_VERIFICATION_TIMEOUT } from '~/scenes/Settings/constants';
import useUserRights from '~/hooks/useUserRights';
import UpdateOfficeComponent, {
  UpdateOfficeType,
} from '../UpdateOfficeComponent';
import useSessionHydration from '~/hooks/useSessionHydration';
import type { ListItemForDeletion } from '~/components/Modals/components/ListItemCard';
import useOffices from '~/hooks/useOffices';
import useUsers, { ExpandedUser } from '~/hooks/useUsers';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import type { RouteComponentProps } from '@reach/router';

export type Props = RouteComponentProps<{ officeId: string }>;

const OfficeDetails: React.FC<Props> = ({ officeId = '' }) => {
  const { id: accountId } = useCurrentAccount();

  const { update: accountAdminMayUpdate, delete: mayDelete } = useUserRights({
    category: 'Office',
  });
  const { update: officeAdminMayUpdate } = useUserRights({
    category: 'Office',
    entity: {
      type: 'office',
      id: officeId,
    },
  });
  const mayEdit = accountAdminMayUpdate || officeAdminMayUpdate;

  const pollingTimeout = useRef<any>(null);

  const offices = useOffices({});

  const [, refetchSessionHydration] = useSessionHydration();

  const { data, loading, refetch, startPolling, stopPolling } =
    useGetOfficeQuery({
      variables: { officeId, accountId },
    });

  const officeUsers = useUsers({
    officeIds: [officeId],
    statuses: [
      UserStatus.Active,
      UserStatus.Pending,
      UserStatus.InvitedPending,
    ],
  });

  if (loading) return <Loading />;

  if (!data) return <AppErrorScreen />;

  const currentOffice = data.getOffice;
  if (!currentOffice) return <AppErrorScreen />;

  const signature = currentOffice.Signature ?? null;

  return (
    <UpdateOfficeComponent
      accountId={accountId}
      office={convertOffice(currentOffice)}
      mailboxes={convertMailboxes(currentOffice.Mailboxes)}
      canEdit={mayEdit}
      onSuccess={() => refetch().then(() => refetchSessionHydration())}
      onSuccessfulMailboxDelete={() =>
        refetch().then(() => refetchSessionHydration())
      }
      onSuccessfulAliasVerificationStart={async () => {
        await refetch();
        startPolling(3000);

        // stop polling if it is not fixed within a minute
        pollingTimeout.current = setTimeout(
          stopPolling,
          ALIAS_VERIFICATION_TIMEOUT,
        );
      }}
      onSuccessfulAliasVerificationEnd={() => {
        stopPolling();
      }}
      officeUsersList={[
        ...convertUsersForDeleteOfficeModal(officeUsers),
        emptyUserForOfficeDeletionListItem,
      ]}
      officesLength={offices.length}
      mayDeleteOffice={mayDelete}
      signature={signature}
      refetch={refetch}
    />
  );
};

type Office = Exclude<GetOfficeQuery['getOffice'], undefined | null>;

const convertOffice = (office: Office): UpdateOfficeType => {
  const { id, name, email, phone, img, address } = office;

  return {
    id,
    name,
    email: email == null ? null : email,
    phone: phone == null ? null : phone,
    img: img == null ? null : img,
    address: address == null ? null : address,
  };
};

const convertUsersForDeleteOfficeModal = (
  users: Array<ExpandedUser>,
): Array<ListItemForDeletion> =>
  users.map(user => ({
    id: user.id,
    name: user.name,
    img: 'img' in user ? user.img : undefined,
  }));

export const NOT_ASSIGNED_TO_USER_ID = 'not-assigned-to-user';
export const emptyUserForOfficeDeletionListItem = {
  id: NOT_ASSIGNED_TO_USER_ID,
  img: null,
  name: 'Alle gebruikers',
};

export default OfficeDetails;
