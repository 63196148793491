import type { ContactFiltersTypeFragment } from '~/graphql/types';

export type TypeMap = Partial<
  {
    [typeId in string]: ContactFiltersTypeFragment;
  }
>;

export const generateTypeMap = (
  types: Array<ContactFiltersTypeFragment>,
): { typeMap: TypeMap } => {
  const typeMap = types.reduce((prev, curr) => {
    prev[curr.typeId] = curr;

    return prev;
  }, {} as TypeMap);

  return {
    typeMap,
  };
};

export const getType = (
  typeId: string,
  typeMap: TypeMap,
): ContactFiltersTypeFragment | undefined => typeMap[typeId];

export default getType;
