import React, { ReactElement, useState } from 'react';

import { NumberPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { OptionOf } from '~/components/Inputs/Dropdown';
import { WithControlledSwitcherProps } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { Input, Dropdown } from '~/components/Inputs';
import TEST_ID from './NumberPrimitiveParameterValueComponent.testid';
import AddParameterValueButton from '../AddParameterValueButton';
import { InputWithAddButtonInGroup } from '~/components/Inputs/InputWithAddButton';
import cleanedFilename from '~/util/cleanedFilename';
import { onlyDigits } from '~/util/string';
import emptyNumberPrimitiveParameterValue from './emptyNumberPrimitiveParameterValue';

const text = {
  inputLabel: 'Waarde',
};
type Props = {
  value: NumberPrimitiveParameterValue;
  change: WithControlledSwitcherProps<NumberPrimitiveParameterValue>;
  options?: Array<OptionOf<number>>;
  inputLabel?: string;
  error?: string;
};
const NumberPrimitiveParameterValueComponent = ({
  value,
  change,
  options,
  inputLabel,
  error,
}: Props) => {
  const [stateValue, setStateValue] =
    useState<NumberPrimitiveParameterValue | null>(
      change.controlled ? null : value,
    );

  let setValue;
  let inputValue: number | null;
  let handleAdding;
  let inputComponent: ReactElement | null = null;
  if (change.controlled) {
    inputValue = value.value;
    setValue = change.onChange;
    handleAdding = () => {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | handleAdding called on controlled component`,
      );
    };
    inputComponent = (
      <Input
        label={inputLabel || text.inputLabel}
        data-testid={TEST_ID.PRIMITIVE_INPUT}
        value={inputValue == null ? null : inputValue.toString()}
        onlyAllowNumbers
        error={error}
        onChange={e => {
          if (e.target != null) {
            const target = e.target as HTMLInputElement;
            const stringValue = target.value;

            let newNumberValue: number | null = null;

            if (stringValue != null && stringValue.length > 0) {
              const numberValue = parseInt(stringValue);
              newNumberValue = isNaN(numberValue) ? 0 : numberValue;
            }

            const newValue = {
              ...value,
              value: newNumberValue,
            };

            setValue(newValue);
          }
        }}
      />
    );
  } else {
    inputValue = stateValue == null ? null : stateValue.value;
    setValue = setStateValue;
    handleAdding = () => {
      if (stateValue == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | handleAdding called without a stateValue!`,
        );
      }

      change.onAdded(stateValue);

      setStateValue(emptyNumberPrimitiveParameterValue());
    };
    inputComponent = (
      <InputWithAddButtonInGroup
        label={inputLabel || text.inputLabel}
        data-testid={TEST_ID.PRIMITIVE_INPUT}
        value={inputValue == null ? null : inputValue.toString()} // error={error}
        onChange={e => {
          if (e.target != null) {
            const target = e.target as HTMLInputElement;
            const stringValue = onlyDigits(target.value);

            let newNumberValue: number | null = null;

            if (stringValue != null && stringValue.length > 0) {
              const numberValue = parseInt(stringValue);
              newNumberValue = isNaN(numberValue) ? 0 : numberValue;
            }

            const newValue = {
              ...value,
              value: newNumberValue,
            };

            setValue(newValue);
          }
        }}
        onSubmit={handleAdding}
      />
    );
  }

  if (options != null) {
    const selectedIdx = options.findIndex(
      option => option.payload === inputValue,
    );

    return (
      <>
        <Dropdown
          options={options}
          selectedOptionIdx={selectedIdx}
          onChange={({ option }) => {
            const newValue = { ...value, value: option.payload };

            setValue(newValue);
          }}
        />
        <AddParameterValueButton
          isVisible={!change.controlled}
          onClick={handleAdding}
        />
      </>
    );
  }

  return inputComponent;
};

export default NumberPrimitiveParameterValueComponent;
