import {} from 'react';
import { UserRole } from '~/graphql/types';
import { Relation } from '~/graphql/types.client.flow';
import useSessionHydration from '../useSessionHydration';

type Props = {
  type: Relation['__typename'];
  role: UserRole;
};
const useRelationshipForRole = ({ type, role }: Props): Array<Relation> => {
  const [{ relations }] = useSessionHydration();

  const relation = relations.filter(
    relation => relation.role === role && relation.__typename === type,
  );

  return relation;
};

export default useRelationshipForRole;
