import React from 'react';
import {
  FormData,
  PasswordConditions,
} from '../../../pages/setup-user-details';

import { Form, Field, PasswordField, EmailField } from '~/components/Forms';
import { FormUtils } from '~/components';
import Button from '~/components/Button';
import { Input, InputGroup } from '~/components/Inputs';
import { ActionWrapper, PasswordRulesText, ErrorMsg } from '../Auth.style';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';

type Props = {
  passwordConditions: PasswordConditions;
  onFormSubmit: (data: FormData) => void;
  onValidate: (passwordConditions: PasswordConditions) => void;
  initialValues: FormData;
  loading: boolean;
  disabled: boolean;
};
const defaultValues: FormData = {
  name: null,
  email: null,
  password: null,
  passwordRepeat: null,
  phone: null,
};

const text = {
  emailLabel: Catalog.emailLabel,
  nameLabel: 'Naam',
  passwordLabel: 'Wachtwoord',
  passwordRepeatLabel: 'Wachtwoord bevestigen',
  updateAccountButton: 'Opslaan',
  noName: 'Voer een naam in',
  phoneLabel: Catalog.phoneLabel,
  noPhone: Catalog.noPhone,
  invalidPhone: Catalog.invalidPhone,
  noPassword: 'Voer een wachtwoord in',
  noPasswordRepeat: 'Voer je wachtwoord nogmaals in',
  passwordsDoNotMatch: 'De wachtwoorden moeten overeenkomen',
  invalidPassword: 'Het wachtwoord voldoet niet aan de regels',
};
const SetupUserDetailsForm = (props: Props) => {
  const { onFormSubmit, onValidate, initialValues, loading, disabled } = props;

  const {
    charNumberValid,
    uppercaseValid,
    lowercaseValid,
    specialCharValid,
    numberValid,
  } = props.passwordConditions;

  const charNumberValidClass = charNumberValid ? 'valid' : 'invalid';
  const uppercaseValidClass = uppercaseValid ? 'valid' : 'invalid';
  const specialCharValidClass = specialCharValid ? 'valid' : 'invalid';
  const numberValidClass = numberValid ? 'valid' : 'invalid';
  const lowercaseValidClass = lowercaseValid ? 'valid' : 'invalid';

  return (
    <Form
      initialValues={{ ...defaultValues, ...initialValues }}
      onSubmit={onFormSubmit}
      validate={(fields: FormData) => validate(fields, onValidate)}
    >
      {({ handleSubmit, submitError, submitting, pristine }) => {
        const disabledInput = submitting || disabled || loading;
        const showHelpText = !disabled;

        return (
          <form
            id="setup-user"
            onSubmit={handleSubmit}
            data-testid="setup-user-form"
          >
            {submitError ? (
              <ErrorMsg data-testid="setup-submit-error-message">
                {submitError}
              </ErrorMsg>
            ) : null}

            <InputGroup>
              <EmailField name="email">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label={text.emailLabel}
                    disabled={true}
                    error={FormUtils.showError(error, touched)}
                    {...input}
                  />
                )}
              </EmailField>
            </InputGroup>

            <InputGroup>
              <Field name="name">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    autoFocus
                    large
                    label={text.nameLabel}
                    disabled={disabledInput}
                    error={FormUtils.showError(error, touched)}
                    {...input}
                  />
                )}
              </Field>
            </InputGroup>
            <InputGroup>
              <Field name="phone">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label={text.phoneLabel}
                    disabled={disabledInput}
                    error={FormUtils.showError(error, touched)}
                    {...input}
                  />
                )}
              </Field>
            </InputGroup>

            {showHelpText ? (
              <PasswordRulesText>
                Je wachtwoord moet minimaal bestaan{' '}
                <span
                  id="charNumberHint"
                  data-testid="char-numbertokens-validation"
                  className={charNumberValidClass}
                >
                  uit 8 tekens
                </span>
                ,{' '}
                <span
                  id="upperCaseHint"
                  data-testid="char-uppercase-validation"
                  className={uppercaseValidClass}
                >
                  een hoofdletter
                </span>
                ,{' '}
                <span
                  id="lowerCaseHint"
                  data-testid="char-lowercase-validation"
                  className={lowercaseValidClass}
                >
                  een kleine letter
                </span>
                ,{' '}
                <span
                  id="numberHint"
                  data-testid="char-number-validation"
                  className={numberValidClass}
                >
                  een nummer
                </span>{' '}
                en{' '}
                <span
                  id="specialCharHint"
                  data-testid="char-specialchar-validation"
                  className={specialCharValidClass}
                >
                  een speciaal teken
                </span>
                .
              </PasswordRulesText>
            ) : null}

            <InputGroup>
              <PasswordField name="password">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label={text.passwordLabel}
                    type="password"
                    disabled={disabledInput}
                    error={FormUtils.showError(error, touched)}
                    {...input}
                  />
                )}
              </PasswordField>
            </InputGroup>
            <InputGroup>
              <PasswordField name="passwordRepeat">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    large
                    label={text.passwordRepeatLabel}
                    type="password"
                    disabled={disabledInput}
                    error={FormUtils.showError(error, touched)}
                    {...input}
                  />
                )}
              </PasswordField>
            </InputGroup>
            <ActionWrapper>
              <Button
                appearance="secondary"
                size="medium"
                data-testid="submit-setup-user-details"
                loading={loading}
                type="submit"
                disabled={disabledInput || pristine || loading}
                label={text.updateAccountButton}
              />
            </ActionWrapper>
          </form>
        );
      }}
    </Form>
  );
};

const validate = (
  { name, phone, password, passwordRepeat }: FormData,
  onValidate: (passwordConditions: PasswordConditions) => void,
) => {
  const errors: {
    name: string | undefined;
    phone: string | undefined;
    password: string | undefined;
    passwordRepeat: string | undefined;
  } = {
    name: undefined,
    phone: undefined,
    password: undefined,
    passwordRepeat: undefined,
  };
  if (Validation.String.isEmpty(name)) {
    errors.name = text.noName;
  }

  if (Validation.String.isEmpty(phone)) {
    errors.phone = text.noPhone;
  }

  if (!Validation.Phone.isValid(phone)) {
    errors.phone = text.invalidPhone;
  }

  const didEnterPassword = Validation.String.isNonEmptyString(password);
  const didRepeatPassword = Validation.String.isNonEmptyString(passwordRepeat);

  if (!didEnterPassword) {
    errors.password = text.noPassword;
  }
  if (!didRepeatPassword) {
    errors.passwordRepeat = text.noPasswordRepeat;
  }

  if (didEnterPassword && didRepeatPassword && password !== passwordRepeat) {
    errors.passwordRepeat = text.passwordsDoNotMatch;
  }

  const charNumberValid = password && password.length >= 8 ? true : false;
  const uppercaseValid =
    password && Validation.Password.containsUpperCaseLetter(password)
      ? true
      : false;
  const specialCharValid =
    password && Validation.Password.containsSpecialCharacter(password)
      ? true
      : false;
  const lowercaseValid =
    password && Validation.Password.containsLowerCaseLetter(password)
      ? true
      : false;
  const numberValid =
    password && Validation.Password.containsNumber(password) ? true : false;

  onValidate({
    charNumberValid,
    uppercaseValid,
    specialCharValid,
    lowercaseValid,
    numberValid,
  });

  if (
    !charNumberValid ||
    !uppercaseValid ||
    !specialCharValid ||
    !lowercaseValid ||
    !numberValid
  ) {
    errors.password = text.invalidPassword;
  }

  return errors;
};

export default SetupUserDetailsForm;
