import {
  WidgetSettingsAppConfiguration,
  WidgetSettingsAppConfigurationItem,
  WidgetSettingsPinned,
} from '~/graphql/types';
import { PinnedAppsMap } from '../../states/pinnedApps';
import weightToIndex from '../weightToIndex';

const deserializePinnedFields = (pinned: WidgetSettingsPinned) => {
  const deserializedWeight = weightToIndex(pinned.weight);
  return { ...pinned, weight: deserializedWeight };
};

const isPinned = (
  app: WidgetSettingsAppConfiguration | WidgetSettingsAppConfigurationItem,
) => app.pinned?.enabled;
const getPinnedApps = (
  apps: Array<WidgetSettingsAppConfiguration>,
): PinnedAppsMap => {
  const pinnedApps = apps.reduce((acc, app) => {
    if (isPinned(app)) {
      if (!acc[app.type] && app.pinned) {
        acc[app.type] = deserializePinnedFields(app.pinned);
      }
    }

    if (app.items?.length) {
      app.items.map(item => {
        if (isPinned(item)) {
          if (!acc[item.id] && item.pinned) {
            acc[item.id] = deserializePinnedFields(item.pinned);
          }
        }
      });
    }

    return acc;
  }, {} as PinnedAppsMap);

  return pinnedApps;
};

export default getPinnedApps;
