import { Flow_ParameterMapping__Input } from '~/graphql/types';
import { FlowParameterMapping } from './types.flow';

import asFlowParameterMappingParameterInput from './asFlowParameterMappingParameterInput';

export default (
  parameterMapping: FlowParameterMapping,
): Flow_ParameterMapping__Input => {
  const { mappingId, mapping } = parameterMapping;

  if (mappingId == null || mapping == null) {
    throw new Error(
      'Should not occur: Validation should not allow a parameter mapping without a mapping',
    );
  }

  return {
    mappingId,
    mapping: asFlowParameterMappingParameterInput(mapping),
  };
};
