import React from 'react';

import { FileType } from '~/util/constants';
import {
  ImportContactsFileFormat,
  ImportContactsV2MutationVariables,
} from '~/graphql/types';
import Overlay from '~/components/ModalsV2/Overlay';
import Modal from '~/components/ModalsV2/Modal';
import { Heading3 } from '~/components/Typography/index';
import JustificationContainer from '~/components/JustificationContainer';
import ImportContactsContent from './components/ImportContactsContent';
import ImportContactsSuccess from './components/ImportContactsSuccess';
import { WorkflowStepType } from '../..';

const text = {
  header: 'Importeer contacten',
  importContactsSuccess: 'Contacten worden geïmporteerd',
};

export type Props = {
  dataTestId?: string;
  onClose: () => void;
  allowedFileExtensions: Array<FileType>;
  loading: boolean;
  fileFormat: ImportContactsFileFormat;
  workflowStep: WorkflowStepType;
  onAddMore: () => void;
  onImport: (
    variables: ImportContactsV2MutationVariables,
  ) => Promise<{ errors: Array<string> | null; success: boolean }>;
};

const MODAL_WIDTH = 650;
const ImportContactsModal: React.FC<Props> = ({
  dataTestId,
  onClose,
  workflowStep,
  onAddMore,
  ...rest
}) => (
  <Overlay dataTestId={dataTestId} onClose={onClose} root="import-contacts">
    <Modal maxWidth={MODAL_WIDTH}>
      <JustificationContainer
        align="center"
        direction="column"
        padding={['xl']}
        margin={['m']}
      >
        {workflowStep === 'MULTIPLE_CONTACTS' ? (
          <>
            <Heading3 color={{ group: 'primary' }}>{text.header}</Heading3>
            <ImportContactsContent {...rest} />
          </>
        ) : (
          <>
            <Heading3 color={{ group: 'primary' }}>
              {text.importContactsSuccess}
            </Heading3>
            <ImportContactsSuccess onClose={onClose} onAddMore={onAddMore} />
          </>
        )}
      </JustificationContainer>
    </Modal>
  </Overlay>
);

export default ImportContactsModal;
