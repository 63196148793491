import React from 'react';
import { useTransition, animated } from 'react-spring';
import styled, { css } from 'styled-components';

export type Props = {
  loading: boolean;
  height?: number;
  maxWidth?: string;
};
const ProgressBar = ({ loading, height = 8, maxWidth = '100%' }: Props) => {
  const transitions = useTransition(loading, {
    from: { width: '0%' },
    enter: { width: '99%' },
    leave: { width: '100%' },
    config: {
      mass: 1,
      tension: 400,
      friction: 20,
      duration: 700,
      clamp: true,
    },
  });

  return (
    <Outer $height={height} $maxWidth={maxWidth}>
      {transitions(
        (style, item) => item && <Inner style={style} $height={height} />,
      )}
    </Outer>
  );
};
type OuterProps = {
  $height: number;
  $maxWidth: string;
};
const Outer = styled.div<OuterProps>`
  overflow: hidden;
  align-self: center;

  ${({ theme, $height, $maxWidth }) => css`
    height: ${$height}px;
    width: ${$maxWidth};
    background-color: ${theme.color('grey', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};
  `};
`;

type InnerProps = {
  $height: number;
};
const Inner = styled(animated.div)<InnerProps>`
  ${({ theme, $height }) => css`
    height: ${$height}px;
    background-color: ${theme.color('success')};
  `};
`;

export default ProgressBar;
