import React from 'react';
import { Props } from '..';

const Branches: React.FC<Props> = ({
  color = 'currentColor',
  ...props
}: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.7 20.2V15.8C18.7 12.3 15.4 12 13.4 10.5C12.6 10 12 9.1 12 7.8V3.2"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.30005 20.2V15.8C5.30005 10.8 12 12.3 12 7.7V3.2"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2001 18.3L18.7001 20.8L16.3 18.3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.70005 18.3L5.30005 20.8L2.80005 18.3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Branches;
