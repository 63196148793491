import React from 'react';
import styled, { css } from 'styled-components';
import extractLabelFromStr from '~/scenes/Automation/v2/util/extractLabelFromStr';

export type Props = {
  dataTestId?: string;
  /**
   * A string that might include both label + text or just text
   * E.g. [[A1]] Some text
   */
  str: string | null;

  /**
   * Changes component's size
   */
  size?: 'small' | 'base';

  /**
   * If the outside container is hovered or clicked
   */
  active?: boolean;
};

const ActionLabelContainer: React.FC<Props> = ({
  dataTestId,
  str,
  size = 'base',
  active = false,
  ...rest
}) => {
  const res = extractLabelFromStr(str);

  return (
    <>
      {res?.label && (
        <ActionLabel
          data-testid={dataTestId}
          $size={size}
          $active={active}
          {...rest}
        >
          {res.label}
        </ActionLabel>
      )}

      {res?.text}
    </>
  );
};

export const ActionLabel = styled.span<{
  $size?: 'small' | 'base';
  $active?: boolean;
}>(
  ({ theme, $size, $active }) => css`
    color: ${theme.color($active ? 'white' : 'primary')};
    background-color: ${theme.color('primary', 'translucent')};
    padding: ${theme.remToPxRaw(theme.space('xxs')) / 2 + 'px'}
      ${theme.space('xxs')};
    margin-right: ${theme.space('xxs')};
    border-radius: ${theme.getTokens().border.radius.s};
    font-size: ${theme.fontSize($size === 'small' ? 'xxs' : 's')};
    font-weight: ${theme.fw('semiBold')};
  `,
);

export default ActionLabelContainer;
