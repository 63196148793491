import React from 'react';

import FoldableCardBlock from '../FoldableCardBlock';
import { Loading, AppErrorScreen } from '~/components';
import FlowInstanceList from '../components/FlowInstanceList';
import useConfirmModal from '~/components/Alerts/useConfirmModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import {
  FlowInstanceStatus,
  FlowV2InstanceStatus,
  useGetFlowInstancesQuery,
  useGetFlowV2InstancesQuery,
  useStopFlowInstanceMutation,
  useStopFlowV2InstanceMutation,
} from '~/graphql/types';
import { Heading5, Variant } from '~/components/Typography/index';
import JustificationContainer from '~/components/JustificationContainer';

const text = {
  confirmModalTitle: 'Bevestiging',
  confirmModalMessage:
    'Weet je zeker dat je deze flow wil stoppen voor dit contact?',
  confirmModalConfirmButtonText: 'Bevestigen',
  confirmModalCancelButtonText: 'Annuleren',
  activeFlowsButtonLabel: 'Actieve flows',
};
const confirmModalText = {
  title: text.confirmModalTitle,
  message: text.confirmModalMessage,
  buttonConfirmTitle: text.confirmModalConfirmButtonText,
  buttonCancelTitle: text.confirmModalCancelButtonText,
};

type Props = {
  contactId: string;
};
const FlowInstancesBlock: React.FC<Props> = ({ contactId }) => {
  const [showConfirmModal, setOnConfirmFunction] = useConfirmModal(
    confirmModalText,
    () => {},
    () => {},
    undefined,
    false,
    'destructive',
  );
  const account = useCurrentAccount();

  const { data, loading, error, refetch } = useGetFlowInstancesQuery({
    variables: {
      accountId: account.id,
      contactId,
      statusFilter: [FlowInstanceStatus.Running, FlowInstanceStatus.Waiting],
    },
  });

  const {
    data: dataV2,
    loading: loadingV2,
    error: errorV2,
    refetch: refetchV2,
  } = useGetFlowV2InstancesQuery({
    variables: {
      accountId: account.id,
      contactId,
      statusFilter: [
        FlowV2InstanceStatus.Running,
        FlowV2InstanceStatus.Waiting,
      ],
    },
  });

  const [stopFlowInstance] = useStopFlowInstanceMutation({
    onCompleted: () => refetch(),
  });

  const [stopFlowV2Instance] = useStopFlowV2InstanceMutation({
    onCompleted: () => refetchV2(),
  });

  const flowv1Instances = data?.getFlowInstances.items;
  const flowv2Instances = dataV2?.getFlowV2Instances.items;

  if (loading || loadingV2) return <Loading />;

  if (error || errorV2 || !flowv1Instances || !flowv2Instances) {
    return <AppErrorScreen />;
  }

  return (
    <FoldableCardBlock
      dataTestId="card-block-contact-flowinstances"
      icon={'GEAR'}
      title={text.activeFlowsButtonLabel}
    >
      <JustificationContainer gap="base" direction="column">
        {flowv2Instances.length > 0 && (
          <FlowInstanceList
            value={flowv2Instances}
            onClick={item => {
              setOnConfirmFunction(() =>
                stopFlowV2Instance({
                  variables: {
                    accountId: account.id,
                    id: item.id,
                  },
                }),
              );
              showConfirmModal();
            }}
          />
        )}

        {flowv1Instances.length > 0 && (
          <div>
            <Heading5 variant={Variant.primary}>Flows v1</Heading5>
            <FlowInstanceList
              value={flowv1Instances}
              onClick={item => {
                setOnConfirmFunction(() =>
                  stopFlowInstance({
                    variables: {
                      accountId: account.id,
                      id: item.id,
                    },
                  }),
                );
                showConfirmModal();
              }}
            />
          </div>
        )}
      </JustificationContainer>
    </FoldableCardBlock>
  );
};

export default FlowInstancesBlock;
