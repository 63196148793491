import React from 'react';
import { Props } from '..';

const ValuationReport: React.FC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  strokeWidth,
  ...props
}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M21.4483 20.8775L1.24829 40.1775C0.748287 40.6775 1.04829 41.4775 1.74829 41.4775H3.84829V65.3775H41.2483V41.4775H43.3483C44.0483 41.4775 44.3483 40.6775 43.8483 40.1775L23.8483 20.8775C23.1483 20.1775 22.0483 20.1775 21.4483 20.8775Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.04834 39.5776L22.0483 24.9776C22.2483 24.7776 22.6483 24.7776 22.9483 24.9776L37.9483 39.5776"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0484 48.7773H10.9484V65.3773H20.0484V48.7773Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3483 48.7773H28.1483V58.4773H36.3483V48.7773Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5483 33.3774H18.5483V42.8774H26.5483V33.3774Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24829 36.0774V28.2774H3.24829V24.8774H17.3483"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8483 17.8774H28.0483V24.8774L32.8483 29.3774V17.8774Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.3483 45.3774H45.1483V65.3774H65.3483V45.3774Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.3484 41.5776H71.0484V45.2776H69.0484V65.3776H41.3484V41.5776Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.04834 65.3774H71.0483"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.4483 18.8777L53.8483 33.9777C53.4483 34.3777 52.8483 34.4777 52.3483 34.4777L45.3483 33.4777C44.0483 33.2777 43.1483 32.2777 43.0483 30.9777L42.6483 23.9777C42.6483 23.4777 42.8483 22.8777 43.2483 22.5777L59.9483 7.47767C60.7483 6.77767 61.8483 6.87767 62.5483 7.57767L70.5483 16.3777C71.2483 17.0777 71.2483 18.1777 70.4483 18.8777Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2483 36.0774L48.0483 28.8774"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.2484 29.9774C50.5186 29.9774 51.5484 28.9477 51.5484 27.6774C51.5484 26.4072 50.5186 25.3774 49.2484 25.3774C47.9781 25.3774 46.9484 26.4072 46.9484 27.6774C46.9484 28.9477 47.9781 29.9774 49.2484 29.9774Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 28L7 28"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ValuationReport;
