import React from 'react';

type Props = {
  id?: string;
  color: string;
  className?: string;
};

const MarkerDefinition: React.FC<Props> = React.memo(
  ({ color, id, className }) => (
    <marker
      className={className || 'react-flow__arrowhead'}
      id={id}
      markerWidth="12.5"
      markerHeight="12.5"
      viewBox="-10 -10 20 20"
      orient="auto"
      markerUnits="strokeWidth"
      refX="0"
      refY="0"
    >
      <polyline
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        fill="none"
        points="-5,-4 0,0 -5,4"
      />
    </marker>
  ),
);

export default MarkerDefinition;
