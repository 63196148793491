import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { Theme } from '~/theme';
import { FlowConditionList, FlowConditionListWithoutId } from './types.flow';
import { FlowCondition } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { ConditionChoiceType } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';

import FlowConditionComponent from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/FlowConditionComponent';
import { Button } from '~/components/Buttons';
import { copyWithoutIndex, omitKeys } from '~/util/array';
import TEST_ID from './FlowConditionListComponent.testid';
import BaseActionContext, {
  withBaseActionContext,
} from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import { FLOW_ACTION_TYPE } from '../../constants';
import { MinimalDropdown } from '~/components/Inputs';
import { expressionOptions } from './expressionHelpers';
import Icon from '~/components/Icon';
import { isEmpty } from 'lodash';

const text = {
  allShouldBeTrue: 'Alle conditie(s) moeten gelden',
  oneShouldBeTrue: 'Minimaal één conditie moet gelden',
  addConditionLabel: 'Conditie toevoegen',
  emptyConditionListError: 'Voeg minimaal één conditie toe',
  emptyConditionList: 'Er zijn nog geen condities toegevoegd',
};
type MyProps = {
  allowNoConditions?: boolean;
  conditionChoiceType?: ConditionChoiceType;
  conditionList: FlowConditionList;
  onChange: (
    newFlowConditionList: FlowConditionListWithoutId | FlowConditionList,
  ) => void;
  onDropdownOpened: () => void;
  getNewConditionToAdd: () => Omit<FlowCondition, 'id'>;
  announceChanges: () => void;
  outputLoading: boolean;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
};
class FlowConditionListComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  validate = (): boolean => {
    const { conditionList, allowNoConditions } = this.props;

    if (allowNoConditions === true) {
      return true;
    }

    return conditionList.conditions.length > 0;
  };

  render() {
    const {
      conditionList,
      onChange,
      onDropdownOpened,
      getNewConditionToAdd,
      conditionChoiceType,
      allowNoConditions,
      announceChanges,
      outputLoading,
    } = this.props;
    const { conditions, allShouldBeTrue } = conditionList;

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => {
          let conditionListComponent: ReactElement | null = null;

          if (
            conditions.length === 0 &&
            showValidation &&
            allowNoConditions !== true
          ) {
            conditionListComponent = (
              <BaseActionContext.Consumer>
                {({ actionType }) => {
                  let toShowText;
                  if (actionType === FLOW_ACTION_TYPE.START) {
                    // A start action has a start condition, so don't tell user to add more
                    toShowText = null;
                  } else {
                    toShowText = text.emptyConditionListError;
                  }

                  return (
                    <Text
                      data-error={true}
                      data-testid={TEST_ID.EMPTY_LIST_TEXT}
                    >
                      {toShowText}
                    </Text>
                  );
                }}
              </BaseActionContext.Consumer>
            );
          } else {
            conditionListComponent = (
              <>
                {conditions.map((condition, idx) => {
                  let andOrComponent: ReactElement | null = null;

                  if (idx > 0) {
                    andOrComponent = (
                      <AndOrComponentContainer>
                        <MinimalDropdown
                          small
                          options={expressionOptions}
                          selectedOptionIdx={expressionOptions.findIndex(
                            option => option.payload === allShouldBeTrue,
                          )}
                          onChange={({ option }) => {
                            onChange({
                              ...conditionList,
                              allShouldBeTrue: option.payload,
                            });
                          }}
                          dataTestid={TEST_ID.AND_OR_DROPDOWN}
                        />
                      </AndOrComponentContainer>
                    );
                  }

                  return (
                    <React.Fragment key={`fragment-${idx}`}>
                      {andOrComponent}
                      <StyledFlowConditionComponent
                        outputLoading={outputLoading}
                        condition={condition}
                        conditionNumber={idx + 1}
                        conditionChoiceType={conditionChoiceType}
                        onDropdownOpened={onDropdownOpened}
                        onChange={newCondition => {
                          const newConditions = [...conditions];
                          const previousCondition = conditions[idx];

                          newConditions[idx] = {
                            ...newCondition,
                            fields:
                              !isEmpty(newCondition.fields) ||
                              // THIS IS A HACK TO HANDLE THE DELETION OF THE LAST FIELD
                              (previousCondition.type === newCondition.type &&
                                'fields' in condition &&
                                condition.fields.length === 1 &&
                                newCondition.fields.length === 0)
                                ? newCondition.fields
                                : // THIS IS A HACK TO KEEP THE PREVIOUS FIELD SELECTIONS, BUT WE DISCARD THE PREVIOUS FIELDS IF THERE IS ONLY ONE FIELD
                                'fields' in previousCondition &&
                                  !isEmpty(previousCondition.fields) &&
                                  previousCondition.fields.length > 1
                                ? omitKeys(previousCondition.fields, [
                                    'label',
                                    'name',
                                  ])
                                : [],
                          };

                          onChange({
                            ...conditionList,
                            conditions: newConditions,
                          });
                        }}
                        onDelete={() => {
                          const newConditions = copyWithoutIndex(
                            conditions,
                            idx,
                          );

                          onChange({
                            ...conditionList,
                            conditions: newConditions,
                          });
                        }}
                        announceChanges={announceChanges}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            );
          }

          return (
            <Container>
              <ConditionListContainer data-testid={TEST_ID.LIST_CONTAINER}>
                {conditionListComponent}
              </ConditionListContainer>
              <ButtonsContainer
                hasConditions={conditionList.conditions.length > 0}
              >
                <Button
                  outline
                  dotted
                  data-testid={TEST_ID.ADD_CONDITION_BUTTON}
                  onClick={() => {
                    const newConditionList = {
                      ...conditionList,
                      conditions: [
                        ...conditionList.conditions,
                        getNewConditionToAdd(),
                      ],
                    };

                    onChange(newConditionList);
                  }}
                  icon={<Icon name="plus" />}
                >
                  <ButtonText>{text.addConditionLabel}</ButtonText>
                </Button>
              </ButtonsContainer>
            </Container>
          );
        }}
      </FlowContext.Consumer>
    );
  }
}

const Text = styled.div<{}>`
  ${({ theme }) => css`
    color: ${theme.color('danger')};
    padding: ${theme.space('m')} 0;
  `};
`;

const getCssForTopLine = (theme: Theme) => css`
  position: relative;
  margin-top: ${theme.space('m')};

  &::after {
    content: '';
    display: block;
    height: 16px;
    width: 1px;
    position: absolute;
    background-color: ${theme.color('grey')};
    top: -${theme.space('m')};
    left: ${theme.space('m')};
  }
`;
type ButtonsContainerProps = {
  hasConditions: boolean;
};
const ButtonsContainer = styled.div<ButtonsContainerProps>`
  ${({ theme, hasConditions }) => {
    if (hasConditions) {
      return css`
        ${getCssForTopLine(theme)}
      `;
    }

    return '';
  }}
`;
type FlowConditionComponentProps = {
  conditionNumber: number;
};
// Needed for this error: https://github.com/styled-components/styled-components/issues/1449#issuecomment-420087359
const StyledFlowConditionComponent = styled(props => (
  <FlowConditionComponent {...props} />
))<FlowConditionComponentProps>`
  ${({ theme, conditionNumber }) => {
    if (conditionNumber > 1) {
      return css`
        ${getCssForTopLine(theme)};
      `;
    }
    return null;
  }}
`;

const AndOrComponentContainer = styled.div<{}>`
  max-width: 65px;

  ${({ theme }) => css`
    ${getCssForTopLine(theme)};
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.s};
  `};
`;

const ConditionListContainer = styled.div<{}>``;

const Container = styled.div<{}>`
  height: 100%;

  ${({ theme }) => css`
    padding: ${theme.space('l')};
    background-color: ${theme.color('grey', 'light')};
    border-bottom-right-radius: ${theme.getTokens().border.radius.s};
  `};
`;

const ButtonText = styled.span<{}>`
  text-decoration: underline;
`;

export default withBaseActionContext<MyProps>(FlowConditionListComponent);
