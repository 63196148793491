import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterFilePrimitiveFields on Flow_Parameter_File_Primitive {
    __typename
    s3key
    filename
    contentType
    contentLength
  }
`;
