import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Loading } from '~/components';
import Button from '~/components/Button';
import EmptyStateComponent from '~/components/EmptyStateComponent';
import TipBanner, { TipId } from '~/components/TipBanner';
import JustificationContainer from '~/components/JustificationContainer';
import { Body, Heading4, Variant } from '~/components/Typography/index';
import AppDetailsContainer from '~/scenes/Apps/components/AppDetailsContainer';
import { Theme } from '~/theme';
import Table from './components/Table';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  data: Array<{
    name: string;
    fields: string;
  }>;
  text: {
    header: React.ReactNode;
    body: React.ReactNode;
    tableHeader: React.ReactNode;
    addButtonLabel: React.ReactNode;
    noConnections: React.ReactNode;
    tableHeaders: {
      name: React.ReactNode;
      fields: React.ReactNode;
      zaps: React.ReactNode;
      flows: React.ReactNode;
    };
    tip: React.ReactNode;
  };
  loading: boolean;
  tipId: TipId;
  onNew: () => void;
  onEdit: (id: string) => void;
  onCopy: (id: string) => void;
  onDelete: (id: string) => void;
};

const OverviewTemplate: React.FC<Props> = ({
  data,
  loading,
  onCopy,
  onDelete,
  onEdit,
  onNew,
  text,
  tipId,
}) => {
  const theme: Theme = useTheme();

  if (loading)
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );

  const shouldShowEmptyState = data.length === 0;

  return (
    <AppDetailsContainer header={text.header} icon="incomingLink">
      <Body>{text.body}</Body>
      <br />

      {!shouldShowEmptyState && (
        <JustificationContainer justification="space-between" align="center">
          <Heading4 variant={Variant.primary}>{text.tableHeader}</Heading4>
          <Button
            size="medium"
            label={text.addButtonLabel}
            onClick={onNew}
            icon="plus"
            appearance="secondary"
            pulse={data.length === 0}
            dataTestId={TEST_ID.ADD_NEW_TRIGGER}
          />
        </JustificationContainer>
      )}
      {shouldShowEmptyState ? (
        <EmptyStateComponent
          header={text.noConnections}
          illustration="box"
          buttonLabel={text.addButtonLabel}
          blobColor={theme.color('grey', 'light')}
          onButtonClick={onNew}
        />
      ) : (
        <>
          <Table
            data={data}
            onDelete={onDelete}
            onCopy={onCopy}
            onEdit={onEdit}
            loading={loading}
          />
          <TipBanner id={tipId} margin={['base', null, null, null]}>
            {text.tip}
          </TipBanner>
        </>
      )}
    </AppDetailsContainer>
  );
};

const LoadingContainer = styled.div<{}>`
  margin: auto;
`;

export default OverviewTemplate;
