import gql from 'graphql-tag';

export default gql`
  fragment ZapierTriggerFields on ZapierTrigger {
    id
    accountId
    name
    fields {
      key
      label
      type
    }
    subscriptions {
      id
      url
    }
    createdDate
  }
`;
