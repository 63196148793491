import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { TaskGroups } from '~/util/task';

import { SLIDE_OPEN_ANIMATION_DURATION_IN_SECONDS } from '~/components/SlideOpenAnimationContainer';
import { APPROXIMATE_HEADER_HEIGHT, FONT_SIZE_OF_18 } from '~/styles/constants';

export const TaskListContainer = styled.div<{}>(
  ({ theme }) => css`
    position: relative;
    padding-left: ${APPROXIMATE_HEADER_HEIGHT}px;
    margin-left: ${APPROXIMATE_HEADER_HEIGHT}px;
    margin-top: ${theme.space('xxl')};
    margin-bottom: ${theme.space('xxl')};
    border-left: 1px dotted ${theme.color('grey')};

    > div:last-child {
      margin-bottom: 0;
    }
  `,
);

type RoundLabelType = {
  isCompleted?: boolean;
  isRemovedFromList?: boolean;
  loading?: boolean;
};

export const RoundLabel = styled.div<RoundLabelType>`
  position: absolute;
  cursor: pointer;

  transition: opacity ${SLIDE_OPEN_ANIMATION_DURATION_IN_SECONDS}s ease-out,
    background-color 0.2 ease-out;
  opacity: 1;

  ${({ theme }) => css`
    height: ${theme.space('xxl')};
    width: ${theme.space('xxl')};
    left: -20px;
    font-size: ${FONT_SIZE_OF_18}px;
  `};

  ${({ theme, isCompleted }) => {
    if (isCompleted) {
      return css`
        pointer-events: none;
        background-color: ${theme.color('success')};
      `;
    }

    return css`
      background-color: ${theme.color('grey')};

      &:hover {
        background-color: ${darken(0.1, theme.color('grey'))};
      }

      &:active {
        background-color: ${darken(0.2, theme.color('grey'))};
      }
    `;
  }};

  ${({ isRemovedFromList }) => {
    if (isRemovedFromList) {
      return css`
        opacity: 0;
      `;
    }

    return null;
  }}

  border-radius: 50%;
  margin-top: 1em;

  display: flex;
  justify-content: center;
  align-items: center;

  > i {
    ${({ theme }) => css`
      color: ${theme.color('white')};
    `};
    > svg {
      position: absolute;
    }
  }
`;

type GroupMarkerProps = {
  taskGroupType: TaskGroups;
};

export const GroupMarker = styled.div<GroupMarkerProps>`
  display: inline-block;
  text-align: center;

  ${({ theme }) => css`
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.s};
    padding: ${theme.space('s')};
    margin-bottom: ${theme.space('l')};
    transform: translateX(-${APPROXIMATE_HEADER_HEIGHT * 2}px);
    min-width: ${APPROXIMATE_HEADER_HEIGHT * 2}px;
  `};

  ${({ taskGroupType, theme }) => {
    if (taskGroupType === 'DELETED')
      return css`
        color: ${theme.color('accent')};
      `;
    if (taskGroupType === 'COMPLETED')
      return css`
        color: ${theme.color('success')};
      `;
    if (taskGroupType === 'OVERDUE')
      return css`
        color: ${theme.color('accent')};
      `;

    if (taskGroupType === 'UPCOMING')
      return css`
        color: ${theme.color('success')};
      `;

    if (taskGroupType === 'TODAY')
      return css`
        color: ${theme.color('primary', 'light')};
      `;

    return null;
  }};
`;
