import React, { ReactElement } from 'react';

import {
  OfficeParameterValue,
  OfficePrimitiveParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { PARAMETER_VALUE_TYPE } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/constants';
import { Dropdown } from '~/components/Inputs';
import Catalog from '~/Catalog';
import { emptyOfficePrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Office';
import OfficePrimitiveParameterValueComponent from './OfficePrimitiveParameterValueComponent';
import TEST_ID from './OfficeParameterValueComponent.testid';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import { getStashVariablesInStash } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';

import cleanedFilename from '~/util/cleanedFilename';
import getVariableWithPrimitiveDropdownProps from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/util/getVariableWithPrimitiveDropdownProps';
import getFieldDropdownProps from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/util/getFieldDropdownProps';
import validateStashVariable from '../util/validateStashVariable';

const text = {
  variableLabel: Catalog.flows.pointerVariableLabel,
  fieldLabel: Catalog.flows.pointerFieldLabel,
  requiredField: Catalog.requiredField,
};
type ValueType = null | OfficeParameterValue;
type MyProps = {
  value: ValueType;
  onChange: (newValue: OfficeParameterValue) => void;

  /** If the value has to be filled in */
  isMandatory?: boolean;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
  primitiveValueCache: OfficePrimitiveParameterValue;
};
class OfficeParameterValueComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { value } = this.props;
    const defaultPrimitiveValueCache =
      value != null && value.type === PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE
        ? value
        : emptyOfficePrimitiveParameterValue();

    this.state = {
      key: null,
      primitiveValueCache: defaultPrimitiveValueCache,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  setPrimitiveValueCache = (
    newPrimitiveValue: OfficePrimitiveParameterValue,
  ) => {
    this.setState({
      primitiveValueCache: newPrimitiveValue,
    });
  };

  validate = (): boolean => {
    const { value, isMandatory } = this.props;

    if (value == null) {
      if (isMandatory) {
        return false;
      } else {
        return true;
      }
    }

    if (value.type === PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE) {
      // validation is done in the primitive component
      return true;
    }

    return validateStashVariable(
      value.variable,
      this.getPossibleVariables(),
      FLOW_OUTPUT_TYPE.FlowOutputOffice,
    );
  };

  getPossibleVariables = () => {
    const { baseActionContext } = this.props;
    const { variableStash } = baseActionContext;

    return getStashVariablesInStash(
      variableStash,
      undefined,
      FLOW_OUTPUT_TYPE.FlowOutputOffice,
    );
  };

  render() {
    const { onChange, value, isMandatory } = this.props;
    const { primitiveValueCache } = this.state;

    const {
      variableOptions,
      selectedVariableIdx,
      selectedVariableOption,
      onChangeVariable,
    } = getVariableWithPrimitiveDropdownProps<OfficeParameterValue>(
      this.getPossibleVariables(),
      value,
      () => {
        onChange({ ...primitiveValueCache });
      },
      newVariableName => {
        onChange({
          type: PARAMETER_VALUE_TYPE.OFFICE_POINTER,
          variable: { name: newVariableName, field: null },
        });
      },
    );

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => {
          let inputComponent: ReactElement | null = null;
          if (value != null) {
            if (value.type === PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE) {
              inputComponent = (
                <OfficePrimitiveParameterValueComponent
                  value={value}
                  onChange={newPrimitiveValue => {
                    this.setPrimitiveValueCache(newPrimitiveValue);
                    onChange(newPrimitiveValue);
                  }}
                  isMandatory={isMandatory}
                />
              );
            } else if (value.type === PARAMETER_VALUE_TYPE.OFFICE_POINTER) {
              const {
                fieldOptions,
                selectedFieldIdx,
                selectedFieldOption,
                onChangeField,
              } = getFieldDropdownProps(
                selectedVariableOption,
                value,
                (newFieldName: string) => {
                  if (value.variable == null) {
                    throw Error(
                      `${cleanedFilename(
                        __filename,
                      )} | Should not occur | Changing field even though variable has not been chosen yet!`,
                    );
                  }

                  const newValue: OfficeParameterValue = {
                    type: PARAMETER_VALUE_TYPE.OFFICE_POINTER,
                    variable: {
                      name: value.variable.name,
                      field: {
                        name: newFieldName,
                      },
                    },
                  };

                  onChange(newValue);
                },
                FLOW_OUTPUT_TYPE.FlowOutputOffice,
                FLOW_OUTPUT_TYPE.FlowOutputOffice,
              );

              inputComponent = (
                <Dropdown
                  dataTestid={TEST_ID.FIELD_DROPDOWN}
                  selectOnlyOptionAutomatically
                  error={
                    showValidation && selectedFieldOption == null
                      ? text.requiredField
                      : ''
                  }
                  label={text.fieldLabel}
                  options={fieldOptions}
                  selectedOptionIdx={selectedFieldIdx}
                  onChange={onChangeField}
                />
              );
            }
          }

          return (
            <>
              <Dropdown
                dataTestid={TEST_ID.VARIABLE_DROPDOWN}
                selectOnlyOptionAutomatically
                error={
                  showValidation && selectedVariableOption == null
                    ? Catalog.requiredField
                    : ''
                }
                label={text.variableLabel}
                options={variableOptions}
                selectedOptionIdx={selectedVariableIdx}
                onChange={onChangeVariable}
              />
              {inputComponent}
            </>
          );
        }}
      </FlowContext.Consumer>
    );
  }
}

export default withBaseActionContext<MyProps>(OfficeParameterValueComponent);
