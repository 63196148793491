import { TemplateStringField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';

export const SET_CONTACT_DETAILS_FIELD_OPTIONS: {
  [key: string]: TemplateStringField;
} = {
  name: {
    name: 'name',
    label: 'Naam',
    type: FIELD_TYPE.TEMPLATE_STRING,
  },
  phone: {
    name: 'phone',
    label: 'Telefoonnummer',
    type: FIELD_TYPE.TEMPLATE_STRING,
  },
};

export const SET_CONTACT_DETAILS_DEFAULT_FIELD =
  SET_CONTACT_DETAILS_FIELD_OPTIONS.name;
