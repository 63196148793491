import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FlowV2_Action_Contact_AssignFragment } from '~/graphql/types';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useUserOptions from '~/hooks/useUserOptions';
import InputGroup from '~/scenes/Apps/components/InputGroup';
import FieldLabel from '~/scenes/Automation/Flows/Actions/FieldLabel';
import { Checkbox } from '~/components/Inputs';
import Dropdown from '~/components/Dropdown';
import { SelectedOptionOf } from '~/components/Inputs/Dropdown';
import { Props as FormProps } from '../ActionForm';
import TEST_ID from './index.testid';
import useEntityValidationForInputs from '../../../Builder/hooks/useEntityValidationForInputs';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_Contact_AssignFragment;
};

type State = Omit<
  FlowV2_Action_Contact_AssignFragment,
  | 'id'
  | 'accountId'
  | 'parentIds'
  | 'flowBlueprintId'
  | '_v'
  | '__typename'
  | 'actionType'
>;

const text = {
  userLabel: 'Selecteer gebruiker',
  officeLabel: 'Selecteer vestiging',
  checkboxName: 'overwriteAssignee',
  checkboxLabel: 'Huidige eigenaar van het contact overschrijven',
  officeDropdownLabel: 'Selecteer vestiging',
  userDropdownLabel: 'Selecteer gebruiker',
};

const AssignContact: React.FC<Props> = ({ action, onChange }) => {
  const initials = {
    office: action.office,
    user: action.user,
    overwriteAssignee: action.overwriteAssignee,
  };

  const [actionDetails, setActionDetails] = useState<State>(initials);

  const { office, user, overwriteAssignee } = actionDetails;

  const officeOptions = useOfficeOptions();

  const userOptions = useUserOptions({
    withAllUsersOption: true,
    officeId: office,
  });

  const onCheckboxClick = () => {
    setActionDetails(prev => ({
      ...prev,
      overwriteAssignee: !prev.overwriteAssignee,
    }));
  };

  const onChooseOffice = (option: SelectedOptionOf<any>) => {
    setActionDetails(prev => ({
      ...prev,
      office: option.option.payload?.id,
    }));
  };

  const onChooseUser = (option: SelectedOptionOf<any>) => {
    setActionDetails(prev => ({
      ...prev,
      user: option.option.payload?.id,
    }));
  };

  useEffect(() => {
    onChange({ ...action, ...actionDetails });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  const { userError, officeError } = useEntityValidationForInputs({
    userOptions,
    userId: actionDetails.user,
    officeId: actionDetails.office,
  });

  return (
    <>
      <InputGroup>
        <FieldLabel>{text.officeLabel}</FieldLabel>
        <Dropdown
          dataTestId={TEST_ID.OFFICE_DROPDOWN}
          label={text.officeDropdownLabel}
          options={officeOptions}
          onChange={onChooseOffice}
          selectedOptionIdx={officeOptions.findIndex(
            selectedOffice => selectedOffice.payload?.id === office,
          )}
          error={officeError}
        />
      </InputGroup>
      <InputGroup>
        <FieldLabel>{text.userLabel}</FieldLabel>
        <Dropdown
          dataTestId={TEST_ID.USER_DROPDOWN}
          label={text.userDropdownLabel}
          options={userOptions}
          onChange={onChooseUser}
          selectedOptionIdx={
            userOptions.findIndex(selectedUser =>
              user === null
                ? selectedUser.payload === user
                : selectedUser.payload?.id === user,
            ) ?? null
          }
          error={userError}
        />
      </InputGroup>
      <InputGroup>
        <StyledCheckbox
          dataTestid={TEST_ID.OVERWRITE_ASSIGNEE}
          value={overwriteAssignee ?? true}
          onChange={onCheckboxClick}
          label={text.checkboxLabel}
          name={text.checkboxName}
        />
      </InputGroup>
    </>
  );
};

const StyledCheckbox = styled(Checkbox)<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('base')};
  `,
);

export default AssignContact;
