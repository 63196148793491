import { TaskStatus } from '~/graphql/types';
import { TaskListType } from './taskListType';

import TASK_LIST_TYPE from './taskListType';
import { reportErrorToTracker } from '~/util/assertion';

export const isTaskCompleted = (
  task: {
    status: TaskStatus;
    deleted: boolean;
  } & $Object,
): boolean => !isTaskDeleted(task) && task.status === TaskStatus.Closed;

export const isTaskDeleted = (task: { deleted: boolean } & $Object): boolean =>
  task.deleted;

export const isTaskOpen = (
  task: {
    status: TaskStatus;
    deleted: boolean;
  } & $Object,
): boolean => !isTaskDeleted(task) && task.status === TaskStatus.Open;

export const isTaskForListType = (
  task: { status: TaskStatus; deleted: boolean } & $Object,
  listType: TaskListType,
): boolean => {
  switch (listType) {
    case TASK_LIST_TYPE.OPEN:
      return isTaskOpen(task);
    case TASK_LIST_TYPE.CLOSED:
      return isTaskCompleted(task);
    case TASK_LIST_TYPE.DELETED:
      return isTaskDeleted(task);
    default:
      reportErrorToTracker(
        new Error(`Unknown list type ${listType.toString()}`),
      );
      return isTaskOpen(task);
  }
};
