import { StartAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { emptyFlowConditionContactDetails } from '../Base';
import { emptyConditionList } from '../Base/FlowConditionList';
import { generateHash } from '~/util/react';

const defaultNewStartActionProps = (
  baseActionProps: BaseActionProps,
): StartAction => {
  const condition = emptyFlowConditionContactDetails();
  return {
    ...baseActionProps,
    type: FLOW_ACTION_TYPE.START,
    startCondition: {
      ...condition,
      id: generateHash(`${JSON.stringify(condition)}-0`),
    },
    conditionList: emptyConditionList(),
  };
};

export default defaultNewStartActionProps;
