import { isNil } from 'ramda';
import React, { HTMLAttributes, useState } from 'react';
import { Handle, Position } from 'reactflow';

import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import Divider from '~/components/Divider';
import Icon from '~/components/Icon';
import JustificationContainer from '~/components/JustificationContainer';
import { ClientFlowAction } from '~/graphql/types.client';
import { actionsSelector } from '~/scenes/Automation/v2/state';

import addBlockMenu from '~/scenes/Automation/v2/state/addBlockMenu';
import interactions from '~/scenes/Automation/v2/state/interactions';
import useBuilderContext from '../../hooks/useBuilderContext';
import usePanTo from '../../hooks/usePanTo';
import getParentActions from '../../utils/getParentActions';
import AddBlockMenu from '../AddBlockMenu/OnEdge';
import { DEFAULT_WIDTH } from '../nodeTypes';

export type Props = HTMLAttributes<HTMLDivElement> & {
  dataTestId?: string;
  onAddAction: (actionType: ClientFlowAction['actionType']) => void;
  id: string;
  parentId: string | null;
  xPos: number;
  yPos: number;
  parentActionType?: ClientFlowAction['actionType'];
  childIdKey?: 'trueChildId' | 'falseChildId';
};

const AddNewNodeCard: React.FC<Props> = React.memo(
  ({
    dataTestId,
    onDrop,
    onAddAction,
    id,
    xPos,
    yPos,
    parentActionType,
    parentId,
    childIdKey,
    ...rest
  }) => {
    const [dragOver, setDragOver] = useState(false);
    const [menuState, setMenuState] = useRecoilState(addBlockMenu);

    const panTo = usePanTo();

    const x = xPos + DEFAULT_WIDTH / 2;
    const y = yPos + 50;

    const { accountId, flowBlueprintId } = useBuilderContext();

    const [actions] = useRecoilState(
      actionsSelector({
        accountId,
        flowBlueprintId,
      }),
    );

    const [currentInteraction, setInteraction] = useRecoilState(interactions);

    const connectAction = () => {
      const sourceAction = actions.find(a => a.id === parentId);

      if (!sourceAction) return;

      const allParentActions = getParentActions(sourceAction, actions);

      const parentMap: Record<string, ClientFlowAction> =
        allParentActions.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});

      /**
       * We do not allow both children of IfElse action to be connected to the same action currently.
       * It causes issues with portal cards & edges being drawn. We will implement this in the future.
       */
      const validTargets = actions.filter(
        a =>
          a.id !== sourceAction.id &&
          'parentIds' in a &&
          !a.parentIds.includes(sourceAction.id) &&
          isNil(parentMap[a.id]) &&
          !isDirectChildOfIfElse(sourceAction, a.id),
      );

      return setInteraction({
        action: sourceAction,
        type: 'connectAction',
        validTargets,
        childIdKey,
      });
    };

    const disabled =
      currentInteraction?.type === 'connectAction' ||
      currentInteraction?.type === 'highlightActions';

    return (
      <>
        <Container
          $dragOver={dragOver}
          onDragOver={() => setDragOver(true)}
          onDragLeave={() => setDragOver(false)}
          onMouseLeave={() => setDragOver(false)}
          onDrop={(...args) => {
            setDragOver(false);
            if (onDrop) onDrop(...args);
          }}
          $disabled={disabled}
          {...rest}
        >
          <Handle
            type="source"
            position={Position.Bottom}
            style={{ opacity: '0' }}
            isConnectable={false}
          />
          <Button
            onClick={() => {
              setMenuState({
                openedOnEdge: true,
                edgeId: id,
                targetX: x,
                targetY: y,
              });
              panTo({ x, y });
            }}
            data-testid={dataTestId}
            type="button"
          >
            <StyledJustificationContainer justification="center" align="center">
              <Icon name="plus" />
              &nbsp;<Label>Toevoegen</Label>
            </StyledJustificationContainer>
          </Button>

          <Divider vertical margin={['l']} />

          <Button onClick={connectAction}>
            <StyledJustificationContainer justification="center" align="center">
              <Icon name="arrowRight" />
              &nbsp;<Label>Verbinden</Label>
            </StyledJustificationContainer>
          </Button>

          <Handle
            type="target"
            position={Position.Top}
            style={{ opacity: '0', left: '50%' }}
            isConnectable={false}
          />
        </Container>
        {menuState.openedOnEdge && menuState.edgeId === id && (
          <AddBlockMenu
            style={{
              left: menuState.targetX ?? 0,
              top: menuState.targetY ?? 0,
            }}
            onSelect={onAddAction}
            parentActionType={parentActionType}
          />
        )}
      </>
    );
  },
);

const Container = styled.div<{ $dragOver?: boolean; $disabled?: boolean }>(
  ({ theme, $dragOver, $disabled }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${DEFAULT_WIDTH}px;
    height: 5rem;
    padding: ${theme.space('l')};

    border-radius: ${theme.getTokens().border.radius.base};

    background-color: ${theme.color(
      $dragOver ? 'primary' : 'grey',
      $dragOver ? 'dark' : 'translucent',
    )};
    color: ${theme.color(
      $dragOver ? 'primary' : 'text',
      $dragOver ? 'text' : 'light',
    )};

    pointer-events: ${$disabled ? 'none' : 'all'};
    opacity: ${$disabled ? 0.5 : 1};
  `,
);

const Label = styled.p(
  ({ theme }) => css`
    font-size: ${theme.fs('base')};
    font-weight: ${theme.fw('semiBold')};
    margin: 0;
  `,
);

const Button = styled.button<{}>(
  ({ theme }) => css`
    /* Font-size to scale the Icon */
    font-size: ${theme.fs('l')};
    padding: ${theme.space('l')};

    border: none;
    border-radius: ${theme.getTokens().border.radius.base};

    cursor: pointer;
    user-select: none;

    background-color: transparent;

    transition: all 0.3s ease-out;

    &:hover {
      color: ${theme.color('primary', 'dark')};
    }
  `,
);

const StyledJustificationContainer = styled(JustificationContainer)`
  & svg {
    transform: translateY(-1px);
  }
`;

export default AddNewNodeCard;

const isDirectChildOfIfElse = (
  sourceAction: ClientFlowAction,
  id: string,
): boolean => {
  if (sourceAction.__typename !== 'FlowV2_Action_IfElse') return false;
  return sourceAction.trueChildId === id || sourceAction.falseChildId === id;
};
