import cleanedFilename from '~/util/cleanedFilename';

export default {
  CHAR_NUMBERTOKENS_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-numbertokens-validation`,

  CHAR_UPPERCASE_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-uppercase-validation`,
  CHAR_LOWERCASE_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-lowercase-validation`,
  CHAR_NUMBER_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-number-validation`,
  CHAR_SPECIALCHAR_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-specialchar-validation`,
  SUBMIT_PASSWORD_BUTTON: 'submit-password-button',
  PASSWORD_INPUT_FIELD: `${cleanedFilename(__filename)}-password-input-field`,
  REPEAT_PASSWORD_INPUT_FIELD: `${cleanedFilename(
    __filename,
  )}-repeat-password-input-field`,
};
