import React, { ReactElement } from 'react';

import { SetContactDetailsAction } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { describeFlowParameterField } from '~/scenes/Automation/Flows/Actions/Base';

export default (
  action: SetContactDetailsAction,
  describeOptions: DescribeOptions,
): ReactElement => {
  const { field } = action;

  return (
    <span>
      {'Wijzig het contact door '}
      {describeFlowParameterField(field, describeOptions)}
    </span>
  );
};
