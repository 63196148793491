import React, { useCallback, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Link } from '~/components/Link';

import { MarginlessH5 } from '~/components/Typography';
import { Spinner } from '~/components/Buttons/Button';
import { URL_PREFIX } from './UrlContainer';
import useClickOutside from '~/hooks/useClickOutside';
import Icon from '~/components/Icon';
import Illustration from '~/components/Illustration';

const text = {
  viewLive: 'Bekijk live',
  copyReport: 'Kopiëren',
  editReport: 'Bijwerken',
  deleteReport: 'Verwijderen',
};
const BLOCK_ICON_SIZE = 2;
type Props = {
  /** Name of report */
  name: string;

  /** Report preview image */
  img: string | null | undefined;

  /** Report Id */
  id: string;

  /** Report details route */
  detailsRoute: string;

  /** Label that shows up on the top right corner of the card in a triangle  */
  cornerTagLabel?: string;

  /** Block status of report. Show possibility to edit report */
  isBlocked: boolean;

  /** Copy report handler. Call create report mutation */
  copyReport: () => void;

  /** Edit report handler. Open curent report page */
  editReport: () => void;

  /** Delete report handler. Call deleteAppValuationReport mutation */
  deleteReport: () => void;

  /** Delete mutation loading state */
  loading: boolean;

  /** Default is #cccd */
  backgroundColor?: string | null | undefined;
  canCopy: boolean;

  canLiveView: boolean;
  /** Route of the live valuation report page */
  route: string | undefined;
};

const IMAGE_CONTAINER_HEIGHT_IN_PX = '190';

type CardImageProps = {
  backgroundColor: string | null | undefined;
  img: string | null | undefined;
};
const CardImage = ({ backgroundColor, img }: CardImageProps) => {
  if (img) return <Image src={img} />;
  return (
    <ColouredDiv backgroundColor={backgroundColor}>
      <ValuationReportIllustration
        name="valuation-report"
        color="#fff"
        strokeWidth={1}
      />
    </ColouredDiv>
  );
};
const ValueReportCard = (props: Props) => {
  const theme = useTheme();
  const {
    id,
    canLiveView,
    cornerTagLabel,
    detailsRoute,
    name,
    img,
    editReport,
    copyReport,
    isBlocked,
    backgroundColor,
    deleteReport,
    loading,
    route,
    canCopy,
  } = props;
  const [showActionMenu, setShowActionMenu] = useState<boolean>(false);
  const menuRef = useRef(null);
  useClickOutside(menuRef, () => setShowActionMenu(false));

  const handleClick = useCallback(
    e => {
      if (isBlocked) {
        e.preventDefault();
      }
    },
    [isBlocked],
  );

  return (
    <CardContainer
      data-objectid={`${id}${isBlocked ? '-blocked' : ''}`}
      data-testid="report-item"
    >
      <LinkStyled
        onClick={handleClick}
        key={id}
        data-testid="report-list-item-link"
        to={detailsRoute}
      >
        {cornerTagLabel ? (
          <ImageContainer>
            <CornerSpan label={cornerTagLabel} />
            <CardImage backgroundColor={backgroundColor} img={img} />
          </ImageContainer>
        ) : (
          <ImageContainer>
            <CardImage backgroundColor={backgroundColor} img={img} />
          </ImageContainer>
        )}
      </LinkStyled>

      <CardFooterContainer>
        <TextWithMenuContainer>
          <TextContainer>
            <Title>{name}</Title>
            <LockBlock>{isBlocked && <LockStyled name="lock" />}</LockBlock>
          </TextContainer>
          <OptionsContainer>
            <StyledDotMenuIcon
              name="dot-menu"
              onClick={e => {
                e.stopPropagation();
                setShowActionMenu(!showActionMenu);
              }}
              data-testid="app-card-dot-menu-icon"
            />
          </OptionsContainer>
        </TextWithMenuContainer>

        {canLiveView && (
          <StyledLinkAsExternalLink
            dataTestid="live-preview-button"
            rel="noreferrer"
            target="_blank"
            to={`https://${URL_PREFIX}${route}`}
          >
            {text.viewLive}
          </StyledLinkAsExternalLink>
        )}
      </CardFooterContainer>
      {showActionMenu && (
        <ActionMenu data-testid="report-item-action-footer" ref={menuRef}>
          <ActionButton data-testid="edit-report-button" onClick={editReport}>
            <Icon name="pencil" />
            <ActionButtonLabel>{text.editReport}</ActionButtonLabel>
          </ActionButton>
          {canCopy && (
            <ActionButton data-testid="copy-report-button" onClick={copyReport}>
              <Icon name="copy" />
              <ActionButtonLabel>{text.copyReport}</ActionButtonLabel>
            </ActionButton>
          )}
          {!isBlocked && (
            <ActionButton
              data-testid="delete-report-button"
              onClick={deleteReport}
            >
              {loading ? (
                <Spinner name="loading" />
              ) : (
                <Icon name="trashcan" color={theme.color('danger')} />
              )}
              <ActionButtonLabel danger>{text.deleteReport}</ActionButtonLabel>
            </ActionButton>
          )}
        </ActionMenu>
      )}
    </CardContainer>
  );
};

// add static height to actionFooter in cypress mode because it doesn`t support css pseudo classes
const ActionMenu = styled.div<{}>`
  display: flex;
  flex-direction: column;
  width: 150px;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;

  z-index: 6000;
  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    box-shadow: ${theme.boxShadow('base')};
  `}
`;

const ActionButton = styled.div<{}>`
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;

  ${({ theme }) => css`
    padding: ${theme.space('s')};
    background-color: ${theme.color('white')};
    border-bottom: 1px solid ${theme.color('grey')};

    &:hover {
      background-color: ${theme.color('grey')};
    }

    &:last-child {
      border-bottom: none;
    }
  `};
`;

const ActionButtonLabel = styled.span<{ danger?: boolean }>(
  ({ theme, danger = false }) => css`
    margin-left: ${theme.space('s')};
    color: ${danger ? theme.color('danger') : theme.color('text')};
  `,
);

const CardFooterContainer = styled.div<{}>(
  ({ theme }) => css`
    position: relative;
    padding: ${theme.space('m')};
    height: 110px;
    padding-left: ${theme.space('l')};
  `,
);
const TextWithMenuContainer = styled.div<{}>(
  () => css`
    position: relative;
    height: 3.3em;
    width: 100%;
  `,
);
const TextContainer = styled.div<{}>(
  () => css`
    position: relative;
    margin-right: 2em;
    margin-bottom: 0px;
  `,
);

const OptionsContainer = styled.div<{}>(
  () =>
    css`
      display: inline-block;
      padding-left: 5px;
    `,
);

const CARD_WIDTH = 280;
const CardContainer = styled.div<{}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  ${({ theme }) => css`
    width: ${CARD_WIDTH}px;
    margin: 0 ${theme.space('l')} ${theme.space('l')} 0;
    box-shadow: ${theme.getTokens().boxShadow.base};
    border-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('white')};

    &:hover ${ColouredDiv}, &:hover ${Image} {
      transform: scale(1.2, 1.2);
    }
  `}
`;

const Title = styled(MarginlessH5)<{}>(
  ({ theme }) => css`
    font-weight: bold;
    margin-top: ${theme.space('xxs')};
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
);
const CORNER_SPAN_WIDTH = 175;
const CORNER_SPAN_HEIGHT = 175;

const CornerSpan = styled.span<{ label: string }>`
  position: absolute;
  width: ${CORNER_SPAN_WIDTH}px;
  height: ${CORNER_SPAN_HEIGHT}px;
  text-align: center;
  display: block;
  top: 0;
  right: 0;

  &:before {
    content: '${({ label }) => label}';
    transform: rotate(45deg);
    position: absolute;
    right: -2px;
    top: 27px;
    z-index: 50;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    z-index: 40;

    border-style: solid;
    border-width: 0 ${CORNER_SPAN_WIDTH / 2}px ${CORNER_SPAN_WIDTH / 2}px 0;

    overflow: hidden;
    position: absolute;
  }

  ${({ theme }) => css`
    color: ${theme.color('white')};
    font-size: ${theme.fontSize('s')};
    &:after {
      border-color: transparent ${theme.color('primary')} transparent
        transparent;
    }
  `}
`;
const ImageContainer = styled.div<{}>`
  overflow: hidden;
  width: 100%;
  height: ${IMAGE_CONTAINER_HEIGHT_IN_PX}px;
  position: relative;
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    border-top-left-radius: ${theme.getTokens().border.radius.s};
    border-top-right-radius: ${theme.getTokens().border.radius.s};
    &:after {
      border-color: transparent ${theme.color('primary')} transparent
        transparent;
    }
  `};
`;

const Image = styled.div<{
  src: string;
}>`
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  background-size: cover;
  ${({ theme, src }) => css`
    background-image: url(${src});
    border-radius: ${theme.getTokens().border.radius.s};
  `};
`;

const LockBlock = styled.div<{}>`
  position: absolute;
  top: 50px;
  left: 50%;
  margin-left: -${BLOCK_ICON_SIZE / 2}em;

  ${({ theme }) => css`
    height: ${theme.space('l')};
    color: ${theme.color('primary')};
  `};
`;

const LockStyled = styled(Icon)<{}>`
  > svg {
    height: ${BLOCK_ICON_SIZE}em;
    width: ${BLOCK_ICON_SIZE}em;
    ${({ theme }) => css`
      color: ${theme.color('white')};
    `};
  }
`;

const LinkStyled = styled(Link)<{}>`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`;

const StyledLinkAsExternalLink = styled(Link)<{}>(
  ({ theme }) => css`
    text-decoration: underline;
    display: block;
    margin-top: ${theme.space('xxs')};
    color: ${theme.color('primary', 'light')};
  `,
);

const ColouredDiv = styled.div<{ backgroundColor: Props['backgroundColor'] }>`
  width: 100%;
  height: ${IMAGE_CONTAINER_HEIGHT_IN_PX}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  ${({ backgroundColor, theme }) => css`
    background-color: ${backgroundColor};
    color: ${theme.color('white')};
    padding: ${theme.space('xxl')};
    transition: transform 0.3s ease;
  `};
`;

const ValuationReportIllustration = styled(Illustration)<{}>`
  width: 55%;
`;

const StyledDotMenuIcon = styled(Icon)<{}>`
  position: absolute;
  top: 2px;
  right: 0;
  cursor: pointer;
`;

export default ValueReportCard;
