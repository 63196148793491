import { FlowConditionAppFundaBrochureRequestNew } from './types.flow';
import { FLOW_CONDITION_TYPES } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';

const emptyFlowConditionAppFundaBrochureRequestNew = (): Omit<
  FlowConditionAppFundaBrochureRequestNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_FUNDA_BROCHUREREQUEST_NEW.type,
});

export default emptyFlowConditionAppFundaBrochureRequestNew;
