/**
 * The goal of this component is to keep changes in the list alive long enough for the add/remove animations of the SlideOpenAnimationContainer to be completed.
 *
 * So use in conjunction with SlideOpenAnimationContainer around your list component
 */

import { Component } from 'react';

import { Task } from '~/scenes/Tasks/types';

import { SLIDE_OPEN_ANIMATION_DURATION_IN_SECONDS } from '~/components/SlideOpenAnimationContainer';

export const ADD_OR_DELETE_ANIMATION_DURATION_IN_MILLISECONDS =
  SLIDE_OPEN_ANIMATION_DURATION_IN_SECONDS * 1000 + 1000; // Add an extra second for calculation time

export type WithNewOrDeleted<T> = {
  isRemovedFromList: boolean;
  isHighlighted?: boolean;
  wasAddedLater: boolean;
  item: T;
};
type DeletedItem<T> = {
  item: T;

  /** Time in ms when the item was seen as removed from the list */
  activatedTime: number;

  /** The index of the list we last saw this one */
  atIndex: number;
};
type NewItem<T> = {
  item: T;

  /** Time in ms when the item was added to the list */
  activatedTime: number;
};
type Props<T> = {
  /** The items to show */
  items: Array<T>;

  /** Component that will receive the updated list */
  childComponent: (childProps: {
    updatedItemList: Array<WithNewOrDeleted<T>>;
    hasLingeringItems: boolean;
  }) => React.ReactNode;
};
type State = {};
class ListDelayer<T extends { id: string }> extends Component<Props<T>, State> {
  deletedList: Array<DeletedItem<T>> = [];
  newList: Array<NewItem<T>> = [];
  prevList: Array<T> | null = null;

  render() {
    const { childComponent, items } = this.props;
    let updatedItemList: Array<WithNewOrDeleted<T>>;

    if (this.prevList === null) {
      this.prevList = items;

      updatedItemList = items.map(asNonChangedItem);
    } else {
      const now = Date.now();

      // Remove any finished animations from the special lists
      this.deletedList = this.deletedList.filter(
        itemInList =>
          now - itemInList.activatedTime <=
          ADD_OR_DELETE_ANIMATION_DURATION_IN_MILLISECONDS,
      );
      this.newList = this.newList.filter(
        itemInList =>
          now - itemInList.activatedTime <=
          ADD_OR_DELETE_ANIMATION_DURATION_IN_MILLISECONDS,
      );

      // Go through the new list from props and check if any item is new
      items.forEach(item => {
        if (
          this.prevList &&
          this.prevList.findIndex(prevItem => prevItem.id === item.id) >= 0
        ) {
          // exists already, ignore
        } else {
          // NEW item, save in new list
          this.newList.push({ item, activatedTime: now });
        }
      });

      // For flow, is not smart enough to figure out that prevList can not be null here
      if (this.prevList === null) {
        updatedItemList = items.map(asNonChangedItem);
      } else {
        // Go through the previous list and check if any item is removed
        this.prevList.forEach((prevItem, index) => {
          const foundIndex = items.findIndex(item => item.id === prevItem.id);
          if (foundIndex >= 0) {
            // is same, ignore
          } else {
            // REMOVED, put in deleted list
            this.deletedList.push({
              item: prevItem,
              activatedTime: now,
              atIndex: index,
            });
          }
        });

        // stitch stuff together
        const stitchedItemList: Array<WithNewOrDeleted<T>> = [];
        let currentIdx = 0;
        items.forEach((item, index) => {
          // if we saw an item at this index that is deleted then add that and up the idx
          const itemDeletedAtIndex = this.deletedList.find(
            deletedTask => deletedTask.atIndex === index,
          );
          if (itemDeletedAtIndex) {
            stitchedItemList[currentIdx] = asDeletedItem(
              itemDeletedAtIndex.item,
            );
            currentIdx = currentIdx + 1;
          }

          // add the item to the list, but add wasAddedLater if in the newList
          if (
            this.newList.findIndex(newItem => item.id === newItem.item.id) >= 0
          ) {
            stitchedItemList[currentIdx] = asNewItem(item);
          } else {
            stitchedItemList[currentIdx] = asNonChangedItem(item);
          }
          currentIdx = currentIdx + 1;
        });

        this.prevList = items;
        updatedItemList = stitchedItemList;
      }
    }

    const hasLingeringItems = this.deletedList.length > 0;

    return childComponent({
      updatedItemList,
      hasLingeringItems,
    });
  }
}

export const asNonChangedItem = <T>(item: T): WithNewOrDeleted<T> => ({
  item,
  isHighlighted: false,
  isRemovedFromList: false,
  wasAddedLater: false,
});

export const asDeletedItem = <T>(item: T): WithNewOrDeleted<T> => ({
  item,
  isHighlighted: false,
  isRemovedFromList: true,
  wasAddedLater: false,
});

export const asNewItem = <T>(item: T): WithNewOrDeleted<T> => ({
  item,
  isHighlighted: false,
  isRemovedFromList: false,
  wasAddedLater: true,
});

export class TaskListDelayer extends ListDelayer<Task> {}

export default ListDelayer;
