import React, { ReactElement } from 'react';

import { AssignContactAction } from './types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';
import {
  OfficeParameterValue,
  UserParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithAccountContext } from '~/contexts/AccountContext';

import { withAccountContext } from '~/contexts/AccountContext';
import FieldLabel from '~/scenes/Automation/Flows/Actions/FieldLabel';
import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import Checkbox from '~/components/Inputs/Checkbox';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import asAssignContactActionInput from './asAssignContactActionInput';
import { FLOW_ACTION_TYPE } from '../constants';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import ActionExplanationHeader from '../ActionExplanationHeader';
import ActionContentContainer from '../ActionContentContainer';
import {
  OfficeParameterValueComponent,
  OfficePrimitiveParameterValueComponent,
} from '../Base/FlowParameter/ParameterValue/Office';
import {
  UserParameterValueComponent,
  UserPrimitiveParameterValueComponent,
  emptyUserPrimitiveParameterValue,
} from '../Base/FlowParameter/ParameterValue/User';
import { InputGroup } from '~/components/Inputs';
import { PARAMETER_VALUE_TYPE } from '../Base/FlowParameter/ParameterValue/constants';
import TEST_ID from './AssignContactActionComponent.testid';

const text = {
  explanation: 'Contact toewijzen',
  officeLabel: 'Selecteer vestiging',
  userLabel: 'Selecteer gebruiker',
  overwrite: 'Huidige eigenaar van het contact overschrijven',
};
type MyProps = ActionSubscriberProps & {
  id: string;
  isHiddenFromView: boolean;
  positionString: string;
  initialActionProps: AssignContactAction;
};
type WithoutValidatorProps = WithAccountContext & MyProps;
type Props = WithoutValidatorProps & WithValidatorListProps;
type State = {
  /** To rerender the component you can update this number. Needed to rerender the description */
  renderChangeCounter: number;
  key: string | null;
  office: OfficeParameterValue;
  user: UserParameterValue;
  overwriteAssignee: boolean;
  variableStashVersion: string | null;
};
class AssignContactActionComponent extends React.Component<Props, State> {
  _isMounted = false;
  variableStash: FlowVariableStash = emptyVariableStash();

  constructor(props: Props) {
    super(props);

    const { initialActionProps } = props;
    const { actionInput } = initialActionProps;

    this.state = {
      renderChangeCounter: 0,
      key: null,
      office: actionInput.office,
      user: actionInput.user,
      overwriteAssignee: actionInput.overwriteAssignee,
      variableStashVersion: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.props.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asAssignContactActionInput(this.getActionProp(), treeNode, defaults);

  updateOffice = (office: OfficeParameterValue) => {
    if (this._isMounted) {
      const { user } = this.state;

      this.props.announceChanges();

      let newState = {
        office: office,
        user: user,
      };

      if (
        office.type === PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE &&
        user.type === PARAMETER_VALUE_TYPE.USER_PRIMITIVE
      ) {
        if (office.value != null && user.value != null) {
          const possibleUsers = this.props.accountContext.usersForOffice(
            office.value,
          );

          if (
            possibleUsers.find(
              possibleUser => possibleUser.id === user.value,
            ) == null
          ) {
            newState = {
              ...newState,
              user: emptyUserPrimitiveParameterValue(),
            };
          }
        }
      }

      this.setState(newState);
    }
  };

  updateUser = (user: UserParameterValue) => {
    if (this._isMounted) {
      this.props.announceChanges();

      this.setState({
        user: user,
      });
    }
  };

  onOverwriteChecked = () => {
    if (this._isMounted) {
      this.setState(
        (state: State) => ({
          overwriteAssignee: !state.overwriteAssignee,
        }),
        () => {
          this.props.announceChanges();
        },
      );
    }
  };

  setVariableStash = (newStash: FlowVariableStash) => {
    this.variableStash = newStash;

    if (this._isMounted) {
      this.setState({
        variableStashVersion: newStash.version,
      });
    }
  };

  getActionProp = (): AssignContactAction => {
    const { id } = this.props;
    const { overwriteAssignee, user, office } = this.state;

    return {
      type: FLOW_ACTION_TYPE.ASSIGN_CONTACT,
      id,
      actionInput: {
        user,
        office,
        overwriteAssignee,
      },
    };
  };

  render() {
    const { overwriteAssignee, user, office } = this.state;
    const {
      subscribeValidator,
      unsubscribeValidator,
      isHiddenFromView,
      positionString,
    } = this.props;
    const action = this.getActionProp();

    let officeFieldComponent: ReactElement | null = null;
    if (office.type === PARAMETER_VALUE_TYPE.OFFICE_PRIMITIVE) {
      officeFieldComponent = (
        <OfficePrimitiveParameterValueComponent
          value={office}
          onChange={this.updateOffice}
          isMandatory={true}
        />
      );
    } else {
      officeFieldComponent = (
        <OfficeParameterValueComponent
          value={office}
          onChange={this.updateOffice}
          isMandatory={true}
        />
      );
    }
    let userFieldComponent: ReactElement | null = null;
    if (user.type === PARAMETER_VALUE_TYPE.USER_PRIMITIVE) {
      userFieldComponent = (
        <UserPrimitiveParameterValueComponent
          value={user}
          onChange={this.updateUser}
          officeId={getOfficeId(office)}
        />
      );
    } else {
      userFieldComponent = (
        <UserParameterValueComponent
          value={user}
          onChange={this.updateUser}
          officeId={getOfficeId(office)}
        />
      );
    }

    return (
      <BaseActionComponent
        positionString={positionString}
        actionLabel={text.explanation}
        action={action}
        variableStash={this.variableStash}
        subscribeValidator={subscribeValidator}
        unsubscribeValidator={unsubscribeValidator}
        isHiddenFromView={isHiddenFromView}
      >
        <ActionExplanationHeader title={text.explanation} />
        <ActionContentContainer>
          <InputGroup data-testid={TEST_ID.OFFICE}>
            <FieldLabel>{text.officeLabel}</FieldLabel>
            {officeFieldComponent}
          </InputGroup>
          <InputGroup data-testid={TEST_ID.USER}>
            <FieldLabel>{text.userLabel}</FieldLabel>
            {userFieldComponent}
          </InputGroup>
          <InputGroup>
            <Checkbox
              value={overwriteAssignee}
              data-testid={TEST_ID.CHECKBOX}
              onChange={this.onOverwriteChecked}
              label={text.overwrite}
              name="overwriteAssignee"
            />
          </InputGroup>
        </ActionContentContainer>
      </BaseActionComponent>
    );
  }
}

const getOfficeId = (
  office: OfficeParameterValue,
): string | null | undefined => {
  if (office.type === PARAMETER_VALUE_TYPE.OFFICE_POINTER) {
    return null;
  }

  return office.value;
};

export default withAccountContext<MyProps>(
  withValidatorList<WithoutValidatorProps>(AssignContactActionComponent),
);
