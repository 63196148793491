import gql from 'graphql-tag';
import ZapierTriggerFields from '../fragments/ZapierTriggerFields';

export default gql`
  query GetZapierTrigger($accountId: ID!, $id: ID!) {
    getZapierTrigger(accountId: $accountId, id: $id) {
      ...ZapierTriggerFields
    }
  }
  ${ZapierTriggerFields}
`;
