import {
  StringField,
  TypedField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';

export const FLOW_CONDITION_FUNDA_VIEWINGREQUEST_DETAILS_FIELD = Object.freeze({
  day: {
    name: 'day',
    label: 'Voorkeursdag',
    type: FIELD_TYPE.STRING,
  } as StringField,
  timeOfDay: {
    name: 'timeOfDay',
    label: 'Voorkeursdagdeel',
    type: FIELD_TYPE.STRING,
  } as StringField,
  subjectAddress: {
    name: 'subjectAddress',
    label: 'Adres uit Funda verzoek',
    type: FIELD_TYPE.STRING,
    helpLink: 'http://help.dathuis.nl/nl/articles/4069417-condities',
  } as StringField,
});

export const FLOW_CONDITION_FUNDA_VIEWINGREQUEST_DETAILS_FIELDS: Array<TypedField> =
  Object.keys(FLOW_CONDITION_FUNDA_VIEWINGREQUEST_DETAILS_FIELD).map(
    key => FLOW_CONDITION_FUNDA_VIEWINGREQUEST_DETAILS_FIELD[key],
  );

export const DEFAULT_FLOW_CONDITION_FUNDA_VIEWINGREQUEST_DETAILS_FIELD: TypedField =
  FLOW_CONDITION_FUNDA_VIEWINGREQUEST_DETAILS_FIELD.subjectAddress;
