import React from 'react';
import styled, { css } from 'styled-components';

import { LinkButton } from '~/components/Buttons';
import { Heading2 } from '~/components/Typography/index';
import Portal from './Portal';
import Icon from './Icon';
import TextButton from './TextButton';
import { MAIN_CONTENT_WIDTH } from './NavigationFrame';

type Props = {
  /** Should be sidebar visible or not */
  active: boolean;

  /** Sidebar content */
  children: React.ReactNode;

  /** Action for close button click */
  hideSidebar?: () => void;

  /** The title to show */
  title?: string;

  /** Takes up as much space as main content */
  wide?: boolean;

  /** If there is a go back button (for drawer workflows) */
  goBack?: {
    onClick: () => void;
    label: string;
  };

  /** Render something at the bottom */
  renderFooter?: () => React.ReactNode;
};

export const SIDEBAR_TRANSITION_TIME = 400;
const Drawer = ({
  active,
  hideSidebar,
  children,
  title,
  wide,
  goBack,
  renderFooter,
}: Props) => (
  <Portal>
    <SidebarWrapper active={active}>
      <Sidebar className="sidebar" data-testid="sidebar-drawer" wide={wide}>
        <CloseButton
          size="medium"
          onClick={hideSidebar}
          dataTestId="close-sidebar"
          label={<StyledCloseIcon name="close" />}
        />

        <Section>
          {goBack ? (
            <LinkButton onClick={goBack.onClick} testId="sidebar-go-back">
              {goBack.label}
            </LinkButton>
          ) : null}
          {title ? <Heading2 withoutMargin>{title}</Heading2> : null}
          {children}
        </Section>
        <Footer>{renderFooter && renderFooter()}</Footer>
      </Sidebar>
    </SidebarWrapper>
  </Portal>
);

const SidebarWrapper = styled.aside<{ active?: boolean }>`
  position: fixed;
  background: transparent;
  z-index: ${({ theme }) => theme.getTokens().zIndex.top};
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: background ${SIDEBAR_TRANSITION_TIME}ms ease-in;
  pointer-events: none;

  ${props =>
    props.active &&
    css`
      background: rgba(0, 0, 0, 0.5);
      pointer-events: auto;

      .sidebar {
        transform: translateX(0);
      }
    `};
`;

const CloseButton = styled(TextButton)<{}>`
  grid-column: close-icon-start / close-icon-end;
  grid-row: close-icon-start / close-icon-end;
  place-self: center;
`;

type SidebarProps = {
  wide?: boolean;
};
const Sidebar = styled.section<SidebarProps>`
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;

  height: 100vh;

  display: grid;
  grid-template-columns: [main-start close-icon-start] 40px [close-icon-end content-start] auto [content-end] 40px [main-end];
  grid-template-rows: [main-start close-icon-start] min-content [close-icon-end content-start] auto [content-end main-end footer-start] min-content [footer-end];

  transition: transform 0.2s ease-in;
  transform: translateX(100%);

  ${({ theme }) => css`
    z-index: ${theme.z('top')};
    padding: ${theme.space('xxs')};
    background: ${theme.color('white')};
    grid-row-gap: ${theme.space('m')};
    grid-column-gap: ${theme.space('xxs')};
  `};

  ${({ wide }) => {
    if (wide) {
      return css`
        width: 100vw;

        @media (min-width: ${MAIN_CONTENT_WIDTH}px) {
          width: ${MAIN_CONTENT_WIDTH}px;
        }
      `;
    } else {
      return css`
        width: 460px;
      `;
    }
  }};
`;

const MAX_HEIGHT_FOR_GOING_SMALL = '600px';
const MAX_WIDTH_FOR_GOING_SMALL = '400px';
const Section = styled.section<{}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  grid-column: content-start / content-end;
  grid-row: content-start / content-end;

  @media (max-width: ${MAX_WIDTH_FOR_GOING_SMALL}) {
    grid-column: main-start / main-end;
  }

  @media (min-width: ${MAX_WIDTH_FOR_GOING_SMALL}) and (max-height: ${MAX_HEIGHT_FOR_GOING_SMALL}) {
    grid-row: main-start / main-end;
  }
`;

const StyledCloseIcon = styled(Icon)<{}>(
  () => css`
    font-size: 1.5em;
  `,
);

const Footer = styled.div<{}>`
  grid-column: content-start / content-end;
  grid-row: footer-start / footer-end;
`;

export default Drawer;
