import uuidv1 from 'uuid/v1';

import Amplify from '~/amplify';
import cleanedFilename from '~/util/cleanedFilename';

export type UploadImageReturnType = {
  url: string;
  filename: string;
  s3key: string;
  contentType: string;
  contentLength: number;
};
const uploadImageToStorage = async (
  image: File | null,
  userId: string,
): Promise<UploadImageReturnType> => {
  // generate a filename
  const name = uuidv1();

  if (!image)
    return {
      url: '',
      s3key: '',
      filename: '',
      contentType: '',
      contentLength: 0,
    };

  const contentType = image.type;
  if (contentType == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | The contentType is undefined!`,
    );
  }

  const contentLength = image.size;
  if (contentLength == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | The contentLength is undefined!`,
    );
  }

  if (userId == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | The userId is undefined!`,
    );
  }

  const result = (await Amplify.Storage.put(`${userId}/${name}`, image, {
    contentType,
    level: 'private',
  })) as { key: string | null };

  const { key } = result;

  if (key == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | The key is undefined!`,
    );
  }

  const url = (await Amplify.Storage.get(key, { level: 'private' })) as string;
  const credentials = await Amplify.Auth.currentCredentials();

  if (name == null) {
    throw Error(
      `${cleanedFilename(__filename)} | Should not occur | Name is undefined!`,
    );
  }

  return {
    url,
    // level is private so is always tmpPrivate. Rest is as mandated by backend
    s3key: `tmpPrivate/${credentials.identityId}/${key}`,
    filename: name,
    contentType,
    contentLength,
  };
};

export default uploadImageToStorage;
