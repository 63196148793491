import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionWait } from '~/graphql/types.client';
import getConditionListInputForTrigger from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/utils/getConditionListInputForTrigger';
import removeTypenames from '../../../removeTypenames';

/**
 *
 * @param {ClientFlowActionWait} action - Wait client action
 * keywords:
 */
const serializeWaitAction = (
  action: ClientFlowActionWait,
): FlowV2_Action__Input => {
  const { id, accountId, parentIds, flowBlueprintId, triggers, connector } =
    action;

  const convertedCondition = getConditionListInputForTrigger(
    triggers,
    connector,
  );

  return {
    Wait: {
      id,
      accountId,
      flowBlueprintId,
      parentIds,
      condition:
        'result' in convertedCondition
          ? removeTypenames(convertedCondition?.result)
          : {
              exp: '(0)',
              conditions: [],
            },
    },
  };
};

export default serializeWaitAction;
