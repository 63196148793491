export enum FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION {
  EQUALS = 'Flow_ConditionPrimitive_Number_Eq',
  LESS_THAN = 'Flow_ConditionPrimitive_Number_Lt',
  GREATER_THAN = 'Flow_ConditionPrimitive_Number_Gt',
}

export enum NUMBER_OPERATION_OPTION {
  LESS_THAN = 'Number_LessThan',
  EQUALS = 'Number_Equals',
  GREATER_THAN = 'Number_GreaterThan',
  NOT_LESS_THAN = 'Number_NotLessThan',
  NOT_EQUAL = 'Number_NotEqual',
  NOT_GREATER_THAN = 'Number_NotGreaterThan',
}

export const FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION_LABELS: {
  [type: string]: { label: string };
} = Object.freeze({
  [NUMBER_OPERATION_OPTION.EQUALS]: {
    label: 'Is gelijk aan',
  },
  [NUMBER_OPERATION_OPTION.NOT_EQUAL]: {
    label: 'Is niet gelijk aan',
  },
  [NUMBER_OPERATION_OPTION.GREATER_THAN]: {
    label: 'Meer dan',
  },
  [NUMBER_OPERATION_OPTION.NOT_GREATER_THAN]: {
    label: 'Niet meer dan',
  },
  [NUMBER_OPERATION_OPTION.LESS_THAN]: {
    label: 'Minder dan',
  },
  [NUMBER_OPERATION_OPTION.NOT_LESS_THAN]: {
    label: 'Niet minder dan',
  },
});
