import { StripeSubscription } from '../../types.flow';

import { isAfter } from '~/util/date';

/**
 * Based on the given subscriptions the date the subscriptions will be cancelled is given.
 * If it won't be cancelled null is given.
 */
const getCancelledDate = (
  subscriptions: Array<StripeSubscription>,
): Date | null => {
  let cancelledDate: Date | null = null;
  let hasOpenEnded = false;

  subscriptions.forEach(subscription => {
    const { endDate } = subscription;
    if (endDate != null) {
      if (cancelledDate == null) {
        cancelledDate = endDate;
      } else if (isAfter(endDate, cancelledDate)) {
        cancelledDate = endDate;
      }
    } else {
      hasOpenEnded = true;
    }
  });

  if (hasOpenEnded) {
    return null;
  } else {
    return cancelledDate;
  }
};

export default getCancelledDate;
