import { FlowConditionAppZapierNew } from './types.flow';

import { FlowConditionFields_Flow_Condition_Event_Contact_App_Zapier_New_Fragment } from '~/graphql/types';
import { asStringPrimitiveParameterValue } from '../../FlowParameter';

export default (
  condition: FlowConditionFields_Flow_Condition_Event_Contact_App_Zapier_New_Fragment,
): Omit<FlowConditionAppZapierNew, 'id'> => {
  const { zapierEventId } = condition;

  if (!isStringPrimitive(zapierEventId)) {
    throw new Error(
      'Should not occur: zapierEventId needs to be a string primitive',
    );
  }

  return {
    type: 'Flow_Condition_Event_Contact_App_Zapier_New',
    zapierEventId: asStringPrimitiveParameterValue(zapierEventId),
  };
};

const isStringPrimitive = (
  zapierEventId: FlowConditionFields_Flow_Condition_Event_Contact_App_Zapier_New_Fragment['zapierEventId'],
): zapierEventId is Extract<
  FlowConditionFields_Flow_Condition_Event_Contact_App_Zapier_New_Fragment['zapierEventId'],
  { __typename: 'Flow_Parameter_String_Primitive' }
> => zapierEventId.__typename.startsWith('Flow_Parameter_String_Primitive');
