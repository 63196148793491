import React from 'react';

type CognitoUser = Object | null;
type AuthContextProps = {
  user: CognitoUser;
  updateCognitoUser: (user: CognitoUser) => void;
};
type AuthProviderProps = { children: any };
type AuthProviderState = {
  user: CognitoUser;
  updateCognitoUser: (user: CognitoUser) => void;
};

const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  updateCognitoUser: () => {},
});
export const AuthContextConsumer = AuthContext.Consumer;

export class AuthContextProvider extends React.Component<
  AuthProviderProps,
  AuthProviderState
> {
  state = {
    user: null,
    updateCognitoUser: (user: CognitoUser) => this.updateCognitoUser(user),
  };

  updateCognitoUser = (user: CognitoUser) => {
    this.setState({ user });
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
