import React from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  AppBbWaardecheckValuationFailure,
  AppVboWaardecheckValuationFailure,
} from '~/graphql/types';
import InputGroupDivider from '~/scenes/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import {
  AppWaardecheckFieldsFragment,
  appWaardecheckState,
  StateId,
} from '../..';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppSectionHeader from '~/scenes/Apps/components/AppSectionHeader';
import WaardecheckIntl from '../../intl';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import IntlInputComponent from '../../components/IntlInputComponent';
import ImageInput from '../../components/ImageInput';
import MainPreview from '../../components/MainPreview';
import { Page } from '../../components/Preview';
import TEST_ID from './index.testid';
import Explanation from '../../components/Explanation';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
};

const ValuationFailure: React.FC<Props> = ({
  data,
  defaultValues,
  loading,
}) => {
  const original = defaultValues.valuationFailure;
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const updateValue = (
    value: DeepPartial<
      AppBbWaardecheckValuationFailure | AppVboWaardecheckValuationFailure
    >,
  ) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        valuationFailure: value,
      }) as any as typeof prev;
    });
  };

  if (original == null || updated == null) return null;

  return (
    <AppDetailsContainer
      header="Geen matches"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Explanation>
        Als er minder dan twee vergelijkbare objecten worden geselecteerd, wordt
        deze pagina getoond. Er kan worden doorverwezen naar een waardebepaling,
        of de objecten kunnen opnieuw worden bekeken. Personaliseer de
        achtergrondfoto en teksten.
      </Explanation>
      <MainPreview data={updated} page={Page['/failure']} />

      <AppEntryItemHeader>{WaardecheckIntl.mainImage.title}</AppEntryItemHeader>
      <Description>{WaardecheckIntl.mainImage.description}</Description>
      <ImageInput
        initialUrl={data.valuationFailure.image?.url}
        filename={data.__typename + 'valuationfailure'}
        s3Key={updated?.valuationFailure.image?.s3key}
        onChange={image => updateValue({ image })}
        dataTestid={TEST_ID.IMAGE}
      />
      <InputGroupDivider large />
      <AppSectionHeader>{WaardecheckIntl.section.text}</AppSectionHeader>
      <Description>{WaardecheckIntl.section.textDescription}</Description>
      <IntlInputComponent
        title={WaardecheckIntl.heading}
        defaultValue={original.heading}
        value={updated.valuationFailure.heading}
        onChange={heading => updateValue({ heading })}
        dataTestId={TEST_ID.HEADING}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Toelichting"
        defaultValue={original.body}
        value={updated.valuationFailure.body}
        onChange={body => updateValue({ body })}
        dataTestId={TEST_ID.BODY}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Aanvraag versturen button"
        defaultValue={original.primaryButton}
        value={updated.valuationFailure.primaryButton}
        onChange={primaryButton => updateValue({ primaryButton })}
        dataTestId={TEST_ID.PRIMARY_BUTTON}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Stap terug button"
        defaultValue={original.secondaryButton}
        value={updated.valuationFailure.secondaryButton}
        onChange={secondaryButton => updateValue({ secondaryButton })}
        dataTestId={TEST_ID.SECONDARY_BUTTON}
      />
    </AppDetailsContainer>
  );
};

export default ValuationFailure;
