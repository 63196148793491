import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import { Body } from '~/components/Typography/index';

type Props = {
  label: string;
  amount: number;
};

const AmountRow: React.FC<Props> = ({ label, amount }) => (
  <JustificationContainer align="center">
    <Body withoutMargin>{label}</Body>
    <AmountLabel
      align="center"
      justification="center"
      padding={['xxxs', 'xxs']}
      margin={[null, null, null, 'base']}
    >
      <div>{amount}</div>
    </AmountLabel>
  </JustificationContainer>
);

const AmountLabel = styled(JustificationContainer)(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('warning')};
    color: ${theme.color('white')};
  `,
);

export default AmountRow;
