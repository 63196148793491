import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { Task } from '~/scenes/Tasks/types';

import { UpdateTaskMutationVariables } from '~/graphql/types';

import TaskClosedBy from './TaskClosedBy';
import TaskInfoBlock from '~/components/TaskInfoBlock';
import TagButtonBar from './TagButtonBar';
import TwoStepDeleteButton from '~/components/Buttons/TwoStepDeleteButton';
import { variablesForUpdate } from './TaskInList';
import { iconForTypeInHeader } from '~/util/taskTypeOptions';
import stripHtml from '~/util/stripHtml';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useOffice from '~/hooks/useOffice';
import getUserName from '~/util/getUserName';
import useUser from '~/hooks/useUser';
import useViewingModeProps from '~/hooks/useViewingModeProps';

type Props = {
  /** The task to show: { dueDate, type, status, description, officeId?, userId?, Contact? } */
  task: Task;

  /** If this task is rendered in the completed list */
  isCompleted: boolean;

  /** If the task is in the loading state (mutation is running) */
  loading: boolean;

  /** If the task is in the disabled state */
  disabled: boolean;

  /** If the task details should be hidden if the task is completed */
  hideTaskDetailsOnCompleted?: boolean;

  /** To call when the task is changed */
  onTaskChange: (variables: UpdateTaskMutationVariables) => any;
};
const TaskInformationBlock = ({
  task,
  isCompleted,
  loading,
  disabled,
  onTaskChange,
  hideTaskDetailsOnCompleted,
}: Props) => {
  const account = useCurrentAccount();
  const viewingModeProps = useViewingModeProps();

  const accountId = account.id;

  const {
    title,
    dueDate,
    closedDate,
    type,
    Contact,
    description,
    userId,
    officeId,
    closedByName,
  } = task;

  const user = useUser(userId);
  const userName = user ? getUserName(user) : null;

  const officeName = useOffice(officeId)?.name;

  const text = description === null ? null : stripHtml(description);

  let taskDetailsComponent: ReactElement | null = (
    <>
      <TaskInfoBlockContainer>
        <TaskInfoBlock
          contactName={Contact ? Contact.name : null}
          officeName={officeName || null}
          userName={userName}
          dueDate={dueDate}
        />
      </TaskInfoBlockContainer>
      <DescriptionContainer>
        <Description
          data-testid="task-in-overview-description"
          {...viewingModeProps}
        >
          {text}
        </Description>
        <TagButtonBar
          loading={loading}
          isCompleted={isCompleted}
          disabled={disabled || loading}
          onComplete={() => {
            if (disabled || loading) return;

            onTaskChange(variablesForUpdate(accountId, isCompleted, task.id));
          }}
        />
      </DescriptionContainer>
    </>
  );
  if (isCompleted && hideTaskDetailsOnCompleted) {
    taskDetailsComponent = null;
  }

  return (
    <Container>
      <TaskHeaderContainer>
        {iconForTypeInHeader(type)}
        <TaskName {...viewingModeProps} isCompleted={isCompleted}>
          {title}
        </TaskName>
        {isCompleted && closedDate && (
          <TaskClosedBy closedByName={closedByName} date={closedDate} />
        )}

        <DeleteButtonContainer>
          <TwoStepDeleteButton
            loading={loading}
            disabled={disabled}
            onDelete={() => {
              if (disabled || loading) return;

              onTaskChange({
                accountId,
                id: task.id,
                update: {
                  deleted: true,
                },
              });
            }}
          />
        </DeleteButtonContainer>
      </TaskHeaderContainer>
      {taskDetailsComponent}
    </Container>
  );
};

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
`;

const TaskHeaderContainer = styled.div<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    min-height: ${theme.space('xl')};
  `};
`;

type TaskNameProps = {
  isCompleted: boolean;
};
const TaskName = styled.div<TaskNameProps>`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;

  ${({ theme }) => css`
    margin: 0 0 0 ${theme.space('xxs')};
    font-size: ${theme.fontSize('m')};
  `};

  ${({ isCompleted }) => {
    if (isCompleted) {
      return css`
        text-decoration: line-through;
      `;
    }
    return null;
  }};
`;

const DeleteButtonContainer = styled.div<{}>`
  align-self: stretch;

  ${({ theme }) => css`
    margin-left: ${theme.space('m')};
  `};
`;

const TaskInfoBlockContainer = styled.div<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('s')};
  `};
`;

const DescriptionContainer = styled.div<{}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Description = styled.p<{}>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;

  ${({ theme }) => css`
    font-size: ${theme.fontSize('base')};
  `};
`;

export default TaskInformationBlock;
