import React from 'react';
import CallToActionBlock from '~/components/CallToActionBlock';
import { SystemSize } from '~/theme';

const text = {
  skipThisStep: 'Sla deze stap over',
};

export type Props = {
  dataTestId?: string;
  skipCurrentStep: () => void;
  description: string;
  margin?: Array<SystemSize | null>;
};

const SkipStepButtonBlock: React.FC<Props> = ({
  dataTestId,
  description,
  skipCurrentStep,
  margin,
}) => (
  <CallToActionBlock
    description={description}
    button={{
      label: text.skipThisStep,
      appearance: 'primary',
      icon: 'arrowRight',
      onClick: skipCurrentStep,
    }}
    icon={{ name: 'triangle', background: 'warning', size: 'large' }}
    margin={margin}
    padding={['base']}
    dataTestId={dataTestId}
    align="center"
  />
);

export default SkipStepButtonBlock;
