import React, { ReactElement } from 'react';

import Modal from '~/components/Modals/Modal';
import BottomButtonRow from '~/components/Modals/BottomButtonRow';
import SmallModalContainer from './components/SmallModalContainer';
import ModalInfoContainer from './components/ModalInfoContainer';
import ModalHeadline from './components/ModalHeadline';
import Button from '../Button';
import useKeybinding from '~/hooks/useKeybinding';

export type Props = {
  /** Action handler. confirm: boolean. If confirm === true, action submited*/
  handleAction: (confirm: boolean) => void;

  /** Title of modal */
  title?: string;

  /** Message of modal */
  message?: string;

  /** title for confirm button */
  buttonConfirmTitle?: string;

  /** title for cancel button */
  buttonCancelTitle?: string;

  /** Hides the cancel button */
  hideCancel?: boolean;
  dataTestid?: string;
  disabled?: boolean;
  loading?: boolean;
  actionType?: 'destructive' | 'normal';
};

const text = {
  defaultTitle: 'Er staan nog wijzigingen open',
  buttonConfirm: 'Wijzigingen vergeten',
  buttonCancel: 'Annuleer',
};

export const ConfirmModalContent: React.FC<Props> = ({
  handleAction,
  title,
  message,
  buttonConfirmTitle,
  buttonCancelTitle,
  dataTestid,
  hideCancel,
  children,
  disabled,
  loading,
  actionType = 'normal',
}) => {
  const titleText = title !== undefined ? title : text.defaultTitle;
  let messageComponent: React.ReactNode | null = null;
  if (message) {
    const messageParagraphs = message.split(/\r?\n/);

    messageComponent = messageParagraphs.map((par, idx) => (
      <p key={`p-${idx}`}>{par}</p>
    ));
  }

  const onCancel = e => {
    if (e != null) {
      e.stopPropagation();
      e.preventDefault();
    }
    handleAction(false);
  };

  const onConfirm = e => {
    if (e != null) {
      e.stopPropagation();
      e.preventDefault();
    }
    handleAction(true);
  };

  let cancelButton: ReactElement<typeof Button> | null = null;
  if (hideCancel !== true) {
    cancelButton = (
      <Button
        data-testid="button-modal-cancel"
        appearance="primary"
        ghost
        label={buttonCancelTitle || text.buttonCancel}
        onClick={onCancel}
      />
    );
  }

  useKeybinding({
    keys: 'enter',
    callback: onConfirm,
  });

  useKeybinding({
    keys: 'escape',
    callback: onCancel,
  });

  return (
    <SmallModalContainer data-testid={dataTestid || 'confirm-modal'}>
      <ModalInfoContainer>
        <ModalHeadline>{titleText}</ModalHeadline>
        {messageComponent}
        {children}
      </ModalInfoContainer>
      <BottomButtonRow>
        {cancelButton}
        <Button
          data-testid="button-modal-yes"
          appearance={actionType === 'destructive' ? 'danger' : 'primary'}
          onClick={onConfirm}
          disabled={disabled || loading}
          loading={loading}
          label={buttonConfirmTitle || text.buttonConfirm}
        />
      </BottomButtonRow>
    </SmallModalContainer>
  );
};

const ConfirmModal: React.FC<Props> = props => (
  <Modal small onClose={() => props.handleAction(false)}>
    <ConfirmModalContent {...props} />
  </Modal>
);

export default ConfirmModal;
