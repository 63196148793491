import React, { useState } from 'react';

import { NumberConditionField } from '../types.flow';
import { NumberParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { NumberOperation } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/Number/types.flow';
import { WithControlledSwitcherProps } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { Dropdown } from '~/components/Inputs';
import {
  FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION_LABELS,
  FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION,
  NUMBER_OPERATION_OPTION,
} from './constants';
import { emptyNumberPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';
import {
  NumberParameterValueComponent,
  NumberPrimitiveParameterValueComponent,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Number';
import TEST_ID from './FlowConditionPrimitiveNumberFieldComponent.testid';
import cleanedFilename from '~/util/cleanedFilename';
import Catalog from '~/Catalog';
import defaultNumberOperation from './defaultNumberOperation';
import { PARAMETER_VALUE_TYPE } from '../../../FlowParameter/ParameterValue/constants';

const text = {
  operatorLabel: Catalog.flows.operatorLabel,
};

export type ConditionPrimitiveNumberField =
  | NumberConditionField
  | (Omit<NumberConditionField, 'label' | 'name'> & {
      label: undefined;
      name: undefined;
    });

type Props = {
  field: ConditionPrimitiveNumberField;
  change: WithControlledSwitcherProps<ConditionPrimitiveNumberField>;
};

const FlowConditionPrimitiveNumberFieldComponent = ({
  field,
  change,
}: Props) => {
  const [numberParameterValueCache, setNumberParameterValueCache] =
    useState<NumberParameterValue>(
      field.operation && field.operation.value
        ? field.operation.value
        : emptyNumberPrimitiveParameterValue(),
    );
  const [stateOperationOption, setStateOperationOption] =
    useState<NumberOperation>(
      field.operation == null ? defaultNumberOperation() : field.operation,
    );

  const operation = change.controlled ? field.operation : stateOperationOption;
  const value =
    operation == null ? emptyNumberPrimitiveParameterValue() : operation.value;

  const changeProps: WithControlledSwitcherProps<NumberParameterValue> =
    change.controlled
      ? {
          controlled: true,
          onChange: (newValue: NumberParameterValue) => {
            setNumberParameterValueCache(newValue);

            change.onChange({
              ...field,
              operation: newOperationFor(
                convertPrimitiveNumberOperationToOption(operation),
                newValue,
              ),
            });
          },
        }
      : {
          controlled: false,
          onAdded: (newValue: NumberParameterValue) => {
            if (
              newValue.type === PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE &&
              newValue.value == null
            ) {
              return;
            }

            setNumberParameterValueCache(newValue);

            change.onAdded({
              ...field,
              operation: newOperationFor(
                convertPrimitiveNumberOperationToOption(stateOperationOption),
                newValue,
              ),
            });
          },
          announceChanges: change.announceChanges,
        };
  let valueComponent = (
    <NumberParameterValueComponent value={value} change={changeProps} />
  );

  // Only allow pointers if already given
  if (value.type === PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE) {
    valueComponent = (
      <NumberPrimitiveParameterValueComponent
        value={value}
        change={changeProps}
      />
    );
  }

  return (
    <>
      <Dropdown
        label={text.operatorLabel}
        dataTestid={TEST_ID.OPERATOR_DROPDOWN}
        options={operationOptions}
        selectedOptionIdx={
          operation == null
            ? null
            : operationOptions.findIndex(
                option =>
                  option.key ===
                  convertPrimitiveNumberOperationToOption(operation),
              )
        }
        onChange={({ option }) => {
          const newOperation = newOperationFor(
            option.payload,
            numberParameterValueCache,
          );
          if (change.controlled) {
            change.onChange({
              ...field,
              operation: newOperation,
            });
          } else {
            change.announceChanges();
            setStateOperationOption(newOperation);
          }
        }}
      />
      {valueComponent}
    </>
  );
};

const newOperationFor = (
  type: NUMBER_OPERATION_OPTION,
  newNumberValue: NumberParameterValue,
): NumberOperation => {
  let newOperation;

  switch (type) {
    case NUMBER_OPERATION_OPTION.EQUALS: {
      newOperation = {
        type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.EQUALS,
        value: newNumberValue,
        negate: false,
      };
      break;
    }
    case NUMBER_OPERATION_OPTION.NOT_EQUAL: {
      newOperation = {
        type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.EQUALS,
        value: newNumberValue,
        negate: true,
      };
      break;
    }
    case NUMBER_OPERATION_OPTION.GREATER_THAN: {
      newOperation = {
        type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.GREATER_THAN,
        value: newNumberValue,
        negate: false,
      };
      break;
    }
    case NUMBER_OPERATION_OPTION.NOT_GREATER_THAN: {
      newOperation = {
        type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.GREATER_THAN,
        value: newNumberValue,
        negate: true,
      };
      break;
    }
    case NUMBER_OPERATION_OPTION.LESS_THAN: {
      newOperation = {
        type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.LESS_THAN,
        value: newNumberValue,
        negate: false,
      };
      break;
    }
    case NUMBER_OPERATION_OPTION.NOT_LESS_THAN: {
      newOperation = {
        type: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.LESS_THAN,
        value: newNumberValue,
        negate: true,
      };
      break;
    }
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | operation of type (${type}) does not define its operation value when chosen`,
      );
  }

  return newOperation;
};

const operationOptions = Object.keys(
  FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION_LABELS,
).map(key => ({
  label: FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION_LABELS[key].label,
  payload: key,
  key,
}));

const convertPrimitiveNumberOperationToOption = (
  operation: NumberOperation | null,
): NUMBER_OPERATION_OPTION => {
  if (operation == null) {
    return NUMBER_OPERATION_OPTION.EQUALS;
  }

  switch (operation.type) {
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.EQUALS:
      if (operation.negate) {
        return NUMBER_OPERATION_OPTION.NOT_EQUAL;
      }

      return NUMBER_OPERATION_OPTION.EQUALS;
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.GREATER_THAN:
      if (operation.negate) {
        return NUMBER_OPERATION_OPTION.NOT_GREATER_THAN;
      }

      return NUMBER_OPERATION_OPTION.GREATER_THAN;
    case FLOW_CONDITION_PRIMITIVE_NUMBER_OPERATION.LESS_THAN:
      if (operation.negate) {
        return NUMBER_OPERATION_OPTION.NOT_LESS_THAN;
      }

      return NUMBER_OPERATION_OPTION.LESS_THAN;
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | operation of unkwnown type (${JSON.stringify(
          operation,
          null,
          2,
        )}) cannot be converted to operation option`,
      );
  }
};

export default FlowConditionPrimitiveNumberFieldComponent;
