import { FlowCondition } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';
import { FlowConditionFieldsFragment } from '~/graphql/types';
import {
  asFlowConditionContactDetailsProp,
  emptyFlowConditionContactDetails,
} from './ContactDetails';
import { asFlowConditionValuationReportDetailsProp } from './AppValuationReportDetails';
import { asFlowConditionValuationRequestDetailsProp } from './AppValuationRequestDetails';
import { asFlowConditionAppFundaContactRequestDetailsProp } from './AppFundaContactRequestDetails';
import { asFlowConditionAppFundaViewingRequestDetailsProp } from './AppFundaViewingRequestDetails';
import { asFlowConditionAppFundaBrochureRequestDetailsProp } from './AppFundaBrochureRequestDetails';
import { asFlowConditionAppBBWaardecheckReportDetailsProp } from './AppBBWaardecheckReportDetails';
import { asFlowConditionAppBBWaardecheckAppraisalRequestDetailsProp } from './AppBBWaardecheckAppraisalRequestDetails';
import { asFlowConditionAppVBOWaardecheckReportDetailsProp } from './AppVBOWaardecheckReportDetails';
import { asFlowConditionAppVBOWaardecheckAppraisalRequestDetailsProp } from './AppVBOWaardecheckAppraisalRequestDetails';

import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';
import { extractHandledFlowConditions } from '~/graphql/types.client';
import { asFlowConditionAppZapierDetailsProp } from './AppZapierDetails';
import { asFlowConditionAppHypotheekbondDetailsProp } from './AppHypotheekbondDetails';

const asFlowConditionGroupProp = (
  conditions_: Array<FlowConditionFieldsFragment> | null | undefined,
  allShouldBeTrue: boolean,
): Omit<FlowCondition, 'id'> => {
  if (conditions_ == null || conditions_.length === 0) {
    return emptyFlowConditionContactDetails();
  }

  const conditions = extractHandledFlowConditions(conditions_);

  if (new Set(conditions.map(condition => condition.__typename)).size !== 1) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | Cannot have different types of conditions in 1 group (${JSON.stringify(
        conditions,
        null,
        2,
      )})`,
    );
  }

  const firstCondition = conditions[0];

  // At this point we can only have one type of condition in the group, so we just pick up the first condition to extract its type
  switch (firstCondition.__typename) {
    case 'Flow_Condition_Contact_Details':
      return asFlowConditionContactDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_ValuationReport_Details':
      return asFlowConditionValuationReportDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_Details':
      return asFlowConditionValuationRequestDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details':
      return asFlowConditionAppFundaViewingRequestDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details':
      return asFlowConditionAppFundaBrochureRequestDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details':
      return asFlowConditionAppFundaContactRequestDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details':
      return asFlowConditionAppBBWaardecheckReportDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details':
      return asFlowConditionAppBBWaardecheckAppraisalRequestDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_Zapier_Details':
      return asFlowConditionAppZapierDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details':
      return asFlowConditionAppVBOWaardecheckReportDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details':
      return asFlowConditionAppVBOWaardecheckAppraisalRequestDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );
    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details':
      return asFlowConditionAppHypotheekbondDetailsProp(
        conditions as Array<typeof firstCondition>,
        allShouldBeTrue,
      );

    case 'Flow_Condition_Contact_Tag':
    case 'Flow_Condition_Event_Email_LinkClicked':
    case 'Flow_Condition_Event_Email_Opened':
    case 'Flow_Condition_Time_Elapsed':
    case 'Flow_Condition_Event_Contact_App_ValuationReport_New':
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New':
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New':
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New':
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New':
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New':
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New':
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New':
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New':
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New':
    case 'Flow_Condition_Contact_New':
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_New':
    case 'Flow_Condition_Event_Contact_App_Zapier_New':
    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_New':
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | asFlowConditionGroupProp function for condition type: ${
          firstCondition.__typename
        } is not supported`,
      );

    default:
      return assertNever(firstCondition);
  }
};

export default asFlowConditionGroupProp;
