import React from 'react';

import { UserPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithAccountContext } from '~/contexts/AccountContext';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { Dropdown } from '~/components/Inputs';
import { withAccountContext } from '~/contexts/AccountContext';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import Catalog from '~/Catalog';

import TEST_ID from './UserPrimitiveParameterValueComponent.testid';

const text = {
  userLabel: Catalog.noUser,
  noUserLabel: 'Alle gebruikers',
  requiredField: Catalog.requiredField,
};
type MyProps = {
  value: UserPrimitiveParameterValue;
  onChange: (newValue: UserPrimitiveParameterValue) => void;

  /** Filter the primitive variable options */
  officeId?: string | null;
  isMandatory?: boolean;
};
type WithoutAccountContextProps = WithBaseActionContextProps & MyProps;
type Props = WithoutAccountContextProps & WithAccountContext;
type State = {
  key: string | null;
};
class UserPrimitiveParameterValueComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  validate = () => {
    const { value, isMandatory } = this.props;

    if (value.value === undefined) {
      return false;
    }

    if (isMandatory === true && value.value == null) {
      return false;
    }

    return true;
  };

  render() {
    const { value, onChange, officeId, accountContext } = this.props;
    const { value: inputValue } = value;
    const { getUserOptions } = accountContext;

    const options = getUserOptions(officeId);

    const selectedIdx = options.findIndex(option => {
      if (option.payload === null) {
        return inputValue === null;
      } else {
        return option.payload.id === inputValue;
      }
    });

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => (
          <Dropdown
            label={text.userLabel}
            dataTestid={TEST_ID.DROPDOWN}
            options={options}
            error={showValidation && !this.validate() ? text.requiredField : ''}
            selectedOptionIdx={selectedIdx}
            onChange={({ option }) => {
              if (value.value !== option.payload) {
                const newValue = {
                  ...value,
                  value: option.payload == null ? null : option.payload.id,
                };

                onChange(newValue);
              }
            }}
          />
        )}
      </FlowContext.Consumer>
    );
  }
}

export default withAccountContext<MyProps>(
  withBaseActionContext<WithoutAccountContextProps>(
    UserPrimitiveParameterValueComponent,
  ),
);
