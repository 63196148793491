import React from 'react';
import { Props } from '..';

const Undo: React.FC<Props> = ({ color = 'currentColor', ...props }: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.7 13.9L3 9.2L7.7 4.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9.2002H16.3C18.9 9.2002 21 11.3002 21 13.9002C21 16.5002 18.9 18.6002 16.3 18.6002H8.1"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Undo;
