import cleanedFilename from '~/util/cleanedFilename';

export default {
  CHANGE_BILLING_DETAILS_BUTTON: `${cleanedFilename(
    __filename,
  )}-change-billing-details-button`,
  GO_TO_INVOICES_BUTTON: `${cleanedFilename(__filename)}-go-to-invoices-button`,
  CHANGE_PAYMENT_BUTTON: `${cleanedFilename(__filename)}-change-payment-button`,
  BANK_ACCOUNT_NUMBER: `${cleanedFilename(__filename)}-bank-account-number`,
  PDF_LINK: `${cleanedFilename(__filename)}-pdf-link`,
  PDF_LINK_PROCESSING: `${cleanedFilename(__filename)}-pdf-processing`,
};
