import React from 'react';
import styled from 'styled-components';

import TopSectionContainer from '../components/TopSectionContainer';
import MidSectionContainer from '../components/MidSectionContainer';
import BottomSectionContainer from '../components/BottomSectionContainer';
import LinkButton from '~/components/Buttons/LinkButton';

import TEST_ID from './index.testid';

const text = {
  goBackButtonLabel: 'Ga terug',
};
type Props = {
  title: string;
  message: string;
  onGoBack: () => void;
};
const InfoStep: React.FC<Props> = ({ title, message, onGoBack }) => (
  <Container data-testid={TEST_ID.CONTAINER}>
    <TopSectionContainer headerText={title} withBorder />
    <MidSectionContainer>
      <MessageContainer>{message}</MessageContainer>
    </MidSectionContainer>
    <BottomSectionContainer>
      <StyledLinkButtion testId={TEST_ID.GO_BACK_BUTTON} onClick={onGoBack}>
        {text.goBackButtonLabel}
      </StyledLinkButtion>
    </BottomSectionContainer>
  </Container>
);

const MessageContainer = styled.p<{}>``;

const StyledLinkButtion = styled(LinkButton)<{}>`
  align-self: center;
  margin-left: auto;
  margin-right: auto;
`;

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export default InfoStep;
