import styled from 'styled-components';

const InfoContainer = styled.div<{}>`
  text-align: left;
  width: 100%;
  ${({ theme }) => `
    padding: ${theme.space('xl')};
  `};
`;

export default InfoContainer;
