import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const HouseTinder: React.FC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.4 63.8999V58.2999C37.4 53.3999 27.8 49.3999 27.8 49.3999L23.2 53.1999C20.8 55.1999 18.7 55.2999 16 53.1999L11.4 49.3999C11.4 49.3999 2.90002 53.3999 2.90002 58.2999V63.8999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7001 33.1003C28.7001 39.3003 24.9001 45.6003 20.2001 45.6003C15.5001 45.6003 11.7001 39.3003 11.7001 33.1003C11.7001 26.9003 15.5001 23.3003 20.2001 23.3003C24.9001 23.3003 28.7001 26.9003 28.7001 33.1003Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5 21.2001C30 13.5001 37.7 8.1001 46.8 8.1001C59.1 8.1001 69.1 18.1001 69.1 30.4001C69.1 42.7001 59.1 52.7001 46.8 52.7001C39.3 52.7001 32.7 49.0001 28.7 43.4001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.8 40.7001C52.4885 40.7001 57.1 36.0886 57.1 30.4001C57.1 24.7116 52.4885 20.1001 46.8 20.1001C41.1115 20.1001 36.5 24.7116 36.5 30.4001C36.5 36.0886 41.1115 40.7001 46.8 40.7001Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.9 21.3L36.5 11"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.1 29.8002L69.1 29.2002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.1 39.2002L58.3 49.4002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.5 40.1001L39.7 51.5001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6 63.8999H56.3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default HouseTinder;
