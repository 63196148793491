import { StartEmailSyncMutationVariables } from '~/graphql/types';
import { reportErrorToTracker } from '~/util/assertion';

// Define the options for flow, so it tells us if we have typo
export enum SynchroniseEntityType {
  Account = 'Account',
  Office = 'Office',
  User = 'User',
}

const isSynchroniseEntityType = (
  value: string,
): value is SynchroniseEntityType =>
  value === SynchroniseEntityType.Account ||
  value === SynchroniseEntityType.Office ||
  value === SynchroniseEntityType.User;

export type NylasState = {
  entityType: SynchroniseEntityType;
  entityId: string;
  accountId: string;
};

export const buildStateString = (state: NylasState): string =>
  encodeURIComponent(
    `${state.entityType}|${state.entityId}|${state.accountId}`,
  );

export const parseStateString = (stateString: string): NylasState | null => {
  const tokens = decodeURIComponent(stateString).split('|');

  if (tokens.length !== 3) {
    reportErrorToTracker(`Invalid Nylas state string given: ${stateString}`);

    return null;
  }

  const entityType = tokens[0];
  if (!isSynchroniseEntityType(entityType)) {
    reportErrorToTracker(
      `Invalid synchronise entity type given: ${stateString}`,
    );

    return null;
  }

  return {
    entityType,
    entityId: tokens[1],
    accountId: tokens[2],
  };
};

export const mutationVariablesForState = (
  state: NylasState,
  code: string,
): StartEmailSyncMutationVariables | null => {
  switch (state.entityType) {
    case SynchroniseEntityType.Account:
      return {
        accountId: state.accountId,
        account: {
          accountId: state.accountId,
          code,
        },
      };
    case SynchroniseEntityType.Office:
      return {
        accountId: state.accountId,
        office: {
          accountId: state.accountId,
          code,
          officeId: state.entityId,
        },
      };

    case SynchroniseEntityType.User:
      return {
        accountId: state.accountId,
        user: {
          accountId: state.accountId,
          code,
        },
      };

    default:
      reportErrorToTracker(
        `Unknown entity type given to NylasAuthoriseEndpointV1: ${state.entityType}`,
      );
      return null;
  }
};
