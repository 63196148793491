import React from 'react';

type Props = {
  name?: string | null | undefined;
  email: string;
};
const NamedEmailLabel = ({ name, email }: Props): React.ReactElement => {
  if (name) {
    return <span>{`${name} (${email})`}</span>;
  } else {
    return <span>{email}</span>;
  }
};

export default NamedEmailLabel;
