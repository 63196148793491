import React from 'react';
import DHRouter from '~/components/DHRouter';

import { WithNavigationFrame as NotFound } from '~/components/404';

import { RouteComponentProps } from '@reach/router';
import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/scenes/ErrorScreen';
import Catalog from '~/Catalog';
import AccountsList from './AccountsList';
import AccountPage from './AccountDetails';

type Props = RouteComponentProps;
const Accounts: React.FC<Props> = withErrorBoundary<Props>(
  () => (
    <DHRouter>
      <AccountsList path={'/*'} />
      <AccountPage path="/:accountId" />
      <NotFound default />
    </DHRouter>
  ),
  <ErrorScreen explanation={Catalog.genericUnknownErrorMessage} />,
);

export default Accounts;
