import { StateStep } from '~/components/Wizard/context/WizardContext';
import { StepId } from '~/components/WizardSteps';

/**
 * Returns step and substep indexes
 * @param {string} x - Example argument
 * keywords:
 */
const getStepIndexes = (
  id: StepId,
  steps: Array<StateStep>,
): [number | null, number | null] => {
  const [stepIndex, subStepIndex] = steps.reduce(
    (acc, curr, index) => {
      if (id === curr.id) {
        return [index, null];
      }
      if (curr.subSteps) {
        const subIndex = curr.subSteps.findIndex(step => step.id === id);
        if (subIndex !== -1) {
          return [index, subIndex];
        }
      }

      return acc;
    },
    [null, null],
  );

  return [stepIndex, subStepIndex];
};
export default getStepIndexes;
