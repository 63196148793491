import { useState, useEffect, MutableRefObject, useCallback } from 'react';
import useDebounce from '../useDebounce';
import useCurrentBreakpoint from '../useCurrentBreakpoint';

export type ElementSize = { width: number; height: number };
type RecalculateFn = () => void;

export const useSize: (
  ref: MutableRefObject<HTMLDivElement | null>,
) => [ElementSize, RecalculateFn] = ref => {
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  });
  const debouncedSize = useDebounce(size, 200);
  const currentBp = useCurrentBreakpoint();

  const rect = ref.current?.getBoundingClientRect();
  const callback = useCallback(() => {
    setSize({ width: rect?.width || 0, height: rect?.height || 0 });
  }, [rect?.width, rect?.height]);

  useEffect(() => {
    callback();
    global.window.addEventListener('resize', callback);
    return () => {
      global.window.removeEventListener('resize', callback);
    };
  }, [ref, callback, currentBp]);

  return [debouncedSize, callback];
};
export default useSize;
