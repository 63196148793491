import styled, { css } from 'styled-components';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

const DetailsBlockSubTitle = styled.div<{}>`
  font-weight: bold;

  ${({ theme }) => css`
    font-size: ${FONT_SIZE_OF_18}px;
    border-bottom: 1px solid ${theme.color('grey')};
    margin-bottom: ${theme.space('m')};
  `}
`;

export default DetailsBlockSubTitle;
