import React from 'react';
import styled, { css } from 'styled-components';
import MetaTags from 'react-meta-tags';

import { DHSubscription, StripeSubscription } from './types.flow';
import { GetAccountForSubscriptionQuery as GetAccountForSubscriptionQueryResult } from '~/graphql/types';

import ContentContainerDefault from '~/components/ContentContainer/Default';
import query from '~/graphql/query/GetAccountForSubscription';

import TEST_ID from './index.testid';
import Catalog from '~/Catalog';
import { MarginlessH2 } from '~/components/Typography';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { GetAccountForSubscriptionQuery } from '~/graphql/Query';
import { isInitialLoadStatus, DATA_READY } from '~/graphql/ApolloConstants';
import { Loading, AppErrorScreen } from '~/components';
import { SUBSCRIPTION_TYPE } from './constants';
import { convertServerDateStringToDate } from '~/util/date';
import SubscriptionContext from './context/SubscriptionContext';
import SubscriptionDetailsPage from './components/SubscriptionDetailsPage';

const text = {
  comingSoonTitle: 'Nog in ontwikkeling',
  explanation: 'Binnenkort kun je hier je abonnement aanpassen.',
  pageTitle: Catalog.subscriptionLabel,
  title: Catalog.subscriptionLabel,
};

const Subscription: React.FC = () => {
  const account = useCurrentAccount();

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <Header>{text.title}</Header>
      <GetAccountForSubscriptionQuery
        name={__filename}
        query={query}
        variables={{ accountId: account.id }}
      >
        {({ data, networkStatus, error, refetch }) => {
          if (isInitialLoadStatus(networkStatus)) {
            return <Loading />;
          }

          if (error || !data || !data.getAccount) {
            return <AppErrorScreen />;
          }

          const subscriptions = convertToSubscriptions(data);

          return (
            <SubscriptionContext.Provider
              value={{ refetchSubscriptionData: refetch }}
            >
              <Container
                data-testid={TEST_ID.CONTAINER}
                data-queryinflight={networkStatus !== DATA_READY}
              >
                <SubscriptionDetailsPage subscriptions={subscriptions} />
              </Container>
            </SubscriptionContext.Provider>
          );
        }}
      </GetAccountForSubscriptionQuery>
    </ContentContainerDefault>
  );
};

const convertToSubscriptions = (
  data: GetAccountForSubscriptionQueryResult,
): Array<DHSubscription> => {
  if (!data || !data.getAccount || !data.getAccount.subscription) {
    return [
      {
        type: SUBSCRIPTION_TYPE.EXTERNAL,
      },
    ];
  }

  const { subscription } = data.getAccount;

  if (subscription.__typename !== 'Subscription_Stripe') {
    return [
      {
        type: SUBSCRIPTION_TYPE.EXTERNAL,
      },
    ];
  }

  const {
    runningPlan,
    cancellationDate,
    nextPlan,
    nextPlanStart,
    nextBillingCycle,
  } = subscription;

  let currentPlanEndDate: Date | null = null;

  if (runningPlan == null) {
    return [
      {
        type: SUBSCRIPTION_TYPE.EXTERNAL,
      },
    ];
  }

  const { plan, billingCycle } = runningPlan;

  const subscriptions: Array<StripeSubscription> = [];
  if (nextPlan != null && nextBillingCycle != null) {
    currentPlanEndDate = convertServerDateStringToDate(nextPlanStart);

    subscriptions.push({
      type: SUBSCRIPTION_TYPE.STRIPE,
      plan: nextPlan,
      billingCycle: nextBillingCycle,
      startDate: currentPlanEndDate,
      endDate: null,
    });
  }

  if (cancellationDate != null) {
    currentPlanEndDate = convertServerDateStringToDate(cancellationDate);
  }

  subscriptions.unshift({
    type: SUBSCRIPTION_TYPE.STRIPE,
    plan,
    billingCycle,
    startDate: null,
    endDate: currentPlanEndDate,
  });

  return subscriptions;
};

const Header = styled(MarginlessH2)<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('xxl')};
  `,
);

const Container = styled.div<{}>``;

export default Subscription;
