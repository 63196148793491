import { FlowParameterStringFieldsFragment } from '~/graphql/types';
import { StringPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyStringPrimitiveParameterValue from './emptyStringPrimitiveParameterValue';

export default (
  stringParameter:
    | Extract<
        FlowParameterStringFieldsFragment,
        { __typename: 'Flow_Parameter_String_Primitive' }
      >
    | null
    | undefined,
): StringPrimitiveParameterValue => {
  if (stringParameter == null) {
    return emptyStringPrimitiveParameterValue();
  }

  return asStringPrimitiveParameterValueForValue(stringParameter.stringValue);
};

export const asStringPrimitiveParameterValueForValue = (
  value?: string | null | undefined,
): StringPrimitiveParameterValue => ({
  type: PARAMETER_VALUE_TYPE.STRING_PRIMITIVE,
  value: value || null,
});
