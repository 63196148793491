import { isNil, groupBy, uniq, findIndex, propEq, update, equals } from 'ramda';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { InputGroup, SelectGroup } from '~/components/Inputs';
import Dropdown from '~/components/Dropdown';
import { SelectedOptionOf } from '~/components/Inputs/Dropdown';
import JustificationContainer from '~/components/JustificationContainer';
import AccountContext from '~/contexts/AccountContext';
import {
  EmailSignature,
  FlowV2_Action_SendEmail_PlainFragment,
  FromAddress,
} from '~/graphql/types';
import { Props as FormProps } from '../ActionForm';
import InputLabel from '~/scenes/Apps/components/InputLabel';
import ErrorLabel from '~/scenes/Automation/Flows/Actions/ErrorLabel';
import { AUTHORISE_LEVEL } from '~/util/constants';
import EmailAttachments, {
  AttachmentWithKey,
} from './components/EmailAttachments';
import TEST_ID from './index.testid';
import {
  fromAddressOptions,
  signatureOptions,
  transactionalOptions,
  TransactionalOptions,
  AddressOptions,
  SignatureOptions,
} from './options';
import DefaultTextEditor from '~/components/DefaultTextEditor';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import useEditorStates from '~/components/PluginsEditor/hooks/useEditorStates';
import useRelativeMaps from '~/scenes/Automation/v2/components/Builder/hooks/useRelativeMaps';
import convertTemplateStringToSlateFragment from '~/components/PluginsEditor/utils/flows/convertTemplateStringToSlate';
import TemplateStringInput from '~/components/TemplateStringInput';
import getImagesAsFlowAttachments from '~/components/PluginsEditor/utils/flows/getImagesAsFlowAttachments';
import useDebounce from '~/hooks/useDebounce';
import passKeysToAttachments from './utils/passKeysToAttachments';
import TextEditorContainer from '../TextEditorContainer';

export type Props = FormProps & {
  action: FlowV2_Action_SendEmail_PlainFragment;
};

export type Attachment = $GetElementType<
  FlowV2_Action_SendEmail_PlainFragment['attachments']
>;

type State = Omit<
  FlowV2_Action_SendEmail_PlainFragment,
  | '__typename'
  | 'id'
  | '_v'
  | 'accountId'
  | 'parentIds'
  | 'flowBlueprintId'
  | 'Statistics'
  | 'actionType'
>;

const text = {
  fromAddressLabel: 'Van',
  addressHelpLink:
    'https://help.dathuis.nl/nl/articles/4247794-automatisch-e-mail-sturen-vanuit-de-eigenaar-van-het-contact',
  emailTypeLabel: 'Type',
  typeHelpLink:
    'https://help.dathuis.nl/nl/articles/3959108-wat-is-het-verschil-tussen-een-marketing-en-transactionele-e-mail',
  subjectLabel: 'Onderwerp',
  signatureLabel: 'Handtekening',
  signatureWarningMessage:
    'Zorg ervoor dat alle gebruikers een handtekening hebben ingesteld als je deze optie selecteert',
  addAttachmentButtonLabel: 'uit een stap',
  duplicatedError: 'Bijlagen al toegevoegd',
  or: 'of',
};

const SendEmailPlain: React.FC<Props> = ({ action, onChange }) => {
  const initials: State = {
    from:
      action.from === undefined ? FromAddress.ClosestToContact : action.from,
    isTransactional: action.isTransactional,
    subject: action.subject,
    body: action.body,
    attachments: action.attachments,
    signature:
      action.signature === undefined
        ? EmailSignature.FromAddressEntity
        : action.signature,
  };

  const { availableEmailsToSendFrom } = useContext(AccountContext);
  const [actionDetails, setActionDetails] = useState<State>(initials);

  const [error, setError] = useState<string | null>(null);

  const {
    from: fromAddress,
    isTransactional,
    attachments,
    signature,
  } = actionDetails;

  const groupedAttachments =
    attachments.length > 0
      ? groupBy(attachment => {
          if (attachment?.inlineId) return 'image';
          return 'file';
        }, actionDetails.attachments)
      : { file: [], image: [] };

  /** File or Pointer attachments that are not inline */
  const [fileAttachments, setFileAttachments] = useState<Array<Attachment>>(
    groupedAttachments.file || [],
  );
  const [imageAttachments, setImageAttachments] = useState<Array<Attachment>>(
    groupedAttachments.image || [],
  );
  const attachmentsWithKey = useMemo(
    () => passKeysToAttachments(fileAttachments),
    [fileAttachments],
  );

  const accountEmail = availableEmailsToSendFrom.find(
    email => email.authoriseLevel === AUTHORISE_LEVEL.ACCOUNT,
  );

  let emailText: string = '';
  if (accountEmail != null) {
    const { emailToShow } = accountEmail.namedEmail;

    emailText = `- ${emailToShow ? emailToShow : ''}`;
  }

  const fromOptions = fromAddressOptions(emailText);

  const onHandleChange = useCallback(
    (
      key: string,
      value: SelectedOptionOf<
        TransactionalOptions | AddressOptions | SignatureOptions
      >,
    ) => {
      setActionDetails(prev => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  useEffect(() => {
    onChange({ ...action, ...actionDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  const maps = useRelativeMaps({ actionId: action.id });

  const initialSubjectValue = convertTemplateStringToSlateFragment({
    html: initials.subject.template || '',
    mappings: initials.subject.mappings,
    ...maps,
  });
  const {
    hasChanges: hasSubjectChanges,
    value: subjectValue,
    onChange: onSubjectChange,
  } = useEditorStates({
    initialValue: initialSubjectValue,
  });

  const initialBodyValue = convertTemplateStringToSlateFragment({
    html: initials.body.template || '',
    mappings: initials.body.mappings,
    attachments: groupedAttachments.image,
    ...maps,
    customElements: [ELEMENTS.IMAGE, ELEMENTS.DH_IMAGE],
  });
  const {
    hasChanges: hasBodyChanges,
    value: bodyValue,
    onChange: onBodyChange,
  } = useEditorStates({
    initialValue: initialBodyValue,
  });

  const debouncedBody = useDebounce(bodyValue, 200);

  useEffect(() => {
    if (hasBodyChanges) {
      const updatedImageAttachments = getImagesAsFlowAttachments(debouncedBody);

      setImageAttachments(updatedImageAttachments);
      setActionDetails(prev => ({
        ...prev,
        bodyValue: debouncedBody,
      }));
    }
  }, [debouncedBody, hasBodyChanges]);

  useEffect(() => {
    if (hasSubjectChanges) {
      setActionDetails(prev => ({
        ...prev,
        subjectValue,
      }));
    }
  }, [subjectValue, hasSubjectChanges]);

  useEffect(() => {
    setError(null);
    setActionDetails(prev => ({
      ...prev,
      attachments: uniq([
        ...(imageAttachments || []),
        ...(fileAttachments || []),
      ]),
    }));
  }, [fileAttachments, imageAttachments]);

  const insertPointerAttachment = useCallback(
    (newAttachment: Attachment) => {
      if (newAttachment.file.__typename !== 'Flow___Argument_Pointer') return;

      const isDuplicatePointerAttachment = checkIsPointerDuplicate(
        fileAttachments,
        newAttachment,
      );

      if (isDuplicatePointerAttachment) {
        setError(text.duplicatedError);
        return;
      } else {
        setFileAttachments(prev => [...prev, newAttachment]);
        setError(null);
        return;
      }
    },
    [fileAttachments],
  );

  const updatePointerAttachment = useCallback(
    ({ key, value }: { key: string; value: AttachmentWithKey }) => {
      const isDuplicatePointerAttachment = checkIsPointerDuplicate(
        attachmentsWithKey,
        value,
      );

      if (isDuplicatePointerAttachment) {
        return setError(text.duplicatedError);
      }

      const index = findIndex(propEq('key', key))(attachmentsWithKey);

      setFileAttachments(prev => update(index, value, prev));
    },
    [attachmentsWithKey],
  );

  const insertFileAttachment = useCallback((newAttachment: Attachment) => {
    if (!newAttachment) return;
    setFileAttachments(prev => [...prev, newAttachment]);
  }, []);

  const onDelete = useCallback(attachmentFile => {
    setFileAttachments(prev =>
      prev.filter(attachment => !equals(attachment.file, attachmentFile)),
    );
  }, []);

  return (
    <>
      <InputGroup>
        <StyledJustificationContainer justification="start">
          <Container>
            <InputLabel
              label={text.fromAddressLabel}
              help={text.addressHelpLink}
              fontWeight="regular"
            />
            <Dropdown
              dataTestId={TEST_ID.FROM_ADDRESS_DROPDOWN}
              options={fromOptions}
              onChange={e => onHandleChange('from', e.option.payload)}
              selectedOptionIdx={fromOptions.findIndex(
                selectedOption =>
                  selectedOption.payload ===
                  (fromAddress === undefined
                    ? FromAddress.ClosestToContact
                    : fromAddress),
              )}
            />
          </Container>
          <div>
            <InputLabel
              label={text.emailTypeLabel}
              help={text.typeHelpLink}
              fontWeight="regular"
            />
            <SelectGroup
              dataTestid={TEST_ID.IS_TRANSACTIONAL_SWITCHER}
              options={transactionalOptions}
              onChange={e => onHandleChange('isTransactional', e.option?.value)}
              selectedIndex={transactionalOptions.findIndex(
                selectedOption => selectedOption.value === isTransactional,
              )}
            />
          </div>
        </StyledJustificationContainer>
      </InputGroup>

      <InputGroup>
        <StyledJustificationContainer direction="column">
          <InputLabel label={text.subjectLabel} fontWeight="regular" />
          <TemplateStringInput
            $key={0}
            value={subjectValue}
            onChange={onSubjectChange}
            dataTestId={TEST_ID.SUBJECT}
          />
        </StyledJustificationContainer>
      </InputGroup>

      <TextEditorContainer>
        <DefaultTextEditor
          key={10000}
          value={bodyValue}
          onChange={onBodyChange}
          customElements={[ELEMENTS.VARIABLE]}
          dataTestId={TEST_ID.BODY}
        />
      </TextEditorContainer>

      <SignatureContainer>
        <InputLabel label={text.signatureLabel} fontWeight="regular" />
        <Dropdown
          dataTestId={TEST_ID.SIGNATURE_DROPDOWN}
          options={signatureOptions}
          onChange={e => onHandleChange('signature', e.option.payload)}
          selectedOptionIdx={signatureOptions.findIndex(
            selectedOption =>
              selectedOption.payload ===
              (signature === undefined
                ? EmailSignature.FromAddressEntity
                : signature),
          )}
        />
        {actionDetails.signature === EmailSignature.ClosestEntity && (
          <WarningContainer>{text.signatureWarningMessage}</WarningContainer>
        )}
      </SignatureContainer>

      <EmailAttachments
        action={action}
        attachments={attachmentsWithKey}
        onPointerInsert={insertPointerAttachment}
        onPointerUpdate={updatePointerAttachment}
        onFileUpload={insertFileAttachment}
        onError={error => setError(error)}
        onDelete={onDelete}
      />

      {!isNil(error) && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
};

const checkIsPointerDuplicate = (
  attachments: Array<Attachment>,
  newAttachment: Attachment,
): boolean =>
  attachments.some(attachment => {
    if (
      attachment.file.__typename === 'Flow___Argument_Pointer' &&
      newAttachment.file.__typename === 'Flow___Argument_Pointer'
    ) {
      return (
        attachment.file?.pointer?.path.toString() ===
        newAttachment.file?.pointer?.path.toString()
      );
    }
    return false;
  });

const Container = styled.div<{}>(
  ({ theme }) => css`
    width: 100%;
    margin-right: ${theme.space('l')};
  `,
);

const SignatureContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

const StyledJustificationContainer = styled(JustificationContainer)<{}>`
  width: 100%;
`;

const WarningContainer = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('warning')};
    margin-top: ${theme.space('xxs')};
    font-size: ${theme.fs('s')};
  `,
);

export default SendEmailPlain;
