import { useContext } from 'react';
import useErrorReporter from '~/hooks/useErrorReporter';
import BuilderContext, { IBuilderContext } from '../../context';

const useBuilderContext = (): IBuilderContext => {
  const reporter = useErrorReporter();
  const context = useContext(BuilderContext);

  if (context === null) {
    const err = new Error(
      `Builder context should be set before using useBuilderContext but it wasn't`,
    );
    reporter.captureException(err, 'critical');

    throw err;
  }

  return context;
};

export default useBuilderContext;
