import React from 'react';
import styled, { css } from 'styled-components';
import { IconType } from '~/components/Icon';
import StatisticsItem from './components/StatisticsItem';

export enum Units {
  Times = 'Times',
  Percent = 'Percent',
  Leads = 'Leads',
}

export type StatisticsProps = {
  heading: string;
  value: number;
  total: number;
  unit: Units;
  color: string;
  icon?: IconType;
};

export type Props = {
  dataTestId?: string;
  statistics: Array<StatisticsProps>;
};

const CardStatistics: React.FC<Props> = ({
  dataTestId,
  statistics = [],
  ...rest
}) => (
  <Container
    data-testid={dataTestId}
    $amountOfStats={statistics.length}
    {...rest}
  >
    {statistics.map(stat => (
      <StatisticsItem key={stat.heading} {...stat} />
    ))}
  </Container>
);

const Container = styled.div<{ $amountOfStats: number }>(
  ({ theme, $amountOfStats }) => css`
    display: flex;

    & > div {
      width: calc(100% / ${$amountOfStats});

      &:not(:last-child) {
        padding-right: ${theme.spacing('m')};
      }
    }
  `,
);

export default CardStatistics;
