import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FlowV2_Action_Task_CreateFragment } from '~/graphql/types';
import { Checkbox, MinimalDropdown } from '~/components/Inputs';
import Dropdown from '~/components/Dropdown';
import JustificationContainer from '~/components/JustificationContainer';
import { Heading4, Variant } from '~/components/Typography/index';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useUserOptions from '~/hooks/useUserOptions';
import InputGroup from '~/scenes/Apps/components/InputGroup';
import FieldLabel from '~/scenes/Automation/Flows/Actions/FieldLabel';
import { taskTypeOptions } from '~/util/taskTypeOptions';
import { Props as FormProps } from '../ActionForm';
import useRelativeMaps from '~/scenes/Automation/v2/components/Builder/hooks/useRelativeMaps';
import convertTemplateStringToSlateFragment from '~/components/PluginsEditor/utils/flows/convertTemplateStringToSlate';
import useEditorStates from '~/components/PluginsEditor/hooks/useEditorStates';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import PluginsEditor from '~/components/PluginsEditor';
import TemplateStringInput from '~/components/TemplateStringInput';
import withHtml from '~/components/PluginsEditor/plugins/withHtml';
import TEST_ID from './index.testid';
import TextEditorContainer from '../TextEditorContainer';
import useEntityValidationForInputs from '../../../Builder/hooks/useEntityValidationForInputs';

export type Props = FormProps & {
  action: FlowV2_Action_Task_CreateFragment;
};

type State = Omit<
  FlowV2_Action_Task_CreateFragment,
  | 'id'
  | 'accountId'
  | 'flowBlueprintId'
  | '__typename'
  | '_v'
  | 'actionType'
  | 'parentIds'
>;

const text = {
  taskTypeLabel: 'Type taak',
  descriptionLabel: 'Omschrijving',
  assignTaskLabel: 'Taak toewijzen',
  checkboxLabel: 'Aan de eigenaar van het contact toewijzen',
  checkboxName: 'overwriteAssignee',
  officeWithNoAccountLabel: 'Vestiging (indien geen eigenaar)',
  userWithNoAccountLabel: 'Gebruiker (indien geen eigenaar)',
  userLabel: 'Gebruiker',
  officeLabel: 'Vestiging',
  officeDropdownLabel: 'Selecteer vestiging',
  userDropdownLabel: 'Selecteer gebruiker',
  title: 'Titel',
};

const FlowActionCreateTask: React.FC<Props> = ({ action, onChange }) => {
  const initials = {
    type: action.type,
    user: action.user,
    assignToContactOwner: action.assignToContactOwner,
    title: action.title,
    description: action.description,
    assignedToOffice: action.assignedToOffice,
  };
  const [actionDetails, setActionDetails] = useState<State>(initials);

  const {
    type,
    user,
    title,
    description,
    assignToContactOwner,
    assignedToOffice,
  } = actionDetails;

  const officeOptions = useOfficeOptions();
  const userOptions = useUserOptions({
    withAllUsersOption: true,
    officeId: assignedToOffice,
  });

  const { userError, officeError } = useEntityValidationForInputs({
    userOptions,
    userId: actionDetails.user,
    officeId: actionDetails.assignedToOffice,
  });

  const handleChange = useCallback((key, value) => {
    setActionDetails(prev => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    onChange({ ...action, ...actionDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  const maps = useRelativeMaps({ actionId: action.id });
  const initialTitleValue = convertTemplateStringToSlateFragment({
    html: title.template || '',
    mappings: title.mappings,
    ...maps,
  });
  const {
    key: titleKey,
    value: titleValue,
    onChange: onTitleChange,
    hasChanges: hasTitleChanges,
  } = useEditorStates({
    initialValue: initialTitleValue,
  });

  const initialDescriptionValue = convertTemplateStringToSlateFragment({
    html: description.template || '',
    mappings: description.mappings,
    ...maps,
  });
  const {
    key: descriptionKey,
    value: descriptionValue,
    onChange: onDescriptionChange,
    hasChanges: hasDescriptionChanges,
  } = useEditorStates({
    initialValue: initialDescriptionValue,
  });

  useEffect(() => {
    if (hasTitleChanges) {
      handleChange('titleValue', titleValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleValue, hasTitleChanges]);

  useEffect(() => {
    if (hasDescriptionChanges) {
      handleChange('descriptionValue', descriptionValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionValue, hasDescriptionChanges]);

  return (
    <Container>
      <JustificationContainer align="end">
        <MatchingDropdown
          label={text.taskTypeLabel}
          options={taskTypeOptions}
          onChange={e => handleChange('type', e.option.payload)}
          selectedOptionIdx={taskTypeOptions.findIndex(
            option => option.payload === type,
          )}
          dataTestid={TEST_ID.TASK_TYPE_DROPDOWN}
        />

        <StyledContainer direction="column" margin={[null, null, null, 'l']}>
          <span>{text.title}</span>
          <TemplateStringInput
            $key={titleKey}
            value={titleValue}
            onChange={onTitleChange}
            dataTestId={TEST_ID.TITLE}
          />
        </StyledContainer>
      </JustificationContainer>
      <InputGroup>
        <StyledContainer direction="column" margin={['l', null, null, null]}>
          <TextEditorContainer>
            <PluginsEditor
              key={descriptionKey}
              value={descriptionValue}
              onChange={onDescriptionChange}
              customElements={[ELEMENTS.VARIABLE]}
              plugins={[
                e =>
                  withHtml({ editor: e, customElements: [ELEMENTS.VARIABLE] }),
              ]}
              dataTestId={TEST_ID.DESCRIPTION}
            />
          </TextEditorContainer>
        </StyledContainer>
      </InputGroup>
      <Heading4 variant={Variant.primary}>{text.assignTaskLabel}</Heading4>
      <InputGroup>
        <Checkbox
          value={assignToContactOwner ?? true}
          onChange={() =>
            setActionDetails(prev => ({
              ...prev,
              assignToContactOwner: !prev.assignToContactOwner,
            }))
          }
          label={text.checkboxLabel}
          name={text.checkboxName}
        />
      </InputGroup>
      <InputGroup>
        <FieldLabel>
          {assignToContactOwner
            ? text.officeWithNoAccountLabel
            : text.officeLabel}
        </FieldLabel>
        <Dropdown
          label={text.officeDropdownLabel}
          options={officeOptions}
          onChange={e => handleChange('assignedToOffice', e.option.payload?.id)}
          selectedOptionIdx={officeOptions.findIndex(
            selectedOffice => selectedOffice.payload?.id === assignedToOffice,
          )}
          dataTestId={TEST_ID.ASSIGNED_OFFICE}
          error={officeError}
        />
      </InputGroup>
      <InputGroup>
        <FieldLabel>
          {assignToContactOwner ? text.userWithNoAccountLabel : text.userLabel}
        </FieldLabel>
        <Dropdown
          label={text.userDropdownLabel}
          options={userOptions}
          onChange={e => handleChange('user', e.option.payload?.id)}
          selectedOptionIdx={userOptions.findIndex(
            selectedUser => selectedUser.payload?.id == user,
          )}
          dataTestId={TEST_ID.ASSIGNED_USER}
          error={userError}
        />
      </InputGroup>
    </Container>
  );
};

const StyledContainer = styled(JustificationContainer)`
  width: 100%;
`;

const MatchingDropdown = styled(MinimalDropdown)(
  ({ theme }) => css`
    /* Custom fixed unit because component is not inline with the theme.space system
    This makes it appear like it's inline
   */
    padding: 1.025rem;
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

const Container = styled.div<{}>``;

export default FlowActionCreateTask;
