import React from 'react';
import { useRecoilValue } from 'recoil';
import { Handle, Position } from 'reactflow';

import { Props as HidableListProps } from '~/components/Inputs/Dropdown/components/DropdownHideableList';
import { Heading5, Variant } from '~/components/Typography/index';
import { issuesByActionId } from '~/scenes/Automation/v2/state/flowIssues';

import CardTemplate, {
  handleStyle,
  Props as CardProps,
} from '../../../CardTemplate';
import Issue from '../../../../../Issues/InCard';
import {
  ClientFlowActionContactAddTag,
  ClientFlowActionContactDeleteTag,
} from '~/graphql/types.client';

export type Props = CardProps<
  ClientFlowActionContactAddTag | ClientFlowActionContactDeleteTag
> & {
  dataTestId?: string;
} & Omit<HidableListProps, 'dropdownListOpen' | 'onClickOutside'>;

const TagActionCard: React.FC<Props> = ({
  selected,
  disabled,
  data,
  ...props
}) => {
  const issues = useRecoilValue(issuesByActionId(data.id));

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={handleStyle}
        isConnectable={false}
      />
      <CardTemplate
        {...props}
        selected={selected}
        disabled={disabled}
        data={data}
      >
        {
          <>
            <Heading5 inline variant={Variant.primary}>
              Tag:&nbsp;
            </Heading5>
            {data.action.name}
            {issues &&
              issues.map(issue => <Issue key={issue.message} {...issue} />)}
          </>
        }
      </CardTemplate>
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        isConnectable={false}
      />
    </>
  );
};

export default TagActionCard;
