import type {
  Node_NodeContainer_Leaf_Activity__Input,
  Node_NodeContainer_Leaf_Activity_Event_Contact__Input,
} from '~/graphql/types';
import type { ActivitySubFilter } from './types.flow';

import { FILTER_SUB_TYPE } from './constants';
import cleanedFilename from '~/util/cleanedFilename';
import { reportErrorToTracker } from '~/util/assertion';
import { asValuationReportFilterInput } from './ValuationReport/asValuationReportFilterInput';
import { asValuationRequestFilterInput } from './ValuationRequest/asValuationRequestFilterInput';
import asHasCallEventFilterInput from './HasCallEvent/asHasCallEventFilterInput';
import asLoggedDateCallEventFilterInput from './LoggedDateCallEvent/asLoggedDateCallEventFilterInput';
import asHasNoteEventFilterInput from './HasNoteEvent/asHasNoteEventFilterInput';
import asLoggedDateNoteEventFilterInput from './LoggedDateNoteEvent/asLoggedDateNoteEventFilterInput';
import asHasFundaBrochureRequestEventFilterInput from './HasFundaBrochureRequestEvent/asHasFundaBrochureRequestEventFilterInput';
import asHasFundaViewingRequestEventFilterInput from './HasFundaViewingRequestEvent/asHasFundaViewingRequestEventFilterInput';
import asHasFundaContactRequestEventFilterInput from './HasFundaContactRequestEvent/asHasFundaContactRequestEventFilterInput';
import asCreatedFundaContactRequestEventFilterInput from './CreatedFundaContactRequestEvent/asCreatedFundaContactRequestEventFilterInput';
import asCreatedFundaViewingRequestEventFilterInput from './CreatedFundaViewingRequestEvent/asCreatedFundaViewingRequestEventFilterInput';
import asCreatedFundaBrochureRequestEventFilterInput from './CreatedFundaBrochureRequestEvent/asCreatedFundaBrochureRequestEventFilterInput';
import asHasBBWaardecheckReportEventFilterInput from './HasBBWaardecheckReportEvent/asHasBBWaardecheckReportEventFilterInput';
import asHasBBWaardecheckContactRequestEventFilterInput from './HasBBWaardecheckContactRequestEvent/asHasBBWaardecheckContactRequestEventFilterInput';
import asHasBBWaardecheckAppraisalRequestEventFilterInput from './HasBBWaardecheckAppraisalRequestEvent/asHasBBWaardecheckAppraisalRequestEventFilterInput';
import asCreatedBBWaardecheckReportEventFilterInput from './CreatedBBWaardecheckReportEvent/asCreatedBBWaardecheckReportEventFilterInput';
import asCreatedBBWaardecheckAppraisalRequestEventFilterInput from './CreatedBBWaardecheckAppraisalRequestEvent/asCreatedBBWaardecheckAppraisalRequestEventFilterInput';
import asCreatedBBWaardecheckContactRequestEventFilterInput from './CreatedBBWaardecheckContactRequestEvent/asCreatedBBWaardecheckContactRequestEventFilterInput';
import asCreatedValuationReportEventFilterInput from './CreatedValuationReportEvent/asCreatedValuationReportEventFilterInput';
import asCreatedValuationRequestEventFilterInput from './CreatedValuationRequestEvent/asCreatedValuationRequestEventFilterInput';
import asCreatedVBOWaardecheckAppraisalRequestEventFilterInput from './CreatedVBOWaardecheckAppraisalRequestEvent/asCreatedVBOWaardecheckAppraisalRequestEventFilterInput';
import asCreatedVBOWaardecheckContactRequestEventFilterInput from './CreatedVBOWaardecheckContactRequestEvent/asCreatedVBOWaardecheckContactRequestEventFilterInput';
import asCreatedVBOWaardecheckReportEventFilterInput from './CreatedVBOWaardecheckReportEvent/asCreatedVBOWaardecheckReportEventFilterInput';
import asHasVBOWaardecheckAppraisalRequestEventFilterInput from './HasVBOWaardecheckAppraisalRequestEvent/asHasVBOWaardecheckAppraisalRequestEventFilterInput';
import asHasVBOWaardecheckContactRequestEventFilterInput from './HasVBOWaardecheckContactRequestEvent/asHasVBOWaardecheckContactRequestEventFilterInput';
import asHasVBOWaardecheckReportEventFilterInput from './HasVBOWaardecheckReportEvent/asHasVBOWaardecheckReportEventFilterInput';

const asActivityLeafInput = (
  filter: ActivitySubFilter,
): Node_NodeContainer_Leaf_Activity__Input | null => {
  let subFilter;

  switch (filter.type) {
    case FILTER_SUB_TYPE.EMPTY: {
      subFilter = null;

      break;
    }
    case FILTER_SUB_TYPE.VALUATION_REPORT: {
      subFilter = asValuationReportFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_VALUATION_REPORT_EVENT: {
      subFilter = asCreatedValuationReportEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.VALUATION_REQUEST: {
      subFilter = asValuationRequestFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_VALUATION_REQUEST_EVENT: {
      subFilter = asCreatedValuationRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_CALL_EVENT: {
      subFilter = asHasCallEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.LOGGED_DATE_CALL_EVENT: {
      subFilter = asLoggedDateCallEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_NOTE_EVENT: {
      subFilter = asHasNoteEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.LOGGED_DATE_NOTE_EVENT: {
      subFilter = asLoggedDateNoteEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_FUNDA_BROCHURE_REQUEST_EVENT: {
      subFilter = asHasFundaBrochureRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_FUNDA_VIEWING_REQUEST_EVENT: {
      subFilter = asHasFundaViewingRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_FUNDA_CONTACT_REQUEST_EVENT: {
      subFilter = asHasFundaContactRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_FUNDA_CONTACT_REQUEST_EVENT: {
      subFilter = asCreatedFundaContactRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_FUNDA_VIEWING_REQUEST_EVENT: {
      subFilter = asCreatedFundaViewingRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_FUNDA_BROCHURE_REQUEST_EVENT: {
      subFilter = asCreatedFundaBrochureRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_BBWAARDECHECK_REPORT_EVENT: {
      subFilter = asHasBBWaardecheckReportEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_BBWAARDECHECK_CONTACT_REQUEST_EVENT: {
      subFilter = asHasBBWaardecheckContactRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT: {
      subFilter = asHasBBWaardecheckAppraisalRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_BBWAARDECHECK_REPORT_EVENT: {
      subFilter = asCreatedBBWaardecheckReportEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT: {
      subFilter =
        asCreatedBBWaardecheckAppraisalRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_BBWAARDECHECK_CONTACT_REQUEST_EVENT: {
      subFilter = asCreatedBBWaardecheckContactRequestEventFilterInput(filter);
      break;
    }

    case FILTER_SUB_TYPE.HAS_VBOWAARDECHECK_REPORT_EVENT: {
      subFilter = asHasVBOWaardecheckReportEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_VBOWAARDECHECK_CONTACT_REQUEST_EVENT: {
      subFilter = asHasVBOWaardecheckContactRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.HAS_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT: {
      subFilter = asHasVBOWaardecheckAppraisalRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_VBOWAARDECHECK_REPORT_EVENT: {
      subFilter = asCreatedVBOWaardecheckReportEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT: {
      subFilter =
        asCreatedVBOWaardecheckAppraisalRequestEventFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.CREATED_VBOWAARDECHECK_CONTACT_REQUEST_EVENT: {
      subFilter = asCreatedVBOWaardecheckContactRequestEventFilterInput(filter);
      break;
    }

    default: {
      reportErrorToTracker(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | filter (${filter}) not implemented`,
      );

      // do not break on a wrong input, just ignore it
      return null;
    }
  }

  if (subFilter == null) {
    return null;
  }

  return asEventContactActivityLeafInput(subFilter);
};

const asEventContactActivityLeafInput = (
  subFilter: Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null,
): Node_NodeContainer_Leaf_Activity__Input => ({
  Event: {
    Contact: {
      ...subFilter,
    },
  },
});

export default asActivityLeafInput;
