import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export const INPUT_GROUP_TOP_BOTTOM_MARGIN = '16px';
const InputGroup = styled.div<Props>`
  display: flex;
  align-items: flex-end;
  margin: ${INPUT_GROUP_TOP_BOTTOM_MARGIN} 0;
  width: 100%;

  > :nth-child(n + 2) {
    ${({ theme }) => `
      margin-left: ${theme.space('l')};
    `};
  }

  /* Our buttons have a min-width but we do not want that in the InputGroup */
  > button {
    min-width: auto;
  }
`;

/**
 * @component
 *
 * Input group, lays out all containing input elements horizontaly.
 * If you want to have multiple input elements below each other,
 * use multiple input groups.
 */

export default InputGroup;
