import { SetterOrUpdater } from 'recoil';
import { OptionOf } from '~/components/Inputs/Dropdown';
import {
  FlowAction,
  FlowV2_ActionFragment,
  FlowV2_Update_Metadata,
} from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import { IssueMap } from '~/scenes/Automation/v2/state/flowIssues';
import { Interaction } from '~/scenes/Automation/v2/state/interactions';
import onDeleteAction from '../onDeleteAction';
import onDeleteActionIfElse from '../onDeleteActionIfElse';
import onDuplicateAction from '../onDuplicateAction';

type ActionArgs<ActionT> = {
  action: ActionT;
  actions: Array<ClientFlowAction>;
  setActions: SetterOrUpdater<Array<ClientFlowAction>>;
  setInteraction: SetterOrUpdater<Interaction | null>;
  setIssues: SetterOrUpdater<IssueMap>;
  isExistingAction: boolean;
  setLastDeletedActionId: (id: string | null) => void;
  setFlowMetadata: SetterOrUpdater<Array<FlowV2_Update_Metadata>>;
  initialActions: Array<FlowV2_ActionFragment>;
  flowEnabled: boolean;
};

const deleteAction = ({
  actions,
  action,
  setActions,
  setIssues,
  setLastDeletedActionId,
  setInteraction,
  setFlowMetadata,
  initialActions,
  flowEnabled,
}: ActionArgs<ClientFlowAction>) =>
  action.__typename === 'FlowV2_Action_IfElse'
    ? onDeleteActionIfElse({
        actions,
        subjectAction: action,
        setActions,
        setIssues,
        setLastDeletedActionId,
        setInteraction,
        setFlowMetadata,
        initialActions,
        flowEnabled,
      })
    : onDeleteAction(action, setActions, setIssues, setLastDeletedActionId);

const getDeleteOption = (
  args: ActionArgs<ClientFlowAction>,
): OptionOf<(action: ClientFlowAction) => void> => ({
  key: 'delete',
  label: 'Verwijderen',
  icon: 'trashcan',
  type: 'DANGER',
  payload: () => {
    if (
      args.action.actionType === FlowAction.Wait &&
      args.isExistingAction &&
      args.flowEnabled
    ) {
      return args.setInteraction({
        type: 'setMetadataForWaitAction',
        action: args.action,
        onConfirm: () => deleteAction(args),
        onCancel: () => {},
      });
    }

    return deleteAction(args);
  },
});

const getDuplicateOption = (
  args: ActionArgs<
    Exclude<
      ClientFlowAction,
      | { __typename: 'FlowV2_Action_Start' }
      | { __typename: 'FlowV2_Action_IfElse' }
    >
  >,
): OptionOf<(action: ClientFlowAction) => void> => ({
  key: 'duplicate',
  label: 'Dupliceren',
  icon: 'copy',
  payload: () =>
    onDuplicateAction(args.action, args.setActions, args.setInteraction),
});

export type GetOptionsArgs = ActionArgs<ClientFlowAction> & {
  isExistingAction?: boolean;
};
/**
 * Returns the dropdown options for a given actionType
 * @param {GetOptionsArgs} args - Args needed to perform the dropdown actions
 * keywords: dropdown options, options, card
 */
const getCardOptions = ({
  action,
  isExistingAction = false,
  ...args
}: GetOptionsArgs): Array<OptionOf<(action: ClientFlowAction) => void>> => {
  switch (action.__typename) {
    case 'FlowV2_Action_Start':
      return [];
    case 'FlowV2_Action_Wait':
    case 'FlowV2_Action_Realworks_SendContact':
    case 'FlowV2_Action_SendEmail_Plain':
    case 'FlowV2_Action_Zapier_Trigger':
    case 'FlowV2_Action_Task_Create':
    case 'FlowV2_Action_Contact_Details':
    case 'FlowV2_Action_Contact_AddTag':
    case 'FlowV2_Action_Contact_DeleteTag':
    case 'FlowV2_Action_Contact_Assign':
      const optionArgs = {
        action,
        isExistingAction,
        ...args,
      };
      const options = [
        getDuplicateOption(optionArgs),
        getDeleteOption(optionArgs),
      ];

      return options;

    case 'FlowV2_Action_IfElse':
      return [getDeleteOption({ action, isExistingAction, ...args })];

    default:
      return [];
  }
};
export default getCardOptions;
