import { InlineImageEditorItem } from '~/components/HTMLEditor/HTMLEditor';
import { FlowEmailAttachmentValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { getAttributeValue } from '~/components/HTMLEditor/util/variableHTML';
import cleanedFilename from '~/util/cleanedFilename';
import {
  INLINE_IMAGE_ID_ATTR,
  INLINE_IMAGE_FILENAME_ATTR,
  INLINE_IMAGE_S3KEY_ATTR,
  INLINE_IMAGE_CONTENT_LENGTH_ATTR,
  INLINE_IMAGE_CONTENT_TYPE_ATTR,
  INLINE_IMAGE_SRC_ATTR,
  IMG_REGEX,
} from '~/components/HTMLEditor/constants';
import removeInlineAttachmentInformation from './removeInlineAttachmentInformation';
import {
  AttachmentType,
  SignatureAttachmentValue,
} from '~/components/SignatureContainer/types';
import { getAttachments, getAttachmentsFromHtml } from './getAttachments';

const SRC_REGEX = /src="[^"]*?"/g;
export type ReturnType = {
  html: string;
  inlineAttachments: Array<FlowEmailAttachmentValue | SignatureAttachmentValue>;
  isStillUploading: boolean;
};
const convertFroalaToHtmlWithInlineAttachments = (
  body: string,
  inlineImageList: Array<InlineImageEditorItem>,
  attachmentType?: AttachmentType,
): ReturnType => {
  if (!Array.isArray(inlineImageList)) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | inlineImageList (${inlineImageList}) is not an array!`,
    );
  }
  const inlineAttachments: ReturnType['inlineAttachments'] = [];
  let isStillUploading = false;

  const convertedHtml = body.replace(IMG_REGEX, matchedValue => {
    const prevDataSrc = getAttributeValue('data-dhsrcurl', matchedValue);

    if (prevDataSrc != null) {
      const { html, inlineAttachment } = grabFileInfoFromHTML(
        matchedValue,
        attachmentType,
      );

      if (
        inlineAttachments.find(
          link => link.inlineId === inlineAttachment.inlineId,
        ) == null
      ) {
        inlineAttachments.push(inlineAttachment);
      }

      return html;
    } else {
      const uploadId = getAttributeValue('data-uploadid', matchedValue);

      if (uploadId != null) {
        const inlineImage = inlineImageList.find(
          image => image.id === uploadId,
        );

        if (inlineImage != null) {
          if (inlineImage.fileDetails == null) {
            isStillUploading = true;
          } else {
            const { filename, s3key, contentLength } = inlineImage.fileDetails;
            const id = inlineImage.id;

            if (
              id == null ||
              filename == null ||
              s3key == null ||
              contentLength == null
            ) {
              throw Error(
                `${cleanedFilename(
                  __filename,
                )} | Should not occur | cannot convert inline image to flow (filename: ${
                  filename || 'null'
                } | s3key: ${s3key || 'null'} | inlineId: ${
                  id || 'null'
                } | contentLength: ${contentLength || 'null'})`,
              );
            }

            if (inlineAttachments.find(link => link.inlineId === id) == null) {
              inlineAttachments.push(
                getAttachments(attachmentType, inlineImage.fileDetails, id),
              );
            }

            const removedFileUploadId = matchedValue.replace(
              /data-uploadid="[^"]*"\s/g,
              '',
            );

            return removedFileUploadId.replace(
              SRC_REGEX,
              () => `src="[[inlineId:${id}]]"`,
            );
          }
        } else {
          isStillUploading = true;
        }
      }
    }
    // not an inline image, so return without changes
    return matchedValue;
  });

  return {
    html: convertedHtml,
    inlineAttachments,
    isStillUploading,
  };
};

const grabFileInfoFromHTML = (
  imgHtml: string,
  attachmentType?: AttachmentType,
): {
  html: string;
  inlineAttachment: FlowEmailAttachmentValue | SignatureAttachmentValue;
} => {
  const inlineId = getAttributeValue(INLINE_IMAGE_ID_ATTR, imgHtml);
  const filename = getAttributeValue(INLINE_IMAGE_FILENAME_ATTR, imgHtml);
  const s3key = getAttributeValue(INLINE_IMAGE_S3KEY_ATTR, imgHtml);
  const contentLength = getAttributeValue(
    INLINE_IMAGE_CONTENT_LENGTH_ATTR,
    imgHtml,
  );
  let contentType = getAttributeValue(INLINE_IMAGE_CONTENT_TYPE_ATTR, imgHtml);
  const src = getAttributeValue(INLINE_IMAGE_SRC_ATTR, imgHtml);
  if (contentType == 'null') {
    contentType = null;
  }

  if (
    inlineId == null ||
    filename == null ||
    s3key == null ||
    contentLength == null
  ) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | cannot convert inline image to event (filename: ${
        filename || 'null'
      } | s3key: ${s3key || 'null'} | inlineId: ${
        inlineId || 'null'
      } | contentLength: ${contentLength || 'null'})`,
    );
  }

  const fileDetails = {
    s3key,
    filename,
    contentLength: parseInt(contentLength),
    contentType,
  };

  const attachment: FlowEmailAttachmentValue | SignatureAttachmentValue =
    getAttachmentsFromHtml(attachmentType, fileDetails, inlineId, src);

  const withoutInlineAttachmentInformation =
    removeInlineAttachmentInformation(imgHtml);
  const removedFileUploadId = withoutInlineAttachmentInformation.replace(
    /data-uploadid="[^"]*"\s/g,
    '',
  );
  const withInlineIdAsUrl = removedFileUploadId.replace(
    /src="([^"]*)"/g,
    () => `src="[[inlineId:${inlineId}]]"`,
  );

  return { html: withInlineIdAsUrl, inlineAttachment: attachment };
};

export default convertFroalaToHtmlWithInlineAttachments;
