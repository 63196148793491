/**
 * Function that converts the html we get from the backend to what the frontend should use
 */

import {
  UNSUBSCRIBE_LINK_ON_BACKEND,
  UNSUBSCRIBE_LINK_IN_FROALA,
  MORTGAGE_LINK_ON_BACKEND,
  MORTGAGE_LINK_IN_FROALA,
} from '~/scenes/Automation/Flows/Actions/constants';

const backendUnsubscribeLinkRegex = new RegExp(
  `href="${UNSUBSCRIBE_LINK_ON_BACKEND}"`,
  'g',
);
const backendMortgageLinkRegex = new RegExp(
  `href="${MORTGAGE_LINK_ON_BACKEND}"`,
  'g',
);
export const convertBackendHTMLToFrontend = (html: string): string => {
  const replacedUnsubscribeLink = html.replace(
    backendUnsubscribeLinkRegex,
    `href="${UNSUBSCRIBE_LINK_IN_FROALA}"`,
  );

  const replacedMortgageLink = replacedUnsubscribeLink.replace(
    backendMortgageLinkRegex,
    `href="${MORTGAGE_LINK_IN_FROALA}"`,
  );

  return replacedMortgageLink;
};

const frontendUnsubscribeLinkRegex = new RegExp(
  `href="${UNSUBSCRIBE_LINK_IN_FROALA}"`,
  'g',
);
const frontendMortgageLinkRegex = new RegExp(
  `href="${MORTGAGE_LINK_IN_FROALA}"`,
  'g',
);
export const convertFrontendHTMLToBackend = (html: string): string => {
  const replacedUnsubscribeLink = html.replace(
    frontendUnsubscribeLinkRegex,
    `href="${UNSUBSCRIBE_LINK_ON_BACKEND}"`,
  );

  const replacedMortgageLink = replacedUnsubscribeLink.replace(
    frontendMortgageLinkRegex,
    `href="${MORTGAGE_LINK_ON_BACKEND}"`,
  );

  return replacedMortgageLink;
};
