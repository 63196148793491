import React from 'react';
import { RenderElementProps } from 'slate-react';
import styled, { css } from 'styled-components';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import { BlockquoteElement } from '~/components/PluginsEditor/types';
import serialize from './serialize';

export type Props = RenderElementProps & {
  dataTestId?: string;
  element: BlockquoteElement;
};

const BlockQuote: React.FC<Props> = ({
  dataTestId,
  attributes,
  element,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...attributes} {...element.attributes}>
    {rest.children}
  </Container>
);

const Container = styled.blockquote<{}>(
  ({ theme }) => css`
    border-left: ${theme.getTokens().border.width.base} solid #ddd;
    padding-left: 8px;
    margin: ${theme.space('m')} ${theme.space('xxs')};
  `,
);

export default {
  nodeName: 'BLOCKQUOTE',
  renderComponent: props => <BlockQuote {...props} />,
  deserialize: () => ({ type: ELEMENTS.BLOCKQUOTE }),
  serialize,
};
