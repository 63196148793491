import gql from 'graphql-tag';

import TaskListTaskFields from './TaskListTaskFields';
import AlgasSoldPropertyFields from './subfragments/AlgasSoldPropertyFields';
import BBWaardecheckPropertyFields from './BBWaardecheckPropertyFields';
import BBWaardecheckFullComparablePropertyFields from './BBWaardecheckFullComparablePropertyFields';
import EnvenPropertyFields from './subfragments/EnvenPropertyFields';
import PropertyFields from './PropertyFields';

export default gql`
  fragment ActivityFields on Activity {
    __typename
    _v
    id
    accountId
    category
    createdDate

    source {
      ... on Actor_Contact {
        __typename
        id
        name
      }

      ... on Actor_User {
        __typename
        id
        name
      }

      ... on Actor_App {
        __typename
        name
      }

      ... on Actor_Flow {
        __typename
        flowBlueprintId
        flowBlueprintActionId
      }
    }

    ... on Event_Contact_Generic {
      # we do not add the body here as it is very large and not needed everywhere
      highlighted
      sortingDate
      icon {
        url
      }
      header {
        text
      }
      summary
      attachments {
        src
        fileName
        fileType
        label
      }
    }

    ... on Event_Contact_App_BBWaardecheck_ContactRequest {
      relatedReportId
      email
      name
      phone
    }

    ... on Event_Contact_Call {
      deleted
      description
      loggedDate
      sortingDate: loggedDate
    }

    ... on Event_Contact_Note {
      deleted
      description
      loggedDate
      sortingDate: loggedDate
    }

    ... on Event_Contact_Email {
      deleted
      receivedAt
      sortingDate: receivedAt
      hasMore
      subject
      snippet
      deliveryStatus
      deliveryErrorMessage
      from {
        name
        email
      }
      to {
        name
        email
      }
      cc {
        name
        email
      }
      bcc {
        name
        email
      }
      tracking {
        opened
        clicked {
          details {
            url
            count
          }
          total
        }
      }
    }

    ... on Task_Contact {
      ...TaskListTaskFields
    }

    ... on Event_Contact_App_Hypotheekbond {
      id
      tool
      email
      name
      hypotheekbondPhone: phone
      dateOfBirth
    }

    ... on Event_Contact_App_ValuationReport {
      deleted
      email
      name
      phone
      why
      when
      contactRequested
      pdfReportUrl
      property {
        location {
          lat
          lng
        }
        street
        houseNumber
        addition
        city
        postcode
        buildIn
        livingArea
        propertyType
      }
      swipe {
        yes {
          ...AlgasSoldPropertyFields
        }
        no {
          ...AlgasSoldPropertyFields
        }
      }
      valuation {
        success
        predicted
        min
        max
      }
    }

    ... on Event_Contact_App_ValuationRequest {
      deleted
      email
      name
      phone
      why
      when
      property {
        location {
          lat
          lng
        }
        street
        houseNumber
        addition
        city
        postcode
        buildIn
        livingArea
        propertyType
      }
      swipe {
        yes {
          ...AlgasSoldPropertyFields
        }
        no {
          ...AlgasSoldPropertyFields
        }
      }
    }

    ... on Event_Contact_App_Funda_ContactRequest {
      deleted
      email
      phone
      name
      tiaraObjectId
      streetName
      houseNumber
      postcode
      placeName
      comments
      mainArea
      subArea
      titlePrefix
      subjectAddress
      propertyCategory
    }

    ... on Event_Contact_App_Funda_BrochureRequest {
      deleted
      email
      phone
      name
      tiaraObjectId
      streetName
      houseNumber
      postcode
      placeName
      comments
      mainArea
      subArea
      titlePrefix
      subjectAddress
      propertyCategory
    }

    ... on Event_Contact_App_Funda_ViewingRequest {
      deleted
      email
      phone
      name
      tiaraObjectId
      streetName
      houseNumber
      postcode
      placeName
      comments
      mainArea
      subArea
      day
      timeOfDay
      titlePrefix
      subjectAddress
      propertyCategory
    }

    ... on Event_Contact_App_BBWaardecheck_Report {
      deleted
      email
      phone
      name
      valuationPriceMin
      valuationPrice
      valuationPriceMax
      pdfReportUrl
      property {
        ...BBWaardecheckPropertyFields
      }
      why
      when
      comparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      nonComparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      nonSwipedProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
    }

    ... on Event_Contact_App_BBWaardecheck_AppraisalRequest {
      deleted
      email
      phone
      name
      why
      when
      property {
        ...BBWaardecheckPropertyFields
      }
      comparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      nonComparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
    }

    ... on Event_Contact_App_BBWaardecheck_ContactRequest {
      deleted
      email
      phone
      name
      property {
        ...BBWaardecheckPropertyFields
      }
      comparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      nonComparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      requestedProperty {
        ...BBWaardecheckFullComparablePropertyFields
      }
    }

    ... on Event_Contact_App_VBOWaardecheck_Report {
      deleted
      email
      phone
      name
      valuationPriceMin
      valuationPrice
      valuationPriceMax
      pdfReportUrl
      property {
        ...BBWaardecheckPropertyFields
      }
      why
      when
      comparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      nonComparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      nonSwipedProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
    }

    ... on Event_Contact_App_VBOWaardecheck_AppraisalRequest {
      deleted
      email
      phone
      name
      why
      when
      property {
        ...BBWaardecheckPropertyFields
      }
      comparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      nonComparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
    }

    ... on Event_Contact_App_VBOWaardecheck_ContactRequest {
      deleted
      email
      phone
      name
      property {
        ...BBWaardecheckPropertyFields
      }
      comparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      nonComparableProperties {
        ...BBWaardecheckFullComparablePropertyFields
      }
      requestedProperty {
        ...BBWaardecheckFullComparablePropertyFields
      }
    }

    ... on Event_Contact_App_Zapier {
      contactId
      name
      deleted
      zapierEventId
      zapierEventKey
      metadata {
        key
        value
      }
    }

    ... on Event_Contact_App_ZapierTrigger {
      contactId
      name
      deleted
      metadata {
        key
        value
      }
    }

    ... on Event_Contact_App_ARXGroep_TransactionCreated {
      contactId
      # Backend says we cannot guarantee that it will have a name and phone
      contactName: name
      contactPhone: phone
      email
      city
      street
      postcode
      houseNumberAddition
      product
      supplier
      amount
    }

    ... on Event_Contact_App_ARXGroep_ContractCreated {
      contactId
      contactName: name
      contactPhone: phone
      email
      city
      street
      postcode
      houseNumberAddition
      product
      supplier
    }

    ... on Event_Contact_App_EnvenRequest {
      contactId
      deleted
      firstName
      lastName
      email
      phone
      property {
        ...EnvenPropertyFields
      }
      products
    }

    ... on Event_Contact_App_EnvenTransaction {
      contactId
      deleted
      products
      transactionAmount: amount
    }

    ... on Event_Contact_App_Trustoo {
      contactId
      name
      email
      trustooPhone: phone
      place
      subject
      confirmed
      details
      questionnaire {
        question
        answers
      }
    }

    ... on Event_Contact_App_TrustooSubmitted {
      contactId
      name
      email
      trustooSubmittedPhone: phone
      city
      subject
      provider
    }

    ... on Event_Contact_App_TrustooConversion {
      contactId
      name
      email
      trustooConversionPhone: phone
      city
      subject
      provider
      commission
    }

    ... on Event_Contact_App_WOZComplaint_ComplaintCreated {
      name
      email
      wozPhone: phone
      wozPostcode: postcode
      wozHouseNumber: houseNumber
      wozHouseNumberAddition: houseNumberAddition
      wozCity: city
      wozStreet: street
      intention
      wozValue
    }

    ... on Event_Contact_App_WOZComplaint_ComplaintSuccess {
      contactId
      deleted
      commission
    }

    ... on Event_Contact_App_SustainabilityReport {
      name
      email
      phone
      pdfReportUrl
      property {
        ...PropertyFields
      }
    }
  }

  ${TaskListTaskFields}
  ${EnvenPropertyFields}
  ${AlgasSoldPropertyFields}
  ${BBWaardecheckPropertyFields}
  ${PropertyFields}
  ${BBWaardecheckFullComparablePropertyFields}
`;
