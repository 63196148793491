/**
 * Returns an address string with street, houseNumber and houseNumberAddition
 * keywords: address
 */
const asAddress = ({
  street,
  houseNumber,
  houseNumberAddition,
}: {
  street: string;
  houseNumber?: number | string | undefined;
  houseNumberAddition?: string | undefined | null;
}): string =>
  `${street}${houseNumber ? ` ${houseNumber}` : ''}${
    houseNumberAddition ? `-${houseNumberAddition}` : ''
  }`;

export default asAddress;
