import React, { useState } from 'react';
import { useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import ChartLabelContainer from '~/components/Charts/DonutChart/components/Legend/components/ChartLabelContainer';
import LegendIcon from '~/components/Charts/LegendIcon';
import useTimeout from '~/components/util/useTimeout';
import { Link as NavLink } from '~/components/Link';
import TEST_ID from '../../index.testid';

export type Props = {
  setActiveOption: (action: string | null) => void;
  numberFormatter: Intl.NumberFormat;
  additionalInfo: React.ReactNode | string | undefined;
  data?: Array<any>;
  loaded: boolean;
  activeOption: string | null;
  dataTestId?: string;
};

const Legend = ({
  setActiveOption,
  numberFormatter,
  additionalInfo,
  data,
  loaded,
  activeOption,
  dataTestId,
}: Props) => {
  const [animationStarted, setAnimationStarted] = useState<boolean>(false);
  const transitions = useTransition(animationStarted ? data : [], {
    from: { opacity: 0, transform: 'translate3d(0,-30px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-30px,0)' },
    config: {
      mass: 1,
      tension: 60,
      friction: 8,
      clamp: true,
    },
    trail: 80,
    keys: item => item.uuid,
  });

  useTimeout(() => setAnimationStarted(true), 1000);

  return (
    <>
      <StyledTable>
        <colgroup>
          <col width="5%" />
          <col width="10%" />
          <col width="85%" />
        </colgroup>

        {transitions((style, item, { key }) => {
          const { color } = item;
          const formattedValue = numberFormatter.format(item.value);

          return (
            <ChartLabelContainer
              onMouseEnter={loaded ? () => setActiveOption(item.uuid) : f => f}
              onMouseLeave={loaded ? () => setActiveOption(null) : f => f}
              $active={activeOption === item.uuid}
              key={key}
              style={style}
              data-testid={`${dataTestId}-${TEST_ID.LEGEND_ITEM}`}
            >
              <StyledTd>
                <LegendIcon
                  color={color}
                  active={!activeOption || activeOption === item.uuid}
                />
              </StyledTd>
              <StyledTd title={formattedValue}>
                <BoldText>{formattedValue}</BoldText>
              </StyledTd>

              <StyledTd title={item.name}>
                {item.link ? (
                  <NavLink to={item.link}>{item.name}</NavLink>
                ) : (
                  <PlainText>{item.name}</PlainText>
                )}
              </StyledTd>
            </ChartLabelContainer>
          );
        })}
      </StyledTable>

      {additionalInfo && (
        <AdditionalInfoContainer style={{ opacity: loaded ? 1 : 0 }}>
          {additionalInfo}
        </AdditionalInfoContainer>
      )}
    </>
  );
};

const AdditionalInfoContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('xl')};
    opacity: 0;
    transition: opacity 0.5s ease-out;
  `,
);

const BoldText = styled.span<{}>`
  ${({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
  `};
`;

const PlainText = styled.span<{}>`
  user-select: none;
  word-break: break-word;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const TRUNCATE_TEXT_LIMIT = '100px';
const StyledTd = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${TRUNCATE_TEXT_LIMIT};

  ${({ theme }) => css`
    padding: ${theme.space('xs')};
  `};
`;

export default Legend;
