import type { Node_NodeContainer_Leaf_Contact__Input } from '~/graphql/types';
import type { ContactSubFilter } from './types.flow';

import { FILTER_SUB_TYPE } from './constants';
import asAssignedToOfficeFilterInput from './AssignedToOffice/asAssignedToOfficeFilterInput';
import asAssignedToUserFilterInput from './AssignedToUser/asAssignedToUserFilterInput';
import asContactSourceFilterInput from './ContactSource/asContactSourceFilterInput';
import asCreatedFilterInput from './Created/asCreatedFilterInput';
import asContactTagFilterInput from './Tag/asContactTagFilterInput';
import asContactBlockMarketingFilterInput from './BlockMarketing/asBlockMarketingFilterInput';
import cleanedFilename from '~/util/cleanedFilename';
import { reportErrorToTracker } from '~/util/assertion';
import asLeadscoreFilterInput from './Leadscore/asLeadscoreFilterInput';
import asContactAddressFilterInput from './Address/asContactAddressFilterInput';

const asContactLeafInput = (
  filter: ContactSubFilter,
): Node_NodeContainer_Leaf_Contact__Input | null => {
  let subFilter;

  switch (filter.type) {
    case FILTER_SUB_TYPE.EMPTY: {
      subFilter = null;

      break;
    }
    case FILTER_SUB_TYPE.ASSIGNED_TO_OFFICE: {
      subFilter = asAssignedToOfficeFilterInput(filter);

      break;
    }
    case FILTER_SUB_TYPE.ASSIGNED_TO_USER: {
      subFilter = asAssignedToUserFilterInput(filter);

      break;
    }
    case FILTER_SUB_TYPE.CREATED: {
      subFilter = asCreatedFilterInput(filter);

      break;
    }
    case FILTER_SUB_TYPE.LEAD_SCORE: {
      subFilter = asLeadscoreFilterInput(filter);

      break;
    }
    case FILTER_SUB_TYPE.HAS_TAG:
    case FILTER_SUB_TYPE.DOES_NOT_HAVE_TAG: {
      subFilter = asContactTagFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.SOURCED_BY_APP_TYPE:
    case FILTER_SUB_TYPE.SOURCED_BY_USER: {
      subFilter = asContactSourceFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.BLOCK_MARKETING: {
      subFilter = asContactBlockMarketingFilterInput(filter);
      break;
    }
    case FILTER_SUB_TYPE.POSTCODE:
    case FILTER_SUB_TYPE.CITY: {
      subFilter = asContactAddressFilterInput(filter);
      break;
    }
    default: {
      reportErrorToTracker(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | filter with type (${
          filter.type
        }) not implemented`,
      );

      // do not break on a wrong input, just ignore it
      return null;
    }
  }

  if (subFilter == null) {
    return null;
  }

  return {
    Details: {
      ...subFilter,
    },
  };
};

export default asContactLeafInput;
