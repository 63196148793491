import React from 'react';
import styled, { css } from 'styled-components';
// import AddBlockMenu from '../AddBlockMenu/Fixed';
import { Expanded } from '../ControlComponents';
import Issues from '../Issues/Fixed';
import SettingsMenu from '../SettingsMenu';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  onToggleExpand: (expanded: Expanded) => () => void;
  expanded: Expanded | null;
  isFullScreen?: boolean;
};

const AsideContainer: React.FC<Props> = ({
  dataTestId,
  onToggleExpand,
  expanded,
  isFullScreen,
  ...rest
}) => (
  <Container data-testid={dataTestId} isFullScreen={isFullScreen} {...rest}>
    {/* Disabled due to lack of time */}
    {/* <AddBlockMenu
      expanded={expanded === 'blocks'}
      onToggleExpand={onToggleExpand('blocks')}
    /> */}
    <SettingsMenu
      dataTestId={TEST_ID.SETTINGS_MENU}
      expanded={expanded === 'settings'}
      onToggleExpand={onToggleExpand('settings')}
    />
    <Issues
      dataTestId={TEST_ID.ISSUES_CONTAINER}
      expanded={expanded === 'issues'}
      onToggleExpand={onToggleExpand('issues')}
    />
  </Container>
);
const CONTAINER_WIDTH = 231;

const Container = styled.div<{ isFullScreen?: boolean }>(
  ({ theme, isFullScreen = false }) => css`
    max-width: ${CONTAINER_WIDTH}px;
    position: absolute;
    width: 100%;
    margin-top: ${isFullScreen ? theme.space('xxl') : theme.space('base')};

    & > * {
      margin-bottom: ${theme.space('m')};
    }
  `,
);

export default AsideContainer;
