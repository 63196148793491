import { filter, mergeRight, pickAll, propEq } from 'ramda';

import {
  DeletedUserToAccountRelationship,
  AccountRelationship,
  UserRole,
  SessionHydrationUserFieldsFragment,
} from '~/graphql/types';
import useSessionHydration from '../useSessionHydration';

export type DeletedUser = {
  id: string;
  name: string;
  role: UserRole;
  __typename: 'DeletedUser';
};

export type User = SessionHydrationUserFieldsFragment | DeletedUser;
export type UserLookupTable = {
  [userId: string]: User;
};

export const filterType = (type, object) =>
  filter(propEq('__typename', type), object);

const useUserLookup = (
  typename?: Array<'User' | 'PendingUser' | 'DeletedUser'>,
): UserLookupTable | null => {
  const [{ users, relations, deletedRelations }] = useSessionHydration();

  if (!users || !relations) return null;

  const deletedUsers: UserLookupTable = {};
  const deletedAccountRelations = deletedRelations
    ? (filterType(
        'DeletedUserToAccountRelationship',
        deletedRelations,
      ) as Array<DeletedUserToAccountRelationship>)
    : [];
  for (const deleted of deletedAccountRelations) {
    if (deleted) {
      deletedUsers[deleted.userId] = {
        id: deleted.userId,
        name: deleted.name,
        role: deleted.role,
        __typename: 'DeletedUser',
      };
    }
  }

  const existingUsers: UserLookupTable = {};
  const accountRelations = filterType(
    'AccountRelationship',
    relations,
  ) as Array<AccountRelationship>;

  for (const user of users) {
    existingUsers[user.id] = pickAll(
      [
        'id',
        'name',
        'phone',
        'email',
        'img',
        'lastInvitationTime',
        '__typename',
      ],
      user,
    );
  }
  for (const relation of accountRelations) {
    const user = existingUsers[relation.userId];

    if (user && user.__typename === 'User') {
      existingUsers[relation.userId] = {
        __typename: user.__typename,
        id: user.id,
        name: user?.name,
        email: user?.email,
        img: user?.img,
        phone: user?.phone,
      };
    }

    if (user && user.__typename === 'PendingUser') {
      existingUsers[relation.userId] = {
        __typename: user.__typename,
        id: user.id,
        email: user?.email,
        lastInvitationTime: user.lastInvitationTime,
      };
    }
  }

  const allUsers = mergeRight(existingUsers, deletedUsers);

  if (typename) {
    return filter(
      (user: User): boolean => typename.includes(user.__typename),
      allUsers,
    );
  }

  return allUsers;
};

export default useUserLookup;
