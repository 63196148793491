import React from 'react';
import styled, { css } from 'styled-components';
import InfoBlock from '~/components/InfoBlock';

export type Props = {
  dataTestId?: string;
};

const ApiKeySuccess: React.FC<Props> = () => (
  <StyledInfoBlock headerText="API key succesvol ingevoerd" icon="check">
    Zapier is nu gekoppeld aan jouw DatHuis account!
  </StyledInfoBlock>
);

const StyledInfoBlock = styled(InfoBlock)<{}>(
  ({ theme }) => css`
    margin: ${theme.space('xl')} 0;
  `,
);

export default ApiKeySuccess;
