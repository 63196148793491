import type { RefObject } from 'react';
import { useEffect, useCallback } from 'react';

/**
 * Checks whether a click registered outside the element
 *
 * keywords: click,outside,element
 */
const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => any,
) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(e.target as Node)) {
        const { x, y, width, height } = ref.current.getBoundingClientRect();
        const { clientX, clientY } = e;

        const insideX = clientX > x && clientX < x + width;
        const insideY = clientY > y && clientY < y + height;

        if (insideX && insideY) return;
        callback();
      }
    },
    [callback, ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};

export default useOutsideClick;
