import { isEmpty, isNil } from 'ramda';
import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionContactDetails } from '~/graphql/types.client';
import removeTypenames from '../../../removeTypenames';

/**
 *
 * @param {ClientFlowActionContactDetails} action - ContactDetails client action
 * keywords:
 */
const serializeContactDetailsAction = (
  action: ClientFlowActionContactDetails,
): FlowV2_Action__Input => {
  const { id, accountId, flowBlueprintId, parentIds, field } = action;

  const isNameFilled = !isNil(field.name) && !isEmpty(field.name?.template);
  const updatedField = isNameFilled
    ? { name: field.name }
    : { phone: field.phone };

  return {
    Contact: {
      Details: {
        id,
        accountId,
        flowBlueprintId,
        parentIds,
        field: removeTypenames(updatedField),
      },
    },
  };
};
export default serializeContactDetailsAction;
