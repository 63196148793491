/**
 * Separates label (inside double brackets) from the text and returns both of them.
 * Make sure to add the label before the text.
 *
 * @param {string} str - string
 * keywords: action label, pointer label
 */

/** Matches every character inside double brackets */
const BRACKETS_REGEX = /\[\[(.+)\]\]/g;

type ReturnType = {
  /* Text after ]] */
  text: string;

  /* Text inside of [[]] */
  label: string | null;
} | null;

const extractLabelFromStr = (str: string | null): ReturnType => {
  if (!str) return null;

  const match = str.split(BRACKETS_REGEX);

  const label = match?.[1] || null;
  const text = match?.[2] || '';

  return {
    text: !label ? str : text,
    label,
  };
};

export default extractLabelFromStr;
