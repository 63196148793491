import gql from 'graphql-tag';

export default gql`
  fragment ZapierEventFields on ZapierEvent {
    id
    accountId
    id
    name
    fields {
      key
      label
      type
    }
    createdDate
  }
`;
