import styleToJS from 'style-to-js';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import { DhImageElement, ImageElement } from '~/components/PluginsEditor/types';
import { styleToJSOptions } from '~/components/PluginsEditor/utils/deserialize/utils/convertDOMAttrsToReactProps/utils';

/** We pass this random url to get the broken image icon for images with no attachments */
export const INVALID_IMAGE_URL = 'https://invalidurl';

const deserialize = (el: Element): DhImageElement | ImageElement => {
  const styleStr = el.getAttribute('style') || '';
  const styleAttr = styleToJS(styleStr, styleToJSOptions);
  const width = el.getAttribute('width') ?? styleAttr?.width;

  const cssStyles = {
    width,
    // Important to make the images align with the text in table cells
    display:
      !styleAttr.display || styleAttr.display === 'unset'
        ? 'inline-block'
        : styleAttr?.display,
    opacity: styleAttr?.opacity,
    float: styleAttr?.float,
  };

  /** If the image is not in the attachments array we return a broken image */
  if (el.getAttribute('hasError')) {
    return {
      type: ELEMENTS.IMAGE,
      url: INVALID_IMAGE_URL,
      children: [{ text: '' }],
      hasError: true,
    };
  }

  const isDHImage = el.getAttribute('data-dhinlineid');
  if (isDHImage) {
    return {
      type: ELEMENTS.DH_IMAGE,
      url: el.getAttribute('src'),
      inlineId: el.getAttribute('data-dhinlineid'),
      s3key: el.getAttribute('data-s3key'),
      filename: el.getAttribute('data-dhfilename'),
      contentType: el.getAttribute('data-dhcontenttype'),
      contentLength: Number(el.getAttribute('data-dhcontentlength')),
      children: [{ text: '' }],
      ...cssStyles,
    };
  }

  return {
    type: ELEMENTS.IMAGE,
    url: el.getAttribute('src'),
    children: [{ text: '' }],
    ...cssStyles,
  };
};

export default deserialize;
