import {
  StringParameterValue,
  NumberParameterValue,
  BooleanParameterValue,
  HandledFlowParameterMappingParameter,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { PARAMETER_VALUE_TYPE } from '../ParameterValue/constants';

export type FlowParameterMappingParameter =
  | BooleanParameterValue
  | StringParameterValue
  | NumberParameterValue;

export type FlowParameterMapping = {
  mappingId: string;
  mapping: FlowParameterMappingParameter;
};

export type HandledFlowParameterMapping = {
  mappingId: string;
  mapping: HandledFlowParameterMappingParameter;
};

export const isHandledFlowParameterMapping = (
  mapping: FlowParameterMapping,
): mapping is HandledFlowParameterMapping =>
  mapping.mapping.type === PARAMETER_VALUE_TYPE.STRING_POINTER ||
  mapping.mapping.type === PARAMETER_VALUE_TYPE.BOOLEAN_POINTER ||
  mapping.mapping.type === PARAMETER_VALUE_TYPE.NUMBER_POINTER;
