import React from 'react';
import styled, { css } from 'styled-components';

type AlertType = 'error' | 'success';
type Props = {
  /** Alert text */
  text?: string | null;

  /** Alert type. */
  type?: AlertType | null;

  /** Can be used instead of type="error" */
  error?: boolean;
};

const SimpleText = ({ type, text, error }: Props) => {
  const alertType = type ? type : error ? 'error' : 'success';
  const isVisible = typeof text === 'string' && text.length > 0;

  return (
    <AlertElement
      $isVisible={isVisible}
      $type={alertType}
      data-testid="alert-element"
    >
      {text}
    </AlertElement>
  );
};

type AlertElementProps = {
  $isVisible: boolean;
  $type: AlertType;
};
const AlertElement = styled.div<AlertElementProps>`
  font-size: 1.25em;

  ${({ theme, $isVisible, $type }) => {
    if ($isVisible) {
      return css`
        padding: 0 0 1em;
        color: ${$type === 'error'
          ? theme.color('danger')
          : theme.color('primary', 'light')};
      `;
    }

    return '';
  }}
`;

export default SimpleText;
