import React from 'react';

import { GetPositionStringFn } from '~/scenes/Automation/Flows/types.flow';

/**
 * No templates in flows yet, so commented out all that code
 */
// import { GetEmailTemplatesQuery } from '~/graphql';
// import query from '~/graphql/query/GetEmailTemplates';
// import { Loading, AppErrorScreen } from '~/components';
import FlowContext from './FlowContext';
import NoAccountErrorScreen from '~/components/NoAccountErrorScreen';
import useCurrentAccount from '~/hooks/useCurrentAccount';

type Props = {
  children: React.ReactNode;
  isInitialised: boolean;
  showValidation: boolean;
  getPositionStringForId: GetPositionStringFn;
  onDeleteAction?: (actionId: string) => void;
};
const FlowContextProvider = ({
  children,
  isInitialised,
  showValidation,
  onDeleteAction,
  getPositionStringForId,
}: Props) => {
  const account = useCurrentAccount();

  if (!account) return <NoAccountErrorScreen />;

  // const baseVariables = { accountId: account.id, limit: 100 };

  return (
    // <GetEmailTemplatesQuery
    //   name={cleanedFilename(__filename)}
    //   query={query}
    //   variables={baseVariables}
    // >
    //   {({ loading, error, data, fetchMore }) => {
    //     if (loading) return <Loading />;
    //     if (error || !data) return <AppErrorScreen />;
    //     const { items, nextToken } = data.getTemplates;
    //     const loadMore = () => {
    //       return fetchMore({
    //         variables: {
    //           ...baseVariables,
    //           nextToken: nextToken || null,
    //         },
    //         updateQuery: (previousResult, { fetchMoreResult }) => {
    //           if (!fetchMoreResult) return previousResult;
    //           const prevData = previousResult.getTemplates;
    //           const newData = fetchMoreResult.getTemplates;
    //           return {
    //             ...previousResult,
    //             getTemplates: {
    //               ...prevData,
    //               items: [...prevData.items, ...(newData.items || [])],
    //               nextToken: newData.nextToken,
    //             },
    //           };
    //         },
    //       });
    //     };
    //     if (nextToken != null) {
    //       loadMore();
    //       return <Loading />;
    //     }
    //     return (
    <FlowContext.Provider
      value={{
        // emailTemplates: items.map(({ id, name }) => ({ id, name })),

        isInitialised,
        showValidation,
        onDeleteAction,
        getPositionStringForId,
      }}
    >
      {children}
    </FlowContext.Provider> // );
    // }}
    // </GetEmailTemplatesQuery>
  );
};
export default FlowContextProvider;
