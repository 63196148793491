const zIndex = {
  toasts: 2147483647,
  top: 2147483646,
  dropdown: 7000,
  footer: 900,
};

export type SystemZIndexMap = typeof zIndex;
export type SystemZIndex = keyof SystemZIndexMap;
export default zIndex;
