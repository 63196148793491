import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/Button';

import { Input } from '~/components/Inputs';
import GetAppValuationReportRouteAvailablity from '~/graphql/query/GetAppValuationReportRouteAvailablity';
import UpdateAppValuationReport from '~/graphql/mutation/UpdateAppValuationReport';

import Validation from '~/util/Validation';
import formatToastMessage from '~/util/formatToastMessage';
import TEST_ID from './index.testid';
import useAddToast from '~/hooks/useAddToast';

export const URL_PREFIX = 'www.mijnwaarderapport.nl/c/';

const text = {
  desiredURL: 'Gewenste URL',
  invalidValue:
    'Helaas, dit is geen geldige URL. Gebruik alleen kleine letters en geen spaties',
  isAvailable: 'Deze URL is nog vrij',
  notAvailable: 'Helaas is deze URL niet meer vrij',
  checkLabel: 'Beschikbaarheid controleren',
  saveLabel: 'Beschikbaar, Opslaan?',
};

export type Props = {
  loading: boolean;
  route: string;
  reportId: string;
  accountId: string;
  refetchAppValuationReport: () => void;
};

const URLContainer = ({
  loading,
  route,
  reportId,
  accountId,
  refetchAppValuationReport,
}: Props) => {
  const addToast = useAddToast();
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [update, setUpdate] = useState<$Object | null>(null);
  const [slug, setSlug] = useState<string>(route);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isAvailable, checkingAvailability, getAvailability] =
    useAvailabilityCheck(hasChanged);
  const [updateRoute] = useMutation(UpdateAppValuationReport, {
    onCompleted: _update => {
      setUpdate(_update);
      setHasChanged(false);
    },
    onError: () => {
      setHasChanged(true);
      addToast([
        formatToastMessage(
          'Wij konden helaas de URL niet updaten, probeer het later opnieuw.',
          'danger',
        ),
      ]);
    },
  });

  useEffect(() => {
    if (!Validation.String.isSlug(slug) || !/^(\w|-)+$/.test(slug)) {
      return setValidationError(text.invalidValue);
    }
    if (isAvailable === false) {
      return setValidationError(text.notAvailable);
    }

    return setValidationError(null);
  }, [slug, isAvailable]);

  return (
    <Container>
      <Label error={validationError}>
        {validationError ? validationError : text.desiredURL}
      </Label>
      <Inner>
        <Prefix>{URL_PREFIX}</Prefix>
        <Input
          type="text"
          disabled={loading}
          error={validationError}
          value={slug}
          onChange={e => {
            if (e.target) {
              setSlug(e.target.value);
              setHasChanged(true);
              setUpdate(null);
            }
          }}
          name="url-link"
        />
        {((hasChanged && isAvailable === null) || checkingAvailability) && (
          <Button
            size="medium"
            loading={checkingAvailability}
            onClick={() => {
              getAvailability({
                variables: { accountId, id: reportId, route: slug },
              });
              setHasChanged(false);
            }}
            disabled={!!validationError}
            dataTestId={TEST_ID.CHECK_AVAILABILITY_BUTTON}
            label={text.checkLabel}
          />
        )}
        {isAvailable === true && !update && (
          <Button
            appearance="secondary"
            size="medium"
            loading={loading}
            onClick={() =>
              updateRoute({
                variables: { accountId, id: reportId, update: { route: slug } },
              }).then(() => {
                refetchAppValuationReport();
              })
            }
            disabled={!!validationError}
            dataTestId={TEST_ID.CONFIRM_BUTTON}
            icon="check"
            label={text.saveLabel}
          />
        )}
      </Inner>
    </Container>
  );
};

const useAvailabilityCheck = hasChanged => {
  const addToast = useAddToast();
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
  const [getAvailability, { loading }] = useLazyQuery(
    GetAppValuationReportRouteAvailablity,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setIsAvailable(data.isAvailable);
      },
      onError: () => {
        setIsAvailable(null);
        addToast([
          formatToastMessage(
            'Wij konden helaas de URL valideren, probeer het later nog eens',
            'danger',
          ),
        ]);
      },
    },
  );

  useEffect(() => {
    setIsAvailable(null);
  }, [hasChanged]);

  return [isAvailable, loading, getAvailability] as const;
};

const Container = styled.div<{}>``;

const Label = styled.label<{ error: string | null }>(
  ({ theme, error }) => css`
    display: block;
    margin: ${theme.space('xxs')} 0 ${theme.space('xxs')} 0;
    color: ${error ? theme.color('danger') : theme.color('text')};
  `,
);

const Prefix = styled.div<{}>(
  ({ theme }) => css`
    background-color: #ddd; /** Yes,yes this should've come from the theme but we don't have it.... */
    height: 100%;
    padding: 11px; /** Exact same result as calculate in Input */
    border-top-left-radius: ${theme.getTokens().border.radius.s};
    border-bottom-left-radius: ${theme.getTokens().border.radius.s};
    border: ${theme.getTokens().border.width.s} solid ${theme.color('grey')};
  `,
);
const Inner = styled.div<{}>`
  display: flex;
  align-items: center;
  width: 100%;

  input {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export default URLContainer;
