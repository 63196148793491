import React, { ReactElement } from 'react';

import {
  FlowVariableStashItem,
  FlowOutputObject,
  FlowOutputObjectField,
} from '~/scenes/Automation/Flows/types.flow';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import { PointerVariable } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { Option } from '~/components/Inputs/Dropdown';

import { Dropdown } from '~/components/Inputs';
import Catalog from '~/Catalog';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import TEST_ID from './PointerComponent.testid';
import FlowContext from '~/scenes/Automation/Flows/FlowContext';
import validateStashVariable from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/util/validateStashVariable';
import getFieldOptions from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/util/getFieldOptions';
import cleanedFilename from '~/util/cleanedFilename';

type MyProps = {
  possibleVariables: Array<FlowVariableStashItem>;
  fieldType: FLOW_OUTPUT_TYPE;
  variable: PointerVariable | null;
  onChange: (newPointerVariable: PointerVariable) => void;
  variableLabel?: string;
  fieldLabel?: string;
  hideVariableDropdownIfOnlyOneOptionAndSelected?: boolean;
  hideFieldDropdownIfOnlyOneOptionAndSelected?: boolean;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
};
class PointerComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  validate = (): boolean => {
    const { variable, possibleVariables, fieldType } = this.props;

    return validateStashVariable(variable, possibleVariables, fieldType);
  };

  render() {
    const {
      possibleVariables,
      fieldType,
      variable,
      onChange,
      fieldLabel,
      variableLabel,
      hideVariableDropdownIfOnlyOneOptionAndSelected,
      hideFieldDropdownIfOnlyOneOptionAndSelected,
    } = this.props;

    const selectedVariableIndex =
      variable == null ? -1 : findVariableIndexIn(possibleVariables, variable);
    const selectedVariable = possibleVariables[selectedVariableIndex];

    const fieldOptions = getFieldOptions(
      selectedVariable,
      fieldType,
      fieldType,
    );

    const selectedField = variable?.field;
    const selectedFieldIndex =
      selectedField == null
        ? -1
        : findFieldIndexIn(fieldOptions, selectedField);

    return (
      <FlowContext.Consumer>
        {({ showValidation }) => {
          let variableDropdownComponent: ReactElement | null = null;
          if (
            hideVariableDropdownIfOnlyOneOptionAndSelected === true &&
            selectedVariableIndex >= 0 &&
            possibleVariables.length === 1
          ) {
            variableDropdownComponent = null;
          } else {
            variableDropdownComponent = (
              <Dropdown
                dataTestid={TEST_ID.VARIABLE_DROPDOWN}
                selectOnlyOptionAutomatically
                error={
                  showValidation && selectedVariable == null
                    ? Catalog.requiredField
                    : ''
                }
                label={
                  variableLabel == null
                    ? Catalog.flows.pointerVariableLabel
                    : variableLabel
                }
                options={possibleVariables.map(possibleVariable => ({
                  label: possibleVariable.variableLabel,
                  payload: possibleVariable,
                  key: possibleVariable.variableName,
                }))}
                selectedOptionIdx={selectedVariableIndex}
                onChange={({ option }) => {
                  const { variableName } = option.payload;

                  onChange({
                    ...variable,
                    name: variableName,
                    field: null,
                  });
                }}
              />
            );
          }

          let fieldDropdownComponent: ReactElement | null;
          if (
            hideFieldDropdownIfOnlyOneOptionAndSelected === true &&
            selectedFieldIndex >= 0 &&
            fieldOptions.length === 1
          ) {
            fieldDropdownComponent = null;
          } else {
            fieldDropdownComponent = (
              <Dropdown
                dataTestid={TEST_ID.FIELD_DROPDOWN}
                selectOnlyOptionAutomatically
                error={
                  showValidation && selectedField == null
                    ? Catalog.requiredField
                    : ''
                }
                label={
                  fieldLabel == null
                    ? Catalog.flows.pointerFieldLabel
                    : fieldLabel
                }
                options={fieldOptions}
                selectedOptionIdx={selectedFieldIndex}
                onChange={({ option }) => {
                  const { outputObject, outputField } = option.payload;

                  if (variable == null) {
                    throw Error(
                      `${cleanedFilename(
                        __filename,
                      )} | Should not occur | variable == null`,
                    );
                  }

                  const changeObject: PointerVariable = {
                    ...variable,
                    field: {
                      name: asPointerName(outputObject, outputField),
                    },
                  };

                  onChange(changeObject);
                }}
              />
            );
          }

          return (
            <>
              {variableDropdownComponent}
              {fieldDropdownComponent}
            </>
          );
        }}
      </FlowContext.Consumer>
    );
  }
}

const findFieldIndexIn = (
  fieldOptions: Array<Option>,
  selectedField: null | { name: string },
): number => {
  if (selectedField == null) return -1;

  return fieldOptions.findIndex(option => option.key === selectedField.name);
};

const findVariableIndexIn = (
  possibleVariables: Array<FlowVariableStashItem>,
  variable: PointerVariable | null,
): number => {
  if (variable == null) return -1;

  return possibleVariables.findIndex(
    possibleVariable => possibleVariable.variableName === variable.name,
  );
};

export const asPointerName = (
  outputObject: FlowOutputObject,
  outputField?: FlowOutputObjectField,
) => {
  if (outputField == null) return outputObject.name;

  return `${outputObject.name}.${outputField.name}`;
};

export default withBaseActionContext<MyProps>(PointerComponent);
