import React from 'react';
import styled, { css } from 'styled-components';
import DatHuisLoading from '~/components/DatHuisLoading';
import Icon, { IconType } from '~/components/Icon';
import SpringContainer from '~/components/SpringContainer';
import { Body } from '~/components/Typography/index';
import { H2 } from '~/components/Typography';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

export type Props = {
  dataTestId?: string;
  /** Header text */
  header: React.ReactNode;
  /** Icon to be rendered before the header text */
  icon?: IconType;
  /** Shows a loading animation */
  loading?: boolean;

  pageDescription?: React.ReactNode;
};

const AppDetailsContainer: React.FC<Props> = ({
  dataTestId,
  header,
  icon = 'file',
  children,
  loading,
  pageDescription,
  ...rest
}) => {
  const details = loading === true ? <DatHuisLoading /> : children;

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Header>
        <StyledIcon name={icon} />
        {header}
      </Header>
      {pageDescription && (
        <Body margin={[null, null, 'l', null]}>{pageDescription}</Body>
      )}
      {details}
    </Container>
  );
};

const Header = styled(H2)<{}>`
  font-size: ${FONT_SIZE_OF_18}px;
  margin-top: 0;
  display: flex;
  align-items: center;
`;

const Container = styled(SpringContainer)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    padding: ${theme.space('l')};
    flex-grow: 1;
  `,
);
const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('s')};
    margin-bottom: 3px;
    font-size: 1.3em;
  `,
);

export default AppDetailsContainer;
