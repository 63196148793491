import { defaultEditorClass } from '~/theme/GlobalStyle/froalaTheme';
import Froala from 'froala-editor/js/froala_editor.min.js';
import {
  HTML_TYPE_ATTRIBUTE_NAME,
  HTML_VARIABLE_NAME_ATTRIBUTE_NAME,
  HTML_FIELD_NAME_ATTRIBUTE_NAME,
  HTML_MAPPING_ID_ATTRIBUTE_NAME,
  HTML_VARIABLE_ATTRIBUTE_NAME,
  HTML_ERROR_ATTRIBUTE_NAME,
} from '~/components/HTMLEditor/constants';

export const getOptions = () => ({
  language: 'nl',
  heightMin: 150,
  heightMax: 600,
  attribution: false,
  linkAlwaysBlank: true,
  charCounterCount: false,
  quickInsertEnabled: false,
  imageDefaultAlign: 'left',
  allowStyle: true,
  tableMultipleStyles: false,
  // imageMaxSize: DOES NOT WORK, ROB IS MAILING SUPPORT
  imageMaxSize: 1024 * 1024 * 5, // 5MB
  imageAddNewLine: true,
  placeholderText: '',
  key: process.env.FROALA_KEY,
  enter: Froala.ENTER_BR,
  editorClass: defaultEditorClass,
  linkList: [],
  linkAutoPrefix: '',
  htmlAllowedAttrs: [
    ...Froala.DEFAULTS.htmlAllowedAttrs,
    HTML_TYPE_ATTRIBUTE_NAME,
    HTML_VARIABLE_NAME_ATTRIBUTE_NAME,
    HTML_FIELD_NAME_ATTRIBUTE_NAME,
    HTML_MAPPING_ID_ATTRIBUTE_NAME,
    HTML_VARIABLE_ATTRIBUTE_NAME,
    HTML_ERROR_ATTRIBUTE_NAME,
    'data-uploadid', // To handle clicks on variables
    'onclick',
  ],
  tableCellStyles: {
    noborder: 'No border',
  },
});
