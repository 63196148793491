import React from 'react';
import styled, { css } from 'styled-components';
import Label from '~/components/EventTimelineV2/components/Block/components/Data/components/Label';
import { Body } from '~/components/Typography/index';
import { TextBlock } from '~/graphql/types';

export type Props = Omit<TextBlock, '__typename'> & {
  dataTestId?: string;
};

const Text: React.FC<Props> = ({ label, value, css }) => (
  <Container css={css}>
    {label && <Label>{label}</Label>}
    <EllipsisContainer size="s">{value}</EllipsisContainer>
  </Container>
);

const Container = styled.div(
  ({ theme }) => css`
    margin: ${theme.space('xxxs')} 0;
  `,
);

const EllipsisContainer = styled(Body)`
  display: inline-flex;
  flex: 1;
  min-width: 0;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
`;

export default Text;
