export enum FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION {
  EQUALS = 'Flow_ConditionPrimitive_Boolean_Eq',
}
export const FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION_LABELS: {
  [key in FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION]: { label: string };
} = Object.freeze({
  [FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS]: {
    label: 'Is gelijk aan',
  },
});
