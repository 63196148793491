import React from 'react';

import cleanedFilename from '~/util/cleanedFilename';

type SubscriptionContextProps = {
  refetchSubscriptionData: () => Promise<any>;
};
const SubscriptionContext = React.createContext<SubscriptionContextProps>({
  refetchSubscriptionData: () => {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | calling refetchSubscriptionData before initialized`,
    );
  },
});

export default SubscriptionContext;
