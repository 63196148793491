import { BaseActionProps } from '../baseTypes.flow';
import { DEFAULT_ALL_SHOULD_BE_TRUE } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/expressionHelpers';

import { FLOW_ACTION_TYPE } from '../constants';
import { emptyFlowConditionTimeElapsed } from '../Base/FlowCondition/TimeElapsed';
import { emptyNumberPrimitiveParameterValue } from '../Base';
import { WaitAction } from './types.flow';
import { generateHash } from '~/util/react';

const defaultProps = (baseActionProps: BaseActionProps): WaitAction => {
  const condition = {
    ...emptyFlowConditionTimeElapsed(),
    day: emptyNumberPrimitiveParameterValue(3),
  };
  return {
    ...baseActionProps,
    type: FLOW_ACTION_TYPE.WAIT,
    conditionList: {
      allShouldBeTrue: DEFAULT_ALL_SHOULD_BE_TRUE,
      conditions: [
        { ...condition, id: generateHash(`${JSON.stringify(condition)}-0`) },
      ],
    },
  };
};

export default defaultProps;
