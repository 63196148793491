import gql from 'graphql-tag';

export default gql`
  mutation UpdateProfilePicture(
    $accountId: ID!
    $identityId: ID!
    $filename: String!
  ) {
    updateProfilePicture(
      accountId: $accountId
      identityId: $identityId
      filename: $filename
    ) {
      id
      name
      img {
        medium
      }
    }
  }
`;
