import { isNil } from 'ramda';
import { NotificationSettings } from '~/graphql/types';
import { EmailNotificationsFormData } from '..';

export const asEmailNotificationsFormData = (
  notificationSettings: NotificationSettings,
): EmailNotificationsFormData => {
  const {
    dailyDigest,
    task: { newUnassigned, gotAssignedToMyOffices, gotAssignedToMe },
    appValuationReport: {
      forContactAssignedToMe,
      forContactAssignedToMyOffices,
      newContact,
    },
    appBBWaardecheck,
    appVBOWaardecheck,
  } = notificationSettings;

  return {
    dailyDigest: !isNil(dailyDigest) ? dailyDigest : true,

    // TASK
    taskNewUnassigned: !isNil(newUnassigned) ? newUnassigned : true,
    taskGotAssignedToMyOffices: !isNil(gotAssignedToMyOffices)
      ? gotAssignedToMyOffices
      : true,
    taskGotAssignedToMe: !isNil(gotAssignedToMe) ? gotAssignedToMe : true,

    // VALUE_RAPORT
    valueReportAppNewContact: !isNil(newContact) ? newContact : true,
    valueReportAppForContactAssignedToMyOffices: !isNil(
      forContactAssignedToMyOffices,
    )
      ? forContactAssignedToMyOffices
      : true,
    valueReportAppForContactAssignedToMe: !isNil(forContactAssignedToMe)
      ? forContactAssignedToMe
      : true,

    // BB_WAARDECHECK
    appBBWaardecheckNewContact: !isNil(appBBWaardecheck?.newContact)
      ? appBBWaardecheck.newContact
      : true,
    appBBWaardecheckForContactAssignedToMyOffices: !isNil(
      appBBWaardecheck?.forContactAssignedToMyOffices,
    )
      ? appBBWaardecheck.forContactAssignedToMyOffices
      : true,
    appBBWaardecheckForContactAssignedToMe: !isNil(
      appBBWaardecheck?.forContactAssignedToMe,
    )
      ? appBBWaardecheck.forContactAssignedToMe
      : true,

    // VBO_WAARDECHECK
    appVBOWaardecheckNewContact: !isNil(appVBOWaardecheck?.newContact)
      ? appVBOWaardecheck.newContact
      : true,

    appVBOWaardecheckForContactAssignedToMyOffices: !isNil(
      appVBOWaardecheck?.forContactAssignedToMyOffices,
    )
      ? appVBOWaardecheck.forContactAssignedToMyOffices
      : true,
    appVBOWaardecheckForContactAssignedToMe: !isNil(
      appVBOWaardecheck?.forContactAssignedToMe,
    )
      ? appVBOWaardecheck.forContactAssignedToMe
      : true,

    /** These fields are not implemented on the backend, only added to call the update mutation successfully */
    contactGotAssignedToMyOffices: false,
    contactGotAssignedToMe: false,
  };
};

export const emailNotificationsFormDataToMutation = (
  formNewData: EmailNotificationsFormData,
) => {
  const {
    dailyDigest,
    taskGotAssignedToMe,
    taskNewUnassigned,
    taskGotAssignedToMyOffices,
    valueReportAppForContactAssignedToMe,
    valueReportAppNewContact,
    valueReportAppForContactAssignedToMyOffices,
    appBBWaardecheckNewContact,
    appBBWaardecheckForContactAssignedToMyOffices,
    appBBWaardecheckForContactAssignedToMe,
    appVBOWaardecheckNewContact,
    appVBOWaardecheckForContactAssignedToMyOffices,
    appVBOWaardecheckForContactAssignedToMe,
  } = formNewData;

  return {
    notificationSettings: {
      dailyDigest,
      task: {
        newUnassigned: taskNewUnassigned,
        gotAssignedToMyOffices: taskGotAssignedToMyOffices,
        gotAssignedToMe: taskGotAssignedToMe,
      },
      appValuationReport: {
        newContact: valueReportAppNewContact,
        forContactAssignedToMyOffices:
          valueReportAppForContactAssignedToMyOffices,
        forContactAssignedToMe: valueReportAppForContactAssignedToMe,
      },
      appBBWaardecheck: {
        newContact: appBBWaardecheckNewContact,
        forContactAssignedToMyOffices:
          appBBWaardecheckForContactAssignedToMyOffices,
        forContactAssignedToMe: appBBWaardecheckForContactAssignedToMe,
      },

      appVBOWaardecheck: {
        newContact: appVBOWaardecheckNewContact,
        forContactAssignedToMyOffices:
          appVBOWaardecheckForContactAssignedToMyOffices,
        forContactAssignedToMe: appVBOWaardecheckForContactAssignedToMe,
      },

      contact: {
        gotAssignedToMyOffices: false,
        gotAssignedToMe: false,
      },
    },
  };
};
