import gql from 'graphql-tag';

export default gql`
  fragment BBWaardecheckFullComparablePropertyFields on BBWaardecheck_FullComparableProperty {
    __typename
    id
    mainPhoto
    address {
      ... on BBWaardecheck_ComparablePropertyAddress {
        street
        city
        houseNumber
        houseNumberAddition
        postcode
        location {
          lat
          lng
        }
      }
    }
    livingAreaSqm
    bedrooms
    propertyType
    matchingPercentage
    transactionDate
    transactionPrice
    indexedTransactionPrice
  }
`;
