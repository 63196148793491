// https://github.com/froala/wysiwyg-editor/issues/1372
// Froala adds certain things to the html, clean it up before
const cleanupRegExp = /fr-original-style="[^"]*?"\s*/g;
const cleanupClassRegExp = /fr-original-class="[^"]*?"\s*/g;
const cleanupBorderBoxRegExp = /<br\s*style="box-sizing:\s*border-box;"/g;
const cleanupFrDataRegExp = /data-fr[^"]*?"[^"]*?"\s/g;
const cleanupAnimationRegExp = /animation:[^;]*?;[\s]*/g;

const cleanupHTML = (html: string): string => {
  const withoutOriginalStyles = html.replace(cleanupRegExp, '');
  const withoutOriginalClasses = withoutOriginalStyles.replace(
    cleanupClassRegExp,
    '',
  );
  const withoutBrBorderBoxStyles = withoutOriginalClasses.replace(
    cleanupBorderBoxRegExp,
    '<br',
  );
  const withoutFrData = withoutBrBorderBoxStyles.replace(
    cleanupFrDataRegExp,
    '',
  );
  const withoutAnimationData = withoutFrData.replace(
    cleanupAnimationRegExp,
    '',
  );

  return withoutAnimationData;
};

export default cleanupHTML;
