import React from 'react';
import styled, { css } from 'styled-components';

import { INPUT_ELEMENT_BORDER } from '~/components/Inputs/InputElement';
import {
  calculateFontSize,
  calculateSpaceInsideComponent,
} from '../util/getSizeCalculation';

type Props = {
  children: React.ReactNode;

  /** If the button should be disabled */
  disabled?: boolean;

  /** Renders a small button */
  small?: boolean;

  /** Renders a large button */
  large?: boolean;

  /** String id for test */
  dataTestId?: string;

  /** Renders a button in the danger color */
  danger?: boolean;

  /** Renders a button in the danger color when hovered*/
  dangerOnHover?: boolean;

  /** Renders a button in the success color */
  success?: boolean;

  /** Renders a button in the success color when hovered*/
  successOnHover?: boolean;

  /** Renders a button in the pending color */
  pending?: boolean;

  /** Renders a button in the pending color when hovered*/
  pendingOnHover?: boolean;

  /** Renders a button in the accent color */
  accent?: boolean;

  /** Renders a button in the accent color when hovered*/
  accentOnHover?: boolean;
  onClick?: () => void;
};

const InputGroupButton = ({
  children,
  dataTestId,
  onClick,
  disabled,
  small,
  large,
  accent,
  accentOnHover,
  danger,
  dangerOnHover,
  success,
  successOnHover,
  pending,
  pendingOnHover,
  ...rest
}: Props) => (
  <Container
    $small={small}
    $large={large}
    $accent={accent}
    $accentOnHover={accentOnHover}
    $danger={danger}
    $dangerOnHover={dangerOnHover}
    $success={success}
    $successOnHover={successOnHover}
    $pending={pending}
    $pendingOnHover={pendingOnHover}
    disabled={disabled}
    data-testid={dataTestId}
    $hasOnClick={onClick != null}
    onClick={e => {
      e.stopPropagation();

      if (!disabled && onClick) {
        onClick();
      }
    }}
    {...rest}
  >
    {children}
  </Container>
);

type ContainerProps = {
  /** If the button should be disabled */
  disabled?: boolean;

  /** Renders a small button */
  $small?: boolean;

  /** Renders a large button */
  $large?: boolean;

  /** Renders a button in the accent color */
  $accent?: boolean;

  /** Renders a button in the accent color when hovered*/
  $accentOnHover?: boolean;

  /** Renders a button in the danger color */
  $danger?: boolean;

  /** Renders a button in the danger color when hovered*/
  $dangerOnHover?: boolean;

  /** Renders a button in the success color */
  $success?: boolean;

  /** Renders a button in the success color when hovered*/
  $successOnHover?: boolean;

  /** Renders a button in the pending color */
  $pending?: boolean;

  /** Renders a button in the pending color when hovered*/
  $pendingOnHover?: boolean;

  /** If the component has an onClick function */
  $hasOnClick: boolean;
};
const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  align-self: flex-end;
  transition: color 0.3s, border 0.3s;
  cursor: default;

  ${({ $hasOnClick, disabled }) =>
    $hasOnClick &&
    !disabled &&
    css`
      cursor: pointer;
    `}

  ${({ theme, $small, $large }) => css`
    padding: ${calculateSpaceInsideComponent(theme, $small, $large)}px;
    font-size: ${calculateFontSize(theme, $small, $large)}px;
  `}

  ${({ theme, $danger, $success, $pending, $accent, disabled }) => {
    let borderColor = theme.color('grey');
    let fontColor: string | null = null;
    let backgroundColor: string | null = null;

    if (!disabled) {
      if ($danger) {
        borderColor = theme.color('danger');
        backgroundColor = theme.color('danger');
        fontColor = theme.color('white');
      }
      if ($success) {
        borderColor = theme.color('success');
        backgroundColor = theme.color('success');
        fontColor = theme.color('white');
      }
      if ($pending) {
        borderColor = theme.color('primary', 'light');
        backgroundColor = theme.color('primary', 'light');
        fontColor = theme.color('white');
      }
      if ($accent) {
        borderColor = theme.color('accent');
        backgroundColor = theme.color('accent');
        fontColor = theme.color('white');
      }
    } else {
      borderColor = theme.color('grey');
      fontColor = theme.color('grey');
    }

    return css`
      border-radius: ${theme.getTokens().border.radius.s};
      border: ${INPUT_ELEMENT_BORDER} solid ${borderColor};
      background-color: ${backgroundColor};
      color: ${fontColor};
    `;
  }}

  ${({
    theme,
    $dangerOnHover,
    $successOnHover,
    $pendingOnHover,
    $accentOnHover,
    disabled,
  }) => {
    let borderColor = theme.color('grey');
    let fontColor: string | null = null;
    let backgroundColor: string | null = null;

    if (!disabled) {
      if ($dangerOnHover) {
        borderColor = theme.color('danger');
        fontColor = theme.color('white');
        backgroundColor = theme.color('danger');
      }
      if ($successOnHover) {
        borderColor = theme.color('success');
        fontColor = theme.color('white');
        backgroundColor = theme.color('success');
      }
      if ($pendingOnHover) {
        borderColor = theme.color('primary', 'light');
        fontColor = theme.color('white');
        backgroundColor = theme.color('primary', 'light');
      }
      if ($accentOnHover) {
        borderColor = theme.color('accent');
        fontColor = theme.color('white');
        backgroundColor = theme.color('accent');
      }
    }

    return css`
      &:hover {
        border-radius: ${theme.getTokens().border.radius.s};
        border: ${INPUT_ELEMENT_BORDER} solid ${borderColor};
        background-color: ${backgroundColor};
        color: ${fontColor};
      }
    `;
  }}
`;

export default InputGroupButton;
