import { PointerVariable } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import {
  FlowParameterMappingParameter,
  HandledFlowParameterMapping,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/FlowParameterMapping/types.flow';

import {
  getVariableAndFieldLabel,
  UNKNOWN_FIELD_NAME,
} from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { PARAMETER_VALUE_TYPE } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/constants';
import {
  HTML_TYPE_ATTRIBUTE_NAME,
  HTML_VARIABLE_NAME_ATTRIBUTE_NAME,
  HTML_FIELD_NAME_ATTRIBUTE_NAME,
  HTML_VARIABLE_REGEX,
  HTML_MAPPING_ID_ATTRIBUTE_NAME,
  HTML_VARIABLE_ATTRIBUTE_NAME,
  HTML_ERROR_ATTRIBUTE_NAME,
} from '~/components/HTMLEditor/constants';
import { matchAll } from '~/util/string';
import getNewMappingId from '~/util/getNewMappingId';
import cleanedFilename from '~/util/cleanedFilename';

export const WINDOW_VARIABLE_UPDATER_DICT_NAME = 'dhVariableUpdaters';

/**
 * Gives the html img tag that represents the given parameterVariable.
 *
 * We make it an image because of an error in firefox:
 *   https://github.com/froala/wysiwyg-editor/issues/3763
 *
 * It will add an onclick handler to the global window that will call the component with componentVariableUpdaterId
 * And it will add data attributes that represents the variable so we can extract them later
 *
 * To get the variable back you can call: parameterVariableFromHTML
 */
export const htmlToInsertForPointerVariable = (
  parameterVariable: HandledFlowParameterMapping,
  stash: FlowVariableStash,
  componentVariableUpdaterId: string,
  createBase64ImageForVariableText: (text: string) => string,
): string => {
  const { mapping, mappingId } = parameterVariable;

  const { variable } = mapping;

  if (mappingId == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} -> No mapping id in parameterVariable: ${JSON.stringify(
        parameterVariable,
        null,
        2,
      )}`,
    );
  }

  const variableLabel =
    variable == null || variable.field == null
      ? UNKNOWN_FIELD_NAME
      : getVariableAndFieldLabel(stash, variable.name, variable.field.name);
  const imgSrc = createBase64ImageForVariableText(variableLabel);
  const errorAttr = variableLabel === UNKNOWN_FIELD_NAME ? htmlErrorAttr() : '';
  const onClickFunction = htmlOnClickHandler(
    componentVariableUpdaterId,
    mappingId,
  );

  const finalHtml = `<img src="${imgSrc}" class="fr-deletable fr-draggable" ${htmlVariableAttr()} ${errorAttr} ${onClickFunction} ${htmlNameAttr(
    mapping,
  )} ${htmlVarNameAttr(variable)} ${htmlFieldNameAttr(
    variable,
  )} ${htmlMappingIdAttr(mappingId)}>`;

  return finalHtml;
};

/**
 * Grabs all the variables from the given html. The html is the one provided by the editor
 */
export const grabAllVariablesInHtml = (
  html: string,
): Array<HandledFlowParameterMapping> => {
  const variableMatches = matchAll(html, HTML_VARIABLE_REGEX);

  return variableMatches.map(parameterMappingFromHTML);
};

/**
 * Converts the html generated by htmlToInsertForPointerVariable back to a parameterMapping
 */
const parameterMappingFromHTML = (
  html: string,
): HandledFlowParameterMapping => {
  const type = getAttributeValue(HTML_TYPE_ATTRIBUTE_NAME, html);
  const variable = getAttributeValue(HTML_VARIABLE_NAME_ATTRIBUTE_NAME, html);
  const field = getAttributeValue(HTML_FIELD_NAME_ATTRIBUTE_NAME, html);
  const mappingId = getAttributeValue(HTML_MAPPING_ID_ATTRIBUTE_NAME, html);

  if (mappingId == null) {
    throw Error(
      `${cleanedFilename(__filename)} -> Cannot find mappingId in html ${html}`,
    );
  }

  if (
    type !== PARAMETER_VALUE_TYPE.STRING_POINTER &&
    type !== PARAMETER_VALUE_TYPE.BOOLEAN_POINTER &&
    type !== PARAMETER_VALUE_TYPE.NUMBER_POINTER
  ) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} -> Cannot convert variable from html ${html}`,
    );
  }

  return {
    mappingId,
    // @ts-ignore
    mapping: {
      type,
      variable:
        variable == null
          ? null
          : {
              name: variable,
              field:
                field == null
                  ? null
                  : {
                      name: field,
                    },
            },
    },
  };
};

/**
 * The given html will be scanned for variables img tags, any found will be replaced with the {{id}} notation
 */
export const replaceVariableTagsWithHandlebars = (html: string): string =>
  html.replace(HTML_VARIABLE_REGEX, matchedValue => {
    const mappingId = getAttributeValue(
      HTML_MAPPING_ID_ATTRIBUTE_NAME,
      matchedValue,
    );

    if (mappingId == null) {
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} -> replaceVariableTagsWithHandlebars could not find a mappingId in ${matchedValue} for html ${html}`,
      );
    }

    return `{{${mappingId}}}`;
  });

/**
 * The given html will be scanned for variables img tags, any found will get a new mappingId.
 *
 * Used when copying html with variables in it, so we don't get 2 variables with the same id.
 */
export const changeMappingIdsForAnyVariables = (html: string): string => {
  const replaced = html.replace(HTML_VARIABLE_REGEX, matchedValue => {
    const prevMappingId = getAttributeValue(
      HTML_MAPPING_ID_ATTRIBUTE_NAME,
      matchedValue,
    );

    if (prevMappingId == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} >> changeMappingIdsForAnyVariables => The prevMappingId cannot be found in ${matchedValue}`,
      );
    }
    const newMappingId = getNewMappingId();

    return matchedValue.replace(new RegExp(prevMappingId, 'g'), newMappingId);
  });

  return replaced;
};

/**
 * Changes any variableUpdaterIds in the html to the given one!
 */
const variableUpdaterIdRegex = new RegExp(
  `${WINDOW_VARIABLE_UPDATER_DICT_NAME}\\['.*?'\\]`,
  'g',
);
export const changeVariableUpdaterIds = (
  html: string,
  newVariableUpdaterId: string,
): string => {
  const replaced = html.replace(
    variableUpdaterIdRegex,
    () => `${WINDOW_VARIABLE_UPDATER_DICT_NAME}['${newVariableUpdaterId}']`,
  );

  return replaced;
};
export const hasVariableUpdaterIdsOtherThan = (
  html: string,
  variableUpdaterId: string,
): boolean => {
  const variableUpdaterFunctionStrings = matchAll(html, variableUpdaterIdRegex);

  if (variableUpdaterFunctionStrings.length === 0) return false;

  variableUpdaterFunctionStrings.forEach(variableUpdaterFunctionString => {
    if (!variableUpdaterFunctionString.includes(variableUpdaterId)) {
      return false;
    }

    return;
  });

  return true;
};

/**
 * The given html will be scanned for variables and any found will get the class fr-draggable added to it
 */
export const addDraggableClassToVariables = (html: string): string => {
  const replaced = html.replace(HTML_VARIABLE_REGEX, matchedValue => {
    if (new RegExp(/class="/g).test(matchedValue)) {
      // Check that there are no classes in the variable string. Froala should have removed them.
      throw Error(
        `${cleanedFilename(
          __filename,
        )} >> addDraggableClassToVariables -> [Should not occur]: Found a class in the variable html to add classes to: ${matchedValue}`,
      );
    }

    const variableIdentifierRegex = new RegExp(htmlVariableAttr(), 'g');
    const withDraggable = matchedValue.replace(
      variableIdentifierRegex,
      matchedValue => `${matchedValue} class="fr-draggable" `,
    );

    return withDraggable;
  });

  return replaced;
};

/***** HTML CONVERSION HELPER FUNCTIONS *****/
const htmlOnClickHandler = (
  componentVariableUpdaterId: string,
  variableId: string,
) =>
  `onclick="${WINDOW_VARIABLE_UPDATER_DICT_NAME}['${componentVariableUpdaterId}'](this, '${variableId}')"`;
const htmlVariableAttr = () =>
  asHTMLAttribute(HTML_VARIABLE_ATTRIBUTE_NAME, 'true');
const htmlErrorAttr = () => asHTMLAttribute(HTML_ERROR_ATTRIBUTE_NAME, 'true');
const htmlFieldNameAttr = (variable: PointerVariable | null | undefined) =>
  asHTMLAttribute(
    HTML_FIELD_NAME_ATTRIBUTE_NAME,
    variable == null || variable.field == null ? '' : variable.field.name,
  );
const htmlVarNameAttr = (variable: PointerVariable | null | undefined) =>
  asHTMLAttribute(
    HTML_VARIABLE_NAME_ATTRIBUTE_NAME,
    variable == null ? '' : variable.name,
  );
const htmlNameAttr = (mapping: FlowParameterMappingParameter) =>
  asHTMLAttribute(HTML_TYPE_ATTRIBUTE_NAME, mapping.type);
const htmlMappingIdAttr = (mappingId: string) =>
  asHTMLAttribute(HTML_MAPPING_ID_ATTRIBUTE_NAME, mappingId);
const asHTMLAttribute = (attrName: string, attrValue: string) =>
  `${attrName}="${attrValue}"`;
const getAttributeGrabRegex = attr => new RegExp(`${attr}=".*?"`);
export const getAttributeValue = (
  attr: string,
  html: string,
): string | null => {
  const reg = getAttributeGrabRegex(attr);

  const fromHtml = html.match(reg);

  if (fromHtml == null || fromHtml[0] == null) return null;

  // attrname="<whatweneed>"
  return fromHtml[0].slice(attr.length + 2, fromHtml[0].length - 1);
};
