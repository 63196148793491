import { Plan, BillingCycle } from '~/graphql/types';

import { capitalizeFirstLetter } from '~/util/string';
import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

export const subscriptionPlanLabel = (subscriptionPlan: Plan): string =>
  capitalizeFirstLetter(subscriptionPlan.toLowerCase());

export const billingCycleLabel = (billingCycle: BillingCycle): string => {
  if (billingCycle === BillingCycle.Monthly) {
    return 'maandelijks';
  } else if (billingCycle === BillingCycle.Yearly) {
    return 'jaarlijks';
  }

  reportErrorToTracker(
    `${cleanedFilename(
      __filename,
    )} >> billingCycleLabel | Should not occur | unknown billingCycle ${billingCycle}`,
  );
  return '';
};

export const billingCycleWithSubscriptionLabel = (
  billingCycle: BillingCycle,
): string => {
  if (billingCycle === BillingCycle.Monthly) {
    return 'maandabonnement';
  } else if (billingCycle === BillingCycle.Yearly) {
    return 'jaarabonnement';
  }

  reportErrorToTracker(
    `${cleanedFilename(
      __filename,
    )} >> billingCycleWithSubscriptionLabel | Should not occur | unknown billingCycle ${billingCycle}`,
  );
  return '';
};
