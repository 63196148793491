import React from 'react';
import styled from 'styled-components';

import ColorPicker from '~/components/ColorPicker/index';

type Props = {
  /** Text to color picker label */
  title: string;

  /** RBG | string value for color */
  color?: string | null;

  /** Field value */
  name: string;

  /** Name for test id  */
  testName: string;

  /** Change color callback */
  onChange: (color: string) => void;
  className?: any;

  /** Disable value */
  disabled?: boolean;
};
type State = {};

class ChangeColorBlock extends React.Component<Props, State> {
  shouldComponentUpdate(nextProps: Props) {
    return (
      nextProps.color !== this.props.color ||
      nextProps.disabled !== this.props.disabled
    );
  }

  render() {
    const { disabled, title, className, name, color, testName, onChange } =
      this.props;

    return (
      <Container className={className}>
        <TitleContainer>{title}</TitleContainer>
        <ColorPicker
          initialColor={color ?? '#ddd'}
          onChange={onChange}
          disabled={disabled}
          dataTestid={`picker_${testName}-${name}`}
        />
      </Container>
    );
  }
}

export default ChangeColorBlock;

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space('m')};
  flex-basis: 150px;
`;

const TitleContainer = styled.div<{}>`
  margin-bottom: ${({ theme }) => theme.space('base')};
  width: 150px;
`;
