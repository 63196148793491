import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import { Theme } from '~/theme';
import { Task } from '~/scenes/Tasks/types';
import { UpdateTaskMutationVariables, TaskStatus } from '~/graphql/types';

import { taskGroupTypeForDueDate } from '~/util/task';
import { RoundLabel } from '../TaskList.style';
import TaskInformationBlock from './TaskInformationBlock';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Icon from '~/components/Icon';

type Props = {
  /** The task to show: { dueDate, type, status, descriptionSnippet, officeId?, userId?, Contact? } */
  task: Task;

  /** If the task is not part of the list anymore. So animate removal! */
  isRemovedFromList?: boolean;

  /** If this task is rendered in the completed list */
  isCompleted: boolean;

  /** If the task is in the loading state (mutation is running) */
  loading: boolean;

  /** What to do when the task is clicked */
  onOpenTask: (task: Task) => any;

  /** To call when the task is changed */
  onTaskChange: (variables: UpdateTaskMutationVariables) => any;
};

const TaskInList: React.FC<Props> = ({
  isRemovedFromList,
  isCompleted,
  loading,
  task,
  onOpenTask,
  onTaskChange,
}) => {
  const theme = useTheme();
  const account = useCurrentAccount();

  const { id, dueDate } = task;
  // For cypress, if a task is deleted, then also mangle the id so it is not seen as in the DOM
  const updatedId = isRemovedFromList ? `removed-${id}` : id;

  return (
    <div>
      <RoundLabel
        data-objectid={`round-button-${task.id}`}
        isCompleted={isCompleted}
        isRemovedFromList={isRemovedFromList}
        onClick={() => {
          if (isRemovedFromList || loading || isCompleted) return;

          onTaskChange(variablesForUpdate(account.id, isCompleted, task.id));
        }}
      >
        {iconForType(loading, theme)}
      </RoundLabel>
      <Container
        data-testid="task-overview-list-item"
        data-isremovedfromlist={isRemovedFromList ? 'true' : 'false'}
        data-objectid={updatedId}
        data-taskgrouptype={taskGroupTypeForDueDate(dueDate)}
        onClick={() => {
          if (isRemovedFromList || loading) return;
          onOpenTask(task);
        }}
      >
        <TaskInformationBlock
          task={task}
          isCompleted={isCompleted}
          loading={loading}
          disabled={Boolean(isRemovedFromList)}
          onTaskChange={onTaskChange}
        />
      </Container>
    </div>
  );
};

export const variablesForUpdate = (
  accountId: string,
  isCompleted: boolean,
  taskId: string,
): UpdateTaskMutationVariables => {
  if (isCompleted) {
    return {
      accountId,
      id: taskId,
      update: {
        status: TaskStatus.Open,
      },
    };
  } else {
    return {
      accountId,
      id: taskId,
      update: {
        status: TaskStatus.Closed,
      },
    };
  }
};

const iconForType = (loading: boolean, theme: Theme): React.ReactNode => {
  if (loading) return <Icon name="loading" color={theme.color('white')} />;

  return <Icon name="check" strokeWidth={3} color={theme.color('white')} />;
};

const Container = styled.div<{}>`
  cursor: pointer;
  transition: box-shadow 0.1s ease-out;

  ${({ theme }) => css`
    margin-bottom: ${theme.space('l')};
    background-color: ${theme.color('white')};
    padding: ${theme.space('l')};
    margin-left: ${theme.space('l')};
    border-radius: ${theme.getTokens().border.radius.s};
    width: calc(100% - ${theme.space('l')});
    box-shadow: ${theme.getTokens().boxShadow.card};
    transition-property: transform, box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    :hover {
      transform: translateY(-5px);
      box-shadow: ${theme.getTokens().boxShadow.cardHover};
    }
  `}
`;

export default TaskInList;
