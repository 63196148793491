import React from 'react';

import { SetContactDetailsAction } from './types.flow';
import { ParameterField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';

import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import { SET_CONTACT_DETAILS_FIELD_OPTIONS } from './constants';
import { ParameterFieldComponent } from '~/scenes/Automation/Flows/Actions/Base/';
import asSetContactDetailsInput from './asSetContactDetailsInput';
import { FLOW_ACTION_TYPE } from '../constants';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import ActionExplanationHeader from '../ActionExplanationHeader';
import ActionContentContainer from '../ActionContentContainer';

const text = {
  explanation: 'Contact wijzigen',
};

type MyProps = ActionSubscriberProps & {
  id: string;
  positionString: string;
  initialActionProps: SetContactDetailsAction;
  isHiddenFromView: boolean;
};
type Props = WithValidatorListProps & MyProps;
type State = {
  initialField: ParameterField;
  key: string | null;
  variableStashVersion: string | null;
};
class SetContactDetailsComponent extends React.Component<Props, State> {
  _isMounted = false;
  variableStash: FlowVariableStash = emptyVariableStash();

  getField = () => this.props.initialActionProps.field;

  constructor(props: Props) {
    super(props);

    const { initialActionProps } = props;
    const { field } = initialActionProps;

    this.state = {
      initialField: field,
      key: null,
      variableStashVersion: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.props.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { isHiddenFromView } = nextProps;
    const { variableStashVersion: nextVariableStashVersion } = nextState;
    const { variableStashVersion } = this.state;

    if (nextVariableStashVersion !== variableStashVersion) {
      return true;
    }

    return !isHiddenFromView;
  }

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asSetContactDetailsInput(this.getActionProp(), treeNode, defaults);

  setVariableStash = (newStash: FlowVariableStash) => {
    this.variableStash = newStash;

    if (this._isMounted) {
      this.setState({
        variableStashVersion: newStash.version,
      });
    }
  };

  setGetField = (getFieldFn: () => ParameterField) => {
    this.getField = getFieldFn;
  };

  getActionProp = (): SetContactDetailsAction => {
    const { id } = this.props;

    return {
      id,
      type: FLOW_ACTION_TYPE.SET_CONTACT_DETAILS,
      field: this.getField(),
    };
  };

  render() {
    const { initialField } = this.state;
    const {
      subscribeValidator,
      unsubscribeValidator,
      announceChanges,
      isHiddenFromView,
      positionString,
    } = this.props;
    const action = this.getActionProp();

    return (
      <BaseActionComponent
        positionString={positionString}
        actionLabel={text.explanation}
        action={action}
        variableStash={this.variableStash}
        subscribeValidator={subscribeValidator}
        unsubscribeValidator={unsubscribeValidator}
        isHiddenFromView={isHiddenFromView}
      >
        <ActionExplanationHeader title={text.explanation} />
        <ActionContentContainer>
          <ParameterFieldComponent
            initialField={initialField}
            fieldOptions={Object.keys(SET_CONTACT_DETAILS_FIELD_OPTIONS).map(
              key => SET_CONTACT_DETAILS_FIELD_OPTIONS[key],
            )}
            setGetValueFn={this.setGetField}
            announceChanges={announceChanges}
          />
        </ActionContentContainer>
      </BaseActionComponent>
    );
  }
}

export default withValidatorList<MyProps>(SetContactDetailsComponent);
