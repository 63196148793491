import React from 'react';
import styled, { css } from 'styled-components';
import { Heading4, Variant } from '~/components/Typography/index';
import ColorInput from '~/scenes/Apps/components/ColorInput';

export type ColorList = Array<{
  label: string;
  value: string;
}>;

export type Props = {
  category: React.ReactNode;
  values: ColorList;
};

const OutputColorGrid: React.FC<Props> = ({ category, values = [] }) => (
  <div style={{ width: '100%' }}>
    <Heading4 withoutMargin variant={Variant.primary}>
      {category}
    </Heading4>

    <GridContainer>
      {values.map(({ label, value }) => (
        <ColorInput
          key={`${label}-${value}`}
          label={label}
          readOnly
          value={value}
        />
      ))}
    </GridContainer>
  </div>
);

const GridContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.space('s')};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${theme.space('base')};
    grid-row-gap: ${theme.space('base')};
  `,
);

export default OutputColorGrid;
