import React from 'react';

import ValueReportComponentCard from './components/ValueReportComponentCard';
import { LivingAreaTabProps } from './ValueReport.type';
import { RouteComponentProps } from '@reach/router';
import TextEditor from './components/TextEditor';
import Icon from '~/components/Icon';

type Props = {
  /** Id for tests */
  datatestId: string;

  /**  Tab text for current tab content */
  label: string;

  /** data contains: {
    mainHeader: { text },
    subHeader: { text }
  }
   */
  data: LivingAreaTabProps;

  /** Callback function which call data updating in container state */
  onChange: (objectName: string) => (value: $Object) => void;
} & RouteComponentProps;

const text = {
  title: 'Woonoppervlakte',
  mainText: 'Koptekst',

  subText: 'Subtekst',
};

const LivingAreaTab = ({ data, datatestId, onChange }: Props) => {
  const { mainHeader, subHeader } = data;

  return (
    <ValueReportComponentCard
      datatestId={datatestId}
      title={text.title}
      cardIcon={<Icon name="maximize" />}
    >
      <TextEditor
        testName={'mainHeader'}
        text={mainHeader.text}
        title={text.mainText}
        onChange={value => onChange('mainHeader')({ text: value })}
        name="text"
        disabled={false}
      />
      <TextEditor
        testName={'subHeader'}
        text={subHeader.text}
        title={text.subText}
        onChange={value => onChange('subHeader')({ text: value })}
        name="text"
        disabled={false}
      />
    </ValueReportComponentCard>
  );
};

export default LivingAreaTab;
