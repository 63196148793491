import styled, { css } from 'styled-components';
import { Container as InputContainer } from '~/components/Input';

const TextEditorContainer = styled(InputContainer)(
  ({ theme }) => css`
    width: 100%;
    padding: ${theme.space('xxs')};
    margin-bottom: ${theme.space('m')};
  `,
);

export default TextEditorContainer;
