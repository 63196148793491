import React, { useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import { clone, remove, isNil } from 'ramda';
import styled, { css } from 'styled-components';
import {
  FlowAction,
  Flow___ArgumentFragment,
  Flow___ConditionFragment,
  Flow___InstanceConditionFragment,
  Flow___SubjectFieldConditionFragment,
} from '~/graphql/types';
import Button from '~/components/Button';

import Row from './components/ConditionRow';
import CollapsedRow from './components/CollapsedConditionRow';
import JustificationContainer from '~/components/JustificationContainer';
import TextButton from '~/components/TextButton';
import { ConditionType } from '../Selector/utils/getFieldsByPath';
import { useRecoilValue } from 'recoil';
import { ConditionIssue, issuesByActionId } from '../../../../state/flowIssues';
import TEST_ID from './index.testid';
import { Body } from '~/components/Typography/index';
import OperatorDropdown, {
  FlowConditionConnector,
} from './components/OperatorDropdown';

/**
 * 1. If a condition group changes - the output / instance might change.
 * 2.
 *
 * Action
 *  - Condition
 *    - Condition
 *    - Condition
 *  - Condition
 *  ...
 * Action...
 */

export type Trigger = {
  id: string;
  trigger: Flow___ConditionPartial | null;
  condition: ConditionGroup;
};

type ConditionGroup = {
  connector: FlowConditionConnector;
  conditions: Array<Flow___ConditionPartial | null>;
};

type Props = {
  dataTestId?: string;
  actionId: string;
  connector: FlowConditionConnector;
  triggers: Array<Trigger>;
  onChange: (
    triggers: Array<Trigger>,
    connector: FlowConditionConnector,
  ) => void;
  actionType: FlowAction;
  conditionType?: ConditionType;
  mainRowLabel?: string;
  conditionRowLabel?: string;
};

export const text = {
  addCondition: 'Conditie toevoegen',
  refineCondition: 'Conditie verfijnen',
  done: 'Klaar',
  cancel: 'Afbreken',
  removeBlock: 'Stap verwijderen',
  triggerRowLabel: 'Trigger',
  conditionRowLabel: 'Condities',
};

/**
 * A potentially not yet completed instance condition.
 * Every `args` which is null is not yet filled.
 *
 * @see isCompleteCondition to validate the completeness of the condition
 */
export type Flow___InstanceConditionPartial = Omit<
  Flow___InstanceConditionFragment,
  'args'
> & {
  args: Array<Flow___ArgumentFragment | null>;
};

/**
 * A potentially not yet completed subject field condition.
 * Every `args` which is null is not yet filled.
 *
 * @see isCompleteCondition to validate the completeness of the condition
 */
export type Flow___SubjectFieldConditionPartial = Omit<
  Flow___SubjectFieldConditionFragment,
  'args'
> & {
  args: Array<Flow___ArgumentFragment | null>;
};

/**
 * Conditions can be partial - if they do not have all arguments filled
 */
export type Flow___ConditionPartial =
  | Flow___InstanceConditionPartial
  | Flow___SubjectFieldConditionPartial;

/**
 * Checks whether a partial condition is complete or not.
 */
export const isCompleteCondition = (
  condition: Flow___ConditionPartial,
): condition is Flow___ConditionFragment =>
  !condition.args.some(arg => arg == null);

export const isSubjectCondition = (
  condition?: any,
): condition is Flow___SubjectFieldConditionFragment =>
  condition != null && condition.__typename === 'Flow___SubjectFieldCondition';

const ConditionEditor: React.FC<Props> = ({
  onChange,
  actionId,
  connector: initialConnector,
  actionType,
  triggers: initialTriggers,
  conditionType,
  mainRowLabel = text.triggerRowLabel,
  conditionRowLabel = text.conditionRowLabel,
}) => {
  const issues = useRecoilValue(issuesByActionId(actionId));
  const [triggers, setTriggers] = useState<Array<Trigger>>(initialTriggers);
  const [connector, setConnector] =
    useState<FlowConditionConnector>(initialConnector);

  const [activeRowIndexes, setActiveRowIndexes] = useState<
    [row: number | null, subRow: number | null]
  >([null, null]);

  /** Active variable indexes in a row */
  const [activeVariableIndexes, setActiveVariableIndexes] = useState<
    [row: number | null, subRow: number | null]
  >([null, null]);

  const issueMap = issues.reduce((acc, issue) => {
    if (issue.type === 'ConditionIssue')
      acc[issue.id] = [...(acc[issue.id] || []), issue];
    return acc;
  }, {} as Record<string, Array<ConditionIssue>>);

  useEffect(() => {
    onChange(triggers, connector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggers, connector]);

  /**
   * Certain actions only allow conditions - e.g. IfElse
   * We disable triggers in that event.
   */
  const isConditionOnly = actionType === FlowAction.IfElse;

  const onGroupOperatorChange = payload => setConnector(payload);
  const onSubGroupOperatorChange = (payload, triggerIndex) => {
    setTriggers(previousTriggers => {
      const nextTriggers = clone(previousTriggers);
      nextTriggers[triggerIndex].condition.connector = payload;

      return nextTriggers;
    });
  };

  return (
    <div>
      <GroupsContainer>
        {triggers.map(({ id, condition, trigger }, triggerIndex) => {
          const hasIssue = !isNil(issueMap[id]);

          const showGroupOperator = triggers.length > 1 && triggerIndex !== 0;
          const isRowActive =
            activeRowIndexes[0] === triggerIndex &&
            activeRowIndexes[1] === null;

          const hasSubGroup =
            condition.conditions.length > 0 && !isConditionOnly;
          const canAddSubGroup = trigger != null && !isConditionOnly;

          return (
            <React.Fragment key={`${id}-${triggerIndex}`}>
              <GroupContainer
                $hasIssue={hasIssue}
                data-hasissue={hasIssue}
                data-testid={TEST_ID.GROUP_CONTAINER}
              >
                <RowLabel size="s">{mainRowLabel}</RowLabel>
                <JustificationContainer align="center">
                  {isRowActive ? (
                    <RowContainer>
                      <Row
                        disableDelete={triggers.length === 1}
                        actionId={actionId}
                        condition={trigger}
                        actionType={actionType}
                        conditionType={conditionType ?? 'trigger'}
                        // We cannot remove the main trigger from start actions
                        onRemove={
                          actionType === FlowAction.Start
                            ? null
                            : () => {
                                setTriggers(triggers => {
                                  const nextTriggers = remove(
                                    triggerIndex,
                                    1,
                                    triggers,
                                  );
                                  return nextTriggers;
                                });
                              }
                        }
                        onClose={() => setActiveRowIndexes([null, null])}
                        onChange={condition => {
                          setTriggers(triggers => {
                            const nextTriggers = clone(triggers);
                            nextTriggers[triggerIndex].trigger = condition;

                            return nextTriggers;
                          });
                        }}
                        shouldOpen={activeRowIndexes[0] === triggerIndex}
                        activeRepIndex={activeVariableIndexes[0]}
                      >
                        <OperatorDropdown
                          onChange={onGroupOperatorChange}
                          value={connector}
                          hasIssue={hasIssue}
                          shouldShow={showGroupOperator}
                          dataTestId={TEST_ID.GROUP_OPERATOR}
                        />
                      </Row>
                    </RowContainer>
                  ) : (
                    <>
                      <OperatorDropdown
                        onChange={onGroupOperatorChange}
                        value={connector}
                        hasIssue={hasIssue}
                        shouldShow={showGroupOperator}
                        dataTestId={TEST_ID.GROUP_OPERATOR}
                      />
                      <CollapsedRow
                        condition={trigger}
                        onClick={(e, idx) => {
                          e.stopPropagation();

                          setActiveRowIndexes([triggerIndex || 0, null]);
                          setActiveVariableIndexes([idx || 0, null]);
                        }}
                        issue={
                          issueMap[id]?.find(({ conditionId }) =>
                            isNil(conditionId),
                          ) ?? null
                        }
                        actionId={actionId}
                        dataTestId={TEST_ID.COLLAPSED_ROW}
                      />
                    </>
                  )}
                </JustificationContainer>

                {hasSubGroup && (
                  <JustificationContainer
                    direction="column"
                    margin={['s', null, null, null]}
                    style={{ width: '100%' }}
                  >
                    <RowLabel size="s">{conditionRowLabel}</RowLabel>

                    <SubGroupContainer
                      data-testid={TEST_ID.SUB_GROUP_CONTAINER}
                    >
                      {condition.conditions.map((cond, condIndex) => {
                        const isActiveSubGroup =
                          activeRowIndexes[0] === triggerIndex &&
                          activeRowIndexes[1] === condIndex;

                        const showSubGroupOperator =
                          condition.conditions.length > 1 && condIndex !== 0;

                        const hasSubGroupIssue = !isNil(
                          issueMap[id]?.find(
                            ({ conditionId }) => conditionId === cond?.id,
                          ),
                        );

                        return (
                          <div key={`${id}-${triggerIndex}-${condIndex}`}>
                            {isActiveSubGroup ? (
                              <RowContainer $appearance="condition">
                                <Row
                                  actionId={actionId}
                                  condition={cond}
                                  parentCondition={trigger}
                                  actionType={actionType}
                                  conditionType="condition"
                                  onRemove={() => {
                                    setTriggers(triggers => {
                                      const nextTriggers = clone(triggers);
                                      nextTriggers[
                                        triggerIndex
                                      ].condition.conditions = remove(
                                        condIndex,
                                        1,
                                        nextTriggers[triggerIndex].condition
                                          .conditions,
                                      );

                                      return nextTriggers;
                                    });
                                  }}
                                  onClose={() =>
                                    setActiveRowIndexes([null, null])
                                  }
                                  onChange={condition => {
                                    setTriggers(triggers => {
                                      const nextTriggers = clone(triggers);
                                      nextTriggers[
                                        triggerIndex
                                      ].condition.conditions[condIndex] =
                                        condition;

                                      return nextTriggers;
                                    });
                                  }}
                                  shouldOpen={activeRowIndexes[1] === condIndex}
                                  activeRepIndex={activeVariableIndexes[1]}
                                >
                                  <OperatorDropdown
                                    onChange={option =>
                                      onSubGroupOperatorChange(
                                        option,
                                        triggerIndex,
                                      )
                                    }
                                    value={condition.connector}
                                    hasIssue={hasSubGroupIssue}
                                    shouldShow={showSubGroupOperator}
                                    dataTestId={TEST_ID.SUB_GROUP_OPERATOR}
                                  />
                                </Row>
                              </RowContainer>
                            ) : (
                              <CollapsedRow
                                appearance="condition"
                                parentCondition={trigger}
                                condition={cond}
                                issue={
                                  issueMap[id]?.find(
                                    ({ conditionId }) =>
                                      conditionId === cond?.id,
                                  ) ?? null
                                }
                                onClick={(e, index) => {
                                  e.stopPropagation();

                                  setActiveRowIndexes([
                                    triggerIndex,
                                    condIndex,
                                  ]);
                                  setActiveVariableIndexes([
                                    triggerIndex,
                                    index || 0,
                                  ]);
                                }}
                                actionId={actionId}
                                dataTestId={TEST_ID.COLLAPSED_ROW}
                              >
                                <OperatorDropdown
                                  onChange={option =>
                                    onSubGroupOperatorChange(
                                      option,
                                      triggerIndex,
                                    )
                                  }
                                  value={condition.connector}
                                  hasIssue={hasSubGroupIssue}
                                  shouldShow={showSubGroupOperator}
                                  dataTestId={TEST_ID.SUB_GROUP_OPERATOR}
                                />
                              </CollapsedRow>
                            )}
                          </div>
                        );
                      })}
                    </SubGroupContainer>
                  </JustificationContainer>
                )}

                {canAddSubGroup && (
                  <JustificationContainer
                    justification="start"
                    align="center"
                    margin={['l', null, null, null]}
                  >
                    <TextButton
                      label={text.refineCondition}
                      withoutPadding
                      icon="plus"
                      onClick={e => {
                        e.stopPropagation();

                        setTriggers(previousTriggers => {
                          const nextTriggers = clone(previousTriggers);
                          nextTriggers[triggerIndex].condition.conditions =
                            nextTriggers[
                              triggerIndex
                            ].condition.conditions.concat(null);

                          setActiveRowIndexes([
                            triggerIndex,
                            nextTriggers[triggerIndex].condition.conditions
                              .length - 1,
                          ]);

                          return nextTriggers;
                        });
                      }}
                      dataTestId={TEST_ID.ADD_SUB_GROUP_BUTTON}
                    />
                  </JustificationContainer>
                )}
              </GroupContainer>
            </React.Fragment>
          );
        })}
      </GroupsContainer>

      {actionType !== FlowAction.Start && (
        <div>
          <Button
            ghost
            label={text.addCondition}
            icon="plus"
            onClick={e => {
              e.stopPropagation();
              setTriggers(prev => {
                setActiveRowIndexes([prev.length, null]);

                return [
                  ...prev,
                  {
                    id: uuid(),
                    trigger: null,
                    condition: {
                      connector: connector || FlowConditionConnector.And,
                      conditions: [],
                    },
                  },
                ];
              });
            }}
            dataTestId={TEST_ID.ADD_GROUP_BUTTON}
          />
        </div>
      )}
    </div>
  );
};

const GroupsContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space('base')};
    margin-bottom: ${theme.space('m')};
  `,
);

const GroupContainer = styled.div<{ $hasIssue: boolean }>(
  ({ theme, $hasIssue }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('m')};
    transition: all 0.3s ease-out;
    background-color: ${theme.color('warning', 'light')};

    ${$hasIssue === true &&
    css`
      background-color: ${theme.color('danger', 'translucent')};
      border-left: ${theme.spacing('xxs')} solid ${theme.color('danger')};
    `}
  `,
);

const RowLabel = styled(Body)`
  cursor: default;
  user-select: none;
`;

const RowContainer = styled.div<{
  $appearance?: ConditionType;
}>(
  ({ theme, $appearance = 'trigger' }) => css`
    width: 100%;
    padding: ${$appearance === 'trigger'
      ? '0'
      : `${theme.space('s')} ${theme.space('m')}`};

    background-color: ${$appearance === 'trigger'
      ? 'transparent'
      : theme.color('white')};
  `,
);

const SubGroupContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space('xxs')};
    width: 100%;
  `,
);

export default ConditionEditor;
