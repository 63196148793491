import React from 'react';
import styled, { css } from 'styled-components';
import CopyCode from '~/components/CopyCode';
import GoToPath from '~/components/GoToPath';
import { Heading4, Variant } from '~/components/Typography/index';

import { text } from '../../index';

export type Props = {
  zapierApiKey: string;
};

const GetApiKey: React.FC<Props> = ({ zapierApiKey }) => (
  <>
    <CopyCode label={text.codeLabel} code={zapierApiKey} />
    <Heading variant={Variant.primary}>{text.secondStep}</Heading>
    <GoToPath path={text.path} label={text.pathLabel} link={text.link} />
  </>
);

const Heading = styled(Heading4)<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('l')};
    margin-bottom: ${theme.space('s')};
  `,
);

export default GetApiKey;
