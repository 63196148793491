import React from 'react';

import { Signature, UserRole, UserStatus } from '~/graphql/types';
import { ImgDefinition } from '~/graphql/types.client.flow';

import { UserDetailsForm } from '~/components';
import { UserDetailsFormData } from '..';

export type OfficeInList = {
  address?: {
    addition?: string;
    city: string;
    houseNumber: number;
    postcode: string;
    street: string;
  };
  email?: string;
  id: string;
  name: string;
  phone?: string;
  img?: ImgDefinition;
  relation?: {
    mainOffice: boolean;
    role: UserRole;
    status: UserStatus;
  };
};

type UserFormDataProps = {
  email: string;
  phone: string;
  cachedOfficeId?: string | null;
};
type Props = {
  onUserDetailsFormSubmit: (formData: UserDetailsFormData) => void;
  userFormData: UserFormDataProps;
  officesList: Array<OfficeInList>;
  initialUserDetailsValues: UserDetailsFormData;
  showDeleteModal: (value: string) => void;
  /** Only true on profile page to enable delete button for both user roles */
  mayEdit: boolean;
  /** To be able to test that polling has stopped */
  dataIsPolling: boolean;
  refetch: () => void;
  signature: Signature | null;
  userId: string;
};

const FormContainer: React.FC<Props> = ({
  onUserDetailsFormSubmit,
  userFormData,
  officesList,
  initialUserDetailsValues,
  showDeleteModal,
  mayEdit,
  dataIsPolling,
}) => {
  const { cachedOfficeId } = userFormData;

  const reducedOffices = getOfficesList(officesList, cachedOfficeId);

  const initialUserValues = {
    ...initialUserDetailsValues,
    ...reducedOffices,
  };

  return (
    <UserDetailsForm
      officesList={officesList}
      showDeleteModal={showDeleteModal}
      mayEdit={mayEdit}
      dataIsPolling={dataIsPolling}
      initialValues={initialUserValues}
      onFormSubmit={onUserDetailsFormSubmit}
    />
  );
};

export const getOfficesList = (
  offices: Array<$Object> | undefined,
  cachedOfficeId?: string | null,
): $Object => {
  const returnObject: $Object = { selectedMainOfficeId: null };

  if (!offices || offices.length === 0) return {};

  offices.forEach((item, index) => {
    const fieldNumber = index + 1;

    if (item.relation.mainOffice) {
      returnObject.selectedMainOfficeId = item.id;
    }

    returnObject[`officeName-${fieldNumber}`] = item.name;
    returnObject[`officeRole-${fieldNumber}`] = item.relation.role;
  });

  if (cachedOfficeId) {
    returnObject.selectedMainOfficeId = cachedOfficeId;
  }

  return returnObject;
};

export default FormContainer;
