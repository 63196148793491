import { BbWaardecheckFullComparablePropertyFieldsFragment } from '~/graphql/types';
import createSimpleList from '../createSimpleList';
import formatDate from '../formatDate';
import getProperty from '../getProperty';
import asString from '../asString';
import asSqm from '../asSqm';
import asAddress from '../asAddress';
import { moneyFormatter } from '~/util/money';
import { isNil } from 'ramda';
import { LayoutStyleBlockFE } from '~/components/EventTimelineV2/types';

/**
 * Return comparable properties list for bb waardecheck
 * @param {Array<BbWaardecheckFullComparablePropertyFieldsFragment>} properties - BB waardecheck properties
 * keywords:
 */
const getBbWaardecheckProperties = (
  properties: Array<BbWaardecheckFullComparablePropertyFieldsFragment>,
  type: 'BBWC' | 'Avix' = 'BBWC',
): Array<LayoutStyleBlockFE> =>
  properties.map((property, index) =>
    getProperty({
      isLastCard: index == properties.length - 1,
      blocks: [
        {
          id: '',
          childrenIds: [],
          __typename: 'FlexLayout',
          blocks: [
            {
              __typename: 'ImageBlock',
              id: '',
              imageValue: {
                __typename: 'ImageBlockValue',
                src: property.mainPhoto,
              },
            },
          ],
        },
        ...createSimpleList([
          {
            __typename: 'TextBlock',
            label: 'Adres',
            value: asAddress({
              street: property.address.street,
              houseNumber: property.address.houseNumber,
              houseNumberAddition: property.address.houseNumberAddition,
            }),
          },
          {
            __typename: 'TextBlock',
            label: 'Postcode',
            value: asString(property.address.postcode),
          },
          {
            __typename: 'TextBlock',
            label: 'Woonplaats',
            value: asString(property.address.city),
          },
          {
            __typename: 'TextBlock',
            label: 'Woonoppervlakte',
            value: asSqm(property.livingAreaSqm),
          },
          type === 'BBWC'
            ? {
                __typename: 'TextBlock',
                label: 'Kamers',
                value: asString(property.bedrooms),
              }
            : {},
          {
            __typename: 'TextBlock',
            label: 'Vergelijkbaarheid',
            value: `${property.matchingPercentage}%`,
          },
          {
            __typename: 'TextBlock',
            label: 'Type woning',
            value: asString(property.propertyType),
          },
          type === 'BBWC' && !isNil(property.transactionPrice)
            ? {
                __typename: 'TextBlock',
                label: 'Koopsom',
                value: moneyFormatter(property.transactionPrice),
              }
            : {},
          type === 'Avix' && !isNil(property.indexedTransactionPrice)
            ? {
                __typename: 'TextBlock',
                label: 'Geïndexeerde koopsom',
                value: moneyFormatter(property.indexedTransactionPrice),
              }
            : {},
          {
            __typename: 'TextBlock',
            label: 'Verkocht op',
            value: property.transactionDate
              ? formatDate(property.transactionDate)
              : '-',
          },
        ]),
      ],
    }),
  );

export default getBbWaardecheckProperties;
