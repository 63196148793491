import React, { useState, RefObject, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Loading from '~/components/Loading';

type Props = {
  /** The function to call to load more results */
  fetchMoreFn: () => Promise<any>;

  /** If there are more items in the list */
  hasMore: boolean;

  /** The component(s) to show in the InfiniteScroll */
  children: React.ReactNode;

  /** Use this parent instead of the window / direct parentNode  */
  scrollParentRef?: RefObject<any>;

  /**
   * Whether the component should load the first set of items.
   * Defaults to false.
   */
  initialLoad?: boolean;

  /**
   * Optional threshold: amount of px before triggered
   */
  threshold?: number | Array<number>;
};

const InfiniteScroll: React.FC<Props> = ({
  children,
  hasMore,
  fetchMoreFn,
  initialLoad = false,
  threshold = 0.25,
}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [loading, setLoading] = useState(false);
  const bottom = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!window.IntersectionObserver) return;

    let observer;

    if (bottom.current !== null) {
      observer = new IntersectionObserver(
        entries => {
          setIsIntersecting(entries[0].isIntersecting);
        },
        {
          threshold,
        },
      );
      observer.observe(bottom.current);
    }

    return () => {
      observer && observer.disconnect();
    };
  }, [hasMore, threshold]);

  useEffect(() => {
    if (isIntersecting && hasMore && !loading) {
      const fetchMore = async () => {
        setLoading(true);

        await fetchMoreFn();
        setLoading(false);
      };
      void fetchMore();
    }
  }, [isIntersecting, hasMore, loading, fetchMoreFn]);

  return (
    <div>
      {children}
      {(initialLoad || loading) && <Loading />}
      <Bottom ref={bottom} />
    </div>
  );
};

const Bottom = styled.div(
  ({ theme }) => css`
    height: ${theme.space('base')};
  `,
);

export default InfiniteScroll;
