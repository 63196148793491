import { OptionOf } from '~/components/Inputs/Dropdown';
import { FlowEmailLink } from '~/scenes/Automation/Flows/types.flow';
import { StringPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { mapNotNull } from '~/util/array';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

export enum FLOW_ACTION_TYPE {
  SEND_EMAIL_PLAIN = 'Flow_Action_SendEmail_Plain',
  CREATE_TASK = 'Flow_Action_Task_Create',
  WAIT = 'Flow_Action_Wait',
  IF_ELSE = 'Flow_Action_IfElse',
  ASSIGN_CONTACT = 'Flow_Action_Contact_Assign',
  ADD_CONTACT_TAG = 'Flow_Action_Contact_AddTag',
  DELETE_CONTACT_TAG = 'Flow_Action_Contact_DeleteTag',
  SET_CONTACT_DETAILS = 'Flow_Action_Contact_Details',
  START = 'Flow_Action_Start',
  SENDCONTACT_TO_REALWORKS = 'Flow_Action_Realworks_SendContact',
  ZAPIER_TRIGGER = 'Flow_Action_Zapier_Trigger',
}

const getLabelForType = (
  type: Exclude<FLOW_ACTION_TYPE, FLOW_ACTION_TYPE.START>,
): string => {
  switch (type) {
    case FLOW_ACTION_TYPE.SET_CONTACT_DETAILS:
      return 'Contact wijzigen';
    case FLOW_ACTION_TYPE.WAIT:
      return 'Wacht op conditie';
    case FLOW_ACTION_TYPE.IF_ELSE:
      return 'Controleer op conditie';
    case FLOW_ACTION_TYPE.CREATE_TASK:
      return 'Taak aanmaken';
    case FLOW_ACTION_TYPE.ADD_CONTACT_TAG:
      return 'Tag toevoegen';
    case FLOW_ACTION_TYPE.DELETE_CONTACT_TAG:
      return 'Tag verwijderen';
    case FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN:
      return 'E-mail versturen';
    case FLOW_ACTION_TYPE.ASSIGN_CONTACT:
      return 'Contact toewijzen';
    case FLOW_ACTION_TYPE.SENDCONTACT_TO_REALWORKS:
      return 'Verstuur naar Realworks';
    case FLOW_ACTION_TYPE.ZAPIER_TRIGGER:
      return 'Verstuur naar Zapier';
    default:
      return assertNever(type, cleanedFilename(__filename));
  }
};

export const getAddFlowActionTypeOptions = (): Array<
  OptionOf<FLOW_ACTION_TYPE>
> =>
  mapNotNull(Object.keys(FLOW_ACTION_TYPE), key => {
    const type = FLOW_ACTION_TYPE[key];

    if (type === FLOW_ACTION_TYPE.START) return null;

    return {
      label: getLabelForType(type),
      payload: type,
      key: type,
    };
  });

export const UNSUBSCRIBE_LINK_ON_BACKEND = '<%UNSUB%>';
export const MORTGAGE_LINK_ON_BACKEND = '<%MORTGAGEBROKER%>';
export const UNSUBSCRIBE_LINK_IN_FROALA = 'https://ZalWordenVervangen';
export const MORTGAGE_LINK_IN_FROALA = 'HYPOTHEEK_PILOT';
export const unsubscribeLinkForPointer = (
  pointer: StringPointerParameterValue,
): FlowEmailLink => {
  if (
    pointer.variable == null ||
    pointer.variable.field == null ||
    pointer.variable.field.name == null
  ) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | pointer not complete (${JSON.stringify(
        pointer,
        null,
        2,
      )})`,
    );
  }

  return {
    label: 'Uitschrijven',
    parameterValue: {
      ...pointer,
      variable: {
        ...pointer.variable,
        field: { name: `${pointer.variable.field.name}.unsubscribeLink` },
      },
    },
    link: UNSUBSCRIBE_LINK_ON_BACKEND,
  };
};
