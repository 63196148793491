import React from 'react';
import styled, { css } from 'styled-components';

import { RouterTransitionContextProps } from '~/contexts/RouterTransitionContext';

import { H2 } from '~/components/Typography';
import { Input } from '~/components/Inputs';
import EditButtonsTools from '~/components/Buttons/EditButtonsTools';

import { withRouterTransitionContext } from '~/contexts/RouterTransitionContext';

type BaseProps = RouterTransitionContextProps & {
  name: string;
  loading: boolean;
  hasChangesOnPage?: boolean;
  redacted?: boolean;
};
type FixedProps = BaseProps & {
  edit: false;
};
type EditableProps = BaseProps & {
  // should be editable or read-only
  edit: true;
  updateName: (name: string) => void;

  /** startedEditing and stoppedEditing can be listened to to identify when this component is in edit mode */
  startedEditing?: () => void;
  stoppedEditing?: () => void;

  /** Can listen to the name change if necessary */
  onNameChange?: (name: string) => void;
};
type Props = FixedProps | EditableProps;
type State = {
  name: string;
  value: string;
  valueBeforeEdit: string;
  editMode: boolean;
  submitting: boolean;
  error: string;
  pristine: boolean;
};

class EditableName extends React.Component<Props, State> {
  inputRef: any = React.createRef();

  state: State = {
    name: '',
    value: '',
    valueBeforeEdit: '',
    editMode: false,
    error: '',
    submitting: false,
    pristine: true,
  };

  static getDerivedStateFromProps(nextProps: BaseProps, prevState: State) {
    if (!nextProps.name) return null;

    const noNameSetYet = !prevState.name;
    const nameIsUpdated = prevState.name && nextProps.name !== prevState.name;
    const pristine =
      prevState.value === nextProps.name || prevState.value.length === 0;

    if (noNameSetYet || nameIsUpdated) {
      const value = nextProps.name;

      nextProps.leaveHandler(pristine && !nextProps.hasChangesOnPage);

      return {
        value,
        name: value,
        pristine,
      };
    }
    if (pristine !== prevState.pristine) {
      nextProps.leaveHandler(pristine && !nextProps.hasChangesOnPage);
    }

    return {
      pristine,
    };
  }

  onChange = (e: $Object) => {
    if (e.target != null) {
      const { value } = e.target;
      this.setState({ value }, () => {
        if (this.props.edit && this.props.onNameChange) {
          this.props.onNameChange(value);
        }
      });
    }
  };

  handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.updateName();
    }
  };

  setEditMode = (editMode: boolean) => {
    if (editMode) {
      this.setState(
        state => ({
          valueBeforeEdit: state.value,
          editMode,
        }),
        () => {
          if (this.props.edit && this.props.startedEditing) {
            this.props.startedEditing();
          }

          if (this.inputRef && this.inputRef.focus) {
            this.inputRef.focus();
          }
        },
      );
    } else {
      this.setState(
        state => ({
          value: state.valueBeforeEdit,
          editMode,
        }),
        () => {
          if (this.props.edit && this.props.stoppedEditing) {
            this.props.stoppedEditing();
          }
        },
      );
    }
  };

  updateName = () => {
    const { leaveHandler, hasChangesOnPage } = this.props;
    const name = this.state.value;
    const prevName = this.state.valueBeforeEdit;

    if (name !== prevName && this.props.edit) {
      this.props.updateName && this.props.updateName(name);
    }

    this.setState(
      {
        editMode: false,
      },
      () => {
        if (hasChangesOnPage) {
          leaveHandler(false);
        } else {
          leaveHandler(true);
        }

        if (this.props.edit && this.props.stoppedEditing) {
          this.props.stoppedEditing();
        }
      },
    );
  };

  render() {
    const { edit: editable = false, loading, redacted } = this.props;
    const { value, editMode, error, submitting } = this.state;

    return (
      <Container>
        <NameHeader editMode={editable && editMode} data-redacted={redacted}>
          {editable && editMode ? (
            <Input
              setRef={ref => (this.inputRef = ref)}
              type="text"
              name="name"
              value={value}
              onChange={this.onChange}
              onKeyPress={this.handleKeyPress}
              error={error}
              disabled={submitting || loading}
              data-testid="editable-name-input"
            />
          ) : (
            <span data-testid="editable-name-text">{value}</span>
          )}
        </NameHeader>

        {editable && (
          <EditButtonsTools
            handleSubmit={this.updateName}
            setEditMode={this.setEditMode}
            editMode={editMode}
          />
        )}
      </Container>
    );
  }
}

const Container = styled.div<{}>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    padding-right: ${theme.space('s')};
  `}
`;

type NameHeaderProps = {
  editMode: boolean;
};
const NameHeader = styled(H2)<NameHeaderProps>`
  margin: 0 0.25em 0 0;

  ${({ editMode }) => {
    if (editMode) {
      return `
        flex-grow: 1;
      `;
    }
    return ``;
  }}

  input,
  span {
    padding-right: ${({ theme }) => theme.space('xxs')};
    font: inherit;
    color: inherit;
  }

  span {
    border: ${({ theme }) => theme.getTokens().border.width.s} solid transparent;
    margin-right: 0;
    display: inline-block;
  }
`;

export default withRouterTransitionContext(EditableName);
