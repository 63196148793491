import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useEffect } from 'react';

export default function Redirect() {
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith('/-/') && location.pathname !== '/-/') {
      const nextPath = `/-${location.pathname}${location.search}`;

      void navigate(nextPath, { replace: true });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
