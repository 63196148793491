import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import { SystemSize, SystemFontWeight } from '~/theme';
import Icon from '../Icon';
import { ValidationFunction } from '../Input';
import JustificationContainer from '../JustificationContainer';
import EditingSection from './components/EditingSection';
import TEST_ID from './index.testid';
import Typography, { Props as TypographyProps } from '../Typography/index';
import { ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Props = {
  dataTestId?: string;
  text?: string;
  color?: ThemeColor;
  readonly?: boolean;
  validation?: Array<ValidationFunction>;
  headerSize?: SystemSize;
  iconSize?: SystemSize;
  margin?: Array<SystemSize | null>;
  weight?: SystemFontWeight;
  onSave?: (value: string) => void;
  as?: TypographyProps['as'];
};

const EditableText: React.FC<Props> = ({
  dataTestId,
  text = '',
  onSave,
  color = { group: 'primary', variant: 'base' },
  readonly = false,
  validation = [],
  headerSize = 'xl',
  iconSize = 'l',
  weight = 'semiBold',
  as,
  margin = [],
  ...rest
}) => {
  const [textValue, setTextValue] = useState<string>(text);
  const [editing, setEditing] = useState<boolean>(false);

  const onCancel = () => {
    if (!readonly) {
      setTextValue(text);
      setEditing(false);
    }
  };

  const _onSave = () => {
    if (!isEmpty(textValue) && !readonly) {
      onSave && onSave(textValue);
      setEditing(false);
    }
  };

  useGlobalKeyBinding({ keys: 'enter', callback: _onSave, enabled: editing });
  useGlobalKeyBinding({ keys: 'escape', callback: onCancel, enabled: editing });

  return (
    <Container
      justification="start"
      align="end"
      nonResponsive
      data-testid={dataTestId || TEST_ID.CONTAINER}
      margin={margin}
      size={headerSize}
      {...rest}
    >
      {!editing && (
        <>
          <Typography
            color={color}
            size={headerSize}
            fontWeight={weight}
            withoutMargin
            as={as}
            dataTestId={TEST_ID.NAME}
          >
            {textValue}
          </Typography>

          {!readonly && (
            <StyledIcon
              name="pencil"
              color={color.group}
              strokeWidth={2.5}
              iconSize={iconSize}
              onClick={() => setEditing(true)}
              data-testid={TEST_ID.EDIT_BUTTON}
            />
          )}
        </>
      )}

      {editing && (
        <EditingSection
          value={textValue}
          onSave={_onSave}
          onChange={event => setTextValue(event?.currentTarget?.value ?? '')}
          validation={validation}
        />
      )}
    </Container>
  );
};

const Container = styled(JustificationContainer)<{
  size?: Props['headerSize'];
}>(
  ({ size, theme }) => css`
    width: 100%;
    /** We pass a fixed height so that when editing section closes the page doesn't get jumpy */
    min-height: 1em;
    font-size: ${theme.fs(size || 'm')};
  `,
);

const StyledIcon = styled(Icon)<{ iconSize: SystemSize }>`
  cursor: pointer;
  ${({ theme, iconSize }) => css`
    margin-left: ${theme.space('s')};
    margin-bottom: 0.3em;
    svg {
      font-size: ${theme.fs(iconSize)};
    }
  `}
`;

export default EditableText;
