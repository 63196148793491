import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/JustificationContainer';
import InputLabel from '~/scenes/Apps/components/InputLabel';

export type Props = {
  src: string;
  label: string;
};

const OverviewImage: React.FC<Props> = ({ src, label }) => (
  <JustificationContainer direction="column">
    <InputLabel label={label} />
    <Image src={src} />
  </JustificationContainer>
);

export const IMAGE_CONTAINER_SIZE_IN_PX = '300';

const Image = styled.img(
  ({ theme }) => css`
    width: ${IMAGE_CONTAINER_SIZE_IN_PX}px;
    border-radius: ${theme.getTokens().border.radius.base};
    box-shadow: ${theme.getTokens().boxShadow.around};
  `,
);

export default OverviewImage;
