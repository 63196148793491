import gql from 'graphql-tag';

import FlowParameterMappingFields from './FlowParameterMappingFields';

export default gql`
  fragment FlowParameterTemplateStringFields on Flow_Parameter__TemplateString {
    ... on Flow_Parameter_TemplateString {
      __typename
      template
      mappings {
        ...FlowParameterMappingFields
      }
    }
  }

  ${FlowParameterMappingFields}
`;
