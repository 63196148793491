import LoadableContainer from '../LoadableContainer';
export {
  EmailEventAttachment,
  InsertHTMLFunction,
  SetHTMLFunction,
  GetHTMLFunction,
  InlineImageEditorItem,
  HTMLConverterFunction,
  InteractionFunctions,
  Props,
  State,
  GetInlineEmailAttachmentsForFlowFunctionType,
  GetInlineEmailAttachmentsForEventFunctionType,
  EventInlineAttachmentsInteractionFunctions,
  InlineAttachmentsInteractionFunctions,
  UseInlineAttachmentsType,
  UseEventInlineAttachmentsType,
} from './types.flow';

const LoadableEditor = LoadableContainer({
  loader: () => import('./ClientSideEmailEditor'),
});

export default LoadableEditor;
