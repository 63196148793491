import React from 'react';
import styled from 'styled-components';
import { mergeDeepRight } from 'ramda';
import {
  ViewReportProps,
  PermanentImageLinksTypes,
  DataProps,
} from './ValueReport.type';
import { AppValuationReport_Update } from '~/graphql/types';
import RequestAddress from './RequestAddressTab';
import LivingArea from './LivingAreaTab';
import HouseType from './HouseTypeTab';
import ComparableObjects from './ComparableObjectsTab';
import RequestUserDetails from './RequestUserDetailsTab';
import FinalisedReport from './FinalisedReportTab';
import FooterSaveBar from '~/components/SaveBar/FooterSaveBar';
import { findDifference, deepEquals } from '~/util/object';
import DHRouter from '~/components/DHRouter';
import objectDiff from '~/util/objectDiff';
import { RouteComponentProps } from '@reach/router';
import SideNav from './components/SideNav';
import Design from './Design';
import Privacy from './Privacy';
import AnalyticsPage from './Analytics';
import TestId from './ValueReportDetails.testid';

const text = {
  requestAddressTab: 'Adres',
  livingAreaTab: 'Woonoppervlakte',
  houseTypeTab: 'Huistype',
  comparableObjectsTab: 'Swipen',
  requestUserDetailsTab: 'Contactgegevens',
  finalisedReportTab: 'Rapport',
  privacy: 'Privacy',
  analytics: 'Analytics',
  design: 'Ontwerp',
};

type Props = {
  data: ViewReportProps;
  handleUpdateReport: (update: AppValuationReport_Update) => void;
  loading: boolean;
  isSuccess: boolean;
  onImageError: () => void;
  isPreviewModeOn: boolean;
} & RouteComponentProps;

type State = {
  selectedTabIdx: number;
  data: ViewReportProps;
  dataIsNotEqual: boolean;
  differenceCount: number;
  permanentImageLinks: PermanentImageLinksTypes;
};

class ValueReportDetails extends React.Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    const { logoImage, backgroundImage } = state.data.general;

    const dataStateUpdated = {
      ...state.data,
      general: {
        ...state.data.general,
        logoImage: props.isSuccess ? props.data.general.logoImage : logoImage,
        backgroundImage: props.isSuccess
          ? props.data.general.backgroundImage
          : backgroundImage,
      },
    };

    const { dataIsNotEqual, differenceCount } = findDifference(
      dataStateUpdated,
      props.data,
    );

    if (props.isSuccess) {
      return {
        ...state,
        dataIsNotEqual,
        differenceCount,
        data: {
          ...dataStateUpdated,
        },
      };
    }

    return {
      dataIsNotEqual,
      differenceCount,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      dataIsNotEqual: false,
      differenceCount: 0,
      selectedTabIdx: 0,
      data: {
        ...props.data,
      },
      permanentImageLinks: {
        logoImageLink: null,
        backgroundImageLink: null,
      },
    };
  }

  onChange = (value: $Object) => {
    this.setState(prevState => ({
      ...prevState,
      data: mergeDeepRight(prevState.data, value),
    }));
  };

  onImagePermanentLinkChange = (value: $Object) => {
    this.setState(prevState => ({
      ...prevState,
      permanentImageLinks: { ...prevState.permanentImageLinks, ...value },
    }));
  };

  onSaveDetails = () => {
    const { data } = this.state;
    const obj1 = this.props.data;
    const obj2 = data;
    const differences = {};

    for (const key in obj1) {
      const equal = deepEquals(obj1[key], obj2[key]);

      if (!equal) {
        differences[key] = objectDiff(obj1[key], obj2[key]);
      }
    }

    this.props.handleUpdateReport({
      ...differences,
    });
  };

  onCancelDetails = () => {
    this.setState({
      data: this.props.data,
      permanentImageLinks: {
        logoImageLink: null,
        backgroundImageLink: null,
      },
    });
  };

  onTabChange = (index: number) => {
    this.setState({ selectedTabIdx: index });
  };

  _onChange =
    (name: keyof ViewReportProps, data: DataProps) =>
    (objectName?: string) =>
    (value: $Object) => {
      this.onChange({
        [name]: {
          ...data,
          ...(objectName ? { [objectName]: value } : value),
        },
      });
    };

  render() {
    const { loading, uri } = this.props;
    const { data, dataIsNotEqual, differenceCount, permanentImageLinks } =
      this.state;

    return (
      <LayoutContainer data-testid={TestId.VALUATION_REPORT_CONTAINER}>
        <SideNav uri={uri || '/'} text={text} />
        <MainContainer>
          <DHRouter>
            <Design
              path="/"
              data={data.general}
              permanentImageLinks={permanentImageLinks}
              onImageUploadError={this.props.onImageError}
              onChange={this.onChange}
              onImagePermanentLinkChange={this.onImagePermanentLinkChange}
              name="general"
            />
            <RequestAddress
              path="address"
              data-objectid="requestAddressTab"
              data={data.address}
              onChange={this._onChange('address', data.address)}
              label={text.requestAddressTab}
              datatestId={'page-requestAddressTab'}
            />
            <LivingArea
              path="living-area"
              onChange={this._onChange('livingArea', data.livingArea)}
              data={data.livingArea}
              label={text.livingAreaTab}
              datatestId={'page-livingAreaTab'}
            />
            <HouseType
              path="house-type"
              onChange={this._onChange('houseType', data.houseType)}
              data={data.houseType}
              label={text.houseTypeTab}
              datatestId={'page-houseTypeTab'}
            />
            <ComparableObjects
              path="comparable-objects"
              onChange={this._onChange(
                'comparableProperties',
                data.comparableProperties,
              )}
              data={data.comparableProperties}
              label={text.comparableObjectsTab}
              datatestId={'page-comparableObjectsTab'}
            />
            <RequestUserDetails
              path="request-user-details"
              onChange={this._onChange('userData', data.userData)}
              data={data.userData}
              label={text.requestUserDetailsTab}
              datatestId={'page-requestUserDetailsTab'}
            />
            <FinalisedReport
              path="finalised-report"
              onChange={this._onChange('report', data.report)}
              data={data.report}
              label={text.finalisedReportTab}
              datatestId={'page-finalisedReportTab'}
            />
            <Privacy
              path="privacy"
              data={data.general}
              name="general"
              onChange={this.onChange}
            />
            <AnalyticsPage
              path="analytics"
              data={data.general}
              onChange={this.onChange}
              name="general"
            />
          </DHRouter>
        </MainContainer>
        {dataIsNotEqual && (
          <FooterSaveBar
            onSave={this.onSaveDetails}
            onCancel={this.onCancelDetails}
            numberOfChanges={differenceCount}
            disableSave={loading}
          />
        )}
      </LayoutContainer>
    );
  }
}

const LayoutContainer = styled.div<{}>`
  margin-top: 2rem;
  display: flex;
`;

const MainContainer = styled.div<{}>`
  flex: 1;
`;

export default ValueReportDetails;
