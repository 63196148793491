import React from 'react';

import { Task } from './types';

import cleanedFilename from '~/util/cleanedFilename';

export type TaskModalControllerContextProps = {
  showTaskModal: (task?: Task) => any;
  shouldShowModal: boolean;
  closeModal: () => void;
  initialTaskForModal: Task | null;
  defaultOfficeId: string | null;
  defaultUserId: string | null;
};

export const TaskModalControllerContext =
  React.createContext<TaskModalControllerContextProps>({
    shouldShowModal: false,
    initialTaskForModal: null,
    defaultOfficeId: null,
    defaultUserId: null,
    closeModal: () => {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Called closeModal before the provider initialised!`,
      );
    },
    showTaskModal: () => {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Called showTaskModal before the provider initialised!`,
      );
    },
  });
