import React from 'react';
import { Flow___ConditionPartial } from '../..';
import { InstanceMap } from '../../../Selector/utils/getInstance';
import PositionWrapper, {
  Props as PositionWrapperProps,
} from '../PositionWrapper';
import { Props as SelectorProps } from '../../../Selector';

import DefaultSelectorContainer from './components/Default';
import LinkSelector from './components/LinkSelector';

export type SelectorContainerProps = {
  dataTestId?: string;
  condition: Flow___ConditionPartial | null;
  instanceMap: InstanceMap;
  variable: number;
} & SelectorProps;

const SelectorContainer: React.FC<SelectorContainerProps> = props => {
  const { condition, ...rest } = props;

  if (condition?.__typename === 'Flow___InstanceCondition') {
    switch (condition.type) {
      case 'Condition_Email_LinkClicked': {
        if (rest.variable === 1) {
          return <LinkSelector {...rest} condition={condition} />;
        }

        break;
      }
    }
  }

  return <DefaultSelectorContainer {...props} />;
};

export type PositionedSelectorProps = SelectorContainerProps &
  PositionWrapperProps;

const PositionedSelectorContainer: React.FC<PositionedSelectorProps> =
  props => (
    <PositionWrapper
      onClose={props.onClose}
      rowContainerRef={props.rowContainerRef}
      parentRef={props.parentRef}
    >
      <SelectorContainer {...props} />
    </PositionWrapper>
  );

export default PositionedSelectorContainer;
