import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Checkbox } from '~/components/Inputs';

import ColorPicker from '~/components/ColorPicker';

const DEFAULT_COLOR = '#ddd';
const text = {
  transparent: 'Transparant',
};

type Props = {
  color: string | null | undefined;

  /** Field name */
  title: string;

  /** Name of the field you want to edit */
  name: string;

  /** Test name for the color picker */
  testName: string;

  /** Callback function which call data updating in container state */
  onChange: (value: string | null) => void;
};

const ColorPickerWithTransparentOption = ({
  color,
  title,
  name,
  testName,
  onChange,
}: Props) => {
  const [isTransparent, setIsTransparent] = useState<boolean>(color == null);

  useEffect(() => {
    setIsTransparent(color ? false : true);
  }, [color]);

  return (
    <Container data-testid="transparent-color-picker-container">
      <TitleWrapper>{title}</TitleWrapper>

      <StyledCheckbox
        value={isTransparent}
        onChange={() => {
          setIsTransparent(!isTransparent);
          onChange(!isTransparent ? null : DEFAULT_COLOR);
        }}
        label={text.transparent}
        name="transparentCheckbox"
        dataTestid="transparent-checkbox"
      />

      <ColorPicker
        initialColor={color || DEFAULT_COLOR}
        onChange={onChange}
        disabled={isTransparent}
        dataTestid={`picker_${testName}-${name}`}
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  grid-auto-rows: 1fr;

  ${({ theme }) => css`
    grid-column-gap: ${theme.space('xl')};
    margin-top: ${theme.space('m')};
  `};
`;

const StyledCheckbox = styled(Checkbox)<{}>`
  height: 40px;
  align-items: center;
  justify-content: flex-end;
`;

const TitleWrapper = styled.div<{}>`
  margin-bottom: ${({ theme }) => theme.space('base')};
`;

export default ColorPickerWithTransparentOption;
