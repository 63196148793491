import { FlowConditionEmailLinkClicked } from './types.flow';

import { emptyEmailPointerParameterValue } from '../../FlowParameter/ParameterValue/Email';
import { emptyStringPrimitiveParameterValue } from '../../FlowParameter';
import { FLOW_CONDITION_TYPES } from '../constants';

export default (): Omit<FlowConditionEmailLinkClicked, 'id'> => ({
  type: FLOW_CONDITION_TYPES.EVENT_EMAIL_LINKCLICKED.type,
  email: emptyEmailPointerParameterValue(),
  link: emptyStringPrimitiveParameterValue(),
});
