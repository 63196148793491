import React from 'react';
import styled, { css } from 'styled-components';

import icons from '~/components/Icon/components';
import MasterLink from '../MasterLink';

export type Nav =
  | {
      type: 'link';
      to: string;
      name: string;
      icon?: keyof typeof icons;
      state?: any;
    }
  | {
      type: 'separator';
    }
  | { type: 'heading'; title: string };

export type Props = {
  dataTestId?: string;
  /** The basePath for all navbar items. */
  basePath: string;
  /** Navbar items. All links are absolute, combined with the basePath */
  navbar: Array<Nav>;
  /** Renders an issue counter for the given path */
  issues?: { [path: string]: number };
};

/**
 * Renders the vertical navbar.
 */
const Master: React.FC<Props> = ({
  dataTestId,
  navbar,
  basePath,
  issues = {},
  ...rest
}) => {
  const navbarItems = navbar.map((nav, index) => {
    switch (nav.type) {
      case 'heading':
        return <Heading key={index}>{nav.title}</Heading>;
      case 'separator':
        return <Separator key={index} />;
      case 'link':
        return (
          <NavItem key={index}>
            <MasterLink
              icon={nav.icon}
              name={nav.name}
              to={`${basePath}${nav.to}`}
              state={nav.state || {}}
              issues={issues[nav.to]}
              dataTestId={`navItem${nav.to}`}
            />
          </NavItem>
        );
    }
  });

  return (
    <NavContainer>
      <ItemsContainer data-testid={dataTestId} {...rest}>
        {navbarItems}
      </ItemsContainer>
    </NavContainer>
  );
};

export const NAV_WIDTH = '250px';
const NavContainer = styled.nav<{}>(
  () => css`
    max-width: ${NAV_WIDTH};
    width: 100%;
  `,
);

const ItemsContainer = styled.ul<{}>(
  ({ theme }) => css`
    list-style: none;
    margin: 0;
    padding-left: 0;
    padding-right: ${theme.space('l')};
    height: 100%;
  `,
);

const NavItem = styled.li<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('xxs')};
  `,
);

const Heading = styled(NavItem)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('s')};
    font-weight: bold;
    color: ${theme.color('text')};
    padding-bottom: 0;
    margin-left: ${theme.space('s')};
    border-left: 4px solid transparent;
  `,
);

const Separator = styled(NavItem)<{}>(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.color('grey')};
    margin-bottom: ${theme.space('s')};
    border-left: 4px solid transparent;
  `,
);

export default Master;
