import React from 'react';
import styled, { css } from 'styled-components';

import { SaveBarProps } from './components/SaveBar';

import SaveBar from './components/SaveBar';

type Props = SaveBarProps;
const FooterSaveBar: React.FC<Props> = ({ numberOfChanges, ...rest }) => (
  <Container>
    <SaveBar numberOfChanges={numberOfChanges} {...rest} />
  </Container>
);

const MIN_SAVE_BAR_HEIGHT = 72;
const Container = styled.div<{}>`
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
    min-height: ${MIN_SAVE_BAR_HEIGHT}px;
    background-color: ${theme.color('white')};
    z-index: ${theme.getTokens().zIndex.top};
  `};
`;

export default FooterSaveBar;
