import { arxGroep } from './ARXGroep/appConfig';
import { bbwc } from './BBWaardecheck/appConfig';
import { AppConfig } from './types';
import { funda } from './Funda/appConfig';
import { hypotheekbond } from './Hypotheekbond/appConfig';
import { realworks } from './Realworks/appConfig';
import { trustoo } from './Trustoo/appConfig';
import { valuationReport } from './ValueReport/appConfig';
import { avix } from './VBOWaardecheck/appConfig';
import { woz } from './WOZ/appConfig';
import { zapier } from './Zapier/appConfig';
import { enven } from './Enven/appConfig';
import { susReport } from './SustainabilityReport/appConfig';

const apps: Array<AppConfig> = [
  bbwc,
  avix,
  funda,
  realworks,
  valuationReport,
  zapier,
  hypotheekbond,
  arxGroep,
  trustoo,
  woz,
  enven,
  susReport,
];

export default apps;
