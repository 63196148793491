import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash';
import { Option, SelectedOption } from './Dropdown';
import Icon from '../Icon';
import DropdownListContainer from '../Dropdown/components/DropdownListContainer';

type Props = {
  /** Called when the searchstring changes */
  onSearch: (searchString: string) => any;

  /** The options to show for the current search string */
  options: Array<Option>;

  /** Called with the selected option */
  onSelected: (option: SelectedOption) => any;

  /** Show red border if has some arrors */
  hasError?: boolean;
};
type State = {
  searchString: string;
  dropdownListOpen: boolean;
};
export default class SearchField extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchString: '',
      dropdownListOpen: false,
    };
  }

  handleSearch = debounce((searchString: string) => {
    this.props.onSearch(searchString);
  }, 300);

  inputChanged = (newInputString: string) => {
    this.setState({
      searchString: newInputString,
      dropdownListOpen: !!newInputString,
    });

    this.handleSearch(newInputString);
  };

  optionSelected = (option: SelectedOption) => {
    this.props.onSelected(option);

    this.setState({
      dropdownListOpen: false,
    });
  };

  onHide = () => {
    this.setState({
      dropdownListOpen: false,
    });
  };

  render() {
    const { searchString, dropdownListOpen } = this.state;
    const { options, hasError } = this.props;

    return (
      <Container>
        <InputContainer
          hasError={hasError}
          data-error={hasError}
          data-testid="search-field-container"
        >
          <StyledIcon name="looking-glass" />
          <InputElement
            data-testid="search-field"
            placeholder={'Zoek....'}
            value={searchString}
            onChange={(event: React.SyntheticEvent<any>) => {
              const target = event.target as HTMLInputElement;

              this.inputChanged(target.value);
            }}
          />
        </InputContainer>

        <DropdownListContainer
          options={options}
          dropdownListOpen={dropdownListOpen}
          onChange={this.optionSelected}
          onClose={() =>
            this.setState({
              dropdownListOpen: false,
            })
          }
          onClickOutside={() =>
            this.setState({
              dropdownListOpen: false,
            })
          }
        />
      </Container>
    );
  }
}

const InputElement = styled.input<{}>`
  border: 0;
  background: inherit;

  ${({ theme }) => css`
    margin-left: ${theme.space('s')};
  `};
`;
type InputContainerProps = {
  hasError?: boolean;
};
const InputContainer = styled.div<InputContainerProps>`
  display: inline-flex;
  align-items: center;

  ${({ theme, hasError }) => {
    const borderColor = hasError ? theme.color('danger') : theme.color('grey');
    return css`
      border-bottom: 1px solid ${borderColor};
      padding: ${theme.space('xxs')};
    `;
  }};
`;

const Container = styled.div<{}>`
  position: relative;
`;

const StyledIcon = styled(Icon)<{}>`
  font-size: ${({ theme }) => theme.fontSize('m')};
`;
