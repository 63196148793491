import React from 'react';
import styled, { css } from 'styled-components';

import JustificationContainer from '../JustificationContainer';

export type Props = {
  dataTestId?: string;
};

const Toolbar: React.FC<Props> = ({ dataTestId, children, ...rest }) => (
  <Container data-testid={dataTestId} {...rest}>
    <Inner justification="space-between">{children}</Inner>
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xxs')};
    width: 100%;
  `,
);

const Inner = styled(JustificationContainer)<{}>`
  align-items: center;
`;

export default Toolbar;
