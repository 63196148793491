/**
 *
 * Sorts elements by their index, taking every nth element and iterating until there are no elements left
 * (arr = [0,1,2,3,4,5,6,7,8,9,10],  n = 3) => [0,3,6,9,1,4,7,10,2,5,8]
 *
 */
const sortEveryNth = (
  arr: Array<React.ReactNode>,
  n: number,
): Array<React.ReactNode> => {
  if (!arr) return [];

  const newArr = [] as Array<React.ReactNode>;
  let base = 0;
  const helper = () => {
    while (base < n) {
      for (let i = base; i < arr.length; i += n) {
        newArr.push(arr[i]);
      }
      base += 1;
      helper();
    }
  };

  if (base < n) helper();
  return newArr;
};

export default sortEveryNth;
