import {
  StateStep,
  StateStepWithSubSteps,
} from '~/components/Wizard/context/WizardContext';
import getStepIndexes from '~/hooks/useWizardStep/utils/getStepIndexes';

/**
 * Updates a specified prop of a step in place
 * @param {Partial<StateStep} props - The properties on the step you want to update
 * @param {StateStep} step - The step you want to update
 * @param {Array<StateStep>} steps - The whole collection of steps
 * keywords: update step
 */
const updateStepInPlace = (
  props: Partial<StateStep>,
  step: StateStep,
  steps: Array<StateStep>,
): Array<StateStep> => {
  const [stepIndex, subStepIndex] = getStepIndexes(step.id, steps);

  // We couldn't find this step, very odd shouldn't happen.
  if (stepIndex === null) {
    // eslint-disable-next-line no-console
    console.error(`Unable to find step with ID: ${step.id}`);
    return steps;
  }

  const _steps = [...steps];

  // step is a substep
  if (subStepIndex !== null) {
    const mainStep = _steps[stepIndex] as StateStepWithSubSteps;

    // This if statement will always be true but Typescript doesn't know that..
    mainStep.subSteps[subStepIndex] = {
      ...mainStep.subSteps[subStepIndex],
      ...props,
    };

    return _steps;
  }

  _steps[stepIndex] = {
    ..._steps[stepIndex],
    ...props,
  };

  return _steps;
};
export default updateStepInPlace;
