import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import UserImage from '../UserImage';
import ChevronIcon from '../ChevronIcon';
import DropdownListContainer, {
  Props as DropdownListProps,
} from '~/components/Dropdown/components/DropdownListContainer';

export type Props = {
  dataTestId?: string;
  label: React.ReactNode;
  userImage?: string | null;
  redacted?: boolean;
} & Omit<DropdownListProps, 'dropdownListOpen' | 'onClickOutside' | 'onClose'>;

const DropdownMenu: React.FC<Props> = ({
  dataTestId,
  label,
  userImage,
  onChange,
  redacted,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Container
      data-testid={dataTestId}
      onClick={() => setIsOpen(true)}
      {...rest}
    >
      <ButtonContainer>
        <UserImage userImage={userImage} />
        <Inner isOpen={isOpen}>
          <Label data-testid="header-username" data-redacted={redacted}>
            {label}
          </Label>
          <ChevronIcon clockWise={isOpen ? 180 : 0} />
        </Inner>
      </ButtonContainer>

      <StyledDropdownListContainer
        onChange={v => {
          onChange(v);
          setIsOpen(false);
        }}
        dropdownListOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
        dataTestId="nav-settings-dropdown"
        onClose={() => setIsOpen(false)}
        {...rest}
      />
    </Container>
  );
};

type ButtonProps = {
  isOpen: boolean;
};

const StyledDropdownListContainer = styled(DropdownListContainer)`
  /* Overwrite the default of 50vh of the DropdownList.
     https://app.shortcut.com/dathuis/story/9176/remove-scrollbar-from-usermenu */

  ul {
    max-height: unset;
  }
`;

const Inner = styled.div<ButtonProps>(({ theme, isOpen }) => {
  const lightBlue = theme.color('primary', 'light');
  const textColor = theme.color('text');
  const color = isOpen ? `${lightBlue}` : `${textColor}`;

  return css`
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 100px;
    color: ${color};
    svg {
      stroke: ${color};
    }
    &:hover {
      color: ${lightBlue};
      svg {
        stroke: ${lightBlue};
      }
    }
  `;
});

const Label = styled.div<{}>(
  ({ theme }) => css`
    width: 100%;
    font-size: ${theme.fs('m')};
    margin-right: ${theme.space('base')};
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
  `,
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    height: 100%;
    cursor: pointer;
    font-size: ${theme.fs('m')};
    position: relative;
  `,
);

const ButtonContainer = styled.div<{}>(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 ${theme.space('l')};
  `,
);

export default DropdownMenu;
