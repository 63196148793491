import React from 'react';
import styled, { css } from 'styled-components';

import {
  ContactFiltersLeafFieldArgument__Input,
  ContactFiltersOptionTypeFragment,
  ContactFiltersTypeFragment,
} from '~/graphql/types';
import TypeBlock from './components/TypeBlock';
import { reporter } from '~/hooks/useErrorReporter';

const InputAppearanceComponent: React.FC<{
  onChange: (param: {
    [key: string]: string | number | boolean | null | undefined;
  }) => void;
  input?: ContactFiltersTypeFragment | ContactFiltersOptionTypeFragment;
  arg: ContactFiltersLeafFieldArgument__Input | null | undefined;
  shouldShowValidationError: boolean;
}> = ({ input, onChange, arg, shouldShowValidationError, ...rest }) => {
  if (!input) {
    reporter.captureMessage(
      `Unknown input primitive: ${JSON.stringify(input, null, 2)}`,
      'critical',
    );
    return null;
  }

  return (
    <InputAppearanceContainer {...rest}>
      <TypeBlock
        shouldShowValidationError={shouldShowValidationError}
        input={input}
        value={arg ? arg[input.inputField] : undefined}
        onChange={value => {
          onChange({ [input.inputField]: value });
        }}
      />
    </InputAppearanceContainer>
  );
};
const InputAppearanceContainer = styled.div<{}>`
  display: flex;
  width: 100%;
  align-items: center;
  ${({ theme }) => css`
    margin-bottom: ${theme.space('xxs')};
    margin-right: ${theme.space('xxs')};
  `}
`;

export default InputAppearanceComponent;
