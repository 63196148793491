import gql from 'graphql-tag';

export default gql`
  fragment SessionHydrationUserFields on BaseUser {
    __typename
    id
    email
    ... on User {
      __typename
      name
      phone
      img {
        medium
      }
    }
    ... on PendingUser {
      __typename
      lastInvitationTime
    }
  }
`;
