import React from 'react';
import styled, { css } from 'styled-components';
import {
  FlowTemplates as FlowTemplatesType,
  useGetFlowV2TemplatesQuery,
  useGetFlowTemplatesQuery,
} from '~/graphql/types';
import { isEmpty, sort } from 'ramda';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { AppErrorScreen, Loading } from '~/components';
import MasterDetail from '~/components/MasterDetail';
import { LINK_PREFIX } from '../Wizard';
import TemplateDetails from './components/TemplateDetails';
import groupByCategory from './utils/groupByCategory';
import useErrorReporter from '~/hooks/useErrorReporter';

type Template = FlowTemplatesType & { weight?: number };
export type FlowTemplatesCategory = {
  [key: string]: {
    name: string;
    slug: string;
    templates: Array<Template>;
  };
};

export type Props = {
  onSelect: (template: FlowTemplatesType | null) => void;
  template?: FlowTemplatesType | null;
  isV2?: boolean;
};

export const MOST_USED_CATEGORY_SLUG = 'meest-gebruikt';
export const MOST_USED_CATEGORY_LABEL = 'Meest gebruikt';
export const EMPTY_TEMPLATE_ID = 'empty-flow-template';

const FlowTemplates: React.FC<Props> = ({ onSelect, template }) => {
  const account = useCurrentAccount();
  const reporter = useErrorReporter();
  const {
    loading: loadingV2,
    error: errorV2,
    data: dataV2,
  } = useGetFlowV2TemplatesQuery({
    variables: { accountId: account.id },
  });
  const { loading, error, data } = useGetFlowTemplatesQuery({
    variables: { accountId: account.id },
  });

  const isV2 = location.pathname.includes('/flows/wizard/v2');

  const queryValues = isV2
    ? {
        data: dataV2,
        loading: loadingV2,
        error: errorV2,
        templates: dataV2?.getFlowV2Templates,
      }
    : {
        data,
        loading: loading,
        error: error,
        templates: data?.getFlowTemplates,
      };

  const flowTemplates: Array<Template> = [
    {
      __typename: 'FlowTemplates' as const,
      id: isV2 ? EMPTY_TEMPLATE_ID : '',
      name: 'Nieuwe flow',
      description: 'Bouw je eigen flow',
      categories: [MOST_USED_CATEGORY_LABEL],
      weight: 9999,
      appTypes: [],
    },
    ...(queryValues.templates ?? []),
    // Guard against empty categories
  ].filter(({ categories }) => categories.length !== 0);

  const groupedTemplates: FlowTemplatesCategory | undefined =
    groupByCategory(flowTemplates);

  if (queryValues.loading)
    return (
      <Container>
        <Loading />
      </Container>
    );
  if (
    !queryValues.data ||
    !groupedTemplates ||
    isEmpty(groupedTemplates) ||
    queryValues.error
  ) {
    reporter.captureException(
      queryValues.error ?? new Error('Could not get flow templates'),
      'critical',
    );

    return <AppErrorScreen />;
  }

  const categories = Object.keys(groupedTemplates);
  const sortedCategories = sort((a, b) => a.localeCompare(b), categories);

  const version = isV2 ? '/v2' : '/v1';

  return (
    <Container>
      <StyledMasterDetail
        basePath={LINK_PREFIX}
        navbar={[
          {
            type: 'link',
            // To select the first group by default
            to: `${version}/`,
            name: MOST_USED_CATEGORY_LABEL,
          },
          ...sortedCategories
            .filter(slug => slug !== MOST_USED_CATEGORY_SLUG)
            .map(slug => ({
              type: 'link' as const,
              // To select the first group by default
              to: `${version}/${slug}`,
              name: groupedTemplates[slug].name,
            })),
        ]}
      >
        <TemplateDetails
          default
          path={`/${EMPTY_TEMPLATE_ID}`}
          flowCategories={groupedTemplates}
          onSelect={onSelect}
          templateId={template?.id ?? null}
        />
        <TemplateDetails
          path=":slug"
          flowCategories={groupedTemplates}
          onSelect={onSelect}
          templateId={template?.id ?? null}
        />
      </StyledMasterDetail>
    </Container>
  );
};

const StyledMasterDetail = styled(MasterDetail)(
  ({ theme }) => css`
    min-width: 200px;
    width: 100%;

    border-right: 1px solid ${theme.color('grey')};
    margin-right: ${theme.space('l')};
  `,
);

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.space('l')} 0;
  `,
);

export default FlowTemplates;
