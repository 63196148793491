import { Descendant } from 'slate';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import deserialize from '../deserialize';
import normalizeDescendantsToDocumentFragment from '../normalizeDescendantsToDocumentFragment';
import removeLineBreaksFromEmptyLines from '../removeLineBreaksFromEmptyLines';

/**
 * Parses html, deserializes into Slate format (array of nodes), returns the fragment
 *
 * keywords: html to slate
 */

const parseAndDeserialize = ({
  html,
  markAsPendingImage,
  customElements = [],
}: {
  html: string;
  markAsPendingImage?: boolean;
  customElements?: Array<ELEMENTS>;
}): Array<Descendant> => {
  const lineBreaksRemoved = removeLineBreaksFromEmptyLines(html);

  const parsed = new DOMParser().parseFromString(
    lineBreaksRemoved,
    'text/html',
  );

  const deserialized = deserialize({
    el: parsed.body,
    markAsPendingImage,
    customElements,
    initialValue: html,
  });

  const fragment = normalizeDescendantsToDocumentFragment(deserialized);

  if (!fragment || fragment.length === 0) {
    return EMPTY_EDITOR_VALUE;
  }
  return fragment;
};

export const EMPTY_EDITOR_VALUE: Array<Descendant> = [
  {
    type: ELEMENTS.DIV,
    children: [{ text: '' }],
  },
];

export default parseAndDeserialize;
