import { defaultEditorClass } from '~/theme/GlobalStyle/froalaTheme';
import { OPEN_INSERT_HTML_BUTTON_NAME } from './constants';

const defaultEmailOptions = {
  editorClass: `${defaultEditorClass} froala-email-editor`,
  useClasses: false,
  toolbarButtons: {
    moreText: {
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontSize',
        'textColor',
        'backgroundColor',
        'clearFormatting',
      ],
    },
    moreParagraph: {
      buttons: [
        'alignLeft',
        'alignCenter',
        'formatOLSimple',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'paragraphStyle',
        'lineHeight',
        'outdent',
        'indent',
        'quote',
      ],
    },
    moreRich: {
      buttons: [
        'insertImage',
        'insertLink',
        'specialCharacters',
        'insertHR',
        OPEN_INSERT_HTML_BUTTON_NAME,
      ],
      buttonsVisible: 4,
    },
    moreMisc: {
      buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'help'],
      align: 'right',
      buttonsVisible: 2,
    },
  },
  pasteAllowedStyleProps: ['text-align'],
  imageEditButtons: [
    // 'imageReplace',
    'imageAlign',
    'imageCaption',
    'imageRemove', // '|',
    // 'imageLink',
    // 'linkOpen',
    // 'linkEdit',
    // 'linkRemove',
    '-',
    'imageDisplay',
    'imageStyle',
    'imageAlt',
    'imageSize',
  ],
};

export default defaultEmailOptions;
