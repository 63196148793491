import { useCallback, useEffect, useRef, useState } from 'react';
import uploadS3Image from '~/util/uploadS3Image';
import uuidv4 from 'uuid/v4';
import useCurrentUser from '../useCurrentUser';
import useAddToast from '../useAddToast';
import getErrorMessage from './utils/getErrorMessage';

type UploadImage = (args: { file: File; filename?: string }) => Promise<void>;
type ClearImage = () => void;

type Props = {
  /** Default image url */
  initialUrl?: string | null;
  /** Default s3Key */
  s3Key?: string | null;
  /** Callback once a new image has been uploaded */
  onUpload?: (args: { url: string; s3Key: string }) => void;
  /** Callback once a image has been cleared */
  onClear?: () => void;
  /** Callback once an error happened */
  onError?: (error: string | Error) => void;
};

type Return = {
  /** Url of the uploaded file */
  url: string | null;
  /** S3Key of the uploaded file */
  s3Key: string | null;
  /** An error happened while uploading the file */
  error: string | Error | null;
  /** True if an upload is in process */
  uploading: boolean;
  /**
   * Upload a file to the user storage. The filename will
   * be a random uuid if not given.
   *
   * Most of the time you do not want to give a filename to prevent collisions.
   */
  upload: UploadImage;
  /**
   * Clears the url / s3Key / error. This does not actually removes the
   * file from the storage (the backend cleans all files uploaded after a certain period)
   */
  clear: ClearImage;
};
const useImageUpload = ({
  initialUrl,
  s3Key: defaultS3Key,
  onUpload,
  onClear,
  onError,
}: Props): Return => {
  const me = useCurrentUser();
  const [url, setUrl] = useState<string | null>(null);
  const [s3Key, setS3Key] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const addToast = useAddToast();

  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  useEffect(() => {
    /**
     * Allows us to reset by resetting the s3Key from the outside.
     */
    if (defaultS3Key === s3Key) return;

    setUrl(initialUrl ?? null);
    setS3Key(defaultS3Key ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUrl, defaultS3Key]);

  const upload = useCallback<UploadImage>(
    async ({ file, filename }) => {
      setUploading(true);
      setError(null);
      let s3Key: string;
      let url: string;

      try {
        const { path, permanentLink } = await uploadS3Image({
          file,
          uploadImageName: `${me.id}/${filename || uuidv4()}`,
        });

        s3Key = path;
        url = permanentLink;
      } catch (e) {
        if (!isMounted.current) return;

        addToast([getErrorMessage(e.message)]);
        setError(e);
        setUploading(false);

        if (onError) onError(e);
        return;
      }

      if (!isMounted.current) return;

      setS3Key(s3Key);
      setUrl(url);
      setUploading(false);
      if (onUpload) onUpload({ s3Key, url });
    },
    [onUpload, me.id, addToast, onError],
  );

  const clear = useCallback<ClearImage>(() => {
    if (!isMounted.current) return;
    if (uploading) return;

    setError(null);
    setS3Key(null);
    setUrl(null);
    setUploading(false);

    if (onClear) onClear();
  }, [uploading, onClear]);

  return {
    url,
    uploading,
    upload,
    error,
    s3Key,
    clear,
  };
};

export default useImageUpload;
