import { Flow_Action__Input } from '~/graphql/types';
import { SendEmailPlainAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import { asTemplateStringParameterValueInput } from '../Base/FlowParameter/ParameterValue';

import { asFlowEmailAttachmentValueInput } from '../Base/FlowEmailAttachment';

export default (
  action: SendEmailPlainAction,
  treeNode: ActionTreeNode,
  defaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, subject, body, attachments, isTransactional, from, signature } =
    action;
  const { parentId } = treeNode;
  const { accountId, flowBlueprintId } = defaults;

  if (parentId == null)
    throw new Error(`Parentless SendEmailPlainAction found! ID: ${id}`);

  return {
    SendEmail: {
      Plain: {
        id,
        accountId,
        flowBlueprintId,
        parentId,
        from,
        signature,
        subject: asTemplateStringParameterValueInput(subject),
        body: asTemplateStringParameterValueInput(body, true),
        attachments: attachments.map(attachment =>
          asFlowEmailAttachmentValueInput(attachment),
        ),
        isTransactional,
      },
    },
  };
};
