import React from 'react';

import { DeleteContactTagAction } from './types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { StringPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';

import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import asDeleteContactTagActionInput from './asDeleteContactTagActionInput';
import { FLOW_ACTION_TYPE } from '../constants';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import ActionExplanationHeader from '../ActionExplanationHeader';
import ActionContentContainer from '../ActionContentContainer';
import { InputGroup } from '~/components/Inputs';
import StringPrimitiveParameterValueComponent from '../Base/FlowParameter/ParameterValue/String/StringPrimitiveParameterValueComponent';
import Validation from '~/util/Validation';
import TEST_ID from './DeleteContactTagActionComponent.testid';

const text = {
  explanation: 'Tag verwijderen',
  invalidTag: 'Voer een geldige \tag in',
};
type MyProps = ActionSubscriberProps & {
  id: string;
  isHiddenFromView: boolean;
  positionString: string;
  initialActionProps: DeleteContactTagAction;
};
type Props = MyProps & WithValidatorListProps;
type State = {
  name: StringPrimitiveParameterValue;
  key: string | null;
};
class DeleteContactTagActionComponent extends React.Component<Props, State> {
  _isMounted = false;
  variableStash: FlowVariableStash = emptyVariableStash();
  constructor(props: Props) {
    super(props);

    const { initialActionProps } = props;

    this.state = {
      name: initialActionProps.name,
      key: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { isHiddenFromView } = nextProps;

    return !isHiddenFromView;
  }

  validate = () => {
    const { validate } = this.props;

    // always call validate to make sure components know to show validation
    const componentValidation = validate();

    if (this.hasInvalidTag()) return false;

    return componentValidation;
  };

  hasInvalidTag = () => {
    const { name } = this.state;

    return !Validation.Tag.isValid(name.value);
  };

  setName = (newName: StringPrimitiveParameterValue) => {
    if (this._isMounted) {
      this.setState({
        name: newName,
      });
    }
  };

  setVariableStash = (newStash: FlowVariableStash) => {
    this.variableStash = newStash;
  };

  getActionProp = (): DeleteContactTagAction => {
    const { name } = this.state;
    const { id } = this.props;

    return {
      id,
      type: FLOW_ACTION_TYPE.DELETE_CONTACT_TAG,
      name,
    };
  };

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asDeleteContactTagActionInput(this.getActionProp(), treeNode, defaults);

  render() {
    const {
      announceChanges,
      subscribeValidator,
      unsubscribeValidator,
      showValidation,
      isHiddenFromView,
      positionString,
    } = this.props;
    const { name } = this.state;
    const action = this.getActionProp();

    const tagErrorText = this.hasInvalidTag() ? text.invalidTag : '';

    return (
      <BaseActionComponent
        positionString={positionString}
        actionLabel={text.explanation}
        action={action}
        variableStash={this.variableStash}
        subscribeValidator={subscribeValidator}
        unsubscribeValidator={unsubscribeValidator}
        isHiddenFromView={isHiddenFromView}
      >
        <ActionExplanationHeader title={text.explanation} />
        <ActionContentContainer>
          <InputGroup data-testid={TEST_ID.TAG_INPUT_GROUP}>
            <StringPrimitiveParameterValueComponent
              value={name}
              error={
                showValidation && this.hasInvalidTag()
                  ? tagErrorText
                  : undefined
              }
              formatFunction={Validation.Tag.format}
              change={{
                controlled: true,
                onChange: newname => {
                  this.setName(newname);
                  announceChanges();
                },
              }}
            />
          </InputGroup>
        </ActionContentContainer>
      </BaseActionComponent>
    );
  }
}

export default withValidatorList<MyProps>(DeleteContactTagActionComponent);
