import { FlowConditionAppFundaBrochureRequestNew } from './types.flow';

const asFlowConditionAppFundaBrochureRequestNewProp = (): Omit<
  FlowConditionAppFundaBrochureRequestNew,
  'id'
> => ({
  type: 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New',
});

export default asFlowConditionAppFundaBrochureRequestNewProp;
