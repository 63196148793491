import React from 'react';
import { Props } from '..';

const Enven: React.FC<Props> = ({ color, ...props }) => (
  <svg
    {...props}
    width="75"
    height="61"
    viewBox="0 0 80 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.7814 7.55066C64.8537 7.51124 64.9367 7.49628 65.0182 7.50803C65.0996 7.51978 65.1751 7.5576 65.2333 7.61583L67.3875 9.77006C67.4301 9.81273 67.462 9.86492 67.4805 9.92232C67.499 9.97972 67.5036 10.0407 67.4939 10.1002C67.4842 10.1598 67.4605 10.2161 67.4248 10.2647C67.3891 10.3133 67.3423 10.3527 67.2884 10.3797L63.4528 12.298L65.5025 14.3478C65.553 14.3982 65.5883 14.4617 65.6044 14.5312C65.6205 14.6007 65.6168 14.6733 65.5937 14.7407C65.5706 14.8082 65.529 14.8678 65.4736 14.9128C65.4183 14.9578 65.3514 14.9863 65.2807 14.9951L56.6637 16.0722C56.5752 16.0835 56.4856 16.0634 56.4104 16.0153C56.3352 15.9672 56.2794 15.8942 56.2525 15.8091C56.2256 15.7241 56.2295 15.6322 56.2634 15.5497C56.2973 15.4672 56.3592 15.3992 56.4381 15.3576L60.5602 13.1753L58.7706 11.3857C58.7287 11.3439 58.6971 11.2929 58.6784 11.2367C58.6597 11.1805 58.6543 11.1208 58.6627 11.0622C58.6712 11.0035 58.6931 10.9477 58.7269 10.8991C58.7607 10.8505 58.8053 10.8104 58.8573 10.782L64.7814 7.55066ZM64.8961 8.35581L59.6689 11.2069L61.4634 13.0014C61.5055 13.0435 61.5372 13.095 61.5558 13.1516C61.5745 13.2082 61.5796 13.2684 61.5707 13.3273C61.5619 13.3862 61.5393 13.4423 61.5049 13.4909C61.4704 13.5395 61.425 13.5794 61.3723 13.6073L58.6306 15.0587L64.4136 14.3359L62.5405 12.4628C62.4979 12.4202 62.466 12.368 62.4475 12.3106C62.429 12.2532 62.4244 12.1922 62.4341 12.1327C62.4438 12.0731 62.4674 12.0168 62.5032 11.9682C62.5389 11.9196 62.5856 11.8802 62.6396 11.8532L66.4752 9.93485L64.8961 8.35581Z"
      fill={color}
    />
    <path
      d="M23.4483 14.8775L3.24829 34.1775C2.74829 34.6775 3.04829 35.4775 3.74829 35.4775H5.84829V59.3775H43.2483V35.4775H45.3483C46.0483 35.4775 46.3483 34.6775 45.8483 34.1775L25.8483 14.8775C25.1483 14.1775 24.0483 14.1775 23.4483 14.8775Z"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.04834 33.5776L24.0483 18.9776C24.2483 18.7776 24.6483 18.7776 24.9483 18.9776L39.9483 33.5776"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0484 42.7773H12.9484V59.3773H22.0484V42.7773Z"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.3483 42.7773H30.1483V52.4773H38.3483V42.7773Z"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5483 27.3774H20.5483V36.8774H28.5483V27.3774Z"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.24829 30.0774V22.2774H5.24829V18.8774H19.3483"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.8483 11.8774H30.0483V18.8774L34.8483 23.3774V11.8774Z"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 60H47"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 22L9 22"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.2 12.8777L56.6 27.9777C56.2 28.3777 55.6 28.4777 55.1 28.4777L48.1 27.4777C46.8 27.2777 45.9 26.2777 45.8 24.9777L45.4 17.9777C45.4 17.4777 45.6 16.8777 46 16.5777L62.7 1.47767C63.5 0.77767 64.6 0.877669 65.3 1.57767L73.3 10.3777C74 11.0777 74 12.1777 73.2 12.8777Z"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43 30.0774L50.8 22.8774"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.0001 23.9774C53.2703 23.9774 54.3001 22.9477 54.3001 21.6774C54.3001 20.4072 53.2703 19.3774 52.0001 19.3774C50.7298 19.3774 49.7001 20.4072 49.7001 21.6774C49.7001 22.9477 50.7298 23.9774 52.0001 23.9774Z"
      stroke={color}
      strokeWidth={props.strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Enven;
