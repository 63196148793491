import { BooleanOperation } from './types.flow';

import { FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION } from './constants';
import { emptyBooleanPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter';

const defaultBooleanOperation = (): BooleanOperation => ({
  type: FLOW_CONDITION_PRIMITIVE_BOOLEAN_OPERATION.EQUALS,
  value: emptyBooleanPrimitiveParameterValue(),
});

export default defaultBooleanOperation;
