import gql from 'graphql-tag';

import FlowConditionListFields from './FlowConditionListFields';

export default gql`
  fragment FlowActionIfElseFields on Flow_Action_IfElse {
    __typename
    id
    accountId
    parentId
    flowBlueprintId
    condition {
      ...FlowConditionListFields
    }
    trueChildId
    falseChildId
  }

  ${FlowConditionListFields}
`;
