import { BlockquoteElement } from '~/components/PluginsEditor/types';
import updateAttrsAndConvertToString from '~/components/PluginsEditor/utils/serialize/updateAttrsAndConvertToString';

/**
 * serializes the BlockquoteElement into html element
 * @param {BlockquoteElement} node - BlockquoteElement
 */
const serialize = (node: BlockquoteElement, children: string): string => {
  const attrsAsString = updateAttrsAndConvertToString(
    {
      style: {
        ['border-left']: '2px solid #ddd',
        ['padding-left']: '8px',
        ['margin']: '16px 8px',
        ['text-align']: node.attributes?.style?.textAlign || 'unset',
      },
    },
    node?.attrsToPassThrough,
  );

  return `<blockquote${
    attrsAsString ? ` ${attrsAsString}` : ''
  }>${children}</blockquote>`;
};

export default serialize;
