import { isNil } from 'ramda';
import { Mailbox } from '~/graphql/types';
import { NamedEmail } from '~/util/namedEmailFormatter';
import useOffices from '../useOffices';
import useSessionHydration from '../useSessionHydration';
import useUsers from '../useUsers';

type Props = {
  senderOption: NamedEmail | null;
};

type TypeOfMailbox = Mailbox['__typename'] | undefined;

const DEFAULT_LINK = '/-/settings/account';
const OFFICE_LINK = '/-/settings/offices/';
const USER_LINK = '/-/settings/users/';
const useEmailConfigLink = ({ senderOption }: Props): string => {
  const [{ mailboxes }] = useSessionHydration();
  const offices = useOffices({});
  const users = useUsers();

  if (isNil(senderOption) || !mailboxes || !mailboxes.length)
    return DEFAULT_LINK;

  const typeOfMailbox: TypeOfMailbox = mailboxes.find(
    (mailbox: Mailbox) => mailbox.email.email === senderOption.email,
  )?.__typename;

  switch (typeOfMailbox) {
    case 'OfficeMailbox' || 'OfficeMailboxAlias':
      const office = offices.find(o => o.name === senderOption.name);
      return office ? `${OFFICE_LINK}${office.id}` : OFFICE_LINK;
    case 'UserMailbox' || 'UserMailboxAlias':
      const user = users.find(u => u.name === senderOption.name);
      return user ? `${USER_LINK}${user.id}` : USER_LINK;
    case 'AccountMailbox' || 'AccountMailboxAlias':
    default:
      return DEFAULT_LINK;
  }
};

export default useEmailConfigLink;
