import { DefinitionNode, DocumentNode, OperationDefinitionNode } from 'graphql';

const isOperationDefinitionNode = (
  definitionNode: DefinitionNode,
): definitionNode is OperationDefinitionNode =>
  definitionNode.kind === 'OperationDefinition';
export const getOperationNameFromDocument = (
  document: DocumentNode,
): string => {
  if (!document.definitions) return 'NoName:NoDefinitions';

  const operationDefinition = document.definitions.find(
    definition => definition.kind === 'OperationDefinition',
  );

  if (!operationDefinition || !isOperationDefinitionNode(operationDefinition))
    return 'NoName:NoOpDefinition';

  if (!operationDefinition.name) return 'NoName:NoOpDefinition:name';

  if (!operationDefinition.name.value)
    return 'NoName:NoOpDefinition:name:value';

  const name = operationDefinition.name.value;

  if (typeof name !== 'string') return 'NoName:Name.Value:NotString';

  return name;
};
