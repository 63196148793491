import React, { useState } from 'react';
import {
  ActivityFields_Task_Contact_Fragment,
  Image,
  UpdateTaskMutationVariables,
  useUpdateTaskMutation,
} from '~/graphql/types';
import BaseEventCard from '../../BaseEventCard';
import asTaskProp from '~/scenes/Tasks/util/asTaskProp';
import TaskBody from './components/TaskBody';
import BottomBar from './components/BottomBar';
import styled, { css } from 'styled-components';
import { iconForTypeInHeader } from '~/util/taskTypeOptions';
import { isNil } from 'ramda';
import { Task as TaskDetailProps } from '~/scenes/Tasks/types';
import Overlay from '~/components/ModalsV2/Overlay';
import Dialog from '~/components/ModalsV2/Dialog';

export type Props = ActivityFields_Task_Contact_Fragment & {
  dataTestId?: string;
  onOpenTaskModal?: (task: TaskDetailProps | null) => void;
  header: {
    text: string;
  };
  icon: Image;
};

const confirmDeleteModalText = {
  title: 'De taak verwijderen',
  message: 'Weet u zeker dat je de taak wilt verwijderen?',
  buttonConfirmTitle: 'Verwijderen',
  buttonCancelTitle: 'Annuleren',
};

const Task: React.FC<Props> = ({ onOpenTaskModal, ...rest }) => {
  const [updateTask, { loading }] = useUpdateTaskMutation();
  const [showModal, setShowModal] = useState<boolean>(false);

  const onUpdate = (variables: UpdateTaskMutationVariables) =>
    updateTask({
      variables,
    });

  const onClose = () => setShowModal(false);

  return (
    <>
      {showModal && (
        <Overlay root="confirm-modal" onClose={onClose}>
          <Dialog
            loading={loading}
            onClose={onClose}
            header={confirmDeleteModalText.title}
            body={confirmDeleteModalText.message}
            confirmAction={{
              label: confirmDeleteModalText.buttonConfirmTitle,
              appearance: 'danger',
            }}
            cancelAction={{ label: confirmDeleteModalText.buttonCancelTitle }}
            onConfirm={async e => {
              e.stopPropagation();
              e.preventDefault();
              await onUpdate({
                accountId: rest.accountId,
                id: rest.id,
                update: { deleted: true },
              });

              setShowModal(false);
            }}
          />
        </Overlay>
      )}
      <BaseEventCard
        {...rest}
        isTaskClosed={!isNil(rest.closedDate)}
        dataTestId="Task_Contact_Card"
        header={{
          text: rest.header.text,
          icon: iconForTypeInHeader(rest.type),
        }}
        onClick={() => onOpenTaskModal && onOpenTaskModal(asTaskProp(rest))}
        body={
          <Container>
            <TaskBody {...rest} />
          </Container>
        }
        bottomBar={
          <BottomBar
            onDelete={() => setShowModal(true)}
            loading={loading}
            accountId={rest.accountId}
            id={rest.id}
            onUpdate={onUpdate}
            status={rest.status}
          />
        }
      />
    </>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('base')};
  `,
);

export default Task;
