import { DeleteContactTagAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { emptyStringPrimitiveParameterValue } from '../Base';

const defaultProps = (
  baseActionProps: BaseActionProps,
): DeleteContactTagAction => ({
  ...baseActionProps,
  type: FLOW_ACTION_TYPE.DELETE_CONTACT_TAG,
  name: emptyStringPrimitiveParameterValue(),
});

export default defaultProps;
