import React from 'react';

import { WaitAction } from './types.flow';
import { ActionSubscriberProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import {
  FlowConditionList,
  FlowConditionListWithoutId,
} from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList/types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';
import { WithValidatorListProps } from '~/scenes/Automation/Flows/Actions/withValidatorList';

import BaseActionComponent from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import { emptyVariableStash } from '~/scenes/Automation/Flows/util/variableHelpers';
import asWaitActionInput from './asWaitActionInput';
import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import withValidatorList from '~/scenes/Automation/Flows/Actions/withValidatorList';
import FlowConditionListComponent from '../Base/FlowConditionList/FlowConditionListComponent';
import { emptyFlowConditionContactDetails } from '../Base';
import ActionExplanationHeader from '../ActionExplanationHeader';
import { generateHash } from '~/util/react';
import { FlowCondition } from '../Base/FlowCondition/types.flow';
import { getBaseActionProps } from '../util/renderActionTree';

const text = {
  explanation: 'Wacht op conditie',
};
type MyProps = ActionSubscriberProps & {
  id: string;
  isHiddenFromView: boolean;
  positionString: string;
  initialActionProps: WaitAction;
} & ReturnType<typeof getBaseActionProps>;
type Props = WithValidatorListProps & MyProps;
type State = {
  conditionListState: FlowConditionList;
  key: string | null;
  variableStashVersion: string | null;
};
class WaitActionComponent extends React.Component<Props, State> {
  _isMounted = false;
  variableStash: FlowVariableStash = emptyVariableStash();
  constructor(props: Props) {
    super(props);

    const { initialActionProps } = props;

    this.state = {
      conditionListState: initialActionProps.conditionList,
      key: null,
      variableStashVersion: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { id, subscribe } = this.props;

    const key = subscribe({
      actionId: id,
      getActionProp: this.getActionProp,
      asInput: this.asInput,
      updateVariableStash: this.setVariableStash,
      validate: this.props.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { unsubscribe } = this.props;

    if (this.state.key != null) {
      unsubscribe(this.state.key);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { isHiddenFromView } = nextProps;
    const { variableStashVersion: nextVariableStashVersion } = nextState;
    const { variableStashVersion } = this.state;

    if (nextVariableStashVersion !== variableStashVersion) {
      return true;
    }

    return !isHiddenFromView;
  }

  setConditionListState = (
    newFieldState: FlowConditionListWithoutId | FlowConditionList,
  ) => {
    if (this._isMounted) {
      this.setState(
        {
          conditionListState: {
            ...newFieldState,
            conditions: newFieldState.conditions.map(
              (
                condition: FlowCondition | Omit<FlowCondition, 'id'>,
                idx: number,
              ) =>
                ({
                  id: generateHash(`${JSON.stringify(condition)}-${idx}`),
                  ...condition,
                } as FlowCondition),
            ),
          },
        },
        () => {
          this.props.announceChanges();
        },
      );
    }
  };

  onDropdownOpened = () => {
    if (this._isMounted) {
      const { conditionListState } = this.state;
      const shouldRefreshStash = conditionListState.conditions.some(
        condition =>
          condition.type === 'Flow_Condition_Event_Contact_App_Zapier_New' ||
          condition.type === 'Flow_Condition_Event_Contact_App_Zapier_Details',
      );
      this.props.announceChanges({
        updateVariableStash: shouldRefreshStash,
      });
    }
  };

  setVariableStash = (newStash: FlowVariableStash) => {
    this.variableStash = newStash;

    if (this._isMounted) {
      this.setState({
        variableStashVersion: newStash.version,
      });
    }
  };

  getActionProp = (): WaitAction => {
    const { conditionListState } = this.state;
    const { id } = this.props;

    return {
      id,
      type: FLOW_ACTION_TYPE.WAIT,
      conditionList: conditionListState,
    };
  };

  asInput = (treeNode: ActionTreeNode, defaults: ActionInputDefaults) =>
    asWaitActionInput(this.getActionProp(), treeNode, defaults);

  render() {
    const {
      subscribeValidator,
      unsubscribeValidator,
      isHiddenFromView,
      positionString,
      outputLoading,
      announceChanges,
    } = this.props;
    const { conditionListState } = this.state;
    const action = this.getActionProp();

    return (
      <BaseActionComponent
        positionString={positionString}
        actionLabel={text.explanation}
        action={action}
        variableStash={this.variableStash}
        subscribeValidator={subscribeValidator}
        unsubscribeValidator={unsubscribeValidator}
        isHiddenFromView={isHiddenFromView}
      >
        <ActionExplanationHeader title={text.explanation} />
        <>
          <FlowConditionListComponent
            outputLoading={outputLoading}
            onDropdownOpened={this.onDropdownOpened}
            conditionList={conditionListState}
            onChange={newConditionList => {
              this.setConditionListState(newConditionList);
            }}
            getNewConditionToAdd={() => emptyFlowConditionContactDetails()}
            announceChanges={announceChanges}
          />
        </>
      </BaseActionComponent>
    );
  }
}

export default withValidatorList<MyProps>(WaitActionComponent);
