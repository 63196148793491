import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import MetaTags from 'react-meta-tags';
import uuidv1 from 'uuid/v1';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import {
  GetContactsV2Query as GetContactsV2QueryType,
  GetContactsV2QueryVariables,
  SortByAdvancedInput,
  SortFieldAdvanced,
  SortDirection,
  useGetContactsV2Query,
  ContactFilters__Input,
} from '~/graphql/types';
import DatHuisLoading from '~/components/DatHuisLoading';
import type { ViewableOffice, ViewableUser } from '~/contexts/AccountContext';
import {
  ContactListData,
  isDeleted,
} from '~/scenes/Contacts/util/composeContactListData';
import { CONTACT_LIST_LIMIT } from '../../constants';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ContactListLeftFilterBar from './ContactListLeftFilterBar';
import Catalog from '~/Catalog';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import contactListColumns, {
  getSortFieldName,
} from '../../util/contactListColumns';
import { isEmptyObject } from '~/util/object';
import TEST_ID from './index.testid';
import Pagination from '~/components/DataTables/components/Pagination';
import serializeFiltersV2 from '~/scenes/Contacts/util/serializeFiltersV2';
import useDebounce from '~/hooks/useDebounce';
import useRowSelect from '~/components/DataTables/useRowSelect';
import { useLocation } from '@reach/router';
import useQueryParams from '~/hooks/useQueryParams';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import useCurrentUser from '~/hooks/useCurrentUser';
import ContactsToolBar from './ContactsToolBar';
import TableTotalAmountContainer from '../TableTotalAmountContainer';
import cleanFilters from './util/cleanFilters';
import ContactListTable from '../ContactListTable';
import composeContactListData from '~/scenes/Contacts/util/composeContactListData';
import ContactListFilterBar, { emptyFilterGroupV2 } from './FilterBar';
import useContactFilterOptionsV2 from '~/hooks/useContactFilterOptionsV2';
import { clone, equals, isNil } from 'ramda';
import useSortSettings, { SortSettings } from '~/hooks/useSortSettings';
import { useSetRecoilState } from 'recoil';
import activeFilter from './ContactListLeftFilterBar/components/Segments/state/activeFilter';

export const text = {
  pageTitle: 'Contacten',
  allUsers: 'Alle gebruikers',
  allOffices: 'Alle vestigingen',
  queryError: Catalog.genericUnknownErrorMessage,
  showThisContact: 'Toon dit contact',
};

export type ViewableContact = {
  id: string;
  name: string;
  email: string;
  phone: string;
  office?: ViewableOffice | null;
  assignedUser?: ViewableUser | null;
};

type Props = RouteComponentProps<{
  currentPage: number;
}> & {
  storedFilters: ContactFilters__Input;
};

export enum CONTACT_SIDEBAR_STATE {
  MULTIPLE_CONTACTS = 'MULTIPLE_CONTACTS',
  SINGLE_CONTACT = 'SINGLE_CONTACT',
}

export type SortToggleFunction = (
  sortField: SortFieldAdvanced,
  sortDirection: SortDirection | null,
) => void;

type QueryVariablesType = {
  limit: number;
  query: string;
  sortBy: SortByAdvancedInput | null;
};

const getSortBy = (sortSettings: SortSettings): SortByAdvancedInput => ({
  field: getSortFieldName(sortSettings.id),
  direction: sortSettings.desc ? SortDirection.Desc : SortDirection.Asc,
});

const ContactList = ({ currentPage: _currentPage, storedFilters }: Props) => {
  const isMountedRef = useIsMountedRef();
  const currentPage = _currentPage && _currentPage > 1 ? _currentPage : 1;
  const [sortSettings, updateSortSettings] = useSortSettings(
    'contactSortSettings',
  );

  const [queryVariables, setQueryVariables] = useState<QueryVariablesType>({
    limit: CONTACT_LIST_LIMIT,
    query: '',
    sortBy: getSortBy(sortSettings),
  });

  const debouncedQueryVariables = useDebounce(queryVariables, 500);
  const account = useCurrentAccount();
  const [version, setVersion] = useState(uuidv1());
  const [key] = useState(uuidv1());
  const tableData = useRef<Array<ContactListData>>([]);

  const me = useCurrentUser();
  const location = useLocation();

  const [filters, setFilters] = useState<ContactFilters__Input>(storedFilters);

  useEffect(() => {
    setFilters(storedFilters);
  }, [storedFilters]);

  const [, setQueryParams] = useQueryParams({
    f: serializeFiltersV2(cleanFilters(filters)),
  });

  const setActiveFilter = useSetRecoilState(activeFilter);

  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [contactSidebarState, setContactSidebarState] =
    useState<CONTACT_SIDEBAR_STATE | null>(null);

  const toggleSidebar = (state: CONTACT_SIDEBAR_STATE) => {
    setContactSidebarState(state);
    setShowSidebar(true);
  };
  const closeSidebar = () => setShowSidebar(false);

  const selectionProps = useRowSelect();
  const { itemsSelected, allSelected, deselectAllItems } = selectionProps;

  const accountError = !account || !account.id;
  const accountId = account ? account.id : '';

  const variables = {
    ...debouncedQueryVariables,
    filters: cleanFilters(filters),
    accountId,
    // pages are 0 based
    page: currentPage - 1,
    extend: {
      lastActivity: false,
      leadActivity: true, // get lead activity
      metadata: true, // get lead score
    },
  };

  const { data, error, networkStatus, loading, updateQuery, refetch } =
    useGetContactsV2Query({
      variables,
    });

  const { options: filterOptions, loading: filterOptionsLoading } =
    useContactFilterOptionsV2();

  if (filterOptionsLoading) return <DatHuisLoading />;

  if (!loading) {
    tableData.current =
      data == null || isEmptyObject(data)
        ? []
        : composeContactListData(data.getContactsV2.items, variables, version);
  }

  const filteredItemsCount =
    data && data.getContactsV2
      ? data.getContactsV2.total -
        data.getContactsV2.items.filter(isDeleted).length
      : null;

  const contactsMeta = {
    accountId: account ? account.id : '',
    officeId: '',
    userId: me.id,
  };

  const totalContactsInAccount =
    data?.getContactsV2?.totalContactsInAccount || 0;

  const selectedContactAmount = allSelected
    ? filteredItemsCount
    : itemsSelected.length;

  return (
    <>
      <ContentContainerDefault style={{ maxWidth: '1400px' }}>
        <MetaTags>
          <title>{text.pageTitle}</title>
        </MetaTags>

        <ContactListContainer
          data-testid={TEST_ID.CONTAINER}
          data-queryinflight={loading}
        >
          <ContactsToolBar
            onRefresh={() => refetch()?.then(() => setVersion(uuidv1()))}
            contactsMeta={contactsMeta}
            onDeleteContact={() => {
              deselectAllItems();

              return refetch().then(() => {
                if (!isMountedRef.current) return;

                setVersion(uuidv1());

                if (tableData.current.length === 0 && currentPage !== 1) {
                  const nextPage =
                    currentPage - 1 !== 0
                      ? `/-/contacts/page/${currentPage - 1}`
                      : '/-/contacts/page/1';
                  void navigate(`${nextPage}${location.search}`, {
                    replace: true,
                  });
                }
              });
            }}
            onAddTags={() =>
              refetch().then(() => {
                deselectAllItems();
                setVersion(uuidv1());
              })
            }
            onFilterChange={newQuery => {
              setQueryVariables(prevVariables => ({
                ...prevVariables,
                query: newQuery,
              }));

              deselectAllItems();
              void navigate('/-/contacts/page/1' + location.search);
            }}
            selectedContacts={itemsSelected}
            allSelected={allSelected}
            queryVariables={variables}
            selectedContactAmount={selectedContactAmount || 0}
            sidebarProps={{
              toggleSidebar,
              closeSidebar,
              contactSidebarState,
              showSidebar,
            }}
          />

          <DividerContainer>
            <FilterListContainer>
              <ContactListLeftFilterBar
                onFilterChangeV2={newFilters => {
                  const clonedNewFilters = cleanFilters(newFilters);
                  const encodedFilters = serializeFiltersV2(clonedNewFilters);

                  setQueryParams(
                    {
                      f: encodedFilters,
                    },
                    { next: '/-/contacts/page/1' },
                  );

                  deselectAllItems();
                }}
                currentFilters={filters}
              />
            </FilterListContainer>
            <TableContainer>
              <ContactListFilterBar
                filters={filters}
                onAddFilterGroup={() => {
                  const emtyGroup = emptyFilterGroupV2();
                  const clonedNewFilters = clone({
                    ...filters,
                    nodes: [
                      ...filters.nodes,
                      {
                        ...emtyGroup,
                        Node: {
                          ...emtyGroup.Node,
                          nodes: [{ Leaf: undefined }],
                        },
                      },
                    ],
                  });
                  setFilters(clonedNewFilters);
                }}
                filterOptions={filterOptions}
                onChange={newFilters => {
                  const clonedNewFilters = cleanFilters(newFilters);
                  const encodedFilters = serializeFiltersV2(clonedNewFilters);

                  setQueryParams(
                    {
                      f: encodedFilters,
                    },
                    { next: '/-/contacts/page/1' },
                  );

                  const filtersChanged = !equals(clonedNewFilters, filters);
                  if (filtersChanged) {
                    setActiveFilter({
                      segmentId: null,
                      filterId: null,
                    });
                  }

                  deselectAllItems();
                }}
              />
              <TableTotalAmountContainer
                filteredItemsCount={filteredItemsCount || 0}
                totalContactsInAccount={totalContactsInAccount || 0}
                selectedContactAmount={selectedContactAmount || 0}
              />

              <ContactListTable
                columns={contactListColumns((sortField, sortDirection) => {
                  !isNil(sortDirection) &&
                    updateSortSettings({ sortField, sortDirection });
                  setQueryVariables(prevQueryVariables => ({
                    ...prevQueryVariables,
                    sortBy:
                      sortDirection == null
                        ? null
                        : {
                            field: sortField,
                            direction: sortDirection,
                          },
                  }));
                }, key)}
                data={tableData.current}
                networkStatus={networkStatus}
                loading={loading}
                error={error ?? accountError}
                onSuccessfulDelete={contactId => {
                  if (!isMountedRef.current) return;

                  if (tableData.current.length === 1 && currentPage !== 1) {
                    void navigate(
                      `/-/contacts/page/${currentPage - 1}${location.search}`,
                      {
                        replace: true,
                      },
                    );
                  } else {
                    updateQuery(
                      getContactsForQueryUpdateAfterRemoveQueryFunction(
                        contactId,
                      ),
                    );
                    setVersion(uuidv1());
                  }
                }}
                selectionProps={selectionProps}
                toggleSidebar={() =>
                  toggleSidebar(CONTACT_SIDEBAR_STATE.SINGLE_CONTACT)
                }
                totalContactsInAccount={
                  !loading ? totalContactsInAccount : null
                }
              />

              {filteredItemsCount != null && (
                <PaginationContainer>
                  <Pagination
                    totalItems={filteredItemsCount}
                    currentPage={currentPage}
                    itemsPerPage={CONTACT_LIST_LIMIT}
                    onSelect={newPage => {
                      void navigate(
                        `/-/contacts/page/${newPage}${location.search}`,
                        { replace: false },
                      );
                    }}
                  />
                </PaginationContainer>
              )}
            </TableContainer>
          </DividerContainer>
        </ContactListContainer>
      </ContentContainerDefault>
    </>
  );
};

const getContactsForQueryUpdateAfterRemoveQueryFunction =
  (
    contactId: string,
  ): ((
    previousQueryResult: GetContactsV2QueryType,
    options: { variables: GetContactsV2QueryVariables },
  ) => GetContactsV2QueryType) =>
  previousResult => {
    const newList = previousResult.getContactsV2.items.filter(
      contact => contact.id !== contactId,
    );

    return {
      ...previousResult,
      getContactsV2: {
        ...previousResult.getContactsV2,
        items: newList,
        total:
          previousResult.getContactsV2.total -
          (previousResult.getContactsV2.items.length - newList.length),
      },
    };
  };

const ContactListContainer = styled.div<{}>`
  /*min-height: 1200px;*/
`;

const PaginationContainer = styled.div<{}>`
  display: flex;
  flex-direction: row-reverse;

  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
  `}
`;

const DividerContainer = styled.div<{}>`
  display: flex;
`;

const FilterListContainer = styled.div<{}>`
  flex: 0.2;
  ${({ theme }) => css`
    margin: ${theme.space('m')} ${theme.space('m')} 0 0;
  `}
`;

const TableContainer = styled.div<{}>`
  flex: 1;
`;

export default ContactList;
