import { ConditionField } from './types.flow';
import {
  Flow_ConditionPrimitive__String__Input,
  Flow_ConditionPrimitive__Boolean__Input,
  Flow_ConditionPrimitive__Number__Input,
} from '~/graphql/types';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';
import { asConditionStringFieldInput } from './String';
import { asConditionBooleanFieldInput } from './Boolean';
import { asConditionNumberFieldInput } from './Number';
import cleanedFilename from '~/util/cleanedFilename';

type ReturnType =
  | Flow_ConditionPrimitive__String__Input
  | Flow_ConditionPrimitive__Boolean__Input
  | Flow_ConditionPrimitive__Number__Input;
const asConditionFieldInput = (field: ConditionField): ReturnType => {
  switch (field.type) {
    case FIELD_TYPE.STRING:
      return asConditionStringFieldInput(field);
    case FIELD_TYPE.BOOLEAN:
      return asConditionBooleanFieldInput(field);
    case FIELD_TYPE.NUMBER:
      return asConditionNumberFieldInput(field);
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | NYI: convert condition field of ${JSON.stringify(
          field,
          null,
          2,
        )} to input`,
      );
  }
};

export default asConditionFieldInput;
