import gql from 'graphql-tag';

import FlowParameterNumberFields from './FlowParameterNumberFields';

export default gql`
  fragment FlowConditionNumberPrimitiveFields on Flow_ConditionPrimitive_Number {
    __typename

    ... on Flow_ConditionPrimitive_Number_Lt {
      numberValue: value {
        ...FlowParameterNumberFields
      }
      negate
    }

    ... on Flow_ConditionPrimitive_Number_Eq {
      numberValue: value {
        ...FlowParameterNumberFields
      }
      negate
    }

    ... on Flow_ConditionPrimitive_Number_Gt {
      numberValue: value {
        ...FlowParameterNumberFields
      }
      negate
    }
  }

  ${FlowParameterNumberFields}
`;
