import React, { useEffect, useState } from 'react';
import useEntityWarnings, {
  Warning as WarningType,
  Warnings,
} from '~/hooks/useEntityWarnings';
import filterWarnings from './utils/filterWarnings';
import snoozeWarning from './utils/snoozeWarning';
import Warning from '../Warning';
import JustificationContainer from '../JustificationContainer';

const WarningsContainer: React.FC<{}> = () => {
  const sessionHydrationWarnings = useEntityWarnings();
  const [warnings, setWarnings] = useState<Warnings>([]);

  useEffect(() => {
    const filteredWarnings = filterWarnings(sessionHydrationWarnings);
    setWarnings(filteredWarnings);
  }, [sessionHydrationWarnings]);

  const snooze = (warning: WarningType) => {
    snoozeWarning(warning);
    const remainingWarnings = warnings.filter(
      item => item.hash !== warning.hash,
    );
    setWarnings(remainingWarnings);
  };

  return (
    <JustificationContainer
      margin={['xl', null, null, null]}
      gap="xxs"
      direction="column"
    >
      {warnings.map(warning => (
        <Warning key={warning.hash} onClose={snooze} warning={warning} />
      ))}
    </JustificationContainer>
  );
};

export default WarningsContainer;
