import { emptyFlowConditionContactDetails } from './ContactDetails';
import { emptyFlowConditionContactTag } from './ContactTag';
import { emptyFlowConditionEmailLinkClicked } from './EmailLinkClicked';
import { emptyFlowConditionEmailOpened } from './EmailOpened';
import { emptyFlowConditionTimeElapsed } from './TimeElapsed';
import { emptyFlowConditionAppValuationReportNew } from './AppValuationReportNew';
import { emptyFlowConditionValuationReportDetails } from './AppValuationReportDetails';
import { emptyFlowConditionAppValuationRequestNew } from './AppValuationRequestNew';
import { emptyFlowConditionValuationRequestDetails } from './AppValuationRequestDetails';
import { emptyFlowConditionAppFundaContactRequestNew } from './AppFundaContactRequestNew';
import { emptyFlowConditionAppFundaViewingRequestNew } from './AppFundaViewingRequestNew';
import { emptyFlowConditionAppFundaBrochureRequestNew } from './AppFundaBrochureRequestNew';
import { emptyFlowConditionAppFundaContactRequestDetails } from './AppFundaContactRequestDetails';
import { emptyFlowConditionAppFundaViewingRequestDetails } from './AppFundaViewingRequestDetails';
import { emptyFlowConditionAppFundaBrochureRequestDetails } from './AppFundaBrochureRequestDetails';
import { emptyFlowConditionAppBBWaardecheckReportNew } from './AppBBWaardecheckReportNew';
import { emptyFlowConditionAppBBWaardecheckReportDetails } from './AppBBWaardecheckReportDetails';
import { emptyFlowConditionAppBBWaardecheckAppraisalRequestNew } from './AppBBWaardecheckAppraisalRequestNew';
import { emptyFlowConditionAppBBWaardecheckAppraisalRequestDetails } from './AppBBWaardecheckAppraisalRequestDetails';
import { emptyFlowConditionAppBBWaardecheckContactRequestNew } from './AppBBWaardecheckContactRequestNew';
import { emptyFlowConditionAppZapierNew } from './AppZapierNew';
import { emptyFlowConditionAppZapierDetails } from './AppZapierDetails';
import { emptyFlowConditionAppVBOWaardecheckReportNew } from './AppVBOWaardecheckReportNew';
import { emptyFlowConditionAppVBOWaardecheckReportDetails } from './AppVBOWaardecheckReportDetails';
import { emptyFlowConditionAppVBOWaardecheckAppraisalRequestNew } from './AppVBOWaardecheckAppraisalRequestNew';
import { emptyFlowConditionAppVBOWaardecheckAppraisalRequestDetails } from './AppVBOWaardecheckAppraisalRequestDetails';
import { emptyFlowConditionAppVBOWaardecheckContactRequestNew } from './AppVBOWaardecheckContactRequestNew';
import { emptyFlowConditionAppHypotheekbondDetails } from './AppHypotheekbondDetails';
import { emptyFlowConditionAppHypotheekbondNew } from './AppHypotheekbondNew';

const emptyFlowConditionDict = () => ({
  Flow_Condition_Contact_Details: emptyFlowConditionContactDetails(),
  Flow_Condition_Contact_Tag: emptyFlowConditionContactTag(),
  Flow_Condition_Event_Email_LinkClicked: emptyFlowConditionEmailLinkClicked(),
  Flow_Condition_Event_Email_Opened: emptyFlowConditionEmailOpened(),
  Flow_Condition_Time_Elapsed: emptyFlowConditionTimeElapsed(),
  Flow_Condition_Event_Contact_App_ValuationReport_New:
    emptyFlowConditionAppValuationReportNew(),
  Flow_Condition_Event_Contact_App_ValuationReport_Details:
    emptyFlowConditionValuationReportDetails(),
  Flow_Condition_Event_Contact_App_ValuationRequest_New:
    emptyFlowConditionAppValuationRequestNew(),
  Flow_Condition_Event_Contact_App_ValuationRequest_Details:
    emptyFlowConditionValuationRequestDetails(),
  Flow_Condition_Event_Contact_App_Funda_ContactRequest_New:
    emptyFlowConditionAppFundaContactRequestNew(),
  Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New:
    emptyFlowConditionAppFundaViewingRequestNew(),
  Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New:
    emptyFlowConditionAppFundaBrochureRequestNew(),
  Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details:
    emptyFlowConditionAppFundaContactRequestDetails(),
  Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details:
    emptyFlowConditionAppFundaViewingRequestDetails(),
  Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details:
    emptyFlowConditionAppFundaBrochureRequestDetails(),
  Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New:
    emptyFlowConditionAppBBWaardecheckReportNew(),
  Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details:
    emptyFlowConditionAppBBWaardecheckReportDetails(),
  Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New:
    emptyFlowConditionAppBBWaardecheckAppraisalRequestNew(),
  Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details:
    emptyFlowConditionAppBBWaardecheckAppraisalRequestDetails(),
  Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New:
    emptyFlowConditionAppBBWaardecheckContactRequestNew(),
  Flow_Condition_Event_Contact_App_Zapier_New: emptyFlowConditionAppZapierNew(),
  Flow_Condition_Event_Contact_App_Zapier_Details:
    emptyFlowConditionAppZapierDetails(),
  Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New:
    emptyFlowConditionAppVBOWaardecheckReportNew(),
  Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details:
    emptyFlowConditionAppVBOWaardecheckReportDetails(),
  Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New:
    emptyFlowConditionAppVBOWaardecheckAppraisalRequestNew(),
  Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details:
    emptyFlowConditionAppVBOWaardecheckAppraisalRequestDetails(),
  Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New:
    emptyFlowConditionAppVBOWaardecheckContactRequestNew(),
  Flow_Condition_Event_Contact_App_Hypotheekbond_Details:
    emptyFlowConditionAppHypotheekbondDetails(),
  Flow_Condition_Event_Contact_App_Hypotheekbond_New:
    emptyFlowConditionAppHypotheekbondNew(),
});

export default emptyFlowConditionDict;
