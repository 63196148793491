import { useRef } from 'react';
import {
  DocumentNode,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';

import GetSessionHydration from '~/graphql/query/GetSessionHydration';

import {
  GetContactInTaskQuery as GetContactInTaskQueryData,
  GetContactInTaskQueryVariables,
  GetMyAccountsQuery as GetMyAccountsQueryData,
  GetAccountQuery as GetAccountQueryData,
  GetSessionHydrationQuery as GetSessionHydrationQueryData,
  GetSessionHydrationQueryVariables,
  GetUserQuery as GetUserQueryData,
  GetUserQueryVariables,
  GetOfficeQuery as GetOfficeQueryData,
  GetOfficeQueryVariables,
  GetMyTasksQuery as GetMyTasksQueryData,
  GetMyTasksQueryVariables,
  GetMyTasksForDashboardQuery as GetMyTasksForDashboardQueryData,
  GetMyTasksForDashboardQueryVariables,
  GetTasksQuery as GetTasksQueryData,
  GetTasksQueryVariables,
  GetAppValuationReportQuery as GetAppValuationReportQueryData,
  GetAppValuationReportQueryVariables,
  GetAppValuationReportsQuery as GetAppValuationReportsQueryData,
  GetAppValuationReportsQueryVariables,
  GetContactQuery as GetContactQueryData,
  GetContactQueryVariables,
  GetExtendedEmailQuery as GetExtendedEmailQueryData,
  GetExtendedEmailQueryVariables,
  GetTemplatesQuery as GetTemplatesQueryData,
  GetTemplatesQueryVariables,
  GetEmailTemplateQuery as GetEmailTemplateQueryData,
  GetEmailTemplateQueryVariables,
  GetFlowsQuery as GetFlowsQueryData,
  GetFlowsQueryVariables,
  GetFlowQuery as GetFlowQueryData,
  GetFlowQueryVariables,
  GetAppStatusesQuery as GetAppStatusesQueryData,
  GetAppStatusesQueryVariables,
  GetFlowInstancesQuery as GetFlowInstancesQueryData,
  GetFlowInstancesQueryVariables,
  GetFlowTemplateQuery as GetFlowTemplateQueryData,
  GetFlowTemplateQueryVariables,
  GetAccountForSubscriptionQuery as GetAccountForSubscriptionQueryData,
  GetAccountForSubscriptionQueryVariables,
  GetApproximateUpcomingOveragesChargeQuery as GetApproximateUpcomingOveragesChargeQueryData,
  GetApproximateUpcomingOveragesChargeQueryVariables,
  GetCouponQuery as GetCouponQueryData,
  GetCouponQueryVariables,
  GetInvoicesQuery as GetInvoicesQueryData,
  GetInvoicesQueryVariables,
  GetBillingDetailsQuery as GetBillingDetailsQueryData,
  GetBillingDetailsQueryVariables,
  GetContactsQuery as GetContactsQueryData,
  GetContactsQueryVariables,
  GetFlowSettingsQuery as GetFlowSettingsQueryData,
  GetFlowSettingsQueryVariables,
  GetAppStatusRealworksQuery as GetAppStatusRealworksQueryData,
  GetAppStatusRealworksQueryVariables,
} from './types';
import errorReporter from './errorReporter';
import GetFlows from './query/GetFlows';
import GetFlowSettings from './query/GetFlowSettings';
import GetAppValuationReports from './query/GetAppValuationReports';
/**
 * We wrap the Apollo Query component to add some DatHuis defaults.
 *
 * Any error returned from the server will by default be sent to our bugtracker to be looked at/solved.
 * If you are using this component and can handle certain errors cleanly then pass the error type to the doNotReportErrorTypes props.
 * For example: 'This contact already exists'
 */
export type ExtraProps = {
  name: string;
  /** Which error types should not be reported to the error tracker */
  doNotReportErrorTypes?: Array<string>;
  /** Which error messages should not be reported to the error tracker */
  doNotReportErrorMessages?: Array<string>;
  /** Ignore all the standard error tracker reporting of the query */
  ignoreAutomaticErrorReporting?: boolean;
};

export type QueryArgs<TData, TVariables> = QueryHookOptions<TData, TVariables> &
  ExtraProps & {
    children: (result: QueryResult<TData, TVariables>) => JSX.Element | null;
    query: DocumentNode;
  };
const Query = <TData, TVariables>(
  props: QueryArgs<TData, TVariables>,
): JSX.Element | null => {
  const {
    onError,
    ignoreAutomaticErrorReporting,
    doNotReportErrorTypes,
    doNotReportErrorMessages,
    name,
    children,
    variables,
    query,
    ...rest
  } = props;
  const onErrorFn = useRef(error =>
    errorReporter(error, {
      doNotReportErrorTypes,
      doNotReportErrorMessages,
      ignoreAutomaticErrorReporting,
      onError,
      name,
      doc: query,
      variables,
    }),
  );
  const queryReturn = useQuery<TData, TVariables>(query, {
    ...rest,
    variables,
    onError: onErrorFn.current,
    partialRefetch: true,
  });

  return children(queryReturn);
};

type HookQueryHookOptions<TData, TVariables> = QueryHookOptions<
  TData,
  TVariables
> &
  ExtraProps &
  ExtraProps;

const useDHQuery = <TData, TVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  options: HookQueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> => {
  const {
    onError,
    ignoreAutomaticErrorReporting,
    doNotReportErrorTypes,
    doNotReportErrorMessages,
    name,
    variables,
    ...rest
  } = options;

  const onErrorFn = useRef(error =>
    errorReporter(error, {
      doNotReportErrorTypes,
      doNotReportErrorMessages,
      ignoreAutomaticErrorReporting,
      onError,
      name,
      doc: query,
      variables,
    }),
  );

  return useQuery<TData, TVariables>(query, {
    ...rest,
    variables,
    onError: onErrorFn.current,
    partialRefetch: true,
  });
};
export const GetUserQuery = (
  props: QueryArgs<GetUserQueryData, GetUserQueryVariables>,
) => Query(props);
export const GetMyAccountsQuery = (
  props: QueryArgs<GetMyAccountsQueryData, {}>,
) => Query(props);
export const GetAccountQuery = (props: QueryArgs<GetAccountQueryData, {}>) =>
  Query(props);
export const GetContactInTaskQuery = (
  props: QueryArgs<GetContactInTaskQueryData, GetContactInTaskQueryVariables>,
) => Query(props);

export const GetSessionHydrationQuery = (
  props: QueryArgs<
    GetSessionHydrationQueryData,
    GetSessionHydrationQueryVariables
  >,
) => Query(props);

export const useGetSessionHydrationQuery = (
  options: HookQueryHookOptions<
    GetSessionHydrationQueryData,
    GetSessionHydrationQueryVariables
  >,
) => useDHQuery(GetSessionHydration, options);

export const GetMyTasksQuery = (
  props: QueryArgs<GetMyTasksQueryData, GetMyTasksQueryVariables>,
) => Query(props);
export const GetTasksQuery = (
  props: QueryArgs<GetTasksQueryData, GetTasksQueryVariables>,
) => Query(props);
export const GetOfficeQuery = (
  props: QueryArgs<GetOfficeQueryData, GetOfficeQueryVariables>,
) => Query(props);
export const GetAppValuationReportQuery = (
  props: QueryArgs<
    GetAppValuationReportQueryData,
    GetAppValuationReportQueryVariables
  >,
) => Query(props);
export const GetAppValuationReportsQuery = (
  props: QueryArgs<
    GetAppValuationReportsQueryData,
    GetAppValuationReportsQueryVariables
  >,
) => Query(props);

export const useGetAppValuationReportsQuery = (
  options: HookQueryHookOptions<
    GetAppValuationReportsQueryData,
    GetAppValuationReportsQueryVariables
  >,
) => useDHQuery(GetAppValuationReports, options);

export const GetContactQuery = (
  props: QueryArgs<GetContactQueryData, GetContactQueryVariables>,
) => Query(props);
export const GetFlowInstancesQuery = (
  props: QueryArgs<GetFlowInstancesQueryData, GetFlowInstancesQueryVariables>,
) => Query(props);
export const GetExtendedEmailQuery = (
  props: QueryArgs<GetExtendedEmailQueryData, GetExtendedEmailQueryVariables>,
) => Query(props);
export const GetEmailTemplatesQuery = (
  props: QueryArgs<GetTemplatesQueryData, GetTemplatesQueryVariables>,
) => Query(props);
export const GetEmailTemplateQuery = (
  props: QueryArgs<GetEmailTemplateQueryData, GetEmailTemplateQueryVariables>,
) => Query(props);
export const GetFlowsQuery = (
  props: QueryArgs<GetFlowsQueryData, GetFlowsQueryVariables>,
) => Query(props);

export const useGetFlowsQuery = (
  options: HookQueryHookOptions<GetFlowsQueryData, GetFlowsQueryVariables>,
) => useDHQuery(GetFlows, options);

export const GetFlowQuery = (
  props: QueryArgs<GetFlowQueryData, GetFlowQueryVariables>,
) => Query(props);
export const GetAppStatusesQuery = (
  props: QueryArgs<GetAppStatusesQueryData, GetAppStatusesQueryVariables>,
) => Query(props);
export const GetFlowTemplateQuery = (
  props: QueryArgs<GetFlowTemplateQueryData, GetFlowTemplateQueryVariables>,
) => Query(props);

export const GetMyTasksForDashboardQuery = (
  props: QueryArgs<
    GetMyTasksForDashboardQueryData,
    GetMyTasksForDashboardQueryVariables
  >,
) => Query(props);
export const GetAccountForSubscriptionQuery = (
  props: QueryArgs<
    GetAccountForSubscriptionQueryData,
    GetAccountForSubscriptionQueryVariables
  >,
) => Query(props);
export const GetApproximateUpcomingOveragesChargeQuery = (
  props: QueryArgs<
    GetApproximateUpcomingOveragesChargeQueryData,
    GetApproximateUpcomingOveragesChargeQueryVariables
  >,
) => Query(props);
export const GetCouponQuery = (
  props: QueryArgs<GetCouponQueryData, GetCouponQueryVariables>,
) => Query(props);
export const GetInvoicesQuery = (
  props: QueryArgs<GetInvoicesQueryData, GetInvoicesQueryVariables>,
) => Query(props);
export const GetBillingDetailsQuery = (
  props: QueryArgs<GetBillingDetailsQueryData, GetBillingDetailsQueryVariables>,
) => Query(props);

export const GetContactsQuery = (
  props: QueryArgs<GetContactsQueryData, GetContactsQueryVariables>,
) => Query(props);

export const GetFlowSettingsQuery = (
  props: QueryArgs<GetFlowSettingsQueryData, GetFlowSettingsQueryVariables>,
) => Query(props);

export const useGetFlowSettings = (
  options: HookQueryHookOptions<
    GetFlowSettingsQueryData,
    GetFlowSettingsQueryVariables
  >,
) => useDHQuery(GetFlowSettings, options);

export const GetAppStatusRealworksQuery = (
  props: QueryArgs<
    GetAppStatusRealworksQueryData,
    GetAppStatusRealworksQueryVariables
  >,
) => Query(props);

// For testing purposes
// export const GetTestDataQuery = (
//   props: QueryArgs<GetTestDataQueryData, GetTestDataQueryVariables>,
// ) => Query(props);

export default Query;
