import cleanedFilename from '~/util/cleanedFilename';

export default {
  EMAIL_SELECT_CONTAINER: `${cleanedFilename(
    __filename,
  )}-email-select-container`,
  EMAIL_SELECT_SEARCH_INPUT: `${cleanedFilename(
    __filename,
  )}-email-select-search-input`,
};
