import { clone, isEmpty } from 'ramda';
import { Node, XYPosition } from 'reactflow';
import { ClientFlowAction } from '~/graphql/types.client';
import { DEFAULT_WIDTH, DEFAULT_HEIGHT } from '../../components/nodeTypes';
import { HandlerId } from '../../components/nodeTypes/components/IfElseCard';
import getDirectParentIdsOfNode from '../getDirectParentIdsOfNode';

const SPACE_FOR_EDGES = 100;
const NODE_WIDTH = DEFAULT_WIDTH + SPACE_FOR_EDGES;
const NODE_HEIGHT = DEFAULT_HEIGHT + 75;
const SPACE_FOR_TARGET_PORTAL = 75;

type PortalNodeData = {
  /** source action id */
  sourceId?: string;

  /** target action id */
  targetId?: string;

  /** Used for source portals. Is it true path, false path or base path */
  ifElseChildKey: HandlerId | null;
};

type PositionedNode = {
  id: string;
  x: number | undefined;
  y: number;
  parentIds: Array<string>;
  children: Array<Node>;
  type?: string;
} & PortalNodeData;

type PositionedNodes = {
  [id: string]: PositionedNode;
};

type ReturnType = Array<{
  id: string;
  data: { id: string; parentId: string } | ClientFlowAction;
  position: {
    x: number;
    y: number;
  };
  type?: string;
}>;
const createGraphLayout = (
  nodes: Array<Node>,
  renderedNodes: Array<Node<any>>,
): ReturnType => {
  const elementToChildren = {};
  const newNodes = clone(nodes);

  for (const node of newNodes) {
    if (node.type === 'empty') {
      if (elementToChildren[node.data.parentId] == null) {
        elementToChildren[node.data.parentId] = [];
      }

      // make sure true case is always at index 0
      elementToChildren[node.data.parentId].unshift(node);
      continue;
    }

    if (node.type === 'source_portal') {
      if (elementToChildren[node.data.parentId] == null) {
        elementToChildren[node.data.parentId] = [];
      }

      const isFalseChild = node.data.ifElseChildKey === HandlerId.falseChild;

      elementToChildren[node.data.parentId][isFalseChild ? 1 : 0] = node;
    }

    if (node?.data.action && 'parentIds' in node.data.action) {
      node.data.action.parentIds.forEach(parentId => {
        if (elementToChildren[parentId] == null) {
          elementToChildren[parentId] = [];
        }

        const parent = nodes.find(n => n.id === parentId);
        const isTrueChild = parent?.data?.action?.trueChildId === node.id;

        // make sure true case is always at index 0
        if (isTrueChild) {
          elementToChildren[parentId].unshift(node);
        } else {
          elementToChildren[parentId].push(node);
        }
      });
    }
  }

  const positionedNodes: PositionedNodes = {};

  nodes.forEach(node => {
    const parentIds = getDirectParentIdsOfNode(newNodes, node);

    const height = renderedNodes.find(n => n.id === node.id)?.height;
    const defaultY = NODE_HEIGHT * parentIds.length;
    const offsetTop =
      height && height > DEFAULT_HEIGHT ? height - DEFAULT_HEIGHT : 0;

    positionedNodes[node.id] = {
      id: node.id,
      x: undefined,
      y: defaultY - offsetTop,
      parentIds,
      children: elementToChildren[node.id],
      type: node.type,
      sourceId: node.data?.sourceId || null,
      targetId: node.data?.targetId || null,
      ifElseChildKey: node.data?.ifElseChildKey,
    };
  });

  let emptyNodeAmount = 0;

  const addPositionX = (id: string | null, parentId: string | null) => {
    if (!id) return;

    const el = positionedNodes[id];

    if (el == null || isEmpty(el)) return;

    const newX = NODE_WIDTH * emptyNodeAmount;

    // only set x if the parentId is the direct parentId
    // so that initial positions persist after connecting another parent to the node
    const directParentId = el.parentIds[0];
    if (directParentId === parentId) {
      positionedNodes[id].x = newX;
    }

    const directChildsParentId =
      el.children?.[0]?.data?.action?.parentIds?.[0] ||
      el.children?.[0]?.data?.parentId;

    if (directChildsParentId !== id) {
      emptyNodeAmount += 1;
    }

    // Do not reset the x value for all the children of a node with multiple parents
    if (parentId && directParentId && directParentId !== parentId) return;

    /*
      Center the parent element:
      1. If current element is a false child, get its sibling's x (true child's x value),
      2. Calculate middle point with these two values
      3. Pass the calculated value to all previous parents until the previous IfElse parent
     */
    const parent = positionedNodes[directParentId];
    const isElFalseChild = parent?.children[1]?.id === id;

    if (parent?.type === 'IfElse' && isElFalseChild) {
      const trueChildId = parent.children[0].id;
      const trueChildX = positionedNodes[trueChildId].x || 0;

      for (const parentId of el.parentIds) {
        const isIfElseParent = positionedNodes[parentId].type === 'IfElse';
        const idx = el.parentIds.findIndex(n => n === parentId);

        if (idx !== 0 && isIfElseParent) {
          break;
        }

        positionedNodes[parentId].x = (trueChildX + newX) / 2;
      }
    }

    /* traverse left subtree */
    addPositionX(el.children?.[0]?.id, id);

    /* traverse right subtree */
    addPositionX(el.children?.[1]?.id, id);
  };

  addPositionX(nodes.find(n => n.type === 'Start')?.id || null, null);

  // push down target node's children (give more space for target nodes)
  for (const id in positionedNodes) {
    const element = positionedNodes[id];

    if (element.type === 'target_portal') {
      const firstIfElseParentIdx = element.parentIds.findIndex(
        id => positionedNodes[id].type === 'IfElse',
      );

      // how many action cards to skip, from the first IfElse parent until the target action
      const startFromIndex = firstIfElseParentIdx;

      const moveChildNodesDown = (id: string, currentIndex: number) => {
        const currentEl = positionedNodes[id];

        if (!id || !currentEl) return;

        if (currentIndex >= startFromIndex) {
          positionedNodes[id].y =
            (positionedNodes[id]?.y || 0) + SPACE_FOR_TARGET_PORTAL / 2;
        }

        if (currentEl?.children?.length > 0) {
          for (const child of currentEl.children) {
            moveChildNodesDown(child.id, currentIndex + 1);
          }
        }
        return;
      };

      // move down and level the actions on both branches of an IfElse action
      moveChildNodesDown(element.parentIds[startFromIndex], 0);
    }
  }

  // align parent and its parents in the center again
  for (const id in positionedNodes) {
    const element = positionedNodes[id];

    if (element.type === 'IfElse') {
      const trueChildId = element.children?.[0]?.id;
      const falseChildId = element.children?.[1]?.id;

      const falseChildX = falseChildId
        ? positionedNodes[falseChildId]?.x || 0
        : 0;
      const trueChildX = trueChildId ? positionedNodes[trueChildId]?.x || 0 : 0;
      const centerX = (trueChildX + falseChildX) / 2;

      positionedNodes[id].x = centerX;

      for (const parentId of element.parentIds) {
        const isIfElseParent = positionedNodes[parentId].type === 'IfElse';
        if (isIfElseParent) break;

        positionedNodes[parentId].x = centerX;
      }
    }
  }

  // give positions to target portals. do this in the end because action card positions must be determined by now
  for (const id in positionedNodes) {
    const element = positionedNodes[id];

    if (element.type === 'target_portal') {
      const targetNodeId = element.targetId;
      const targetNodesAtSameTarget = newNodes.filter(
        n => n.data?.targetId === targetNodeId && n.type === 'target_portal',
      );
      const targetNodeIdx = targetNodesAtSameTarget.findIndex(
        a => a.id === element.id,
      );

      const renderedWidth = renderedNodes.find(n => n.id === id)?.width || 0;

      const primaryParentId = element.parentIds[1];
      const isDirectFalseChildOfIfElseAction =
        nodes.find(
          n =>
            n.id === primaryParentId &&
            n.data?.action?.falseChildId === element.targetId,
        ) != null;
      const isDirectTrueChildOfIfElseAction =
        nodes.find(
          n =>
            n.id === primaryParentId &&
            n.data?.action?.trueChildId === element.targetId,
        ) != null;

      const sourceX =
        (element?.sourceId ? positionedNodes[element.sourceId]?.x : 0) || 0;
      const currentX = targetNodeId ? positionedNodes[targetNodeId].x || 0 : 0;
      const currentY = targetNodeId ? positionedNodes[targetNodeId].y : 0;

      const isOnRight = isDirectFalseChildOfIfElseAction
        ? true
        : isDirectTrueChildOfIfElseAction
        ? false
        : sourceX - currentX > 0;

      positionedNodes[id].x =
        currentX + (isOnRight ? DEFAULT_WIDTH - renderedWidth : 0);
      positionedNodes[id].y =
        currentY - SPACE_FOR_TARGET_PORTAL * (targetNodeIdx + 1);
    }
  }

  const res = newNodes.map(n => {
    const newPosition: XYPosition = {
      x: positionedNodes?.[n.id]?.x || 0,
      y: positionedNodes?.[n.id]?.y || 0,
    };

    return {
      ...n,
      position: newPosition,
    };
  });

  return res;
};

export default createGraphLayout;
