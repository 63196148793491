import cleanedFilename from '~/util/cleanedFilename';

export default {
  ADD_EMAIL_SYNCHRONISATION: `${cleanedFilename(
    __filename,
  )}-add-email-synchronisation`,
  SYNC_NEW_EMAIL_ACCOUNT_BUTTON: `${cleanedFilename(
    __filename,
  )}-sync-new-email-account-button`,
};
