import React, { useState } from 'react';
import { animated, UseTransitionProps, useTransition } from 'react-spring';

import { UpdateProfilePictureMutationVariables } from '~/graphql/types';
import { ImgDefinition } from '~/graphql/types.client.flow';

import EditableName from '~/components/DetailsPage/EditableName';
import { TitleContainer } from './ProfileHeader.style';
import { UpdateProfilePictureMutation } from '~/graphql/Mutation';
import mutation from '~/graphql/mutation/UpdateProfilePicture';
import cleanedFilename from '~/util/cleanedFilename';
import { ANIMATION_CONFIG } from '~/scenes/Settings/constants';
import uploadS3Image from '~/util/uploadS3Image';
import AvatarWithUploader from '../AvatarWithUploader';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import JustificationContainer from '../JustificationContainer';
import CopyTextBlock from '../CopyTextBlock';

import useViewingModeProps from '~/hooks/useViewingModeProps';

type Props = {
  // should be editable or read-only
  edit?: boolean | null | undefined;
  user:
    | { img?: ImgDefinition | null | undefined; name: string; id: string }
    | null
    | undefined;
  refetchUser: () => void;
  onImageUploadError: () => void;
  updateMyName: (name: string) => void;
};

const text = {
  userIdLabel: 'User ID',
};
const UserProfileHeader = ({
  edit,
  user,
  refetchUser,
  onImageUploadError,
  updateMyName,
}: Props) => {
  const viewingModeProps = useViewingModeProps();
  const account = useCurrentAccount();
  const [loading, setLoading] = useState(false);

  const imageUrl = user && user.img ? user.img.medium : '';
  const userName = user ? user.name : '';

  const transitionProps: UseTransitionProps = {
    from: ANIMATION_CONFIG.from,
    enter: ANIMATION_CONFIG.to,
    leave: ANIMATION_CONFIG.from,
    config: ANIMATION_CONFIG.config,
    trail: 125,
  };
  // Use transition instead of useSpring here because updates may cause a rerender/trigger of animation.
  const transitions = useTransition(true, transitionProps);

  return (
    <UpdateProfilePictureMutation
      name={cleanedFilename(__filename)}
      mutation={mutation}
      onCompleted={refetchUser}
    >
      {updateProfilePicture => {
        const onUploadImageDone = (
          identityId: string | null | undefined,
          filename: string,
        ) => {
          if (!identityId) return;

          const variables: UpdateProfilePictureMutationVariables = {
            accountId: account.id,
            identityId,
            filename,
          };

          return updateProfilePicture({ variables }).then(() => {
            setLoading(false);
          });
        };

        const onUploadImage = async (file: File) => {
          try {
            setLoading(true);
            const { identityId, filename } = await uploadS3Image({
              file,
            });

            return onUploadImageDone(identityId, filename);
          } catch {
            setLoading(false);
            onImageUploadError();
          }
        };

        return (
          <>
            {transitions(
              (style, item) =>
                item && (
                  <TitleContainer as={animated.div} style={style}>
                    <AvatarWithUploader
                      avatarFor="userProfile"
                      edit={edit}
                      imageUrl={imageUrl}
                      onUpload={files => onUploadImage(files[0])}
                      loading={loading}
                    />
                    <JustificationContainer direction="column" gap="xxs">
                      <EditableName
                        edit={edit}
                        updateName={updateMyName}
                        name={userName}
                        redacted={viewingModeProps['data-redacted']}
                      />
                      {user?.id && (
                        <CopyTextBlock
                          label={text.userIdLabel}
                          textToCopy={user.id}
                        />
                      )}
                    </JustificationContainer>
                  </TitleContainer>
                ),
            )}
          </>
        );
      }}
    </UpdateProfilePictureMutation>
  );
};

export default UserProfileHeader;
