import React from 'react';

import { FlowConditionList } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';
import { DescribeNodeElementType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { needsUserActionDescription } from '../../util';
import describeFlowCondition from '../FlowCondition/describeFlowCondition';

const describeFlowConditionList = (
  conditionList: FlowConditionList,
  describeOptions: DescribeOptions,
  inStart: boolean,
): DescribeNodeType => {
  const { allShouldBeTrue, conditions } = conditionList;

  if (conditions.length === 0)
    return needsUserActionDescription('Vul een conditie in');

  if (conditions.length === 1) {
    const description = describeFlowCondition(conditions[0], describeOptions);

    if (!inStart) return description;

    if (Array.isArray(description)) {
      return [' en ', ...description];
    }

    return [' en ', description];
  }

  const str: Array<DescribeNodeElementType> = [];
  let isFirst = true;
  conditions.forEach(condition => {
    const conditionDescription = describeFlowCondition(
      condition,
      describeOptions,
    );

    if (inStart || !isFirst) {
      str.push(
        <p>
          {allShouldBeTrue ? 'En ' : 'Of '} {conditionDescription}
        </p>,
      );
    } else {
      if (Array.isArray(conditionDescription)) {
        str.push(...conditionDescription);
      } else {
        str.push(conditionDescription);
      }
    }

    isFirst = false;
  });

  return str;
};

export default describeFlowConditionList;
