import { ComponentType } from 'react';
import { WizardStepProps } from '../Wizard/context/WizardContext';
// Zapier
import {
  id as ConnectZapierId,
  Component as ConnectZapierComponent,
  OutputType as ConnectZapierOutput,
} from './Apps/Zapier/ConnectZapier';
import {
  id as AddZapierEventId,
  Component as AddZapierEventComponent,
  OutputType as AddZapierEventOutput,
} from './Apps/Zapier/AddZapierEvent';
import {
  id as AddZapierTriggerId,
  Component as AddZapierTriggerComponent,
  OutputType as AddZapierTriggerOutput,
} from './Apps/Zapier/AddZapierTrigger';
import {
  id as ZapierNextStepsId,
  Component as ZapierNextStepsComponent,
  OutputType as ZapierNextStepsOutput,
} from './Apps/Zapier/ZapierNextSteps';

// Trustoo
import {
  id as AddTrustooKoppeling,
  Component as AddTrustooKoppelingComponent,
  OutputType as AddTrustooKoppelingOutput,
} from './Apps/Trustoo/AddTrustooKoppeling';
// Realworks
import {
  id as RealworksSetupId,
  Component as RealworksSetupComponent,
  OutputType as RealworksSetupOutput,
} from './Apps/Realworks/RealworksSetup';
import {
  id as RealworksImportContactsId,
  Component as RealworksImportContactsComponent,
  OutputType as RealworksImportContactsOutput,
} from './Apps/Realworks/RealworksImportContacts';
import {
  id as RealworksExportContactsId,
  Component as RealworksExportContactsComponent,
  OutputType as RealworksExportContactsOutput,
} from './Apps/Realworks/RealworksExportContacts';
import {
  id as RealworksActivationPendingId,
  Component as RealworksActivationPendingComponent,
  OutputType as RealworksActivationPendingOutput,
} from './Apps/Realworks/RealworksActivationPending';

// Waardecheck
import {
  id as WaardecheckGeneralId,
  Component as WaardecheckGeneralComponent,
  OutputType as WaardecheckGeneralOutput,
} from './Apps/Waardecheck/WaardecheckGeneral';
import {
  id as WaardecheckDesignId,
  Component as WaardecheckDesignComponent,
  OutputType as WaardecheckDesignOutput,
} from './Apps/Waardecheck/WaardecheckDesign';
// Hypotheekbond
import {
  id as HypotheekbondColorSettingsId,
  Component as HypotheekbondColorSettingsComponent,
  OutputType as HypotheekbondColorSettingsOutput,
} from './Apps/Hypotheekbond/HypotheekColorSettings';

// ValueReport
import {
  id as ValueReportGeneralImageSettingsId,
  Component as ValueReportGeneralImageSettingsComponent,
  OutputType as ValueReportGeneralImageSettingsOutput,
} from './Apps/ValueReport/ValueReportGeneralImageSettings';
import {
  id as ValueReportColorSettingsId,
  Component as ValueReportColorSettingsComponent,
  OutputType as ValueReportColorSettingsOutput,
} from './Apps/ValueReport/ValueReportColorSettings';

// Enable flows
import {
  id as EnableFlowsForAppStatusId,
  Component as EnableFlowsForAppStatusComponent,
  OutputType as EnableFlowsForAppStatusOutput,
} from './EnableFlowsForAppStatus/index';

// Overview
import {
  id as AppStatusOverviewId,
  Component as AppStatusOverviewComponent,
  OutputType as AppStatusOverviewOutput,
} from './AppStatusOverview';
import {
  id as GenericId,
  Component as GenericStep,
  OutputType as GenericOutputType,
} from './components/GenericStep';
import {
  id as GenericWidgetConfigurationId,
  Component as GenericWidgetConfigurationStep,
  OutputType as GenericWidgetConfigurationOutputType,
} from './components/GenericWidgetConfiguration';

export type StepId =
  // Generic step ids
  | typeof GenericId
  | typeof GenericWidgetConfigurationId
  // Zapier
  | typeof ConnectZapierId
  | typeof AddZapierEventId
  | typeof AddZapierTriggerId
  | typeof ZapierNextStepsId

  // Trustoo
  | typeof AddTrustooKoppeling

  // Realworks
  | typeof RealworksSetupId
  | typeof RealworksImportContactsId
  | typeof RealworksExportContactsId
  | typeof RealworksActivationPendingId
  // Waardecheck
  | typeof WaardecheckGeneralId
  | typeof WaardecheckDesignId

  // Hypotheekbond
  | typeof HypotheekbondColorSettingsId

  // ValueReport
  | typeof ValueReportGeneralImageSettingsId
  | typeof ValueReportColorSettingsId

  // Enable flow
  | typeof EnableFlowsForAppStatusId
  // Overview
  | typeof AppStatusOverviewId;

export type StepComponentMap = Record<StepId, ComponentType<WizardStepProps>>;

/**
 * Each new type of step we create should be added to this map
 * This map is used to look up the StepComponent by ID because we cannot serialise/persist the component in the db
 */

const stepComponentMap: StepComponentMap = {
  //Generic steps
  [GenericId]: GenericStep,
  [GenericWidgetConfigurationId]: GenericWidgetConfigurationStep,
  // Zapier
  [ConnectZapierId]: ConnectZapierComponent,
  [AddZapierEventId]: AddZapierEventComponent,
  [AddZapierTriggerId]: AddZapierTriggerComponent,
  [ZapierNextStepsId]: ZapierNextStepsComponent,

  // Trustoo
  [AddTrustooKoppeling]: AddTrustooKoppelingComponent,

  // Realworks
  [RealworksSetupId]: RealworksSetupComponent,
  [RealworksImportContactsId]: RealworksImportContactsComponent,
  [RealworksExportContactsId]: RealworksExportContactsComponent,
  [RealworksActivationPendingId]: RealworksActivationPendingComponent,

  // Waardecheck
  [WaardecheckGeneralId]: WaardecheckGeneralComponent,
  [WaardecheckDesignId]: WaardecheckDesignComponent,
  // Hypotheekbond
  [HypotheekbondColorSettingsId]: HypotheekbondColorSettingsComponent,

  // ValueReport
  [ValueReportGeneralImageSettingsId]: ValueReportGeneralImageSettingsComponent,
  [ValueReportColorSettingsId]: ValueReportColorSettingsComponent,

  // Enable flows
  [EnableFlowsForAppStatusId]: EnableFlowsForAppStatusComponent,
  // Overview
  [AppStatusOverviewId]: AppStatusOverviewComponent,
};

export type StepOutput =
  // Zapier
  | ConnectZapierOutput
  | AddZapierEventOutput
  | AddZapierTriggerOutput
  | ZapierNextStepsOutput
  // Trustoo
  | AddTrustooKoppelingOutput
  // Realworks
  | RealworksSetupOutput
  | RealworksImportContactsOutput
  | RealworksExportContactsOutput
  | RealworksActivationPendingOutput
  // Waardecheck
  | WaardecheckGeneralOutput
  | WaardecheckDesignOutput
  // Hypotheekbond
  | HypotheekbondColorSettingsOutput
  // ValueReport
  | ValueReportGeneralImageSettingsOutput
  | ValueReportColorSettingsOutput
  // Generic step outputs
  | GenericOutputType
  | GenericWidgetConfigurationOutputType
  // Enable flows
  | EnableFlowsForAppStatusOutput
  | AppStatusOverviewOutput;

export type OutputMap = Record<StepId, StepOutput>;

export default stepComponentMap;
