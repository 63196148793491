import React from 'react';
import styled, { css } from 'styled-components';

import Icon, { IconType } from '../Icon';
import Button, { Appearance } from '../Button';
import JustificationContainer, {
  Props as JustificationContainerProps,
} from '../JustificationContainer';
import CloseButton from '../CloseButton';
import { BaseColor } from '~/theme/System/tokens';
import TEST_ID from './index.testid';

export type IconSize = 'base' | 'large';
export type CTAIcon = {
  size: IconSize;
  name?: IconType;
  background?: BaseColor;
};

export type Props = {
  dataTestId?: string;
  header?: string;
  description?: string;

  /** Icon on the left */
  icon?: CTAIcon;

  /** Button on the right */
  button?: {
    label: string;
    appearance: Appearance;
    icon: IconType;
    onClick: () => void;
  };

  /** Margin of the container */
  margin?: JustificationContainerProps['margin'];

  /** Padding of the container */
  padding?: JustificationContainerProps['padding'];

  /** Aligns content at the top or the center */
  align?: 'start' | 'center';

  onClose?: () => void;
};

const CallToActionBlock: React.FC<Props> = ({
  dataTestId,
  icon,
  header,
  description,
  button,
  children,
  onClose,
  margin,
  padding = ['xl'],
  align,
  ...rest
}) => (
  <Container
    justification="space-between"
    align={align}
    margin={margin}
    padding={padding}
    dataTestId={dataTestId ?? TEST_ID.CONTAINER}
    {...rest}
  >
    <StyledIcon
      name={icon?.name || 'triangle'}
      background={icon?.background}
      color="#ffffff"
      size={icon?.size || 'base'}
    />

    <Inner>
      {header && <Heading>{header}</Heading>}
      {description}
      {children}
    </Inner>

    {button && (
      <StyledButton
        size="medium"
        label={button.label}
        icon={button.icon}
        appearance={button.appearance}
        onClick={button.onClick}
      />
    )}
    {onClose && (
      <StyledCloseButton onClick={onClose} dataTestId={TEST_ID.CLOSE_BUTTON} />
    )}
  </Container>
);

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    position: relative;
    background-color: ${theme.color('primary')};
    color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};

    ${theme.mq.lessThan('mobile')`
      flex-direction: column;
    `}
  `,
);

const Inner = styled.div<{}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) =>
    css`
      padding: 0 ${theme.space('xl')};

      ${theme.mq.lessThan('mobile')`
        margin-bottom: ${theme.space('m')};
        padding: 0;
        order: 3;
      `};
    `};
`;

const Heading = styled.div<{}>(
  ({ theme }) =>
    css`
      color: ${theme.color('white')};
      font-weight: ${theme.fontWeight('semiBold')};
      margin-bottom: ${theme.space('xxs')};
    `,
);

const StyledIcon = styled(Icon)<{ size: IconSize }>(
  ({ size, theme }) => css`
    font-size: ${size === 'base' ? '1.5em' : '2em'};
    & > svg {
      width: 0.8em;
      height: 0.8em;
      transform: translateY(-1px);
    }

    ${theme.mq.lessThan('mobile')`
      margin-bottom: ${theme.space('m')};
      align-self: flex-start;
      order: 2;
    `};
  `,
);

const StyledButton = styled(Button)<{}>`
  justify-content: flex-start;
  min-width: max-content;

  ${({ theme }) =>
    css`
      ${theme.mq.lessThan('mobile')`
        width: 100%;
        order: 4;
      `};

      &:hover {
        background-color: ${theme.color('white', 'dark')};
        color: ${theme.color('primary')};
      }
    `};
`;

const StyledCloseButton = styled(CloseButton)<{}>(
  ({ theme }) => css`
    align-self: flex-start;
    font-size: ${theme.fs('base')};
    margin-left: ${theme.space('m')};

    ${theme.mq.lessThan('mobile')`
      align-self: flex-end;
      order: 1;
    `};
  `,
);

export default CallToActionBlock;
