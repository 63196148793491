import React, { useRef } from 'react';
import { useSlate } from 'slate-react';
import styled from 'styled-components';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import ColorButton from '../Buttons/Color';
import InsertImageButton from '../Buttons/InsertImage';
import OptionsButton from '../Buttons/components/Options';
import InsertVariableButton from '../Buttons/InsertVariable';
import BaseButton from '../Buttons/components/Base';
import InsertLinkButton from '../Buttons/InsertLink';
import InsertHtmlButton from '../Buttons/InsertHtml';
import { HistoryEditor } from 'slate-history';
import MarkButton, { MarkStyles } from '../Buttons/Mark';
import BlockButton from '../Buttons/Block';
import {
  getAlignmentOptions,
  getFontFamilyOptions,
  getFontSizeOptions,
} from '../Buttons/components/Options/buttonOptions';
import { tooltipText } from '../Buttons/text';
import TEST_ID from './index.testid';
import { toggleIndent } from '../../commands/modify/block';
import { EdgeDetectionContext } from '../../hooks/useEdgeContext';

export type Props = {
  dataTestId?: string;
  customElements?: Array<ELEMENTS>;
  readOnly?: boolean;
};

const StaticToolbar: React.FC<Props> = ({
  dataTestId,
  customElements = [],
  readOnly = false,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const editor = useSlate();
  const alignmentOptions = getAlignmentOptions(editor);
  const fontSizeOptions = getFontSizeOptions(editor);
  const fontFamilyOptions = getFontFamilyOptions(editor);

  const toolbarRect = containerRef.current?.getBoundingClientRect();

  return (
    <EdgeDetectionContext.Provider
      value={{
        right: toolbarRect?.right,
      }}
    >
      <Container
        data-testid={dataTestId}
        {...rest}
        onClick={e => {
          if (readOnly) e.preventDefault();
        }}
        style={{ pointerEvents: readOnly ? 'none' : 'auto' }}
        ref={containerRef}
      >
        <MarkButton
          icon="bold"
          format={MarkStyles.STRONG}
          editor={editor}
          dataTestId={TEST_ID.BOLD_BUTTON}
        />
        <MarkButton
          icon="italic"
          format={MarkStyles.EM}
          editor={editor}
          dataTestId={TEST_ID.ITALIC_BUTTON}
        />
        <MarkButton
          icon="underline"
          format={MarkStyles.U}
          editor={editor}
          dataTestId={TEST_ID.UNDERLINE_BUTTON}
        />
        <Divider />

        <BlockButton
          icon="numbered-list"
          editor={editor}
          format={ELEMENTS.OL}
          dataTestId={TEST_ID.OL_BUTTON}
        />
        <BlockButton
          icon="list"
          editor={editor}
          format={ELEMENTS.UL}
          dataTestId={TEST_ID.UL_BUTTON}
        />
        <Divider />

        <BaseButton
          icon="indent"
          onClick={() => toggleIndent(editor, true)}
          tooltipMessage={tooltipText.indent}
          dataTestId={TEST_ID.INDENT_BUTTON}
        />
        <BaseButton
          icon="outdent"
          onClick={() => toggleIndent(editor, false)}
          tooltipMessage={tooltipText.outdent}
          dataTestId={TEST_ID.OUTDENT_BUTTON}
        />
        <OptionsButton
          icon="align-left"
          options={alignmentOptions}
          tooltipMessage={tooltipText.alignment}
          dataTestId={TEST_ID.ALIGN_BUTTON}
          persistFocus
          editor={editor}
        />
        <Divider />

        <ColorButton
          format="color"
          icon="text-color"
          editor={editor}
          dataTestId={TEST_ID.COLOR_BUTTON}
        />
        <ColorButton
          format="backgroundColor"
          icon="text-background"
          editor={editor}
          dataTestId={TEST_ID.BACKGROUND_COLOR_BUTTON}
        />
        <OptionsButton
          icon="font-size"
          options={fontSizeOptions}
          tooltipMessage={tooltipText.fontSize}
          dataTestId={TEST_ID.FONT_SIZE_BUTTON}
          persistFocus
          editor={editor}
        />
        <OptionsButton
          icon="type"
          options={fontFamilyOptions}
          tooltipMessage={tooltipText.fontFamily}
          dataTestId={TEST_ID.FONT_FAMILY_BUTTON}
          persistFocus
          editor={editor}
        />

        <Divider />

        {customElements.includes(ELEMENTS.DH_IMAGE) && (
          <InsertImageButton
            dataTestId={TEST_ID.INSERT_IMAGE_BUTTON}
            editor={editor}
          />
        )}

        <InsertLinkButton
          dataTestId={TEST_ID.INSERT_LINK_BUTTON}
          editor={editor}
        />
        <InsertHtmlButton
          dataTestId={TEST_ID.INSERT_HTML_BUTTON}
          editor={editor}
          customElements={customElements}
        />

        <BlockButton
          icon="quote"
          editor={editor}
          format={ELEMENTS.BLOCKQUOTE}
          dataTestId={TEST_ID.BLOCKQUOTE_BUTTON}
        />

        {customElements.includes(ELEMENTS.VARIABLE) && (
          <InsertVariableButton dataTestId={TEST_ID.INSERT_VARIABLE_BUTTON} />
        )}

        <Divider />

        <BaseButton
          icon="undo"
          onClick={() => HistoryEditor.undo(editor)}
          disabled={editor.history.undos.length === 0}
          tooltipMessage={tooltipText.undo}
          dataTestId={TEST_ID.UNDO_BUTTON}
        />
        <BaseButton
          icon="redo"
          onClick={() => HistoryEditor.redo(editor)}
          disabled={editor.history.redos.length === 0}
          tooltipMessage={tooltipText.redo}
          dataTestId={TEST_ID.REDO_BUTTON}
        />
      </Container>
    </EdgeDetectionContext.Provider>
  );
};

const Container = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  min-height: 20px;
`;

const Divider = styled.div<{}>`
  width: 1px;
  height: 2.2em;
  margin: 0 5px;
  align-self: center;
  background-color: ${({ theme }) => theme.color('grey', 'light')};
`;

export default StaticToolbar;
