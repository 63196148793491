import { WizardFlow } from '~/graphql/types.client';
import { id as stepId, title } from '../../components/GenericStep';
import { ExtendedAppConfig } from '~/hooks/useApps';
import getUWAppConfigurationItems from '~/util/getUWAppConfigurationItems';
import {
  title as genericWidgetConfigurationTitle,
  id as genericWidgetConfigurationId,
} from '../../components/GenericWidgetConfiguration';
import { StepId } from '../..';

/**
 * Returns default wizard flow for provided app
 * @param {ExtendedAppConfig} app
 * keywords: wizard flow, default wizard
 */

const getGenericWizardFlow = (app: ExtendedAppConfig): WizardFlow => {
  const id = app.__typename;
  const withWidget = getUWAppConfigurationItems(app.appStatus).length > 1;

  const enablementFlow: WizardFlow = {
    id,
    steps: [
      { title: title, id: stepId, metadata: { typename: app.__typename } },
    ],
  };

  if (withWidget) {
    return {
      ...enablementFlow,
      steps: [
        ...enablementFlow.steps,
        {
          title: genericWidgetConfigurationTitle,
          id: genericWidgetConfigurationId as StepId,
          metadata: { typename: app.__typename },
        },
      ],
    };
  }

  return enablementFlow;
};
export default getGenericWizardFlow;
