import { Descendant, Element, Text } from 'slate';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import { inlineElements, inlines } from '../../plugins/withInlineVoidElements';
import { last } from 'ramda';

interface AnyObject {
  [key: string]: any;
}
type TElement = Element &
  AnyObject & {
    type: string;
    children: Array<Descendant>;
  };

const isInlineNode = () => (node: Descendant) =>
  Text.isText(node) ||
  inlineElements.includes(node.type) ||
  (node.type === ELEMENTS.GENERIC_HTML_ELEMENT && inlines.includes(node.name));

const makeBlockLazy = (type: string) => (): TElement => ({
  // @ts-ignore
  type,
  children: [],
});

const hasDifferentChildNodes = (
  descendants: Array<Descendant>,
  isInline: (node: Descendant) => boolean,
): boolean =>
  descendants.some((descendant, index, arr) => {
    const prevDescendant = arr[index - 1];
    if (index !== 0) {
      return isInline(descendant) !== isInline(prevDescendant);
    }
    return false;
  });

/**
 * Handles 3rd constraint: "Block nodes can only contain other blocks, or inline and text nodes."
 */
const normalizeDifferentNodeTypes = (
  descendants: Array<Descendant>,
  isInline: (node: Descendant) => boolean,
  makeDefaultBlock: () => Element,
): Array<Descendant> => {
  const hasDifferentNodes = hasDifferentChildNodes(descendants, isInline);

  const { fragment } = descendants.reduce(
    (prev, node) => {
      if (hasDifferentNodes && isInline(node)) {
        let block = prev.precedingBlock;

        if (!block) {
          block = makeDefaultBlock();
          prev.precedingBlock = block;
          prev.fragment.push(block);
        }
        block.children.push(node);
      } else {
        prev.fragment.push(node);
        prev.precedingBlock = null;
      }

      return prev;
    },
    {
      fragment: [] as Array<Descendant>,
      precedingBlock: null as TElement | null,
    },
  );

  return fragment;
};

/**
 * Handles 1st constraint: "All Element nodes must contain at least one Text descendant."
 */
const normalizeEmptyChildren = (
  descendants: Array<Descendant>,
): Array<Descendant> => {
  if (!descendants.length) {
    return [{ text: '' }];
  }

  /**
   * Fixes 'Cannot resolve DOM point from Slate point' error when selectAll + Delete
   *
   * If the last child of a children array is a newline character Slate cannot focus on the element
   * because there is no corresponding element in the DOM
   *
   * With { text: '\n' } this is the element created in the DOM:
   *
   * <span data-slate-node="text">
   *   <span data-slate-leaf="true">
   *     <span data-slate-string="true"></span> // This point cannot be found
   *   </span>
   * </span>
   *
   * After adding { text: '' } this is added after the previous part in the dom:
   *
   * <span data-slate-node="text">
   *   <span data-slate-leaf="true">
   *     <span data-slate-zero-width="z" data-slate-length="0">&#xFEFF;</span>
   *   </span>
   * </span>
   *
   * This &#xFEFF; (Zero Width No-Break Space character) allows Slate to focus on the element
   */
  const lastChild = last(descendants);

  if (Text.isText(lastChild) && lastChild.text === '\n') {
    return [...descendants, { text: '' }];
  }

  return descendants;
};

const normalize = (
  descendants: Array<Descendant>,
  isInline: (node: Descendant) => boolean,
  makeDefaultBlock: () => Element,
) => {
  if (!descendants) return [];

  descendants = normalizeEmptyChildren(descendants);
  descendants = normalizeDifferentNodeTypes(
    descendants,
    isInline,
    makeDefaultBlock,
  );

  descendants = descendants?.map(node => {
    if (Element.isElement(node)) {
      return {
        ...node,
        children: normalize(node.children, isInline, makeDefaultBlock),
      };
    }
    return node;
  }) as Array<Descendant>;

  return descendants;
};

/** Top level element must be a block at all times */
const normalizeInlineElementsAtTopLevel = (
  descendants: Array<Descendant>,
  isInline,
): Array<Descendant> => {
  const allAreInline = descendants.every(n => isInline(n));

  if (allAreInline) {
    return [
      {
        type: ELEMENTS.DIV,
        children: descendants,
      },
    ] as Array<Descendant>;
  }

  return descendants;
};

/**
 * Normalize the descendants to a valid document fragment.
 */
const normalizeDescendantsToDocumentFragment = (
  descendants: Array<Descendant>,
) => {
  const isInline = isInlineNode();
  const makeDefaultBlock = makeBlockLazy(ELEMENTS.DIV);
  const topLevelAllDiv = normalizeInlineElementsAtTopLevel(
    descendants,
    isInline,
  );

  return normalize(topLevelAllDiv, isInline, makeDefaultBlock);
};

export default normalizeDescendantsToDocumentFragment;
