import { StripeSubscription } from '../../types.flow';

import cleanedFilename from '~/util/cleanedFilename';
import { reportErrorToTracker } from '~/util/assertion';
import { isBefore, isAfter } from '~/util/date';

const getActiveSubscription = (
  subscriptions: Array<StripeSubscription>,
): StripeSubscription | null => {
  if (subscriptions.length == 0) {
    return null;
  }

  let activeSubscription: StripeSubscription | null = null;

  const today = new Date();

  subscriptions.forEach(subscription => {
    if (subscription.startDate == null) {
      if (activeSubscription != null) {
        reportErrorToTracker(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | multiple active subscriptions in ${JSON.stringify(
            subscriptions,
            null,
            2,
          )}`,
        );
      } else {
        activeSubscription = subscription;
      }
    } else {
      if (
        isBefore(subscription.startDate, today) &&
        (subscription.endDate == null || isAfter(subscription.endDate, today))
      ) {
        if (activeSubscription != null) {
          reportErrorToTracker(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | multiple active subscriptions in ${JSON.stringify(
              subscriptions,
              null,
              2,
            )}`,
          );
        } else {
          activeSubscription = subscription;
        }
      }
    }
  });

  return activeSubscription;
};

export default getActiveSubscription;
