import { FlowParameterEventFieldsFragment } from '~/graphql/types';
import { AppFundaViewingRequestPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

import { PARAMETER_VALUE_TYPE } from '../../../constants';
import emptyFundaViewingRequestEventParameterValue from './emptyFundaViewingRequestEventParameterValue';

export default (
  eventFundaViewingRequestParameter:
    | Extract<
        FlowParameterEventFieldsFragment,
        {
          __typename: 'Flow_Parameter_Event_Contact_App_Funda_ViewingRequest_Pointer';
        }
      >
    | null
    | undefined,
): AppFundaViewingRequestPointerParameterValue => {
  if (eventFundaViewingRequestParameter == null) {
    return emptyFundaViewingRequestEventParameterValue();
  }
  switch (eventFundaViewingRequestParameter.__typename) {
    case 'Flow_Parameter_Event_Contact_App_Funda_ViewingRequest_Pointer':
      return {
        type: PARAMETER_VALUE_TYPE.EVENT_CONTACT_APP_FUNDA_VIEWINGREQUEST_POINTER,
        variable: {
          name: eventFundaViewingRequestParameter.variable,
          field: eventFundaViewingRequestParameter.field
            ? {
                name: eventFundaViewingRequestParameter.field,
              }
            : null,
        },
      };
    default:
      return assertNever(
        eventFundaViewingRequestParameter.__typename,
        cleanedFilename(__filename),
      );
  }
};
