import { isNil } from 'ramda';
import { IconType } from '~/components/Icon';
import { ClientFlowAction } from '~/graphql/types.client';

import { Issue } from '~/scenes/Automation/v2/state/flowIssues';
import theme from '~/theme';

type CardValue = {
  color: string;
  icon: IconType;
};

type CardValueMap = Record<ClientFlowAction['actionType'], CardValue>;

const actionColor = theme.color('success');

const cardValues: CardValueMap = {
  Start: {
    color: theme.color('info'),
    icon: 'star',
  },
  Wait: {
    color: '#AC86B3',
    icon: 'clock',
  },
  IfElse: {
    color: '#FC7E64',
    icon: 'branches',
  },
  SendEmail_Plain: {
    color: actionColor,
    icon: 'mail',
  },
  Contact_DeleteTag: {
    color: actionColor,
    icon: 'tag',
  },
  Contact_AddTag: {
    color: actionColor,
    icon: 'tag',
  },
  Task_Create: {
    color: actionColor,
    icon: 'list',
  },
  Zapier_Trigger: {
    color: actionColor,
    icon: 'send',
  },
  Realworks_SendContact: {
    color: actionColor,
    icon: 'send',
  },
  Contact_Assign: {
    color: actionColor,
    icon: 'user',
  },
  Contact_Details: {
    color: actionColor,
    icon: 'user',
  },
};

type CardIssuesMap = Record<string, CardValue>;

const issueValues: CardIssuesMap = {
  error: {
    color: theme.color('danger'),
    icon: 'exclamation',
  },
  warning: {
    color: theme.color('warning'),
    icon: 'exclamation',
  },
};

const defaultValues: { color: string; icon: IconType } = {
  color: theme.color('secondary'),
  icon: 'question-mark',
};

type Args = {
  actionType: ClientFlowAction['actionType'];
  issueLevel: Issue['level'] | null;
};

export const getCardValues = ({ actionType, issueLevel }: Args): CardValue => {
  if (!isNil(issueLevel) && !isNil(issueValues[issueLevel])) {
    return issueValues[issueLevel];
  }

  return cardValues[actionType] || defaultValues;
};
