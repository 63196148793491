import { isNil } from 'lodash';
import { useRef, useEffect } from 'react';

/**
 * Creates DOM element to be used as React root.
 */
function createRootElement(id: string): Element {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

/**
 * Appends element as last child of body.
 */
function addRootElement(rootElem: Element) {
  if (document.body.lastElementChild)
    document.body.insertBefore(
      rootElem,
      document.body.lastElementChild.nextElementSibling,
    );
}

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 */
function usePortal(id: string = 'modal-portal'): HTMLElement {
  const rootElemRef = useRef<HTMLElement | null>(null);

  useEffect(
    function setupElement() {
      // Look for existing target dom element to append to
      const existingParent = document.querySelector(`#${id}`);
      // Parent is either a new root or the existing dom element
      const parentElem = existingParent || createRootElement(id);

      // If there is no existing DOM element, add a new one.
      if (!existingParent) {
        addRootElement(parentElem);
      }

      // Add the detached element to the parent
      if (!isNil(rootElemRef.current)) {
        parentElem.appendChild(rootElemRef.current);
      }

      return function removeElement() {
        if (!isNil(rootElemRef.current)) {
          rootElemRef.current.remove();
        }
      };
    },
    [id],
  );
  function getRootElem() {
    if (isNil(rootElemRef.current)) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
}

export default usePortal;
