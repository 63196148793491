import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import DetailsBlockSubTitle from '~/components/DetailsBlockSubTitle';
import { LinkButton } from '~/components/Buttons';
import Button from '~/components/Button';
import EditBillingDetailsSidebar from '../EditBillingDetailsSidebar';

import TEST_ID from './index.testid';
import EditPaymentDetailsSidebar from '../EditPaymentDetailsSidebar';
import { BillingDetailsFieldsFragment } from '~/graphql/types';
import ExternalLink from '~/components/ExternalLink';
import { Body } from '~/components/Typography/index';

const text = {
  billingTitle: 'Facturatie',
  paymentsTitle: 'Betaalmethode',
  changeBillingDetails: 'Wijzig factuurgegevens',
  showInvoices: 'Bekijk facturen',
  paymentDescription: 'Betaling vindt plaats via ',
  paymentTypeDescription: 'SEPA automatische incasso.',
  changePayment: 'Wijzig betaalmethode',
  emailLabel: 'E-mail voor facturatie',
  termsAndConditionsPdf: 'Algemene voorwaarden',
  processingAgreementPdf: '(Verwerkers)overeenkomst',
};
type Props = {
  billingDetails: BillingDetailsFieldsFragment;
  onSuccessfullUpdate: () => void;
};
const BillingDetailsBlockComponent = ({
  billingDetails,
  onSuccessfullUpdate,
}: Props) => {
  const [showEditBillingDetailsSidebar, setShowEditBillingDetailsSidebar] =
    useState(false);
  const [showEditPaymentDetailsSidebar, setShowEditPaymentDetailsSidebar] =
    useState(false);

  const { name, email, address, last4 } = billingDetails || {};

  let addressComponent: ReactElement | null = null;
  if (address != null) {
    const { line1, line2, postcode, city } = address;

    addressComponent = (
      <>
        {line1 == null ? (
          ''
        ) : (
          <Body withoutMargin size="base">
            {line1}
            <br />
          </Body>
        )}
        {line2 == null ? (
          ''
        ) : (
          <Body withoutMargin size="base">
            {line2}
            <br />
          </Body>
        )}
        {postcode == null && city == null ? (
          ''
        ) : (
          <Body withoutMargin size="base">
            {postcode} {city} <br />
          </Body>
        )}

        <br />
      </>
    );
  }

  return (
    <>
      <DetailsBlockSubTitle>{text.billingTitle}</DetailsBlockSubTitle>
      <BillingDetailsBlock>
        <InfoContainer>
          {name == null ? (
            ''
          ) : (
            <Body withoutMargin size="base">
              {name}
              <br />
            </Body>
          )}
          {addressComponent}
          {email == null ? (
            ''
          ) : (
            <Body withoutMargin size="base">
              <EmailTitle>{text.emailLabel}</EmailTitle>
              {email}
              <br />
            </Body>
          )}
          <ChangeBillingDetailsContainer>
            <LinkButton
              testId={TEST_ID.CHANGE_BILLING_DETAILS_BUTTON}
              onClick={() => setShowEditBillingDetailsSidebar(true)}
            >
              {text.changeBillingDetails}
            </LinkButton>
          </ChangeBillingDetailsContainer>
          <Button
            size="medium"
            data-testid={TEST_ID.GO_TO_INVOICES_BUTTON}
            onClick={() => void navigate('/-/settings/invoices')}
            label={text.showInvoices}
          />
        </InfoContainer>
      </BillingDetailsBlock>
      <DetailsBlockSubTitle>{text.paymentsTitle}</DetailsBlockSubTitle>
      <PaymentDetailsBlock>
        {text.paymentDescription}
        <PaymentTypeSpan>{text.paymentTypeDescription}</PaymentTypeSpan>
        {last4 != null && (
          <PaymentFrom
            data-testid={TEST_ID.BANK_ACCOUNT_NUMBER}
          >{`Van rekening **** **${last4.substring(0, 2)} ${last4.substring(
            2,
            4,
          )}`}</PaymentFrom>
        )}
        <ChangePaymentContainer>
          <LinkButton
            testId={TEST_ID.CHANGE_PAYMENT_BUTTON}
            onClick={() => setShowEditPaymentDetailsSidebar(true)}
          >
            {text.changePayment}
          </LinkButton>
        </ChangePaymentContainer>
      </PaymentDetailsBlock>
      <ContractDetailsBlock>
        <DetailsBlockSubTitle>Overeenkomst</DetailsBlockSubTitle>
        <p>
          <ExternalLink
            href="https://dhstash.s3-eu-west-1.amazonaws.com/DatHuis+(verwerkers)overeenkomst+v20201125.pdf"
            target="_blank"
            download="DatHuis verwerkers overeenkomst.pdf"
            data-testid={TEST_ID.PDF_LINK_PROCESSING}
          >
            {text.processingAgreementPdf}
          </ExternalLink>
        </p>
        <p>
          <ExternalLink
            href="https://dhstash.s3-eu-west-1.amazonaws.com/Nederland+ICT+Voorwaarden+2014+-+NL.pdf"
            target="_blank"
            download="NederlandICTVoorwaarden.pdf"
            data-testid={TEST_ID.PDF_LINK}
          >
            {text.termsAndConditionsPdf}
          </ExternalLink>
        </p>
      </ContractDetailsBlock>
      <EditBillingDetailsSidebar
        show={showEditBillingDetailsSidebar}
        onClose={hasUpdated => {
          setShowEditBillingDetailsSidebar(false);

          if (hasUpdated) {
            onSuccessfullUpdate();
          }
        }}
        billingDetails={billingDetails}
      />
      <EditPaymentDetailsSidebar
        show={showEditPaymentDetailsSidebar}
        billingEmail={email}
        billingName={name}
        currentLast4={last4}
        onClose={hasUpdated => {
          setShowEditPaymentDetailsSidebar(false);

          if (hasUpdated) {
            onSuccessfullUpdate();
          }
        }}
      />
    </>
  );
};

const ChangePaymentContainer = styled.div<{}>``;
const ChangeBillingDetailsContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('m')};
  `,
);

const PaymentTypeSpan = styled.span<{}>`
  font-weight: bold;
`;

const PaymentFrom = styled.p<{}>``;

const BillingDetailsBlock = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DetailContainer = styled.div<{}>(
  ({ theme }) => css`
    margin: ${theme.space('s')} 0 ${theme.space('xl')} 0;
  `,
);
const InfoContainer = styled(DetailContainer)<{}>`
  width: 100%;
`;
const PaymentDetailsBlock = styled(DetailContainer)<{}>``;
const ContractDetailsBlock = styled(DetailContainer)<{}>``;
const EmailTitle = styled.div<{}>`
  font-weight: bold;
`;
export default BillingDetailsBlockComponent;
