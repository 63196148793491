import {
  Flow_Parameter__Number__Input,
  Flow_Parameter_Number__Input,
} from '~/graphql/types';
import { NumberPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

export default (
  parameterValue: NumberPrimitiveParameterValue,
): Flow_Parameter__Number__Input => {
  let value: Flow_Parameter_Number__Input | null = null;
  if (parameterValue.value != null) {
    value = {
      Primitive: {
        value: parameterValue.value,
      },
    };
  }

  return {
    Number: value,
  };
};
