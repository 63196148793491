import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '~/components/Button';
import Input from '~/components/Input';
import JustificationContainer from '~/components/JustificationContainer';
import { withOverlay } from '~/components/ModalsV2/Overlay';
import TextButton from '~/components/TextButton';
import { useInsertZapierApiKeyMutation, ZapierApiKey } from '~/graphql/types';
import FormContainer from '~/components/ModalsV2/FormContainer';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import { isEmpty } from 'ramda';
import { isNonEmptyString } from '~/util/Validation/String';
import TEST_ID from './index.testid';

const text = {
  formHeader: 'Nieuwe API key',
  placeholder: 'Kies een naam bijv. "Marketingbureau Jan"',
  errorMessage:
    'Er is iets fout gegaan bij het toevoegen van de api key, probeer het later opnieuw.',
  save: 'Opslaan',
  cancel: 'Afbreken',
  validationMessage: 'Vul een waarde in',
};

export type Props = {
  onAddKey: (key: ZapierApiKey | undefined) => void;
  onClose: () => void;
  accountId: string;
  disableClosing?: boolean;
};

const AddApiKeyModal: React.FC<Props> = ({
  onAddKey,
  onClose,
  accountId,
  disableClosing,
  ...rest
}) => {
  const addToast = useAddToast();
  const [name, setName] = useState<string>('');

  const [insertZapierApiKey, { loading }] = useInsertZapierApiKeyMutation();

  return (
    <Container
      data-testid={TEST_ID.CONTAINER}
      {...rest}
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();

        return insertZapierApiKey({
          variables: {
            accountId,
            name,
          },
        }).then(({ data, errors }) => {
          if (errors && errors.length > 0) {
            addToast([formatToastMessage(text.errorMessage, 'danger')]);

            return;
          }

          onAddKey(data?.insertZapierApiKey);
          onClose();
        });
      }}
    >
      <FormContainer header={text.formHeader} onClose={onClose}>
        <Input
          placeholder={text.placeholder}
          value={name}
          onChange={e => setName(e.target.value)}
          validation={[
            (value: string) =>
              isNonEmptyString(value) ? true : text.validationMessage,
          ]}
          name="api-key-name"
        />

        <JustificationContainer
          justification="space-between"
          align="center"
          margin={['l', null, null]}
        >
          {!disableClosing && (
            <TextButton
              label={text.cancel}
              onClick={onClose}
              withoutPadding
              appearance="danger"
              type="button"
              dataTestId={TEST_ID.CANCEL}
            />
          )}
          <Button
            icon="check"
            label={text.save}
            type="submit"
            loading={loading}
            disabled={isEmpty(name)}
            dataTestId={TEST_ID.SUBMIT}
          />
        </JustificationContainer>
      </FormContainer>
    </Container>
  );
};

const Container = styled.form<{}>`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default withOverlay(AddApiKeyModal);
