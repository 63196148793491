import React from 'react';
import { Option } from '~/components/Inputs/SelectGroup';
import { SelectGroup } from '~/components/Inputs';
import JustificationContainer from '~/components/JustificationContainer';

type Props = {
  name: string;
  onChange: (arg0: { [key: string]: string }) => any;
  title: string;
  options: Array<Option>;
  selectedIndex: number;
  secondary?: boolean;
  primary?: boolean;
  className?: string;
};
type State = {};

class SelectGroupWrapper extends React.Component<Props, State> {
  shouldComponentUpdate(nextProps: Props) {
    return nextProps.selectedIndex !== this.props.selectedIndex;
  }

  onChange = (value: $Object) => {
    this.props.onChange({ [this.props.name]: value.option.value });
  };
  render() {
    const { title, selectedIndex, name, options, className } = this.props;

    return (
      <JustificationContainer
        className={className || ''}
        direction="column"
        gap="base"
      >
        <div>{title}</div>
        <SelectGroup
          size="small"
          appearance="secondary"
          onChange={this.onChange}
          selectedIndex={selectedIndex}
          options={options}
          dataTestid={`select_${name}`}
        />
      </JustificationContainer>
    );
  }
}

export default SelectGroupWrapper;
