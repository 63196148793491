import React from 'react';
import { Props } from '..';

const Enven: React.FC<Props> = ({ ...props }) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 39 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 9.49995V2.69995L33.4 6.09995L27.5 9.49995Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 32.3999L5.9 35.7999L11.8 39.1999V32.3999Z"
      fill="#BBCE00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.9 15.4V8.69995L11.8 12.1L5.9 15.4Z"
      fill="#80BA27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.9 15.3999L0 18.8999L5.9 22.1999V15.3999Z"
      fill="#9DC31A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 5.2998L5.9 8.69981L11.8 12.0998V5.2998Z"
      fill="#78B72A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 25.5999V18.8999L5.9 22.1999L0 25.5999Z"
      fill="#A7C712"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4 29L35.3 25.5999L29.4 22.2V29Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4 29V35.8L23.5 32.4L29.4 29Z"
      fill="#80BA27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.6 19.2998V24.4998L32.1 21.8998L36.6 19.2998Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 39.1999L29.4 35.7999L23.5 32.3999V39.1999Z"
      fill="#89BD23"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 6.3999L24.4 3.5999L19.5 0.899902V6.3999Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.9 29V35.8L0 32.4L5.9 29Z"
      fill="#CFD600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.4 12.4V8L38.2 10.2L34.4 12.4Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6 35.7998L11.8 39.1998L17.6 42.5998V35.7998Z"
      fill="#A7C712"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2 4.8999V11.6999L20.3 8.1999L26.2 4.8999Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.9 35.8L11.8 32.4L5.9 29V35.8Z"
      fill="#C5D200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.7 8.19995L27.9 11L32.7 13.7V8.19995Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 39.1999V32.3999L17.6 35.7999L11.8 39.1999Z"
      fill="#B1CA07"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.9 8.69995V15.4L0 12.1L5.9 8.69995Z"
      fill="#89BD23"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 18.8999L5.9 15.3999L0 12.0999V18.8999Z"
      fill="#95C01E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.3 25.5999L29.4 28.9999L35.3 32.3999V25.5999Z"
      fill="#6DB42C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4 35.8V29L35.3 32.4L29.4 35.8Z"
      fill="#78B72A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6 1.8999L11.8 5.2999L17.6 8.6999V1.8999Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 32.3999V25.5999L5.9 28.9999L0 32.3999Z"
      fill="#BBCE00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 12.0998V5.2998L17.6 8.69981L11.8 12.0998Z"
      fill="#6DB42C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.9 22.2L0 25.5999L5.9 29V22.2Z"
      fill="#B1CA07"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.8 12.2V16.7L33.9 14.4L37.8 12.2Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6 42.5998L23.5 39.1998L17.6 35.7998V42.5998Z"
      fill="#9DC31A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.4 20.8999L36.3 17.4999L30.4 14.0999V20.8999Z"
      fill="#66B22E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 32.3999V39.1999L17.6 35.7999L23.5 32.3999Z"
      fill="#95C01E"
    />
  </svg>
);

export default Enven;
