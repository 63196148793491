import React from 'react';
import MetaTags from 'react-meta-tags';
import AccountContext from '~/contexts/AccountContext';
import CreateActivitiesFullPage from '~/components/Dev/CreateActivitiesFullPage/index';
import { AccountContextProps } from '~/contexts/AccountContext';
import TEST_ID from './index.testid';
import { RouteComponentProps } from '@reach/router';

const text = {
  pageTitle: 'Create an activity',
};
const CreateActivities: React.FC<RouteComponentProps> = () => (
  <>
    <MetaTags>
      <title>{text.pageTitle}</title>
    </MetaTags>
    <AccountContext.Consumer>
      {({ account }: AccountContextProps) => {
        if (account == null) throw Error();
        const accountId = account.id;
        return (
          <CreateActivitiesFullPage
            accountId={accountId}
            dataTestid={TEST_ID.CreateActivities}
          />
        );
      }}
    </AccountContext.Consumer>
  </>
);

export default CreateActivities;
