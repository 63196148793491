import React from 'react';
import Markdown, { InlineAnchor } from '~/components/Markdown';
import { Heading5, Heading4, Variant } from '~/components/Typography/index';

export type Props = {
  dataTestId?: string;
  value: string;
};

const components = {
  h1: props => <Heading4 variant={Variant.primary} {...props} />,
  h2: props => <Heading5 variant={Variant.primary} {...props} />,
  a: props => (
    <InlineAnchor {...props} target="_blank" rel="noreferrer noopener" />
  ),
};

const CustomMarkdown: React.FC<Props> = ({ value, dataTestId }) => (
  <Markdown
    dataTestId={dataTestId}
    value={value}
    options={{ remarkReactComponents: components }}
  />
);

export default CustomMarkdown;
