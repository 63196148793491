import React from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '~/theme';
import { Checkbox } from '../Inputs';
import MasonryCard from '../MasonryCard';
import RadioButton from '../RadioButton';

export type Props = {
  dataTestId?: string;
  checkboxTitle: string;
  description?: string;
  type: 'checkbox' | 'radio';

  checked: boolean;
  disabled?: boolean;

  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const SelectBlock: React.FC<Props> = ({
  dataTestId,
  type,
  checkboxTitle,
  checked,
  disabled,
  description,
  children,
  onClick,
  ...rest
}) => {
  const componentByType = {
    checkbox: StyledCheckbox,
    radio: StyledRadioButton,
  };

  const ComponentByType: React.FC<CheckedProps> =
    componentByType[type] ?? componentByType.checkbox;

  // When the component is disabled we shouldn't be able to do anything with the component
  const _onClick = disabled ? () => {} : onClick;

  return (
    <MasonryCardContainer
      dataTestId={dataTestId}
      checked={checked}
      disabled={disabled}
      onClick={_onClick}
      {...rest}
    >
      <ComponentByType
        value={checked}
        label={checkboxTitle}
        checked={checked}
        disabled={disabled}
        onChange={_onClick}
        dataTestid={`${type}-test-id`}
      />
      {description && <Description>{description}</Description>}
      {children}
    </MasonryCardContainer>
  );
};

type CheckedProps = {
  value?: boolean;
  label?: string;
  checked: boolean;
  disabled?: boolean;
  dataTestid?: string;
  onClick?: Props['onClick'];
  onChange?: Props['onClick'];
};

const getComponentColor = (theme: Theme, disabled: boolean = false): string =>
  disabled ? theme.color('grey') : theme.color('primary', 'light');

const MasonryCardContainer = styled(MasonryCard)<CheckedProps>`
  user-select: none;

  ${({ theme, checked, disabled }) => css`
    margin-bottom: ${theme.space('l')};
    padding: ${theme.space('l')};
    min-height: unset;

    border: ${theme.getTokens().border.width.base} solid
      ${checked ? getComponentColor(theme, disabled) : 'transparent'};
    transition: all 0.3ease;
    pointer-events: ${disabled ? 'none' : 'auto'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    &:hover {
      box-shadow: ${theme.boxShadow('cardHover')};
    }
  `}
`;

const StyledRadioButton = styled(RadioButton)<CheckedProps>(
  ({ theme, checked, disabled }) => css`
    cursor: inherit;
    font-weight: ${theme.fw('semiBold')};
    color: ${checked
      ? getComponentColor(theme, disabled)
      : theme.color('text')};
    margin-top: 0;
  `,
);

const StyledCheckbox = styled(Checkbox)<CheckedProps>(
  ({ theme, checked, disabled }) => css`
    cursor: inherit;
    font-weight: ${theme.fw('semiBold')};
    color: ${checked
      ? getComponentColor(theme, disabled)
      : theme.color('text')};

    & > input {
      :hover,
      :focus {
        border-color: ${theme.color('grey')};
      }
    }
  `,
);

const RADIO_MARGIN = '28px';
const CHECKBOX_MARGIN = '35px';

const Description = styled.div<{ radioButton?: boolean }>(
  ({ theme, radioButton }) => css`
    margin-left: ${radioButton ? RADIO_MARGIN : CHECKBOX_MARGIN};
    margin-top: ${theme.space('m')};
  `,
);

export default SelectBlock;
