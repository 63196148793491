import {
  NumberField,
  StringField,
  TypedField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';

export const FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD = Object.freeze(
  {
    name: {
      name: 'name',
      label: 'Naam',
      type: FIELD_TYPE.STRING,
    } as StringField,
    phone: {
      name: 'phone',
      label: 'Telefoonnummer',
      type: FIELD_TYPE.STRING,
    } as StringField,
    email: {
      name: 'email',
      label: 'E-mailadres',
      type: FIELD_TYPE.STRING,
    } as StringField,
    why: {
      name: 'why',
      label: 'Reden aanvraag',
      type: FIELD_TYPE.STRING,
      options: [
        'Mijn huis verkopen',
        'Een huis kopen',
        'Mijn hypotheek oversluiten',
        'Mijn huis verbouwen',
        'Mijn WOZ waarde controleren',
        'Mij orienteren',
      ],
    } as StringField,
    when: {
      name: 'when',
      label: 'Verhuisintentie',
      type: FIELD_TYPE.STRING,
      options: [
        'Zo snel mogelijk',
        'Als mijn nieuwe huis is opgeleverd',
        'Als ik een huis heb gevonden',
        'Als ik mijn huis heb verkocht',
        'Weet ik nog niet',
      ],
    } as StringField,
    postcode: {
      name: 'postcode',
      label: 'Postcode',
      type: FIELD_TYPE.STRING,
    } as StringField,
    city: {
      name: 'city',
      label: 'Woonplaats',
      type: FIELD_TYPE.STRING,
    } as StringField,
    livingArea: {
      name: 'livingArea',
      label: 'Woonoppervlakte',
      type: FIELD_TYPE.NUMBER,
    } as NumberField,
    // Not for now, this is an enum field
    // type: {
    //   name: 'type',
    //   label: 'Woningtype',
    //   type: FIELD_TYPE.STRING,
    // },
    valuationPrice: {
      name: 'valuationPrice',
      label: 'Waardeschatting',
      type: FIELD_TYPE.NUMBER,
    },
  },
);

export const FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELDS: Array<TypedField> =
  Object.keys(FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD).map(
    key => FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD[key],
  );

export const DEFAULT_FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD: TypedField =
  FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD.name;
