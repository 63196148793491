import React from 'react';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';

import { ViewReportProps } from './ValueReport.type';
import {
  GetAppValuationReportQuery as ValueReportQueryProps,
  AppValuationReport_Update,
} from '~/graphql/types';

import { AppErrorScreen, Loading, NoResultSection } from '~/components';
import {
  DeleteAppValuationReportMutation,
  GetAppValuationReportQuery,
  UpdateAppValuationReportMutation,
} from '~/graphql';
import mutationUpdateAppValuationReport from '~/graphql/mutation/UpdateAppValuationReport';
import deleteMutation from '~/graphql/mutation/DeleteAppValuationReport';
import query from '~/graphql/query/GetAppValuationReport';
import ValueReportDetails from './ValueReportDetails';
import Header from './components/Header';
import cleanedFilename from '~/util/cleanedFilename';
import AccountContext from '~/contexts/AccountContext';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import formatToastMessage from '~/util/formatToastMessage';
import Catalog from '~/Catalog';
import { RouteComponentProps } from '@reach/router';
import withAddToastMessage from '~/components/ToastsContainer/withAddToastMessage';
import URLContainer, { URL_PREFIX } from './components/UrlContainer';
import { navigate } from 'gatsby';
import DetailsActionList from '~/components/DetailsPage/DetailsActionList';

type Props = RouteComponentProps<{ reportId: string }> & {
  addToast: any;
};

type State = {
  isPreviewModeOn: boolean;
  initialDataLoaded: boolean;
  hasUpdated: boolean;
  urlString: string;
};

const text = {
  noResultsText: Catalog.noResults,
  valueReportUpdateError:
    'Opslaan niet gelukt door foutieve waarden in het formulier',
  valueReportUpdateSuccess: 'Opslaan gelukt',
  imageError: Catalog.imageUploadErrorMessage,
  pageTitle: 'Waarderapport',
  deleteLabel: 'Verwijderen',
  confirmDelete: 'Weet je zeker dat je dit rapport wilt verwijderen?',
  viewReport: 'Bekijk rapport',
};

class ValueReportDetailsContainer extends React.Component<Props, State> {
  state: State = {
    isPreviewModeOn: false,
    initialDataLoaded: false,
    hasUpdated: false,
    urlString: '',
  };

  switchPreviewModeOn = () => {
    this.setState({ isPreviewModeOn: !this.state.isPreviewModeOn });
  };

  setSuccessState = () => {
    this.setState({
      hasUpdated: true,
    });

    // hasUpdated must be set to false after saving with the save bar otherwise we don't get the new changes after saving once
    setTimeout(
      () =>
        this.setState({
          hasUpdated: false,
        }),
      1000,
    );
  };

  setInitialDataLoaded = () => {
    this.setState({
      initialDataLoaded: true,
    });
  };

  render() {
    const { reportId, addToast, ...props } = this.props;
    const { initialDataLoaded, hasUpdated, isPreviewModeOn } = this.state;

    const showErrorMessage = () => {
      addToast(formatToastMessage(text.valueReportUpdateError, 'danger'));
    };
    const showImageUpdateErrorMessage = () => {
      addToast(formatToastMessage(text.imageError, 'danger'));
    };

    if (!reportId) {
      return <AppErrorScreen />;
    }

    return (
      <>
        <MetaTags>
          <title>{text.pageTitle}</title>
        </MetaTags>
        <AccountContext.Consumer>
          {({ account }) => (
            <GetAppValuationReportQuery
              name={cleanedFilename(__filename)}
              query={query}
              variables={{
                accountId: account.id,
                id: reportId,
              }}
            >
              {({ data, refetch, loading }) => {
                if (loading && !initialDataLoaded) return <Loading />;
                if (!data) return <AppErrorScreen />;

                const convertedData = convertDataToProps(data);
                const refetchAppValuationReport = () => {
                  if (!initialDataLoaded) this.setInitialDataLoaded();
                  return refetch();
                };

                if (convertedData) {
                  return (
                    <ContentContainerDefault
                      breadcrumbs={[
                        {
                          to: '/apps/value-report',
                          label: 'Waarderapporten',
                        },
                        {
                          label: convertedData.name,
                        },
                      ]}
                    >
                      <UpdateAppValuationReportMutation
                        name={cleanedFilename(__filename)}
                        mutation={mutationUpdateAppValuationReport}
                        onCompleted={async () => {
                          await refetchAppValuationReport();
                          this.setSuccessState();
                        }}
                        onError={showErrorMessage}
                      >
                        {(updateAppValuationReport, { loading }) => {
                          const updateReport = (
                            update: AppValuationReport_Update,
                          ) =>
                            updateAppValuationReport({
                              variables: {
                                accountId: account.id,
                                id: reportId,
                                update,
                              },
                            });

                          return (
                            <React.Fragment>
                              <HeaderContainer>
                                <Header
                                  name={convertedData.name}
                                  switchPreviewModeOn={this.switchPreviewModeOn}
                                  isPreviewModeOn={isPreviewModeOn}
                                  updateReportName={value =>
                                    updateReport({ name: value })
                                  }
                                />
                                <DeleteAppValuationReportMutation
                                  name={__filename}
                                  mutation={deleteMutation}
                                  onError={showErrorMessage}
                                >
                                  {deleteAppValuationReport => (
                                    <DetailsActionList
                                      options={[
                                        {
                                          label: text.viewReport,
                                          icon: 'eye',
                                          key: 'view',
                                          onClick: () => {
                                            global.window.open(
                                              `https://${URL_PREFIX}${convertedData.route}`,
                                            );
                                          },
                                        },
                                        {
                                          label: text.deleteLabel,
                                          icon: 'trashcan',
                                          type: 'DELETE',
                                          key: 'delete',
                                          onClick: () => {
                                            const confirmation = confirm(
                                              text.confirmDelete,
                                            );

                                            if (confirmation) {
                                              void deleteAppValuationReport({
                                                variables: {
                                                  accountId: account.id,
                                                  id: reportId,
                                                },
                                              }).then(() =>
                                                navigate(
                                                  '/-/apps/value-report',
                                                ),
                                              );
                                            }
                                          },
                                        },
                                      ]}
                                    />
                                  )}
                                </DeleteAppValuationReportMutation>
                              </HeaderContainer>
                              <URLContainer
                                loading={loading}
                                route={convertedData.route}
                                reportId={reportId}
                                accountId={account.id}
                                refetchAppValuationReport={
                                  refetchAppValuationReport
                                }
                              />

                              <ValueReportDetails
                                isPreviewModeOn={isPreviewModeOn}
                                handleUpdateReport={updateReport}
                                loading={loading}
                                isSuccess={hasUpdated}
                                onImageError={showImageUpdateErrorMessage}
                                data={convertedData.data}
                                {...props}
                              />
                            </React.Fragment>
                          );
                        }}
                      </UpdateAppValuationReportMutation>
                    </ContentContainerDefault>
                  );
                }
                return <NoResultSection>{text.noResultsText}</NoResultSection>;
              }}
            </GetAppValuationReportQuery>
          )}
        </AccountContext.Consumer>
      </>
    );
  }
}

const HeaderContainer = styled.div<{}>`
  display: flex;
  justify-content: space-between;
`;

type ConvertedData = {
  name: string;
  route: string;
  data: ViewReportProps;
};
// export for test
export const convertDataToProps = (
  data: ValueReportQueryProps,
): ConvertedData | null => {
  const valueReportData = data.getAppValuationReport || null;
  if (
    !valueReportData ||
    valueReportData.__typename !== 'App_ValuationReport'
  ) {
    return null;
  }

  const {
    general,
    address,
    livingArea,
    houseType,
    comparableProperties,
    userData,
    report,
    route,
  } = valueReportData;

  /**
   * Those types has issue, if you are here and you
   * have some capacity over, we should probably make them
   * follow the gql types ?
   */
  return {
    name: valueReportData.name,
    route,
    data: {
      general: {
        logoImage: general.logoImage,
        logoPosition: general.logoPosition,
        logoLink: general.logoLink,
        backgroundImage: general.backgroundImage,
        mainColor: general.mainColor,
        headerTextColor: general.headerTextColor,
        accentColor: general.accentColor,
        // @ts-ignore
        nextButton: {
          backgroundColor: general.nextButton.backgroundColor,
          mainText: {
            text: general.nextButton.mainText.text,
            color: general.nextButton.mainText.color,
          },
        },
        step: {
          color: general.step.color,
          backgroundColor: general.step.backgroundColor,
        },
        backgroundColor: general.backgroundColor,
        navbarBackgroundColor: general.navbarBackgroundColor,
        cookiePolicy: general.cookiePolicy,
        privacyPolicy: general.privacyPolicy,
        googleAnalytics: general.googleAnalytics,
        facebookPixel: general.facebookPixel,
      },
      address: {
        mainHeader: {
          text: address.mainHeader.text,
        },
        subHeader: {
          text: address.subHeader.text,
        },
        why: {
          text: address.why.text,
        },
        when: {
          text: address.when.text,
        },
      },
      livingArea: {
        mainHeader: {
          text: livingArea.mainHeader.text,
        },
        subHeader: {
          text: livingArea.subHeader.text,
        },
      },
      houseType: {
        mainHeader: {
          text: houseType.mainHeader.text,
        },
        subHeader: {
          text: houseType.subHeader.text,
        },
      },
      // @ts-ignore
      comparableProperties: {
        mainHeader: {
          text: comparableProperties.mainHeader.text,
        },
      },
      userData: {
        mainHeader: {
          text: userData.mainHeader.text,
        },
        subHeader: {
          text: userData.subHeader.text,
        },
        formSubtext: {
          text: userData.formSubtext.text,
          color: userData.formSubtext.color,
        },
      },
      report: {
        contactButton: {
          mainText: {
            text: report.contactButton.mainText.text,
            color: report.contactButton.mainText.color,
          },
          subText: {
            text: report.contactButton.subText.text,
            color: report.contactButton.subText.color,
          },
          backgroundColor: report.contactButton.backgroundColor,
        },
        contactButtonClickedText: {
          text: report.contactButtonClickedText.text,
          color: report.contactButtonClickedText.color,
        },
        formSubtext: {
          text: report.formSubtext.text,
          color: report.formSubtext.color,
        },
      },
    },
  };
};

// Mocked props for certain value report page.
// export for test
export const mockProps = {
  permalink: 'https://waarderapport.hendriks.nl/amsterdam-kerst',
  preview: {
    link: 'https://somelink',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  data: {
    settings: {
      swipeFunction: 0,
      receiveByEmail: 0,
      email: 'some_email@gmail.com',
    },
  },
};

export default withAddToastMessage(ValueReportDetailsContainer);
