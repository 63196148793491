import React from 'react';

import { EmailAliasType } from './EmailAlias';

import { DeleteEmailAliasMutation } from '~/graphql';
import { DeleteInputButton } from '~/components/Buttons';
import deleteMutation from '~/graphql/mutation/DeleteEmailAlias';

import cleanedFilename from '~/util/cleanedFilename';

import TEST_ID from './EmailAliasDeleteButton.testid';
import useErrorModal from '~/components/Alerts/useErrorModal';

const text = {
  deleteErrorTitle: 'Oeps!',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van de alias. Probeer het nog eens.',
};
type Props = {
  accountId: string;
  emailAlias: EmailAliasType | null;
  onSuccessfulDelete: () => void;
  disabled: boolean;
};
const EmailAliasDeleteButton = ({
  emailAlias,
  onSuccessfulDelete,
  accountId,
  disabled,
}: Props) => {
  const [errorModal, showErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.deleteErrorMessage,
  });
  const { state, email } = emailAlias || { state: null, email: null };

  return (
    <DeleteEmailAliasMutation name={__filename} mutation={deleteMutation}>
      {(deleteEmailAlias, { loading }) => (
        <>
          <DeleteInputButton
            dataTestId={TEST_ID.DELETE_BUTTON}
            disabled={disabled || state == null || email == null || loading}
            loading={loading}
            onClick={() => {
              if (email == null) {
                throw Error(
                  `${cleanedFilename(
                    __filename,
                  )} | Should not occur | email is null even though the button should then be disabled!`,
                );
              }
              deleteEmailAlias({
                variables: { emailAlias: email, accountId },
              })
                .then(mutationResult => {
                  if (mutationResult && mutationResult.data) {
                    const { success } = mutationResult.data.deleteEmailAlias;

                    if (success) {
                      onSuccessfulDelete();
                    } else {
                      showErrorModal();
                    }
                  } else {
                    showErrorModal();
                  }
                })
                .catch(() => {
                  showErrorModal();
                });
            }}
          />
          {errorModal}
        </>
      )}
    </DeleteEmailAliasMutation>
  );
};

export default EmailAliasDeleteButton;
