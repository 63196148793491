import { FlowConditionAppVBOWaardecheckReportDetails } from './types.flow';

import {
  FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD,
  DEFAULT_FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD,
} from './constants';
import {
  asConditionFieldProp,
  emptyConditionField,
} from '~/scenes/Automation/Flows/Actions/Base';
import { asVBOWaardecheckReportEventParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event';
import equalsEventPointer from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/Event/equalsEventPointer';
import cleanedFilename from '~/util/cleanedFilename';
import { AppVBOWaardecheckReportPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ConditionField } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/types.flow';
import { FlowConditionFieldsFragment } from '~/graphql/types';

type ConditionType = Extract<
  FlowConditionFieldsFragment,
  {
    __typename: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details';
  }
>;

type ExpectedConditionEventType = Extract<
  ConditionType['event'],
  {
    __typename: 'Flow_Parameter_Event_Contact_App_VBOWaardecheck_Report_Pointer';
  }
>;

type ExpectedConditionType = ConditionType & {
  event: ExpectedConditionEventType;
};

const isConditionEventAppVBOWaardecheckReportPointer = (
  event: ConditionType['event'],
): event is ExpectedConditionEventType =>
  event.__typename ===
  'Flow_Parameter_Event_Contact_App_VBOWaardecheck_Report_Pointer';

const isExpectedEventCondition = (
  condition: ConditionType,
): condition is ExpectedConditionType =>
  isConditionEventAppVBOWaardecheckReportPointer(condition.event);

const asFlowConditionAppVBOWaardecheckReportDetailsProp = (
  conditions: Array<ConditionType>,
  allShouldBeTrue: boolean,
): Omit<FlowConditionAppVBOWaardecheckReportDetails, 'id'> => {
  const fields: Array<ConditionField> = [];
  let eventParameter: AppVBOWaardecheckReportPointerParameterValue | null =
    null;

  conditions.forEach(condition => {
    if (!isExpectedEventCondition(condition)) return;
    const { event } = condition;
    const newEvent = asVBOWaardecheckReportEventParameterValue(event);
    if (
      eventParameter != null &&
      !equalsEventPointer(eventParameter, newEvent)
    ) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | different events in the conditions (${JSON.stringify(
          conditions,
          null,
          2,
        )})`,
      );
    }
    eventParameter = newEvent;

    let setField: ConditionField = emptyConditionField(
      DEFAULT_FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD,
    );

    Object.keys(FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD).forEach(
      fieldName => {
        if (condition[fieldName] != null) {
          setField = asConditionFieldProp(
            FLOW_CONDITION_VBOWAARDECHECK_REPORT_DETAILS_FIELD[fieldName],
            condition[fieldName],
          );
          fields.push(setField);
        }
      },
    );
  });

  if (eventParameter == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | no event found in conditions (${JSON.stringify(
        conditions,
        null,
        2,
      )})`,
    );
  }

  return {
    type: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details',
    event: eventParameter,
    fields,
    allShouldBeTrue,
  };
};

export default asFlowConditionAppVBOWaardecheckReportDetailsProp;
