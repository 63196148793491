import { ActivityV2 } from '~/components/EventTimelineV2/types';
import { ThemeColor } from '~/theme/System/tokens/colorPalette';

type Args = {
  typename: ActivityV2['__typename'];
  isTaskClosed?: boolean;
};

const getHeaderBackgroundForEvent = ({
  typename,
  isTaskClosed,
}: Args): ThemeColor => {
  switch (typename) {
    case 'Event_Contact_Call': {
      return { group: 'accent', variant: 'translucent' };
    }

    case 'Event_Contact_Note': {
      return { group: 'warning', variant: 'translucent' };
    }

    case 'Event_Contact_Email': {
      return { group: 'tertiary', variant: 'light' };
    }

    case 'Task_Contact': {
      return {
        group: isTaskClosed ? 'success' : 'text',
        variant: 'translucent',
      };
    }

    default: {
      return { group: 'primary', variant: 'translucent' };
    }
  }
};

export default getHeaderBackgroundForEvent;
