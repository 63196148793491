import { path } from 'ramda';
import { useEffect } from 'react';

const getIdFromCursor: (obj: any) => string | undefined = path([
  'payload',
  0,
  'payload',
  'name',
]);

type Props = {
  setActiveGroup: (id: number | null) => void;

  // Made optional but will always be provided by Recharts
  payloadIndex?: number;
};
const FakeCursor: React.FC<Props> = ({ setActiveGroup, ...props }) => {
  useEffect(() => {
    const id = getIdFromCursor(props);
    if (id) {
      setActiveGroup(props.payloadIndex ?? 0);
    }

    return () => {
      setActiveGroup(null);
    };
  }, [props, setActiveGroup]);

  return null;
};

export default FakeCursor;
