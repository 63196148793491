import {
  HandledTemplateStringParameterValue,
  TemplateStringParameterValue,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import {
  HandledFlowParameterMapping,
  isHandledFlowParameterMapping,
} from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/FlowParameterMapping/types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';
import { UseTemplateStringParameterHTMLHandlerOptions } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/TemplateString/components/TemplateStringParameterHTMLHandler';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import {
  htmlToInsertForPointerVariable,
  grabAllVariablesInHtml,
  replaceVariableTagsWithHandlebars,
} from '~/components/HTMLEditor/util/variableHTML';
import { PARAMETER_VALUE_TYPE } from '../../constants';
import describeParameterValue from '../../describeParameterValue';
import { matchAll } from '~/util/string';
import cleanedFilename from '~/util/cleanedFilename';

// On backend we expect variables to be handlebar strings. So {{0}}
export const TEMPLATE_STRING_VARIABLE_REGEX = /{{.+?}}/g;

export const convertTemplateStringToHTML = (
  templateString: TemplateStringParameterValue,
  componentVariableUpdaterId: string,
  stash: FlowVariableStash,
  createBase64ImageForVariableText: (text: string) => string,
  options?: UseTemplateStringParameterHTMLHandlerOptions,
): string => {
  const convertedTemplateString = { ...templateString };

  if (options && options.stripHtml === true) {
    convertedTemplateString.template = templateString.template.replace(
      /\r?\n/g,
      '<br />',
    );
  }

  return splitTemplateString(convertedTemplateString, templateStringVariable =>
    htmlToInsertForPointerVariable(
      templateStringVariable,
      stash,
      componentVariableUpdaterId,
      createBase64ImageForVariableText,
    ),
  );
};

export const convertTemplateStringToDescription = (
  templateString: TemplateStringParameterValue,
  describeOptions: DescribeOptions,
): DescribeNodeType => {
  const splits = templateString.template.split(TEMPLATE_STRING_VARIABLE_REGEX);
  const matchAlls = matchAll(
    templateString.template,
    TEMPLATE_STRING_VARIABLE_REGEX,
  );

  const str: DescribeNodeType = [];
  splits.forEach((split, idx) => {
    str.push(split);

    const variableString = matchAlls[idx];
    if (variableString) {
      // string is {{id}}, so ignore the curly braces
      const mappingId = variableString.substring(2, variableString.length - 2);

      const variable = templateString.mappings.find(
        mapping => mapping.mappingId === mappingId,
      );

      if (variable == null) {
        // people can type {{}} in the field, we should just ignore that
        str.push(variableString);
      } else {
        const describedValue = describeParameterValue(
          variable.mapping,
          describeOptions,
          true,
        );

        if (Array.isArray(describedValue)) {
          str.push(...describedValue);
        } else {
          str.push(describedValue);
        }
      }
    }
  });

  return str;
};

const splitTemplateString = (
  { template, mappings }: TemplateStringParameterValue,
  variableConverter: (
    mapping: HandledFlowParameterMapping,
    id: string,
  ) => string,
): string =>
  template.replace(TEMPLATE_STRING_VARIABLE_REGEX, matchedVariable => {
    // Without the {{ }} tags
    const mappingId = matchedVariable.substring(2, matchedVariable.length - 2);

    const templateStringVariable = mappings.find(
      mapping => mapping.mappingId === mappingId,
    );

    if (templateStringVariable == null) {
      // If we can't find it the user could have typed something that resembles our variable matching. Just treat it as text
      return matchedVariable;
    }

    if (!isHandledFlowParameterMapping(templateStringVariable)) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} -> Cannot convert mapping from variable ${templateStringVariable} since mapping type:${
          templateStringVariable.mapping.type
        } is not supported`,
      );
    }

    return variableConverter(
      templateStringVariable,
      templateStringVariable.mappingId,
    );
  });

/**
 * A function to convert html to a template string.
 *
 * The html parameter will be used to determine the variables and what they are. Then the html parameter (or strippedHtml if given) will be
 * split on the variables with the {{<id>}} code placed in between.
 *
 * @param {*} html - The html to use for grabbing all the variable data. This should consist of the span of the markers with the information.
 * @param {*} strippedHtml - OPTIONAL: If given, this html will be used to fill the template string. It is expected to have the '@@@@' markers in it to locate the variables
 */
export const convertHTMLToTemplateString = (
  html: string,
  strippedHtml?: string,
): HandledTemplateStringParameterValue => {
  const mappings = grabAllVariablesInHtml(html);
  const template =
    strippedHtml == null
      ? replaceVariableTagsWithHandlebars(html)
      : strippedHtml;

  return {
    type: PARAMETER_VALUE_TYPE.TEMPLATE_STRING,
    template,
    mappings,
  };
};
