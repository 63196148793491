import gql from 'graphql-tag';

import FlowParameterOfficeFields from './FlowParameterOfficeFields';
import FlowParameterUserFields from './FlowParameterUserFields';

export default gql`
  fragment FlowActionContactAssignFields on Flow_Action_Contact_Assign {
    __typename
    id
    _v
    accountId
    flowBlueprintId
    parentId
    officeField: office {
      ...FlowParameterOfficeFields
    }
    user {
      ...FlowParameterUserFields
    }
    overwriteAssignee
  }

  ${FlowParameterOfficeFields}
  ${FlowParameterUserFields}
`;
