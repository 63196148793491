import { navigate } from 'gatsby';
import { isNil } from 'ramda';
import React from 'react';
import Button from '~/components/Button';
import {
  AdminUserInfo,
  ImpersonationViewingMode,
  useStartImpersonationMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useSessionHydration from '~/hooks/useSessionHydration';
import formatToastMessage from '~/util/formatToastMessage';
import TEST_ID from './index.testid';
import {
  ACCOUNT_ID_BEFORE_IMPERSONATION,
  setLocalStorageItem,
} from '~/util/localStorageKeys';
import useErrorReporter from '~/hooks/useErrorReporter';
import JustificationContainer from '~/components/JustificationContainer';

export type Props = {
  dataTestId?: string;
  accountId: string;
  cell: {
    row: {
      original: AdminUserInfo;
    };
  };
};

const UserActionColumnCell: React.FC<Props> = ({
  dataTestId,
  cell,
  accountId,
  ...rest
}) => {
  const [{ impersonatedBy, account }, refetch] = useSessionHydration();
  const addToast = useAddToast();
  const targetUser = cell.row.original;
  const errorReporter = useErrorReporter();

  const [startImpersonation, { loading }] = useStartImpersonationMutation({
    variables: {
      accountId,
      userId: targetUser.id,
      viewingMode: ImpersonationViewingMode.Complete,
    },
    onError: error => {
      errorReporter.captureException(error, 'critical');
      addToast([
        formatToastMessage(error.message, 'danger', {
          label: 'Refresh',
          onClick: () => (window.location.href = '/-/'),
          to: '/-/',
        }),
      ]);
    },
    onCompleted: data => {
      if (!data) {
        // We need to throw in the `onCompleted` to trigger `onError`
        throw new Error(
          'Could not start impersonation session, please refresh to see if you have any running sessions',
        );
      }
      setLocalStorageItem(ACCOUNT_ID_BEFORE_IMPERSONATION, account.id);
      return refetch({ accountId }).then(() => navigate('/-/'));
    },
  });

  return (
    <JustificationContainer data-testid={dataTestId} gap="s" {...rest}>
      <Button
        dataTestId={TEST_ID.BUTTON}
        loading={loading}
        label="Impersonate"
        icon="eye"
        appearance="danger"
        disabled={!isNil(impersonatedBy)}
        onClick={() => {
          void startImpersonation({
            variables: {
              accountId,
              userId: targetUser.id,
              viewingMode: ImpersonationViewingMode.Complete,
            },
          });
        }}
      />
      <Button
        dataTestId={TEST_ID.BUTTON}
        loading={loading}
        label="Demo mode"
        icon="lock"
        appearance="secondary"
        disabled={!isNil(impersonatedBy)}
        onClick={() => {
          void startImpersonation({
            variables: {
              accountId,
              userId: targetUser.id,
              viewingMode: ImpersonationViewingMode.Restricted,
            },
          });
        }}
      />
    </JustificationContainer>
  );
};

export default UserActionColumnCell;
