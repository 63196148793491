import React from 'react';
import styled, { css } from 'styled-components';

import { FlowCondition } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';
import { ConditionChoiceType } from './types.flow';

import TEST_ID from './FlowConditionComponent.testid';
import FlowConditionDetailsComponent from './FlowConditionDetailsComponent';
import { IconButton } from '~/components/Buttons';
import getConditionTypeOptions from './util/getConditionTypeOptions';
import Icon from '~/components/Icon';

const text = {
  selectCondition: (label?: string) =>
    `Selecteer een ${label || 'conditietype'}`,
  conditionLabel: (num: number) => `Conditie ${num}: `,
};
type Props = {
  conditionLabel?: string;
  condition: FlowCondition;

  /** The number to show in the list */
  conditionNumber: number;
  onChange: (newFlowCondition: FlowCondition) => void;
  onDropdownOpened: () => void;

  /** If null no delete option will be given */
  onDelete: null | (() => void);

  /** To determine what conditions can be chosen, if none given all will be shown */
  conditionChoiceType?: ConditionChoiceType;

  /** className for styled components */
  className?: string;
  announceChanges: () => void;
  outputLoading: boolean;
};
const FlowConditionComponent: React.FC<Props> = ({
  outputLoading,
  condition,
  onChange,
  onDropdownOpened,
  onDelete,
  conditionNumber,
  conditionChoiceType,
  conditionLabel,
  className,
  announceChanges,
}) => {
  const conditionTypeOptions = getConditionTypeOptions(
    conditionChoiceType ?? null,
  );
  const conditionTypeOptionSelectedIdx = conditionTypeOptions.findIndex(
    option => option.key === condition.type,
  );
  let conditionTypeLabel = text.selectCondition(
    conditionLabel,
  ) as React.ReactNode; // It is a string which is a valid React.ReactNode and typing of label is a React.ReactNode
  if (conditionTypeOptionSelectedIdx >= 0) {
    conditionTypeLabel =
      conditionTypeOptions[conditionTypeOptionSelectedIdx].label;
  }

  return (
    <Container data-testid={TEST_ID.CONTAINER} className={className}>
      <ColouredBar />
      <ConditionContainer>
        <ConditionTitleContainer>
          <ConditionTitle>
            {text.conditionLabel(conditionNumber)}
            {conditionTypeLabel}
          </ConditionTitle>
          {onDelete == null ? null : (
            <IconButton data-testid={TEST_ID.DELETE_BUTTON} onClick={onDelete}>
              <Icon name="trashcan" />
            </IconButton>
          )}
        </ConditionTitleContainer>
        <FlowConditionDetailsComponent
          key={condition.id}
          outputLoading={outputLoading}
          onDropdownOpened={onDropdownOpened}
          onChange={onChange}
          condition={condition}
          conditionLabel={conditionLabel}
          conditionChoiceType={conditionChoiceType}
          announceChanges={announceChanges}
        />
      </ConditionContainer>
    </Container>
  );
};

const ConditionContainer = styled.div<{}>`
  width: 100%;

  ${({ theme }) => css`
    border-top-right-radius: ${theme.getTokens().border.radius.s};
    border-bottom-right-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('white')};
  `}
`;

const ColouredBar = styled.div<{}>(
  ({ theme }) => css`
    width: 6px;
    background-color: ${theme.color('primary', 'light')};
    border-top-left-radius: ${theme.getTokens().border.radius.s};
    border-bottom-left-radius: ${theme.getTokens().border.radius.s};
  `,
);

const ConditionTitleContainer = styled.div<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color('grey')};
    margin: 0 ${theme.space('m')};
  `};
`;
const ConditionTitle = styled.div<{}>`
  flex-grow: 1;
  font-weight: bold;
`;

const Container = styled.div<{}>`
  display: flex;
  flex-grow: 1;
`;

export default FlowConditionComponent;
