import gql from 'graphql-tag';

export default gql`
  fragment InvoiceItemFields on InvoiceItem {
    __typename

    ... on TieredInvoiceItem {
      name
      tiers {
        description
        quantity
        pricePerUnit
        unitLabel
        amount
      }
    }
    ... on BasicInvoiceItem {
      name
      amount
    }
    ... on CombinedInvoiceItem {
      name
      subItems {
        description
        amount
        quantity
        pricePerUnit
      }
    }
  }
`;
