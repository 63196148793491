import { TaskListTaskFieldsFragment } from '~/graphql/types';

import {
  isBeforeOrEqual,
  isAfterOrEqual,
  convertServerDateStringToDate,
} from '~/util/date';
import TASK_LIST_TYPE, { TaskListType } from '~/scenes/Tasks/util/taskListType';
import { isTaskForListType } from './taskManipulation';

/**
 * Check if the given task is in the window of the given list (depending on the filter type)
 * to determine if we need to add subscription tasks to it.
 *
 * Open tasks are sorted by dueDate with the earliest date at the top
 * Closed and deleted tasks are sorted by closedDate with the latest date at the top
 */
export default (
  currentTaskList: Array<TaskListTaskFieldsFragment>,
  toInsertOrUpdateTask: TaskListTaskFieldsFragment,
  taskListType: TaskListType,
  queryHasMoreResults: boolean,
): boolean => {
  if (!isTaskForListType(toInsertOrUpdateTask, taskListType)) return false;

  // If we have all of our data and the status is correct we do not need to check date window
  if (!queryHasMoreResults) return true;

  switch (taskListType) {
    case TASK_LIST_TYPE.OPEN:
      return isDueInDateWindow(currentTaskList, toInsertOrUpdateTask);
    case TASK_LIST_TYPE.CLOSED:
      return isClosedInDateWindow(currentTaskList, toInsertOrUpdateTask);
    case TASK_LIST_TYPE.DELETED:
      return isDeletedInDateWindow(currentTaskList, toInsertOrUpdateTask);
    default:
      return false;
  }
};

const isDueInDateWindow = (
  currentTaskList: Array<TaskListTaskFieldsFragment>,
  toInsertTask: TaskListTaskFieldsFragment,
): boolean => {
  if (currentTaskList.length === 0) {
    return true;
  }

  const toInsertDate = convertServerDateStringToDate(toInsertTask.dueDate);
  const lastTask = currentTaskList[currentTaskList.length - 1];
  const toCheckDate = convertServerDateStringToDate(lastTask.dueDate);

  if (toInsertDate === null || toCheckDate === null) {
    return false;
  }

  return isBeforeOrEqual(toInsertDate, toCheckDate);
};

const isClosedInDateWindow = (
  currentTaskList: Array<TaskListTaskFieldsFragment>,
  toInsertTask: TaskListTaskFieldsFragment,
): boolean => {
  if (currentTaskList.length === 0) {
    return true;
  }

  const toInsertDate = convertServerDateStringToDate(toInsertTask.closedDate);
  const lastTask = currentTaskList[currentTaskList.length - 1];
  const toCheckDate = convertServerDateStringToDate(lastTask.closedDate);

  if (toInsertDate === null || toCheckDate === null) {
    return false;
  }

  return isAfterOrEqual(toInsertDate, toCheckDate);
};

const isDeletedInDateWindow = (
  currentTaskList: Array<TaskListTaskFieldsFragment>,
  toInsertTask: TaskListTaskFieldsFragment,
): boolean => {
  if (currentTaskList.length === 0) {
    return true;
  }

  const toInsertDate = convertServerDateStringToDate(toInsertTask.deletedDate);
  const lastTask = currentTaskList[currentTaskList.length - 1];
  const toCheckDate = convertServerDateStringToDate(lastTask.deletedDate);

  if (toInsertDate === null || toCheckDate === null) {
    return false;
  }

  return isAfterOrEqual(toInsertDate, toCheckDate);
};
