import {
  INLINE_IMAGE_ID_REGEX,
  INLINE_IMAGE_FILENAME_REGEX,
  INLINE_IMAGE_S3KEY_REGEX,
  INLINE_IMAGE_CONTENT_TYPE_REGEX,
  INLINE_IMAGE_CONTENT_LENGTH_REGEX,
  INLINE_IMAGE_SRC_REGEX,
} from '~/components/HTMLEditor/constants';

const removeInlineAttachmentInformation = (html: string): string => {
  const withoutContentType = html.replace(INLINE_IMAGE_CONTENT_TYPE_REGEX, '');
  const withoutContentLength = withoutContentType.replace(
    INLINE_IMAGE_CONTENT_LENGTH_REGEX,
    '',
  );
  const withoutInlineId = withoutContentLength.replace(
    INLINE_IMAGE_ID_REGEX,
    '',
  );
  const withoutS3Key = withoutInlineId.replace(INLINE_IMAGE_S3KEY_REGEX, '');
  const withoutFilename = withoutS3Key.replace(INLINE_IMAGE_FILENAME_REGEX, '');
  const withoutSrc = withoutFilename.replace(INLINE_IMAGE_SRC_REGEX, '');
  return withoutSrc;
};

export default removeInlineAttachmentInformation;
