import React, { ReactElement } from 'react';

import { CreateTaskAction } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

export default (
  action: CreateTaskAction,
  describeOptions: DescribeOptions,
): ReactElement => {
  const { taskInput } = action;
  const { title } = taskInput;

  return (
    <span>
      {'Creëer een taak met de titel '}
      {describeParameterValue(title, describeOptions, true)}
    </span>
  );
};
