import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  /** Extended description of an error, will be displayed below the component */
  errorDescription?: string | null | undefined;

  /** Extended description, will be displayed below the component */
  description?: string;

  /** Additional css to be added */
  css?: string;
};

const InputGroupElement = <ComponentProps extends $Object>(
  WrappedComponent: React.ComponentType<ComponentProps>,
  componentStyles?: any,
  useComponentWidth?: boolean,
): React.ComponentType<Props & ComponentProps> => {
  type CombinedProps = Props & ComponentProps;
  return function WrapperComponent(props: CombinedProps) {
    const { description, errorDescription, css, ...rest } = props;
    return (
      <InputContainer
        componentStyles={componentStyles}
        elementStyles={css}
        useComponentWidth={useComponentWidth}
      >
        <WrappedComponent {...(rest as ComponentProps)} />
        {description && <Description>{description}</Description>}
        {errorDescription && <Error>{errorDescription}</Error>}
      </InputContainer>
    );
  };
};

type InputContainerProps = {
  useComponentWidth?: boolean;
  componentStyles?: $Object;
  elementStyles?: string;
};

const InputContainer = styled.div<InputContainerProps>`
  ${({ useComponentWidth }) => {
    if (useComponentWidth !== true) {
      return 'width: 100%;';
    }

    return '';
  }};

  ${({ componentStyles }) => {
    if (componentStyles != null) {
      return componentStyles;
    }

    return '';
  }};

  ${({ elementStyles }) => {
    if (elementStyles != null) {
      return elementStyles;
    }

    return '';
  }};
`;

const Description = styled.span<{}>`
  display: block;

  ${({ theme }) => css`
    color: ${theme.color('text')};
    font-size: ${theme.fontSize('s')};
    padding-left: ${theme.space('xxxs')};
  `};
`;

const Error = styled.span<{}>`
  display: block;

  ${({ theme }) => css`
    color: ${theme.color('danger')};
    font-size: ${theme.fontSize('s')};
    padding-left: ${theme.space('xxxs')};
  `};
`;

export default InputGroupElement;
