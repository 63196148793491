import { Flow_Parameter_File_Primitive__Input } from '~/graphql/types';
import { FilePrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

const asFilePrimitiveParameterValue = (
  parameterValue: FilePrimitiveParameterValue,
): Flow_Parameter_File_Primitive__Input => {
  if (
    parameterValue.s3key == null ||
    parameterValue.filename == null ||
    parameterValue.contentLength == null
  ) {
    throw new Error(
      `Validation should have caught that the parameterValue ${JSON.stringify(
        parameterValue,
        null,
        2,
      )} has no null fields`,
    );
  }

  return {
    s3key: parameterValue.s3key,
    filename: parameterValue.filename,
    contentLength: parameterValue.contentLength,
  };
};

export default asFilePrimitiveParameterValue;
