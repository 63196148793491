import { isNil } from 'ramda';
import React, { useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import TextButton from '~/components/TextButton';
import useViewportSize from '~/components/util/useViewportSize';

const text = { showMore: 'Toon meer', showLess: 'Toon minder' };

/** Viewport height to start truncating */
const HEIGHT_LIMIT = 900;

export type Props = {
  dataTestId?: string;

  /** String to be truncate */
  str: string;

  /** Maximum string length to start truncating */
  maxLength?: number;
};

const TruncateWrapper: React.FC<Props> = ({
  children,
  maxLength = 1000,
  str,
}) => {
  const [viewportSize] = useViewportSize();
  const [truncate, setTruncate] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    if (viewportSize.height < HEIGHT_LIMIT && str.length > maxLength) {
      setTruncate(true);
    } else setTruncate(null);
  }, [viewportSize.height, str.length, maxLength]);

  return (
    <>
      <Container truncate={truncate}>{children}</Container>

      {!isNil(truncate) && (
        <TextButton
          onClick={() => setTruncate(prev => !prev)}
          label={truncate ? text.showMore : text.showLess}
        />
      )}
    </>
  );
};

const Container = styled.div<{ truncate: boolean | null }>(({ truncate }) => {
  if (truncate)
    return css`
      mask: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 1) 0,
          rgba(0, 0, 0, 1) 40%,
          rgba(0, 0, 0, 0) 95%,
          rgba(0, 0, 0, 0) 0
        )
        100% 50% / 100% 100% repeat-x;

      max-height: 50vh;
      overflow: hidden;
    `;

  return css``;
});

export default TruncateWrapper;
