import React, { useEffect, useMemo, useState } from 'react';
import withErrorBoundary from '~/ErrorBoundary';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import {
  GetWidgetSettingsQuery,
  useGetWidgetSettingsQuery,
  useUpdateWidgetSettingsMutation,
  WidgetSettings__Input,
  WidgetSettingsAppConfiguration__Input,
  WidgetSettings as WidgetSettingsType,
} from '~/graphql/types';
import DatHuisLoading from '~/components/DatHuisLoading';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import { FormApi } from 'final-form';
import getAppsConfigurationInput from './utils/getAppsConfigurationInput';
import getAppsGroupLookup from './utils/getAppsGroupLookup';
import WidgetSettingsContent from './components/WidgetSettingsContent';
import { GroupLookup } from '~/components/ToggleAccordion';
import { useRecoilState } from 'recoil';
import pinnedAppsState, { PinnedAppsMap } from './states/pinnedApps';
import getPinnedApps from './utils/getPinnedApps';

export type FormData = {
  unsubscribeLinkText: string;
};

const text = {
  saveError:
    'Er is iets mis gegaan met het opslaan van de aanpassingen, wij hebben hiervan een melding gekregen. Probeer het later opnieuw.',
  saveSuccess: 'Wijzigen zijn opgeslagen!',
};

const WidgetSettings: React.FC<{}> = () => {
  const [hasSet, setHasSet] = useState(false);
  const [logo, setLogo] = useState<WidgetSettingsType['logo']>(undefined);
  const [image, setImage] =
    useState<WidgetSettingsType['highlightImage']>(undefined);
  const account = useCurrentAccount();
  const addToast = useAddToast();
  const { data, loading, updateQuery } = useGetWidgetSettingsQuery({
    variables: {
      accountId: account.id,
    },
  });

  const [pinnedApps, setPinnedApps] = useRecoilState(pinnedAppsState);
  const [initiallyPinnedApps, setInitiallyPinnedApps] =
    useState<PinnedAppsMap | null>(null);

  /** Forces image inputs to rerender after cancelling changes */
  const [version, setVersion] = useState(0.0000001);

  const [updateSettings, { loading: updateLoading }] =
    useUpdateWidgetSettingsMutation();

  const [initialAppsGroupLookup, setInitialAppsGroupLookup] = useState<
    GroupLookup<{ appConfiguration: string }>
  >({});
  const [appsGroupLookup, setAppsGroupLookup] = useState<
    GroupLookup<{ appConfiguration: string }>
  >({});

  useEffect(() => {
    if (data && data.getWidgetSettings) {
      const defaultGroups = getAppsGroupLookup(
        data.getWidgetSettings.appsConfiguration,
      );
      setInitialAppsGroupLookup(defaultGroups);
      setAppsGroupLookup(defaultGroups);

      const getInitiallyPinnedApps = getPinnedApps(
        data.getWidgetSettings.appsConfiguration,
      );
      setPinnedApps(getInitiallyPinnedApps);
      setInitiallyPinnedApps(getInitiallyPinnedApps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onChange = useMemo(
    () =>
      ({ groupId, groupLookup }) => {
        setAppsGroupLookup(prevState => ({
          ...prevState,
          [groupId]: {
            ...prevState[groupId],
            ...groupLookup,
          },
        }));
      },
    [],
  );

  useEffect(() => {
    if (data && data.getWidgetSettings) {
      if (!hasSet) {
        setLogo(data.getWidgetSettings.logo);
        setImage(data.getWidgetSettings.highlightImage);
        setHasSet(true);
      }
    }
  }, [data, hasSet]);

  if (
    !data?.getWidgetSettings ||
    initiallyPinnedApps === null ||
    pinnedApps === null ||
    loading
  )
    return <DatHuisLoading />;

  const onFormSubmit = async (update, form: FormApi) => {
    const appsConfigurationInput = getAppsConfigurationInput(
      pinnedApps,
      appsGroupLookup,
      data.getWidgetSettings.appsConfiguration,
    );
    const { data: updateData, errors } = await updateSettings({
      variables: {
        accountId: account.id,
        widgetSettings: serializeInput(
          update,
          appsConfigurationInput,
          logo?.s3key,
          image?.s3key,
        ),
      },
    });

    if (updateData && updateData.updateWidgetSettings) {
      updateQuery(prev => ({
        ...prev,
        getWidgetSettings: updateData.updateWidgetSettings,
      }));

      const updatedGroups = getAppsGroupLookup(
        updateData.updateWidgetSettings.appsConfiguration,
      );

      setInitialAppsGroupLookup(updatedGroups);
      setAppsGroupLookup(updatedGroups);

      const updatedPinnedApps = getPinnedApps(
        updateData.updateWidgetSettings.appsConfiguration,
      );
      setInitiallyPinnedApps(updatedPinnedApps);
      setPinnedApps(updatedPinnedApps);

      setLogo(updateData.updateWidgetSettings.logo);
      setImage(updateData.updateWidgetSettings.highlightImage);

      form.change('apps', undefined);
      form.reset(updateData.updateWidgetSettings);
      return addToast([formatToastMessage(text.saveSuccess, 'success')]);
    }

    if (errors && errors.length !== 0) {
      return addToast([formatToastMessage(text.saveError, 'danger')]);
    }
  };

  const onCancelSave = () => {
    setVersion(prev => prev + 0.000001);
    setAppsGroupLookup(initialAppsGroupLookup);
    setLogo(data.getWidgetSettings.logo);
    setImage(data.getWidgetSettings.highlightImage);
    setPinnedApps(initiallyPinnedApps);
  };

  return (
    <WidgetSettingsContent
      initiallyPinnedApps={initiallyPinnedApps}
      pinnedApps={pinnedApps}
      onCancelSave={onCancelSave}
      initialAppsGroupLookup={initialAppsGroupLookup}
      appsGroupLookup={appsGroupLookup}
      onFormSubmit={onFormSubmit}
      data={data.getWidgetSettings}
      onChange={onChange}
      loading={loading || updateLoading}
      logo={logo}
      setLogo={setLogo}
      image={image}
      setImage={setImage}
      version={version}
    />
  );
};

const serializeInput = (
  update: GetWidgetSettingsQuery['getWidgetSettings'],
  appsConfigurationInput: Array<WidgetSettingsAppConfiguration__Input>,
  logoS3Key?: string,
  imageS3Key?: string,
): WidgetSettings__Input => {
  const input: WidgetSettings__Input = {
    googleAnalytics4: update.googleAnalytics4 ?? null,
    positions: {
      desktop: update.positions.desktop,
      mobile: update.positions.mobile,
    },
    appearances: {
      desktop: update.appearances.desktop,
      mobile: update.appearances.mobile,
    },
    style: {
      primary: {
        background: update.style.primary.background,
        color: update.style.primary.color,
      },
      secondary: {
        background: update.style.secondary.background,
        color: update.style.secondary.color,
      },
    },
    zIndex: update.zIndex,
    highlightImage: imageS3Key
      ? {
          s3key: imageS3Key,
        }
      : null,
    logo: logoS3Key ? { s3key: logoS3Key } : null,
    appsConfiguration: appsConfigurationInput,
  };

  return input;
};

export default withErrorBoundary<{}>(WidgetSettings);
