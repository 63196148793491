import FroalaEditor from 'froala-editor/js/froala_editor.min.js';
import { OPEN_INSERT_HTML_BUTTON_NAME } from './constants';

FroalaEditor.DefineIcon('insertHTMLIcon', {
  NAME: 'plus',
  PATH: 'M9.4,16.6,4.8,12,9.4,7.4,8,6,2,12l6,6Zm5.2,0L19.2,12,14.6,7.4,16,6l6,6-6,6Z',
});
FroalaEditor.RegisterCommand(OPEN_INSERT_HTML_BUTTON_NAME, {
  title: 'HTML invoegen',
  icon: 'insertHTMLIcon',
  callback: function callback() {
    this.onOpenInsertHTMLCommand();
  },
});
