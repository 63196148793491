import type { FlowPath } from '../getFieldsByPath';
import type { InstanceMap } from '../getInstance';
import type { SubjectMap } from '../getSubject';
import type { ConditionMap } from '../getConditions';
import type { Flow___ConditionPartial } from '../../../ConditionEditor';
import type { DirectoryMap } from '../getDirectory';
import getPathForFieldSubjectCondition from './getPathForFieldSubjectCondition';
import getPathForInstanceCondition from './getPathForInstanceCondition';

const getPathForCondition = (
  condition: Flow___ConditionPartial,
  opts: {
    instanceMap: InstanceMap;
    subjectMap: SubjectMap;
    conditionMap: ConditionMap;
    directoryMap: DirectoryMap;
  },
):
  | {
      result: FlowPath;
      fullResult: FlowPath;
      error?: undefined;
    }
  | { error: string } => {
  switch (condition.__typename) {
    case 'Flow___InstanceCondition':
      return getPathForInstanceCondition(condition, opts);
    case 'Flow___SubjectFieldCondition':
      return getPathForFieldSubjectCondition(condition, opts);
  }
};
export default getPathForCondition;
