import {
  FlowData___FlowInstanceFragment,
  FlowData___SubjectFragment,
} from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import getCardLabel from '~/scenes/Automation/v2/components/Builder/components/nodeTypes/components/CardTemplate/utils/getCardLabel';
import getCardHeading from '~/scenes/Automation/v2/components/Builder/utils/getCardHeading';

const generateInstance = (
  subject: FlowData___SubjectFragment,
  key: string,
  action: ClientFlowAction,
  name?: string,
): FlowData___FlowInstanceFragment => {
  let extraDescription = '';

  if (action.__typename === 'FlowV2_Action_Task_Create') {
    extraDescription = action.title.template || '';
  }

  if (action.__typename === 'FlowV2_Action_SendEmail_Plain') {
    extraDescription = action.subject.template || '';
  }

  // Brackets will be replaced later on with the span tag
  const label = action.label ? `[[${getCardLabel(action.label)}]]` : '';

  return {
    __typename: 'FlowData___FlowInstance',
    dir: [
      {
        key: action.id,
        label:
          name ||
          `${label}${getCardHeading(action.actionType)}${
            extraDescription ? ` | ${extraDescription}` : ''
          }`,
        weight: Number.MAX_SAFE_INTEGER,
        __typename: 'FlowData___Directory',
      },
      {
        key,
        label: subject.label,
        weight: Number.MAX_SAFE_INTEGER,
        __typename: 'FlowData___Directory',
      },
    ],
    instanceType: {
      __typename: 'Flow___SubjectId',
      type: subject.type,
      typeId: subject.typeId,
    },
    key: [action.id, key],
  };
};

export default generateInstance;
