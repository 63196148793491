import React from 'react';
import styled, { css } from 'styled-components';

import { ChangeableEmailListProps } from '~/components/SendEmailBlock/components/MessageHeader';

import NamedEmailLabel from './NamedEmailLabel';
import RowLabel from './RowLabel';
import { EmailSelectInput } from '~/components/Inputs';

type Props = {
  /** The label to show (Cc or Bcc) */
  rowLabel: string;

  /** The grid placement to use */
  grid: {
    start: string;
    end: string;
  };
  changeableEmailList: ChangeableEmailListProps;
  disabled?: boolean;
  dataTestid?: string;
};

const MultipleEmailRow = ({
  grid,
  rowLabel,
  changeableEmailList,
  disabled,
  dataTestid,
}: Props): React.ReactElement => {
  let inputComponent;

  if (changeableEmailList.readOnly) {
    const { namedEmailList } = changeableEmailList;

    inputComponent = (
      <>
        {namedEmailList.map((namedEmail, idx) => {
          const { name, email } = namedEmail;

          return (
            <NamedEmailLabel
              key={keyForIdx(rowLabel, idx)}
              name={name}
              email={email}
            />
          );
        })}
      </>
    );
  } else {
    const { selectedValues, onSelected, onRemoved } = changeableEmailList;

    inputComponent = (
      <EmailSelectInput
        disabled={disabled}
        selectedValues={selectedValues}
        onSelected={onSelected}
        onRemoved={onRemoved}
      />
    );
  }
  return (
    <>
      <MultipleEmailLabel gridStart={grid.start} gridEnd={grid.end}>
        {rowLabel}
      </MultipleEmailLabel>
      <MultipleEmailInput
        gridStart={grid.start}
        gridEnd={grid.end}
        data-testid={dataTestid}
      >
        {inputComponent}
      </MultipleEmailInput>
    </>
  );
};

const keyForIdx = (rowLabel, idx) => `${rowLabel}-${idx}`;

type GridProps = {
  gridStart: string;
  gridEnd: string;
};
type LabelProps = GridProps;
const MultipleEmailLabel = styled(RowLabel)<LabelProps>`
  ${({ gridStart, gridEnd }) => css`
    grid-row: ${gridStart} / ${gridEnd};
    grid-column: label-start / label-end;
  `}
`;
type InputProps = GridProps;
const MultipleEmailInput = styled.div<InputProps>`
  ${({ gridStart, gridEnd }) => css`
    grid-row: ${gridStart} / ${gridEnd};
    grid-column: input-start / button-end;
  `}
`;

export default MultipleEmailRow;
