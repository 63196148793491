import React, { useRef } from 'react';
import DetailsBlock, { SectionTitle } from '~/components/DetailsBlock';
import SynchronisedMailboxes from '../../components/SynchronisedMailboxes';
import convertMailboxes from '~/util/converters/convertMailboxes';
import { SynchroniseEntityType } from '~/scenes/External/Nylas/util';
import { ALIAS_VERIFICATION_TIMEOUT } from '../../constants';
import useCurrentUser from '~/hooks/useCurrentUser';

const text = {
  integrationHeader: 'E-mailintegratie',
};

type Props = {
  mailboxes: Array<any>;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  refetch: () => void;
};

const SynchronisedMailboxesBlock = ({
  mailboxes,
  refetch,
  startPolling,
  stopPolling,
}: Props) => {
  const me = useCurrentUser();
  const isPolling = useRef<boolean>(false);
  const pollingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const filteredMailboxes = mailboxes.filter(
    mailbox =>
      mailbox.__typename === 'UserMailbox' ||
      mailbox.__typename === 'UserMailboxAlias',
  );

  return (
    <DetailsBlock>
      <SectionTitle>{text.integrationHeader}</SectionTitle>
      <SynchronisedMailboxes
        mailboxes={convertMailboxes(filteredMailboxes)}
        entityType={SynchroniseEntityType.User}
        entityId={me.id}
        mayEdit={true}
        onSuccessfulDelete={() => {
          refetch();
        }}
        onSuccessfulSync={() => refetch()}
        onSuccessfulAliasVerificationStart={() => {
          refetch();
          startPolling(3000);
          isPolling.current = true;

          // stop polling if it is not fixed within a minute
          pollingTimeout.current = setTimeout(
            stopPolling,
            ALIAS_VERIFICATION_TIMEOUT,
          );
        }}
        onSuccessfulAliasVerificationEnd={() => {
          stopPolling();
          isPolling.current = false;
        }}
      />
    </DetailsBlock>
  );
};

export default SynchronisedMailboxesBlock;
