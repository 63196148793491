import React from 'react';
import JustificationContainer from '~/components/JustificationContainer';
import { Body, Label } from '~/components/Typography/index';

export type Props = {
  dataTestId?: string;
  label: string;
  value: React.ReactNode;
};

const Item: React.FC<Props> = ({ label, value }) => (
  <JustificationContainer direction="column">
    <Label color={{ group: 'primary', variant: 'light' }}>{label}</Label>
    <Body size="s">{value}</Body>
  </JustificationContainer>
);

export default Item;
