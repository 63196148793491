import { FlowActionProps } from '~/scenes/Automation/Flows/Actions/types.flow';
import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';

import { defaultNewSetContactDetailsProps } from '../SetContactDetailsAction';
import { defaultNewStartActionProps } from '../StartAction';
import { defaultNewWaitActionProps } from '../WaitAction';
import { defaultNewSendEmailPlainActionProps } from '../SendEmailPlainAction';
import { defaultNewDeleteContactTagActionProps } from '../DeleteContactTagAction';
import { defaultNewCreateTaskActionProps } from '../CreateTaskAction';
import { defaultNewAddContactTagActionProps } from '../AddContactTagAction';
import { defaultNewIfElseActionProps } from '../IfElseAction';
import { defaultNewAssignContactActionProps } from '../AssignContactAction';
import { defaultNewRealworksSendContactActionProps } from '../RealworksSendContactAction';
import { defaultNewZapierTriggerActionProps } from '../ZapierTriggerAction';

import idGenerator from '../../util/idGenerator';

import cleanedFilename from '~/util/cleanedFilename';

const newActionPropForType = (type: FLOW_ACTION_TYPE): FlowActionProps => {
  const baseActionProps = {
    id: idGenerator.generateFlowBlueprintActionId(),
  };
  switch (type) {
    case FLOW_ACTION_TYPE.SET_CONTACT_DETAILS:
      return defaultNewSetContactDetailsProps(baseActionProps);
    case FLOW_ACTION_TYPE.START:
      return defaultNewStartActionProps(baseActionProps);
    case FLOW_ACTION_TYPE.WAIT:
      return defaultNewWaitActionProps(baseActionProps);
    case FLOW_ACTION_TYPE.CREATE_TASK:
      return defaultNewCreateTaskActionProps(baseActionProps);
    case FLOW_ACTION_TYPE.ADD_CONTACT_TAG:
      return defaultNewAddContactTagActionProps(baseActionProps);
    case FLOW_ACTION_TYPE.DELETE_CONTACT_TAG:
      return defaultNewDeleteContactTagActionProps(baseActionProps);
    case FLOW_ACTION_TYPE.SEND_EMAIL_PLAIN:
      return defaultNewSendEmailPlainActionProps(baseActionProps);
    case FLOW_ACTION_TYPE.IF_ELSE:
      return defaultNewIfElseActionProps(baseActionProps);
    case FLOW_ACTION_TYPE.ASSIGN_CONTACT:
      return defaultNewAssignContactActionProps(baseActionProps);
    case FLOW_ACTION_TYPE.SENDCONTACT_TO_REALWORKS:
      return defaultNewRealworksSendContactActionProps(baseActionProps);
    case FLOW_ACTION_TYPE.ZAPIER_TRIGGER:
      return defaultNewZapierTriggerActionProps(baseActionProps);
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should Not Occur: >> newActionPropForType => type ${type} not covered`,
      );
  }
};

export default newActionPropForType;
