/* eslint-disable no-console */
import uuid from 'uuid/v4';
import React, { useMemo } from 'react';
import { FlowV2_Action_StartFragment } from '~/graphql/types';
import ConditionEditor, { Trigger } from '../ConditionEditor';
import { FlowConditionConnector } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/components/OperatorDropdown';
import { Props as FormProps } from '../ActionForm';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_StartFragment;
};

const generateEmptyTrigger = (): Trigger => ({
  id: uuid(),
  trigger: null,
  condition: {
    conditions: [],
    connector: FlowConditionConnector.And,
  },
});

const StartAction: React.FC<Props> = ({ action, onChange }) => {
  const generatedTrigger = useMemo(() => generateEmptyTrigger(), []);
  const triggers =
    action.triggers.length === 0 ? [generatedTrigger] : action.triggers;

  return (
    <ConditionEditor
      connector={action.connector}
      triggers={triggers}
      onChange={triggers => {
        onChange({ ...action, triggers });
      }}
      actionType={action.actionType}
      actionId={action.id}
    />
  );
};

export default StartAction;
