import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const Hypotheekbond: React.FC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.1 42.2V25.4H55C55.6 25.4 55.9 24.7 55.4 24.2L37.6 7C37 6.4 36 6.4 35.4 7L17.4 24.2C17 24.6 17.2 25.4 17.8 25.4H19.7V49.9H26.3"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3999 23.7L35.9999 12.4C36.1999 12.2 36.4999 12.2 36.6999 12.4L48.3999 23.7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.8 6.5H43.5V12.8L47.8 16.8V6.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.9999 52.3L35.0999 55.6C33.8999 56.1 32.4999 56.1 31.2999 55.6C26.5999 53.9 17.5999 50.6 14.6999 49.5C11.0999 48.1 7.29985 51.5 10.5999 53.3C13.9999 55.1 30.9999 64.5 30.9999 64.5C32.0999 65.1 33.3999 65.4 34.6999 65.4H40.4999C41.3999 65.4 42.1999 65.3 42.9999 65L54.9999 60.8L60.5999 60.9V44.6L49.1999 41.4C47.7999 40.9 46.1999 41 44.7999 41.6L32.4999 46.5C27.1999 49 32.9999 52.2 32.9999 52.2L43.9999 50V52.3Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.4998 30.9C33.4387 30.9 34.1998 30.1389 34.1998 29.2C34.1998 28.2611 33.4387 27.5 32.4998 27.5C31.5609 27.5 30.7998 28.2611 30.7998 29.2C30.7998 30.1389 31.5609 30.9 32.4998 30.9Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.3997 38.8C41.3386 38.8 42.0997 38.0389 42.0997 37.1C42.0997 36.1611 41.3386 35.4 40.3997 35.4C39.4608 35.4 38.6997 36.1611 38.6997 37.1C38.6997 38.0389 39.4608 38.8 40.3997 38.8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7998 38.7L41.7998 27.7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Container>
);

const Container = styled.div``;

export default Hypotheekbond;
