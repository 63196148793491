import { WindowLocation, useLocation } from '@reach/router';
import MetaTags from 'react-meta-tags';
import type { FormApi } from 'final-form';
import { Link, navigate } from 'gatsby';
import Maybe from 'graphql/tsutils/Maybe';
import { isEmpty } from 'ramda';
import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import CallToActionBlock from '~/components/CallToActionBlock';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import CopyCode from '~/components/CopyCode';
import DetailsBlock from '~/components/DetailsBlock';
import { InputGroup, SelectGroup } from '~/components/Inputs';
import Input from '~/components/Input';
import Dropdown from '~/components/Dropdown';
import OverviewListHeader from '~/components/OverviewListHeader';
import FormSaveBar from '~/components/SaveBar/FormSaveBar';
import { Body, Heading4, Variant } from '~/components/Typography/index';
import {
  GetWidgetSettingsQuery,
  WidgetSettingsLogo,
  WidgetSettings,
  DhImage,
  WidgetSettingsAppearance,
  WidgetSettingsPositionV2,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ColorInput from '~/scenes/Apps/components/ColorInput';
import ImageInput from '../ImageInput';
import WidgetPreview from '../WidgetPreview';
import styled from 'styled-components';
import Catalog from '~/Catalog';
import TEST_ID from './index.testid';
import ToggleAccordion, {
  AccordionGroup,
  GroupLookup,
} from '~/components/ToggleAccordion';
import { PinnedAppsMap } from '../../states/pinnedApps';
import countChanges from '~/util/countChanges';
import ExternalField from '../ExternalField';

const text = {
  pageTitle: Catalog.widgetLabel,
  unsubscribeLinkTextInvalid: 'Deze tekst is verplicht',
  heading: 'Instellingen',
  integrate: 'Integratie',
  loading: 'Laden...',
  saveError:
    'Er is iets mis gegaan met het opslaan van de aanpassingen, wij hebben hiervan een melding gekregen. Probeer het later opnieuw.',
  saveSuccess: 'Wijzigen zijn opgeslagen!',

  logoLabel: 'Logo',
  highlightImageLabel: 'Featured afbeelding',
  primaryBackgroundColourLabel: 'Primaire kleur',
  primaryColourLabel: 'Contrasterede kleur',
  secondaryBackgroundColourLabel: 'Accent kleur',
  secondaryColourLabel: 'Contrasterede kleur',
  zIndexLabel: 'Z-Index',
  positionLabel: 'Positie',
  appearanceLabel: 'Grootte',
  desktop: 'Desktop',
  mobile: 'Mobiel',
  enableApps: 'Weergave van apps in de widget',
  body: (
    <>
      Deze widget bevat alle leadgeneratie apps op één plaats. Apps voeg je aan
      de widget toe, door in het menu op &#39;apps&#39; te klikken en een
      leadgeneratie app aan te zetten.
      <br />
      <br />
      <Link
        to="http://help.dathuis.nl/nl/articles/6133047-widget-installatie-en-integratie"
        target="_blank"
      >
        Bekijk hier de installatie en integratie instructies
      </Link>
    </>
  ),
  CTADescription:
    'Je moet sommige apps inschakelen om ze in de widget te kunnen tonen',
  CTAButton: 'Ga naar apps',
  ga4Label: 'Google Analytics 4',
  ga4Description: 'Geef uw GA 4-ID op.',
  gaHelpLinkText: 'Zie dit Helpartikel voor meer informatie',
};

const positionOptionTexts = {
  [WidgetSettingsPositionV2.TopRight]: 'Rechts boven',
  [WidgetSettingsPositionV2.TopLeft]: 'Links boven',
  [WidgetSettingsPositionV2.BottomRight]: 'Rechts onder',
  [WidgetSettingsPositionV2.BottomLeft]: 'Links onder',
  [WidgetSettingsPositionV2.CenterLeft]: 'Midden links',
  [WidgetSettingsPositionV2.CenterRight]: 'Midden rechts',
};

const widgetAppearanceOptions = [
  {
    label: 'Klein',
    value: WidgetSettingsAppearance.Small,
    dataTestid: TEST_ID.APPEARANCE_OPTION_SMALL,
  },
  {
    label: 'Groot',
    value: WidgetSettingsAppearance.Large,
    dataTestid: TEST_ID.APPEARANCE_OPTION_LARGE,
  },
];

const scrollToAppCategory = (location: WindowLocation) => {
  const hash = location.hash;

  // Removes # from the beginning of the hash
  const id = hash.substring(1);
  const el = document.getElementById(`${id}`);

  if (el) {
    window.scrollTo({
      behavior: 'smooth',
      top: el.offsetTop,
    });
  }
};

export type Props = {
  onCancelSave: () => void;
  initialAppsGroupLookup: GroupLookup<{ appConfiguration: string }>;
  appsGroupLookup: GroupLookup<{ appConfiguration: string }>;
  onChange: (args: {
    groupId: string;
    groupLookup: {
      items: Array<AccordionGroup<any>> | null;
      mainChecked: boolean;
    };
  }) => void;
  data: GetWidgetSettingsQuery['getWidgetSettings'];
  onFormSubmit: (update: any, form: FormApi) => Promise<void>;
  loading: boolean;
  logo: WidgetSettings['logo'];
  setLogo: React.Dispatch<React.SetStateAction<Maybe<WidgetSettingsLogo>>>;
  image: WidgetSettings['highlightImage'];
  setImage: React.Dispatch<React.SetStateAction<Maybe<DhImage>>>;
  version: number;
  initiallyPinnedApps: PinnedAppsMap;
  pinnedApps: PinnedAppsMap;
};

const WidgetSettingsContent: React.FC<Props> = ({
  onCancelSave,
  initiallyPinnedApps,
  pinnedApps,
  data,
  initialAppsGroupLookup,
  appsGroupLookup,
  onChange,
  onFormSubmit,
  loading,
  logo,
  setLogo,
  image,
  setImage,
  version,
}) => {
  const account = useCurrentAccount();
  const appsAreEnabled = !isEmpty(data.appsConfiguration);
  const location = useLocation();

  const changes = countChanges(initiallyPinnedApps, pinnedApps);

  useEffect(() => {
    // We need to wait a little bit for animation to be completed and scroll to the right place
    setTimeout(() => scrollToAppCategory(location), 700);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <OverviewListHeader title={text.pageTitle} />
      <DetailsBlock title={text.heading} manualLayout>
        <br />
        <Body>{text.body}</Body>
        <br />
        <CopyCode
          dataTestId={TEST_ID.CODE_SNIPPET}
          label="Plaats deze code op je website"
          code={`<script>
(function(d,a,t,h,u,i,s){d["DatHuisObject"]=u;d[u]=d[u]||function(){(d[u].q=d[u].q||[]).push(arguments)};d[u].l=1*new Date;se=a.createElement(t);fs=a.getElementsByTagName(t)[0];se["async"]=1;se.src=h;fs.parentNode.insertBefore(se,fs)})(window,document,"script","https://btstrp.dathuis.nl/assets/widget.js","dhuw");
dhuw('init', { accountId: '${account.id}' });
</script>`}
        />
        <Form
          onSubmit={onFormSubmit}
          initialValues={{ ...data, pinnedApps: initiallyPinnedApps }}
        >
          {({ form, submitError }) => (
            <form
              onSubmit={event => {
                if (event) event.preventDefault();
                return form.submit();
              }}
            >
              {submitError ? (
                <div data-testid={TEST_ID.ERROR_MESSAGE}>{submitError}</div>
              ) : null}
              <InputGroup>
                <Field name="logo">
                  {({ input }) => (
                    <ImageInput
                      initialUrl={logo?.url}
                      onChange={value => {
                        setLogo({
                          __typename: 'WidgetSettingsLogo',
                          s3key: value?.s3key || '',
                          url: value?.url || '',
                        });
                        input.onChange(value);
                      }}
                      s3Key={logo?.s3key}
                      title={text.logoLabel}
                      filename="logo"
                      dataTestid={TEST_ID.LOGO_INPUT}
                      key={'logo' + version}
                    />
                  )}
                </Field>
              </InputGroup>
              <InputGroup>
                <Field name="highlightImage">
                  {({ input }) => (
                    <ImageInput
                      initialUrl={image?.url}
                      onChange={value => {
                        setImage({
                          __typename: 'DHImage',
                          s3key: value?.s3key || '',
                          url: value?.url || '',
                        });
                        input.onChange(value);
                      }}
                      s3Key={image?.s3key}
                      title={text.highlightImageLabel}
                      filename="highlightImage"
                      dataTestid={TEST_ID.HIGHLIGHT_IMAGE_INPUT}
                      key={'highlightImage' + version}
                    />
                  )}
                </Field>
              </InputGroup>
              <Heading4 variant={Variant.primary}>{text.ga4Label}</Heading4>
              <Body withoutMargin>
                {text.ga4Description}{' '}
                <Link to="https://help.dathuis.nl/nl/articles/7854772-tracking-met-google-analytics-4">
                  {text.gaHelpLinkText}
                </Link>
              </Body>
              <InputGroup>
                <Field name="googleAnalytics4">
                  {({ input, meta: { submitting } }) => (
                    <Input
                      width="100%"
                      size="large"
                      placeholder="G-..."
                      dataTestId={TEST_ID.GOOGLE_ANALYTICS}
                      disabled={submitting}
                      {...input}
                    />
                  )}
                </Field>
              </InputGroup>
              <InputGroup>
                <Field name="style.primary.background">
                  {({ input, meta: { submitting } }) => (
                    <ColorInput
                      value={input.value}
                      onChange={value => input.onChange(value)}
                      label={text.primaryBackgroundColourLabel}
                      disabled={submitting || loading}
                      dataTestId={TEST_ID.PRIMARY_BACKGROUND_COLOR_INPUT}
                    />
                  )}
                </Field>
                <Field name="style.primary.color">
                  {({ input, meta: { submitting } }) => (
                    <ColorInput
                      value={input.value}
                      onChange={value => input.onChange(value)}
                      label={text.primaryColourLabel}
                      disabled={submitting || loading}
                      dataTestId={TEST_ID.PRIMARY_COLOR_INPUT}
                    />
                  )}
                </Field>
              </InputGroup>
              <InputGroup>
                <Field name="style.secondary.background">
                  {({ input, meta: { submitting } }) => (
                    <ColorInput
                      value={input.value}
                      onChange={value => input.onChange(value)}
                      label={text.secondaryBackgroundColourLabel}
                      disabled={submitting || loading}
                      dataTestId={TEST_ID.SECONDARY_BACKGROUND_COLOR_INPUT}
                    />
                  )}
                </Field>
                <Field name="style.secondary.color">
                  {({ input, meta: { submitting } }) => (
                    <ColorInput
                      value={input.value}
                      onChange={value => input.onChange(value)}
                      label={text.secondaryColourLabel}
                      disabled={submitting || loading}
                      dataTestId={TEST_ID.SECONDARY_COLOR_INPUT}
                    />
                  )}
                </Field>
              </InputGroup>
              <InputGroup>
                <Field name="zIndex">
                  {({ input, meta: { submitting } }) => (
                    <Input
                      size="large"
                      label={text.zIndexLabel}
                      type="number"
                      disabled={submitting || loading}
                      {...input}
                      dataTestId={TEST_ID.Z_INDEX}
                    />
                  )}
                </Field>
              </InputGroup>

              <Heading4 variant={Variant.primary} withoutMargin>
                {text.desktop}
              </Heading4>
              <InputGroup>
                <Field name="positions.desktop">
                  {({ input }) => (
                    <Dropdown
                      size="large"
                      label={text.positionLabel}
                      dataTestId={TEST_ID.WIDGET_POSITION_DESKTOP}
                      options={Object.keys(positionOptionTexts).map(key => ({
                        key,
                        label: positionOptionTexts[key],
                        payload: key,
                      }))}
                      selectedOptionIdx={Object.keys(
                        positionOptionTexts,
                      ).findIndex(key => key === input.value)}
                      onChange={({ option }) => {
                        input.onChange(option.payload);
                      }}
                    />
                  )}
                </Field>
                <Field name="appearances.desktop">
                  {({ input }) => (
                    <SelectGroup
                      size="large"
                      dataTestid={TEST_ID.APPEARANCE_DESKTOP}
                      label={text.appearanceLabel}
                      options={widgetAppearanceOptions}
                      selectedIndex={widgetAppearanceOptions.findIndex(
                        option => option.value === input.value,
                      )}
                      onChange={value => input.onChange(value.option?.value)}
                    />
                  )}
                </Field>
              </InputGroup>

              <Heading4 variant={Variant.primary} withoutMargin>
                {text.mobile}
              </Heading4>
              <InputGroup>
                <Field name="positions.mobile">
                  {({ input }) => (
                    <Dropdown
                      size="large"
                      label={text.positionLabel}
                      dataTestId={TEST_ID.WIDGET_POSITION_MOBILE}
                      options={Object.keys(positionOptionTexts).map(key => ({
                        key,
                        label: positionOptionTexts[key],
                        payload: key,
                      }))}
                      selectedOptionIdx={Object.keys(
                        positionOptionTexts,
                      ).findIndex(key => key === input.value)}
                      onChange={({ option }) => {
                        input.onChange(option.payload);
                      }}
                    />
                  )}
                </Field>
                <Field name="appearances.mobile">
                  {({ input }) => (
                    <SelectGroup
                      size="large"
                      dataTestid={TEST_ID.APPEARANCE_MOBILE}
                      label={text.appearanceLabel}
                      options={widgetAppearanceOptions}
                      selectedIndex={widgetAppearanceOptions.findIndex(
                        option => option.value === input.value,
                      )}
                      onChange={value => input.onChange(value.option?.value)}
                    />
                  )}
                </Field>
              </InputGroup>

              <SpacerSmall />

              <Heading4 variant={Variant.primary}>{text.enableApps}</Heading4>
              {appsAreEnabled ? (
                <InputGroup>
                  <Field name="apps">
                    {({ input }) => (
                      <ToggleAccordion
                        groupLookup={appsGroupLookup}
                        onChange={value => {
                          input.onChange({
                            ...appsGroupLookup,
                            ...input.value,
                            [value.groupId]: value.groupLookup,
                          });

                          return onChange(value);
                        }}
                        dataTestId={TEST_ID.APPS_CONTAINER}
                      />
                    )}
                  </Field>
                  <ExternalField name="pinnedApps" value={pinnedApps} />
                </InputGroup>
              ) : (
                <CallToActionBlock
                  align="center"
                  description={text.CTADescription}
                  icon={{
                    name: 'triangle',
                    size: 'large',
                    background: 'warning',
                  }}
                  button={{
                    appearance: 'primary',
                    label: text.CTAButton,
                    icon: 'arrowRight',
                    onClick: () => navigate('/-/apps'),
                  }}
                />
              )}

              <FormSaveBar
                externalChanges={changes}
                inBlockComponent
                onCancel={() => {
                  onCancelSave();
                }}
              />
              <WidgetPreview
                pinnedApps={pinnedApps}
                initialSettings={data}
                groupLookup={appsGroupLookup}
                initialGroupLookup={initialAppsGroupLookup}
                initiallyPinnedApps={initiallyPinnedApps}
              />
            </form>
          )}
        </Form>
      </DetailsBlock>
      <Spacer />
    </ContentContainerDefault>
  );
};

const Spacer = styled.div`
  margin-bottom: 16rem;
`;

const SpacerSmall = styled.div`
  margin-bottom: ${({ theme }) => theme.space('xl')};
`;

export default WidgetSettingsContent;
