import React, { useState } from 'react';

import { FlowConditionAppZapierNew } from './types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';

import useCurrentAccount from '~/hooks/useCurrentAccount';
import AppZapierEventsDropdown from '~/components/Inputs/Dropdown/AppZapierEventsDropdown';
import { emptyStringPrimitiveParameterValue } from '../../FlowParameter';
import { asStringPrimitiveParameterValueForValue } from '../../FlowParameter/ParameterValue/String/asStringPrimitiveParameterValue';

type MyProps = {
  condition: FlowConditionAppZapierNew;
  onChange: (newFlowCondition: FlowConditionAppZapierNew) => void;
  onDropdownOpened: () => void;
  outputLoading: boolean;
};

type Props = WithBaseActionContextProps & MyProps;
const FlowConditionAppZapierNewComponent = ({
  condition,
  onChange,
  onDropdownOpened,
  outputLoading,
}: Props) => {
  const account = useCurrentAccount();
  const [selectedId, setSelectedId] = useState<string | null>(
    condition.zapierEventId.value,
  );

  return (
    <AppZapierEventsDropdown
      onChange={({ option }) => {
        if (option.payload == null) {
          setSelectedId(null);

          onChange({
            ...condition,
            zapierEventId: emptyStringPrimitiveParameterValue(),
          });
          return;
        }
        setSelectedId(option.payload);

        onChange({
          ...condition,
          zapierEventId: asStringPrimitiveParameterValueForValue(
            option.payload,
          ),
        });
      }}
      onDropdownOpened={onDropdownOpened}
      outputLoading={outputLoading}
      variables={{ accountId: account.id }}
      selectedOptionId={selectedId}
    />
  );
};

export default withBaseActionContext<MyProps>(
  FlowConditionAppZapierNewComponent,
);
