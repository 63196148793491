import {
  FlowVariableStash,
  FlowVariableStashItem,
  FlowOutputObject,
  FlowOutputObjectField,
} from '~/scenes/Automation/Flows/types.flow';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import { PointerVariable } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { DescribeNodeElementType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { needsUserActionDescription } from '~/scenes/Automation/Flows/Actions/util';

export const getVariableLabel = (
  stash: FlowVariableStash,
  variableName: string,
): string => {
  const variable = stash[variableName];

  return variable == null ? 'Onbekende variabele' : variable.variableLabel;
};

export const UNKNOWN_FIELD_NAME = 'Onbekend veld';

export const getVariableAndFieldLabel = (
  stash: FlowVariableStash,
  variableName: string,
  fieldName: string,
): string => getLabel(stash, variableName, fieldName, true);
export const getFieldLabel = (
  stash: FlowVariableStash,
  variableName: string,
  fieldName: string,
): string => getLabel(stash, variableName, fieldName, false);

const getLabel = (
  stash: FlowVariableStash,
  variableName: string,
  fieldName: string,
  includeVariableName: boolean,
): string => {
  const variable = stash[variableName];

  if (variable == null) {
    return UNKNOWN_FIELD_NAME;
  }

  const splitName = splitObjectAndFieldName(fieldName);

  if (splitName == null) return UNKNOWN_FIELD_NAME;

  const [objectName, subfieldName] = splitName;

  const outputObject = variable.outputObjects.find(
    outputObject => outputObject.name === objectName,
  );

  if (outputObject == null) {
    return UNKNOWN_FIELD_NAME;
  }

  const field = outputObject.fields.find(
    outputField => outputField.name === subfieldName,
  );

  if (field == null) {
    return UNKNOWN_FIELD_NAME;
  }

  const fieldLabel = asFieldLabel(outputObject, field);

  if (includeVariableName === true) {
    const variableLabel = variable.variableLabel;

    return `${variableLabel} > ${fieldLabel}`;
  } else {
    return `${fieldLabel}`;
  }
};

export const asFieldLabel = (
  outputObject: FlowOutputObject,
  field?: FlowOutputObjectField,
): string => {
  if (field == null) return outputObject.objectLabel;

  return `${outputObject.objectLabel} > ${field.label}`;
};

export const getPointerVariableLabel = (
  variable: PointerVariable | null | undefined,
  stash: FlowVariableStash,
): DescribeNodeElementType => {
  if (variable == null || variable.field == null) {
    return needsUserActionDescription('Vul een referentie in');
  }

  const variableName = variable.name;
  const fieldName = variable.field.name;
  const variableLabel = getVariableLabel(stash, variableName);
  const fieldLabel = getFieldLabel(stash, variableName, fieldName);

  return `${variableLabel} > ${fieldLabel}`;
};

export const splitObjectAndFieldName = (
  fieldName: string,
): [string, string] | null => {
  const splitIndex = fieldName.indexOf('.');

  if (splitIndex == -1) {
    return [fieldName, ''];
  }

  const objectName = fieldName.substring(0, splitIndex);
  const subfieldName = fieldName.substring(splitIndex + 1, fieldName.length);

  return [objectName, subfieldName];
};

export const getVariableStashItem = (
  variable: PointerVariable | null | undefined,
  stash: FlowVariableStash,
): FlowVariableStashItem | null => {
  if (variable == null) return null;

  const stashVariable = stash[variable.name];

  return stashVariable == null ? null : stashVariable;
};

export const getVariableStashOutputObject = (
  variable: PointerVariable | null | undefined,
  stash: FlowVariableStash,
): FlowOutputObject | null => {
  const stashVariable = getVariableStashItem(variable, stash);

  if (stashVariable == null) return null;
  if (variable == null || variable.field == null) return null;

  const splitName = splitObjectAndFieldName(variable.field.name);

  if (splitName == null) return null;

  const outputObject = stashVariable.outputObjects.find(
    object => object.name === splitName[0],
  );

  return outputObject == null ? null : outputObject;
};

export const getStashVariablesInStash = (
  stash: FlowVariableStash,
  outputObjectType?: FLOW_OUTPUT_TYPE,
  fieldType?: FLOW_OUTPUT_TYPE,
): Array<FlowVariableStashItem> => {
  const variables: Array<FlowVariableStashItem> = [];

  Object.keys(stash).forEach(key => {
    if (key !== 'version') {
      const stashItem = stash[key];

      // If we need to filter
      if (outputObjectType != null || fieldType != null) {
        let filteredObjects = stashItem.outputObjects;

        if (outputObjectType != null) {
          filteredObjects = filteredObjects.filter(
            object => object.outputType === outputObjectType,
          );
        }

        if (fieldType != null) {
          filteredObjects = filteredObjects.filter(object =>
            object.fields.some(field => field.outputType === fieldType),
          );
        }

        if (filteredObjects.length > 0) {
          variables.push(stashItem);
        }
      } else {
        variables.push(stashItem);
      }
    }
  });

  return variables;
};
