import { isEmpty, isNil, omit } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Catalog from '~/Catalog';
import AppErrorScreen from '~/components/AppErrorScreen';
import CallToActionBlock from '~/components/CallToActionBlock';
import Input from '~/components/Input';
import JustificationContainer from '~/components/JustificationContainer';
import Loading from '~/components/Loading';
import { Heading4, Variant } from '~/components/Typography/index';
import {
  OutputFieldItem,
  OutputFieldImage,
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/Wizard/context/WizardContext';
import { OutputMap } from '~/components/WizardSteps';
import { useGetAppValuationReportsQuery } from '~/graphql';
import {
  GetAppValuationReportsQuery,
  useInsertAppValuationReportMutation,
  useUpdateAppValuationReportMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useErrorReporter from '~/hooks/useErrorReporter';
import useWizardStep from '~/hooks/useWizardStep';
import ImageInput from '~/scenes/Settings/Widget/components/ImageInput';
import cleanedFilename from '~/util/cleanedFilename';
import formatToastMessage from '~/util/formatToastMessage';
import { isValidURL } from '~/util/Validation/URL';

export const id = 'ValueReportGeneralImageSettings';
export const title = 'Waarderapport algemene beeldinstellingen';

type AddedReport =
  | GetAppValuationReportsQuery['getAppValuationReports'][0]
  | undefined;

type Image = {
  s3key?: string;
  url?: string;
};

export type OutputType = {
  type: typeof id;
  logoImage: OutputFieldImage<Image>;
  valueReportLogoLink?: OutputFieldItem;
  backgroundImage: OutputFieldImage<Image>;
  addedReport?: OutputFieldInvisible<AddedReport>;
};

const text = {
  successToastMessage: 'Kleurinstellingen succesvol opgeslagen',
  errorToastMessage:
    'Er is iets mis gegaan bij het opslaan, probeer het later nog eens',
  imagesCategory: 'Images',
  logoTitle: 'Logo',
  logoLinkTitle: 'Logo link',
  backgroundImageTitle: 'Achtergrond afbeelding',
  invalidLogoURL: 'Voer een geldige url in',
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
  reportName: 'Waarderapport',
  insertErrorDescription:
    'Helaas kon er op dit moment geen waarderapport worden toegevoegd. Klik hier om het opnieuw te proberen. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
  insertErrorToastMessage: `Er is iets mis gegaan bij het toevoegen van het waarderapport.`,
};

export const Component: React.FC<WizardStepProps> = ({ step, outputMap }) => {
  const { id: accountId } = useCurrentAccount();
  const errorReporter = useErrorReporter();
  const addToast = useAddToast();

  const [currentOutput, setCurrentOutput] = useState(
    outputMap[id] as OutputType,
  );
  const [invalidURLError, setInvalidURLError] = useState<boolean>(false);

  const { data, loading, error, refetch } = useGetAppValuationReportsQuery({
    name: cleanedFilename(__filename),
    variables: {
      accountId,
    },
  });

  const [
    insertAppValuationReport,
    { loading: insertLoading, error: insertError },
  ] = useInsertAppValuationReportMutation();

  const insertReport = async (): Promise<void> => {
    const { data, errors } = await insertAppValuationReport({
      variables: { name: text.reportName, accountId },
    });

    if (errors && errors.length) {
      return addToast([
        formatToastMessage(text.insertErrorToastMessage, 'danger'),
      ]);
    }

    if (data?.insertAppValuationReport) {
      void refetch();
    }
  };

  useEffect(() => {
    if (!isNil(data) && data.getAppValuationReports.length === 0) {
      void insertReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [updateAppValuationReport] = useUpdateAppValuationReportMutation();
  const firstAddedReport: AddedReport = data?.getAppValuationReports[0];

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const report =
        'addedReport' in outputMap[id] && outputMap[id].addedReport?.value
          ? outputMap[id].addedReport.value
          : undefined;
      const updatedOutput = outputMap[id] as OutputType;

      const logoImage = updatedOutput.logoImage.value;
      const bgImage = updatedOutput.backgroundImage.value;
      const logoLink = updatedOutput.valueReportLogoLink?.value;

      if (report && 'general' in report) {
        const formattedReport = omit(
          ['__typename', 'id', 'accountId', 'officeId'],
          report,
        );

        const update = {
          ...formattedReport,
          general: {
            ...omit(['__typename', 'backgroundColor'], formattedReport.general),
            logoLink,
            logoImage: logoImage?.s3key,
            backgroundImage: bgImage?.s3key,
          },
        };

        const variables = {
          accountId,
          id: report.id,
          update,
        };

        try {
          await updateAppValuationReport({ variables });
        } catch (e) {
          errorReporter.captureException(
            new Error(
              JSON.stringify(
                e ||
                  `updateAppValuationReport failed with variables: ${variables}`,
              ),
            ),
            'debug',
          );

          addToast([
            formatToastMessage(
              Catalog.genericUnknownErrorMessageShort,
              'danger',
            ),
          ]);
        }
      }

      const output: OutputType = {
        ...updatedOutput,
        addedReport: firstAddedReport
          ? {
              type: 'invisible',
              value: firstAddedReport,
            }
          : undefined,
        logoImage: {
          category: text.imagesCategory,
          type: 'image',
          value: logoImage,
          label: text.logoTitle,
        },
        valueReportLogoLink: logoLink
          ? {
              category: text.logoLinkTitle,
              type: 'item',
              value: logoLink,
              label: logoLink,
            }
          : undefined,
        backgroundImage: {
          category: text.imagesCategory,
          type: 'image',
          value: bgImage,
          label: text.backgroundImageTitle,
        },
      };

      return output;
    },
    [
      accountId,
      firstAddedReport,
      updateAppValuationReport,
      addToast,
      errorReporter,
    ],
  );

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );

  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    if (invalidURLError || insertError || insertLoading || !firstAddedReport) {
      api.lock({
        ...currentOutput,
        addedReport: {
          type: 'invisible',
          value: null,
        },
      });
    } else {
      api.free({
        ...currentOutput,
        addedReport: {
          type: 'invisible',
          value: firstAddedReport,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOutput, invalidURLError, firstAddedReport]);

  if (loading || insertLoading) {
    return <Loading />;
  }

  if (!data?.getAppValuationReports || error) {
    errorReporter.captureException(
      new Error(
        JSON.stringify(error || 'Could not get app valuation reports data'),
      ),
      'critical',
    );
    return <AppErrorScreen />;
  }

  if (insertError) {
    return (
      <CallToActionBlock
        description={text.insertErrorDescription}
        button={{
          label: Catalog.tryAgainLabel,
          appearance: 'secondary',
          icon: 'repeat',
          onClick: () => {
            if (data.getAppValuationReports.length === 0) {
              return insertReport();
            }

            return refetch();
          },
        }}
        icon={{
          size: 'large',
          background: 'danger',
        }}
      />
    );
  }

  return (
    <JustificationContainer direction="column" gap="l">
      <ImageInput
        initialUrl={currentOutput.logoImage.value?.url}
        onChange={value => {
          setCurrentOutput(prevOutput => ({
            ...prevOutput,
            logoImage: {
              category: text.imagesCategory,
              type: 'image',
              value: value ?? null,
              label: text.logoTitle,
            },
          }));
        }}
        s3Key={currentOutput.logoImage.value?.s3key}
        title={text.logoTitle}
        filename="logoImage"
      />

      <InputGroup>
        <Heading4 variant={Variant.primary}>{text.logoLinkTitle}</Heading4>
        <Input
          externalErrors={invalidURLError ? [text.invalidLogoURL] : []}
          size="large"
          type="text"
          value={currentOutput.valueReportLogoLink?.value}
          placeholder="https://vastgoedprofessional.nl/"
          onChange={e => {
            if (e.target) {
              const value = e.target.value;

              const isValidValue = isValidURL(value) || isEmpty(value);

              if (!isValidValue) {
                setInvalidURLError(true);
              } else {
                setInvalidURLError(false);
              }

              setCurrentOutput(prevOutput => ({
                ...prevOutput,
                valueReportLogoLink: {
                  category: text.logoLinkTitle,
                  type: 'item',
                  value: value,
                  label: value,
                  icon: 'link',
                },
              }));
            }
          }}
        />
      </InputGroup>

      <ImageInput
        initialUrl={currentOutput.backgroundImage.value?.url}
        onChange={value => {
          setCurrentOutput(prevOutput => ({
            ...prevOutput,
            backgroundImage: {
              category: text.imagesCategory,
              type: 'image',
              value: value ?? null,
              label: text.backgroundImageTitle,
            },
          }));
        }}
        s3Key={currentOutput.backgroundImage.value?.s3key}
        title={text.backgroundImageTitle}
        filename="backgroundImage"
      />
    </JustificationContainer>
  );
};

const InputGroup = styled.div`
  width: 100%;
`;

export default {
  id,
  title,
};
