import React, { useEffect, useLayoutEffect, useMemo, useReducer } from 'react';
import WizardContext, {
  initialState,
  WizardReducer,
  WizardState,
  WizardStep,
  WizardStepProps,
} from '~/components/Wizard/context/WizardContext';
import { Props as ButtonProps } from '~/components/Button';
import AppDetailsContainer from '~/scenes/Apps/components/AppDetailsContainer';
import Overlay from '../ModalsV2/Overlay';
import StepList, { Step } from '../StepList';
import WizardLayout from '../WizardLayout';
import NavigationControls from './components/NavigationControls';
import db from './db';
import wizardReducer from './reducers/wizard';
import stepComponentMap from '../WizardSteps';
import useErrorReporter from '~/hooks/useErrorReporter';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { clone } from 'ramda';

export type Props = {
  /**
   * A unique ID is needed to ensure states are stored uniquely
   */
  id: string;

  /**
   * The initial steps of this flow
   */
  steps: Array<WizardStep>;

  /**
   * Visible big outside header
   */
  header: React.ReactNode;

  /**
   * Button prop overrides for the complete button
   */
  completeButton?: Omit<ButtonProps, 'onClick'>;

  /**
   * Will get called when all steps have been completed
   */
  onComplete?: () => void;
};

const Wizard: React.FC<Props> = ({
  header,
  steps,
  children,
  id,
  onComplete,
}) => {
  const reporter = useErrorReporter();
  const { id: accountId } = useCurrentAccount();
  const [value, dispatch] = useReducer<WizardReducer, WizardState>(
    wizardReducer,
    { ...initialState, steps, id },
    state => state,
  );
  const currentWizardId = value.id ?? id;

  const mainStep = value.steps[value.currentStep];
  const currentSubStep =
    mainStep && mainStep.subSteps && value.currentSubStep !== null
      ? mainStep?.subSteps[value.currentSubStep] ?? null
      : null;

  const currentStep = currentSubStep ?? mainStep;

  const CurrentStep =
    currentStep && currentStep.id && stepComponentMap[currentStep.id]
      ? stepComponentMap[currentStep.id]
      : UnknownStep;

  useLayoutEffect(() => {
    let inFlight = false;
    const restoreState = async () => {
      if (!db.isOpen()) return;
      inFlight = true;

      try {
        const storedState = await db.states.get({
          id: currentWizardId,
          accountId,
        });

        if (storedState && !inFlight) {
          dispatch({ type: 'restoreState', payload: storedState });
        }
      } catch (error) {
        throw error;
      } finally {
        inFlight = false;
      }
    };

    void restoreState().catch(err => {
      reporter.captureException(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    let inFlight = false;
    const storeState = async () => {
      if (!db.isOpen()) return;
      try {
        const cleanedState = cleanState(value);
        if (!inFlight) {
          inFlight = true;
          await db.states.put({
            ...cleanedState,
            id: currentWizardId,
            accountId,
          });
        }
      } catch (error) {
        throw error;
      } finally {
        inFlight = false;
      }
    };

    void storeState().catch(err => {
      reporter.captureException(err);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const memoStepList = useMemo(
    (): Array<Step> =>
      value.steps.map((step, index) => ({
        disabled: false,
        id: `step-${index}`,
        label: step.title,
        subSteps: step.subSteps?.map(
          (subStep, subIndex): Step =>
            ({
              disabled: false,
              id: `step-${index}-substep-${subIndex}`,
              label: subStep.title,
            } ?? null),
        ),
      })),
    [value.steps],
  );

  return (
    <WizardContext.Provider
      value={{ state: value, dispatch, id: currentWizardId }}
    >
      {children}
      {value.show === true && currentStep && (
        <Overlay
          root="global-wizard-portal"
          onClose={() => {
            dispatch({ type: 'hide', payload: {} });
          }}
        >
          <WizardLayout header={value.header ?? header}>
            <StepList
              currentStep={value.currentStep}
              currentSubStep={value.currentSubStep}
              steps={memoStepList}
            />
            <AppDetailsContainer
              header={currentStep.title}
              icon={currentStep.icon}
              pageDescription={currentStep.description}
            >
              <CurrentStep step={currentStep} outputMap={value.outputMap} />
              <NavigationControls
                onComplete={onComplete}
                outputMap={value.outputMap}
              />
            </AppDetailsContainer>
          </WizardLayout>
        </Overlay>
      )}
    </WizardContext.Provider>
  );
};

const cleanState = (state: WizardState): WizardState => {
  const cloned = clone(state);

  // Functions cannot be inserted so we need to remove those
  for (const step of cloned.steps) {
    delete step.options?.onBeforeNext;
    delete step.options?.onBeforePrevious;
  }

  return cloned;
};

const UnknownStep: React.FC<WizardStepProps> = () => (
  <div>
    <h1>Error: A step with an unknown ID was added</h1>
    <p>
      This should have caused a linter error however you likely forgot to
      register the step in <pre>src/components/WizardSteps/index.tsx</pre>
    </p>
  </div>
);

export default Wizard;
