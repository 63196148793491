import React from 'react';

import NylasAuthoriseEndpointV1 from './NylasAuthoriseEndpointV1';
import { WithNavigationFrame as NotFound } from '~/components/404';
import { RouteComponentProps } from '@reach/router';
import DHRouter from '~/components/DHRouter';

const Nylas: React.FC<RouteComponentProps> = () => (
  <DHRouter>
    <NylasAuthoriseEndpointV1 path={`/auth/v1`} />
    <NotFound default />
  </DHRouter>
);

export default Nylas;
