/**
 * Atribute names for the variables
 */
export const HTML_TYPE_ATTRIBUTE_NAME = 'dhvariabletype';
export const HTML_VARIABLE_NAME_ATTRIBUTE_NAME = 'dhvariablename';
export const HTML_FIELD_NAME_ATTRIBUTE_NAME = 'dhfieldname';
export const HTML_MAPPING_ID_ATTRIBUTE_NAME = 'dhmappingid';
export const HTML_VARIABLE_ATTRIBUTE_NAME = 'dhvariable';
export const HTML_ERROR_ATTRIBUTE_NAME = 'dherror';

/**
 * Attribute names for the inline images
 *
 * Inline images inserted as:
 *
 * <img src="" data-dhfilename="<filename>" data-dhinlineid="<inlineId>" data-s3key="<s3key>" data-dhcontenttype="image/png" data-dhcontentlength="24000" />
 */
export const INLINE_IMAGE_ID_ATTR = 'data-dhinlineid';
export const INLINE_IMAGE_FILENAME_ATTR = 'data-dhfilename';
export const INLINE_IMAGE_S3KEY_ATTR = 'data-s3key';
export const INLINE_IMAGE_CONTENT_TYPE_ATTR = 'data-dhcontenttype';
export const INLINE_IMAGE_CONTENT_LENGTH_ATTR = 'data-dhcontentlength';
export const INLINE_IMAGE_SRC_ATTR = 'data-dhsrcurl';
export const INLINE_IMAGE_FROM_BACKEND_REGEX =
  /<img[^>]*?src="\[\[inlineId:.*?\]\].*?"[^>]*?>/g;
export const INLINE_IMAGE_REGEX = /<img[^>]*?data-s3key=".*?"[^>]*?>/g;
export const INLINE_IMAGE_ID_REGEX = /data-dhinlineid="[^"]*"[\s]*/g;
export const INLINE_IMAGE_FILENAME_REGEX = /data-dhfilename="[^"]*"[\s]*/g;
export const INLINE_IMAGE_S3KEY_REGEX = /data-s3key="[^"]*"[\s]*/g;
export const INLINE_IMAGE_CONTENT_TYPE_REGEX =
  /data-dhcontenttype="[^"]*"[\s]*/g;
export const INLINE_IMAGE_CONTENT_LENGTH_REGEX =
  /data-dhcontentlength="[^"]*"[\s]*/g;
export const INLINE_IMAGE_SRC_REGEX = /data-dhsrcurl="[^"]*"[\s]*/g;

export const HTML_VARIABLE_REGEX = new RegExp(
  `<img[^>]*?${HTML_VARIABLE_ATTRIBUTE_NAME}[^>]*?>`,
  'g',
);

export const IMG_REGEX = /<img[^>]*?src="[^"]*?"[^>]*?>/g;

export const IMAGE_ALLOWED_TYPES = ['jpeg', 'jpg', 'png', 'gif'];
export const UNSUBSCRIBE_LINK_BUTTON_NAME = 'unsubscribeLinkButton';
export const OPEN_INSERT_HTML_BUTTON_NAME = 'openInsertHTMLButton';
