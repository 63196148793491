import {
  AssignContactAction,
  ActionInput,
  InexactAssignContactActionFieldsFragment,
} from './types.flow';
import { BaseActionProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { asOfficeParameterValue } from '../Base/FlowParameter/ParameterValue/Office';
import { asUserParameterValue } from '../Base/FlowParameter/ParameterValue/User';

export default (
  item: InexactAssignContactActionFieldsFragment,
  baseProps: BaseActionProps,
): AssignContactAction => {
  const { id } = item;

  return {
    ...baseProps,
    type: FLOW_ACTION_TYPE.ASSIGN_CONTACT,
    id,
    actionInput: asActionInput(item),
  };
};

export const asActionInput = (
  fragment: InexactAssignContactActionFieldsFragment,
): ActionInput => {
  const { officeField, user, overwriteAssignee } = fragment;

  return {
    office: asOfficeParameterValue(officeField),
    user: asUserParameterValue(user),
    overwriteAssignee: overwriteAssignee,
  };
};
