import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import JustificationContainer from '~/components/JustificationContainer';
import ExternalLink from '~/components/ExternalLink';
import TextButton from '~/components/TextButton';
import TEST_ID from './index.testid';
import { isNil } from 'ramda';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import Input from '~/components/Input';
import isValidLink from './utils/isValidLink';
import { animated, useTransition } from 'react-spring';

export type Props = {
  dataTestId?: string;
  onSave: (val: { url: string; text: string }) => void;
  urlValue?: string;
  textValue?: string;
  submitText?: string;
};

const text = {
  validationMessage: 'Moet beginnen met http://, https://, mailto: of tel:',
  submit: 'Voer een url in',
  warningMessage: "'tel:' link moet beginnen met een landcode, bijv. +31",
};

const EditUrlBlock: React.FC<Props> = ({
  onSave,
  urlValue = '',
  textValue = '',
  submitText = 'Link invoegen',
  children,
  ...rest
}) => {
  const [url, setUrl] = useState<string>(urlValue);
  const [linkText, setLinkText] = useState<string>(textValue);
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);

  const onSubmit = useCallback(() => {
    if (!isValidLink(url)) {
      setHasValidationError(true);
      return;
    }

    onSave({ url, text: linkText });
  }, [url, linkText, onSave]);

  useGlobalKeyBinding({ keys: 'enter', callback: onSubmit, enabled: true });

  const showWarning = url.startsWith('tel:');
  const transitions = useTransition(showWarning, {
    from: { transform: 'translateY(10px)', opacity: 0 },
    enter: { transform: 'translateY(-10px)', opacity: 1 },
    leave: { transform: 'translateY(10px)', opacity: 0 },
  });

  return (
    <Container data-testid={TEST_ID.CONTAINER} {...rest}>
      <LinkContainer>
        <ExternalLink
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
          disabled={!url}
          onClick={e => {
            if (!url) e.preventDefault();
          }}
        >
          <Icon name="externalLink" />{' '}
        </ExternalLink>
      </LinkContainer>

      <InputContainer>
        {transitions((style, show) => (
          <>
            {show && (
              <InfoContainer style={style}>
                <Subheader>Let op: </Subheader>
                {text.warningMessage}
              </InfoContainer>
            )}
          </>
        ))}

        <Input
          label="URL"
          value={url || ''}
          onChange={e => {
            if (hasValidationError) setHasValidationError(false);
            if (!isNil(e?.target?.value)) {
              setUrl(e.target.value);
            }
          }}
          type="url"
          autoFocus
          name="url"
          externalErrors={
            hasValidationError ? [text.validationMessage] : undefined
          }
        />
      </InputContainer>

      <InputContainer>
        <Input
          label="Tekst"
          value={linkText || ''}
          onChange={e => {
            if (!isNil(e?.target?.value)) {
              setLinkText(e.target.value || '');
            }
          }}
          name="text"
        />
      </InputContainer>

      <JustificationContainer align="center" justification="end">
        {children}

        <TextButton
          label={submitText}
          onClick={onSubmit}
          dataTestId={TEST_ID.SUBMIT_BUTTON}
        />
      </JustificationContainer>
    </Container>
  );
};

const Container = styled.div<{}>`
  max-width: 190px;
`;

const LinkContainer = styled.div<{}>`
  text-align: end;
`;

const InfoContainer = styled(animated.div)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('primary', 'translucent')};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xs')};
  `,
);

const Subheader = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary')};
    font-weight: bold;
  `,
);

const InputContainer = styled.div<{}>`
  /* Fixes FloatingLabel overflow */
  label > div:first-child {
    white-space: normal;
    text-align: left;
  }

  ${({ theme }) => css`
    margin: ${theme.space('base')} 0;
  `}
`;

export default EditUrlBlock;
