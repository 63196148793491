import React from 'react';
import styled, { css } from 'styled-components';

import { StripeSubscription } from '../../types.flow';

import { PRICING_INFO } from '../../constants';
import { isAfterOrEqual, getStartOfToday, format } from '~/util/date';
import {
  subscriptionPlanLabel,
  billingCycleLabel,
} from '../../util/pricingLabelHelpers';

import TEST_ID from './index.testid';

const text = {
  perMonthLabel: 'p/m',
  endsOn: (endDate: Date) => `Eindigt op ${format(endDate, 'd MMMM yyyy')}`,
  startsOn: (startDate: Date) => `Start op ${format(startDate, 'd MMMM yyyy')}`,
};
type Props = {
  subscription: StripeSubscription;
};
const SubscriptionDetails: React.FC<Props> = ({ subscription, ...rest }) => {
  const { plan, billingCycle, startDate, endDate } = subscription;

  const pricingInfo = PRICING_INFO[plan];
  let willEnd = false;

  let subscriptionInfo;
  if (endDate != null && isAfterOrEqual(endDate, getStartOfToday())) {
    willEnd = true;
    subscriptionInfo = text.endsOn(endDate);
  } else if (
    startDate != null &&
    isAfterOrEqual(startDate, getStartOfToday())
  ) {
    subscriptionInfo = text.startsOn(startDate);
  } else {
    subscriptionInfo = pricingInfo.description;
  }

  return (
    <Container {...rest}>
      <NameContainer>
        <SubscriptionTitle
          data-plan={plan}
          data-billingcycle={billingCycle}
          data-testid={TEST_ID.SUBSCRIPTION_TITLE}
        >
          {subscriptionPlanLabel(plan)}, {billingCycleLabel(billingCycle)}
        </SubscriptionTitle>
        <SubscriptionInfo
          data-testid={TEST_ID.SUBSCRIPTION_INFO}
          data-iscancelled={willEnd}
        >
          {subscriptionInfo}
        </SubscriptionInfo>
      </NameContainer>
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NameContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;

  flex-grow: 1;
`;

const SubscriptionTitle = styled.span<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('m')};
    font-weight: bold;
    padding-bottom: ${theme.space('xxs')};
  `,
);

const SubscriptionInfo = styled.span<{}>(
  ({ theme }) => css`
    padding-left: ${theme.space('m')};
  `,
);

export default SubscriptionDetails;
