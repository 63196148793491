import { PinnedApp } from '~/scenes/Settings/Widget/states/pinnedApps';

type Args = {
  id: string;
  weight: number;
};

const generatePinnedItem = ({ id, weight }: Args): PinnedApp => ({
  id,
  pinned: {
    __typename: 'WidgetSettingsPinned',
    enabled: true,
    weight,
  },
});

export default generatePinnedItem;
