import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  AppBbWaardecheck__Input,
  AppVboWaardecheck__Input,
  GetAppBbWaardecheckRouteAvailabilityQuery,
  GetAppBbWaardecheckRouteAvailabilityQueryVariables,
  GetAppVboWaardecheckRouteAvailabilityQuery,
  GetAppVboWaardecheckRouteAvailabilityQueryVariables,
} from '~/graphql/types';
import Input from '~/scenes/Apps/components/Input';
import InputGroup from '~/scenes/Apps/components/InputGroup';
import InputGroupDivider from '~/scenes/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import {
  AppWaardecheckFieldsFragment,
  appWaardecheckState,
  StateId,
} from '../..';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import ControlledInput from '~/scenes/Apps/components/ControlledInput';
import useDebounce from '~/hooks/useDebounce';
import TEST_ID from './index.testid';
import Icon from '~/components/Icon';
import { isEmpty } from '~/util/Validation/String';
import ControlledHTMLInput from '~/scenes/Apps/components/ControlledHTMLInput';
import { Checkbox } from '~/components/Inputs';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import HrefInput from '../../components/HrefInput';
import MainPreview from '../../components/MainPreview';
import { Page } from '../../components/Preview';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
  routeAvailability: {
    data?:
      | GetAppBbWaardecheckRouteAvailabilityQuery
      | GetAppVboWaardecheckRouteAvailabilityQuery;
    variables?:
      | GetAppBbWaardecheckRouteAvailabilityQueryVariables
      | GetAppVboWaardecheckRouteAvailabilityQueryVariables;
    loading: boolean;
  };
  getRouteAvailability: (route: string) => void;
};

const TEXT = {
  error: {
    routeUnavailable: 'Deze route is niet beschikbaar',
  },
};

const HTML_CHAR_LIMIT = 20000;

type InputType =
  | AppBbWaardecheck__Input['general']
  | AppVboWaardecheck__Input['general'];
const Settings: React.FC<Props> = ({
  data,
  loading,
  routeAvailability,
  getRouteAvailability,
}) => {
  const { id: accountId } = useCurrentAccount();
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  /** Update the form */
  const updateValue = (value: DeepPartial<InputType>) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        general: value,
      }) as any as typeof prev;
    });
  };

  /** Check the route availability */
  const debouncedRoute = useDebounce(updated?.route, 1000);
  const statuses = useRef([
    <Label key="checking" $color="#5191b7">
      <Icon name="spinner" /> Beschikbaarheid controleren
    </Label>,
    <Label key="available" $color="#3da07b">
      <Icon name="check" strokeWidth={2.5} /> Beschikbaar
    </Label>,
    <Label key="taken" $color="#fc7e64">
      <Icon name="close" strokeWidth={2.5} /> Niet beschikbaar
    </Label>,
  ]);

  const {
    loading: routeLoading,
    data: routeData,
    variables: routeVariables,
  } = routeAvailability;

  useEffect(() => {
    if (debouncedRoute == null || isEmpty(debouncedRoute)) return;

    getRouteAvailability(debouncedRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, debouncedRoute]);

  if (updated == null) return null;
  const { general } = updated;

  /**
   * Extra errors
   */
  const errors: Array<string> = [];

  /**
   * Show the appropriate label if the route is currently changing.
   */
  let statusIndex: number | undefined = undefined;
  if (updated.route !== data.route) {
    const isCheckingRoute = debouncedRoute !== updated.route || routeLoading;

    statusIndex = 0;

    if (
      !isCheckingRoute &&
      routeData != null &&
      routeVariables?.route === debouncedRoute
    ) {
      statusIndex = routeData.isAvailable ? 1 : 2;
      if (statusIndex === 2) errors.push(TEXT.error.routeUnavailable);
    }
  }

  const facebookPixelConfigured = !isEmpty(general.facebookPixel);

  return (
    <AppDetailsContainer header="Algemeen" icon="gear" loading={loading}>
      <InputGroup>
        <ControlledInput
          id="route"
          statuses={!isEmpty(updated?.route) ? statuses.current : undefined}
          statusIndex={statusIndex}
          prefix="https://mijnwaardecheck.nl/"
          label="Gewenste adres"
          reset="Bekijk live"
          onReset={() => {
            window.open(
              `https://mijnwaardecheck.nl/${data.route}`,
              '_blank',
              'noopener,noreferrer',
            );
          }}
          errors={errors}
          transform={[
            value => value?.toLowerCase() ?? null,
            (val, prev) => {
              if (!/^([a-z0-9]|-)+$/.test(val ?? '') && val != null) {
                return prev;
              }

              return val;
            },
          ]}
          value={updated.route}
          validation={[
            value => {
              if (/^([a-z0-9]|-)+$/.test(value ?? '')) return true;

              return 'Vul een waarde in. Alleen letters, cijfers en het streepje(-) zijn toegestaan';
            },
          ]}
          onChange={async route => {
            setUpdatedValue(prev => {
              if (!prev) return prev;

              return {
                ...prev,
                route: route || '',
              };
            });
          }}
          dataTestId={TEST_ID.ROUTE}
        />
      </InputGroup>
      <MainPreview data={updated} page={Page['/']} />

      <InputGroupDivider />
      <InputGroup>
        <HrefInput
          id="general.privacyStatement"
          title="Privacy statement"
          description="Een verwijzing naar de privacyverklaring wordt toegevoegd bij de stap “Contactgegevens”."
          value={general.privacyStatement}
          onChange={privacyStatement => {
            updateValue({ privacyStatement });
          }}
          dataTestId={TEST_ID.PRIVACY_STATEMENT}
        />
      </InputGroup>
      <InputGroupDivider />
      <InputGroup>
        <HrefInput
          id="general.cookieStatement"
          title="Cookie statement"
          description="Een verwijzing naar de cookieverklaring wordt toegevoegd in de cookienotificatie. Deze wordt alleen
          weergegeven zodra er gebruik wordt gemaakt tracking cookies."
          value={general.cookieStatement}
          onChange={cookieStatement => {
            updateValue({ cookieStatement });
          }}
          dataTestId={TEST_ID.COOKIE_STATEMENT}
        />
      </InputGroup>
      <InputGroupDivider />
      <AppEntryItemHeader>Tracking</AppEntryItemHeader>
      <Description>
        Het gedrag van bezoekers wordt bijgehouden en kan worden gebruikt voor
        het bijhouden van de conversie of het optimaliseren van
        advertentiecampagnes. In de kennisbank vind je de gebruikshandleiding
        voor{' '}
        <a
          href="https://help.dathuis.nl/nl/articles/8077988-tracking-van-de-waardecheck-powered-by-brainbay-op-mijnwaardecheck-nl-met-google-analytics-4"
          target="_blank"
          rel="noreferrer"
        >
          Google
        </a>{' '}
        en{' '}
        <a
          href="https://help.dathuis.nl/nl/articles/5067270-tracking-via-de-facebook-pixel"
          target="_blank"
          rel="noreferrer"
        >
          Facebook
        </a>
        .
      </Description>
      <InputGroup>
        <Input
          disabled
          label="Google Analytics (verouderd)"
          value={general.googleAnalytics}
          placeholder="GA-..."
          onChange={googleAnalytics => {
            updateValue({ googleAnalytics });
          }}
          dataTestId={TEST_ID.GOOGLE_ANALYTICS}
        />
        <Input
          label="Google Analytics 4"
          value={general.googleAnalytics4}
          placeholder="G-..."
          onChange={googleAnalytics4 => {
            updateValue({ googleAnalytics4 });
          }}
          dataTestId={TEST_ID.GOOGLE_ANALYTICS_4}
        />
        <Input
          label="Facebook Pixel"
          placeholder="1234..."
          value={general.facebookPixel}
          onChange={facebookPixel => {
            updateValue({
              facebookPixel,

              // Any update to the Facebook pixel config should force cookie banner `true`
              showCookieBanner: true,
            });
          }}
          dataTestId={TEST_ID.FACEBOOK_PIXEL}
        />
      </InputGroup>
      <InputGroupDivider />
      <AppEntryItemHeader>Custom HTML</AppEntryItemHeader>

      <Description>
        ⚠️ Wanneer je de keuze maakt om eigen HTML toe te voegen aan de app
        kunnen wij niet meer de kwaliteit van de applicatie garanderen. Gebruik
        deze functionaliteit alleen als je begrijpt wat je doet!
      </Description>
      <InputGroup>
        <ControlledHTMLInput
          id="general.html"
          dataTestId={TEST_ID.CUSTOM_HTML}
          errors={errors}
          onChange={value => {
            // We're not allowed to send empty string values for HTML so when the value is an empty string we will set `null`
            const html = isEmpty(value) ? null : value;
            updateValue({ html });
          }}
          value={general.html ?? ''}
          height="200px"
          validation={[
            value =>
              value === null || value.length <= HTML_CHAR_LIMIT
                ? true
                : 'De HTML heeft te veel characters, wij staan maar 20.000 characters toe.',
          ]}
        />
      </InputGroup>
      <InputGroupDivider />
      <AppEntryItemHeader>Cookie banner</AppEntryItemHeader>

      <Description>
        In sommige gevallen kan je er voor kiezen om geen cookie banner te laten
        zien. Zorg er wel voor dat je op de hoogte bent van de{' '}
        <a
          href="https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/avg-europese-privacywetgeving"
          target="_blank"
          rel="noreferrer noopener"
        >
          wet AVG
        </a>
        .
      </Description>
      <Checkbox
        onChange={value => {
          updateValue({ showCookieBanner: !value.target.checked });
        }}
        value={general.showCookieBanner ?? true}
        disabled={facebookPixelConfigured}
        label="Cookie banner tonen?"
      />
    </AppDetailsContainer>
  );
};

const Label = styled.div<{ $color: string }>(
  ({ theme, $color }) => css`
    background-color: ${$color};
    color: #fff;
    padding: ${theme.space('xxs')};
    transform: translateY(-${theme.space('xxs')});
    border-radius: ${theme.getTokens().border.radius.s};
    display: flex;
    align-items: center;
    /**
    * Because of the line-height of the div, there is extra space under the text that makes it look like it's close to the top..
    * This way we push the text below.
    */
    padding-top: 8px;
    & > span {
      margin-right: 4px;
    }
  `,
);

export default Settings;
