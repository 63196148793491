import {
  flatten,
  groupBy,
  isNil,
  map,
  pipe,
  prop,
  sortBy,
  values,
} from 'ramda';
import { ZapierApiKey } from '~/graphql/types';

const groupByLastConnectedAt = groupBy((item: ZapierApiKey) => {
  if (isNil(item.lastConnectedAt)) return '1';
  return '0';
});
const sortByCreatedDate = map(sortBy(prop('createdDate')));

const sortApiKeys: (apiKeys: Array<ZapierApiKey>) => Array<ZapierApiKey> =
  apiKeys => {
    if (!apiKeys) return [];

    return pipe(
      groupByLastConnectedAt,
      values,
      sortByCreatedDate,
      flatten,
    )(apiKeys);
  };

export default sortApiKeys;
