import React from 'react';
import styled from 'styled-components';

import { GetExtendedEmailQuery } from '~/graphql';
import query from '~/graphql/query/GetExtendedEmail';
import Loading from '~/components/Loading';
import AppErrorScreen from '~/components/AppErrorScreen';
import Catalog from '~/Catalog';
import ErrorTypes from '~/ErrorTypes';
import { getErrorTypes } from '~/util/errorHandling';
import cleanedFilename from '~/util/cleanedFilename';
import useCurrentAccount from '~/hooks/useCurrentAccount';

const text = {
  unknownErrorMessage: Catalog.genericUnknownErrorMessage,
  notSyncedErrorMessage:
    'De volledige e-mail is nog niet beschikbaar, probeer het later nog eens.',
  mailboxNotFoundErrorMessage:
    'De volledige e-mail kan niet worden weergegeven. Mogelijk is deze verwijderd of verplaatst.',
};
type GetFullEmailMessageProps = {
  eventId: string;
  renderProp: (extendedEmail: string) => JSX.Element | null;
};
export const GetFullEmailMessage = ({
  eventId,
  renderProp,
}: GetFullEmailMessageProps) => {
  const account = useCurrentAccount();

  return (
    <Container>
      <GetExtendedEmailQuery
        name={cleanedFilename(__filename)}
        query={query}
        variables={{
          accountId: account.id,
          id: eventId,
        }}
        fetchPolicy={'cache-first'}
        doNotReportErrorTypes={[ErrorTypes.emailNotSynced]}
      >
        {({ data, loading, error }) => {
          if (loading) return <Loading />;

          if (!data || !data.getExtendedEmail || error) {
            const errorTypes = getErrorTypes(error);
            let errorMessage = text.unknownErrorMessage;

            if (errorTypes.includes(ErrorTypes.emailNotSynced)) {
              errorMessage = text.notSyncedErrorMessage;
            }

            if (errorTypes.includes(ErrorTypes.notFoundError)) {
              errorMessage = text.mailboxNotFoundErrorMessage;
            }

            return (
              <AppErrorScreen
                inline
                message={errorMessage}
                setBackgroundColor={false}
              />
            );
          }

          return renderProp(data.getExtendedEmail.body);
        }}
      </GetExtendedEmailQuery>
    </Container>
  );
};

type Props = {
  eventId: string;
};
const FullEmailMessage = ({ eventId }: Props) => (
  // We can safely set the html as backend has already sanitized it
  <GetFullEmailMessage
    eventId={eventId}
    renderProp={extendedEmail => (
      <MailHTMLContainer
        className={'dh-email-container'}
        dangerouslySetInnerHTML={{ __html: extendedEmail }}
      />
    )}
  />
);
const MailHTMLContainer = styled.div<{}>`
  border: ${({ theme }) => theme.getTokens().border.width.s} solid transparent;
`;

const Container = styled.div<{}>`
  min-height: 50px;
`;

export default FullEmailMessage;
