import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

export const submitCancellationForm = (
  email: string,
  reason: string,
  reasonExplanation: string,
) => {
  const formData = {
    fields: [
      {
        name: 'email',
        value: email,
      },
      {
        name: 'reden_opzeggen',
        value: reason,
      },
      {
        name: 'overige_reden',
        value: reasonExplanation,
      },
    ],
  };

  fetch(process.env.HUBSPOT_CANCEL_FORM_URL as string, {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(result => {
      if (result.status !== 200) {
        reportErrorToTracker(
          `${cleanedFilename(
            __filename,
          )} | Error while posting form (${JSON.stringify(formData)}) to (${
            process.env.HUBSPOT_CANCEL_FORM_URL
          }) | Received: ${JSON.stringify(result)}`,
        );
      } else {
        void result.json().then(hubspotResponse => {
          const errors = hubspotResponse?.errors;
          if (Array.isArray(errors) && errors.length > 0) {
            reportErrorToTracker(
              `${cleanedFilename(
                __filename,
              )} | Error while posting form (${JSON.stringify(formData)}) to (${
                process.env.HUBSPOT_CANCEL_FORM_URL
              }) | Received errors: ${JSON.stringify(errors)}`,
            );
          }
        });
      }
    })
    .catch(e => {
      reportErrorToTracker(
        `${cleanedFilename(
          __filename,
        )} | Error while posting form (${JSON.stringify(formData)}) to (${
          process.env.HUBSPOT_CANCEL_FORM_URL
        }) | Error: ${JSON.stringify(e)}`,
      );
    });
};
