import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Tabs from '~/components/Tabs';
import TabBody from '~/components/Tabs/components/TabBody';
import { Heading5, Body } from '~/components/Typography/index';
import TEST_ID from './index.testid';

import {
  ConnectorOperator,
  SortDirection,
  SortFieldAdvanced,
  useGetContactsQuery,
} from '~/graphql/types';
import Button from '~/components/Button';
import expressActivity from '~/util/expressActivity';
import SimpleTable, { Row } from '~/components/SimpleTable';
import Link from '~/components/Link';
import truncateName from '../../utils/truncateName';
import Loading from '~/components/SimpleTable/components/Loading';
import CardHeader from '../CardHeader';
import getDateRangeOptions from './utils/getDateRangeOptions';
import Icon from '~/components/Icon';
import LeadScoreDisplayer from '~/components/LeadScoreDisplayer';
import { isNil } from 'ramda';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import withMasonryCard from '../withMasonryCard';

export type Props = {
  dataTestId?: string;
};

const getColumns = () => ({
  LeadScore: {
    header: <IconHeader name="lead-score" />,
    format: (value: number) => <LeadScoreDisplayer leadScore={value} />,
  },
  name: {
    header: 'Naam',
    format: (value: string, row: Row) => (
      <Link to={`/-/contacts/${row.id}`} style={{ whiteSpace: 'nowrap' }}>
        {truncateName(value)}
      </Link>
    ),
  },
  lastActivity: {
    header: 'Laatste activiteit',
    format: expressActivity,
  },
});

const text = {
  heading: 'Openstaande kansen',

  noContactsHeader: 'Geen contacten',
  noContactBody: 'Je hebt nog geen contacten aangemaakt.',
  noContactWithLeadScoreBody:
    'Er zijn geen contacten met sterke betrokkenheid.',
  noContactCTA: 'Maak een contact aan',

  errorHeader: 'Uh-oh 😱',
  errorBody:
    'Het lijkt er op dat er iets mis is gegaan bij het ophalen van jouw contacten. Probeer het later opnieuw.',
};

export const defaultQueryVariables = {
  query: '',
  limit: 5,
  nodes: {
    connector: ConnectorOperator.And,
    nodes: [],
  },
};

const nodesMap = {
  [SortFieldAdvanced.ThreeDayChange]: [
    { threeDayChange: { lte: 90 } },
    {
      leadScore: { eq: 0, negate: true },
    },
  ],
  [SortFieldAdvanced.SevenDayChange]: [
    {
      sevenDayChange: { lte: 90 },
    },
    {
      leadScore: { eq: 0, negate: true },
    },
  ],
};

const tabsConfig = [
  {
    label: 'Sterke betrokkenheid',
    direction: SortDirection.Desc,
  },
  {
    label: 'Dalende betrokkenheid',
    direction: SortDirection.Asc,
  },
];

const allTimeNode = {
  Leaf: {
    Contact: { Details: { leadScore: { eq: 0, negate: true } } },
  },
};

const ChangeInLeadScore: React.FC<Props> = () => {
  const { id: accountId } = useCurrentAccount();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
  const [sortDirection, setSortingDirection] = useState<SortDirection>(
    SortDirection.Desc,
  );
  const options = getDateRangeOptions();
  const selectedField = options[selectedOptionIndex].payload;

  const variables = {
    accountId,
    query: '',
    limit: 5,
    nodes: {
      connector: ConnectorOperator.And,
      nodes:
        selectedField === SortFieldAdvanced.OneDayChange ||
        isNil(nodesMap[selectedField]) // Any duration not in the map will use leadscore not equal to 0 node
          ? [allTimeNode]
          : [
              ...nodesMap[selectedField].map(field => ({
                Leaf: { Contact: { Details: field } },
              })),
            ],
    },
    sortBy: {
      direction: sortDirection,
      field: selectedField,
    },
  };

  const { data, error, loading } = useGetContactsQuery({
    variables,
  });

  const FilledCardHeader = () => (
    <CardHeader
      headingText={text.heading}
      onOptionChange={({ selectedOptionIdx }) =>
        setSelectedOptionIndex(selectedOptionIdx)
      }
      options={options}
      selectedOptionIndex={selectedOptionIndex}
      dataTestId={TEST_ID.CARD_HEADER}
    />
  );

  if (error) {
    return (
      <>
        <FilledCardHeader />
        <Heading5>{text.errorHeader}</Heading5>
        <Body>{text.errorBody}</Body>
      </>
    );
  }

  if (data && data.getContacts.totalContactsInAccount <= 0) {
    return (
      <>
        <FilledCardHeader />
        <Heading5 dataTestId={TEST_ID.NO_CONTACTS}>
          {text.noContactsHeader}
        </Heading5>
        <Body>{text.noContactBody}</Body>
        <Button
          size="medium"
          onClick={() => navigate('/-/contacts')}
          label={text.noContactCTA}
        />
      </>
    );
  }

  if (data && data.getContacts.items.length <= 0) {
    return (
      <>
        <FilledCardHeader />
        <Heading5 dataTestId={TEST_ID.NO_CONTACTS_WITH_LEADSCORE}>
          {text.noContactsHeader}
        </Heading5>
        <Body>{text.noContactWithLeadScoreBody}</Body>
      </>
    );
  }

  const rows = data?.getContacts.items || [];

  return (
    <>
      <FilledCardHeader />
      <Tabs
        onChange={index => setSortingDirection(tabsConfig[index].direction)}
      >
        {tabsConfig.map(({ label }) => (
          <TabBody label={label} key={label}>
            {loading ? (
              <Loading />
            ) : (
              <SimpleTable
                columns={getColumns()}
                rows={rows}
                dataTestId={TEST_ID.TABLE}
              />
            )}
          </TabBody>
        ))}
      </Tabs>
    </>
  );
};

const IconHeader = styled(Icon)<{}>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.2rem;
`;

export default withMasonryCard(ChangeInLeadScore, {
  containerTestId: TEST_ID.CONTAINER,
});
