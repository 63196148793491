/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import {
  FlowV2_Action_WaitFragment,
  FlowV2_Update_Action,
} from '~/graphql/types';
import ConditionEditor, {
  text as conditionEditorText,
} from '../ConditionEditor';
import { Props as FormProps } from '../ActionForm';
import RadioButton from '~/components/RadioButton';
import { Body, Heading4, Variant } from '~/components/Typography/index';
import { equals, isNil } from 'ramda';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';
import JustificationContainer from '~/components/JustificationContainer';
import { animated, useTransition } from 'react-spring';
import { useRecoilValue } from 'recoil';
import metadata from '~/scenes/Automation/v2/state/metadata';
import useBuilderContext from '../../../Builder/hooks/useBuilderContext';
import { getFlowActionToClientAction } from '~/scenes/Automation/v2/util/getFlowActionsToClientActions';
import { ClientFlowActionWait } from '~/graphql/types.client';
import { text as metadataText } from '~/scenes/Automation/v2/components/Builder/components/nodeTypes/components/WaitForCard';
import TEST_ID from './index.testid';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_WaitFragment;
};

const text = {
  ...conditionEditorText,
  ...metadataText,
  noop: 'Geen actie',
};

const WaitAction: React.FC<Props> = ({ action, onChange }) => {
  const flowMetaData = useRecoilValue(metadata);
  const { initialFlow } = useBuilderContext();

  const savedAction = initialFlow.actions.find(a => a.id === action.id);
  const converted: ClientFlowActionWait | null = savedAction
    ? (getFlowActionToClientAction(savedAction) as ClientFlowActionWait)
    : action;

  const updated = isExistingActionUpdated({
    savedAction,
    triggers: action.triggers,
    connector: action.connector,
    converted,
  });

  const selectedOption =
    flowMetaData.find(m => m.flowBlueprintActionId === action.id)?.action ||
    FlowV2_Update_Action.Noop;

  const [option, setOption] = useState(
    selectedOption || FlowV2_Update_Action.Noop,
  );
  const [conditionChange, setConditionChange] = useState<
    Pick<ClientFlowActionWait, 'triggers' | 'connector'>
  >({
    triggers: action.triggers,
    connector: action.connector,
  });

  const [hasMetadata, setHasMetadata] = useState<boolean>(updated || false);

  const onInputChange = useCallback(
    (triggers, connector) => {
      const hasChanged = isExistingActionUpdated({
        savedAction,
        triggers,
        connector,
        converted,
      });

      setHasMetadata(hasChanged);
      setConditionChange({
        triggers,
        connector,
      });
    },
    [converted, savedAction],
  );

  useEffect(() => {
    onChange({
      ...action,
      ...conditionChange,
      metadataAction: hasMetadata ? option : null,
    });
  }, [option, onChange, action, hasMetadata, conditionChange]);

  const transition = useTransition(hasMetadata, {
    from: { opacity: 0, transform: 'translate3d(0,-30px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-30px,0)' },
  });

  return (
    <>
      <ConditionEditor
        connector={action.connector}
        triggers={action.triggers}
        onChange={onInputChange}
        actionType={action.actionType}
        actionId={action.id}
        mainRowLabel={text.conditionRowLabel}
        conditionRowLabel={text.conditionRowLabel}
      />

      <>
        {transition((style, item) => {
          if (!item) return null;
          return (
            <Container style={style} data-testid={TEST_ID.METADATA_CONTAINER}>
              <JustificationContainer
                align="center"
                margin={[null, null, 'base', null]}
              >
                <Icon
                  name="exclamation"
                  margin={[null, 'base', 'xxxs', null]}
                  strokeWidth={3}
                />
                <Heading4 variant={Variant.primary} withoutMargin>
                  {text.confirmModalHeader}
                </Heading4>
              </JustificationContainer>
              <Body>{text.confirmModalDescription}</Body>
              <RadioButton
                checked={option === FlowV2_Update_Action.Noop}
                onChange={() => setOption(FlowV2_Update_Action.Noop)}
                label={text.noop}
                dataTestid={FlowV2_Update_Action.Noop}
              />
              <RadioButton
                checked={option === FlowV2_Update_Action.Continue}
                onChange={() => setOption(FlowV2_Update_Action.Continue)}
                label={text.continue}
                dataTestid={FlowV2_Update_Action.Continue}
              />
              <RadioButton
                checked={option === FlowV2_Update_Action.Drop}
                onChange={() => setOption(FlowV2_Update_Action.Drop)}
                label={text.drop}
                dataTestid={FlowV2_Update_Action.Drop}
              />
            </Container>
          );
        })}
      </>
    </>
  );
};

const Container = styled(animated.div)(
  ({ theme }) => css`
    background-color: ${theme.color('primary', 'translucent')};
    border-radius: ${theme.getTokens().border.radius.base};
    margin-top: ${theme.space('xxl')};
    padding: ${theme.space('m')};
    font-size: ${theme.fontSize('base')};
  `,
);

const isExistingActionUpdated = ({
  savedAction,
  triggers,
  connector,
  converted,
}): boolean => {
  if (!savedAction) return false;
  if (!equals(connector, converted?.connector)) return true;

  return !equals(
    triggers.filter(t => !isNil(t.trigger)),
    converted?.triggers,
  );
};

export default WaitAction;
