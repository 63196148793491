import React, { ReactElement, useContext, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  FlowOutputObject,
  FlowVariableStash,
} from '~/scenes/Automation/Flows/types.flow';
import {
  FlowVariableStashItem,
  FlowOutputObjectField,
} from '~/scenes/Automation/Flows/types.flow';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';

import { Dropdown, InputGroup } from '~/components/Inputs';
import {
  GLOBAL_VARIABLE_STASH_ITEM,
  FLOW_OUTPUT_OBJECT,
} from '~/scenes/Automation/Flows/constants';
import useDropdown from '~/components/Inputs/Dropdown/useDropdown';
import BaseActionContext from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import {
  getVariableOptions,
  getOutputObjectTypeOptions,
  getVariableDescriptorForPointer,
} from '~/scenes/Automation/Flows/util/variableHelpers';
import DropdownList from '~/components/Inputs/Dropdown/DropdownList';
import { Button } from '~/components/Buttons';
import Catalog from '~/Catalog';
import useSaveValidation from '~/components/util/useSaveValidation';
import { PARAMETER_VALUE_TYPE } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/constants';
import { FloatingLabel } from '~/components';
import Modal from '~/components/Modals/Modal';
import ModalHeader from '~/components/Modals/ModalHeader';
import BottomButtonRow from '~/components/Modals/BottomButtonRow';
import TEST_ID from './TemplateStringParameterInsertOrUpdateModal.testid';
import useVariableStashChangeNotifier from '~/scenes/Automation/Flows/util/useVariableStashChangeNotifier';
import { HandledFlowParameterMappingParameter } from '../../../../types.flow';

type FlowVariable = {
  stashItem: FlowVariableStashItem;
  outputObject: FlowOutputObject;
};
const text = {
  flowOutputObjectTypeLabel: 'Type variabele',
  stashVariableLabel: (objectType: FlowOutputObject | null) =>
    objectType == null ? '' : objectType.objectSelectLabel,
  saveButtonLabel: 'Toevoegen',
  updateButtonLabel: 'Wijzigen',
  requiredFieldLabel: Catalog.requiredField,
  requiredVariableLabel: Catalog.requiredField,
  selectStepLabel: 'Selecteer stap',
  fieldLabel: 'Selecteer variabele',
  dropdownTitle: 'Personaliseren',
  cancelButtonLabel: 'Annuleren',
  dropdownHelpText:
    'Gebruik data uit de flow om deze persoonlijker te maken. Data is beschikbaar via variabelen.',
};
export type UpdatingVariableProps = {
  variable: HandledFlowParameterMappingParameter;
  variableId: string;
};
export type OnSaveFunction = (
  newVariable: HandledFlowParameterMappingParameter,
  variableId: string | null,
) => void;
type Props = {
  onSave: OnSaveFunction;
  onClose: () => void;
  updatingVariable?: UpdatingVariableProps | null | undefined;
  triggerOutputType:
    | typeof FLOW_OUTPUT_TYPE.FlowOutputString
    | typeof FLOW_OUTPUT_TYPE.FlowOutputNumber
    | typeof FLOW_OUTPUT_TYPE.FlowOutputBoolean;
};
const ZapierMappingParameterInsertOrUpdateModal = ({
  onSave,
  onClose,
  updatingVariable,
  triggerOutputType,
}: Props) => {
  const { variableStash } = useContext(BaseActionContext);

  const {
    defaultFlowOutputType,
    defaultSelectedVariable,
    defaultSelectedField,
  } = getDefaults(updatingVariable, variableStash);
  const isUpdating = updatingVariable != null;

  const [hasStashChanged] = useVariableStashChangeNotifier(variableStash);
  const [allOutputObjectTypeOptions, setAllOutputObjectTypeOptions] = useState(
    getOutputObjectTypeOptions(variableStash),
  );
  const [allVariableOptions, setAllVariableOptions] = useState(
    getVariableOptions(variableStash),
  );

  if (hasStashChanged(variableStash)) {
    const newOutputObjectOptions = getOutputObjectTypeOptions(variableStash);
    const newVariableOptions = getVariableOptions(variableStash);

    setAllOutputObjectTypeOptions(newOutputObjectOptions);
    setAllVariableOptions(newVariableOptions);
  }

  const [
    selectedFlowOutputObjectTypeIdx,
    onFlowOutputObjectTypeChange,
    selectedFlowOutputObjectType,
  ] = useDropdown<FlowOutputObject>(
    allOutputObjectTypeOptions,
    allOutputObjectTypeOptions.findIndex(
      option => option.payload.outputType === defaultFlowOutputType,
    ),
  );

  const useGlobalStep =
    selectedFlowOutputObjectType != null &&
    selectedFlowOutputObjectType.outputType ===
      FLOW_OUTPUT_TYPE.FlowOutputContact;

  const options =
    selectedFlowOutputObjectType == null
      ? []
      : allVariableOptions.filter(
          option =>
            option.payload.outputObject.outputType ===
            selectedFlowOutputObjectType.outputType,
        );

  const [
    selectedVariableIdx,
    onVariableChange,
    selectedVariable,
    setSelectedVariableIdx,
  ] = useDropdown<FlowVariable>(
    options,
    options.findIndex(option =>
      isFlowVariableEqual(option.payload, defaultSelectedVariable),
    ),
  );

  // If we are using a global step we hide the dropdown, so make sure it is selected here
  if (
    useGlobalStep &&
    !isFlowVariableEqual(selectedVariable, globalContactVariable)
  ) {
    setSelectedVariableIdx(
      options.findIndex(option =>
        isFlowVariableEqual(option.payload, globalContactVariable),
      ),
    );
  }

  const filteredFields =
    selectedFlowOutputObjectType == null
      ? []
      : selectedFlowOutputObjectType.fields.filter(
          field => field.outputType === triggerOutputType,
        );

  const fieldOptions = filteredFields.map(field => ({
    label: field.label,
    payload: field,
    key: field.name,
  }));

  const validate = () => {
    const error: {
      selectedField: string | undefined;
      selectedVariable: string | undefined;
    } = {
      selectedField: undefined,
      selectedVariable: undefined,
    };

    if (selectedField == null) {
      error.selectedField = text.requiredFieldLabel;
    }

    if (selectedVariable == null) {
      error.selectedVariable = text.requiredVariableLabel;
    }

    return error;
  };

  const [selectedFieldIdx, onFieldChange, selectedField] =
    useDropdown<FlowOutputObjectField>(
      fieldOptions,
      defaultSelectedField == null
        ? null
        : fieldOptions.findIndex(
            option => option.payload.name === defaultSelectedField.name,
          ),
    );

  const [attemptSave, validationResult] = useSaveValidation<{
    selectedField: string;
    selectedVariable: string;
  }>(validate, () => {
    if (selectedVariable == null || selectedField == null) return;

    onSave(
      {
        variable: {
          name: selectedVariable.stashItem.variableName,
          field: {
            name: `${selectedVariable.outputObject.name}.${selectedField.name}`,
          },
        },
        type:
          selectedField.outputType === FLOW_OUTPUT_TYPE.FlowOutputBoolean
            ? PARAMETER_VALUE_TYPE.BOOLEAN_POINTER
            : selectedField.outputType === FLOW_OUTPUT_TYPE.FlowOutputNumber
            ? PARAMETER_VALUE_TYPE.NUMBER_POINTER
            : PARAMETER_VALUE_TYPE.STRING_POINTER,
      },
      updatingVariable == null ? null : updatingVariable.variableId,
    );
  });

  let stepDropdownComponent: ReactElement | null = null;
  if (!useGlobalStep) {
    stepDropdownComponent = (
      <InputGroup>
        <Dropdown
          dataTestid={TEST_ID.SELECT_STEP_DROPDOWN}
          label={text.selectStepLabel}
          options={options}
          selectedOptionIdx={selectedVariableIdx}
          onChange={onVariableChange}
          error={
            'selectedVariable' in validationResult
              ? validationResult.selectedVariable
              : ''
          }
        />
      </InputGroup>
    );
  }

  return (
    <Modal small onClose={onClose}>
      <ModalHeader title={text.dropdownTitle} />
      <ContentContainer>
        <Helptext>{text.dropdownHelpText}</Helptext>
        <InputGroup>
          <Dropdown
            dataTestid={TEST_ID.SELECT_OBJECT_TYPE_DROPDOWN}
            label={text.flowOutputObjectTypeLabel}
            options={allOutputObjectTypeOptions}
            selectedOptionIdx={selectedFlowOutputObjectTypeIdx}
            onChange={onFlowOutputObjectTypeChange}
          />
        </InputGroup>
        {stepDropdownComponent}
        <FloatingLabel actAsPlaceholder={false}>
          {text.fieldLabel}
        </FloatingLabel>
        <StyledDropdownList
          data-testid={TEST_ID.FIELD_LIST}
          options={fieldOptions}
          selectedOptionIdx={selectedFieldIdx}
          onChange={onFieldChange}
          error={
            'selectedField' in validationResult
              ? validationResult.selectedField
              : ''
          }
        />
      </ContentContainer>
      <BottomButtonRow>
        <Button outline onClick={onClose} data-testid={TEST_ID.CANCEL_BUTTON}>
          {text.cancelButtonLabel}
        </Button>
        <Button onClick={attemptSave} data-testid={TEST_ID.SAVE_BUTTON}>
          {isUpdating ? text.updateButtonLabel : text.saveButtonLabel}
        </Button>
      </BottomButtonRow>
    </Modal>
  );
};

const getDefaults = (
  updatingVariable: UpdatingVariableProps | null | undefined,
  variableStash: FlowVariableStash,
): {
  defaultFlowOutputType: FLOW_OUTPUT_TYPE;
  defaultSelectedVariable: FlowVariable | null;
  defaultSelectedField: FlowOutputObjectField | null;
} => {
  if (updatingVariable == null) {
    return emptyDefaults;
  }

  const { variable } = updatingVariable;

  const variableDescriptor = getVariableDescriptorForPointer(
    variable,
    variableStash,
  );

  if (variableDescriptor == null) {
    return emptyDefaults;
  }

  const { stashItem, outputObject, field } = variableDescriptor;

  if (outputObject == null) {
    // If we don't know the object we can't really choose anything
    return emptyDefaults;
  }

  return {
    defaultFlowOutputType: outputObject.outputType,
    defaultSelectedVariable: { stashItem, outputObject },
    defaultSelectedField: field == null ? null : field,
  };
};

const isFlowVariableEqual = (
  variableA?: FlowVariable | null | undefined,
  variableB?: FlowVariable | null | undefined,
): boolean => {
  if (variableA == null || variableB == null) {
    return false;
  }

  return (
    variableA.stashItem.variableName === variableB.stashItem.variableName &&
    variableA.outputObject.name === variableB.outputObject.name
  );
};

const emptyDefaults = {
  defaultFlowOutputType: FLOW_OUTPUT_TYPE.FlowOutputContact,
  defaultSelectedVariable: null,
  defaultSelectedField: null,
};

const globalContactVariable = {
  stashItem: GLOBAL_VARIABLE_STASH_ITEM,
  outputObject: FLOW_OUTPUT_OBJECT.CONTACT,
};

type StyledDropdownListProps = {
  error: string | null | undefined;
};
const StyledDropdownList = styled(DropdownList)<StyledDropdownListProps>`
  width: 100%;
  margin-top: 0;
  max-height: 150px;

  ${({ theme, error }) => {
    if (error && error.length > 0) {
      return `
        border-color: ${theme.color('danger')};
      `;
    }

    return '';
  }}
`;

const Helptext = styled.p<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('s')};
    margin: ${theme.space('m')} 0;
  `,
);

const ContentContainer = styled.div<{}>`
  height: 370px;

  ${({ theme }) => css`
    padding: 0 ${theme.space('xl')};
  `};
`;

export default ZapierMappingParameterInsertOrUpdateModal;
