import { OrderedListElement } from '~/components/PluginsEditor/types';
import getUpdatedBlockAttrs from '~/components/PluginsEditor/utils/serialize/getUpdatedBlockAttrs';

/**
 * serializes the OrderedListElement into html element
 * @param {OrderedListElement} node - OrderedListElement
 */
const serialize = (node: OrderedListElement, children: string): string => {
  const attrs = getUpdatedBlockAttrs(node);

  return `<ol${attrs ? ` ${attrs}` : ''}>${children}</ol>`;
};

export default serialize;
