import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { clone, isNil, pluck } from 'ramda';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { NodeProps } from 'reactflow';
import { animated, useSpring } from 'react-spring';
import { actionsSelector } from '~/scenes/Automation/v2/state';
import interactions from '~/scenes/Automation/v2/state/interactions';
import flowIssues, {
  issuesByActionId,
} from '~/scenes/Automation/v2/state/flowIssues';
import { DEFAULT_WIDTH } from '../..';
import Icon from '~/components/Icon';
import Button from '~/components/Button';
import JustificationContainer from '~/components/JustificationContainer';
import { Body, Heading4, Variant } from '~/components/Typography/index';
import useBuilderContext from '../../../../hooks/useBuilderContext';
import getIssueLevel from '../../../../utils/getIssueLevel';
import getCardOptions from './utils/getCardOptions';
import { getCardValues } from './utils/getCardValues';
import { ClientFlowAction } from '~/graphql/types.client';
import TEST_ID from './index.testid';
import isEmptyAction from '../../../../utils/isEmptyAction';
import getIssuesForAction from '~/scenes/Automation/v2/components/UpdateAction/utils/getIssuesForAction';
import useRelativeMaps from '../../../../hooks/useRelativeMaps';
import lastDeletedActionId from '~/scenes/Automation/v2/state/lastDeletedActionId';
import flowChanges from '~/scenes/Automation/v2/state/flowChanges';
import DropdownListContainer from '~/components/Dropdown/components/DropdownListContainer';
import { ActionLabel } from '../../../ActionLabelContainer';
import getCardLabel from './utils/getCardLabel';
import onConnectActions from './utils/onConnectActions';
import metadata from '~/scenes/Automation/v2/state/metadata';
import useOffices from '~/hooks/useOffices';
import useUsers from '~/hooks/useUsers';

export type NodeData<ActionT> = {
  id: string;
  action: ActionT;
};

export type Props<ActionT extends ClientFlowAction> = NodeProps<
  NodeData<ActionT>
> & {
  dataTestId?: string;
  actionType: ClientFlowAction['actionType'];
  heading: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

const CardTemplate: React.FC<Props<ClientFlowAction>> = ({
  actionType,
  heading,
  disabled = false,
  selected = false,
  children,
  data,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { accountId, flowBlueprintId, initialFlow, availableActions } =
    useBuilderContext();

  const setIssues = useSetRecoilState(flowIssues);
  const storedIssues = useRecoilValue(issuesByActionId(data.action.id));
  const relativeMaps = useRelativeMaps({
    actionId: data.action.id,
    addAllParentsAsInstances: true,
  });

  const offices = useOffices({ onlyExistingOffices: false });
  const users = useUsers();

  const relativeIssues = getIssuesForAction({
    action: data.action,
    relativeMaps,
    availableActions,
    opts: {
      offices,
      users,
    },
  });

  const [actions, setActions] = useRecoilState(
    actionsSelector({
      accountId,
      flowBlueprintId,
    }),
  );
  const [currentInteraction, setInteraction] = useRecoilState(interactions);
  const setFlowMetadata = useSetRecoilState(metadata);
  const [shouldHighlight, setShouldHighlight] = useState(false);

  const styles = useSpring({
    from: {
      transform: 'scale(0)',
      opacity: 0,
    },
    to: {
      transform: 'scale(1)',
      opacity: 1,
    },
    config: {
      mass: 1,
      tension: 170,
      friction: 13,
    },
  });

  const issueLevel = getIssueLevel(storedIssues);
  const { icon, color } = getCardValues({ actionType, issueLevel });

  useEffect(() => {
    const actionIds = pluck('id', actions);
    if (
      relativeIssues.length !== storedIssues.length &&
      actionIds.includes(data.action.id) &&
      !isEmptyAction(data.action) &&
      currentInteraction?.type !== 'updateAction'
    ) {
      setIssues(prev => ({
        ...prev,
        [data.action.id]: relativeIssues,
      }));
    }
  }, [
    actions,
    currentInteraction,
    data.action,
    relativeIssues,
    setIssues,
    storedIssues,
  ]);

  const isExistingAction =
    initialFlow.actions.findIndex(action => action.id === data.action.id) !==
    -1;

  // Disabled due to lack of time
  // const onDragStart = useCallback(
  //   (event: DragEvent) => {
  //     event.stopPropagation();
  //     setInteraction({ type: 'dragging', action: data.action });
  //     event.dataTransfer.setData(
  //       'application/reactflow',
  //       JSON.stringify(data.action),
  //     );
  //     event.dataTransfer.effectAllowed = 'move';
  //   },
  //   [data, setInteraction],
  // );

  const setLastDeletedActionId = useSetRecoilState(lastDeletedActionId);
  const setFlowHasChanges = useSetRecoilState(flowChanges);

  const initialActions = initialFlow.actions;
  const flowEnabled = initialFlow.enabled;
  const dropdownOptions = useMemo(
    () =>
      getCardOptions({
        action: data.action,
        actions,
        setActions,
        setInteraction,
        isExistingAction,
        setIssues,
        setLastDeletedActionId: id => setLastDeletedActionId(id),
        setFlowMetadata,
        initialActions,
        flowEnabled,
      }),
    [
      data.action,
      actions,
      setActions,
      setInteraction,
      isExistingAction,
      setIssues,
      setLastDeletedActionId,
      setFlowMetadata,
      initialActions,
      flowEnabled,
    ],
  );

  const isConnecting =
    currentInteraction?.type === 'connectAction' &&
    isNil(
      currentInteraction.validTargets.find(({ id }) => id === data.action.id),
    );

  const highlighted =
    currentInteraction?.type === 'highlightActions'
      ? currentInteraction.highlightedActions?.includes(data.action.id)
      : true;

  const enableHighlightOnMouseOver =
    currentInteraction?.type === 'connectAction';

  const actionLabel = data.action.label
    ? getCardLabel(data.action.label)
    : null;

  const onCardClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      switch (currentInteraction?.type) {
        case 'connectAction': {
          setActions(prevActions => {
            const nextActions = onConnectActions({
              actions: prevActions,
              currentInteraction,
              targetAction: data.action,
            });

            return nextActions;
          });

          setFlowHasChanges(prev => {
            const flowChanges = clone(prev);
            flowChanges.actions[data.action.id] = true;
            return flowChanges;
          });

          setShouldHighlight(false);
          return setInteraction(null);
        }
        case null:
        default:
          return setInteraction({
            type: 'updateAction',
            action: data.action,
          });
      }
    },
    [
      currentInteraction,
      setInteraction,
      data.action,
      setActions,
      setFlowHasChanges,
    ],
  );

  return (
    <Container
      // Disabled due to lack of time
      // draggable
      onClick={onCardClick}
      // Add nowheel classname to enable scrolling in cards
      // className="nowheel"
      data-testid={TEST_ID.CONTAINER}
      $color={color}
      selected={selected || showDropdown || shouldHighlight}
      disabled={disabled || !highlighted || isConnecting}
      $hasIssue={!isNil(issueLevel)}
      // onDragStart={onDragStart}
      style={styles}
      onMouseEnter={
        enableHighlightOnMouseOver ? () => setShouldHighlight(true) : undefined
      }
      onMouseLeave={
        enableHighlightOnMouseOver ? () => setShouldHighlight(false) : undefined
      }
      data-hasissue={!isNil(issueLevel)}
    >
      {dropdownOptions.length !== 0 && (
        <Options>
          <StyledMenuIcon
            data-testid={TEST_ID.OPTIONS}
            name="dot-menu"
            disabled={disabled || enableHighlightOnMouseOver}
            onClick={e => {
              e.stopPropagation();
              setShowDropdown(!showDropdown);
            }}
          />
          <StyledDropdown
            dataTestId={TEST_ID.DROPDOWN}
            onChange={v => {
              v.option.payload(data.action);
              setShowDropdown(false);
            }}
            dropdownListOpen={showDropdown}
            onClickOutside={() => setShowDropdown(false)}
            onClose={() => setShowDropdown(false)}
            options={dropdownOptions}
          />
        </Options>
      )}
      <JustificationContainer
        justification="start"
        align="center"
        nonResponsive={true}
        margin={children ? [null, null, 'm', null] : [null, null]}
      >
        <StyledIcon name={icon} iconColor={color} issue={!isNil(issueLevel)} />
        {actionLabel && <ActionLabel>{actionLabel}</ActionLabel>}
        <Heading4 variant={Variant.primary} withoutMargin>
          {heading}
        </Heading4>
      </JustificationContainer>
      <ChildrenWrapper>
        {isEmptyAction(data.action) ? (
          <>
            <Body>Deze actie is nog niet gedefineerd</Body>
            <Button
              onClick={onCardClick}
              label="Defineer deze actie"
              appearance="secondary"
            />
          </>
        ) : (
          children
        )}
      </ChildrenWrapper>
    </Container>
  );
};

const Container = styled(animated.div)<{
  $color: string;
  selected?: boolean;
  disabled?: boolean;
  $hasIssue?: boolean;
}>(
  ({ theme, $color, selected, disabled, $hasIssue }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    background: ${theme.color('white')};
    outline: ${!disabled && selected
      ? `2px solid ${$color}`
      : `1px solid transparent`};
    border-left: ${theme.spacing('xxs')} solid
      ${disabled ? theme.color('grey') : $color};
    width: ${DEFAULT_WIDTH}px;
    padding: ${theme.spacing('l')};
    cursor: pointer;
    pointer-events: ${disabled ? 'none' : 'auto'};
    box-shadow: ${$hasIssue
      ? `0 0 15px 0 ${$color}`
      : theme.getTokens().boxShadow.s};
    user-select: none;
    opacity: ${disabled && 0.5} !important;
    position: relative;

    transition: outline 0.2s ${selected ? 'ease-out' : 'ease-in'},
      opacity 0.2s ease-out;
  `,
);

const StyledIcon = styled(Icon)<{ issue: boolean; iconColor: string }>(
  ({ theme, issue, iconColor }) => css`
    padding-right: ${theme.spacing('l')};
    font-size: ${theme.fontSize('xl')};
    color: ${issue && iconColor};
  `,
);
const StyledMenuIcon = styled(Icon)<{ disabled?: boolean }>(
  ({ theme, disabled }) => css`
    color: ${disabled ? theme.color('grey') : theme.color('primary', 'light')};
    pointer-events: ${disabled ? 'none' : 'auto'};

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${theme.space('xxs')};
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 0.3s ease-out;

    &:hover {
      background-color: ${theme.color('grey', 'translucent')};
    }
  `,
);

const Options = styled.div<{}>(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.space('m')};
    right: ${theme.space('m')};
  `,
);

const ChildrenWrapper = styled.div<{}>(
  ({ theme }) => css`
    margin-left: ${theme.spacing('xxxl')};
    margin-right: ${theme.spacing('xxs')};
  `,
);

const StyledDropdown = styled(DropdownListContainer)<{}>`
  right: 0;
`;

export const handleStyle = { opacity: '0', height: '1rem', left: '50%' };

export default CardTemplate;
