import { FlowConditionAppFundaViewingRequestNew } from './types.flow';
import { FLOW_CONDITION_TYPES } from '../constants';

const emptyFlowConditionAppFundaViewingRequestNew = (): Omit<
  FlowConditionAppFundaViewingRequestNew,
  'id'
> => ({
  type: FLOW_CONDITION_TYPES.EVENT_CONTACT_APP_FUNDA_VIEWINGREQUEST_NEW.type,
});

export default emptyFlowConditionAppFundaViewingRequestNew;
