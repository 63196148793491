import React from 'react';
import uuid from 'uuid/v4';
import { Location, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';
import { isElementOfType } from '~/components/PluginsEditor/commands';
import { DHEditor, VariableElement } from '~/components/PluginsEditor/types';
import useRelativeMaps from '~/scenes/Automation/v2/components/Builder/hooks/useRelativeMaps';
import useBuilderContext from '~/scenes/Automation/v2/components/Builder/hooks/useBuilderContext';
import Selector from '~/scenes/Automation/v2/components/UpdateAction/components/Selector';
import { FlowPath } from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getFieldsByPath';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import isPrimitiveArgument from '~/scenes/Automation/v2/util/isPrimitiveArgument';
import isPointerArgument from '~/scenes/Automation/v2/util/isPointerArgument';
import getPathForPointer from '~/scenes/Automation/v2/components/UpdateAction/components/Selector/utils/getPathForPointer';
import { getPathRepresentation } from '~/scenes/Automation/v2/components/UpdateAction/components/ConditionEditor/utils/getLabelForRep';
import { Flow___ArgumentFragment } from '~/graphql/types';
import useActionContext from '~/scenes/Automation/v2/components/Builder/hooks/useActionContext';

export type Props = {
  dataTestId?: string;
  editor: DHEditor;
  path: Location | undefined;
  element: VariableElement;
  pointerOffset: number;
  pointerLocation: 'top' | 'bottom';
};

const VariableToolbar: React.FC<Props> = ({
  dataTestId,
  editor,
  path,
  element,
  pointerOffset,
  pointerLocation,
  ...rest
}) => {
  const builderContext = useBuilderContext();

  const { opts, superSubjects } = builderContext;

  const { actionId, actionType } = useActionContext();
  const maps = useRelativeMaps({ actionId });
  const currentMaps = {
    subjectToConditionMap: opts.subjectToConditionMap,
    ...maps,
  };

  const handleChange = (value: Flow___ArgumentFragment) => {
    if (isPointerArgument(value)) {
      const varPath = getPathForPointer(value.pointer, maps);

      if (varPath.error === undefined) {
        const variableName = getPathRepresentation(varPath.fullResult);

        Transforms.setNodes(
          editor,
          {
            mappingId: uuid().replaceAll('-', ''),
            variableType: 'Flow___Argument_Pointer',
            variableName,
            variableInfo: value,
            pending: false,
          },
          { at: path, match: n => isElementOfType(n, ELEMENTS.VARIABLE) },
        );
      }

      ReactEditor.focus(editor);
      Transforms.move(editor, {
        distance: 1,
        unit: 'offset',
      });
    }

    return;
  };

  const mappingValue = element.variableInfo;
  const argument = isPrimitiveArgument(mappingValue) ? mappingValue : undefined;

  let selectorPath: FlowPath = [];
  if (mappingValue && isPointerArgument(mappingValue)) {
    const pathRes = getPathForPointer(mappingValue.pointer, currentMaps);

    // When error isn't undefined, we could not get the path for the pointer
    // most likely because the pointer is missing from the instances
    // Therefore we cannot open the selector at a non existing path,
    // so go from root to correct
    if (pathRes.error === undefined) {
      selectorPath = pathRes.result;
    }
  }

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Selector
        onClose={() => {
          ReactEditor.focus(editor);
        }}
        onSelect={value => {
          if (
            value.__typename === 'Flow___InstanceCondition' ||
            value.__typename === 'Flow___SubjectFieldCondition'
          )
            return;

          handleChange(value);
        }}
        argument={argument}
        initialPath={selectorPath}
        opts={{
          ...currentMaps,
          action: actionType,
          conditionType: 'condition',
          limitToInstanceSubjects: superSubjects.TemplateString.subjectIds,
        }}
        pointerOffset={pointerOffset}
        pointerLocation={pointerLocation}
      />
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    box-shadow: ${theme.getTokens().boxShadow.card};
    width: 100%;
  `,
);

export default VariableToolbar;
