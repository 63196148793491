import React from 'react';

import { RequestUserDetailsTabProps } from './ValueReport.type';

import ValueReportComponentCard from './components/ValueReportComponentCard';
import CombinedTextWithColor from './components/CombinedTextWithColor';
import { RouteComponentProps } from '@reach/router';
import TextEditor from './components/TextEditor';
import Icon from '~/components/Icon';

type Props = {
  /** Id for tests */
  datatestId: string;

  /**  Tab text for current tab content */
  label: string;

  /** data contains: {
    mainHeader: { text },
    subHeader: { text },
    formSubtext: { text },
  }
   */
  data: RequestUserDetailsTabProps;

  /** Callback function which call data updating in container state */
  onChange: (objectName?: string) => (value: $Object) => void;
} & RouteComponentProps;

const text = {
  title: 'Gebruikergegevens',
  mainText: 'Koptekst',
  subText: 'Subtekst',
  formSubText: 'Formulier subtekst',
  formSubTextColor: 'Kleur formulier subtekst',
};

const RequestUserDetailsTab = ({ onChange, datatestId, data }: Props) => {
  const { mainHeader, subHeader, formSubtext } = data;

  return (
    <ValueReportComponentCard
      datatestId={datatestId}
      title={text.title}
      cardIcon={<Icon name="file-text" />}
    >
      <TextEditor
        testName={'mainHeader'}
        text={mainHeader.text}
        title={text.mainText}
        onChange={value => onChange('mainHeader')({ text: value })}
        name="text"
        disabled={false}
      />
      <TextEditor
        testName={'subHeader'}
        text={subHeader.text}
        title={text.subText}
        onChange={value => onChange('subHeader')({ text: value })}
        name="text"
        disabled={false}
      />
      <CombinedTextWithColor
        textTitle={text.formSubText}
        colorTitle={text.formSubTextColor}
        objectName="formSubtext"
        data={formSubtext}
        onChange={onChange('formSubtext')}
      />
    </ValueReportComponentCard>
  );
};

export default RequestUserDetailsTab;
