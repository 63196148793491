import React, { useState } from 'react';
import qs from 'query-string';
import { navigate } from 'gatsby';
import { FlowFieldsFragment } from '~/graphql/types';
import FlowBuilderComponent from './FlowBuilderComponent';
import Catalog from '~/Catalog';
import { InsertFlowMutation } from '~/graphql';
import flowQueryToFlowProp from '../util/flowQueryToFlowProp';
import mutation from '~/graphql/mutation/InsertFlow';
import cleanedFilename from '~/util/cleanedFilename';
import ContentContainerDefault from '~/components/ContentContainer/Default';
import { reportErrorToTracker } from '~/util/assertion';
import useEnabledFlowModal from '~/scenes/Automation/Flows/util/useEnabledFlowModal';
import { ApolloError } from '@apollo/client';
import useIsMountedRef from '~/hooks/useIsMountedRef';

const text = {
  tryAgainButtonLabel: Catalog.tryAgainLabel,
  flowListBreadcrumbLabel: Catalog.flowsLabel,
  newFlowName: 'Nieuwe flow',
};

type Props = {
  flow?: FlowFieldsFragment;
  accountId: string;
};

const FlowBuilderController = ({ flow, accountId }: Props) => {
  const isMountedRef = useIsMountedRef();
  const [showEnabledFlowModal] = useEnabledFlowModal('');
  const [error, setError] = useState<ApolloError | null>(null);

  const { name } = qs.parse(global.window?.location?.search || '');
  const flowName = (Array.isArray(name) ? null : name) ?? text.newFlowName;

  return (
    <ContentContainerDefault
      breadcrumbs={[
        {
          to: '/-/automation/flows',
          label: text.flowListBreadcrumbLabel,
        },
        {
          label: flowName,
        },
      ]}
    >
      <InsertFlowMutation
        name={cleanedFilename(__filename)}
        mutation={mutation}
        onError={err => setError(err)}
      >
        {(insertFlow, { loading }) => (
          <FlowBuilderComponent
            onErrorClear={() => setError(null)}
            error={error != null}
            initialFlow={
              flow ? flowQueryToFlowProp({ ...flow, name: flowName }) : null
            }
            loading={loading}
            accountId={accountId}
            onCreate={flow =>
              insertFlow({
                variables: {
                  accountId: accountId,
                  flow,
                },
              }).then(result => {
                if (result != null && result.data != null) {
                  const { data } = result;
                  const { insertFlow } = data;

                  if (insertFlow.enabled) {
                    showEnabledFlowModal(insertFlow.id);
                    return;
                  }

                  if (isMountedRef.current) {
                    void navigate(
                      `/-/automation/flows/details/${insertFlow.id}`,
                      { replace: true },
                    );
                  }
                } else {
                  const err = new Error(
                    `${cleanedFilename(
                      __filename,
                    )} | Inserting flow did not return a valid result (${
                      result == null ? 'null' : JSON.stringify(result, null, 2)
                    }) for flow (${JSON.stringify(flow, null, 2)})`,
                  );
                  reportErrorToTracker(err);
                  throw error;
                }
              })
            }
          />
        )}
      </InsertFlowMutation>
    </ContentContainerDefault>
  );
};

export default FlowBuilderController;
