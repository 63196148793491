import React, { useContext, useState } from 'react';
import { SynchroniseEntityType } from '~/scenes/External/Nylas/util';
import {
  Mailboxes,
  AliasMailbox,
  AccountMailbox,
  OfficeMailbox,
  UserMailbox,
} from '~/graphql/types.client.flow';
import { EmailAliasStatus } from '~/graphql/types';

import { MAILBOX_TYPE } from '~/util/constants';
import { LinkButton } from '~/components/Buttons';
import SynchronisedMailbox from './SynchronisedMailbox';
import { DesyncEmailMutation } from '~/graphql';
import mutation from '~/graphql/mutation/DesyncEmail';
import { AppErrorScreen } from '~/components';
import AccountContext from '~/contexts/AccountContext';
import ChooseEmailSyncTypeModal from '~/scenes/Settings/components/ChooseEmailSyncTypeModal';
import TEST_ID from './SynchronisedMailboxes.testid';
import { reportErrorToTracker } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';
import useRunOnce from '~/components/util/useRunOnce';
import { convertServerDateStringToDate } from '~/util/date';

import useCurrentAccount from '~/hooks/useCurrentAccount';
import { Body } from '~/components/Typography/index';

const text = {
  inputLabel: 'E-mail',
  synchronizeButtonText: 'E-mailadres toevoegen',
  deleteError:
    'Er is iets fout gegaan bij het verwijderen van het gesynchroniseerde e-mail account. Probeer het later nog eens',
  info: 'Bespaar tijd door automatisch e-mail vanuit je eigen inbox te versturen. DatHuis koppelt e-mails automatisch aan de betreffende contacten.',
};

type Props = {
  /** Where this component is from */
  entityType: SynchroniseEntityType;

  /** The id of the entity (account, office, user) */
  entityId: string;

  /** Any e-mail address that should be used as placeholder */
  email?: string;

  /** If the user may edit the mailboxes */
  mayEdit: boolean;
  mailboxes: Mailboxes;

  /** When the removal is successful, this function will be called. The component expects the parent to then update the mailboxes prop */
  onSuccessfulDelete: () => void;

  /** When synchronizing account is successful, this function will be called. The component expects the parent to then refetch data */
  onSuccessfulSync: () => void;

  /** When an alias verification is started */
  onSuccessfulAliasVerificationStart: () => void;

  /** When alias verification state is verified */
  onSuccessfulAliasVerificationEnd: () => void;
};

const SynchronisedMailboxes = ({
  entityType,
  entityId,
  mailboxes,
  mayEdit,
  onSuccessfulDelete,
  onSuccessfulSync,
  onSuccessfulAliasVerificationStart,
  onSuccessfulAliasVerificationEnd,
}: Props) => {
  const { refetchSessionHydration } = useContext(AccountContext);
  const account = useCurrentAccount();
  const [runOnceOnSuccessfulAliasVerificationEnd] = useRunOnce(
    onSuccessfulAliasVerificationEnd,
  );

  const [showModal, setShowModal] = useState(false);

  const handleSuccess = () => {
    onSuccessfulSync();
    setShowModal(false);
  };

  const primaryMailboxes = mailboxes.filter(
    (mailbox): mailbox is AccountMailbox | OfficeMailbox | UserMailbox =>
      mailbox.__typename === MAILBOX_TYPE.AccountMailbox ||
      mailbox.__typename === MAILBOX_TYPE.OfficeMailbox ||
      mailbox.__typename === MAILBOX_TYPE.UserMailbox,
  );
  if (primaryMailboxes.length > 1) {
    reportErrorToTracker(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | Second primary mailbox found, we only support a single e-mail`,
    );
  }
  const primaryMailbox = primaryMailboxes[0];

  const aliasMailboxes = mailboxes.filter(
    (mailbox): mailbox is AliasMailbox =>
      mailbox.__typename === MAILBOX_TYPE.AccountMailboxAlias ||
      mailbox.__typename === MAILBOX_TYPE.OfficeMailboxAlias ||
      mailbox.__typename === MAILBOX_TYPE.UserMailboxAlias,
  );
  if (aliasMailboxes.length > 1) {
    reportErrorToTracker(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | Second alias mailbox found, we only support a single e-mail`,
    );
  }
  const aliasMailbox = aliasMailboxes[0];

  if (mayEdit && (!primaryMailbox || showModal))
    return (
      <>
        <Body>{text.info}</Body>
        <>
          <LinkButton
            onClick={() => setShowModal(true)}
            testId={TEST_ID.SYNC_NEW_EMAIL_ACCOUNT_BUTTON}
          >
            {text.synchronizeButtonText}
          </LinkButton>
          {showModal && (
            <ChooseEmailSyncTypeModal
              entityType={entityType}
              accountId={account.id}
              entityId={entityId}
              onClose={() => setShowModal(false)}
              onSuccessfulSync={handleSuccess}
            />
          )}
        </>
      </>
    );

  if (!primaryMailbox)
    return (
      <>
        <Body>{text.info}</Body>
      </>
    );

  const { email, syncState } = primaryMailbox.email;

  let aliasEmail: typeof aliasMailbox.email.emailAlias | null = null;
  let aliasSyncState: typeof aliasMailbox.email.status | null = null;
  let aliasValidatedDate: Date | null = null;
  if (aliasMailbox != null) {
    aliasEmail = aliasMailbox.email.emailAlias;
    aliasSyncState = aliasMailbox.email.status;
    aliasValidatedDate = convertServerDateStringToDate(
      aliasMailbox.email.validatedDate,
    );

    if (aliasSyncState === EmailAliasStatus.Verified) {
      runOnceOnSuccessfulAliasVerificationEnd();
    }
  }

  return (
    <>
      <Body margin={['m', null]}>{text.info}</Body>
      <DesyncEmailMutation name={__filename} mutation={mutation}>
        {(desyncEmail, { loading: desyncLoading, error }) => {
          if (error) {
            return <AppErrorScreen inline message={text.deleteError} />;
          }

          return (
            <SynchronisedMailbox
              initialEmailAlias={aliasEmail}
              onResyncRequested={() => setShowModal(true)}
              emailAliasState={aliasSyncState}
              emailAliasValidatedDate={aliasValidatedDate}
              entityType={entityType}
              entityId={entityId}
              email={email}
              syncState={syncState}
              mayEdit={mayEdit}
              onDelete={() =>
                desyncEmail({
                  variables: {
                    accountId: account.id,
                    email: email,
                  },
                }).then(() => {
                  onSuccessfulDelete();
                  return refetchSessionHydration();
                })
              }
              loading={desyncLoading}
              onSuccessfulAliasVerificationStart={
                onSuccessfulAliasVerificationStart
              }
              onSuccessfulAliasDelete={() => {
                onSuccessfulDelete();
                return refetchSessionHydration();
              }}
            />
          );
        }}
      </DesyncEmailMutation>
    </>
  );
};

export default SynchronisedMailboxes;
