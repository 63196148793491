import { clone, set, lensPath } from 'ramda';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import uuid from 'uuid/v4';
import {
  ConnectorOperator,
  ContactFiltersLeaf__Input,
  ContactFiltersContainer__Input,
} from '~/graphql/types';
import usePrevious from '~/hooks/usePrevious';

import FilterActionSelector from '~/components/Filters/components/FilterActionSelector';
import { isEmptyObject } from '~/util/object';
import TextButton from '~/components/TextButton';
import OperatorComponent from '../OperatorComponent';
import getLabelForLeafRepresentation from '../../util/contactFilter/getLabelForLeafRepresentation';
import { FilterOptions } from '~/hooks/useContactFilterOptionsV2';
import { TopicMap } from '../../util/contactFilter/getTopic';
import { OptionTypeMap } from '../../util/contactFilter/getOptionType';
import {
  CommandMap,
  TypeIdToCommandsMap,
} from '../../util/contactFilter/getCommand';
import getSubject, { SubjectMap } from '../../util/contactFilter/getSubject';
import { TypeMap } from '../../util/contactFilter/getType';
import FilterRepresentation from '~/components/FilterRepresentation';
import useViewportSize from '~/components/util/useViewportSize';
import TEST_ID from './index.testid';

export type FilterGroupV2Type = {
  connector: ConnectorOperator;
  negate: boolean;
  nodes: Array<{ Leaf?: ContactFiltersContainer__Input['Leaf'] }>;
};

const text = {
  label: 'Veld',
  addFilter: 'Filter toevoegen',
};

export type Leaf = {
  id: string;
} & Partial<ContactFiltersLeaf__Input>;

export type CurrentLeaf = {
  id: string;
  filterGroupIndex: number;
} | null;

type Props = {
  dataTestId?: string;
  filterGroupIndex: number;
  filterGroup: FilterGroupV2Type;
  onConfirm: (newFilterGroup: FilterGroupV2Type) => void;
  filterOptions: FilterOptions;
  subjectMap: SubjectMap;
  topicMap: TopicMap;
  typeMap: TypeMap;
  optionTypeMap: OptionTypeMap;
  commandMap: CommandMap;
  typeIdToCommandsMap: TypeIdToCommandsMap;
};

const FilterGroupV2 = ({
  filterGroupIndex,
  filterGroup,
  filterOptions,
  subjectMap,
  topicMap,
  typeMap,
  optionTypeMap,
  commandMap,
  typeIdToCommandsMap,
  onConfirm,
}: Props) => {
  const nodes = filterGroup.nodes;

  const initialLeafs = nodes.map(l => ({
    id: uuid(),
    ...l.Leaf,
  }));
  const [leafs, setLeafs] = useState<Array<Leaf>>(initialLeafs);

  const [currentLeaf, setCurrentLeaf] = useState<CurrentLeaf>(
    // (when adding a new filter group, this makes the first empty leaf block active)
    leafs.length === 1 &&
      leafs[0].attribute == null &&
      leafs[0].instanceAttribute == null
      ? { id: leafs[0].id, filterGroupIndex }
      : null,
  );

  const currentLeafIndex = leafs.findIndex(({ id }) => id === currentLeaf?.id);

  const [currentCommand, setCurrentCommand] = useState<{
    commandIdx: number;
  } | null>(
    currentLeafIndex != -1 &&
      leafs[currentLeafIndex].instanceAttribute?.commands.length === 1
      ? { commandIdx: 0 }
      : null,
  );

  const initialLeafsRepresentations = leafs.map(leaf => {
    const subjectId =
      leaf.attribute?.subjectId ?? leaf.instanceAttribute?.subjectId;
    const eventId = leaf.attribute?.eventId ?? leaf.instanceAttribute?.eventId;

    const subject =
      subjectId != null ? getSubject({ subjectId, eventId }, subjectMap) : null;

    const representation = getLabelForLeafRepresentation(
      {
        leaf,
        leafRepresentation: subject?.filterBarRepresentation ?? '',
      },
      { subjectMap, topicMap, commandMap, typeMap, optionTypeMap },
    );
    return representation;
  });

  const [leafsRepresentations, setLeafsRepresentations] = useState<
    Array<
      | {
          error?: undefined;
          result: { label: string; moreFilters: number | null };
        }
      | { error: string }
    >
  >(initialLeafsRepresentations);

  const compileLeafRepresentations = useCallback(
    () =>
      setLeafsRepresentations(
        leafs.map(leaf => {
          const subjectId =
            leaf.attribute?.subjectId ?? leaf.instanceAttribute?.subjectId;
          const eventId =
            leaf.attribute?.eventId ?? leaf.instanceAttribute?.eventId;

          const subject =
            subjectId != null
              ? getSubject({ subjectId, eventId }, subjectMap)
              : null;

          const representation = getLabelForLeafRepresentation(
            {
              leaf,
              leafRepresentation: subject?.filterBarRepresentation ?? '',
            },
            { subjectMap, topicMap, commandMap, typeMap, optionTypeMap },
          );
          return representation;
        }),
      ),
    [commandMap, leafs, optionTypeMap, subjectMap, topicMap, typeMap],
  );

  useEffect(() => {
    compileLeafRepresentations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectMap, topicMap, commandMap, typeMap, optionTypeMap]);

  const previousLeafsLength = usePrevious(leafs.length);

  const [groupConnector, setGroupConnector] = useState<ConnectorOperator>(
    filterGroup.connector ?? null,
  );

  const previousGroupConnector = usePrevious(groupConnector);

  const onSave = useCallback(() => {
    setCurrentCommand(null);
    setCurrentLeaf(null);

    compileLeafRepresentations();

    onConfirm({
      ...filterGroup,
      connector: groupConnector,
      nodes: leafs.reduce<
        Array<{ Leaf: ContactFiltersContainer__Input['Leaf'] }>
      >((prev, curr, idx) => {
        const clonedLeaf = clone(curr);

        if (clonedLeaf.attribute == null) delete clonedLeaf.attribute;
        if (clonedLeaf.instanceAttribute == null) {
          delete clonedLeaf.instanceAttribute;
        }

        const { id, ...rest } = clonedLeaf;

        if (isEmptyObject(rest)) {
          setLeafs(prev => {
            const clonedPrev = clone(prev);
            clonedPrev.splice(idx, 1);
            return clonedPrev;
          });

          return prev;
        }

        // Remove the empty commands from the leaf
        if (
          'instanceAttribute' in clonedLeaf &&
          clonedLeaf.instanceAttribute?.commands != null
        ) {
          clonedLeaf.instanceAttribute.commands =
            clonedLeaf.instanceAttribute?.commands.filter(
              command => !isEmptyObject(command),
            );

          // If no commands left, remove this leaf.
          if (clonedLeaf.instanceAttribute.commands.length === 0) {
            setLeafs(prev => {
              const clonedPrev = clone(prev);
              clonedPrev.splice(idx, 1);
              return clonedPrev;
            });

            return prev;
          }

          setLeafs(prev => set(lensPath([idx]), { ...clonedLeaf, id }, prev));
        }

        prev.push({ Leaf: rest });
        return prev;
      }, []),
    });
  }, [
    filterGroup,
    groupConnector,
    leafs,

    onConfirm,
    compileLeafRepresentations,
  ]);

  // Save on leaf deletion & connector updates
  useEffect(() => {
    if (
      previousLeafsLength !== leafs.length + 1 &&
      groupConnector === previousGroupConnector
    ) {
      return;
    }
    onSave();
  }, [
    previousLeafsLength,
    groupConnector,
    previousGroupConnector,
    leafs.length,
    onSave,
  ]);

  const ref = useRef<HTMLDivElement>(null);
  const [viewportSize] = useViewportSize();
  const [maxWidth, setMaxWidth] = useState(1);
  useEffect(() => {
    if (ref == null || ref.current == null) return;
    setMaxWidth(ref.current.offsetWidth * 0.75);
  }, [ref, viewportSize]);

  const buttonRefs = useRef<{ [key: string]: null | HTMLButtonElement }>({});

  return (
    <Container ref={ref}>
      {leafs.map((leaf, leafIndex) => {
        const isLeafSelected =
          currentLeaf?.id === leaf.id &&
          currentLeaf?.filterGroupIndex === filterGroupIndex;

        const key = `${leaf.id}#${leafIndex}`;

        const representation = leafsRepresentations[leafIndex] ?? {
          result: { label: '...' },
        };

        return (
          <React.Fragment key={key}>
            <LeafContainer>
              <FilterRepresentation
                maxWidth={`${maxWidth}px`}
                active={isLeafSelected}
                error={representation.error ?? null}
                moreFilters={
                  representation.error == null
                    ? representation.result.moreFilters
                    : null
                }
                label={
                  representation.error == null
                    ? representation.result.label
                    : undefined
                }
                ref={element => {
                  buttonRefs.current[key] = element as HTMLButtonElement | null;
                }}
                onClick={e => {
                  e.stopPropagation();
                  setCurrentLeaf({
                    id: leaf.id,
                    filterGroupIndex,
                  });
                }}
                onRemove={() => {
                  setCurrentLeaf(null);
                  setCurrentCommand(null);
                  setLeafs(prev => {
                    const clonedPrev = clone(prev);
                    clonedPrev.splice(leafIndex, 1);

                    return clonedPrev;
                  });
                }}
                dataTestId={TEST_ID.FILTER_REPRESENTATION_CONTAINER}
              />

              {isLeafSelected && (
                <FilterActionSelector
                  representationButtonElement={buttonRefs.current[key]}
                  filterGroupIndex={filterGroupIndex}
                  currentCommand={currentCommand}
                  setCurrentCommand={setCurrentCommand}
                  onSave={onSave}
                  filterOptions={filterOptions}
                  subjectMap={subjectMap}
                  commandMap={commandMap}
                  typeMap={typeMap}
                  optionTypeMap={optionTypeMap}
                  topicMap={topicMap}
                  typeIdToCommandsMap={typeIdToCommandsMap}
                  setLeafs={setLeafs}
                  setCurrentLeaf={setCurrentLeaf}
                  leafs={leafs}
                  currentLeafIndex={currentLeafIndex}
                />
              )}
            </LeafContainer>
            <LeafContainer>
              <OperatorComponent
                blockIndex={leafIndex}
                blockCount={leafs.length}
                connector={groupConnector}
                filterGroupIndex={filterGroupIndex}
                onChange={setGroupConnector}
              />
            </LeafContainer>
          </React.Fragment>
        );
      })}
      <AddFilterButtonContainer>
        <TextButton
          label={text.addFilter}
          icon="plus"
          onClick={e => {
            e.currentTarget.blur();
            const newLeafId = uuid();
            setLeafs(prev => [
              ...prev,
              {
                id: newLeafId,
              },
            ]);
            setCurrentLeaf({
              id: newLeafId,
              filterGroupIndex,
            });
          }}
          dataTestId={TEST_ID.ADD_FILTER_BUTTON}
        />
      </AddFilterButtonContainer>
    </Container>
  );
};

const LeafContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: ${theme.space('xxs')};
    margin-right: ${theme.space('xxs')};
    font-size: ${theme.fs('s')};

    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

const AddFilterButtonContainer = styled(LeafContainer)<{}>(
  ({ theme }) => css`
    padding-bottom: ${theme.space('xxxs')};
    border: none !important;
  `,
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};

    max-width: ${1200}px;
    width: 100%;
    margin: 0 auto;

    padding-left: ${theme.space('xxs')};
    padding-top: ${theme.space('xxs')};
  `,
);

export default FilterGroupV2;
