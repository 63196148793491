import type { AppStatus__Input } from '~/graphql/types';
import type { ExtendedAppConfig } from '~/hooks/useApps';
import getWidgetAppConfigurationUpdate from '~/util/getWidgetAppConfigurationUpdate';

const getAppStatusUpdate = (app: ExtendedAppConfig): AppStatus__Input => {
  if (app.__typename === 'AppStatus_Trustoo') {
    return {
      [app.__typename]: {
        ...getWidgetAppConfigurationUpdate(app.appStatus),
        tokens: [],
        enabled: !app.appStatus.enabled,
        metadata: {
          setup: {
            addedFlows: [],
            addedTokens: [],
            completed: false,
          },
        },
      },
    };
  }

  if (app.__typename === 'AppStatus_Realworks') {
    return {
      [app.__typename]: {
        enabled: !app.appStatus.enabled,
        usageAgreement: true,
        metadata: {
          setup: {
            addedFlows: [],
            addedTokens: [],
            completed: false,
          },
        },
      },
    };
  }

  return {
    [app.__typename]: {
      ...getWidgetAppConfigurationUpdate(app.appStatus),
      enabled: !app.appStatus.enabled,
      metadata: {
        setup: {
          addedFlows: [],
          completed: false,
        },
      },
    },
  };
};

export default getAppStatusUpdate;
