import React from 'react';
import styled, { css } from 'styled-components';
import extractLabelFromStr from '~/scenes/Automation/v2/util/extractLabelFromStr';

export type Props = {
  dataTestId?: string;

  /**
   * A string that might include both label + text or just text
   * E.g. [[A1]] Some text
   */
  str: string | null;

  /**
   * Makes the label bold
   */
  highlightLabel?: boolean;
};

const ActionLabelAsText: React.FC<Props> = ({
  str,
  highlightLabel = false,
}) => {
  const res = extractLabelFromStr(str);

  if (!res?.label) return <>{res?.text}</>;

  if (highlightLabel)
    return (
      <>
        <LabelContainer>{res?.label}</LabelContainer> - {res?.text}
      </>
    );

  return (
    <>
      {res?.label} - {res?.text}
    </>
  );
};

const LabelContainer = styled.span<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
  `,
);

export default ActionLabelAsText;
