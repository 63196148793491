import { useCallback } from 'react';

type BaseEvent = {
  event: string;
};
type LoginEvent = BaseEvent & {
  loginMethod: string;
};

type RegisterEvent = BaseEvent & {
  message: string;
};

type ScreenSizeEvent = {
  event: 'screenSize';
  size: string;
};

export type TrackingEvent =
  | BaseEvent
  | LoginEvent
  | RegisterEvent
  | ScreenSizeEvent;

// We export the function separately so we can call it from class based components
// But sadly these events won't be enriched with user or account context
export const fireTrackingEvent = (event: TrackingEvent) => {
  global.window.dataLayer = global.window.dataLayer || [];
  global.window.dataLayer.push(event);
};

const useFireTrackingEvent = () => {
  // Any context from hooks can be provided here
  const fireEvent = useCallback((event: TrackingEvent) => {
    fireTrackingEvent(event);
  }, []);

  return fireEvent;
};

export default useFireTrackingEvent;
