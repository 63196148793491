import React, { useState } from 'react';
import TEST_ID from './index.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { useGetRevenuePerSourceGroupQuery } from '~/graphql/types';
import CardHeader from '../CardHeader';
import { Loading } from '~/components';
import { Body, Heading5 } from '~/components/Typography/index';
import DonutChart from '~/components/Charts/DonutChart';
import useToday from '~/hooks/useToday';
import getDateRangeOptionsWithEndDate from '../../utils/getDateRangeOptionsWithEndDate';
import withMasonryCard from '../withMasonryCard';

export type Props = {
  dataTestId?: string;
  today?: Date;
};

const text = {
  noRevenue: 'Geen opbrengst',
  noRevenueDescription: 'Je hebt nog geen opbrengst van apps.',
  heading: 'Opbrengst per app',
  errorHeader: 'Uh-oh 😱',
  errorBody:
    'Het lijkt er op dat er iets mis is gegaan bij het ophalen van jouw opbrengst. Probeer het later opnieuw.',
  donutTotalLabel: 'totale omzet',
};

const RevenuePerSourceGroup: React.FC<Props> = ({ today: originalToday }) => {
  const today = useToday(originalToday);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
  const options = getDateRangeOptionsWithEndDate(today);
  const dateRange = options[selectedOptionIndex].payload;

  const { id: accountId } = useCurrentAccount();

  const { data, loading, error } = useGetRevenuePerSourceGroupQuery({
    variables: {
      accountId,
      ...dateRange,
    },
  });

  const FilledCardHeader = () => (
    <CardHeader
      headingText={text.heading}
      onOptionChange={({ selectedOptionIdx }) =>
        setSelectedOptionIndex(selectedOptionIdx)
      }
      options={options}
      selectedOptionIndex={selectedOptionIndex}
      dataTestId={TEST_ID.CARD_HEADER}
    />
  );

  if (loading) {
    return (
      <>
        <FilledCardHeader />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      // <MasonryCard dataTestId={`${dataTestId}-${TEST_ID.CONTAINER}`} {...rest}>
      <>
        <FilledCardHeader />
        <Heading5>{text.errorHeader}</Heading5>
        <Body>{text.errorBody}</Body>
      </>
    );
  }

  if (data && data.getRevenuePerSourceGroup.length <= 0) {
    return (
      <>
        <FilledCardHeader />
        <Heading5 dataTestId={TEST_ID.NO_REVENUE}>{text.noRevenue}</Heading5>
        <Body>{text.noRevenueDescription}</Body>
      </>
    );
  }

  return (
    <>
      <FilledCardHeader />
      <DonutChart
        label={text.donutTotalLabel}
        dataTestId={TEST_ID.DONUT_CHART}
        data={(data?.getRevenuePerSourceGroup ?? []).map(
          ({ name, amount }) => ({
            id: `${name}`,
            name,
            value: (amount ?? 0) / 100,
          }),
        )}
        formatOptions={{
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }}
      />
    </>
  );
};

export default withMasonryCard(RevenuePerSourceGroup, {
  containerTestId: TEST_ID.CONTAINER,
});
