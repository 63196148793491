import { pluck } from 'ramda';
import { ClientFlowAction } from '~/graphql/types.client';
import { reporter } from '~/hooks/useErrorReporter';
import getChildActions from '~/scenes/Automation/v2/components/Builder/utils/getChildActions';

/**
 * Delete IfElse action and reassign all the ids
 * @param {Array<ClientFlowAction>} actions - Actions
 * @param {ClientFlowAction} subjectAction - Action to delete
 * keywords: delete if else action
 */
const deleteIfElseAction = ({
  actions,
  subjectAction,
}: {
  actions: Array<ClientFlowAction>;
  subjectAction: ClientFlowAction;
}): {
  actions: Array<ClientFlowAction>;
  deletedActionId: string | null;
  childActionsToDelete: Array<string>;
} => {
  if (subjectAction.__typename !== 'FlowV2_Action_IfElse')
    return { actions, deletedActionId: null, childActionsToDelete: [] };

  let parentId: string | null = null;
  const childActionIdsToDelete: Array<string> = [];

  const mutableActions = [...actions];

  for (const id of subjectAction.parentIds) {
    const parentIdx = mutableActions.findIndex(a => a.id === id);
    const parentAction = mutableActions[parentIdx];

    // Which child is being deleted from the parent IfElse action
    const ifElseKey =
      parentAction.__typename === 'FlowV2_Action_IfElse' &&
      parentAction.trueChildId === subjectAction.id
        ? 'trueChildId'
        : 'falseChildId';

    const updatedParentAction: ClientFlowAction =
      parentAction.__typename === 'FlowV2_Action_IfElse'
        ? {
            ...parentAction,
            [ifElseKey]: null,
          }
        : parentAction;

    parentId = updatedParentAction.id;
    mutableActions[parentIdx] = updatedParentAction;
  }

  const childActions = getChildActions(subjectAction, actions);
  const allChildIds = pluck('id', childActions);

  childActions.forEach(a => {
    if (a.__typename === 'FlowV2_Action_Start') return;

    const secondaryParents = a.parentIds.filter(
      parentId =>
        !allChildIds.includes(parentId) && parentId !== subjectAction.id,
    );

    if (secondaryParents.length === 0) childActionIdsToDelete.push(a.id);
  });

  const actionsToUpdate = childActions
    .reduce((prev, a) => {
      if (
        a.__typename === 'FlowV2_Action_Start' ||
        childActionIdsToDelete.includes(a.id)
      )
        return prev;

      prev.push({
        ...a,
        parentIds: a.parentIds?.filter(
          parentId =>
            !childActionIdsToDelete.includes(parentId) &&
            parentId !== subjectAction.id,
        ),
      });

      return prev;
    }, [] as Array<ClientFlowAction>)
    .map(a => {
      if (a.__typename === 'FlowV2_Action_IfElse') {
        const removeTrueChild =
          a.trueChildId && childActionIdsToDelete.includes(a.trueChildId);
        const removeFalseChild =
          a.falseChildId && childActionIdsToDelete.includes(a.falseChildId);

        return {
          ...a,
          trueChildId: removeTrueChild ? null : a.trueChildId,
          falseChildId: removeFalseChild ? null : a.falseChildId,
        };
      } else return a;
    });

  const updatedChildrenAmount = actionsToUpdate.length;
  const deletedChildrenAmount = childActionIdsToDelete.length;
  const allChildrenAmount = allChildIds.length;

  if (updatedChildrenAmount + deletedChildrenAmount !== allChildrenAmount) {
    const err = new Error(
      `Something went wrong while deleting IfElse action ${subjectAction.id} 
        Updated children amount: '${updatedChildrenAmount}' |
        Deleted children amount: '${deletedChildrenAmount}'|
        Total children amount: '${allChildrenAmount}' |
        Actions: ${JSON.stringify(actions, null, 2)}
      `,
    );
    reporter.captureException(err, 'critical');

    throw err;
  }

  const nextActions = mutableActions
    .filter(({ id }) => id !== subjectAction.id && !allChildIds.includes(id))
    .concat(actionsToUpdate);

  return {
    actions: nextActions,
    deletedActionId: parentId,
    childActionsToDelete: childActionIdsToDelete,
  };
};

export default deleteIfElseAction;
