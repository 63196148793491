import { keys } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import OverflowScrollWrapper from '~/components/OverflowScrollWrapper';
import useCurrentBreakpoint from '~/hooks/useCurrentBreakpoint';
import TEST_ID from './index.testid';

import KenmerkGroup from '../KenmerkGroup';

export type Kenmerk = {
  id: number;
  description: string;
  tag: string | null;
  group: string;
  checked: boolean;
};

export type KenmerkLookup = {
  [groupName: string]: Array<Kenmerk>;
};

export type Props = {
  dataTestId?: string;
  kenmerkLookup: KenmerkLookup;
  selectable?: boolean;
  onChange: (args: { groupName: string; kenmerkList: Array<Kenmerk> }) => void;
};

const RealworksSelectableAccordion: React.FC<Props> = ({
  dataTestId,
  kenmerkLookup,
  onChange,
  selectable = true,
  ...rest
}) => {
  const groupNames = keys(kenmerkLookup);
  const currentBp = useCurrentBreakpoint();

  return (
    <Container data-testid={dataTestId} {...rest}>
      <OverflowScrollWrapper
        style={{ maxHeight: currentBp === 'mobile' ? '100vh' : '50vh' }}
      >
        {groupNames.map((name: string) => (
          <KenmerkGroup
            key={`key-${name}`}
            kenmerken={kenmerkLookup[name]}
            name={name}
            onChange={({ kenmerkList }) =>
              onChange({ groupName: name, kenmerkList })
            }
            dataTestId={TEST_ID.KENMERK_GROUP}
            selectable={selectable}
          />
        ))}
      </OverflowScrollWrapper>
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('l')};

    ${theme.mq.lessThan('mobile')`
      margin: ${theme.space('l')} 0;
    `}
  `,
);

export default RealworksSelectableAccordion;
