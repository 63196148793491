import React from 'react';
import styled, { css } from 'styled-components';
import TEST_ID from './index.testid';
import { Body, Heading4, Variant } from '~/components/Typography/index';
import JustificationContainer from '~/components/JustificationContainer';
import { navigate } from 'gatsby';
import StatusIndicator, { Status } from '~/components/StatusIndicator';
import { AppStatusLockedReason } from '~/graphql/types';
import getPriceWithInterval from '~/util/getPriceWithInterval';
import ProgressiveImgIx from '~/components/ProgressiveImgIx';
import type { ExtendedAppConfig } from '~/hooks/useApps';
import getAppStatusIndicator, {
  AppStatusIndicator,
} from '../AppsList/components/AppCards/utils/getAppStatusIndicator';

const text = {
  comingSoon: 'Komt binnenkort',

  statusMessages: {
    enabled: 'Actief',
    incomplete: 'Installatie niet afgerond',
    disabled: 'Inactief',
    expired: 'Autoriseer',
    Disabling: 'Deinstalleren..',
  },
};

export type Props = {
  /**
   * A badge to be rendered top right
   */
  badge?: string;
  /**
   * General app information
   */
  app: ExtendedAppConfig;
  /**
   * True if the user can interact with this card.
   *
   * This can be used if the user has e.g. no rights
   * to change anything.
   */
  disabled?: boolean;

  /**
   * Object ID to target from Cypress
   */
  'data-objectid'?: string;
};

const AppCard: React.FC<Props> = React.memo(
  ({ badge, disabled = false, app, ...rest }) => {
    const statusIndicator = getAppStatusIndicator(app.appStatus);

    return (
      <Container
        disabled={disabled}
        onClick={() => navigate(`/-/apps/setup/${app.slug}`)}
        data-testid={TEST_ID.CONTAINER}
        {...rest}
      >
        {badge && <Badge>{badge}</Badge>}

        <Inner padding={['l']}>
          <BrandingContainer
            align="center"
            justification="center"
            margin={[null, 'm', null, null]}
          >
            <IllustrationContainer align="center" justification="center">
              <ProgressiveImgIx width={60} src={app.image.src} />
            </IllustrationContainer>
          </BrandingContainer>

          <Header disabled={disabled}>
            <JustificationContainer
              justification="space-between"
              align="center"
            >
              <Heading4 withoutMargin variant={Variant.primary}>
                {app.name}
              </Heading4>
            </JustificationContainer>
            <InfoContainer withoutMargin>{app.provider}</InfoContainer>
            <InfoContainer withoutMargin>
              {getPriceWithInterval(app.price)}
            </InfoContainer>
          </Header>
        </Inner>

        <Footer
          justification="space-between"
          align="center"
          data-testid={TEST_ID.STATUS_CONTAINER}
          padding={['base', 's', 'base', 'l']}
        >
          <HorizontalJustificationContainer
            justification="start"
            align="center"
          >
            <Body fontWeight="semiBold" margin={[null, 'base', null, null]}>
              Status:
            </Body>
            <HorizontalJustificationContainer
              justification="start"
              align="center"
            >
              <StyledStatusIndicator status={getStatus(statusIndicator)} />
              <span>{text.statusMessages[statusIndicator]}</span>
            </HorizontalJustificationContainer>
          </HorizontalJustificationContainer>
        </Footer>
      </Container>
    );
  },
);
const getStatus = (status: AppStatusIndicator): Status => {
  switch (status) {
    case 'enabled':
      return 'active';
    case 'expired':
      return 'error';
    case 'incomplete':
      return 'warning';
    case 'disabled':
    case AppStatusLockedReason.Disabling:
    default:
      return 'inactive';
  }
};

const StyledStatusIndicator = styled(StatusIndicator)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('xxs')};
  `,
);

type HeaderProps = {
  disabled: boolean;
};
const Header = styled.div<HeaderProps>`
  width: 100%;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        opacity: 0.6;
      `;
    }

    return '';
  }}
`;

type ContainerProps = {
  disabled: boolean;
};
const Container = styled.div<ContainerProps>(
  ({ disabled, theme }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    opacity: ${disabled ? '.3' : '1'};
    display: flex;
    flex-direction: column;

    position: relative;
    min-width: 300px;
    width: 100%;
    transition-property: transform, box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};
    box-shadow: ${theme.getTokens().boxShadow.card};
    user-select: none;
    overflow: hidden;
    height: 100%;

    :hover {
      transform: translateY(-5px);
      box-shadow: ${theme.getTokens().boxShadow.cardHover};
    }
  `,
);

const Inner = styled(JustificationContainer)<{}>`
  width: 100%;
  height: 100%;
`;

const BrandingContainer = styled(JustificationContainer)<{}>`
  position: relative;
`;

const ILLUSTRATION_SIZE = 60;
const IllustrationContainer = styled(JustificationContainer)<{}>`
  width: ${ILLUSTRATION_SIZE}px;
  height: ${ILLUSTRATION_SIZE}px;
`;

const Badge = styled.div<{}>`
  right: 0;
  position: absolute;

  ${({ theme }) => css`
    top: ${theme.space('xxs')};
    color: ${theme.color('white')};
    background-color: ${theme.color('success')};
    padding: ${theme.space('xxs')} ${theme.space('s')};
  `};
`;

const InfoContainer = styled(Body)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('base')};
    color: ${theme.color('grey')};
  `,
);

const HorizontalJustificationContainer = styled(JustificationContainer)<{}>`
  ${({ theme }) => css`
    ${theme.mq.lessThan('mobile')`
      flex-direction: row;
    `}
  `}
`;

const Footer = styled(HorizontalJustificationContainer)<{}>`
  background-color: ${({ theme }) => theme.color('tertiary', 'light')};
  width: 100%;
`;

export default AppCard;
