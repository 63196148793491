import { FlowParameterNumberFieldsFragment } from '~/graphql/types';
import { NumberPrimitiveParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyNumberPrimitiveParameterValue from './emptyNumberPrimitiveParameterValue';

export default (
  numberParameter: FlowParameterNumberFieldsFragment | null | undefined,
): NumberPrimitiveParameterValue => {
  if (numberParameter == null) {
    return emptyNumberPrimitiveParameterValue();
  }

  if (numberParameter.__typename !== PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE) {
    throw new Error(
      'Should not occur: Trying to convert a pointer number variable to a primitive!',
    );
  }

  return {
    type: PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE,
    value: numberParameter.numberValue ? numberParameter.numberValue : null,
  };
};
