import React, { useContext } from 'react';

import { ConditionField } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive/types.flow';

import describeOperation from './describeOperation';
import cleanedFilename from '~/util/cleanedFilename';
import { describeParameterValue } from '../../FlowParameter';
import BaseActionContext from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import TagField from '~/components/Inputs/TagListInputs/components/TagField';

type Props = {
  field: ConditionField;
  onDelete: () => void;
};
const FieldTag = ({ field, onDelete, ...rest }: Props) => {
  const { operation } = field;
  const { describeOptions } = useContext(BaseActionContext);

  if (operation == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | operation == null (${JSON.stringify(
        field,
        null,
        2,
      )})`,
    );
  }

  return (
    <TagField
      {...rest}
      isValid={true}
      useValidation={false}
      onDelete={onDelete}
      value={'-'}
      renderValue={
        <div>
          {describeOperation(operation, { dropdownLabel: true })}{' '}
          {describeParameterValue(operation.value, describeOptions, true)}
        </div>
      }
    />
  );
};

export default FieldTag;
