import React, { Component } from 'react';
import styled from 'styled-components';

import { UserRole } from '~/graphql/types';
import { User } from '../Account.flow';

import { Drawer } from '~/components';
import Button from '~/components/Button';
import { InputGroup } from '~/components/Inputs';
import Dropdown from '~/components/Dropdown';

type SelectedUserType = {
  selectedOptionIdx: number;
  id: string;
  label: string;
};

type DropdownHandler = {
  selectedOptionIdx: number;
  option: User;
};

type Props = {
  updateUserRole: (arg0: { role: UserRole; userId: string }) => void;
  handleToggleDrawer: (show: boolean) => void;
  usersList: Array<User>;
  isOpen: boolean;
  loading: boolean;
};

type State = {
  dataLoaded: boolean;
  error: string | null | undefined;
  selectedUser: SelectedUserType | null | undefined;
};

export const text = {
  title: 'Administrator toevoegen',
  descriptionP: 'Voeg een gebruiker toe als administrator voor dit account.',
  buttonTitle: 'Toevoegen',
  dropdown: 'Selecteer een gebruiker',
};

// TODO: Refactor this component when useUserOptions is a thing...
// https://app.clubhouse.io/dathuis/story/4869/create-useuseroptions-and-clean-up-getuseroptions
class AccountDrawer extends Component<Props, State> {
  state: State = {
    dataLoaded: false,
    selectedUser: null,
    error: null,
  };

  handleChangeOption = ({ selectedOptionIdx, option }: DropdownHandler) => {
    this.setState({
      selectedUser: {
        selectedOptionIdx,
        id: option.payload.id,
        label: option.label,
      },
      error: null,
    });
  };

  saveAdmin = () => {
    const { selectedUser } = this.state;
    if (selectedUser) {
      this.props.updateUserRole({
        role: UserRole.Admin,
        userId: selectedUser.id,
      });
      this.handleCloseDrawer();
    } else {
      this.setState({
        error: text.dropdown,
      });
    }
  };

  handleCloseDrawer = () => {
    this.setState({
      selectedUser: null,
      error: null,
    });

    this.props.handleToggleDrawer(false);
  };

  render() {
    const { selectedUser, error } = this.state;
    const { usersList, isOpen, loading } = this.props;

    const selectedOptionIdx: number | null | undefined = selectedUser
      ? selectedUser.selectedOptionIdx
      : null;

    return (
      <Drawer
        active={isOpen}
        hideSidebar={this.handleCloseDrawer}
        title={text.title}
      >
        <p>{text.descriptionP}</p>
        <InputGroupCented>
          <Dropdown
            dataTestId="admins-dropdown"
            selectedOptionIdx={selectedOptionIdx}
            options={usersList}
            disabled={usersList.length === 0}
            onChange={this.handleChangeOption}
            error={error || null}
          />
        </InputGroupCented>
        <Button
          size="medium"
          loading={loading}
          disabled={loading}
          data-testid="save-admin-button"
          onClick={this.saveAdmin}
          label={text.buttonTitle}
        />
      </Drawer>
    );
  }
}
const InputGroupCented = styled(InputGroup)<{}>`
  align-items: center;
`;

export default AccountDrawer;
