import React from 'react';
import styled, { css } from 'styled-components';

import {
  BillingDetailsFieldsFragment,
  BillingDetails_New,
} from '~/graphql/types';

import { Drawer, FormUtils } from '~/components';
import { InputGroup, Input } from '~/components/Inputs';
import { UpdateBillingDetailsMutation } from '~/graphql';
import mutation from '~/graphql/mutation/UpdateBillingDetails';
import { Field, EmailField, PostalCodeField } from '~/components/Forms';
import { Form } from 'react-final-form';
import Catalog from '~/Catalog';
import Button from '~/components/Button';
import Validation from '~/util/Validation';
import cleanedFilename from '~/util/cleanedFilename';
import useCurrentAccount from '~/hooks/useCurrentAccount';

import TEST_ID from './index.testid';

const text = {
  drawerTitle: 'Wijzig facturatiegegevens',
  nameLabel: 'Naam rekeninghouder',
  noName: Catalog.requiredField,
  emailLabel: 'Facturatie e-mailadres',
  noEmail: Catalog.requiredField,
  invalidEmail: Catalog.invalidEmail,
  line1Label: 'Adresregel 1',
  line2Label: 'Adresregel 2',
  postcodeLabel: Catalog.postalCodeLabel,
  invalidPostcode: Catalog.invalidPostalCode,
  cityLabel: Catalog.cityLabel,
  submitLabel: 'Opslaan',
  updateBillingDetailsErrorMessage: Catalog.genericUnknownErrorMessageShort,
};
type FormData = {
  name: string | null | undefined;
  email: string | null | undefined;
  addressLine1: string | null | undefined;
  addressLine2: string | null | undefined;
  addressPostcode: string | null | undefined;
  addressCity: string | null | undefined;
};
type Props = {
  show: boolean;
  onClose: (hasUpdated?: boolean) => void;
  billingDetails: BillingDetailsFieldsFragment | null;
};
const EditBillingDetailsSidebar = ({
  show,
  onClose,
  billingDetails,
}: Props) => {
  const account = useCurrentAccount();

  return (
    <Drawer active={show} hideSidebar={onClose} title={text.drawerTitle}>
      <UpdateBillingDetailsMutation
        name={__filename}
        mutation={mutation}
        onCompleted={() => onClose(true)}
      >
        {(updateBilling, { loading, error }) => {
          const initialValues = convertToFormData(billingDetails);

          return (
            <Container data-testid={TEST_ID.CONTAINER}>
              {error && (
                <ErrorMsg>{text.updateBillingDetailsErrorMessage}</ErrorMsg>
              )}
              <Form
                onSubmit={fields => {
                  const newBillingDetails = convertToBillingDetails(fields);

                  void updateBilling({
                    variables: {
                      accountId: account.id,
                      billingDetails: newBillingDetails,
                    },
                  });
                }}
                initialValues={initialValues}
                validate={validate}
              >
                {form => {
                  const { handleSubmit, submitting, pristine } = form;

                  return (
                    <form
                      onSubmit={handleSubmit}
                      data-testid="add-contact-form"
                    >
                      <InputGroup>
                        <Field name="name">
                          {({ input, meta: { error, touched } }) => (
                            <Input
                              label={text.nameLabel}
                              type="text"
                              error={FormUtils.showError(error, touched)}
                              {...input}
                              disabled={loading}
                            />
                          )}
                        </Field>
                      </InputGroup>
                      <InputGroup>
                        <EmailField name="email">
                          {({ input, meta: { error, touched } }) => (
                            <Input
                              label={text.emailLabel}
                              type="email"
                              error={FormUtils.showError(error, touched)}
                              {...input}
                              disabled={loading}
                            />
                          )}
                        </EmailField>
                      </InputGroup>
                      <InputGroup>
                        <Field name="addressLine1">
                          {({ input, meta: { error, touched } }) => (
                            <Input
                              label={text.line1Label}
                              type="text"
                              error={FormUtils.showError(error, touched)}
                              {...input}
                              disabled={loading}
                            />
                          )}
                        </Field>
                      </InputGroup>
                      <InputGroup>
                        <Field name="addressLine2">
                          {({ input, meta: { error, touched } }) => (
                            <Input
                              label={text.line2Label}
                              type="text"
                              error={FormUtils.showError(error, touched)}
                              {...input}
                              disabled={loading}
                            />
                          )}
                        </Field>
                      </InputGroup>
                      <InputGroup>
                        <PostalCodeField name="addressPostcode">
                          {({ input, meta: { error, touched } }) => (
                            <Input
                              label={text.postcodeLabel}
                              type="text"
                              error={FormUtils.showError(error, touched)}
                              {...input}
                              disabled={loading}
                            />
                          )}
                        </PostalCodeField>
                        <Field name="addressCity">
                          {({ input, meta: { error, touched } }) => (
                            <Input
                              label={text.cityLabel}
                              type="text"
                              error={FormUtils.showError(error, touched)}
                              {...input}
                              disabled={loading}
                            />
                          )}
                        </Field>
                      </InputGroup>
                      <InputGroup>
                        <StyledButton
                          size="medium"
                          onClick={handleSubmit}
                          data-testid={TEST_ID.SUBMIT_BUTTON}
                          type="submit"
                          disabled={submitting || pristine || loading}
                          label={text.submitLabel}
                        />
                      </InputGroup>
                    </form>
                  );
                }}
              </Form>
            </Container>
          );
        }}
      </UpdateBillingDetailsMutation>
    </Drawer>
  );
};

const validate = (fields: FormData) => {
  const errors: FormData = {
    name: undefined,
    email: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    addressPostcode: undefined,
    addressCity: undefined,
  };

  if (Validation.String.isEmpty(fields.name)) {
    errors.name = text.noName;
  }

  if (Validation.String.isEmpty(fields.email)) {
    errors.email = text.noEmail;
  } else {
    if (!Validation.Email.isValid(fields.email)) {
      errors.email = text.invalidEmail;
    }
  }

  return errors;
};

const convertToFormData = (
  billingDetails: BillingDetailsFieldsFragment | null,
): FormData => {
  const { name, email, address } = billingDetails || {};

  let addressFields: {
    addressLine1: string | null;
    addressLine2: string | null;
    addressPostcode: string | null;
    addressCity: string | null;
  } = {
    addressLine1: null,
    addressLine2: null,
    addressPostcode: null,
    addressCity: null,
  };
  if (address != null) {
    const { line1, line2, postcode, city } = address;
    addressFields = {
      addressLine1: line1 ?? null,
      addressLine2: line2 ?? null,
      addressPostcode: postcode ?? null,
      addressCity: city ?? null,
    };
  }

  return {
    name: name || null,
    email: email || null,
    ...addressFields,
  };
};

const convertToBillingDetails = (formData: FormData): BillingDetails_New => {
  const {
    name,
    email,
    addressLine1,
    addressLine2,
    addressPostcode,
    addressCity,
  } = formData;

  if (name == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Validation should have caught that name == null`,
    );
  }
  if (email == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Validation should have caught that email == null`,
    );
  }

  return {
    name,
    email,
    address: {
      line1: addressLine1,
      line2: addressLine2,
      postcode: addressPostcode,
      city: addressCity,
    },
  };
};

const StyledButton = styled(Button)<{}>`
  margin-left: auto;
`;

const ErrorMsg = styled.div<{}>`
  ${({ theme }) => css`
    color: ${theme.color('danger')};
  `};
`;

const Container = styled.div<{}>``;

export default EditBillingDetailsSidebar;
