import React from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import DHRouter from '~/components/DHRouter';
import { withOverlay } from '~/components/ModalsV2/Overlay';
import StepList from '~/components/StepList';
import WizardLayout from '~/components/WizardLayout';
import ActivationPendingStep from './components/ActivationPendingStep';
import SetupStep from './components/SetupStep';
import ImportContactsStep from './components/ImportContactsStep';
import ExportContactsStep from './components/ExportContactsStep';
import pathnameToStep from '~/util/pathnameToStep';

export type Props = {
  /**  Provided by withOverlay HOC */
  onClose?: () => void;
  refreshAppStatus: () => void;
} & RouteComponentProps;

export const LINK_PREFIX = '/-/apps/realworks/wizard';

const AddTokenContainerWizard: React.FC<Props> = ({
  onClose,
  refreshAppStatus,
}) => {
  const location = useLocation();

  const steps = [
    {
      id: 'setup',
      label: 'Koppeling instellen',
      disabled: false,
    },
    {
      id: 'import-contacts',
      label: 'Contacten importeren',
      disabled: false,
    },
    {
      id: 'export-contacts',
      label: 'Contacten exporteren',
      disabled: false,
    },
    {
      id: 'activation-pending',
      label: 'Activatie verwerken',
      disabled: false,
    },
  ];

  return (
    <WizardLayout header="Realworks relaties koppelen">
      <StepList
        currentStep={pathnameToStep(location.pathname, steps, 'setup')}
        steps={steps}
      />
      <DHRouter>
        <SetupStep path={'/'} onCancel={onClose} disableClosing />
        <ImportContactsStep
          path={'/import-contacts'}
          onCancel={onClose}
          disableClosing
        />
        <ExportContactsStep
          path={'/export-contacts'}
          onCancel={onClose}
          disableClosing
        />
        <ActivationPendingStep
          path="/activation-pending"
          onCancel={onClose}
          refreshAppStatus={refreshAppStatus}
          disableClosing
        />
      </DHRouter>
    </WizardLayout>
  );
};

export type StepProps = {
  onCancel?: () => void;
  disableClosing: boolean;
} & RouteComponentProps;

export default withOverlay<Props>(AddTokenContainerWizard);
