import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import ModalContext from '~/contexts/ModalContext';
import type { DoContactAction_AddTags__Input } from '~/graphql/types';
import TagList from '~/components/Inputs/TagListInputs/TagList';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import BasePopover from '../../components/BasePopover';
import { ActionModalProps } from '../../actionSwitcher';

export type Props = {
  dataTestId?: string;
} & ActionModalProps;

const text = {
  title: 'Tag toevoegen',
  confirm: 'Bevestigen',
  label: 'Nieuwe tag toevoegen',
};

const AddTags: React.FC<Props> = ({
  dataTestId,
  lockContactsVariables,
  selectedContactAmount,
  onComplete,
  onCancel,
  ...rest
}) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const [tagList, setTagList] = useState<Array<string>>([]);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <BasePopover
        title={text.title}
        handleAction={confirm => {
          if (confirm) {
            showModal(
              <ConfirmActionModal
                lockContactsVariables={lockContactsVariables}
                onComplete={() => {
                  hideModal();
                  onComplete();
                }}
                onCancel={() => {
                  hideModal();
                  onCancel();
                }}
                selectedContactAmount={selectedContactAmount}
                actionInput={{
                  AddTags: { tags: tagList } as DoContactAction_AddTags__Input,
                }}
              />,
            );
          } else {
            onCancel();
          }
        }}
        confirmText={text.confirm}
        disabled={tagList.length === 0}
      >
        <TagList
          selectedValues={tagList}
          onAdded={newTag => setTagList(prevList => [...prevList, newTag])}
          onRemoved={removedTag =>
            setTagList(prevList => prevList.filter(tag => tag !== removedTag))
          }
        />
      </BasePopover>
    </Container>
  );
};

const Container = styled.div<{}>``;

export default AddTags;
