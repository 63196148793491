import { ZapierTriggerAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';

const defaultProps = (
  baseActionProps: BaseActionProps,
): ZapierTriggerAction => ({
  ...baseActionProps,
  type: FLOW_ACTION_TYPE.ZAPIER_TRIGGER,
  zapierTriggerId: null,
  mappings: [],
});

export default defaultProps;
