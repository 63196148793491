/* eslint-disable no-console */
import React from 'react';
import { FlowV2_Action_IfElseFragment } from '~/graphql/types';
import ConditionEditor, { text } from '../ConditionEditor';
import { Props as FormProps } from '../ActionForm';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_IfElseFragment;
};

const IfElseAction: React.FC<Props> = ({ action, onChange }) => (
  <ConditionEditor
    connector={action.connector}
    triggers={action.triggers}
    onChange={(triggers, connector) => {
      onChange({ ...action, triggers, connector });
    }}
    actionType={action.actionType}
    actionId={action.id}
    conditionType="condition"
    mainRowLabel={text.conditionRowLabel}
    conditionRowLabel={text.conditionRowLabel}
  />
);

export default IfElseAction;
