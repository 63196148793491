import React from 'react';
import styled, { css } from 'styled-components';
import Icon, { IconType } from '~/components/Icon';
import JustificationContainer, {
  Props as JustificationProps,
} from '~/components/JustificationContainer';
import { BaseColor } from '~/theme/System/tokens';

export type Level = 'success' | 'warning' | 'danger' | 'loading';

export type Props = JustificationProps & {
  dataTestId?: string;

  /** Status of the event */
  level?: Level;

  /** Label to describe the status */
  label?: React.ReactNode;
};

/**
 * Can be used to show the status of SendEmail action.
 */
const StatusContainer: React.FC<Props> = ({
  dataTestId,
  children,
  level,
  label,
  padding = ['xs'],
  ...rest
}) => {
  const group = getGroupForLevel(level);

  return (
    <Container
      direction="column"
      data-testid={dataTestId}
      data-level={level}
      width="100%"
      padding={padding}
      $color={group}
      {...rest}
    >
      {level && (
        <JustificationContainer padding={['xxxs', null]}>
          <Icon
            name={getIconNameForLevel(level)}
            margin={[null, 'xs', null, null]}
          />
          {label && <div>{label}</div>}
        </JustificationContainer>
      )}

      {children}
    </Container>
  );
};

const Container = styled(JustificationContainer)<{ $color: BaseColor }>(
  ({ theme, $color }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    color: ${theme.color($color)};
    background-color: ${theme.color($color, 'translucent')};
  `,
);

const getGroupForLevel = (level?: Level): BaseColor => {
  if (!level || level === 'loading') return 'grey';
  return level;
};

const getIconNameForLevel = (level: Level): IconType => {
  switch (level) {
    case 'loading':
      return 'spinner';
    case 'danger':
      return 'error';
    case 'warning':
      return 'exclamation';
    case 'success':
      return 'check';
    default:
      return 'spinner';
  }
};

export default StatusContainer;
