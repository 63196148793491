import { each, filter, isArray, isEmpty, isObject } from 'lodash';
import { clone } from 'ramda';
import {
  ConnectorOperator,
  ContactFiltersFlattenedFragment,
  ContactFilters__Input,
} from '~/graphql/types';

import { reporter } from '~/hooks/useErrorReporter';

/**
 * Inflates an array of flattened contact filters into a `ContactFilters__Input` type.
 */
export const inflateContactFilters = ({
  contactFilters,
}: {
  contactFilters: Array<ContactFiltersFlattenedFragment>;
}): ContactFilters__Input => {
  const [head, ...tail] = contactFilters;

  const tree = {
    connector: head?.Node?.connector ?? ConnectorOperator.And,
    negate: head?.Node?.negate ?? false,
    nodes: [],
  };

  return inflateContactFiltersRecursively({ array: clone(tail), tree });
};

const inflateContactFiltersRecursively = ({
  array,
  parent,
  tree,
}: {
  array: Array<ContactFiltersFlattenedFragment>;
  parent?: any;
  tree?: ContactFilters__Input;
}): ContactFilters__Input => {
  const treeCopy =
    tree != null
      ? tree
      : { connector: ConnectorOperator.And, negate: false, nodes: [] };
  const parentCopy = typeof parent !== 'undefined' ? parent : { filterId: 0 };

  const children = filter(
    array,
    child => child.parentId === parentCopy.filterId,
  );

  if (!isEmpty(children)) {
    if (parentCopy.filterId === 0) {
      // @ts-ignore children here have a parentId and id. Ignoring the ts lint as this function can be shared with FE with this ignore.
      treeCopy.nodes = children.map(inflateChildren);
    } else {
      parentCopy.Node['nodes'] = children.map(inflateChildren);
    }

    each(children, child =>
      inflateContactFiltersRecursively({ array, parent: child }),
    );
  }

  return treeCopy;
};

const inflateChildren = (child: ContactFiltersFlattenedFragment) => {
  if (child.Node != null) {
    return {
      Node: child.Node,
    };
  } else if (child.Leaf) {
    if (child.Leaf.__typename === 'ContactFiltersLeafAttributeFlattened') {
      return {
        Leaf: { attribute: child.Leaf },
      };
    } else if (
      child.Leaf.__typename === 'ContactFiltersLeafInstanceAttributeFlattened'
    ) {
      return {
        Leaf: {
          instanceAttribute: {
            ...child.Leaf,
            commands: child.Leaf.commands.map(command => ({
              ...command,
              args: command.args.map(arg => {
                /**
                 * @see src/graphql/fragments/ContactFiltersFlattened.ts
                 * first requested field is __typename, second is the key for the arg input.
                 * keep this in sync with the fragment structure
                 */

                const argInputKey = Object.keys(arg)[1];
                return {
                  [argInputKey]: arg[argInputKey],
                };
              }),
            })),
          },
        },
      };
    }
  }
  reporter.captureMessage(
    `Malformed flattened contact filters: ${JSON.stringify(child, null, 2)}`,
    'critical',
  );
  return null;
};

/**
 * Removes metadata used to rebuild the `ContactFilters__Input`.
 */
export const cleanInflatedContactFilters = (
  obj: Record<string, unknown> | unknown,
): ContactFilters__Input | Record<string, unknown> | unknown => {
  if (!isObject(obj)) return obj;
  if (isArray(obj)) {
    return obj.map(i => cleanInflatedContactFilters(i));
  }

  return Object.keys(obj).reduce((prev, key) => {
    const keysToRemove = ['__typename', 'parentId', 'filterId'];

    if (keysToRemove.includes(key)) {
      return prev;
    }

    const value = cleanInflatedContactFilters(obj[key]);

    // We are not supposed to send null for eventId to backend
    const keysToRemoveIfNull = ['Node', 'Leaf', 'eventId'];
    if (keysToRemoveIfNull.includes(key) && value === null) {
      return prev;
    }

    prev[key] = value;
    return prev;
  }, {});
};
