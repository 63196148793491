import {
  SetContactDetailsAction,
  InexactSetContactDetailsActionFieldsFragment,
} from './types.flow';
import { BaseActionProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import extractParameterFieldFromFragment from './extractParameterFieldFromFragment';

export default (
  item: InexactSetContactDetailsActionFieldsFragment,
  baseProps: BaseActionProps,
): SetContactDetailsAction => {
  const { id } = item;

  return {
    ...baseProps,
    type: FLOW_ACTION_TYPE.SET_CONTACT_DETAILS,
    id,
    field: extractParameterFieldFromFragment(item),
  };
};
