import React, { useEffect, useState } from 'react';
import DatHuisLoading from '~/components/DatHuisLoading';
import { Body } from '~/components/Typography/index';
import {
  OutputFieldColor,
  OutputFieldImage,
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/Wizard/context/WizardContext';
import {
  AppBbWaardecheckFieldsFragment,
  AppType,
  AppVboWaardecheckFieldsFragment,
  GetAppBbWaardecheckQuery,
  GetAppVboWaardecheckQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import {
  AppWaardecheckFieldsFragment,
  appWaardecheckImageStash,
  getImageStash,
} from '~/scenes/Apps/components/WaardecheckTemplate';
import transformToInput from '~/scenes/Apps/components/WaardecheckTemplate/utils/transformToInput';
import ImageInput from '~/scenes/Settings/Widget/components/ImageInput';
import useGetAppWaardeCheckQuery from '../hooks/useGetAppWaardeCheckQuery';
import useUpdateAppWaardecheckMutation from '../hooks/useUpdateAppWaardeCheckMutation';
import TEST_ID from './index.testid';
import { Checkbox, InputGroup } from '~/components/Inputs';
import ControlledInput, {
  UrlValidation,
} from '~/scenes/Apps/components/ControlledInput';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import InputGroupDivider from '~/scenes/Apps/components/InputGroupDivider';
import ColorInputComponent from '~/scenes/Apps/components/WaardecheckTemplate/components/ColorInputComponent';
import Description from '~/scenes/Apps/components/WaardecheckTemplate/components/Description';
import { isEmpty } from '~/util/Validation/String';
import PreviewContainer from '~/scenes/Apps/components/WaardecheckTemplate/components/PreviewContainer';
import { Page } from '~/scenes/Apps/components/WaardecheckTemplate/components/Preview';
import { useSetRecoilState } from 'recoil';
import { AppStatus__typename } from '~/graphql/types.client';
import getTypenameForWaardecheckContainer from '../utils/getTypenameForWaardecheckContainer';

const text = {
  primaryColorsLabel: 'Hoofdkleuren',
  primaryColorsBody: (
    <>
      Accentkleuren worden toegepast in de stap indicator, laad animatie en
      kaart elementen.
    </>
  ),
  supportColorsLabel: 'Steunkleuren',
  supportColorsBody: (
    <>
      De achtergrondkleur wordt toegepast op de hoofdvlakken op de
      contactgegevens pagina, rapport pagina en in links, zoals op de ‘geen
      matches’ pagina. Geef de buttons een andere kleur, zodat het goed leesbaar
      is.
    </>
  ),
  body: (
    <>
      Pas de waardecheck aan naar je eigen huisstijl. Upload je logo en voeg een
      webadres toe zodat een klik op het logo doorverwijst naar je website. Voeg
      vervolgens een achtergrondfoto toe en kies je kleurenpallet. <br />
      <br />
      Tip: gebruik de hoofdkleuren van je website om een consistente huisstijl
      uit te stralen.
    </>
  ),
  error: {
    genericErrorMessage:
      'Er is iets misgegaan tijdens het opslaan, probeer het later opnieuw',
  },
  colorLabels: {
    primary: {
      color: 'Primary tekst',
      background: 'Primary achtergrond',
    },
    secondary: {
      color: 'Secondary tekst',
      background: 'Secondary achtergrond',
    },
    tertiary: {
      color: 'Tertiary tekst',
      background: 'Tertiary achtergrond',
    },
    quaternary: {
      color: 'Quaternar tekst',
      background: 'Quaternary achtergrond',
    },
  },
};

export const id = 'WaardecheckDesign';
export const title = 'Ontwerp';

export type OutputType = {
  type: typeof id;
  data: OutputFieldInvisible<
    | GetAppBbWaardecheckQuery['getAppBBWaardecheck']
    | GetAppVboWaardecheckQuery['getAppVBOWaardecheck']
  >;
  logoImage: OutputFieldImage<
    | AppBbWaardecheckFieldsFragment['general']['logoImage']
    | AppVboWaardecheckFieldsFragment['general']['logoImage']
    | null
  >;
  logoLink: OutputFieldInvisible<{ en: string | null; nl: string | null }>;
  fallbackImage: OutputFieldImage<
    | AppBbWaardecheckFieldsFragment['general']['fallbackImage']
    | AppVboWaardecheckFieldsFragment['general']['fallbackImage']
    | null
  >;
  primaryBackgroundColor: OutputFieldColor;
  primaryColor: OutputFieldColor;
  secondaryBackgroundColor: OutputFieldColor;
  secondaryColor: OutputFieldColor;
  tertiaryBackgroundColor: OutputFieldColor;
  tertiaryColor: OutputFieldColor;
  quaternaryBackgroundColor: OutputFieldColor;
  quaternaryColor: OutputFieldColor;
  sameColors: boolean;
};

const colorConfigDefaults = {
  category: 'Kleuren',
  type: 'color' as OutputFieldColor['type'],
};

export const Component: React.FC<WizardStepProps> = ({ step, outputMap }) => {
  const { id: accountId } = useCurrentAccount();
  const currentOutput = outputMap[id] as OutputType;
  const useQueryHook = useGetAppWaardeCheckQuery(step.metadata.typename);
  const useUpdateMutation = useUpdateAppWaardecheckMutation(
    step.metadata.typename,
  );
  const [stepOutput, setStepOutput] = useState<OutputType>(currentOutput);
  const [hasError, setHasError] = useState<Record<string, boolean>>({});
  const setImageStash = useSetRecoilState(appWaardecheckImageStash);
  const route =
    outputMap.WaardecheckGeneral.type === 'WaardecheckGeneral'
      ? outputMap.WaardecheckGeneral.route.value
      : null;

  const { data: queryData, loading } = useQueryHook({
    variables: { accountId },
    onCompleted: data => {
      const waardecheckData =
        'getAppBBWaardecheck' in data
          ? data.getAppBBWaardecheck
          : data.getAppVBOWaardecheck;

      setImageStash(getImageStash(waardecheckData));
    },
  });
  const [updateMutation, { data: updateData }] = useUpdateMutation();

  const data = (() => {
    if (queryData) {
      if ('getAppVBOWaardecheck' in queryData) {
        return queryData.getAppVBOWaardecheck;
      }
      if ('getAppBBWaardecheck' in queryData) {
        return queryData.getAppBBWaardecheck;
      }
    }
    return undefined;
  })();

  const [, api] = useWizardStep(step.id, {
    onBeforeNext: async outputMap => {
      const currentOutput = outputMap[id] as OutputType;

      if (!currentOutput.data.value) return currentOutput;
      const _data = currentOutput.data.value;

      const fields = outputToUpdate(
        currentOutput,
        _data,
        route,
        step.metadata.typename,
      );

      if (!fields) return currentOutput;
      const update = transformToInput(fields);

      try {
        await updateMutation({
          variables: {
            accountId,
            update: update,
          },
        });
      } catch (error) {
        throw new Error(text.error.genericErrorMessage);
      }

      return currentOutput;
    },
  });

  // Watch for updates
  useEffect(() => {
    if (updateData) {
      const _data = (() => {
        if (queryData) {
          if ('updateAppVBOWaardecheck' in updateData) {
            return updateData.updateAppVBOWaardecheck;
          }
          if ('updateAppBBWaardecheck' in updateData) {
            return updateData.updateAppBBWaardecheck;
          }
        }
        return undefined;
      })();

      if (_data && _data.general.logoImage && _data.general.fallbackImage) {
        setStepOutput(prevOutput => dataToOutput(_data, prevOutput));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    // Set initial/previous state from query data
    if (data) {
      setStepOutput(prevOutput => {
        const nextOutput = dataToOutput(data, prevOutput);

        api.free(nextOutput);
        return nextOutput;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Watch step output for wizard API interaction
  useEffect(() => {
    const apiMethod =
      Object.values(hasError).filter(x => x).length === 0 ? 'free' : 'lock';
    return api[apiMethod](stepOutput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOutput, hasError]);

  const previewData = outputToUpdate(
    stepOutput,
    data,
    route,
    step.metadata.typename,
  );

  useEffect(() => {
    if (previewData !== null) {
      setImageStash(getImageStash(previewData));
    }
  }, [previewData, setImageStash]);

  if (loading || !isOutputInitialised(stepOutput)) return <DatHuisLoading />;

  return (
    <>
      <Body>{text.body}</Body>

      {previewData && (
        <PreviewContainer
          appType={
            step.metadata.typename === 'AppStatus_BBWaardecheck'
              ? AppType.BbWaardecheck
              : AppType.VboWaardecheck
          }
          data={previewData}
          previews={[{ device: 'desktop', page: Page['/'] }]}
        />
      )}

      <ImageInput
        title="Logo"
        description="Upload een logo. Voeg een link toe om bij een klik de bezoeker naar je website door te sturen. Als je
        de Engelse link niet instelt, dan wordt standaard de Nederlandse link toegepast."
        initialUrl={
          stepOutput?.logoImage?.value?.url || data?.general.logoImage?.url
        }
        s3Key={stepOutput?.logoImage?.value?.s3key}
        filename={data?.__typename + 'logo'}
        onChange={logoImage => {
          if (logoImage) {
            setStepOutput(prevOutput => ({
              ...prevOutput,
              type: id,
              logoImage: {
                category: 'Ontwerp',
                type: 'image',
                label: 'Logo',
                value: {
                  __typename:
                    step.metadata.typename === 'AppStatus_VBOWaardecheck'
                      ? 'AppVBOWaardecheckImage'
                      : 'AppBBWaardecheckImage',
                  ...logoImage,
                },
              },
            }));
          }
        }}
        dataTestid={TEST_ID.LOGO_IMAGE_UPLOADER}
      />
      <InputGroup>
        <ControlledInput
          label="Nederlandse link"
          placeholder="https://..."
          id={`${id}.href.nl`}
          value={stepOutput.logoLink?.value.nl}
          validation={[UrlValidation]}
          onError={() => {
            api.lock(stepOutput);
          }}
          onChange={(nl, { hasErrors }) => {
            setHasError(prevError => ({
              ...prevError,
              linkNL: hasErrors,
            }));

            setStepOutput(prevOutput => {
              const nextOutput: OutputType = {
                ...prevOutput,
                type: 'WaardecheckDesign',
                logoLink: {
                  type: 'invisible',
                  value: {
                    ...prevOutput.logoLink?.value,
                    nl,
                  },
                },
              };

              return nextOutput;
            });
          }}
        />
        <ControlledInput
          label="Engelse link"
          placeholder="https://..."
          id={`${id}.href.en`}
          value={stepOutput.logoLink?.value.en}
          validation={[UrlValidation]}
          onError={() => {
            api.lock(stepOutput);
          }}
          onChange={(en, { hasErrors }) => {
            setHasError(prevError => ({
              ...prevError,
              linkEN: hasErrors,
            }));

            setStepOutput(prevOutput => {
              const nextOutput: OutputType = {
                ...prevOutput,
                type: 'WaardecheckDesign',
                logoLink: {
                  type: 'invisible',
                  value: {
                    ...stepOutput.logoLink?.value,
                    en,
                  },
                },
              };
              return nextOutput;
            });
          }}
        />
      </InputGroup>
      <InputGroup>
        <ImageInput
          title="Achtergrondfoto"
          description="Upload een achtergrond foto. Deze wordt standaard toegepast door de hele waardecheck. Per
        pagina kan deze worden aangepast in het menu links."
          initialUrl={
            stepOutput?.fallbackImage?.value?.url ||
            data?.general.fallbackImage?.url
          }
          s3Key={stepOutput?.fallbackImage?.value?.s3key}
          filename={data?.__typename + 'achtergrondfoto'}
          onChange={fallbackImage => {
            if (fallbackImage) {
              setStepOutput(prevOutput => ({
                ...prevOutput,
                type: id,
                fallbackImage: {
                  category: 'Ontwerp',
                  type: 'image',
                  label: 'Achtergrond',
                  value: {
                    ...fallbackImage,
                    __typename:
                      step.metadata.typename === 'AppStatus_VBOWaardecheck'
                        ? 'AppVBOWaardecheckImage'
                        : 'AppBBWaardecheckImage',
                  },
                },
              }));
            }
          }}
          dataTestid={TEST_ID.BACKGROUND_IMAGE_UPLOADER}
        />
      </InputGroup>

      <AppEntryItemHeader>{text.primaryColorsLabel}</AppEntryItemHeader>
      <Description>{text.primaryColorsBody}</Description>
      <InputGroup>
        <ColorInputComponent
          value={{
            background: stepOutput.primaryBackgroundColor?.value,
            color: stepOutput.primaryColor?.value,
          }}
          onChange={primary => {
            setStepOutput(prevOutput => ({
              ...prevOutput,
              type: id,
              primaryBackgroundColor: {
                ...prevOutput.primaryBackgroundColor,
                value: primary.background,
              },
              primaryColor: {
                ...prevOutput.primaryColor,
                value: primary.color,
              },
              tertiaryBackgroundColor: {
                ...prevOutput.tertiaryBackgroundColor,
                value: prevOutput.sameColors
                  ? primary.background
                  : prevOutput.tertiaryBackgroundColor.value,
              },
              tertiaryColor: {
                ...prevOutput.tertiaryColor,
                value: prevOutput.sameColors
                  ? primary.color
                  : prevOutput.tertiaryColor.value,
              },
            }));
          }}
          dataTestId={TEST_ID.PRIMARY_COLOR_SELECT}
        />
        <ColorInputComponent
          value={{
            background: stepOutput.secondaryBackgroundColor.value,
            color: stepOutput.secondaryColor.value,
          }}
          label={['Achtergrond accent', 'Tekst accent']}
          onChange={secondary => {
            setStepOutput(prevOutput => ({
              ...prevOutput,
              type: id,
              secondaryBackgroundColor: {
                ...prevOutput.secondaryBackgroundColor,
                value: secondary.background,
              },
              secondaryColor: {
                ...prevOutput.secondaryColor,
                value: secondary.color,
              },
              quaternaryBackgroundColor: {
                ...prevOutput.quaternaryBackgroundColor,
                value: prevOutput.sameColors
                  ? secondary.background
                  : prevOutput.quaternaryBackgroundColor.value,
              },
              quaternaryColor: {
                ...prevOutput.quaternaryColor,
                value: prevOutput.sameColors
                  ? secondary.color
                  : prevOutput.quaternaryColor.value,
              },
            }));
          }}
          dataTestId={TEST_ID.SECONDARY_COLOR_SELECT}
        />
      </InputGroup>
      <InputGroupDivider />

      <AppEntryItemHeader>{text.supportColorsLabel}</AppEntryItemHeader>
      <Description>{text.supportColorsBody}</Description>
      <Checkbox
        label="Gebruik hoofdkleuren"
        value={stepOutput.sameColors}
        onChange={() => {
          if (!stepOutput.sameColors) {
            setStepOutput(prevOutput => ({
              ...prevOutput,
              type: id,

              tertiaryBackgroundColor: {
                ...prevOutput.tertiaryBackgroundColor,
                value: prevOutput.primaryBackgroundColor.value,
              },
              tertiaryColor: {
                ...prevOutput.tertiaryColor,
                value: prevOutput.primaryColor.value,
              },

              quaternaryBackgroundColor: {
                ...prevOutput.quaternaryBackgroundColor,
                value: prevOutput.secondaryBackgroundColor.value,
              },
              quaternaryColor: {
                ...prevOutput.quaternaryColor,
                value: prevOutput.secondaryColor.value,
              },
            }));
          }

          setStepOutput(prevOutput => ({
            ...prevOutput,
            sameColors: !prevOutput.sameColors,
          }));
        }}
      />

      <InputGroup>
        <ColorInputComponent
          value={{
            background: stepOutput.tertiaryBackgroundColor.value,
            color: stepOutput.tertiaryColor.value,
          }}
          disabled={stepOutput.sameColors}
          onChange={tertiary => {
            setStepOutput(prevOutput => ({
              ...prevOutput,
              type: id,
              tertiaryBackgroundColor: {
                ...prevOutput.tertiaryBackgroundColor,
                value: tertiary.background,
              },
              tertiaryColor: {
                ...prevOutput.tertiaryColor,
                value: tertiary.color,
              },
            }));
          }}
          dataTestId={TEST_ID.TERTIARY_COLOR_SELECT}
        />
        <ColorInputComponent
          value={{
            background: stepOutput.quaternaryBackgroundColor.value,
            color: stepOutput.quaternaryColor.value,
          }}
          label={['Achtergrond accent', 'Tekst accent']}
          disabled={stepOutput.sameColors}
          onChange={quaternary => {
            setStepOutput(prevOutput => ({
              ...prevOutput,
              type: id,
              quaternaryBackgroundColor: {
                ...prevOutput.quaternaryBackgroundColor,
                value: quaternary.background,
              },
              quaternaryColor: {
                ...prevOutput.quaternaryColor,
                value: quaternary.color,
              },
            }));
          }}
          dataTestId={TEST_ID.QUATERNARY_COLOR_SELECT}
        />
      </InputGroup>
    </>
  );
};

const outputToUpdate = (
  currentOutput: OutputType,
  data:
    | GetAppBbWaardecheckQuery['getAppBBWaardecheck']
    | GetAppVboWaardecheckQuery['getAppVBOWaardecheck'],
  route: string | null,
  typename: AppStatus__typename,
): AppWaardecheckFieldsFragment | null => {
  if (!data) return null;

  // @ts-ignore We're doing some generic stuff that Typescript doesn't like
  const fields: AppWaardecheckFieldsFragment = {
    ...data,
    ...{
      route,
      general: {
        ...data.general,
        logoLink: {
          ...data.general.logoLink,
          href: {
            ...data.general.logoLink.href,
            en: currentOutput.logoLink.value.en,
            nl: currentOutput.logoLink.value.nl,
          },
        },
        logoImage: currentOutput.logoImage.value,
        fallbackImage: currentOutput.fallbackImage.value,
        primary: {
          background: currentOutput.primaryBackgroundColor.value,
          color: currentOutput.primaryColor.value,
          __typename: getTypenameForWaardecheckContainer(typename),
        },
        secondary: {
          background: currentOutput.secondaryBackgroundColor.value,
          color: currentOutput.secondaryColor.value,
          __typename: getTypenameForWaardecheckContainer(typename),
        },
        tertiary: {
          background: currentOutput.tertiaryBackgroundColor.value,
          color: currentOutput.tertiaryColor.value,
          __typename: getTypenameForWaardecheckContainer(typename),
        },
        quaternary: {
          background: currentOutput.quaternaryBackgroundColor.value,
          color: currentOutput.quaternaryColor.value,
          __typename: getTypenameForWaardecheckContainer(typename),
        },
      },
    },
  };

  return fields;
};

const dataToOutput = (
  data:
    | GetAppBbWaardecheckQuery['getAppBBWaardecheck']
    | GetAppVboWaardecheckQuery['getAppVBOWaardecheck'],
  prevOutput: OutputType,
): OutputType => {
  if (!data) return prevOutput;

  return {
    ...prevOutput,
    type: id,
    data: {
      type: 'invisible',
      value: data,
    },
    logoImage: {
      category: 'Ontwerp',
      type: 'image',
      label: 'Logo',
      value: prevOutput?.logoImage.value ?? data.general.logoImage ?? null,
    },
    fallbackImage: {
      category: 'Ontwerp',
      type: 'image',
      label: 'Achtergrond',
      value:
        prevOutput?.fallbackImage.value ?? data.general.fallbackImage ?? null,
    },
    logoLink: {
      type: 'invisible',
      value: {
        en:
          prevOutput?.logoLink.value.en ??
          data.general.logoLink.href.en ??
          null,
        nl:
          prevOutput?.logoLink.value.nl ??
          data.general.logoLink.href.nl ??
          null,
      },
    },

    primaryBackgroundColor: {
      ...colorConfigDefaults,
      label: text.colorLabels.primary.background,
      value: isEmpty(prevOutput?.primaryBackgroundColor?.value)
        ? data.general.primary.background
        : prevOutput?.primaryBackgroundColor?.value,
    },
    primaryColor: {
      ...colorConfigDefaults,
      label: text.colorLabels.primary.color,
      value: isEmpty(prevOutput?.primaryColor?.value)
        ? data.general.primary.color
        : prevOutput?.primaryColor?.value,
    },
    secondaryBackgroundColor: {
      ...colorConfigDefaults,
      label: text.colorLabels.secondary.background,
      value: isEmpty(prevOutput?.secondaryBackgroundColor?.value)
        ? data.general.secondary.background
        : prevOutput?.secondaryBackgroundColor?.value,
    },
    secondaryColor: {
      ...colorConfigDefaults,
      label: text.colorLabels.secondary.color,
      value: isEmpty(prevOutput?.secondaryColor?.value)
        ? data.general.secondary.color
        : prevOutput?.secondaryColor?.value,
    },
    tertiaryBackgroundColor: {
      ...colorConfigDefaults,
      label: text.colorLabels.tertiary.background,
      value: isEmpty(prevOutput?.tertiaryBackgroundColor?.value)
        ? data.general.tertiary.background
        : prevOutput?.tertiaryBackgroundColor?.value,
    },
    tertiaryColor: {
      ...colorConfigDefaults,
      label: text.colorLabels.tertiary.color,
      value: isEmpty(prevOutput?.tertiaryColor?.value)
        ? data.general.tertiary.color
        : prevOutput?.tertiaryColor?.value,
    },
    quaternaryBackgroundColor: {
      ...colorConfigDefaults,
      label: text.colorLabels.quaternary.background,
      value: isEmpty(prevOutput?.quaternaryBackgroundColor?.value)
        ? data.general.quaternary.background
        : prevOutput?.quaternaryBackgroundColor?.value,
    },
    quaternaryColor: {
      ...colorConfigDefaults,
      label: text.colorLabels.quaternary.color,
      value: isEmpty(prevOutput?.quaternaryColor?.value)
        ? data.general.quaternary.color
        : prevOutput?.quaternaryColor?.value,
    },
    sameColors: prevOutput.sameColors,
  };
};

const isOutputInitialised = (output: OutputType): boolean => {
  if (
    !output?.primaryBackgroundColor?.value ||
    isEmpty(output?.primaryBackgroundColor?.value)
  )
    return false;

  return true;
};

export default {
  id,
  title,
};
