import styled from 'styled-components';
import React from 'react';

import type { TableHeaderCellType } from '~/components/DataTables/types.flow';

import { Checkbox } from '~/components/Inputs/Checkbox';
import JustificationContainer from '~/components/JustificationContainer';

type Props = {
  column: TableHeaderCellType<any>;
  onChange: () => void;
  minus: boolean;
  value: boolean;
  noRows: boolean;
  isFocused: boolean;
};

const CheckboxHeaderCell = ({
  column,
  onChange,
  minus,
  value,
  noRows,
  isFocused,
  ...rest
}: Props) => (
  <CheckboxContainer align="center" {...rest} data-testid={column.dataTestid}>
    {!noRows && (
      <Checkbox
        name="selectAllCheckbox"
        value={value}
        minus={minus}
        isFocused={isFocused}
        onChange={onChange}
        large
      />
    )}
  </CheckboxContainer>
);

const CheckboxContainer = styled(JustificationContainer)<{}>`
  height: 100%;
`;

export default CheckboxHeaderCell;
