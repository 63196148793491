import { SetContactDetailsAction } from './types.flow';
import { BaseActionProps } from '../baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { emptyFlowParameterField } from '../Base';
import { SET_CONTACT_DETAILS_DEFAULT_FIELD } from './constants';

const defaultProps = (
  baseActionProps: BaseActionProps,
): SetContactDetailsAction => ({
  ...baseActionProps,
  type: FLOW_ACTION_TYPE.SET_CONTACT_DETAILS,
  field: emptyFlowParameterField(SET_CONTACT_DETAILS_DEFAULT_FIELD),
});

export default defaultProps;
