import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import { IconButton } from '~/components/Buttons';
import SlideOpenAnimationContainer from '~/components/SlideOpenAnimationContainer';
import { getAddFlowActionTypeOptions } from '../Actions/constants';
import useDropdown from '~/components/Inputs/Dropdown/useDropdown';
import { Dropdown, InputGroup } from '~/components/Inputs';
import TEST_ID from './AddAction.testid';
import Button from '~/components/Button';
import cleanedFilename from '~/util/cleanedFilename';
import { getDeleteButtonSize } from '~/scenes/Automation/Flows/Actions/BaseActionComponent';
import Icon from '~/components/Icon';

const text = {
  actionTypeLabel: 'Selecteer een actie',
  addButtonLabel: 'Toevoegen',
};
type Props = {
  onAdd?: (actionType: FLOW_ACTION_TYPE) => Promise<any>;
  underneathActionId: string;
  underneathActionType: FLOW_ACTION_TYPE;
  underneathActionVisiblePath?: boolean;
  hasActionUnderneath: boolean;
};
const AddAction = ({
  onAdd,
  underneathActionId,
  underneathActionType,
  underneathActionVisiblePath,
  hasActionUnderneath,
}: Props) => {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const [selectedIdx, onChange, selectedType] = useDropdown(
    addFlowActionOptions,
    0,
  );
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading && onAdd) {
      if (selectedType == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} Should not occur | selectedType is null where it should not be`,
        );
      }
      void onAdd(selectedType).then(() => {
        setLoading(false);
        scrollToActionContainer();
      });
    } else {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const scrollToActionContainer = () => {
    if (
      containerRef.current != null &&
      containerRef.current.scrollIntoView != null
    ) {
      return containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  if (onAdd == null) {
    return (
      <Container
        data-testid={TEST_ID.CONTAINER}
        data-objectid={underneathActionId}
        actionType={underneathActionType}
        visiblePath={underneathActionVisiblePath || false}
        hasActionUnderneath={hasActionUnderneath}
        hasDeleteButton={false}
      />
    );
  }

  const iconButtonComponent = (
    <StyledIconButton
      data-testid={TEST_ID.EXPAND_BUTTON}
      round
      onClick={() => {
        setExpanded(!isExpanded);

        if (!isExpanded) {
          scrollToActionContainer();
        }
      }}
      large
    >
      <Icon name="plus" clockwise={isExpanded ? 45 : 0} strokeWidth={3} />
    </StyledIconButton>
  );

  return (
    <Container
      data-testid={TEST_ID.CONTAINER}
      data-objectid={underneathActionId}
      actionType={underneathActionType}
      visiblePath={underneathActionVisiblePath || false}
      hasActionUnderneath={hasActionUnderneath}
      hasDeleteButton={true}
      ref={containerRef}
    >
      {iconButtonComponent}
      <StyledSlideOpenAnimationContainer
        isOpen={isExpanded}
        useOverflowHidden={true}
      >
        {ref => (
          <ActionChoiceContainer ref={ref}>
            <InputGroup>
              <Dropdown
                dataTestid={TEST_ID.ACTION_TYPE_DROPDOWN}
                label={text.actionTypeLabel}
                options={addFlowActionOptions}
                selectedOptionIdx={selectedIdx}
                onChange={onChange}
              />
              <Button
                data-testid={TEST_ID.ADD_BUTTON}
                loading={loading}
                onClick={() => {
                  if (selectedType == null) {
                    throw new Error(
                      `${cleanedFilename(
                        __filename,
                      )} Should not occur: >>AddAction => selectedType is null`,
                    );
                  }
                  setLoading(true);
                }}
                label={text.addButtonLabel}
              />
            </InputGroup>
          </ActionChoiceContainer>
        )}
      </StyledSlideOpenAnimationContainer>
    </Container>
  );
};

const StyledIconButton = styled(IconButton)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('xl')};
    z-index: 1;
  `,
);

// z-index to go above the line in between the actions. 3 to go above the froala. TODO: find a better way
const StyledSlideOpenAnimationContainer = styled(
  SlideOpenAnimationContainer,
)<any>`
  z-index: 3;
`;

type ContainerType = {
  actionType: FLOW_ACTION_TYPE;
  visiblePath?: boolean;
  hasActionUnderneath?: boolean;
  hasDeleteButton?: boolean;
};
const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.space('s')};
  `};

  ${({
    theme,
    actionType,
    visiblePath,
    hasActionUnderneath,
    hasDeleteButton,
  }) => {
    if (hasActionUnderneath) {
      if (actionType === FLOW_ACTION_TYPE.IF_ELSE) {
        let left;
        let backgroundColor;

        if (visiblePath) {
          backgroundColor = theme.color('success');
          left = hasDeleteButton
            ? `calc((100% - ${getDeleteButtonSize(theme)}) / 4)`
            : '25%';
        } else {
          backgroundColor = '#311D6B';
          left = hasDeleteButton
            ? `calc(3 * (100% - ${getDeleteButtonSize(theme)}) / 4)`
            : '75%';
        }

        return css`
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: ${backgroundColor};
            left: ${left};
          }
        `;
      } else {
        return css`
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: ${theme.color('grey', 'light')};
            z-index: 0;
          }
        `;
      }
    }

    return '';
  }};
`;

const ActionChoiceContainer = styled.div<{}>`
  align-self: center;
  width: 500px;
  z-index: 3;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('grey', 'light')};
    background-color: ${theme.color('white')};
    padding: ${theme.space('m')};

    margin: ${theme.space('s')} 0;
  `};
`;
const addFlowActionOptions = getAddFlowActionTypeOptions();

export default AddAction;
