import React from 'react';
import MetaTags from 'react-meta-tags';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';

import { PremiumValueReportDetailsFields } from './components/PremiumValueReportDetailsComponent';

import FullPageInformation from '~/components/FullPageInformation';
import {
  GetAppValuationReportQuery as GetReportQuery,
  UpdateAppPremiumValuationReportMutation,
  DeleteAppPremiumValuationReportMutation,
} from '~/graphql';
import query from '~/graphql/query/GetAppValuationReport';
import mutation from '~/graphql/mutation/UpdateAppPremiumValuationReport';
import deleteMutation from '~/graphql/mutation/DeleteAppPremiumValuationReport';
import { Loading } from '~/components';
import PremiumValueReportDetailsComponent from './components/PremiumValueReportDetailsComponent';
import cleanedFilename from '~/util/cleanedFilename';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import type { ValuationReportPremiumFieldsFragment } from '~/graphql/types';

const text = {
  pageTitle: 'Waarderapport',
  errorTitle: 'Geen data gevonden',
  errorExplanation:
    'Er is iets misgegaan bij het ophalen van de data van het account. Waarschijnlijk kan er geen verbinding gemaakt worden met de server. Probeer het nog een keer',
};
type Props = RouteComponentProps<{ reportId: string }>;
const PremiumValueReportDetails: React.FC<Props> = ({ reportId }) => {
  const isMountedRef = useIsMountedRef();
  const account = useCurrentAccount();

  if (!reportId)
    return (
      <>
        <FullPageInformation
          title={text.errorTitle}
          explanation={text.errorExplanation}
        />
      </>
    );

  return (
    <>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>

      <GetReportQuery
        name={cleanedFilename(__filename)}
        query={query}
        variables={{ accountId: account.id, id: reportId }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <Loading />;
          }

          if (error || !data) {
            return (
              <FullPageInformation
                title={text.errorTitle}
                explanation={text.errorExplanation}
              />
            );
          }
          const { getAppValuationReport } = data;

          if (
            getAppValuationReport == null ||
            getAppValuationReport.__typename === 'App_ValuationReport'
          ) {
            return (
              <FullPageInformation
                title={text.errorTitle}
                explanation={text.errorExplanation}
              />
            );
          }

          return (
            <UpdateAppPremiumValuationReportMutation
              name={cleanedFilename(__filename)}
              mutation={mutation}
            >
              {(updateAppPremiumValuationReport, { error, loading }) => {
                if (error) {
                  return (
                    <FullPageInformation
                      title={text.errorTitle}
                      explanation={text.errorExplanation}
                    />
                  );
                }

                return (
                  <DeleteAppPremiumValuationReportMutation
                    name={__filename}
                    mutation={deleteMutation}
                  >
                    {(
                      deleteAppPremiumValuationReport,
                      { error, loading: deleteLoading },
                    ) => {
                      if (error) {
                        return (
                          <FullPageInformation
                            title={text.errorTitle}
                            explanation={text.errorExplanation}
                          />
                        );
                      }

                      return (
                        <PremiumValueReportDetailsComponent
                          report={asProp(getAppValuationReport)}
                          updating={loading || deleteLoading}
                          updateFn={reportUpdate =>
                            updateAppPremiumValuationReport({
                              variables: {
                                accountId: account.id,
                                id: reportId,
                                update: {
                                  accountId: account.id,
                                  ...reportUpdate,
                                },
                              },
                            })
                          }
                          onDelete={() =>
                            deleteAppPremiumValuationReport({
                              variables: {
                                accountId: account.id,
                                id: reportId,
                              },
                            }).then(
                              () =>
                                isMountedRef.current &&
                                void navigate('/-/apps/value-report'),
                            )
                          }
                        />
                      );
                    }}
                  </DeleteAppPremiumValuationReportMutation>
                );
              }}
            </UpdateAppPremiumValuationReportMutation>
          );
        }}
      </GetReportQuery>
    </>
  );
};

const asProp = (
  report: ValuationReportPremiumFieldsFragment,
): PremiumValueReportDetailsFields => {
  const { algasId, name, sendAgentEmail, sendContactEmail, domain } = report;

  return {
    algasId,
    name,
    sendAgentEmail,
    sendContactEmail,
    domain: domain ?? null,
  };
};

export default PremiumValueReportDetails;
