import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { SynchroniseEntityType } from '~/scenes/External/Nylas/util';

import Validation from '~/util/Validation';
import { reportErrorToTracker } from '~/util/assertion';
import { buildStateString } from '~/scenes/External/Nylas/util';
import Modal from '~/components/Modals/Modal';
import ExpandableEmailSyncForm from '~/scenes/Settings/components/ExpandableEmailSyncForm';
import TEST_ID from './ChooseEmailSyncTypeModal.testid';
import Icon from '~/components/Icon';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

const text = {
  realworks: 'Realworks e-mailadres',
  other: 'Ander e-mailadres',
};

type Props = {
  /** When the modal is closed */
  onClose: () => void;

  /** Where this component is from */
  entityType: SynchroniseEntityType;

  /** The id of the entity (account, office, user) */
  entityId: string;

  /** Always need the accountId to send to the server if it is not account */
  accountId: string;

  /** Any e-mail address that should be used as placeholder */
  email?: string;

  /** Is called as soon as the sync was successful */
  onSuccessfulSync: () => void;
};

const ChooseEmailSyncTypeModal = ({
  onClose,
  entityId,
  entityType,
  accountId,
  email,
  onSuccessfulSync,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Modal onClose={onClose} small>
      <Container>
        <Option
          onSelect={() => setExpanded(!expanded)}
          data-testid={TEST_ID.EXPANDABLE_EMAIL_SYNC_FORM}
        >
          <ExpandableEmailSyncForm
            accountId={accountId}
            entityId={entityId}
            entityType={entityType}
            loading
            onSuccessfulSync={onSuccessfulSync}
          />
        </Option>
        <Divider />

        <Option>
          <StyledAnchor
            href={getNylasLink(
              buildStateString({ entityType, entityId, accountId }),
              email,
            )}
            data-testid={TEST_ID.SYNC_NYLAS_LINK}
          >
            <OptionHeader>
              {text.other}
              <Icon name="chevron" clockwise={-90} />
            </OptionHeader>
          </StyledAnchor>
        </Option>
      </Container>
    </Modal>
  );
};

const getNylasLink = (state: string, email?: string) => {
  let link = `https://api.nylas.com/oauth/authorize?client_id=${process.env.NYLAS_CLIENT_ID}&response_type=code`;

  if (email && Validation.Email.isValid(email)) {
    link = link + `&login_hint=${email}`;
  }

  const redirectUrl = `${process.env.BASE_URL}/-/external/nylas/auth/v1/`;
  link = link + `&redirect_uri=${redirectUrl}`;

  if (!Validation.String.isNonEmptyString(state)) {
    reportErrorToTracker(
      'Error while trying to build Nylas link because the state is not a string.',
    );
    return '';
  }

  if (state.length > 255) {
    reportErrorToTracker(
      `Error while trying to build Nylas link because the state: ${state} is too long. Max length is 255 characters.`,
    );
    return '';
  }

  link = link + `&state=${state}`;

  return link;
};

const Divider = styled.div<{}>`
  height: 1px;

  ${({ theme }) => css`
    width: calc(100% - ${theme.space('m')} * 2);
    margin: 0 ${theme.space('m')};
    background-color: ${theme.color('grey', 'light')};
  `};
`;

const Option = styled.div<{}>`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ theme }) => css`
    font-size: ${FONT_SIZE_OF_18}px;
    padding: ${theme.space('l')};
  `};
`;

export const OptionHeader = styled.div<{}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.color('text')};
    padding: ${theme.space('m')};

    &:hover {
      color: ${theme.color('grey')};
    }
  `};
`;

const StyledAnchor = styled.a<{}>`
  text-decoration: none;
  width: 100%;
`;

const Container = styled.div<{}>`
  width: 500px;

  @media (max-width: 500px) {
    width: 100vw;
  }
`;

export default ChooseEmailSyncTypeModal;
