import React, { useState } from 'react';
import styled from 'styled-components';

import LinkButton from './LinkButton';
import SlideOpenAnimationContainer from '~/components/SlideOpenAnimationContainer';

import TEST_ID from './ExpandableSectionLinkButton.testid';
import Icon from '../Icon';

type Props = {
  title: string;

  /** The children will be shown on expanding */
  children: React.ReactNode;
};
const ExpandableSectionLinkButton = ({ title, children }: Props) => {
  const [isExpanded, setExpanded] = useState(false);

  const onClickFn = () => setExpanded(!isExpanded);

  return (
    <>
      <LinkContainer>
        <ChevronContainer>
          <Icon
            name="chevron"
            flipX={isExpanded}
            onClick={onClickFn}
            data-testid={TEST_ID.CHEVRON}
          />
        </ChevronContainer>
        <LinkButtonContainer onClick={onClickFn} data-testid={TEST_ID.BUTTON}>
          <LinkButton useParentFontColor={true}>{title}</LinkButton>
        </LinkButtonContainer>
        <LineDivider />
      </LinkContainer>
      <SlideOpenAnimationContainer isOpen={isExpanded} useOverflowHidden={true}>
        {ref => <div ref={ref}>{children}</div>}
      </SlideOpenAnimationContainer>
    </>
  );
};

const ChevronContainer = styled.div<{}>`
  cursor: pointer;
`;

const LinkButtonContainer = styled.div<{}>`
  cursor: pointer;

  ${({ theme }) => `
    padding: 0 ${theme.space('s')};
  `};
`;

const LineDivider = styled.div<{}>`
  height: 1px;
  flex-grow: 1;

  ${({ theme }) => `
    background: ${theme.color('grey', 'light')};
  `};
`;

const LinkContainer = styled.div<{}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => `
      :hover {
        color: ${theme.color('grey')};
      }
  `};
`;

export default ExpandableSectionLinkButton;
