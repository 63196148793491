import styled, { css } from 'styled-components';

const Container = styled.div<{
  $openingDirection: 'top' | 'bottom' | undefined;
  $moveSelector: number | undefined;
}>(
  ({ theme, $openingDirection, $moveSelector }) => css`
    position: absolute;
    left: -${theme.space('s')};
    width: 100%;

    ${() => css`
      ${$openingDirection === 'top' ? 'top' : 'bottom'}: ${$moveSelector
        ? `${$moveSelector}px`
        : theme.space('xxxxl')};
    `}
  `,
);
export default Container;
