import { Flow_Action__Input } from '~/graphql/types';
import { StartAction } from './types.flow';
import { ActionTreeNode } from '~/scenes/Automation/Flows/components/FlowActionTrees/types.flow';
import { ActionInputDefaults } from '~/scenes/Automation/Flows/Actions/types.flow';

import { asFlowConditionInput } from '~/scenes/Automation/Flows/Actions/Base/index';
import { asFlowConditionListInput } from '../Base/FlowConditionList';

export default (
  action: StartAction,
  treeNode: ActionTreeNode,
  inputActionDefaults: ActionInputDefaults,
): Flow_Action__Input => {
  const { id, startCondition, conditionList } = action;
  const { accountId, flowBlueprintId } = inputActionDefaults;

  const startConditionInput = asFlowConditionInput(startCondition);

  return {
    Start: {
      id,
      accountId,
      flowBlueprintId,
      startCondition: startConditionInput,
      condition: asFlowConditionListInput(conditionList),
    },
  };
};
