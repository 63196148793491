import React, { useEffect, useRef, useState } from 'react';
import {
  FlowAction,
  FlowData___ConditionRepresentationFragment,
  Flow___ArgumentFragment,
  Flow___SubjectId,
} from '~/graphql/types';
import type { ConditionType } from '../../../Selector/utils/getFieldsByPath';
import type { Flow___ConditionPartial } from '../..';
import styled, { css } from 'styled-components';
import Button from '~/components/Button';
import { omit } from 'ramda';
import getLabelForRep, { text } from '../../utils/getLabelForRep';
import { getCondition } from '../../../Selector/utils/getConditions';
import getSubject, { isEqualSubject } from '../../../Selector/utils/getSubject';
import { assertNever } from '~/util/assertion';
import JustificationContainer from '~/components/JustificationContainer';
import useBuilderContext from '../../../../../Builder/hooks/useBuilderContext';
import useRelativeMaps from '~/scenes/Automation/v2/components/Builder/hooks/useRelativeMaps';
import TEST_ID from './index.testid';
import { useSetRecoilState } from 'recoil';
import flowIssues from '~/scenes/Automation/v2/state/flowIssues';
import SelectorContainer from '../SelectorContainer';
import ActionLabelContainer from '~/scenes/Automation/v2/components/Builder/components/ActionLabelContainer';
import generateKeyFromPath from '~/scenes/Automation/v2/util/generateKeyFromPath';

type TriggerInstance = {
  key: Array<string>;
  instanceType: Flow___SubjectId;
};

export type Props = {
  triggerInstance?: TriggerInstance;
  actionId: string;
  actionType: FlowAction;
  conditionType: ConditionType;
  condition: Flow___ConditionPartial | null;
  parentCondition?: Flow___ConditionPartial | null;
  disableDelete?: boolean;
  /** If row is active */
  shouldOpen: boolean;
  /** Used to position the Selector component on the selected Variable in a row. */
  activeRepIndex: number | null;
  onChange: (condition: Flow___ConditionPartial | null) => void;
  onRemove?: ((condition: Flow___ConditionPartial | null) => void) | null;
  onClose: () => void;
};

const ConditionRow: React.FC<Props> = ({
  condition,
  parentCondition,
  actionId,
  actionType,
  conditionType,
  disableDelete = false,
  shouldOpen,
  activeRepIndex,
  onChange,
  onRemove,
  onClose,
  children,
}) => {
  const builderContext = useBuilderContext();
  const omitted = omit(['onSave', 'onCancel'], builderContext);
  const { opts, primitiveInput, primitiveListInput } = omitted;
  const { subjectMap, conditionMap } = opts;

  const [openRepIndex, setOpenRepIndex] = useState<number | null>(null);
  const setIssues = useSetRecoilState(flowIssues);
  const buttonRefs = useRef<{ [key: string]: null | HTMLButtonElement }>({});

  const maps = useRelativeMaps({ actionId, parentCondition });

  /** A key like "global-contact-name" */
  const [usedInstanceField, setUsedInstanceField] = useState<
    string | undefined
  >(undefined);

  const rowContainerRef = useRef<HTMLDivElement>(null);

  const representations: Array<FlowData___ConditionRepresentationFragment> | null =
    condition == null
      ? [
          {
            __typename: 'FlowData___ConditionRepresentation',
            rep:
              actionType === FlowAction.Start && conditionType !== 'condition'
                ? 'Selecteer een trigger...'
                : 'Selecteer een conditie...',
            variable: 0,
          },
        ]
      : condition.__typename === 'Flow___SubjectFieldCondition'
      ? getSubject(
          { type: condition.type, typeId: condition.typeId },
          subjectMap,
        )?.fields.find(({ key }) => condition.field === key)?.representation ??
        null
      : getCondition(condition?.type, conditionMap)?.representation ?? null;

  const varReps = representations?.filter(r => !!r.variable);
  const emptyVarReps = varReps?.filter(
    (_, index) => condition?.args?.[index] === null,
  );
  const nextEmptyVarRepIdx =
    emptyVarReps && emptyVarReps?.length > 0
      ? representations?.findIndex(
          rep => rep.variable === emptyVarReps?.[0].variable,
        )
      : null;

  useEffect(() => {
    if (!condition) return;

    if (nextEmptyVarRepIdx != null) setOpenRepIndex(nextEmptyVarRepIdx);

    if (
      condition.__typename === 'Flow___InstanceCondition' &&
      condition?.input?.path
    ) {
      setUsedInstanceField(generateKeyFromPath(condition.input.path));
    }
  }, [condition, nextEmptyVarRepIdx]);

  const buttonRefLength = Object.values(buttonRefs.current).length;
  useEffect(() => {
    if (openRepIndex === null) {
      setOpenRepIndex(activeRepIndex || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRepIndex, buttonRefLength]);

  const onSelect =
    (rep: FlowData___ConditionRepresentationFragment | null) =>
    (selection: Flow___ConditionPartial | Flow___ArgumentFragment) => {
      // Resets issues for an action while editing the conditions
      setIssues(prev => ({ ...prev, [actionId]: [] }));
      setOpenRepIndex(null);
      setUsedInstanceField(undefined);
      const expectsCondition = rep !== null ? rep.variable === 0 : true;

      switch (selection.__typename) {
        case 'Flow___InstanceCondition':
          setUsedInstanceField(generateKeyFromPath(selection.input.path));

          if (!expectsCondition) return;
          if (!condition) return onChange(selection);
          if (
            condition.__typename !== selection.__typename ||
            selection.type !== condition.type ||
            condition.input.path.toString() !== selection.input.path.toString()
          ) {
            return onChange(selection);
          }

          /** No Change */
          return;
        case 'Flow___SubjectFieldCondition':
          if (!expectsCondition) return;
          if (!condition) return onChange(selection);
          if (
            condition.__typename !== selection.__typename ||
            !isEqualSubject(
              {
                type: selection.type,
                typeId: selection.typeId,
              },
              {
                type: condition.type,
                typeId: condition.typeId,
              },
            ) ||
            selection.field !== condition.field
          ) {
            return onChange(selection);
          }

          /** No Change */
          return;

        case 'Flow___Argument_File':
        case 'Flow___Argument_AWSDateTime':
        case 'Flow___Argument_Boolean':
        case 'Flow___Argument_Float':
        case 'Flow___Argument_Integer':
        case 'Flow___Argument_Pointer':
        case 'Flow___Argument_String': {
          if (condition == null) return;
          if (rep && rep.variable == null) return;
          if (rep && rep.variable === 0) return;

          const nextCondition: Flow___ConditionPartial = {
            ...condition,
            args: [...(condition.args ?? [])],
          };

          const argIndex = rep === null ? 0 : (rep.variable ?? 1) - 1;
          nextCondition.args[argIndex] = selection;
          return onChange(nextCondition);
        }
        default:
          assertNever(selection);
      }
    };

  return (
    <Container data-testid={TEST_ID.CONTAINER} ref={rowContainerRef}>
      <StyledJustificationContainer
        justification="start"
        align="center"
        wrap="wrap"
      >
        {children}
        {/* Normal path all good */}
        {representations !== null &&
          representations.map((rep, repIndex) => {
            const representation = getLabelForRep(
              {
                representation: rep,
                condition,
                ctx: {
                  action: actionType,
                  type: conditionType,
                },
              },
              maps,
            );

            if (rep.variable == null)
              return (
                <span
                  key={`${rep.variable}` + repIndex}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {representation.error != null
                    ? representation.error
                    : representation.result}
                  &nbsp;
                </span>
              );

            const isOpenSelector = shouldOpen && openRepIndex === repIndex;
            const isArgument = rep.variable !== 0;

            return (
              <div key={rep.variable}>
                <Button
                  appearance={
                    representation.error != null ? 'danger' : 'primary'
                  }
                  ref={element => {
                    buttonRefs.current[repIndex] =
                      element as HTMLButtonElement | null;
                  }}
                  label={
                    <ButtonLabel
                      $labelType={isArgument ? 'argument' : 'pointer'}
                    >
                      {representation.error != null ? (
                        representation.error
                      ) : (
                        <ActionLabelContainer
                          str={representation.result}
                          active={isOpenSelector}
                        />
                      )}
                    </ButtonLabel>
                  }
                  ghost
                  active={isOpenSelector}
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    setOpenRepIndex(isOpenSelector ? null : repIndex);
                  }}
                  dataTestId={TEST_ID.VARIABLE_BUTTON}
                />
                {isOpenSelector && (
                  <SelectorContainer
                    parentRef={buttonRefs.current[repIndex]}
                    inputPrimitives={primitiveInput}
                    inputListPrimitives={primitiveListInput}
                    variable={rep.variable}
                    opts={{
                      ...opts,
                      directoryMap: maps.directoryMap,
                      action: actionType,
                      conditionType: isArgument ? 'condition' : conditionType,
                      usedInstanceField:
                        rep.variable !== 0 ? usedInstanceField : undefined,
                    }}
                    condition={condition}
                    instanceMap={maps.instanceMap}
                    onClose={() => setOpenRepIndex(null)}
                    onSelect={onSelect(rep)}
                    rowContainerRef={rowContainerRef.current}
                  />
                )}
              </div>
            );
          })}

        {/*
         * Representation is null which can only happen when user disabled an app
         * this is an unrecoverable state within the rest of our architecture, so we shim it into place
         */}
        {representations === null && (
          <>
            <Button
              appearance="danger"
              ref={element => {
                buttonRefs.current[0] = element as HTMLButtonElement | null;
              }}
              label={
                <ButtonLabel $labelType="pointer">
                  {text.invalidVariable}
                </ButtonLabel>
              }
              ghost
              active={shouldOpen && openRepIndex === 0}
              margin={[null, 'xxs', null, null]}
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                setOpenRepIndex(shouldOpen && openRepIndex === 0 ? null : 0);
              }}
              dataTestId={TEST_ID.VARIABLE_BUTTON}
            />
            {shouldOpen && openRepIndex === 0 && (
              <SelectorContainer
                parentRef={buttonRefs.current[0]}
                inputPrimitives={primitiveInput}
                inputListPrimitives={primitiveListInput}
                variable={0}
                opts={{
                  ...opts,
                  directoryMap: maps.directoryMap,
                  action: actionType,
                  conditionType: conditionType,
                  usedInstanceField,
                }}
                condition={condition}
                instanceMap={maps.instanceMap}
                onClose={() => setOpenRepIndex(null)}
                onSelect={onSelect(null)}
                rowContainerRef={rowContainerRef.current}
              />
            )}
          </>
        )}
      </StyledJustificationContainer>
      <ButtonsContainer>
        {onRemove != null && !disableDelete && (
          <Button
            icon="trashcan"
            appearance="danger"
            onClick={() => onRemove(condition)}
            margin={[null, 'xxs']}
            dataTestId={TEST_ID.DELETE_BUTTON}
          />
        )}
        <Button
          icon="check"
          appearance="secondary"
          onClick={onClose}
          dataTestId={TEST_ID.CHECK_BUTTON}
        />
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const ButtonLabel = styled.span<{ $labelType: 'argument' | 'pointer' }>(
  ({ $labelType }) =>
    $labelType === 'argument'
      ? css`
          word-break: break-all;
        `
      : css`
          white-space: nowrap;
        `,
);

const StyledJustificationContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    /**  Add space between elements (especially for multiple lines) */
    & > * {
      margin: ${theme.remToPxRaw(theme.space('xxs')) / 2 + 'px'}
        ${theme.space('xxs')} ${theme.remToPxRaw(theme.space('xxs')) / 2 + 'px'}
        0px;
    }
    flex-basis: 100%;
  `,
);

const ButtonsContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    /** Align Save & Cancel buttons with the variable buttons */
    margin-top: ${theme.remToPxRaw(theme.space('xxs')) / 2}px;
  `,
);

export default ConditionRow;
