import React from 'react';

import { FlowVariableStash } from '~/scenes/Automation/Flows/types.flow';
import { FLOW_ACTION_TYPE } from './constants';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { emptyVariableStash } from '../util/variableHelpers';
import cleanedFilename from '~/util/cleanedFilename';

export type ValidationSubscriber = {
  validate: () => boolean;
};
export type ValidationSubscriberActions = {
  subscribeValidator: (newSubscriber: ValidationSubscriber) => string;
  unsubscribeValidator: (toRemoveSubscriberKey: string) => void;
};
export type BaseActionContextProps = ValidationSubscriberActions & {
  actionType: FLOW_ACTION_TYPE | null;
  variableStash: FlowVariableStash;
  isHiddenFromView: boolean;
  describeOptions: DescribeOptions;
};

const BaseActionContext = React.createContext<BaseActionContextProps>({
  actionType: null,
  variableStash: emptyVariableStash(),
  subscribeValidator: () => {
    throw new Error('Should not occur: Forgot to initialise BaseActionContext');
  },
  unsubscribeValidator: () => {
    throw new Error('Should not occur: Forgot to initialise BaseActionContext');
  },
  isHiddenFromView: false,
  describeOptions: {
    actionType: null,
    getPositionStringForId: () => {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | called getPositionStringForId before initialised`,
      );
    },
    officeWithId: () => {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | called officeWithId before initialised`,
      );
    },
    userWithId: () => {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | called userWithId before initialised`,
      );
    },
    stash: emptyVariableStash(),
  },
});

export type WithBaseActionContextProps = {
  baseActionContext: BaseActionContextProps;
};

export const withBaseActionContext = <ComponentProps extends $Object>(
  WrappedComponent: React.ComponentType<ComponentProps>,
): React.ComponentType<ComponentProps> =>
  function ConnectedComponent(props: ComponentProps) {
    return (
      <BaseActionContext.Consumer>
        {(baseActionContext: BaseActionContextProps) => (
          <WrappedComponent {...props} baseActionContext={baseActionContext} />
        )}
      </BaseActionContext.Consumer>
    );
  };

export default BaseActionContext;
