import React from 'react';
import Link from '~/components/Link';
import useIntercom from '~/hooks/useIntercom';

export type Props = {
  dataTestId?: string;

  //Text that will be sent to chat when click the link
  text?: string;

  linkText: string;
};

const ChatLink: React.FC<Props> = ({ dataTestId, text, linkText, ...rest }) => {
  const intercom = useIntercom();

  return (
    <Link
      to="#"
      data-testid={dataTestId}
      onClick={() => intercom.openChat(text)}
      {...rest}
    >
      {linkText}
    </Link>
  );
};

export default ChatLink;
