import React, { ReactElement } from 'react';

import { TaskListProps } from './types.flow';

import { taskGroupLabelForType, TaskGroups } from '~/util/task';
import TaskInListContainer from './TaskInList/TaskInListContainer';
import { TaskListContainer, GroupMarker } from './TaskList.style';
import TASK_LIST_TYPE from '~/scenes/Tasks/util/taskListType';

const CompletedTaskList: React.FC<TaskListProps> = ({
  tasks,
  hasMore,
  updateTask,
  loading,
}) => {
  const completedTaskList: Array<ReactElement> = [];

  tasks.forEach(taskWithNewOrDeleted => {
    const { item, isRemovedFromList } = taskWithNewOrDeleted;

    if (item) {
      completedTaskList.push(
        <TaskInListContainer
          key={item.id}
          isRemovedFromList={isRemovedFromList}
          task={item}
          updateTask={updateTask}
          listType={TASK_LIST_TYPE.CLOSED}
        />,
      );
    }
  });

  const hasCompletedList = completedTaskList.length > 0;
  const componentList: Array<ReactElement> = [];

  if (!hasCompletedList) {
    return null;
  }

  if (hasCompletedList) {
    componentList.push(
      <GroupMarker
        taskGroupType={TaskGroups.COMPLETED}
        data-testid="task-group-completed"
        key="task-group-completed"
      >
        {taskGroupLabelForType(TaskGroups.COMPLETED)}
      </GroupMarker>,
    );
    componentList.push(...completedTaskList);
  }

  if (!hasMore) {
    componentList.push(
      <GroupMarker
        taskGroupType={TaskGroups.COMPLETED_END}
        data-testid="task-group-completed-end"
        key="task-group-completed-end"
      >
        {taskGroupLabelForType()}
      </GroupMarker>,
    );
  }

  return (
    <TaskListContainer
      data-testid="completed-task-list"
      data-queryinflight={loading}
    >
      {componentList}
    </TaskListContainer>
  );
};

export default CompletedTaskList;
