import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import { DeleteFlowMutation } from '~/graphql';
import deleteFlowMutation from '~/graphql/mutation/DeleteFlow';
import EditableName from '~/components/DetailsPage/EditableName';
import DetailsActionList from '~/components/DetailsPage/DetailsActionList';
import cleanedFilename from '~/util/cleanedFilename';
import useConfirmModal from '~/components/Alerts/useConfirmModal';
import FullPageInformation from '~/components/FullPageInformation';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { withRouterTransitionContext } from '~/contexts/RouterTransitionContext';
import useIsMountedRef from '~/hooks/useIsMountedRef';
import getFlowCreatedDate from '../../v2/util/getFlowCreatedDate';

const text = {
  updateErrorTitle: 'Oeps!',
  updateErrorExplanation:
    'Er is iets misgegaan tijdens het opslaan van de flow. Probeer het alsjeblieft nog een keer.',
  updateErrorGoToLinkText: 'Ga naar flows',
  deleteErrorTitle: 'Oeps!',
  deleteErrorExplanation:
    'Er is iets misgegaan tijdens het verwijderen van de flow. Probeer het alsjeblieft nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via support@dathuis.nl of 020-222 35 71.',
  deleteErrorGoToLinkText: 'Ga naar flows',
  deleteLabel: 'Verwijder flow',
  copyFlow: 'Kopieer flow',
  deleteWarningLabels: {
    title: 'Waarschuwing',
    message:
      'Contacten die zich in deze flow bevinden zullen permanent uit de flow worden verwijderd.',
    buttonConfirmTitle: 'Verwijder flow',
    buttonCancelTitle: 'Annuleren',
  },
};
type Props = {
  id: string;
  name: string;
  onNameChange: (arg0: string) => void;
  hasChanges: boolean;
  leaveHandler: (confirm: boolean, cb?: () => void) => void;
  setNextRoute: (nextRoute: string) => void;
};
const FlowDetailsHeader = ({
  id,
  name,
  hasChanges,
  onNameChange,
  leaveHandler,
  setNextRoute,
}: Props) => {
  const isMountedRef = useIsMountedRef();

  const [showConfirmModal, setOnConfirmFunction] = useConfirmModal(
    text.deleteWarningLabels,
    () => {},
    () => {},
    undefined,
    false,
    'destructive',
  );
  const account = useCurrentAccount();

  return (
    <DeleteFlowMutation
      name={cleanedFilename(__filename)}
      mutation={deleteFlowMutation}
      onCompleted={() =>
        isMountedRef.current &&
        navigate('/-/automation/flows', { replace: true })
      }
    >
      {(deleteFlow, { error }) => {
        if (error) {
          return (
            <FullPageInformation
              title={text.deleteErrorTitle}
              explanation={text.deleteErrorExplanation}
              goToLink={() =>
                navigate('/-/automation/flows', { replace: true })
              }
              goToLinkText={text.deleteErrorGoToLinkText}
            />
          );
        }

        return (
          <HeaderContainer key={'flow-details-header'}>
            <EditableName
              name={name}
              edit={true}
              hasChangesOnPage={hasChanges}
              updateName={onNameChange}
            />
            <DetailsActionList
              options={[
                {
                  label: text.copyFlow,
                  onClick: () => {
                    void navigate(
                      `/-/automation/flows/builder/${id}?name=${encodeURIComponent(
                        `Kopie van ${name} gemaakt op ${getFlowCreatedDate()}`,
                      )}`,
                    );
                  },
                  type: null,
                  key: 'copy-flow',
                },
                {
                  label: text.deleteLabel,
                  onClick: () => {
                    setOnConfirmFunction(async () => {
                      leaveHandler(true, () =>
                        setNextRoute('/-/automation/flows'),
                      );
                      await deleteFlow({
                        variables: {
                          accountId: account.id,
                          id,
                        },
                      });
                    });
                    showConfirmModal();
                  },
                  type: 'DELETE',
                  key: 'delete-flow',
                },
              ]}
            />
          </HeaderContainer>
        );
      }}
    </DeleteFlowMutation>
  );
};

const HeaderContainer = styled.div<{}>`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    margin-bottom: ${theme.space('xxl')};
  `};
`;
export default withRouterTransitionContext(FlowDetailsHeader);
