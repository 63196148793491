import { KeyedActionSubscriber, ActionTreeNode } from './types.flow';

const assignSubscribersToAction = (
  actionNode: ActionTreeNode,
  subscribers: Array<KeyedActionSubscriber>,
) => {
  const foundSubscriber = subscribers.find(
    subscriber => subscriber.actionId === actionNode.id,
  );
  if (foundSubscriber != null) {
    actionNode.subscriber = foundSubscriber;
  }

  actionNode.children.forEach(child =>
    assignSubscribersToAction(child, subscribers),
  );
};

export default assignSubscribersToAction;
