import { MarkStyles } from '~/components/PluginsEditor/components/Buttons/Mark';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import { DHEditor } from '../types';
import { Editor, Range, Transforms } from 'slate';
import { toggleMark } from '../commands/modify/mark';
import { getEndPoint, getSelectedElement, getStartPoint } from '../commands';
import { nestList } from '../commands/modify/list';

let pressedKeys: Array<string> = [];
let lastKeyTime = Date.now();

const onKeydown = (
  editor: DHEditor,
  e: React.KeyboardEvent<HTMLDivElement>,
) => {
  const currentTime = Date.now();

  if (currentTime - lastKeyTime > 300) {
    pressedKeys = [];
  }

  pressedKeys.push(e.key);
  lastKeyTime = currentTime;

  const isModifier = e.ctrlKey || e.metaKey;

  const firstElementPath = getStartPoint(editor);
  const lastElementPath = getEndPoint(editor);

  const fullRange = Editor.range(editor, firstElementPath, lastElementPath);
  const allSelected = editor.selection
    ? Range.equals(editor.selection, fullRange)
    : false;

  /** Mark events */

  if (isModifier && e.key === 'b') {
    e.preventDefault();
    toggleMark(editor, MarkStyles.STRONG);
  }
  if (isModifier && e.key === 'i') {
    e.preventDefault();
    toggleMark(editor, MarkStyles.EM);
  }
  if (isModifier && e.key === 'u') {
    e.preventDefault();
    toggleMark(editor, MarkStyles.U);
  }

  /** List events */

  if (e.key === 'Tab') {
    e.preventDefault();
    const selectedEl = getSelectedElement(editor, 'highest');
    const elementType = selectedEl?.element.type;
    if (!elementType) return;

    if (elementType === ELEMENTS.UL || elementType === ELEMENTS.OL) {
      nestList(editor, elementType);
    }
  }

  if (e.key === 'Backspace' || e.key === 'Delete') {
    if (!editor.selection) return;

    /**
     *  Fixes this issue: 'select all' and 'delete' does not delete everything when there is a list, blockquote etc
     *  https://github.com/ianstormtaylor/slate/issues/2500
     * */
    if (allSelected) {
      resetEditor(editor);
    }

    return;
  }

  /** General events */

  if (checkDoubleKeyPress('Enter', pressedKeys)) {
    e.preventDefault();
    pressedKeys = [];

    /** Insert a new empty line on two sequential Enter key press */
    Transforms.insertNodes(
      editor,
      {
        type: ELEMENTS.DIV,
        children: [{ text: '' }],
      },
      { mode: 'highest' },
    );
  }
};

const checkDoubleKeyPress = (keyName, pressedKeys) => {
  if (pressedKeys.length < 2) return false;

  return pressedKeys.filter(n => n === keyName).length > 1;
};

const resetEditor = editor => {
  Editor.withoutNormalizing(editor, () => {
    // loop delete all
    editor.children.map(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [
      {
        type: ELEMENTS.DIV,
        children: [{ text: '' }],
      },
    ];

    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point };
  });
};

export default onKeydown;
