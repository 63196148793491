import React from 'react';
import styled from 'styled-components';
import Input from '../../../Input';
import { AppBbWaardecheckText__Input } from '~/graphql/types';
import InputGroup from '~/scenes/Apps/components/InputGroup';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import Description from '../Description';

export type Props = {
  dataTestId?: string;
  title?: string;
  description?: string | React.ReactNode;
  value: AppBbWaardecheckText__Input;
  onChange: (value: AppBbWaardecheckText__Input) => void;
  defaultValue: AppBbWaardecheckText__Input;
};

const LANG = {
  reset: 'Standaardtekst gebruiken',
  labelNl: 'Tekst Nederlands',
  labelEn: 'Tekst Engels',
};

const IntlInputComponent: React.FC<Props> = ({
  dataTestId,
  title,
  value,
  description,
  defaultValue,
  onChange,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    {title && <AppEntryItemHeader>{title}</AppEntryItemHeader>}
    {description && <Description>{description}</Description>}
    <InputGroup>
      <Input
        value={value.nl}
        onChange={nl => onChange({ ...value, nl: nl || null })}
        label={LANG.labelNl}
        reset={LANG.reset}
        onReset={() => onChange({ ...value, nl: defaultValue.nl })}
        data-testid={`${dataTestId}-nl`}
      />
    </InputGroup>
    <InputGroup>
      <Input
        value={value.en}
        onChange={en => onChange({ ...value, en: en || null })}
        label={LANG.labelEn}
        reset={LANG.reset}
        onReset={() => onChange({ ...value, en: defaultValue.en })}
        data-testid={`${dataTestId}-en`}
      />
    </InputGroup>
  </Container>
);

const Container = styled.div<{}>``;

export default IntlInputComponent;
