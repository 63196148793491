import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { FlowCondition } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';
import { ConditionChoiceType } from './types.flow';

import emptyFlowConditionDict from './emptyFlowConditionDict';
import { FlowConditionContactDetailsComponent } from './ContactDetails';
import { FlowConditionContactTagComponent } from './ContactTag';
import { FlowConditionEmailLinkClickedComponent } from './EmailLinkClicked';
import { FlowConditionEmailOpenedComponent } from './EmailOpened';
import { FlowConditionTimeElapsedComponent } from './TimeElapsed';
import { FlowConditionValuationReportDetailsComponent } from './AppValuationReportDetails';
import { FlowConditionValuationReportNewComponent } from './AppValuationReportNew';
import { FlowConditionValuationRequestDetailsComponent } from './AppValuationRequestDetails';
import { FlowConditionValuationRequestNewComponent } from './AppValuationRequestNew';
import { InputGroup, Dropdown } from '~/components/Inputs';
import getConditionTypeOptions from './util/getConditionTypeOptions';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import TEST_ID from './FlowConditionDetailsComponent.testid';
import { assertNeverWithoutThrowing } from '~/util/assertion';
import { FlowConditionAppFundaViewingRequestDetailsComponent } from './AppFundaViewingRequestDetails';
import { FlowConditionAppFundaContactRequestDetailsComponent } from './AppFundaContactRequestDetails';
import { FlowConditionAppFundaBrochureRequestDetailsComponent } from './AppFundaBrochureRequestDetails';
import { FlowConditionAppBBWaardecheckReportDetailsComponent } from './AppBBWaardecheckReportDetails';
import { FlowConditionAppBBWaardecheckAppraisalRequestDetailsComponent } from './AppBBWaardecheckAppraisalRequestDetails';
import { FlowConditionAppBBWaardecheckContactRequestNewComponent } from './AppBBWaardecheckContactRequestNew';
import { FlowConditionAppZapierNewComponent } from './AppZapierNew';
import { FlowConditionAppZapierDetailsComponent } from './AppZapierDetails';
import { FlowConditionTypeOption } from './constants';
import { OptionOf } from '~/components/Inputs/Dropdown';
import { FlowConditionAppVBOWaardecheckReportDetailsComponent } from './AppVBOWaardecheckReportDetails';
import { FlowConditionAppVBOWaardecheckAppraisalRequestDetailsComponent } from './AppVBOWaardecheckAppraisalRequestDetails';
import { FlowConditionAppVBOWaardecheckContactRequestNewComponent } from './AppVBOWaardecheckContactRequestNew';
import { FlowConditionAppHypotheekbondDetailsComponent } from './AppHypotheekbondDetails';
import FlowConditionAppHyptheekbondNewComponent from './AppHypotheekbondNew/FlowConditionAppHyptheekbondNewComponent';

type Props = {
  conditionLabel?: string;
  condition: FlowCondition;
  onChange: (newFlowCondition: FlowCondition) => void;
  onDropdownOpened: () => void;

  /** To determine what conditions can be chosen, if none given all will be shown */
  conditionChoiceType?: ConditionChoiceType;
  announceChanges: () => void;
  outputLoading: boolean;
};
const FlowConditionDetailsComponent = ({
  outputLoading,
  conditionLabel,
  onChange,
  onDropdownOpened,
  condition,
  conditionChoiceType,
  announceChanges,
}: Props) => {
  const [conditionTypeDict, setConditionTypeDict] = useState({
    ...emptyFlowConditionDict(),
    [condition.type]: condition,
  });

  const conditionTypeOptions = getConditionTypeOptions(
    conditionChoiceType ?? null,
  );

  const conditionTypeOptionSelectedIdx = conditionTypeOptions.findIndex(
    option => option.key === condition.type,
  );

  return (
    <ConditionDetailsContainer>
      <InputGroup data-testid={TEST_ID.CONDITION_TYPE_DROPDOWN}>
        <Dropdown
          label={conditionLabel || Catalog.flows.conditionTypeLabel}
          options={conditionTypeOptions}
          selectedOptionIdx={conditionTypeOptionSelectedIdx}
          onDropdownOpened={onDropdownOpened}
          onChange={({
            option,
          }: {
            option: OptionOf<FlowConditionTypeOption>;
          }) => {
            const newCondition = conditionTypeDict[option.payload.type];
            if (newCondition == null) {
              throw Error(
                `${cleanedFilename(
                  __filename,
                )} | Should not occur | conditionTypeDict does not have a property of type ${
                  option.payload.type
                }`,
              );
            }
            onChange(newCondition);
          }}
        />
      </InputGroup>
      {componentSwitcher(
        condition,
        newFlowCondition => {
          setConditionTypeDict({
            ...conditionTypeDict,
            [newFlowCondition.type]: newFlowCondition,
          });
          onChange(newFlowCondition);
        },
        onDropdownOpened,
        conditionChoiceType,
        announceChanges,
        outputLoading,
      )}
    </ConditionDetailsContainer>
  );
};

const componentSwitcher = (
  condition: FlowCondition,
  onChange: (newFlowCondition: FlowCondition) => void,
  onDropdownOpened: () => void,
  conditionChoiceType: ConditionChoiceType | null | undefined,
  announceChanges: () => void,
  outputLoading,
): React.ReactNode => {
  switch (condition.type) {
    case 'Flow_Condition_Contact_Details':
      return (
        <FlowConditionContactDetailsComponent
          condition={condition}
          conditionChoiceType={conditionChoiceType}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Contact_Tag':
      return (
        <FlowConditionContactTagComponent
          condition={condition}
          onChange={onChange}
          conditionChoiceType={conditionChoiceType}
        />
      );
    case 'Flow_Condition_Event_Email_LinkClicked':
      return (
        <FlowConditionEmailLinkClickedComponent
          condition={condition}
          onChange={onChange}
        />
      );
    case 'Flow_Condition_Event_Email_Opened':
      return (
        <FlowConditionEmailOpenedComponent
          condition={condition}
          onChange={onChange}
        />
      );
    case 'Flow_Condition_Time_Elapsed':
      return (
        <FlowConditionTimeElapsedComponent
          condition={condition}
          onChange={onChange}
        />
      );
    case 'Flow_Condition_Event_Contact_App_ValuationReport_New':
      return (
        <FlowConditionValuationReportNewComponent
          condition={condition}
          onChange={onChange}
        />
      );
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_New':
      return (
        <FlowConditionValuationRequestNewComponent
          condition={condition}
          onChange={onChange}
        />
      );
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New':
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New':
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New':
      return null;
    case 'Flow_Condition_Event_Contact_App_ValuationReport_Details':
      return (
        <FlowConditionValuationReportDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_Details':
      return (
        <FlowConditionValuationRequestDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details':
      return (
        <FlowConditionAppFundaContactRequestDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details':
      return (
        <FlowConditionAppFundaViewingRequestDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details':
      return (
        <FlowConditionAppFundaBrochureRequestDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New':
      return null;
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details':
      return (
        <FlowConditionAppBBWaardecheckReportDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New':
      return null;
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details':
      return (
        <FlowConditionAppVBOWaardecheckReportDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New':
      return null;
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details':
      return (
        <FlowConditionAppBBWaardecheckAppraisalRequestDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New':
      return null;
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details':
      return (
        <FlowConditionAppVBOWaardecheckAppraisalRequestDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New':
      return (
        <FlowConditionAppBBWaardecheckContactRequestNewComponent
          condition={condition}
          onChange={onChange}
        />
      );
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New':
      return (
        <FlowConditionAppVBOWaardecheckContactRequestNewComponent
          condition={condition}
          onChange={onChange}
        />
      );
    case 'Flow_Condition_Event_Contact_App_Zapier_New':
      return (
        <FlowConditionAppZapierNewComponent
          outputLoading={outputLoading}
          onDropdownOpened={onDropdownOpened}
          condition={condition}
          onChange={onChange}
        />
      );
    case 'Flow_Condition_Event_Contact_App_Zapier_Details':
      return (
        <FlowConditionAppZapierDetailsComponent
          outputLoading={outputLoading}
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );

    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_New':
      return (
        <FlowConditionAppHyptheekbondNewComponent
          onDropdownOpened={onDropdownOpened}
          condition={condition}
          onChange={onChange}
        />
      );
    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details':
      return (
        <FlowConditionAppHypotheekbondDetailsComponent
          condition={condition}
          //@ts-ignore
          onChange={onChange}
          announceChanges={announceChanges}
        />
      );

    default: {
      assertNeverWithoutThrowing(condition, cleanedFilename(__filename));
      return null;
    }
  }
};

const ConditionDetailsContainer = styled.div<{}>(
  ({ theme }) => css`
    margin: 0 ${theme.space('m')};
  `,
);
export default FlowConditionDetailsComponent;
