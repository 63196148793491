import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '~/components/Button';
import CopyCode from '~/components/CopyCode';
import Input from '~/components/Input';
import JustificationContainer from '~/components/JustificationContainer';
import FormContainer from '~/components/ModalsV2/FormContainer';
import { withOverlay } from '~/components/ModalsV2/Overlay';
import Screenshot from '~/components/Screenshot';
import TextButton from '~/components/TextButton';
import {
  RealworksTokenType,
  useUpdateRealworksAppStatusMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import AppEntryItemHeader from '~/scenes/Apps/components/AppEntryItemHeader';
import isDuplicatedToken from '~/scenes/Apps/Realworks/utils/isDuplicatedToken';
import formatToastMessage from '~/util/formatToastMessage';
import { text } from '../../../AddTokenContainerWizard/components/ActivationPendingStep';
import { exportContactsScreenshotLink } from '../../../AddTokenContainerWizard/components/ExportContactsStep';
import { importContactsScreenshotLink } from '../../../AddTokenContainerWizard/components/ImportContactsStep';
import TEST_ID from './index.testid';

export type Props = {
  containerId: string;
  tokenType: RealworksTokenType;
  onClose?: () => void;
};

const AddTokenModal: React.FC<Props> = ({
  onClose,
  containerId,
  tokenType,
  ...rest
}) => {
  const addToast = useAddToast();
  const [token, setToken] = useState('');
  const { id: accountId } = useCurrentAccount();
  const [updateRealworksAppStatus] = useUpdateRealworksAppStatusMutation({});

  const screenshotSource =
    tokenType === RealworksTokenType.Wonen
      ? exportContactsScreenshotLink
      : importContactsScreenshotLink;

  return (
    <Inner
      data-testid={TEST_ID.FORM}
      {...rest}
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();

        return updateRealworksAppStatus({
          variables: {
            accountId,
            addToken: {
              tokenContainerId: containerId,
              type: tokenType,
              token,
            },
          },
        }).then(({ errors }) => {
          if (errors && errors.length !== 0) {
            if (isDuplicatedToken(errors)) {
              addToast([
                formatToastMessage(text.duplicateErrorMessage, 'danger'),
              ]);
              return;
            } else {
              addToast([formatToastMessage(text.toastErrorMessage, 'danger')]);
              return;
            }
          }
          return onClose && onClose();
        });
      }}
    >
      <FormContainer header="Token toevoegen" onClose={onClose}>
        <JustificationContainer
          justification="start"
          margin={['base', null, null, null]}
        >
          <CopyCode
            label="1. Plak ons Developer Id in Realworks"
            code="eb92a256-b27d-4747-80c4-4d4b45485cc8"
            style={{ width: '100%' }}
          />
        </JustificationContainer>
        <JustificationContainer justification="center" margin={['base', null]}>
          <Screenshot src={screenshotSource} alt="screenshot" maxWidth={600} />
        </JustificationContainer>
        <AppEntryItemHeader>
          2. Kopieer de “Token” en plak deze in dit veld
        </AppEntryItemHeader>
        <Input
          appearance="primary"
          validation={[value => (value === '' ? 'Vul een waarde in' : true)]}
          value={token}
          onChange={e => setToken(e.target.value)}
          validationIndicator
          dataTestId={TEST_ID.TOKEN_INPUT}
        />
        <JustificationContainer
          justification="space-between"
          align="center"
          margin={['l', null, null]}
        >
          <TextButton
            label="Afbreken"
            onClick={onClose}
            withoutPadding
            appearance="danger"
            type="button"
          />
          <Button
            icon="check"
            label="Opslaan"
            type="submit"
            dataTestId={TEST_ID.SUBMIT}
          />
        </JustificationContainer>
      </FormContainer>
    </Inner>
  );
};

const Inner = styled.form<{}>`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default withOverlay(AddTokenModal);
