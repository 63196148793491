import { useEffect, useRef } from 'react';

/**
 * Simple hook to verify that a component is currently mounted.
 *
 * For more discussion
 * @see https://gist.github.com/jaydenseric/a67cfb1b809b1b789daa17dfe6f83daa#gistcomment-3356735
 */
const useIsMountedRef = () => {
  const componentIsMounted = useRef(true);
  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    [],
  );
  return componentIsMounted;
};

export default useIsMountedRef;
