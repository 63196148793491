import React, { ReactElement } from 'react';

import { SendEmailPlainAction } from './types.flow';
import { DescribeOptions } from '~/scenes/Automation/Flows/types.flow';

import { describeParameterValue } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue';

export default (
  action: SendEmailPlainAction,
  describeOptions: DescribeOptions,
): ReactElement => {
  const { subject } = action;

  return (
    <span>
      Verstuur een e-mail met onderwerp{' '}
      {describeParameterValue(subject, describeOptions, true)}
    </span>
  );
};
