import React from 'react';
import { Props } from '../';

const AvixLogo: React.FC<Props> = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Logotype"
    viewBox="0 0 566.93 255.12"
    {...props}
  >
    <title>avix_logo</title>
    <path
      d="M445.87,114.91,383.64,53.54a4.72,4.72,0,0,0-.37-.41A20,20,0,1,0,355,81.39c.13.13.27.24.41.36L416.8,144l0,0v0h29.07V114.94h-.05Z"
      style={{ fill: '#0e5c59' }}
    />
    <path
      d="M456.33,183.4l62.23,61.37.36.41a20,20,0,1,0,28.26-28.25c-.13-.13-.27-.25-.41-.37l-61.36-62.23,0,0v0H456.32v29.07h0Z"
      style={{ fill: '#9ad2b1' }}
    />
    <path
      d="M485.42,144l61.37-62.23c.13-.13.27-.24.41-.37a20,20,0,0,0-28.26-28.26c-.13.13-.24.28-.37.41l-62.23,61.37,0,0h0V144h29.07v0Z"
      style={{ fill: '#c94397' }}
    />
    <path
      d="M416.85,154.3l-61.37,62.23c-.14.13-.28.24-.41.37a20,20,0,0,0,28.26,28.25c.13-.12.24-.27.37-.4l62.23-61.37,0,0H446V154.29H416.88v.05Z"
      style={{ fill: '#05ab89' }}
    />
    <path
      d="M141,221l-13.9-46.82H69.21L55.31,221H13.88L78.57,23.25h42.85L183.56,221ZM106.67,101.57l-8-34.9c-1.13,4.54-6,25-9.36,38-3.69,14.19-6.25,23.55-10.5,36.6h38.59C116,137,109.79,115.19,106.67,101.57Z"
      style={{ fill: '#0e5c59' }}
    />
    <path
      d="M238.67,221h-31.5L154.39,78l38.88-4.83,21,65.55c3.12,9.65,7.37,25.54,9.36,34.9,1.13-8.51,4.26-22.42,8.8-36.89l19.57-61h39.73Z"
      style={{ fill: '#0e5c59' }}
    />
    <path
      d="M318.08,59.29a23.44,23.44,0,0,1-23.27-23.55,23.74,23.74,0,0,1,23.84-23.55c12.77,0,23,10.49,23,23.55A23.49,23.49,0,0,1,318.08,59.29ZM299.07,221V78.3l38-6V221Z"
      style={{ fill: '#0e5c59' }}
    />
    <rect y="0.07" width="566.93" height="250.97" style={{ fill: 'none' }} />
  </svg>
);

export default AvixLogo;
