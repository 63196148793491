import { Task } from '../types';
import {
  TaskType,
  TaskStatus,
  TaskListTaskFieldsFragment,
} from '~/graphql/types';
import { ActorType } from '~/graphql/types.client.flow';

import { convertServerDateStringToDate } from '~/util/date';

type TaskInput = {
  id: string;
  type: TaskType;
  title: string;
  officeId: string | null | undefined;
  userId: string | null | undefined;
  status: TaskStatus;
  dueDate: string;
  closedDate: string | null | undefined;
  deleted: boolean;
  deletedDate: string | null | undefined;
  description: string | null | undefined;
  contactId: string;
  Contact:
    | {
        id: string;
        name: string;
        email: string;
      }
    | null
    | undefined;
  closedBy: ActorType | null | undefined;
};

/**
 * Convert the task from the graphql query to the UI task prop
 */
const asTaskProp = (
  task: TaskInput | TaskListTaskFieldsFragment,
): Task | null => {
  const {
    id,
    dueDate,
    closedDate,
    type,
    status,
    description,
    officeId,
    userId,
    contactId,
    Contact,
    deleted,
    deletedDate,
    title,
    closedBy,
  } = task;

  // If the contact is not there it is a contact being deleted, so the task should not be visible
  // This is a timing problem where the task list is taken from an elastic search cluster that is not always up to date
  if (Contact == null) return null;

  const convertedDueDate = convertServerDateStringToDate(dueDate);
  const convertedClosedDate = convertServerDateStringToDate(closedDate);
  const convertedDeletedDate = convertServerDateStringToDate(deletedDate);

  if (convertedDueDate === null) return null;

  let closedByName: string | null = null;
  if (
    closedBy != null &&
    'name' in closedBy &&
    typeof closedBy.name === 'string'
  ) {
    closedByName = closedBy.name;
  }

  const taskProp: Task = {
    id,
    type,
    description: description ? description : null,
    status,
    dueDate: convertedDueDate,
    closedDate: convertedClosedDate,
    contactId,
    Contact: {
      id: Contact.id,
      name: Contact.name,
      email: Contact.email,
    },
    officeId,
    userId,
    deleted,
    deletedDate: convertedDeletedDate,
    title,
    closedByName,
  };
  return taskProp;
};

export default asTaskProp;
