import { useState } from 'react';
import {
  MutationHookOptions,
  useMutation,
  MutationFunction,
  DocumentNode,
  MutationTuple,
} from '@apollo/client';

import InsertTask from '~/graphql/mutation/InsertTask';
import UpdateFlowSettings from '~/graphql/mutation/UpdateFlowSettings';

import {
  UpdateProfilePictureMutation as UpdateProfilePictureMutationData,
  UpdateProfilePictureMutationVariables,
  UpdateMyDetailsMutation as UpdateMyDetailsMutationData,
  UpdateMyDetailsMutationVariables,
  UpdateTaskMutation as UpdateTaskMutationData,
  UpdateTaskMutationVariables,
  InsertAccountMutation as InsertAccountMutationData,
  InsertAccountMutationVariables,
  UpdateAccountMutation as UpdateAccountMutationData,
  UpdateAccountMutationVariables,
  UpdateAccountRelationMutation as UpdateAccountRelationMutationData,
  UpdateAccountRelationMutationVariables,
  InsertOfficeMutation as InsertOfficeMutationData,
  InsertOfficeMutationVariables,
  UpdateOfficeMutation as UpdateOfficeMutationData,
  UpdateOfficeMutationVariables,
  InviteUsersToOfficeMutation as InviteUsersToOfficeMutationData,
  InviteUsersToOfficeMutationVariables,
  InsertTaskMutation as InsertTaskMutationData,
  InsertTaskMutationVariables,
  UpdateOfficePictureMutation as UpdateOfficePictureMutationData,
  UpdateOfficePictureMutationVariables,
  InsertContactMutation as InsertContactMutationData,
  InsertContactMutationVariables,
  UpdateOfficeRelationMutation as UpdateOfficeRelationMutationData,
  UpdateOfficeRelationMutationVariables,
  UpdateAppValuationReportMutation as UpdateAppValuationReportMutationData,
  UpdateAppValuationReportMutationVariables,
  InsertAppValuationReportMutation as InsertAppValuationReportMutationData,
  InsertAppValuationReportMutationVariables,
  StartEmailSyncMutation as StartEmailSyncMutationData,
  StartEmailSyncMutationVariables,
  InsertEmailMutation as InsertEmailMutationData,
  InsertEmailMutationVariables,
  InsertActivityMutation as InsertActivityMutationData,
  InsertActivityMutationVariables,
  UpdateEmailTemplateMutation as UpdateEmailTemplateMutationData,
  UpdateEmailTemplateMutationVariables,
  InsertEmailTemplateMutation as InsertEmailTemplateMutationData,
  InsertEmailTemplateMutationVariables,
  UpdateContactMutation as UpdateContactMutationData,
  UpdateContactMutationVariables,
  UpdateFlowMutation as UpdateFlowMutationData,
  UpdateFlowMutationVariables,
  InsertFlowMutation as InsertFlowMutationData,
  InsertFlowMutationVariables,
  UpdateFlowDetailsMutation as UpdateFlowDetailsMutationData,
  UpdateFlowDetailsMutationVariables,
  UpdateAppStatusMutation as UpdateAppStatusMutationData,
  UpdateAppStatusMutationVariables,
  InsertAppPremiumValuationReportMutation as InsertAppPremiumValuationReportMutationData,
  InsertAppPremiumValuationReportMutationVariables,
  UpdateAppPremiumValuationReportMutation as UpdateAppPremiumValuationReportMutationData,
  UpdateAppPremiumValuationReportMutationVariables,
  ResendUserInvitationMutation as ResendUserInvitationMutationData,
  ResendUserInvitationMutationVariables,
  DeleteFlowMutation as DeleteFlowMutationData,
  DeleteFlowMutationVariables,
  StopFlowInstanceMutation as StopFlowInstanceMutationData,
  StopFlowInstanceMutationVariables,
  DesyncEmailMutation as DesyncEmailMutationData,
  DesyncEmailMutationVariables,
  DeleteAppPremiumValuationReportMutation as DeleteAppPremiumValuationReportMutationData,
  DeleteAppPremiumValuationReportMutationVariables,
  StartEmailSyncWithCustomProviderMutation as StartEmailSyncWithCustomProviderMutationData,
  StartEmailSyncWithCustomProviderMutationVariables,
  MarkContactForDeletionMutation as MarkContactForDeletionMutationData,
  MarkContactForDeletionMutationVariables,
  StartNvmOauthMutation as StartNvmOauthMutationData,
  StartNvmOauthMutationVariables,
  StartEmailAliasVerificationMutation as StartEmailAliasVerificationMutationData,
  StartEmailAliasVerificationMutationVariables,
  DeleteEmailAliasMutation as DeleteEmailAliasMutationData,
  DeleteEmailAliasMutationVariables,
  InsertBillingMethodWithSignupMutation as InsertBillingMethodWithSignupMutationData,
  InsertBillingMethodWithSignupMutationVariables,
  StartAddBillingMethodMutation as StartAddBillingMethodMutationData,
  StartAddBillingMethodMutationVariables,
  UpdateSubscriptionMutation as UpdateSubscriptionMutationData,
  UpdateSubscriptionMutationVariables,
  UpdateBillingDetailsMutation as UpdateBillingDetailsMutationData,
  UpdateBillingDetailsMutationVariables,
  CancelSubscriptionMutation as CancelSubscriptionMutationData,
  CancelSubscriptionMutationVariables,
  UpdateBillingMethodMutation as UpdateBillingMethodMutationData,
  UpdateBillingMethodMutationVariables,
  DeleteUserFromOfficeMutation as DeleteUserFromOfficeMutationData,
  DeleteUserFromOfficeMutationVariables,
  DeleteUserFromAccountMutation as DeleteUserFromAccountMutationData,
  DeleteUserFromAccountMutationVariables,
  DeleteOfficeMutation as DeleteOfficeMutationData,
  DeleteOfficeMutationVariables,
  DoContactActionMutation as DoContactActionMutationData,
  DoContactActionMutationVariables,
  UpsertSignatureMutation as UpsertSignatureMutationData,
  UpsertSignatureMutationVariables,
  DeleteAppValuationReportMutation as DeleteAppValuationReportMutationData,
  DeleteAppValuationReportMutationVariables,
  UpdateFlowSettingsMutationVariables,
} from './types';

import errorReporter from './errorReporter';
import { safeMutationObject } from '~/util/object';
import DeleteAppPremiumValuationReport from './mutation/DeleteAppPremiumValuationReport';
import DeleteAppValuationReport from './mutation/DeleteAppValuationReport';
import InsertAppValuationReport from './mutation/InsertAppValuationReport';

/**
 * We wrap the Apollo Mutation component to add some DatHuis defaults.
 *
 * Any error returned from the server will by default be sent to our bugtracker to be looked at/solved.
 * If you are using this component and can handle certain errors cleanly then pass the error type to the doNotReportErrorTypes props.
 * For example: 'This contact already exists'
 */
type ExtraProps = {
  name: string;

  /** Which error types should not be reported to the error tracker */
  doNotReportErrorTypes?: Array<string>;

  /** Which error messages should not be reported to the error tracker */
  doNotReportErrorMessages?: Array<string>;

  /** Ignore all the standard error tracker reporting of the query */
  ignoreAutomaticErrorReporting?: boolean;
};

type MutationArgs<TData, TVariables> = MutationHookOptions<TData, TVariables> &
  ExtraProps & {
    children: (
      mutfn: MutationTuple<TData, TVariables>[0],
      result: MutationTuple<TData, TVariables>[1],
    ) => JSX.Element | null;
    mutation: DocumentNode;
  };
const Mutation = <TData, TVariables>(
  props: MutationArgs<TData, TVariables>,
): JSX.Element | null => {
  const [mutationVariables, setMutationVariables] = useState({});

  const {
    onError,
    ignoreAutomaticErrorReporting,
    doNotReportErrorMessages,
    doNotReportErrorTypes,
    children,
    name,
    mutation,
    ...rest
  } = props;

  const [mutate, result] = useMutation<TData, TVariables>(mutation, {
    ...rest,
    onError: error =>
      errorReporter(error, {
        doNotReportErrorTypes,
        doNotReportErrorMessages,
        ignoreAutomaticErrorReporting,
        onError,
        name,
        doc: mutation,
        variables: mutationVariables,
      }),
  });

  const safeMutate = options => {
    if (options && options.variables !== undefined) {
      options.variables = safeMutationObject(options.variables);
      setMutationVariables(options.variables);
    }

    return mutate(options);
  };

  return children(safeMutate, result);
};

export const useDHMutation = <TData, TVariables>(
  mutation: DocumentNode,
  props: ExtraProps & MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> => {
  const [mutationVariables, setMutationVariables] = useState({});

  const {
    onError,
    ignoreAutomaticErrorReporting,
    doNotReportErrorMessages,
    doNotReportErrorTypes,
    name,
    ...rest
  } = props;

  const [mutate, result] = useMutation<TData, TVariables>(mutation, {
    ...rest,
    onError: error =>
      errorReporter(error, {
        doNotReportErrorTypes,
        doNotReportErrorMessages,
        ignoreAutomaticErrorReporting,
        onError,
        name,
        doc: mutation,
        variables: mutationVariables,
      }),
  });

  const safeMutate = (variables: TVariables) => {
    const safeVariables = safeMutationObject(variables);
    setMutationVariables(safeVariables);

    return mutate(safeVariables);
  };

  return [safeMutate, result];
};

export const UpdateProfilePictureMutation = (
  props: MutationArgs<
    UpdateProfilePictureMutationData,
    UpdateProfilePictureMutationVariables
  >,
) => Mutation(props);

export const UpdateMyDetailsMutation = (
  props: MutationArgs<
    UpdateMyDetailsMutationData,
    UpdateMyDetailsMutationVariables
  >,
) => Mutation(props);

export type UpdateTaskMutationFunction = MutationFunction<
  UpdateTaskMutationData,
  UpdateTaskMutationVariables
>;
export const UpdateTaskMutation = (
  props: MutationArgs<UpdateTaskMutationData, UpdateTaskMutationVariables>,
) => Mutation(props);

export const InsertAccountMutation = (
  props: MutationArgs<
    InsertAccountMutationData,
    InsertAccountMutationVariables
  >,
) => Mutation(props);

export const UpdateAccountMutation = (
  props: MutationArgs<
    UpdateAccountMutationData,
    UpdateAccountMutationVariables
  >,
) => Mutation(props);

export const UpdateAccountRelationMutation = (
  props: MutationArgs<
    UpdateAccountRelationMutationData,
    UpdateAccountRelationMutationVariables
  >,
) => Mutation(props);

export const InsertOfficeMutation = (
  props: MutationArgs<InsertOfficeMutationData, InsertOfficeMutationVariables>,
) => Mutation(props);

export const UpdateOfficeMutation = (
  props: MutationArgs<UpdateOfficeMutationData, UpdateOfficeMutationVariables>,
) => Mutation(props);

export const InviteUsersToOfficeMutation = (
  props: MutationArgs<
    InviteUsersToOfficeMutationData,
    InviteUsersToOfficeMutationVariables
  >,
) => Mutation(props);

export const InsertTaskMutation = (
  props: MutationArgs<InsertTaskMutationData, InsertTaskMutationVariables>,
) => Mutation(props);

export const useInsertTaskMutation = (
  props: ExtraProps &
    MutationHookOptions<InsertTaskMutationData, InsertTaskMutationVariables>,
) => useDHMutation(InsertTask, props);

export const UpdateOfficePictureMutation = (
  props: MutationArgs<
    UpdateOfficePictureMutationData,
    UpdateOfficePictureMutationVariables
  >,
) => Mutation(props);

export const InsertContactMutation = (
  props: MutationArgs<
    InsertContactMutationData,
    InsertContactMutationVariables
  >,
) => Mutation(props);

export const UpdateOfficeRelationMutation = (
  props: MutationArgs<
    UpdateOfficeRelationMutationData,
    UpdateOfficeRelationMutationVariables
  >,
) => Mutation(props);

export const UpdateAppValuationReportMutation = (
  props: MutationArgs<
    UpdateAppValuationReportMutationData,
    UpdateAppValuationReportMutationVariables
  >,
) => Mutation(props);

export const InsertAppValuationReportMutation = (
  props: MutationArgs<
    InsertAppValuationReportMutationData,
    InsertAppValuationReportMutationVariables
  >,
) => Mutation(props);

export const useInsertAppValuationReportMutation = (
  props: ExtraProps &
    MutationHookOptions<
      InsertAppValuationReportMutationData,
      InsertAppValuationReportMutationVariables
    >,
) => useDHMutation(InsertAppValuationReport, props);

export const StartEmailSyncMutation = (
  props: MutationArgs<
    StartEmailSyncMutationData,
    StartEmailSyncMutationVariables
  >,
) =>
  Mutation<StartEmailSyncMutationData, StartEmailSyncMutationVariables>(props);

export const InsertEmailMutation = (
  props: MutationArgs<InsertEmailMutationData, InsertEmailMutationVariables>,
) => Mutation(props);

export type InsertActivityMutationFunction = MutationFunction<
  InsertActivityMutationData,
  InsertActivityMutationVariables
>;
export const InsertActivityMutation = (
  props: MutationArgs<
    InsertActivityMutationData,
    InsertActivityMutationVariables
  >,
) => Mutation(props);

export const UpdateEmailTemplateMutation = (
  props: MutationArgs<
    UpdateEmailTemplateMutationData,
    UpdateEmailTemplateMutationVariables
  >,
) => Mutation(props);

export const InsertEmailTemplateMutation = (
  props: MutationArgs<
    InsertEmailTemplateMutationData,
    InsertEmailTemplateMutationVariables
  >,
) => Mutation(props);

export const UpdateContactMutation = (
  props: MutationArgs<
    UpdateContactMutationData,
    UpdateContactMutationVariables
  >,
) => Mutation(props);

export const ResendUserInvitationMutation = (
  props: MutationArgs<
    ResendUserInvitationMutationData,
    ResendUserInvitationMutationVariables
  >,
) => Mutation(props);

export const UpdateFlowMutation = (
  props: MutationArgs<UpdateFlowMutationData, UpdateFlowMutationVariables>,
) => Mutation(props);

export const InsertFlowMutation = (
  props: MutationArgs<InsertFlowMutationData, InsertFlowMutationVariables>,
) => Mutation(props);

export const DeleteFlowMutation = (
  props: MutationArgs<DeleteFlowMutationData, DeleteFlowMutationVariables>,
) => Mutation(props);

export const UpdateFlowDetailsMutation = (
  props: MutationArgs<
    UpdateFlowDetailsMutationData,
    UpdateFlowDetailsMutationVariables
  >,
) => Mutation(props);

export const UpdateAppStatusMutation = (
  props: MutationArgs<
    UpdateAppStatusMutationData,
    UpdateAppStatusMutationVariables
  >,
) => Mutation(props);

export const InsertAppPremiumValuationReportMutation = (
  props: MutationArgs<
    InsertAppPremiumValuationReportMutationData,
    InsertAppPremiumValuationReportMutationVariables
  >,
) => Mutation(props);

export const StopFlowInstanceMutation = (
  props: MutationArgs<
    StopFlowInstanceMutationData,
    StopFlowInstanceMutationVariables
  >,
) => Mutation(props);

export const UpdateAppPremiumValuationReportMutation = (
  props: MutationArgs<
    UpdateAppPremiumValuationReportMutationData,
    UpdateAppPremiumValuationReportMutationVariables
  >,
) => Mutation(props);

export const DesyncEmailMutation = (
  props: MutationArgs<DesyncEmailMutationData, DesyncEmailMutationVariables>,
) => Mutation(props);

export const DeleteAppPremiumValuationReportMutation = (
  props: MutationArgs<
    DeleteAppPremiumValuationReportMutationData,
    DeleteAppPremiumValuationReportMutationVariables
  >,
) => Mutation(props);

export const useDeleteAppPremiumValuationReportMutation = (
  props: ExtraProps &
    MutationHookOptions<
      DeleteAppPremiumValuationReportMutationData,
      DeleteAppPremiumValuationReportMutationVariables
    >,
) => useDHMutation(DeleteAppPremiumValuationReport, props);

export const StartEmailSyncWithCustomProviderMutation = (
  props: MutationArgs<
    StartEmailSyncWithCustomProviderMutationData,
    StartEmailSyncWithCustomProviderMutationVariables
  >,
) => Mutation(props);

export const MarkContactForDeletionMutation = (
  props: MutationArgs<
    MarkContactForDeletionMutationData,
    MarkContactForDeletionMutationVariables
  >,
) => Mutation(props);

export const StartNVMOauthMutation = (
  props: MutationArgs<
    StartNvmOauthMutationData,
    StartNvmOauthMutationVariables
  >,
) => Mutation(props);

export const StartEmailAliasVerificationMutation = (
  props: MutationArgs<
    StartEmailAliasVerificationMutationData,
    StartEmailAliasVerificationMutationVariables
  >,
) => Mutation(props);

export const DeleteEmailAliasMutation = (
  props: MutationArgs<
    DeleteEmailAliasMutationData,
    DeleteEmailAliasMutationVariables
  >,
) => Mutation(props);

export const InsertBillingMethodWithSignup = (
  props: MutationArgs<
    InsertBillingMethodWithSignupMutationData,
    InsertBillingMethodWithSignupMutationVariables
  >,
) => Mutation(props);

export const StartAddBillingMethod = (
  props: MutationArgs<
    StartAddBillingMethodMutationData,
    StartAddBillingMethodMutationVariables
  >,
) => Mutation(props);

export const UpdateSubscriptionMutation = (
  props: MutationArgs<
    UpdateSubscriptionMutationData,
    UpdateSubscriptionMutationVariables
  >,
) => Mutation(props);

export const UpdateBillingDetailsMutation = (
  props: MutationArgs<
    UpdateBillingDetailsMutationData,
    UpdateBillingDetailsMutationVariables
  >,
) => Mutation(props);

export const CancelSubscriptionMutation = (
  props: MutationArgs<
    CancelSubscriptionMutationData,
    CancelSubscriptionMutationVariables
  >,
) => Mutation(props);

export const UpdateBillingMethodMutation = (
  props: MutationArgs<
    UpdateBillingMethodMutationData,
    UpdateBillingMethodMutationVariables
  >,
) => Mutation(props);

export const DeleteUserFromOfficeMutation = (
  props: MutationArgs<
    DeleteUserFromOfficeMutationData,
    DeleteUserFromOfficeMutationVariables
  >,
) => Mutation(props);

export const DeleteUserFromAccountMutation = (
  props: MutationArgs<
    DeleteUserFromAccountMutationData,
    DeleteUserFromAccountMutationVariables
  >,
) => Mutation(props);

export const DeleteOfficeMutation = (
  props: MutationArgs<DeleteOfficeMutationData, DeleteOfficeMutationVariables>,
) => Mutation(props);

export const DoContactActionMutation = (
  props: MutationArgs<
    DoContactActionMutationData,
    DoContactActionMutationVariables
  >,
) => Mutation(props);

export const UpsertSignatureMutation = (
  props: MutationArgs<
    UpsertSignatureMutationData,
    UpsertSignatureMutationVariables
  >,
) => Mutation(props);

export const DeleteAppValuationReportMutation = (
  props: MutationArgs<
    DeleteAppValuationReportMutationData,
    DeleteAppValuationReportMutationVariables
  >,
) => Mutation(props);

export const useDeleteAppValuationReportMutation = (
  props: ExtraProps &
    MutationHookOptions<
      DeleteAppValuationReportMutationData,
      DeleteAppValuationReportMutationVariables
    >,
) => useDHMutation(DeleteAppValuationReport, props);

export const UpdateFlowSettingsMutation = (
  props: MutationArgs<
    UpdateFlowDetailsMutationData,
    UpdateFlowSettingsMutationVariables
  >,
) => Mutation(props);

export const useUpdateFlowSettingsMutation = (
  props: ExtraProps &
    MutationHookOptions<
      UpdateFlowDetailsMutationData,
      UpdateFlowSettingsMutationVariables
    >,
) => useDHMutation(UpdateFlowSettings, props);

export default Mutation;
