import gql from 'graphql-tag';

export default gql`
  query GetContactInTask($accountId: ID!, $contactId: ID!) {
    getContact(accountId: $accountId, contactId: $contactId) {
      id
      name
      phone
      email
      officeId
      userId
      LeadScore
    }
  }
`;
