import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Option } from '~/components/Inputs/Dropdown/';

import { Dropdown } from '~/components/Inputs';
import TEST_ID from './RunXTimesContainer.testid';
import Icon from '~/components/Icon';

const text = {
  run: 'Maximaal aantal keren dat deze flow voor hetzelfde contact mag starten ',
  unlimited: 'Onbeperkt',
};

type Props = {
  runTimes: number | null;
  onSetRunTimes: (value: number | null) => void;
};

const RunXTimesContainer = ({ runTimes, onSetRunTimes }: Props) => {
  const [value, setValue] = useState<number | null>(runTimes);
  const dropdownOptions = getDropdownOptions(10);

  return (
    <Container data-testid={TEST_ID.CONTAINER}>
      <TextContainer>
        <StyledRepeat name="repeat" />
        {text.run}
      </TextContainer>

      <Dropdown
        hideLabelOnTop
        dataTestid={TEST_ID.DROPDOWN}
        options={dropdownOptions}
        selectedOptionIdx={dropdownOptions.findIndex(
          option => option.payload === value,
        )}
        onChange={({ option }) => {
          setValue(option.payload);
          onSetRunTimes(option.payload);
        }}
      />
    </Container>
  );
};

const getDropdownOptions = (number: number): Array<Option> => {
  const array: Array<Option> = [];

  for (let i = 1; i < number + 1; i++) {
    array.push({
      key: i,
      payload: i,
      label: `${i}`,
    });
  }

  array.push({
    key: 'Unlimited',
    payload: null,
    label: text.unlimited,
  });

  return array;
};

const TextContainer = styled.span<{}>`
  font-weight: bold;
  min-width: 77%;
  display: flex;
  align-items: center;
`;

const StyledRepeat = styled(Icon)<{}>`
  ${({ theme }) => css`
    margin: 0 ${theme.space('xxs')};
  `}
`;

const Container = styled.div<{}>`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-width: 500px;

  ${({ theme }) => css`
    background-color: ${theme.color('white')};
    padding: ${theme.space('m')};
    margin: ${theme.space('m')} 0;
    border: ${theme.getTokens().border.width.s} solid ${theme.color('grey')};
    border-radius: ${theme.getTokens().border.radius.s};
  `}
`;

export default RunXTimesContainer;
