import React from 'react';
import ColorPicker from '~/components/ColorPicker/index';
import InputLabel, { Props as LabelProps } from '../InputLabel';

export type Props = LabelProps & {
  dataTestId?: string;
  value?: string;
  onChange?: (color: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
};

const ColorInput: React.FC<Props> = React.memo(
  ({
    dataTestId,
    error,
    label,
    help,
    value,
    onChange,
    disabled,
    readOnly,
    ...rest
  }) => (
    <InputLabel
      data-testid={dataTestId}
      error={error}
      label={label}
      help={help}
      {...rest}
    >
      <ColorPicker
        initialColor={value || '#fff'}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
      />
    </InputLabel>
  ),
);

export default ColorInput;
