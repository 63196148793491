import gql from 'graphql-tag';

import FlowParameterStringFields from './FlowParameterStringFields';

export default gql`
  fragment FlowConditionStringPrimitiveFields on Flow_ConditionPrimitive_String {
    __typename

    ... on Flow_ConditionPrimitive_String_EndsWith {
      stringValue: value {
        ...FlowParameterStringFields
      }
      negate
    }

    ... on Flow_ConditionPrimitive_String_Eq {
      stringValue: value {
        ...FlowParameterStringFields
      }
      negate
    }

    ... on Flow_ConditionPrimitive_String_StartsWith {
      stringValue: value {
        ...FlowParameterStringFields
      }
      negate
    }

    ... on Flow_ConditionPrimitive_String_Contains {
      stringValue: value {
        ...FlowParameterStringFields
      }
      negate
    }
  }

  ${FlowParameterStringFields}
`;
