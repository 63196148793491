import styled, { css } from 'styled-components';

import { calculateFontSize } from '~/components/util/getSizeCalculation';
import { getDisabledInputStyle } from '~/components/Inputs/InputElement';

const BORDER_SIZE = 1;

type SelectFieldContainerType = {
  large?: boolean;
  small?: boolean;
  disabled?: boolean;
};
export default styled.div<SelectFieldContainerType>`
  display: flex;
  align-items: center;

  ${({ theme, disabled }) => {
    if (disabled) {
      return getDisabledInputStyle(theme);
    }

    return '';
  }}

  ${({ theme, large, small }) => {
    const activeBorderStyle = `${BORDER_SIZE}px solid ${theme.color('text')}`;
    const border = `${BORDER_SIZE}px solid ${theme.color('grey')}`;

    return css`
      font-size: ${calculateFontSize(theme, small, large)}px;
      border: ${border};
      border-radius: ${theme.getTokens().border.radius.s};
      &:hover {
        border: ${activeBorderStyle};
      }

      &:focus-within {
        border: ${activeBorderStyle};
      }
    `;
  }};
`;
