import React from 'react';
import styled from 'styled-components';
import logos from './components';

export type Props = {
  dataTestId?: string;
  name: keyof typeof logos;
  secondary?: boolean;
  color?: string;
  onClick?: () => void;
};

const Logo: React.FC<Props> = ({
  dataTestId,
  name,
  secondary,
  color,
  ...rest
}) => {
  const PickedLogo = logos[name];

  return (
    <Container data-testid={dataTestId} {...rest}>
      <PickedLogo color={color || 'currentColor'} secondary={secondary} />
    </Container>
  );
};

const Container = styled.span<{}>`
  height: 1em;
`;

export default Logo;
