import { FlowCondition } from './types.flow';
import { Flow_Condition__Input } from '~/graphql/types';

import { asFlowConditionContactDetailsInput } from './ContactDetails/';
import { asFlowConditionContactTagInput } from './ContactTag';
import { asFlowConditionEmailLinkClickedInput } from './EmailLinkClicked';
import { asFlowConditionEmailOpenedInput } from './EmailOpened';
import { asFlowConditionTimeElapsedInput } from './TimeElapsed';
import { asFlowConditionAppValuationReportNewInput } from './AppValuationReportNew';
import { asFlowConditionValuationReportDetailsInput } from './AppValuationReportDetails';
import { asFlowConditionAppValuationRequestNewInput } from './AppValuationRequestNew';
import { asFlowConditionValuationRequestDetailsInput } from './AppValuationRequestDetails';

import cleanedFilename from '~/util/cleanedFilename';
import { asFlowConditionAppFundaContactRequestNewInput } from './AppFundaContactRequestNew';
import { asFlowConditionAppFundaViewingRequestNewInput } from './AppFundaViewingRequestNew';
import { asFlowConditionAppFundaBrochureRequestNewInput } from './AppFundaBrochureRequestNew';
import { assertNever } from '~/util/assertion';
import { asFlowConditionAppFundaContactRequestDetailsInput } from './AppFundaContactRequestDetails';
import { asFlowConditionAppFundaViewingRequestDetailsInput } from './AppFundaViewingRequestDetails';
import { asFlowConditionAppFundaBrochureRequestDetailsInput } from './AppFundaBrochureRequestDetails';

import { asFlowConditionAppBBWaardecheckReportNewInput } from './AppBBWaardecheckReportNew';
import { asFlowConditionAppBBWaardecheckReportDetailsInput } from './AppBBWaardecheckReportDetails';
import { asFlowConditionAppBBWaardecheckAppraisalRequestNewInput } from './AppBBWaardecheckAppraisalRequestNew';
import { asFlowConditionAppBBWaardecheckAppraisalRequestDetailsInput } from './AppBBWaardecheckAppraisalRequestDetails';
import { asFlowConditionAppBBWaardecheckContactRequestNewInput } from './AppBBWaardecheckContactRequestNew';
import { asFlowConditionAppZapierNewInput } from './AppZapierNew';
import { asFlowConditionAppZapierDetailsInput } from './AppZapierDetails';

import { asFlowConditionAppVBOWaardecheckReportNewInput } from './AppVBOWaardecheckReportNew';
import { asFlowConditionAppVBOWaardecheckReportDetailsInput } from './AppVBOWaardecheckReportDetails';
import { asFlowConditionAppVBOWaardecheckAppraisalRequestNewInput } from './AppVBOWaardecheckAppraisalRequestNew';
import { asFlowConditionAppVBOWaardecheckAppraisalRequestDetailsInput } from './AppVBOWaardecheckAppraisalRequestDetails';
import { asFlowConditionAppVBOWaardecheckContactRequestNewInput } from './AppVBOWaardecheckContactRequestNew';
import { asFlowConditionAppHypotheekbondDetailsInput } from './AppHypotheekbondDetails';
import { asFlowConditionAppHypotheekbondNewInput } from './AppHypotheekbondNew';

export default (condition: FlowCondition): Flow_Condition__Input => {
  switch (condition.type) {
    case 'Flow_Condition_Contact_Details': {
      const input = asFlowConditionContactDetailsInput(condition);

      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }
    case 'Flow_Condition_Contact_Tag':
      return asFlowConditionContactTagInput(condition);
    case 'Flow_Condition_Event_Email_LinkClicked':
      return asFlowConditionEmailLinkClickedInput(condition);
    case 'Flow_Condition_Event_Email_Opened':
      return asFlowConditionEmailOpenedInput(condition);
    case 'Flow_Condition_Time_Elapsed':
      return asFlowConditionTimeElapsedInput(condition);
    case 'Flow_Condition_Event_Contact_App_ValuationReport_New':
      return asFlowConditionAppValuationReportNewInput(condition);
    case 'Flow_Condition_Event_Contact_App_ValuationReport_Details': {
      const input = asFlowConditionValuationReportDetailsInput(condition);

      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_New':
      return asFlowConditionAppValuationRequestNewInput(condition);
    case 'Flow_Condition_Event_Contact_App_ValuationRequest_Details': {
      const input = asFlowConditionValuationRequestDetailsInput(condition);

      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New':
      return asFlowConditionAppFundaContactRequestNewInput();
    case 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details': {
      const input =
        asFlowConditionAppFundaContactRequestDetailsInput(condition);
      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }

    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New':
      return asFlowConditionAppFundaViewingRequestNewInput();
    case 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details': {
      const input =
        asFlowConditionAppFundaViewingRequestDetailsInput(condition);
      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New':
      return asFlowConditionAppFundaBrochureRequestNewInput();
    case 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details': {
      const input =
        asFlowConditionAppFundaBrochureRequestDetailsInput(condition);
      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New': {
      return asFlowConditionAppBBWaardecheckReportNewInput();
    }
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details': {
      const input =
        asFlowConditionAppBBWaardecheckReportDetailsInput(condition);
      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New': {
      return asFlowConditionAppBBWaardecheckAppraisalRequestNewInput();
    }
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details': {
      const input =
        asFlowConditionAppBBWaardecheckAppraisalRequestDetailsInput(condition);
      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }
    case 'Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New': {
      return asFlowConditionAppBBWaardecheckContactRequestNewInput(condition);
    }
    case 'Flow_Condition_Event_Contact_App_Zapier_New': {
      return asFlowConditionAppZapierNewInput(condition);
    }
    case 'Flow_Condition_Event_Contact_App_Zapier_Details': {
      const input = asFlowConditionAppZapierDetailsInput(condition);
      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }

    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New': {
      return asFlowConditionAppVBOWaardecheckReportNewInput();
    }
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details': {
      const input =
        asFlowConditionAppVBOWaardecheckReportDetailsInput(condition);
      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New': {
      return asFlowConditionAppVBOWaardecheckAppraisalRequestNewInput();
    }
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details': {
      const input =
        asFlowConditionAppVBOWaardecheckAppraisalRequestDetailsInput(condition);
      if (input.length !== 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | asFlowConditionInput cannot convert a grouped condition without exactly 1 other`,
        );
      }

      return input[0];
    }
    case 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New': {
      return asFlowConditionAppVBOWaardecheckContactRequestNewInput(condition);
    }

    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_New': {
      return asFlowConditionAppHypotheekbondNewInput(condition);
    }
    case 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details': {
      const input = asFlowConditionAppHypotheekbondDetailsInput(condition);
      return input[0];
    }

    default:
      assertNever(condition);
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | convert condition of (${JSON.stringify(
          condition,
          null,
          2,
        )}) to input not implemented`,
      );
  }
};
