import { Flow_ParameterMapping__Input } from '~/graphql/types';
import { FlowParameterMappingParameter } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/FlowParameterMapping/types.flow';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';
import {
  asBooleanParameterValueInput,
  asNumberParameterValueInput,
} from '../ParameterValue';

import { PARAMETER_VALUE_TYPE } from '../ParameterValue/constants';
import { asStringParameterValueInput } from '../ParameterValue/String';

export default (
  parValue: FlowParameterMappingParameter,
): Flow_ParameterMapping__Input['mapping'] => {
  switch (parValue.type) {
    case PARAMETER_VALUE_TYPE.STRING_POINTER:
    case PARAMETER_VALUE_TYPE.STRING_PRIMITIVE:
      return asStringParameterValueInput(parValue);
    case PARAMETER_VALUE_TYPE.BOOLEAN_POINTER:
    case PARAMETER_VALUE_TYPE.BOOLEAN_PRIMITIVE:
      return asBooleanParameterValueInput(parValue);
    case PARAMETER_VALUE_TYPE.NUMBER_POINTER:
    case PARAMETER_VALUE_TYPE.NUMBER_PRIMITIVE:
      return asNumberParameterValueInput(parValue);

    default:
      return assertNever(parValue, cleanedFilename(__filename));
  }
};
