import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useTransition } from 'react-spring';
import illustrations from '~/components/Illustration/components';
import { GroupLookup } from '~/components/ToggleAccordion';
import {
  GetWidgetSettingsQuery,
  WidgetSettingsAppearance,
  WidgetSettingsPinned,
  WidgetSettingsPositionV2,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { ANIMATION_CONFIG } from '~/scenes/Settings/constants';
import loadWidget from '../../utils/loadWidget';
import Loader from './components/Loader';
import { reporter } from '~/hooks/useErrorReporter';
import { isNil } from 'ramda';
import parseJSONSafe from '~/util/parseJSONSafe';
import { PinnedAppsMap } from '../../states/pinnedApps';
import indexToWeight from '../../utils/indexToWeight';

export type Props = {
  initialSettings: GetWidgetSettingsQuery['getWidgetSettings'];
  groupLookup: GroupLookup<{ appConfiguration: string }>;
  initialGroupLookup: GroupLookup<{ appConfiguration: string }>;
  pinnedApps: PinnedAppsMap;
  initiallyPinnedApps: PinnedAppsMap;
};

const WidgetPreview: React.FC<Props> = React.memo(
  ({
    initialSettings,
    groupLookup,
    initialGroupLookup,
    initiallyPinnedApps,
    pinnedApps,
  }) => {
    const account = useCurrentAccount();
    const [widgetApi, setWidgetApi] = useState<null | Function>(null);

    const transitions = useTransition(widgetApi === null, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: ANIMATION_CONFIG.config,
    });

    useFormState({
      onChange: ({ values }) => {
        if (widgetApi === null) return;
        const previousWindowConfig: WindowConfig = widgetApi('getConfig');
        const windowConfig = settingsToWindowConfig({
          accountId: account.id,
          settings: values as GetWidgetSettingsQuery['getWidgetSettings'],
          appsLookup: values.apps ?? groupLookup,
          previousWindowConfig,
          pinnedApps: values.pinnedApps ?? pinnedApps,
        });

        widgetApi('clearDismiss');
        widgetApi('__overwriteConfig', windowConfig);
      },
    });

    // Handle dismissal separately
    useEffect(() => {
      if (widgetApi !== null) {
        // Delay this call until after the dismiss command is called in UW useDismiss hook
        setTimeout(() => {
          widgetApi('clearDismiss');
        }, 1000);
      }

      return () => {
        // dismiss when navigating away from the page
        if (widgetApi !== null) {
          widgetApi('dismiss');
        }
      };
    }, [widgetApi]);

    useEffect(() => {
      // Enable logging for widget.
      // @ts-ignore
      // window.DH_DEBUG = 'true';

      const initialWindowConfig = settingsToWindowConfig({
        accountId: account.id,
        settings: initialSettings,
        appsLookup: initialGroupLookup,
        pinnedApps: initiallyPinnedApps,
      });
      const inner = async () => {
        const widgetApi = await loadWidget(account.id, initialWindowConfig);

        setWidgetApi(() => widgetApi);
        const previousWindowConfig: WindowConfig = widgetApi('getConfig');
        const windowConfig = settingsToWindowConfig({
          accountId: account.id,
          settings: initialSettings,
          appsLookup: initialGroupLookup,
          previousWindowConfig,
          pinnedApps: initiallyPinnedApps,
        });
        widgetApi('__overwriteConfig', windowConfig);
      };

      // We only need to call this once when widgetApi is null so it is not added in the dependency array
      void inner();
    }, [account.id, initialSettings, initialGroupLookup, initiallyPinnedApps]);

    return transitions((style, item) =>
      item ? <Loader style={style} initialSettings={initialSettings} /> : null,
    );
  },
);

const getPinnedFields = (pinnedItem?: WidgetSettingsPinned) => {
  const pinned = {
    enabled: pinnedItem ? true : false,
    weight: pinnedItem ? indexToWeight(pinnedItem.weight) : 0,
  };

  return pinned;
};

export type StyleConfigColorPair = {
  background: string;
  color: string;
};

export type StyleConfig = {
  primary: StyleConfigColorPair;
  secondary: StyleConfigColorPair;
};

export enum Appearance {
  Small = 'Small',
  Large = 'Large',
}

export type Positions = {
  desktop: WidgetSettingsPositionV2;
  mobile: WidgetSettingsPositionV2;
};

export type Appearances = {
  desktop: WidgetSettingsAppearance;
  mobile: WidgetSettingsAppearance;
};

export type Locale = 'nl' | 'en';

export type ContentString = Record<Locale, string>;

export type WindowIframeApp = {
  type: 'iframe';
  appUrl: string;
  appParams: object;
  slug: string;
  title: ContentString;
  description: ContentString;
  illustration: keyof typeof illustrations;
  imageUrl?: string;
  highlighted?: boolean;
};

export type WindowApp = WindowIframeApp;
export type WindowConfig = {
  accountId: string;
  positions: Positions;
  appearances: Appearances;
  logo: {
    src: string;
  };
  style: StyleConfig;
  zIndex: number;
  apps?: Array<WindowApp>;
};

type Args = {
  accountId: string;
  settings: GetWidgetSettingsQuery['getWidgetSettings'];
  appsLookup: GroupLookup<{ appConfiguration: string }>;
  previousWindowConfig?: WindowConfig;
  pinnedApps?: PinnedAppsMap;
};
const settingsToWindowConfig = ({
  accountId,
  settings,
  appsLookup,
  previousWindowConfig,
  pinnedApps,
}: Args): WindowConfig => {
  const windowConfig: WindowConfig = {
    accountId,
    logo: {
      src: settings.logo?.url || '',
    },
    positions: {
      desktop: settings.positions.desktop,
      mobile: settings.positions.mobile,
    },
    appearances: {
      desktop: settings.appearances.desktop,
      mobile: settings.appearances.mobile,
    },
    zIndex: settings.zIndex,
    style: {
      primary: {
        background: settings.style.primary.background,
        color: settings.style.primary.color,
      },
      secondary: {
        background: settings.style.secondary.background,
        color: settings.style.secondary.color,
      },
    },
  };

  if (previousWindowConfig && previousWindowConfig.apps) {
    const { apps } = previousWindowConfig;
    windowConfig.apps = apps;
  }

  if (windowConfig && windowConfig.apps && appsLookup) {
    const enabledApps = Object.keys(appsLookup).reduce((acc, key) => {
      const app = appsLookup[key];

      if (app.mainChecked === false) return acc;

      if (isNil(app.items) && app.appConfiguration) {
        const parsed = parseJSONSafe(app.appConfiguration);
        const imageUrl =
          settings.highlightImage?.url ??
          settings.defaultConfigValues.highlightImage;
        const pinnedApp = pinnedApps ? pinnedApps[key] : undefined;

        acc.push({ ...parsed, imageUrl, pinned: getPinnedFields(pinnedApp) });
      }

      app.items?.forEach(curr => {
        if (curr.checked) {
          try {
            const parsed = parseJSONSafe(curr.appConfiguration);
            const imageUrl =
              settings.highlightImage?.url ??
              settings.defaultConfigValues.highlightImage;

            const pinnedItem = pinnedApps ? pinnedApps[curr.id] : undefined;

            acc.push({
              ...parsed,
              imageUrl,
              pinned: getPinnedFields(pinnedItem),
            });
          } catch (error) {
            reporter.captureException(error);
          }
        }
      });
      return acc;
    }, [] as Array<any>);

    windowConfig.apps = enabledApps;
  }

  return windowConfig;
};

export default WidgetPreview;
