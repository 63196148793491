import React from 'react';

import { StringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { StringParameterValueComponent } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/String';

type Props = {
  value: StringParameterValue;
  onChange: (newStringParameterValue: StringParameterValue) => void;
};
const ParameterStringFieldComponent = ({ value, onChange }: Props) => (
  <StringParameterValueComponent
    value={value}
    change={{
      controlled: true,
      onChange: onChange,
    }}
  />
);

export default ParameterStringFieldComponent;
