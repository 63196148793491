import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Alerts } from '~/components';
import { InputGroup, Input } from '~/components/Inputs';
import LinkButton from '~/components/Buttons/LinkButton';

import { UserRole } from '~/graphql/types';
import DeleteInputButton from '~/components/Buttons/DeleteInputButton';
import { Admin } from '../Account.flow';
import DetailsBlock from '~/components/DetailsBlock';
type Props = {
  handleToggleDrawer: (open: boolean) => void;
  updateUserRole: (arg0: { role: UserRole; userId: string }) => void;
  admins: Array<Admin>;
  isAdmin: boolean;
  loading: boolean;
};

type State = {
  showModal: boolean;
  selectedId: string | null | undefined;
};

const text = {
  inputLabel: 'Gebruiker',
  linkButton: 'Administrator toevoegen',
  h3: 'Account administrators',
  modalTitle:
    'Weet je het zeker dat je deze gebruiker geen beheerdersrechten meer wilt geven?',
  confirmButtonTitle: 'Ja ik weet het zeker',
};

class AccountAdministrators extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
      selectedId: null,
    };
  }

  handleModalAction = (confirm: boolean) => {
    const { updateUserRole } = this.props;
    const { selectedId } = this.state;

    this.setState({
      showModal: false,
    });

    if (confirm && selectedId) {
      return updateUserRole({
        role: UserRole.User,
        userId: selectedId,
      });
    }
  };

  render() {
    const { showModal } = this.state;
    const { admins, handleToggleDrawer, loading, isAdmin } = this.props;
    const showDeleteButton = isAdmin && admins.length > 1;

    const modalOrNull = showModal ? (
      <Alerts.ConfirmModal
        title={text.modalTitle}
        handleAction={this.handleModalAction}
        buttonConfirmTitle={text.confirmButtonTitle}
      />
    ) : null;

    const adminList = admins.map(item => {
      const deleteInputOrNull = showDeleteButton ? (
        <DeleteInputButton
          large
          dataTestId={`admin-user-delete-button-${item.payload.id}`}
          disabled={loading}
          onClick={() => {
            this.setState({
              selectedId: item.payload.id,
              showModal: true,
            });
          }}
        />
      ) : null;

      return (
        <InputGroup key={item.payload.id}>
          <Input
            large
            label={text.inputLabel}
            type="text"
            disabled={true}
            value={item.label}
            data-testid={`admin-input-${item.payload.id}`}
            name={`admin-input-${item.label}`}
          />
          {deleteInputOrNull}
        </InputGroup>
      );
    });

    const adminButtonOrNull = isAdmin ? (
      <AddAdminButton
        testId="add-new-admin-link"
        disabled={loading}
        onClick={() => handleToggleDrawer(true)}
      >
        {text.linkButton}
      </AddAdminButton>
    ) : null;

    return (
      <DetailsBlock title={text.h3} dataTestid="admin-user-container">
        {modalOrNull}
        {adminList}
        {adminButtonOrNull}
      </DetailsBlock>
    );
  }
}

const AddAdminButton = styled(LinkButton)<{}>(
  ({ theme }) => css`
    margin: ${theme.space('l')} 0;
  `,
);

export default AccountAdministrators;
