import { FlowParameterFileFieldsFragment } from '~/graphql/types';
import { FilePointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../constants';
import emptyFilePointerParameterValue from './emptyFilePointerParameterValue';

import cleanedFilename from '~/util/cleanedFilename';

export default (
  fileParameter: FlowParameterFileFieldsFragment | null | undefined,
): FilePointerParameterValue => {
  if (fileParameter == null) {
    return emptyFilePointerParameterValue();
  }

  if (fileParameter.__typename !== PARAMETER_VALUE_TYPE.FILE_POINTER) {
    throw new Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur: >>asFilePointerParameterValue with a non pointer type: ${
        fileParameter.__typename
      }`,
    );
  }

  return {
    type: PARAMETER_VALUE_TYPE.FILE_POINTER,
    variable: {
      name: fileParameter.variable,
      field: fileParameter.field
        ? {
            name: fileParameter.field,
          }
        : null,
    },
  };
};
