import React from 'react';

import { WithDelayedLoading } from '~/components/util/withDelayedLoading';

import NewOfficeForm from './NewOfficeForm';
import { InsertOfficeMutation } from '~/graphql';
import mutation from '~/graphql/mutation/InsertOffice';
import Drawer from '~/components/Drawer';
import withDelayedLoading from '~/components/util/withDelayedLoading';
import cleanedFilename from '~/util/cleanedFilename';

type MyProps = {
  accountId: string;

  /** Handler on new Office added */
  onOfficeAdded: () => void;

  /** When the sidebar has to hide */
  hide: () => void;

  /** If the drawer is showing */
  isShowing: boolean;
};
type Props = WithDelayedLoading & MyProps;

type State = {};
const text = {
  title: 'Nieuwe vestiging',
};
export class AddOfficeSidebar extends React.Component<Props, State> {
  onInsertSucces = () => {
    this.props.removeLoading();
    this.props.onOfficeAdded();
  };

  onErrorUpdate = () => {
    this.props.removeLoading();
  };

  render() {
    const { accountId, setLoading, hide, isShowing } = this.props;
    return (
      <React.Fragment>
        <Drawer active={isShowing} hideSidebar={hide} title={text.title}>
          <InsertOfficeMutation
            name={cleanedFilename(__filename)}
            mutation={mutation}
            onCompleted={this.onInsertSucces}
            onError={this.onErrorUpdate}
          >
            {(InsertOffice, { error: mutationError, loading }) => (
              <NewOfficeForm
                accountId={accountId}
                loading={this.props.loading || loading}
                onSubmit={variables => {
                  setLoading();

                  return InsertOffice({
                    variables,
                  });
                }}
                insertionError={mutationError}
              />
            )}
          </InsertOfficeMutation>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withDelayedLoading<MyProps>(AddOfficeSidebar);
