import styled, { css } from 'styled-components';
import React from 'react';
import { Link } from '~/components/Link';
import Icon from '~/components/Icon';
import { isNil } from 'ramda';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import { useLocation } from '@reach/router';

export type BreadcrumbType = {
  /** Makes the breadcrumb a link */
  to?: string;

  /** The label to display */
  label: string;
};

type Props = {
  breadcrumbs: Array<BreadcrumbType>;
};
const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  const viewingModeProps = useViewingModeProps();
  const location = useLocation();

  const isOnContacts = location.pathname.includes('contacts');

  return (
    <BreadcrumbContainer data-testid="breadcrumb-container">
      {breadcrumbs.map(({ to, label }, index) => (
        <React.Fragment key={label + index}>
          {!isNil(to) ? (
            <BreadcrumbLink
              key={label}
              to={to}
              dataTestid="breadcrumb-item-link"
              isactive={'false'}
            >
              {label}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbText
              key={label}
              data-testid="breadcrumb-item-text"
              isactive={'true'}
              data-redacted={isOnContacts && viewingModeProps['data-redacted']}
            >
              {label}
            </BreadcrumbText>
          )}

          {index !== breadcrumbs.length - 1 && (
            <ChevronElement name="chevron" key={`chevron-${index}`} />
          )}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};

const BreadcrumbContainer = styled.div<{}>`
  display: flex;
`;

const activeCss = css`
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BreadcrumbCss = css`
  white-space: nowrap;
`;

type BreadcrumbLinkType = { isactive: string };
const BreadcrumbLink = styled(Link)<BreadcrumbLinkType>`
  color: ${({ theme }) => theme.color('primary', 'light')};

  ${BreadcrumbCss}

  ${({ isactive }) => {
    if (isactive === 'true') {
      return activeCss;
    }
    return null;
  }}
`;

type BreadcrumbTextType = { isactive: string };
const BreadcrumbText = styled.span<BreadcrumbTextType>`
  ${BreadcrumbCss}

  ${({ isactive }) => {
    if (isactive === 'true') {
      return activeCss;
    }

    return null;
  }}
`;

const ChevronElement = styled(Icon)<{}>`
  transform: rotate(-90deg);
  margin: 0 1em;
`;

export default Breadcrumbs;
