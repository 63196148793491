import { RouteComponentProps } from '@reach/router';
import MetaTags from 'react-helmet';
import React, { useEffect } from 'react';
import { useGetFlowV2Query } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DatHuisLoading from '~/components/DatHuisLoading';
import BuilderWithContext from '../BuilderWithContext';
import {
  RouterTransitionContextProps,
  withRouterTransitionContext,
} from '~/contexts/RouterTransitionContext';
import getFlowActionsToClientActions from '../../util/getFlowActionsToClientActions';
import { useSetRecoilState } from 'recoil';
import flowActions from '../../state';

type Props = RouterTransitionContextProps &
  RouteComponentProps<{ id: string }> & {};

const UpdateFlowBuilder: React.FC<Props> = ({ id }) => {
  const account = useCurrentAccount();
  const { data, loading } = useGetFlowV2Query({
    variables: {
      id: id as string,
      accountId: account.id,
    },
  });

  const setActions = useSetRecoilState(flowActions);

  useEffect(() => {
    if (data?.getFlowV2) {
      const clientActions = getFlowActionsToClientActions(
        data?.getFlowV2.Actions ?? [],
      );
      setActions(clientActions);
    }
  }, [data?.getFlowV2, setActions]);

  if (loading || !data?.getFlowV2 || !data.getFlowV2.id)
    return (
      <>
        <MetaTags>
          <title>Laden...</title>
        </MetaTags>
        <DatHuisLoading />
      </>
    );

  const initialSettings = {
    flowId: data.getFlowV2.id,
    flowName: data.getFlowV2.name,
    flowDescription: data.getFlowV2.description,
    enabled: data.getFlowV2.enabled,
    maximumFlowRun: data.getFlowV2.maximumFlowRun,
    actions: data.getFlowV2.Actions,
  };
  const {
    flowId,
    flowName,
    flowDescription,
    enabled,
    maximumFlowRun,
    actions,
  } = initialSettings;

  return (
    <BuilderWithContext
      flowId={flowId}
      initialFlow={{
        flowName,
        flowDescription,
        enabled,
        maximumFlowRun,
        actions,
      }}
    />
  );
};

export default withRouterTransitionContext(UpdateFlowBuilder);
