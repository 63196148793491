import { FlowConditionAppVBOWaardecheckReportNew } from './types.flow';

const asFlowConditionAppVBOWaardecheckReportNewProp = (): Omit<
  FlowConditionAppVBOWaardecheckReportNew,
  'id'
> => ({
  type: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New',
});

export default asFlowConditionAppVBOWaardecheckReportNewProp;
