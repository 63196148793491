import React from 'react';
import styled from 'styled-components';

import { FormApi } from 'final-form';

import Validation from '~/util/Validation';
import { InviteUsersToOfficeMutation } from '~/graphql';
import mutation from '~/graphql/mutation/InviteUsersToOffice';
import { Drawer, FormUtils } from '~/components';
import Button from '~/components/Button';
import { InputGroup, Input } from '~/components/Inputs';
import Dropdown from '~/components/Dropdown';
import { Form, Field, EmailField } from '~/components/Forms';
import withDelayedLoading, {
  WithDelayedLoading,
} from '~/components/util/withDelayedLoading';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import Disclaimer from '~/components/Disclaimer';
import { OfficeOptions } from '~/hooks/useOfficeOptions';

type FormData = {
  email: string | null | undefined;
  officeId: string | null | undefined;
};

type MyProps = {
  officeOptions: OfficeOptions;
  accountId: string;

  /** Called when the user is successfully added */
  onUserAdded?: () => void;
  isShowing: boolean;
  hide: () => void;
};
type Props = WithDelayedLoading & MyProps;

type State = {
  showSidebar: boolean;
  responseError: boolean;
};

export const text = {
  emailLabel: Catalog.emailLabel,
  officeLabel: Catalog.officeLabel,
  addMore: 'voeg meer toe',
  submit: 'Uitnodigen',
  noEmailEntered: Catalog.noEmail,
  noOfficeSelected: Catalog.noOffice,
  invalidEmail: Catalog.invalidEmail,
  generalErrorMsg: Catalog.genericUnknownErrorMessage,
  asideTitle: 'Gebruiker toevoegen',
  asidePara1: 'Voeg hier collegas toe aan het system.',
  asidePara2:
    'Admins kunnen ook gebruikers toevoegen en wijzigingen aanbrengen in bijvoorbeeld de apps & vestigingen.',
  disclaimer: Catalog.mayIncreaseCostsWarning('Gebruikers'),
};

class AddUserSidebar extends React.Component<Props, State> {
  state = {
    showSidebar: false,
    responseError: false,
  };

  onCompleted = (result: $Object) => {
    this.props.removeLoading();
    const anyError =
      result.inviteUsersToOffice.length && result.inviteUsersToOffice[0].error;

    if (anyError) {
      this.setState({ responseError: true });
    } else {
      this.props.hide();
    }
  };

  onError = () => {
    this.setState({ responseError: true });
    this.props.removeLoading();
  };

  validate = (fields: FormData) => {
    const errors: FormData = { email: undefined, officeId: undefined };

    if (Validation.String.isEmpty(fields.email))
      errors.email = text.noEmailEntered;
    if (Validation.String.isEmpty(fields.officeId))
      errors.officeId = text.noOfficeSelected;
    if (fields.email && !Validation.Email.isValid(fields.email))
      errors.email = text.invalidEmail;

    return errors;
  };

  render() {
    const { officeOptions, accountId, onUserAdded, isShowing, hide } =
      this.props;
    const { responseError } = this.state;

    return (
      <React.Fragment>
        <Drawer active={isShowing} hideSidebar={hide} title={text.asideTitle}>
          <p>{text.asidePara1}</p>
          <p>{text.asidePara2}</p>

          <InviteUsersToOfficeMutation
            name={cleanedFilename(__filename)}
            mutation={mutation}
            onCompleted={(result: $Object) => {
              this.onCompleted(result);
              if (onUserAdded) onUserAdded();
            }}
            onError={this.onError}
          >
            {(inviteUsersToOffice, { error: mutationError, loading }) => {
              const handleSubmit = (
                formData: FormData,
                form: FormApi<FormData>,
              ) => {
                const officeId = formData.officeId ? formData.officeId : '';
                const users = formData.email
                  ? [formData.email.toLocaleLowerCase()]
                  : [];

                return inviteUsersToOffice({
                  variables: {
                    accountId,
                    officeId,
                    users,
                  },
                }).then(() => {
                  const fieldNames = Object.keys(formData) as Array<
                    keyof typeof formData
                  >;
                  fieldNames.forEach(name => {
                    form.change(name, null);
                    form.resetFieldState(name);
                  });
                });
                this.props.setLoading();
              };

              return (
                <Form
                  onSubmit={handleSubmit}
                  validate={this.validate}
                  initialValues={{
                    email: null,
                    officeId:
                      officeOptions.length === 1
                        ? officeOptions[0]?.payload?.id
                        : null,
                  }}
                  data-testid="add-user-form"
                >
                  {({ handleSubmit, submitError, submitting, pristine }) => {
                    const newUserError =
                      mutationError || submitError || responseError
                        ? text.generalErrorMsg
                        : false;

                    return (
                      <form onSubmit={handleSubmit} data-testid="add-user-form">
                        {newUserError && (
                          <ErrorMsg data-testid="add-user-error">
                            {newUserError}
                          </ErrorMsg>
                        )}

                        <InvitationBlock>
                          <InputGroup>
                            <EmailField name="email">
                              {({ input, meta: { error, touched } }) => (
                                <Input
                                  large
                                  label={text.emailLabel}
                                  error={FormUtils.showError(error, touched)}
                                  {...input}
                                  data-testid="user-email-input"
                                  autoComplete="new-email"
                                  disabled={loading}
                                />
                              )}
                            </EmailField>
                          </InputGroup>

                          <InputGroup>
                            <Field name="officeId">
                              {({ input, meta: { error, touched } }) => (
                                <Dropdown
                                  name="office"
                                  dataTestId="office-dropdown"
                                  label={text.officeLabel}
                                  size="large"
                                  options={officeOptions}
                                  selectedOptionIdx={officeOptions.findIndex(
                                    option => {
                                      const nextOfficeId =
                                        option.payload?.id ?? null;
                                      return nextOfficeId === input.value;
                                    },
                                  )}
                                  error={FormUtils.showError(error, touched)}
                                  {...input}
                                  onChange={({ option }) =>
                                    input.onChange(option.payload.id)
                                  }
                                  disabled={loading}
                                  required
                                />
                              )}
                            </Field>
                          </InputGroup>
                        </InvitationBlock>

                        <ActionWrapper>
                          <Button
                            size="large"
                            loading={this.props.loading}
                            onClick={handleSubmit}
                            appearance="secondary"
                            type="submit"
                            disabled={submitting || pristine || loading}
                            data-testid="add-new-user-submit"
                            label={text.submit}
                          />
                        </ActionWrapper>
                        <Disclaimer>{text.disclaimer}</Disclaimer>
                      </form>
                    );
                  }}
                </Form>
              );
            }}
          </InviteUsersToOfficeMutation>
        </Drawer>
      </React.Fragment>
    );
  }
}

const ActionWrapper = styled.div<{}>`
  display: flex;
  align-items: center;

  & button {
    margin-left: auto;
  }
`;

const InvitationBlock = styled.section<{}>`
  padding-bottom: 1em;

  & + section {
    ${({ theme }) => `
      border-top: 1px solid ${theme.color('grey', 'light')};
    `};
  }
`;

const ErrorMsg = styled.div<{}>`
  ${({ theme }) => `
    color: ${theme.color('danger')};
  `};
`;

export default withDelayedLoading<MyProps>(AddUserSidebar);
