import React from 'react';
import { EdgeProps, getSmoothStepPath } from 'reactflow';
import { useRecoilValue } from 'recoil';

import AnimatedPath from '../AnimatedPath';
import { ClientFlowAction } from '~/graphql/types.client';
import { shouldDisable } from '~/scenes/Automation/v2/state/interactions';
import { MARKER_END_DISABLED } from '../../../MarkerDefinitions';

export type EdgeData = {
  action: ClientFlowAction;
  parentNode: ClientFlowAction;
  id: string;
  parentId: string;
};

export const EDGE_BORDER_RADIUS = 20;

export type Props = EdgeProps<EdgeData> & {};

const BaseEdge: React.FC<Props> = React.memo(
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
  }) => {
    const edgePath = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      borderRadius: EDGE_BORDER_RADIUS,
    });

    const disabled = useRecoilValue(shouldDisable);

    return (
      <AnimatedPath
        id={id}
        style={{
          ...style,
          strokeWidth: 2,
          pointerEvents: 'none',
          opacity: disabled ? 0.3 : 1,
          transition: 'opacity 0.5s ease-out',
        }}
        className="react-flow__edge-path"
        d={edgePath[0]}
        markerEnd={disabled ? MARKER_END_DISABLED : markerEnd}
      />
    );
  },
);

export default BaseEdge;
