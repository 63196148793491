import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/Button';
import Input from '~/components/Input';
import Dropdown from '~/components/Dropdown';
import type { SelectedOptionOf } from '~/components/Inputs/Dropdown';
import JustificationContainer from '~/components/JustificationContainer';
import FormContainer from '~/components/ModalsV2/FormContainer';
import { withOverlay } from '~/components/ModalsV2/Overlay';
import TextButton from '~/components/TextButton';
import type { AppStatus_Trustoo_Token, Office } from '~/graphql/types';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  token: AppStatus_Trustoo_Token;
  onClose: () => void;
  onSave: (token: AppStatus_Trustoo_Token) => void;
  loading: boolean;
};

const text = {
  formHeader: 'Wijzigen eigenaar van dit Trustoo account',
  cancel: 'Afbreken',
  save: 'Opslaan',
  token: 'Trustoo token',
  changeOffice: 'Eigenaar van dit Trustoo account',
};

const EditTokenModal: React.FC<Props> = ({
  dataTestId,
  token,
  onClose,
  onSave,
  loading,
  ...rest
}) => {
  const officeOptions = useOfficeOptions();

  const [newOfficeId, setOfficeId] = useState<string>(token.officeId);
  const saveIsDisabled = token.officeId === newOfficeId;

  return (
    <Container data-testid={dataTestId} {...rest}>
      <StyledFormContainer header={text.formHeader} onClose={onClose}>
        <StyledInput
          label={text.token}
          value={token.token}
          name="token"
          disabled={true}
        />
        <Dropdown
          label={text.changeOffice}
          dataTestId={TEST_ID.OFFICE_DROPDOWN}
          options={officeOptions}
          selectedOptionIdx={officeOptions.findIndex(
            office => office.payload?.id === newOfficeId,
          )}
          onChange={({ option }: SelectedOptionOf<Office>) =>
            setOfficeId(option.payload.id)
          }
        />

        <JustificationContainer
          justification="space-between"
          align="center"
          margin={['l', null, null]}
        >
          <TextButton
            label={text.cancel}
            onClick={onClose}
            withoutPadding
            appearance="danger"
            type="button"
            dataTestId={TEST_ID.CANCEL}
          />

          <Button
            dataTestId={TEST_ID.SAVE}
            icon="check"
            label={text.save}
            type="submit"
            disabled={saveIsDisabled}
            appearance="secondary"
            loading={loading}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              const updatedToken = { ...token, officeId: newOfficeId };
              onSave(updatedToken);
            }}
          />
        </JustificationContainer>
      </StyledFormContainer>
    </Container>
  );
};

const StyledFormContainer = styled(FormContainer)<{}>`
  min-height: max-content;
`;

const StyledInput = styled(Input)<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `,
);

const Container = styled.form<{}>`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default withOverlay(EditTokenModal);
