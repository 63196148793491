import { sortBy, prop } from 'ramda';
import { ViewableOffice } from '~/contexts/AccountContext';
import { OfficeInList } from '~/scenes/Settings/Profile/components/FormContainer';
import { ListItemForDeletion } from '~/components/Modals/components/ListItemCard';
import { ExpandedUsers } from '~/hooks/useUsers';

export const filterUsers = (
  users: ExpandedUsers,
  filter: string,
): ExpandedUsers => {
  if (!users.length) return [];
  const filterLwCase = filter.toLowerCase();

  return users.filter(
    user =>
      !filter ||
      ('name' in user && user.name.toLowerCase().includes(filterLwCase)) ||
      ('phone' in user &&
        user.phone &&
        user.phone.toLowerCase().includes(filterLwCase)) ||
      (user.email && user.email.toLowerCase().includes(filterLwCase)),
  );
};

const _sortByName = sortBy(prop('name'));
const _sortByEmail = sortBy(prop('email'));
export const sortByName = (users: ExpandedUsers): ExpandedUsers => {
  const usersWithoutNames = users.filter(
    user =>
      user.__typename === 'PendingUser' ||
      user.name === '' ||
      user.name === user.email,
  );
  const usersWithNames = users.filter(
    user => 'name' in user && user.name !== user.email && user.name !== '',
  );

  return [..._sortByName(usersWithNames), ..._sortByEmail(usersWithoutNames)];
};

export const convertOfficeInListToListItemForDeletion = (
  office: OfficeInList,
): ListItemForDeletion => ({
  id: office.id,
  name: office.name,
  img: office.img,
});

export const convertViewableUserOfficeToListItemForDeletion = (
  office: ViewableOffice,
): ListItemForDeletion => ({
  id: office.id,
  name: office.name,
  img: office.img,
});
