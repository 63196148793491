import gql from 'graphql-tag';

export default gql`
  fragment SignatureFields on Signature {
    html
    attachments {
      file {
        s3key
        filename
        contentLength
        contentType
      }
      url
      inlineId
    }
  }
`;
