import { InlineImageEditorItem } from '~/components/HTMLEditor/HTMLEditor';
import { EmailEventAttachment } from '~/components/HTMLEditor/types.flow';

import { getAttributeValue } from '~/components/HTMLEditor/util/variableHTML';
import cleanedFilename from '~/util/cleanedFilename';
import { IMG_REGEX } from '~/components/HTMLEditor/constants';

const SRC_REGEX = /src="[^"]*?"/g;
export type ReturnType = {
  html: string;
  inlineAttachments: Array<EmailEventAttachment>;
  isStillUploading: boolean;
};
const convertFroalaToHtmlWithEventInlineAttachments = (
  body: string,
  inlineImageList: Array<InlineImageEditorItem>,
): ReturnType => {
  let isStillUploading = false;

  if (!Array.isArray(inlineImageList)) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | inlineImageList (${inlineImageList}) is not an array!`,
    );
  }
  const inlineAttachments: ReturnType['inlineAttachments'] = [];

  const convertedHtml = body.replace(IMG_REGEX, matchedValue => {
    const id = getAttributeValue('data-uploadid', matchedValue);

    const inlineImage = inlineImageList.find(image => image.id === id);
    if (inlineImage) {
      if (inlineImage.fileDetails == null) {
        isStillUploading = true;
      } else {
        const { filename, s3key, contentLength } = inlineImage.fileDetails;
        const id = inlineImage.id;
        if (id == null || filename == null || s3key == null) {
          throw Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | cannot convert inline image to event (filename: ${
              filename || 'null'
            } | s3key: ${s3key || 'null'} | inlineId: ${
              id || 'null'
            } | contentLength: ${contentLength || 'null'})`,
          );
        }

        if (inlineAttachments.find(link => link.inlineId === id) == null) {
          inlineAttachments.push({
            s3key,
            filename,
            inlineId: id,
            contentLength,
          });
        }

        const removedFileUploadId = matchedValue.replace(
          /data-uploadid="[^"]*"\s/g,
          '',
        );

        return removedFileUploadId.replace(
          SRC_REGEX,
          () => `src="[[inlineId:${id}]]"`,
        );
      }
    }

    // not an inline image, so return without changes
    return matchedValue;
  });

  return {
    html: convertedHtml,
    inlineAttachments,
    isStillUploading,
  };
};

export default convertFroalaToHtmlWithEventInlineAttachments;
