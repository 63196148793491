import React from 'react';
import styled from 'styled-components';

import { MarginlessH3 } from '../Typography';
import { getModalBorderRadius } from './Modal';

type Props = {
  title: string;
};
const ModalHeader = ({ title }: Props) => (
  <HeaderContainer>
    <MarginlessH3>{title}</MarginlessH3>
  </HeaderContainer>
);

const HeaderContainer = styled.div<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => `
    padding: ${theme.space('s')} ${theme.space('l')};
    background-color: ${theme.color('white', 'dark')};
    border-top-left-radius: ${getModalBorderRadius(theme)};
    border-top-right-radius: ${getModalBorderRadius(theme)};
    margin-bottom: ${theme.space('m')};
  `};
`;

export default ModalHeader;
