import React from 'react';

import { FlowConditionEmailOpened } from './types.flow';

import { InputGroup } from '~/components/Inputs';
import EmailPointerParameterComponent from '../../FlowParameter/ParameterValue/Email/EmailPointerParameterValueComponent';
import FieldLabel from '~/scenes/Automation/Flows/Actions/FieldLabel';

const text = {
  emailLabel: 'Welke e-mail?',
};
type Props = {
  condition: FlowConditionEmailOpened;
  onChange: (newCondition: FlowConditionEmailOpened) => void;
};
const FlowConditionEmailOpenedComponent = ({ condition, onChange }: Props) => {
  const { email } = condition;

  return (
    <InputGroup>
      <FieldLabel>{text.emailLabel}</FieldLabel>
      <EmailPointerParameterComponent
        value={email}
        onChange={newValue => {
          onChange({
            ...condition,
            email: newValue,
          });
        }}
      />
    </InputGroup>
  );
};

export default FlowConditionEmailOpenedComponent;
