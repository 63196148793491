import React, { ReactElement } from 'react';

import { FlowConditionAppZapierDetails } from './types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { ConditionChoiceType } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';

import {
  SingleFlowConditionPrimitiveFieldComponent,
  MultipleFlowConditionPrimitiveFieldComponent,
  emptyFlowConditionStringField,
  emptyFlowConditionBooleanField,
  emptyFlowConditionNumberField,
} from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive';

import { InputGroup } from '~/components/Inputs';
import PointerComponent from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/PointerComponent';
import TEST_ID from './FlowConditionAppZapierDetailsComponent.testid';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { getStashVariablesInStash } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import ErrorLabel from '~/scenes/Automation/Flows/Actions/ErrorLabel';

import cleanedFilename from '~/util/cleanedFilename';
import { PointerVariable } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ConditionFieldProp } from '../Primitive/MultipleFlowConditionPrimitiveFieldComponent';
import { FIELD_TYPE } from '../../constants';
import { ConditionField } from '../Primitive/types.flow';
import { FlowVariableStashItem } from '~/scenes/Automation/Flows/types.flow';
import { FlowOutputType } from '~/graphql/types';
import { Loading } from '~/components';

const text = {
  noPossibilities: 'Voeg eerst een inkomende koppeling toe aan deze flow',
  variableLabel: 'Selecteer een inkomende koppeling',
  noFieldOptions: 'Kon geen velden voor deze inkomende koppeling',
};
type MyProps = {
  condition: FlowConditionAppZapierDetails;
  conditionChoiceType?: ConditionChoiceType | null | undefined;
  onChange: (
    newFlowCondition: Omit<FlowConditionAppZapierDetails, 'fields'> & {
      fields: Array<ConditionFieldProp>;
    },
  ) => void;
  announceChanges: () => void;
  outputLoading: boolean;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
};
class FlowConditionAppZapierDetailsComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  possibleAppZapierEvents = () => {
    const { baseActionContext } = this.props;
    const { variableStash } = baseActionContext;
    return getStashVariablesInStash(
      variableStash,
      FLOW_OUTPUT_TYPE.FlowOutputEventContactAppZapier,
    );
  };

  validate = () => {
    // The pointer component will check if there is a selection and if that selction is correct
    if (this.possibleAppZapierEvents().length === 0) {
      return false;
    }

    return true;
  };

  render() {
    const {
      condition,
      onChange,
      conditionChoiceType,
      announceChanges,
      outputLoading,
    } = this.props;
    const { event, fields, allShouldBeTrue } = condition;

    if (outputLoading) return <Loading />;

    const possibleAppZapierEvents = this.possibleAppZapierEvents();

    if (possibleAppZapierEvents.length === 0) {
      return (
        <InputGroup>
          <ErrorLabel>{text.noPossibilities}</ErrorLabel>
        </InputGroup>
      );
    }

    if (event.variable == null) {
      return (
        <>
          <InputGroup data-testid={TEST_ID.EVENT_CONTAINER}>
            <PointerComponent
              variable={event.variable}
              hideVariableDropdownIfOnlyOneOptionAndSelected
              hideFieldDropdownIfOnlyOneOptionAndSelected
              variableLabel={text.variableLabel}
              possibleVariables={possibleAppZapierEvents}
              fieldType={FLOW_OUTPUT_TYPE.FlowOutputEventContactAppZapier}
              onChange={newPointerVariable => {
                const newValue = {
                  ...condition,
                  event: {
                    ...condition.event,
                    variable: newPointerVariable,
                  },
                };

                onChange(newValue);
              }}
            />
          </InputGroup>
        </>
      );
    }

    const selectedVariableIndex = findVariableIndexIn(
      possibleAppZapierEvents,
      event.variable,
    );
    const selectedVariable = possibleAppZapierEvents[selectedVariableIndex];

    const output = selectedVariable.outputObjects[0];

    const fieldOptions =
      output.fields?.map(field => ({
        name: field.name,
        label: field.label,
        type: (field.outputType === FlowOutputType.FlowOutputString
          ? FIELD_TYPE.STRING
          : field.outputType === FlowOutputType.FlowOutputNumber
          ? FIELD_TYPE.NUMBER
          : FIELD_TYPE.BOOLEAN) as
          | FIELD_TYPE.STRING
          | FIELD_TYPE.NUMBER
          | FIELD_TYPE.BOOLEAN,
      })) ?? [];

    // If the connected event has been removed, there will be no field options
    if (fieldOptions.length === 0) {
      return (
        <InputGroup>
          <ErrorLabel>{text.noFieldOptions}</ErrorLabel>
        </InputGroup>
      );
    }

    const fieldsWithLabel = fields.map(
      field =>
        ({
          ...field,
          label: fieldOptions.find(
            fieldOption => fieldOption.name === field.name,
          )?.label,
        } as ConditionFieldProp),
    );

    let fieldComponent: ReactElement | null = null;
    const onlyAllowOneField = conditionChoiceType === 'START';

    if (onlyAllowOneField) {
      if (fields.length > 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | more than 1 field (${JSON.stringify(
            fields,
            null,
            2,
          )}) given to a startcondition `,
        );
      }

      if (fields.length === 0) {
        onChange({
          ...condition,
          fields: [],
        });

        return null;
      }

      fieldComponent = (
        <SingleFlowConditionPrimitiveFieldComponent
          field={fieldsWithLabel[0]}
          fieldOptions={fieldOptions}
          onChange={newField => {
            onChange({
              ...condition,
              fields: [newField],
            });
          }}
        />
      );
    } else {
      const fieldOption = fieldOptions[0];

      let initialField: ConditionField | undefined;
      switch (fieldOption.type) {
        case FIELD_TYPE.STRING:
          initialField = emptyFlowConditionStringField(fieldOptions[0]);
          break;
        case FIELD_TYPE.NUMBER:
          initialField = emptyFlowConditionNumberField(fieldOptions[0]);
          break;
        case FIELD_TYPE.BOOLEAN:
          initialField = emptyFlowConditionBooleanField(fieldOptions[0]);
          break;
      }
      fieldComponent = (
        <MultipleFlowConditionPrimitiveFieldComponent
          initialField={fields.length === 0 ? initialField : fields[0]}
          fields={fieldsWithLabel}
          fieldOptions={fieldOptions}
          onChange={newFields => {
            onChange({
              ...condition,
              fields: newFields,
            });
          }}
          allShouldBeTrue={allShouldBeTrue}
          onAllShouldBeTrueChange={newAllShouldBeTrue => {
            onChange({
              ...condition,
              allShouldBeTrue: newAllShouldBeTrue,
            });
          }}
          announceChanges={announceChanges}
        />
      );
    }

    return (
      <>
        <InputGroup data-testid={TEST_ID.EVENT_CONTAINER}>
          <PointerComponent
            variable={event.variable}
            hideVariableDropdownIfOnlyOneOptionAndSelected
            hideFieldDropdownIfOnlyOneOptionAndSelected
            variableLabel={text.variableLabel}
            possibleVariables={possibleAppZapierEvents}
            fieldType={FLOW_OUTPUT_TYPE.FlowOutputEventContactAppZapier}
            onChange={newPointerVariable => {
              const newValue = {
                ...condition,
                event: {
                  ...condition.event,
                  variable: newPointerVariable,
                },
              };

              onChange(newValue);
            }}
          />
        </InputGroup>
        <div data-testid={TEST_ID.FIELD_CONTAINER}>{fieldComponent}</div>
      </>
    );
  }
}

const findVariableIndexIn = (
  possibleVariables: Array<FlowVariableStashItem>,
  variable: PointerVariable | null,
): number => {
  if (variable == null) return -1;

  return possibleVariables.findIndex(
    possibleVariable => possibleVariable.variableName === variable.name,
  );
};

export default withBaseActionContext<MyProps>(
  FlowConditionAppZapierDetailsComponent,
);
