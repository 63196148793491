import { FlowParameterEventFieldsFragment } from '~/graphql/types';
import { ValuationReportPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';

import { PARAMETER_VALUE_TYPE } from '../../constants';
import emptyValuationReportEventParameterValue from './emptyValuationReportEventParameterValue';

export default (
  eventValuationReportParameter:
    | Extract<
        FlowParameterEventFieldsFragment,
        {
          __typename: 'Flow_Parameter_Event_Contact_AppValuationReport_Pointer';
        }
      >
    | null
    | undefined,
): ValuationReportPointerParameterValue => {
  if (eventValuationReportParameter == null) {
    return emptyValuationReportEventParameterValue();
  }
  switch (eventValuationReportParameter.__typename) {
    case 'Flow_Parameter_Event_Contact_AppValuationReport_Pointer':
      return {
        type: PARAMETER_VALUE_TYPE.EVENT_VALUATIONREPORT_POINTER,
        variable: {
          name: eventValuationReportParameter.variable,
          field: eventValuationReportParameter.field
            ? {
                name: eventValuationReportParameter.field,
              }
            : null,
        },
      };

    default:
      return assertNever(
        eventValuationReportParameter.__typename,
        cleanedFilename(__filename),
      );
  }
};
