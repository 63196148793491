import React, { ReactElement } from 'react';

import { FlowConditionAppBBWaardecheckAppraisalRequestDetails } from './types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { ConditionChoiceType } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';

import {
  SingleFlowConditionPrimitiveFieldComponent,
  MultipleFlowConditionPrimitiveFieldComponent,
  emptyFlowConditionStringField,
} from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/Primitive';
import {
  FLOW_CONDITION_BBWAARDECHECK_APPRAISALREQUEST_DETAILS_FIELDS,
  DEFAULT_FLOW_CONDITION_BBWAARDECHECK_APPRAISALREQUEST_DETAILS_FIELD,
} from './constants';
import { InputGroup } from '~/components/Inputs';
import PointerComponent from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/PointerComponent';
import TEST_ID from './FlowConditionAppBBWaardecheckAppraisalRequestDetailsComponent.testid';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { getStashVariablesInStash } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import ErrorLabel from '~/scenes/Automation/Flows/Actions/ErrorLabel';
import { FLOW_ACTION_TYPE } from '~/scenes/Automation/Flows/Actions/constants';
import cleanedFilename from '~/util/cleanedFilename';
import { TypedField } from '~/scenes/Automation/Flows/Actions/Base/types.flow';
import { ConditionFieldProp } from '../Primitive/MultipleFlowConditionPrimitiveFieldComponent';

const text = {
  noValueAppraisalRequestPossibilities:
    'Voeg eerst een brainbay waardecheck - waardebepaling toe aan deze flow',
  variableLabel: 'Selecteer brainbay waardecheck - waardebepaling',
};
type MyProps = {
  condition: FlowConditionAppBBWaardecheckAppraisalRequestDetails;
  conditionChoiceType?: ConditionChoiceType | null | undefined;
  onChange: (
    newFlowCondition: Omit<
      FlowConditionAppBBWaardecheckAppraisalRequestDetails,
      'fields'
    > & {
      fields: Array<ConditionFieldProp>;
    },
  ) => void;
  announceChanges: () => void;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
};
class FlowConditionAppBBWaardecheckAppraisalRequestDetailsComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  possibleBBWaardecheckAppraisalRequests = () => {
    const { baseActionContext } = this.props;
    const { variableStash } = baseActionContext;
    return getStashVariablesInStash(
      variableStash,
      FLOW_OUTPUT_TYPE.FlowOutputEventContactAppBbWaardecheckAppraisalRequest,
    );
  };

  validate = () => {
    // The pointer component will check if there is a selection and if that selction is correct
    if (this.possibleBBWaardecheckAppraisalRequests().length === 0) {
      return false;
    }

    return true;
  };

  render() {
    const {
      condition,
      onChange,
      baseActionContext,
      conditionChoiceType,
      announceChanges,
    } = this.props;
    const { fields, event, allShouldBeTrue } = condition;
    const { actionType } = baseActionContext;

    const possibleBBWaardecheckAppraisalRequests =
      this.possibleBBWaardecheckAppraisalRequests();

    if (possibleBBWaardecheckAppraisalRequests.length === 0) {
      return (
        <InputGroup>
          <ErrorLabel>{text.noValueAppraisalRequestPossibilities}</ErrorLabel>
        </InputGroup>
      );
    }

    const fieldOptions: Array<TypedField> = [];
    FLOW_CONDITION_BBWAARDECHECK_APPRAISALREQUEST_DETAILS_FIELDS.forEach(
      field => {
        if (
          field.showInStartAction === false &&
          actionType === FLOW_ACTION_TYPE.START
        ) {
          return;
        } else {
          fieldOptions.push(field);
        }
      },
    );

    let fieldComponent: ReactElement | null = null;
    const onlyAllowOneField = conditionChoiceType === 'START';
    if (onlyAllowOneField) {
      if (fields.length > 1) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | more than 1 field (${JSON.stringify(
            fields,
            null,
            2,
          )}) given to a startcondition `,
        );
      }

      if (fields.length === 0) {
        onChange({
          ...condition,
          fields: [
            emptyFlowConditionStringField(
              DEFAULT_FLOW_CONDITION_BBWAARDECHECK_APPRAISALREQUEST_DETAILS_FIELD,
            ),
          ],
        });

        return null;
      }

      fieldComponent = (
        <SingleFlowConditionPrimitiveFieldComponent
          field={fields[0]}
          fieldOptions={fieldOptions}
          onChange={newField => {
            onChange({
              ...condition,
              fields: [newField],
            });
          }}
        />
      );
    } else {
      fieldComponent = (
        <MultipleFlowConditionPrimitiveFieldComponent
          initialField={emptyFlowConditionStringField(
            DEFAULT_FLOW_CONDITION_BBWAARDECHECK_APPRAISALREQUEST_DETAILS_FIELD,
          )}
          fields={fields}
          fieldOptions={fieldOptions}
          onChange={newFields => {
            onChange({
              ...condition,
              fields: newFields,
            });
          }}
          allShouldBeTrue={allShouldBeTrue}
          onAllShouldBeTrueChange={newAllShouldBeTrue => {
            onChange({
              ...condition,
              allShouldBeTrue: newAllShouldBeTrue,
            });
          }}
          announceChanges={announceChanges}
        />
      );
    }

    return (
      <>
        <InputGroup data-testid={TEST_ID.EVENT_CONTAINER}>
          <PointerComponent
            variable={event.variable}
            hideVariableDropdownIfOnlyOneOptionAndSelected
            hideFieldDropdownIfOnlyOneOptionAndSelected
            variableLabel={text.variableLabel}
            possibleVariables={possibleBBWaardecheckAppraisalRequests}
            fieldType={
              FLOW_OUTPUT_TYPE.FlowOutputEventContactAppBbWaardecheckAppraisalRequest
            }
            onChange={newPointerVariable => {
              const newValue = {
                ...condition,
                event: {
                  ...condition.event,
                  variable: newPointerVariable,
                },
              };

              onChange(newValue);
            }}
          />
        </InputGroup>
        <div data-testid={TEST_ID.FIELD_CONTAINER}>{fieldComponent}</div>
      </>
    );
  }
}

export default withBaseActionContext<MyProps>(
  FlowConditionAppBBWaardecheckAppraisalRequestDetailsComponent,
);
