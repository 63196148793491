import React from 'react';

import MetaTags from 'react-meta-tags';

import FullPageInformation from '~/components/FullPageInformation';
import { navigate } from 'gatsby';

export const text = {
  pageTitle: 'Oeps!',
  title: 'Geen data gevonden',
  explanation:
    'Er is iets misgegaan bij het ophalen van de data van het account. Waarschijnlijk kan er geen verbinding gemaakt worden met de server. Probeer het nog een keer',
  goToLinkText: 'Terug naar dashboard',
};
type Props = {
  /** Alternate title, otherwise default will be used */
  title?: string;

  /** Alternate explanation, otherwise default will be used */
  explanation?: string;
};

const ErrorScreen = ({ explanation, title }: Props) => (
  <>
    <MetaTags>
      <title>{text.pageTitle}</title>
    </MetaTags>
    <FullPageInformation
      dataTestid={'full-page-error-screen'}
      title={title || text.title}
      explanation={explanation || text.explanation}
      goToLink={() => void navigate('/-/')}
      goToLinkText={text.goToLinkText}
    />
  </>
);

export default ErrorScreen;
