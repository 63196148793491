import React from 'react';

import { TaskListTaskFieldsFragment } from '~/graphql/types';
import { TaskListType } from '~/scenes/Tasks/util/taskListType';
import { Task } from './types';
import { ApolloError } from '@apollo/client';

import GetOfficeUserTasks from './GetOfficeUserTasks';
import GetMyTasks from './GetMyTasks';

export type TaskGetterChildProps = {
  loadMore: () => Promise<any>;
  hasMore: boolean;
  tasks: Array<Task>;
  onTaskChanged: (insertedOrUpdatedTask: TaskListTaskFieldsFragment) => void;
  error: ApolloError | null | undefined;
  networkStatus: number;
};
type Props = {
  accountId: string;
  selectedUserId: string | null;
  selectedOfficeId: string | null;
  selectedListType: TaskListType;
  children: (props: TaskGetterChildProps) => JSX.Element;
};
const TaskWithSubscriptionGetter = ({
  accountId,
  selectedOfficeId,
  selectedUserId,
  selectedListType,
  children,
}: Props) => {
  const isSpecificOfficeSelected = selectedOfficeId != null;
  const isMyTaskList = !isSpecificOfficeSelected;

  const baseTaskGetterProps = {
    accountId,
    filteredUserId: selectedUserId || '',
    selectedListType,
  };
  const withOfficeTaskGetterProps = {
    ...baseTaskGetterProps,
    filteredOfficeId: selectedOfficeId || '',
  };

  if (isMyTaskList) {
    return (
      <GetMyTasks {...baseTaskGetterProps}>
        {props => children(props)}
      </GetMyTasks>
    );
  }

  return (
    <GetOfficeUserTasks {...withOfficeTaskGetterProps}>
      {props => children(props)}
    </GetOfficeUserTasks>
  );
};

export default TaskWithSubscriptionGetter;
