import { TypedField } from './types.flow';
import { DescribeNodeType } from '~/scenes/Automation/Flows/Actions/util/descriptionStandards';

import { fieldLabelDescription } from '~/scenes/Automation/Flows/Actions/util';

const describeTypedField = (field: TypedField): DescribeNodeType => [
  'het veld ',
  fieldLabelDescription(field.label),
];

export default describeTypedField;
