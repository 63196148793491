import { unnest } from 'ramda';
import { useEffect, useState } from 'react';
import errorMessagesForAccountIssues from '~/contexts/util/errorMessagesForAccountIssues';
import errorMessagesForMailboxes from '~/contexts/util/errorMessagesForMailboxes';
import errorMessagesForSubscription from '~/contexts/util/errorMessagesForSubscription';
import {
  NotificationListItem,
  SubscriptionFieldsFragment,
} from '~/graphql/types';
import errorMessagesForWarningItems from './utils/errorMessagesForWarningItems';
import useSessionHydration from '../useSessionHydration';
import { generateHash } from '~/util/react';
import useOfficeLookup from '../useOfficeLookup';

export enum WARNING_TYPE {
  ERROR = 'Error',
  INFO = 'Info',
  WARNING = 'Warning',
}

export type Warning = {
  item: NotificationListItem;
  hash: string;
};

export type Warnings = Array<Warning>;

const useEntityWarnings = () => {
  const [{ account, mailboxes, warningList }] = useSessionHydration();
  const officeLookup = useOfficeLookup();

  const [warnings, setWarnings] = useState<Warnings>([]);

  useEffect(() => {
    const mailboxWarnings = errorMessagesForMailboxes(
      mailboxes ?? [],
      officeLookup,
    );
    const subscriptionWarnings =
      account && account.subscription
        ? errorMessagesForSubscription(
            account.subscription as SubscriptionFieldsFragment,
          )
        : [];
    const accountWarnings =
      account && account.issues
        ? errorMessagesForAccountIssues(account.issues)
        : [];
    const warningListWarnings = errorMessagesForWarningItems(warningList ?? []);

    const currentWarnings = unnest([
      mailboxWarnings,
      subscriptionWarnings,
      accountWarnings,
      warningListWarnings,
    ]);

    currentWarnings.forEach(
      warning => (warning.hash = generateHash(warning.item)),
    );

    setWarnings(currentWarnings);
    // Adding officeLookup in the dependency array causes an infinite loop so don't.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, mailboxes, warningList]);

  return warnings;
};

export default useEntityWarnings;
