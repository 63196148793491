import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'gatsby-link';

import Icon from '~/components/Icon';

type Props = {
  uri: string;
  text: {
    requestAddressTab: string;
    livingAreaTab: string;
    houseTypeTab: string;
    comparableObjectsTab: string;
    requestUserDetailsTab: string;
    finalisedReportTab: string;
    privacy: string;
    analytics: string;
    design: string;
  };
};

const SideNav = ({ uri, text }: Props) => (
  <Container>
    <ListItem>
      <StyledLink
        activeClassName="active"
        to={`${uri}`}
        data-testid="link-design"
      >
        <Icon name="layout" />
        <span>{text.design}</span>
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink
        activeClassName="active"
        to={`${uri}/address`}
        data-testid="link-address"
      >
        <Icon name="map-pin" />
        <span>{text.requestAddressTab}</span>
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink
        activeClassName="active"
        to={`${uri}/living-area`}
        data-testid="link-living-area"
      >
        <Icon name="maximize" />
        <span>{text.livingAreaTab}</span>
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink
        activeClassName="active"
        to={`${uri}/house-type`}
        data-testid="link-house-type"
      >
        <Icon name="home" />
        <span>{text.houseTypeTab}</span>
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink
        activeClassName="active"
        to={`${uri}/comparable-objects`}
        data-testid="link-comparable-objects"
      >
        <Icon name="grid" />
        <span>{text.comparableObjectsTab}</span>
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink
        activeClassName="active"
        to={`${uri}/request-user-details`}
        data-testid="link-request-user-details"
      >
        <Icon name="file-text" />
        <span>{text.requestUserDetailsTab}</span>
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink
        activeClassName="active"
        to={`${uri}/finalised-report`}
        data-testid="link-finalised-report"
      >
        <Icon name="clipboard" />
        <span>{text.finalisedReportTab}</span>
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink
        activeClassName="active"
        to={`${uri}/privacy`}
        data-testid="privacy"
      >
        <Icon name="key" />
        <span>{text.privacy}</span>
      </StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink
        activeClassName="active"
        to={`${uri}/analytics`}
        data-testid="analytics"
      >
        <Icon name="bar-chart" />
        <span>{text.analytics}</span>
      </StyledLink>
    </ListItem>
  </Container>
);

const Container = styled.ul<{}>`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const StyledLink = styled(Link)<{}>(
  ({ theme }) => css`
    padding: ${[
      theme.space('xxs'),
      theme.space('xxl'),
      theme.space('xxs'),
      theme.space('s'),
    ].join(' ')};
    margin-bottom: ${theme.space('s')};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 4px solid transparent;
    color: ${theme.color('text')};
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    text-decoration: none;

    & > span {
      margin-left: ${theme.space('s')};
    }

    & > svg {
      font-size: 1.5em;
    }

    &.active {
      color: ${theme.color('info')};
      border-left: 4px solid ${theme.color('info')};
    }
  `,
);

const ListItem = styled.li<{}>`
  & svg {
    font-size: 1.5em;
  }
`;

export default SideNav;
