import DotMenu from './DotMenu';
import DoubleChevron from './DoubleChevron';
import Funda from './Funda';
import FontSize from './FontSize';
import HouseAccepted from './HouseAccepted';
import HouseRejected from './HouseRejected';
import IncomingLink from './IncomingLink';
import Indent from './Indent';
import LeadScoreChange from './LeadScoreChange';
import Loading from './Loading';
import NumberedList from './NumberedList';
import Office from './Office';
import Outdent from './Outdent';
import Redo from './Redo';
import Reply from './Reply';
import Spinner from './Spinner';
import TextColor from './TextColor';
import TextBackground from './TextBackground';
import Quote from './Quote';
import Undo from './Undo';
import {
  AlertTriangle,
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  ArrowLeft,
  ArrowRight,
  ArrowDownCircle,
  ArrowRightCircle,
  ArrowUp,
  BarChart,
  Bold,
  Briefcase,
  Calendar,
  Camera,
  Check,
  CheckSquare,
  ChevronDown,
  Clipboard,
  Clock,
  Copy,
  CreditCard,
  Delete,
  DollarSign,
  Edit,
  Edit3,
  ExternalLink,
  Eye,
  File,
  FileText,
  Flag,
  GitPullRequest,
  Grid,
  HelpCircle,
  Home,
  Image,
  Info,
  Italic,
  Key,
  Layers,
  Layout,
  Link,
  List,
  Lock,
  LogOut,
  Mail,
  Map,
  MapPin,
  Maximize,
  Menu,
  Minimize,
  Minimize2,
  Minus,
  Monitor,
  Phone,
  Play,
  Plus,
  RefreshCcw,
  Repeat,
  Save,
  Search,
  Send,
  Settings,
  Slash,
  Sliders,
  Star,
  Square,
  Tag,
  Trash2,
  Triangle,
  Type,
  Underline,
  Upload,
  User,
  UserPlus,
  Users,
  X,
  MinusSquare,
  PlusSquare,
  ChevronUp,
} from 'react-feather';
import IncomingUser from './IncomingUser';
import OutgoingUser from './OutgoingUser';
import Branches from '../components/Branches';
import RemoveStyle from './RemoveStyle';
import HtmlTag from './HtmlTag';
import Hypotheekbond from '~/components/Logo/components/Hypotheekbond';
import TrustooEmblem from '~/components/Logo/components/TrustooEmblem';
import Bank from './Bank';
import Pin from './Pin';

export type Props = React.SVGAttributes<SVGElement> & {
  color?: string;
};

const icons = {
  'align-center': AlignCenter,
  'align-justify': AlignJustify,
  'align-left': AlignLeft,
  'align-right': AlignRight,
  'arrow-down-circle': ArrowDownCircle,
  'arrow-right-circle': ArrowRightCircle,
  'bar-chart': BarChart,
  'check-square': CheckSquare,
  'chevron-up': ChevronUp,
  'credit-card': CreditCard,
  'dot-menu': DotMenu,
  'double-chevron': DoubleChevron,
  'file-text': FileText,
  'font-size': FontSize,
  'house-accepted': HouseAccepted,
  'house-rejected': HouseRejected,
  'html-tag': HtmlTag,
  'lead-score-change': LeadScoreChange,
  'lead-score': Star,
  'looking-glass': Search,
  'map-pin': MapPin,
  'numbered-list': NumberedList,
  'question-mark': HelpCircle,
  'remove-style': RemoveStyle,
  'sign-out': LogOut,
  'text-background': TextBackground,
  'text-color': TextColor,
  'user-plus': UserPlus,
  arrow: ArrowUp,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  bold: Bold,
  bank: Bank,
  branches: Branches,
  briefcase: Briefcase,
  calendar: Calendar,
  camera: Camera,
  check: Check,
  chevron: ChevronDown,
  clipboard: Clipboard,
  clock: Clock,
  close: X,
  copy: Copy,
  delete: Delete,
  desktop: Monitor,
  dollar: DollarSign,
  edit: Edit,
  error: Slash,
  exclamation: AlertTriangle,
  externalLink: ExternalLink,
  eye: Eye,
  file: File,
  flag: Flag,
  flow: GitPullRequest,
  funda: Funda,
  gear: Settings,
  grid: Grid,
  home: Home,
  hypotheekbond: Hypotheekbond,
  image: Image,
  incomingLink: IncomingLink,
  incomingUser: IncomingUser,
  indent: Indent,
  info: Info,
  italic: Italic,
  key: Key,
  layer: Layers,
  layout: Layout,
  link: Link,
  list: List,
  loading: Loading,
  lock: Lock,
  mail: Mail,
  map: Map,
  maximize: Maximize,
  menu: Menu,
  minimize: Minimize,
  minimize2: Minimize2,
  minus: Minus,
  minusSquare: MinusSquare,
  office: Office,
  outdent: Outdent,
  outgoingUser: OutgoingUser,
  pencil: Edit3,
  phone: Phone,
  pin: Pin,
  play: Play,
  plus: Plus,
  plusSquare: PlusSquare,
  quote: Quote,
  redo: Redo,
  reload: RefreshCcw,
  repeat: Repeat,
  reply: Reply,
  save: Save,
  send: Send,
  slash: Slash,
  sliders: Sliders,
  spinner: Spinner,
  star: Star,
  square: Square,
  tag: Tag,
  trashcan: Trash2,
  triangle: Triangle,
  trustoo: TrustooEmblem,
  type: Type,
  underline: Underline,
  undo: Undo,
  upload: Upload,
  user: User,
  users: Users,
};

export default icons;
