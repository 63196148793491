import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import SelectBlock from '~/components/SelectBlock';
import MasonryGrid from '~/components/MasonryGrid';

import Tooltip from '~/components/Tooltip';
import zapierTriggerTemplates, {
  ZapierTriggerTemplate,
  ZapierTriggerTemplates,
} from './zapierTriggerTemplates';
import TEST_ID from './index.testid';
import { isNil } from 'ramda';

export type Props = {
  dataTestId?: string;
  selectedTriggers: ZapierTriggerTemplates;
  onChange: (triggers: ZapierTriggerTemplates) => void;
  previouslyEnabledTriggers?: Array<string>;
};

const text = {
  tooltip:
    'Contactgegevens worden standaard aan iedere uitgaande koppeling toegevoegd.',
};

const TriggerTemplatesContainer: React.FC<Props> = ({
  dataTestId,
  selectedTriggers,
  onChange,
  previouslyEnabledTriggers,
  ...rest
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <SelectBlock
        type="checkbox"
        checked
        disabled
        checkboxTitle="Contactgegevens"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {showTooltip && <Tooltip>{text.tooltip}</Tooltip>}
      </SelectBlock>

      {zapierTriggerTemplates.map((trigger: ZapierTriggerTemplate) => {
        const wasPreviouslyEnabled =
          previouslyEnabledTriggers?.includes(trigger.id) ?? false;

        const isTriggerSelected =
          (selectedTriggers.some(selected => selected.id === trigger.id) ||
            previouslyEnabledTriggers?.includes(trigger.id)) ??
          false;

        return (
          <SelectBlock
            type="checkbox"
            disabled={!isNil(trigger.insertedId) || wasPreviouslyEnabled}
            checked={isTriggerSelected || wasPreviouslyEnabled}
            data-checked={isTriggerSelected}
            checkboxTitle={trigger.name}
            description={trigger.description}
            key={trigger.id}
            dataTestId={TEST_ID.TEMPLATE}
            onClick={() => {
              if (isTriggerSelected) {
                onChange(
                  selectedTriggers.filter(
                    selectedTrigger => selectedTrigger.id !== trigger.id,
                  ),
                );
              } else {
                onChange([...selectedTriggers, trigger]);
              }
            }}
          />
        );
      })}
    </Container>
  );
};

const Container = styled(MasonryGrid)<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
    margin-bottom: ${theme.space('base')};
    column-gap: ${theme.space('l')};
  `}
`;

export default TriggerTemplatesContainer;
