import {
  ApolloError,
  ApolloQueryResult,
  NetworkStatus,
  WatchQueryOptions,
} from '@apollo/client';
import { useMemo } from 'react';
import {
  Exact,
  GetAppStatusesQuery,
  useGetAppStatusesQuery,
} from '~/graphql/types';
import type { AppStatus, AppStatus__typename } from '~/graphql/types.client';
import apps from '~/scenes/Apps/appsConfig';
import type { AppConfig } from '~/scenes/Apps/types';
import useCurrentAccount from '../useCurrentAccount';

export type ExtendedAppConfig = AppConfig & {
  appStatus: AppStatus;
};

export type AppsHashMap = Record<AppStatus__typename, ExtendedAppConfig>;
export type ReturnType = {
  apps: AppsHashMap;
  error?: ApolloError;
  loading?: boolean;
  updateQuery: (
    mapFn: (
      previousQueryResult: GetAppStatusesQuery,
      options: Pick<
        WatchQueryOptions<
          Exact<{
            accountId: string;
          }>,
          GetAppStatusesQuery
        >,
        'variables'
      >,
    ) => GetAppStatusesQuery,
  ) => void;
  networkStatus: NetworkStatus;
  refetch: () => Promise<ApolloQueryResult<GetAppStatusesQuery>>;
};

/* We will be querying for apps from BE here.*/
/* For now it uses constant apps.*/

const appsToHashMap = (apps: Array<ExtendedAppConfig>): AppsHashMap =>
  apps.reduce((acc, curr) => {
    if (acc[curr.__typename]) {
      return acc;
    } else {
      acc[curr.__typename] = curr;
    }
    return acc;
  }, {} as AppsHashMap);

const useApps = (): ReturnType => {
  const { id: accountId } = useCurrentAccount();
  const { data, loading, error, updateQuery, networkStatus, refetch } =
    useGetAppStatusesQuery({
      variables: { accountId },
    });

  const appsWithStatus: Array<ExtendedAppConfig> = useMemo(() => {
    const _apps = apps
      .map(app => {
        const appStatus = data?.getAppStatuses.find(
          status => app.__typename === status.__typename,
        );

        if (!appStatus) return null;

        return {
          ...app,
          appStatus,
        };
      })
      // Also filter apps under feature flag here when needed
      .filter(
        app => app !== null && app.visibility !== 'hidden',
      ) as Array<ExtendedAppConfig>;

    return _apps;
  }, [data?.getAppStatuses]);

  const appsHashMap = useMemo(
    () => appsToHashMap(appsWithStatus),
    [appsWithStatus],
  );

  return {
    apps: appsHashMap,
    error,
    loading,
    networkStatus,
    updateQuery,
    refetch,
  };
};

export default useApps;
