import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';
import { DhImageElement, ImageElement } from '~/components/PluginsEditor/types';
import updateAttrsAndConvertToString from '~/components/PluginsEditor/utils/serialize/updateAttrsAndConvertToString';

const serialize = (node: ImageElement | DhImageElement): string => {
  if (node.pending || node.hasError) return '';

  const attrsToUpdate = {
    style: {
      display: node.display || 'unset',
      float: node.float || 'unset',
    },
    width: node.width,
  };

  if (node.type === ELEMENTS.DH_IMAGE) {
    if (!node.contentType?.startsWith('image')) return '';

    const attrsAsString = updateAttrsAndConvertToString(
      {
        ...attrsToUpdate,
        /** Make sure src is not duplicated */
        src: `[[inlineId:${node.inlineId}]]`,
      },
      node?.attrsToPassThrough,
    );

    return `<img${attrsAsString ? ` ${attrsAsString}` : ''}/>`;
  }

  const attrsAsString = updateAttrsAndConvertToString(
    {
      ...attrsToUpdate,
      /** Make sure src is not duplicated */
      src: node.url,
    },
    node?.attrsToPassThrough,
  );

  return `<img${attrsAsString ? ` ${attrsAsString}` : ''}/>`;
};

export default serialize;
