import { FlowParameterEventFieldsFragment } from '~/graphql/types';
import { ValuationRequestPointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { PARAMETER_VALUE_TYPE } from '../../constants';
import emptyValuationRequestEventParameterValue from './emptyValuationRequestEventParameterValue';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

export default (
  eventValuationRequestParameter:
    | Extract<
        FlowParameterEventFieldsFragment,
        {
          __typename: 'Flow_Parameter_Event_Contact_AppValuationRequest_Pointer';
        }
      >
    | null
    | undefined,
): ValuationRequestPointerParameterValue => {
  if (eventValuationRequestParameter == null) {
    return emptyValuationRequestEventParameterValue();
  }
  switch (eventValuationRequestParameter.__typename) {
    case 'Flow_Parameter_Event_Contact_AppValuationRequest_Pointer':
      return {
        type: PARAMETER_VALUE_TYPE.EVENT_VALUATIONREQUEST_POINTER,
        variable: {
          name: eventValuationRequestParameter.variable,
          field: eventValuationRequestParameter.field
            ? {
                name: eventValuationRequestParameter.field,
              }
            : null,
        },
      };

    default:
      return assertNever(
        eventValuationRequestParameter.__typename,
        cleanedFilename(__filename),
      );
  }
};
