import { ConditionChoiceType } from '../types.flow';
import { OptionOf } from '~/components/Inputs/Dropdown';
import { FlowConditionTypeOption } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/constants';

import getVisibleFlowConditionTypes from './getVisibleFlowConditionTypes';

const getConditionTypeOptions = (
  conditionChoiceType: ConditionChoiceType | null,
): Array<OptionOf<FlowConditionTypeOption>> => {
  let possibleConditions = getVisibleFlowConditionTypes();

  if (conditionChoiceType === 'START') {
    possibleConditions = possibleConditions.filter(
      condition => condition.showInStartAction,
    );
  } else if (conditionChoiceType == 'START_SUBCONDITION') {
    possibleConditions = possibleConditions.filter(
      condition => condition.showInStartSubConditionList,
    );
  } else if (conditionChoiceType == 'IF_ELSE_CONDITION') {
    possibleConditions = possibleConditions.filter(
      condition => condition.showInIfElseConditionList,
    );
  }

  return possibleConditions.map(conditionType => ({
    label:
      conditionChoiceType === 'START'
        ? conditionType.startConditionLabel
        : conditionType.label,
    payload: conditionType,
    key: conditionType.type,
  }));
};

export default getConditionTypeOptions;
