import React from 'react';
import styled, { css } from 'styled-components';

import Card from '~/components/Card';
import { useSpring, animated } from 'react-spring';
import { Heading5 } from '~/components/Typography/index';

type Props = {
  cardIcon: React.ReactNode;
  title: string;
  children: React.ReactNode;
  datatestId: string;
};

type CardHeaderProps = {
  cardIcon: React.ReactNode;
  title: string;
};

const ValueReportComponentCard = (props: Props) => {
  const { datatestId, cardIcon, title, children } = props;
  const animation = useSpring({
    from: { opacity: 0, transform: 'translate3d(-40px,0,0)' },
    to: { opacity: 1, transform: 'translate3d(0px,0,0)' },
    config: {
      mass: 1,
      tension: 110,
      friction: 14,
    },
  });
  return (
    <animated.div style={animation}>
      <CardContainer data-testid={datatestId}>
        <CardHeader cardIcon={cardIcon} title={title} />
        <CardBody>{children}</CardBody>
      </CardContainer>
    </animated.div>
  );
};

export default ValueReportComponentCard;

const CardHeader = (props: CardHeaderProps) => {
  const { cardIcon, title } = props;
  return (
    <Heading5>
      <CardHeaderElement>
        {cardIcon} &nbsp;&nbsp;
        {title}
      </CardHeaderElement>
    </Heading5>
  );
};

const CardHeaderElement = styled.div<{}>`
  display: flex;
  align-items: center;
  & svg {
    font-size: 1.3em;
  }

  ${({ theme }) => css`
    color: ${theme.color('text')};
    border-bottom: 1px solid ${theme.color('grey', 'light')};
    padding-bottom: ${theme.space('m')};
  `};
`;

const CardContainer = styled(Card)<{}>`
  flex-direction: column;
  justify-content: start;
`;

const CardBody = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('l')};
  `,
);
