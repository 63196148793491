import { OptionOf } from '~/components/Dropdown';
import { setTextStyle } from '~/components/PluginsEditor/commands/modify/text';
import { DHEditor } from '~/components/PluginsEditor/types';

const fontSizes: Array<number> = [14, 18, 24, 30, 36, 48, 60, 72, 96];

const getFontSizeOptions = (editor: DHEditor): Array<OptionOf<number>> =>
  fontSizes.map(item => ({
    key: item,
    label: item,
    payload: item,
    onClickAction: () => setTextStyle(editor, 'fontSize', `${item}px`),
  }));

export default getFontSizeOptions;
