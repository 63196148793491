import React from 'react';
import { Props } from '../';

const AvixEmblem: React.FC<Props> = ({ ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.3735 22.6743L14.0135 6.55372V6.442C13.0183 5.51311 11.7012 5.00703 10.34 5.03048C8.97887 5.05394 7.68001 5.6051 6.71738 6.56773C5.75475 7.53036 5.2036 8.82921 5.18014 10.1904C5.15668 11.5515 5.66276 12.8686 6.59165 13.8638L6.70338 13.9596L22.8239 30.3036H30.4533V22.6743H30.3735Z"
      fill="#0E5C59"
    />
    <path
      d="M33.1666 40.6463L49.4947 56.7669L49.6064 56.8786C50.5906 57.8607 51.9246 58.4116 53.315 58.4101C54.7053 58.4086 56.0382 57.8548 57.0203 56.8706C58.0024 55.8864 58.5532 54.5524 58.5517 53.162C58.5502 51.7717 57.9965 50.4388 57.0123 49.4568L56.9165 49.361L40.796 33.017H33.1666V40.6463Z"
      fill="#9AD2B1"
    />
    <path
      d="M40.7481 30.3036L56.8686 13.9596L56.9804 13.8638C57.9092 12.8686 58.4153 11.5515 58.3919 10.1904C58.3684 8.82921 57.8173 7.53036 56.8546 6.56773C55.892 5.6051 54.5931 5.05394 53.232 5.03048C51.8708 5.00703 50.5537 5.51311 49.5585 6.442L49.4628 6.55372L33.1666 22.6743V30.3036H40.7481Z"
      fill="#C94397"
    />
    <path
      d="M22.7441 33.017L6.63953 49.345L6.5278 49.4408C5.54361 50.4334 4.99406 51.7764 5.00005 53.1743C5.00603 54.5721 5.56707 55.9103 6.55973 56.8945C7.5524 57.8787 8.89537 58.4283 10.2932 58.4223C11.6911 58.4163 13.0293 57.8553 14.0135 56.8626L14.1093 56.7509L30.4533 40.6463V33.001H22.808L22.7441 33.017Z"
      fill="#05AB89"
    />
  </svg>
);

export default AvixEmblem;
