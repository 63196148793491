import React from 'react';

import { InsertAccountMutation, InsertOfficeMutation } from '~/graphql';
import mutationForInsert from '~/graphql/mutation/InsertAccount';
import mutationForInsertOffice from '~/graphql/mutation/InsertOffice';
import CompanyDetailsForm from './CompanyDetailsForm';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';

export type FormData = {
  company: string | null;
};
type Props = {
  afterSubmit: () => void;
};
const text = {
  noName: 'Voer de naam van jouw kantoor',
  errorMessage: Catalog.genericUnknownErrorMessage,
  defaultOfficeName: 'Hoofdvestiging',
};

const CompanyDetails = ({ afterSubmit }: Props) => {
  const validate = ({ company }: FormData) => {
    const errors: {
      company: string | undefined;
    } = {
      company: undefined,
    };

    if (Validation.String.isEmpty(company)) {
      errors.company = text.noName;
    }

    return errors;
  };

  return (
    <InsertAccountMutation
      name={cleanedFilename(__filename)}
      mutation={mutationForInsert}
    >
      {(insertAccount, { error, loading }) => (
        <InsertOfficeMutation
          name={cleanedFilename(__filename)}
          mutation={mutationForInsertOffice}
          onCompleted={() => {
            afterSubmit();
          }}
        >
          {(
            insertOffice,
            { error: insertOfficeError, loading: insertOfficeLoading },
          ) => {
            const submitError =
              error || insertOfficeError ? text.errorMessage : false;

            const handleSubmit = ({ company }: FormData) => {
              if (company === null) {
                throw Error(
                  `${cleanedFilename(
                    __filename,
                  )} | Should not occur | Company field is empty but user somehow submitted it`,
                );
              }

              return insertAccount({
                variables: {
                  account: {
                    name: company,
                  },
                },
              }).then(async result => {
                if (result && result.data) {
                  const { insertAccount } = result.data;

                  await insertOffice({
                    variables: {
                      accountId: insertAccount.id,
                      office: { name: text.defaultOfficeName },
                    },
                  });
                }
              });
            };

            return (
              <CompanyDetailsForm
                handleSubmit={handleSubmit}
                validate={validate}
                submitError={submitError}
                loading={loading || insertOfficeLoading}
                initialValues={{ company: null }}
              />
            );
          }}
        </InsertOfficeMutation>
      )}
    </InsertAccountMutation>
  );
};

export default CompanyDetails;
