import React from 'react';
import styled, { css } from 'styled-components';

import getColorForLeadscore from '~/util/getColorForLeadscore';
import JustificationContainer from '../JustificationContainer';
import TEST_ID from './index.testid';
type Props = {
  className?: string;
  leadScore: number;
};

const LeadScoreDisplayer = (props: Props) => {
  const { className, leadScore } = props;

  return (
    <JustificationContainer
      data-testid={TEST_ID.CONTAINER}
      className={className}
      align="center"
      justification="center"
    >
      <CircleContainer leadScoreToDisplay={leadScore}>
        <LeadScoreSpan data-testid={TEST_ID.SPAN}>{leadScore}</LeadScoreSpan>
      </CircleContainer>
    </JustificationContainer>
  );
};

type CircleContainerProps = {
  leadScoreToDisplay: number;
};

const CircleContainer = styled.div<CircleContainerProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;

  ${({ theme, leadScoreToDisplay }) => css`
    background-color: ${getColorForLeadscore(leadScoreToDisplay)};
    padding: ${theme.space('xxs')};
    font-size: ${theme.fontSize('s')};
    line-height: ${theme.lineHeight('m')};
  `};
`;

const LeadScoreSpan = styled.div<{}>(
  ({ theme }) => css`
    align-self: center;
    color: ${theme.color('white')};
    white-space: nowrap;
  `,
);

export default LeadScoreDisplayer;
