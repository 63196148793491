import { AppType } from '~/graphql/types';
import { AppConfig } from '../types';

const description = `## Omschrijving
Ben je een NVM Makelaar? Maak dan gebruik van de brainbay waardecheck. In samenwerking met brainbay, een dochterbedrijf van de NVM, heeft DatHuis deze app ontwikkeld.

De brainbay waardecheck gebruikt de meest recente transacties uit de Tiara uitwisseling om de waarde van een huis vast te stellen. Het rapport bevat recente transacties, inclusief koopsommen. Een grensverleggende manier om leads te genereren en klanten te informeren.

Nieuwe leads verwerk je heel makkelijk op een persoonlijke en relevante manier met flows. Gebruik hiervoor onze template of bouw je eigen flow.

## Installatie en kosten
Activeer de app pas het waarderapport aan je eigen huisstijl. Gebruik de beschikbare flow templates om de juiste opvolging voor nieuwe leads in te stellen.

Kom je er niet uit? Lees het artikel in de kennisbank of stuur ons een chatbericht.`;

const termsOfUse =
  'Voor het gebruik van deze app dien je lid te zijn van de NVM. Voor de toegang en het gebruik van transactiegegevens uit de Tiara uitwisseling gelden de algemene voorwaarden van het NVM lidmaatschap. Zodra je NVM lidmaatschap beëindigd, zal automatisch de toegang tot deze app worden ingetrokken. Door deze app te activeren ga je akkoord dat DatHuis periodiek hiervoor extra kosten in rekening brengt.';

export const bbwc: AppConfig = {
  __typename: 'AppStatus_BBWaardecheck',
  appType: AppType.BbWaardecheck,
  type: 'with_config',
  slug: 'bb-waardecheck',
  name: 'Waardecheck - Powered by Brainbay',
  tags: ['Leadgeneratie', 'E-mailcampagne', 'Informatievoorziening'],
  provider: 'DatHuis B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/Group%2098.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/Group%2098.svg',
  },
  price: {
    interval: {
      amount: 1,
      duration: 'month',
    },
    amount: 3000,
    currency: 'EUR',
  },
  description,
  termsOfUse,
  compatibility: 'Alleen beschikbaar voor leden van de NVM',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/5062936-over-de-brainbay-waardecheck',
  },
  setupFlow: 'oAuth',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'custom',
};
