import { IfElseAction, InexactIfElseActionFieldsFragment } from './types.flow';
import { BaseActionProps } from '~/scenes/Automation/Flows/Actions/baseTypes.flow';

import { FLOW_ACTION_TYPE } from '../constants';
import { asFlowConditionListProp } from '~/scenes/Automation/Flows/Actions/Base/FlowConditionList';
import { generateHash } from '~/util/react';
import { FlowCondition } from '../Base/FlowCondition/types.flow';

export default (
  item: InexactIfElseActionFieldsFragment,
  baseProps: BaseActionProps,
): IfElseAction => {
  const { id, condition, trueChildId, falseChildId } = item;

  const conditionListProp = asFlowConditionListProp(condition);

  return {
    ...baseProps,
    type: FLOW_ACTION_TYPE.IF_ELSE,
    id,
    conditionList: {
      ...conditionListProp,
      conditions: conditionListProp.conditions.map(
        (condition, idx) =>
          ({
            id: generateHash(`${JSON.stringify(condition)}-${idx}`),
            ...condition,
          } as FlowCondition),
      ),
    },
    yesPathChildId: trueChildId ?? null,
    noPathChildId: falseChildId ?? null,
    visiblePath: true,
  };
};
