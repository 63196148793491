import { useFlag } from '@unleash/proxy-client-react';

/**
 * List all flags created inside of Gitlab
 * @see https://gitlab.dathuis.nl/dh/sfa-app/-/feature_flags
 */
export type Flag = 'is-developer' | 'holiday-cheer' | 'pride-month';

const useDHFlag = (flag: Flag): boolean => {
  const enabled = useFlag(flag);

  return enabled;
};

export default useDHFlag;
