import { FlowParameterStringFieldsFragment } from '~/graphql/types';
import { StringParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import emptyStringPrimitiveParameterValue from './emptyStringPrimitiveParameterValue';
import asStringPrimitiveParameterValue from './asStringPrimitiveParameterValue';
import asStringPointerParameterValue from './asStringPointerParameterValue';
import cleanedFilename from '~/util/cleanedFilename';
import { assertNever } from '~/util/assertion';

export default (
  stringParameter: FlowParameterStringFieldsFragment | null | undefined,
): StringParameterValue => {
  if (stringParameter == null) {
    return emptyStringPrimitiveParameterValue();
  }
  switch (stringParameter.__typename) {
    case 'Flow_Parameter_String_Primitive':
      return asStringPrimitiveParameterValue(stringParameter);
    case 'Flow_Parameter_String_Pointer':
      return asStringPointerParameterValue(stringParameter);

    default:
      return assertNever(stringParameter, cleanedFilename(__filename));
  }
};
