import React from 'react';

import { Field, EmailField } from '~/components/Forms';
import { InputGroup, Input } from '~/components/Inputs';
import { FormUtils } from '~/components';
import FoldableCardBlock from '../FoldableCardBlock';
import useViewingModeProps from '~/hooks/useViewingModeProps';

type Props = {
  /** If the fields should be disabled */
  isFormDisabled: boolean;
};
const ContactDetailsBlock = ({ isFormDisabled }: Props) => {
  const viewingModeProps = useViewingModeProps();
  return (
    <FoldableCardBlock
      dataTestId="card-block-contact-details"
      icon={'PERSON'}
      title="Contactgegevens"
    >
      <InputGroup>
        <Field name="name">
          {({ input, meta: { error, touched } }) => (
            <Input
              label="Naam"
              type="text"
              error={FormUtils.showError(error, touched)}
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </Field>
      </InputGroup>
      <InputGroup>
        <Field name="phone">
          {({ input, meta: { error, touched } }) => (
            <Input
              label="Telefoonnummer"
              type="text"
              error={FormUtils.showError(error, touched)}
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </Field>
      </InputGroup>
      <InputGroup>
        <EmailField name="email">
          {({ input, meta: { error, touched } }) => (
            <Input
              label="E-mailadres"
              error={FormUtils.showError(error, touched)}
              disabled={true}
              {...viewingModeProps}
              {...input}
            />
          )}
        </EmailField>
      </InputGroup>
    </FoldableCardBlock>
  );
};

export default ContactDetailsBlock;
