import { isNil } from 'ramda';
import { SortDirection } from '~/graphql/types';
import {
  CONTACT_SORT_SETTINGS,
  FLOW_SORT_SETTINGS,
  getLocalStorageItem,
} from '~/util/localStorageKeys';
import { ParsedSettings, SortSettings, SortSettingsFor } from '../..';
import camelizeContactSortField from '../camelizeContactSortField';
import retrieveValidObject from '../retrieveValidObject';

/**
 * returns sortSettings from the localStorage or defaultSettings
 * @param {SortSettingsFor} sortSettingsFor - Local storage key
 * @param {SortSettingsFor} defaultSortSettings - createdDate, desc is the default. It can be set to another field.
 * keywords: sort, settings, sortSettings, direction, sortField, sortDirection
 */
export const defaultSettings: Record<SortSettingsFor, SortSettings> = {
  ['contactSortSettings']: {
    id: 'lastLeadActivity',
    desc: true,
  },
  ['flowSortSettings']: {
    id: 'createdDate',
    desc: true,
  },
  ['flowSortSettingsLegacy']: {
    id: 'createdDate',
    desc: true,
  },
};

const getSortSettings = (sortSettingsFor: SortSettingsFor) => {
  let sortSettings: SortSettings | null = null;

  if (sortSettingsFor === 'contactSortSettings') {
    const contactSortSettings = getLocalStorageItem(CONTACT_SORT_SETTINGS);
    const parsed = retrieveValidObject(contactSortSettings);

    !isNil(parsed) && (sortSettings = formatParsed(parsed, sortSettingsFor));
  }

  if (sortSettingsFor === 'flowSortSettings') {
    const flowSortSettings = getLocalStorageItem(FLOW_SORT_SETTINGS);
    const parsed = retrieveValidObject(flowSortSettings);

    !isNil(parsed) && (sortSettings = formatParsed(parsed, sortSettingsFor));
  }

  return sortSettings || defaultSettings[sortSettingsFor];
};

export const formatParsed = (
  parsed: ParsedSettings,
  sortSettingsFor: SortSettingsFor,
): SortSettings => ({
  id:
    sortSettingsFor === 'contactSortSettings'
      ? camelizeContactSortField(parsed.sortField)
      : parsed.sortField,
  desc: parsed.sortDirection === SortDirection.Desc,
});

export default getSortSettings;
