import {
  ClientFlowAction,
  ClientFlowActionSendEmailPlain,
} from '~/graphql/types.client';
import { RelativeMaps } from '~/scenes/Automation/v2/components/Builder/hooks/useRelativeMaps';
import { Issue } from '~/scenes/Automation/v2/state/flowIssues';
import getPathForPointer from '../../../../components/Selector/utils/getPathForPointer';

/**
 * Check if the pointer attachment is missing in the action
 *
 * keywords: pointer attachment validation
 */
const collectIssuesForPointerAttachments = (
  action: ClientFlowAction,
  attachments: ClientFlowActionSendEmailPlain['attachments'],
  relativeMaps?: RelativeMaps,
) => {
  const issues: Array<Issue> = [];
  if (!relativeMaps) return [];

  for (const attachment of attachments) {
    if (
      attachment.file.__typename === 'Flow___Argument_Pointer' &&
      relativeMaps
    ) {
      const pathToPointer = getPathForPointer(
        attachment.file.pointer,
        relativeMaps,
      );

      if (pathToPointer.error) {
        issues.push({
          message:
            'Er mist een variabele, waarschijnlijk heb je een stap aangepast of verwijderd',
          level: 'error',
          type: 'BadVariableIssue',
          actionId: action.id,
          actionType: action.actionType,
        });
      }
    }
  }

  return issues;
};

export default collectIssuesForPointerAttachments;
