import { Flow_Parameter_File_Pointer__Input } from '~/graphql/types';
import { FilePointerParameterValue } from '~/scenes/Automation/Flows/Actions/Base/types.flow';

export default (
  parameterValue: FilePointerParameterValue,
): Flow_Parameter_File_Pointer__Input => {
  if (
    parameterValue.variable == null ||
    parameterValue.variable.field == null
  ) {
    throw new Error(
      `Validation should have caught that the parameterValue ${JSON.stringify(
        parameterValue,
        null,
        2,
      )} has no field`,
    );
  }

  return {
    variable: parameterValue.variable.name,
    field: parameterValue.variable.field.name,
  };
};
