import { Flow, FlowDetails } from '../types.flow';

export default (flow: Flow): FlowDetails => {
  const { id, _v, accountId, name, enabled, maximumFlowRun } = flow;

  return {
    id,
    _v,
    accountId,
    name,
    enabled,
    maximumFlowRun,
  };
};
