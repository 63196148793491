import { FlowConditionList } from './types.flow';
import { OptionOf } from '~/components/Inputs/Dropdown';

import cleanedFilename from '~/util/cleanedFilename';

// Should mirror the operators on the backend
const OPERATORS = /(!|&&| AND | OR |\|\||\(|\)|\[|\]| )/;
export const AND_IDENTIFIER = 'AND';
export const OR_IDENTIFIER = 'OR';
export const DEFAULT_ALL_SHOULD_BE_TRUE = false;

export const expressionStringFor = (
  conditionList: FlowConditionList,
): string => {
  let str = '';
  const separator = conditionList.allShouldBeTrue
    ? AND_IDENTIFIER
    : OR_IDENTIFIER;

  let isFirst = true;
  conditionList.conditions.forEach((condition, idx) => {
    if (isFirst) {
      isFirst = false;
      str = str + `${idx}`;
    } else {
      str = str + ` ${separator} ${idx}`;
    }
  });

  return str;
};

export const expressionOptions: Array<OptionOf<boolean>> = [
  {
    label: 'en',
    payload: true,
    key: 'AND',
  },
  {
    label: 'of',
    payload: false,
    key: 'OR',
  },
];

/**
 * The expression string in the condition list can have grouped conditions. For example:
 *
 * '0 AND [(1 OR 2 OR 3)] AND 4'
 *
 * This function will return an array of all the condition indexes that need to be grouped into one component. The example:
 *
 * [0], [1,2,3], [4]
 */
export type InnerGroup = {
  conditionIndexes: Array<number>;
  allShouldBeTrue: boolean;
};
const defaultInnerGroup = { allShouldBeTrue: false, conditionIndexes: [] };
export const grabGroupsFromExpression = (
  exp: string,
): { allShouldBeTrue: boolean; conditionGroups: Array<InnerGroup> } => {
  const expressionElements = exp
    .split(OPERATORS)
    .map(element => element.trim());

  const groups: Array<InnerGroup> = [];
  let isInGroup = false;
  let shouldBeTrue: boolean | null = null;
  let innerGroupIndexes: Array<number> = [];
  let allShouldBeTrue: boolean | null = null;

  expressionElements.forEach(element => {
    switch (element) {
      case '[': {
        if (isInGroup) {
          throw Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | Nested groups are not allowed (${exp})`,
          );
        }

        isInGroup = true;
        shouldBeTrue = null;
        break;
      }
      case ']': {
        if (!isInGroup) {
          throw Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | Closing a group before opening (${exp})`,
          );
        }

        groups.push({
          allShouldBeTrue: shouldBeTrue == null ? true : shouldBeTrue,
          conditionIndexes: [...innerGroupIndexes],
        });
        innerGroupIndexes = [];
        isInGroup = false;
        shouldBeTrue = null;
        break;
      }
      case 'AND':
      case '&&': {
        if (shouldBeTrue === false) {
          throw Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | Using AND and OR together within groups is not supported (${exp})`,
          );
        }
        if (!isInGroup) {
          if (allShouldBeTrue === false) {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | Using AND and OR together in between groups is not supported (${exp})`,
            );
          }
          allShouldBeTrue = true;
        }

        shouldBeTrue = true;

        break;
      }
      case 'OR':
      case '||': {
        if (shouldBeTrue === true) {
          throw Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | Using AND and OR together within groups is not supported (${exp})`,
          );
        }
        if (!isInGroup) {
          if (allShouldBeTrue === true) {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | Using AND and OR together in between groups is not supported (${exp})`,
            );
          }
          allShouldBeTrue = false;
        }

        shouldBeTrue = false;

        break;
      }
      default: {
        const elementAsDigit = parseInt(element);
        if (!isNaN(elementAsDigit)) {
          if (isInGroup) {
            innerGroupIndexes.push(elementAsDigit);
          } else {
            groups.push({
              ...defaultInnerGroup,
              conditionIndexes: [elementAsDigit],
            });
            shouldBeTrue = null;
          }
        }
      }
    }
  });

  return {
    allShouldBeTrue:
      allShouldBeTrue == null ? DEFAULT_ALL_SHOULD_BE_TRUE : allShouldBeTrue,
    conditionGroups: groups,
  };
};
