/**
 * Converts all nested slate nodes into html string
 * @param {Descendant} node - Slate node
 * keywords: slate nodes, convert to html
 */

import { Descendant, Text } from 'slate';
import getElements from '../../components/elements';
import ELEMENTS from '~/components/PluginsEditor/components/elements/elementsEnum';

const serialize = ({
  node,
  customElements,
}: {
  node: Descendant;
  customElements: Array<ELEMENTS>;
}): string => {
  if (Text.isText(node)) {
    let string = node.text;

    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    if (node.text.includes('\n')) {
      string = string.replace(/\n/g, '<br>');
    }

    return string;
  }

  const children = node.children
    ?.map(n => serialize({ node: n, customElements }))
    .join('');

  const element = getElements(customElements)[node.type];

  if (element) return element?.serialize(node, children);

  return children;
};

const serializeAllElements = ({
  fragment,
  customElements,
}: {
  fragment: Array<Descendant>;
  customElements: Array<ELEMENTS>;
}): string => {
  const str = fragment
    .map(node => serialize({ node: node, customElements }))
    .join('');

  return str.replace(/(<div><\/div>)/g, '<div><br></div>');
};

export default serializeAllElements;
