/**
 * Shared config getter as we use it both in the main app as the mockSubscriptionServer
 */
export const getAmplifyConfig = (
  appSyncEndpoint,
  appSyncAuthType,
  identityPoolId,
  userPoolId,
  webclientId,
  awsRegion,
  storageBucket,
) => ({
  Auth: {
    mandatorySignIn: true,
    identityPoolId: identityPoolId,
    region: awsRegion,
    userPoolId: userPoolId,
    userPoolWebClientId: webclientId,
  },
  aws_appsync_graphqlEndpoint: appSyncEndpoint,
  aws_appsync_region: awsRegion,
  aws_appsync_authenticationType: appSyncAuthType,
  Storage: {
    bucket: storageBucket,
    identityPoolId: identityPoolId,
    region: awsRegion,
    customPrefix: {
      private: 'tmpPrivate/',
      protected: 'tmpPublic/',
      public: 'never upload here/',
    },
  },
});
