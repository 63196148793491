import React from 'react';
import { UseTransitionProps, useTransition, animated } from 'react-spring';
import { ANIMATION_CONFIG } from '~/scenes/Settings/constants';
import BaseEventCard, { EventCardState } from '../BaseEventCard';
import DateLabel from '../DateLabel';
import styled, { css } from 'styled-components';
import Task from '../Cards/Task';
import TimelineWrapper from '../TimelineWrapper';
import SendEmail from '../Cards/SendEmail';
import Block from '../Block';
import { EventTimelineContact } from '../..';
import { Task as TaskDetailProps } from '~/scenes/Tasks/types';
import { ActivityV2 } from '../../types';

type Props = {
  date: string;
  items: Array<ActivityV2>;
  contact: EventTimelineContact;
  onOpenTaskModal?: (task: TaskDetailProps | null) => void;
};

const CardGroup: React.FC<Props> = ({
  date,
  items,
  contact,
  onOpenTaskModal,
}) => {
  const transitionProps: UseTransitionProps = {
    from: ANIMATION_CONFIG.from,
    enter: ANIMATION_CONFIG.to,
    leave: ANIMATION_CONFIG.from,
    config: ANIMATION_CONFIG.config,
    trail: 125,
    keys: (item: ActivityV2) => item.id,
  };
  const transitions = useTransition(items, transitionProps);

  return (
    <Container key={date} id={date}>
      <DateLabel value={date} />
      {transitions((style, activity: ActivityV2) => {
        if (!activity) return null;

        if (activity.__typename === 'Event_Contact_Email') {
          return (
            <animated.div key={activity.id} style={style}>
              <TimelineWrapper icon={activity.icon}>
                <SendEmail
                  {...activity}
                  contact={contact}
                  state={
                    activity.highlighted ? EventCardState.Highlighted : null
                  }
                  key={activity.id}
                />
              </TimelineWrapper>
            </animated.div>
          );
        }

        if (activity.__typename === 'Task_Contact')
          return (
            <animated.div key={activity.id} style={style}>
              <TimelineWrapper icon={activity.icon}>
                <Task {...activity} onOpenTaskModal={onOpenTaskModal} />
              </TimelineWrapper>
            </animated.div>
          );

        return (
          <animated.div key={activity.id} style={style}>
            <TimelineWrapper icon={activity.icon}>
              <BaseEventCard
                {...activity}
                state={activity.highlighted ? EventCardState.Highlighted : null}
                key={activity.id}
                body={
                  <>
                    {activity.body.map(block => (
                      <Block {...block} key={block.id} />
                    ))}
                  </>
                }
                dataTestId={`${activity.__typename}_Card`}
              />
            </TimelineWrapper>
          </animated.div>
        );
      })}
    </Container>
  );
};

const Container = styled.div(() => css``);

export default CardGroup;
