import { PinnedAppsMap } from '~/scenes/Settings/Widget/states/pinnedApps';
import { AccordionGroup } from '../..';
import itemIsPinned from '../itemIsPinned';

type Args = {
  items: Array<AccordionGroup>;
  pinnedApps: PinnedAppsMap;
};

/**
 * Returns pinned item ids if there are any in the app group
 * @param {PinnedAppsMap} pinnedApps
 * @param {Array<AccordionGroup>} items
 * keywords: pinned, pinnedApps, pinnedItems
 */
const getPinnedItemsForGroup = ({ items, pinnedApps }: Args): Array<string> => {
  const pinnedItems = items
    .filter(item => itemIsPinned(item.id, pinnedApps))
    .map(item => item.id);

  return pinnedItems;
};
export default getPinnedItemsForGroup;
