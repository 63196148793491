import React from 'react';
import styled from 'styled-components';
import ExternalLink from '~/components/ExternalLink';
import {
  safeFormattedDateTime,
  convertServerDateStringToDate,
} from '~/util/date';

export type Props = {
  dataTestId?: string;

  /** Null if the api key is not connected, otherwise Date string */
  lastConnectedAt?: string | null;
};

const text = {
  goTo: 'Ga naar ',
  myApps: 'My Apps',
  stepsToFollow:
    ' , klik “Add connection”, zoek op “DatHuis”, plak de api key. Daarna ververs deze pagina.',
  lastConnectedAt: 'Laatst verbonden op ',
};

const Description: React.FC<Props> = ({
  dataTestId,
  lastConnectedAt,
  ...rest
}) => {
  const formattedDate = safeFormattedDateTime(
    convertServerDateStringToDate(lastConnectedAt),
  );

  if (lastConnectedAt && lastConnectedAt.length > 0)
    return (
      <Container data-testid={dataTestId} {...rest}>
        {text.lastConnectedAt}
        {formattedDate}
      </Container>
    );

  return (
    <Container data-testid={dataTestId} {...rest}>
      {text.goTo}
      <ExternalLink href="https://zapier.com/app/connections" target="_blank">
        {text.myApps}
      </ExternalLink>
      {text.stepsToFollow}
    </Container>
  );
};

const Container = styled.div<{}>``;

export default Description;
