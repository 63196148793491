import gql from 'graphql-tag';

import FlowFields from '../fragments/FlowFields';

export default gql`
  query GetFlow($accountId: ID!, $id: ID!) {
    getFlow(accountId: $accountId, id: $id) {
      ...FlowFields
    }
  }

  ${FlowFields}
`;
