import { isNil } from 'ramda';
import { ConditionalFlowAction } from '~/graphql/types.client';
import { ConditionIssue } from '~/scenes/Automation/v2/state/flowIssues';
import { EMPTY_START_ACTION_ID } from '../../../Builder/constants/actionTemplates';
import { RelativeMaps } from '../../../Builder/hooks/useRelativeMaps';
import getPathForPointer from '../../components/Selector/utils/getPathForPointer';
import getSubject from '../../components/Selector/utils/getSubject';

const messages = {
  emptyCondition: 'Condities mogen niet leeg zijn',
  missingTrigger: 'Condities moeten een startpunt hebben',
  missingArg: 'Conditie mist één of meerdere argumenten',
  missingInvalidOrMissingArg: 'Conditie mist een geldige waarde',
  missingPointer: 'Conditie wijst naar een waarde die niet (meer) bestaat',
};

/**
 * Returns an array of all issues for given conditional action
 * @param {ConditionalFlowAction} action - A conditional action such as Wait, Start or IfElse
 * keywords: issues,flow,condition
 */

const getIssuesForTriggers = (
  action: ConditionalFlowAction,
  relativeMaps?: RelativeMaps,
): Array<ConditionIssue> => {
  const triggers = action.triggers;
  const issues: Array<ConditionIssue> = [];

  if (triggers.length === 0) {
    issues.push({
      type: 'ConditionIssue',
      id: EMPTY_START_ACTION_ID,
      argIndex: null,
      level: 'error',
      message: messages.missingTrigger,
      actionId: action.id,
      actionType: action.actionType,
    });
  }

  triggers.forEach(trigger => {
    if (!trigger.trigger) {
      issues.push({
        type: 'ConditionIssue',
        id: trigger.id,
        argIndex: null,
        level: 'error',
        message: messages.missingTrigger,
        actionId: action.id,
        actionType: action.actionType,
      });
    }

    if (relativeMaps && trigger.trigger && 'input' in trigger.trigger) {
      const res = getPathForPointer(trigger.trigger.input, relativeMaps);
      if (res.error) {
        issues.push({
          type: 'ConditionIssue',
          id: trigger.id,
          argIndex: null,
          level: 'error',
          message: messages.missingPointer,
          actionId: action.id,
          actionType: action.actionType,
        });
      }
    }

    if (
      relativeMaps &&
      trigger.trigger &&
      trigger.trigger.__typename === 'Flow___SubjectFieldCondition'
    ) {
      const subject = getSubject(
        { type: trigger.trigger.type, typeId: trigger.trigger.typeId },
        relativeMaps.subjectMap,
      );

      if (isNil(subject)) {
        issues.push({
          type: 'ConditionIssue',
          id: trigger.id,
          argIndex: null,
          level: 'error',
          message: messages.missingPointer,
          actionId: action.id,
          actionType: action.actionType,
        });
      }

      trigger.trigger?.args.forEach((arg, index) => {
        if (arg === null) {
          issues.push({
            type: 'ConditionIssue',
            id: trigger.id,
            argIndex: index,
            level: 'error',
            message: messages.missingInvalidOrMissingArg,
            actionId: action.id,
            actionType: action.actionType,
          });
        }
      });
    }

    if (trigger.trigger?.__typename === 'Flow___InstanceCondition') {
      trigger.trigger?.args.forEach((arg, index) => {
        if (isNil(arg)) {
          issues.push({
            type: 'ConditionIssue',
            id: trigger.id,
            argIndex: index,
            level: 'error',
            message: messages.missingArg,
            actionId: action.id,
            actionType: action.actionType,
          });
        }

        if (relativeMaps && arg && 'pointer' in arg) {
          const res = getPathForPointer(arg.pointer, relativeMaps);
          if (res.error) {
            issues.push({
              type: 'ConditionIssue',
              id: trigger.id,
              argIndex: index,
              level: 'error',
              message: messages.missingPointer,
              actionId: action.id,
              actionType: action.actionType,
            });
          }
        }
      });
    }

    trigger.condition.conditions.forEach((condition, index) => {
      if (isNil(condition)) {
        issues.push({
          type: 'ConditionIssue',
          id: trigger.id,
          level: 'error',
          message: messages.emptyCondition,
          actionId: action.id,
          actionType: action.actionType,
        });
      }

      if (relativeMaps && condition && 'input' in condition) {
        const res = getPathForPointer(condition.input, relativeMaps);
        if (res.error) {
          issues.push({
            type: 'ConditionIssue',
            id: trigger.id,
            conditionId: condition.id,
            argIndex: index,
            level: 'error',
            message: messages.missingPointer,
            actionId: action.id,
            actionType: action.actionType,
          });
        }
      }

      if (condition?.__typename === 'Flow___InstanceCondition')
        condition?.args.forEach((arg, index) => {
          if (isNil(arg)) {
            issues.push({
              type: 'ConditionIssue',
              id: trigger.id,
              conditionId: condition.id,
              argIndex: index,
              level: 'error',
              message: messages.missingArg,
              actionId: action.id,
              actionType: action.actionType,
            });
          }

          if (relativeMaps && arg && 'pointer' in arg) {
            const res = getPathForPointer(arg.pointer, relativeMaps);
            if (res.error) {
              issues.push({
                type: 'ConditionIssue',
                id: trigger.id,
                conditionId: condition.id,
                argIndex: index,
                level: 'error',
                message: messages.missingPointer,
                actionId: action.id,
                actionType: action.actionType,
              });
            }
          }
        });

      if (condition?.__typename === 'Flow___SubjectFieldCondition') {
        condition?.args.forEach((arg, index) => {
          if (arg === null) {
            issues.push({
              type: 'ConditionIssue',
              id: trigger.id,
              argIndex: index,
              level: 'error',
              message: messages.missingInvalidOrMissingArg,
              actionId: action.id,
              actionType: action.actionType,
            });
          }
        });
      }
    });
  });

  return issues;
};
export default getIssuesForTriggers;
