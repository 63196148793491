import {
  FlowOutputObject,
  FlowOutputObjectField,
} from '~/scenes/Automation/Flows/types.flow';
import { FlowVariableStashItem } from '~/scenes/Automation/Flows/types.flow';
import { PrimitiveVariableType } from './constants';
import { OptionOf } from '~/components/Inputs/Dropdown';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';

import { asFieldLabel } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { asPointerName } from '~/scenes/Automation/Flows/Actions/Base/FlowParameter/ParameterValue/PointerComponent';

export type FieldType = {
  outputObject: FlowOutputObject;
  outputField?: FlowOutputObjectField;
};
const getFieldOptions = (
  selectedVariable: FlowVariableStashItem | PrimitiveVariableType | null,
  objectType?: FLOW_OUTPUT_TYPE,
  fieldType?: FLOW_OUTPUT_TYPE,
): Array<OptionOf<FieldType>> => {
  if (selectedVariable == null) return [];
  if (typeof selectedVariable === 'string') return [];

  const fieldOptions: Array<OptionOf<FieldType>> = [];
  selectedVariable.outputObjects.forEach(outputObject => {
    if (objectType == null || outputObject.outputType === objectType) {
      fieldOptions.push({
        label: asFieldLabel(outputObject),
        payload: { outputObject },
        key: asPointerName(outputObject),
      });
    }

    outputObject.fields.forEach(outputField => {
      if (fieldType == null || outputField.outputType === fieldType) {
        fieldOptions.push({
          label: asFieldLabel(outputObject, outputField),
          payload: { outputObject, outputField },
          key: asPointerName(outputObject, outputField),
        });
      }
    });
  });

  return fieldOptions;
};

export default getFieldOptions;
