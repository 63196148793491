import TASK_LIST_TYPE from './taskListType';
import { reportErrorToTracker } from '~/util/assertion';

import { TaskListType } from '~/scenes/Tasks/util/taskListType';
import { TaskStatus } from '~/graphql/types';

export default (
  listType: TaskListType,
): { status?: TaskStatus; deleted: boolean } => {
  switch (listType) {
    case TASK_LIST_TYPE.OPEN:
      return {
        status: TaskStatus.Open,
        deleted: false,
      };
    case TASK_LIST_TYPE.CLOSED:
      return {
        status: TaskStatus.Closed,
        deleted: false,
      };
    case TASK_LIST_TYPE.DELETED:
      return {
        deleted: true,
      };
    default:
      reportErrorToTracker(new Error(`Invalid list type ${listType}`));
      return {
        status: TaskStatus.Open,
        deleted: false,
      };
  }
};
