import React from 'react';

import { FlowConditionAppVBOWaardecheckContactRequestNew } from './types.flow';
import { WithBaseActionContextProps } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { ConditionChoiceType } from '~/scenes/Automation/Flows/Actions/Base/FlowCondition/types.flow';

import { InputGroup } from '~/components/Inputs';
import TEST_ID from './Component.testid';
import { withBaseActionContext } from '~/scenes/Automation/Flows/Actions/BaseActionContext';
import { getStashVariablesInStash } from '~/scenes/Automation/Flows/Actions/util/stashHelpers';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';
import { emptyVBOWaardecheckReportEventParameterValue } from '../../FlowParameter/ParameterValue/Event';
import AppVBOWaardecheckReportPointerComponent from '../../FlowParameter/ParameterValue/AppVBOWaardecheckReportPointerComponent';

const text = {
  variableLabel: 'Selecteer Avix waardecheck',
};
type MyProps = {
  condition: FlowConditionAppVBOWaardecheckContactRequestNew;
  conditionChoiceType?: ConditionChoiceType | null | undefined;
  onChange: (
    newFlowCondition: FlowConditionAppVBOWaardecheckContactRequestNew,
  ) => void;
};
type Props = WithBaseActionContextProps & MyProps;
type State = {
  key: string | null;
};
class FlowConditionAppVBOWaardecheckContactRequestNewComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      key: null,
    };
  }

  componentDidMount() {
    const { baseActionContext } = this.props;

    const key = baseActionContext.subscribeValidator({
      validate: this.validate,
    });

    this.setState({
      key,
    });
  }

  componentWillUnmount() {
    const { baseActionContext } = this.props;

    if (this.state.key != null) {
      baseActionContext.unsubscribeValidator(this.state.key);
    }
  }

  possibleVBOWaardecheckReports = () => {
    const { baseActionContext } = this.props;
    const { variableStash } = baseActionContext;

    return getStashVariablesInStash(
      variableStash,
      FLOW_OUTPUT_TYPE.FlowOutputEventContactAppVboWaardecheckReport,
    );
  };

  validate = () =>
    // The pointer component will check if there is a selection and if that selection is correct
    true;

  render() {
    const { condition, onChange } = this.props;
    const { event } = condition;

    const possibleVBOWaardecheckReports = this.possibleVBOWaardecheckReports();

    if (possibleVBOWaardecheckReports.length === 0) {
      return null;
    }

    return (
      <>
        <InputGroup data-testid={TEST_ID.EVENT_CONTAINER}>
          <AppVBOWaardecheckReportPointerComponent
            variable={event?.variable ?? null}
            hideVariableDropdownIfOnlyOneOptionAndSelected
            hideFieldDropdownIfOnlyOneOptionAndSelected
            variableLabel={text.variableLabel}
            possibleVariables={possibleVBOWaardecheckReports}
            fieldType={
              FLOW_OUTPUT_TYPE.FlowOutputEventContactAppVboWaardecheckReport
            }
            onChange={newPointerVariable => {
              const newValue: FlowConditionAppVBOWaardecheckContactRequestNew =
                {
                  ...condition,
                  event: newPointerVariable
                    ? {
                        type: emptyVBOWaardecheckReportEventParameterValue()
                          .type,
                        variable: newPointerVariable,
                      }
                    : null,
                };
              onChange(newValue);
            }}
          />
        </InputGroup>
      </>
    );
  }
}

export default withBaseActionContext<MyProps>(
  FlowConditionAppVBOWaardecheckContactRequestNewComponent,
);
