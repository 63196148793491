import {
  StringField,
  TypedField,
} from '~/scenes/Automation/Flows/Actions/Base/types.flow';

import { FIELD_TYPE } from '~/scenes/Automation/Flows/Actions/Base/constants';

export const FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELD = Object.freeze({
  name: {
    name: 'name',
    label: 'Naam',
    type: FIELD_TYPE.STRING,
  } as StringField,
  phone: {
    name: 'phone',
    label: 'Telefoonnummer',
    type: FIELD_TYPE.STRING,
  } as StringField,
  email: {
    name: 'email',
    label: 'E-mailadres',
    type: FIELD_TYPE.STRING,
  } as StringField,
});

export const FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELDS: Array<TypedField> =
  Object.keys(FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELD).map(
    key => FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELD[key],
  );

export const DEFAULT_FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELD: TypedField =
  FLOW_CONDITION_APP_HYPOTHEEKBOND_DETAILS_FIELD.name;
