import { Unionize } from 'utility-types';
import { FlowConditionFieldsFragment } from '~/graphql/types';

export type FlowConditionType =
  Unionize<FlowConditionFieldsFragment>['__typename'];

export type FlowConditionTypeOption = {
  type: FlowConditionType;
  showInStartAction: boolean;
  showInStartSubConditionList: boolean;
  showInIfElseConditionList: boolean;
  startConditionLabel: string;
  label: string;
};
export const FLOW_CONDITION_TYPES = Object.freeze({
  CONTACT_DETAILS: {
    type: 'Flow_Condition_Contact_Details',
    showInStartAction: true,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: 'Contact krijgt eigenschap',
    label: 'Contact heeft eigenschap',
  } as const,
  APPVALUATIONREPORT_NEW: {
    type: 'Flow_Condition_Event_Contact_App_ValuationReport_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuw waarderapport',
    label: 'Nieuw waarderapport',
  } as const,
  APPVALUATIONREPORT_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_ValuationReport_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Waarderapport heeft eigenschap',
  } as const,
  APPVALUATIONREQUEST_NEW: {
    type: 'Flow_Condition_Event_Contact_App_ValuationRequest_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuw waarderapport - waardebepaling verzocht',
    label: 'Nieuw waarderapport - waardebepaling verzocht',
  } as const,
  APPVALUATIONREQUEST_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_ValuationRequest_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Waardebepaling heeft eigenschap',
  } as const,
  EVENT_EMAIL_LINKCLICKED: {
    type: 'Flow_Condition_Event_Email_LinkClicked',
    showInStartAction: false,
    showInStartSubConditionList: false,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Link in e-mail is geklikt',
  } as const,
  EVENT_EMAIL_OPENED: {
    type: 'Flow_Condition_Event_Email_Opened',
    showInStartAction: false,
    showInStartSubConditionList: false,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'E-mail is geopend',
  } as const,
  TIME_ELAPSED: {
    type: 'Flow_Condition_Time_Elapsed',
    showInStartAction: false,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: '',
    label: 'Tijd is verstreken',
  } as const,
  CONTACT_TAG: {
    type: 'Flow_Condition_Contact_Tag',
    showInStartAction: true,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: 'Tag is toegevoegd/verwijderd',
    label: 'Tag is aan/afwezig',
  } as const,
  EVENT_CONTACT_APP_FUNDA_CONTACTREQUEST_NEW: {
    type: 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuw Funda contactverzoek',
    label: 'Nieuw Funda contactverzoek',
  } as const,
  EVENT_CONTACT_APP_FUNDA_VIEWINGREQUEST_NEW: {
    type: 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuw Funda bezichtigingsverzoek',
    label: 'Nieuw Funda bezichtigingsverzoek',
  } as const,
  EVENT_CONTACT_APP_FUNDA_BROCHUREREQUEST_NEW: {
    type: 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuw Funda brochureverzoek',
    label: 'Nieuw Funda brochureverzoek',
  } as const,
  EVENT_CONTACT_APP_FUNDA_BROCHUREREQUEST_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_Funda_BrochureRequest_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Funda brochureverzoek heeft eigenschap',
  } as const,
  EVENT_CONTACT_APP_FUNDA_VIEWINGREQUEST_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_Funda_ViewingRequest_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Funda bezichtigingsverzoek heeft eigenschap',
  } as const,
  EVENT_CONTACT_APP_FUNDA_CONTACTREQUEST_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_Funda_ContactRequest_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Funda contactverzoek heeft eigenschap',
  } as const,
  EVENT_CONTACT_APP_BBWAARDECHECK_REPORT_NEW: {
    type: 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuwe brainbay waardecheck',
    label: 'Nieuwe brainbay waardecheck',
  } as const,
  EVENT_CONTACT_APP_BBWAARDECHECK_REPORT_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_BBWaardecheck_Report_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Brainbay waardecheck heeft eigenschap',
  } as const,
  EVENT_CONTACT_APP_BBWAARDECHECK_APPRAISALREQUEST_NEW: {
    type: 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel:
      'Nieuwe brainbay waardecheck - waardebepaling verzocht',
    label: 'Nieuwe brainbay waardecheck - waardebepaling verzocht',
  } as const,
  EVENT_CONTACT_APP_BBWAARDECHECK_APPRAISALREQUEST_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_BBWaardecheck_AppraisalRequest_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Brainbay waardecheck - waardebepaling heeft eigenschap',
  } as const,
  EVENT_CONTACT_APP_BBWAARDECHECK_CONTACTREQUEST_NEW: {
    type: 'Flow_Condition_Event_Contact_App_BBWaardecheck_ContactRequest_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuw brainbay contactverzoek',
    label: 'Nieuw brainbay contactverzoek',
  } as const,
  EVENT_CONTACT_APP_ZAPIER_NEW: {
    type: 'Flow_Condition_Event_Contact_App_Zapier_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuw Zapier event',
    label: 'Nieuw Zapier event',
  } as const,
  EVENT_CONTACT_APP_ZAPIER_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_Zapier_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Zapier event heeft eigenschap',
  } as const,
  EVENT_CONTACT_APP_VBOWAARDECHECK_REPORT_NEW: {
    type: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuwe Avix waardecheck',
    label: 'Nieuwe Avix waardecheck',
  } as const,
  EVENT_CONTACT_APP_VBOWAARDECHECK_REPORT_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_Report_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Avix waardecheck heeft eigenschap',
  } as const,
  EVENT_CONTACT_APP_VBOWAARDECHECK_APPRAISALREQUEST_NEW: {
    type: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuwe Avix waardecheck - waardebepaling verzocht',
    label: 'Nieuwe Avix waardecheck - waardebepaling verzocht',
  } as const,
  EVENT_CONTACT_APP_VBOWAARDECHECK_APPRAISALREQUEST_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_AppraisalRequest_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Avix waardecheck - waardebepaling heeft eigenschap',
  } as const,
  EVENT_CONTACT_APP_VBOWAARDECHECK_CONTACTREQUEST_NEW: {
    type: 'Flow_Condition_Event_Contact_App_VBOWaardecheck_ContactRequest_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuw Avix contactverzoek',
    label: 'Nieuw Avix contactverzoek',
  } as const,

  EVENT_CONTACT_APP_HYPOTHEEKBOND_NEW: {
    type: 'Flow_Condition_Event_Contact_App_Hypotheekbond_New',
    showInStartAction: true,
    showInStartSubConditionList: false,
    showInIfElseConditionList: false,
    startConditionLabel: 'Nieuw hypotheekbond contactverzoek',
    label: 'Nieuw hypotheekbond contactverzoek',
  } as const,

  EVENT_CONTACT_APP_HYPOTHEEKBOND_DETAILS: {
    type: 'Flow_Condition_Event_Contact_App_Hypotheekbond_Details',
    showInStartAction: false,
    showInStartSubConditionList: true,
    showInIfElseConditionList: true,
    startConditionLabel: '',
    label: 'Hypotheekbond contact verzoek heeft eigenschap',
  } as const,
});
