import React, { useRef, useState } from 'react';

import {
  GetAppValuationReportsQuery as GetAppValuationReportsQueryProps,
  GetAppValuationReportsQueryVariables,
} from '~/graphql/types';
import cleanedFilename from '~/util/cleanedFilename';
import TEST_ID from './AppValuationReportDropdown.testid';
import { useGetAppValuationReportsQuery } from '~/graphql/types';
import useDelayedLoading from '~/components/util/useDelayedLoading';

import Dropdown, { Option } from './index';
import InputGroup from '../InputGroup';
import Catalog from '~/Catalog';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';

const text = {
  label: 'Selecteer een waarderapport',
  noSelectionLabel: 'Alle waarderapporten',
};

type Props = {
  /** onChange function for Dropdown component */
  onChange: <GetAppValuationReportsQueryProps extends { option: Option }>(
    option: GetAppValuationReportsQueryProps,
  ) => void;
  /** Variables for the query */
  variables?: GetAppValuationReportsQueryVariables;
  /** Dropdown list item's id */
  selectedOptionId: string | null;
};

const AppValuationReportDropdown: React.FC<Props> = ({
  onChange,
  variables,
  selectedOptionId,
}) => {
  const [dropdownListOpen, setDropdownListOpen] = useState(false);
  const [loading, setLoading, removeLoading] = useDelayedLoading();
  const previousLoading = useRef<boolean>(false);

  const {
    data,
    loading: queryLoading,
    error,
    networkStatus,
  } = useGetAppValuationReportsQuery({
    variables,
    skip: selectedOptionId == null && dropdownListOpen === false,
    displayName: __filename,
  });

  const hasNoSelectionOption = !error;

  let options: Array<Option> = [];
  if (hasNoSelectionOption) {
    options = [
      {
        key: 'no-selection',
        label: text.noSelectionLabel,
        payload: null,
      },
    ];
  }

  if (queryLoading != previousLoading.current) {
    if (queryLoading) {
      setLoading();
    } else {
      removeLoading();
    }

    previousLoading.current = queryLoading;
  }

  if (data) {
    options = [...options, ...convertDataToOptions(data)];
  }
  const selectedOptionKey = selectedOptionId != null ? selectedOptionId : null;
  const selectedOption = options.findIndex(
    option => option.payload === selectedOptionKey,
  );

  return (
    <InputGroup>
      <Dropdown
        onDropdownOpened={() => setDropdownListOpen(true)}
        options={options}
        onChange={onChange}
        selectedOptionIdx={selectedOption}
        error={error ? Catalog.genericUnknownErrorMessage : ''}
        loading={loading}
        disabled={!!error}
        dataTestid={TEST_ID.VALUE_REPORT_DROPDOWN}
        hideDropdownList={queryLoading && !loading}
        label={text.label}
        hideLabelOnTop={
          selectedOptionId != null && isInitialLoadStatus(networkStatus)
        }
      />
    </InputGroup>
  );
};

const convertDataToOptions = (
  data: GetAppValuationReportsQueryProps,
): Array<Option> => {
  const { getAppValuationReports } = data;

  const newArray = getAppValuationReports.map(item => {
    if (!item) {
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | function convertDataToOptions cannot have item as null`,
      );
    }

    const { id, name } = item;

    return {
      label: name,
      key: id,
      payload: id,
    };
  });

  return [...newArray];
};

export default AppValuationReportDropdown;
