/**
 *
 * A Hook to help use the Dropdown with a specific payload. It will give the user:
 *  [0] - the currently selected index to send to the dropdown
 *  [1] - the onChange function to send to the dropdown
 *  [2] - the currently selected payload
 *  [3] - a function to specifically set a new selected idx
 *
 * It expects:
 *  options - the array of options
 *  initialSelectedIndex - optionally set the initial index
 *  onSelectedChange - an optional callback that will be called with the payload of any new selection
 */

import { useState } from 'react';

import { OptionOf, OnChangeFunctionOf, SelectedOptionOf } from './';

type ReturnProps<T> = [
  number | null,
  OnChangeFunctionOf<T>,
  T | null,
  (idx: number | null) => void,
];

const useDropdown = <T>(
  options: Array<OptionOf<T>>,
  initialSelectedIndex?: number | null,
  onSelectedChange?: (payload: T) => void,
): ReturnProps<T> => {
  const [selectedIdx, setSelectedIdxValue] = useState<number | null>(
    initialSelectedIndex == null || initialSelectedIndex === -1
      ? null
      : initialSelectedIndex,
  );

  let selectedPayload: T | null = null;
  if (selectedIdx != null && options[selectedIdx]) {
    selectedPayload = options[selectedIdx].payload;
  }

  const setSelectedIdx = (idx: number | null) => {
    setSelectedIdxValue(idx);

    if (onSelectedChange && idx != null) {
      onSelectedChange(options[idx].payload);
    }
  };

  const onChange = (selectedOption: SelectedOptionOf<T>) => {
    const { selectedOptionIdx } = selectedOption;

    if (selectedOptionIdx !== selectedIdx) {
      setSelectedIdx(selectedOptionIdx);
    }
  };

  return [selectedIdx, onChange, selectedPayload, setSelectedIdx];
};

export const asDropdownOption = <T extends { name: string; id: string }>(
  item: T,
): OptionOf<T> => ({
  label: item.name,
  key: item.id,
  payload: item,
});

export default useDropdown;
