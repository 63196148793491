import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { omit } from 'ramda';

import { SystemSize } from '~/theme';
import { isString } from 'lodash';
import { SystemFontWeight } from '~/theme/System/tokens';
import arrayToCss from '~/util/arrayToCss';
import { ThemeColor } from '~/theme/System/tokens/colorPalette';

export enum Variant {
  primary = 'primary',
  secondary = 'secondary',
}

type WordBreak = 'normal' | 'break-all' | 'keep-all' | 'break-word';
type WhiteSpace = 'normal' | 'nowrap' | 'break-spaces';

export type Props = {
  className?: string;
  as?: string | React.ComponentType<any>;
  variant?: Variant;
  size?: SystemSize;
  highlight?: string;
  dataTestId?: string;
  withoutMargin?: boolean;
  margin?: Array<SystemSize | null>;
  padding?: Array<SystemSize | null>;
  backgroundColor?: string;
  fontWeight?: SystemFontWeight;
  color?: ThemeColor;
  inline?: boolean;
  style?: any;
  wordBreak?: WordBreak;
  whiteSpace?: WhiteSpace;
};

const Typography: React.FC<Props> = (
  props = {
    className: '',
    as: 'p',
    variant: Variant.primary,
    size: 'm',
    dataTestId: '',
    withoutMargin: false,
    backgroundColor: 'transparent',
    inline: false,
  },
) => {
  const chunks =
    props.highlight && typeof props.children === 'string'
      ? // Typescript thinks this can be an number but we check if its a string so we can safely cast it.
        [...(props.children as string).split(props.highlight)]
      : [props.children];

  return (
    <Container data-testid={props.dataTestId} {...omit(['children'], props)}>
      {chunks.map((chunk, index) => (
        <React.Fragment key={index}>
          <span>{chunk}</span>
          {index !== chunks.length - 1 && (
            <Highlight>{props.highlight}</Highlight>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

const defaultProps = {
  h1: { size: 'xxl' as SystemSize, as: 'h1', variant: Variant.primary },
  h2: { size: 'xl' as SystemSize, as: 'h2', variant: Variant.primary },
  h3: { size: 'l' as SystemSize, as: 'h3', variant: Variant.primary },
  h4: { size: 'm' as SystemSize, as: 'h4', variant: Variant.secondary },
  h5: { size: 'base' as SystemSize, as: 'h5', variant: Variant.secondary },
  h6: { size: 's' as SystemSize, as: 'h6', variant: Variant.secondary },
  subheading: {
    size: 'xs' as SystemSize,
    as: 'h6',
    variant: Variant.secondary,
  },
  label: {
    size: 's' as SystemSize,
    as: 'span',
    variant: Variant.secondary,
    fontWeight: 'semiBold' as SystemFontWeight,
  },
  body: { size: 'm' as SystemSize, as: 'p', variant: Variant.primary },
  code: {
    size: 'm' as SystemSize,
    as: 'code',
    variant: Variant.primary,
    backgroundColor: 'grey',
  },
  strong: {
    size: 'base' as SystemSize,
    as: 'strong',
    variant: Variant.primary,
  },
};

const weightMap = Object.freeze({
  h1: 'semiBold',
  h2: 'semiBold',
  h3: 'semiBold',
  h4: 'normal',
  h5: 'normal',
  h6: 'normal',
  p: 'normal',
  code: 'normal',
});

const Container = styled.p<Props>(
  ({
    theme,
    size,
    variant,
    as: as_,
    withoutMargin = false,
    inline = false,
    color,
    fontWeight,
    padding: $padding,
    margin: $margin,
    wordBreak,
    whiteSpace,
  }) => {
    const as = isString(as_) ? as_ : 'p';

    return css`
      font-size: ${theme.fs(size || 'm')};
      font-weight: ${theme.fw(
        fontWeight ? fontWeight : weightMap[as] || 'normal',
      )};
      color: ${color
        ? theme.color(color.group, color.variant || 'base')
        : theme.color('text', variant === 'primary' ? 'base' : 'light')};
      margin: ${withoutMargin
        ? '0 0 0 0'
        : $margin
        ? arrayToCss($margin, theme)
        : '0 0 0.5em 0'};
      background-color: transparent;
      border-radius: ${theme.getTokens().border.radius.base};
      padding: ${as === 'code'
        ? theme.space('xxxs')
        : $padding
        ? arrayToCss($padding, theme)
        : 0};
      display: ${inline ? 'inline' : 'block'};

      ${wordBreak &&
      css`
        word-break: ${wordBreak};
      `};

      ${whiteSpace &&
      css`
        white-space: ${whiteSpace};
      `};
    `;
  },
);

const highlightAnimation = keyframes`
  from { width: 0; opacity: 0 }
  50% { opacity: 1; }
  to { width: 100%; }
`;

const Highlight = styled.span<{}>`
  display: inline-block;
  position: relative;
  z-index: 0;

  ::before {
    position: absolute;
    content: ' ';
    display: block;
    height: 0.2em;
    width: 0;
    bottom: 0.25em;
    z-index: -1;
    background-color: ${({ theme }) => theme.color('accent')};
    animation: ${highlightAnimation} 0.35s ease-out forwards;
  }
`;

// Shorthands
export const Heading1: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.h1, ...props }} />
);
export const Heading2: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.h2, ...props }} />
);
export const Heading3: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.h3, ...props }} />
);
export const Heading4: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.h4, ...props }} />
);
export const Heading5: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.h5, ...props }} />
);
export const Heading6: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.h6, ...props }} />
);
export const SubHeading: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.subheading, ...props }} />
);
export const Label: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.label, ...props }} />
);
export const Body: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.body, ...props }} />
);
export const Code: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.code, ...props }} />
);

export const Strong: React.FC<Props> = props => (
  <Typography {...{ ...defaultProps.strong, ...props }} />
);

export default Typography;
