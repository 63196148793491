import gql from 'graphql-tag';

export default gql`
  fragment FlowParameterBooleanFields on Flow_Parameter__Boolean {
    __typename

    ... on Flow_Parameter_Boolean_Primitive {
      booleanValue: value
    }

    ... on Flow_Parameter_Boolean_Pointer {
      variable
      field
    }
  }
`;
