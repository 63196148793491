import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { scrollBarStyles } from '~/components/OverflowScrollWrapper';
import { Heading3 } from '~/components/Typography/index';

import CloseButton from '../../CloseButton';
import Header from '../components/Header';

export type Props = {
  dataTestId?: string;
  header: React.ReactNode;
  /* Method provided by an Overlay component */
  onClose?: () => void;
  children?: React.ReactNode;
};

export const FormContext = React.createContext<{
  formContainerRef: HTMLDivElement | null;
}>({ formContainerRef: null });

const Form: React.FC<Props> = ({
  dataTestId,
  header,
  onClose,
  children,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <FormContext.Provider value={{ formContainerRef: ref.current }}>
      <Outer>
        <Container data-testid={dataTestId} {...rest} ref={ref}>
          <Header justification="space-between" align="start">
            <CloseButton onClick={onClose} dataTestId="close-button" />
            <Heading3 withoutMargin>{header}</Heading3>
          </Header>
          <Inner>
            {React.Children.map(
              children,
              child =>
                React.isValidElement(child) &&
                React.cloneElement(child, {
                  // Pass down the onClose handler to any child component
                  onClose,
                  ...child.props,
                }),
            )}
          </Inner>
        </Container>
      </Outer>
    </FormContext.Provider>
  );
};

const MAX_DIALOG_WIDTH = 760;
const Outer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};
    box-shadow: ${theme.getTokens().boxShadow.modal};

    max-width: ${MAX_DIALOG_WIDTH}px;
    width: 100%;
    max-height: 90vh;
    min-height: 50%;

    margin: 0 auto;

    padding: ${theme.space('xxxxl')};

    overflow-y: scroll;

    display: flex;
    flex-direction: column;

    ${scrollBarStyles}
  `,
);

const Inner = styled.div<{}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default Form;
