import { setContext } from '@apollo/client/link/context';
import { ApolloLink, HttpLink } from '@apollo/client';

const createHttpLink = function ({ appSyncApiUrl, getJWTToken }) {
  const authorizationHeaderLink = setContext(async (_, previousContext) => {
    const jwtToken = await getJWTToken();

    return {
      ...previousContext,
      headers: {
        Authorization: jwtToken,
      },
    };
  });

  return ApolloLink.concat(
    authorizationHeaderLink,
    new HttpLink({ uri: appSyncApiUrl }),
  );
};

export default createHttpLink;
