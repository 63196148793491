import { SIGNATURE } from '~/components/SignatureContainer/constants';
import { EmptyAttachmentValue } from '~/components/SignatureContainer/types';

type Options = {
  s3key: string;
  filename: string;
  contentLength: number;
  contentType?: string | null;
};
const emptyAttachmentValue = ({
  s3key,
  filename,
  contentLength,
  contentType,
}: Options): EmptyAttachmentValue => ({
  type: SIGNATURE.DHFILE,
  s3key: s3key,
  filename: filename,
  contentLength: contentLength,
  contentType: contentType || null,
});

export default emptyAttachmentValue;
