import React from 'react';
import styled, { css } from 'styled-components';

import { Link as NavLink } from '../Link/index';
import UserMenu from './components/UserMenu';
import AccountsMenu from './components/AccountsMenu';
import MobileMenu from './components/MobileMenu';
import Logo from './components/Logo';
import useCurrentBreakpoint from '~/hooks/useCurrentBreakpoint';
import Divider from './components/Divider';
import TEST_ID from './index.testid';

type NavigationLinkBase = {
  label: React.ReactNode;
  to: string;
};
export type NavigationLinkWithSubMenu = NavigationLinkBase & {
  subMenu: Array<NavigationLinkBase>;
};
export type NavigationLink = NavigationLinkBase;

const requestLinks: Array<NavigationLink> = [
  {
    label: 'Dashboard',
    to: '/-/',
  },
  {
    label: 'Taken',
    to: '/-/tasks',
  },
  {
    label: 'Contacten',
    to: '/-/contacts',
  },
  {
    label: 'Automation',
    to: '/-/automation/flows',
  },
  {
    label: 'Apps',
    to: '/-/apps',
  },
];

type Ref = HTMLDivElement;

export type Props = {
  dataTestId?: string;
};

const Navigation = React.forwardRef<Ref, Props>(({ ...rest }, ref) => {
  const currentBp = useCurrentBreakpoint();

  if (['mobile', 'tablet', 'tabletLandscape'].includes(currentBp))
    return <MobileMenu links={requestLinks} />;

  return (
    <NavigationContainer {...rest} data-testid="app-header" ref={ref}>
      <Logo />
      <Divider />
      <NavigationContent>
        <LinksContainer>
          {requestLinks.map(link => (
            <Link
              to={link.to}
              key={link.to}
              dataTestid={TEST_ID.asNavLink(link.to)}
            >
              {link.label}
            </Link>
          ))}
        </LinksContainer>
        <OptionsContainer>
          <UserMenu />
          <AccountsMenu />
        </OptionsContainer>
      </NavigationContent>
    </NavigationContainer>
  );
});

const HOVER_LINE_HEIGHT = '8px';
const ACTIVE_LINE_HEIGHT = '4px';
const TRANSITION = 'all ease 0.3s';

const NavigationContent = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;

    ${theme.mq.lessThan('tablet')`
     flex-direction: column;
     font-size: ${theme.fs('m')};
  `}
  `,
);

const OptionsContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Link = styled(NavLink)<{}>(({ theme }) => {
  const lightBlue = theme.color('primary', 'light');

  return css`
    color: ${theme.color('text')};
    height: 100%;
    text-decoration: none;
    margin: 0 ${theme.space('l')};
    padding: ${theme.space('l')} 0;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin: 0 ${theme.space('l')} 0 ${theme.space('xl')};
    }

    &:hover &:after,
    &:hover,
    &.active {
      color: ${lightBlue};
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 0;
      display: block;
      transition: ${TRANSITION};
      background: ${lightBlue};
    }

    &:hover {
      &::after {
        height: ${HOVER_LINE_HEIGHT};
      }
    }

    &.active {
      &::after {
        content: '';
        position: absolute;
        background: ${lightBlue};
        width: 100%;
        height: ${ACTIVE_LINE_HEIGHT};
        display: block;
        bottom: 0px;
      }
    }
  `;
});

const LinksContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const NavigationContainer = styled.header<{}>`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    box-shadow: ${theme.boxShadow('base')};
    background-color: ${theme.color('white')};
    z-index: 5000;
    font-size: ${theme.fs('m')};
  `}
`;

export default Navigation;
