import gql from 'graphql-tag';

export default gql`
  fragment EnvenPropertyFields on EnvenProperty {
    street
    houseNumber
    addition
    city
    postcode
    buildIn
    livingArea
  }
`;
