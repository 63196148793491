import React from 'react';

import {
  SessionHydrationUserFields_User_Fragment,
  SessionHydrationUserFieldsFragment,
  SessionHydrationOfficeFieldsFragment,
  SessionHydrationAccountFieldsFragment,
} from '~/graphql/types';
import { NamedEmail } from '~/util/namedEmailFormatter';
import { OptionOf } from '~/components/Inputs/Dropdown/';
import { AUTHORISE_LEVEL } from '~/util/constants';
import { SUBSCRIPTION_TYPE } from '~/scenes/Settings/Subscription/constants';

export type ViewableUser = SessionHydrationUserFieldsFragment;
export type ViewableOffice = SessionHydrationOfficeFieldsFragment;

export type AccountSubscription = {
  type: SUBSCRIPTION_TYPE;
};
export type ViewableAccount = SessionHydrationAccountFieldsFragment;

export type WithAccountContext = {
  accountContext: AccountContextProps;
};

export type MeContextProps = SessionHydrationUserFields_User_Fragment;

export type SendableMailbox = {
  authoriseLevel: AUTHORISE_LEVEL;
  namedEmail: NamedEmail;
};

export type AppRight = {
  enabled: boolean;
};

export type AccountContextProps = {
  viewableOffices: Array<ViewableOffice>;
  viewableUsers: Array<ViewableUser>;
  me: SessionHydrationUserFields_User_Fragment;
  account: ViewableAccount;
  availableEmailsToSendFrom: Array<SendableMailbox>;
  userWithId: (id: string) => ViewableUser | null;
  officeWithId: (id: string) => ViewableOffice | null;
  /**
   * Will return all active users within a given office.
   * It the office is null | undefined, an emptry array will be
   * returned.
   *
   * The decision to return an empty array comes from our
   * legacy codebase which expects an empty array when an office
   * is not set.
   */
  getUserOptionsForOffice: (
    officeId?: string | null,
    withAllUsersOption?: boolean,
  ) => Array<OptionOf<SessionHydrationUserFields_User_Fragment | null>>;
  getUserOptions: (
    officeId?: string | null,
    withAllUsersOption?: boolean,
  ) => Array<OptionOf<SessionHydrationUserFields_User_Fragment | null>>;
  /**
   * Get a list of all users within this office.
   */
  usersForOffice: (officeId: string) => Array<ViewableUser>;
  refetchSessionHydration: () => Promise<any>;
  officesForUser: (userId: string) => Array<ViewableOffice>;
  usersForOfficeWithPendingUsers: (officeId: string) => Array<ViewableUser>;
  // auth: AuthorisationMatrix;
  checkUserIsBeingDeletedFromOffice: (
    officeId: string,
    userId: string,
  ) => boolean;
  nameForUserWithId: (userId: string) => string | null;
  nameForOfficeWithId: (officeId: string) => string | null;
};

const AccountContext = React.createContext<AccountContextProps>({
  viewableOffices: [],
  viewableUsers: [],
  getUserOptionsForOffice: () => [],
  getUserOptions: () => [],
  /**
   * This can not be null given the SessionHydration query.
   *
   * We should check the other parameters and act accordingly get rid of a
   * number of conditionals.
   */
  me: null as any,
  account: null as any,
  availableEmailsToSendFrom: [],
  userWithId: () => null,
  officeWithId: () => null,
  usersForOffice: () => [],
  refetchSessionHydration: () => Promise.resolve(),
  officesForUser: () => [],
  usersForOfficeWithPendingUsers: () => [],
  checkUserIsBeingDeletedFromOffice: () => false,
  nameForUserWithId: () => null,
  nameForOfficeWithId: () => null,
});

export const withAccountContext = <ComponentProps extends object>(
  Component: React.ComponentType<ComponentProps>,
): React.ComponentType<ComponentProps> =>
  function ConnectedComponent(props: ComponentProps) {
    return (
      <AccountContext.Consumer>
        {(accountContext: AccountContextProps) => (
          <Component {...props} accountContext={accountContext} />
        )}
      </AccountContext.Consumer>
    );
  };

export default AccountContext;
