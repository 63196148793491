import gql from 'graphql-tag';

export default gql`
  fragment GenericEventBodyFields on StyleBlock {
    # ---------- Primitive Blocks ---------- #

    ... on TextBlock {
      label
      value
      css
      id
    }

    ... on MarkdownBlock {
      label
      value
      css
      id
    }

    ... on LinkBlock {
      label
      id
      css
      linkValue {
        href
        label
        type
      }
    }

    ... on ImageBlock {
      label
      id
      css
      imageValue {
        src
      }
    }

    ... on MapsBlock {
      label
      id
      css
      mapValue {
        location {
          lat
          lng
        }
      }
    }

    ... on DividerBlock {
      id
      css
      margin
      padding
    }

    # ---------- Layout Blocks ---------- #

    ... on FlexLayout {
      id
      childrenIds
      css

      margin
      padding
      direction
      justification
      align
      gap
    }

    ... on GridLayout {
      id
      childrenIds
      css

      margin
      padding
      gap
    }

    ... on ExpandableLayout {
      id
      childrenIds
      css

      margin
      padding
      title {
        expanded
        collapsed
      }
    }
  }
`;
