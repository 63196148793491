import type { RefObject } from 'react';
import { useEffect } from 'react';

/**
 * Checks whether a click registered outside the element
 *
 * This is currently not used anywhere. Decide if we want to keep it.
 * Logic is different than useOnOutsideClick. Here the callback gets called if e.target is not
 * a descendant of the ref.
 *
 * keywords: click,outside,element
 */
const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => any,
) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(e.target as Node)) {
        const { x, y, width, height } = ref.current.getBoundingClientRect();
        const { clientX, clientY } = e;

        const insideX = clientX > x && clientX < x + width;
        const insideY = clientY > y && clientY < y + height;

        if (insideX && insideY) return;
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export default useOutsideClick;
