import gql from 'graphql-tag';

export default gql`
  fragment ValuationReportPremiumFields on App_PremiumValuationReport {
    id
    accountId
    name
    officeId
    assignedUserId
    algasId
    sendContactEmail
    sendAgentEmail
    domain
  }
`;
